import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';

class BudgetMobx {
  //========================================================================================
  //observable values
  //budgetexec workload
  o_budgetExecExpandedUserIDsArray = [];
  o_budgetExecSelectedReassignBudgetManagerUserID = undefined;
  o_budgetExecCheckedCaptureIDsArray = [];
  o_budgetExecIncludeRejectedAndApprovedTF = false;

  //budget todo
  o_budgetTodoTableSelectedView = "awaitingApproval"; //"awaitingApproval", "rejected", "approved"
  o_budgetTodoOpenFundingRequestID = undefined;

  constructor() {
    makeObservable(this, {
      o_budgetExecExpandedUserIDsArray: observable,
      o_budgetExecSelectedReassignBudgetManagerUserID: observable,
      o_budgetExecCheckedCaptureIDsArray: observable,
      o_budgetExecIncludeRejectedAndApprovedTF: observable,
      o_budgetTodoTableSelectedView: observable,
      o_budgetTodoOpenFundingRequestID: observable,

      c_budgetExecDataObj: computed,
      c_budgetTodoSelectTableViewFieldTypeObj: computed,
      c_budgetTodoCaptureIDsAssignedToBudgetManagerArray: computed,
      c_budgetTodoFundingApprovalsArrayOfObjs: computed,
      c_budgetTodoSectionedAwaitingApprovalObj: computed,
      c_budgetTodoRejectedObj: computed,
      c_budgetTodoApprovedObj: computed,
      c_budgetTodoSelectedTodoObj: computed,
      c_budgetTodoOpenFundingRequestObj: computed,

      a_budget_exec_toggle_expand_collapse_user: action,
      a_budget_exec_toggle_include_rejected_and_approved_tf: action,
      a_budget_exec_set_selected_reassign_budget_manager_user_id: action,
      a_budget_exec_reassign_checked_captures_to_selected_budget_manager_user: action,
      a_budget_exec_single_user_check_or_uncheck_all_captures: action,
      a_budget_exec_check_or_uncheck_single_capture: action,
      a_budget_todo_set_table_selected_view: action,
      a_budget_todo_set_open_funding_request_id: action,
      a_budget_update_funding_request_status: action,
      a_budget_insert_new_expense: action,
      a_budget_update_expense_field: action,
      a_budget_delete_expense: action
    });
  }


  //========================================================================================
  //computed values
  //budgetexec workload
  get c_budgetExecDataObj() { //all budget and budgetexecs in divisions in or below the one this budgetexec user is in
    const c_userDivisionID = UserMobx.c_userDivisionID;
    
    const childrenDivisionIDsArray = DatabaseMobx.division_and_all_children_division_ids_array(c_userDivisionID);

    var budgetUsersArrayOfObjs = [];
    var userIDsToNotIncludeArray = [];
    for(let combinedUserMap of DatabaseMobx.c_combinedUsersNotDeactivatedMapOfMaps.values()) { //loop through combined user maps looking for budget/budgetexec users
      if(combinedUserMap.get("powerHasBudgetPowerTF")) { //if user can do budget or budgetexec (which includes superadmin)
        var userID = combinedUserMap.get("user_id");
        var userFullName = combinedUserMap.get("fullName");
        var userDivisionID = combinedUserMap.get("division_id");

        var userIsInDivisionTree = JSFUNC.in_array(userDivisionID, childrenDivisionIDsArray);
        if(!userIsInDivisionTree) { //keep an array of budget users not in the division tree of this exec to exclude them from the reassign selection list
          userIDsToNotIncludeArray.push(userID);
        }
        else { //budget user within the exec division tree
          var numFundingRequestsOverdue = 0;
          var numFundingRequestsDueWithinWeek = 0;
          var numFundingRequestsDueLonger = 0;
          var numFundingRequestsRejectedOrApproved = 0;

          //add newly constructed data fields for budget this user has assigned to them
          var capturesArrayOfObjs = []; //will sort these captures by most overdue need by date on top after looping through
          for(let captureMap of DatabaseMobx.o_tbl_captures.values()) {
            if(captureMap.get("budget_manager_user_id") === userID) {
              var captureID = captureMap.get("id");
              var captureName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);

              var earliestNeedByDate = JSFUNC.sort_max_date(); //9999 date will sort to the bottom for captures that have 0 in process budget, the lowest date (most overdue) will be on top

              var fundingRequestsArrayOfObjs = [];
              for(let fundingRequestMap of DatabaseMobx.o_tbl_c_budget_funding_requests.values()) {
                if(fundingRequestMap.get("capture_id") === captureID) {
                  //include all budget if the switch is flipped, do not show FE/C if the switch is off
                  var fundingRequestStatus01234 = fundingRequestMap.get("nr0_rq1_rj2_rs3_ap4");

                  if(fundingRequestStatus01234 > 0) { //do not include No Yet Requested status 0 for budgetexecs to see
                    var fundingRequestIsRejectedOrApprovedTF = (fundingRequestStatus01234 === 2 || fundingRequestStatus01234 === 4);
                    if(this.o_budgetExecIncludeRejectedAndApprovedTF || !fundingRequestIsRejectedOrApprovedTF) {
                      var fundingRequestObj = this.expanded_funding_request_obj_from_funding_request_map(fundingRequestMap);

                      var dateNeededSort = ((JSFUNC.date_is_filled_out_tf(fundingRequestObj.need_by_date)) ? (fundingRequestObj.need_by_date) : (JSFUNC.sort_max_date()));
                      if(dateNeededSort < earliestNeedByDate) {
                        earliestNeedByDate = dateNeededSort;
                      }

                      if(fundingRequestIsRejectedOrApprovedTF) {
                        numFundingRequestsRejectedOrApproved++;
                      }
                      else {
                        var numDaysUntilDateNeeded = JSFUNC.num_days_from_date1_to_date2(CaptureExecMobx.o_nowDate, fundingRequestObj.need_by_date);
                        if(numDaysUntilDateNeeded < 0) {
                          numFundingRequestsOverdue++;
                        }
                        else if(numDaysUntilDateNeeded <= 7) {
                          numFundingRequestsDueWithinWeek++;
                        }
                        else {
                          numFundingRequestsDueLonger++;
                        }
                      }

                      fundingRequestObj.dateNeededSort = dateNeededSort;
                      fundingRequestsArrayOfObjs.push(fundingRequestObj);
                    }
                  }

                }
              }
              JSFUNC.sort_arrayOfObjs(fundingRequestsArrayOfObjs, "dateNeededSort", true);

              capturesArrayOfObjs.push({
                id: captureID,
                captureFullName: captureName,
                earliestNeedByDate: earliestNeedByDate,
                fundingRequestsArrayOfObjs: fundingRequestsArrayOfObjs
              });
            }
          }
          JSFUNC.sort_arrayOfObjs(capturesArrayOfObjs, "earliestNeedByDate", true); //sort the captures for each user by the earliest need by date

          //create the budget user obj and append it to the array of budget users
          budgetUsersArrayOfObjs.push({
            user_id: userID,
            fullName: userFullName,
            numFundingRequestsOverdue: numFundingRequestsOverdue,
            numFundingRequestsDueWithinWeek: numFundingRequestsDueWithinWeek,
            numFundingRequestsDueLonger: numFundingRequestsDueLonger,
            numFundingRequestsRejectedOrApproved: numFundingRequestsRejectedOrApproved,
            capturesArrayOfObjs: capturesArrayOfObjs
          });
        }
      }
    }
    budgetUsersArrayOfObjs.sort(JSFUNC.sort_by_asc("sortName"));
    return({
      budgetUsersArrayOfObjs: budgetUsersArrayOfObjs,
      userIDsToNotIncludeArray: userIDsToNotIncludeArray
    });
  }





  //budget todo
  get c_budgetTodoSelectTableViewFieldTypeObj() {
    const valueArray = ["awaitingApproval", "rejected", "approved"];
    const displayArray = ["View Funding Requests Awaiting Your Approval", "View Your Rejected Funding Requests", "View Your Approved Funding Requests"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Todo Table View Filter", valueArray, true, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_budgetTodoCaptureIDsAssignedToBudgetManagerArray() { //precomputed only to be used by the filtering in c_budgetTodoFundingApprovalsArrayOfObjs
    var budgetTodoCaptureIDsAssignedToBudgetManagerArray = [];
    for(let captureMap of DatabaseMobx.o_tbl_captures.values()) {
      if(captureMap.get("budget_manager_user_id") === UserMobx.o_userID) {
        budgetTodoCaptureIDsAssignedToBudgetManagerArray.push(captureMap.get("id"));
      }
    }
    return(budgetTodoCaptureIDsAssignedToBudgetManagerArray);
  }

  get c_budgetTodoFundingApprovalsArrayOfObjs() { //precomputed only to be used to get each filtered/sectioned list of funding requests
    var budgetTodoFundingApprovalsArrayOfObjs = [];
    for(let fundingRequestMap of DatabaseMobx.o_tbl_c_budget_funding_requests.values()) {
      if(JSFUNC.in_array(fundingRequestMap.get("capture_id"), this.c_budgetTodoCaptureIDsAssignedToBudgetManagerArray)) {
        var fundingRequestObj = this.expanded_funding_request_obj_from_funding_request_map(fundingRequestMap);
        budgetTodoFundingApprovalsArrayOfObjs.push(fundingRequestObj);
      }
    }
    JSFUNC.sort_arrayOfObjs(budgetTodoFundingApprovalsArrayOfObjs, "rq_date", false);
    return(budgetTodoFundingApprovalsArrayOfObjs);
  }

  get c_budgetTodoSectionedAwaitingApprovalObj() {
    const nowJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(CaptureExecMobx.o_nowDate);

    var numFundingRequests = 0;
    var overdueFRAOO = [];
    var todayFRAOO = [];
    var tomorrowFRAOO = [];
    var weekFRAOO = [];
    var longerFRAOO = [];
    for(let fundingRequestObj of this.c_budgetTodoFundingApprovalsArrayOfObjs) { //loop through all funding requests assigned to this logged in budget manager and filter for only those that are requested or resubmitted
      if((fundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 1) || (fundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 3)) {
        var rqJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(fundingRequestObj.rq_date);
        var rqPlusApprovalNumDaysJsDateObj = JSFUNC.jsdateobj_add_days(rqJsDateObj, DatabaseMobx.c_companyBudgetManagerApprovalNumDays);
        var numDaysUntilDateNeeded = JSFUNC.num_days_from_jsDateObj1_to_jsDateObj2(nowJsDateObj, rqPlusApprovalNumDaysJsDateObj);

        if(numDaysUntilDateNeeded < 0) {
          overdueFRAOO.push(fundingRequestObj);
        }
        else if(numDaysUntilDateNeeded === 0) {
          todayFRAOO.push(fundingRequestObj);
        }
        else if(numDaysUntilDateNeeded === 1) {
          tomorrowFRAOO.push(fundingRequestObj);
        }
        else if(numDaysUntilDateNeeded <= 7) {
          weekFRAOO.push(fundingRequestObj);
        }
        else {
          longerFRAOO.push(fundingRequestObj);
        }

        numFundingRequests++;
      }
    }

    return({
      numFundingRequests: numFundingRequests,
      sectionNamesArray: ["Overdue", "Due Today", "Due Tomorrow", "Due within a Week", "Longer than a Week"],
      allSectionedFundingRequestsArrayOfArrayOfObjs: [overdueFRAOO, todayFRAOO, tomorrowFRAOO, weekFRAOO, longerFRAOO]
    });
  }

  get c_budgetTodoRejectedObj() {
    var numFundingRequests = 0;
    var rejectedFRAOO = [];
    for(let fundingRequestObj of this.c_budgetTodoFundingApprovalsArrayOfObjs) { //loop through all funding requests assigned to this logged in budget manager and filter for only those that are requested or resubmitted
      if(fundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 2) {
        rejectedFRAOO.push(fundingRequestObj);
        numFundingRequests++;
      }
    }

    return({
      numFundingRequests: numFundingRequests,
      sectionNamesArray: ["Rejected Funding Requests (waiting for resubmission/cancellation)"],
      allSectionedFundingRequestsArrayOfArrayOfObjs: [rejectedFRAOO]
    });
  }

  get c_budgetTodoApprovedObj() {
    var numFundingRequests = 0;
    var rejectedAPAOO = [];
    for(let fundingRequestObj of this.c_budgetTodoFundingApprovalsArrayOfObjs) { //loop through all funding requests assigned to this logged in budget manager and filter for only those that are requested or resubmitted
      if(fundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 4) {
        fundingRequestObj.need_by_date = JSFUNC.date_add_days(fundingRequestObj.rq_date, DatabaseMobx.c_companyBudgetManagerApprovalNumDays);
        rejectedAPAOO.push(fundingRequestObj);
        numFundingRequests++;
      }
    }

    return({
      numFundingRequests: numFundingRequests,
      sectionNamesArray: ["Approved Funding Requests"],
      allSectionedFundingRequestsArrayOfArrayOfObjs: [rejectedAPAOO]
    });
  }

  get c_budgetTodoSelectedTodoObj() {
    var numFundingRequests = 0;
    var sectionNamesArray = [];
    var allSectionedFundingRequestsArrayOfArrayOfObjs = [];
    if(this.o_budgetTodoTableSelectedView === "awaitingApproval") {
      numFundingRequests = this.c_budgetTodoSectionedAwaitingApprovalObj.numFundingRequests;
      sectionNamesArray = this.c_budgetTodoSectionedAwaitingApprovalObj.sectionNamesArray;
      allSectionedFundingRequestsArrayOfArrayOfObjs = this.c_budgetTodoSectionedAwaitingApprovalObj.allSectionedFundingRequestsArrayOfArrayOfObjs;
    }
    else if(this.o_budgetTodoTableSelectedView === "rejected") {
      numFundingRequests = this.c_budgetTodoRejectedObj.numFundingRequests;
      sectionNamesArray = this.c_budgetTodoRejectedObj.sectionNamesArray;
      allSectionedFundingRequestsArrayOfArrayOfObjs = this.c_budgetTodoRejectedObj.allSectionedFundingRequestsArrayOfArrayOfObjs;
    }
    else if(this.o_budgetTodoTableSelectedView === "approved") {
      numFundingRequests = this.c_budgetTodoApprovedObj.numFundingRequests;
      sectionNamesArray = this.c_budgetTodoApprovedObj.sectionNamesArray;
      allSectionedFundingRequestsArrayOfArrayOfObjs = this.c_budgetTodoApprovedObj.allSectionedFundingRequestsArrayOfArrayOfObjs;
    }

    return({
      numFundingRequests: numFundingRequests,
      sectionNamesArray: sectionNamesArray,
      allSectionedFundingRequestsArrayOfArrayOfObjs: allSectionedFundingRequestsArrayOfArrayOfObjs
    });
  }



  get c_budgetTodoOpenFundingRequestObj() {
    const o_budgetTodoOpenFundingRequestID = this.o_budgetTodoOpenFundingRequestID;

    if(o_budgetTodoOpenFundingRequestID === undefined) {
      return(undefined);
    }

    const budgetTodoOpenFundingRequestMap = DatabaseMobx.o_tbl_c_budget_funding_requests.get(o_budgetTodoOpenFundingRequestID);
    if(budgetTodoOpenFundingRequestMap === undefined) {
      return(undefined);
    }

    const captureID = budgetTodoOpenFundingRequestMap.get("capture_id");
    const budgetCategoryID = budgetTodoOpenFundingRequestMap.get("budget_category_id");

    const budgetCategoryMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_budget_categories_pool", budgetCategoryID);
    const budgetCategoryObj = JSFUNC.obj_from_map(budgetCategoryMap);

    const singleOpenFundingRequestMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_c_budget_funding_requests, "id", o_budgetTodoOpenFundingRequestID); //filtered mapOfMaps containing the single matching open funding request
    const filteredFundingRequestExpensesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_c_budget_expenses, ["capture_id", "funding_request_id"], [captureID, o_budgetTodoOpenFundingRequestID]);

    const expandedBudgetCategoryObj = this.expanded_budget_category_obj_from_budget_category_obj(captureID, budgetCategoryObj, singleOpenFundingRequestMapOfMaps, filteredFundingRequestExpensesMapOfMaps);

    if(expandedBudgetCategoryObj.fundingRequestsRq1ArrayOfObjs.length > 0) {
      return(expandedBudgetCategoryObj.fundingRequestsRq1ArrayOfObjs[0]);
    }
    else if(expandedBudgetCategoryObj.fundingRequestsRj2ArrayOfObjs.length > 0) {
      return(expandedBudgetCategoryObj.fundingRequestsRj2ArrayOfObjs[0]);
    }
    else if(expandedBudgetCategoryObj.fundingRequestsRs3ArrayOfObjs.length > 0) {
      return(expandedBudgetCategoryObj.fundingRequestsRs3ArrayOfObjs[0]);
    }
    else if(expandedBudgetCategoryObj.fundingRequestsAp4ArrayOfObjs.length > 0) {
      return(expandedBudgetCategoryObj.fundingRequestsAp4ArrayOfObjs[0]);
    }
    return(undefined);
  }


  //========================================================================================
  //action methods
  //budgetexec workload
  a_budget_exec_toggle_expand_collapse_user(i_userID) {
    const removedTF = this.o_budgetExecExpandedUserIDsArray.remove(i_userID);
    if(!removedTF) {
      this.o_budgetExecExpandedUserIDsArray.push(i_userID);
    }
  }

  a_budget_exec_toggle_include_rejected_and_approved_tf() {
    this.o_budgetExecIncludeRejectedAndApprovedTF = (!this.o_budgetExecIncludeRejectedAndApprovedTF);
  }

  a_budget_exec_set_selected_reassign_budget_manager_user_id(i_selectedContractsUserID) {
    this.o_budgetExecSelectedReassignBudgetManagerUserID = i_selectedContractsUserID;
  }

  a_budget_exec_reassign_checked_captures_to_selected_budget_manager_user(i_budgetExecCheckedCaptureIDsArray, i_budgetExecSelectedReassignBudgetManagerUserID) {
    var captureIDsNotAccessibleByNewBudgetManagerArray = [];

    const jsDescription = JSFUNC.js_description_from_action("BudgetMobx", "a_budget_exec_reassign_checked_captures_to_selected_budget_manager_user", ["i_budgetExecCheckedCaptureIDsArray", "i_budgetExecSelectedReassignBudgetManagerUserID"], [i_budgetExecCheckedCaptureIDsArray, i_budgetExecSelectedReassignBudgetManagerUserID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    for(let checkedCaptureID of i_budgetExecCheckedCaptureIDsArray) {
      var captureMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_captures, "id", checkedCaptureID);
      if(captureMap !== undefined) {
        //verify if this user has access to this capture
        var newBudgetManagerCanAccessCaptureTF = DatabaseMobx.user_can_access_capture_tf_from_capture_map_and_user_id(captureMap, i_budgetExecSelectedReassignBudgetManagerUserID);
        if(newBudgetManagerCanAccessCaptureTF) { //new budget manager can access this capture
          //get old budget manager user id for this capture to avoid updating the database with the same value
          var oldBudgetManagerID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfBudgetManager);

          //update this capture with the new budget manager user id
          C_CallPhpTblUID.add_update("tbl_captures", checkedCaptureID, "budget_manager_user_id", i_budgetExecSelectedReassignBudgetManagerUserID, "i", oldBudgetManagerID);
        }
        else { //new budget manager can't access this capture (division firewalling restriction), don't reassign and collect the capture name
          captureIDsNotAccessibleByNewBudgetManagerArray.push(checkedCaptureID);
        }
      }
    }
    C_CallPhpTblUID.execute();

    //reset the check marks all to blank
    this.o_budgetExecCheckedCaptureIDsArray.replace([]);

    return(captureIDsNotAccessibleByNewBudgetManagerArray);
  }

  a_budget_exec_single_user_check_or_uncheck_all_captures(i_capturesArrayOfObjs) {
    const oldCheckedCaptureIDsArray = this.o_budgetExecCheckedCaptureIDsArray.slice();
    var allCapturesCheckedTF = true;
    var userCaptureIDsArray = [];
    for(let captureObj of i_capturesArrayOfObjs) {
      if(!JSFUNC.in_array(captureObj.id, oldCheckedCaptureIDsArray)) {
        allCapturesCheckedTF = false;
      }
      userCaptureIDsArray.push(captureObj.id);
    }

    var newCheckedCaptureIDsArray = [];
    if(allCapturesCheckedTF) {
      for(let oldCaptureID of oldCheckedCaptureIDsArray) {
        if(!JSFUNC.in_array(oldCaptureID, userCaptureIDsArray)) {
          newCheckedCaptureIDsArray.push(oldCaptureID);
        }
      }
    }
    else {
      newCheckedCaptureIDsArray = JSFUNC.merge_unique(oldCheckedCaptureIDsArray, userCaptureIDsArray);
    }

    this.o_budgetExecCheckedCaptureIDsArray.replace(newCheckedCaptureIDsArray);
  }

  a_budget_exec_check_or_uncheck_single_capture(i_captureID) {
    const removedTF = this.o_budgetExecCheckedCaptureIDsArray.remove(i_captureID);
    if(!removedTF) {
      this.o_budgetExecCheckedCaptureIDsArray.push(i_captureID);
    }
  }





  //budget todo
  a_budget_todo_set_table_selected_view(i_selectedView) {
    this.o_budgetTodoTableSelectedView = i_selectedView;
  }

  a_budget_todo_set_open_funding_request_id(i_openFundingRequestID) {
    this.o_budgetTodoOpenFundingRequestID = i_openFundingRequestID;
  }

  a_budget_update_funding_request_status(i_fundingRequestObj, i_newStatus01234, i_budgetManagerNotes, i_functionOnFinish) {
    const totalExpensesValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_fundingRequestObj.totalExpenses, DatabaseMobx.c_genericMoneyFieldTypeObj);
    const captureMap = DatabaseMobx.o_tbl_captures.get(i_fundingRequestObj.capture_id);
    const captureBudgetManagerUserID = ((captureMap !== undefined) ? (captureMap.get("budget_manager_user_id")) : (-1));
    const budgetManagerNameValueMaskPlainText = DatabaseMobx.user_name_mask_plaintext_from_user_id(captureBudgetManagerUserID);
    const budgetCategoryMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_budget_categories_pool", i_fundingRequestObj.budget_category_id);
    const budgetCategoryName = budgetCategoryMap.get("name");
    const captureName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);

    const numEstimatedExpensesString = i_fundingRequestObj.numExpenses + " estimated " + JSFUNC.plural(i_fundingRequestObj.numExpenses, "expense", "expenses");

    var dateFieldDbName = "";
    var userIDFieldDbName = "";
    var changeLogField = "Funding Request";
    var changeLogValue = "";
    var notificationUserID = -1;
    var notificationMessage = "";
    var notificationClickActionString = "";
    if(i_newStatus01234 === 1) {
      dateFieldDbName = "rq_date";
      userIDFieldDbName = "rq_user_id";
      changeLogValue = "Submitted request for " + totalExpensesValueMaskPlainText + " (" + numEstimatedExpensesString + ") to " + budgetManagerNameValueMaskPlainText + " for approval to add to '" + budgetCategoryName + "' budget";
      if(i_fundingRequestObj.cm_notes !== "") {
        changeLogValue += " [Notes: '" + i_fundingRequestObj.cm_notes + "']";
      }
      notificationUserID = captureBudgetManagerUserID;
      notificationMessage = "New Funding Request for " + totalExpensesValueMaskPlainText + " from " + UserMobx.c_userName + " for '" + budgetCategoryName + "' budget regarding capture '" + captureName + "'";
      if(i_fundingRequestObj.cm_notes !== "") {
        notificationMessage += " [Notes: '" + i_fundingRequestObj.cm_notes + "']";
      }
      notificationClickActionString = "openFundingRequestAsBudgetManager:" + i_fundingRequestObj.id;
    }
    else if(i_newStatus01234 === 2) {
      dateFieldDbName = "rj_date";
      userIDFieldDbName = "rj_user_id";
      changeLogValue = "Funding rejected for " + totalExpensesValueMaskPlainText + " by " + UserMobx.c_userName + " to '" + budgetCategoryName + "' budget [Notes: '" + i_budgetManagerNotes + "']";
      notificationUserID = i_fundingRequestObj.rq_user_id;
      notificationMessage = "Funding Request rejected for " + totalExpensesValueMaskPlainText + " by " + UserMobx.c_userName + " for '" + budgetCategoryName + "' budget regarding capture '" + captureName + "' [Notes: '" + i_budgetManagerNotes + "']";
      notificationClickActionString = "openCaptureBudgetFundingRequest:c" + i_fundingRequestObj.capture_id + ",fr" + i_fundingRequestObj.id;
    }
    else if(i_newStatus01234 === 3) {
      dateFieldDbName = "rs_date";
      userIDFieldDbName = "rs_user_id";
      changeLogValue = "Resubmitted request for " + totalExpensesValueMaskPlainText + " (" + numEstimatedExpensesString + ") to " + budgetManagerNameValueMaskPlainText + " for approval to add to '" + budgetCategoryName + "' budget";
      if(i_fundingRequestObj.cm_notes !== "") {
        changeLogValue += " [Notes: '" + i_fundingRequestObj.cm_notes + "']";
      }
      notificationUserID = captureBudgetManagerUserID;
      notificationMessage = "Resubmitted Funding Request for " + totalExpensesValueMaskPlainText + " from " + UserMobx.c_userName + " for '" + budgetCategoryName + "' budget regarding capture '" + captureName + "'";
      if(i_fundingRequestObj.cm_notes !== "") {
        notificationMessage += " [Notes: '" + i_fundingRequestObj.cm_notes + "']";
      }
      notificationClickActionString = "openFundingRequestAsBudgetManager:" + i_fundingRequestObj.id;
    }
    else if(i_newStatus01234 === 4) {
      dateFieldDbName = "ap_date";
      userIDFieldDbName = "ap_user_id";
      changeLogValue = "Funding approved for " + totalExpensesValueMaskPlainText + " by " + UserMobx.c_userName + " to '" + budgetCategoryName + "' budget";
      notificationUserID = ((i_fundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 3) ? (i_fundingRequestObj.rs_user_id) : (i_fundingRequestObj.rq_user_id));
      notificationMessage = "Funding Request approved for " + totalExpensesValueMaskPlainText + " by " + UserMobx.c_userName + " for '" + budgetCategoryName + "' budget regarding Capture '" + captureName + "'";
      notificationClickActionString = "openCaptureBudgetFundingRequest:c" + i_fundingRequestObj.capture_id + ",fr" + i_fundingRequestObj.id;
    }

    var fieldNamesArray = ["nr0_rq1_rj2_rs3_ap4", dateFieldDbName, userIDFieldDbName];
    var valuesArray = [i_newStatus01234, JSFUNC.now_date(), UserMobx.o_userID];
    var idsbArray = ["i", "s", "i"];
    if(i_budgetManagerNotes !== "") {
      fieldNamesArray.push("bm_notes");
      valuesArray.push(i_budgetManagerNotes);
      idsbArray.push("s");
    }

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_update_funding_request_status", ["i_fundingRequestObj"], [i_fundingRequestObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_budget_funding_requests", i_fundingRequestObj.id, fieldNamesArray, valuesArray, idsbArray);
    C_CallPhpTblUID.add_changelog_budget(i_fundingRequestObj.capture_id, i_fundingRequestObj.id, changeLogField, changeLogValue);
    if(notificationUserID > 0) {
      C_CallPhpTblUID.add_notifications_with_emails(notificationUserID, false, notificationMessage, notificationClickActionString);
    }
    C_CallPhpTblUID.add_function("onFinish", i_functionOnFinish);
    C_CallPhpTblUID.execute();
  }

  a_budget_insert_new_expense(i_captureID, i_budgetCategoryID, i_fundingRequestID, i_newExpenseTypeID, i_newExpenseValue, i_newExpenseDescription) {
    const fieldNamesArray = ["capture_id", "budget_category_id", "funding_request_id", "date", "expense_type_id", "description", "value"];
    const valuesArray = [i_captureID, i_budgetCategoryID, i_fundingRequestID, JSFUNC.now_date(), i_newExpenseTypeID, i_newExpenseDescription, i_newExpenseValue];
    const idsbArray = ["i", "i", "i", "s", "i", "s", "i"];

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_insert_new_expense", ["i_captureID", "i_budgetCategoryID", "i_fundingRequestID", "i_newExpenseTypeID", "i_newExpenseValue", "i_newExpenseDescription"], [i_captureID, i_budgetCategoryID, i_fundingRequestID, i_newExpenseTypeID, i_newExpenseValue, i_newExpenseDescription]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert("tbl_c_budget_expenses", fieldNamesArray, valuesArray, idsbArray);

    //only insert budget changelog entry if inserting a new spent expense against the actual budget (do not include estimated expenses that make up the funding request)
    if(i_fundingRequestID <= 0) {
      const changeLogField = "Spent Expense";
      const expenseValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_newExpenseValue, DatabaseMobx.c_genericMoneyFieldTypeObj);
      const budgetCategoryMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_budget_categories_pool", i_budgetCategoryID);
      const expenseTypeValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_newExpenseTypeID, DatabaseMobx.c_selectBudgetExpenseTypeFieldTypeObj);
      const changeLogValue = "Added " + expenseValueMaskPlainText + " spent expense to '" + budgetCategoryMap.get("name") + "' budget (Expense Type: '" + expenseTypeValueMaskPlainText + "', Description: '" + i_newExpenseDescription + "')";
      C_CallPhpTblUID.add_changelog_budget(i_captureID, -1, changeLogField, changeLogValue);
    }

    C_CallPhpTblUID.execute();
  }

  a_budget_update_expense_field(i_expenseObj, i_fieldName, i_value, i_idsb) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_update_expense_field", ["i_expenseObj", "i_fieldName", "i_value", "i_idsb"], [i_expenseObj, i_fieldName, i_value, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_budget_expenses", i_expenseObj.id, i_fieldName, i_value, i_idsb);

    //only insert budget changelog entry if updating a spent expense against the actual budget (do not include estimated expenses that make up the funding request)
    if(i_expenseObj.funding_request_id <= 0) {
      const changeLogField = "Spent Expense";

      var updatedFieldDisplayName = "";
      var expenseTypeID = i_expenseObj.expense_type_id;
      var description = i_expenseObj.description;
      var value = i_expenseObj.value;
      if(i_fieldName === "expense_type_id") {
        updatedFieldDisplayName = "Expense Type";
        expenseTypeID = i_value;
      }
      else if(i_fieldName === "description") {
        updatedFieldDisplayName = "Description";
        description = i_value;
      }
      else if(i_fieldName === "value") {
        updatedFieldDisplayName = "Value";
        value = i_value;
      }
      const expenseValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(value, DatabaseMobx.c_genericMoneyFieldTypeObj);
      const budgetCategoryMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_budget_categories_pool", i_expenseObj.budget_category_id);
      const expenseTypeValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(expenseTypeID, DatabaseMobx.c_selectBudgetExpenseTypeFieldTypeObj);
      const changeLogValue = "Updated '" + updatedFieldDisplayName + "' for spent expense in '" + budgetCategoryMap.get("name") + "' budget (Value: " + expenseValueMaskPlainText + ", Expense Type: '" + expenseTypeValueMaskPlainText + "', Description: '" + description + "')";
      C_CallPhpTblUID.add_changelog_budget(i_expenseObj.capture_id, -1, changeLogField, changeLogValue);
    }

    C_CallPhpTblUID.execute();
  }

  a_budget_delete_expense(i_expenseObj) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_budget_delete_expense", ["i_expenseObj"], [i_expenseObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_c_budget_expenses", i_expenseObj.id); //sorted by date, no need for resort after delete

    //only insert budget changelog entry if deleting a spent expense against the actual budget (do not include estimated expenses that make up the funding request)
    if(i_expenseObj.funding_request_id <= 0) {
      const changeLogField = "Spent Expense";
      const expenseValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_expenseObj.value, DatabaseMobx.c_genericMoneyFieldTypeObj);
      const budgetCategoryMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_budget_categories_pool", i_expenseObj.budget_category_id);
      const expenseTypeValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_expenseObj.expense_type_id, DatabaseMobx.c_selectBudgetExpenseTypeFieldTypeObj);
      const changeLogValue = "Deleted " + expenseValueMaskPlainText + " spent expense from '" + budgetCategoryMap.get("name") + "' budget (Expense Type: '" + expenseTypeValueMaskPlainText + "', Description: '" + i_expenseObj.description + "')";
      C_CallPhpTblUID.add_changelog_budget(i_expenseObj.capture_id, -1, changeLogField, changeLogValue);
    }

    C_CallPhpTblUID.execute();
  }






  expanded_funding_request_obj_from_funding_request_map(i_fundingRequestMap) {
    var fundingRequestObj = JSFUNC.obj_from_map(i_fundingRequestMap);

    var labelColorObj = this.funding_request_label_and_color_obj_from_status_number(fundingRequestObj.nr0_rq1_rj2_rs3_ap4);
    fundingRequestObj.statusLabel = labelColorObj.label;
    fundingRequestObj.statusColor = labelColorObj.color;

    fundingRequestObj.need_by_date = JSFUNC.date_add_days(fundingRequestObj.rq_date, DatabaseMobx.c_companyBudgetManagerApprovalNumDays);
    var needByDateFieldTypeObj = ((fundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 4) ? (DatabaseMobx.c_genericDateDayMdyDaysUntil1FieldTypeObj) : (DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj)); //approved funding requests should not show 'overdue'
    fundingRequestObj.needByDateMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(fundingRequestObj.need_by_date, needByDateFieldTypeObj);

    fundingRequestObj.budgetCategoryName = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(fundingRequestObj.budget_category_id, DatabaseMobx.c_selectBudgetCategoryFieldTypeObj);

    fundingRequestObj.captureFullName = DatabaseMobx.capture_name_plaintext_from_capture_id(fundingRequestObj.capture_id);

    var requestedOrResubmittedByUserID = ((fundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 3) ? (fundingRequestObj.rs_user_id) : (fundingRequestObj.rq_user_id));
    fundingRequestObj.requestedByUserName = DatabaseMobx.user_name_mask_from_user_id(requestedOrResubmittedByUserID);

    return(fundingRequestObj);
  }



  expanded_budget_category_obj_from_budget_category_obj(i_captureID, i_budgetCategoryObj, i_fundingRequestsMapOfMaps, i_expensesMapOfMaps) {
    var ebco = JSFUNC.copy_obj(i_budgetCategoryObj);

    var fundingRequestsNr0ArrayOfObjs = [];
    var fundingRequestsRq1ArrayOfObjs = [];
    var fundingRequestsRj2ArrayOfObjs = [];
    var fundingRequestsRs3ArrayOfObjs = [];
    var fundingRequestsAp4ArrayOfObjs = [];
    var expensesArrayOfObjs = [];
    var totalPending = 0;
    var totalApproved = 0;
    var totalSpent = 0;
    var numFundingRequests = 0;

    for(let fundingRequestMap of i_fundingRequestsMapOfMaps.values()) {
      if((fundingRequestMap.get("capture_id") === i_captureID) && (fundingRequestMap.get("budget_category_id") === i_budgetCategoryObj.id)) {
        var expandedFundingRequestObj = JSFUNC.obj_from_map(fundingRequestMap);

        //find all expenses listed that make up this funding request
        var fundingExpensesArrayOfObjs = [];
        var totalExpenses = 0;
        for(let expenseMap of i_expensesMapOfMaps.values()) {
          if((expenseMap.get("capture_id") === i_captureID) && (expenseMap.get("funding_request_id") === expandedFundingRequestObj.id)) {
            var expenseObj = JSFUNC.obj_from_map(expenseMap);
            fundingExpensesArrayOfObjs.push(expenseObj);
            totalExpenses += expenseObj.value;
          }
        }

        expandedFundingRequestObj.expensesArrayOfObjs = fundingExpensesArrayOfObjs;
        expandedFundingRequestObj.numExpenses = fundingExpensesArrayOfObjs.length;
        expandedFundingRequestObj.totalExpenses = totalExpenses;
        expandedFundingRequestObj.budgetCategoryName = i_budgetCategoryObj.name;

        //place the funding request in an array based on the nr0_rq1_rj2_rs3_ap4 status
        if(expandedFundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 0) {
          fundingRequestsNr0ArrayOfObjs.push(expandedFundingRequestObj);
        }
        else if(expandedFundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 1) {
          fundingRequestsRq1ArrayOfObjs.push(expandedFundingRequestObj);
          totalPending += totalExpenses;
        }
        else if(expandedFundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 2) {
          fundingRequestsRj2ArrayOfObjs.push(expandedFundingRequestObj);
        }
        else if(expandedFundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 3) {
          fundingRequestsRs3ArrayOfObjs.push(expandedFundingRequestObj);
          totalPending += totalExpenses;
        }
        else if(expandedFundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 4) {
          fundingRequestsAp4ArrayOfObjs.push(expandedFundingRequestObj);
          totalApproved += totalExpenses;
        }

        numFundingRequests++;
      }
    }

    //gather all spent expenses
    for(let expenseMap of i_expensesMapOfMaps.values()) {
      if((expenseMap.get("capture_id") === i_captureID) && (expenseMap.get("budget_category_id") === i_budgetCategoryObj.id) && (expenseMap.get("funding_request_id") <= 0)) { //fundingRequestID <= 0 is a flag meaning a spent expense (as apposed to a funding request expense which has a funding request id that it is attached to)
        var expenseObj = JSFUNC.obj_from_map(expenseMap);
        expensesArrayOfObjs.push(expenseObj);
        totalSpent += expenseObj.value;
      }
    }

    ebco.fundingRequestsNr0ArrayOfObjs = fundingRequestsNr0ArrayOfObjs;
    ebco.fundingRequestsRq1ArrayOfObjs = fundingRequestsRq1ArrayOfObjs;
    ebco.fundingRequestsRj2ArrayOfObjs = fundingRequestsRj2ArrayOfObjs;
    ebco.fundingRequestsRs3ArrayOfObjs = fundingRequestsRs3ArrayOfObjs;
    ebco.fundingRequestsAp4ArrayOfObjs = fundingRequestsAp4ArrayOfObjs;
    ebco.expensesArrayOfObjs = expensesArrayOfObjs;
    ebco.totalPending = totalPending;
    ebco.totalApproved = totalApproved;
    ebco.totalSpent = totalSpent;
    ebco.totalRemaining = totalApproved - totalSpent;
    ebco.isOverspentTF = (ebco.totalRemaining < 0);
    ebco.numFundingRequests = numFundingRequests;

    return(ebco);
  }



  funding_request_label_and_color_obj_from_status_number(i_nr0_rq1_rj2_rs3_ap4) {
    var label = undefined;
    var color = undefined;
    if(i_nr0_rq1_rj2_rs3_ap4 === 0) {
      label = "Not Yet Submitted";
      color = "bbbbbb";
    }
    else if(i_nr0_rq1_rj2_rs3_ap4 === 1) {
      label = "Requested and Awaiting Approval";
      color = "eeee99";
    }
    else if(i_nr0_rq1_rj2_rs3_ap4 === 2) {
      label = "Rejected Needing Edits/Resubmission or Deletion";
      color = "ee6666";
    }
    else if(i_nr0_rq1_rj2_rs3_ap4 === 3) {
      label = "Resubmitted and Awaiting Approval";
      color = "cccc77";
    }
    else if(i_nr0_rq1_rj2_rs3_ap4 === 4) {
      label = "Approved";
      color = "66cc66";
    }
    else {
      label = "--Invalid Funding Request Status " + i_nr0_rq1_rj2_rs3_ap4 + "--";
      color = "666666";
    }

    return({
      label: label,
      color: color
    });
  }


}
export default new BudgetMobx();
