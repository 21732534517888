import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import AdminMobx from '../Admin/AdminMobx.js';
import AdminIntegrationsMobx from '../AdminIntegrations/AdminIntegrationsMobx.js';
import BudgetMobx from '../Budget/BudgetMobx.js';
import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import CapturesMobx from '../Captures/CapturesMobx.js';
import ContactsMobx from '../Contacts/ContactsMobx.js';
import DivexecMobx from '../Divexec/DivexecMobx.js';
import GCSSMobx from '../GCSS/GCSSMobx.js';
import OpenCaptureMobx from '../OpenCapture/OpenCaptureMobx.js';
import TeammateContractsMobx from '../TeammateContracts/TeammateContractsMobx.js';

export class RightPanelMobx {
  //===observable fields===========================================================================================
  o_closed0_u1_t2_n3 = 0; //0 - panel is closed, 1 - User panel, 2 - Tasks panel, 3 - Notifications panel
  o_helpPanelIsOpenTF = false;

  //User Panel
  o_userTabNameOpen = null; //null if currenly on tab selection screen, is the name of the selected tab if currently in a subtab
  o_myAdminSelectedUserID = -1; //set when entering the Message My Admin tab
  o_messageMyAdminText = "";
  o_newTicketObj = {
    priorityCode: 4, //4 (Low) is the starting choice
    description: "",
    priorityErrorTF: false,
    descriptionErrorTF: false,
    messageObj: null
  }
  o_currentlyViewingTicketThreadID = -1; //id in ticketsMatrix[t].initialThread.id when a ticket is selected to be viewed, affects computed c_currentlyViewedSingleTicketObj below
  o_ticketNewResponseObj = {
    response: "",
    responseErrorTF: false,
    messageObj: null
  };

  //Tasks Panel
  o_tasksPanelShowingActiveTF = true;
  o_tasksPanelFiltersSubpanelOpenTF = false;
  o_tasksPanelFilterMeToMeTF = false;
  o_tasksPanelFilterOtherToMeTF = false;
  o_tasksPanelFilterMeToOtherTF = false;
  o_tasksPanelFilterAssignedToUserIDsComma = "";
  o_tasksPanelFilterAssignedByUserIDsComma = "";
  o_tasksPanelFilterDateFromYmd = JSFUNC.blank_date();
  o_tasksPanelFilterDateToYmd = JSFUNC.blank_date();
  o_tasksPanelFilterText = "";

  //Add/Edit Task floating box
  o_tasksEditingTaskRelevanceCaptureID = undefined; //undefined - box not open, -1 - adding a new task, 1-N - editing an existing task
  o_tasksEditingTaskObj = undefined; //undefined - new task, computed taskObj - editing that task

  //Notifications
  o_notificationsClickActionNotificationID = undefined;
  o_broadcastReaderNotificationDate = undefined;
  o_broadcastReaderNotificationMessage = undefined; //undefined - the broadcast reader floating box is not drawn, otherwise it is drawn with the message displayed inside and can be closed

  //Help Panel
  o_helpPanelContentIsLoadingTF = false;

  constructor() {
    makeObservable(this, {
      o_closed0_u1_t2_n3: observable,
      o_helpPanelIsOpenTF: observable,
      o_userTabNameOpen: observable,
      o_myAdminSelectedUserID: observable,
      o_messageMyAdminText: observable,
      o_newTicketObj: observable,
      o_currentlyViewingTicketThreadID: observable,
      o_ticketNewResponseObj: observable,
      o_tasksPanelShowingActiveTF: observable,
      o_tasksPanelFiltersSubpanelOpenTF: observable,
      o_tasksPanelFilterMeToMeTF: observable,
      o_tasksPanelFilterOtherToMeTF: observable,
      o_tasksPanelFilterMeToOtherTF: observable,
      o_tasksPanelFilterAssignedToUserIDsComma: observable,
      o_tasksPanelFilterAssignedByUserIDsComma: observable,
      o_tasksPanelFilterDateFromYmd: observable,
      o_tasksPanelFilterDateToYmd: observable,
      o_tasksPanelFilterText: observable,
      o_tasksEditingTaskRelevanceCaptureID: observable,
      o_tasksEditingTaskObj: observable,
      o_notificationsClickActionNotificationID: observable,
      o_broadcastReaderNotificationDate: observable,
      o_broadcastReaderNotificationMessage: observable,
      o_helpPanelContentIsLoadingTF: observable,

      c_rightPanelIsOpenTF: computed,
      c_desktopRightPanelIsOpenTF: computed,
      c_currentDesktopRightPanelWidthEm: computed,
      c_desktopHelpPanelIsOpenTF: computed,
      c_currentDesktopHelpPanelWidthEm: computed,
      c_combinedRightAndHelpMobileFloatingPanelIsOpenTF: computed,
      c_userPanel3rdPartyIntegrationTabDisplayName: computed,
      c_userPanelTabNamesArray: computed,
      c_isOnMainMenuTabsTF: computed,
      c_myAdminUsersArrayOfObjs: computed,
      c_messageMyAdminSelectedAdminFullName: computed,
      c_userTicketsArrayOfObjs: computed,
      c_numOpenTickets: computed,
      c_numClosedTickets: computed,
      c_currentlyViewedSingleTicketObj: computed,
      c_ticketsSubpanelSingleTicketIsOpenTF: computed,
      c_currentlyViewedSingleTicketResponsesArrayOfObjs: computed,
      c_ticketNumResponsesMap: computed,
      c_ticketsTabNumUnreadBITResponses: computed,
      c_allMyTasksArrayOfObjs: computed,
      c_tasksNumTaskFiltersApplied: computed,
      c_allMyFilteredTasksArrayOfObjs: computed,
      c_myActiveTasksArrayOfObjs: computed,
      c_activeTasksIAssignedToOthersArrayOfObjs: computed,
      c_myCompletedTasksArrayOfObjs: computed,
      c_completedTasksIAssignedToOthersArrayOfObjs: computed,
      c_tasksExtraCaptureFieldsArrayOfObjs: computed,
      c_notificationsArrayOfObjs: computed,
      c_numNotifications: computed,
      c_notificationsUnreadIDsArray: computed,
      c_numUnreadNotifications: computed,
      c_helpPanelsArrayOfObjs: computed,
      c_topBarHelpPanelObjOrUndefined: computed,
      c_systemCurrentHelpPanelObjOrUndefined: computed,

      a_set_open_panel: action,
      a_set_help_panel_is_open_tf: action,
      a_tab_click: action,
      a_subpanel_return_click: action,
      a_message_my_admin_set_my_admin_selected_user_id: action,
      a_message_my_admin_set_text: action,
      a_set_new_ticket_priority: action,
      a_set_new_ticket_description: action,
      a_set_new_ticket_priority_error_tf: action,
      a_set_new_ticket_description_error_tf: action,
      a_set_new_ticket_message_obj: action,
      a_new_ticket_select_priority: action,
      a_new_ticket_change_description: action,
      a_new_ticket_submit: action,
      a_set_ticket_new_response_response: action,
      a_set_ticket_new_response_response_error_tf: action,
      a_set_ticket_new_response_message_obj: action,
      a_ticket_new_response_change_response: action,
      a_view_ticket_click: action,
      a_close_currently_viewed_ticket: action,
      a_set_ticket_closed0_open1: action,
      a_ticket_submit_new_response: action,
      a_mark_all_BIT_responses_as_read_for_currently_viewed_ticket: action,
      a_tasks_set_panel_showing_active_tf: action,
      a_tasks_panel_set_filters_subpanel_open_tf: action,
      a_tasks_panel_set_filter_me_to_me_tf: action,
      a_tasks_panel_set_filter_other_to_me_tf: action,
      a_tasks_panel_set_filter_me_to_other_tf: action,
      a_tasks_panel_set_filter_assigned_to_user_ids_comma: action,
      a_tasks_panel_set_filter_assigned_by_user_ids_comma: action,
      a_tasks_panel_set_filter_date_from_ymd: action,
      a_tasks_panel_set_filter_date_to_ymd: action,
      a_tasks_panel_set_filter_text: action,
      a_tasks_set_editing_task_relevance_and_task_obj: action,
      a_tasks_open_task_from_task_id: action,
      a_tasks_insert_new_task: action,
      a_tasks_update_task: action,
      a_tasks_delete_task: action,
      a_notification_set_click_action_notification_id: action,
      a_notification_mark_as_read: action,
      a_notification_handle_click_action: action,
      a_notification_dismiss: action,
      a_notification_send_broadcast_notifications: action,
      a_set_broadcast_reader_notification_date_and_message: action,
      a_help_panel_load_help_panel_content_if_not_yet_loaded: action,
      a_set_help_panel_content_is_loading_tf: action
    });
  }


  //===computed fields============================================================================================
  //Right and Help Panels
  get c_rightPanelIsOpenTF() {
    return(this.o_closed0_u1_t2_n3 > 0);
  }

  get c_desktopRightPanelIsOpenTF() {
    return(this.c_rightPanelIsOpenTF && !this.c_combinedRightAndHelpMobileFloatingPanelIsOpenTF);
  }

  get c_currentDesktopRightPanelWidthEm() {
    if(this.c_desktopRightPanelIsOpenTF) {
      return(UserMobx.c_userRightPanelWidthEm);
    }
    return(0);
  }

  get c_desktopHelpPanelIsOpenTF() {
    return(this.o_helpPanelIsOpenTF && !this.c_combinedRightAndHelpMobileFloatingPanelIsOpenTF);
  }

  get c_currentDesktopHelpPanelWidthEm() {
    if(this.c_desktopHelpPanelIsOpenTF) {
      return(UserMobx.c_userHelpPanelWidthEm);
    }
    return(0);
  }

  get c_combinedRightAndHelpMobileFloatingPanelIsOpenTF() {
    if(CaptureExecMobx.c_isMobileTF) {
      return(this.c_rightPanelIsOpenTF || this.o_helpPanelIsOpenTF);
    }
    else if(CaptureExecMobx.c_isTabletTF) {
      return((this.c_rightPanelIsOpenTF && !this.o_helpPanelIsOpenTF) || (!this.c_rightPanelIsOpenTF && this.o_helpPanelIsOpenTF));
    }
    else {
      return(false);
    }
  }



  //User Panel
  get c_userPanel3rdPartyIntegrationTabDisplayName() {
    const c_integrationSystemDisplayName = AdminIntegrationsMobx.c_integrationSystemDisplayName;
    return("Integration to " + c_integrationSystemDisplayName);
  }

  get c_userPanelTabNamesArray() {
    const c_userPanel3rdPartyIntegrationTabDisplayName = this.c_userPanel3rdPartyIntegrationTabDisplayName;
    const o_captureExecInSingleCaptureDirectAccessModeTF = CaptureExecMobx.o_captureExecInSingleCaptureDirectAccessModeTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;
    const c_hasMultipleLoginsTF = UserMobx.c_hasMultipleLoginsTF;
    const c_anyOfUsersMultiLoginsHaveAdminPowerTF = UserMobx.c_anyOfUsersMultiLoginsHaveAdminPowerTF;

    var userPanelTabNamesArray = [];
    if(c_hasMultipleLoginsTF && !o_captureExecInSingleCaptureDirectAccessModeTF) { //only draw the multi login users tab if the multiLoginUsersMatrix contains data
      userPanelTabNamesArray.push("Switch Login");
    }
    userPanelTabNamesArray.push("Settings");
    if(c_companyIntegrationOnTF) {
      userPanelTabNamesArray.push(c_userPanel3rdPartyIntegrationTabDisplayName);
    }
    if(!c_anyOfUsersMultiLoginsHaveAdminPowerTF && !o_captureExecInSingleCaptureDirectAccessModeTF) {
      userPanelTabNamesArray.push("Message My Admin");
    }
    if(!o_captureExecInSingleCaptureDirectAccessModeTF) {
      userPanelTabNamesArray.push("Change Password");
    }
    if(!o_captureExecInSingleCaptureDirectAccessModeTF) {
      userPanelTabNamesArray.push("Support Ticket for BIT Solutions");
    }
    userPanelTabNamesArray.push("Log Out");
    return(userPanelTabNamesArray);
  }

  get c_isOnMainMenuTabsTF() { //if you have one of the tabs open, you are no longer on the main menu tabs
    return(!JSFUNC.in_array(this.o_userTabNameOpen, this.c_userPanelTabNamesArray));
  }




  //Message My Admin subpanel
  get c_myAdminUsersArrayOfObjs() {
    const c_allAdminPowerUsersNotDeactivatedArrayOfObjs = DatabaseMobx.c_allAdminPowerUsersNotDeactivatedArrayOfObjs;
    const c_userDivisionID = UserMobx.c_userDivisionID;

    const numAdmins = c_allAdminPowerUsersNotDeactivatedArrayOfObjs.length;

    //if there is only 1 admin (or 0), no need to sort the list
    if(numAdmins < 2) {
      return(c_allAdminPowerUsersNotDeactivatedArrayOfObjs);
    }

    //if there are 2 or more admins, try to choose the first one in your division, otherwise choose the highest level one to be first, all others will be alphabetical by last name
    var myAdminListedFirstUserID = -1;
    var myAdminUsersArrayOfObjs = [];
    for(let userObj of c_allAdminPowerUsersNotDeactivatedArrayOfObjs) {
      if(userObj.division_id === c_userDivisionID) { //first pass try to match divisionID
        myAdminListedFirstUserID = userObj.user_id;
        myAdminUsersArrayOfObjs.push(userObj);
        break;
      }
    }

    if(myAdminListedFirstUserID === -1) { //if not successful, second pass try to get highest level admin at 00
      for(let userObj of c_allAdminPowerUsersNotDeactivatedArrayOfObjs) {
        if(userObj.divisionTreeID === "00") {
          myAdminListedFirstUserID = userObj.user_id;
          myAdminUsersArrayOfObjs.push(userObj);
          break;
        }
      }
    }

    //add back the other users in alphabetically order
    for(let userObj of c_allAdminPowerUsersNotDeactivatedArrayOfObjs) {
      if(userObj.user_id !== myAdminListedFirstUserID) {
        myAdminUsersArrayOfObjs.push(userObj);
      }
    }

    return(myAdminUsersArrayOfObjs);
  }

  get c_messageMyAdminSelectedAdminFullName() {
    const o_myAdminSelectedUserID = this.o_myAdminSelectedUserID;
    const c_myAdminUsersArrayOfObjs = this.c_myAdminUsersArrayOfObjs;

    for(let userObj of c_myAdminUsersArrayOfObjs) {
      if(userObj.user_id === o_myAdminSelectedUserID) {
        return(userObj.fullName);
      }
    }
    return("--No Admin Selected--");
  }




  //Support Ticket for BIT Solutions subpanel
  get c_userTicketsArrayOfObjs() {
    const userOpenTicketsMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_z_ticket_initial_threads, "user_per_email_id", UserMobx.o_userPerEmailID);
    var userTicketsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(userOpenTicketsMapOfMaps, "datetime_utc", false); //sort by date DESC
    for(let ticketObj of userTicketsArrayOfObjs) {
      ticketObj.dateMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(ticketObj.datetime_utc, DatabaseMobx.c_genericDateTimeMjYgiAFieldTypeObj);
    }
    return(userTicketsArrayOfObjs); //sort the resulting matrix of open or closed tickets by date (newest on top)
  }
  get c_numOpenTickets() {
    const c_userTicketsArrayOfObjs = this.c_userTicketsArrayOfObjs;
    return(JSFUNC.count_num_filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(c_userTicketsArrayOfObjs, "closed0_open1", 1));
  }
  get c_numClosedTickets() {
    const c_userTicketsArrayOfObjs = this.c_userTicketsArrayOfObjs;
    return(JSFUNC.count_num_filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(c_userTicketsArrayOfObjs, "closed0_open1", 0));
  }

  get c_currentlyViewedSingleTicketObj() {
    for(let ticketObj of this.c_userTicketsArrayOfObjs) { //check all open tickets for the matching ticket id
      if(ticketObj.id === this.o_currentlyViewingTicketThreadID) {
        return(ticketObj);
      }
    }
    return(null); //null is the flag that there is no ticket thread currently open
  }
  get c_ticketsSubpanelSingleTicketIsOpenTF() {
    return(this.c_currentlyViewedSingleTicketObj !== null);
  }
  get c_currentlyViewedSingleTicketResponsesArrayOfObjs() {
    const ticketResponsesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_z_ticket_responses, "ticket_thread_id", this.o_currentlyViewingTicketThreadID);
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(ticketResponsesMapOfMaps, "datetime_utc", true)); //sort the responses by date (oldest on top)
  }
  get c_ticketNumResponsesMap() {
    var ticketNumResponsesMap = new Map(); //ticketIDs as keys mapped to numResponses, thus c_ticketNumResponsesMap.get(ticketID) gets the count
    for(let ticketObj of this.c_userTicketsArrayOfObjs) { //check all open tickets for the matching ticket id
      var responsesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_z_ticket_responses, ["ticket_thread_id", "user0_bit1_bitviewed2"], [ticketObj.id, 1]);
      ticketNumResponsesMap.set(ticketObj.id, responsesMapOfMaps.size);
    }
    return(ticketNumResponsesMap);
  }
  get c_ticketsTabNumUnreadBITResponses() {
    var numUnreadBITResponses = 0;
    for(let ticketNumUnreadBITResponses of this.c_ticketNumResponsesMap.values()) {
      numUnreadBITResponses += ticketNumUnreadBITResponses;
    }
    return(numUnreadBITResponses);
  }





  //Tasks Panel
  get c_allMyTasksArrayOfObjs() {
    var allMyTasksArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_u_tasks);
    var allExpandedTasksArrayOfObjs = [];
    for(let taskObj of allMyTasksArrayOfObjs) {
      var expandedTaskObj = this.tasks_compute_extra_fields_for_task_obj(taskObj);
      allExpandedTasksArrayOfObjs.push(expandedTaskObj);
    }
    return(allExpandedTasksArrayOfObjs);
  }

  get c_tasksNumTaskFiltersApplied() {
    const o_tasksPanelFilterMeToMeTF = this.o_tasksPanelFilterMeToMeTF;
    const o_tasksPanelFilterOtherToMeTF = this.o_tasksPanelFilterOtherToMeTF;
    const o_tasksPanelFilterMeToOtherTF = this.o_tasksPanelFilterMeToOtherTF;
    const o_tasksPanelFilterAssignedToUserIDsComma = this.o_tasksPanelFilterAssignedToUserIDsComma;
    const o_tasksPanelFilterAssignedByUserIDsComma = this.o_tasksPanelFilterAssignedByUserIDsComma;
    const o_tasksPanelFilterDateFromYmd = this.o_tasksPanelFilterDateFromYmd;
    const o_tasksPanelFilterDateToYmd = this.o_tasksPanelFilterDateToYmd;
    const o_tasksPanelFilterText = this.o_tasksPanelFilterText;
    
    const filterAssignedToIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_tasksPanelFilterAssignedToUserIDsComma);
    const filterAssignedByIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_tasksPanelFilterAssignedByUserIDsComma);
    const filterDateFromIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_tasksPanelFilterDateFromYmd);
    const filterDateToIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_tasksPanelFilterDateToYmd);
    const filterTextIsFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(o_tasksPanelFilterText);

    var numTaskFiltersApplied = 0;
    if(o_tasksPanelFilterMeToMeTF) { numTaskFiltersApplied++; }
    if(o_tasksPanelFilterOtherToMeTF) { numTaskFiltersApplied++; }
    if(o_tasksPanelFilterMeToOtherTF) { numTaskFiltersApplied++; }
    if(filterAssignedToIsFilledOutTF) { numTaskFiltersApplied++; }
    if(filterAssignedByIsFilledOutTF) { numTaskFiltersApplied++; }
    if(filterDateFromIsFilledOutTF) { numTaskFiltersApplied++; }
    if(filterDateToIsFilledOutTF) { numTaskFiltersApplied++; }
    if(filterTextIsFilledOutTF) { numTaskFiltersApplied++; }

    return(numTaskFiltersApplied);
  }

  get c_allMyFilteredTasksArrayOfObjs() {
    const o_tasksPanelFilterMeToMeTF = this.o_tasksPanelFilterMeToMeTF;
    const o_tasksPanelFilterOtherToMeTF = this.o_tasksPanelFilterOtherToMeTF;
    const o_tasksPanelFilterMeToOtherTF = this.o_tasksPanelFilterMeToOtherTF;
    const o_tasksPanelFilterAssignedToUserIDsComma = this.o_tasksPanelFilterAssignedToUserIDsComma;
    const o_tasksPanelFilterAssignedByUserIDsComma = this.o_tasksPanelFilterAssignedByUserIDsComma;
    const o_tasksPanelFilterDateFromYmd = this.o_tasksPanelFilterDateFromYmd;
    const o_tasksPanelFilterDateToYmd = this.o_tasksPanelFilterDateToYmd;
    const o_tasksPanelFilterText = this.o_tasksPanelFilterText;
    const c_allMyTasksArrayOfObjs = this.c_allMyTasksArrayOfObjs;

    const filterAssignedToIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_tasksPanelFilterAssignedToUserIDsComma);
    const filterAssignedByIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_tasksPanelFilterAssignedByUserIDsComma);
    const filterDateFromIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_tasksPanelFilterDateFromYmd);
    const filterDateToIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_tasksPanelFilterDateToYmd);
    const filterTextIsFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(o_tasksPanelFilterText);

    if(!o_tasksPanelFilterMeToMeTF && !o_tasksPanelFilterOtherToMeTF && !o_tasksPanelFilterMeToOtherTF && !filterAssignedToIsFilledOutTF && !filterAssignedByIsFilledOutTF && !filterDateFromIsFilledOutTF && !filterDateToIsFilledOutTF && !filterTextIsFilledOutTF) {
      return(c_allMyTasksArrayOfObjs);
    }

    var filteredTasksArrayOfObjs = [];
    for(let taskObj of c_allMyTasksArrayOfObjs) {
      var includeTaskTF = true;

      if(includeTaskTF && (o_tasksPanelFilterMeToMeTF || o_tasksPanelFilterOtherToMeTF || o_tasksPanelFilterMeToOtherTF)) {
        var meToMeTF = (taskObj.assignerIsMeTF && taskObj.assignedToMeTF);
        var otherToMeTF = (!taskObj.assignerIsMeTF && taskObj.assignedToMeTF);
        var meToOtherTF = (taskObj.assignerIsMeTF && !taskObj.assignedToMeTF);
        includeTaskTF = ((o_tasksPanelFilterMeToMeTF && meToMeTF) || (o_tasksPanelFilterOtherToMeTF && otherToMeTF) || (o_tasksPanelFilterMeToOtherTF && meToOtherTF));
      }

      if(includeTaskTF && filterAssignedToIsFilledOutTF) {
        includeTaskTF = JSFUNC.any_of_array1_is_in_array2(JSFUNC.convert_comma_list_to_int_array(o_tasksPanelFilterAssignedToUserIDsComma), JSFUNC.convert_comma_list_to_int_array(taskObj.assigned_to_user_ids_comma));
      }

      if(includeTaskTF && filterAssignedByIsFilledOutTF) {
        includeTaskTF = JSFUNC.any_of_array1_is_in_array2(JSFUNC.convert_comma_list_to_int_array(o_tasksPanelFilterAssignedByUserIDsComma), JSFUNC.convert_comma_list_to_int_array(taskObj.assigner_user_id));
      }

      if(includeTaskTF && filterDateFromIsFilledOutTF) {
        includeTaskTF = (o_tasksPanelFilterDateFromYmd <= taskObj.date_due);
      }

      if(includeTaskTF && filterDateToIsFilledOutTF) {
        includeTaskTF = (o_tasksPanelFilterDateToYmd >= taskObj.date_due);
      }

      if(includeTaskTF && filterTextIsFilledOutTF) {
        var filterTextLowercaseArray = [o_tasksPanelFilterText.toLowerCase()];
        includeTaskTF = (JSFUNC.input_string_contains_any_from_lowercase_contains_strings_array(taskObj.description, filterTextLowercaseArray) || JSFUNC.input_string_contains_any_from_lowercase_contains_strings_array(taskObj.maskRelevancePlainText, filterTextLowercaseArray));
      }

      if(includeTaskTF) {
        filteredTasksArrayOfObjs.push(taskObj);
      }
    }
    return(filteredTasksArrayOfObjs);
  }

  get c_myActiveTasksArrayOfObjs() {
    const assignerIsMeTFU = undefined, assignedToMeTFU = true, dueDateSetTFU = undefined, completedTFU = false, sortField = "date_due", sortIsAscTF = true;
    return(this.filter_tasks(this.c_allMyFilteredTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }
  get c_activeTasksIAssignedToOthersArrayOfObjs() {
    const assignerIsMeTFU = true, assignedToMeTFU = false, dueDateSetTFU = undefined, completedTFU = false, sortField = "date_due", sortIsAscTF = true;
    return(this.filter_tasks(this.c_allMyFilteredTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }

  get c_myCompletedTasksArrayOfObjs() {
    const assignerIsMeTFU = undefined, assignedToMeTFU = true, dueDateSetTFU = undefined, completedTFU = true, sortField = "date_due", sortIsAscTF = false;
    return(this.filter_tasks(this.c_allMyFilteredTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }
  get c_completedTasksIAssignedToOthersArrayOfObjs() {
    const assignerIsMeTFU = true, assignedToMeTFU = false, dueDateSetTFU = undefined, completedTFU = true, sortField = "date_due", sortIsAscTF = false;
    return(this.filter_tasks(this.c_allMyFilteredTasksArrayOfObjs, assignerIsMeTFU, assignedToMeTFU, dueDateSetTFU, completedTFU, sortField, sortIsAscTF));
  }

  get c_tasksExtraCaptureFieldsArrayOfObjs() {
    var tasksExtraCaptureFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(DatabaseMobx.o_tbl_a_tasks_extra_capture_fields, "sort", true);
    for(let tasksExtraCaptureFieldObj of tasksExtraCaptureFieldsArrayOfObjs) {
      var expandedCaptureFieldMapOrUndefined = DatabaseMobx.c_tbl_captures_fields.get(tasksExtraCaptureFieldObj.capture_field_id);
      tasksExtraCaptureFieldObj.expandedCaptureFieldMapOrUndefined = expandedCaptureFieldMapOrUndefined;
      if(expandedCaptureFieldMapOrUndefined !== undefined) {
        tasksExtraCaptureFieldObj.captureFieldDisplayName = expandedCaptureFieldMapOrUndefined.get("display_name");
      }
    }
    return(tasksExtraCaptureFieldsArrayOfObjs);
  }

  tasks_compute_extra_fields_for_task_obj(i_taskObj) {
    const isCompletedTF = JSFUNC.date_is_filled_out_tf(i_taskObj.date_completed);

    const assignedToUsersValueMaskSortIfoObj = DatabaseMobx.user_names_comma_mask_sort_ifo_obj_from_user_ids_comma(i_taskObj.assigned_to_user_ids_comma);
    const assignedByUsersValueMaskSortIfoObj = DatabaseMobx.user_name_mask_sort_ifo_obj_from_user_id(i_taskObj.assigner_user_id);
    const descriptionValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_taskObj.description, DatabaseMobx.c_genericTextareaFieldTypeObj);
    const activeTaskMdyDueDateValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_taskObj.date_due, ((isCompletedTF) ? (DatabaseMobx.c_genericDateDMjYFieldTypeObj) : (DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj)));

    var expandedTaskObj = JSFUNC.copy_obj(i_taskObj);

    expandedTaskObj.dueDateIsSetTF = JSFUNC.date_is_filled_out_tf(i_taskObj.date_due);
    expandedTaskObj.isCompletedTF = isCompletedTF;
    expandedTaskObj.isImportantTF = (i_taskObj.important_01 === 1);
    expandedTaskObj.isGeneralTaskTF = (i_taskObj.capture_id <= 0);
    expandedTaskObj.assignerIsMeTF = JSFUNC.in_array(i_taskObj.assigner_user_id, UserMobx.c_multiLoginUserIDsArray);
    expandedTaskObj.assignedToMeTF = JSFUNC.any_of_array1_is_in_array2(UserMobx.c_multiLoginUserIDsArray, JSFUNC.convert_comma_list_to_int_array(i_taskObj.assigned_to_user_ids_comma));
    expandedTaskObj.preReminderIsOnTF = (i_taskObj.pre_reminder_days >= 0);
    expandedTaskObj.postReminderIsOnTF = (i_taskObj.post_reminder_days >= 0);
    expandedTaskObj.maskAssignedToName = assignedToUsersValueMaskSortIfoObj.valueMask;
    expandedTaskObj.maskAssignedToNamePlainText = assignedToUsersValueMaskSortIfoObj.valueMaskPlainText;
    expandedTaskObj.maskAssignerName = assignedByUsersValueMaskSortIfoObj.valueMask;
    expandedTaskObj.maskAssignerNamePlainText = assignedByUsersValueMaskSortIfoObj.valueMaskPlainText;
    expandedTaskObj.maskRelevancePlainText = ((JSFUNC.select_int_is_filled_out_tf(i_taskObj.capture_id)) ? (DatabaseMobx.capture_name_plaintext_from_capture_id(i_taskObj.capture_id)) : ("General Task"));
    expandedTaskObj.maskActiveTaskMdyDueDate = activeTaskMdyDueDateValueMaskSortIfoObj.valueMask;
    expandedTaskObj.maskActiveTaskMdyDueDatePlainText = activeTaskMdyDueDateValueMaskSortIfoObj.valueMaskPlainText;
    expandedTaskObj.maskCompletedTaskItemDueDate = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(i_taskObj.date_due, DatabaseMobx.c_genericDateFieldTypeObj);
    expandedTaskObj.maskCompletedTaskItemCompletedDate = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(i_taskObj.date_completed, DatabaseMobx.c_genericDateFieldTypeObj);
    expandedTaskObj.maskDescription = descriptionValueMaskSortIfoObj.valueMask;
    expandedTaskObj.maskDescriptionPlainText = descriptionValueMaskSortIfoObj.valueMaskPlainText;
    expandedTaskObj.extraCaptureFieldValuesArrayOfObjs = this.tasks_compute_extra_capture_field_values_arrayOfObjs_from_capture_id(i_taskObj.capture_id);
    
    return(expandedTaskObj);
  }

  tasks_compute_extra_capture_field_values_arrayOfObjs_from_capture_id(i_captureID) {
    const c_tasksExtraCaptureFieldsArrayOfObjs = this.c_tasksExtraCaptureFieldsArrayOfObjs;

    var extraCaptureFieldValuesArrayOfObjs = [];
    if(i_captureID > 0) {
      const captureMapOrUndefined = DatabaseMobx.o_tbl_captures.get(i_captureID);
      if(captureMapOrUndefined !== undefined) {
        for(let tasksExtraCaptureFieldObj of c_tasksExtraCaptureFieldsArrayOfObjs) {
          if(tasksExtraCaptureFieldObj.expandedCaptureFieldMapOrUndefined !== undefined) {
            var captureFieldValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, tasksExtraCaptureFieldObj.expandedCaptureFieldMapOrUndefined);
            extraCaptureFieldValuesArrayOfObjs.push({
              captureFieldDisplayName: tasksExtraCaptureFieldObj.captureFieldDisplayName,
              valueMaskNoClickLinks: captureFieldValueMaskSortIfoObj.valueMaskNoClickLinks,
              valueMaskPlainText: captureFieldValueMaskSortIfoObj.valueMaskPlainText
            });
          }
        }
      }
    }
    return(extraCaptureFieldValuesArrayOfObjs);
  }

  filter_tasks(i_tasksArrayOfObjs, i_assignerIsMeTFU, i_assignedToMeTFU, i_dueDateSetTFU, i_completedTFU, i_sortField, i_sortIsAscTF) {
    //loop through source data tasks
    var filteredTasksArrayOfObjs = [];
    for(let taskObj of i_tasksArrayOfObjs) {
      var assignerMatchesInputTF = ((i_assignerIsMeTFU === undefined) || (i_assignerIsMeTFU && taskObj.assignerIsMeTF) || (!i_assignerIsMeTFU && !taskObj.assignerIsMeTF));
      var assignedToMatchesInputTF = ((i_assignedToMeTFU === undefined) || (i_assignedToMeTFU && taskObj.assignedToMeTF) || (!i_assignedToMeTFU && !taskObj.assignedToMeTF));
      var dueDateSetMatchesInputTF = ((i_dueDateSetTFU === undefined) || (i_dueDateSetTFU && taskObj.dueDateIsSetTF) || (!i_dueDateSetTFU && !taskObj.dueDateIsSetTF)); //true = result must be true, false = result must be false, undefined = result can be either true of false
      var isCompletedMatchesInputTF = ((i_completedTFU === undefined) || (i_completedTFU && taskObj.isCompletedTF) || (!i_completedTFU && !taskObj.isCompletedTF));
      if(assignerMatchesInputTF && assignedToMatchesInputTF && dueDateSetMatchesInputTF && isCompletedMatchesInputTF) {
        filteredTasksArrayOfObjs.push(taskObj);
      }
    }
    JSFUNC.sort_arrayOfObjs(filteredTasksArrayOfObjs, i_sortField, i_sortIsAscTF); //sort by the sortField ASC or DESC
    return(filteredTasksArrayOfObjs);
  }




  //Notifications
  get c_notificationsArrayOfObjs() {
    return(JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_u_notifications, "user_per_email_id", UserMobx.o_userPerEmailID, "id", false));
  }

  get c_numNotifications() {
    return(this.c_notificationsArrayOfObjs.length);
  }

  get c_notificationsUnreadIDsArray() {
    return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_notificationsArrayOfObjs, "id", "read_01", 0));
  }

  get c_numUnreadNotifications() {
    return(this.c_notificationsUnreadIDsArray.length);
  }




  //Help Panel Content
  get c_helpPanelsArrayOfObjs() {
    const helpH1HelpPanelsMapOfMaps = DatabaseMobx.o_bitcompaniesH1HelpPanelsTblMapOfMaps;
    const helpH2HelpPanelCollapsibleHeadersMapOfMaps = DatabaseMobx.o_bitcompaniesH2HelpPanelCollapsibleHeadersTblMapOfMaps;
    const helpH3HelpPanelHeaderContentMapOfMaps = DatabaseMobx.o_bitcompaniesH3HelpPanelHeaderContentTblMapOfMaps;

    var helpPanelsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(helpH1HelpPanelsMapOfMaps, "sort", true);
    for(let helpPanelObj of helpPanelsArrayOfObjs) {
      var allCollapsibleHeadersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(helpH2HelpPanelCollapsibleHeadersMapOfMaps, "help_panel_id", helpPanelObj.id, "sort", true);
      var collapsibleHeadersArrayOfObjs = [];
      for(let collapsibleHeaderObj of allCollapsibleHeadersArrayOfObjs) {
        if(collapsibleHeaderObj.published_01 === 1) {
          collapsibleHeaderObj.headerContentArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(helpH3HelpPanelHeaderContentMapOfMaps, "header_id", collapsibleHeaderObj.id, "sort", true);
          collapsibleHeadersArrayOfObjs.push(collapsibleHeaderObj);
        }
      }
      helpPanelObj.collapsibleHeadersArrayOfObjs = collapsibleHeadersArrayOfObjs;
    }

    return(helpPanelsArrayOfObjs);
  }

  get c_topBarHelpPanelObjOrUndefined() {
    return(JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_helpPanelsArrayOfObjs, "ce_page_name", "TopBar"));
  }

  get c_systemCurrentHelpPanelObjOrUndefined() {
    const o_leftNavTabNameSelected = CaptureExecMobx.o_leftNavTabNameSelected;
    const o_importFlag = AdminMobx.o_importFlag; //"importCapturesUploadCsv", "updateCapturesUploadCsv", "importContactCompaniesUploadCsv", "importContactPersonsUploadCsv", "capturesAnalysisTranslationAndImportOrUpdate", "contactsAnalysisAndImport"
    const o_importContactsIsPersonTF = AdminMobx.o_importContactsIsPersonTF;
    const o_detailsFieldsOptionsSelectedSubtab = AdminMobx.o_detailsFieldsOptionsSelectedSubtab;
    const o_shortcutPresetsSelectedTabDbName = AdminMobx.o_shortcutPresetsSelectedTabDbName; //"captureTeam", "documentsCard"
    const o_selectedTabDbName = AdminIntegrationsMobx.o_selectedTabDbName; //"setup", "mapping", "specialHandling",  "log"
    const o_createNewCaptureIsOpenTF = CapturesMobx.o_createNewCaptureIsOpenTF;
    const o_masterPresetEditorOpenTF = CapturesMobx.o_masterPresetEditorOpenTF;
    const o_ganttDatesPresetEditorOpenTF = CapturesMobx.o_ganttDatesPresetEditorOpenTF;
    const o_leftSideState = ContactsMobx.o_leftSideState; //"treeWithSearch", "advancedCompanySearch", "exportContactsToCsv", "selectParentDivision"
    const o_rightSideState = ContactsMobx.o_rightSideState; //"empty", "viewEditSingleContact", "createNewContact", "savingNewContact", "advancedSearchCapturesAnalysis"
    const o_openContactIsPersonTF = ContactsMobx.o_openContactIsPersonTF;
    const o_openContactViewEditSingleContactSelectedTabDbName = ContactsMobx.o_openContactViewEditSingleContactSelectedTabDbName; //"contactInfo", "teammateRating", "contactConversations", "viewDocs"
    const o_performanceEditingFyr = DivexecMobx.o_performanceEditingFyr;
    const o_openCaptureID = OpenCaptureMobx.o_openCaptureID;
    const o_openCardLeftID = OpenCaptureMobx.o_openCardLeftID;
    const o_openCardRightID = OpenCaptureMobx.o_openCardRightID;
    const o_openCardFullID = OpenCaptureMobx.o_openCardFullID;
    const o_teammatesLeftTabSelected = OpenCaptureMobx.o_teammatesLeftTabSelected; //"teammateSelection", "sbRequirements", "surveysCapabilitiesGapAnalysis"
    const o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab = OpenCaptureMobx.o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab; //"surveyResultsMatrix", "editSurveyQuestions", "uploadSurveyDocuments", "invitationText"
    const o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF = OpenCaptureMobx.o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF;
    const o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF = OpenCaptureMobx.o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF;
    const o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF = OpenCaptureMobx.o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF;
    const o_teammatesSurveysEditQuestionsImportQuestionsFloatingBoxIsOpenTF = OpenCaptureMobx.o_teammatesSurveysEditQuestionsImportQuestionsFloatingBoxIsOpenTF;
    const c_teammatesFloatingBoxSurveyIsOpenTF = OpenCaptureMobx.c_teammatesFloatingBoxSurveyIsOpenTF; //click single diagonal teammate name in survey results matrix to send single survey
    const o_manualSearchIsOpenTF = GCSSMobx.o_manualSearchIsOpenTF;
    const o_openSearchResultDetailGcssIDOrUndefined = GCSSMobx.o_openSearchResultDetailGcssIDOrUndefined;
    const o_selectedSearchResultsTabDbName = GCSSMobx.o_selectedSearchResultsTabDbName;
    const k_cardIDAdvanceStage = DatabaseMobx.k_cardIDAdvanceStage;
    const k_cardIDDates = DatabaseMobx.k_cardIDDates;
    const k_cardIDTasks = DatabaseMobx.k_cardIDTasks;
    const k_cardIDDetails = DatabaseMobx.k_cardIDDetails;
    const k_cardIDDealShaping = DatabaseMobx.k_cardIDDealShaping;
    const k_cardIDTeammates = DatabaseMobx.k_cardIDTeammates;
    const k_cardIDCompetitors = DatabaseMobx.k_cardIDCompetitors;
    const k_cardIDProposalThemes = DatabaseMobx.k_cardIDProposalThemes;
    const k_cardIDRiskAssessment = DatabaseMobx.k_cardIDRiskAssessment;
    const k_cardIDBudget = DatabaseMobx.k_cardIDBudget;
    const k_cardIDConversations = DatabaseMobx.k_cardIDConversations;
    const k_cardIDDocuments = DatabaseMobx.k_cardIDDocuments;
    const k_cardIDTemplates = DatabaseMobx.k_cardIDTemplates;
    const k_cardIDIDIQTaskOrders = DatabaseMobx.k_cardIDIDIQTaskOrders;
    const k_cardIDChangelog = DatabaseMobx.k_cardIDChangelog;
    const k_cardIDNotepad = DatabaseMobx.k_cardIDNotepad;
    const k_cardIDRevenue = DatabaseMobx.k_cardIDRevenue;

    var systemCurrentCEPageName = undefined;
    if(o_openCaptureID !== undefined) {
      if((o_openCardFullID === undefined) && (o_openCardLeftID === undefined) && (o_openCardRightID === undefined)) { systemCurrentCEPageName = "OpenCapture"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDAdvanceStage)) { systemCurrentCEPageName = "OpenCaptureAdvanceStageCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDDates)) { systemCurrentCEPageName = "OpenCaptureDatesCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDTasks)) { systemCurrentCEPageName = "OpenCaptureTasksCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDDetails)) { systemCurrentCEPageName = "OpenCaptureDetailsCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDDealShaping)) { systemCurrentCEPageName = "OpenCaptureDealShapingCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDTeammates)) {
        if(o_teammatesLeftTabSelected === "sbRequirements") { systemCurrentCEPageName = "OpenCaptureTeammatesCardSBRequirementsButton"; } //Teammate SB Reqs
        else if(o_teammatesLeftTabSelected === "surveysCapabilitiesGapAnalysis") { //Teammate Surveys
          if(o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab === "editSurveyQuestions") {
            if(o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF) { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysEditQuestionsSubtabAddQuestion"; }
            else if(o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF) { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysEditQuestionsSubtabCopyQuestions"; }
            else if(o_teammatesSurveysEditQuestionsImportQuestionsFloatingBoxIsOpenTF) { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysEditQuestionsSubtabImportQuestions"; }
            else { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysEditQuestionsSubtab"; }
          }
          else if(o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab === "uploadSurveyDocuments") { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysAttachDocumentsSubtab"; }
          else if(o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab === "invitationText") { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysInvitationTextSubtab"; }
          else {
            if(c_teammatesFloatingBoxSurveyIsOpenTF) { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysSurveyResultsMatrixSubtabSendSingleTeammateSurvey"; }
            else if(o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF) { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysSurveyResultsMatrixSubtabPrepareSurveyToMultipleTeammates"; }
            else { systemCurrentCEPageName = "OpenCaptureTeammatesCardSurveysSurveyResultsMatrixSubtab"; } //explains Create New Survey button and Survey Results Matrix
          }
        }
        else { systemCurrentCEPageName = "OpenCaptureTeammatesCard"; } //Teammates
      }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDCompetitors)) { systemCurrentCEPageName = "OpenCaptureCompetitorsCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDProposalThemes)) { systemCurrentCEPageName = "OpenCaptureProposalThemesCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDRiskAssessment)) { systemCurrentCEPageName = "OpenCaptureRiskAssessmentCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDBudget)) { systemCurrentCEPageName = "OpenCaptureBudgetCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDConversations)) { systemCurrentCEPageName = "OpenCaptureConversationsCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDDocuments)) { systemCurrentCEPageName = "OpenCaptureDocumentsCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDTemplates)) { systemCurrentCEPageName = "OpenCaptureTemplatesCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDIDIQTaskOrders)) { systemCurrentCEPageName = "OpenCaptureIDIQTaskOrdersCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDChangelog)) { systemCurrentCEPageName = "OpenCaptureChangelogCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDNotepad)) { systemCurrentCEPageName = "OpenCaptureNotepadCard"; }
      else if(this.help_panel_open_capture_card_is_open_tf_from_cardID(k_cardIDRevenue)) { systemCurrentCEPageName = "OpenCaptureRevenueCard"; }
    }

    else if(o_createNewCaptureIsOpenTF) { systemCurrentCEPageName = "CreateNewCapture"; }
    else if(o_masterPresetEditorOpenTF) { systemCurrentCEPageName = "CreateEditPresets"; }
    else if(o_ganttDatesPresetEditorOpenTF) { systemCurrentCEPageName = "CreateEditGanttDatePresets"; }

    else if(o_leftNavTabNameSelected === "User Activity") { systemCurrentCEPageName = "AdminUserActivity"; }
    else if(o_leftNavTabNameSelected === "Capture Import") {
      if((o_importFlag === "importCapturesUploadCsv") || (o_importFlag === "capturesAnalysisTranslationAndImportOrUpdate")) { systemCurrentCEPageName = "AdminCaptureImportCaptures"; }
      else if(o_importFlag === "importContactCompaniesUploadCsv") { systemCurrentCEPageName = "AdminCaptureImportContactCompanies"; }
      else if((o_importFlag === "contactsAnalysisAndImport") && !o_importContactsIsPersonTF) { systemCurrentCEPageName = "AdminCaptureImportContactCompanies"; }
      else if((o_importFlag === "contactsAnalysisAndImport") && o_importContactsIsPersonTF) { systemCurrentCEPageName = "AdminCaptureImportContactPersons"; }
    }
    else if(o_leftNavTabNameSelected === "My Account") { systemCurrentCEPageName = "AdminSetupMyAccount"; }
    else if(o_leftNavTabNameSelected === "Company/Divisions") { systemCurrentCEPageName = "AdminSetupCompanyDivisions"; }
    else if(o_leftNavTabNameSelected === "Users") { systemCurrentCEPageName = "AdminSetupUsers"; }
    else if(o_leftNavTabNameSelected === "Capture Types") { systemCurrentCEPageName = "AdminSetupCaptureTypes"; }
    else if(o_leftNavTabNameSelected === "Capture Cards") { systemCurrentCEPageName = "AdminSetupCaptureCards"; }
    else if(o_leftNavTabNameSelected === "Stages") { systemCurrentCEPageName = "AdminSetupStages"; }
    else if(o_leftNavTabNameSelected === "Details Card Groups") { systemCurrentCEPageName = "AdminSetupDetailsCardGroups"; }
    else if(o_leftNavTabNameSelected === "Details/Dates Fields") { systemCurrentCEPageName = "AdminSetupDetailsDatesFields"; }
    else if(o_leftNavTabNameSelected === "Details Fields Options") {
      if(o_detailsFieldsOptionsSelectedSubtab === "competitionTypeSBCertsMasterTbl") { systemCurrentCEPageName = "AdminSetupDetailsFieldsOptionsCompetitionTypeSetAsides"; }
      else if(o_detailsFieldsOptionsSelectedSubtab === "naicsCodes") { systemCurrentCEPageName = "AdminSetupDetailsFieldsOptionsNaicsCodes"; }
      else if(o_detailsFieldsOptionsSelectedSubtab === "departmentsAgenciesMasterTbl") { systemCurrentCEPageName = "AdminSetupDetailsFieldsOptionsDepartmentsAgencies"; }
      else if(o_detailsFieldsOptionsSelectedSubtab === "subTiersMasterTbl") { systemCurrentCEPageName = "AdminSetupDetailsFieldsOptionsSubTiers"; }
      else if(o_detailsFieldsOptionsSelectedSubtab === "idiqVehicleFieldOptions") { systemCurrentCEPageName = "AdminSetupDetailsFieldsOptionsIDIQVehicle"; }
      else if(o_detailsFieldsOptionsSelectedSubtab === "priorityFavorites") { systemCurrentCEPageName = "AdminSetupDetailsFieldsOptionsCapturePriorityFavorites"; }
    }
    else if(o_leftNavTabNameSelected === "Deal Shaping Questions") { systemCurrentCEPageName = "AdminSetupDealShapingQuestions"; }
    else if(o_leftNavTabNameSelected === "Teammates") { systemCurrentCEPageName = "AdminSetupTeammates"; }
    else if(o_leftNavTabNameSelected === "Teammate Contracts") { systemCurrentCEPageName = "AdminSetupTeammateContracts"; }
    else if(o_leftNavTabNameSelected === "Teammate Ratings") { systemCurrentCEPageName = "AdminSetupTeammateRatings"; }
    else if(o_leftNavTabNameSelected === "Teammate Surveys") { systemCurrentCEPageName = "AdminSetupTeammateSurveys"; }
    else if(o_leftNavTabNameSelected === "Competitors") { systemCurrentCEPageName = "AdminSetupCompetitors"; }
    else if(o_leftNavTabNameSelected === "Proposal Themes") { systemCurrentCEPageName = "AdminSetupProposalThemes"; }
    else if(o_leftNavTabNameSelected === "Risk Assessment") { systemCurrentCEPageName = "AdminSetupRiskAssessment"; }
    else if(o_leftNavTabNameSelected === "Budgets") { systemCurrentCEPageName = "AdminSetupBudgets"; }
    else if(o_leftNavTabNameSelected === "Capture Templates") { systemCurrentCEPageName = "AdminSetupCaptureTemplates"; }
    else if(o_leftNavTabNameSelected === "GCSS Options") { systemCurrentCEPageName = "AdminSetupGCSSOptions"; }
    else if(o_leftNavTabNameSelected === "Contacts Fields") { systemCurrentCEPageName = "AdminSetupContactsFields"; }
    else if(o_leftNavTabNameSelected === "Tasks Options") { systemCurrentCEPageName = "AdminSetupTasksOptions"; }
    else if(o_leftNavTabNameSelected === "Company Communications") { systemCurrentCEPageName = "AdminSetupCompanyCommunicationsBanner"; }
    else if(o_leftNavTabNameSelected === "Automated Reminders") { systemCurrentCEPageName = "AdminSetupAutomatedReminders"; }
    else if(o_leftNavTabNameSelected === "Excel Report Templates") { systemCurrentCEPageName = "AdminSetupExcelReportTemplates"; }
    else if(o_leftNavTabNameSelected === "System") { systemCurrentCEPageName = "AdminSetupSystem"; }
    else if(o_leftNavTabNameSelected === "Shortcut Presets") {
      if(o_shortcutPresetsSelectedTabDbName === "captureTeam") { systemCurrentCEPageName = "AdminSetupShortcutPresetsCaptureTeam"; }
      else if(o_shortcutPresetsSelectedTabDbName === "documentsCard") { systemCurrentCEPageName = "AdminSetupShortcutPresetsDocumentsCard"; }
    }
    else if(o_leftNavTabNameSelected === "Capture Archiving") { systemCurrentCEPageName = "AdminSetupCaptureArchiving"; }
    else if(o_leftNavTabNameSelected === "New Capture Additional Fields") { systemCurrentCEPageName = "AdminSetupNewCaptureAdditionalFields"; }
    else if(o_leftNavTabNameSelected === "Search Fields") { systemCurrentCEPageName = "AdminSetupSearchFields"; }
    else if(o_leftNavTabNameSelected === "PWin") { systemCurrentCEPageName = "AdminSetupPWin"; }
    else if(o_leftNavTabNameSelected === "Progress Colors") { systemCurrentCEPageName = "AdminSetupProgressColors"; }
    else if(o_leftNavTabNameSelected === "Upcoming Date Colors") { systemCurrentCEPageName = "AdminSetupUpcomingDateColors"; }
    else if(o_leftNavTabNameSelected === "Delete Capture Reasons") { systemCurrentCEPageName = "AdminSetupDeleteCaptureReasons"; }
    else if(o_leftNavTabNameSelected === "Question Tags") { systemCurrentCEPageName = "AdminSetupQuestionTags"; }
    else if(o_leftNavTabNameSelected === "3rd Party Integrations") {
      if(o_selectedTabDbName === "setup") { systemCurrentCEPageName = "AdminSetupIntegrationsSetup"; }
      else if(o_selectedTabDbName === "mapping") { systemCurrentCEPageName = "AdminSetupIntegrationsMapping"; }
      else if(o_selectedTabDbName === "specialHandling") { systemCurrentCEPageName = "AdminSetupIntegrationsSpecialHandling"; }
      else if(o_selectedTabDbName === "log") { systemCurrentCEPageName = "AdminSetupIntegrationsLog"; }
    }
    else if(o_leftNavTabNameSelected === "Admin Changelog") { systemCurrentCEPageName = "AdminSetupAdminChangelog"; }
    else if(o_leftNavTabNameSelected === "On-Premise") { systemCurrentCEPageName = "AdminSetupOnPremise"; }

    else if(o_leftNavTabNameSelected === "Performance") {
      if(o_performanceEditingFyr !== undefined) { systemCurrentCEPageName = "ExecutiveManagementPerformanceGoals"; }
      else { systemCurrentCEPageName = "ExecutiveManagementPerformance"; }
    }
    else if(o_leftNavTabNameSelected === "Daily Snapshot") { systemCurrentCEPageName = "ExecutiveManagementDailySnapshot"; }
    else if(o_leftNavTabNameSelected === "Trend Analyzer") { systemCurrentCEPageName = "ExecutiveManagementTrendAnalyzer"; }
    else if(o_leftNavTabNameSelected === "Financial Projections") { systemCurrentCEPageName = "ExecutiveManagementFinanicalProjections"; }
    else if(o_leftNavTabNameSelected === "Hot Bits") { systemCurrentCEPageName = "ExecutiveManagementHotBits"; }
    else if(o_leftNavTabNameSelected === "Critical Thresholds") { systemCurrentCEPageName = "ExecutiveManagementCriticalThresholds"; }
    else if(o_leftNavTabNameSelected === "Stage Flow") { systemCurrentCEPageName = "ExecutiveManagementStageFlow"; }
    else if(o_leftNavTabNameSelected === "Excel Report Writer") { systemCurrentCEPageName = "ExecutiveManagementExcelReportWriter"; }

    else if(o_leftNavTabNameSelected === "GovCon Smart Search") { //1. detail open, 2. search open, 3. import tab, 4. all others
      if(o_openSearchResultDetailGcssIDOrUndefined !== undefined) {
        systemCurrentCEPageName = "GCSSSearchResultDetails";
      }
      else if(o_manualSearchIsOpenTF) {
        systemCurrentCEPageName = "GCSSCreateNewSearch";
      }
      else if(o_selectedSearchResultsTabDbName === "markedForImport") {
        systemCurrentCEPageName = "GCSSMarkedForImportTab";
      }
      else {
        systemCurrentCEPageName = "GCSSSearchResults";
      }
    }

    else if(o_leftNavTabNameSelected === "Capture Table View") { systemCurrentCEPageName = "CaptureTable"; }
    else if(o_leftNavTabNameSelected === "Stage View") { systemCurrentCEPageName = "StageView"; }
    else if(o_leftNavTabNameSelected === "Gantt Chart View") { systemCurrentCEPageName = "GanttChart"; }
    else if(o_leftNavTabNameSelected === "Progress Chart View") { systemCurrentCEPageName = "ProgressChart"; }

    else if(o_leftNavTabNameSelected === "My Performance") { systemCurrentCEPageName = "MySummariesMyPerformance"; }
    else if(o_leftNavTabNameSelected === "My Surveys") { systemCurrentCEPageName = "MySummariesMySurveys"; }

    else if(o_leftNavTabNameSelected === "Contacts") {
      if(o_leftSideState === "advancedCompanySearch") { systemCurrentCEPageName = "ContactsAdvancedSearch"; }
      else if(o_leftSideState === "exportContactsToCsv") { systemCurrentCEPageName = "ContactsExport"; }
      else if(o_rightSideState === "createNewContact") {
        if(!o_openContactIsPersonTF) { systemCurrentCEPageName = "ContactsCreateNewCompany"; }
        else { systemCurrentCEPageName = "ContactsCreateNewPerson"; }
      }
      else if(o_rightSideState === "viewEditSingleContact") {
        if(!o_openContactIsPersonTF) {
          if(o_openContactViewEditSingleContactSelectedTabDbName === "teammateRating") { systemCurrentCEPageName = "OpenContactCompanyTeammateRating"; }
          else if(o_openContactViewEditSingleContactSelectedTabDbName === "viewDocs") { systemCurrentCEPageName = "OpenContactCompanyViewDocs"; }
          else { systemCurrentCEPageName = "OpenContactCompanyContactInfo"; }
        }
        else {
          if(o_openContactViewEditSingleContactSelectedTabDbName === "viewDocs") { systemCurrentCEPageName = "OpenContactPersonViewDocs"; }
          else { systemCurrentCEPageName = "OpenContactPersonContactInfo"; }
        }
      }
      else { systemCurrentCEPageName = "Contacts"; }
    }

    else if(o_leftNavTabNameSelected === "Marketing Docs") { systemCurrentCEPageName = "MarketingDocs"; }

    else if(o_leftNavTabNameSelected === "Contracts Workload") { systemCurrentCEPageName = "ContractsWorkload"; }
    else if(o_leftNavTabNameSelected === "Contracts Todo") { systemCurrentCEPageName = "ContractsToDo"; }
    else if(o_leftNavTabNameSelected === "Contracts Metrics") { systemCurrentCEPageName = "ContractsMetrics"; }
    else if(o_leftNavTabNameSelected === "Budgets Workload") { systemCurrentCEPageName = "BudgetsWorkload"; }
    else if(o_leftNavTabNameSelected === "Budgets Todo") { systemCurrentCEPageName = "BudgetsToDo"; }

    return(JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_helpPanelsArrayOfObjs, "ce_page_name", systemCurrentCEPageName));
  }

  help_panel_open_capture_card_is_open_tf_from_cardID(i_cardID) {
    const o_openCardLeftID = OpenCaptureMobx.o_openCardLeftID;
    const o_openCardRightID = OpenCaptureMobx.o_openCardRightID;
    const o_openCardFullID = OpenCaptureMobx.o_openCardFullID;
    return((o_openCardFullID === i_cardID) || (o_openCardLeftID === i_cardID) || ((o_openCardRightID === i_cardID) && (o_openCardLeftID === undefined)));
  }













  //===action methods==============================================================================================
  a_set_open_panel(i_closed0_u1_t2_n3) {
    const o_tasksPanelFiltersSubpanelOpenTF = this.o_tasksPanelFiltersSubpanelOpenTF;
    const c_tasksNumTaskFiltersApplied = this.c_tasksNumTaskFiltersApplied;

    //recompute the mediaQuery widths that change when the panel is opened or closed
    if((i_closed0_u1_t2_n3 === 0 && this.c_rightPanelIsOpenTF) || (i_closed0_u1_t2_n3 > 0 && !this.c_rightPanelIsOpenTF)) { //open to closed or closed to open (width does not change if simply changing panels between users and tasks)
      var newDesktopRightAndHelpPanelWidthsEm = 0;
      if(i_closed0_u1_t2_n3 > 0) { //just opened the right panel
        newDesktopRightAndHelpPanelWidthsEm = UserMobx.c_userRightPanelWidthEm;
      }

      //if desktop help panel is already open
      newDesktopRightAndHelpPanelWidthsEm += this.c_currentDesktopHelpPanelWidthEm;

      CaptureExecMobx.a_set_enquire_media_width_from_font_size_and_right_panel(UserMobx.c_userFontSizePx, newDesktopRightAndHelpPanelWidthsEm);
    }

    //if closing the panel, reset any subtabs back to their initial value
    if(i_closed0_u1_t2_n3 === 0) {
      this.a_close_currently_viewed_ticket();
      this.o_userTabNameOpen = null;
      this.o_currentlyViewingTicketThreadID = -1;
    }

    //if no panel or any other panel was open, and now the tasks panel is open - if there are any task filters applied, force the filters subpanel to open
    if((this.o_closed0_u1_t2_n3 !== 2) && (i_closed0_u1_t2_n3 === 2)) {
      if(!o_tasksPanelFiltersSubpanelOpenTF && (c_tasksNumTaskFiltersApplied > 0)) {
        this.a_tasks_panel_set_filters_subpanel_open_tf(true);
      }
    }

    //if the notifications panel was open and it is now closed (or changed to a different panel)
    if((this.o_closed0_u1_t2_n3 === 3) && (i_closed0_u1_t2_n3 !== 3)) {
      //reset the click action active notification
      this.a_notification_set_click_action_notification_id(undefined);

      //if there are unread notifications, mark them all as read
      if(this.c_numUnreadNotifications > 0) {
        const jsDescription = JSFUNC.js_description_from_action("RightPanelMobx", "a_set_open_panel", ["i_closed0_u1_t2_n3"], [i_closed0_u1_t2_n3]);
        const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
        C_CallPhpTblUID.add_update("tbl_u_notifications", this.c_notificationsUnreadIDsArray, "read_01", 1, "i");
        C_CallPhpTblUID.execute();
      }
    }

    //set the panel index
    this.o_closed0_u1_t2_n3 = i_closed0_u1_t2_n3;
  }

  a_set_help_panel_is_open_tf(i_newValueTF) {
    const c_currentDesktopRightPanelWidthEm = this.c_currentDesktopRightPanelWidthEm;
    const c_userFontSizePx = UserMobx.c_userFontSizePx;
    const c_userHelpPanelWidthEm = UserMobx.c_userHelpPanelWidthEm;

    var newDesktopRightAndHelpPanelWidthsEm = c_currentDesktopRightPanelWidthEm;
    if(i_newValueTF) {
      newDesktopRightAndHelpPanelWidthsEm += c_userHelpPanelWidthEm;
    }
    CaptureExecMobx.a_set_enquire_media_width_from_font_size_and_right_panel(c_userFontSizePx, newDesktopRightAndHelpPanelWidthsEm);

    //set if the help panel is open
    this.o_helpPanelIsOpenTF = i_newValueTF;
  }


  a_tab_click(i_tabNameSelected) {
    if(i_tabNameSelected === "Log Out") {
      CaptureExecMobx.a_logout("userLoggedOut");
    }
    else {
      if(i_tabNameSelected === "Change Password") { //when displaying Change Password, always reset all inputs and errors from previous actions
        CaptureExecMobx.a_change_password_full_reset();
      }
      else if(i_tabNameSelected === "Support Ticket for BIT Solutions") { //when displaying Tickets, reset any new ticket error indicators from previous actions (but leave any typed description or priority selection)
        this.a_set_new_ticket_priority_error_tf(false);
        this.a_set_new_ticket_description_error_tf(false);
        this.a_set_new_ticket_message_obj(null);
      }
      this.o_userTabNameOpen = i_tabNameSelected;
    }
  }


  a_subpanel_return_click() {
    if(this.c_isOnMainMenuTabsTF) {
      this.o_closed0_u1_t2_n3 = 0;
    }
    else {
      if(this.o_userTabNameOpen === "Support Ticket for BIT Solutions" && this.c_ticketsSubpanelSingleTicketIsOpenTF) {
        this.a_close_currently_viewed_ticket(); //if a single ticket is open (currently being viewed), return to the tickets subpanel
      }
      else {
        this.a_tab_click(null); //returning from a subtab goes back to the main menu of tabs (null for the o_userTabNameOpen is the flag for this)
      }
    }
  }


  //Settings subtab



  //Message My Admin subtab
  a_message_my_admin_set_my_admin_selected_user_id(i_userID) {
    this.o_myAdminSelectedUserID = i_userID;
  }

  a_message_my_admin_set_text(i_newValue) {
    this.o_messageMyAdminText = i_newValue;
  }


  //Tickets subtab
  //submit new ticket
  a_set_new_ticket_priority(i_newValue) { this.o_newTicketObj.priorityCode = i_newValue; }
  a_set_new_ticket_description(i_newValue) { this.o_newTicketObj.description = i_newValue; }
  a_set_new_ticket_priority_error_tf(i_newValue) { this.o_newTicketObj.priorityErrorTF = i_newValue; }
  a_set_new_ticket_description_error_tf(i_newValue) { this.o_newTicketObj.descriptionErrorTF = i_newValue; }
  a_set_new_ticket_message_obj(i_messageObj) { this.o_newTicketObj.messageObj = i_messageObj; }

  a_new_ticket_select_priority(i_selectedPriorityValue) {
    if(this.o_newTicketObj.priorityErrorTF) { this.a_set_new_ticket_priority_error_tf(false); } //reset the priority error indicator when selecting a priority
    if(this.o_newTicketObj.messageObj) { this.a_set_new_ticket_message_obj(null); }
    this.a_set_new_ticket_priority(i_selectedPriorityValue);
  }

  a_new_ticket_change_description(i_newDescriptionText) {
    if(this.o_newTicketObj.descriptionErrorTF) { this.a_set_new_ticket_description_error_tf(false); } //reset the description error indicator when typing in a description
    if(this.o_newTicketObj.messageObj) { this.a_set_new_ticket_message_obj(null); }
    this.a_set_new_ticket_description(i_newDescriptionText);
  }

  a_new_ticket_submit() {
    const o_newTicketObj = this.o_newTicketObj;
    const c_userPerEmailIDsOfAllAdminPowerUsersNotDeactivatedArray = DatabaseMobx.c_userPerEmailIDsOfAllAdminPowerUsersNotDeactivatedArray;
    const o_userID = UserMobx.o_userID;
    const o_userPerEmailID = UserMobx.o_userPerEmailID;
    const o_userCompanyCode = UserMobx.o_userCompanyCode;
    const c_userName = UserMobx.c_userName;
    const c_userEmail = UserMobx.c_userEmail;
    const c_userPerEmailHasNotDeactivatedAdminOrSuperAdminLoginTF = UserMobx.c_userPerEmailHasNotDeactivatedAdminOrSuperAdminLoginTF;

    if(o_newTicketObj.priorityCode < 1) {
      this.a_set_new_ticket_priority_error_tf(true);
      this.a_set_new_ticket_description_error_tf(false);
      this.a_set_new_ticket_message_obj(JSFUNC.message_obj("userError", "Please select a priority for this ticket"));
    }
    else if(o_newTicketObj.description.length === 0) {
      this.a_set_new_ticket_priority_error_tf(false);
      this.a_set_new_ticket_description_error_tf(true);
      this.a_set_new_ticket_message_obj(JSFUNC.message_obj("userError", "Please enter a description for this ticket"));
    }
    else {
      const jsDescription = JSFUNC.js_description_from_action("RightPanelMobx", "a_new_ticket_submit", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //insert the newly created initial ticket thread from the user
      const fieldnamesArray = ["user_per_email_id", "datetime_utc", "priority_code", "initial_message", "closed0_open1", "bitexec_viewed_last_user_response_01", "new_01"];
      const valuesArray = [o_userPerEmailID, JSFUNC.now_datetime_utc(), o_newTicketObj.priorityCode, o_newTicketObj.description, 1, 1, 1];
      const idsbArray = ["i", "s", "i", "s", "i", "i", "i"];
      C_CallPhpTblUID.add_insert("tbl_z_ticket_initial_threads", fieldnamesArray, valuesArray, idsbArray);

      //send email to BIT
      var priorityString = "Enhancement";
      if(o_newTicketObj.priorityCode === 2) { priorityString = "High"; }
      else if(o_newTicketObj.priorityCode === 3) { priorityString = "Medium"; }
      else if(o_newTicketObj.priorityCode === 4) { priorityString = "Low"; }
      const emailSubject = "CEv2 Ticket Submitted (" + o_userCompanyCode + ")";
      const emailBodyHtml = "User: " + c_userEmail + "\nPriority: " + priorityString + "\nDescription: " + o_newTicketObj.description;
      C_CallPhpTblUID.add_email("support@bitsolutionsllc.com", "", emailSubject, emailBodyHtml);

      //send a notification to all admins in the system (except yourself if you are an admin) that this ticket was submitted and can be viewed on the User Activity tab
      var toUserPerEmailIDsArray = c_userPerEmailIDsOfAllAdminPowerUsersNotDeactivatedArray;
      if(c_userPerEmailHasNotDeactivatedAdminOrSuperAdminLoginTF) {
        toUserPerEmailIDsArray = JSFUNC.remove_all_values_from_array(o_userPerEmailID, toUserPerEmailIDsArray);
      }

      if(toUserPerEmailIDsArray.length > 0) {
        const userPerEmailTrueUserFalse = true;
        const notificationMessage = c_userName + " has submitted a Support Ticket to BIT Solutions\nPriority: " + priorityString + "\nDescription: " + o_newTicketObj.description;
        const notificationClickActionString = "openUserActivityTicket:" + o_userID;
        C_CallPhpTblUID.add_notifications_with_emails(toUserPerEmailIDsArray, userPerEmailTrueUserFalse, notificationMessage, notificationClickActionString);
      }

      C_CallPhpTblUID.execute();

      //reset the new ticket error indicators
      this.a_set_new_ticket_priority_error_tf(false);
      this.a_set_new_ticket_description_error_tf(false);
      this.a_set_new_ticket_priority("");
      this.a_set_new_ticket_description("");
      this.a_set_new_ticket_message_obj(JSFUNC.message_obj("success", "Ticket successfully submitted to BIT Solutions for review"));
    }
  }

  //display already existing open and closed tickets
  a_set_ticket_new_response_response(i_newValue) { this.o_ticketNewResponseObj.response = i_newValue; }
  a_set_ticket_new_response_response_error_tf(i_newValue) { this.o_ticketNewResponseObj.responseErrorTF = i_newValue; }
  a_set_ticket_new_response_message_obj(i_messageObj) { this.o_ticketNewResponseObj.messageObj = i_messageObj; }

  a_ticket_new_response_change_response(i_newResponseText) {
    if(this.o_ticketNewResponseObj.responseErrorTF) { this.a_set_ticket_new_response_response_error_tf(false); } //reset the description error indicator when typing in a description
    if(this.o_ticketNewResponseObj.messageObj) { this.a_set_ticket_new_response_message_obj(null); }
    this.a_set_ticket_new_response_response(i_newResponseText);
  }

  a_view_ticket_click(i_ticketID) {
    this.o_currentlyViewingTicketThreadID = i_ticketID; //set this ticketID as the one selected to view its initial message and all thread responses linked to it by the ticketID
    this.a_set_new_ticket_message_obj(null); //erase any error or success messages left over from the new ticket submission process
  }

  a_close_currently_viewed_ticket() {
    if(this.o_currentlyViewingTicketThreadID > 0) {
      this.a_mark_all_BIT_responses_as_read_for_currently_viewed_ticket(); //first, closing the currently viewed ticket subsubpanel (the return button) sets all BIT viewed flags on the responses in that ticket thread as 'viewed'
      this.o_currentlyViewingTicketThreadID = null; //set the curently viewed ticketID to null, which takes the subpanel back to the main tickets panel showing all the user's tickets
    }
  }

  a_set_ticket_closed0_open1(i_newValue01) {
    const jsDescription = JSFUNC.js_description_from_action("RightPanelMobx", "a_set_ticket_closed0_open1", ["i_newValue01"], [i_newValue01]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldnamesArray = ["closed0_open1", "bitexec_viewed_last_user_response_01"];
    const valuesArray = [i_newValue01, 0];
    const idsbArray = ["i", "i"];
    C_CallPhpTblUID.add_update("tbl_z_ticket_initial_threads", this.o_currentlyViewingTicketThreadID, fieldnamesArray, valuesArray, idsbArray);

    C_CallPhpTblUID.execute();
  }

  a_ticket_submit_new_response() {
    if(this.o_ticketNewResponseObj.response.length === 0) {
      this.a_set_ticket_new_response_response_error_tf(true);
      this.a_set_ticket_new_response_message_obj(JSFUNC.message_obj("userError", "Please enter a response to send to BIT Solutions"));
    }
    else {
      const jsDescription = JSFUNC.js_description_from_action("RightPanelMobx", "a_ticket_submit_new_response", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //insert the new response from the user
      const fieldnamesArray = ["ticket_thread_id", "datetime_utc", "user0_bit1_bitviewed2", "message"];
      const valuesArray = [this.o_currentlyViewingTicketThreadID, JSFUNC.now_datetime_utc(), 0, this.o_ticketNewResponseObj.response];
      const idsbArray = ["i", "s", "i", "s"];
      C_CallPhpTblUID.add_insert("tbl_z_ticket_responses", fieldnamesArray, valuesArray, idsbArray);

      //update the BIT field for the thread that BIT has not yet viewed this new response from the user
      C_CallPhpTblUID.add_update("tbl_z_ticket_initial_threads", this.o_currentlyViewingTicketThreadID, "bitexec_viewed_last_user_response_01", 0, "i");

      //send an email to support and skip
      const emailSubject = "CEv2 Ticket Response Submitted (" + UserMobx.o_userCompanyCode + ")";
      const emailBodyHtml = "User: " + UserMobx.c_combinedUserObj.email + "\nResponse: " + this.o_ticketNewResponseObj.response;
      C_CallPhpTblUID.add_email("support@bitsolutionsllc.com", "", emailSubject, emailBodyHtml);

      C_CallPhpTblUID.execute();

      //reset the new ticket error indicators
      this.a_set_ticket_new_response_response_error_tf(false);
      this.a_set_ticket_new_response_response("");
      this.a_set_ticket_new_response_message_obj(null);

      //mark any unread BIT tickets for this ticketID thread as read
      this.a_mark_all_BIT_responses_as_read_for_currently_viewed_ticket();
    }
  }


  a_mark_all_BIT_responses_as_read_for_currently_viewed_ticket() {
    //find the id number of all responses for this ticketID that have a userBIT flag of 1
    const responseIDsToUpdateArray = [];
    for(let ticketResponseObj of this.c_currentlyViewedSingleTicketResponsesArrayOfObjs) {
      if(ticketResponseObj.user0_bit1_bitviewed2 === 1) { //if the response ticket thread id matches the ticketID that is currently viewed
        responseIDsToUpdateArray.push(ticketResponseObj.id);
      }
    }

    //change the viewed flag from 1 (from BIT, not yet viewed) to 2 (from BIT, viewed) in local and database
    if(responseIDsToUpdateArray.length > 0) { //no need to update anything if no responses need updating
      const jsDescription = JSFUNC.js_description_from_action("RightPanelMobx", "a_mark_all_BIT_responses_as_read_for_currently_viewed_ticket", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_z_ticket_responses", responseIDsToUpdateArray, "user0_bit1_bitviewed2", 2, "i");
      C_CallPhpTblUID.execute();
    }
  }






  //Tasks Panel
  a_tasks_set_panel_showing_active_tf(i_newValueTF) {
    this.o_tasksPanelShowingActiveTF = i_newValueTF;
  }

  a_tasks_panel_set_filters_subpanel_open_tf(i_newValueTF) {
    this.o_tasksPanelFiltersSubpanelOpenTF = i_newValueTF;
  }

  a_tasks_panel_set_filter_me_to_me_tf(i_newValueTF) {
    this.o_tasksPanelFilterMeToMeTF = i_newValueTF;
  }

  a_tasks_panel_set_filter_other_to_me_tf(i_newValueTF) {
    this.o_tasksPanelFilterOtherToMeTF = i_newValueTF;
  }

  a_tasks_panel_set_filter_me_to_other_tf(i_newValueTF) {
    this.o_tasksPanelFilterMeToOtherTF = i_newValueTF;
  }

  a_tasks_panel_set_filter_assigned_to_user_ids_comma(i_newValueCommaListString) {
    this.o_tasksPanelFilterAssignedToUserIDsComma = i_newValueCommaListString;
  }

  a_tasks_panel_set_filter_assigned_by_user_ids_comma(i_newValueCommaListString) {
    this.o_tasksPanelFilterAssignedByUserIDsComma = i_newValueCommaListString;
  }

  a_tasks_panel_set_filter_date_from_ymd(i_newValueDateYmd) {
    this.o_tasksPanelFilterDateFromYmd = i_newValueDateYmd;
  }

  a_tasks_panel_set_filter_date_to_ymd(i_newValueDateYmd) {
    this.o_tasksPanelFilterDateToYmd = i_newValueDateYmd;
  }

  a_tasks_panel_set_filter_text(i_newValue) {
    this.o_tasksPanelFilterText = i_newValue;
  }

  a_tasks_set_editing_task_relevance_and_task_obj(i_taskrelevanceCaptureID, i_taskObj) {
    this.o_tasksEditingTaskRelevanceCaptureID = i_taskrelevanceCaptureID;
    this.o_tasksEditingTaskObj = i_taskObj;
  }

  a_tasks_open_task_from_task_id(i_taskID) {
    const taskMap = DatabaseMobx.o_tbl_u_tasks.get(i_taskID);
    if(taskMap !== undefined) {
      var taskObj = JSFUNC.obj_from_map(taskMap);
      var expandedTaskObj = this.tasks_compute_extra_fields_for_task_obj(taskObj);
      this.a_tasks_set_editing_task_relevance_and_task_obj(expandedTaskObj.capture_id, expandedTaskObj);
    }
  }

  a_tasks_insert_new_task(i_captureID, i_assignedToUserIDsComma, i_dateDue, i_dateCompleted, i_important01, i_description, i_preReminderDays, i_postReminderDays) {
    const c_multiLoginUserIDsArray = UserMobx.c_multiLoginUserIDsArray;

    //insert the new task
    const assignerUserID = UserMobx.o_userID;
    const dateCreated = JSFUNC.now_date();
    const viewed0123 = 1;
    const preReminderSent01 = 0;
    const postReminderSentDate = JSFUNC.blank_date();
    const fieldNamesArray = ["capture_id", "assigner_user_id", "assigned_to_user_ids_comma", "date_created", "date_due", "date_completed", "important_01", "viewed_0123", "description", "pre_reminder_days", "pre_reminder_sent_01", "post_reminder_days", "post_reminder_sent_date"];
    const valuesArray = [i_captureID, assignerUserID, i_assignedToUserIDsComma, dateCreated, i_dateDue, i_dateCompleted, i_important01, viewed0123, i_description, i_preReminderDays, preReminderSent01, i_postReminderDays, postReminderSentDate];
    const valuesIdsbArray = ["i", "i", "s", "s", "s", "s", "i", "i", "s", "i", "i", "i", "s"];

    const dateDueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_dateDue, DatabaseMobx.c_genericDateFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("RightPanelMobx", "a_tasks_insert_new_task", ["i_captureID", "i_assignedToUserIDsComma", "i_dateDue", "i_dateCompleted", "i_important01", "i_description", "i_preReminderDays", "i_postReminderDays"], [i_captureID, i_assignedToUserIDsComma, i_dateDue, i_dateCompleted, i_important01, i_description, i_preReminderDays, i_postReminderDays]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert("tbl_u_tasks", fieldNamesArray, valuesArray, valuesIdsbArray);

    if(i_captureID > 0) {
      const assignerNamePlainText = DatabaseMobx.user_name_mask_plaintext_from_user_id(assignerUserID);
      const assignedToNamesCommaPlainText = DatabaseMobx.user_names_comma_mask_plaintext_from_user_ids_comma(i_assignedToUserIDsComma);
      const insertTaskChangeLogString = "Assigner: '" + assignerNamePlainText + "', Assigned To: '" + assignedToNamesCommaPlainText + "', Due: '" + dateDueMaskPlainText + "', Description: '" + i_description + "'";
      C_CallPhpTblUID.add_changelog_details(i_captureID, DatabaseMobx.k_cardIDTasks, "cnt", "Create New Task", insertTaskChangeLogString);
    }

    //send notifications to users other than currently logged in user, call notification after successfully inserting the new task so that the taskID can be used
    const userIDsNotLoggedInUserComma = JSFUNC.remove_all_values_from_comma_list(c_multiLoginUserIDsArray, i_assignedToUserIDsComma);
    if(userIDsNotLoggedInUserComma !== "") { //if there is at least 1 other user to send a notification to
      const functionOnSuccess = (i_parseResponse) => {
        const newTaskID = i_parseResponse.outputObj.i0;

        var newTaskObj = {};
        for(let f = 0; f < fieldNamesArray.length; f++) {
          newTaskObj[fieldNamesArray[f]] = valuesArray[f];
        }
        var notificationMessage = "New Task from: " + UserMobx.c_userName + "\n\n";
        notificationMessage += this.get_task_description_block_from_taskObj(newTaskObj);
        const notificationClickAction = "openTask:" + newTaskID;

        const C_CallPhpTblUIDNewTaskNotification = new JSPHP.ClassCallPhpTblUID(jsDescription);
        C_CallPhpTblUIDNewTaskNotification.add_notifications_with_emails(userIDsNotLoggedInUserComma, false, notificationMessage, notificationClickAction);
        C_CallPhpTblUIDNewTaskNotification.execute();
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    C_CallPhpTblUID.execute();
  }

  a_tasks_update_task(i_initialTaskObj, i_updatedTaskObj) {
    //==============Skip is logged in as Skip==================
    //--Drew created this Task (by Drew), edited by Skip--
    //Example #00
    //(created by) Drew
    //(assigned to) Initial: Skip
    //(assigned to) Updated: Skip (assignees can't change assigned to field)
    //(due date) Initial: 9/25
    //(due date) Updated: 9/27
    //action: Drew gets a special notification (a Task you created has been updated [due date, description, completed, important])

    //--Skip created this task (by Skip), edited by Skip--
    //Example #1a
    //(assigned to) Initial: Skip
    //(assigned to) Updated: Drew
    //action: Drew add notification

    //Example #1b
    //(assigned to) Initial: Skip
    //(assigned to) Updated: Drew, Natalie
    //action: Drew add notification (Drew add [from Drew's perspective], Skip removed, Natalie added), Natalie add notification

    //Example #1c
    //(assigned to) Initial: Skip
    //(assigned to) Updated: Skip, Drew
    //action: Drew add notification

    //Example #2a
    //(assigned to) Initial: Drew
    //(assigned to) Updated: Natalie, Brenda
    //action: Drew removal notification, Natalie add notification, Brenda add notification

    //Example #2b
    //(assigned to) Initial: Drew
    //(assigned to) Updated: Drew, Natalie
    //action: Drew person added notification, Natalie add notification

    //Example #2c
    //(assigned to) Initial: Drew
    //(assigned to) Updated: Skip
    //action: Drew removal notification

    //Example #2d
    //(assigned to) Initial: Drew
    //(assigned to) Updated: Skip, Natalie
    //action: Drew removal notification, Natalie add notification

    //Example #3a
    //(assigned to) Initial: Skip, Drew
    //(assigned to) Updated: Skip
    //action: Drew removal notification

    //Example #3b
    //(assigned to) Initial: Skip, Drew
    //(assigned to) Updated: Drew
    //action: Drew persons removed notification

    //Example #3c
    //(assigned to) Initial: Skip, Drew
    //(assigned to) Updated: Skip, Natalie
    //action: Drew removal notification, Natalie add notification

    //Example #3d
    //(assigned to) Initial: Skip, Drew
    //(assigned to) Updated: Natalie
    //action: Drew removal notification, Natalie add notification

    //Example #3e
    //(assigned to) Initial: Skip, Drew
    //(assigned to) Updated: Drew, Natalie
    //action: Drew person added and person removed notification, Natalie add notification

    //Example #3f
    //(assigned to) Initial: Skip, Drew, Natalie
    //(assigned to) Updated: Drew
    //action: Drew persons removed notification

    //Example #3g
    //(assigned to) Initial: Skip(div), Skip(admin), Drew, Natalie
    //(assigned to) Updated: Skip(div), Drew, Natalie
    //action: Drew persons removed notification, Natalie persons removed notification


    //=======NOTIFICATIONS - who to send to and ownership change line======
    //combine together initial and updated assigned to user lists (remove duplicates)
    //remove all loggedInUser's multilogins
    //loop over all of those users
    //for each user: am I on the initial list? am I on the updated list?
    // 1. I'm on updated but not initial - notification first line is 'add' notification
    // 2. I'm on initial but not updated - notification first line is 'removal' notification
    // 3. I'm on both - notification first line is other persons were added/removed (can calculate if it's add or remove or both)


    //!!!!NEED TO TEST that simple functionality works afterwards of opening a task and just changing the description or the due date!!!!
    //want the tagline at top to say 'description was changed', something really simple to say that one field was updated


    //&&&&&&&&&Task Notification Email&&&&&&&&&&&
    //[Ownership change line] (new task created, you were added, you were removed, other people add/remove, task deleted)
    //[blank line]
    //Task updated by: Skip Blackburn
    //Task field(s) updated: 'Assigned To', 'Due Date', 'Description'
    //[blank line]
    //[blank line]
    //------------------------------- Task --------------------------------------------
    //Due Date: 09/25/2023 ('Active' or 'Completed' in paren after date)
    //Relevance:
    //Extra Capture Field:
    //To:
    //By:
    //Description: BIG DESCRIPTION 50 LINES
    //---------------------------------------------------------------------------------
    //[blank line]
    //[blank line]
    //Task was updated:
    //Old 'Assigned To': Skip, Natalie, Drew
    //New 'Assigned To': Drew, Mike
    //[blank line]
    //Old 'Due Date': 09/25/2023
    //New 'Due DAte': 09/27/2023
    //[blank line]
    //Old 'Description': BIG DESCRIPTION 50 LINES
    //New 'Description': BIGGER DESCRIPTION 75 LINES

    //id, capture_id, assigner_user_id, {assigned_to_user_ids_comma}, date_created, {date_due}, {date_completed}, {important_01}, viewed_0123, {description}, {pre_reminder_days}, pre_reminder_sent_01, {post_reminder_days}, post_reminder_sent_date
    const k_cardIDTasks = DatabaseMobx.k_cardIDTasks;
    const c_userName = UserMobx.c_userName;

    const taskID = i_initialTaskObj.id;
    const taskRelevanceCaptureID = i_initialTaskObj.capture_id;
    const taskPreReminderSent01 = i_initialTaskObj.pre_reminder_sent_01;

    const recordCaptureChangelogTF = JSFUNC.select_int_is_filled_out_tf(taskRelevanceCaptureID);
    const taskPreReminderSentTF = (taskPreReminderSent01 === 1);

    const updatedAssignerUserName = DatabaseMobx.user_name_mask_plaintext_from_user_id(i_updatedTaskObj.assigner_user_id);
    const updatedAssignedToUserNamesComma = DatabaseMobx.user_names_comma_mask_plaintext_from_user_ids_comma(i_updatedTaskObj.assigned_to_user_ids_comma);
    const updatedDateDueMask = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_updatedTaskObj.date_due, DatabaseMobx.c_genericDateFieldTypeObj);

    const initialAssignedToUserIDsArray = JSFUNC.convert_comma_list_to_int_array(i_initialTaskObj.assigned_to_user_ids_comma);
    const updatedAssignedToUserIDsArray = JSFUNC.convert_comma_list_to_int_array(i_updatedTaskObj.assigned_to_user_ids_comma);
    const allTaskUniqueOldNewUserIDsArray = JSFUNC.unique(JSFUNC.concat_arrays_or_values_into_new_array(i_updatedTaskObj.assigner_user_id, initialAssignedToUserIDsArray, updatedAssignedToUserIDsArray)); //combine assigner and old/new assigned to userIDs into 1 array, get unique ids
    const allTaskUserIDsNotLoggedInUserArray = JSFUNC.remove_entries_matching_array1_values_from_array2(UserMobx.c_multiLoginUserIDsArray, allTaskUniqueOldNewUserIDsArray);

    //initialize arrays to store only fields that were updated
    var ownershipChangeTF = false;
    var fieldNamesArray = [];
    var valuesArray = [];
    var valuesIdsbArray = [];
    var updateTaskChangeLogArray = []; //used in both changelog and notifications
    var notificationUpdatesArrayOfObjs = [];

    //assigned_to_user_ids_comma
    if(i_updatedTaskObj.assigned_to_user_ids_comma !== i_initialTaskObj.assigned_to_user_ids_comma) {
      ownershipChangeTF = true;
      fieldNamesArray.push("assigned_to_user_ids_comma");
      valuesArray.push(i_updatedTaskObj.assigned_to_user_ids_comma);
      valuesIdsbArray.push("s");

      const initialAssignedToUserNamesComma = DatabaseMobx.user_names_comma_mask_plaintext_from_user_ids_comma(i_initialTaskObj.assigned_to_user_ids_comma);
      updateTaskChangeLogArray.push("Assigned To changed from '" + initialAssignedToUserNamesComma + "' to '" + updatedAssignedToUserNamesComma + "'");

      notificationUpdatesArrayOfObjs.push({fieldDisplayName:"Assigned To", initialValueMaskPlainText:initialAssignedToUserNamesComma, updatedValueMaskPlainText:updatedAssignedToUserNamesComma});
    }

    //date_due
    if(i_updatedTaskObj.date_due !== i_initialTaskObj.date_due) {
      fieldNamesArray.push("date_due");
      valuesArray.push(i_updatedTaskObj.date_due);
      valuesIdsbArray.push("s");

      const initialDateDue = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_initialTaskObj.date_due, DatabaseMobx.c_genericDateFieldTypeObj);
      updateTaskChangeLogArray.push("Due Date changed from " + initialDateDue + " to " + updatedDateDueMask);

      notificationUpdatesArrayOfObjs.push({fieldDisplayName:"Due Date", initialValueMaskPlainText:initialDateDue, updatedValueMaskPlainText:updatedDateDueMask});

      //when the due date is changed, check if the pre-reminder email was already sent, if so, reset it as not yet sent
      if(taskPreReminderSentTF) {
        fieldNamesArray.push("pre_reminder_sent_01");
        valuesArray.push(0);
        valuesIdsbArray.push("i");
      }
    }

    //date_completed
    if(i_updatedTaskObj.date_completed !== i_initialTaskObj.date_completed) {
      fieldNamesArray.push("date_completed");
      valuesArray.push(i_updatedTaskObj.date_completed);
      valuesIdsbArray.push("s");

      var initialCompletedNotCompletedString = "";
      var updatedCompletedNotCompletedString = "";
      if(JSFUNC.date_is_filled_out_tf(i_updatedTaskObj.date_completed)) {
        initialCompletedNotCompletedString = "Not Completed";
        const updatedDateCompleted = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_updatedTaskObj.date_completed, DatabaseMobx.c_genericDateFieldTypeObj);
        updatedCompletedNotCompletedString = "Completed on " + updatedDateCompleted;
      }
      else {
        const initialDateCompleted = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_initialTaskObj.date_completed, DatabaseMobx.c_genericDateFieldTypeObj);
        initialCompletedNotCompletedString = "Completed on " + initialDateCompleted;
        updatedCompletedNotCompletedString = "Not Completed";
      }
      updateTaskChangeLogArray.push("Marked as " + updatedCompletedNotCompletedString);

      notificationUpdatesArrayOfObjs.push({fieldDisplayName:"Completed", initialValueMaskPlainText:initialCompletedNotCompletedString, updatedValueMaskPlainText:updatedCompletedNotCompletedString});
    }

    //important_01
    if(i_updatedTaskObj.important_01 !== i_initialTaskObj.important_01) {
      fieldNamesArray.push("important_01");
      valuesArray.push(i_updatedTaskObj.important_01);
      valuesIdsbArray.push("i");

      const initialImportantNotImportantString = ((i_initialTaskObj.important_01 === 1) ? ("Important") : ("Not Important"));
      const updatedImportantNotImportantString = ((i_updatedTaskObj.important_01 === 1) ? ("Important") : ("Not Important"));
      updateTaskChangeLogArray.push("Marked as " + updatedImportantNotImportantString);

      notificationUpdatesArrayOfObjs.push({fieldDisplayName:"Important", initialValueMaskPlainText:initialImportantNotImportantString, updatedValueMaskPlainText:updatedImportantNotImportantString});
    }

    //description
    if(i_updatedTaskObj.description !== i_initialTaskObj.description) {
      fieldNamesArray.push("description");
      valuesArray.push(i_updatedTaskObj.description);
      valuesIdsbArray.push("s");

      updateTaskChangeLogArray.push("Description was updated");

      notificationUpdatesArrayOfObjs.push({fieldDisplayName:"Description", initialValueMaskPlainText:i_initialTaskObj.description, updatedValueMaskPlainText:i_updatedTaskObj.description});
    }

    //pre_reminder_days
    if(i_updatedTaskObj.pre_reminder_days !== i_initialTaskObj.pre_reminder_days) {
      fieldNamesArray.push("pre_reminder_days");
      valuesArray.push(i_updatedTaskObj.pre_reminder_days);
      valuesIdsbArray.push("i");
    }

    //post_reminder_days
    if(i_updatedTaskObj.post_reminder_days !== i_initialTaskObj.post_reminder_days) {
      fieldNamesArray.push("post_reminder_days");
      valuesArray.push(i_updatedTaskObj.post_reminder_days);
      valuesIdsbArray.push("i");
    }

    const numUpdatedFields = fieldNamesArray.length;
    const numChangelogUpdates = updateTaskChangeLogArray.length;

    if(numUpdatedFields > 0) { //if none of the fields have been changed, do not do an update/changelog operation
      const jsDescription = JSFUNC.js_description_from_action("RightPanelMobx", "a_tasks_update_task", ["i_initialTaskObj", "i_updatedTaskObj"], [i_initialTaskObj, i_updatedTaskObj]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //update the task entry for all updated fields
      C_CallPhpTblUID.add_update("tbl_u_tasks", taskID, fieldNamesArray, valuesArray, valuesIdsbArray);

      //if there are any capture changelog entries to report, create a new entry
      if(recordCaptureChangelogTF && (numChangelogUpdates > 0)) {
        var updateTaskCaptureChangelogString = updateTaskChangeLogArray.join(", ");
        updateTaskCaptureChangelogString += "\n(Task " + updatedAssignerUserName + " assigned to " + updatedAssignedToUserNamesComma + " due " + updatedDateDueMask + ")";
        updateTaskCaptureChangelogString += "\nDescription:\n'" + i_updatedTaskObj.description + "'";
        C_CallPhpTblUID.add_changelog_details(taskRelevanceCaptureID, k_cardIDTasks, "ut", "Update Task", updateTaskCaptureChangelogString);
      }

      //send notifications to users that are not the logged in user, loop through all other users involved in the task to determine what type of notification they get
      for(let userID of allTaskUserIDsNotLoggedInUserArray) {
        var notificationMessage = "";

        var userOnInitialTaskTF = JSFUNC.in_array(userID, initialAssignedToUserIDsArray);
        var userOnUpdatedTaskTF = JSFUNC.in_array(userID, updatedAssignedToUserIDsArray);

        //determine ownership change line if 'assigned to' field was changed
        if(ownershipChangeTF) {
          if(!userOnInitialTaskTF && userOnUpdatedTaskTF) { //added
            notificationMessage += "You have been added to an existing Task\n\n";
          }
          else if(userOnInitialTaskTF && !userOnUpdatedTaskTF) { //removed
            notificationMessage += "You have been removed from a Task\n\n";
          }
          else if(userOnInitialTaskTF && userOnUpdatedTaskTF) { //remained on, other people changed
            notificationMessage += "Other Users have been added/removed on a Task assigned to you\n\n";
          }
        }

        notificationMessage += "Task updated by: " + c_userName;
        notificationMessage += "\nTask field(s) updated: " + JSFUNC.convert_array_to_display_comma_list(JSFUNC.get_column_vector_from_arrayOfObjs(notificationUpdatesArrayOfObjs, "fieldDisplayName"), "'") + "\n\n\n";
        
        notificationMessage += this.get_task_description_block_from_taskObj(i_updatedTaskObj);

        notificationMessage += "\n\n\n================ Task values updated ================";
        for(let notificationUpdateObj of notificationUpdatesArrayOfObjs) {
          notificationMessage += "\n" + notificationUpdateObj.fieldDisplayName;
          notificationMessage += "\n - Old: " + notificationUpdateObj.initialValueMaskPlainText;
          notificationMessage += "\n - New: " + notificationUpdateObj.updatedValueMaskPlainText + "\n";
        }
        notificationMessage += "\n=====================================================";

        const notificationClickAction = "openTask:" + i_updatedTaskObj.id;
        C_CallPhpTblUID.add_notifications_with_emails(userID, false, notificationMessage, notificationClickAction);
      }

      C_CallPhpTblUID.execute();
    }
  }

  a_tasks_delete_task(i_taskObj) {
    const dateDueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_taskObj.date_due, DatabaseMobx.c_genericDateFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("RightPanelMobx", "a_tasks_delete_task", ["i_taskObj"], [i_taskObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_u_tasks", i_taskObj.id); //no sort column (tasks sorted by date), no need for resort after delete

    if(i_taskObj.capture_id > 0) {
      const assignerName = DatabaseMobx.user_name_mask_plaintext_from_user_id(i_taskObj.assigner_user_id);
      const assignedToNamesComma = DatabaseMobx.user_names_comma_mask_plaintext_from_user_ids_comma(i_taskObj.assigned_to_user_ids_comma);
      const deleteTaskChangeLogString = "Assigner: '" + assignerName + "', Assigned To: '" + assignedToNamesComma + "', Due: '" + dateDueMaskPlainText + "', Description: '" + i_taskObj.description + "'";
      C_CallPhpTblUID.add_changelog_details(i_taskObj.capture_id, DatabaseMobx.k_cardIDTasks, "dc", "Delete Task", deleteTaskChangeLogString);
    }

    //delete task notification to the person it was assigned to if not yourself (no click action since the task is now deleted)
    const assignedToUserIDsArray = JSFUNC.convert_comma_list_to_int_array(i_taskObj.assigned_to_user_ids_comma);
    const allTaskUniqueUserIDsArray = JSFUNC.unique(JSFUNC.concat_arrays_or_values_into_new_array(i_taskObj.assigner_user_id, assignedToUserIDsArray));
    const allTaskUserIDsNotLoggedInUserArray = JSFUNC.remove_entries_matching_array1_values_from_array2(UserMobx.c_multiLoginUserIDsArray, allTaskUniqueUserIDsArray);
    if(allTaskUserIDsNotLoggedInUserArray.length > 0) {
      var notificationMessage = "Task deleted by " + UserMobx.c_userName + "\n\n";
      notificationMessage += this.get_task_description_block_from_taskObj(i_taskObj);
      const notificationClickAction = ""; //do nothing since the task does not exist anymore
      C_CallPhpTblUID.add_notifications_with_emails(allTaskUserIDsNotLoggedInUserArray, false, notificationMessage, notificationClickAction);
    }

    C_CallPhpTblUID.execute();
  }

  get_task_description_block_from_taskObj(i_taskObj) {
    const expandedTaskObj = this.tasks_compute_extra_fields_for_task_obj(i_taskObj);

    var notificationMessage = "---------------------------------- Task ----------------------------------";

    notificationMessage += "\nDue: " + DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_taskObj.date_due, DatabaseMobx.c_genericDateFieldTypeObj);
    if(JSFUNC.date_is_filled_out_tf(i_taskObj.date_completed)) {
      notificationMessage += " (Completed: " + DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_taskObj.date_completed, DatabaseMobx.c_genericDateFieldTypeObj) + ")";
    }

    var relevanceMaskPlainText = "General Task";
    if(JSFUNC.select_int_is_filled_out_tf(i_taskObj.capture_id)) {
      relevanceMaskPlainText = DatabaseMobx.capture_name_plaintext_from_capture_id(i_taskObj.capture_id);
    }
    notificationMessage += "\nRelevance: " + relevanceMaskPlainText;

    for(let extraCaptureFieldValueObj of expandedTaskObj.extraCaptureFieldValuesArrayOfObjs) {
      notificationMessage += "\n" + extraCaptureFieldValueObj.captureFieldDisplayName + ": " + extraCaptureFieldValueObj.valueMaskPlainText;
    }

    const assignedToNamesComma = DatabaseMobx.user_names_comma_mask_plaintext_from_user_ids_comma(i_taskObj.assigned_to_user_ids_comma);
    notificationMessage += "\nAssigned To " + assignedToNamesComma;

    const assignerName = DatabaseMobx.user_name_mask_plaintext_from_user_id(i_taskObj.assigner_user_id);
    notificationMessage += "\nAssigned By " + assignerName;

    notificationMessage += "\nDescription:\n'" + i_taskObj.description + "'";

    notificationMessage += "\n--------------------------------------------------------------------------";

    return(notificationMessage);
  }




  //Notifications
  a_notification_set_click_action_notification_id(i_notificationID) {
    this.o_notificationsClickActionNotificationID = i_notificationID;
  }

  a_notification_handle_click_action(i_clickActionString, i_broadcastReaderNotificationDate="", i_broadcastReaderNotificationMessage="") {
    if(JSFUNC.is_string(i_clickActionString)) {
      var clickActionIsOpenCaptureTF = false;
      var clickActionIsAdminDeleteCaptureTF = false;
      var clickActionIsOpenTaskTF = false;
      var clickActionIsOpenTeammateContractAsContractsManagerTF = false;
      var clickActionIsOpenTeammateContractAsCaptureTF = false;
      var clickActionIsOpenTeammateSurveyTF = false;
      var clickActionIsOpenCaptureBudgetFundingRequestTF = false;
      var clickActionIsOpenFundingRequestAsBudgetManagerTF = false;
      var clickActionIsReadBroadcastTF = false;
      var clickActionIsOpenTicketTF = false;
      var clickActionIsOpenUserActivityTicketTF = false;
      if(i_clickActionString.substring(0, 12) === "openCapture:") { clickActionIsOpenCaptureTF = true; }
      else if(i_clickActionString.substring(0, 19) === "adminDeleteCapture:") { clickActionIsAdminDeleteCaptureTF = true; }
      else if(i_clickActionString.substring(0, 9) === "openTask:") { clickActionIsOpenTaskTF = true; }
      else if(i_clickActionString.substring(0, 39) === "openTeammateContractAsContractsManager:") { clickActionIsOpenTeammateContractAsContractsManagerTF = true; }
      else if(i_clickActionString.substring(0, 30) === "openTeammateContractAsCapture:") { clickActionIsOpenTeammateContractAsCaptureTF = true; }
      else if(i_clickActionString.substring(0, 19) === "openTeammateSurvey:") { clickActionIsOpenTeammateSurveyTF = true; }
      else if(i_clickActionString.substring(0, 32) === "openCaptureBudgetFundingRequest:") { clickActionIsOpenCaptureBudgetFundingRequestTF = true; }
      else if(i_clickActionString.substring(0, 34) === "openFundingRequestAsBudgetManager:") { clickActionIsOpenFundingRequestAsBudgetManagerTF = true; }
      else if(i_clickActionString === "readBroadcast") { clickActionIsReadBroadcastTF = true; }
      else if(i_clickActionString.substring(0, 11) === "openTicket:") { clickActionIsOpenTicketTF = true; }
      else if(i_clickActionString.substring(0, 23) === "openUserActivityTicket:") { clickActionIsOpenUserActivityTicketTF = true; }

      if(clickActionIsOpenCaptureTF) {
        const captureIDToOpen = JSFUNC.str2int(i_clickActionString.substring(12));
        OpenCaptureMobx.a_open_single_capture(captureIDToOpen);
      }
      else if(clickActionIsAdminDeleteCaptureTF) {
        if(UserMobx.c_combinedUserObj.powerHasAdminPowerTF) { //logged in user is currently in as an admin, they can delete the capture
          const captureIDToOpen = JSFUNC.str2int(i_clickActionString.substring(19));

          //check to see if this captureID exists in the system, if not, assume that it was deleted by a different admin and display an alert message
          if(DatabaseMobx.o_tbl_captures.has(captureIDToOpen)) { //capture still exists, open the capture
            OpenCaptureMobx.a_open_single_capture(captureIDToOpen);
          }
          else { //a different admin already deleted this capture
            alert("This Capture (ID: " + captureIDToOpen + ") has already been deleted by an admin");
          }
        }
        else { //logged in user has an admin multilogin, but is currently something else, display an alert to tell them to switch
          alert("Open the User Panel to switch to your Admin user profile to delete this capture");
        }
      }
      else if(clickActionIsOpenTaskTF) {
        const taskIDToOpen = JSFUNC.str2int(i_clickActionString.substring(9));
        this.a_tasks_open_task_from_task_id(taskIDToOpen);
      }
      else if(clickActionIsOpenTeammateContractAsContractsManagerTF || clickActionIsOpenTeammateContractAsCaptureTF) {
        if(clickActionIsOpenTeammateContractAsContractsManagerTF && !UserMobx.c_combinedUserObj.powerHasContractsPowerTF) {
          alert("Open the User Panel to switch to your Contracts/ContractsExec user profile to view/update this Teammate Contract request");
        }
        else {
          const clickActionNumChars = ((clickActionIsOpenTeammateContractAsContractsManagerTF) ? (39) : (30));
          const ctctComma = i_clickActionString.substring(clickActionNumChars); //"openTeammateContract:c" + this.o_openContractCaptureID + ",t" + this.o_openContractTeammateID + "ct" + this.o_openContractTypeID;
          const ctctArray = JSFUNC.convert_comma_list_to_array(ctctComma);
          if(ctctArray.length === 3) {
            const captureID = JSFUNC.str2int(ctctArray[0].substring(1));
            const teammateID = JSFUNC.str2int(ctctArray[1].substring(1));
            const contractTypeID = JSFUNC.str2int(ctctArray[2].substring(2));

            //open the teammate contract floating box
            if(clickActionIsOpenTeammateContractAsContractsManagerTF) { //a contracts login already has all teammate data loaded into memory for every capture, they can just open the teammate contract
              TeammateContractsMobx.a_open_teammate_contract(captureID, teammateID, contractTypeID);
            }
            else { //a regular user needs to open the capture first to get all the teammate data in order to open the teammate contract
              const functionOnSuccess = (i_parseResponse) => {
                OpenCaptureMobx.a_launch_card_full(DatabaseMobx.k_cardIDTeammates);
                TeammateContractsMobx.a_open_teammate_contract(captureID, teammateID, contractTypeID);
              }
              OpenCaptureMobx.a_open_single_capture(captureID, functionOnSuccess);
            }
          }
        }
      }
      else if(clickActionIsOpenTeammateSurveyTF) {
        const cstComma = i_clickActionString.substring(19); //"openTeammateSurvey:c" + captureID + ",s" + surveyID + ",t" + teammateID
        const cstArray = JSFUNC.convert_comma_list_to_array(cstComma);
        if(cstArray.length === 3) {
          const captureID = JSFUNC.str2int(cstArray[0].substring(1));
          const surveyID = JSFUNC.str2int(cstArray[1].substring(1));
          const teammateID = JSFUNC.str2int(cstArray[2].substring(1));

          const functionOnSuccess = (i_parseResponse) => {
            OpenCaptureMobx.a_launch_card_full(DatabaseMobx.k_cardIDTeammates);
            OpenCaptureMobx.a_teammates_set_left_tab_selected("surveysCapabilitiesGapAnalysis");
            OpenCaptureMobx.a_teammates_set_floating_box_survey_teammate_id_and_survey_id(teammateID, surveyID);
          }
          OpenCaptureMobx.a_open_single_capture(captureID, functionOnSuccess);
        }
      }
      else if(clickActionIsOpenCaptureBudgetFundingRequestTF) {
        const cfrComma = i_clickActionString.substring(32); //"openCaptureBudgetFundingRequest:c" + captureID + ",fr" + fundingRequestID;
        const cfrArray = JSFUNC.convert_comma_list_to_array(cfrComma);
        if(cfrArray.length === 2) {
          const captureID = JSFUNC.str2int(cfrArray[0].substring(1));
          const fundingRequestID = JSFUNC.str2int(cfrArray[1].substring(2));

          const functionOnSuccess = (i_parseResponse) => {
            OpenCaptureMobx.a_launch_card_full(DatabaseMobx.k_cardIDBudget);

            const fundingRequestMap = DatabaseMobx.o_tbl_c_budget_funding_requests.get(fundingRequestID); //this funding request tbl is now loaded with all funding requests from this capture, which should contain this fundingRequestID
            if(fundingRequestMap !== undefined) {
              OpenCaptureMobx.a_budget_set_selected_budget_category_id(fundingRequestMap.get("budget_category_id"));
              OpenCaptureMobx.a_budget_set_editing_funding_request_id(fundingRequestID);
            }
          }
          OpenCaptureMobx.a_open_single_capture(captureID, functionOnSuccess);
        }
      }
      else if(clickActionIsOpenFundingRequestAsBudgetManagerTF) {
        if(!UserMobx.c_combinedUserObj.powerHasBudgetPowerTF) {
          alert("Open the User Panel to switch to your Budget/BudgetExec user profile to view/update this Funding Request");
        }
        else {
          const fundingRequestID = JSFUNC.str2int(i_clickActionString.substring(34));
          BudgetMobx.a_budget_todo_set_open_funding_request_id(fundingRequestID);
        }
      }
      else if(clickActionIsReadBroadcastTF) {
        this.a_set_broadcast_reader_notification_date_and_message(i_broadcastReaderNotificationDate, i_broadcastReaderNotificationMessage);
      }
      else if(clickActionIsOpenTicketTF) {
        const ticketIDToOpen = JSFUNC.str2int(i_clickActionString.substring(11));
        this.a_set_open_panel(1);
        this.a_tab_click("Support Ticket for BIT Solutions");
        this.a_view_ticket_click(ticketIDToOpen);
      }
      else if(clickActionIsOpenUserActivityTicketTF) {
        if(!UserMobx.c_combinedUserObj.powerHasAdminPowerTF) {
          alert("Open the User Panel to switch to your Admin user profile to view this Support Ticket in the User Activity tab");
        }
        else {
          const showTicketsUserID = JSFUNC.str2int(i_clickActionString.substring(23));
          const showTicketsUserIDsArray = [showTicketsUserID];
          CaptureExecMobx.a_left_nav_select_tab("User Activity");
          AdminMobx.a_activity_set_show_tickets_user_ids_array(showTicketsUserIDsArray);
        }
      }
    }
  }

  a_notification_mark_as_read(i_notificationID) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_notification_mark_as_read", ["i_notificationID"], [i_notificationID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_u_notifications", i_notificationID, "read_01", 1, "i");
    C_CallPhpTblUID.execute();
  }

  a_notification_dismiss(i_notificationID) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_notification_dismiss", ["i_notificationID"], [i_notificationID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_u_notifications", i_notificationID); //no sort column (tasks sorted by date), no need for resort after delete
    C_CallPhpTblUID.execute();
  }

  a_notification_send_broadcast_notifications(i_userPerEmailIDsArray, i_broadcastMessageWithFrom, i_broadcastClickAction) {
    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureMobx", "a_notification_send_broadcast_notifications", ["i_userPerEmailIDsArray", "i_broadcastMessageWithFrom", "i_broadcastClickAction"], [i_userPerEmailIDsArray, i_broadcastMessageWithFrom, i_broadcastClickAction]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const userPerEmailTrueUserFalse = true;
    const emailRedirectButtonWithNotificationClickActionStringTF = false; //broadcast reader does not have an ID number for the notification because it is created simultaneously with the email being sent, do not show the redirect button in the email
    C_CallPhpTblUID.add_notifications_with_emails(i_userPerEmailIDsArray, userPerEmailTrueUserFalse, i_broadcastMessageWithFrom, i_broadcastClickAction, emailRedirectButtonWithNotificationClickActionStringTF);

    C_CallPhpTblUID.execute();
  }

  a_set_broadcast_reader_notification_date_and_message(i_broadcastDateRaw, i_broadcastMessage) {
    this.o_broadcastReaderNotificationDate = i_broadcastDateRaw;
    this.o_broadcastReaderNotificationMessage = i_broadcastMessage;
  }




  //help panel
  a_help_panel_load_help_panel_content_if_not_yet_loaded() {
    const o_bitcompaniesH1HelpPanelsTblMapOfMaps = DatabaseMobx.o_bitcompaniesH1HelpPanelsTblMapOfMaps;
    const c_helpPanelTblNamesToLoadArray = DatabaseMobx.c_helpPanelTblNamesToLoadArray;

    if(o_bitcompaniesH1HelpPanelsTblMapOfMaps.size === 0) { //if help has not yet been loaded into local js memory (user hasn't opened panel yet during this login session), load all help content
      this.a_set_help_panel_content_is_loading_tf(true);
      const functionOnFinish = () => {
        this.a_set_help_panel_content_is_loading_tf(false);
      }
      JSPHP.php_db_api_load_multiple_full_database_tbls_from_tbl_names_array_and_uncompress_and_update_local_memory_maps(c_helpPanelTblNamesToLoadArray, functionOnFinish);
    }
  }

  a_set_help_panel_content_is_loading_tf(i_newValueTF) {
    this.o_helpPanelContentIsLoadingTF = i_newValueTF;
  }



}
export default new RightPanelMobx();
