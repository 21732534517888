import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from '../../CaptureExecLocalDatabaseMobx/JSPHP.js';

import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import OpenCaptureMobx from '../OpenCapture/OpenCaptureMobx.js';

class AdminIntegrationsMobx {
  k_integrationsLogActionsArrayOfObjs = [
    {dbName:"iCEiIN", displayName:"Insert CE"},
    {dbName:"uCEuIN", displayName:"Update CE"},
    {dbName:"dCEdIN", displayName:"Delete CE"}
  ];

  o_selectedTabDbName = "setup"; //"setup", "mapping", "specialHandling",  "log"
  o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1 = -1;
  o_setupVerifyApiCredentialsFlag = "start"; //"start", "working", "verified", "credentialsFailed", "ceError"
  o_mappingExampleFieldsPanelOpenTF = false;
  o_mappingExampleFieldsIntegrationOppIDString = "";
  o_mappingExampleFieldsFlag = "start"; //"start", "working", "verified", "credentialsFailed", "ceError"
  o_mappingExampleFieldsFetchedIntegrationOppsJsonString = "";
  o_mappingCollapsedLinkedFieldIDsArray = [];
  o_integrationsChangelogLoadingDataOrErrorUndefinedTFU = false;
  o_integrationsChangelogFilterObj = {
    numItemsPerPage: 25,
    currentPageNumber: 1,
    dateMin: JSFUNC.blank_date(),
    dateMax: JSFUNC.blank_date(),
    userID: -1,
    actionDbNamesComma: "",
    ceCaptureIDsComma: "",
    integrationUniqueIDSearchText: "",
    ceFieldIDsComma: "",
    integrationFieldDbNamesComma: "",
    sortColumnDbName: "dateTimeLocalMask",
    sortIsAscTF: false
  };

  constructor() {
    makeObservable(this, {
      o_selectedTabDbName: observable,
      o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1: observable,
      o_setupVerifyApiCredentialsFlag: observable,
      o_mappingExampleFieldsPanelOpenTF: observable,
      o_mappingExampleFieldsIntegrationOppIDString: observable,
      o_mappingExampleFieldsFlag: observable,
      o_mappingExampleFieldsFetchedIntegrationOppsJsonString: observable,
      o_mappingCollapsedLinkedFieldIDsArray: observable,
      o_integrationsChangelogLoadingDataOrErrorUndefinedTFU: observable,
      o_integrationsChangelogFilterObj: observable,

      c_integrationSystemDisplayName: computed,
      c_setupAllIntegrationApiConnectionFieldsArrayOfObjs: computed,
      c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined: computed,
      c_setupExpandedIntegrationsCredentialsArrayOfObjs: computed,
      c_setupAllIntegrationsCredentialsIDsArray: computed,
      c_selectIntegrationCredentialsFieldTypeObj: computed,
      c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined: computed,
      c_linkedFieldsWithValuesArrayOfObjs: computed,
      c_expandedIntegrationsChangelogArrayOfObjs: computed,
      c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs: computed,
      c_integrationsChangelogFilteredTotalNumItems: computed,
      c_integrationsChangelogCurrentPageFirstItemNumber: computed,
      c_integrationsChangelogCurrentPageLastItemNumber: computed,
      c_integrationsChangelogCanIncrementCurrentPageNumberTF: computed,
      c_integrationsChangelogCanDecrementCurrentPageNumberTF: computed,
      c_filteredSortedItemsOnSelectedPageExpandedIntegrationsChangelogArrayOfObjs: computed,
      c_selectIntegrationsLogActionsFieldTypeObj: computed,
      c_selectMultiIntegrationFieldDbNamesFieldTypeObj: computed,

      a_set_selected_tab_db_name: action,
      a_set_setup_verify_api_credentials_selected_credentials_row_id_or_m1: action,
      a_set_setup_verify_api_credentials_flag: action,
      a_setup_verify_api_credentials: action,
      a_set_mapping_example_fields_panel_open_tf: action,
      a_set_mapping_example_fields_integration_opp_id_string: action,
      a_set_mapping_example_fields_flag: action,
      a_set_mapping_example_fields_fetched_integration_opps_json_string: action,
      a_mapping_example_fields_search_for_integration_opp_by_id_string: action,
      a_create_new_integration_linked_fields_pairing_from_integration_field_db_name: action,
      a_update_linked_fields_field: action,
      a_delete_linked_fields_and_all_mapped_values: action,
      a_create_new_value_pair_under_field_pairing: action,
      a_update_linked_field_values_field: action,
      a_delete_mapped_values_pair: action,
      a_set_mapping_collapsed_linked_field_ids_array: action,
      a_mapping_expand_all_linked_fields: action,
      a_mapping_collapse_all_linked_fields: action,
      a_integrations_changelog_set_loading_data_or_error_undefined_tfu: action,
      a_load_integrations_changelog_data: action,
      a_set_integrations_changelog_filter_field: action
    });
  }


  get c_integrationSystemDisplayName() {
    const c_bitIntegrationSystemVantagePointTF = DatabaseMobx.c_bitIntegrationSystemVantagePointTF;
    if(c_bitIntegrationSystemVantagePointTF) {
      return("VantagePoint");
    }
    return("--Unknown Integration System '" + DatabaseMobx.o_bitcompaniesTblRowMap.get("integration_system") + "'--");
  }


  get c_setupAllIntegrationApiConnectionFieldsArrayOfObjs() {
    const c_companyIntegrationOauthWebAddress = DatabaseMobx.c_companyIntegrationOauthWebAddress;
    const c_companyIntegrationApiWebAddress = DatabaseMobx.c_companyIntegrationApiWebAddress;
    const c_companyIntegrationDatabaseName = DatabaseMobx.c_companyIntegrationDatabaseName;
    const c_companyIntegrationClientIDScramble = DatabaseMobx.c_companyIntegrationClientIDScramble;
    const c_companyIntegrationClientSecretScramble = DatabaseMobx.c_companyIntegrationClientSecretScramble;

    var allIntegrationApiConnectionFieldsArrayOfObjs = [
      {valueRaw:c_companyIntegrationOauthWebAddress, fieldDbName:"integration_oauth_web_address", fieldDisplayName:"OAuth Web Address", inputType:"text", scrambleTF:false},
      {valueRaw:c_companyIntegrationApiWebAddress, fieldDbName:"integration_api_web_address", fieldDisplayName:"API Web Address", inputType:"text", scrambleTF:false},
      {valueRaw:c_companyIntegrationDatabaseName, fieldDbName:"integration_database_name", fieldDisplayName:"Database Name", inputType:"text", scrambleTF:false},
      {valueRaw:c_companyIntegrationClientIDScramble, fieldDbName:"integration_client_id_scramble", fieldDisplayName:"Client ID", inputType:"text", scrambleTF:true},
      {valueRaw:c_companyIntegrationClientSecretScramble, fieldDbName:"integration_client_secret_scramble", fieldDisplayName:"Client Secret", inputType:"text", scrambleTF:true},
    ];

    for(let integrationApiConnectionFieldObj of allIntegrationApiConnectionFieldsArrayOfObjs) {
      integrationApiConnectionFieldObj.isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(integrationApiConnectionFieldObj.valueRaw);
    }

    return(allIntegrationApiConnectionFieldsArrayOfObjs);
  }


  get c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined() {
    const c_tbl_captures_fields = DatabaseMobx.c_tbl_captures_fields;
    const c_companyIntegrationIntegrationUniqueIDCEFieldID = DatabaseMobx.c_companyIntegrationIntegrationUniqueIDCEFieldID;

    if(JSFUNC.select_int_is_filled_out_tf(c_companyIntegrationIntegrationUniqueIDCEFieldID)) {
      return(c_tbl_captures_fields.get(c_companyIntegrationIntegrationUniqueIDCEFieldID)); //either the correct expanded capture field map or undefined
    }
    return(undefined);
  }


  get c_setupExpandedIntegrationsCredentialsArrayOfObjs() {
    const o_tbl_a_integrations_credentials = DatabaseMobx.o_tbl_a_integrations_credentials;
    
    var expandedIntegrationsCredentialsArrayOfObjs = [];
    for(let integrationsCredentialsMap of o_tbl_a_integrations_credentials.values()) {
      var expandedIntegrationsCredentialsObj = JSFUNC.obj_from_map(integrationsCredentialsMap);

      expandedIntegrationsCredentialsArrayOfObjs.push(expandedIntegrationsCredentialsObj);
    }
    return(expandedIntegrationsCredentialsArrayOfObjs);
  }

  get c_setupAllIntegrationsCredentialsIDsArray() {
    const c_setupExpandedIntegrationsCredentialsArrayOfObjs = this.c_setupExpandedIntegrationsCredentialsArrayOfObjs;
    return(JSFUNC.get_column_vector_from_arrayOfObjs(c_setupExpandedIntegrationsCredentialsArrayOfObjs, "id"));
  }

  get c_selectIntegrationCredentialsFieldTypeObj() {
    const c_setupExpandedIntegrationsCredentialsArrayOfObjs = this.c_setupExpandedIntegrationsCredentialsArrayOfObjs;

    const valueDisplayArraysObjAdminChangelogCategories = DatabaseMobx.create_value_display_arrays_obj_from_arrayOfObjs_and_vd_column_names("Integration Credential", c_setupExpandedIntegrationsCredentialsArrayOfObjs, "id", false, "username");
    const swsOptionsObj = {isMultiSelectTF:false, hasSearchTF:false, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjAdminChangelogCategories, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }


  get c_mappingExampleFieldsFetchedIntegrationSingleOppFieldsArrayOfObjsOrUndefined() {
    const o_mappingExampleFieldsFetchedIntegrationOppsJsonString = this.o_mappingExampleFieldsFetchedIntegrationOppsJsonString;

    const fetchedOppsArrayOfObjsOrUndefined = JSFUNC.parse_json_arrayOfObjs_string_into_arrayOfObjs_or_undefined(o_mappingExampleFieldsFetchedIntegrationOppsJsonString);
    
    if(!JSFUNC.is_array(fetchedOppsArrayOfObjsOrUndefined)) {
      return(undefined);
    }

    var oppFieldsArrayOfObjsOrUndefined = [];
    if(fetchedOppsArrayOfObjsOrUndefined.length >= 1) { //empty array means that 0 results were returned from the fetch
      const fetchedOppObj = fetchedOppsArrayOfObjsOrUndefined[0]; //if multiple opps were fetched from the integration, use the first one as the example
      for(var key in fetchedOppObj) {
        if(fetchedOppObj.hasOwnProperty(key)) {
          oppFieldsArrayOfObjsOrUndefined.push({
            integrationFieldDbName: key,
            integrationValueString: fetchedOppObj[key]
          });
        }
      }
    }
    return(oppFieldsArrayOfObjsOrUndefined);
  }


  get c_linkedFieldsWithValuesArrayOfObjs() {
    const o_tbl_a_integrations_linked_fields = DatabaseMobx.o_tbl_a_integrations_linked_fields;
    const o_tbl_a_integrations_linked_field_values = DatabaseMobx.o_tbl_a_integrations_linked_field_values;

    var linkedFieldsWithValuesArrayOfObjs = [];
    for(let linkedFieldMap of o_tbl_a_integrations_linked_fields.values()) {
      var linkedFieldObj = JSFUNC.obj_from_map(linkedFieldMap);

      //get the selected linked CE field info and fieldTypeObj if it is a select type
      var ceFieldIsValidTF = false;
      var canMapValuesTF = false;
      var ceFieldFieldTypeObjOrUndefined = undefined;
      if(JSFUNC.select_int_is_filled_out_tf(linkedFieldObj.capture_field_id)) {
        var captureFieldMapOrUndefined = DatabaseMobx.c_tbl_captures_fields.get(linkedFieldObj.capture_field_id);
        if(captureFieldMapOrUndefined !== undefined) {
          ceFieldFieldTypeObjOrUndefined = captureFieldMapOrUndefined.get("fieldTypeObj");
          if(ceFieldFieldTypeObjOrUndefined !== undefined) {
            ceFieldIsValidTF = true;
            if(ceFieldFieldTypeObjOrUndefined.requiresSelectWithSearchDataObjTF) {
              canMapValuesTF = true;
            }
          }
        }
      }

      //determine if the integration field dbName has any spaces in it, which displays a red warning
      var integrationFieldDbNameContainsAnySpacesTF = JSFUNC.input_lowercase_string_contains_lowercase_search_term_string_tf(linkedFieldObj.integration_field_db_name, " ");

      //compute fields related to the integration field new capture default value
      var integrationFieldUsingDefaultValueTF = (linkedFieldObj.integration_field_using_default_value_01 === 1);

      //fetch all mapped values for this linked pair
      var mappedValuesArrayOfObjs = []; //no mapped values loaded/shown if the selected CE field is not valid
      if(ceFieldIsValidTF) {
        mappedValuesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_integrations_linked_field_values, "linked_field_id", linkedFieldObj.id, "sort", true);
        
        //loop through mapped values and compute the valueMaskSortIfoObj from the CE raw value
        for(let mappedValueObj of mappedValuesArrayOfObjs) {
          //convert CE raw value string from the database to the raw value (int or string) based on the fieldTypeObj
          var captureFieldValueRaw = DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(mappedValueObj.capture_field_value_string, ceFieldFieldTypeObjOrUndefined);
          var captureFieldValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(captureFieldValueRaw, ceFieldFieldTypeObjOrUndefined);
          mappedValueObj.captureFieldValueRaw = captureFieldValueRaw;
          mappedValueObj.captureFieldValueMaskSortIfoObj = captureFieldValueMaskSortIfoObj;
        }
      }

      //used when converting a new CE raw value into the integration new value
      var hasAtLeast1MappedValueTF = (mappedValuesArrayOfObjs.length > 0);

      linkedFieldObj.ceFieldIsValidTF = ceFieldIsValidTF;
      linkedFieldObj.canMapValuesTF = canMapValuesTF;
      linkedFieldObj.ceFieldFieldTypeObjOrUndefined = ceFieldFieldTypeObjOrUndefined;
      linkedFieldObj.integrationFieldDbNameContainsAnySpacesTF = integrationFieldDbNameContainsAnySpacesTF;
      linkedFieldObj.integrationFieldUsingDefaultValueTF = integrationFieldUsingDefaultValueTF;
      linkedFieldObj.mappedValuesArrayOfObjs = mappedValuesArrayOfObjs;
      linkedFieldObj.hasAtLeast1MappedValueTF = hasAtLeast1MappedValueTF;

      linkedFieldsWithValuesArrayOfObjs.push(linkedFieldObj);
    }

    JSFUNC.sort_arrayOfObjs(linkedFieldsWithValuesArrayOfObjs, "sort", true);
    
    return(linkedFieldsWithValuesArrayOfObjs);
  }




  get c_expandedIntegrationsChangelogArrayOfObjs() {
    const o_tbl_a_integrations_log = DatabaseMobx.o_tbl_a_integrations_log;

    var expandedIntegrationsChangelogArrayOfObjs = [];
    for(let integrationChangelogMap of o_tbl_a_integrations_log.values()) {
      var expandedIntegrationsChangelogObj = JSFUNC.obj_from_map(integrationChangelogMap);

      expandedIntegrationsChangelogObj.dateLocalYmd = JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(expandedIntegrationsChangelogObj.datetime_utc);
      expandedIntegrationsChangelogObj.dateTimeLocalMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(expandedIntegrationsChangelogObj.datetime_utc, DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj);
      expandedIntegrationsChangelogObj.userNameMask = DatabaseMobx.user_name_mask_from_user_id(expandedIntegrationsChangelogObj.user_id);
      
      var iudActionStringMask = expandedIntegrationsChangelogObj.iud_action_string;
      if(expandedIntegrationsChangelogObj.iud_action_string === "iCEiIN") { iudActionStringMask = "Insert CE"; }
      else if(expandedIntegrationsChangelogObj.iud_action_string === "uCEuIN") { iudActionStringMask = "Update CE"; }
      else if(expandedIntegrationsChangelogObj.iud_action_string === "dCEdIN") { iudActionStringMask = "Delete CE"; }
      expandedIntegrationsChangelogObj.iudActionStringMask = iudActionStringMask;

      expandedIntegrationsChangelogObj.captureNameMask = DatabaseMobx.capture_name_plaintext_from_capture_id(expandedIntegrationsChangelogObj.ce_capture_id, true);
      expandedIntegrationsChangelogObj.ceFieldDisplayNameMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(expandedIntegrationsChangelogObj.ce_field_id, DatabaseMobx.c_selectCaptureFieldFieldTypeObj);

      var successErrorBgClass = "";
      var successErrorFontClass = "";
      var successErrorMessage = "";
      if(JSFUNC.string_is_filled_out_tf(expandedIntegrationsChangelogObj.error_message)) {
        if(expandedIntegrationsChangelogObj.error_message.substring(0, 9) === "[skipped]") {
          successErrorBgClass = "bgLightOrange";
          successErrorFontClass = "fontItalic fontTextLight";
        }
        else {
          successErrorBgClass = "bgLightRed";
          successErrorFontClass = "fontDarkRed";
        }
        successErrorMessage = expandedIntegrationsChangelogObj.error_message;
      }
      else {
        successErrorBgClass = "bgLightGreen";
        successErrorFontClass = "fontItalic fontDarkGreen";
        successErrorMessage = "Success";
      }
      var successErrorMask = (
        <div className={"flex11a displayFlexColumnHcVc " + successErrorBgClass + " tbMicroPad lrPad textCenter"}>
          <font className={successErrorFontClass}>
            {successErrorMessage}
          </font>
        </div>
      );
      expandedIntegrationsChangelogObj.successErrorMask = successErrorMask;

      expandedIntegrationsChangelogArrayOfObjs.push(expandedIntegrationsChangelogObj);
    }

    //sort log entries by date newest on top
    JSFUNC.sort_arrayOfObjs(expandedIntegrationsChangelogArrayOfObjs, "datetime_utc", false);

    return(expandedIntegrationsChangelogArrayOfObjs);
  }

  get c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs() {
    const o_integrationsChangelogFilterObj = this.o_integrationsChangelogFilterObj; //numItemsPerPage, currentPageNumber, dateMin, dateMax, userID, actionDbNamesComma, ceCaptureIDsComma, integrationUniqueIDSearchText, ceFieldIDsComma, integrationFieldDbNamesComma, sortColumnDbName, sortIsAscTF
    const c_expandedIntegrationsChangelogArrayOfObjs = this.c_expandedIntegrationsChangelogArrayOfObjs;
    
    const filterDateMinIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_integrationsChangelogFilterObj.dateMin);
    const filterDateMaxIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_integrationsChangelogFilterObj.dateMax);
    const filterUserIsFilledOutTF = JSFUNC.select_int_is_filled_out_tf(o_integrationsChangelogFilterObj.userID);
    const filterActionIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_integrationsChangelogFilterObj.actionDbNamesComma);
    const filterCECapturesIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_integrationsChangelogFilterObj.ceCaptureIDsComma);
    const filterIntegrationUniqueIDSearchIsFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(o_integrationsChangelogFilterObj.integrationUniqueIDSearchText);
    const filterCEFieldsIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_integrationsChangelogFilterObj.ceFieldIDsComma);
    const filterIntegrationFieldsIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(o_integrationsChangelogFilterObj.integrationFieldDbNamesComma);

    const selectedFilterActionDbNamesArray = JSFUNC.convert_comma_list_to_array(o_integrationsChangelogFilterObj.actionDbNamesComma);
    const selectedFilterCECaptureIDsArray = JSFUNC.convert_comma_list_to_int_array(o_integrationsChangelogFilterObj.ceCaptureIDsComma);
    const integrationUniqueIDSearchTextLowercase = o_integrationsChangelogFilterObj.integrationUniqueIDSearchText.toLowerCase();
    const selectedFilterCEFieldIDsArray = JSFUNC.convert_comma_list_to_int_array(o_integrationsChangelogFilterObj.ceFieldIDsComma);
    const selectedFilterIntegrationFieldDbNamesArray = JSFUNC.convert_comma_list_to_array(o_integrationsChangelogFilterObj.integrationFieldDbNamesComma);

    //filter the changelog items
    var changelogFilteredSortedItemsArrayOfObjs = [];
    for(let expandedIntegrationsChangelogObj of c_expandedIntegrationsChangelogArrayOfObjs) {
      var includeChangelogItemTF = true;

      if(includeChangelogItemTF && filterDateMinIsFilledOutTF && (expandedIntegrationsChangelogObj.dateLocalYmd < o_integrationsChangelogFilterObj.dateMin)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterDateMaxIsFilledOutTF && (expandedIntegrationsChangelogObj.dateLocalYmd > o_integrationsChangelogFilterObj.dateMax)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterUserIsFilledOutTF && (expandedIntegrationsChangelogObj.user_id !== o_integrationsChangelogFilterObj.userID)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterActionIsFilledOutTF && !JSFUNC.in_array(expandedIntegrationsChangelogObj.iud_action_string, selectedFilterActionDbNamesArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterCECapturesIsFilledOutTF && !JSFUNC.in_array(expandedIntegrationsChangelogObj.ce_capture_id, selectedFilterCECaptureIDsArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterIntegrationUniqueIDSearchIsFilledOutTF && !JSFUNC.input_string_converted_to_lowercase_contains_lowercase_search_term_string_tf(expandedIntegrationsChangelogObj.integration_unique_id_string, integrationUniqueIDSearchTextLowercase)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterCEFieldsIsFilledOutTF && !JSFUNC.in_array(expandedIntegrationsChangelogObj.ce_field_id, selectedFilterCEFieldIDsArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF && filterIntegrationFieldsIsFilledOutTF && !JSFUNC.in_array(expandedIntegrationsChangelogObj.integration_field_db_name, selectedFilterIntegrationFieldDbNamesArray)) {
        includeChangelogItemTF = false;
      }

      if(includeChangelogItemTF) {
        changelogFilteredSortedItemsArrayOfObjs.push(expandedIntegrationsChangelogObj);
      }
    }

    //for sort, if the column being sorted is dateTimeLocalMask, use the raw datetime value datetime_utc instead
    var selectedSortColumnDbName = o_integrationsChangelogFilterObj.sortColumnDbName;
    if(o_integrationsChangelogFilterObj.sortColumnDbName === "dateTimeLocalMask") {
      selectedSortColumnDbName = "datetime_utc";
    }

    //sort the changelog items
    var sortColumnDbNamesArray = [selectedSortColumnDbName];
    var sortIsAscTFArray = [o_integrationsChangelogFilterObj.sortIsAscTF];
    if(o_integrationsChangelogFilterObj.sortColumnDbName !== "dateTimeLocalMask") { //if sorting by a column other than date
      sortColumnDbNamesArray.push("datetime_utc"); //do a secondary sort by date desc (so that sorting by field groups all fields together sorted by latest on top)
      sortIsAscTFArray.push(false);
      sortColumnDbNamesArray.push("id"); //tertiary sort on log id
      sortIsAscTFArray.push(false);
    }
    else { //primary sort is dateTimeLocalMask, add a secondary sort in the same direction for id
      sortColumnDbNamesArray.push("id");
      sortIsAscTFArray.push(o_integrationsChangelogFilterObj.sortIsAscTF);
    }
    JSFUNC.sort_arrayOfObjs(changelogFilteredSortedItemsArrayOfObjs, sortColumnDbNamesArray, sortIsAscTFArray);

    return(changelogFilteredSortedItemsArrayOfObjs);
  }

  get c_integrationsChangelogFilteredTotalNumItems() {
    const c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs = this.c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs;
    return(c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs.length);
  }

  get c_integrationsChangelogCurrentPageFirstItemNumber() {
    const o_integrationsChangelogFilterObj = this.o_integrationsChangelogFilterObj;
    const c_integrationsChangelogFilteredTotalNumItems = this.c_integrationsChangelogFilteredTotalNumItems;

    if(c_integrationsChangelogFilteredTotalNumItems === 0) {
      return(0);
    }
    return(((o_integrationsChangelogFilterObj.currentPageNumber - 1) * o_integrationsChangelogFilterObj.numItemsPerPage) + 1);
  }

  get c_integrationsChangelogCurrentPageLastItemNumber() {
    const o_integrationsChangelogFilterObj = this.o_integrationsChangelogFilterObj;
    const c_integrationsChangelogFilteredTotalNumItems = this.c_integrationsChangelogFilteredTotalNumItems;

    var changelogCurrentPageLastItemNumber = (o_integrationsChangelogFilterObj.currentPageNumber * o_integrationsChangelogFilterObj.numItemsPerPage);
    if(c_integrationsChangelogFilteredTotalNumItems < changelogCurrentPageLastItemNumber) {
      changelogCurrentPageLastItemNumber = c_integrationsChangelogFilteredTotalNumItems;
    }
    return(changelogCurrentPageLastItemNumber);
  }

  get c_integrationsChangelogCanIncrementCurrentPageNumberTF() {
    const c_integrationsChangelogFilteredTotalNumItems = this.c_integrationsChangelogFilteredTotalNumItems;
    const c_integrationsChangelogCurrentPageLastItemNumber = this.c_integrationsChangelogCurrentPageLastItemNumber;

    return(c_integrationsChangelogCurrentPageLastItemNumber < c_integrationsChangelogFilteredTotalNumItems);
  }

  get c_integrationsChangelogCanDecrementCurrentPageNumberTF() {
    const o_integrationsChangelogFilterObj = this.o_integrationsChangelogFilterObj;
    return(o_integrationsChangelogFilterObj.currentPageNumber > 1);
  }

  get c_filteredSortedItemsOnSelectedPageExpandedIntegrationsChangelogArrayOfObjs() {
    const c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs = this.c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs;
    const c_integrationsChangelogCurrentPageFirstItemNumber = this.c_integrationsChangelogCurrentPageFirstItemNumber;
    const c_integrationsChangelogCurrentPageLastItemNumber = this.c_integrationsChangelogCurrentPageLastItemNumber;

    var changelogFilteredSortedItemsOnSelectedPageArrayOfObjs = [];
    var itemNumber = 1;
    for(let expandedIntegrationsChangelogObj of c_filteredSortedExpandedIntegrationsChangelogArrayOfObjs) {
      if((itemNumber >= c_integrationsChangelogCurrentPageFirstItemNumber) && (itemNumber <= c_integrationsChangelogCurrentPageLastItemNumber)) {
        changelogFilteredSortedItemsOnSelectedPageArrayOfObjs.push(expandedIntegrationsChangelogObj);
      }
      itemNumber++;
    }
    return(changelogFilteredSortedItemsOnSelectedPageArrayOfObjs);
  }

  get c_selectIntegrationsLogActionsFieldTypeObj() {
    const k_integrationsLogActionsArrayOfObjs = this.k_integrationsLogActionsArrayOfObjs;

    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:false, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("Action", k_integrationsLogActionsArrayOfObjs, "dbName", true, "displayName", swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiIntegrationFieldDbNamesFieldTypeObj() {
    const c_linkedFieldsWithValuesArrayOfObjs = this.c_linkedFieldsWithValuesArrayOfObjs;

    const allIntegrationFieldDbNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(c_linkedFieldsWithValuesArrayOfObjs, "integration_field_db_name");
    const uniqueIntegrationFieldDbNamesArray = JSFUNC.unique(allIntegrationFieldDbNamesArray);
    JSFUNC.sort_array(uniqueIntegrationFieldDbNamesArray, true, true); //sort fieldDbNames alphabetic case insensitive
    const valueDisplayArraysObjIntegrationsChangelogCategories = DatabaseMobx.create_value_display_arrays_obj_from_value_array_and_display_array("Integration Field Name", uniqueIntegrationFieldDbNamesArray, true, uniqueIntegrationFieldDbNamesArray);
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjIntegrationsChangelogCategories, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }





  a_set_selected_tab_db_name(i_tabDbName) {
    this.o_selectedTabDbName = i_tabDbName;
  }

  a_set_setup_verify_api_credentials_selected_credentials_row_id_or_m1(i_newValueInt) {
    this.o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1 = i_newValueInt;
  }

  a_set_setup_verify_api_credentials_flag(i_newValueString) {
    this.o_setupVerifyApiCredentialsFlag = i_newValueString;
  }

  a_setup_verify_api_credentials() {
    const o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1 = this.o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1;

    this.a_set_setup_verify_api_credentials_flag("working");

    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_setup_verify_api_credentials", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("integrationsFetchSingleOppByIDString", jsDescription);
    C_CallPhpScript.add_post_var("i_integrationsCredentialsTblRowID", o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1);
    C_CallPhpScript.add_post_var("i_integrationOppIDString", "");
    C_CallPhpScript.add_return_vars(["integrationOppsJsonString", "integrationErrorMessage"]);

    const functionOnSuccess = (i_parseResponse) => {
      if(i_parseResponse.integrationErrorMessage === "") { //no error from test API fetch
        this.a_set_setup_verify_api_credentials_flag("verified");
      }
      else {
        this.a_set_setup_verify_api_credentials_flag(i_parseResponse.integrationErrorMessage);
      }
      
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      this.a_set_setup_verify_api_credentials_flag("ceError");
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();
  }

  a_set_mapping_example_fields_panel_open_tf(i_newValueTF) {
    this.o_mappingExampleFieldsPanelOpenTF = i_newValueTF;
  }

  a_set_mapping_example_fields_integration_opp_id_string(i_newValueString) {
    this.o_mappingExampleFieldsIntegrationOppIDString = i_newValueString;
  }

  a_set_mapping_example_fields_flag(i_newValueString) {
    this.o_mappingExampleFieldsFlag = i_newValueString;
  }

  a_set_mapping_example_fields_fetched_integration_opps_json_string(i_newValueString) {
    this.o_mappingExampleFieldsFetchedIntegrationOppsJsonString = i_newValueString;
  }

  a_mapping_example_fields_search_for_integration_opp_by_id_string(i_integrationOppIDString) {
    const o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1 = this.o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1;

    this.a_set_mapping_example_fields_flag("working");

    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_mapping_example_fields_search_for_integration_opp_by_id_string", ["i_integrationOppIDString"], [i_integrationOppIDString]);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("integrationsFetchSingleOppByIDString", jsDescription);
    C_CallPhpScript.add_post_var("i_integrationsCredentialsTblRowID", o_setupVerifyApiCredentialsSelectedCredentialsRowIDOrM1);
    C_CallPhpScript.add_post_var("i_integrationOppIDString", i_integrationOppIDString);
    C_CallPhpScript.add_return_vars(["integrationOppsJsonString", "integrationErrorMessage"]);

    const functionOnSuccess = (i_parseResponse) => {
      if(i_parseResponse.integrationErrorMessage === "") {
        this.a_set_mapping_example_fields_fetched_integration_opps_json_string(i_parseResponse.integrationOppsJsonString);
        this.a_set_mapping_example_fields_flag("verified");
      }
      else {
        this.a_set_mapping_example_fields_flag(i_parseResponse.integrationErrorMessage);
      }
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnError = () => {
      this.a_set_mapping_example_fields_flag("ceError");
    }
    C_CallPhpScript.add_function("onError", functionOnError);

    C_CallPhpScript.execute();
  }


  a_create_new_integration_linked_fields_pairing_from_integration_field_db_name(i_integrationFieldDbName) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_create_new_integration_linked_fields_pairing_from_integration_field_db_name", ["i_integrationFieldDbName"], [i_integrationFieldDbName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["sort", "integration_field_db_name", "capture_field_id"];
    const valuesArray = [JSFUNC.sort_max_mysqli_int(), i_integrationFieldDbName, -1];
    const idsbArray = ["i", "s", "i"];
    const resortSortColumnName = "sort";
    const resortFilterFieldNamesArray = [];
    const resortFilterValuesArray = [];
    C_CallPhpTblUID.add_insert("tbl_a_integrations_linked_fields", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNamesArray, resortFilterValuesArray);

    C_CallPhpTblUID.execute();
  }


  a_update_linked_fields_field(i_linkedFieldWithValuesObj, i_fieldDbName, i_newValue, i_idsb) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_update_linked_fields_field", ["i_linkedFieldWithValuesObj", "i_fieldDbName", "i_newValue", "i_idsb"], [i_linkedFieldWithValuesObj, i_fieldDbName, i_newValue, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_integrations_linked_fields", i_linkedFieldWithValuesObj.id, i_fieldDbName, i_newValue, i_idsb);
    C_CallPhpTblUID.execute();
  }


  a_delete_linked_fields_and_all_mapped_values(i_linkedFieldWithValuesObj) {
    var mappedValuesIDsToDeleteArray = [];
    if(JSFUNC.is_array(i_linkedFieldWithValuesObj.mappedValuesArrayOfObjs)) {
      mappedValuesIDsToDeleteArray = JSFUNC.get_column_vector_from_arrayOfObjs(i_linkedFieldWithValuesObj.mappedValuesArrayOfObjs, "id");
    }

    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_delete_linked_fields_and_all_mapped_values", ["i_linkedFieldWithValuesObj"], [i_linkedFieldWithValuesObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    if(mappedValuesIDsToDeleteArray.length > 0) {
      C_CallPhpTblUID.add_delete("tbl_a_integrations_linked_field_values", mappedValuesIDsToDeleteArray);
    }

    C_CallPhpTblUID.add_delete("tbl_a_integrations_linked_fields", i_linkedFieldWithValuesObj.id, "sort", [], []);

    C_CallPhpTblUID.execute();
  }


  a_create_new_value_pair_under_field_pairing(p_linkedFieldWithValuesObj, i_newIntegrationValueString) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_create_new_value_pair_under_field_pairing", ["p_linkedFieldWithValuesObj", "i_newIntegrationValueString"], [p_linkedFieldWithValuesObj, i_newIntegrationValueString]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["sort", "linked_field_id", "integration_value_string", "capture_field_value_string", "extra_capture_field_value_strings_comma"];
    const valuesArray = [JSFUNC.sort_max_mysqli_int(), p_linkedFieldWithValuesObj.id, i_newIntegrationValueString, "", ""];
    const idsbArray = ["i", "i", "s", "s", "s"];
    const resortSortColumnName = "sort";
    const resortFilterFieldNamesArray = ["linked_field_id"];
    const resortFilterValuesArray = [p_linkedFieldWithValuesObj.id];
    C_CallPhpTblUID.add_insert("tbl_a_integrations_linked_field_values", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNamesArray, resortFilterValuesArray);

    C_CallPhpTblUID.execute();
  }


  a_update_linked_field_values_field(i_mappedValueObj, i_fieldDbName, i_newValue, i_idsb) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_update_linked_fields_field", ["i_mappedValueObj", "i_fieldDbName", "i_newValue", "i_idsb"], [i_mappedValueObj, i_fieldDbName, i_newValue, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_integrations_linked_field_values", i_mappedValueObj.id, i_fieldDbName, i_newValue, i_idsb);
    C_CallPhpTblUID.execute();
  }


  a_delete_mapped_values_pair(i_mappedValueObj) {
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "a_delete_mapped_values_pair", ["i_mappedValueObj"], [i_mappedValueObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_integrations_linked_field_values", i_mappedValueObj.id, "sort", ["linked_field_id"], [i_mappedValueObj.linked_field_id]);
    C_CallPhpTblUID.execute();
  }


  a_set_mapping_collapsed_linked_field_ids_array(i_newValueArray) {
    this.o_mappingCollapsedLinkedFieldIDsArray = i_newValueArray;
  }
  a_mapping_expand_all_linked_fields() {
    this.o_mappingCollapsedLinkedFieldIDsArray = [];
  }
  a_mapping_collapse_all_linked_fields() {
    const c_linkedFieldsWithValuesArrayOfObjs = this.c_linkedFieldsWithValuesArrayOfObjs;
    this.o_mappingCollapsedLinkedFieldIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(c_linkedFieldsWithValuesArrayOfObjs, "id");
  }





  a_integrations_changelog_set_loading_data_or_error_undefined_tfu(i_newValueTFU) {
    this.o_integrationsChangelogLoadingDataOrErrorUndefinedTFU = i_newValueTFU;
  }


  a_load_integrations_changelog_data() {
    const o_isLocalhost3000SystemTF = CaptureExecMobx.o_isLocalhost3000SystemTF;
    const c_adminIntegrationsChangelogTblNamesToLoadArray = DatabaseMobx.c_adminIntegrationsChangelogTblNamesToLoadArray;

    if(!o_isLocalhost3000SystemTF) {
      this.a_integrations_changelog_set_loading_data_or_error_undefined_tfu(true);

      const functionOnFinish = (i_successTrueErrorFalse) => {
        const updatedLoadingDataOrErrorUndefinedTFU = ((i_successTrueErrorFalse) ? (false) : (undefined));
        this.a_integrations_changelog_set_loading_data_or_error_undefined_tfu(updatedLoadingDataOrErrorUndefinedTFU);
      }
      JSPHP.php_db_api_load_multiple_full_database_tbls_from_tbl_names_array_and_uncompress_and_update_local_memory_maps(c_adminIntegrationsChangelogTblNamesToLoadArray, functionOnFinish);
    }
  }


  a_set_integrations_changelog_filter_field(i_integrationsChangelogFilterField, i_newValue) {
    //numItemsPerPage, currentPageNumber, dateMin, dateMax, userID, actionDbNamesComma, ceCaptureIDsComma, integrationUniqueIDSearchText, ceFieldIDsComma, integrationFieldDbNamesComma, sortColumnDbName, sortIsAscTF
    this.o_integrationsChangelogFilterObj[i_integrationsChangelogFilterField] = i_newValue;
  }







  
  
  
  //----------------------------------------------------------------------------------------------------------------------------------------------------------
  //3rd Party Integration system Capture insert/update/delete functions (js-php calls to integration system API, followed by CE integration changelog entry inserts)
  //----------------------------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------
  //--------- [Insert] 3rd Party Integration system ---------
  //---------------------------------------------------------
  integration_insert_integration_new_opp_with_newly_created_unique_id_string_from_new_ce_capture_id(i_newCECaptureID, i_newCaptureAllCECaptureFieldIDsArray, i_newCaptureAllCERawValuesArray) {
    //alert("integration insert [" + i_newCECaptureID + "]:\nfieldIDs: " + JSFUNC.print_array(i_newCaptureAllCECaptureFieldIDsArray) + "\nvalues: " + JSFUNC.print_array(i_newCaptureAllCERawValuesArray));

    //called for all 3 new capture creation sources: 1) CE Create New Capture, 2) CE GCSS Import, 3) CE Admin Import from .csv
    //  i_newCECaptureID - new capture id autoincrement from insert into tbl_captures
    //  i_newCaptureAllCECaptureFieldIDsArray - every capture fieldID from newly inserted capture (filled out or blank) except `id` [copmuted in DatabaseMobx.create_new_capture_field_names_values_idsb_and_log_arrays_obj_from_new_capture_obj()]
    //  i_newCaptureAllCERawValuesArray - every raw value for each fieldID

    const c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined = this.c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined;
    const c_linkedFieldsWithValuesArrayOfObjs = this.c_linkedFieldsWithValuesArrayOfObjs;
    const k_cardIDUserCreateNewCapture = DatabaseMobx.k_cardIDUserCreateNewCapture;
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;
    const c_companyIntegrationLogRecordSuccessesTF = DatabaseMobx.c_companyIntegrationLogRecordSuccessesTF;
    const c_companyIntegrationIntegrationUniqueIDCEFieldID = DatabaseMobx.c_companyIntegrationIntegrationUniqueIDCEFieldID;
    const c_fieldMapOfDivisionOwners = DatabaseMobx.c_fieldMapOfDivisionOwners;
    const c_userSyncUserCEActionsInIntegrationSystemTF = UserMobx.c_userSyncUserCEActionsInIntegrationSystemTF;

    //3rd Party Integration is turned on in BIT and the Admin and in the User Panel Integration Settings
    if(!(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF && c_userSyncUserCEActionsInIntegrationSystemTF)) {
      return;
    }

    //no need to calculate anything or attempt to make an insert if there are 0 linked pairs created on the 3rd Party Integrations 'Mapping' subtab, the new capture will find 0 matches and not make an insert anyway
    if(c_linkedFieldsWithValuesArrayOfObjs.length === 0) {
      return;
    }

    //if this CE field is not filled out on the Setup tab (required to be filled out to insert/update integration system)
    if(c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined === undefined) {
      return;
    }

    const integrationSystem = "vantagepoint";
    
    const divisionOwnersFieldID = c_fieldMapOfDivisionOwners.get("id");

    //find the raw value for division owners in the fieldID/value input arrays (use a division owners blank value if it can't be found)
    var newCECaptureDivisionIDsColonPercentsCommaOrUndefined = JSFUNC.get_first_array2_value_or_undefined_where_array1_matches_input(divisionOwnersFieldID, i_newCaptureAllCECaptureFieldIDsArray, i_newCaptureAllCERawValuesArray);
    if(newCECaptureDivisionIDsColonPercentsCommaOrUndefined === undefined) {
      newCECaptureDivisionIDsColonPercentsCommaOrUndefined = c_fieldMapOfDivisionOwners.get("fieldBlankValue");
    }

    //determine the credentials to use based on the capture highest percent division owner (or user division)
    const integrationsCredentialsRowIDOrM1 = this.determine_integrations_credentials_row_id_or_m1_from_ce_capture_division_owners_ids_colon_percent_comma(newCECaptureDivisionIDsColonPercentsCommaOrUndefined);
    if(!JSFUNC.is_number_not_nan_gt_0(integrationsCredentialsRowIDOrM1)) { //if credentials could not be found matching capture division or user division, don't perform the insert and don't create an integrations log entry
      return;
    }

    //create the new integration unique ID string based on the new CE captureID
    const newIntegrationOppUniqueIDString = this.create_new_integration_unique_id_string_from_new_ce_capture_id(i_newCECaptureID, newCECaptureDivisionIDsColonPercentsCommaOrUndefined);
    
    //make a copy of the raw CE values array to replace the blank integration unique ID with this computed one below
    var newCaptureAllCERawValuesWithNewIntegrationOppUniqueIDStringArray = JSFUNC.copy_array(i_newCaptureAllCERawValuesArray);

    //find the CE field (matched from CE captureFieldID) representing the integration unique ID field (filled out on the 3rd Party Integration 'Setup' subtab)
    for(let f = 0; f < i_newCaptureAllCECaptureFieldIDsArray.length; f++) {
      if(i_newCaptureAllCECaptureFieldIDsArray[f] === c_companyIntegrationIntegrationUniqueIDCEFieldID) {
        newCaptureAllCERawValuesWithNewIntegrationOppUniqueIDStringArray[f] = newIntegrationOppUniqueIDString;
      }
    }

    //gather any integration field default values turned on for any integration fields on the 3rd Party Integrations Mapping tab to pass to the js-php insert in addition to the list of mapped CE fields
    var integrationFieldDbNamesWithDefaultValuesArrayOfObjs = [];
    for(let linkedFieldWithValuesObj of c_linkedFieldsWithValuesArrayOfObjs) {
      if(linkedFieldWithValuesObj.integrationFieldUsingDefaultValueTF) {
        integrationFieldDbNamesWithDefaultValuesArrayOfObjs.push({
          integrationFieldDbName: linkedFieldWithValuesObj.integration_field_db_name,
          defaultValueString: linkedFieldWithValuesObj.integration_field_default_value_string
        })
      }
    }
  
    //create the new opp in the integration system, defining the translated fields/values (from CE to Integration) and calling a php POST to make the insert (send this command off without waiting to see if it was successful)
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "integration_insert_integration_new_opp_with_newly_created_unique_id_string_from_new_ce_capture_id", ["i_newCECaptureID", "i_newCaptureAllCECaptureFieldIDsArray", "i_newCaptureAllCERawValuesArray"], [i_newCECaptureID, i_newCaptureAllCECaptureFieldIDsArray, i_newCaptureAllCERawValuesArray]);
  
    //changelog values
    const iudActionString = "iCEiIN";
    const changelogCaptureFieldID = -1;
    const changelogIntegrationFieldDbName = "{Insert New Opportunity}";
    var changelogNewCEFieldAndValueStringsArray = [];
    var changelogIntegrationNewFieldAndValueStringsArray = [];
  
    //intialize array to collect integration new field/value pairs converted from CE for this insert
    var integrationFieldsValuesArrayOfObjs = [];
    var debugStringsArray = [];
  
    //loop to assign the following new CE capture values (mapped equivalent value if necessary) to their integration fieldDbNames:
    //  "key"/"WBSNumber"/"WBS1" (with newIntegrationOppUniqueIDString)
    //  "Name"/"LongName"/"Level1Name" (with CE opportunity_name)
    //  "Stage" (with CE stage_id mapped integration value)
    //  "Org" (with CE division_owners_ids_colon_percent_comma mapped integration value)
    //plus all other CE fields found in i_newCaptureAllCECaptureFieldIDsArray (either the few standard fields from Create New Capture, or many more fields defined from a Capture Import)
    for(let f = 0; f < i_newCaptureAllCECaptureFieldIDsArray.length; f++) { //loop through every field/value pair within i_newCaptureAllCECaptureFieldIDsArray/newCaptureAllCERawValuesWithNewIntegrationOppUniqueIDStringArray (all fields filled out in create new capture process)
      var ceFieldID = i_newCaptureAllCECaptureFieldIDsArray[f];
      var ceNewValueRaw = newCaptureAllCERawValuesWithNewIntegrationOppUniqueIDStringArray[f];
  
      //match the CE field to 0, 1, or multiple linked integration fields, then use the new CE value, or translate through a matching mapped value
      var singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj = this.integration_find_all_integration_field_db_names_and_mapped_values_matching_ce_field_id_and_ce_value_raw(iudActionString, i_newCECaptureID, ceFieldID, ceNewValueRaw, newIntegrationOppUniqueIDString)
      var singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs = singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj.singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs;
      var ceFieldDebugString = singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj.ceFieldDebugString;
  
      //loop over all linked pair(s) for this CE fieldID (should be 0 or 1 matches, but can be multiple integration fields linked to the same CE field)
      for(let matchingIntegrationFieldValueObj of singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs) {
        //add matching linked pair(s) from single CE fieldID to collection of all CE fields
        integrationFieldsValuesArrayOfObjs.push(matchingIntegrationFieldValueObj);
  
        //record changelog pieces for CE and integration for this one field that will be inserted into the integration new opp
        changelogNewCEFieldAndValueStringsArray.push(matchingIntegrationFieldValueObj.ceFieldDisplayName + ": '" + matchingIntegrationFieldValueObj.ceNewValueChangelogString + "'");
        changelogIntegrationNewFieldAndValueStringsArray.push(matchingIntegrationFieldValueObj.integrationFieldDbName + ": '" + matchingIntegrationFieldValueObj.integrationValueString + "'");
      }
  
      //add debug string from single CE fieldID to array (include f index number)
      debugStringsArray.push(f + " - " + ceFieldDebugString);
    }
  
    //if integration fields that have default values were provided as an arrayOfObjs input, add those here (that are not defined with real values in the mappings above) to the full list on integration insert field dbNames/values
    if(JSFUNC.is_array(integrationFieldDbNamesWithDefaultValuesArrayOfObjs)) {
      for(let integrationFieldDbNameWithDefaultValueObj of integrationFieldDbNamesWithDefaultValuesArrayOfObjs) { //loop through each integration field with a default value defined
        var matchingIntegrationFieldsValuesObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(integrationFieldsValuesArrayOfObjs, "integrationFieldDbName", integrationFieldDbNameWithDefaultValueObj.integrationFieldDbName);
        if(matchingIntegrationFieldsValuesObjOrUndefined === undefined) { //if the field with a set default value does not already exist in the full list of mapped fields with set values, append this field with its default value
          integrationFieldsValuesArrayOfObjs.push({
            integrationFieldDbName: integrationFieldDbNameWithDefaultValueObj.integrationFieldDbName,
            integrationValueString: integrationFieldDbNameWithDefaultValueObj.defaultValueString
          });
  
          //add debug string from single CE fieldID to array (include f index number)
          debugStringsArray.push("##default - " + ceFieldDebugString + "##");
        }
      }
    }
  
    //count the total fields to be inserted into the integration system
    const numIntegrationFieldValuePairs = integrationFieldsValuesArrayOfObjs.length;
  
    //create the changelog strings
    const changelogNewCEValueString = changelogNewCEFieldAndValueStringsArray.join("\n");
    const changelogIntegrationNewValueString = changelogIntegrationNewFieldAndValueStringsArray.join("\n");
  
    //build up to call the php that inserts an integration system opp with success/error functions for integration log entries
    const C_CallPhpScriptInsertIntegrationOpp = new JSPHP.ClassCallPhpScript("integrationInsertNewIntegrationOpp", jsDescription);
  
    C_CallPhpScriptInsertIntegrationOpp.add_post_var("i_integrationsCredentialsTblRowID", integrationsCredentialsRowIDOrM1);
    C_CallPhpScriptInsertIntegrationOpp.add_post_var("i_integrationSystem", integrationSystem);
    C_CallPhpScriptInsertIntegrationOpp.add_post_var("i_numIntegrationFieldValuePairs", numIntegrationFieldValuePairs);
    for(let i = 0; i < numIntegrationFieldValuePairs; i++) {
      var postVarFieldDbNameString = "i_integrationFieldDbName" + i;
      var postVarValueString = "i_integrationValueString" + i;
      var integrationFieldValueObj = integrationFieldsValuesArrayOfObjs[i];
      C_CallPhpScriptInsertIntegrationOpp.add_post_var(postVarFieldDbNameString, integrationFieldValueObj.integrationFieldDbName);
      C_CallPhpScriptInsertIntegrationOpp.add_post_var(postVarValueString, integrationFieldValueObj.integrationValueString);
    }
  
    C_CallPhpScriptInsertIntegrationOpp.add_return_vars("integrationErrorMessageOrBlankSuccess");
  
    const functionOnSuccessIntegrationInsert = (i_parseResponse) => {
      var integrationChangelogErrorMessage = ""; //blank changelog error message value for success
      if(i_parseResponse.integrationErrorMessageOrBlankSuccess !== "") {
        integrationChangelogErrorMessage = i_parseResponse.integrationErrorMessageOrBlankSuccess;
      }
  
      if(c_companyIntegrationLogRecordSuccessesTF || (integrationChangelogErrorMessage !== "")) {
        this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_newCECaptureID, newIntegrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, integrationChangelogErrorMessage);
      }
    }
    C_CallPhpScriptInsertIntegrationOpp.add_function("onSuccess", functionOnSuccessIntegrationInsert);
  
    const functionOnErrorIntegrationInsert = () => {
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_newCECaptureID, newIntegrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, "Unexpected issue handling the request");
    }
    C_CallPhpScriptInsertIntegrationOpp.add_function("onError", functionOnErrorIntegrationInsert);
  
    C_CallPhpScriptInsertIntegrationOpp.execute();

    //record_z_error(jsDescription, debugStringsArray.join("\n"));

    //update the new capture record with the new integration unique ID value (overwrite the original blank value from the new capture creation), also a changelog entry for it (no changelog entry created originally as the value was blank)
    const sendNotificationTF = false; //only capture/contract manager field updates send notifications, set it false anyway
    const updateIntegrationSystemLinkedFieldTF = false; //do not update the integration system with the integration unique ID since it was already included when the new opp was created in the integration system integration_insert_integration_new_opp_with_newly_created_unique_id_string_from_new_ce_capture_id(), without it the new opp could not be created, and don't want to update with the same value twice
    OpenCaptureMobx.a_details_update_field_value(c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined, newIntegrationOppUniqueIDString, i_newCECaptureID, k_cardIDUserCreateNewCapture, sendNotificationTF, updateIntegrationSystemLinkedFieldTF, undefined, undefined);
  }


  //---------------------------------------------------------
  //--------- [Update] 3rd Party Integration system ---------
  //---------------------------------------------------------
  integration_update_integration_opp_field_if_ce_field_is_paired(i_ceCaptureID, i_ceFieldID, i_ceNewValueRaw) {
    //alert("integration update [" + i_ceCaptureID + "]:\nfield: " + JSFUNC.print(i_ceFieldID) + "\nvalue: " + JSFUNC.print(i_ceNewValueRaw));
    
    const c_integrationSystemDisplayName = this.c_integrationSystemDisplayName;
    const c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined = this.c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined;
    const c_linkedFieldsWithValuesArrayOfObjs = this.c_linkedFieldsWithValuesArrayOfObjs;
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const o_tbl_captures = DatabaseMobx.o_tbl_captures;
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;
    const c_companyIntegrationLogRecordSuccessesTF = DatabaseMobx.c_companyIntegrationLogRecordSuccessesTF;
    const c_fieldMapOfCaptureID = DatabaseMobx.c_fieldMapOfCaptureID;
    const c_fieldMapOfDivisionOwners = DatabaseMobx.c_fieldMapOfDivisionOwners;
    const c_userSyncUserCEActionsInIntegrationSystemTF = UserMobx.c_userSyncUserCEActionsInIntegrationSystemTF;

    //3rd Party Integration is turned on in BIT and the Admin
    if(!(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF && c_userSyncUserCEActionsInIntegrationSystemTF)) {
      return;
    }
  
    //no need to calculate anything or attempt to make an insert if there are 0 linked pairs created on the 3rd Party Integrations 'Mapping' subtab, the new capture will find 0 matches and not make an insert anyway
    if(c_linkedFieldsWithValuesArrayOfObjs.length === 0) {
      return;
    }
  
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "integration_update_integration_opp_field_if_ce_field_is_paired", ["i_ceCaptureID", "i_ceFieldID", "i_ceNewValueRaw"], [i_ceCaptureID, i_ceFieldID, i_ceNewValueRaw]);
  
    //initialize that this integration unique ID string is unknown until the verification below can load the value from the capture
    const iudActionString = "uCEuIN";
    var integrationOppUniqueIDString = "--";
    var changelogIntegrationFieldDbName = "--";
    var changelogNewCEValueString = "--";
    var changelogIntegrationNewValueString = "--";
  
    //check that the i_ceCaptureID provided is a valid CE capture (need this to extract the existing integration unique ID from the CE capture)
    const captureMapOrUndefined = o_tbl_captures.get(i_ceCaptureID);
    if(captureMapOrUndefined === undefined) {
      const captureIDNotAValidCaptureErrorMessage = c_productStylingObj.productName + " " + c_fieldMapOfCaptureID.get("display_name") + " '" + i_ceCaptureID + "' is not a valid capture";
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, captureIDNotAValidCaptureErrorMessage);
      return;
    }

    //determine the credentials to use based on the capture highest percent division owner (or user division)
    const ceCaptureDivisionOwnersIDsColonPercentCommaOrUndefined = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_fieldMapOfDivisionOwners);
    const integrationsCredentialsRowIDOrM1 = this.determine_integrations_credentials_row_id_or_m1_from_ce_capture_division_owners_ids_colon_percent_comma(ceCaptureDivisionOwnersIDsColonPercentCommaOrUndefined);
    if(!JSFUNC.is_number_not_nan_gt_0(integrationsCredentialsRowIDOrM1)) { //if credentials could not be found matching capture division or user division, don't perform the insert and don't create an integrations log entry
      return;
    }
  
    //verify if all Setup subtab fields are filled out
    const setupErrorMessageOrUndefined = this.integration_verify_all_setup_and_mapping_is_filled_out_undefined_or_error_message_string();
    if(setupErrorMessageOrUndefined !== undefined) { //an APi Connection or Unique ID field is not filled out, write an integration log message
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, setupErrorMessageOrUndefined);
      return;
    }
  
    //load the integration unique ID from this CE capture using the input i_ceCaptureID loaded captureMap
    const ceIntegrationUniqueIDValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined, true); //plainTextTF is true
    if(!ceIntegrationUniqueIDValueMaskSortIfoObj.isFilledOutTF) { //if this CE capture does not have the integration unique ID field filled out
      const ceIntegrationUniqueIDFieldIsNotFilledOutErrorMessage = c_productStylingObj.productName +  " Capture Field '" + c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined.get("display_name") + "' is not filled out with the " + c_integrationSystemDisplayName + " unique ID that links this opportunity across the 2 systems";
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, ceIntegrationUniqueIDFieldIsNotFilledOutErrorMessage);
      return;
    }
  
    //get the CE integration opp uniqueID string stored in the CE capture linking the CE capture to the integration system opp
    integrationOppUniqueIDString = ceIntegrationUniqueIDValueMaskSortIfoObj.valueMaskPlainText;
  
    //match the CE field to 0, 1, or multiple linked integration fields, then use the new CE value, or translate through a matching mapped value
    var singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj = this.integration_find_all_integration_field_db_names_and_mapped_values_matching_ce_field_id_and_ce_value_raw(iudActionString, i_ceCaptureID, i_ceFieldID, i_ceNewValueRaw, integrationOppUniqueIDString);
    var singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs = singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj.singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs;
    var ceFieldDebugString = singleCEFieldIDMatchingIntegrationFieldsValuesAndDebugObj.ceFieldDebugString;
    //alert(ceFieldDebugString);
  
    //if 0 linked pairs matched the input i_ceFieldID, do not make an update to the integration system (no linked integration field to this CE field to update)
    const numIntegrationFieldValuePairs = singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs.length;
    if(numIntegrationFieldValuePairs === 0) {
      return;
    }
    
    //get the integration fieldDbName(s) linked to i_ceFieldID as a display comma list for the integrationFieldDbName column of the changelog
    changelogIntegrationFieldDbName = JSFUNC.convert_array_to_display_comma_list(JSFUNC.get_column_vector_from_arrayOfObjs(singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs, "integrationFieldDbName"));
    changelogNewCEValueString = singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs[0].ceNewValueChangelogString; //all new CE values are the same for this single ceFieldID, use the first one
    changelogIntegrationNewValueString = JSFUNC.convert_array_to_display_comma_list(JSFUNC.get_column_vector_from_arrayOfObjs(singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs, "integrationValueString"));
  
    //call a php function to update the same field to the equivalent value in the integration system to sync with the CE update
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("integrationUpdateIntegrationOppMultipleFieldsWithValues", jsDescription);
  
    C_CallPhpScript.add_post_var("i_integrationsCredentialsTblRowID", integrationsCredentialsRowIDOrM1);
    C_CallPhpScript.add_post_var("i_integrationSystem", "vantagepoint");
    C_CallPhpScript.add_post_var("i_integrationOppUniqueIDString", integrationOppUniqueIDString);
    C_CallPhpScript.add_post_var("i_numIntegrationFieldValuePairs", numIntegrationFieldValuePairs);
    for(let i = 0; i < numIntegrationFieldValuePairs; i++) {
      var postVarFieldDbNameString = "i_integrationFieldDbName" + i;
      var postVarValueString = "i_integrationValueString" + i;
      var integrationFieldValueObj = singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs[i];
      C_CallPhpScript.add_post_var(postVarFieldDbNameString, integrationFieldValueObj.integrationFieldDbName);
      C_CallPhpScript.add_post_var(postVarValueString, integrationFieldValueObj.integrationValueString);
    }
  
    C_CallPhpScript.add_return_vars("integrationErrorMessageOrBlankSuccess");
  
    //update integrations log on success or error
    const functionOnSuccess = (i_parseResponse) => {
      //check i_parseResponse for errorMessages from the php call to OAuth and the API when updating the integration system
      var errorMessage = ""; //success has "" for the error message
      if(i_parseResponse.integrationErrorMessageOrBlankSuccess !== "") { //successful integration system field update
        errorMessage = i_parseResponse.integrationErrorMessageOrBlankSuccess;
      }
  
      //insert integrations changelog entry for php OAuth/API success or error
      if(c_companyIntegrationLogRecordSuccessesTF || (errorMessage !== "")) { //do not create a success integrations changelog entry if the 'record successes' switch is turned off
        this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, errorMessage);
      }
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
  
    const functionOnError = () => {
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, i_ceFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, "Unexpected issue handling the request");
    }
    C_CallPhpScript.add_function("onError", functionOnError);
  
    C_CallPhpScript.execute();
  }


  //---------------------------------------------------------
  //--------- [Delete] 3rd Party Integration system ---------
  //---------------------------------------------------------
  integration_delete_integration_opp(i_ceCaptureID) {
    //alert("integration delete [" + i_ceCaptureID + "]");
    
    const c_integrationSystemDisplayName = this.c_integrationSystemDisplayName;
    const c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined = this.c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined;
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const o_tbl_captures = DatabaseMobx.o_tbl_captures;
    const c_bitUsing3rdPartyIntegrationTF = DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyIntegrationOnTF = DatabaseMobx.c_companyIntegrationOnTF;
    const c_companyIntegrationLogRecordSuccessesTF = DatabaseMobx.c_companyIntegrationLogRecordSuccessesTF;
    const c_fieldMapOfCaptureID = DatabaseMobx.c_fieldMapOfCaptureID;
    const c_fieldMapOfDivisionOwners = DatabaseMobx.c_fieldMapOfDivisionOwners;
    const c_userSyncUserCEActionsInIntegrationSystemTF = UserMobx.c_userSyncUserCEActionsInIntegrationSystemTF;

    //3rd Party Integration is turned on in BIT and the Admin and in the User Panel Integration Settings
    if(!(c_bitUsing3rdPartyIntegrationTF && c_companyIntegrationOnTF && c_userSyncUserCEActionsInIntegrationSystemTF)) {
      return;
    }
  
    const integrationSystem = "vantagepoint";
  
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "integration_delete_integration_opp", ["i_ceCaptureID"], [i_ceCaptureID]);
    
    //changelog values
    const iudActionString = "dCEdIN";
    var integrationOppUniqueIDString = "--";
    const changelogCaptureFieldID = -1;
    const changelogIntegrationFieldDbName = "{Delete Existing Opportunity}";
    const changelogNewCEValueString = "--";
    const changelogIntegrationNewValueString = "--";

    //try to load the CE capture map from the input i_ceCaptureID
    var captureMapToDeleteOrUndefined = o_tbl_captures.get(i_ceCaptureID);
    if(captureMapToDeleteOrUndefined !== undefined) {
      captureMapToDeleteOrUndefined = JSFUNC.copy_map(captureMapToDeleteOrUndefined);
    }
  
    //check that the i_ceCaptureID provided is a valid CE capture (need this to extract the existing integration unique ID from the CE capture)
    if(captureMapToDeleteOrUndefined === undefined) {
      const captureIDNotAValidCaptureErrorMessage = c_productStylingObj.productName + " " + c_fieldMapOfCaptureID.get("display_name") + " '" + i_ceCaptureID + "' is not a valid capture";
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, captureIDNotAValidCaptureErrorMessage);
      return;
    }

    //determine the credentials to use based on the capture highest percent division owner (or user division)
    const ceCaptureDivisionOwnersIDsColonPercentCommaOrUndefined = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMapToDeleteOrUndefined, c_fieldMapOfDivisionOwners);
    const integrationsCredentialsRowIDOrM1 = this.determine_integrations_credentials_row_id_or_m1_from_ce_capture_division_owners_ids_colon_percent_comma(ceCaptureDivisionOwnersIDsColonPercentCommaOrUndefined);
    if(!JSFUNC.is_number_not_nan_gt_0(integrationsCredentialsRowIDOrM1)) { //if credentials could not be found matching capture division or user division, don't perform the insert and don't create an integrations log entry
      return;
    }
  
    //verify if all Setup subtab fields are filled out
    const setupErrorMessageOrUndefined = this.integration_verify_all_setup_and_mapping_is_filled_out_undefined_or_error_message_string();
    if(setupErrorMessageOrUndefined !== undefined) { //an APi Connection or Unique ID field is not filled out, write an integration log message
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, setupErrorMessageOrUndefined);
      return;
    }
  
    //load the integration unique ID from this CE capture using the input i_ceCaptureID loaded captureMap
    const ceIntegrationUniqueIDValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMapToDeleteOrUndefined, c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined, true); //plainTextTF is true
    if(!ceIntegrationUniqueIDValueMaskSortIfoObj.isFilledOutTF) { //if this CE capture does not have the integration unique ID field filled out
      const ceIntegrationUniqueIDFieldIsNotFilledOutErrorMessage = c_productStylingObj.productName +  " Capture Field '" + c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined.get("display_name") + "' is not filled out with the " + c_integrationSystemDisplayName + " unique ID that links this opportunity across the 2 systems";
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, ceIntegrationUniqueIDFieldIsNotFilledOutErrorMessage);
      return;
    }
  
    //get the CE integration opp uniqueID string stored in the CE capture linking the CE capture to the integration system opp
    integrationOppUniqueIDString = ceIntegrationUniqueIDValueMaskSortIfoObj.valueMaskPlainText;
  
    const C_CallPhpScriptDeleteIntegrationOpp = new JSPHP.ClassCallPhpScript("integrationDeleteIntegrationOpp", jsDescription);
  
    C_CallPhpScriptDeleteIntegrationOpp.add_post_var("i_integrationsCredentialsTblRowID", integrationsCredentialsRowIDOrM1);
    C_CallPhpScriptDeleteIntegrationOpp.add_post_var("i_integrationSystem", integrationSystem);
    C_CallPhpScriptDeleteIntegrationOpp.add_post_var("i_integrationOppUniqueIDString", integrationOppUniqueIDString);
  
    C_CallPhpScriptDeleteIntegrationOpp.add_return_vars("integrationErrorMessageOrBlankSuccess");
  
    const functionOnSuccessIntegrationDelete = (i_parseResponse) => {
      var integrationChangelogErrorMessage = ""; //blank changelog error message value for success
      if(i_parseResponse.integrationErrorMessageOrBlankSuccess !== "") {
        integrationChangelogErrorMessage = i_parseResponse.integrationErrorMessageOrBlankSuccess;
      }
      
      if(c_companyIntegrationLogRecordSuccessesTF || (integrationChangelogErrorMessage !== "")) {
        this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, integrationChangelogErrorMessage);
      }
    }
    C_CallPhpScriptDeleteIntegrationOpp.add_function("onSuccess", functionOnSuccessIntegrationDelete);
  
    const functionOnErrorIntegrationDelete = () => {
      this.integration_insert_integration_changelog_entry(jsDescription, iudActionString, i_ceCaptureID, integrationOppUniqueIDString, changelogCaptureFieldID, changelogIntegrationFieldDbName, changelogNewCEValueString, changelogIntegrationNewValueString, "Unexpected issue handling the request");
    }
    C_CallPhpScriptDeleteIntegrationOpp.add_function("onError", functionOnErrorIntegrationDelete);
  
    C_CallPhpScriptDeleteIntegrationOpp.execute();
  }

  
  //---------------------------------------------------------
  //- [CE changelog and other functions] Integration system -
  //---------------------------------------------------------
  integration_insert_integration_changelog_entry(i_jsDescription, i_iudActionString, i_ceCaptureID, i_integrationOppUniqueIDString, i_changelogCaptureFieldID, i_changelogIntegrationFieldDbName, i_changelogNewCEValueString, i_changelogIntegrationNewValueString, i_integrationsChangelogErrorMessageOrUndefined) {
    const o_userID = UserMobx.o_userID;
  
    const nowDateTimeUtc = JSFUNC.now_datetime_utc();
  
    const changelogFieldNamesArray = ["datetime_utc", "user_id", "iud_action_string", "ce_capture_id", "integration_unique_id_string", "ce_field_id", "integration_field_db_name", "new_ce_value_string", "new_integration_value_string", "error_message"];
    const changelogValuesArray = [nowDateTimeUtc, o_userID, i_iudActionString, i_ceCaptureID, i_integrationOppUniqueIDString, i_changelogCaptureFieldID, i_changelogIntegrationFieldDbName, i_changelogNewCEValueString, i_changelogIntegrationNewValueString, i_integrationsChangelogErrorMessageOrUndefined];
    const changelogIdsbArray = ["s", "i", "s", "i", "s", "i", "s", "s", "s", "s"];
  
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(i_jsDescription);
    C_CallPhpTblUID.add_insert("tbl_a_integrations_log", changelogFieldNamesArray, changelogValuesArray, changelogIdsbArray);
    C_CallPhpTblUID.execute();
  }


  integration_verify_all_setup_and_mapping_is_filled_out_undefined_or_error_message_string() {
    const c_setupAllIntegrationApiConnectionFieldsArrayOfObjs = this.c_setupAllIntegrationApiConnectionFieldsArrayOfObjs;
    const c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined = this.c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined;
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
  
    //check that every 'API Connection' field on the integration Setup tab is filled out
    const notFilledOutApiConnectionFieldDisplayNamesArray = JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(c_setupAllIntegrationApiConnectionFieldsArrayOfObjs, "fieldDisplayName", "isFilledOutTF", false);
    if(notFilledOutApiConnectionFieldDisplayNamesArray.length > 0) {
      return("'API Connection' field(s) on the Setup tab are not filled out: " + JSFUNC.convert_array_to_display_comma_list(notFilledOutApiConnectionFieldDisplayNamesArray));
    }
  
    //check that 
    if(c_setupSelectedIntegrationUniqueIDCEExpandedCaptureFieldMapOrUndefined === undefined) {
      return("'Unique ID Field Created in " + c_productStylingObj.productName + "' field on the Setup tab is not filled out");
    }
  
    return(undefined);
  }
  
  
  integration_find_all_integration_field_db_names_and_mapped_values_matching_ce_field_id_and_ce_value_raw(i_iudActionString, i_ceCaptureID, i_ceFieldID, i_ceValueRaw, i_newIntegrationOppUniqueIDString) {
    const c_linkedFieldsWithValuesArrayOfObjs = this.c_linkedFieldsWithValuesArrayOfObjs;
    const c_tbl_captures_fields = DatabaseMobx.c_tbl_captures_fields;
  
    //initialize output obj vars
    var singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs = [];
    var ceFieldDebugString = "";
  
    const jsDescription = JSFUNC.js_description_from_action("AdminIntegrationsMobx", "integration_find_all_integration_field_db_names_and_mapped_values_matching_ce_field_id_and_ce_value_raw", ["i_iudActionString", "i_ceCaptureID", "i_ceFieldID", "i_ceValueRaw", "i_newIntegrationOppUniqueIDString"], [i_iudActionString, i_ceCaptureID, i_ceFieldID, i_ceValueRaw, i_newIntegrationOppUniqueIDString]);
  
    ceFieldDebugString += "[ceCID" + i_ceCaptureID + ", ceFID" + i_ceFieldID + "] [[" + i_ceValueRaw + "]]";
  
    //find the integration linked fields pair(s) containing this i_ceFieldID
    var ceFieldMatchingLinkedFieldsWithValuesArrayOfObjs = JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(c_linkedFieldsWithValuesArrayOfObjs, "capture_field_id", i_ceFieldID);
    if(ceFieldMatchingLinkedFieldsWithValuesArrayOfObjs.length === 0) {
      ceFieldDebugString += " (no 'linked fields' created that includes capture fieldID " + i_ceFieldID + ")";
    }
    else {
      //verify CE field and get its properties, mask the new raw CE value for changelogs
      var insertCECaptureFieldExpandedCaptureFieldMapOrUndefined = c_tbl_captures_fields.get(i_ceFieldID);
      if(insertCECaptureFieldExpandedCaptureFieldMapOrUndefined === undefined) { //CE capture field does not exist, changelog error and leave this field off of the integration insert list
        var ceFieldMatchingIntegrationFieldDbNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(ceFieldMatchingLinkedFieldsWithValuesArrayOfObjs, "integration_field_db_name");
        var ceFieldMatchingIntegrationFieldDbNamesCommaDisplayList = JSFUNC.convert_array_to_display_comma_list(ceFieldMatchingIntegrationFieldDbNamesArray);
        var noCEFieldMatchingCEFieldIDErrorMessage = "CE Capture Field ID '" + i_ceFieldID + "' does not exist in CE";
        this.integration_insert_integration_changelog_entry(jsDescription, i_iudActionString, i_ceCaptureID, i_newIntegrationOppUniqueIDString, i_ceFieldID, ceFieldMatchingIntegrationFieldDbNamesCommaDisplayList, i_ceValueRaw, "", noCEFieldMatchingCEFieldIDErrorMessage);
        ceFieldDebugString += " !!!!! No CE Field matching i_ceFieldID !!!!!";
      }
      else { //CE field is valid
        //get masked and ifo values for CE capture raw value
        var ceFieldDisplayName = insertCECaptureFieldExpandedCaptureFieldMapOrUndefined.get("display_name");
        var ceFieldFieldTypeObj = insertCECaptureFieldExpandedCaptureFieldMapOrUndefined.get("fieldTypeObj");
        var ceValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_ceValueRaw, ceFieldFieldTypeObj);
  
        //create the "i_ceValueRaw {ceValueMaskPlainText}" changelog entry string from the CE raw value
        var ceValueRawString = JSFUNC.num2str(i_ceValueRaw);
        var ceValueTrueRawIntegrationsString = JSFUNC.num2str(ceValueMaskSortIfoObj.valueTrueRawIntegrations);
        var ceNewValueMaskPlainTextString = JSFUNC.num2str(ceValueMaskSortIfoObj.valueMaskPlainText);
        var ceNewValueChangelogString = ceValueRawString;
        if(ceValueRawString !== ceNewValueMaskPlainTextString) { //if the mask value of this updated field value is different from the raw value, display both for the CE changelog column
          ceNewValueChangelogString = ceValueRawString + " {" + ceNewValueMaskPlainTextString + "}";
        }
        ceFieldDebugString += " [ceFieldDisplayName: '" + ceFieldDisplayName + "'] [[" + ceNewValueChangelogString + "]]";
  
        //loop over each integration field linked to this CE field (can have multiple different integration fields mapped to the same CE field)
        for(let linkedFieldsWithValuesObj of ceFieldMatchingLinkedFieldsWithValuesArrayOfObjs) {
          //unpack the integration fieldDbName and the mapped values between CE and the integration system
          var integrationFieldDbName = linkedFieldsWithValuesObj.integration_field_db_name; //"key","WBSNumber","WBS1","Name","LongName","Level1Name","Stage","Org",etc (also special "{ignore}" to skip mapped value from going into update)
          var canMapValuesTF = linkedFieldsWithValuesObj.canMapValuesTF;
          var mappedValuesArrayOfObjs = linkedFieldsWithValuesObj.mappedValuesArrayOfObjs;
          var hasAtLeast1MappedValueTF = linkedFieldsWithValuesObj.hasAtLeast1MappedValueTF;
          ceFieldDebugString += " {integrationFieldDbName: '" + integrationFieldDbName + "'}";
  
          //initialize the new integration value for this field as ""
          var matchingIntegrationValueString = "";
  
          //if there's mapped matching values between CE and the integration system, find the matching CE value raw and get its mapped integration value
          var matchingCEMappedValueIntegrationIgnoreTF = false;
          var noMatchingCEMappedValueTF = false;
          if(!(canMapValuesTF && hasAtLeast1MappedValueTF)) { //if this CE field can't map values (text/textarea/number/date/etc type fields), or can and has 0 mapped values, use the true raw value for integrations (converted num2str() above) directly
            matchingIntegrationValueString = ceValueTrueRawIntegrationsString;
            ceFieldDebugString += " {{direct '" + matchingIntegrationValueString + "'}}";
          }
          else { //select/multiselect/sharedpercent field that needs value mapping, match the CE value with the mapped integration value string
            var matchingCEValueFoundTF = false; //loop through all mapped values to match input CE value (converted to string or known isFilledOutTF) to the mapped CE raw value string to get its mapped integration value
            for(let mappedValueObj of mappedValuesArrayOfObjs) {
              if((mappedValueObj.capture_field_value_string === ceValueRawString) || (!mappedValueObj.captureFieldValueMaskSortIfoObj.isFilledOutTF && !ceValueMaskSortIfoObj.isFilledOutTF)) { //either string values match exactly, or both are considered not filled out (might be -1 vs 0 or a select raw value which are both not filled out)
                matchingCEValueFoundTF = true; //found a mapped CE raw value string that matched the input CE value
  
                if(mappedValueObj.integration_value_string === "{ignore}") { //special CE code for integration value mapping to ignore a selected value from CE, don't include this CE field/value as part of the output arrayOfObjs of integration fields/values to update
                  matchingIntegrationValueString = "--"; //not used if ignored
                  matchingCEMappedValueIntegrationIgnoreTF = true;
                  ceFieldDebugString += " {{ignored}}";
                }
                else { //use mapped integration value string found matching input CE value
                  matchingIntegrationValueString = mappedValueObj.integration_value_string;
                  ceFieldDebugString += " {{matched '" + mappedValueObj.integration_value_string + "'}}";
                }
  
                break;
              }
            }
  
            if(!matchingCEValueFoundTF) { //no matching CE raw value string among mapped CE values
              if(!ceValueMaskSortIfoObj.isFilledOutTF) { //CE valueRaw is not filled out, use "" empty string for the integration value (this is done here because the Admin may have mapped a CE value of "" or 0 or -1 to an integration value that is not "", like "empty" or "1" or something they need specifically)
                matchingIntegrationValueString = "";
                ceFieldDebugString += ' {{ce not filled out, "" used}}';
              }
              else { //CE value was filled out, but no matching mapping was found, still continue but write a changelog entry and use "" emptry string for the integration value (changelog error message has "[skipped]" in front to give a different color to the message in the Log)
                matchingIntegrationValueString = "";
                noMatchingCEMappedValueTF = true; //integration field will not be included as matching the CE field (will be essentially skipped)
                var ceValueNotMappedToIntegrationValueErrorMessage = "[skipped] CE value '" + ceNewValueMaskPlainTextString + "' is not mapped to an Integration value, this field was not set or modified in the Integration database";
                this.integration_insert_integration_changelog_entry(jsDescription, i_iudActionString, i_ceCaptureID, i_newIntegrationOppUniqueIDString, i_ceFieldID, integrationFieldDbName, ceNewValueChangelogString, "{No Mapped Integration Value}", ceValueNotMappedToIntegrationValueErrorMessage);
                ceFieldDebugString += " @@@@@ CE value is not mapped to an integration value @@@@@";
              }
            }
          }
  
          //apply any special handling steps on each integration value string before sending to the integration database
          if(ceFieldFieldTypeObj.valueDisplayIsDateOrDateTimeTF && !ceValueMaskSortIfoObj.isFilledOutTF) { //any of the 5 date types and not filled out
            if(ceFieldFieldTypeObj.valueDisplayIsDateTF) {
              matchingIntegrationValueString = DatabaseMobx.c_companyIntegrationSpecialIntegrationBlankDate;
            }
            else if(ceFieldFieldTypeObj.valueDisplayIsDateTimeTF) {
              matchingIntegrationValueString = DatabaseMobx.c_companyIntegrationSpecialIntegrationBlankDateTime;
            }
            ceFieldDebugString += " {{specialhandling '" + matchingIntegrationValueString + "'}}";
          }
  
          //add integration fieldDbName and new value string to the collection array (if not ignored), also computed ce field/value display for changelog entries
          if(!matchingCEMappedValueIntegrationIgnoreTF && !noMatchingCEMappedValueTF) {
            singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs.push({
              ceFieldDisplayName: ceFieldDisplayName,
              ceNewValueChangelogString: ceNewValueChangelogString,
              integrationFieldDbName: integrationFieldDbName,
              integrationValueString: matchingIntegrationValueString
            });
          }
        }
      }
    }
  
    return({
      singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs: singleCEFieldIDMatchingIntegrationFieldsValuesArrayOfObjs,
      ceFieldDebugString: ceFieldDebugString
    });
  }
  
  
  create_new_integration_unique_id_string_from_new_ce_capture_id(i_newCECaptureID, i_newCECaptureDivisionIDsColonPercentsCommaOrUndefined=undefined) {
    const c_tbl_a_divisions = DatabaseMobx.c_tbl_a_divisions;

    //initialize the integration unique ID prefix string as empty
    var integrationUniqueIDPrefix = "";

    //sort the raw value of sharedpercent colon comma list divisionIDs and their percents, and get the one with the largest percent ownership to use as the integration unique ID prefix (if filled out in tbl_a_divisions for that divisionID)
    if(JSFUNC.is_string(i_newCECaptureDivisionIDsColonPercentsCommaOrUndefined)) {
      const newCECaptureHighestPercentDivisionIntsObjOrUndefined = JSFUNC.get_first_ints_obj_or_undefined_from_colon_comma_list_after_sorting(i_newCECaptureDivisionIDsColonPercentsCommaOrUndefined, 2, false);
      if(newCECaptureHighestPercentDivisionIntsObjOrUndefined !== undefined) {
        const newCECaptureHighestPercentDivisionID = newCECaptureHighestPercentDivisionIntsObjOrUndefined.int1;
        const newCECaptureHighestExpandedDivisionMapOrUndefined = c_tbl_a_divisions.get(newCECaptureHighestPercentDivisionID);
        if(newCECaptureHighestExpandedDivisionMapOrUndefined !== undefined) {
          if(newCECaptureHighestExpandedDivisionMapOrUndefined.get("calcIntegrationPrefixIsFilledOutTF")) {
            integrationUniqueIDPrefix = newCECaptureHighestExpandedDivisionMapOrUndefined.get("integration_new_capture_integration_unique_id_prefix");
          }
        }
      }
    }

    //build string "CE1234" or "PREFIX-CE1234"
    var newIntegrationUniqueIDString = "";
    if(integrationUniqueIDPrefix !== "") {
      newIntegrationUniqueIDString += integrationUniqueIDPrefix + "-";
    }
    newIntegrationUniqueIDString += "CE" + i_newCECaptureID;

    return(newIntegrationUniqueIDString);
  }


  determine_integrations_credentials_row_id_or_m1_from_ce_capture_division_owners_ids_colon_percent_comma(i_ceCaptureDivisionOwnersIDsColonPercentComma) {
    const o_tbl_a_integrations_credentials = DatabaseMobx.o_tbl_a_integrations_credentials;
    const c_userDivisionID = UserMobx.c_userDivisionID;

    //initialize divisionID to use (from caputre division owners (highest percent), or from the user's division if the capture has an empty division field)
    var ceCaptureDivisionIDOrUserDivisionIDOrM1 = -1;

    //first try to see if the capture division owners field is filled out and get the divisionID with the most percent ownership
    const ceCaptureHighestPercentDivisionIntsObjOrUndefined = JSFUNC.get_first_ints_obj_or_undefined_from_colon_comma_list_after_sorting(i_ceCaptureDivisionOwnersIDsColonPercentComma, 2, false);
    if(ceCaptureHighestPercentDivisionIntsObjOrUndefined !== undefined) {
      ceCaptureDivisionIDOrUserDivisionIDOrM1 = ceCaptureHighestPercentDivisionIntsObjOrUndefined.int1;
    }
    else { //if the divison field is blank, use the user's divisionID
      ceCaptureDivisionIDOrUserDivisionIDOrM1 = c_userDivisionID;
    }
    
    //if a valid divisionID was found for the capture/user, try to match it to the multiple integration credential entries, each with a comma list of divisionIDs that they apply to
    if(JSFUNC.is_number_not_nan_gt_0(ceCaptureDivisionIDOrUserDivisionIDOrM1)) {
      for(let integrationsCredentialsMap of o_tbl_a_integrations_credentials.values()) {
        var credentialsDivisionIDsArray = JSFUNC.convert_comma_list_to_int_array(integrationsCredentialsMap.get("division_ids_comma"));
        if(JSFUNC.in_array(ceCaptureDivisionIDOrUserDivisionIDOrM1, credentialsDivisionIDsArray)) { //pick the first credentials that has this divisionID listed
          return(integrationsCredentialsMap.get("id")); //return the matching credentials rowID
        }
      }
    }

    //if the division was assigned to Unassigned (-1), or the user has no division assigned, or the credentials don't have this divisionID assigned to a credential, return a credentials rowID of -1 
    return(-1);
  }


}
export default new AdminIntegrationsMobx();
