import React, { Component } from 'react'; //React js language
import { makeObservable, observable, computed, action } from 'mobx';

import * as LibraryReact from "../Library/LibraryReact.js";
import * as JSFUNC from "../Library/JSFUNC.js";

import * as CEGeneralReact from "../CaptureExecGeneral/CEGeneralReact.js";

import CaptureExecMobx from '../CaptureExecReactMobxPairs/CaptureExec/CaptureExecMobx.js';
import ContactsMobx from '../CaptureExecReactMobxPairs/Contacts/ContactsMobx.js';
import OpenCaptureMobx from '../CaptureExecReactMobxPairs/OpenCapture/OpenCaptureMobx.js';

import UserMobx from './UserMobx.js';
import * as JSPHP from "./JSPHP.js";

import * as ContactsReact from "../CaptureExecReactMobxPairs/Contacts/ContactsReact.js";

export class DatabaseMobx {
  //===constant fields==================================================================================================================================
  k_bitCompaniesTblNamesArray = ["bitcompaniesTblRowMap"]; //loads single company row from BITcompanies tbl
  k_bitMasterTblNamesArray = ["tbl_bit_master_contract_opportunity_types", "tbl_bit_master_countries", "tbl_bit_master_departments_agencies", "tbl_bit_master_fedcomp_contract_types", "tbl_bit_master_naics_codes", "tbl_bit_master_product_service_codes", "tbl_bit_master_set_asides", "tbl_bit_master_states", "tbl_bit_master_sub_tiers"];
  k_bitHelpPanelTblNamesArray = ["bitcompaniesH1HelpPanelsTblMapOfMaps", "bitcompaniesH2HelpPanelCollapsibleHeadersTblMapOfMaps", "bitcompaniesH3HelpPanelHeaderContentTblMapOfMaps"];
  k_bitHelpPanelSGTTblNamesArray = ["bitcompaniesH1SGTHelpPanelsTblMapOfMaps", "bitcompaniesH2SGTHelpPanelCollapsibleHeadersTblMapOfMaps", "bitcompaniesH3SGTHelpPanelHeaderContentTblMapOfMaps"];
  k_adminTblNamesArray = [
    //"tbl_a_automated_exports", 
    "tbl_a_automated_reminders", "tbl_a_budget_categories_pool", "tbl_a_budget_expense_types", "tbl_a_business_types", "tbl_a_capabilities",
    "tbl_a_capture_cards_custom_names", "tbl_a_capture_priority_levels", "tbl_a_capture_types", "tbl_a_capture_types_debrief_questions", "tbl_a_capture_types_details_fields",
    "tbl_a_capture_types_shaping_questions", "tbl_a_communications_banners", "tbl_a_company", "tbl_a_competitors_extra_fields", "tbl_a_contacts_companies_extra_fields", "tbl_a_contacts_persons_extra_fields",
    "tbl_a_create_new_capture_additional_fields", "tbl_a_contract_types", "tbl_a_date_upcoming_colors1", "tbl_a_date_upcoming_colors2", "tbl_a_delete_capture_reasons", "tbl_a_details_groups_pool",
    "tbl_a_divisions", "tbl_a_excel_report_templates_filefoldersystem", "tbl_a_gcss_data_source_credentials", "tbl_a_gcss_not_interested_reasons", "tbl_a_pt_differentiators", "tbl_a_pwin_calc_lines",
    "tbl_a_pwin_colors", "tbl_a_reasons_won_lost", "tbl_a_risks_categories", "tbl_a_risks_impact_levels", "tbl_a_risks_probability_levels",
    "tbl_a_search_fields", "tbl_a_shaping_progress_colors", "tbl_a_shaping_questions_pool", "tbl_a_shaping_questions_tags", "tbl_a_shaping_select_answers", 
    "tbl_a_shortcut_presets_capture_managers", "tbl_a_shortcut_presets_documents_card_folders", "tbl_a_shortcut_presets_documents_card_folders_filefoldersystem", "tbl_a_single_capture_templates_filefoldersystem", "tbl_a_stages_pool", "tbl_a_styling_string_presets", "tbl_a_tasks_extra_capture_fields", "tbl_a_teammates_contract_agreement_types", 
    "tbl_a_teammates_contract_statuses", "tbl_a_teammates_contract_templates_filefoldersystem", "tbl_a_teammates_contract_types", "tbl_a_teammates_extra_fields", "tbl_a_teammates_ratings_questions", 
    "tbl_a_teammates_surveys_scale05_colors", "tbl_a_teammates_workshare_types", "tbl_a_users",  "tbl_a_users_per_email", "tbl_captures_fields", 
    "tbl_cap"
  ];
  k_adminSupplementChangelogTblNamesArray = ["tbl_a_log_admin"];
  k_adminImportTblNamesArray = ["tbl_a_import_auto_assigns", "tbl_a_import_column_assigns", "tbl_a_import_custom_assigns", "tbl_a_import_presets"]; //admin import tbls loaded separately during import process as they are not used by any other user
  k_adminIntegrationsTblNamesArray = ["tbl_a_integrations_credentials", "tbl_a_integrations_linked_fields", "tbl_a_integrations_linked_field_values"];
  k_adminIntegrationsSupplementChangelogTblNamesArray = ["tbl_a_integrations_log"];
  k_capturesTblNamesArray = ["tbl_capturesSlimCST"];
  k_captureSupplementTblNamesFullyLoadedAtLogin = ["tbl_c_teammates", "tbl_c_notepad_note_stamps"]; //full teammates for contacts rating system, full note stamps for Recent/Pinned Note Stamp computed capture column
  k_captureSupplementTblNamesFilteredForSingleOpenedCaptureArray = [
    "tbl_c_budget_expenses", "tbl_c_budget_funding_requests", "tbl_c_competitors", "tbl_c_conversations", "tbl_c_documents_filefoldersystem",
    "tbl_c_pt_differentiators", "tbl_c_pt_ghost_themes", "tbl_c_pt_win_themes", "tbl_c_risks", "tbl_c_shaping_answers_select",
    "tbl_c_shaping_answers_textarea", "tbl_c_teammates_contracts", "tbl_c_teammates_contracts_filefoldersystem", "tbl_c_teammates_ratings_questionnaire_submissions",
    "tbl_c_teammates_sb_certification_allocations", "tbl_c_teammates_surveys", "tbl_c_teammates_surveys_answers", "tbl_c_teammates_surveys_filefoldersystem", "tbl_c_teammates_surveys_questions",
    "tbl_c_teammates_surveys_response_times",
    "singleCaptureFullTextareaFieldsMap"
  ];
  k_captureSupplementChangeLogTblNamesArray = ["tbl_c_log_budget", "tbl_c_log_details", "tbl_c_log_pwin", "tbl_c_log_shaping", "tbl_c_log_stages", "tbl_c_log_teammate_contracts"]; //log tbls only loaded when the changelog is opened in a capture or the progress chart is drawn
  k_divexecTblNamesArray = ["tbl_d_daily_snapshot_graphs", "tbl_d_daily_snapshot_pages", "tbl_d_quota", "tbl_d_trend_analyzer_graphs", "tbl_d_trend_analyzer_pages"];
  k_filterTblNamesArray = ["tbl_f_capture_table_presets", "tbl_f_cst_columns", "tbl_f_cst_column_presets", "tbl_f_filters", "tbl_f_filter_presets", "tbl_f_gantt_dates", "tbl_f_gantt_date_presets", "tbl_f_sorts", "tbl_f_sort_presets"];
  k_contactsTblNamesArray = ["tbl_g_contacts_companies", "tbl_g_contacts_persons"];
  k_contactDocumentsTblNamesArray = ["tbl_g_contacts_companies_filefoldersystem", "tbl_g_contacts_persons_filefoldersystem"]; //loaded in ContactsMobx with custom database_api filter functions when a contact is opened, no need to load at login
  k_generalDocumentsTblNamesArray = ["tbl_g_general_documents_filefoldersystem"];
  k_gcssLoadOnLoginTblNamesArray = ["tbl_u_gcss_auto_searches"];
  k_gcssLoadOnTabTblNamesArray = ["tbl_g_gcss_not_interested"];
  k_taskTblNamesArray = ["tbl_u_tasks"];
  k_notificationTblNamesArray = ["tbl_u_notifications"];
  k_ticketTblNamesArray = ["tbl_z_ticket_initial_threads", "tbl_z_ticket_responses"];
  k_allCapturesNamesReferenceForCreateNewCaptureTblNamesArray = ["allCapturesNamesAndArchiveDateMapOfMaps"];

  k_cardIDAdvanceStage = 1;
  k_cardIDDates = 2;
  k_cardIDTasks = 3;
  k_cardIDDetails = 4;
  k_cardIDDealShaping = 5;
  k_cardIDTeammates = 6;
  k_cardIDCompetitors = 7;
  k_cardIDProposalThemes = 8;
  k_cardIDRiskAssessment = 9;
  k_cardIDBudget = 10;
  k_cardIDConversations = 11;
  k_cardIDDocuments = 12;
  k_cardIDTemplates = 13;
  k_cardIDIDIQTaskOrders = 14;
  k_cardIDChangelog = 15;
  k_cardIDNotepad = 16;
  k_cardIDRevenue = 17;
  
  k_cardIDUserCreateNewCapture = 1000;
  k_cardNameUserCreateNewCapture = "[User Create New Capture]";
  k_cardIDGCSSUserImportNewCapture = 1001;
  k_cardNameGCSSUserImportNewCapture = "[GCSS User Import New Capture]";
  k_cardIDGCSSSystemUpdateExistingCapture = 1002;
  k_cardNameGCSSSystemUpdateExistingCapture = "[GCSS System Update Existing Capture]";
  k_cardIDAdminImportNewCapture = 1003;
  k_cardNameAdminImportNewCapture = "[Admin Import New Capture]";
  k_cardIDAdminUpdateExistingCapture = 1004;
  k_cardNameAdminUpdateExistingCapture = "[Admin Update Existing Capture]";
  k_cardIDThirdPartyCRMCreateNewCapture = 1005;
  k_cardNameThirdPartyCRMCreateNewCapture = "[Third-Party CRM Create New Capture]";
  k_cardIDThirdPartyCRMUpdateExistingCapture = 1006;
  k_cardNameThirdPartyCRMUpdateExistingCapture = "[Third-Party CRM Update Existing Capture]";
  k_cardIDAdminMassEditCapture = 1007;
  k_cardNameAdminMassEditCapture = "[Admin Mass Edit Capture]";

  //===observable fields (raw data from database for this "Data" Store)=================================================================================
  //Map layers per tbl:
  // - o_tbl_company, o_singleCaptureFullTextareaFieldsMap - single Map() of the company fields and data
  // - all other o_tbl - double Map(id1, Map(k1v1, k2v2, etc)) tbl must have a field called "id" with unique numbers for first layer keys
  // - o_tbl_cap - triple Map("tbl_cap_security", Map(id1, Map(f1v1, f2v2, etc))) accessed by full tbl name, then record id, then each field/value

  //BITcompanies tbl row for this customer with license information
  o_bitcompaniesTblRowMap = new Map();

  //BITcompanies bit master tbls
  o_tbl_bit_master_contract_opportunity_types = new Map();
  o_tbl_bit_master_countries = new Map();
  o_tbl_bit_master_departments_agencies = new Map();
  o_tbl_bit_master_fedcomp_contract_types = new Map();
  o_tbl_bit_master_naics_codes = new Map();
  o_tbl_bit_master_product_service_codes = new Map();
  o_tbl_bit_master_set_asides = new Map();
  o_tbl_bit_master_states = new Map();
  o_tbl_bit_master_sub_tiers = new Map();

  //BITcompanies help panel tbls
  o_bitcompaniesH1HelpPanelsTblMapOfMaps = new Map();
  o_bitcompaniesH2HelpPanelCollapsibleHeadersTblMapOfMaps = new Map();
  o_bitcompaniesH3HelpPanelHeaderContentTblMapOfMaps = new Map();

  //database tables loaded in login.php with a successful login
  o_tbl_a_automated_exports = new Map();
  o_tbl_a_automated_reminders = new Map();
  o_tbl_a_budget_categories_pool = new Map();
  o_tbl_a_budget_expense_types = new Map();
  o_tbl_a_business_types = new Map();
  o_tbl_a_capabilities = new Map();
  o_tbl_a_capture_cards_custom_names = new Map();
  o_tbl_a_capture_priority_levels = new Map();
  o_tbl_a_capture_types = new Map(); //custom capture types control 1-details groups, 2-details fields, 3-shaping questions, 4-stages, 5-cards on capture (ids 1-13 hardcoded for cards)
  o_tbl_a_capture_types_debrief_questions = new Map();
  o_tbl_a_capture_types_details_fields = new Map(); //holds details fields selected for each capture type
  o_tbl_a_capture_types_shaping_questions = new Map(); //holds shaping questions selected for each capture type
  o_tbl_a_communications_banners = new Map();
  o_tbl_a_company = new Map(); //single map of company flags and settings (no matrix holding it, so it is updated in a special and can't have insert or delete operations)
  o_tbl_a_competitors_extra_fields = new Map();
  o_tbl_a_contacts_persons_extra_fields = new Map();
  o_tbl_a_contacts_companies_extra_fields = new Map();
  o_tbl_a_contract_types = new Map(); //custom contract types that are either 1-single award, 2-idiq, or 3-task order
  o_tbl_a_create_new_capture_additional_fields = new Map();
  o_tbl_a_date_upcoming_colors1 = new Map();
  o_tbl_a_date_upcoming_colors2 = new Map();
  o_tbl_a_delete_capture_reasons = new Map();
  o_tbl_a_details_groups_pool = new Map(); //pool of details groups available to add to tbl_a_capture_types field `details_group_ids_comma`
  o_tbl_a_divisions = new Map(); //divisions in the company, use contact_company objects for name, tree, info
  o_tbl_a_excel_report_templates_filefoldersystem = new Map();
  o_tbl_a_gcss_data_source_credentials = new Map();
  o_tbl_a_gcss_not_interested_reasons = new Map();
  o_tbl_a_import_auto_assigns = new Map();
  o_tbl_a_import_column_assigns = new Map();
  o_tbl_a_import_custom_assigns = new Map();
  o_tbl_a_import_presets = new Map();
  o_tbl_a_integrations_credentials = new Map();
  o_tbl_a_integrations_linked_fields = new Map();
  o_tbl_a_integrations_linked_field_values = new Map();
  o_tbl_a_integrations_log = new Map();
  o_tbl_a_log_admin = new Map(); //only loaded when the admin changelog tab is opened
  o_tbl_a_pt_differentiators = new Map();
  o_tbl_a_pwin_calc_lines = new Map();
  o_tbl_a_pwin_colors = new Map();
  o_tbl_a_reasons_won_lost = new Map(); //reasons for win or loss, captures can select multiple reasons and weight them, divexec has win/loss analysis table against these reasons
  o_tbl_a_risks_categories = new Map();
  o_tbl_a_risks_impact_levels = new Map();
  o_tbl_a_risks_probability_levels = new Map();
  o_tbl_a_search_fields = new Map();
  o_tbl_a_shaping_progress_colors = new Map(); //colors for different percent ranges when shaping question progress increases
  o_tbl_a_shaping_questions_pool = new Map(); //pool of questions that can be assigned to tbl_a_capture_types_shaping_questions
  o_tbl_a_shaping_questions_tags = new Map(); //all possible tags that can be assigned to shaping questions in tbl_a_shaping_questions_pool
  o_tbl_a_shaping_select_answers = new Map(); //multiple choice answers for questions of type 'select'
  o_tbl_a_shortcut_presets_capture_managers = new Map();
  o_tbl_a_shortcut_presets_documents_card_folders = new Map();
  o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem = new Map();
  o_tbl_a_single_capture_templates_filefoldersystem = new Map();
  o_tbl_a_stages_pool = new Map(); //pool of stages that can be assigned to tbl_a_capture_types field `stage_ids_comma`
  o_tbl_a_styling_string_presets = new Map();
  o_tbl_a_tasks_extra_capture_fields = new Map();
  o_tbl_a_teammates_contract_agreement_types = new Map();
  o_tbl_a_teammates_contract_statuses = new Map();
  o_tbl_a_teammates_contract_templates_filefoldersystem = new Map();
  o_tbl_a_teammates_contract_types = new Map();
  o_tbl_a_teammates_extra_fields = new Map();
  o_tbl_a_teammates_ratings_questions = new Map();
  o_tbl_a_teammates_surveys_scale05_colors = new Map();
  o_tbl_a_teammates_workshare_types = new Map();
  o_tbl_a_users = new Map(); //all users from tbl_a_users (users tied to usersPerEmail entries as multiple logins)
  o_tbl_a_users_per_email = new Map(); //all users from tbl_a_users_per_email (1 entry per unique email address)
  o_tbl_cap = new Map(); //all tbl_cap_ tables are kept under a single container object (first layer keyed by "tbl_cap_name")
  o_tbl_captures = new Map(); //all filtered captures in slim textarea format
  o_tbl_captures_fields = new Map(); //field corresponding to columns in tbl_captures or columns a user can add to their CST
  o_tbl_c_budget_expenses = new Map();
  o_tbl_c_budget_funding_requests = new Map();
  o_tbl_c_competitors = new Map();
  o_tbl_c_conversations = new Map();
  o_tbl_c_documents_filefoldersystem = new Map();
  o_tbl_c_log_budget = new Map()
  o_tbl_c_log_details = new Map();
  o_tbl_c_log_pwin = new Map();
  o_tbl_c_log_shaping = new Map();
  o_tbl_c_log_stages = new Map();
  o_tbl_c_log_teammate_contracts = new Map();
  o_tbl_c_notepad_note_stamps = new Map();
  o_tbl_c_pt_differentiators = new Map();
  o_tbl_c_pt_win_themes = new Map();
  o_tbl_c_pt_ghost_themes = new Map();
  o_tbl_c_risks = new Map();
  o_tbl_c_shaping_answers_select = new Map();
  o_tbl_c_shaping_answers_textarea = new Map();
  o_tbl_c_teammates = new Map();
  o_tbl_c_teammates_contracts = new Map();
  o_tbl_c_teammates_contracts_filefoldersystem = new Map();
  o_tbl_c_teammates_ratings_questionnaire_submissions = new Map();
  o_tbl_c_teammates_sb_certification_allocations = new Map();
  o_tbl_c_teammates_surveys = new Map();
  o_tbl_c_teammates_surveys_answers = new Map();
  o_tbl_c_teammates_surveys_filefoldersystem = new Map();
  o_tbl_c_teammates_surveys_questions = new Map();
  o_tbl_c_teammates_surveys_response_times = new Map();
  o_tbl_d_daily_snapshot_graphs = new Map();
  o_tbl_d_daily_snapshot_pages = new Map();
  o_tbl_d_quota = new Map();
  o_tbl_d_trend_analyzer_graphs = new Map();
  o_tbl_d_trend_analyzer_pages = new Map();
  o_tbl_f_capture_table_presets = new Map();
  o_tbl_f_cst_columns = new Map();
  o_tbl_f_cst_column_presets = new Map();
  o_tbl_f_filters = new Map();
  o_tbl_f_filter_presets = new Map();
  o_tbl_f_gantt_dates = new Map();
  o_tbl_f_gantt_date_presets = new Map();
  o_tbl_f_sorts = new Map();
  o_tbl_f_sort_presets = new Map();
  o_tbl_g_contacts_companies = new Map();
  o_tbl_g_contacts_companies_filefoldersystem = new Map();
  o_tbl_g_contacts_persons = new Map();
  o_tbl_g_contacts_persons_filefoldersystem = new Map();
  o_tbl_g_gcss_not_interested = new Map();
  o_tbl_g_general_documents_filefoldersystem = new Map();
  o_tbl_u_gcss_auto_searches = new Map();
  o_tbl_u_notifications = new Map();
  o_tbl_u_tasks = new Map(); //only load all active tasks in the company when logging in (active and completed tasks for a capture loaded when a capture is opened) (completed tasks loaded when viewed in tasks panel)
  o_tbl_z_ticket_initial_threads = new Map();
  o_tbl_z_ticket_responses = new Map();

  //single map that holds db_name of textarea fields mapped to their full values with html tags
  o_singleCaptureFullTextareaFieldsMap = new Map();

  //mapOfMaps that holds every capture in tbl_captures with only id, codename, opportunity_name, and archive_date columns - used to fill Create New Capture feature that looks for existing capture names, and to get a list of archived captureIDs
  o_allCapturesNamesAndArchiveDateMapOfMaps = new Map();

  //load filtered capture changelog records for progress chart, arrayOfObjs of date/value data split up by captureID into a Map
  o_progressChartLogPwinMapOfArrayOfObjs = new Map();
  o_progressChartLogShapingMapOfArrayOfObjs = new Map();
  o_progressChartLogStagesMapOfArrayOfObjs = new Map();

  //SGT opening Admin My Account subtab fetches billing/account info from Stripe.com (using BIT companies company row stripe customer ID string) and loads this obj
  o_stripeCustomerDataObjOrUndefined = undefined;

  constructor() {
    makeObservable(this, {
      o_bitcompaniesTblRowMap: observable,
      o_tbl_bit_master_contract_opportunity_types: observable,
      o_tbl_bit_master_countries: observable,
      o_tbl_bit_master_departments_agencies: observable,
      o_tbl_bit_master_fedcomp_contract_types: observable,
      o_tbl_bit_master_naics_codes: observable,
      o_tbl_bit_master_product_service_codes: observable,
      o_tbl_bit_master_set_asides: observable,
      o_tbl_bit_master_states: observable,
      o_tbl_bit_master_sub_tiers: observable,
      o_bitcompaniesH1HelpPanelsTblMapOfMaps: observable,
      o_bitcompaniesH2HelpPanelCollapsibleHeadersTblMapOfMaps: observable,
      o_bitcompaniesH3HelpPanelHeaderContentTblMapOfMaps: observable,
      o_tbl_a_automated_exports: observable,
      o_tbl_a_automated_reminders: observable,
      o_tbl_a_budget_categories_pool: observable,
      o_tbl_a_budget_expense_types: observable,
      o_tbl_a_business_types: observable,
      o_tbl_a_capabilities: observable,
      o_tbl_a_capture_cards_custom_names: observable,
      o_tbl_a_capture_priority_levels: observable,
      o_tbl_a_capture_types: observable,
      o_tbl_a_capture_types_debrief_questions: observable,
      o_tbl_a_capture_types_details_fields: observable,
      o_tbl_a_capture_types_shaping_questions: observable,
      o_tbl_a_communications_banners: observable,
      o_tbl_a_company: observable,
      o_tbl_a_competitors_extra_fields: observable,
      o_tbl_a_contacts_persons_extra_fields: observable,
      o_tbl_a_contacts_companies_extra_fields: observable,
      o_tbl_a_contract_types: observable,
      o_tbl_a_create_new_capture_additional_fields: observable,
      o_tbl_a_date_upcoming_colors1: observable,
      o_tbl_a_date_upcoming_colors2: observable,
      o_tbl_a_delete_capture_reasons: observable,
      o_tbl_a_details_groups_pool: observable,
      o_tbl_a_divisions: observable,
      o_tbl_a_excel_report_templates_filefoldersystem: observable,
      o_tbl_a_gcss_data_source_credentials: observable,
      o_tbl_a_gcss_not_interested_reasons: observable,
      o_tbl_a_import_auto_assigns: observable,
      o_tbl_a_import_column_assigns: observable,
      o_tbl_a_import_custom_assigns: observable,
      o_tbl_a_import_presets: observable,
      o_tbl_a_integrations_credentials: observable,
      o_tbl_a_integrations_linked_fields: observable,
      o_tbl_a_integrations_linked_field_values: observable,
      o_tbl_a_integrations_log: observable,
      o_tbl_a_log_admin: observable,
      o_tbl_a_pt_differentiators: observable,
      o_tbl_a_pwin_calc_lines: observable,
      o_tbl_a_pwin_colors: observable,
      o_tbl_a_reasons_won_lost: observable,
      o_tbl_a_risks_categories: observable,
      o_tbl_a_risks_impact_levels: observable,
      o_tbl_a_risks_probability_levels: observable,
      o_tbl_a_search_fields: observable,
      o_tbl_a_shaping_progress_colors: observable,
      o_tbl_a_shaping_questions_pool: observable,
      o_tbl_a_shaping_questions_tags: observable,
      o_tbl_a_shaping_select_answers: observable,
      o_tbl_a_shortcut_presets_capture_managers: observable,
      o_tbl_a_shortcut_presets_documents_card_folders: observable,
      o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem: observable,
      o_tbl_a_single_capture_templates_filefoldersystem: observable,
      o_tbl_a_stages_pool: observable,
      o_tbl_a_styling_string_presets: observable,
      o_tbl_a_tasks_extra_capture_fields: observable,
      o_tbl_a_teammates_contract_agreement_types: observable,
      o_tbl_a_teammates_contract_statuses: observable,
      o_tbl_a_teammates_contract_templates_filefoldersystem: observable,
      o_tbl_a_teammates_contract_types: observable,
      o_tbl_a_teammates_extra_fields: observable,
      o_tbl_a_teammates_ratings_questions: observable,
      o_tbl_a_teammates_surveys_scale05_colors: observable,
      o_tbl_a_teammates_workshare_types: observable,
      o_tbl_a_users: observable,
      o_tbl_a_users_per_email: observable,
      o_tbl_captures: observable,
      o_tbl_captures_fields: observable,
      o_tbl_cap: observable,
      o_tbl_c_budget_expenses: observable,
      o_tbl_c_budget_funding_requests: observable,
      o_tbl_c_competitors: observable,
      o_tbl_c_conversations: observable,
      o_tbl_c_documents_filefoldersystem: observable,
      o_tbl_c_log_budget: observable,
      o_tbl_c_log_details: observable,
      o_tbl_c_log_pwin: observable,
      o_tbl_c_log_shaping: observable,
      o_tbl_c_log_stages: observable,
      o_tbl_c_log_teammate_contracts: observable,
      o_tbl_c_notepad_note_stamps: observable,
      o_tbl_c_pt_differentiators: observable,
      o_tbl_c_pt_win_themes: observable,
      o_tbl_c_pt_ghost_themes: observable,
      o_tbl_c_risks: observable,
      o_tbl_c_shaping_answers_select: observable,
      o_tbl_c_shaping_answers_textarea: observable,
      o_tbl_c_teammates: observable,
      o_tbl_c_teammates_contracts: observable,
      o_tbl_c_teammates_contracts_filefoldersystem: observable,
      o_tbl_c_teammates_ratings_questionnaire_submissions: observable,
      o_tbl_c_teammates_sb_certification_allocations: observable,
      o_tbl_c_teammates_surveys: observable,
      o_tbl_c_teammates_surveys_answers: observable,
      o_tbl_c_teammates_surveys_filefoldersystem: observable,
      o_tbl_c_teammates_surveys_questions: observable,
      o_tbl_c_teammates_surveys_response_times: observable,
      o_tbl_d_daily_snapshot_graphs: observable,
      o_tbl_d_daily_snapshot_pages: observable,
      o_tbl_d_quota: observable,
      o_tbl_d_trend_analyzer_graphs: observable,
      o_tbl_d_trend_analyzer_pages: observable,
      o_tbl_f_capture_table_presets: observable,
      o_tbl_f_cst_columns: observable,
      o_tbl_f_cst_column_presets: observable,
      o_tbl_f_filters: observable,
      o_tbl_f_filter_presets: observable,
      o_tbl_f_gantt_dates: observable,
      o_tbl_f_gantt_date_presets: observable,
      o_tbl_f_sorts: observable,
      o_tbl_f_sort_presets: observable,
      o_tbl_g_contacts_companies: observable,
      o_tbl_g_contacts_companies_filefoldersystem: observable,
      o_tbl_g_contacts_persons: observable,
      o_tbl_g_contacts_persons_filefoldersystem: observable,
      o_tbl_g_gcss_not_interested: observable,
      o_tbl_g_general_documents_filefoldersystem: observable,
      o_tbl_u_gcss_auto_searches: observable,
      o_tbl_u_notifications: observable,
      o_tbl_u_tasks: observable,
      o_tbl_z_ticket_initial_threads: observable,
      o_tbl_z_ticket_responses: observable,
      o_singleCaptureFullTextareaFieldsMap: observable,
      o_allCapturesNamesAndArchiveDateMapOfMaps: observable,
      o_progressChartLogPwinMapOfArrayOfObjs: observable,
      o_progressChartLogShapingMapOfArrayOfObjs: observable,
      o_progressChartLogStagesMapOfArrayOfObjs: observable,
      o_stripeCustomerDataObjOrUndefined: observable,

      c_loginTblNamesToLoadArray: computed,
      c_phase1TblNamesToLoadArray: computed,
      c_phase2TblNamesToLoadArray: computed,
      c_openCaptureTblNamesToLoadArray: computed,
      c_openCaptureChangeLogCardTblNamesToLoadArray: computed,
      c_contractsUserTblNamesToLoadArray: computed,
      c_budgetUserTblNamesToLoadArray: computed,
      c_gcssTabTblNamesToLoadArray: computed,
      c_helpPanelTblNamesToLoadArray: computed,
      c_adminChangelogTblNamesToLoadArray: computed,
      c_adminIntegrationsChangelogTblNamesToLoadArray: computed,
      c_adminImportTblNamesToLoadArray: computed,
      c_singleCaptureDirectAccessLinkLoginTblNamesToLoadArray: computed,
      c_singleCaptureDirectAccessLinkPhase1TblNamesToLoadArray: computed,
      c_singleCaptureDirectAccessLinkPhase2TblNamesToLoadArray: computed,
      c_tbl_bit_master_countries: computed,
      c_tbl_bit_master_departments_agencies: computed,
      c_tbl_bit_master_naics_codes: computed,
      c_tbl_bit_master_product_service_codes: computed,
      c_tbl_bit_master_set_asides: computed,
      c_tbl_bit_master_states: computed,
      c_tbl_bit_master_sub_tiers: computed,
      c_tbl_a_divisions: computed,
      c_tbl_a_users_per_email: computed,
      c_tbl_a_users: computed,
      c_tbl_a_stages_pool: computed,
      c_tbl_a_styling_string_presets: computed,
      c_tbl_a_budget_categories_pool: computed,
      c_tbl_a_shortcut_presets_capture_managers: computed,
      c_tbl_a_shortcut_presets_documents_card_folders: computed,
      c_tbl_a_capture_types_details_fields: computed,
      c_tbl_captures_fields: computed({keepAlive:true}),
      c_teammatesExtraFieldsArrayOfObjs: computed,
      c_contactsCompaniesExtraFieldsArrayOfObjs: computed,
      c_contactsPersonsExtraFieldsArrayOfObjs: computed,
      c_allTblsInfoObj: computed,
      c_bitCompanyDomainsDotExtsArray: computed,
      c_bitJumbleKey: computed,
      c_bitNumLicensesPurchased: computed,
      c_bitConsultantsLicenseCost: computed,
      c_bitReadOnlyLicenseCost: computed,
      c_bitLicenseStartDate: computed,
      c_bitLicenseNumMonths: computed,
      c_bitSuperAdminsMaxNum: computed,
      c_bitSuperAdminsLicenseCost: computed,
      c_bitGovConSmartSearchTF: computed,
      c_bitCexaiTF: computed,
      c_bitIdiqRapidResponseTF: computed,
      c_bitSingleCaptureDirectAccessLinksTF: computed,
      c_bitIntegrationSystemVantagePointTF: computed,
      c_bitSGTDocumentsCardUpgrade01TF: computed,
      c_bitSGTStripeCustomerID: computed,
      c_bitEnterpriseLicenseTF: computed,
      c_bitUsing3rdPartyIntegrationTF: computed,
      c_companyUsingDivisionFirewallingTF: computed,
      c_companyPrimeSBCertsCanVaryPerDivisionTF: computed,
      c_companyCreateNewCaptureDefaultCaptureTypeID: computed,
      c_companyRestrictAdvanceCaptureStage01: computed,
      c_companyRestrictAdvanceCaptureStageTF: computed,
      c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma: computed,
      c_companyRestrictAdvanceCaptureStageUserNamesMaskSortIfoObj: computed,
      c_companyTeammatesAllocOfCovAltFieldDisplayName: computed,
      c_companyTeammatesWorkshareTypeAltFieldDisplayName: computed,
      c_companyTeammatesWorkshareNumFtesAltFieldDisplayName: computed,
      c_companyTeammatesWorkshareNotesAltFieldDisplayName: computed,
      c_teammatesAllocOfCovFieldDisplayName: computed,
      c_teammatesWorkshareTypeFieldDisplayName: computed,
      c_teammatesWorkshareNumFtesFieldDisplayName: computed,
      c_teammatesWorkshareNotesFieldDisplayName: computed,
      c_companyTeammateContractsOnOtherTeammatesWhenSub01: computed,
      c_companyTeammateContractsOnOtherTeammatesWhenSubTF: computed,
      c_companyDateFormat: computed,
      c_companyDateFormatIsmdYTF: computed,
      c_companyDateFormatIsdmYTF: computed,
      c_companyDateFormatIsYmdTF: computed,
      c_companyDateFormatIsMjYTF: computed,
      c_companyCaptureNameFormat: computed,
      c_companyPerformanceGraphsAllUsersIgnoreSharedPercentCalcs01: computed,
      c_companyPerformanceGraphsAllUsersIgnoreSharedPercentCalcsTF: computed,
      c_companyTasksAssigneeCanEditDueDate01: computed,
      c_companyTasksAssigneeCanEditDueDateTF: computed,
      c_companyUsingCodenameTF: computed,
      c_companyShortcutPresetsCaptureManagersOnCreateNewCapture01: computed,
      c_companyShortcutPresetsCaptureManagersOnCreateNewCaptureTF: computed,
      c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCapture01: computed,
      c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF: computed,
      c_companyTeammateSurveysNotificationsToAllCaptureManagers01: computed,
      c_companyTeammateSurveysNotificationsToAllCaptureManagersTF: computed,
      c_companyFyrStartMonth1to12: computed,
      c_companyNewUserDefaultPassword: computed,
      c_companyDaysBetweenUserPasswordChange: computed,
      c_companyCaptureConsultantsCanViewParentIDIQs01: computed,
      c_companyUsingPwinTF: computed,
      c_companyPwinIsCalculatedTF: computed,
      c_companyPwinUsersCanEditTF: computed,
      c_companyPwinCalcMinThreshScore: computed,
      c_companyPwinCalcMinThreshPwin: computed,
      c_pwinCalcLinesSortedArrayOfObjs: computed,
      c_companyUsingPTDifferentiatorsTF: computed,
      c_companyUsingPTWinThemesTF: computed,
      c_companyUsingPTGhostThemesTF: computed,
      c_companyBudgetManagerApprovalNumDays: computed,
      c_companyBitMasterSetAsidesOnlyShortNames01: computed,
      c_companyBitMasterSetAsidesOnlyShortNamesTF: computed,
      c_companyBitMasterSetAsideIDsToNotIncludeArray: computed,
      c_companyBitMasterNaicsCodesAll0Only6Digit1: computed,
      c_companyBitMasterNaicsCodesRangesComma: computed,
      c_companyBitMasterProductServiceCodeIDsToNotIncludeArray: computed,
      c_companyBitMasterDepartmentsAgenciesIDsToNotIncludeArray: computed,
      c_companyBitMasterSubTiersIDsToNotIncludeArray: computed,
      c_companyIDIQVehicleWins0WinsAndActive1: computed,
      c_companyCaptureFavoritesColor: computed,
      c_companyGcssDataSourceSamShownTF: computed,
      c_companyGcssDataSourceGovWinShownTF: computed,
      c_companyGcssDataSourceFedCompShownTF: computed,
      c_gcssHasAtLeast1DataSourceShownTF: computed,
      c_companyGcssBlankDataOverwrite01: computed,
      c_companyUsingAutomatedCaptureArchivingTF: computed,
      c_companyCaptureArchivingClosedStageIDsComma: computed,
      c_companyCaptureArchivingNumDaysAfterDate: computed,
      c_companyCaptureArchivingDateFieldID: computed,
      c_companyCaptureArchivingNumDaysNoActivity: computed,
      c_companyCaptureArchivingSendGcssUpdateRemindersTF: computed,
      c_companyCaptureArchivingSendTaskRemindersTF: computed,
      c_companyCaptureArchivingSendAutomatedRemindersTF: computed,
      c_companySearchFieldsSeparateDivexecFields01: computed,
      c_companySearchFieldsSeparateDivexecFieldsTF: computed,
      c_companyIntegrationOnTF: computed,
      c_companyIntegrationOauthWebAddress: computed,
      c_companyIntegrationApiWebAddress: computed,
      c_companyIntegrationDatabaseName: computed,
      c_companyIntegrationClientIDScramble: computed,
      c_companyIntegrationClientSecretScramble: computed,
      c_companyIntegrationIntegrationUniqueIDCEFieldID: computed,
      c_companyIntegrationLogRecordSuccessesTF: computed,
      c_companyIntegrationSpecialIntegrationBlankDate: computed,
      c_companyIntegrationSpecialIntegrationBlankDateTime: computed,
      c_allCaptureCardsArrayOfObjs: computed,
      c_availableCaptureCardsArrayOfObjs: computed,
      c_availableDetailsCardIDsArray: computed,
      c_cardNameAdvanceStage: computed,
      c_cardNameDates: computed,
      c_cardNameTasks: computed,
      c_cardNameDetails: computed,
      c_cardNameDealShaping: computed,
      c_cardNameTeammates: computed,
      c_cardNameCompetitors: computed,
      c_cardNameProposalThemes: computed,
      c_cardNameRiskAssessment: computed,
      c_cardNameBudget: computed,
      c_cardNameConversations: computed,
      c_cardNameDocuments: computed,
      c_cardNameTemplates: computed,
      c_cardNameIDIQTaskOrders: computed,
      c_cardNameChangelog: computed,
      c_cardNameNotepad: computed,
      c_cardNameRevenue: computed,
      c_availableDetailsCardCustomOrCENamesArray: computed,
      c_cardAvailableTFAdvanceStage: computed,
      c_cardAvailableTFDates: computed,
      c_cardAvailableTFTasks: computed,
      c_cardAvailableTFDetails: computed,
      c_cardAvailableTFDealShaping: computed,
      c_cardAvailableTFTeammates: computed,
      c_cardAvailableTFCompetitors: computed,
      c_cardAvailableTFProposalThemes: computed,
      c_cardAvailableTFRiskAssessment: computed,
      c_cardAvailableTFBudget: computed,
      c_cardAvailableTFConversations: computed,
      c_cardAvailableTFDocuments: computed,
      c_cardAvailableTFTemplates: computed,
      c_cardAvailableTFIDIQTaskOrders: computed,
      c_cardAvailableTFChangelog: computed,
      c_cardAvailableTFNotepad: computed,
      c_cardAvailableTFRevenue: computed,
      c_customDealShapingQuestionsStringFromCardName: computed,
      c_allUserPowersDbNamesArray: computed,
      c_allUserPowersDataArrayOfObjs: computed,
      c_expandedUsersPerEmailNotDeactivatedMapOfMaps: computed,
      c_combinedUsersNotDeactivatedMapOfMaps: computed,
      c_allAdminPowerUsersNotDeactivatedArrayOfObjs: computed,
      c_userIDsOfAllAdminPowerUsersNotDeactivatedArray: computed,
      c_userPerEmailIDsOfAllAdminPowerUsersNotDeactivatedArray: computed,
      c_userIDsOfAllContractsPowerUsersNotDeactivatedArray: computed,
      c_userIDsOfAllBudgetPowerUsersNotDeactivatedArray: computed,
      c_allDivisionsArrayOfObjs: computed,
      c_allDivisionIDsArray: computed,
      c_allDivisionIDsComma: computed,
      c_topDivision00Map: computed,
      c_topDivision00ID: computed,
      c_topDivision00Name: computed,
      c_topDivision00BusinessTypeID: computed,
      c_topDivision00PrimeSBCertificationsBmSetAsideIDsComma: computed,
      c_allStagesArrayOfObjs: computed,
      c_activeStageIDsArray: computed,
      c_submittedStageIDsArray: computed,
      c_closedStageIDsArray: computed,
      c_closedWonStageIDsArray: computed,
      c_closedLostStageIDsArray: computed,
      c_closedNoBidStageIDsArray: computed,
      c_closedCancelledStageIDsArray: computed,
      c_activeAndClosedWonStageIDsArray: computed,
      c_lostNoBidOrCancelledStageIDsArray: computed,
      c_capturePriorityLevelsEditConstantsObj: computed,
      c_capturePriorityLevelsArrayOfObjs: computed,
      c_captureTypesArrayOfObjs: computed,
      c_captureTypeIDsArray: computed,
      c_captureTypeNamesArray: computed,
      c_firstCaptureTypeIDOrUndefined: computed,
      c_createNewCaptureAdminDefaultCaptureTypeIDOrM1: computed,
      c_idiqContractTypeIDsArray: computed,
      c_taskOrderContractTypeIDsArray: computed,
      c_reasonsWonIDsArray: computed,
      c_reasonsLostIDsArray: computed,
      c_firstBusinessTypeID: computed,
      c_pwinColorsArrayOfObjs: computed,
      c_shapingProgressColorsArrayOfObjs: computed,
      c_dateUpcomingColors1ArrayOfObjs: computed,
      c_teammatesContractTypesArrayOfObjs: computed,
      c_teammatesRatingsQuestionsArrayOfObjs: computed,
      c_expandedFilterPresetsArrayOfObjs: computed,
      c_expandedSortPresetsArrayOfObjs: computed,
      c_numCapturesLoadedInSystem: computed,
      c_allCapturesCurrentlyLoadedCaptureIDsAndNamesLowercaseArrayOfObjs: computed,
      c_allCapturesCurrentlyLoadedCaptureIDsArray: computed,
      c_allCapturesInDatabaseCaptureIDNamesAndArchiveDateArrayOfObjs: computed,
      c_archivedCaptureIDsArray: computed,
      c_numArchivedCaptures: computed,
      c_gcssAllAlreadyInCEImportedCapturesArrayOfObjs: computed,
      c_gcssAllSamSearchResultsMarkedAsNotInterestedGcssIDsArray: computed,
      c_gcssAllSamSearchResultsMarkedAsNotInterestedSolicitationNumbersArray: computed,
      c_gcssAllSamSearchResultsMarkedForImportSolicitationNumbersArray: computed,
      c_captureIDFieldZeroPaddingNumDigitsOrUndefined: computed,
      c_fieldMapOfCaptureID: computed,
      c_fieldMapOfCaptureManagers: computed,
      c_fieldMapOfDivisionOwners: computed,
      c_fieldMapOfCodename: computed,
      c_fieldMapOfOpportunityName: computed,
      c_fieldMapOfCaptureType: computed,
      c_fieldMapOfPrimeSub: computed,
      c_fieldMapOfContractType: computed,
      c_fieldMapOfStage: computed,
      c_fieldMapOfIdiqCaptureIDTOLink: computed,
      c_fieldMapOfContractOverallValue: computed,
      c_fieldMapOfContractRevenueValue: computed,
      c_fieldMapOfAllocatedRevenueValue: computed,
      c_fieldMapOfAllocatedNetValue: computed,
      c_fieldMapOfAllocatedRevenuePerMonth: computed,
      c_fieldMapOfAllocatedNetPerMonth: computed,
      c_fieldMapOfPwinAdjustedContractOverallValue: computed,
      c_fieldMapOfPwinAdjustedContractRevenueValue: computed,
      c_fieldMapOfPwinAdjustedAllocatedRevenueValue: computed,
      c_fieldMapOfPwinAdjustedAllocatedNetValue: computed,
      c_fieldMapOfIDIQTOAnticipatedValue: computed,
      c_fieldMapOfPeriodOfPerformance: computed,
      c_fieldMapOfReasonsWonLost: computed,
      c_fieldMapOfContractsManager: computed,
      c_fieldMapOfBudgetManager: computed,
      c_fieldMapOfOurPrimeSubTeammateDivisionID: computed,
      c_fieldMapOfOurPrimeSubTeammateAllocation: computed,
      c_fieldMapOfSmallBusinessAllocation: computed,
      c_fieldMapOfPwin: computed,
      c_fieldMapOfTotalShapingProgress: computed,
      c_fieldMapOfStageShapingProgress: computed,
      c_fieldMapOfPrimeCompany: computed,
      c_fieldMapOfIncumbentCompanies: computed,
      c_fieldMapOfOurIncumbentCompetitorDivisionID: computed,
      c_fieldMapOfAddedDate: computed,
      c_fieldMapOfRFPDate: computed,
      c_fieldMapOfProposalDueDateTimeUtc: computed,
      c_fieldMapOfAwardDate: computed,
      c_fieldMapOfContractStartDate: computed,
      c_fieldMapOfContractEndDate: computed,
      c_fieldMapOfDescriptionOfWork: computed,
      c_fieldMapOfNotepad: computed,
      c_fieldMapOfRecentPinnedNoteStamp: computed,
      c_fieldMapOfNextActiveTask: computed,
      c_fieldMapOfCaptureFavorites: computed,
      c_fieldMapOfCapturePriorityLevel: computed,
      c_fieldMapOfLastChangedDate: computed,
      c_fieldMapOfLastStageDate: computed,
      c_fieldMapOfLastProgressDate: computed,
      c_fieldMapOfLastPWinDate: computed,
      c_fieldMapOfLastRFPDate: computed,
      c_fieldMapOfArchiveDate: computed,
      c_fieldMapOfCompetitionTypeBmSetAsideIDsComma: computed,
      c_fieldMapOfNaicsCodeIDsComma: computed,
      c_fieldMapOfSolicitationNumber: computed,
      c_fieldMapOfGcssOverwriteDataWithUpdates01: computed,
      c_fieldMapOfGcssID: computed,
      c_fieldMapOfGcssGovWinIDString: computed,
      c_fieldMapOfGcssFedCompIDString: computed,
      c_fieldMapOfGcssOriginalPublishedDateTimeUtc: computed,
      c_fieldMapOfGcssLastUpdatedDateTimeUtc: computed,
      c_fieldMapOfGcssSamLink: computed,
      c_fieldMapOfGcssGovWinLink: computed,
      c_fieldMapOfGcssFedCompLink: computed,
      c_fieldMapOfGcssBmDepartmentAgencyID: computed,
      c_fieldMapOfGcssBmSubTierID: computed,
      c_fieldMapOfGcssOffice: computed,
      c_fieldMapOfGcssBmProductServiceCodeIDsComma: computed,
      c_fieldMapOfGcssBmContractOpportunityTypeID: computed,
      c_fieldMapOfGcssGovWinProcurementComment: computed,
      c_fieldMapOfGcssGovWinDuration: computed,
      c_fieldMapOfGcssGovWinPrimaryWorkRequired: computed,
      c_fieldMapOfGcssGovWinPriority1to5: computed,
      c_fieldMapOfGcssBmFedCompContractTypeID: computed,
      c_fieldMapOfGcssAwardNumber: computed,
      c_fieldMapOfGcssPlaceOfPerformanceAddress: computed,
      c_fieldMapOfGcssPlaceOfPerformanceCity: computed,
      c_fieldMapOfGcssPlaceOfPerformanceBmStateIDsComma: computed,
      c_fieldMapOfGcssPlaceOfPerformanceZip: computed,
      c_fieldMapOfGcssPlaceOfPerformanceProvince: computed,
      c_fieldMapOfGcssPlaceOfPerformanceBmCountryIDsComma: computed,
      c_fieldMapOfGcssContractingOfficeCity: computed,
      c_fieldMapOfGcssContractingOfficeBmStateID: computed,
      c_fieldMapOfGcssContractingOfficeZip: computed,
      c_fieldMapOfGcssContractingOfficeProvince: computed,
      c_fieldMapOfGcssContractingOfficeBmCountryID: computed,
      c_fieldMapOfGcssContractingOfficePOC1Textarea: computed,
      c_fieldMapOfGcssContractingOfficePOC2Textarea: computed,
      c_fieldMapOfGcssAwardeeInformationTextarea: computed,
      c_fieldMapOfGcssDocumentLinksJsonString: computed,
      c_valueDisplayArraysObjBitMasterDepartmentsAgencies: computed,
      c_valueDisplayArraysObjBitMasterSubTiers: computed,
      c_valueDisplayArraysObjBitMasterSetAsides: computed,
      c_valueDisplayArraysObjBitMasterSetAsidesShortNames: computed,
      c_valueDisplayArraysObjBitMasterContractOpportunityTypes: computed,
      c_valueDisplayArraysObjBitMasterFedCompContractTypeNames: computed,
      c_valueDisplayArraysObjBitMasterNaicsCodesCombinedNames: computed,
      c_valueDisplayArraysObjBitMasterNaicsCodesCodes: computed,
      c_valueDisplayArraysObjBitMasterNaicsCodesCodeDescOnlys: computed,
      c_valueDisplayArraysObjBitMasterProductServiceCodes: computed,
      c_valueDisplayArraysObjBitMasterStatesA2AndName: computed,
      c_valueDisplayArraysObjBitMasterCountriesA3AndName: computed,
      c_valueDisplayArraysObjUsersPerEmailNotDeactivated: computed,
      c_valueDisplayArraysObjUsers: computed,
      c_valueDisplayArraysObjUsersWithAdminPower: computed,
      c_valueDisplayArraysObjUsersWithContractsPower: computed,
      c_valueDisplayArraysObjUsersWithBudgetPower: computed,
      c_valueDisplayArraysObjDivisions: computed,
      c_valueDisplayArraysObjAdminAllDivisionsIncludingFirewalled: computed,
      c_valueDisplayArraysObjPrimeSub: computed,
      c_valueDisplayArraysObjContactPersons: computed,
      c_valueDisplayArraysObjStages: computed,
      c_valueDisplayArraysObjContactCompanies: computed,
      c_valueDisplayArraysObjContactCompaniesWithUsMinus2AtTop: computed,
      c_valueDisplayArraysObjCaptureTypes: computed,
      c_valueDisplayArraysObjContractTypes: computed,
      c_valueDisplayArraysObjCaptures: computed,
      c_valueDisplayArraysObjCapturesForceCaptureIDInName: computed,
      c_valueDisplayArraysObjIdiqCaptures: computed,
      c_valueDisplayArraysObjBusinessTypes: computed,
      c_valueDisplayArraysObjCapabilities: computed,
      c_valueDisplayArraysObjCaptureFlagPriorities: computed,
      c_valueDisplayArraysObjReasonsWonLost: computed,
      c_valueDisplayArraysObjReasonsWonOrBoth: computed,
      c_valueDisplayArraysObjReasonsLostOrBoth: computed,
      c_valueDisplayArraysObjShortcutPresetsCaptureManagerPresets: computed,
      c_valueDisplayArraysObjStylingStringPresets: computed,
      c_valueDisplayArraysObjTeammatesContractAgreementTypes: computed,
      c_valueDisplayArraysObjTeammatesWorkshareTypes: computed,
      c_valueDisplayArraysObjTeammatesContractStatuses: computed,
      c_valueDisplayArraysObjCaptureFields: computed,
      c_valueDisplayArraysObjMassEditableCaptureFields: computed,
      c_valueDisplayArraysObjCaptureFieldsDates: computed,
      c_valueDisplayArraysObjCaptureFieldsMoneyRevenueCard: computed,
      c_valueDisplayArraysObjCaptureShapingQuestionTags: computed,
      c_valueDisplayArraysObjPTDifferentiators: computed,
      c_valueDisplayArraysObjRisksCategories: computed,
      c_valueDisplayArraysObjRisksImpactLevels: computed,
      c_valueDisplayArraysObjRisksProbabilityLevels: computed,
      c_valueDisplayArraysObjDeleteCaptureReasons: computed,
      c_valueDisplayArraysObjGcssNotInterestedReasons: computed,
      c_valueDisplayArraysObjAdminUserPowersWithSuperAdminLicenseCost: computed,
      c_allTblCapsMapOfComputedTblCapObjs: computed,
      c_allQuestionIDsArrayAndFieldTypeObjsArrayObj: computed,
      c_selectTypeQuestionFieldTypeObjNoSwsDataObj: computed,
      c_scoredTextTypeQuestionFieldTypeObj: computed,
      c_scoredTextareaTypeQuestionFieldTypeObj: computed,
      c_scoredTextScoreHiddenTypeQuestionFieldTypeObj: computed,
      c_scoredTextareaScoreHiddenTypeQuestionFieldTypeObj: computed,
      c_genericTextFieldTypeObj: computed,
      c_genericTextareaFieldTypeObj: computed,
      c_genericEmailFieldTypeObj: computed,
      c_genericPhoneFieldTypeObj: computed,
      c_genericWebsiteFieldTypeObj: computed,
      c_genericIntFieldTypeObj: computed,
      c_genericIntPositiveFieldTypeObj: computed,
      c_genericInt0to9FieldTypeObj: computed,
      c_genericPercentIntm1000to100FieldTypeObj: computed,
      c_genericDecimalFieldTypeObj: computed,
      c_genericDecimalPositiveFieldTypeObj: computed,
      c_genericPercentIntFieldTypeObj: computed,
      c_genericPercentInt0to100FieldTypeObj: computed,
      c_genericPercentDecimalFieldTypeObj: computed,
      c_genericPercentDecimal0to100FieldTypeObj: computed,
      c_genericProgressBarShapingFieldTypeObj: computed,
      c_genericMoneyFieldTypeObj: computed,
      c_genericMoneyCentsFieldTypeObj: computed,
      c_genericColorFieldTypeObj: computed,
      c_genericDateFieldTypeObj: computed,
      c_genericDateFromRawDateTimeUtcFieldTypeObj: computed,
      c_genericDateYmdFromRawDateTimeUtcFieldTypeObj: computed,
      c_genericDateDMjYFieldTypeObj: computed,
      c_genericDateDayMdyDaysUntil1FieldTypeObj: computed,
      c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj: computed,
      c_genericDateDayMdyDaysUntil2FieldTypeObj: computed,
      c_genericDateDayMdyDaysUntil2OverdueFieldTypeObj: computed,
      c_genericDateWithRelativeDateFieldTypeObj: computed,
      c_genericDateWithDurationFieldTypeObj: computed,
      c_genericDateTimeFieldTypeObj: computed,
      c_genericDateTimeNaturalFieldTypeObj: computed,
      c_genericDateTimeYmdgiaFieldTypeObj: computed,
      c_genericDateTimeYmdgiaNaturalFieldTypeObj: computed,
      c_genericDateTimeMjYgiAFieldTypeObj: computed,
      c_genericDateTimeMjYgiANaturalFieldTypeObj: computed,
      c_genericDateTimeDayMdyDaysUntil1FieldTypeObj: computed,
      c_genericDateTimeDayMdyDaysUntil1OverdueFieldTypeObj: computed,
      c_genericDateTimeDayMdyDaysUntil2FieldTypeObj: computed,
      c_genericDateTimeDayMdyDaysUntil2OverdueFieldTypeObj: computed,
      c_genericStylingStringFieldTypeObj: computed,
      c_genericStylingStringFontBlueFieldTypeObj: computed,
      c_genericStylingStringFontDarkGrayBoldFieldTypeObj: computed,
      c_selectMultiBitMasterDepartmentsAgenciesFieldTypeObj: computed,
      c_selectMultiBitMasterSubTiersFieldTypeObj: computed,
      c_selectBitMasterSetAsideFieldTypeObj: computed,
      c_selectMultiBitMasterSetAsidesFieldTypeObj: computed,
      c_selectMultiBitMasterSetAsidesShortNamesFieldTypeObj: computed,
      c_selectMultiBitMasterContractOpportunityTypesFieldTypeObj: computed,
      c_selectMultiBitMasterFedCompContractTypeNamesFieldTypeObj: computed,
      c_selectMultiBitMasterNaicsCodesCombinedNamesFieldTypeObj: computed,
      c_selectMultiBitMasterProductServiceCodesFieldTypeObj: computed,
      c_selectMultiBitMasterStatesA2AndNameFieldTypeObj: computed,
      c_selectMultiBitMasterCountriesA3AndNameFieldTypeObj: computed,
      c_selectUserPerEmailNotDeactivatedFieldTypeObj: computed,
      c_selectMultiUsersPerEmailNotDeactivatedFieldTypeObj: computed,
      c_selectMultiUsersPerEmailNotDeactivatedOrAllFieldTypeObj: computed,
      c_selectUserFieldTypeObj: computed,
      c_selectMultiUsersFieldTypeObj: computed,
      c_selectUserWithAdminPowerFieldTypeObj: computed,
      c_selectUserWithContractsPowerFieldTypeObj: computed,
      c_selectUserWithBudgetPowerFieldTypeObj: computed,
      c_selectDivisionFieldTypeObj: computed,
      c_selectMultiDivisionsFieldTypeObj: computed,
      c_selectMultiDivisionsOrAllFieldTypeObj: computed,
      c_selectMultiAdminAllDivisionsIncludingFirewalledFieldTypeObj: computed,
      c_selectCaptureTypeFieldTypeObj: computed,
      c_selectCaptureTypeNoClearFieldTypeObj: computed,
      c_selectMultiStagesFieldTypeObj: computed,
      c_selectContactCompanyFieldTypeObj: computed,
      c_selectMultiContactCompaniesFieldTypeObj: computed,
      c_selectContactCompanyWithUsMinus2AtTopFieldTypeObj: computed,
      c_selectMultiContactCompaniesWithUsMinus2AtTopFieldTypeObj: computed,
      c_selectContactPersonFieldTypeObj: computed,
      c_selectMultiContactPersonsFieldTypeObj: computed,
      c_selectContactCompanyExtraFieldFieldTypeObj: computed,
      c_selectDealShapingQuestionsSelectFieldTypeObj: computed,
      c_selectDealShapingQuestionsTextareaFieldTypeObj: computed,
      c_selectNoClearDealShapingQuestionTagsFieldTypeObj: computed,
      c_selectMultiDealShapingQuestionTagsFieldTypeObj: computed,
      c_selectMultiAddDealShapingQuestionTagsFieldTypeObj: computed,
      c_selectBusinessTypeFieldTypeObj: computed,
      c_selectMultiBusinessTypesFieldTypeObj: computed,
      c_selectMultiCapabilitiesFieldTypeObj: computed,
      c_selectMultiAddCapabilitiesFieldTypeObj: computed,
      c_selectCaptureFlagFieldTypeObj: computed,
      c_selectShortcutPresetsCaptureManagerPresetFieldTypeObj: computed,
      c_selectShortcutPresetsDocumentsCardFoldersPresetFieldTypeObj: computed,
      c_selectStylingStringPresetFieldTypeObj: computed,
      c_selectTeammatesContractAgreementTypeFieldTypeObj: computed,
      c_selectTeammatesWorkshareTypeFieldTypeObj: computed,
      c_selectTeammatesContractStatusTypeFieldTypeObj: computed,
      c_selectAddPTDifferentiatorsFieldTypeObj: computed,
      c_selectRisksCategoriesFieldTypeObj: computed,
      c_selectRisksImpactLevelFieldTypeObj: computed,
      c_selectRisksProbabilityLevelFieldTypeObj: computed,
      c_selectBudgetCategoryFieldTypeObj: computed,
      c_selectBudgetExpenseTypeFieldTypeObj: computed,
      c_selectPrimeSubFieldTypeObj: computed,
      c_selectMultiChangelogCaptureCardsFieldTypeObj: computed,
      c_selectCaptureFieldFieldTypeObj: computed,
      c_selectMultiCaptureFieldsFieldTypeObj: computed,
      c_selectNoClearCaptureFieldFieldTypeObj: computed,
      c_selectMassEditableCaptureFieldFieldTypeObj: computed,
      c_selectCaptureDateFieldFieldTypeObj: computed,
      c_selectCaptureRevenueCardMoneyFieldFieldTypeObj: computed,
      c_ganttPresetEditorSelectMultiCaptureDateFieldsFieldTypeObj: computed,
      c_selectMultiAddDeleteCaptureReasonsFieldTypeObj: computed,
      c_selectMultiAddGcssNotInterestedReasonsFieldTypeObj: computed,
      c_selectUserPowerWithSuperAdminLicenseCostFieldTypeObj: computed,
      c_selectStageType1234567FieldTypeObj: computed,
      c_selectAdminImportCaptureImportPresetsFieldTypeObj: computed,
      c_selectAdminImportCaptureUpdatePresetsFieldTypeObj: computed,
      c_selectAdminFontColorFieldTypeObj: computed,
      c_selectMonth1to12FieldTypeObj: computed,
      c_selectAllCapturesNoClearFieldTypeObj: computed,
      c_selectAllCapturesFieldTypeObj: computed,
      c_selectMultiAllCapturesFieldTypeObj: computed,
      c_selectAllCapturesDisplayWithClickLinkFieldTypeObj: computed,
      c_selectAllCapturesForcedCaptureIDInNameFieldTypeObj: computed,
      c_selectExcelReportTemplateFieldTypeObj: computed,
      c_selectSingleCaptureTemplateFieldTypeObj: computed,
      c_selectDateWithRelativeDateFixedOrRelativeDurationTypeFieldTypeObj: computed,
      c_selectDurationType1234DaysWeeksMonthsYearsFieldTypeObj: computed,
      c_selectFileFolderSystemFileAccessFieldTypeObj: computed,
      c_selectFileFolderSystemXmlTypeFieldTypeObj: computed,
      c_selectOperatorFieldTypeObj: computed,
      c_selectDayOfTheWeekFieldTypeObj: computed,
      c_selectDateOfTheMonth1stTo31stFieldTypeObj: computed,
      c_selectTimeHour1to12AmPmFieldTypeObj: computed,

      a_clear_all_local_tbls: action,
      a_clear_local_tbls_from_tbl_names_array: action,
      a_insert_or_update_local_data_map: action,
      a_update_local_data_mapOfMaps_if_existing_otherwise_insert_with_fake_id: action,
      a_insert_or_update_multiple_local_data_maps_with_uncompressed_data_tbls_obj: action,
      a_delete_local_data_mapOfMaps_item_from_id_or_id_array: action,
      a_resort_tbl: action,
      a_local_alter_tbl_insert_column: action,
      a_local_alter_tbl_delete_column: action,
      a_local_create_new_tbl_cap_table: action,
      a_local_delete_tbl_cap_table: action,
      a_overwrite_progress_chart_log_tbl_from_php_raw_data: action,
      a_create_new_capture_inject_id_field_into_new_local_capture_record: action,
      a_set_stripe_account_obj_or_undefined: action
    });
  }



  //===computed fields autoupdated when observable fields change=================================================================================
  //login tbls need to include all tbls that should be in place before captures enter the system, thus all admin tbls (BIT/company/user/division structure, etc), plus master/filters and divexec parts need to be loaded
  get c_loginTblNamesToLoadArray() {
    return(JSFUNC.concat_arrays_or_values_into_new_array(this.k_bitCompaniesTblNamesArray, this.k_bitMasterTblNamesArray, this.k_adminTblNamesArray, this.k_adminIntegrationsTblNamesArray, this.k_divexecTblNamesArray, this.k_filterTblNamesArray, this.k_gcssLoadOnLoginTblNamesArray));
  }

  //phase 1 is specifically all filtered (capture consultant user, division firewalling, capture archiving) 'slim' (short textareas) captures, plus any capture tbl_c_ tbls loaded in their entirety (also filtered by captureIDs retrieved from capture tbl loading)
  get c_phase1TblNamesToLoadArray() {
    //load all (or filtered) slim captures
    var phase1TblNamesToLoadArray = this.k_capturesTblNamesArray;

    //all users load the full tbl_c_ capture supplement tbls (tbl_c_teammates, etc) and hold them in memory even when single captures are opened and closed
    phase1TblNamesToLoadArray = JSFUNC.concat_arrays_or_values_into_new_array(phase1TblNamesToLoadArray, this.k_captureSupplementTblNamesFullyLoadedAtLogin);

    //contract users load full contract tbls for todo summaries
    if(UserMobx.c_combinedUserObj.powerHasContractsPowerTF) {
      phase1TblNamesToLoadArray = JSFUNC.concat_arrays_or_values_into_new_array(phase1TblNamesToLoadArray, this.c_contractsUserTblNamesToLoadArray);
    }

    //budget users load full budget tbls for todo summaries
    if(UserMobx.c_combinedUserObj.powerHasBudgetPowerTF) {
      phase1TblNamesToLoadArray = JSFUNC.concat_arrays_or_values_into_new_array(phase1TblNamesToLoadArray, this.c_budgetUserTblNamesToLoadArray);
    }

    return(phase1TblNamesToLoadArray);
  }

  //phase 2 loads tbls that can wait until after captures are loaded, such as contacts (without contact documents which load when a contact is opened), general docs, and right panel (help/tasks/notifications/tickets)
  get c_phase2TblNamesToLoadArray() {
    return(JSFUNC.concat_arrays_or_values_into_new_array(this.k_contactsTblNamesArray, this.k_generalDocumentsTblNamesArray, this.k_notificationTblNamesArray, this.k_taskTblNamesArray, this.k_ticketTblNamesArray, this.k_allCapturesNamesReferenceForCreateNewCaptureTblNamesArray));
  }

  //open capture loads tbl_c_ tbls filtered based on which captureID was just opened as the open capture
  get c_openCaptureTblNamesToLoadArray() {
    //remove fully loaded tbl_c_ tbls (tbl_c_teammates, etc) since those tbls are loaded in entirety during login and remains in memory (loading a captureID filtered version here would overwrite the full data tbl in local memory)
    var openCaptureTblNamesToLoadArray = JSFUNC.remove_all_values_from_array(this.k_captureSupplementTblNamesFullyLoadedAtLogin, this.k_captureSupplementTblNamesFilteredForSingleOpenedCaptureArray);

    //if this is a contracts user, don't load the tbls they already have loaded in their entirety in memory from phase 1, remove those tbl names from this load
    if(UserMobx.c_combinedUserObj.powerHasContractsPowerTF) {
      openCaptureTblNamesToLoadArray = JSFUNC.remove_all_values_from_array(this.c_contractsUserTblNamesToLoadArray, openCaptureTblNamesToLoadArray);
    }

    //if this is a budget user, don't load the tbls they already have loaded in their entirety in memory from phase 1, remove those tbl names from this load
    if(UserMobx.c_combinedUserObj.powerHasBudgetPowerTF) {
      openCaptureTblNamesToLoadArray = JSFUNC.remove_all_values_from_array(this.c_budgetUserTblNamesToLoadArray, openCaptureTblNamesToLoadArray);
    }

    return(openCaptureTblNamesToLoadArray);
  }

  //changelog tbls loading filtered data for the currently open captureID when the changelog card is opened
  get c_openCaptureChangeLogCardTblNamesToLoadArray() {
    return(this.k_captureSupplementChangeLogTblNamesArray);
  }

  get c_contractsUserTblNamesToLoadArray() {
    return(["tbl_c_teammates_contracts", "tbl_c_teammates_contracts_filefoldersystem"]);
  }

  get c_budgetUserTblNamesToLoadArray() {
    return(["tbl_c_budget_expenses", "tbl_c_budget_funding_requests"]);
  }

  //gcss tab some tbls only loaded when tab is first accessed
  get c_gcssTabTblNamesToLoadArray() {
    return(this.k_gcssLoadOnTabTblNamesArray);
  }

  //help panel content only loaded when help panel is opened
  get c_helpPanelTblNamesToLoadArray() {
    if(CaptureExecMobx.c_productIsSamGovTrackerTF) {
      return(this.k_bitHelpPanelSGTTblNamesArray); //has "SGT" strings in this array, are loaded from "_sgt_" help tbls in memory, but then finally are loaded into user browser memory into the CE generic version of the help Map() mapOfMaps
    }
    return(this.k_bitHelpPanelTblNamesArray);
  }

  //admin setup changelog tbl loaded separately when tab is opened
  get c_adminChangelogTblNamesToLoadArray() {
    return(this.k_adminSupplementChangelogTblNamesArray);
  }

  //admin integrations changelog tbl loaded separately when subtab within 3rd Party Integrations subtab is opened
  get c_adminIntegrationsChangelogTblNamesToLoadArray() {
    return(this.k_adminIntegrationsSupplementChangelogTblNamesArray);
  }

  //admin import tbls loaded separately during import process as they are not used by any other user
  get c_adminImportTblNamesToLoadArray() {
    return(this.k_adminImportTblNamesArray);
  }

  //direct access link to a single capture bypassing login loads almost every tbl into memory, with tbl_captures and all capture supplemental tbls filtered to 1 specific capture (don't need adminImport, captureChangeLogs, divexec, filter, contactDocs, user, tickets)
  get c_singleCaptureDirectAccessLinkLoginTblNamesToLoadArray() { //single capture login loads admin
    return(JSFUNC.concat_arrays_or_values_into_new_array(this.k_bitCompaniesTblNamesArray, this.k_bitMasterTblNamesArray, this.k_adminTblNamesArray));
  }

  get c_singleCaptureDirectAccessLinkPhase1TblNamesToLoadArray() { //single capture phase1 loads single capture with supplement data (including filtering tasks by single captureID)
    return(JSFUNC.concat_arrays_or_values_into_new_array(this.k_capturesTblNamesArray, this.k_captureSupplementTblNamesFullyLoadedAtLogin, this.k_captureSupplementTblNamesFilteredForSingleOpenedCaptureArray, this.k_taskTblNamesArray));
  }

  get c_singleCaptureDirectAccessLinkPhase2TblNamesToLoadArray() { //single capture phase2 loads contacts
    return(this.k_contactsTblNamesArray);
  }



  get c_allTblNamesArray() {
    var allTblNamesArray = JSFUNC.concat_arrays_or_values_into_new_array(
      this.k_bitCompaniesTblNamesArray,
      this.k_bitMasterTblNamesArray,
      this.k_bitHelpPanelTblNamesArray,
      this.k_adminTblNamesArray,
      this.k_adminSupplementChangelogTblNamesArray,
      this.k_adminImportTblNamesArray,
      this.k_capturesTblNamesArray,
      this.k_captureSupplementTblNamesFullyLoadedAtLogin,
      this.k_captureSupplementTblNamesFilteredForSingleOpenedCaptureArray,
      this.k_captureSupplementChangeLogTblNamesArray,
      this.k_divexecTblNamesArray,
      this.k_filterTblNamesArray,
      this.k_contactsTblNamesArray,
      this.k_contactDocumentsTblNamesArray,
      this.k_generalDocumentsTblNamesArray,
      this.k_gcssLoadOnLoginTblNamesArray,
      this.k_gcssLoadOnTabTblNamesArray,
      this.k_taskTblNamesArray,
      this.k_notificationTblNamesArray,
      this.k_ticketTblNamesArray,
      this.k_allCapturesNamesReferenceForCreateNewCaptureTblNamesArray
    );
    return(JSFUNC.unique(allTblNamesArray));
  }


  //---------------------------------------------------------------------------------------------------------
  //tbls that have extra computed fields added to their observable raw database data columns (for each tbl utilized throughout the code, use c_tblName rather than o_tblName if it has been defined here)
  //---------------------------------------------------------------------------------------------------------
  get c_tbl_bit_master_countries() {
    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_bit_master_countries) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var a3 = dbRowMap.get("a3");
      var name = dbRowMap.get("name");
      var altNamesSemi = dbRowMap.get("alt_names_semi");

      var a3AndName = "[" + a3 + "] " + name;

      var allNameVariationsLowercaseArray = [];
      allNameVariationsLowercaseArray.push(JSFUNC.str2lower(a3, ""));
      allNameVariationsLowercaseArray.push(JSFUNC.str2lower(name, ""));
      if(altNamesSemi !== "") {
        var altNamesArray = JSFUNC.convert_semicolon_list_to_array(altNamesSemi);
        for(let altName of altNamesArray) {
          allNameVariationsLowercaseArray.push(JSFUNC.str2lower(altName, ""));
        }
      }

      var sortNameUSOnTop = "";
      if(a3 === "USA") {
        sortNameUSOnTop = "1" + name;
      }
      else {
        sortNameUSOnTop = "2" + name;
      }

      expandedDbRowMap.set("a3AndName", a3AndName);
      expandedDbRowMap.set("allNameVariationsLowercaseArray", allNameVariationsLowercaseArray);
      expandedDbRowMap.set("sortNameUSOnTop", sortNameUSOnTop);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_bit_master_departments_agencies() {
    const c_companyBitMasterDepartmentsAgenciesIDsToNotIncludeArray = this.c_companyBitMasterDepartmentsAgenciesIDsToNotIncludeArray;

    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_bit_master_departments_agencies) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var id = dbRowMap.get("id");
      var deptAgNotIncludedTF = JSFUNC.in_array(id, c_companyBitMasterDepartmentsAgenciesIDsToNotIncludeArray);

      expandedDbRowMap.set("departmentAgencyHiddenUnlessCheckedTF", deptAgNotIncludedTF);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_bit_master_naics_codes() {
    //determine if company is minimizing the BIT Master NAICS Codes using code ranges
    const c_companyBitMasterNaicsCodesAll0Only6Digit1 = this.c_companyBitMasterNaicsCodesAll0Only6Digit1;
    const c_companyBitMasterNaicsCodesRangesComma = this.c_companyBitMasterNaicsCodesRangesComma;

    const companyBitMasterNaicsCodesOnly6DigitCodesTF = (c_companyBitMasterNaicsCodesAll0Only6Digit1 === 1);

    const usingNaicsRangesTF = (JSFUNC.is_string(c_companyBitMasterNaicsCodesRangesComma) && (c_companyBitMasterNaicsCodesRangesComma !== ""));
    var naicsRangesArrayOfObjs = [];
    if(usingNaicsRangesTF) {
      const numbersHyphensCommasRegExp = /[^0-9,-]/g;
      const naicsRangesComma = c_companyBitMasterNaicsCodesRangesComma.replace(numbersHyphensCommasRegExp, "");
      const naicsRangesArray = JSFUNC.convert_comma_list_to_array(naicsRangesComma);
      for(let naicsRangeString of naicsRangesArray) {
        var hyphenSplitArray = naicsRangeString.split("-");
        if(hyphenSplitArray.length === 2) {
          naicsRangesArrayOfObjs.push({
            min: hyphenSplitArray[0],
            max: hyphenSplitArray[1]
          });
        }
      }
    }

    //build the minimized list of expanded NAICS codes with their titles and ARL/ME
    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_bit_master_naics_codes) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var code = dbRowMap.get("code");
      var codeString = JSFUNC.num2str(code);

      var codeStringLength = -1;
      if(usingNaicsRangesTF || companyBitMasterNaicsCodesOnly6DigitCodesTF) {
        codeStringLength = codeString.length;
      }

      //determine if this NAICS code is within the NAICS ranges specified
      var includeCurrentNaicsCodeTF = true; //naics code is always within the range if admin has not specified ranges
      if(usingNaicsRangesTF) {
        //if naics code is "123456" it stays as "123456", if naics code is "11" it becomes "110000"
        var code6DigitString = codeString;
        for(let z = 0; z < (6 - codeStringLength); z++) {
          code6DigitString += "0";
        }

        //check if the 6 digit version of the code falls between any of the admin specified naics code ranges (example "100000-299999,500000-599999")
        includeCurrentNaicsCodeTF = false;
        for(let naicsRangeObj of naicsRangesArrayOfObjs) {
          if((code6DigitString >= naicsRangeObj.min) && (code6DigitString <= naicsRangeObj.max)) {
            includeCurrentNaicsCodeTF = true;
            break;
          }
        }
      }

      //determine if this NAICS code is 6 digits if admin has specified to only include 6 digit NAICS codes
      if(companyBitMasterNaicsCodesOnly6DigitCodesTF) {
        if(includeCurrentNaicsCodeTF) {
          includeCurrentNaicsCodeTF = (codeStringLength === 6);
        }
      }

      //compute naics code size and combined name
      var title = dbRowMap.get("title");
      var annualRevenueLimit0MaximumNumEmployees1 = dbRowMap.get("arl0_me1");
      var size = dbRowMap.get("size");
      var exceptionsText = dbRowMap.get("exceptions");
      var madeObsoleteInYearIntOr0 = dbRowMap.get("made_obsolete_in_year");
      var obsoleteReplacedByCodeIntOr0 = dbRowMap.get("obsolete_replaced_by_code");

      //put together combined name with 'code - [OBSOLETE (year)] title (revenue/employees) [exceptions]'
      var combinedName = codeString + " - ";

      if(madeObsoleteInYearIntOr0 > 0) {
        combinedName += "[OBSOLETE in " + madeObsoleteInYearIntOr0;
        if(obsoleteReplacedByCodeIntOr0 > 0) {
          combinedName += " (replaced by " + obsoleteReplacedByCodeIntOr0 + ")";
        }
        combinedName += "] ";
      }

      combinedName += title;

      if(size > 0) {
        combinedName += " (";
        if(annualRevenueLimit0MaximumNumEmployees1 === 1) {
          combinedName += JSFUNC.money(size, 0, false) + " Employees";
        }
        else {
          combinedName += JSFUNC.money_short(size);
        }
        combinedName += ")";
      }

      //version of name with code and description, but not including the exceptions (used in codeword masking for NAICS)
      var codeDescOnly = combinedName;

      if(JSFUNC.is_string(exceptionsText) && (exceptionsText != "")) {
        combinedName += " [" + exceptionsText + "]";
      }

      expandedDbRowMap.set("codeString", codeString);
      expandedDbRowMap.set("codeDescOnly", codeDescOnly);
      expandedDbRowMap.set("combinedName", combinedName);
      expandedDbRowMap.set("naicsCodeHiddenUnlessCheckedTF", (!includeCurrentNaicsCodeTF));

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_bit_master_product_service_codes() {
    const c_companyBitMasterProductServiceCodeIDsToNotIncludeArray = this.c_companyBitMasterProductServiceCodeIDsToNotIncludeArray;

    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_bit_master_product_service_codes) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var id = dbRowMap.get("id");
      var pscNotIncludedTF = JSFUNC.in_array(id, c_companyBitMasterProductServiceCodeIDsToNotIncludeArray);

      expandedDbRowMap.set("productServiceCodeHiddenUnlessCheckedTF", pscNotIncludedTF);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_bit_master_set_asides() {
    const c_companyBitMasterSetAsidesOnlyShortNamesTF = this.c_companyBitMasterSetAsidesOnlyShortNamesTF;
    const c_companyBitMasterSetAsideIDsToNotIncludeArray = this.c_companyBitMasterSetAsideIDsToNotIncludeArray;

    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_bit_master_set_asides) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var id = dbRowMap.get("id");
      var isHiddenDuplicateOfRowIDOr0 = dbRowMap.get("is_hidden_duplicate_of_row_id_or_0"); //used to mark if certain entries have variations of `gcss_name` used by SAM.gov that represent the same Set-Aside name/short_name used in CaptureExec, don't include these duplicate entries in CaptureExec
      var name = dbRowMap.get("name");
      var shortName = dbRowMap.get("short_name");

      var isHiddenDuplicateRowTF = JSFUNC.is_number_not_nan_gt_0(isHiddenDuplicateOfRowIDOr0);
      var nameLowercase = name.toLowerCase();
      var shortNameLowercase = shortName.toLowerCase();

      var shortNameDashName = "";
      var shortNameOrNameIfBlank = ""; //use short name if filled out, otherwise use the full name
      var bracketsShortNameName = ""; //include [short name] in brackets first if different from full name
      var nameSort = "";
      var shortNameOrNameIfBlankSort = "";
      var bracketsShortNameNameSort = "";
      if(c_companyBitMasterSetAsidesOnlyShortNamesTF) {
        shortNameDashName = shortName;
        shortNameOrNameIfBlank = shortName;
        bracketsShortNameName = shortName;
        nameSort = shortNameLowercase;
        shortNameOrNameIfBlankSort = shortNameLowercase;
        bracketsShortNameNameSort = shortNameLowercase;
      }
      else {
        if(name !== "" && shortName !== "") {
          shortNameDashName = shortName + " - " + name;
          shortNameOrNameIfBlank = shortName;
          shortNameOrNameIfBlankSort = shortNameLowercase;
          if(name === shortName) {
            bracketsShortNameName = name;
            bracketsShortNameNameSort = nameLowercase;
          }
          else {
            bracketsShortNameName = "[" + shortName + "] " + name;
            bracketsShortNameNameSort = shortNameLowercase + " " + nameLowercase;
          }
        }
        else if(name === "" && shortName !== "") {
          shortNameDashName = shortName;
          shortNameOrNameIfBlank = shortName;
          shortNameOrNameIfBlankSort = shortNameLowercase;
          bracketsShortNameName = shortName;
          bracketsShortNameNameSort = shortNameLowercase;
        }
        else if(name !== "" && shortName === "") {
          shortNameDashName = name;
          shortNameOrNameIfBlank = name;
          shortNameOrNameIfBlankSort = nameLowercase;
          bracketsShortNameName = name;
          bracketsShortNameNameSort = nameLowercase;
        }

        nameSort = nameLowercase;
      }

      //mark that the set-aside is not included in the normal list of select options
      var setAsideHiddenUnlessCheckedTF = false;
      if(JSFUNC.in_array(id, c_companyBitMasterSetAsideIDsToNotIncludeArray)) {
        setAsideHiddenUnlessCheckedTF = true; //hidden if the admin has unchecked this set-aside from the master list in "Details Fields Options"
      }
      else if(isHiddenDuplicateRowTF) {
        setAsideHiddenUnlessCheckedTF = true; //if this is a duplicate SAM.gov gcss_name entry, do not show it in the select list
      }

      expandedDbRowMap.set("isHiddenDuplicateRowTF", isHiddenDuplicateRowTF);
      expandedDbRowMap.set("shortNameDashName", shortNameDashName);
      expandedDbRowMap.set("shortNameOrNameIfBlank", shortNameOrNameIfBlank);
      expandedDbRowMap.set("bracketsShortNameName", bracketsShortNameName);
      expandedDbRowMap.set("nameSort", nameSort);
      expandedDbRowMap.set("bracketsShortNameNameSort", bracketsShortNameNameSort);
      expandedDbRowMap.set("setAsideHiddenUnlessCheckedTF", setAsideHiddenUnlessCheckedTF);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_bit_master_states() {
    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_bit_master_states) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var a2 = dbRowMap.get("a2");
      var name = dbRowMap.get("name");
      var altNamesSemi = dbRowMap.get("alt_names_semi");

      var a2AndName = "(" + a2 + ") " + name;

      var allNameVariationsLowercaseArray = [];
      allNameVariationsLowercaseArray.push(JSFUNC.str2lower(a2, ""));
      allNameVariationsLowercaseArray.push(JSFUNC.str2lower(name, ""));
      if(altNamesSemi !== "") {
        var altNamesArray = JSFUNC.convert_semicolon_list_to_array(altNamesSemi);
        for(let altName of altNamesArray) {
          allNameVariationsLowercaseArray.push(JSFUNC.str2lower(altName, ""));
        }
      }

      expandedDbRowMap.set("a2AndName", a2AndName);
      expandedDbRowMap.set("allNameVariationsLowercaseArray", allNameVariationsLowercaseArray);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_bit_master_sub_tiers() {
    const c_companyBitMasterSubTiersIDsToNotIncludeArray = this.c_companyBitMasterSubTiersIDsToNotIncludeArray;

    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_bit_master_sub_tiers) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var id = dbRowMap.get("id");
      var name = dbRowMap.get("name");
      var duplicateMapToOtherIDOr0 = dbRowMap.get("duplicate_map_to_other_id_or_0");
      var bitOverwriteDisplayName = dbRowMap.get("bit_overwrite_display_name");

      var displayName = name;
      if(JSFUNC.string_is_filled_out_tf(bitOverwriteDisplayName)) {
        displayName = bitOverwriteDisplayName;
      }
      
      var adminSelectedSubtierToNotIncludeTF = JSFUNC.in_array(id, c_companyBitMasterSubTiersIDsToNotIncludeArray);
      var isDuplicateEntryTF = (duplicateMapToOtherIDOr0 > 0); //if there are multiple entries in the tbl from SAM of different spellings in the 'name' column, one is designated as the entry for that subtier, and all others are marked as 'duplicates' pointing to the rowID of the designated BIT master tbl entry, hide those duplicates

      expandedDbRowMap.set("displayName", displayName);
      expandedDbRowMap.set("isDuplicateEntryTF", isDuplicateEntryTF);
      expandedDbRowMap.set("subTierHiddenUnlessCheckedTF", (adminSelectedSubtierToNotIncludeTF || isDuplicateEntryTF));

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }

  get c_tbl_a_divisions() {
    const o_tbl_a_divisions = this.o_tbl_a_divisions;
    const c_companyPrimeSBCertsCanVaryPerDivisionTF = this.c_companyPrimeSBCertsCanVaryPerDivisionTF;
    const c_allDivisionsArrayOfObjs = this.c_allDivisionsArrayOfObjs;
    const c_allDivisionIDsComma = this.c_allDivisionIDsComma;
    const c_topDivision00ID = this.c_topDivision00ID;
    const c_topDivision00BusinessTypeID = this.c_topDivision00BusinessTypeID;
    const c_topDivision00PrimeSBCertificationsBmSetAsideIDsComma = this.c_topDivision00PrimeSBCertificationsBmSetAsideIDsComma;
    const c_selectMultiBitMasterSetAsidesFieldTypeObj = this.c_selectMultiBitMasterSetAsidesFieldTypeObj;
    const c_selectMultiBitMasterSetAsidesShortNamesFieldTypeObj = this.c_selectMultiBitMasterSetAsidesShortNamesFieldTypeObj;
    const c_selectMultiAdminAllDivisionsIncludingFirewalledFieldTypeObj = this.c_selectMultiAdminAllDivisionsIncludingFirewalledFieldTypeObj;
    const c_selectBusinessTypeFieldTypeObj = this.c_selectBusinessTypeFieldTypeObj;

    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of o_tbl_a_divisions) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var divisionID = dbRowMap.get("id");
      var treeID = dbRowMap.get("tree_id");
      var divisionName = dbRowMap.get("name");
      var businessTypeID = dbRowMap.get("business_type_id");
      var primeSBCertificationsBmSetAsideIDsComma = dbRowMap.get("prime_sb_certifications_bm_set_aside_ids_comma");
      var firewalledDivisionIDsComma = dbRowMap.get("firewalled_division_ids_comma");
      var samUei = dbRowMap.get("sam_uei");
      var samUeiCompanyName = dbRowMap.get("sam_uei_company_name");
      var integrationNewCaptureIntegrationUniqueIDPrefix = dbRowMap.get("integration_new_capture_integration_unique_id_prefix");

      var isTopDivision00TF = (divisionID === c_topDivision00ID);

      var calcBusinessTypeID = (c_companyPrimeSBCertsCanVaryPerDivisionTF) ? (businessTypeID) : (c_topDivision00BusinessTypeID);
      var calcPrimeSBCertificationsBmSetAsideIDsComma = (c_companyPrimeSBCertsCanVaryPerDivisionTF) ? (primeSBCertificationsBmSetAsideIDsComma) : (c_topDivision00PrimeSBCertificationsBmSetAsideIDsComma); //if flag is set so each division can have different sb certs, use the set cert, otherwise every divisions gets its cert value from the top 00 division
      var calcPrimeSBCertificationsBmSetAsideIDsArray = JSFUNC.convert_comma_list_to_int_array(calcPrimeSBCertificationsBmSetAsideIDsComma);
      var calcPrimeSBCertificationsIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(calcPrimeSBCertificationsBmSetAsideIDsComma);

      var calcPrimeSBCertificationsMask = undefined;
      var calcPrimeSBCertificationsMaskPlainText = undefined;
      var calcPrimeSBCertificationsShortNamesMask = undefined;
      var calcPrimeSBCertificationsShortNamesMaskPlainText = undefined;
      if(calcPrimeSBCertificationsIsFilledOutTF) {
        var sbCertificationsMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(calcPrimeSBCertificationsBmSetAsideIDsComma, c_selectMultiBitMasterSetAsidesFieldTypeObj);
        calcPrimeSBCertificationsMask = sbCertificationsMaskSortIfoObj.valueMask;
        calcPrimeSBCertificationsMaskPlainText = sbCertificationsMaskSortIfoObj.valueMaskPlainText;

        var sbCertificationsShortNamesMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(calcPrimeSBCertificationsBmSetAsideIDsComma, c_selectMultiBitMasterSetAsidesShortNamesFieldTypeObj);
        calcPrimeSBCertificationsShortNamesMask = sbCertificationsShortNamesMaskSortIfoObj.valueMask;
        calcPrimeSBCertificationsShortNamesMaskPlainText = sbCertificationsShortNamesMaskSortIfoObj.valueMaskPlainText;
      }
      else {
        calcPrimeSBCertificationsMask = "--None--";
        calcPrimeSBCertificationsMaskPlainText = "--None--";
        calcPrimeSBCertificationsShortNamesMask = "--None--";
        calcPrimeSBCertificationsShortNamesMaskPlainText = "--None--";
      }

      //firewalled divisions
      var calcFirewalledDivisionIDsComma = "";
      var allDivisionIDsAreSelectedForFirewallingTF = undefined;
      if(firewalledDivisionIDsComma === "-1") {
        calcFirewalledDivisionIDsComma = c_allDivisionIDsComma; //if the firewalledDivisionIDsComma is "-1" replaced the 'calc' value with all divisionIDs in the company
        allDivisionIDsAreSelectedForFirewallingTF = true;
      }
      else {
        calcFirewalledDivisionIDsComma = firewalledDivisionIDsComma;
        allDivisionIDsAreSelectedForFirewallingTF = JSFUNC.determine_if_every_id_within_mapOfMaps_is_preset_in_input_int_comma_list_tf(o_tbl_a_divisions, "id", firewalledDivisionIDsComma); //determine if all divisions are selected for firewalling
      }

      var calcFirewalledDivisionIDsArray = JSFUNC.convert_comma_list_to_int_array(calcFirewalledDivisionIDsComma);
      var calcFirewalledDivisionsIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(calcFirewalledDivisionIDsComma);

      var calcFirewalledDivisionsMaskPlainText = undefined;
      var calcFirewalledDivisionsMask = undefined;
      if(calcFirewalledDivisionsIsFilledOutTF) {
        if(allDivisionIDsAreSelectedForFirewallingTF) {
          calcFirewalledDivisionsMaskPlainText = "[All Divisions Selected]";
          calcFirewalledDivisionsMask = calcFirewalledDivisionsMaskPlainText;
        }
        else {
          var calcFirewalledDivisionsMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(calcFirewalledDivisionIDsComma, c_selectMultiAdminAllDivisionsIncludingFirewalledFieldTypeObj);
          calcFirewalledDivisionsMaskPlainText = calcFirewalledDivisionsMaskSortIfoObj.valueMaskPlainText;
          calcFirewalledDivisionsMask = calcFirewalledDivisionsMaskSortIfoObj.valueMask;
        }
      }
      else {
        calcFirewalledDivisionsMaskPlainText = "--None--";
        calcFirewalledDivisionsMask = calcFirewalledDivisionsMaskPlainText;
      }

      //calculate SAM UEI code for this division, if it's blank, search the tree parent division line rolling uphill to topDivison until a filled out UEI is found
      var calcSamUeiIsFilledOutTF = JSFUNC.string_is_filled_out_tf(samUei);
      var calcSamUeiWithUphillRoll = samUei;
      var calcCompanyNameWithUphillRoll = samUeiCompanyName;
      var calcSamUeiIsFromUphillTF = false;
      var calcSamUeiIsFromUphillDivisionName = "";
      if(!calcSamUeiIsFilledOutTF) { //if this division SAM UEI has a value, keep that value, otherwise search through its line of parents
        if(!isTopDivision00TF) { //if this is top divisions and it is blank, no need to search as there are no parents of the top division
          var directLineOfParentsTopToDirectParentArrayOfObjs = JSFUNC.get_direct_line_of_parents_top_to_direct_parent_arrayOfObjs_from_child_tree_id(c_allDivisionsArrayOfObjs, treeID);
          var numDirectParents = directLineOfParentsTopToDirectParentArrayOfObjs.length;
          for(let p = (numDirectParents - 1); p >= 0; p--) {
            var parentDivisionObj = directLineOfParentsTopToDirectParentArrayOfObjs[p];
            if(JSFUNC.string_is_filled_out_tf(parentDivisionObj.sam_uei)) {
              calcSamUeiWithUphillRoll = parentDivisionObj.sam_uei;
              calcCompanyNameWithUphillRoll = parentDivisionObj.sam_uei_company_name;
              calcSamUeiIsFromUphillTF = true;
              calcSamUeiIsFromUphillDivisionName = parentDivisionObj.name;
              break;
            }
          }
        }
      }
      var calcSamUeiWithUphillRollIsFilledOutTF = JSFUNC.string_is_filled_out_tf(calcSamUeiWithUphillRoll);
      var calcSamUeiWithSamUeiCompanyNameAndDivisionName = calcSamUeiWithUphillRoll + " [" + calcCompanyNameWithUphillRoll + "] - (" + divisionName + ")";

      //integration prefix
      var calcIntegrationPrefixIsFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(integrationNewCaptureIntegrationUniqueIDPrefix);

      var smallLargeInvalidBusinessTypeTFObj = this.determine_small_large_invalid_business_type_tf_obj_from_business_type_id(calcBusinessTypeID);

      var businessTypeMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(calcBusinessTypeID, c_selectBusinessTypeFieldTypeObj);

      expandedDbRowMap.set("isTopDivision00TF", isTopDivision00TF);
      expandedDbRowMap.set("calcPrimeSBCertificationsBmSetAsideIDsComma", calcPrimeSBCertificationsBmSetAsideIDsComma);
      expandedDbRowMap.set("calcPrimeSBCertificationsBmSetAsideIDsArray", calcPrimeSBCertificationsBmSetAsideIDsArray);
      expandedDbRowMap.set("calcPrimeSBCertificationsIsFilledOutTF", calcPrimeSBCertificationsIsFilledOutTF);
      expandedDbRowMap.set("calcPrimeSBCertificationsMask", calcPrimeSBCertificationsMask);
      expandedDbRowMap.set("calcPrimeSBCertificationsMaskPlainText", calcPrimeSBCertificationsMaskPlainText);
      expandedDbRowMap.set("calcPrimeSBCertificationsShortNamesMask", calcPrimeSBCertificationsShortNamesMask);
      expandedDbRowMap.set("calcPrimeSBCertificationsShortNamesMaskPlainText", calcPrimeSBCertificationsShortNamesMaskPlainText);
      expandedDbRowMap.set("calcFirewalledDivisionIDsComma", calcFirewalledDivisionIDsComma);
      expandedDbRowMap.set("calcFirewalledDivisionIDsArray", calcFirewalledDivisionIDsArray);
      expandedDbRowMap.set("calcFirewalledDivisionsIsFilledOutTF", calcFirewalledDivisionsIsFilledOutTF);
      expandedDbRowMap.set("allDivisionIDsAreSelectedForFirewallingTF", allDivisionIDsAreSelectedForFirewallingTF);
      expandedDbRowMap.set("calcFirewalledDivisionsMaskPlainText", calcFirewalledDivisionsMaskPlainText);
      expandedDbRowMap.set("calcFirewalledDivisionsMask", calcFirewalledDivisionsMask);
      expandedDbRowMap.set("calcSamUeiIsFilledOutTF", calcSamUeiIsFilledOutTF);
      expandedDbRowMap.set("calcSamUeiWithUphillRoll", calcSamUeiWithUphillRoll);
      expandedDbRowMap.set("calcCompanyNameWithUphillRoll", calcCompanyNameWithUphillRoll);
      expandedDbRowMap.set("calcSamUeiIsFromUphillTF", calcSamUeiIsFromUphillTF);
      expandedDbRowMap.set("calcSamUeiIsFromUphillDivisionName", calcSamUeiIsFromUphillDivisionName);
      expandedDbRowMap.set("calcSamUeiWithUphillRollIsFilledOutTF", calcSamUeiWithUphillRollIsFilledOutTF);
      expandedDbRowMap.set("calcSamUeiWithSamUeiCompanyNameAndDivisionName", calcSamUeiWithSamUeiCompanyNameAndDivisionName);
      expandedDbRowMap.set("calcIntegrationPrefixIsFilledOutTF", calcIntegrationPrefixIsFilledOutTF);
      expandedDbRowMap.set("divisionIsLargeBusinessTF", smallLargeInvalidBusinessTypeTFObj.businessTypeIsLargeTF);
      expandedDbRowMap.set("divisionIsSmallBusinessTF", smallLargeInvalidBusinessTypeTFObj.businessTypeIsSmallTF);
      expandedDbRowMap.set("divisionIsInvalidBusinessTypeTF", smallLargeInvalidBusinessTypeTFObj.businessTypeIsInvalidTF);
      expandedDbRowMap.set("businessTypeMask", businessTypeMaskSortIfoObj.valueMask);
      expandedDbRowMap.set("businessTypeMaskPlainText", businessTypeMaskSortIfoObj.valueMaskPlainText);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  determine_small_large_invalid_business_type_tf_obj_from_business_type_id(i_businessTypeID) {
    const businessTypeMap = this.o_tbl_a_business_types.get(i_businessTypeID);

    var businessTypeIsLargeTF = false;
    var businessTypeIsSmallTF = false;
    if(businessTypeMap !== undefined) {
      var businessTypeIsSbType01 = businessTypeMap.get("is_sb_type_01");
      if(businessTypeIsSbType01 === 1) {
        businessTypeIsSmallTF = true;
      }
      else if(businessTypeIsSbType01 === 0) {
        businessTypeIsLargeTF = true;
      }
    }

    return({
      businessTypeIsLargeTF: businessTypeIsLargeTF,
      businessTypeIsSmallTF: businessTypeIsSmallTF,
      businessTypeIsInvalidTF: (!businessTypeIsLargeTF && !businessTypeIsSmallTF)
    });
  }

  get c_tbl_a_users_per_email() {
    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_a_users_per_email) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var userPerEmailFirstName = dbRowMap.get("first_name");
      var userPerEmailLastName = dbRowMap.get("last_name");
      var userPerEmailDeactivated01 = dbRowMap.get("disabled");

      var userPerEmailFirstNameLastName = JSFUNC.full_name_from_first_name_last_name(userPerEmailFirstName, userPerEmailLastName);
      var userPerEmailSortName = userPerEmailLastName.toLowerCase() + userPerEmailFirstName.toLowerCase();
      var upeDeactivatedTF = (userPerEmailDeactivated01 === 1);

      expandedDbRowMap.set("firstNameLastName", userPerEmailFirstNameLastName);
      expandedDbRowMap.set("sortName", userPerEmailSortName);
      expandedDbRowMap.set("upeDeactivatedTF", upeDeactivatedTF);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }

  get c_tbl_a_users() { //all users from tbl_a_users combined with corresponding fields from tbl_a_users_per_email (from user_per_email_id) and tbl_a_divisions (from division_id) plus extra computed fields (display names, colors, etc)
    var combinedUsersMapOfMaps = new Map();
    for(let userID of this.o_tbl_a_users.keys()) { //loop through all users in tbl_a_users to add each one to the new c_tbl_a_users Map() referenced by tbl_a_users "id" field
      combinedUsersMapOfMaps.set(userID, this.create_combined_user_map(userID));
    }
    return(combinedUsersMapOfMaps);
  }


  get c_tbl_a_stages_pool() {
    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_a_stages_pool) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var stageType1234567 = dbRowMap.get("pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7");

      var stageTypePreActive1TF = false;
      var stageTypeActive2TF = false;
      var stageTypeActiveSubmitted3TF = false;
      var stageTypeClosedSubmittedWon4TF = false;
      var stageTypeClosedSubmittedLost5TF = false;
      var stageTypeClosedNotSubmittedNoBid6TF = false;
      var stageTypeClosedNotSubmittedGovernmentCancelled7TF = false;
      var stageTypeClosedNotSubmittedCustomerCancelled8TF = false;
      if(stageType1234567 === 1) { stageTypePreActive1TF = true; }
      else if(stageType1234567 === 2) { stageTypeActive2TF = true; }
      else if(stageType1234567 === 3) { stageTypeActiveSubmitted3TF = true; }
      else if(stageType1234567 === 4) { stageTypeClosedSubmittedWon4TF = true; }
      else if(stageType1234567 === 5) { stageTypeClosedSubmittedLost5TF = true; }
      else if(stageType1234567 === 6) { stageTypeClosedNotSubmittedNoBid6TF = true; }
      else if(stageType1234567 === 7) { stageTypeClosedNotSubmittedGovernmentCancelled7TF = true; }
      else if(stageType1234567 === 8) { stageTypeClosedNotSubmittedCustomerCancelled8TF = true; }

      var isActiveStageTF = (stageTypePreActive1TF || stageTypeActive2TF || stageTypeActiveSubmitted3TF);
      var isClosedStageTF = (stageTypeClosedSubmittedWon4TF || stageTypeClosedSubmittedLost5TF || stageTypeClosedNotSubmittedNoBid6TF || stageTypeClosedNotSubmittedGovernmentCancelled7TF || stageTypeClosedNotSubmittedCustomerCancelled8TF);
      var isWonStageTF = (stageTypeClosedSubmittedWon4TF);
      var isLostStageTF = (stageTypeClosedSubmittedLost5TF);
      var isNotSubmittedStageTF = (stageTypeClosedNotSubmittedNoBid6TF || stageTypeClosedNotSubmittedGovernmentCancelled7TF || stageTypeClosedNotSubmittedCustomerCancelled8TF);

      expandedDbRowMap.set("isActiveStageTF", isActiveStageTF);
      expandedDbRowMap.set("isClosedStageTF", isClosedStageTF);
      expandedDbRowMap.set("isWonStageTF", isWonStageTF);
      expandedDbRowMap.set("isLostStageTF", isLostStageTF);
      expandedDbRowMap.set("isNotSubmittedStageTF", isNotSubmittedStageTF);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_a_styling_string_presets() {
    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_a_styling_string_presets) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var name = dbRowMap.get("name");
      var stylingStringCommaList = dbRowMap.get("styling_string_comma_list");

      var nameLowercase = name.toLowerCase();
      var nameAndStyleStringCommaList = name + " [" + stylingStringCommaList + "]";

      expandedDbRowMap.set("nameLowercase", nameLowercase);
      expandedDbRowMap.set("nameAndStyleStringCommaList", nameAndStyleStringCommaList);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_a_budget_categories_pool() {
    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_a_budget_categories_pool) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var budgetCategoryName = dbRowMap.get("name");

      var dbNameFromName = JSFUNC.db_name_from_display_name(budgetCategoryName);

      expandedDbRowMap.set("dbNameFromName", dbNameFromName);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_a_shortcut_presets_capture_managers() {
    const c_fieldMapOfCaptureManagers = this.c_fieldMapOfCaptureManagers;

    const captureManagersFieldTypeObj = c_fieldMapOfCaptureManagers.get("fieldTypeObj");

    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of this.o_tbl_a_shortcut_presets_capture_managers) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var presetName = dbRowMap.get("preset_name");
      var userIDsColonPercentsComma = dbRowMap.get("user_ids_colon_percents_comma");

      var captureManagerUserNamesWithPercentsDisplayCommaPlainText = this.value_mask_plaintext_from_value_raw_and_field_type_obj(userIDsColonPercentsComma, captureManagersFieldTypeObj);
      var presetNameWithUsersAndPercents = presetName + " [" + captureManagerUserNamesWithPercentsDisplayCommaPlainText + "]";

      expandedDbRowMap.set("presetNameWithUsersAndPercents", presetNameWithUsersAndPercents);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }

  get c_tbl_a_shortcut_presets_documents_card_folders() {
    const o_tbl_a_shortcut_presets_documents_card_folders = this.o_tbl_a_shortcut_presets_documents_card_folders;
    const o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem = this.o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem;

    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of o_tbl_a_shortcut_presets_documents_card_folders) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var shortcutPresetID = dbRowMap.get("id");

      var presetFolderIDsArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem, "shortcut_preset_id", shortcutPresetID, "id");
      var numFolders = presetFolderIDsArray.length;

      expandedDbRowMap.set("presetFolderIDsArray", presetFolderIDsArray);
      expandedDbRowMap.set("numFolders", numFolders);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  get c_tbl_a_capture_types_details_fields() { //expand o_tbl_a_capture_types_details_fields to compute TF fields and break apart the styling string comma
    const o_tbl_a_capture_types_details_fields = this.o_tbl_a_capture_types_details_fields;
    const c_fieldMapOfStage = this.c_fieldMapOfStage;

    const stageFieldTypeObj = c_fieldMapOfStage.get("fieldTypeObj");

    var expandedMapOfMaps = new Map();
    for(let [idKey, dbRowMap] of o_tbl_a_capture_types_details_fields) {
      var expandedDbRowMap = JSFUNC.copy_map(dbRowMap);

      var fieldID = dbRowMap.get("field_id");
      var stageIDRelevant = dbRowMap.get("stage_id_relevant");
      var rec1ReqSig2Req3 = dbRowMap.get("rec1_reqsig2_req3");
      var errorIfNotFilledOut01 = dbRowMap.get("error_if_not_filled_out_01");
      var errorIfMultipleSelected01 = dbRowMap.get("error_if_multiple_selected_01");
      var errorIfSelectedNotAllowedIDsComma = dbRowMap.get("error_if_selected_not_allowed_ids_comma");
      var useDefault01 = dbRowMap.get("use_default_01");
      var defaultValueRawString = dbRowMap.get("default_value");
      var onlyCaptureManagersCanView01 = dbRowMap.get("only_capture_managers_can_view_field_01");
      
      var isBlankSpaceTF = (fieldID === -10);
      var isLineBreakTF = (fieldID === -11);

      var expandedCaptureFieldMap = this.fetch_expanded_capture_field_map_from_field_id(fieldID); //creates empty field if no matching ID is found
      var fieldDisplayName = expandedCaptureFieldMap.get("display_name");
      var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");

      var errorIfNotFilledOutTF = (errorIfNotFilledOut01 === 1);
      var errorIfMultipleSelectedTF = (errorIfMultipleSelected01 === 1);
      var errorIfSelectedNotAllowedIDsIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(errorIfSelectedNotAllowedIDsComma);
      var errorIfSelectedNotAllowedIDsArray = JSFUNC.convert_comma_list_to_int_array(errorIfSelectedNotAllowedIDsComma);
      var errorIfSelectedNotAllowedOptionDisplayNamesComma = ""; //only need to work to compute this option display names list if any are identified as 'not allowed'
      if(errorIfSelectedNotAllowedIDsIsFilledOutTF) {
        if(fieldTypeObj !== undefined) {
          if(fieldTypeObj.selectTF || fieldTypeObj.sharedPercentTF) {
            var forcedSelectMultiOptionsSwsOptionsObj = {isMultiSelectTF:true};
            var forcedSelectMultiOptionsFieldTypeObj = this.create_new_copy_field_type_obj_from_old_field_type_obj_and_field_input_type_and_sws_options_obj(fieldTypeObj, "select", forcedSelectMultiOptionsSwsOptionsObj);
            errorIfSelectedNotAllowedOptionDisplayNamesComma = this.value_mask_plaintext_from_value_raw_and_field_type_obj(errorIfSelectedNotAllowedIDsComma, forcedSelectMultiOptionsFieldTypeObj);
          }
        }
      }

      var useDefaultTF = (useDefault01 === 1);
      var defaultValueRawOrUndefined = undefined;
      var defaultValueMaskSortIfoObjOrUndefined = undefined;
      if(useDefaultTF) {
        defaultValueRawOrUndefined = this.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(defaultValueRawString, fieldTypeObj); //translate the default value (always a string in the database) into an int/decimal/string for the raw value
        defaultValueMaskSortIfoObjOrUndefined = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(defaultValueRawOrUndefined, fieldTypeObj);
      }

      var recommended1TF = (rec1ReqSig2Req3 === 1);
      var required3TF = (rec1ReqSig2Req3 === 3);
      var requiredToAdvanceStageTF = (required3TF && JSFUNC.select_int_is_filled_out_tf(stageIDRelevant));
      var requiredInStageNameMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(stageIDRelevant, stageFieldTypeObj);

      var private1CaptureManagersOnlyTF = (onlyCaptureManagersCanView01 === 1);
      var private2AdminsOnlyTF = (onlyCaptureManagersCanView01 === 2);
      var private0EveryoneTF = (!private1CaptureManagersOnlyTF && !private2AdminsOnlyTF);

      expandedDbRowMap.set("fieldDisplayName", fieldDisplayName);
      expandedDbRowMap.set("fieldTypeObj", fieldTypeObj);
      expandedDbRowMap.set("isBlankSpaceTF", isBlankSpaceTF);
      expandedDbRowMap.set("isLineBreakTF", isLineBreakTF);
      expandedDbRowMap.set("useDefaultTF", useDefaultTF);
      expandedDbRowMap.set("errorIfNotFilledOutTF", errorIfNotFilledOutTF);
      expandedDbRowMap.set("errorIfMultipleSelectedTF", errorIfMultipleSelectedTF);
      expandedDbRowMap.set("errorIfSelectedNotAllowedIDsIsFilledOutTF", errorIfSelectedNotAllowedIDsIsFilledOutTF);
      expandedDbRowMap.set("errorIfSelectedNotAllowedIDsArray", errorIfSelectedNotAllowedIDsArray);
      expandedDbRowMap.set("errorIfSelectedNotAllowedOptionDisplayNamesComma", errorIfSelectedNotAllowedOptionDisplayNamesComma);
      expandedDbRowMap.set("defaultValueRawOrUndefined", defaultValueRawOrUndefined);
      expandedDbRowMap.set("defaultValueMaskSortIfoObjOrUndefined", defaultValueMaskSortIfoObjOrUndefined);
      expandedDbRowMap.set("recommended1TF", recommended1TF);
      expandedDbRowMap.set("required3TF", required3TF);
      expandedDbRowMap.set("requiredToAdvanceStageTF", requiredToAdvanceStageTF);
      expandedDbRowMap.set("requiredInStageNameMaskSortIfoObj", requiredInStageNameMaskSortIfoObj);
      expandedDbRowMap.set("private0EveryoneTF", private0EveryoneTF);
      expandedDbRowMap.set("private1CaptureManagersOnlyTF", private1CaptureManagersOnlyTF);
      expandedDbRowMap.set("private2AdminsOnlyTF", private2AdminsOnlyTF);

      expandedMapOfMaps.set(idKey, expandedDbRowMap);
    }
    return(expandedMapOfMaps);
  }


  new_capture_field_admin_error_message_or_undefined(i_expandedCaptureFieldMap, i_newCaptureValueRaw, i_newCaptureCaptureTypeID) {
    const c_tbl_a_capture_types_details_fields = this.c_tbl_a_capture_types_details_fields;

    if(JSFUNC.is_number_not_nan_gt_0(i_newCaptureCaptureTypeID)) { //no need to verify these errors if the capture type is not filled out
      const fieldID = i_expandedCaptureFieldMap.get("id");

      const fieldNamesArray = ["capture_type_id", "field_id"];
      const valuesArray = [i_newCaptureCaptureTypeID, fieldID];
      const captureTypeDetailsFieldMapOrUndefined = JSFUNC.get_first_map_matching_field_value(c_tbl_a_capture_types_details_fields, fieldNamesArray, valuesArray);
      if(captureTypeDetailsFieldMapOrUndefined !== undefined) {
        const fieldDisplayName = i_expandedCaptureFieldMap.get("display_name");
        const fieldTypeObj = i_expandedCaptureFieldMap.get("fieldTypeObj");

        if(captureTypeDetailsFieldMapOrUndefined.get("errorIfNotFilledOutTF")) {
          if(!this.value_is_filled_out_tf_from_value_raw_and_field_type_obj(i_newCaptureValueRaw, fieldTypeObj)) {
            return(fieldDisplayName + " is required by the Admin to be filled out");
          }
        }

        var fieldIsSelectTF = false;
        var fieldIsMultiSelectTF = false;
        var fieldIsSharedPercentTF = false;
        if(fieldTypeObj !== undefined) {
          fieldIsSelectTF = fieldTypeObj.selectTF;
          if(fieldIsSelectTF) {
            if(fieldTypeObj.selectWithSearchDataObj !== undefined) {
              fieldIsMultiSelectTF = fieldTypeObj.selectWithSearchDataObj.isMultiSelectTF;
            }
          }
          fieldIsSharedPercentTF = fieldTypeObj.sharedPercentTF;
        }
        
        if(fieldIsMultiSelectTF || fieldIsSharedPercentTF) {
          if(captureTypeDetailsFieldMapOrUndefined.get("errorIfMultipleSelectedTF")) {
            var numSelectedOptions = 0;
            if(fieldIsMultiSelectTF) {
              const selectedIDsArray = JSFUNC.convert_comma_list_to_int_array(i_newCaptureValueRaw);
              numSelectedOptions = selectedIDsArray.length;
            }
            else if(fieldIsSharedPercentTF) {
              numSelectedOptions = JSFUNC.count_colon_comma_list_num_items(i_newCaptureValueRaw);
            }

            if(numSelectedOptions > 1) {
              return(fieldDisplayName + " is required by the Admin to not have multiple options selected");
            }
          }
        }
        
        if(fieldIsSelectTF || fieldIsMultiSelectTF || fieldIsSharedPercentTF) {
          if(captureTypeDetailsFieldMapOrUndefined.get("errorIfSelectedNotAllowedIDsIsFilledOutTF")) {
            var selectedOptionIDsArray = [];
            if(fieldIsSelectTF) {
              selectedOptionIDsArray = [i_newCaptureValueRaw];
            }
            else if(fieldIsMultiSelectTF) {
              selectedOptionIDsArray = JSFUNC.convert_comma_list_to_int_array(i_newCaptureValueRaw); //get ids from comma list
            }
            else if(fieldIsSharedPercentTF) {
              selectedOptionIDsArray = JSFUNC.get_all_colon_comma_list_int1_array(i_newCaptureValueRaw); //get ids from colon comma percents list
            }

            if(JSFUNC.any_of_array1_is_in_array2(captureTypeDetailsFieldMapOrUndefined.get("errorIfSelectedNotAllowedIDsArray"), selectedOptionIDsArray)) {
              return(fieldDisplayName + " is required by the Admin to not contain the following options: " + captureTypeDetailsFieldMapOrUndefined.get("errorIfSelectedNotAllowedOptionDisplayNamesComma"));
            }
          }
        }
      }
    }
    return(undefined);
  }


  get c_tbl_captures_fields() { //expansion of o_tbl_captures_fields to compute extra properties of each capture field
    var expandedMapOfMaps = new Map();
    for(let [fieldIDKey, fieldMap] of this.o_tbl_captures_fields) {
      var expandedCaptureFieldMap = this.create_expanded_capture_field_map_from_capture_field_map(fieldMap);
      expandedMapOfMaps.set(fieldIDKey, expandedCaptureFieldMap);
    }
    return(expandedMapOfMaps);
  }




  //------------------------------------------------------------------------------------------------------------------------
  //for c_allTblsInfoObj, these tbls need specification in order regarding their extra user defined data columns
  get c_teammatesExtraFieldsArrayOfObjs() {
    var expandedMapOfMaps = new Map();
    for(let [fieldIDKey, fieldMap] of this.o_tbl_a_teammates_extra_fields) {
      var expandedCaptureFieldMap = this.create_expanded_capture_field_map_from_capture_field_map(fieldMap);
      expandedMapOfMaps.set(fieldIDKey, expandedCaptureFieldMap);
    }
    const teammatesExtraFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(expandedMapOfMaps, "sort", true);
    return(teammatesExtraFieldsArrayOfObjs);
  }

  get c_contactsCompaniesExtraFieldsArrayOfObjs() { //expanded field maps converted to objs and sorted by "sort"
    var expandedMapOfMaps = new Map();
    for(let [fieldIDKey, fieldMap] of this.o_tbl_a_contacts_companies_extra_fields) {
      var expandedCaptureFieldMap = this.create_expanded_capture_field_map_from_capture_field_map(fieldMap);
      expandedMapOfMaps.set(fieldIDKey, expandedCaptureFieldMap);
    }
    var contactsCompaniesExtraFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(expandedMapOfMaps, "sort", true);
    return(contactsCompaniesExtraFieldsArrayOfObjs);
  }

  get c_contactsPersonsExtraFieldsArrayOfObjs() {
    var expandedMapOfMaps = new Map();
    for(let [fieldIDKey, fieldMap] of this.o_tbl_a_contacts_persons_extra_fields) {
      var expandedCaptureFieldMap = this.create_expanded_capture_field_map_from_capture_field_map(fieldMap);
      expandedMapOfMaps.set(fieldIDKey, expandedCaptureFieldMap);
    }
    var contactsPersonsExtraFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(expandedMapOfMaps, "sort", true);
    return(contactsPersonsExtraFieldsArrayOfObjs);
  }

  get c_allTblsInfoObj() {
    //used in tbl_row_map_from_id() only when the itemID searched for in the tbl does not exist, this fills in "Nonexistant" filler strings and int values in a complete map with all fields for the system to use when an id does not exist
    //for each tbl definition, use all columns in original o_tbls plus any custom columns added to the c_tbl versions
    const c_teammatesWorkshareTypeFieldDisplayName = this.c_teammatesWorkshareTypeFieldDisplayName;

    //append user defined data columns to teammates tbl
    var teammatesTblInfoFieldDbNamesArray = ["id", "capture_id", "contact_company_id", "prime_01", "selected_01", "allocation_percent", "workshare_type_id", "workshare_number_of_ftes", "workshare_notes", "teammate_contracts_manager_contact_person_id", "contact_person_ids_comma", "surveys_contact_person_ids_comma"];
    var teammatesTblInfoIdsbOrTypeStringsArray = ["i", "i", "i", "i", "i", "d", "i", "i", "s", "i", "s", "s"];
    for(let teammatesExtraFieldObj of this.c_teammatesExtraFieldsArrayOfObjs) {
      teammatesTblInfoFieldDbNamesArray.push(teammatesExtraFieldObj.db_name);
      teammatesTblInfoIdsbOrTypeStringsArray.push("s");
    }

    //append user defined data columns to contact companies tbl
    var contactsCompaniesTblInfoFieldDbNamesArray = ["id", "org_topdiv_id", "tree_id", "legal_name", "abbreviated_name", "business_type_id", "sb_certifications_bm_set_aside_ids_comma", "capability_ids_comma", "naics_code_ids_comma"]; //hardcoded fields in contact persons tbl
    var contactsCompaniesTblInfoIdsbOrTypeStringsArray = ["i", "i", "s", "s", "s", "i", "s", "s", "s"];
    for(let contactsCompaniesFieldObj of this.c_contactsCompaniesExtraFieldsArrayOfObjs) { //loop through each extra field (all are text type inputs)
      contactsCompaniesTblInfoFieldDbNamesArray.push(contactsCompaniesFieldObj.db_name);
      contactsCompaniesTblInfoIdsbOrTypeStringsArray.push("s");
    }

    //append user defined data columns to contact persons tbl
    var contactsPersonsTblInfoFieldDbNamesArray = ["id", "first_name", "last_name", "title", "email", "phone", "contact_company_id"]; //hardcoded fields in contact persons tbl
    var contactsPersonsTblInfoIdsbOrTypeStringsArray = ["i", "s", "s", "s", "s", "s", "i"];
    for(let contactsPersonsFieldObj of this.c_contactsPersonsExtraFieldsArrayOfObjs) { //loop through each extra field (all are text type inputs)
      contactsPersonsTblInfoFieldDbNamesArray.push(contactsPersonsFieldObj.db_name);
      contactsPersonsTblInfoIdsbOrTypeStringsArray.push("s");
    }

    //tbl: {itemName:"", tblInfoFieldDbNamesArray:[], tblInfoIdsbOrTypeStringsArray:[]},
    return({
      tbl_bit_master_naics_codes:               {itemName:"NAICS Code",
                                                 tblInfoFieldDbNamesArray:["id", "code", "title", "arl0_me1", "size", "exceptions", "made_obsolete_in_year", "obsolete_replaced_by_code"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "s", "i", "i", "s", "i", "i"]},
      tbl_a_automated_exports:                  {itemName:"Automated Export",
                                                 tblInfoFieldDbNamesArray:["off0_on1", "job_name", "recurrence_type_123", "recurrence_count", "run_time_hour_0to23", "capture_table_view_button_preset_id", "last_run_datetime_est", "created_by_user_id", "date_created", "view_button_sorted_filtered_capture_ids_comma", "view_button_sorted_capture_field_db_names_comma"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "i", "i", "i", "s", "i", "s", "s", "s"]},
      tbl_a_automated_reminders:                {itemName:"Automated Reminder",
                                                 tblInfoFieldDbNamesArray:["id", "date_field_id", "num_days_prior", "pre0_post1", "active0_all1", "extra_user_per_email_ids_to_notify_comma", "extra_message", "division_ids_comma_or_all", "created_datetime_utc", "created_by_user_id", "created_by_user_name"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i", "i", "s", "s", "s", "s", "i", "s"]},
      tbl_a_budget_categories_pool:             {itemName:"Budget Category",
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort"]},
      tbl_a_budget_expense_types:               {itemName:"Expense Type",
                                                 tblInfoFieldDbNamesArray:["id", "name"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s"]},
      tbl_a_business_types:                     {itemName:"Business Type",
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort", "is_sb_type_01"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort", "i"]},
      tbl_a_capabilities:                       {itemName:"Capability",
                                                 tblInfoFieldDbNamesArray:["id", "name"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s"]},
      tbl_a_capture_cards_custom_names:          undefined,
      tbl_a_capture_priority_levels:            {itemName:"Capture Priority",
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort", "color"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i_sort", "s", "s"]},
      tbl_a_capture_types:                      {itemName:"Capture Type",
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort", "prime1_sub2", "card_ids_comma", "stage_ids_comma", "details_group_ids_comma", "budget_category_ids_comma"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort", "i", "s", "s", "s", "s"]},
      tbl_a_capture_types_debrief_questions:    {itemName:"Capture Type Debrief Question Reference",
                                                 tblInfoFieldDbNamesArray:["id", "capture_type_id", "question_id", "sort", "closed_stage_id"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i_sort", "i"]},
      tbl_a_capture_types_details_fields:       {itemName:"Capture Type Detail Field",
                                                 tblInfoFieldDbNamesArray:["id", "capture_type_id", "group_id", "field_id", "sort", "stage_id_relevant", "rec1_reqsig2_req3", "error_if_not_filled_out_01", "error_if_multiple_selected_01", "error_if_selected_not_allowed_ids_comma", "use_default_01", "default_value", "only_capture_managers_can_view_field_01"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i", "i_sort", "i", "i", "i", "i", "s", "i", "s", "i"]},
      tbl_a_capture_types_shaping_questions:    {itemName:"Capture Type Shaping Question Reference",
                                                 tblInfoFieldDbNamesArray:["id", "capture_type_id", "question_id", "sort", "progress_weight", "pwin_weight", "stage_id_relevant", "rec1_reqsig2_req3"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i_sort", "i", "i", "i", "i"]},
      tbl_a_communications_banners:             {itemName:"Communications Banner",
                                                 tblInfoFieldDbNamesArray:["id", "created_datetime_utc", "created_by_user_id", "sort", "off0_onlyme1_on2", "start_date", "end_date", "banner_display_text", "banner_full_description", "banner_height_em_decimal", "banner_styling_string_comma", "banner_font_size_multiplier_10x", "seen_by_user_per_email_ids_comma_or_all", "seen_by_division_ids_comma_or_all"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "i_sort", "i", "s", "s", "s", "s", "d", "s", "i", "s", "s"]},
      tbl_a_company:                            undefined,
      tbl_a_competitors_extra_fields:           {itemName:"Competitor Custom Field",
                                                 tblInfoFieldDbNamesArray:["id", "input_type", "db_name", "display_name", "sort", "hover_text"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "s", "s", "i_sort", "s"]},
      tbl_a_contacts_persons_extra_fields:      undefined,
      tbl_a_contacts_companies_extra_fields:    undefined,
      tbl_a_contract_types:                     {itemName:"Contract Type",
                                                 tblInfoFieldDbNamesArray:["id", "sa1_idiq2_to3", "name", "sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "s", "i_sort"]},
      tbl_a_create_new_capture_additional_fields:{itemName:"Create New Capture Additional Field",
                                                tblInfoFieldDbNamesArray:["id", "capture_type_id", "capture_field_id", "sort"],
                                                tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i_sort"]},
      tbl_a_date_upcoming_colors1:              {itemName:"Date Upcoming Color",
                                                 tblInfoFieldDbNamesArray:["id", "days_before_due", "color", "font_color"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "s", "s"]},
      tbl_a_date_upcoming_colors2:              {itemName:"Date Upcoming Color",
                                                 tblInfoFieldDbNamesArray:["id", "days_before_due", "color", "font_color"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "s", "s"]},
      tbl_a_delete_capture_reasons:             {itemName:"Delete Capture Reason",
                                                 tblInfoFieldDbNamesArray:["id", "name", "admin_sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort"]},
      tbl_a_details_groups_pool:                {itemName:"Details Group",
                                                 tblInfoFieldDbNamesArray:["id", "name", "admin_sort", "group_name_styling_string_comma"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort", "s"]},
      tbl_a_divisions:                          {itemName:"Division",
                                                 tblInfoFieldDbNamesArray:["id", "tree_id", "name", "nickname", "color", "prime_sb_certifications_bm_set_aside_ids_comma", "firewalled_division_ids_comma", "sam_uei", "sam_uei_company_name", "integration_new_capture_integration_unique_id_prefix"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "s", "s", "color", "s", "s", "s", "s", "s"]},
      tbl_a_excel_report_templates_filefoldersystem: undefined,
      tbl_a_gcss_data_source_credentials:       {itemName:"GCSS Data Source Credentials",
                                                 tblInfoFieldDbNamesArray:["id", "data_source_sam0_govwin1", "division_ids_comma", "username", "password_scramble", "client_id_scramble", "client_secret_scramble", "oauth_access_json", "access_token_expiration_datetime_utc", "previous_access_successful_01"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "s", "s", "s", "s", "s", "s", "s", "i"]},
      tbl_a_gcss_not_interested_reasons:        {itemName:"GCSS Not Interested Reason",
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort"]},
      tbl_a_import_auto_assigns:                undefined,
      tbl_a_import_column_assigns:              undefined,
      tbl_a_import_custom_assigns:              undefined,
      tbl_a_import_presets:                     undefined,
      tbl_a_integrations_credentials:           {itemName:"Integrations Credential",
                                                 tblInfoFieldDbNamesArray:["id", "division_ids_comma", "username", "password_scramble", "oauth_json_access_token", "oauth_json_expiration_datetime_utc"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "s", "s", "s", "s"]},
      tbl_a_integrations_linked_fields:         {itemName:"Integrations Linked Field",
                                                 tblInfoFieldDbNamesArray:["id", "sort", "integration_field_db_name", "capture_field_id"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i_sort", "s", "i"]},
      tbl_a_integrations_linked_field_values:   {itemName:"Integrations Linked Field Values",
                                                 tblInfoFieldDbNamesArray:["id", "sort", "linked_field_id", "integration_value_string", "capture_field_value_string", "extra_capture_field_value_strings_comma"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i_sort", "i", "s", "s", "s"]},
      tbl_a_integrations_log:                   {itemName:"Integrations Log",
                                                 tblInfoFieldDbNamesArray:["id", "datetime_utc", "user_id", "iud_action_string", "ce_capture_id", "integration_unique_id_string", "ce_field_id", "integration_field_db_name", "new_ce_value_string", "new_integration_value_string", "error_message"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "s", "i", "s", "i", "s", "s", "s", "s"]},
      tbl_a_log_admin:                          undefined,
      tbl_a_pt_differentiators:                 {itemName:"Differentiator",
                                                 tblInfoFieldDbNamesArray:["id", "name"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s"]},
      tbl_a_pwin_calc_lines:                    {itemName:"Calculated PWin Line",
                                                 tblInfoFieldDbNamesArray:["id", "progress_x1", "progress_x2", "pwin_y1", "pwin_y2"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i", "i"]},
      tbl_a_pwin_colors:                        {itemName:"PWin Color",
                                                 tblInfoFieldDbNamesArray:["id", "color", "percent_start", "font_color"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "color", "i", "ffffff"]},
      tbl_a_reasons_won_lost:                   undefined,
      tbl_a_risks_categories:                   {itemName:"Risk Category",
                                                 tblInfoFieldDbNamesArray:["id", "name"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s"]},
      tbl_a_risks_impact_levels:                {itemName:"Impact Risk Level",
                                                 tblInfoFieldDbNamesArray:["id", "name", "percent_0to100", "color"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "color"]},
      tbl_a_risks_probability_levels:           {itemName:"Probability Risk Level",
                                                 tblInfoFieldDbNamesArray:["id", "name", "percent_0to100", "color"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "color"]},
      tbl_a_search_fields:                      {itemName:"Search Field",
                                                tblInfoFieldDbNamesArray:["id", "field_id", "sort", "used_in_search_01", "width_em", "sortcol_1no_2asc_3desc", "sum_row_01"],
                                                tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i", "i", "i", "i"]},
      tbl_a_shaping_progress_colors:            {itemName:"Deal Shaping Progress Color",
                                                 tblInfoFieldDbNamesArray:["id", "color", "percent_start", "font_color"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "color", "i", "ffffff"]},
      tbl_a_shaping_questions_pool:             {itemName:"Deal Shaping Question",
                                                 tblInfoFieldDbNamesArray:["id", "name", "answer_select1_text2_textarea3", "tag_ids_comma", "admin_sort", "hover_text", "question_name_styling_string_comma"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "s", "i_sort", "s", "s"]},
      tbl_a_shaping_questions_tags:             {itemName:"Deal Shaping Question Tag",
                                                 tblInfoFieldDbNamesArray:["id", "name", "hover_text"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "s"]},
      tbl_a_shaping_select_answers:             {itemName:"Deal Shaping Select Answer",
                                                 tblInfoFieldDbNamesArray:["id", "question_id", "name", "score0to100"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "s", "i"]},
      tbl_a_shortcut_presets_capture_managers:  {itemName:"Capture Manager Shortcut Preset",
                                                tblInfoFieldDbNamesArray:["id", "sort", "preset_name", "user_ids_colon_percents_comma"],
                                                tblInfoIdsbOrTypeStringsArray:["i", "i_sort", "s", "s"]},
      tbl_a_shortcut_presets_documents_card_folders: {itemName:"Documents Card Folders Shortcut Preset",
                                                tblInfoFieldDbNamesArray:["id", "sort", "preset_name"],
                                                tblInfoIdsbOrTypeStringsArray:["i", "i_sort", "s"]},
      tbl_a_shortcut_presets_documents_card_folders_filefoldersystem: undefined,
      tbl_a_single_capture_templates_filefoldersystem: undefined,
      tbl_a_stages_pool:                        {itemName:"Stage",
                                                 tblInfoFieldDbNamesArray:["id", "name", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", "color", "hover_text", "admin_sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "color", "s", "i_sort"]},
      tbl_a_styling_string_presets:             {itemName:"Styling Preset",
                                                 tblInfoFieldDbNamesArray:["id", "name", "styling_string_comma_list"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "s"]},
      tbl_a_tasks_extra_capture_fields:         {itemName:"Tasks Extra Capture Field",
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort"]},
      tbl_a_teammates_contract_agreement_types: {itemName:"Teammate Contract Agreement Type",
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort"]},
      tbl_a_teammates_contract_statuses:        {itemName:"Teammate Contract Status",
                                                 tblInfoFieldDbNamesArray:["id", "name", "hc0_they1_we2", "color", "sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "color", "i"]},
      tbl_a_teammates_contract_templates_filefoldersystem: undefined,
      tbl_a_teammates_contract_types:           {itemName:"Teammate Contract Type",
                                                 tblInfoFieldDbNamesArray:["id", "name", "short_name", "admin_sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "s", "i_sort"]},
      tbl_a_teammates_extra_fields:             {itemName:"Teammate Custom Field",
                                                 tblInfoFieldDbNamesArray:["id", "input_type", "db_name", "display_name", "sort", "hover_text"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "s", "s", "i_sort", "s"]},
      tbl_a_teammates_ratings_questions:        {itemName:"Teammate Rating Question",
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort", "weight"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort", "i"]},
      tbl_a_teammates_surveys_scale05_colors:   {itemName:"Teammate Survey Scale 0-5 Color",
                                                 tblInfoFieldDbNamesArray:["id", "color0", "color1", "color2", "color3", "color4", "color5"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "color", "color", "color", "color", "color", "color"]},
      tbl_a_teammates_workshare_types:          {itemName:"Teammate " + c_teammatesWorkshareTypeFieldDisplayName,
                                                 tblInfoFieldDbNamesArray:["id", "name", "sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i_sort"]},
      tbl_a_users:                              undefined,
      tbl_a_users_per_email:                    undefined,
      tbl_captures:                             undefined,
      tbl_captures_fields:                      {itemName:"Capture Field",
                                                 tblInfoFieldDbNamesArray:["id", "db_name", "display_name", "input_type", "select_tbl_name", "textarea_details_max_lines", "num_decimals", "hover_text", "display_name_styling_string_comma", "codeword_names_comma"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "s", "s", "s", "i", "i", "s", "s", "s"]},
      tbl_cap:                                  undefined,
      tbl_c_budget_expenses:                    undefined,
      tbl_c_budget_funding_requests:            undefined,
      tbl_c_competitors:                        undefined,
      tbl_c_conversations:                      undefined,
      tbl_c_documents_filefoldersystem:         undefined,
      tbl_c_notepad_note_stamps:                {itemName:"Note Stamp",
                                                 tblInfoFieldDbNamesArray:["id", "capture_id", "pinned_01", "sort", "created_datetime_utc", "created_by_user_id", "created_by_user_name", "last_edited_datetime_utc", "last_edited_by_user_id", "last_edited_by_user_name", "title", "body"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i_sort", "s", "i", "s", "s", "i", "s", "s", "s"]},
      tbl_c_pt_differentiators:                 {itemName:"Differentiator",
                                                 tblInfoFieldDbNamesArray:["id", "sort", "differentiator_id", "justification"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i_sort", "i", "s"]},
      tbl_c_pt_ghost_themes:                    {itemName:"Ghost Theme",
                                                 tblInfoFieldDbNamesArray:["id", "sort", "theme", "justification"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i_sort", "s", "s"]},
      tbl_c_pt_win_themes:                      {itemName:"Win Theme",
                                                 tblInfoFieldDbNamesArray:["id", "sort", "theme", "justification"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i_sort", "s", "s"]},
      tbl_c_risks:                              {itemName:"Risk",
                                                 tblInfoFieldDbNamesArray:["id", "capture_id", "sort", "risk_category_id", "probability_risk_level_id", "impact_risk_level_id", "identified_risk", "mitigation_approach"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i_sort", "i", "i", "i", "s", "s"]},
      tbl_c_shaping_answers_select:             undefined,
      tbl_c_shaping_answers_textarea:           undefined,
      tbl_c_teammates:                          {itemName:"Teammate",
                                                 tblInfoFieldDbNamesArray:teammatesTblInfoFieldDbNamesArray,
                                                 tblInfoIdsbOrTypeStringsArray:teammatesTblInfoIdsbOrTypeStringsArray},
      tbl_c_teammates_contracts:                {itemName:"Teammate Contract",
                                                 tblInfoFieldDbNamesArray:["id", "capture_id", "teammate_id", "teammate_contract_type_id", "agreement_type_id", "status_id", "requested_by_user_id", "date_requested", "date_needed", "date_completed"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i", "i", "i", "i", "date", "date", "date"]},
      tbl_c_teammates_contracts_filefoldersystem: undefined,
      tbl_c_teammates_ratings_questionnaire_submissions: undefined,
      tbl_c_teammates_sb_certification_allocations: undefined,
      tbl_c_teammates_surveys:                  {itemName:"Teammate Survey",
                                                 tblInfoFieldDbNamesArray:["id", "capture_id", "sort", "title", "invitation_text"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i_sort", "s", "s"]},
      tbl_c_teammates_surveys_answers:          undefined,
      tbl_c_teammates_surveys_filefoldersystem: undefined,
      tbl_c_teammates_surveys_questions:        undefined,
      tbl_c_teammates_surveys_response_times:   undefined,
      tbl_c_log_budget:                         undefined,
      tbl_c_log_pwin:                           undefined,
      tbl_c_log_details:                        undefined,
      tbl_c_log_shaping:                        undefined,
      tbl_c_log_stages:                         undefined,
      tbl_c_log_teammate_contracts:             undefined,
      tbl_d_daily_snapshot_graphs:              undefined,
      tbl_d_daily_snapshot_pages:               {itemName:"Daily Snapshot Page",
                                                 tblInfoFieldDbNamesArray:["id", "user_id", "sort", "name"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "s"]},
      tbl_d_quota:                              undefined,
      tbl_d_trend_analyzer_graphs:              undefined,
      tbl_d_trend_analyzer_pages:               {itemName:"Trend Analyzer Page",
                                                 tblInfoFieldDbNamesArray:["id", "user_id", "sort", "name"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "s"]},
      tbl_f_capture_table_presets:              {itemName:"Capture Table Preset",
                                                 tblInfoFieldDbNamesArray:["id", "name", "description", "public_01", "user_id", "cst_column_preset_id", "filter_preset_id", "sort_preset_id"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "s", "i", "i", "i", "i", "i"]},
      tbl_f_cst_columns:                        {itemName:"Capture Table Columns Configuration",
                                                 tblInfoFieldDbNamesArray:["id", "cst_column_preset_id", "capture_field_id", "width_em", "sort", "cst_sum_row_01"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i", "i_sort", "i"]},
      tbl_f_cst_column_presets:                 {itemName:"Capture Table Columns Preset",
                                                 tblInfoFieldDbNamesArray:["id", "name", "public_01", "user_id"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "i"]},
      tbl_f_filters:                            {itemName:"Capture Filter",
                                                 tblInfoFieldDbNamesArray:["id", "filter_preset_id", "capture_field_id", "operator", "value"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "s", "s"]},
      tbl_f_filter_presets:                     {itemName:"Capture Filter Preset",
                                                 tblInfoFieldDbNamesArray:["id", "name", "public_01", "user_id", "excel_report_writer_codeword"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "i", "s"]},
      tbl_f_gantt_dates:                        {itemName:"Gantt Date/Color",
                                                 tblInfoFieldDbNamesArray:["id", "gantt_date_preset_id", "capture_field_id", "color"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "color"]},
      tbl_f_gantt_date_presets:                 {itemName:"Gantt Date Preset",
                                                 tblInfoFieldDbNamesArray:["id", "name", "public_01", "user_id"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "i"]},
      tbl_f_sorts:                              {itemName:"Capture Sort",
                                                 tblInfoFieldDbNamesArray:["id", "sort_preset_id", "capture_field_id", "is_asc_01", "sort"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "i", "i", "i_sort"]},
      tbl_f_sort_presets:                       {itemName:"Capture Sort Preset",
                                                 tblInfoFieldDbNamesArray:["id", "name", "public_01", "user_id", "excel_report_writer_codeword"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "s", "i", "i", "s"]},
      tbl_g_contacts_companies:                 {itemName:"Contact Company",
                                                 tblInfoFieldDbNamesArray:contactsCompaniesTblInfoFieldDbNamesArray,
                                                 tblInfoIdsbOrTypeStringsArray:contactsCompaniesTblInfoIdsbOrTypeStringsArray},
      tbl_g_contacts_companies_filefoldersystem: undefined,
      tbl_g_contacts_persons:                   {itemName:"Contact Person",
                                                 tblInfoFieldDbNamesArray:contactsPersonsTblInfoFieldDbNamesArray,
                                                 tblInfoIdsbOrTypeStringsArray:contactsPersonsTblInfoIdsbOrTypeStringsArray},
      tbl_g_contacts_persons_filefoldersystem:  undefined,
      tbl_g_gcss_not_interested:                {itemName:"GCSS Not Interested",
                                                 tblInfoFieldDbNamesArray:["id", "gcss_id", "gcss_govwin_id_string", "gcss_fedcomp_id_string", "solicitation_number", "user_per_email_id", "undecided0_notinterested1", "reason_ids_comma", "explanation"],
                                                 tblInfoIdsbOrTypeStringsArray:["i", "i", "s", "s", "s", "i", "i", "s", "s"]},
      tbl_g_general_documents_filefoldersystem: undefined,
      tbl_u_gcss_auto_searches:                 undefined,
      tbl_u_notifications:                      undefined,
      tbl_u_tasks:                              undefined,
      tbl_z_ticket_initial_threads:             undefined,
      tbl_z_ticket_responses:                   undefined
    });
  }

  get_tbl_info_field_db_names_array_and_idsb_array_from_tbl_name(i_tblName, i_notIncludeFieldDbNameOrFieldDbNamesArray=undefined) {
    const allTblsInfoObj = this.c_allTblsInfoObj;

    const tblInfoObj = allTblsInfoObj[i_tblName];
    if(tblInfoObj === undefined) {
      alert("--" + i_tblName + " not in c_allTblsInfoObj--");
    }

    //unpack tblInfoObj
    const itemName = tblInfoObj.itemName;
    const tblInfoFieldDbNamesArray = tblInfoObj.tblInfoFieldDbNamesArray;
    const tblInfoIdsbOrTypeStringsArray = tblInfoObj.tblInfoIdsbOrTypeStringsArray;

    //interpret input of fieldDbNames to not include in the output arrays
    var notIncludeFieldDbNamesArray = [];
    if(i_notIncludeFieldDbNameOrFieldDbNamesArray !== undefined) {
      notIncludeFieldDbNamesArray = JSFUNC.convert_single_or_array_to_array(i_notIncludeFieldDbNameOrFieldDbNamesArray);
    }

    var fieldDbNamesArray = [];
    var idsbArray = [];
    var blankValuesArray = [];
    for(let f = 0; f < tblInfoFieldDbNamesArray.length; f++) {
      var fieldDbName = tblInfoFieldDbNamesArray[f];
      var tblInfoIdsbOrTypeString = tblInfoIdsbOrTypeStringsArray[f];

      if(!JSFUNC.in_array(fieldDbName, notIncludeFieldDbNamesArray)) {
        var idsb = "xxxx"; //xxxx a flag that this manual definition of the database tbl structure has a field in it that is invalid that needs to be fixed
        var blankValue = "";
        if(tblInfoIdsbOrTypeString === "i" || tblInfoIdsbOrTypeString === "d") {
          idsb = tblInfoIdsbOrTypeString;
          blankValue = 0;
        }
        else if(tblInfoIdsbOrTypeString === "s" || tblInfoIdsbOrTypeString === "b") {
          idsb = tblInfoIdsbOrTypeString;
          blankValue = "";
        }
        else if(tblInfoIdsbOrTypeString === "date") {
          idsb = "s";
          blankValue = JSFUNC.blank_date();
        }
        else if(tblInfoIdsbOrTypeString === "datetime") {
          idsb = "s";
          blankValue = JSFUNC.blank_datetime();
        }
        else if(tblInfoIdsbOrTypeString === "color") {
          idsb = "s";
          blankValue = "666666";
        }
        else if(tblInfoIdsbOrTypeString === "i_sort") {
          idsb = "i";
          blankValue = 0;
        }
        else if(tblInfoIdsbOrTypeString === "s_sort") {
          idsb = "s";
          blankValue = "";
        }
        else {
          alert("--" + i_tblName + " invalid idsb '" + tblInfoIdsbOrTypeString + "' for column " + fieldDbName + "--");
        }

        fieldDbNamesArray.push(fieldDbName);
        idsbArray.push(idsb);
        blankValuesArray.push(blankValue);
      }
    }

    return({
      fieldDbNamesArray: fieldDbNamesArray,
      idsbArray: idsbArray,
      blankValuesArray: blankValuesArray
    });
  }

  //loading a row of data from a tbl matching the "id" column, returns a map with "nonexistant" or "nothing selected" values for each field if the id is not found
  tbl_row_map_from_id(i_tblName, i_itemID, i_forceToUseOTblFlag=undefined) {
    //i_forceToUseOTblFlag
    //  - "o"         forces to use o_tbl_name tbl for reference even if a c_tbl_name tbl exists
    //  - undefined   prioritizes using a c_tbl_name if it exists, otherwise refers to the o_tbl_name (c_tbl_name is created to provide more computed fields in the tbl than the database has)
    const tblRef = this.tbl_ref_from_tbl_name(i_tblName, i_forceToUseOTblFlag); //find the computed version of the table with extra calculated fields if it exists
    if(tblRef === undefined) {
      return(new Map());
    }

    const tblRowMap = tblRef.get(i_itemID);
    if(tblRowMap !== undefined) {
      return(tblRowMap); //id was found in the tbl, return the data row from the tbl for that id number
    }
    return(this.does_not_exist_tbl_row_map(i_tblName, "id", i_itemID, i_forceToUseOTblFlag));
  }

  tbl_row_map_from_matching_field_value(i_tblName, i_fieldNameOrArrayOfFieldnamesToCheck, i_valueOrArrayOfValuesToCompare, i_forceToUseOTblFlag=undefined) {
    const tblRef = this.tbl_ref_from_tbl_name(i_tblName, i_forceToUseOTblFlag); //find the computed version of the table with extra calculated fields if it exists
    if(tblRef === undefined) {
      return(new Map());
    }

    const tblRowMap = JSFUNC.get_first_map_matching_field_value(tblRef, i_fieldNameOrArrayOfFieldnamesToCheck, i_valueOrArrayOfValuesToCompare);
    if(tblRowMap !== undefined) {
      return(tblRowMap); //first row matching field/value filters found in the tbl, return that data row
    }
    const fieldNamesArray = JSFUNC.convert_single_or_array_to_array(i_fieldNameOrArrayOfFieldnamesToCheck);
    const valuesArray = JSFUNC.convert_single_or_array_to_array(i_valueOrArrayOfValuesToCompare);
    return(this.does_not_exist_tbl_row_map(i_tblName, fieldNamesArray.join(", "), valuesArray.join(", "), i_forceToUseOTblFlag));
  }

  does_not_exist_tbl_row_map(i_tblName, i_fieldDbName, i_value, i_forceToUseOTblFlag=undefined) { //Nonexistant Map of all fields provided by c_allTblsInfoObj
    //unpack the tbl info obj for this tbl
    const tblInfoObj = this.c_allTblsInfoObj[i_tblName];
    if(tblInfoObj === undefined) {
      return(new Map());
    }

    //unpack tblInfoObj
    const itemName = tblInfoObj.itemName;
    const tblInfoFieldDbNamesArray = tblInfoObj.tblInfoFieldDbNamesArray;
    const tblInfoIdsbOrTypeStringsArray = tblInfoObj.tblInfoIdsbOrTypeStringsArray;

    //all strings designated with "s" are replaced with a "does not exist" string (or "nothing selected" when id is < 0)
    const stringReplacement = "--" + itemName + " Does Not Exist (" + i_fieldDbName + ":" + i_value + ")--"; //positive id numbers that could not be found in the tbl return a "does not exist" for string fields with the failed id number
    const intReplacement = -1; //all ints/decimals designated with "i" are replaced with -1

    var nonexistantMap = new Map();
    for(let f = 0; f < tblInfoFieldDbNamesArray.length; f++) { //loop through each extra field (all are text type inputs) and give them does not exist messages
      var fieldDbName = tblInfoFieldDbNamesArray[f];
      var tblInfoIdsbOrTypeString = tblInfoIdsbOrTypeStringsArray[f];

      var replacementValue = tblInfoIdsbOrTypeString; //hardcoded provided value

      if(tblInfoIdsbOrTypeString === "s" || tblInfoIdsbOrTypeString === "b") {
        replacementValue = stringReplacement;
      }
      else if(tblInfoIdsbOrTypeString === "i" || tblInfoIdsbOrTypeString === "d") {
        if(fieldDbName === "id") { //retain actual id numbers even when they do not exist
          replacementValue = i_value;
        }
        else {
          replacementValue = intReplacement;
        }
      }
      else if(tblInfoIdsbOrTypeString === "date") {
        replacementValue = JSFUNC.blank_date();
      }
      else if(tblInfoIdsbOrTypeString === "color") {
        replacementValue = "666666";
      }
      else if(tblInfoIdsbOrTypeString === "i_sort") {
        replacementValue = JSFUNC.sort_max_mysqli_bigint();
      }
      else if(tblInfoIdsbOrTypeString === "s_sort") {
        replacementValue = JSFUNC.sort_max_string();
      }

      nonexistantMap.set(fieldDbName, replacementValue);
    }
    return(nonexistantMap);
  }

  //tbl names
  tbl_ref_from_tbl_name(i_tblName, i_forceToUseOTblFlag=undefined) {
    //passes a reference to the tbl in memory, undefined if the tbl doesn't exist
    if(this.tbl_name_is_tbl_cap(i_tblName)) {
      const tblCapTblRef = this.o_tbl_cap.get(i_tblName); //o_tbl_cap is the observable container object hold "tbl_cap_NAME" tbls in memory
      if(tblCapTblRef !== undefined) {
        return(tblCapTblRef);
      }
    }

    //find the computed version of the table with extra calculated fields if it exists
    if(i_forceToUseOTblFlag === undefined) {
      const cTblRef = this["c_" + i_tblName];
      if(cTblRef !== undefined) {
        return(cTblRef);
      }
    }

    const oTblRef = this["o_" + i_tblName]; //"o_NAME" is the observable tbl name in memory
    if(oTblRef !== undefined) {
      return(oTblRef);
    }

    //no matching tblName could be found in memory, throw a z_error and return undefined
    const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "tbl_ref_from_tbl_name", ["i_tblName", "i_forceToUseOTblFlag"], [i_tblName, i_forceToUseOTblFlag]);
    JSPHP.record_z_error(jsDescription, "tblName does not exist in local memory");
    return(undefined);
  }

  tbl_name_is_tbl_cap(i_tblName) {
    if(JSFUNC.is_string(i_tblName)) {
      const tblNameFirst10Chars = i_tblName.substring(0,10);
      return(tblNameFirst10Chars === "tbl_cap_n_" || tblNameFirst10Chars === "tbl_cap_s_");
    }
    return(false);
  }

  tbl_max_id_number(i_tblName) {
    var tblRef = this.tbl_ref_from_tbl_name(i_tblName, "o");
    return(JSFUNC.max_value_from_mapOfMaps_column(tblRef, "id")); //-1 if tbl does not exist, 0 if empty, max id value otherwise
  }



  //----------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------
  //BIT customer info (no need for "product" as it's known from the initial website address and only used to verify login against purchased product)
  get c_bitCompanyDomainsDotExtsArray() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitCompanyDomainsDotExtsArray);
    }
    return(JSFUNC.convert_comma_list_to_array(this.o_bitcompaniesTblRowMap.get("company_domain_dot_ext_comma")));
  }
  get c_bitJumbleKey() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return("onprem");
    }
    return(this.o_bitcompaniesTblRowMap.get("jumble_key"));
  }
  get c_bitNumLicensesPurchased() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitNumLicensesPurchased);
    }
    return(this.o_bitcompaniesTblRowMap.get("licenses"));
  }
  get c_bitLicenseStartDate() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitLicenseStartDate);
    }
    return(this.o_bitcompaniesTblRowMap.get("license_start_date"));
  }
  get c_bitLicenseNumMonths() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitLicenseNumMonths);
    }
    return(this.o_bitcompaniesTblRowMap.get("license_num_months"));
  }
  get c_bitSuperAdminsMaxNum() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitSuperAdminsMaxNum);
    }
    return(this.o_bitcompaniesTblRowMap.get("superadmins_max_num"));
  }
  get c_bitSuperAdminsLicenseCost() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitSuperAdminsLicenseCost);
    }
    return(this.o_bitcompaniesTblRowMap.get("superadmins_license_cost"));
  }
  get c_bitConsultantsLicenseCost() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitConsultantsLicenseCost);
    }
    return(this.o_bitcompaniesTblRowMap.get("consultants_license_cost"));
  }
  get c_bitReadOnlyLicenseCost() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitReadOnlyLicenseCost);
    }
    return(this.o_bitcompaniesTblRowMap.get("read_only_license_cost"));
  }
  get c_bitGovConSmartSearchTF() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitGovConSmartSearchTF);
    }
    return(this.o_bitcompaniesTblRowMap.get("govcon_smart_search_01") === 1);
  }
  get c_bitCexaiTF() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitCexaiTF);
    }
    return(this.o_bitcompaniesTblRowMap.get("cexai_01") === 1);
  }
  get c_bitIdiqRapidResponseTF() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitIdiqRapidResponseTF);
    }
    return(this.o_bitcompaniesTblRowMap.get("idiq_rapid_response_01") === 1);
  }
  get c_bitSingleCaptureDirectAccessLinksTF() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitSingleCaptureDirectAccessLinksTF);
    }
    return(this.o_bitcompaniesTblRowMap.get("single_capture_direct_access_links_01") === 1);
  }
  get c_bitIntegrationSystemVantagePointTF() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitIntegrationSystemVantagePointTF);
    }
    return(this.o_bitcompaniesTblRowMap.get("integration_system") === "vantagepoint");
  }
  get c_bitSGTDocumentsCardUpgrade01TF() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitSGTDocumentsCardUpgrade01TF);
    }
    return(this.o_bitcompaniesTblRowMap.get("sgt_documents_card_upgrade_01") === 1);
  }
  get c_bitSGTStripeCustomerID() {
    if(CaptureExecMobx.k_onPremiseCustomerTF) {
      return(CaptureExecMobx.k_onPremiseBitSGTStripeCustomerID);
    }
    return(this.o_bitcompaniesTblRowMap.get("sgt_stripe_customer_id"));
  }

  get c_bitEnterpriseLicenseTF() {
    return(this.c_bitNumLicensesPurchased < 0);
  }

  get c_bitUsing3rdPartyIntegrationTF() {
    return(this.c_bitIntegrationSystemVantagePointTF);
  }




  //company
  get c_companyUsingDivisionFirewallingTF() { return(this.o_tbl_a_company.get("using_division_firewalling_01") === 1); }
  get c_companyPrimeSBCertsCanVaryPerDivisionTF() { return(this.o_tbl_a_company.get("prime_sb_certs_can_vary_per_division_01") === 1); }
  get c_companyCreateNewCaptureDefaultCaptureTypeID() { return(this.o_tbl_a_company.get("create_new_capture_default_capture_type_id")); }

  get c_companyRestrictAdvanceCaptureStage01() { return(this.o_tbl_a_company.get("restrict_advance_capture_stage_01")); }
  get c_companyRestrictAdvanceCaptureStageTF() { return(this.c_companyRestrictAdvanceCaptureStage01 === 1); }
  get c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma() { return(this.o_tbl_a_company.get("restrict_advance_capture_stage_user_per_email_ids_comma")); }
  get c_companyRestrictAdvanceCaptureStageUserNamesMaskSortIfoObj() { return(this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(this.c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma, this.c_selectMultiUsersPerEmailNotDeactivatedFieldTypeObj)); }

  get c_companyTeammatesAllocOfCovAltFieldDisplayName() { return(this.o_tbl_a_company.get("teammates_alloc_of_cov_alt_field_display_name")); }
  get c_companyTeammatesWorkshareTypeAltFieldDisplayName() { return(this.o_tbl_a_company.get("teammates_workshare_type_alt_field_display_name")); }
  get c_companyTeammatesWorkshareNumFtesAltFieldDisplayName() { return(this.o_tbl_a_company.get("teammates_workshare_num_ftes_alt_field_display_name")); }
  get c_companyTeammatesWorkshareNotesAltFieldDisplayName() { return(this.o_tbl_a_company.get("teammates_workshare_notes_alt_field_display_name")); }
  get c_teammatesAllocOfCovFieldDisplayName() { return((this.c_companyTeammatesAllocOfCovAltFieldDisplayName === "") ? ("Allocation % of " + this.c_fieldMapOfContractOverallValue.get("display_name")) : (this.c_companyTeammatesAllocOfCovAltFieldDisplayName)); }
  get c_teammatesWorkshareTypeFieldDisplayName() { return((this.c_companyTeammatesWorkshareTypeAltFieldDisplayName === "") ? ("Workshare Type") : (this.c_companyTeammatesWorkshareTypeAltFieldDisplayName)); }
  get c_teammatesWorkshareNumFtesFieldDisplayName() { return((this.c_companyTeammatesWorkshareNumFtesAltFieldDisplayName === "") ? ("Workshare Number of FTEs") : (this.c_companyTeammatesWorkshareNumFtesAltFieldDisplayName)); }
  get c_teammatesWorkshareNotesFieldDisplayName() { return((this.c_companyTeammatesWorkshareNotesAltFieldDisplayName === "") ? ("Workshare Notes") : (this.c_companyTeammatesWorkshareNotesAltFieldDisplayName)); }

  get c_companyTeammateContractsOnOtherTeammatesWhenSub01() { return(this.o_tbl_a_company.get("teammate_contracts_on_other_teammates_when_sub_01")); }
  get c_companyTeammateContractsOnOtherTeammatesWhenSubTF() { return(this.c_companyTeammateContractsOnOtherTeammatesWhenSub01 === 1); }

  get c_companyDateFormat() { return(this.o_tbl_a_company.get("date_format")); }
  get c_companyDateFormatIsmdYTF() { return(this.c_companyDateFormat === "m/d/Y"); }
  get c_companyDateFormatIsdmYTF() { return(this.c_companyDateFormat === "d/m/Y"); }
  get c_companyDateFormatIsYmdTF() { return(this.c_companyDateFormat === "Y-m-d"); }
  get c_companyDateFormatIsMjYTF() { return(this.c_companyDateFormat === "M j, Y"); }

  get c_companyCaptureNameFormat() { return(this.o_tbl_a_company.get("capture_name_format")); }

  get c_companyPerformanceGraphsAllUsersIgnoreSharedPercentCalcs01() { return(this.o_tbl_a_company.get("performance_graphs_all_users_ignore_shared_percent_calcs_01")); }
  get c_companyPerformanceGraphsAllUsersIgnoreSharedPercentCalcsTF() { return(this.c_companyPerformanceGraphsAllUsersIgnoreSharedPercentCalcs01 === 1); }

  get c_companyTasksAssigneeCanEditDueDate01() { return(this.o_tbl_a_company.get("tasks_assignee_can_edit_due_date_01")); }
  get c_companyTasksAssigneeCanEditDueDateTF() { return(this.c_companyTasksAssigneeCanEditDueDate01 === 1); }
  get c_companyUsingCodenameTF() { return(this.o_tbl_a_company.get("using_codename_01") === 1); }

  get c_companyShortcutPresetsCaptureManagersOnCreateNewCapture01() { return(this.o_tbl_a_company.get("shortcut_presets_capture_managers_on_cnc_01")); }
  get c_companyShortcutPresetsCaptureManagersOnCreateNewCaptureTF() { return(this.c_companyShortcutPresetsCaptureManagersOnCreateNewCapture01 === 1); }
  get c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCapture01() { return(this.o_tbl_a_company.get("shortcut_presets_documents_card_folders_on_cnc_01")); }
  get c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF() { return(this.c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCapture01 === 1); }
  
  get c_companyTeammateSurveysNotificationsToAllCaptureManagers01() { return(this.o_tbl_a_company.get("teammate_surveys_notifications_to_all_capture_managers_01")); }
  get c_companyTeammateSurveysNotificationsToAllCaptureManagersTF() { return(this.c_companyTeammateSurveysNotificationsToAllCaptureManagers01 === 1); }

  get c_companyFyrStartMonth1to12() { return(this.o_tbl_a_company.get("fyr_start_month")); }
  get c_companyNewUserDefaultPassword() { return(this.o_tbl_a_company.get("new_user_default_password")); }
  get c_companyDaysBetweenUserPasswordChange() { return(this.o_tbl_a_company.get("days_between_user_password_change")); }
  get c_companyCaptureConsultantsCanViewParentIDIQs01() { return(this.o_tbl_a_company.get("capture_consultants_can_view_parent_idiqs_01")); }

  get c_companyUsingPwinTF() { return(JSFUNC.in_array(this.o_tbl_a_company.get("pwin_flag"), ["manual", "calculated"])); }
  get c_companyPwinIsCalculatedTF() { return(this.o_tbl_a_company.get("pwin_flag") === "calculated"); }
  get c_companyPwinUsersCanEditTF() { return(this.o_tbl_a_company.get("pwin_flag") === "manual"); }
  get c_companyPwinCalcMinThreshScore() { return(this.o_tbl_a_company.get("pwin_calc_min_thresh_score")); }
  get c_companyPwinCalcMinThreshPwin() { return(this.o_tbl_a_company.get("pwin_calc_min_thresh_pwin")); }
  get c_pwinCalcLinesSortedArrayOfObjs() {
    const tblRef = this.tbl_ref_from_tbl_name("tbl_a_pwin_calc_lines");
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_pwin_calc_lines, "progress_x1", true));
  }

  get c_companyUsingPTDifferentiatorsTF() { return(this.o_tbl_a_company.get("pt_differentiators_01") === 1); }
  get c_companyUsingPTWinThemesTF() { return(this.o_tbl_a_company.get("pt_win_themes_01") === 1); }
  get c_companyUsingPTGhostThemesTF() { return(this.o_tbl_a_company.get("pt_ghost_themes_01") === 1); }

  get c_companyBudgetManagerApprovalNumDays() {
    const budgetManagerApprovalNumDays = this.o_tbl_a_company.get("budget_manager_approval_num_days");
    if(!JSFUNC.is_number(budgetManagerApprovalNumDays) || (budgetManagerApprovalNumDays < 0)) {
      return(7);
    }
    return(budgetManagerApprovalNumDays);
  }

  get c_companyBitMasterSetAsidesOnlyShortNames01() { return(this.o_tbl_a_company.get("bit_master_set_asides_only_short_names_01")); }
  get c_companyBitMasterSetAsidesOnlyShortNamesTF() { return(this.c_companyBitMasterSetAsidesOnlyShortNames01 === 1); }
  get c_companyBitMasterSetAsideIDsToNotIncludeArray() { return(JSFUNC.convert_comma_list_to_int_array(this.o_tbl_a_company.get("bit_master_set_aside_ids_to_not_include_comma"))); }
  get c_companyBitMasterNaicsCodesAll0Only6Digit1() { return(this.o_tbl_a_company.get("bit_master_naics_codes_all0_only6digit1")); }
  get c_companyBitMasterNaicsCodesRangesComma() { return(this.o_tbl_a_company.get("bit_master_naics_codes_ranges_comma")); }
  get c_companyBitMasterProductServiceCodeIDsToNotIncludeArray() { return(JSFUNC.convert_comma_list_to_int_array(this.o_tbl_a_company.get("bit_master_product_service_code_ids_to_not_include_comma"))); }
  get c_companyBitMasterDepartmentsAgenciesIDsToNotIncludeArray() { return(JSFUNC.convert_comma_list_to_int_array(this.o_tbl_a_company.get("bit_master_depts_ags_ids_to_not_include_comma"))); }
  get c_companyBitMasterSubTiersIDsToNotIncludeArray() { return(JSFUNC.convert_comma_list_to_int_array(this.o_tbl_a_company.get("bit_master_subtiers_ids_to_not_include_comma"))); }
  get c_companyIDIQVehicleWins0WinsAndActive1() { return(this.o_tbl_a_company.get("idiq_vehicle_wins0_winsandactive1")); }
  get c_companyCaptureFavoritesColor() { return(this.o_tbl_a_company.get("capture_favorites_color")); }

  get c_companyGcssDataSourceSamShownTF() { return(this.o_tbl_a_company.get("gcss_data_source_sam_shown_01") === 1); }
  get c_companyGcssDataSourceGovWinShownTF() { return(this.o_tbl_a_company.get("gcss_data_source_govwin_shown_01") === 1); }
  get c_companyGcssDataSourceFedCompShownTF() { return(this.o_tbl_a_company.get("gcss_data_source_fedcomp_shown_01") === 1); }
  get c_gcssHasAtLeast1DataSourceShownTF() { return(this.c_companyGcssDataSourceSamShownTF || this.c_companyGcssDataSourceGovWinShownTF || this.c_companyGcssDataSourceFedCompShownTF); }
  get c_companyGcssBlankDataOverwrite01() { return(this.o_tbl_a_company.get("gcss_blank_data_overwrite_01")); }

  get c_companyUsingAutomatedCaptureArchivingTF() { return(this.o_tbl_a_company.get("using_automated_capture_archiving_01") === 1); }
  get c_companyCaptureArchivingClosedStageIDsComma() { return(this.o_tbl_a_company.get("capture_archiving_closed_stage_ids_comma")); }
  get c_companyCaptureArchivingNumDaysAfterDate() { return(this.o_tbl_a_company.get("capture_archiving_num_days_after_date")); }
  get c_companyCaptureArchivingDateFieldID() { return(this.o_tbl_a_company.get("capture_archiving_date_field_id")); }
  get c_companyCaptureArchivingNumDaysNoActivity() { return(this.o_tbl_a_company.get("capture_archiving_num_days_no_activity")); }
  get c_companyCaptureArchivingSendGcssUpdateRemindersTF() { return(this.o_tbl_a_company.get("capture_archiving_send_gcss_update_reminders_01") === 1); }
  get c_companyCaptureArchivingSendTaskRemindersTF() { return(this.o_tbl_a_company.get("capture_archiving_send_task_reminders_01") === 1); }
  get c_companyCaptureArchivingSendAutomatedRemindersTF() { return(this.o_tbl_a_company.get("capture_archiving_send_automated_reminders_01") === 1); }

  get c_companySearchFieldsSeparateDivexecFields01() { return(this.o_tbl_a_company.get("search_fields_separate_divexec_fields_01")); }
  get c_companySearchFieldsSeparateDivexecFieldsTF() { return(this.c_companySearchFieldsSeparateDivexecFields01 === 1); }

  get c_companyIntegrationOnTF() { return(this.o_tbl_a_company.get("integration_on_01") === 1); }
  get c_companyIntegrationOauthWebAddress() { return(this.o_tbl_a_company.get("integration_oauth_web_address")); }
  get c_companyIntegrationApiWebAddress() { return(this.o_tbl_a_company.get("integration_api_web_address")); }
  get c_companyIntegrationDatabaseName() { return(this.o_tbl_a_company.get("integration_database_name")); }
  get c_companyIntegrationClientIDScramble() { return(this.o_tbl_a_company.get("integration_client_id_scramble")); }
  get c_companyIntegrationClientSecretScramble() { return(this.o_tbl_a_company.get("integration_client_secret_scramble")); }
  get c_companyIntegrationIntegrationUniqueIDCEFieldID() { return(this.o_tbl_a_company.get("integration_integration_unique_id_ce_field_id")); }
  get c_companyIntegrationLogRecordSuccessesTF() { return(this.o_tbl_a_company.get("integration_log_record_successes_01") === 1); }
  get c_companyIntegrationSpecialIntegrationBlankDate() { return(this.o_tbl_a_company.get("integration_special_integration_blank_date")); }
  get c_companyIntegrationSpecialIntegrationBlankDateTime() { return(this.o_tbl_a_company.get("integration_special_integration_blank_datetime")); }
  
  


  //---------------------------------------------------------------------------------------------------------
  //Open Capture cards
  get c_allCaptureCardsArrayOfObjs() {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const o_tbl_a_capture_cards_custom_names = this.o_tbl_a_capture_cards_custom_names;

    var allCaptureCardsArrayOfObjs = [
      {cardID:this.k_cardIDRevenue,         ceCardName:"Revenue",          adminPictureFileName:"revenue",         adminSort:1,  availableTF: c_productStylingObj.cardRevenueTF},
      {cardID:this.k_cardIDAdvanceStage,    ceCardName:"Advance Stage",    adminPictureFileName:"advanceStage",    adminSort:2,  availableTF: c_productStylingObj.cardAdvanceStageTF},
      {cardID:this.k_cardIDDates,           ceCardName:"Dates",            adminPictureFileName:"dates",           adminSort:3,  availableTF: c_productStylingObj.cardDatesTF},
      {cardID:this.k_cardIDTasks,           ceCardName:"Tasks",            adminPictureFileName:"tasks",           adminSort:4,  availableTF: c_productStylingObj.cardTasksTF},
      {cardID:this.k_cardIDDetails,         ceCardName:"Details",          adminPictureFileName:"details",         adminSort:5,  availableTF: c_productStylingObj.cardDetailsTF},
      {cardID:this.k_cardIDDealShaping,     ceCardName:"Deal Shaping",     adminPictureFileName:"dealShaping",     adminSort:6,  availableTF: c_productStylingObj.cardDealShapingTF},
      {cardID:this.k_cardIDTeammates,       ceCardName:"Teammates",        adminPictureFileName:"teammates",       adminSort:7,  availableTF: c_productStylingObj.cardTeammatesTF},
      {cardID:this.k_cardIDCompetitors,     ceCardName:"Competitors",      adminPictureFileName:"competitors",     adminSort:8,  availableTF: c_productStylingObj.cardCompetitorsTF},
      {cardID:this.k_cardIDProposalThemes,  ceCardName:"Proposal Themes",  adminPictureFileName:"proposalThemes",  adminSort:9,  availableTF: c_productStylingObj.cardProposalThemesTF},
      {cardID:this.k_cardIDRiskAssessment,  ceCardName:"Risk Assessment",  adminPictureFileName:"riskAssessment",  adminSort:10, availableTF: c_productStylingObj.cardRiskAssessmentTF},
      {cardID:this.k_cardIDBudget,          ceCardName:"Budget",           adminPictureFileName:"budget",          adminSort:11, availableTF: c_productStylingObj.cardBudgetTF},
      {cardID:this.k_cardIDConversations,   ceCardName:"Conversations",    adminPictureFileName:"conversations",   adminSort:12, availableTF: c_productStylingObj.cardConversationsTF},
      {cardID:this.k_cardIDDocuments,       ceCardName:"Documents",        adminPictureFileName:"documents",       adminSort:13, availableTF: c_productStylingObj.cardDocumentsTF},
      {cardID:this.k_cardIDTemplates,       ceCardName:"Templates",        adminPictureFileName:"templates",       adminSort:14, availableTF: c_productStylingObj.cardTemplatesTF},
      {cardID:this.k_cardIDIDIQTaskOrders,  ceCardName:"IDIQ/Task Orders", adminPictureFileName:"idiqTaskOrders",  adminSort:15, availableTF: c_productStylingObj.cardIDIQTaskOrdersTF},
      {cardID:this.k_cardIDNotepad,         ceCardName:"Notepad",          adminPictureFileName:"notepad",         adminSort:16, availableTF: c_productStylingObj.cardNotepadTF},
      {cardID:this.k_cardIDChangelog,       ceCardName:"Changelog",        adminPictureFileName:"changelog",       adminSort:17, availableTF: c_productStylingObj.cardChangeLogTF},
    ];

    for(let captureCardObj of allCaptureCardsArrayOfObjs) {
      var cardName = "";
      var cardNameIsCustomTF = false;
      var captureCardsCustomNameMap = o_tbl_a_capture_cards_custom_names.get(captureCardObj.cardID); //try to load an Admin custom card name if it exists for this cardID
      if(captureCardsCustomNameMap !== undefined) {
        cardName = captureCardsCustomNameMap.get("name");
        cardNameIsCustomTF = true;
      }
      else { //if no custom name, use the standard CaptureExec card name
        cardName = captureCardObj.ceCardName;
      }
      captureCardObj.cardName = cardName;
      captureCardObj.cardNameIsCustomTF = cardNameIsCustomTF;
    }

    JSFUNC.sort_arrayOfObjs(allCaptureCardsArrayOfObjs, "adminSort", true);

    return(allCaptureCardsArrayOfObjs);
  }

  get c_availableCaptureCardsArrayOfObjs() {
    return(JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(this.c_allCaptureCardsArrayOfObjs, "availableTF", true));
  }

  get c_availableDetailsCardIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_availableCaptureCardsArrayOfObjs, "cardID"));
  }

  get c_cardNameAdvanceStage() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDAdvanceStage)); }
  get c_cardNameDates() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDDates)); }
  get c_cardNameTasks() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDTasks)); }
  get c_cardNameDetails() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDDetails)); }
  get c_cardNameDealShaping() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDDealShaping)); }
  get c_cardNameTeammates() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDTeammates)); }
  get c_cardNameCompetitors() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDCompetitors)); }
  get c_cardNameProposalThemes() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDProposalThemes)); }
  get c_cardNameRiskAssessment() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDRiskAssessment)); }
  get c_cardNameBudget() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDBudget)); }
  get c_cardNameConversations() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDConversations)); }
  get c_cardNameDocuments() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDDocuments)); }
  get c_cardNameTemplates() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDTemplates)); }
  get c_cardNameIDIQTaskOrders() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDIDIQTaskOrders)); }
  get c_cardNameChangelog() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDChangelog)); }
  get c_cardNameNotepad() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDNotepad)); }
  get c_cardNameRevenue() { return(this.get_details_card_custom_or_ce_name(this.k_cardIDRevenue)); }

  get_details_card_custom_or_ce_name(i_cardID) {
    const captureCardObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_allCaptureCardsArrayOfObjs, "cardID", i_cardID);
    if(captureCardObj !== undefined) {
      return(captureCardObj.cardName);
    }
    return("Card (ID: " + i_cardID + ")");
  }

  get c_availableDetailsCardCustomOrCENamesArray() {
    const c_availableDetailsCardIDsArray = this.c_availableDetailsCardIDsArray;
    var availableDetailsCardCustomOrCENamesArray = [];
    for(let cardID of c_availableDetailsCardIDsArray) {
      availableDetailsCardCustomOrCENamesArray.push(this.get_details_card_custom_or_ce_name(cardID));
    }
    return(availableDetailsCardCustomOrCENamesArray);
  }

  get c_cardAvailableTFAdvanceStage() { return(this.get_details_card_available_tf(this.k_cardIDAdvanceStage)); }
  get c_cardAvailableTFDates() { return(this.get_details_card_available_tf(this.k_cardIDDates)); }
  get c_cardAvailableTFTasks() { return(this.get_details_card_available_tf(this.k_cardIDTasks)); }
  get c_cardAvailableTFDetails() { return(this.get_details_card_available_tf(this.k_cardIDDetails)); }
  get c_cardAvailableTFDealShaping() { return(this.get_details_card_available_tf(this.k_cardIDDealShaping)); }
  get c_cardAvailableTFTeammates() { return(this.get_details_card_available_tf(this.k_cardIDTeammates)); }
  get c_cardAvailableTFCompetitors() { return(this.get_details_card_available_tf(this.k_cardIDCompetitors)); }
  get c_cardAvailableTFProposalThemes() { return(this.get_details_card_available_tf(this.k_cardIDProposalThemes)); }
  get c_cardAvailableTFRiskAssessment() { return(this.get_details_card_available_tf(this.k_cardIDRiskAssessment)); }
  get c_cardAvailableTFBudget() { return(this.get_details_card_available_tf(this.k_cardIDBudget)); }
  get c_cardAvailableTFConversations() { return(this.get_details_card_available_tf(this.k_cardIDConversations)); }
  get c_cardAvailableTFDocuments() { return(this.get_details_card_available_tf(this.k_cardIDDocuments)); }
  get c_cardAvailableTFTemplates() { return(this.get_details_card_available_tf(this.k_cardIDTemplates)); }
  get c_cardAvailableTFIDIQTaskOrders() { return(this.get_details_card_available_tf(this.k_cardIDIDIQTaskOrders)); }
  get c_cardAvailableTFChangelog() { return(this.get_details_card_available_tf(this.k_cardIDChangelog)); }
  get c_cardAvailableTFNotepad() { return(this.get_details_card_available_tf(this.k_cardIDNotepad)); }
  get c_cardAvailableTFRevenue() { return(this.get_details_card_available_tf(this.k_cardIDRevenue)); }

  get_details_card_available_tf(i_cardID) {
    const captureCardObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_allCaptureCardsArrayOfObjs, "cardID", i_cardID);
    if(captureCardObj !== undefined) {
      return(captureCardObj.availableTF);
    }
    return(false);
  }

  get c_customDealShapingQuestionsStringFromCardName() {
    const c_cardNameDealShaping = this.c_cardNameDealShaping;

    //default card name is "Deal Shaping" -> "Deal Shaping Questions", c_cardNameDealShaping custom like "Go/No Go Questions" -> "Go/No Go Questions Questions", so don't add the 2nd Questions if the custom name ends in Questions 
    var customDealShapingQuestionsString = c_cardNameDealShaping;
    const dealShapingCardNameNumChars = c_cardNameDealShaping.length;
    const dealShapingCardNameLast9Chars = c_cardNameDealShaping.substring((dealShapingCardNameNumChars - 9), dealShapingCardNameNumChars);
    if(dealShapingCardNameLast9Chars !== "Questions") {
      customDealShapingQuestionsString += " Questions";
    }
    return(customDealShapingQuestionsString);
  }






  //---------------------------------------------------------------------------------------------------------
  //User Power types and CE Users
  get c_allUserPowersDbNamesArray() {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;

    var allUserPowersDbNamesArray = [];
    if(c_productStylingObj.setupUsersUserPowerSuperAdminTF) { allUserPowersDbNamesArray.push("superadmin"); }
    if(c_productStylingObj.setupUsersUserPowerAdminTF) { allUserPowersDbNamesArray.push("admin"); }
    if(c_productStylingObj.setupUsersUserPowerDivisionExecutiveTF) { allUserPowersDbNamesArray.push("divexec"); }
    if(c_productStylingObj.setupUsersUserPowerDivisionExecutiveReadOnlyTF) { allUserPowersDbNamesArray.push("divexecreadonly"); }
    if(c_productStylingObj.setupUsersUserPowerCaptureExecutiveTF) { allUserPowersDbNamesArray.push("captureexec"); }
    if(c_productStylingObj.setupUsersUserPowerCaptureExecutiveReadOnlyTF) { allUserPowersDbNamesArray.push("captureexecreadonly"); }
    if(c_productStylingObj.setupUsersUserPowerCaptureConsultantTF) { allUserPowersDbNamesArray.push("captureconsultant"); }
    if(c_productStylingObj.setupUsersUserPowerContractsExecutiveTF) { allUserPowersDbNamesArray.push("contractsexec"); }
    if(c_productStylingObj.setupUsersUserPowerContractsManagerTF) { allUserPowersDbNamesArray.push("contracts"); }
    if(c_productStylingObj.setupUsersUserPowerBudgetExecutiveTF) { allUserPowersDbNamesArray.push("budgetexec"); }
    if(c_productStylingObj.setupUsersUserPowerBudgetManagerTF) { allUserPowersDbNamesArray.push("budget"); }

    return(allUserPowersDbNamesArray);
  }

  get c_allUserPowersDataArrayOfObjs() {
    const c_allUserPowersDbNamesArray = this.c_allUserPowersDbNamesArray;

    var allUserPowersDataArrayOfObjs = [];
    for(let userPowerDbName of c_allUserPowersDbNamesArray) {
      var powerDataObj = this.power_data_obj_from_power_name(userPowerDbName);
      allUserPowersDataArrayOfObjs.push(powerDataObj);
    }

    return(allUserPowersDataArrayOfObjs);
  }

  power_data_obj_from_power_name(i_powerDbName) {
    const c_bitSuperAdminsLicenseCost = this.c_bitSuperAdminsLicenseCost;
    const c_bitConsultantsLicenseCost = this.c_bitConsultantsLicenseCost;
    const c_bitReadOnlyLicenseCost = this.c_bitReadOnlyLicenseCost;

    var isSuperAdminTF = false;
    var isAdminTF = false;
    var isDivExecTF = false;
    var isCaptureExecTF = false;
    var isCaptureConsultantTF = false;
    var isContractsExecTF = false;
    var isContractsTF = false;
    var isBudgetExecTF = false;
    var isBudgetTF = false;
    var hasAdminPowerTF = false;
    var hasDivexecPowerTF = false;
    var hasContractsPowerTF = false;
    var hasBudgetPowerTF = false;
    var isReadOnlyTF = false;
    var licenseCost = 1;
    var displayName = undefined
    var displayShortName = undefined;
    var displayNameWithSuperAdminLicenseCost = undefined;
    var displayNameRequiresAnTrueAFalse = undefined; //true - displayName requires 'an' like "an Admin", false - displayName requires 'a' like "a Capture Manager"
    var color = undefined;
    var hashColor = undefined;
    var description = undefined;
    var sortNumber = undefined;

    if(i_powerDbName === "superadmin") {
      isSuperAdminTF = true;
      hasAdminPowerTF = true;
      hasDivexecPowerTF = true;
      hasContractsPowerTF = true;
      hasBudgetPowerTF = true;
      licenseCost = c_bitSuperAdminsLicenseCost;
      displayName = "Super Admin";
      displayShortName = "Super Admin";
      displayNameRequiresAnTrueAFalse = false;
      color = "d82";
      hashColor = "linear-gradient(135deg,#d22,#d82,#dd2,#2d2,#282,#22d)";
      description = "Uses " + licenseCost + " " + JSFUNC.plural(licenseCost, "license", "licenses") + " and can manage and execute all options below: Admin, Executive, Capture, Contracts and Budget";
      sortNumber = 1;
    }
    else if(i_powerDbName === "admin") {
      isAdminTF = true;
      hasAdminPowerTF = true;
      displayName = "Admin";
      displayShortName = "Admin";
      displayNameRequiresAnTrueAFalse = true; //"an Admin"
      color = "cc7722";
      hashColor = "#" + color;
      description = "Manages all aspects of customizing the system and the user experience";
      sortNumber = 2;
    }
    else if(i_powerDbName === "divexec") {
      isDivExecTF = true;
      hasDivexecPowerTF = true;
      displayName = "Division Executive";
      displayShortName = "DivExec";
      displayNameRequiresAnTrueAFalse = false;
      color = "338833";
      hashColor = "#" + color;
      description = "Manages the health and activity of all companies, divisions, departments, business units and people based on the Division they are assigned to by the Admin";
      sortNumber = 3;
    }
    else if(i_powerDbName === "divexecreadonly") {
      isDivExecTF = true;
      hasDivexecPowerTF = true;
      isReadOnlyTF = true;
      licenseCost = c_bitReadOnlyLicenseCost;
      displayName = "Division Executive [Read-Only]";
      displayShortName = "DivExec [RO]";
      displayNameRequiresAnTrueAFalse = false;
      color = "7A997A";
      hashColor = "#" + color;
      description = "A Division Executive, but cannot create, edit, or remove data from the system";
      sortNumber = 4;
    }
    else if(i_powerDbName === "captureexec") {
      isCaptureExecTF = true;
      displayName = "Capture Executive";
      displayShortName = "Capture";
      displayNameRequiresAnTrueAFalse = false;
      color = "6789AB";
      hashColor = "#" + color;
      description = "Manages every aspect of the capture process and can search and find any capture based on the Firewall Options set by the Admin";
      sortNumber = 5;
    }
    else if(i_powerDbName === "captureexecreadonly") {
      isCaptureExecTF = true;
      isReadOnlyTF = true;
      licenseCost = c_bitReadOnlyLicenseCost;
      displayName = "Capture Executive [Read-Only]";
      displayShortName = "Capture [RO]";
      displayNameRequiresAnTrueAFalse = false;
      color = "8A99A8";
      hashColor = "#" + color;
      description = "A Capture Executive, but cannot create, edit, or remove data from the system";
      sortNumber = 6;
    }
    else if(i_powerDbName === "captureconsultant") {
      isCaptureConsultantTF = true;
      licenseCost = c_bitConsultantsLicenseCost;
      displayName = "Capture Consultant";
      displayShortName = "Consult";
      displayNameRequiresAnTrueAFalse = false;
      color = "89ABCD";
      hashColor = "#" + color;
      description = "Can ONLY see opportunities where they are on the 'Capture Team'. They can manage every aspect of the capture process for those opportunities";
      sortNumber = 7;
    }
    else if(i_powerDbName === "contractsexec") {
      isContractsExecTF = true;
      hasContractsPowerTF = true;
      displayName = "Contracts Executive";
      displayShortName = "ContractsExec";
      displayNameRequiresAnTrueAFalse = false;
      color = "aa6666";
      hashColor = "#" + color;
      description = "Manages and oversees activity for all Contracts Managers. Can also manage agreements assigned to them";
      sortNumber = 8;
    }
    else if(i_powerDbName === "contracts") {
      isContractsTF = true;
      hasContractsPowerTF = true;
      displayName = "Contracts Manager";
      displayShortName = "Contracts";
      displayNameRequiresAnTrueAFalse = false;
      color = "cc9999";
      hashColor = "#" + color;
      description = "Manages and creates all agreements assigned to them";
      sortNumber = 9;
    }
    else if(i_powerDbName === "budgetexec") {
      isBudgetExecTF = true;
      hasBudgetPowerTF = true;
      displayName = "Budget Executive";
      displayShortName = "BudgetExec";
      displayNameRequiresAnTrueAFalse = false;
      color = "aaaa66";
      hashColor = "#" + color;
      description = "Manages and oversees activity for all Budget Managers. Can also manage budgets assigned to them";
      sortNumber = 10;
    }
    else if(i_powerDbName === "budget") {
      isBudgetTF = true;
      hasBudgetPowerTF = true;
      displayName = "Budget Manager";
      displayShortName = "Budget";
      displayNameRequiresAnTrueAFalse = false;
      color = "bbbb77";
      hashColor = "#" + color;
      description = "Manages and approves all budgets assigned to them";
      sortNumber = 11;
    }
    else {
      isReadOnlyTF = true;
      displayName = "--Invalid User Power (" + i_powerDbName + ")--";
      displayShortName = displayName;
      displayNameRequiresAnTrueAFalse = true;
      color = "666666";
      hashColor = "#" + color;
      description = displayName;
      sortNumber = 99;
    }

    displayNameWithSuperAdminLicenseCost = displayName;
    if(licenseCost !== 1) {
      displayNameWithSuperAdminLicenseCost += " (uses " + licenseCost + " licenses)";
    }

    return({
      isSuperAdminTF: isSuperAdminTF,
      isAdminTF: isAdminTF,
      isDivExecTF: isDivExecTF,
      isCaptureExecTF: isCaptureExecTF,
      isCaptureConsultantTF: isCaptureConsultantTF,
      isContractsExecTF: isContractsExecTF,
      isContractsTF: isContractsTF,
      isBudgetExecTF: isBudgetExecTF,
      isBudgetTF: isBudgetTF,
      hasAdminPowerTF: hasAdminPowerTF,
      hasDivexecPowerTF: hasDivexecPowerTF,
      hasContractsPowerTF: hasContractsPowerTF,
      hasBudgetPowerTF: hasBudgetPowerTF,
      isReadOnlyTF: isReadOnlyTF,
      licenseCost: licenseCost,
      dbName: i_powerDbName,
      displayName: displayName,
      displayShortName: displayShortName,
      displayNameWithSuperAdminLicenseCost: displayNameWithSuperAdminLicenseCost,
      displayNameRequiresAnTrueAFalse: displayNameRequiresAnTrueAFalse,
      color: color,
      hashColor: hashColor,
      description: description,
      sortNumber: sortNumber
    });
  }


  get c_expandedUsersPerEmailNotDeactivatedMapOfMaps() {
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.c_tbl_a_users_per_email, "upeDeactivatedTF", false));
  }

  get c_combinedUsersNotDeactivatedMapOfMaps() {
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.c_tbl_a_users, "deactivatedTF", false));
  }

  get c_allAdminPowerUsersNotDeactivatedArrayOfObjs() {
    var allAdminOrSuperAdminUsersArrayOfObjs = [];
    for(let combinedUserMap of this.c_combinedUsersNotDeactivatedMapOfMaps.values()) {
      if(combinedUserMap.get("powerHasAdminPowerTF")) {
        var userObj = JSFUNC.obj_from_map(combinedUserMap);
        allAdminOrSuperAdminUsersArrayOfObjs.push(userObj);
      }
    }
    JSFUNC.sort_arrayOfObjs(allAdminOrSuperAdminUsersArrayOfObjs, "sortName", true);
    return(allAdminOrSuperAdminUsersArrayOfObjs);
  }

  get c_userIDsOfAllAdminPowerUsersNotDeactivatedArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_allAdminPowerUsersNotDeactivatedArrayOfObjs, "user_id"));
  }

  get c_userPerEmailIDsOfAllAdminPowerUsersNotDeactivatedArray() {
    const userPerEmailIDsOfAllAdminsNotDeactivatedArray = JSFUNC.get_column_vector_from_arrayOfObjs(this.c_allAdminPowerUsersNotDeactivatedArrayOfObjs, "user_per_email_id");
    const uniqueUserPerEmailIDsOfAllAdminsNotDeactivatedArray = JSFUNC.unique(userPerEmailIDsOfAllAdminsNotDeactivatedArray);
    return(uniqueUserPerEmailIDsOfAllAdminsNotDeactivatedArray);
  }

  get c_userIDsOfAllContractsPowerUsersNotDeactivatedArray() {
    var userIDsOfAllContractsPowerUsersNotDeactivatedArray = [];
    for(let combinedUserMap of this.c_combinedUsersNotDeactivatedMapOfMaps.values()) {
      if(combinedUserMap.get("powerHasContractsPowerTF")) {
        userIDsOfAllContractsPowerUsersNotDeactivatedArray.push(combinedUserMap.get("user_id"));
      }
    }
    return(userIDsOfAllContractsPowerUsersNotDeactivatedArray);
  }

  get c_userIDsOfAllBudgetPowerUsersNotDeactivatedArray() {
    var userIDsOfAllContractsPowerUsersNotDeactivatedArray = [];
    for(let combinedUserMap of this.c_combinedUsersNotDeactivatedMapOfMaps.values()) {
      if(combinedUserMap.get("powerHasBudgetPowerTF")) {
        userIDsOfAllContractsPowerUsersNotDeactivatedArray.push(combinedUserMap.get("user_id"));
      }
    }
    return(userIDsOfAllContractsPowerUsersNotDeactivatedArray);
  }

  //divisions
  get c_allDivisionsArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_divisions, "tree_id", true));
  }

  get c_allDivisionIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_allDivisionsArrayOfObjs, "id"));
  }

  get c_allDivisionIDsComma() {
    return(JSFUNC.convert_array_to_comma_list(this.c_allDivisionIDsArray));
  }

  get c_topDivision00Map() {
    return(this.tbl_row_map_from_matching_field_value("tbl_a_divisions", "tree_id", "00", "o"));
  }

  get c_topDivision00ID() { return(this.c_topDivision00Map.get("id")); }
  get c_topDivision00Name() { return(this.c_topDivision00Map.get("name")); }
  get c_topDivision00BusinessTypeID() { return(this.c_topDivision00Map.get("business_type_id")); }
  get c_topDivision00PrimeSBCertificationsBmSetAsideIDsComma() { return(this.c_topDivision00Map.get("prime_sb_certifications_bm_set_aside_ids_comma")); }

  //stages
  get c_allStagesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.c_tbl_a_stages_pool, "admin_sort", true));
  }

  get c_activeStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", [1,2,3])); }
  get c_submittedStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", [3,4,5])); }
  get c_closedStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", [4,5,6,7,8])); }
  get c_closedWonStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", 4)); }
  get c_closedLostStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", 5)); }
  get c_closedNoBidStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", 6)); }
  get c_closedCancelledStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", [7,8])); }
  get c_activeAndClosedWonStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", [1,2,3,4])); }
  get c_lostNoBidOrCancelledStageIDsArray() { return(JSFUNC.filtered_array_of_values_from_arrayOfObjs_and_output_field_name_matching_filter_field_values(this.c_allStagesArrayOfObjs, "id", "pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", [5,6,7,8])); }


  //capture priority levels
  get c_capturePriorityLevelsEditConstantsObj() {
    const numCapturePriorityLevels = this.o_tbl_a_capture_priority_levels.size;

    var zeroPriorityLevelsTF = false;
    var onePriorityLevelTF = false;
    if(numCapturePriorityLevels === 0) { zeroPriorityLevelsTF = true; }
    else if(numCapturePriorityLevels === 1) { onePriorityLevelTF = true; }

    const containerWidthEm = 4.4;
    const containerHeightEm = containerWidthEm;
    const orbitBorderColor = "bbbbbb";
    const orbitUnselectedBgColor = "e8e8e8";

    var centerCircleDiameterEm = 2.5;
    var centerCircleBorderColor = "dddddd";
    var centerCircleUnselectedBgColor = "f6f6f6";
    if(onePriorityLevelTF) {
      centerCircleDiameterEm = 1.6;
      centerCircleBorderColor = orbitBorderColor;
      centerCircleUnselectedBgColor = orbitUnselectedBgColor;
    }

    var orbitDiameterEm = 2.5;
    if(numCapturePriorityLevels <= 5) { orbitDiameterEm = 1.4; }
    else if(numCapturePriorityLevels <= 8) { orbitDiameterEm = 1.3; }
    else if(numCapturePriorityLevels <= 9) { orbitDiameterEm = 1.2; }
    else if(numCapturePriorityLevels <= 12) { orbitDiameterEm = 1; }
    else if(numCapturePriorityLevels <= 14) { orbitDiameterEm = 0.9; }
    else if(numCapturePriorityLevels <= 16) { orbitDiameterEm = 0.8; }
    else { orbitDiameterEm = 0.5; }

    const containerHalfWidthEm = (containerWidthEm / 2);
    const containerHalfHeightEm = (containerHeightEm / 2);
    const centerCircleRadiusEm = (centerCircleDiameterEm / 2);
    const orbitRadiusEm = (orbitDiameterEm / 2);
    const orbitCenterRadiusFromCenterEm = (containerHalfHeightEm - orbitRadiusEm);

    const centerCircleLeftEm = (containerHalfWidthEm - centerCircleRadiusEm);
    const centerCircleTopEm = (containerHalfHeightEm - centerCircleRadiusEm);

    return({
      numCapturePriorityLevels: numCapturePriorityLevels,
      zeroPriorityLevelsTF: zeroPriorityLevelsTF,
      onePriorityLevelTF: onePriorityLevelTF,
      containerWidthEm: containerWidthEm,
      containerHeightEm: containerHeightEm,
      containerHalfWidthEm: containerHalfWidthEm,
      containerHalfHeightEm: containerHalfHeightEm,
      centerCircleDiameterEm: centerCircleDiameterEm,
      centerCircleRadiusEm: centerCircleRadiusEm,
      centerCircleBorderColor: centerCircleBorderColor,
      centerCircleUnselectedBgColor: centerCircleUnselectedBgColor,
      centerCircleTopEm: centerCircleTopEm,
      centerCircleLeftEm: centerCircleLeftEm,
      orbitDiameterEm: orbitDiameterEm,
      orbitRadiusEm: orbitRadiusEm,
      orbitCenterRadiusFromCenterEm: orbitCenterRadiusFromCenterEm,
      orbitBorderColor: orbitBorderColor,
      orbitUnselectedBgColor: orbitUnselectedBgColor
    });
  }

  get c_capturePriorityLevelsArrayOfObjs() {
    const o_tbl_a_capture_priority_levels = this.o_tbl_a_capture_priority_levels;
    const c_capturePriorityLevelsEditConstantsObj = this.c_capturePriorityLevelsEditConstantsObj;

    var capturePriorityLevelsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(o_tbl_a_capture_priority_levels, "sort", true);

    const numCapturePriorityLevels = capturePriorityLevelsArrayOfObjs.length;
    const startAngleDeg = 90; //start at top of circle
    const ccwTrueCwFalse = false; //move clockwise
    const stepAnglePositiveDeg = (360 / numCapturePriorityLevels);
    const capturePriorityLevelXYPointsArraysObj = JSFUNC.calculate_circle_points_xy_0to1_arrays_obj(startAngleDeg, stepAnglePositiveDeg, ccwTrueCwFalse, numCapturePriorityLevels);

    var index = 0;
    for(let capturePriorityLevelObj of capturePriorityLevelsArrayOfObjs) {
      capturePriorityLevelObj.orbitTopEm = (c_capturePriorityLevelsEditConstantsObj.containerHalfWidthEm - (c_capturePriorityLevelsEditConstantsObj.orbitCenterRadiusFromCenterEm * capturePriorityLevelXYPointsArraysObj.y0to1Array[index]) - c_capturePriorityLevelsEditConstantsObj.orbitRadiusEm);
      capturePriorityLevelObj.orbitLeftEm = (c_capturePriorityLevelsEditConstantsObj.containerHalfWidthEm + (c_capturePriorityLevelsEditConstantsObj.orbitCenterRadiusFromCenterEm * capturePriorityLevelXYPointsArraysObj.x0to1Array[index]) - c_capturePriorityLevelsEditConstantsObj.orbitRadiusEm);
      index++;
    }

    return(capturePriorityLevelsArrayOfObjs);
  }


  //capture types
  get c_captureTypesArrayOfObjs() { //tbl_a_capture_types sorted by sort field ASC
    const o_tbl_a_capture_types = this.o_tbl_a_capture_types;
    const o_tbl_a_capture_types_details_fields = this.o_tbl_a_capture_types_details_fields; //only accessing field_id for revenue field dbNames from this, can use o_ instead of computed c_ tbl map
    
    var captureTypesArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(o_tbl_a_capture_types, "sort", true);

    //precompute the revenue card cost field db_names per capture type for faster computations when fetching revenue card computed money field raw values
    for(let captureTypeObj of captureTypesArrayOfObjs) {
      var revenueCardFieldsGroupID = -1; //-1 is revenue card costs, 0 is dates card dates, 1-N is details card groups
      var captureTypeDetailsFieldsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_capture_types_details_fields, ["capture_type_id", "group_id"], [captureTypeObj.id, revenueCardFieldsGroupID], "sort", true);
      var revenueCostFieldDbNamesArray = [];
      for(let captureTypeDetailsFieldObj of captureTypeDetailsFieldsArrayOfObjs) {
        var captureFieldMap = this.o_tbl_captures_fields.get(captureTypeDetailsFieldObj.field_id);
        if(captureFieldMap !== undefined) {
          revenueCostFieldDbNamesArray.push(captureFieldMap.get("db_name"));
        }
      }

      captureTypeObj.revenueCostFieldDbNamesArray = revenueCostFieldDbNamesArray;
    }

    return(captureTypesArrayOfObjs);
  }

  get c_captureTypeIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_captureTypesArrayOfObjs, "id"));
  }

  get c_captureTypeNamesArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_captureTypesArrayOfObjs, "name"));
  }

  get c_firstCaptureTypeIDOrUndefined() {
    if(this.c_captureTypesArrayOfObjs.length > 0) {
      return(this.c_captureTypesArrayOfObjs[0].id);
    }
    return(undefined);
  }

  get c_createNewCaptureAdminDefaultCaptureTypeIDOrM1() {
    const c_companyCreateNewCaptureDefaultCaptureTypeID = this.c_companyCreateNewCaptureDefaultCaptureTypeID; //admin default setting in System Settings - Capture Types setup
    const c_captureTypeIDsArray = this.c_captureTypeIDsArray;
    const c_firstCaptureTypeIDOrUndefined = this.c_firstCaptureTypeIDOrUndefined;

    //if a valid default capture type is set by the admin, use that capture type id
    if(JSFUNC.in_array(c_companyCreateNewCaptureDefaultCaptureTypeID, c_captureTypeIDsArray)) {
      return(c_companyCreateNewCaptureDefaultCaptureTypeID);
    }

    //if 0 was selected by the admin or the selected capture type id >0 does not exist, use the first capture type in the admin pool (if there is at least 1)
    if(c_companyCreateNewCaptureDefaultCaptureTypeID >= 0) {
      if(c_firstCaptureTypeIDOrUndefined !== undefined) {
        return(c_firstCaptureTypeIDOrUndefined);
      }
    }

    //otherwise return -1 so that a new capture starts with no capture type selected yet
    return(-1);
  }


  //contract types
  get c_idiqContractTypeIDsArray() { //all contractTypeIDs that have the type of IDIQ (sa1_idiq2_to3 === 2)
    var idiqContractTypeIDsArray = [];
    for(let contractTypeMap of this.o_tbl_a_contract_types.values()) {
      if(contractTypeMap.get("sa1_idiq2_to3") === 2) {
        idiqContractTypeIDsArray.push(contractTypeMap.get("id"));
      }
    }
    return(idiqContractTypeIDsArray);
  }

  get c_taskOrderContractTypeIDsArray() { //all contractTypeIDs that have the type of Task Order (sa1_idiq2_to3 === 3)
    var taskOrderContractTypeIDsArray = [];
    for(let contractTypeMap of this.o_tbl_a_contract_types.values()) {
      if(contractTypeMap.get("sa1_idiq2_to3") === 3) {
        taskOrderContractTypeIDsArray.push(contractTypeMap.get("id"));
      }
    }
    return(taskOrderContractTypeIDsArray);
  }


  //reasons won/lost
  get c_reasonsWonIDsArray() {
    const reasonsWonMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_reasons_won_lost, "won1_lost2_both3", 1); //won only (used to filter Reasons Won/Lost list in Advance Stage card based on capture Stage)
    return(JSFUNC.get_column_vector_from_mapOfMaps(reasonsWonMapOfMaps, "id"));
  }

  get c_reasonsLostIDsArray() {
    const reasonsWonMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_reasons_won_lost, "won1_lost2_both3", 2); //lost only (used to filter Reasons Won/Lost list in Advance Stage card based on capture Stage)
    return(JSFUNC.get_column_vector_from_mapOfMaps(reasonsWonMapOfMaps, "id"));
  }


  //business types
  get c_firstBusinessTypeID() {
    const businessTypesArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_business_types, "sort", true);
    if(businessTypesArrayOfObjs.length > 0) {
      return(businessTypesArrayOfObjs[0].id);
    }
    return(-1);
  }


  //pwin/progress/date colors
  get c_pwinColorsArrayOfObjs() {
    var shapingProgressColorsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_pwin_colors, "percent_start", true); //sort the matrix by percent start
    const numColors = shapingProgressColorsArrayOfObjs.length;
    for(let c = 0; c < numColors; c++) {
      var percentEnd = (((c + 1) === numColors) ? (100) : (shapingProgressColorsArrayOfObjs[c + 1].percent_start));
      shapingProgressColorsArrayOfObjs[c].name = shapingProgressColorsArrayOfObjs[c].percent_start + "% to " + percentEnd + "%"; //add a name field used in the progress chart legend to show the color ranges
    }
    return(shapingProgressColorsArrayOfObjs);
  }

  get c_shapingProgressColorsArrayOfObjs() {
    var shapingProgressColorsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_shaping_progress_colors, "percent_start", true); //sort the matrix by percent start
    const numColors = shapingProgressColorsArrayOfObjs.length;
    for(let c = 0; c < numColors; c++) {
      var percentEnd = (((c + 1) === numColors) ? (100) : (shapingProgressColorsArrayOfObjs[c + 1].percent_start));
      shapingProgressColorsArrayOfObjs[c].name = shapingProgressColorsArrayOfObjs[c].percent_start + "% to " + percentEnd + "%"; //add a name field used in the progress chart legend to show the color ranges
    }
    return(shapingProgressColorsArrayOfObjs);
  }

  get c_dateUpcomingColors1ArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_date_upcoming_colors1, "days_before_due", true));
  }

  get c_dateUpcomingColors2ArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_date_upcoming_colors2, "days_before_due", true));
  }


  //teammate admin tables
  get c_teammatesContractTypesArrayOfObjs() {
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_teammates_contract_types, "admin_sort", true));
  }

  get c_teammatesRatingsQuestionsArrayOfObjs() {
    var teammatesRatingsQuestionsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(this.o_tbl_a_teammates_ratings_questions, "sort", true);
    for(let teammatesRatingsQuestionObj of teammatesRatingsQuestionsArrayOfObjs) {
      teammatesRatingsQuestionObj.contactCompaniesRatingColumnDbName = "tr_q" + teammatesRatingsQuestionObj.id;
    }
    return(teammatesRatingsQuestionsArrayOfObjs);
  }


  //filter/sort tables
  get c_expandedFilterPresetsArrayOfObjs() {
    var expandedFilterPresetsArrayOfObjs = [];
    for(let filterPresetMap of this.o_tbl_f_filter_presets.values()) {
      var expandedFilterPresetObj = JSFUNC.obj_from_map(filterPresetMap);

      var excelReportWriterCodewordLowercase = "";
      if(JSFUNC.is_string(expandedFilterPresetObj.excel_report_writer_codeword)) {
        excelReportWriterCodewordLowercase = expandedFilterPresetObj.excel_report_writer_codeword.toLowerCase();
      }
      expandedFilterPresetObj.excelReportWriterCodewordLowercase = excelReportWriterCodewordLowercase;

      expandedFilterPresetsArrayOfObjs.push(expandedFilterPresetObj);
    }
    return(expandedFilterPresetsArrayOfObjs);
  }


  get c_expandedSortPresetsArrayOfObjs() {
    var expandedSortPresetsArrayOfObjs = [];
    for(let sortPresetMap of this.o_tbl_f_sort_presets.values()) {
      var expandedSortPresetObj = JSFUNC.obj_from_map(sortPresetMap);

      var excelReportWriterCodewordLowercase = "";
      if(JSFUNC.is_string(expandedSortPresetObj.excel_report_writer_codeword)) {
        excelReportWriterCodewordLowercase = expandedSortPresetObj.excel_report_writer_codeword.toLowerCase();
      }
      expandedSortPresetObj.excelReportWriterCodewordLowercase = excelReportWriterCodewordLowercase;

      expandedSortPresetsArrayOfObjs.push(expandedSortPresetObj);
    }
    return(expandedSortPresetsArrayOfObjs);
  }


  //captures currently loaded into system (firewalled and archived [unless user allows archived] captures not brought in)
  get c_numCapturesLoadedInSystem() {
    return(this.o_tbl_captures.size);
  }

  get c_allCapturesCurrentlyLoadedCaptureIDsAndNamesLowercaseArrayOfObjs() {
    var captureIDsAndNamesArrayOfObjs = [];
    for(let captureMap of this.o_tbl_captures.values()) {
      var captureName = this.capture_name_plaintext_from_capture_map(captureMap);
      var captureNameLowercase = captureName.toLowerCase();
      captureIDsAndNamesArrayOfObjs.push({
        id: captureMap.get("id"),
        name: captureName,
        nameLowercase: captureNameLowercase
      });
    }

    JSFUNC.sort_arrayOfObjs(captureIDsAndNamesArrayOfObjs, "nameLowercase", true);

    return(captureIDsAndNamesArrayOfObjs);
  }

  get c_allCapturesCurrentlyLoadedCaptureIDsArray() {
    const c_allCapturesCurrentlyLoadedCaptureIDsAndNamesLowercaseArrayOfObjs = this.c_allCapturesCurrentlyLoadedCaptureIDsAndNamesLowercaseArrayOfObjs;
    return(JSFUNC.get_column_vector_from_arrayOfObjs(c_allCapturesCurrentlyLoadedCaptureIDsAndNamesLowercaseArrayOfObjs, "id"));
  }



  //all capture names (for Create New Capture duplicate finder) and archived captures
  get c_allCapturesInDatabaseCaptureIDNamesAndArchiveDateArrayOfObjs() {
    const o_allCapturesNamesAndArchiveDateMapOfMaps = this.o_allCapturesNamesAndArchiveDateMapOfMaps;
    const c_allCapturesCurrentlyLoadedCaptureIDsArray = this.c_allCapturesCurrentlyLoadedCaptureIDsArray;

    var allCapturesInDatabaseCaptureIDNamesAndArchiveDateArrayOfObjs = [];
    for(let captureNamesAndArchiveDateMap of o_allCapturesNamesAndArchiveDateMapOfMaps.values()) {
      var captureIDNamesAndArchiveDateObj = JSFUNC.obj_from_map(captureNamesAndArchiveDateMap);

      var archivedTF = JSFUNC.date_is_filled_out_tf(captureIDNamesAndArchiveDateObj.archive_date);
      var userNoAccessTF = (!archivedTF && !JSFUNC.in_array(captureIDNamesAndArchiveDateObj.id, c_allCapturesCurrentlyLoadedCaptureIDsArray)); //if the capture is not archived, but was not loaded into the system, then it must be unaccessible to the user through firewalling
      var captureName = this.capture_name_plaintext_from_capture_map(captureNamesAndArchiveDateMap, false);
      
      var captureTagOrUndefined = undefined;
      var captureNameWithTag = "";
      if(archivedTF) {
        captureTagOrUndefined = "[Archived]";
        captureNameWithTag = captureTagOrUndefined + " " + captureName;
      }
      else if(userNoAccessTF) {
        captureTagOrUndefined = "[No Access]";
        captureNameWithTag = captureTagOrUndefined + " " + captureName;
      }
      else {
        captureNameWithTag = captureName;
      }

      var captureNameLowercase = captureName.toLowerCase();

      captureIDNamesAndArchiveDateObj.archivedTF = archivedTF;
      captureIDNamesAndArchiveDateObj.userNoAccessTF = userNoAccessTF;
      captureIDNamesAndArchiveDateObj.captureName = captureName;
      captureIDNamesAndArchiveDateObj.captureTagOrUndefined = captureTagOrUndefined;
      captureIDNamesAndArchiveDateObj.captureNameWithTag = captureNameWithTag;
      captureIDNamesAndArchiveDateObj.captureNameLowercase = captureNameLowercase;

      allCapturesInDatabaseCaptureIDNamesAndArchiveDateArrayOfObjs.push(captureIDNamesAndArchiveDateObj);
    }
    return(allCapturesInDatabaseCaptureIDNamesAndArchiveDateArrayOfObjs);
  }

  get c_archivedCaptureIDsArray() {
    const c_allCapturesInDatabaseCaptureIDNamesAndArchiveDateArrayOfObjs = this.c_allCapturesInDatabaseCaptureIDNamesAndArchiveDateArrayOfObjs;

    var archivedCaptureIDsArray = [];
    for(let captureIDNamesAndArchiveDateObj of c_allCapturesInDatabaseCaptureIDNamesAndArchiveDateArrayOfObjs) {
      if(captureIDNamesAndArchiveDateObj.archivedTF) {
        archivedCaptureIDsArray.push(captureIDNamesAndArchiveDateObj.id);
      }
    }
    return(archivedCaptureIDsArray);
  }

  get c_numArchivedCaptures() {
    const c_archivedCaptureIDsArray = this.c_archivedCaptureIDsArray;
    return(c_archivedCaptureIDsArray.length);
  }


  //GCSS imported captures and search results marked as not interested
  get c_gcssAllAlreadyInCEImportedCapturesArrayOfObjs() {
    var allCapturesImportedFromGCSSArrayOfObjs = [];
    for(let [captureID, captureMap] of this.o_tbl_captures) {
      var samGcssID = captureMap.get("gcss_id");
      var gcssGovWinIDString = captureMap.get("gcss_govwin_id_string");
      var gcssFedCompIDString = captureMap.get("gcss_fedcomp_id_string");
      if(JSFUNC.select_int_is_filled_out_tf(samGcssID) || JSFUNC.string_is_filled_out_tf(gcssGovWinIDString) || JSFUNC.string_is_filled_out_tf(gcssFedCompIDString)) {
        var addedDateMaskPlainText = this.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(captureMap, this.c_fieldMapOfAddedDate);
        allCapturesImportedFromGCSSArrayOfObjs.push({
          samGcssID: samGcssID,
          gcssGovWinIDString: gcssGovWinIDString,
          gcssFedCompIDString: gcssFedCompIDString,
          captureID: captureID,
          addedDateMaskPlainText: addedDateMaskPlainText
        });
      }
    }
    return(allCapturesImportedFromGCSSArrayOfObjs);
  }

  get c_gcssAllSamSearchResultsMarkedAsNotInterestedGcssIDsArray() {
    var samGcssIDsArray = [];
    for(let gcssNotInterestedMap of this.o_tbl_g_gcss_not_interested.values()) {
      if(gcssNotInterestedMap.get("undecided0_notinterested1") === 1) {
        var samGcssID = gcssNotInterestedMap.get("gcss_id");
        if(JSFUNC.select_int_is_filled_out_tf(samGcssID)) { //the column `gcss_id` is 0 for all GovWin deals, and filled out with a positive int for SAM deals
          samGcssIDsArray.push(samGcssID);
        }
      }
    }
    return(samGcssIDsArray);
  }

  get c_gcssAllSamSearchResultsMarkedAsNotInterestedSolicitationNumbersArray() {
    const o_userPerEmailID = UserMobx.o_userPerEmailID;

    var samSolicitationNumbersArray = [];
    for(let gcssNotInterestedMap of this.o_tbl_g_gcss_not_interested.values()) {
      if(gcssNotInterestedMap.get("user_per_email_id") === o_userPerEmailID) { //marked by me
        if(gcssNotInterestedMap.get("undecided0_notinterested1") === 1) { //not interested
          var samSolicitationNumber = gcssNotInterestedMap.get("solicitation_number");
          if(JSFUNC.string_is_filled_out_tf(samSolicitationNumber)) {
            samSolicitationNumbersArray.push(samSolicitationNumber);
          }
        }
      }
    }
    return(samSolicitationNumbersArray);
  }

  get c_gcssAllSamSearchResultsMarkedForImportSolicitationNumbersArray() {
    const o_userPerEmailID = UserMobx.o_userPerEmailID;

    var samSolicitationNumbersArray = [];
    for(let gcssNotInterestedMap of this.o_tbl_g_gcss_not_interested.values()) {
      if(gcssNotInterestedMap.get("user_per_email_id") === o_userPerEmailID) { //marked by me
        if(gcssNotInterestedMap.get("undecided0_notinterested1") === 2) { //import
          var samSolicitationNumber = gcssNotInterestedMap.get("solicitation_number");
          if(JSFUNC.string_is_filled_out_tf(samSolicitationNumber)) {
            samSolicitationNumbersArray.push(samSolicitationNumber);
          }
        }
      }
    }
    return(samSolicitationNumbersArray);
  }




  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  get c_captureIDFieldZeroPaddingNumDigitsOrUndefined() {
    const captureFieldMapOfCaptureID = JSFUNC.get_first_map_matching_field_value(this.o_tbl_captures_fields, "db_name", "id");
    if(captureFieldMapOfCaptureID !== undefined) {
      const captureFieldMapOfCaptureIDNumDecimals = captureFieldMapOfCaptureID.get("num_decimals");
      if(captureFieldMapOfCaptureIDNumDecimals > 1) { //-1 or 0 turns this zero padding off, 1 digit would look idential to the normal captureID ints, so 2 digits and above mark this as turned on
        return(captureFieldMapOfCaptureIDNumDecimals);
      }
    }
    return(undefined);
  }

  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  //hardcoded expanded field maps (CST columns from tbl_captures_fields)
  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  get c_fieldMapOfCaptureID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("id")); }
  get c_fieldMapOfCaptureManagers() { return(this.fetch_expanded_capture_field_map_from_field_db_name("capture_managers_ids_colon_percent_comma")); }
  get c_fieldMapOfDivisionOwners() { return(this.fetch_expanded_capture_field_map_from_field_db_name("division_owners_ids_colon_percent_comma")); }
  get c_fieldMapOfCodename() { return(this.fetch_expanded_capture_field_map_from_field_db_name("codename")); }
  get c_fieldMapOfOpportunityName() { return(this.fetch_expanded_capture_field_map_from_field_db_name("opportunity_name")); }
  get c_fieldMapOfCaptureType() { return(this.fetch_expanded_capture_field_map_from_field_db_name("capture_type_id")); }
  get c_fieldMapOfPrimeSub() { return(this.fetch_expanded_capture_field_map_from_field_db_name("tbl_a_capture_types_prime_sub")); }
  get c_fieldMapOfContractType() { return(this.fetch_expanded_capture_field_map_from_field_db_name("contract_type_id")); }
  get c_fieldMapOfStage() { return(this.fetch_expanded_capture_field_map_from_field_db_name("stage_id")); }
  get c_fieldMapOfIdiqCaptureIDTOLink() { return(this.fetch_expanded_capture_field_map_from_field_db_name("idiq_capture_id_TO_link")); }
  get c_fieldMapOfContractOverallValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("contract_overall_value")); }
  get c_fieldMapOfContractRevenueValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("contract_revenue_value")); }
  get c_fieldMapOfAllocatedRevenueValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("allocated_revenue_value")); }
  get c_fieldMapOfAllocatedNetValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("allocated_net_value")); }
  get c_fieldMapOfAllocatedRevenuePerMonth() { return(this.fetch_expanded_capture_field_map_from_field_db_name("allocated_revenue_per_month")); }
  get c_fieldMapOfAllocatedNetPerMonth() { return(this.fetch_expanded_capture_field_map_from_field_db_name("allocated_net_per_month")); }
  get c_fieldMapOfPwinAdjustedContractOverallValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("pwin_adjusted_contract_overall_value")); }
  get c_fieldMapOfPwinAdjustedContractRevenueValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("pwin_adjusted_contract_revenue_value")); }
  get c_fieldMapOfPwinAdjustedAllocatedRevenueValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("pwin_adjusted_allocated_revenue_value")); }
  get c_fieldMapOfPwinAdjustedAllocatedNetValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("pwin_adjusted_allocated_net_value")); }
  get c_fieldMapOfIDIQTOAnticipatedValue() { return(this.fetch_expanded_capture_field_map_from_field_db_name("idiq_to_anticipated_value")); }
  get c_fieldMapOfPeriodOfPerformance() { return(this.fetch_expanded_capture_field_map_from_field_db_name("period_of_performance")); }
  get c_fieldMapOfReasonsWonLost() { return(this.fetch_expanded_capture_field_map_from_field_db_name("reasons_won_lost_ids_colon_percent_comma")); }
  get c_fieldMapOfContractsManager() { return(this.fetch_expanded_capture_field_map_from_field_db_name("contracts_manager_user_id")); }
  get c_fieldMapOfBudgetManager() { return(this.fetch_expanded_capture_field_map_from_field_db_name("budget_manager_user_id")); }
  get c_fieldMapOfOurPrimeSubTeammateDivisionID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("our_prime_sub_teammate_division_id")); }
  get c_fieldMapOfOurPrimeSubTeammateAllocation() { return(this.fetch_expanded_capture_field_map_from_field_db_name("our_prime_sub_teammate_allocation")); }
  get c_fieldMapOfSmallBusinessAllocation() { return(this.fetch_expanded_capture_field_map_from_field_db_name("req_sb_allocation")); }
  get c_fieldMapOfPwin() { return(this.fetch_expanded_capture_field_map_from_field_db_name("pwin")); }
  get c_fieldMapOfTotalShapingProgress() { return(this.fetch_expanded_capture_field_map_from_field_db_name("shaping_total_progress")); }
  get c_fieldMapOfStageShapingProgress() { return(this.fetch_expanded_capture_field_map_from_field_db_name("shaping_stage_progress")); }
  get c_fieldMapOfPrimeCompany() { return(this.fetch_expanded_capture_field_map_from_field_db_name("prime_contact_company_id")); }
  get c_fieldMapOfIncumbentCompanies() { return(this.fetch_expanded_capture_field_map_from_field_db_name("incumbent_contact_company_ids_comma")); }
  get c_fieldMapOfOurIncumbentCompetitorDivisionID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("our_incumbent_competitor_division_id")); }
  get c_fieldMapOfAddedDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("added_date")); }
  get c_fieldMapOfRFPDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("rfp_date")); }
  get c_fieldMapOfProposalDueDateTimeUtc() { return(this.fetch_expanded_capture_field_map_from_field_db_name("proposal_due_datetime_utc")); }
  get c_fieldMapOfAwardDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("award_date")); }
  get c_fieldMapOfContractStartDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("contract_start_date")); }
  get c_fieldMapOfContractEndDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("contract_end_date")); }
  get c_fieldMapOfDescriptionOfWork() { return(this.fetch_expanded_capture_field_map_from_field_db_name("description_of_work")); }
  get c_fieldMapOfNotepad() { return(this.fetch_expanded_capture_field_map_from_field_db_name("notepad")); }
  get c_fieldMapOfRecentPinnedNoteStamp() { return(this.fetch_expanded_capture_field_map_from_field_db_name("recent_pinned_notestamp")); }
  get c_fieldMapOfNextActiveTask() { return(this.fetch_expanded_capture_field_map_from_field_db_name("next_active_task")); }
  get c_fieldMapOfCaptureFavorites() { return(this.fetch_expanded_capture_field_map_from_field_db_name("capture_favorites_upe_x")); }
  get c_fieldMapOfCapturePriorityLevel() { return(this.fetch_expanded_capture_field_map_from_field_db_name("capture_priority_level_id")); }
  get c_fieldMapOfLastChangedDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("last_changed_date")); }
  get c_fieldMapOfLastStageDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("last_stage_date")); }
  get c_fieldMapOfLastProgressDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("last_progress_date")); }
  get c_fieldMapOfLastPWinDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("last_pwin_date")); }
  get c_fieldMapOfLastRFPDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("last_rfp_date")); }
  get c_fieldMapOfArchiveDate() { return(this.fetch_expanded_capture_field_map_from_field_db_name("archive_date")); }
  get c_fieldMapOfCompetitionTypeBmSetAsideIDsComma() { return(this.fetch_expanded_capture_field_map_from_field_db_name("competition_type_bm_set_aside_ids_comma")); }
  get c_fieldMapOfNaicsCodeIDsComma() { return(this.fetch_expanded_capture_field_map_from_field_db_name("naics_code_ids_comma")); }
  get c_fieldMapOfSolicitationNumber() { return(this.fetch_expanded_capture_field_map_from_field_db_name("solicitation_num")); }
  get c_fieldMapOfGcssOverwriteDataWithUpdates01() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_overwrite_data_with_updates_01")); }
  get c_fieldMapOfGcssID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_id")); }
  get c_fieldMapOfGcssGovWinIDString() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_govwin_id_string")); }
  get c_fieldMapOfGcssFedCompIDString() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_fedcomp_id_string")); }
  get c_fieldMapOfGcssOriginalPublishedDateTimeUtc() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_original_published_datetime_utc")); }
  get c_fieldMapOfGcssLastUpdatedDateTimeUtc() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_last_updated_datetime_utc")); }
  get c_fieldMapOfGcssSamLink() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_sam_link")); }
  get c_fieldMapOfGcssGovWinLink() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_govwin_link")); }
  get c_fieldMapOfGcssFedCompLink() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_fedcomp_link")); }
  get c_fieldMapOfGcssBmDepartmentAgencyID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_bm_department_agency_id")); }
  get c_fieldMapOfGcssBmSubTierID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_bm_sub_tier_id")); }
  get c_fieldMapOfGcssOffice() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_office")); }
  get c_fieldMapOfGcssBmProductServiceCodeIDsComma() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_bm_product_service_code_ids_comma")); }
  get c_fieldMapOfGcssBmContractOpportunityTypeID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_bm_contract_opportunity_type_id")); }
  get c_fieldMapOfGcssGovWinProcurementComment() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_govwin_procurement_comment")); }
  get c_fieldMapOfGcssGovWinDuration() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_govwin_duration")); }
  get c_fieldMapOfGcssGovWinPrimaryWorkRequired() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_govwin_primary_work_required")); }
  get c_fieldMapOfGcssGovWinPriority1to5() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_govwin_priority_1to5")); }
  get c_fieldMapOfGcssBmFedCompContractTypeID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_bm_fedcomp_contract_type_id")); }
  get c_fieldMapOfGcssAwardNumber() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_award_number")); }
  get c_fieldMapOfGcssPlaceOfPerformanceAddress() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_place_of_performance_address")); }
  get c_fieldMapOfGcssPlaceOfPerformanceCity() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_place_of_performance_city")); }
  get c_fieldMapOfGcssPlaceOfPerformanceBmStateIDsComma() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_place_of_performance_bm_state_ids_comma")); }
  get c_fieldMapOfGcssPlaceOfPerformanceZip() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_place_of_performance_zip")); }
  get c_fieldMapOfGcssPlaceOfPerformanceProvince() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_place_of_performance_province")); }
  get c_fieldMapOfGcssPlaceOfPerformanceBmCountryIDsComma() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_place_of_performance_bm_country_ids_comma")); }
  get c_fieldMapOfGcssContractingOfficeCity() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_contracting_office_city")); }
  get c_fieldMapOfGcssContractingOfficeBmStateID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_contracting_office_bm_state_id")); }
  get c_fieldMapOfGcssContractingOfficeZip() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_contracting_office_zip")); }
  get c_fieldMapOfGcssContractingOfficeProvince() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_contracting_office_province")); }
  get c_fieldMapOfGcssContractingOfficeBmCountryID() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_contracting_office_bm_country_id")); }
  get c_fieldMapOfGcssContractingOfficePOC1Textarea() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_contracting_office_poc1_name_email_phone")); }
  get c_fieldMapOfGcssContractingOfficePOC2Textarea() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_contracting_office_poc2_name_email_phone")); }
  get c_fieldMapOfGcssAwardeeInformationTextarea() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_awardee_information")); }
  get c_fieldMapOfGcssDocumentLinksJsonString() { return(this.fetch_expanded_capture_field_map_from_field_db_name("gcss_document_links_json_string")); }




  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  //value display arrays objs (provides every select/multiselect/sharedpercent/contacts field with the valueArray and displayArray in the correct sorted order to build a selectWithSearchDataObj and ultimately a fieldTypeObj)
  //this is simply the valueArray and displayArray (and tree/color/bgColor arrays), the selectWithSearchDataObj adds more fields to this to specify if a select is multiSelect, if it can clearSelection, if items can be added to the database by users, etc
  //that selectWithSearchDataObj is then used to create a fieldTypeObj, where the same select can be used to create a select or a sharedpercent
  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  get c_valueDisplayArraysObjBitMasterDepartmentsAgencies() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Department/Agency", this.c_tbl_bit_master_departments_agencies, "id", "name", "name", true, true, undefined, undefined, "departmentAgencyHiddenUnlessCheckedTF"));
  }
  get c_valueDisplayArraysObjBitMasterSubTiers() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Sub-Tier", this.c_tbl_bit_master_sub_tiers, "id", "displayName", "displayName", true, true, undefined, undefined, "subTierHiddenUnlessCheckedTF"));
  }
  get c_valueDisplayArraysObjBitMasterSetAsides() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Set-Aside", this.c_tbl_bit_master_set_asides, "id", "bracketsShortNameName", "nameSort", true, true, undefined, undefined, "setAsideHiddenUnlessCheckedTF"));
  }
  get c_valueDisplayArraysObjBitMasterSetAsidesShortNames() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Set-Aside", this.c_tbl_bit_master_set_asides, "id", "shortNameOrNameIfBlank", "shortNameOrNameIfBlankSort", true, true, undefined, undefined, "setAsideHiddenUnlessCheckedTF"));
  }
  get c_valueDisplayArraysObjBitMasterContractOpportunityTypes() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("SAM Contract Opportunity Type", this.o_tbl_bit_master_contract_opportunity_types, "id", "name", "name", true, true));
  }
  get c_valueDisplayArraysObjBitMasterFedCompContractTypeNames() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Federal Compass Contract Type", this.o_tbl_bit_master_fedcomp_contract_types, "id", "name", "name", true, true));
  }
  get c_valueDisplayArraysObjBitMasterNaicsCodesCombinedNames() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("NAICS Code", this.c_tbl_bit_master_naics_codes, "id", "combinedName", "combinedName", true, true, undefined, undefined, "naicsCodeHiddenUnlessCheckedTF"));
  }
  get c_valueDisplayArraysObjBitMasterNaicsCodesCodes() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("NAICS Code", this.c_tbl_bit_master_naics_codes, "id", "codeString", "codeString", true, true, undefined, undefined, "naicsCodeHiddenUnlessCheckedTF"));
  }
  get c_valueDisplayArraysObjBitMasterNaicsCodesCodeDescOnlys() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("NAICS Code", this.c_tbl_bit_master_naics_codes, "id", "codeDescOnly", "codeDescOnly", true, true, undefined, undefined, "naicsCodeHiddenUnlessCheckedTF"));
  }
  get c_valueDisplayArraysObjBitMasterProductServiceCodes() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Product Service Code", this.c_tbl_bit_master_product_service_codes, "id", "code", "code", true, true, undefined, undefined, "productServiceCodeHiddenUnlessCheckedTF"));
  }
  get c_valueDisplayArraysObjBitMasterStatesA2AndName() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("State", this.c_tbl_bit_master_states, "id", "a2AndName", "id", true, false)); //sort by id in the order they appear in the database tbl (50 states, territories, armed forces)
  }
  get c_valueDisplayArraysObjBitMasterCountriesA3AndName() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Country", this.c_tbl_bit_master_countries, "id", "a3AndName", "sortNameUSOnTop", true, true));
  }

  get c_valueDisplayArraysObjUsersPerEmailNotDeactivated() {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    return(this.create_value_display_arrays_obj_from_mapOfMaps(c_productStylingObj.productName + " User", this.c_expandedUsersPerEmailNotDeactivatedMapOfMaps, "id", "firstNameLastName", "sortName", true, true));
  }

  get c_valueDisplayArraysObjUsers() { //using hiddenUnlessCheckedTF column 'deactivatedTF' to only display non deactivated users, but mask all possibilities
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    return(this.create_value_display_arrays_obj_from_mapOfMaps(c_productStylingObj.productName + " User", this.c_tbl_a_users, "user_id", "fullName", "sortName", true, true, undefined, undefined, "deactivatedTF"));
  }

  get c_valueDisplayArraysObjUsersWithAdminPower() {
    const adminUsersMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.c_tbl_a_users, "powerHasAdminPowerTF", true);
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Admin User", adminUsersMapOfMaps, "user_id", "fullName", "sortName", true, true, undefined, undefined, "deactivatedTF"));
  }

  get c_valueDisplayArraysObjUsersWithContractsPower() {
    const contractsUsersMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.c_tbl_a_users, "powerHasContractsPowerTF", true);
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Contracts User", contractsUsersMapOfMaps, "user_id", "fullName", "sortName", true, true, undefined, undefined, "deactivatedTF"));
  }

  get c_valueDisplayArraysObjUsersWithBudgetPower() {
    const budgetUsersMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.c_tbl_a_users, "powerHasBudgetPowerTF", true);
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Budget User", budgetUsersMapOfMaps, "user_id", "fullName", "sortName", true, true, undefined, undefined, "deactivatedTF"));
  }

  get c_valueDisplayArraysObjDivisions() { //TODO this should be filtered to not include firewalled divisions, but is not
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Division", this.o_tbl_a_divisions, "id", "name", "tree_id", true, true, "tree_id", "color"));
  }

  get c_valueDisplayArraysObjAdminAllDivisionsIncludingFirewalled() { //all divisions
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Division", this.o_tbl_a_divisions, "id", "name", "tree_id", true, true, "tree_id", "color"));
  }

  get c_valueDisplayArraysObjPrimeSub() {
    const valueArray = [1, 2];
    const displayArray = ["Prime", "Sub"];
    return(this.create_value_display_arrays_obj_from_value_array_and_display_array("Prime/Sub", valueArray, false, displayArray));
  }

  get c_valueDisplayArraysObjStages() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Stage", this.c_tbl_a_stages_pool, "id", "name", "admin_sort", true, false, "00", "color"));
  }

  get c_valueDisplayArraysObjCaptureTypes() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Capture Type", this.o_tbl_a_capture_types, "id", "name", "sort", true, false));
  }

  get c_valueDisplayArraysObjContractTypes() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Contract Type", this.o_tbl_a_contract_types, "id", "name", "sort", true, false));
  }

  get c_valueDisplayArraysObjBusinessTypes() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Business Type", this.o_tbl_a_business_types, "id", "name", "sort", true, false));
  }

  get c_valueDisplayArraysObjCapabilities() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Capability", this.o_tbl_a_capabilities, "id", "name", "name", true, true));
  }

  get c_valueDisplayArraysObjCaptureFlagPriorities() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Capture Priority", this.o_tbl_a_capture_priority_levels, "id", "name", "sort", true, false, "00", "color"));
  }

  get c_valueDisplayArraysObjReasonsWonLost() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Reason Won/Lost", this.o_tbl_a_reasons_won_lost, "id", "name", "name", true, true));
  }

  get c_valueDisplayArraysObjReasonsWonOrBoth() {
    const reasonsWonOrBothMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_reasons_won_lost, "won1_lost2_both3", [1, 3]); //won/both
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Reason Won", reasonsWonOrBothMapOfMaps, "id", "name", "name", true, true));
  }

  get c_valueDisplayArraysObjReasonsLostOrBoth() {
    const reasonsLostOrBothMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_reasons_won_lost, "won1_lost2_both3", [2, 3]); //lost/both
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Reason Lost", reasonsLostOrBothMapOfMaps, "id", "name", "name", true, true));
  }

  get c_valueDisplayArraysObjShortcutPresetsCaptureManagerPresets() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Capture Manager Shortcut Presets", this.c_tbl_a_shortcut_presets_capture_managers, "id", "presetNameWithUsersAndPercents", "sort", true, false));
  }

  get c_valueDisplayArraysObjStylingStringPresets() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Styling Presets", this.c_tbl_a_styling_string_presets, "id", "nameAndStyleStringCommaList", "nameLowercase", true, false));
  }

  get c_valueDisplayArraysObjTeammatesContractAgreementTypes() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Teammate Contract Agreement Type", this.o_tbl_a_teammates_contract_agreement_types, "id", "name", "sort", true, false));
  }

  get c_valueDisplayArraysObjTeammatesWorkshareTypes() {
    const c_teammatesWorkshareTypeFieldDisplayName = this.c_teammatesWorkshareTypeFieldDisplayName;
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Teammate " + c_teammatesWorkshareTypeFieldDisplayName, this.o_tbl_a_teammates_workshare_types, "id", "name", "sort", true, false));
  }

  get c_valueDisplayArraysObjTeammatesContractStatuses() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Teammate Contract Status", this.o_tbl_a_teammates_contract_statuses, "id", "name", "sort", true, false));
  }

  get c_valueDisplayArraysObjCaptureFields() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Capture Field", this.o_tbl_captures_fields, "id", "display_name", "display_name", true, true));
  }

  get c_valueDisplayArraysObjMassEditableCaptureFields() {
    var captureFieldsDatesMapOfMaps = new Map();
    for(let [fieldID, expandedCaptureFieldMap] of this.c_tbl_captures_fields) {
      if(expandedCaptureFieldMap.get("canMassEditFieldTF")) {
        captureFieldsDatesMapOfMaps.set(fieldID, expandedCaptureFieldMap);
      }
    }
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Capture Field", captureFieldsDatesMapOfMaps, "id", "display_name", "display_name", true, true));
  }

  get c_valueDisplayArraysObjCaptureFieldsDates() {
    var captureFieldsDatesMapOfMaps = new Map();
    for(let [fieldID, expandedCaptureFieldMap] of this.c_tbl_captures_fields) {
      var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");
      if(fieldTypeObj !== undefined) {
        if(fieldTypeObj.valueDisplayIsDateOrDateTimeTF) {
          captureFieldsDatesMapOfMaps.set(fieldID, expandedCaptureFieldMap);
        }
      }
    }
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Capture Date Field", captureFieldsDatesMapOfMaps, "id", "display_name", "display_name", true, true));
  }

  get c_valueDisplayArraysObjCaptureFieldsMoneyRevenueCard() {
    const valueArray = [
      this.c_fieldMapOfContractOverallValue.get("id"),
      this.c_fieldMapOfContractRevenueValue.get("id"),
      this.c_fieldMapOfAllocatedRevenueValue.get("id"),
      this.c_fieldMapOfAllocatedNetValue.get("id"),
      this.c_fieldMapOfPwinAdjustedContractOverallValue.get("id"),
      this.c_fieldMapOfPwinAdjustedContractRevenueValue.get("id"),
      this.c_fieldMapOfPwinAdjustedAllocatedRevenueValue.get("id"),
      this.c_fieldMapOfPwinAdjustedAllocatedNetValue.get("id"),
      this.c_fieldMapOfIDIQTOAnticipatedValue.get("id")
    ];
    const displayArray = [
      this.c_fieldMapOfContractOverallValue.get("display_name"),
      this.c_fieldMapOfContractRevenueValue.get("display_name"),
      this.c_fieldMapOfAllocatedRevenueValue.get("display_name"),
      this.c_fieldMapOfAllocatedNetValue.get("display_name"),
      this.c_fieldMapOfPwinAdjustedContractOverallValue.get("display_name"),
      this.c_fieldMapOfPwinAdjustedContractRevenueValue.get("display_name"),
      this.c_fieldMapOfPwinAdjustedAllocatedRevenueValue.get("display_name"),
      this.c_fieldMapOfPwinAdjustedAllocatedNetValue.get("display_name"),
      this.c_fieldMapOfIDIQTOAnticipatedValue.get("display_name")
    ];
    return(this.create_value_display_arrays_obj_from_value_array_and_display_array("Capture Money Field", valueArray, false, displayArray));
  }

  get c_valueDisplayArraysObjCaptureShapingQuestionTags() { //not one of the standard capure field types, used directly in deal shaping
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Deal Shaping Question Tag", this.o_tbl_a_shaping_questions_tags, "id", "name", "name", true, true));
  }

  get c_valueDisplayArraysObjPTDifferentiators() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Differentiator", this.o_tbl_a_pt_differentiators, "id", "name", "name", true, true));
  }

  get c_valueDisplayArraysObjRisksCategories() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Risk Category", this.o_tbl_a_risks_categories, "id", "name", "name", true, true));
  }

  get c_valueDisplayArraysObjRisksImpactLevels() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Impact Risk Level", this.o_tbl_a_risks_impact_levels, "id", "name", "percent_0to100", true, false, "00", "color"));
  }

  get c_valueDisplayArraysObjRisksProbabilityLevels() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Probability Risk Level", this.o_tbl_a_risks_probability_levels, "id", "name", "percent_0to100", true, false, "00", "color"));
  }

  get c_valueDisplayArraysObjDeleteCaptureReasons() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("Delete Capture Reason", this.o_tbl_a_delete_capture_reasons, "id", "name", "admin_sort", true, false));
  }

  get c_valueDisplayArraysObjGcssNotInterestedReasons() {
    return(this.create_value_display_arrays_obj_from_mapOfMaps("GCSS Not Interested Reason", this.o_tbl_a_gcss_not_interested_reasons, "id", "name", "sort", true, false));
  }

  get c_valueDisplayArraysObjAdminUserPowersWithSuperAdminLicenseCost() {
    const c_allUserPowersDataArrayOfObjs = this.c_allUserPowersDataArrayOfObjs;

    var allUserPowerDbNamesArray = [];
    var allUserPowerDisplayNamesArray = [];
    var colorArray = [];
    for(let userPowerDataObj of c_allUserPowersDataArrayOfObjs) {
      allUserPowerDbNamesArray.push(userPowerDataObj.dbName);
      allUserPowerDisplayNamesArray.push(userPowerDataObj.displayNameWithSuperAdminLicenseCost);
      colorArray.push(userPowerDataObj.hashColor);
    }

    return(this.create_value_display_arrays_obj_from_value_array_and_display_array("User Power", allUserPowerDbNamesArray, true, allUserPowerDisplayNamesArray, undefined, colorArray));
  }

  get c_valueDisplayArraysObjCaptures() {
    const c_allCapturesCurrentlyLoadedCaptureIDsAndNamesLowercaseArrayOfObjs = this.c_allCapturesCurrentlyLoadedCaptureIDsAndNamesLowercaseArrayOfObjs;
    return(this.create_value_display_arrays_obj_from_arrayOfObjs_and_vd_column_names("Capture", c_allCapturesCurrentlyLoadedCaptureIDsAndNamesLowercaseArrayOfObjs, "id", false, "name"));
  }

  get c_valueDisplayArraysObjCapturesForceCaptureIDInName() {
    const forceCaptureIDInNameTFU = true;
    var captureIDsAndNamesArrayOfObjs = [];
    for(let captureMap of this.o_tbl_captures.values()) {
      var captureNameWithForcedCaptureID = this.capture_name_plaintext_from_capture_map(captureMap, forceCaptureIDInNameTFU);
      var captureNameWithForcedCaptureIDLowercase = captureNameWithForcedCaptureID.toLowerCase();
      captureIDsAndNamesArrayOfObjs.push({
        id: captureMap.get("id"),
        name: captureNameWithForcedCaptureID,
        nameLowercase: captureNameWithForcedCaptureIDLowercase
      });
    }
    JSFUNC.sort_arrayOfObjs(captureIDsAndNamesArrayOfObjs, "nameLowercase", true);

    return(this.create_value_display_arrays_obj_from_arrayOfObjs_and_vd_column_names("Capture", captureIDsAndNamesArrayOfObjs, "id", false, "name"));
  }

  get c_valueDisplayArraysObjIdiqCaptures() { //filter the captures by all IDIQ contract types where sa1_idiq2_to3 is 2, sort the captures in 2 alphabetical groups with 'prior to POP end date' on top in white and 'past POP end date' expired ones on the bottom with a gray background
    const c_companyIDIQVehicleWins0WinsAndActive1 = this.c_companyIDIQVehicleWins0WinsAndActive1;

    const todayDateYmd = JSFUNC.now_date();

    //admin company setting
    var idiqWinsOnlyTF = false;
    var idiqWinsAndActiveTF = false;
    var allIDIQsTF = false;
    if(c_companyIDIQVehicleWins0WinsAndActive1 === 1) { idiqWinsAndActiveTF = true; } //1 - wins and active only
    else if(c_companyIDIQVehicleWins0WinsAndActive1 === 2) { allIDIQsTF = true; } //2 - all IDIQs regardless of stage
    else { idiqWinsOnlyTF = true; } //0 - wins only

    //use IDIQs from Won stage (or also Active if company specified), split them up based on the POP end date into expired and not expired
    var idiqSelectDataArrayOfObjs = [];
    const capturesTblRef = this.tbl_ref_from_tbl_name("tbl_captures");
    for(let captureMap of capturesTblRef.values()) {
      if(JSFUNC.in_array(captureMap.get("contract_type_id"), this.c_idiqContractTypeIDsArray)) { //capture is IDIQ type (won stage filter not used
        var idiqStageID = captureMap.get("stage_id");
        var idiqStageIsWonTF = JSFUNC.in_array(idiqStageID, this.c_closedWonStageIDsArray);
        var idiqStageIsActiveTF = JSFUNC.in_array(idiqStageID, this.c_activeStageIDsArray);
        if((idiqWinsOnlyTF && idiqStageIsWonTF) || (idiqWinsAndActiveTF && (idiqStageIsWonTF || idiqStageIsActiveTF)) || allIDIQsTF) { //IDIQ capture stage is won or won/active depending on company setting in Details Fields Options subtab for IDIQ Vehicle
          var captureID = captureMap.get("id");
          var captureName = this.capture_name_plaintext_from_capture_map(captureMap);
          var captureNameLower = captureName.toLowerCase();

          //build an IDIQ capture display name, showing the stage and expiration contract end date
          var contractEndDate = captureMap.get("contract_end_date");
          var contractEndDateValueMaskPlainText = this.value_mask_plaintext_from_value_raw_and_field_type_obj(contractEndDate, this.c_genericDateFieldTypeObj);
          var stageID = captureMap.get("stage_id");
          var stageValueMaskPlainText = "--Stage Does Not Exist (ID: " + stageID + ")--";
          var stageMapOrUndefined = JSFUNC.get_first_map_matching_field_value(this.o_tbl_a_stages_pool, "id", stageID);
          if(stageMapOrUndefined !== undefined) {
            stageValueMaskPlainText = stageMapOrUndefined.get("name");
          }

          var expiresExpired = "expires";
          if(JSFUNC.date_is_filled_out_tf(contractEndDate) && (todayDateYmd > contractEndDate)) {
            expiresExpired = "expired";
          }
          var idiqCaptureSelectOptionDisplayOverwriteName = "[" + stageValueMaskPlainText + " (" + expiresExpired + " " + contractEndDateValueMaskPlainText + ")] " + captureName;

          //determine if this IDIQ is expired or not (contract_start_date + POP duration compared to today), considered expired if CSD is not set, POP duration considered 0 if it is not set
          var sortCategoryInt = 1;
          var idiqBgColor = "ffffff";
          if(idiqStageIsActiveTF) {
            sortCategoryInt = 2;
            idiqBgColor = "ccddff";
          }
          else if(idiqStageIsWonTF) { //Won stages have to consider whether it's within the CSD-CED PoP period or not (lost and cancelled IDIQs are always considered expired)
            var contractIsExpiredTF = true;
            var contractStartDate = captureMap.get("contract_start_date");
            if(JSFUNC.date_is_filled_out_tf(contractStartDate)) { //CSD is valid, possible that this contract is not expired, use POP to determine that
              var popMonths = captureMap.get("period_of_performance");
              if(popMonths === 0 || !JSFUNC.text_or_number_is_filled_out_tf(popMonths)) {
                contractIsExpiredTF = (todayDateYmd > contractStartDate);
              }
              else { //POP is valid and nonzero, need to compute the POP end date
                var popEndDate = JSFUNC.compute_end_date_from_start_date_and_num_months(contractStartDate, popMonths);
                contractIsExpiredTF = (todayDateYmd > popEndDate);
              }
            }

            if(contractIsExpiredTF) {
              sortCategoryInt = 3;
              idiqBgColor = "cccccc"; //gray background for IDIQ expired
            }
          }
          else {
            sortCategoryInt = 4;
            idiqBgColor = "ffbbbb"; //red background for IDIQ lost, no bid, or cancelled
          }

          var selectDataObj = {
            v: captureID,
            d: captureName,
            s: captureNameLower,
            bg: idiqBgColor,
            odoS: idiqCaptureSelectOptionDisplayOverwriteName,
            sortCategoryInt: sortCategoryInt
          };

          idiqSelectDataArrayOfObjs.push(selectDataObj);
        }
      }
    }

    //sort the not expired and expired select objs alphabetically separate from each other
    JSFUNC.sort_arrayOfObjs(idiqSelectDataArrayOfObjs, ["sortCategoryInt", "s"], [true, true]);

    const valueArray = JSFUNC.get_column_vector_from_arrayOfObjs(idiqSelectDataArrayOfObjs, "v");
    const displayArray = JSFUNC.get_column_vector_from_arrayOfObjs(idiqSelectDataArrayOfObjs, "d");
    const bgColorArray = JSFUNC.get_column_vector_from_arrayOfObjs(idiqSelectDataArrayOfObjs, "bg");
    const optionDisplayOverwriteStringArray = JSFUNC.get_column_vector_from_arrayOfObjs(idiqSelectDataArrayOfObjs, "odoS");
    return(this.create_value_display_arrays_obj_from_value_array_and_display_array("IDIQ Capture", valueArray, false, displayArray, undefined, undefined, bgColorArray, undefined, undefined, optionDisplayOverwriteStringArray, undefined));
  }

  get c_allTblCapsMapOfComputedTblCapObjs() { //Map (key is tbl_cap tbl name) of valueDisplayArraysObjs for each cap tbl
    //for every tbl_cap, store a value array of item ids, and a display array of item names in the correct sorted order (by name or by sort number based on field type) for use by input selects when editing the capture value
    var allTblCapsMapOfComputedTblCapObjs = new Map();
    for(let [tblCapName, tblCapMapOfMaps] of this.o_tbl_cap) {
      var tblCapHasSortColumnTF = (tblCapName.substring(0, 10) === "tbl_cap_s_"); //determine if the table has a sort column whether the tbl name starts with "tbl_cap_s_" or "tbl_cap_n_"

      var tblCapTempItemName = tblCapName; //this itemName for this valueDisplayArraysObj is a temporary placeholder to be overwrittn (capture field display_name) when creating select/selectmulti fieldTypeObjs for each custom capture field that uses these
      var sortByPropertyName = ((tblCapHasSortColumnTF) ? ("sort") : ("name"));
      var sortStringTrueNumberFalse = (!tblCapHasSortColumnTF);
      var valueDisplayArraysObj = this.create_value_display_arrays_obj_from_mapOfMaps(tblCapTempItemName, tblCapMapOfMaps, "id", "name", sortByPropertyName, true, sortStringTrueNumberFalse);

      var computedTblCapObj = {
        tblCapHasSortColumnTF: tblCapHasSortColumnTF,
        valueDisplayArraysObj: valueDisplayArraysObj
      };

      allTblCapsMapOfComputedTblCapObjs.set(tblCapName, computedTblCapObj); //Map is keyed by the tbl_cap tbl name
    }
    return(allTblCapsMapOfComputedTblCapObjs);
  }


  get c_valueDisplayArraysObjContactCompanies() {
    const c_allCompaniesArrayOfObjs = ContactsMobx.c_allCompaniesArrayOfObjs;
    const contactNameField = ContactsMobx.contact_name_field(false);
    return(this.create_value_display_arrays_obj_from_arrayOfObjs_and_vd_column_names("Contact Company", c_allCompaniesArrayOfObjs, "id", false, contactNameField));
  }

  get c_valueDisplayArraysObjContactCompaniesWithUsMinus2AtTop() {
    const c_valueDisplayArraysObjContactCompanies = this.c_valueDisplayArraysObjContactCompanies;
    const valueArray = JSFUNC.concat_arrays_or_values_into_new_array(987987987987, c_valueDisplayArraysObjContactCompanies.valueArray);
    const displayArray = JSFUNC.concat_arrays_or_values_into_new_array("++ Our Company ++", c_valueDisplayArraysObjContactCompanies.displayArray);
    return(this.create_value_display_arrays_obj_from_value_array_and_display_array(c_valueDisplayArraysObjContactCompanies.itemName, valueArray, c_valueDisplayArraysObjContactCompanies.valuesAreStringsTF, displayArray));
  }

  get c_valueDisplayArraysObjContactPersons() {
    const c_allPersonsArrayOfObjs = ContactsMobx.c_allPersonsArrayOfObjs;
    const contactNameField = ContactsMobx.contact_name_field(true);
    return(this.create_value_display_arrays_obj_from_arrayOfObjs_and_vd_column_names("Contact Person", c_allPersonsArrayOfObjs, "id", false, contactNameField));
  }




  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  //fieldTypeObjs precomputed (for masking raw values and for creating edit components) for generic fields (text, date, color, etc) and for specific selects (select/selectmulti for contacts, users, divisions, etc)
  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
  get c_allQuestionIDsArrayAndFieldTypeObjsArrayObj() {
    //deal shaping questions - precompute the fieldTypeObj/selectWithSearchDataObj for every question (from every capture type) in the system (makes it so OpenCaptureMobx doesn't have to recompute attaching the select answers to each question every time an answer is changed or a capture is opened)
    var allQuestionIDsArray = []; //array of every questionID in the system (from all capture types mixed together)
    var allQuestionFieldTypeObjsArray = []; //array of every computed fieldTypeObj for each questionID in the same index of the array
    for(let questionMap of this.o_tbl_a_shaping_questions_pool.values()) {
      var questionID = questionMap.get("id");
      var answerType123 = questionMap.get("answer_select1_text2_textarea3");

      var fieldTypeObj = undefined;
      if(answerType123 === 1) { //select answer type
        fieldTypeObj = JSFUNC.copy_obj(this.c_selectTypeQuestionFieldTypeObjNoSwsDataObj);

        //compute the selectWithSearchDataObj for the select answers paired with this select type question
        var answersToThisSelectQuestionMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_shaping_select_answers, "question_id", questionID); //get only the answers to this question
        var questionAnswersValueDisplayArraysObj = this.create_value_display_arrays_obj_from_mapOfMaps("Answer", answersToThisSelectQuestionMapOfMaps, "id", "name", "score0to100", false, false); //sort by score0to100 desc
        var swsOptionsObj = {hasClearSelectionTF:true};
        fieldTypeObj.selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(questionAnswersValueDisplayArraysObj, swsOptionsObj);
      }
      else if(answerType123 === 2) { //scored text answer type
        fieldTypeObj = this.c_scoredTextTypeQuestionFieldTypeObj;
      }
      else if(answerType123 === 3) { //scored textarea answer type
        fieldTypeObj = this.c_scoredTextareaTypeQuestionFieldTypeObj;
      }
      else { //invalid answer type number that is not 1,2,3
        fieldTypeObj = this.create_field_type_obj("questionID" + questionID + "_answerType" + answerType123, undefined); //create a custom fieldTypeObj that will fail and display the questionID and the answerType number
      }

      allQuestionIDsArray.push(questionID);
      allQuestionFieldTypeObjsArray.push(fieldTypeObj);
    }

    return({
      allQuestionIDsArray: allQuestionIDsArray,
      allQuestionFieldTypeObjsArray: allQuestionFieldTypeObjsArray
    });
  }

  get_question_field_type_obj_from_question_id(i_questionID) {
    const matchIndex = this.c_allQuestionIDsArrayAndFieldTypeObjsArrayObj.allQuestionIDsArray.indexOf(i_questionID);
    if(matchIndex >= 0) { //found the precomputed question matching the input questionID (select questions and scored_text/textarea questions)
      return(this.c_allQuestionIDsArrayAndFieldTypeObjsArrayObj.allQuestionFieldTypeObjsArray[matchIndex]); //return this question's precomputed fieldTypeObj
    }
    return(this.create_field_type_obj("questionID" + i_questionID, undefined)); //questionID is not one of the questions in the system, create an empty fieldTypeObj
  }

  get c_selectTypeQuestionFieldTypeObjNoSwsDataObj() { //used for questions with answer_select1_text2_textarea3 of 1
    const swsOptionsObj = {hasClearSelectionTF:true};
    const tempSelectQuestionAnswersSwsDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Answer", [], false, [], swsOptionsObj);
    return(this.create_field_type_obj("select", tempSelectQuestionAnswersSwsDataObj));
  }

  get c_scoredTextTypeQuestionFieldTypeObj() { //used for questions with answer_select1_text2_textarea3 of 2
    return(this.create_field_type_obj("scored_text", undefined));
  }

  get c_scoredTextareaTypeQuestionFieldTypeObj() { //used for questions with answer_select1_text2_textarea3 of 3
    return(this.create_field_type_obj("scored_textarea", undefined));
  }

  get c_scoredTextScoreHiddenTypeQuestionFieldTypeObj() { //only used internally for hiding the score of a Deal Shaping text question when on the Advance Stage Card as a closed stage debrief question
    return(this.create_field_type_obj("scored_text_score_hidden", undefined));
  }

  get c_scoredTextareaScoreHiddenTypeQuestionFieldTypeObj() { //only used internally for hiding the score of a Deal Shaping textarea question when on the Advance Stage Card as a closed stage debrief question
    return(this.create_field_type_obj("scored_textarea_score_hidden", undefined));
  }

  get c_genericTextFieldTypeObj() { return(this.create_field_type_obj("text", undefined)); }
  get c_genericTextareaFieldTypeObj() { return(this.create_field_type_obj("textarea", undefined)); }
  get c_genericEmailFieldTypeObj() { return(this.create_field_type_obj("email", undefined)); }
  get c_genericPhoneFieldTypeObj() { return(this.create_field_type_obj("phone", undefined)); }
  get c_genericWebsiteFieldTypeObj() { return(this.create_field_type_obj("website", undefined)); }
  get c_genericIntFieldTypeObj() { return(this.create_field_type_obj("int", undefined)); }
  get c_genericIntPositiveFieldTypeObj() { return(this.create_field_type_obj("int_positive", undefined)); }
  get c_genericInt0to9FieldTypeObj() { return(this.create_field_type_obj("int_0to9", undefined));}
  get c_genericDecimalFieldTypeObj() { return(this.create_field_type_obj("decimal", undefined)); }
  get c_genericDecimalPositiveFieldTypeObj() { return(this.create_field_type_obj("decimal_positive", undefined)); }
  get c_genericPercentIntFieldTypeObj() { return(this.create_field_type_obj("percent_int", undefined));}
  get c_genericPercentInt0to100FieldTypeObj() { return(this.create_field_type_obj("percent_int_0to100", undefined));}
  get c_genericPercentIntm1000to100FieldTypeObj() { return(this.create_field_type_obj("percent_m1000to100", undefined));}
  get c_genericPercentDecimalFieldTypeObj() { return(this.create_field_type_obj("percent_decimal", undefined));}
  get c_genericPercentDecimal0to100FieldTypeObj() { return(this.create_field_type_obj("percent_decimal_0to100", undefined));}
  get c_genericProgressBarShapingFieldTypeObj() { return(this.create_field_type_obj("progress_bar_shaping", undefined)); }
  get c_genericMoneyFieldTypeObj() { return(this.create_field_type_obj("money", undefined)); }
  get c_genericMoneyCentsFieldTypeObj() { return(this.create_field_type_obj("money_cents", undefined)); }
  get c_genericColorFieldTypeObj() { return(this.create_field_type_obj("color", undefined)); }
  get c_genericDateFieldTypeObj() { return(this.create_field_type_obj("date", undefined)); }
  get c_genericDateFromRawDateTimeUtcFieldTypeObj() { return(this.create_field_type_obj("date_fromRawDateTimeUtc", undefined)); }
  get c_genericDateYmdFromRawDateTimeUtcFieldTypeObj() { return(this.create_field_type_obj("date_Ymd_fromRawDateTimeUtc", undefined)); }
  get c_genericDateDMjYFieldTypeObj() { return(this.create_field_type_obj("date_DMjY", undefined)); }
  get c_genericDateDayMdyDaysUntil1FieldTypeObj() { return(this.create_field_type_obj("date_dayMdyDaysUntil1", undefined)); }
  get c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj() { return(this.create_field_type_obj("date_dayMdyDaysUntil1Overdue", undefined)); }
  get c_genericDateDayMdyDaysUntil2FieldTypeObj() { return(this.create_field_type_obj("date_dayMdyDaysUntil2", undefined)); }
  get c_genericDateDayMdyDaysUntil2OverdueFieldTypeObj() { return(this.create_field_type_obj("date_dayMdyDaysUntil2Overdue", undefined)); }
  get c_genericDateWithRelativeDateFieldTypeObj() { return(this.create_field_type_obj("dateWithRelativeDate", undefined)); }
  get c_genericDateWithDurationFieldTypeObj() { return(this.create_field_type_obj("dateWithDuration", undefined)); }
  get c_genericDateTimeFieldTypeObj() { return(this.create_field_type_obj("datetime", undefined)); }
  get c_genericDateTimeNaturalFieldTypeObj() { return(this.create_field_type_obj("datetime_natural", undefined)); }
  get c_genericDateTimeYmdgiaFieldTypeObj() { return(this.create_field_type_obj("datetime_YmdgiA", undefined)); }
  get c_genericDateTimeYmdgiaNaturalFieldTypeObj() { return(this.create_field_type_obj("datetime_YmdgiA_natural", undefined)); }
  get c_genericDateTimeMjYgiAFieldTypeObj() { return(this.create_field_type_obj("datetime_MjYgiA", undefined)); }
  get c_genericDateTimeMjYgiANaturalFieldTypeObj() { return(this.create_field_type_obj("datetime_MjYgiA_natural", undefined)); }
  get c_genericDateTimeDayMdyDaysUntil1FieldTypeObj() { return(this.create_field_type_obj("datetime_dayMdyDaysUntil1", undefined)); }
  get c_genericDateTimeDayMdyDaysUntil1OverdueFieldTypeObj() { return(this.create_field_type_obj("datetime_dayMdyDaysUntil1Overdue", undefined)); }
  get c_genericDateTimeDayMdyDaysUntil2FieldTypeObj() { return(this.create_field_type_obj("datetime_dayMdyDaysUntil2", undefined)); }
  get c_genericDateTimeDayMdyDaysUntil2OverdueFieldTypeObj() { return(this.create_field_type_obj("datetime_dayMdyDaysUntil2Overdue", undefined)); }
  get c_genericStylingStringFieldTypeObj() { return(this.create_field_type_obj("stylingString", undefined)); }
  get c_genericStylingStringFontBlueFieldTypeObj() { return(this.create_field_type_obj("stylingString_fontBlue", undefined)); }
  get c_genericStylingStringFontDarkGrayBoldFieldTypeObj() { return(this.create_field_type_obj("stylingString_fontDarkGrayBold", undefined)); }

  //other fieldTypeObjs
  get c_selectMultiBitMasterDepartmentsAgenciesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterDepartmentsAgencies, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterSubTiersFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterSubTiers, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectBitMasterSetAsideFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterSetAsides, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterSetAsidesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterSetAsides, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterSetAsidesShortNamesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterSetAsidesShortNames, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterContractOpportunityTypesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterContractOpportunityTypes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterFedCompContractTypeNamesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterFedCompContractTypeNames, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterNaicsCodesCombinedNamesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterNaicsCodesCombinedNames, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterProductServiceCodesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterProductServiceCodes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterStatesA2AndNameFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterStatesA2AndName, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBitMasterCountriesA3AndNameFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBitMasterCountriesA3AndName, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectUserPerEmailNotDeactivatedFieldTypeObj() { //select Admin User reassign custom creations
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjUsersPerEmailNotDeactivated, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiUsersPerEmailNotDeactivatedFieldTypeObj() { //select broadcast recipients
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjUsersPerEmailNotDeactivated, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiUsersPerEmailNotDeactivatedOrAllFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true, multiSelectHasAllSwitchTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjUsersPerEmailNotDeactivated, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectUserFieldTypeObj() { //user name mask/plaintext/ifo from any userID functions, task recipient
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjUsers, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiUsersFieldTypeObj() { //user name mask/plaintext/ifo from any userID functions, task recipient
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjUsers, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectUserWithAdminPowerFieldTypeObj() { //admin changelog User column filtering
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjUsersWithAdminPower, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectUserWithContractsPowerFieldTypeObj() { //contractsexec reassign contracts
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjUsersWithContractsPower, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectUserWithBudgetPowerFieldTypeObj() { //budgetexec reassign budgets
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjUsersWithBudgetPower, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectDivisionFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjDivisions, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiDivisionsFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjDivisions, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiDivisionsOrAllFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true, multiSelectHasAllSwitchTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjDivisions, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiAdminAllDivisionsIncludingFirewalledFieldTypeObj() { //Admin - Company - editing a Division - all divisions that a potential user assigned to edited division can see
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjAdminAllDivisionsIncludingFirewalled, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectCaptureTypeFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureTypes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectCaptureTypeNoClearFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureTypes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiStagesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjStages, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectContactCompanyFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true, selectContactCompanyTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjContactCompanies, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiContactCompaniesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true, selectContactCompanyTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjContactCompanies, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectContactCompanyWithUsMinus2AtTopFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true, selectContactCompanyTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjContactCompaniesWithUsMinus2AtTop, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiContactCompaniesWithUsMinus2AtTopFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true, selectContactCompanyTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjContactCompaniesWithUsMinus2AtTop, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectContactPersonFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true, selectContactPersonTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjContactPersons, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiContactPersonsFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true, selectContactPersonTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjContactPersons, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectContactCompanyExtraFieldFieldTypeObj() { //Contacts Advanced Search - custom field search
    const valueDisplayArraysObjContactCompanyExtraFields = this.create_value_display_arrays_obj_from_mapOfMaps("Contact Company Extra Field", this.o_tbl_a_contacts_companies_extra_fields, "id", "display_name", "sort", true, false);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjContactCompanyExtraFields, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectDealShapingQuestionsSelectFieldTypeObj() {
    const dealShapingQuestionsSelectMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_shaping_questions_pool, "answer_select1_text2_textarea3", 1); //select
    const valueDisplayArraysObjDealShapingQuestionsSelect = this.create_value_display_arrays_obj_from_mapOfMaps("Deal Shaping Question (Select)", dealShapingQuestionsSelectMapOfMaps, "id", "name", "name", true, true);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjDealShapingQuestionsSelect, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectDealShapingQuestionsTextareaFieldTypeObj() {
    const dealShapingQuestionsTextareaMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_shaping_questions_pool, "answer_select1_text2_textarea3", [2, 3]); //text/textarea
    const valueDisplayArraysObjDealShapingQuestionsTextarea = this.create_value_display_arrays_obj_from_mapOfMaps("Deal Shaping Question (Textarea)", dealShapingQuestionsTextareaMapOfMaps, "id", "name", "name", true, true);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjDealShapingQuestionsTextarea, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectNoClearDealShapingQuestionTagsFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureShapingQuestionTags, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiDealShapingQuestionTagsFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureShapingQuestionTags, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiAddDealShapingQuestionTagsFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true, selectAddTblName:"tbl_a_shaping_questions_tags", selectAddNameFieldDbName:"name"};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureShapingQuestionTags, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectBusinessTypeFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBusinessTypes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiBusinessTypesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjBusinessTypes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiCapabilitiesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCapabilities, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiAddCapabilitiesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true, selectAddTblName:"tbl_a_capabilities", selectAddNameFieldDbName:"name"};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCapabilities, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectCaptureFlagFieldTypeObj() {
    const swsOptionsObj = {hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureFlagPriorities, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectShortcutPresetsCaptureManagerPresetFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjShortcutPresetsCaptureManagerPresets, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectShortcutPresetsDocumentsCardFoldersPresetFieldTypeObj() {
    const c_tbl_a_shortcut_presets_documents_card_folders = this.c_tbl_a_shortcut_presets_documents_card_folders;
    const c_cardNameDocuments = this.c_cardNameDocuments;
    const shortcutPresetsDocumentsCardFoldersValueDisplayArraysObj = this.create_value_display_arrays_obj_from_mapOfMaps(c_cardNameDocuments + " Card Folders Shortcut Presets", c_tbl_a_shortcut_presets_documents_card_folders, "id", "preset_name", "sort", true, false);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(shortcutPresetsDocumentsCardFoldersValueDisplayArraysObj, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectStylingStringPresetFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjStylingStringPresets, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectTeammatesContractAgreementTypeFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjTeammatesContractAgreementTypes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectTeammatesWorkshareTypeFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjTeammatesWorkshareTypes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectTeammatesContractStatusTypeFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjTeammatesContractStatuses, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectAddPTDifferentiatorsFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, selectAddTblName:"tbl_a_pt_differentiators", selectAddNameFieldDbName:"name"};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjPTDifferentiators, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectRisksCategoriesFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjRisksCategories, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectRisksImpactLevelFieldTypeObj() {
    const swsOptionsObj = {hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjRisksImpactLevels, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectRisksProbabilityLevelFieldTypeObj() {
    const swsOptionsObj = {hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjRisksProbabilityLevels, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectBudgetCategoryFieldTypeObj() {
    const valueDisplayArraysObjBudgetCategories = this.create_value_display_arrays_obj_from_mapOfMaps("Budget Category", this.o_tbl_a_budget_categories_pool, "id", "name", "sort", true, false);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjBudgetCategories, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectBudgetExpenseTypeFieldTypeObj() {
    const valueDisplayArraysObjBudgetExpenseTypes = this.create_value_display_arrays_obj_from_mapOfMaps("Expense Type", this.o_tbl_a_budget_expense_types, "id", "name", "name", true, true);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true, selectAddTblName:"tbl_a_budget_expense_types", selectAddNameFieldDbName:"name"};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjBudgetExpenseTypes, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectPrimeSubFieldTypeObj() {
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjPrimeSub, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiChangelogCaptureCardsFieldTypeObj() {
    const c_availableDetailsCardIDsArray = this.c_availableDetailsCardIDsArray;
    const c_availableDetailsCardCustomOrCENamesArray = this.c_availableDetailsCardCustomOrCENamesArray;

    const extraNegativeCaptureCardIDsArray = [
      this.k_cardIDUserCreateNewCapture,
      this.k_cardIDGCSSUserImportNewCapture,
      this.k_cardIDGCSSSystemUpdateExistingCapture,
      this.k_cardIDAdminImportNewCapture,
      this.k_cardIDAdminUpdateExistingCapture,
      this.k_cardIDThirdPartyCRMCreateNewCapture,
      this.k_cardIDThirdPartyCRMUpdateExistingCapture,
    ];
    const extraNegativeCaptureCardNamesArray = [
      this.k_cardNameUserCreateNewCapture,
      this.k_cardNameGCSSUserImportNewCapture,
      this.k_cardNameGCSSSystemUpdateExistingCapture,
      this.k_cardNameAdminImportNewCapture,
      this.k_cardNameAdminUpdateExistingCapture,
      this.k_cardNameThirdPartyCRMCreateNewCapture,
      this.k_cardNameThirdPartyCRMUpdateExistingCapture,
    ];

    const cardIDsArray = JSFUNC.concat_arrays_or_values_into_new_array(c_availableDetailsCardIDsArray, extraNegativeCaptureCardIDsArray);
    const cardNamesArray = JSFUNC.concat_arrays_or_values_into_new_array(c_availableDetailsCardCustomOrCENamesArray, extraNegativeCaptureCardNamesArray);

    const valueDisplayArraysObjCaptureCards = this.create_value_display_arrays_obj_from_value_array_and_display_array("Capture Card", cardIDsArray, false, cardNamesArray);
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjCaptureCards, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectCaptureFieldFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureFields, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiCaptureFieldsFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureFields, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectNoClearCaptureFieldFieldTypeObj() { //to pick search fields in admin
    const swsOptionsObj = {hasSearchTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureFields, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMassEditableCaptureFieldFieldTypeObj() { //fields from tbl_captures_fields, but only editable ones for captures table mass edit
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjMassEditableCaptureFields, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectCaptureDateFieldFieldTypeObj() { //fields from tbl_captures_fields, but only "date" and "datetime" types (used when selecting date field for trend analyzer graphs)
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureFieldsDates, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_ganttPresetEditorSelectMultiCaptureDateFieldsFieldTypeObj() { //fields from tbl_captures_fields, but only "date" and "datetime" types (used when selecting gantt chart date fields)
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureFieldsDates, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectCaptureRevenueCardMoneyFieldFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptureFieldsMoneyRevenueCard, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiAddDeleteCaptureReasonsFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasClearSelectionTF:true, selectAddTblName:"tbl_a_delete_capture_reasons", selectAddNameFieldDbName:"name", selectAddSortFieldDbName:"admin_sort"};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjDeleteCaptureReasons, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiAddGcssNotInterestedReasonsFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasClearSelectionTF:true, selectAddTblName:"tbl_a_gcss_not_interested_reasons", selectAddNameFieldDbName:"name", selectAddSortFieldDbName:"sort"};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjGcssNotInterestedReasons, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectUserPowerWithSuperAdminLicenseCostFieldTypeObj() {
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjAdminUserPowersWithSuperAdminLicenseCost, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectStageType1234567FieldTypeObj() {
    const valueArray = [1, 2, 3, 4, 5, 6, 7, 8];
    const displayArray = ["Pre-Active", "Active", "Active Post Submission", "Closed - Won", "Closed - Lost", "Closed - Not Submitted (No Bid)", "Closed - Not Submitted (Government Cancelled)", "Closed - Not Submitted (Customer Cancelled)"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Stage Type", valueArray, false, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectAdminImportCaptureImportPresetsFieldTypeObj() {
    const importCaptureImportPresetsMapofMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_import_presets, "import0_update1", 0);
    const valueDisplayArraysObjImportPresets = this.create_value_display_arrays_obj_from_mapOfMaps("Capture Import Preset", importCaptureImportPresetsMapofMaps, "id", "name", "name", true, true);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjImportPresets, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectAdminImportCaptureUpdatePresetsFieldTypeObj() {
    const importCaptureUpdatePresetsMapofMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_import_presets, "import0_update1", 1);
    const valueDisplayArraysObjImportPresets = this.create_value_display_arrays_obj_from_mapOfMaps("Capture Update Preset", importCaptureUpdatePresetsMapofMaps, "id", "name", "name", true, true);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjImportPresets, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectAdminFontColorFieldTypeObj() {
    const valueArray = ["2a2a2a", "999999", "ffffff"];
    const displayArray = ["Black", "Gray", "White"];
    const colorArray = valueArray;
    const swsOptionsObj = {colorArray:colorArray};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Font Color", valueArray, true, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMonth1to12FieldTypeObj() {
    const valueArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const displayArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Month", valueArray, false, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectAllCapturesNoClearFieldTypeObj() {
    var captureIDsArray = [];
    var captureNamesArray = [];
    for(let captureMap of this.o_tbl_captures.values()) {
      captureIDsArray.push(captureMap.get("id"));
      captureNamesArray.push(this.capture_name_plaintext_from_capture_map(captureMap));
    }
    const swsOptionsObj = {hasSearchTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Capture", captureIDsArray, false, captureNamesArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectAllCapturesFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptures, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectMultiAllCapturesFieldTypeObj() {
    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptures, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectAllCapturesDisplayWithClickLinkFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true, selectCaptureTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCaptures, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectAllCapturesForcedCaptureIDInNameFieldTypeObj() {
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(this.c_valueDisplayArraysObjCapturesForceCaptureIDInName, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectExcelReportTemplateFieldTypeObj() {
    const excelReportTemplateFilesMapofMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_excel_report_templates_filefoldersystem, "folder0_file1", 1);
    const valueDisplayArraysObjExcelReportTemplates = this.create_value_display_arrays_obj_from_mapOfMaps("Excel Report Template", excelReportTemplateFilesMapofMaps, "id", "display_name", "display_name", true, true);
    const swsOptionsObj = {hasSearchTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjExcelReportTemplates, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectSingleCaptureTemplateFieldTypeObj() {
    const singleCaptureTemplateFilesMapofMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_single_capture_templates_filefoldersystem, "folder0_file1", 1);
    const valueDisplayArraysObjSingleCaptureTemplates = this.create_value_display_arrays_obj_from_mapOfMaps("Single Capture Template", singleCaptureTemplateFilesMapofMaps, "id", "display_name", "display_name", true, true);
    const swsOptionsObj = {hasSearchTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjSingleCaptureTemplates, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectDateWithRelativeDateFixedOrRelativeDurationTypeFieldTypeObj() {
    const valueArray = ["fixed", "days", "weeks", "months", "years"];
    const displayArray = ["Date is fixed", "Relative to [today] +/- # days", "Relative to [today] +/- # weeks", "Relative to [today] +/- # months", "Relative to [today] +/- # years"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Date Relative/Fixed", valueArray, true, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectDurationType1234DaysWeeksMonthsYearsFieldTypeObj() {
    const valueArray = [1, 2, 3, 4];
    const displayArray = ["Day(s)", "Week(s)", "Month(s)", "Year(s)"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Duration Type", valueArray, false, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectFileFolderSystemFileAccessFieldTypeObj() {
    const valueArray = ["open"];
    const displayArray = ["Open"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("File Access", valueArray, true, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectFileFolderSystemXmlTypeFieldTypeObj() {
    const customColorsObj = JSFUNC.custom_colors_obj();
    const valueArray = ["generic", "word", "excel", "ppt"];
    const displayArray = ["Generic File", "Document", "Spreadsheet", "Presentation"];
    const treeIDArray = undefined;
    const colorArray = ["bbbbbb", customColorsObj.wordBlue, customColorsObj.excelGreen, customColorsObj.pptOrange];
    const swsOptionsObj = {treeIDArray:treeIDArray, colorArray:colorArray};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("XML File Type", valueArray, true, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectOperatorFieldTypeObj() {
    const valueArray = ["e", "ne", "gt", "gte", "lt", "lte", "c", "dnc", "is", "ins"];
    const displayArray = ["Equals", "Does Not Equal", "Greater Than", "Greater Than or Equal To", "Less Than", "Less Than or Equal To", "Contains", "Does Not Contain", "Is Set", "Is Not Set"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Operator", valueArray, true, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectDayOfTheWeekFieldTypeObj() {
    const valueArray = JSFUNC.array_fill_incrementing_0_to_nm1(7); //[0, 1, 2, 3, 4, 5, 6]
    var displayArray = [];
    for(let value of valueArray) {
      displayArray.push(JSFUNC.date_dayname_from_index(value));
    }
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Day Of The Week", valueArray, false, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectDateOfTheMonth1stTo31stFieldTypeObj() {
    const valueArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    const displayArray = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Date Of The Month", valueArray, false, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectTimeHour1to12AmPmFieldTypeObj() {
    const timeHour1to12AmPmValueArray = JSFUNC.array_fill_incrementing_0_to_nm1(24);
    var timeHour1to12AmPmDisplayArray = [];
    for(let value of timeHour1to12AmPmValueArray) {
      timeHour1to12AmPmDisplayArray.push(JSFUNC.hours1to12_with_ampm_from_hours0to23(value));
    }
    const selectTimeHour1to12AmPmSelectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Time Hour", timeHour1to12AmPmValueArray, false, timeHour1to12AmPmDisplayArray, undefined);
    return(this.create_field_type_obj("select", selectTimeHour1to12AmPmSelectWithSearchDataObj));
  }




  //*********************************************************************************************************************************************************
  //action methods that change @observable state variables
  //*********************************************************************************************************************************************************
  a_clear_all_local_tbls() {
    var allTblNamesToClearArray = this.c_allTblNamesArray;
    for(let t = 0; t < allTblNamesToClearArray.length; t++) {
      if(allTblNamesToClearArray[t] === "tbl_capturesSlimCST") {
        allTblNamesToClearArray[t] = "tbl_captures";
      }
    }
    allTblNamesToClearArray = JSFUNC.unique(allTblNamesToClearArray);
    const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_clear_all_local_tbls", [], []);
    this.a_clear_local_tbls_from_tbl_names_array(allTblNamesToClearArray, jsDescription);
  }


  a_clear_local_tbls_from_tbl_names_array(i_tblNamesArray, i_jsDescriptionFromHigherCaller) {
    var invalidTblNamesArray = []; //fill this will tblNames where the tbl does not exist in local memory and .clear() would crash
    for(let tblName of i_tblNamesArray) {
      var tblRef = this.tbl_ref_from_tbl_name(tblName, "o");
      if(tblRef) { //verify that the tbl to clear exists in local memory
        tblRef.clear();
      }
      else { //push tblNames that don't exist into an error to throw an error message
        invalidTblNamesArray.push(tblName);
      }
    }

    if(invalidTblNamesArray.length > 0) {
      const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_clear_local_tbls_from_tbl_names_array", ["i_tblNamesArray"], [i_tblNamesArray], i_jsDescriptionFromHigherCaller);
      JSPHP.record_z_error(jsDescription, "invalid tblNames: " + JSFUNC.print(invalidTblNamesArray));
    }
  }




  a_insert_or_update_local_data_map(i_tblName, i_dataTable, i_clearOldMapDataFirstTF, i_jsDescriptionFromHigherCaller) {
  	//overwrite existing or insert new data rows into the referenced map or mapOfMaps using Map.set() (set over an existing id erases the previous data and writes in the new data)
    //  - i_tblName
    //      tblName that references a local database observable Map or mapOfMaps (or "tbl_cap" referencing the triple mapOfMapOfMaps containing the user definable cap tbls)
    //  - i_dataTable (multiple forms, must contain "id" field per data row)
    //      1) single row obj of data ("bitcompaniesTblRowMap", "tbl_a_company", "singleCaptureFullTextareaFieldsMap")
    //      2) arrayOfObjs of multiple rows of data [{id:1, name:"One", sort:1}, {id:2, name:"Two", sort:2}, {id:3, name:"Three", sort:3}]
    //      3) CE compressed obj of multiple rows of data {cecCols:{a:"id", b:"name", c:"sort"}, cecData:[{a:1, b:"One", c:1}, {a:2, b:"Two", c:2}, {a:3, b:"Three", c:3}]}
    //      4) objOfArrayOfObjs ("tbl_cap") obj contains multiple arrayOfObjs tables of data (each table may be compressed as well)
    //  - i_clearOldMapDataFirstTF
    //      true - clear the entire outer map of all previous data, new data is then all inserted
    //      false - for each data row obj, if the "id" exists in the map already as a key, update the data, otherwise insert a new row

    var errorMessage = undefined; //if filled in, throw the error at the bottom of this function

    const tblRef = this.tbl_ref_from_tbl_name(i_tblName, "o"); //get the reference to the "o_tblName" local database map tbl observable variable
    if(tblRef === undefined) {
      errorMessage = "tblName does not exist as a Map in local memory";
    }
    else { //tblName exists
      if(JSFUNC.in_array(i_tblName, ["bitcompaniesTblRowMap", "tbl_a_company", "singleCaptureFullTextareaFieldsMap"])) { //single Map() tbls
        const dataWithIDArrayOfObjs = JSFUNC.convert_single_or_array_to_array(i_dataTable); //if updating a single Map() tbl, i_dataTable input must be a single obj of fields/values to update (does not include "id" field, only the column fields being updated with their values)
        for(let dataObj of dataWithIDArrayOfObjs) {
          dataObj.id = undefined; //for extra safety, for each obj to try to update here (should only ever be 1 update for a single obj with id=1), set any "id" passed to undefined
          JSFUNC.single_map_update_or_insert_values_from_obj(tblRef, dataObj); //does not erase existing fields in the map, overwrites specified values and creates new ones for fields that do not exist
        }
      }
      else { //2 or 3 layer Map() tbls
        //clear map before inserting new data if specified by input
        if(i_clearOldMapDataFirstTF) {
          tblRef.clear();
        }

        if((i_tblName === "tbl_cap") && JSFUNC.is_obj(i_dataTable)) { //referencing the entire tbl_cap triple Map, updated using a triple layer data structure, must be an obj containing arrayOfObjs in each field
          for(var tblCapName in i_dataTable) { //loop through the obj keys to get each tbl_cap_ tbl name and data
            if(i_dataTable.hasOwnProperty(tblCapName)) {
              var tblCapDataArrayOfObjs = i_dataTable[tblCapName];
              var uncompressedTblCapDataArrayOfObjs = this.uncompress_ce_compressed_data_table_arrayOfObjs(tblCapDataArrayOfObjs); //uncompress each tbl_cap tbl if it it detected to be compressed
              var tblCapDataMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs(uncompressedTblCapDataArrayOfObjs);
              this.o_tbl_cap.set(tblCapName, tblCapDataMapOfMaps); //creates or overwrites the entire tbl_cap_NAME tbl within the o_tbl_cap triple Map
            }
          }
        }
        else { //2 layer mapOfMaps tbls (regular tbls or one of the tbl_cap_ tbls referenced specifically by tbl_ref_from_tbl_name() above)
          const uncompressedDataWithIDObjOrArrayOfObjs = this.uncompress_ce_compressed_data_table_arrayOfObjs(i_dataTable);
          const dataWithIDArrayOfObjs = JSFUNC.convert_single_or_array_to_array(uncompressedDataWithIDObjOrArrayOfObjs); //if a single obj was provided to update 1 row of data, wrap it in an array to loop through the one item
          for(let dataObj of dataWithIDArrayOfObjs) {
            if(!dataObj.hasOwnProperty("id")) {
              errorMessage = "dataObj does not have 'id' field";
            }
            else {
              var dataRowMapRef = tblRef.get(dataObj.id); //gets the Map associated with the id number, if it does not exist this returns undefined
              if(dataRowMapRef === undefined) { //insert a new row using the set() method
                tblRef.set(dataObj.id, JSFUNC.map_from_obj(dataObj));
              }
              else { //update an existing record, for each field/value pair in dataObj, update the value if field exists already, or create a new field/value pair in the row
                JSFUNC.single_map_update_or_insert_values_from_obj(dataRowMapRef, dataObj);
              }
            }
          }
        }
      }
    }

    //if an error was caught, throw the error
    if(errorMessage !== undefined) {
      const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_insert_or_update_local_data_map", ["i_tblName", "i_dataTable", "i_clearOldMapDataFirstTF"], [i_tblName, i_dataTable, i_clearOldMapDataFirstTF], i_jsDescriptionFromHigherCaller);
      JSPHP.record_z_error(jsDescription, errorMessage);
    }
  }

  uncompress_ce_compressed_data_table_arrayOfObjs(i_ceCompressedDataTableObjOrUncompressedArrayOfObjsOrUncompressedObj) {
    //this function can handle 3 cases:
    //  1) input is an uncompressed single data row obj, pass it out unchanged
    //  2) input is an uncompressed data table arrayOfObjs, pass it out unchanged
    //  3) input is a CE compressed data table obj which contains a data table arrayOfObjs along with the compressed column names, uncompress it and pass that out

    //input was a regular uncompressed data table arrayOfObjs
    if(JSFUNC.is_array(i_ceCompressedDataTableObjOrUncompressedArrayOfObjsOrUncompressedObj)) {
      return(i_ceCompressedDataTableObjOrUncompressedArrayOfObjsOrUncompressedObj);
    }

    //input is a single obj, check if the input is a CE compressed data table obj which would contain 2 fields "cecCols" and "cecData"
    if(i_ceCompressedDataTableObjOrUncompressedArrayOfObjsOrUncompressedObj.hasOwnProperty("cecCols") && i_ceCompressedDataTableObjOrUncompressedArrayOfObjsOrUncompressedObj.hasOwnProperty("cecData")) {
      var cecColumnsObj = i_ceCompressedDataTableObjOrUncompressedArrayOfObjsOrUncompressedObj.cecCols;
      var cecDataArrayOfObjs = i_ceCompressedDataTableObjOrUncompressedArrayOfObjsOrUncompressedObj.cecData;
      var uncompressedDataArrayOfObjs = [];
      for(let cecDataObj of cecDataArrayOfObjs) {
        var uncompressedDataObj = {};
        for(var shortColName in cecColumnsObj) {
          if(cecColumnsObj.hasOwnProperty(shortColName)) {
            var colName = cecColumnsObj[shortColName];
            uncompressedDataObj[colName] = cecDataObj[shortColName];
          }
        }
        uncompressedDataArrayOfObjs.push(uncompressedDataObj);
      }
      return(uncompressedDataArrayOfObjs);
    }

    //input was a regular uncompressed single data row obj
    return(i_ceCompressedDataTableObjOrUncompressedArrayOfObjsOrUncompressedObj);
  }


  a_update_local_data_mapOfMaps_if_existing_otherwise_insert_with_fake_id(i_tblName, i_whereFieldNamesArray, i_whereValuesArray, i_fieldNamesArray, i_valuesArray, i_performUpdateIfExistsTF, i_jsDescriptionFromHigherCaller) {
    const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_update_local_data_mapOfMaps_if_existing_otherwise_insert_with_fake_id", ["i_tblName", "i_whereFieldNamesArray", "i_whereValuesArray", "i_fieldNamesArray", "i_valuesArray", "i_performUpdateIfExistsTF"], [i_tblName, i_whereFieldNamesArray, i_whereValuesArray, i_fieldNamesArray, i_valuesArray, i_performUpdateIfExistsTF], i_jsDescriptionFromHigherCaller);

    //make sure the tblName exists
    const tblRef = this.tbl_ref_from_tbl_name(i_tblName, "o"); //get the reference to the "o_tblName" local database map tbl observable variable
    if(tblRef === undefined) {
      JSPHP.record_z_error(jsDescription, "tblName does not exist as a Map in local memory");
      return;
    }

    //determine rowID based on update or insert
    var updateIDOrFakeInsertID = -1;
    var performUpdateOrInsertOperationTF = true;
    const existingDataRowMap = JSFUNC.get_first_map_matching_field_value(tblRef, i_whereFieldNamesArray, i_whereValuesArray);
    if(existingDataRowMap === undefined) { //data row does not exist based on where filters, need to insert a new row creating a fakeID
      updateIDOrFakeInsertID = (this.tbl_max_id_number(i_tblName) + 1);
    }
    else { //update existing record using found id number
      updateIDOrFakeInsertID = existingDataRowMap.get("id");
      performUpdateOrInsertOperationTF = i_performUpdateIfExistsTF;
    }

    if(performUpdateOrInsertOperationTF) {
      const clearOldMapDataFirstTF = false;
      const dataArrayOfObjs = JSFUNC.create_data_arrayOfObjs_from_idOrIdArray_fieldName_and_value_arrays(updateIDOrFakeInsertID, i_fieldNamesArray, i_valuesArray);
      this.a_insert_or_update_local_data_map(i_tblName, dataArrayOfObjs, clearOldMapDataFirstTF, jsDescription);
    }
  }


  a_insert_or_update_multiple_local_data_maps_with_uncompressed_data_tbls_obj(i_uncompressedDataTblsObj, i_clearOldMapDataFirstTF, i_jsDescriptionFromHigherCaller) {
    //i_uncompressedDataTblsObj = {
    //  "tblName1": [{id:1, name:"Name1"}, {id:2, name:"Name2"}],
    //  "tblName2": [{id:1, number:360}, {id:4, name:720}, {id:5, name:1080}]
    //}
    //loop over all tbl names in the input data tbls obj, each holding either an obj (single row of data), an arrayOfObjs (rows of data), or objOfArrayOfObjs (multiple tbls together)
    for(var tblName in i_uncompressedDataTblsObj) {
      if(i_uncompressedDataTblsObj.hasOwnProperty(tblName)) {
        this.a_insert_or_update_local_data_map(tblName, i_uncompressedDataTblsObj[tblName], i_clearOldMapDataFirstTF, i_jsDescriptionFromHigherCaller); //insert or update this tbl data into the local memory map or mapOfMaps
      }
    }
  }


  a_delete_local_data_mapOfMaps_item_from_id_or_id_array(i_tblName, i_idOrIdArray, i_jsDescriptionFromHigherCaller) {
    var tblRef = this.tbl_ref_from_tbl_name(i_tblName, "o");
    if(tblRef === undefined) {
      const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_delete_local_data_mapOfMaps_item_from_id_or_id_array", ["i_tblName", "i_idOrIdArray"], [i_tblName, i_idOrIdArray], i_jsDescriptionFromHigherCaller);
      JSPHP.record_z_error(jsDescription, "tblName does not exist in local memory");
      return;
    }

    const arrayOfIDs = JSFUNC.convert_single_or_array_to_array(i_idOrIdArray);
    for(let id of arrayOfIDs) { //loop through each id provided and delete the matching id record from the map if it exists, nothing happens if it didn't exist
      if(tblRef.has(id)) {
        tblRef.delete(id);
      }
    }
  }


  a_resort_tbl(i_tblName, i_sortColumnName, i_filterFieldNameOrFieldNamesArray, i_filterValueOrValuesArray, i_draggedItemID, i_droppedOnItemID, i_jsDescriptionFromHigherCaller) {
    var tblRef = this.tbl_ref_from_tbl_name(i_tblName, "o");
    if(tblRef === undefined) {
      const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_resort_tbl", ["i_tblName", "i_sortColumnName", "i_filterFieldNameOrFieldNamesArray", "i_filterValueOrValuesArray", "i_draggedItemID", "i_droppedOnItemID"], [i_tblName, i_sortColumnName, i_filterFieldNameOrFieldNamesArray, i_filterValueOrValuesArray, i_draggedItemID, i_droppedOnItemID], i_jsDescriptionFromHigherCaller);
      JSPHP.record_z_error(jsDescription, "tblName does not exist in local memory");
      return;
    }

    const oldFilteredTblMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(tblRef, i_filterFieldNameOrFieldNamesArray, i_filterValueOrValuesArray); //filter the tbl based on the given filter columns/values
    const oldFilteredSortedTblArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(oldFilteredTblMapOfMaps);
    const numItems = oldFilteredSortedTblArrayOfObjs.length;
    if(numItems === 0) { //if there are 0 items in the list, then there is nothing to resort
      return;
    }

    //lookup dragged/droppedOn items to verify they exist in the filtered list of items
    var draggedRowMap = undefined;
    if(i_draggedItemID > 0) {
      draggedRowMap = oldFilteredTblMapOfMaps.get(i_draggedItemID);
    }

    var droppedOnRowMap = undefined;
    if(i_droppedOnItemID > 0) {
      droppedOnRowMap = oldFilteredTblMapOfMaps.get(i_droppedOnItemID);
    }

    //flag for different modes of resorting the filtered items
    var resortAboveTrueBottomFalseNoneUndefined = undefined; //undefined - resort items without changing any of their relative positions (dragID and dropID both -1)
    if((i_draggedItemID > 0) && (draggedRowMap !== undefined) && (i_droppedOnItemID > 0) && (droppedOnRowMap !== undefined)) {
      resortAboveTrueBottomFalseNoneUndefined = true; //true - resort with draggedItem above droppedOnItem
    }
    else if((i_draggedItemID > 0) && (draggedRowMap !== undefined) && (i_droppedOnItemID === -1)) {
      resortAboveTrueBottomFalseNoneUndefined = false; //false - resort with draggedItem moved to bottom of list (dropID is -1)
    }

    //alert(i_tblName + "\n" + i_sortColumnName + "\n" + JSFUNC.print(i_filterFieldNameOrFieldNamesArray) + "\n" + JSFUNC.print(i_filterValueOrValuesArray) + "\n" + i_draggedItemID + "\n" + i_droppedOnItemID + "\n" + resortAboveTrueBottomFalseNoneUndefined);

    //build array to keep track of itemID, oldSort, and newSort values
    var resortArrayOfObjs = JSFUNC.drag_drop_resort_arrayOfObjs(oldFilteredSortedTblArrayOfObjs, "id", i_sortColumnName, i_draggedItemID, i_droppedOnItemID, resortAboveTrueBottomFalseNoneUndefined);

    //update each filtered item with the new sort number, only if their sort has changed from its old value
    for(let resortObj of resortArrayOfObjs) {
      if(resortObj.oldSort !== resortObj.newSort) {
        tblRef.get(resortObj.id).set(i_sortColumnName, resortObj.newSort);
      }
    }
  }


  a_local_alter_tbl_insert_column(i_tblName, i_columnDbName, i_blankValue, i_jsDescriptionFromHigherCaller) {
    var oTblRef = this.tbl_ref_from_tbl_name(i_tblName, "o");
    if(oTblRef === undefined) {
      const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_local_alter_tbl_insert_column", ["i_tblName", "i_columnDbName", "i_blankValue"], [i_tblName, i_columnDbName, i_blankValue], i_jsDescriptionFromHigherCaller);
      JSPHP.record_z_error(jsDescription, "tblName does not exist in local memory");
      return;
    }

    //inject the blank value into a new column of each inner map of the tbl
    for(let innerMap of oTblRef.values()) {
    	innerMap.set(i_columnDbName, i_blankValue);
    }
  }


  a_local_alter_tbl_delete_column(i_tblName, i_columnDbName, i_jsDescriptionFromHigherCaller) {
    var oTblRef = this.tbl_ref_from_tbl_name(i_tblName, "o");
    if(oTblRef === undefined) {
      const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_local_alter_tbl_delete_column", ["i_tblName", "i_columnDbName"], [i_tblName, i_columnDbName], i_jsDescriptionFromHigherCaller);
      JSPHP.record_z_error(jsDescription, "tblName does not exist in local memory");
      return;
    }

    //in each inner map (tbl rows) of the local tbl map, delete each map entry corresponding to the column being deleted
    for(let innerMap of oTblRef.values()) {
    	innerMap.delete(i_columnDbName);
    }
  }

  a_local_create_new_tbl_cap_table(i_tblCapName, i_hasSortTF, i_jsDescriptionFromHigherCaller) {
    const emptyNewTblCapTableMap = new Map();
    this.o_tbl_cap.set(i_tblCapName, emptyNewTblCapTableMap);
  }

  a_local_delete_tbl_cap_table(i_tblCapName, i_jsDescriptionFromHigherCaller) {
    this.o_tbl_cap.delete(i_tblCapName);
  }





  a_overwrite_progress_chart_log_tbl_from_php_raw_data(i_tblFlag, i_dvDataArrayPerCapture) {
    var tblName = undefined;
    if(i_tblFlag === "pwin") {
      tblName = "o_progressChartLogPwinMapOfArrayOfObjs";
    }
    else if(i_tblFlag === "progress") {
      tblName = "o_progressChartLogShapingMapOfArrayOfObjs";
    }
    else if(i_tblFlag === "stages") {
      tblName = "o_progressChartLogStagesMapOfArrayOfObjs";
    }
    else {
      const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "a_overwrite_progress_chart_log_tbl_from_php_raw_data", ["i_tblFlag", "i_dvDataArrayPerCapture"], [i_tblFlag, i_dvDataArrayPerCapture]);
      JSPHP.record_z_error(jsDescription, "invalid i_tblFlag");
      return;
    }

    var tblRef = this[tblName];
    tblRef.clear();

    for(var key in i_dvDataArrayPerCapture) { //key is captureID as int from php
      if(i_dvDataArrayPerCapture.hasOwnProperty(key)) {
        var captureIDInt = JSFUNC.str2int(key);
        tblRef.set(captureIDInt, i_dvDataArrayPerCapture[key]); //set an arrayOfObjs of [{d:"2018-04-15 16:00:00", v:15}, {d:"2018-05-01 09:00:00", v:30}, {d:"2018-05-02 11:30:00", v:72}]
      }
    }
  }



  a_create_new_capture_inject_id_field_into_new_local_capture_record(i_newCaptureID) {
    var newCaptureMap = this.o_tbl_captures.get(i_newCaptureID);
    if(newCaptureMap !== undefined) {
      newCaptureMap.set("id", i_newCaptureID);
    }
  }



  a_set_stripe_account_obj_or_undefined(i_stripeAccountObj) {
    this.o_stripeCustomerDataObjOrUndefined = i_stripeAccountObj;
  }





  //=====================================================================================================================================
  //regular methods that do not autorun or autoupdate
  //=====================================================================================================================================
  create_combined_user_map(i_userID) { //combinedUserMap combines a row from tbl_a_users with the corresponding row in tbl_a_users_per_email along with the tbl_a_divisions row matching the division_id
    const k_onPremiseCustomerTF = CaptureExecMobx.k_onPremiseCustomerTF;

    var combinedUserMap = new Map(); //empty combinedUserMap Map()

    //copy all tbl_a_users fields from the given input (if input is a nonexistant_user, then the userPerEmail and division will also come up as empty as well as the extra fields)
    if(JSFUNC.is_number_not_nan_gt_0(i_userID) && this.o_tbl_a_users.has(i_userID)) { //valid userID to load data from
      for(let [key, value] of this.o_tbl_a_users.get(i_userID)) {
        if(key === "id") { //since both users and usersPerEmail have a field called "id", rename them so that they can be looked up independently
          combinedUserMap.set("user_id", value);
        }
        else {
          combinedUserMap.set(key, value);
        }
      }
    }
    else { //nonexistant user record inserted
      combinedUserMap.set("user_id", -1);
      combinedUserMap.set("user_per_email_id", -1);
      combinedUserMap.set("division_id", -1);
      combinedUserMap.set("power_name", "invalid");
      combinedUserMap.set("bit_non_billable_user_01", -1);
      combinedUserMap.set("alt_full_name", "--User Does Not Exist (uID: " + i_userID + ")--");
      combinedUserMap.set("num_logins", -1);
      combinedUserMap.set("last_login_datetime_utc", JSFUNC.blank_datetime());
      combinedUserMap.set("last_login_timezone_string", "");
      combinedUserMap.set("last_login_browser_type", "");
      combinedUserMap.set("last_login_mediaqueryflag", -1);
      combinedUserMap.set("start_tab_name", "");
      combinedUserMap.set("quick_access_master_preset_ids_comma", "");
      combinedUserMap.set("quick_access_master_preset_id_selected", -1);
      combinedUserMap.set("gantt_date_preset_id_selected", -1);
      combinedUserMap.set("divexec_filter_division_ids_comma", "");
      combinedUserMap.set("divexec_goals_latest_year", 0);
      combinedUserMap.set("divexec_goals_num_years", 10);
      combinedUserMap.set("divexec_graphs_start_date", JSFUNC.blank_date());
      combinedUserMap.set("divexec_graphs_end_date", JSFUNC.blank_date());
      combinedUserMap.set("divexec_performance_money_field_id", -1);
      combinedUserMap.set("divexec_finproj_custom0_manual1", 1);
      combinedUserMap.set("divexec_finproj_filter_preset_id", -1);
      combinedUserMap.set("divexec_finproj_division_ids_comma", "");
      combinedUserMap.set("divexec_finproj_stage_ids_comma", "");
      combinedUserMap.set("divexec_finproj_money_field_id", -1);
      combinedUserMap.set("divexec_hotbits_fixed0_calendar1", -1);
      combinedUserMap.set("divexec_critthresh_money_field_id", -1);
    }

    const powerDbName = combinedUserMap.get("power_name");
    const bitNonBillableUser01 = combinedUserMap.get("bit_non_billable_user_01");

    //BIT Non-Billable User flag
    const bitNonBillableUserTF = ((bitNonBillableUser01 === 1) && !k_onPremiseCustomerTF); //do not give onprem customers 'non-billable users' since they can change the 0/1 column in their tbl_a_users tbl too easily
    combinedUserMap.set("bitNonBillableUserTF", bitNonBillableUserTF);

    //power name attributes
    const powerDataObj = this.power_data_obj_from_power_name(powerDbName);
    
    //modify license cost for a BIT non-billable user down to 0
    var powerLicenseCost = powerDataObj.licenseCost;
    if(bitNonBillableUserTF) {
      powerLicenseCost = 0;
    }

    combinedUserMap.set("powerIsSuperAdminTF", powerDataObj.isSuperAdminTF);
    combinedUserMap.set("powerIsAdminTF", powerDataObj.isAdminTF);
    combinedUserMap.set("powerIsDivExecTF", powerDataObj.isDivExecTF);
    combinedUserMap.set("powerIsCaptureExecTF", powerDataObj.isCaptureExecTF);
    combinedUserMap.set("powerIsCaptureConsultantTF", powerDataObj.isCaptureConsultantTF);
    combinedUserMap.set("powerIsContractsExecTF", powerDataObj.isContractsExecTF);
    combinedUserMap.set("powerIsContractsTF", powerDataObj.isContractsTF);
    combinedUserMap.set("powerIsBudgetExecTF", powerDataObj.isBudgetExecTF);
    combinedUserMap.set("powerIsBudgetTF", powerDataObj.isBudgetTF);
    combinedUserMap.set("powerHasAdminPowerTF", powerDataObj.hasAdminPowerTF);
    combinedUserMap.set("powerHasDivexecPowerTF", powerDataObj.hasDivexecPowerTF);
    combinedUserMap.set("powerHasContractsPowerTF", powerDataObj.hasContractsPowerTF);
    combinedUserMap.set("powerHasBudgetPowerTF", powerDataObj.hasBudgetPowerTF);
    combinedUserMap.set("powerIsReadOnlyTF", powerDataObj.isReadOnlyTF);
    combinedUserMap.set("powerLicenseCost", powerLicenseCost);
    combinedUserMap.set("powerDisplayName", powerDataObj.displayName);
    combinedUserMap.set("powerDisplayNameShort", powerDataObj.displayShortName);
    combinedUserMap.set("powerDisplayNameWithSuperAdminLicenseCost", powerDataObj.displayNameWithSuperAdminLicenseCost);
    combinedUserMap.set("powerDisplayNameRequiresAnTrueAFalse", powerDataObj.displayNameRequiresAnTrueAFalse);
    combinedUserMap.set("powerColor", powerDataObj.color);
    combinedUserMap.set("powerHashColor", powerDataObj.hashColor);
    combinedUserMap.set("powerDescription", powerDataObj.description);
    combinedUserMap.set("powerSortNumber", powerDataObj.sortNumber);

    //copy all tbl_a_divisions fields from the given input from tbl_a_users division_id field
    const divisionID = combinedUserMap.get("division_id");
    const divisionMap = this.tbl_row_map_from_id("tbl_a_divisions", divisionID);
    const divisionTreeID = divisionMap.get("tree_id");
    var divisionName = divisionMap.get("name");
    var divisionNickname = divisionMap.get("nickname");
    if(!JSFUNC.select_int_is_filled_out_tf(divisionID)) {
      divisionName ="--Unassigned--";
      divisionNickname ="--Unassigned--";
    }
    const divisionColor = divisionMap.get("color");
    const divisionFirewalledDivisionIDsComma = divisionMap.get("firewalled_division_ids_comma");
    const divisionSamUei = divisionMap.get("sam_uei");
    const divisionSamUeiCompanyName = divisionMap.get("sam_uei_company_name");
    const divisionCalcSamUeiWithUphillRoll = divisionMap.get("calcSamUeiWithUphillRoll");
    const divisionCalcCompanyNameWithUphillRoll = divisionMap.get("calcCompanyNameWithUphillRoll");
    const calcSamUeiWithUphillRollIsFilledOutTF = divisionMap.get("calcSamUeiWithUphillRollIsFilledOutTF");
    const integrationNewCaptureIntegrationUniqueIDPrefix = divisionMap.get("integration_new_capture_integration_unique_id_prefix");
    combinedUserMap.set("divisionTreeID", divisionTreeID);
    combinedUserMap.set("divisionName", divisionName);
    combinedUserMap.set("divisionNickname", divisionNickname);
    combinedUserMap.set("divisionColor", divisionColor);
    combinedUserMap.set("divisionFirewalledDivisionIDsComma", divisionFirewalledDivisionIDsComma);
    combinedUserMap.set("divisionSamUei", divisionSamUei);
    combinedUserMap.set("divisionSamUeiCompanyName", divisionSamUeiCompanyName);
    combinedUserMap.set("divisionCalcSamUeiWithUphillRoll", divisionCalcSamUeiWithUphillRoll);
    combinedUserMap.set("divisionCalcCompanyNameWithUphillRoll", divisionCalcCompanyNameWithUphillRoll);
    combinedUserMap.set("calcSamUeiWithUphillRollIsFilledOutTF", calcSamUeiWithUphillRollIsFilledOutTF);
    combinedUserMap.set("integrationNewCaptureIntegrationUniqueIDPrefix", integrationNewCaptureIntegrationUniqueIDPrefix);

    //copy all tbl_a_users_per_email fields from the given input from tbl_a_users user_per_email_id field
    const userPerEmailID = combinedUserMap.get("user_per_email_id");
    if(JSFUNC.is_number_not_nan_gt_0(userPerEmailID) && this.c_tbl_a_users_per_email.has(userPerEmailID)) { //valid userID to load data from
      for(let [key, value] of this.c_tbl_a_users_per_email.get(userPerEmailID)) {
        if(key === "id") { //since both users and usersPerEmail have a field called "id", rename them so that they can be looked up independently
          combinedUserMap.set("user_per_email_id", value);
        }
        else {
          combinedUserMap.set(key, value); //copy every field from tbl_a_users_per_email
        }
      }

      //set full name based on first and last name
      const firstName = combinedUserMap.get("first_name");
      const lastName = combinedUserMap.get("last_name");
      const altFullName = combinedUserMap.get("alt_full_name");
      const fullName = this.user_full_name(userPerEmailID, firstName, lastName, altFullName, powerDbName, powerDataObj.displayName, powerDataObj.displayShortName, divisionName, divisionNickname, true);
      const fullNameNoPower = this.user_full_name(userPerEmailID, firstName, lastName, altFullName, powerDbName, powerDataObj.displayName, powerDataObj.displayShortName, divisionName, divisionNickname, false);
      combinedUserMap.set("fullName", fullName); //full name styled according to multilogins and nickname (in plaintext)
      combinedUserMap.set("fullNameNoPower", fullNameNoPower); //full name without any multilogin power
    }
    else { //nonexistant userPerEmail record inserted
      combinedUserMap.set("contacts_person_id", -1);
      combinedUserMap.set("first_name", "--User Does Not Exist (uID: " + i_userID + ", upeID: " + userPerEmailID + ")--");
      combinedUserMap.set("last_name", "--User Does Not Exist (uID: " + i_userID + ", upeID: " + userPerEmailID + ")--");
      combinedUserMap.set("disabled", 0);
      combinedUserMap.set("lockedout", 0);
      combinedUserMap.set("font_size_px", 12);
      combinedUserMap.set("left_nav_0collapsed_1expanded", 1);
      combinedUserMap.set("right_panel_width_em", 22);
      combinedUserMap.set("notification_email_01", 0);
      combinedUserMap.set("archived_captures_preference", "noLoadArchivedWithPrompt"); //"alwaysLoadArchived", "noLoadArchivedWithPrompt", "noLoadArchivedNoPrompt"
      combinedUserMap.set("capture_card_min_height_em", 20);
      combinedUserMap.set("capture_card_max_height_em", 35);
      combinedUserMap.set("dates_card_three_cols_0_single_col_1", 0);
      combinedUserMap.set("recently_visited_capture_ids_comma", "");
      combinedUserMap.set("favorited_capture_ids_comma", "");
      combinedUserMap.set("cst_row_height_em", 5);
      combinedUserMap.set("cst_edit_cells_mode_01", 0);
      combinedUserMap.set("stage_view_box_size", 2);
      combinedUserMap.set("stage_view_selected_capture_type_ids_comma", "");
      combinedUserMap.set("stage_view_minimized_stage_ids_comma", "");
      combinedUserMap.set("priority_view_minimized_priority_ids_comma", "");
      combinedUserMap.set("sync_user_ce_actions_in_integration_system_01", 1);
      combinedUserMap.set("password_last_changed_date", JSFUNC.blank_date());
      combinedUserMap.set("sortName", JSFUNC.sort_max_string());

      //set full name to --User Does Not Exist--
      const userDoesNotExistName = "--User Does Not Exist (uID: " + i_userID + ", upeID: " + userPerEmailID + ")--";
      combinedUserMap.set("fullName", userDoesNotExistName);
      combinedUserMap.set("fullNameNoPower", userDoesNotExistName);
    }

    //other U/UPE fields computed from raw database column values
    const deactivated01 = combinedUserMap.get("disabled");
    const lockedout01 = combinedUserMap.get("lockedout");
    combinedUserMap.set("deactivatedTF", (deactivated01 === 1));
    combinedUserMap.set("lockedOutTF", (lockedout01 === 1));
    combinedUserMap.set("divexecFilterDivisionIDsArray", JSFUNC.convert_comma_list_to_int_array(combinedUserMap.get("divexec_filter_division_ids_comma")));
    combinedUserMap.set("divexecHotBitsFixedTF", (combinedUserMap.get("divexec_hotbits_fixed0_calendar1") === 0));
    combinedUserMap.set("divexecHotBitsCalendarTF", (combinedUserMap.get("divexec_hotbits_fixed0_calendar1") === 1));

    return(combinedUserMap);
  }

  user_full_name(i_userPerEmailID, i_firstName, i_lastName, i_userAltFullName, i_powerDbName, i_powerDisplayName, i_powerDisplayNameShort, i_divisionName, i_divisionNickname, i_includeMultiLoginPowerTF=true) {
    if(JSFUNC.is_string(i_userAltFullName) && (i_userAltFullName.length > 0)) {
      return(i_userAltFullName);
    }

    const firstLastNameInCompanyFormat = JSFUNC.full_name_from_first_name_last_name(i_firstName, i_lastName);
    if(!i_includeMultiLoginPowerTF) {
      return(firstLastNameInCompanyFormat);
    }

    const userMapOfMapsMatchingUserPerEmailID = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_users, "user_per_email_id", i_userPerEmailID);
    const numLogins = userMapOfMapsMatchingUserPerEmailID.size;
    if(numLogins <= 1) {
      return(firstLastNameInCompanyFormat);
    }

    const userMapOfMapsMatchingUserPerEmailIDAndPowerName = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.o_tbl_a_users, ["user_per_email_id", "power_name"], [i_userPerEmailID, i_powerDbName]);
    const numLoginsWithMatchingPower = userMapOfMapsMatchingUserPerEmailIDAndPowerName.size;
    var fullName = firstLastNameInCompanyFormat + " (" + i_powerDisplayNameShort;
    if(numLoginsWithMatchingPower > 1) {
      fullName += ": ";
      if(i_divisionNickname && i_divisionNickname.length > 0) {
        fullName += i_divisionNickname;
      }
      else {
        fullName += i_divisionName;
      }
    }
    fullName += ")";
    return(fullName);
  }

  division_and_all_children_division_ids_array(i_divisionID, i_directChildrenOnlyTF=false) {
    const tblRef = this.tbl_ref_from_tbl_name("tbl_a_divisions");
    var divisionIDsArray = [i_divisionID];
    const inputDivisionMap = tblRef.get(i_divisionID);
    if(inputDivisionMap !== undefined) { //only return the input divisionID if the division does not exist in tbl_a_divisions
      const inputDivisionTreeID = inputDivisionMap.get("tree_id");
      for(let divisionMap of tblRef.values()) {
        if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(inputDivisionTreeID, divisionMap.get("tree_id"), i_directChildrenOnlyTF)) {
          divisionIDsArray.push(divisionMap.get("id"));
        }
      }
    }
    return(divisionIDsArray);
  }

  compute_division_indents_array_from_division_obj(i_divisionObj, i_indentsToSubtract=undefined) {
    var numIndents = Math.ceil(i_divisionObj.tree_id.length / 2) - 1;
    if(JSFUNC.is_number(i_indentsToSubtract) && i_indentsToSubtract > 0) {
      numIndents -= i_indentsToSubtract;
      if(numIndents < 0) {
        numIndents = 0;
      }
    }

    var indentsArray = [];
    for(let i = 0; i < numIndents; i++) {
      indentsArray.push(i);
    }
    return(indentsArray);
  }


  get_all_user_ids_from_user_per_email_id(i_userPerEmailID) {
    var userIDsArray = [];
    for(let combinedUserMap of this.c_tbl_a_users.values()) {
      if(combinedUserMap.get("user_per_email_id") === i_userPerEmailID) {
        userIDsArray.push(combinedUserMap.get("user_id"));
      }
    }
    return(userIDsArray);
  }







  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //Fields/Values: creating fieldObjs, getting capture raw values, masking capture values using a fieldObj)
  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  create_field_type_obj(i_fieldInputType, i_selectWithSearchDataObj=undefined) {
    //2 functions use the massive obj produced by this function as input
    //  - masking the raw value (DatabaseMobx - value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj, i_plainTextTF=false))
    //  - producing a react input component to edit the raw value (CEGeneralReact)
    //i_selectWithSearchDataObj is used for i_fieldInputType "select" and "sharedpercent"
    /* FIELD INPUT TYPES
    Generic Inputs (value display is the raw value with formatting)
      "text"
      "textarea"
      "int"                     BIGINT
      "int_positive"            BIGINT
      "decimal"                 DECIMAL(18,9)
      "decimal_positive"        DECIMAL(18,9)
      "percent_int"             BIGINT
      "percent_int_0to100"      TINYINT
      "percent_decimal"         DECIMAL(18,9)
      "percent_decimal_0to100"  DECIMAL(18,9)
      "money"                   BIGINT
      "money_cents"             DECIMAL(18,9)
      "email"
      "phone"
      "website"
      "color"
      "date"                              in admin company date format
      "date_fromRawDateTimeUtc"           local date in company format from a raw dateTimeUTC value
      "date_Ymd_fromRawDateTimeUtc"       local date in format "2024-12-23" from a raw dateTimeUTC value
      "date_Ymd"                          date in format "2018-04-15"
      "date_MjY"                          date in format "Apr 15, 2018"
      "date_DMjY"                         date in format "Sun Apr 15, 2018"
      "date_dayMdyDaysUntil1"             date in html format "[Sun Apr 15, 2018 [4 weeks]]" with a colored background for the days until box
      "date_dayMdyDaysUntil1Overdue"      date in html format "[Sun Apr 15, 2018 [overdue]]" with a colored background for the days until box, also including "overdue" message when days until date is negative
      "date_dayMdyDaysUntil2"
      "date_dayMdyDaysUntil2Overdue"      like date_dayMdyDaysUntil1Overdue with different colors for GCSS proposal_due_datetime_utc
      "date_dayMdyDaysUntil2Overdue_fromRawDateTimeUtc"       date in company format (converted to local time) from a database raw value that is a dateTimeUtc (where seconds have special 58/59 for time not filled out) [this type cannot be created by the Admin as a field type, it is secret to make Proposal Due Date/Time work to display/edit only Date for some customers]
      "datetime"                          date part in admin date company format, time part is "g:i A" like "09:32 AM", XX:XX:58/59 will display "--:--" for time not set
      "datetime_natural"                  date part in admin date company format, time part is "g:i A" like "09:32 AM", all second values (including 58/59) display normally
      "datetime_YmdgiA"                   datetime in format "2018-11-30 12:45 PM" "Y-m-d g:i A"
      "datetime_MjYgiA"                   datetime in format "Nov 30, 2018 12:45 PM" "M j, Y g:i A"
      "datetime_dayMdyDaysUntil1"
      "datetime_dayMdyDaysUntil1Overdue"
      "datetime_dayMdyDaysUntil2"
      "datetime_dayMdyDaysUntil2Overdue"
      "dateWithRelativeDate"              in admin company date format, an input of 8XXX-01-01 is changed to [today's date] - xxx days, 9XXX-01-01 is changed to [today's date] + xxx days
      "dateWithDuration"                  stored as a datetime in the database, uses date as the date, and time is used to represent the duration hh:ii:ss -> h1 (unused), i1 (2=weeks, 3=months, 4=years, anything else days), s1/h2/i2/s2 (4 digit number 0000-5999)

    Deal Shaping Scored Text/Textareas
      "scored_text"                       valueRaw is a 2 property obj in the format {textarea:"Answer String", score0to100:65}
      "scored_textarea"                   valueRaw is a 2 property obj in the format {textarea:"Answer String", score0to100:65}
      "scored_text_score_hidden"          used for Deal Shaping debrief questions for closed stages, self score mechanism is not shown and not required to be filled out
      "scored_textarea_score_hidden"      used for Deal Shaping debrief questions for closed stages, self score mechanism is not shown and not required to be filled out

    SelectWithSearch Pulldowns (value display is fetched from a reference tbl)
      "select"/"selectadd"                display/value arrays for each individual select (hardcoded system and user defined custom "tbl_cap_") are computed fields here in DatabaseMobx, selected using the field db_name
      "selectmulti"/"selectmultiadd"      in tbl_captures_fields, "input_type" can have values "select", "selectmulti", "select_name", "selectmulti_name", "select_sort", "selectmulti_sort".  The "sort" and "name" types are converted to "select_db_name"/"selectmulti_db_name" after their fieldObj "hasSort" flag is set to true

    Shared Percent Inputs
      "shared_percent"/"sharedpercentadd" valueRaw is a colonCommaList of ids and percents, output is masked strings with percents in parens ("1:42,5:48"  becomes  "Skip (48%), Drew (42%)") used for capture_owners, division_owners, reasons_won, reasons_lost

    Other
      "verticalswitch"                    GCSS options for data overwrite - part of system defined hardcoded switches category as a 2/3/N way switch (options defined per field dbName in code for display and edit)
      "captureprioritylevel"
      "capturefavorite"
      "task"                              system field that displays text of the first (due date nearest in future) task from a given capture (display only with no edit)
      "progress_bar_shaping"              shaping_total_progress, shaping_stage_progress (display only with no edit, uses tbl_a_shaping_progress_colors coloring)
      "progress_bar_pwin"                 pwin (display only with no edit, uses tbl_a_pwin_colors coloring)
      "documentlinks"                     comma list of {json strings} of links to SAM documents (display only with no edit)
      "stylingString"                     comma list of 4 text styling attributes (bold, italic, font color, highlight color) that can be individually editied, with an additional save preset admin tbl feature [used in Admin Details Card group names and details field display names]
      "stylingString_fontBlue"
      "stylingString_fontDarkGrayBold"
    */

    var inputTypeMask = undefined;
    var idsb = undefined;
    var idsbIsValidTF = undefined;
    var mysqlColumnDataType = undefined;
    var blankValue = undefined;

    var textTF = undefined;
    var textareaTF = undefined;
    var intTF = undefined;
    var decimalTF = undefined;
    var percentTF = undefined;
    var maskPercentProgressBarShapingTF = undefined;
    var maskPercentPwinCubeTF = undefined;
    var editNumberOnlyPositiveTF = undefined;
    var editNumberOnly0to100TF = undefined;
    var editNumberOnlym1000to100TF = undefined;
    var editNumberOnly0to9TF = undefined;
    var editPercentHasDecimalsTF = undefined;
    var moneyTF = undefined;
    var moneyIncludeCentsTF = undefined;
    var emailTF = undefined;
    var phoneTF = undefined;
    var websiteTF = undefined;
    var colorTF = undefined;
    var dateTF = undefined;
    var dateLocalFromRawDateTimeUtcTF = undefined;
    var dateTimeTF = undefined; //time portion of a datetime is always formatted in "g:i A", unless in "natural time"
    var dateWithRelativeDateTF = undefined;
    var dateWithDurationTF = undefined;
    var maskDateFormatCompanyTF = undefined;
    var maskDateFormatNaturalTimeTF = undefined;
    var maskDateFormatYmdTF = undefined;
    var maskDateFormatMjYTF = undefined;
    var maskDateFormatDMjYTF = undefined;
    var maskDateFormatDayMdyDaysUntilTF = undefined;
    var maskDateFormatDaysUntilColorNumber = undefined;
    var maskDateFormatDaysUntilIncludeOverdueTF = undefined;
    var maskDateFormatHideDaysUntilBoxTF = false; //set to true when masking with a captureMap if the capture is in a closed stage, draws the <div> with the days until box as an empty space so its the same size
    var selectTF = undefined;
    var sharedPercentTF = undefined;
    var scoredTextTF = undefined;
    var scoredTextareaTF = undefined;
    var scoredTextOrTextareaIncludeScoringTF = undefined; //if a deal shaping question is an Advance Stage Card debrief question for a closed stage, set this to false, otherwise normally true 
    var verticalSwitchTF = undefined;
    var captureFavoritesTF = undefined;
    var capturePriorityLevelTF = undefined;
    var notestampTF = undefined;
    var taskTF = undefined;
    var documentLinksTF = undefined;
    var stylingStringTF = undefined;
    var stylingStringDefaultFontClassName = ""; //when editing a styling string, this class is used as the default when displayed with no styling applied
    var stylingStringBoldOptionTF = true; //when editing a styling string, if this is set to false, the bold switch is replaced with N/A and cannot be changed (this is used when the default font class already has bold as part of the style)

    var selectContactCompanyTF = undefined;
    var selectContactPersonTF = undefined;
    var selectCaptureTF = undefined;
    var sharedPercentCaptureManagersTF = undefined;
    var requiresSelectWithSearchDataObjTF = undefined;
    var valueRawIsNumericTF = undefined; //flag for if the valueRaw is a numeric value (not a string), this is used when a capture filter has multiple values to determine if the comma list should be broken into an array (strings) or an int array (numeric values)
    var valueRawIsNumericIntTF = undefined; //flag if the database stores int "i" values, used in capture expanded filters to convert strings of entered numeric values into numbers/ints
    var valueRawIsNumericDecimalTF = undefined;
    var valueDisplayIsNumericTF = undefined; //flag for if the valueMask is a numeric value, this is used when writing to excel reports to determine if the Cell should be a Number or a String type
    var valueDisplayIsDateOrDateTimeTF = undefined; //flag for if the field is any type of date including a date (of any style), a relative date, or a datetime (of any style)
    var valueDisplayIsDateTF = undefined; //dateTF, dateLocalFromRawDateTimeUtcTF, dateWithRelativeDateTF, dateWithDurationTF
    var valueDisplayIsDateTimeTF = undefined; //dateTimeTF
    var valueSortIsNumericTF = undefined; //flag for is the valueSort is a numeric value, used when determining whether to use the omega max string symbol or a number when dealing with fields/captures that doen't exist so that the column still sorts properly with homogenous string or numeric data
    var hasTrimDecimalsOptionTF = undefined; //database stores value with decimal places, capture field can set a max number of decimals places to be trimmed to
    var hasZeroPaddingOptionTF = undefined; //for "int_postive", can specify number of digits in the num_decimals column to zero pad the display for the int as a string
    var recommendedEditMaxWidthOrUndefined = undefined; //when editing a given field type, some types look strange when stretched to a wide automatic 100% width (like when editing a CST cell floating box), some recommended max width values are put here to make them look more normal

    //compute selectContactCompanyTF and selectContactCompanyTF from the provided i_selectWithSearchDataObj
    var valuesAreStringsTF = false;
    var isMultiSelectTF = false;
    if(i_selectWithSearchDataObj !== undefined) {
      valuesAreStringsTF = i_selectWithSearchDataObj.valuesAreStringsTF;
      isMultiSelectTF = i_selectWithSearchDataObj.isMultiSelectTF;
      selectContactCompanyTF = i_selectWithSearchDataObj.selectContactCompanyTF;
      selectContactPersonTF = i_selectWithSearchDataObj.selectContactPersonTF;
      selectCaptureTF = i_selectWithSearchDataObj.selectCaptureTF;
      sharedPercentCaptureManagersTF = i_selectWithSearchDataObj.sharedPercentCaptureManagersTF;
    }

    if(i_fieldInputType === "text") {                                                 inputTypeMask = "Text";                                                 idsb = "s"; mysqlColumnDataType = "text";           textTF = true; }
    else if(i_fieldInputType === "textarea") {                                        inputTypeMask = "Textarea";                                             idsb = "s"; mysqlColumnDataType = "mediumtext";     textareaTF = true; }
    else if(i_fieldInputType === "int") {                                             inputTypeMask = "Integer";                                              idsb = "i"; mysqlColumnDataType = "bigint";         intTF = true; }
    else if(i_fieldInputType === "int_positive") {                                    inputTypeMask = "Integer (positive only)";                              idsb = "i"; mysqlColumnDataType = "bigint";         intTF = true; editNumberOnlyPositiveTF = true; }
    else if(i_fieldInputType === "int_0to9") {                                        inputTypeMask = "Integer (0-9)";                                        idsb = "i"; mysqlColumnDataType = "tinyint";        intTF = true; editNumberOnly0to9TF = true; }
    else if(i_fieldInputType === "decimal") {                                         inputTypeMask = "Decimal";                                              idsb = "d"; mysqlColumnDataType = "decimal(18,9)";  decimalTF = true; }
    else if(i_fieldInputType === "decimal_positive") {                                inputTypeMask = "Decimal (positive only)";                              idsb = "d"; mysqlColumnDataType = "decimal(18,9)";  decimalTF = true; editNumberOnlyPositiveTF = true; }
    else if(i_fieldInputType === "percent_int") {                                     inputTypeMask = "Percent Integer";                                      idsb = "i"; mysqlColumnDataType = "bigint";         percentTF = true; }
    else if(i_fieldInputType === "percent_int_0to100") {                              inputTypeMask = "Percent Integer (0-100)";                              idsb = "i"; mysqlColumnDataType = "tinyint";        percentTF = true; editNumberOnly0to100TF = true; }
    else if(i_fieldInputType === "percent_m1000to100") {                              inputTypeMask = "Percent Integer (-1000-100)";                          idsb = "i"; mysqlColumnDataType = "smallint";       percentTF = true; editNumberOnlym1000to100TF = true; }
    else if(i_fieldInputType === "percent_decimal") {                                 inputTypeMask = "Percent Decimal";                                      idsb = "d"; mysqlColumnDataType = "decimal(18,9)";  percentTF = true; editPercentHasDecimalsTF = true; }
    else if(i_fieldInputType === "percent_decimal_0to100") {                          inputTypeMask = "Percent Decimal (0-100)";                              idsb = "d"; mysqlColumnDataType = "decimal(18,9)";  percentTF = true; editPercentHasDecimalsTF = true; editNumberOnly0to100TF = true; }
    else if(i_fieldInputType === "progress_bar_shaping") {                            inputTypeMask = "Progress Bar";                                         idsb = "d"; mysqlColumnDataType = "decimal(18,9)";  percentTF = true; editPercentHasDecimalsTF = true; editNumberOnly0to100TF = true; maskPercentProgressBarShapingTF = true; }
    else if(i_fieldInputType === "progress_bar_pwin") {                               inputTypeMask = "PWin Box";                                             idsb = "d"; mysqlColumnDataType = "decimal(18,9)";  percentTF = true; editPercentHasDecimalsTF = true; editNumberOnly0to100TF = true; maskPercentPwinCubeTF = true; }
    else if(i_fieldInputType === "money") {                                           inputTypeMask = "Money";                                                idsb = "i"; mysqlColumnDataType = "bigint";         moneyTF = true; }
    else if(i_fieldInputType === "money_cents") {                                     inputTypeMask = "Money (with cents)";                                   idsb = "d"; mysqlColumnDataType = "decimal(18,9)";  moneyTF = true; moneyIncludeCentsTF = true; }
    else if(i_fieldInputType === "email") {                                           inputTypeMask = "Email";                                                idsb = "s"; mysqlColumnDataType = "text";           emailTF = true; }
    else if(i_fieldInputType === "phone") {                                           inputTypeMask = "Phone";                                                idsb = "s"; mysqlColumnDataType = "text";           phoneTF = true; }
    else if(i_fieldInputType === "website") {                                         inputTypeMask = "Website";                                              idsb = "s"; mysqlColumnDataType = "text";           websiteTF = true; }
    else if(i_fieldInputType === "color") {                                           inputTypeMask = "Color";                                                idsb = "s"; mysqlColumnDataType = "varchar(6)";     colorTF = true; }
    else if(i_fieldInputType === "date") {                                            inputTypeMask = "Date (company format)";                                idsb = "s"; mysqlColumnDataType = "date";           dateTF = true; maskDateFormatCompanyTF = true; }
    else if(i_fieldInputType === "date_fromRawDateTimeUtc") {                         inputTypeMask = "Date (company format) [from Date/Time UTC]";           idsb = "s"; mysqlColumnDataType = "datetime";       dateLocalFromRawDateTimeUtcTF = true; maskDateFormatCompanyTF = true; }
    else if(i_fieldInputType === "date_Ymd") {                                        inputTypeMask = "Date (YYYY-mm-dd)";                                    idsb = "s"; mysqlColumnDataType = "date";           dateTF = true; maskDateFormatYmdTF = true; }
    else if(i_fieldInputType === "date_Ymd_fromRawDateTimeUtc") {                     inputTypeMask = "Date (YYYY-mm-dd) [from Date/Time UTC]";               idsb = "s"; mysqlColumnDataType = "datetime";       dateLocalFromRawDateTimeUtcTF = true; maskDateFormatYmdTF = true; }
    else if(i_fieldInputType === "date_MjY") {                                        inputTypeMask = "Date (MMM dd, YYYY)";                                  idsb = "s"; mysqlColumnDataType = "date";           dateTF = true; maskDateFormatMjYTF = true; }
    else if(i_fieldInputType === "date_DMjY") {                                       inputTypeMask = "Date (Day MMM dd, YYYY)";                              idsb = "s"; mysqlColumnDataType = "date";           dateTF = true; maskDateFormatDMjYTF = true; }
    else if(i_fieldInputType === "date_dayMdyDaysUntil1") {                           inputTypeMask = "Date Upcoming (color 1)";                              idsb = "s"; mysqlColumnDataType = "date";           dateTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 1; maskDateFormatDaysUntilIncludeOverdueTF = false; }
    else if(i_fieldInputType === "date_dayMdyDaysUntil1Overdue") {                    inputTypeMask = "Date Upcoming (color 1 with overdue)";                 idsb = "s"; mysqlColumnDataType = "date";           dateTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 1; maskDateFormatDaysUntilIncludeOverdueTF = true; }
    else if(i_fieldInputType === "date_dayMdyDaysUntil2") {                           inputTypeMask = "Date Upcoming (color 2)";                              idsb = "s"; mysqlColumnDataType = "date";           dateTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 2; maskDateFormatDaysUntilIncludeOverdueTF = false; }
    else if(i_fieldInputType === "date_dayMdyDaysUntil2Overdue") {                    inputTypeMask = "Date Upcoming (color 2 with overdue)";                 idsb = "s"; mysqlColumnDataType = "date";           dateTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 2; maskDateFormatDaysUntilIncludeOverdueTF = true; }
    else if(i_fieldInputType === "date_dayMdyDaysUntil2Overdue_fromRawDateTimeUtc") { inputTypeMask = "Date Upcoming (color 2 with overdue) [from Date/Time UTC]";idsb = "s"; mysqlColumnDataType = "datetime";   dateLocalFromRawDateTimeUtcTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 2; maskDateFormatDaysUntilIncludeOverdueTF = true; }
    else if(i_fieldInputType === "datetime") {                                        inputTypeMask = "Date/Time (company format)";                           idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatCompanyTF = true; }
    else if(i_fieldInputType === "datetime_natural") {                                inputTypeMask = "Date/Time (company format) [natural]";                 idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatCompanyTF = true; maskDateFormatNaturalTimeTF = true; }
    else if(i_fieldInputType === "datetime_YmdgiA") {                                 inputTypeMask = "Date/Time (Y-m-d h:m a/p)";                            idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatYmdTF = true; }
    else if(i_fieldInputType === "datetime_YmdgiA_natural") {                         inputTypeMask = "Date/Time (Y-m-d h:m a/p) [natural]";                  idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatYmdTF = true; maskDateFormatNaturalTimeTF = true; }
    else if(i_fieldInputType === "datetime_MjYgiA") {                                 inputTypeMask = "Date/Time (MMM dd, YYYY hh:mm A/P)";                   idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatMjYTF = true; }
    else if(i_fieldInputType === "datetime_MjYgiA_natural") {                         inputTypeMask = "Date/Time (MMM dd, YYYY hh:mm A/P) [natural]";         idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatMjYTF = true; maskDateFormatNaturalTimeTF = true; }
    else if(i_fieldInputType === "datetime_DMjYgiA") {                                inputTypeMask = "Date/Time (Day MMM dd, YYYY hh:mm A/P)";               idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatDMjYTF = true; }
    else if(i_fieldInputType === "datetime_DMjYgiA_natural") {                        inputTypeMask = "Date/Time (Day MMM dd, YYYY hh:mm A/P) [natural]";     idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatDMjYTF = true; maskDateFormatNaturalTimeTF = true; }
    else if(i_fieldInputType === "datetime_dayMdyDaysUntil1") {                       inputTypeMask = "Date/Time Upcoming (color 1)";                         idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 1; maskDateFormatDaysUntilIncludeOverdueTF = false; }
    else if(i_fieldInputType === "datetime_dayMdyDaysUntil1Overdue") {                inputTypeMask = "Date/Time Upcoming (color 1 with overdue)";            idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 1; maskDateFormatDaysUntilIncludeOverdueTF = true; }
    else if(i_fieldInputType === "datetime_dayMdyDaysUntil2") {                       inputTypeMask = "Date/Time Upcoming (color 2)";                         idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 2; maskDateFormatDaysUntilIncludeOverdueTF = false; }
    else if(i_fieldInputType === "datetime_dayMdyDaysUntil2Overdue") {                inputTypeMask = "Date/Time Upcoming (color 2 with overdue)";            idsb = "s"; mysqlColumnDataType = "datetime";       dateTimeTF = true; maskDateFormatDayMdyDaysUntilTF = true; maskDateFormatDaysUntilColorNumber = 2; maskDateFormatDaysUntilIncludeOverdueTF = true; }
    else if(i_fieldInputType === "dateWithRelativeDate") {                            inputTypeMask = "Relative Date";                                        idsb = "s"; mysqlColumnDataType = "date";           dateWithRelativeDateTF = true; } //forced to be masked in company date format
    else if(i_fieldInputType === "dateWithDuration") {                                inputTypeMask = "Date with Duration";                                   idsb = "s"; mysqlColumnDataType = "datetime";       dateWithDurationTF = true; } //forced to be masked in company date format
    else if(i_fieldInputType === "select") { //single select (int or string valueArray), multiselects
      if(isMultiSelectTF) {                                                           inputTypeMask = "Multi-Select";                                         idsb = "s"; mysqlColumnDataType = "text";           selectTF = true; }
      else { //single select
        if(valuesAreStringsTF) {                                                      inputTypeMask = "Select";                                               idsb = "s"; mysqlColumnDataType = "text";           selectTF = true; }
        else {                                                                        inputTypeMask = "Select";                                               idsb = "i"; mysqlColumnDataType = "int";            selectTF = true; }
      }
    }
    else if(i_fieldInputType === "sharedpercent") {                                   inputTypeMask = "Shared Percent";                                       idsb = "s"; mysqlColumnDataType = "text";           sharedPercentTF = true; }
    else if(i_fieldInputType === "scored_text") {                                     inputTypeMask = "Scored Text";                                          idsb = "s"; mysqlColumnDataType = "text";           scoredTextTF = true; scoredTextOrTextareaIncludeScoringTF = true; }
    else if(i_fieldInputType === "scored_textarea") {                                 inputTypeMask = "Scored Textarea";                                      idsb = "s"; mysqlColumnDataType = "text";           scoredTextareaTF = true; scoredTextOrTextareaIncludeScoringTF = true; }
    else if(i_fieldInputType === "scored_text_score_hidden") {                        inputTypeMask = "Scored Text (Score Hidden)";                           idsb = "s"; mysqlColumnDataType = "text";           scoredTextTF = true; scoredTextOrTextareaIncludeScoringTF = false; }
    else if(i_fieldInputType === "scored_textarea_score_hidden") {                    inputTypeMask = "Scored Textarea (Score Hidden)";                       idsb = "s"; mysqlColumnDataType = "text";           scoredTextareaTF = true; scoredTextOrTextareaIncludeScoringTF = false; }
    else if(i_fieldInputType === "verticalswitch") {                                  inputTypeMask = "Vertical Switch";                                      idsb = "i"; mysqlColumnDataType = "int";            verticalSwitchTF = true; }
    else if(i_fieldInputType === "capturefavorite") {                                 inputTypeMask = "Capture Favorite";                                     idsb = "upex"; mysqlColumnDataType = "upex";        captureFavoritesTF = true; }
    else if(i_fieldInputType === "captureprioritylevel") {                            inputTypeMask = "Capture Priority Level";                               idsb = "i"; mysqlColumnDataType = "int";            capturePriorityLevelTF = true; }
    else if(i_fieldInputType === "notestamp") {                                       inputTypeMask = "Notestamp";                                            idsb = "s"; mysqlColumnDataType = "text";           notestampTF = true; }
    else if(i_fieldInputType === "task") {                                            inputTypeMask = "Task";                                                 idsb = "s"; mysqlColumnDataType = "text";           taskTF = true; }
    else if(i_fieldInputType === "documentlinks") {                                   inputTypeMask = "Document Links";                                       idsb = "s"; mysqlColumnDataType = "text";           documentLinksTF = true; }
    else if(i_fieldInputType === "stylingString") {                                   inputTypeMask = "Styling";                                              idsb = "s"; mysqlColumnDataType = "text";           stylingStringTF = true; }
    else if(i_fieldInputType === "stylingString_fontBlue") {                          inputTypeMask = "Styling";                                              idsb = "s"; mysqlColumnDataType = "text";           stylingStringTF = true; stylingStringDefaultFontClassName = "fontBlue"; }
    else if(i_fieldInputType === "stylingString_fontDarkGrayBold") {                  inputTypeMask = "Styling";                                              idsb = "s"; mysqlColumnDataType = "text";           stylingStringTF = true; stylingStringDefaultFontClassName = "fontBold fontTextLighter"; stylingStringBoldOptionTF = false; }
    else {                                                                            inputTypeMask = "--Input Type (" + i_fieldInputType + ")--";            idsb = "x"; mysqlColumnDataType = "xxx"; }

    //compute if idsb is valid
    var idsbIsITF = false;
    var idsbIsDTF = false;
    var idsbIsSTF = false;
    var idsbIsBTF = false;
    idsbIsValidTF = true;
    if(idsb === "i") { idsbIsITF = true; }
    else if(idsb === "d") { idsbIsDTF = true; }
    else if(idsb === "s") { idsbIsSTF = true; }
    else if(idsb === "b") { idsbIsBTF = true; }
    else { idsbIsValidTF = false; }

    //compute requiresSelectWithSearchDataObjTF
    requiresSelectWithSearchDataObjTF = (selectTF || sharedPercentTF || verticalSwitchTF || capturePriorityLevelTF);

    //compute valueRawIsNumericTF and valueRawIsNumericIntTF/valueRawIsNumericDecimalTF based on field idsb
    valueRawIsNumericTF = (idsbIsITF || idsbIsDTF);
    valueRawIsNumericIntTF = idsbIsITF;
    valueRawIsNumericDecimalTF = idsbIsDTF;

    //compute valueDisplayIsNumericTF based on the type of field
    valueDisplayIsNumericTF = (intTF || decimalTF || percentTF || moneyTF);

    //compute valueDisplayIsDateOrDateTimeTF as date or relative date or date with duration or datetime
    valueDisplayIsDateOrDateTimeTF = (dateTF || dateLocalFromRawDateTimeUtcTF || dateTimeTF || dateWithRelativeDateTF || dateWithDurationTF);
    valueDisplayIsDateTF = (dateTF || dateLocalFromRawDateTimeUtcTF || dateWithRelativeDateTF || dateWithDurationTF);
    valueDisplayIsDateTimeTF = (dateTimeTF);

    //compute valueSortIsNumericTF
    valueSortIsNumericTF = (valueRawIsNumericTF && !(selectTF || verticalSwitchTF || capturePriorityLevelTF)); //for non multi select types, even though the database storage idsb is "i", the sort gets converted into a string of numbers like "00000000015" for sorting

    //compute hasTrimDecimalsOptionTF (all isdb "d" decimal types (decimal numbers and percents), but not money with cents)
    hasTrimDecimalsOptionTF = (idsbIsDTF && !moneyTF);

    //compute hasZeroPaddingOptionTF (for "int_positive" field input type, uses the "num_decimals" field to specify the number of digits, 0 or -1 displays a normal int number)
    hasZeroPaddingOptionTF = (intTF && editNumberOnlyPositiveTF);

    //compute the blank value
    blankValue = ""; //blank string value (text, multiselect, sharedpercent)
    if(colorTF) { blankValue = "666666"; } //blank color value
    else if(dateTF || dateWithRelativeDateTF) { blankValue = JSFUNC.blank_date(); } //blank date 0000-00-00
    else if(dateLocalFromRawDateTimeUtcTF || dateTimeTF || dateWithDurationTF) { blankValue = JSFUNC.blank_datetime(); } //blank datetime 0000-00-00 00:00:00
    else if(valueRawIsNumericTF) { blankValue = 0; } //blank number (int, decimal, percent, money, select)

    //field edit mechanism recommended max width to not look weirdly stretched [used in Capture Table cell edit floating box]
    if(valueDisplayIsNumericTF) { recommendedEditMaxWidthOrUndefined = "14em"; }
    else if(dateTF || dateLocalFromRawDateTimeUtcTF) { recommendedEditMaxWidthOrUndefined = "10em"; }
    else if(dateWithRelativeDateTF) { recommendedEditMaxWidthOrUndefined = "18em"; }
    else if(dateWithDurationTF) { recommendedEditMaxWidthOrUndefined = "18em"; }
    else if(dateTimeTF) { recommendedEditMaxWidthOrUndefined = "13em"; }
    else if(capturePriorityLevelTF) { recommendedEditMaxWidthOrUndefined = "6em"; }
    else if(colorTF) { recommendedEditMaxWidthOrUndefined = "10em"; }
    else if(stylingStringTF) { recommendedEditMaxWidthOrUndefined = "20em"; }

    return({
      fieldInputType: i_fieldInputType, //this is only carried around to print in an error message at the bottom of the input editing components selection tree and should not be used for comparisons in the code
      inputTypeMask: inputTypeMask,

      selectWithSearchDataObj: i_selectWithSearchDataObj,

      idsb: idsb,
      idsbIsValidTF: idsbIsValidTF,
      mysqlColumnDataType: mysqlColumnDataType,
      blankValue: blankValue, //the value that would be put into the mysql db by default if a new column was created with the column data type

      textTF: textTF,
      textareaTF: textareaTF,
      intTF: intTF,
      decimalTF: decimalTF,
      percentTF: percentTF,
      maskPercentProgressBarShapingTF: maskPercentProgressBarShapingTF,
      maskPercentPwinCubeTF: maskPercentPwinCubeTF,
      editNumberOnlyPositiveTF: editNumberOnlyPositiveTF,
      editNumberOnly0to100TF: editNumberOnly0to100TF,
      editNumberOnlym1000to100TF: editNumberOnlym1000to100TF,
      editNumberOnly0to9TF: editNumberOnly0to9TF,
      editPercentHasDecimalsTF: editPercentHasDecimalsTF,
      moneyTF: moneyTF,
      moneyIncludeCentsTF: moneyIncludeCentsTF,
      emailTF: emailTF,
      phoneTF: phoneTF,
      websiteTF: websiteTF,
      colorTF: colorTF,
      dateTF: dateTF,
      dateLocalFromRawDateTimeUtcTF: dateLocalFromRawDateTimeUtcTF,
      dateTimeTF: dateTimeTF,
      dateWithRelativeDateTF: dateWithRelativeDateTF,
      dateWithDurationTF: dateWithDurationTF,
      maskDateFormatCompanyTF: maskDateFormatCompanyTF,
      maskDateFormatNaturalTimeTF: maskDateFormatNaturalTimeTF,
      maskDateFormatYmdTF: maskDateFormatYmdTF,
      maskDateFormatMjYTF: maskDateFormatMjYTF,
      maskDateFormatDMjYTF: maskDateFormatDMjYTF,
      maskDateFormatDayMdyDaysUntilTF: maskDateFormatDayMdyDaysUntilTF,
      maskDateFormatDaysUntilColorNumber: maskDateFormatDaysUntilColorNumber,
      maskDateFormatDaysUntilIncludeOverdueTF: maskDateFormatDaysUntilIncludeOverdueTF,
      maskDateFormatHideDaysUntilBoxTF: maskDateFormatHideDaysUntilBoxTF,
      selectTF: selectTF, //uses i_selectWithSearchDataObj
      selectContactCompanyTF: selectContactCompanyTF, //uses i_selectWithSearchDataObj
      selectContactPersonTF: selectContactPersonTF, //uses i_selectWithSearchDataObj
      selectCaptureTF: selectCaptureTF, //uses i_selectWithSearchDataObj
      sharedPercentCaptureManagersTF: sharedPercentCaptureManagersTF, //uses i_selectWithSearchDataObj
      sharedPercentTF: sharedPercentTF, //uses i_selectWithSearchDataObj
      scoredTextTF: scoredTextTF,
      scoredTextareaTF: scoredTextareaTF,
      scoredTextOrTextareaIncludeScoringTF: scoredTextOrTextareaIncludeScoringTF,
      verticalSwitchTF: verticalSwitchTF,
      captureFavoritesTF: captureFavoritesTF,
      capturePriorityLevelTF: capturePriorityLevelTF,
      notestampTF: notestampTF,
      taskTF: taskTF,
      documentLinksTF: documentLinksTF,
      stylingStringTF: stylingStringTF,
      stylingStringDefaultFontClassName: stylingStringDefaultFontClassName,
      stylingStringBoldOptionTF: stylingStringBoldOptionTF,

      requiresSelectWithSearchDataObjTF: requiresSelectWithSearchDataObjTF,
      valueRawIsNumericTF: valueRawIsNumericTF,
      valueRawIsNumericIntTF: valueRawIsNumericIntTF,
      valueRawIsNumericDecimalTF: valueRawIsNumericDecimalTF,
      valueDisplayIsNumericTF: valueDisplayIsNumericTF,
      valueDisplayIsDateOrDateTimeTF: valueDisplayIsDateOrDateTimeTF,
      valueDisplayIsDateTF: valueDisplayIsDateTF,
      valueDisplayIsDateTimeTF: valueDisplayIsDateTimeTF,
      valueSortIsNumericTF: valueSortIsNumericTF,
      hasTrimDecimalsOptionTF: hasTrimDecimalsOptionTF,
      hasZeroPaddingOptionTF: hasZeroPaddingOptionTF,
      recommendedEditMaxWidthOrUndefined: recommendedEditMaxWidthOrUndefined
    });
  }


  create_new_copy_field_type_obj_from_old_field_type_obj_and_field_input_type_and_sws_options_obj(i_oldFieldTypeObj, i_newFieldInputType, i_newSwsOptionsObj) {
    //useful for converting a sharedpercent into a multiselect, which requires having the same value/display data, but the fieldInputType is "multiselect", and the swsOptionsObj has isMultiSelectTF set to true

    //get the old swsDataObj from the old fieldTypeObj (undefined if not a select type)
    var oldSelectWithSearchDataObj = i_oldFieldTypeObj.selectWithSearchDataObj;

    //initialize new swsDataObj as undefined
    var newSelectWithSearchDataObj = undefined;

    //if the old swsDataObj is defined, use the required fields for a valueDisplayArrayObjs copied exactly, then use the new input i_newSwsOptionsObj to alter the swsOptions (like changing multiselect and clear)
    if(oldSelectWithSearchDataObj !== undefined) {
      const oldValueDisplayArraysObj = {
        itemName: oldSelectWithSearchDataObj.itemName,
        valueArray: oldSelectWithSearchDataObj.valueArray,
        valuesAreStringsTF: oldSelectWithSearchDataObj.valuesAreStringsTF,
        displayArray: oldSelectWithSearchDataObj.displayArray,
        treeIDArray: oldSelectWithSearchDataObj.treeIDArray,
        colorArray: oldSelectWithSearchDataObj.colorArray,
        bgColorArray: oldSelectWithSearchDataObj.bgColorArray,
        hiddenUnlessCheckedTFArray: oldSelectWithSearchDataObj.hiddenUnlessCheckedTFArray,
        unableToHighlightOrClickTFArray: oldSelectWithSearchDataObj.unableToHighlightOrClickTFArray,
        optionDisplayOverwriteStringsArray: oldSelectWithSearchDataObj.optionDisplayOverwriteStringsArray,
        multiIDsCheckedWhenCheckedArrayOfArrays: oldSelectWithSearchDataObj.multiIDsCheckedWhenCheckedArrayOfArrays
      };
      newSelectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(oldValueDisplayArraysObj, i_newSwsOptionsObj);
    }

    //create a new fieldTypeObj with the new fieldInputType string and an updated swsDataObj if a select type
    return(this.create_field_type_obj(i_newFieldInputType, newSelectWithSearchDataObj));
  }


  //============================================================================================================
  //SelectWithSearchDataObj (and valueDisplayArraysObj precursor)
  //============================================================================================================
  create_value_display_arrays_obj_from_mapOfMaps(i_itemName, i_filteredMapOfMaps, i_valueFieldName, i_displayFieldName, i_sortFieldName, i_sortIsAscTF, i_sortColumnIsStringsTF, i_treeIDFieldName=undefined, i_colorFieldName=undefined, i_hiddenUnlessCheckedTFFieldName=undefined, i_unableToHighlightOrClickTFFieldName=undefined, i_optionDisplayOverwriteStringFieldName=undefined, i_multiIDsCheckedWhenCheckedArrayFieldName=undefined) {
    const usingTreeIDAndColorTF = ((i_treeIDFieldName !== undefined) && (i_colorFieldName !== undefined));
    const treeIDIsAll00TF = (i_treeIDFieldName === "00");
    const usingHiddenUnlessCheckedTF = (i_hiddenUnlessCheckedTFFieldName !== undefined);
    const usingUnableToHighlightOrClickTF = (i_unableToHighlightOrClickTFFieldName !== undefined);
    const usingOptionDisplayOverwriteStringTF = (i_optionDisplayOverwriteStringFieldName !== undefined);
    const usingMultiIDsCheckedWhenCheckedArrayTF = (i_multiIDsCheckedWhenCheckedArrayFieldName !== undefined);

    //convert data mapOfMaps to an arrayOfObjs, sort it, then extract the column vector arrays for the value and display fields (need to convert all sort values to lowercase)
    var anyHiddenUnlessCheckedIsTrueTF = false;
    var anyUnableToHighlightOrClickIsTrueTF = false;
    var anyOptionDisplayOverwriteIsFilledOutStringTF = false;
    var anyMultiIDsCheckedWhenCheckedIsAnArrayTF = false;
    var sortedArrayOfObjs = [];
    for(let map of i_filteredMapOfMaps.values()) {
      var sortValue = map.get(i_sortFieldName);
      if(i_sortColumnIsStringsTF) {
        if(JSFUNC.is_string(sortValue)) {
          sortValue = sortValue.toLowerCase(); //create the sort array with all lowercase letters for sorting a string column
        }
      }
      else {
        sortValue = Number(sortValue);
      }

      var obj = {
        v: map.get(i_valueFieldName),
        d: map.get(i_displayFieldName),
        s: sortValue
      };

      if(usingTreeIDAndColorTF) {
        obj.t = ((treeIDIsAll00TF) ? ("00") : (map.get(i_treeIDFieldName)));
        obj.c = map.get(i_colorFieldName);
      }

      //hiddenUnlessCheckedTF
      if(usingHiddenUnlessCheckedTF) {
        obj.hucTF = map.get(i_hiddenUnlessCheckedTFFieldName);
        if(obj.hucTF) {
          anyHiddenUnlessCheckedIsTrueTF = true;
        }
      }

      //unableToHighlightOrClickTF
      if(usingUnableToHighlightOrClickTF) {
        obj.uthocTF = map.get(i_unableToHighlightOrClickTFFieldName);
        if(obj.uthocTF) {
          anyUnableToHighlightOrClickIsTrueTF = true;
        }
      }

      //optionDisplayOverwriteString
      if(usingOptionDisplayOverwriteStringTF) {
        obj.odoS = map.get(i_optionDisplayOverwriteStringFieldName);
        if(JSFUNC.string_is_filled_out_tf(obj.odoS)) {
          anyOptionDisplayOverwriteIsFilledOutStringTF = true;
        }
      }

      //multiIDsCheckedWhenCheckedArray
      if(usingMultiIDsCheckedWhenCheckedArrayTF) {
        obj.midcwcA = map.get(i_multiIDsCheckedWhenCheckedArrayFieldName);
        if(JSFUNC.is_array(obj.midcwcA)) {
          anyMultiIDsCheckedWhenCheckedIsAnArrayTF = true;
        }
      }

      sortedArrayOfObjs.push(obj);
    }
    JSFUNC.sort_arrayOfObjs(sortedArrayOfObjs, "s", i_sortIsAscTF);

    //pull each individual array from the sorted arrayOfObjs
    var valueArray = [];
    var displayArray = [];
    for(let obj of sortedArrayOfObjs) {
      valueArray.push(obj.v);
      displayArray.push(obj.d);
    }

    //treeID and color arrays
    var treeIDArray = undefined;
    var colorArray = undefined;
    if(usingTreeIDAndColorTF) {
      treeIDArray = [];
      colorArray = [];
      for(let obj of sortedArrayOfObjs) {
        treeIDArray.push(obj.t);
        colorArray.push(obj.c);
      }
    }

    //no bgColor definition in this function
    const bgColorArray = undefined;

    //hiddenUnlessCheckedTFArray (only need to fill out this array if any of its values are true, because all false is equivalent to having all select options be not hidden)
    var hiddenUnlessCheckedTFArray = undefined;
    if(usingHiddenUnlessCheckedTF && anyHiddenUnlessCheckedIsTrueTF) {
      hiddenUnlessCheckedTFArray = JSFUNC.get_column_vector_from_arrayOfObjs(sortedArrayOfObjs, "hucTF");
    }

    //unableToHighlightOrClickTFArray
    var unableToHighlightOrClickTFArray = undefined;
    if(usingUnableToHighlightOrClickTF && anyUnableToHighlightOrClickIsTrueTF) {
      unableToHighlightOrClickTFArray = JSFUNC.get_column_vector_from_arrayOfObjs(sortedArrayOfObjs, "uthocTF");
    }

    //optionDisplayOverwriteString
    var optionDisplayOverwriteStringsArray= undefined;
    if(usingOptionDisplayOverwriteStringTF && anyOptionDisplayOverwriteIsFilledOutStringTF) {
      optionDisplayOverwriteStringsArray = JSFUNC.get_column_vector_from_arrayOfObjs(sortedArrayOfObjs, "odoS");
    }

    //multiIDsCheckedWhenCheckedArray
    var multiIDsCheckedWhenCheckedArrayOfArrays = undefined;
    if(usingMultiIDsCheckedWhenCheckedArrayTF && anyMultiIDsCheckedWhenCheckedIsAnArrayTF) {
      multiIDsCheckedWhenCheckedArrayOfArrays = JSFUNC.get_column_vector_from_arrayOfObjs(sortedArrayOfObjs, "midcwcA");
    }

    //create the valueDisplayArraysObj for output (assumed that all mapOfMaps values (usually tbl row ids) are always ints)
    return(this.create_value_display_arrays_obj_from_value_array_and_display_array(i_itemName, valueArray, false, displayArray, treeIDArray, colorArray, bgColorArray, hiddenUnlessCheckedTFArray, unableToHighlightOrClickTFArray, optionDisplayOverwriteStringsArray, multiIDsCheckedWhenCheckedArrayOfArrays));
  }


  create_value_display_arrays_obj_from_arrayOfObjs_and_vd_column_names(i_itemName, i_valuesAndDisplaysArrayOfObjs, i_valueColumnName, i_valuesAreStringsTF, i_displayColumnName) {
    var valueArray = [];
    var displayArray = [];
    for(let valueAndDisplayObj of i_valuesAndDisplaysArrayOfObjs) {
      valueArray.push(valueAndDisplayObj[i_valueColumnName]);
      displayArray.push(valueAndDisplayObj[i_displayColumnName]);
    }
    return(this.create_value_display_arrays_obj_from_value_array_and_display_array(i_itemName, valueArray, i_valuesAreStringsTF, displayArray));
  }


  create_value_display_arrays_obj_from_value_array_and_display_array(i_itemName, i_valueArray, i_valuesAreStringsTF, i_displayArray, i_treeIDArray=undefined, i_colorArray=undefined, i_bgColorArray=undefined, i_hiddenUnlessCheckedTFArray=undefined, i_unableToHighlightOrClickTFArray=undefined, i_optionDisplayOverwriteStringsArray=undefined, i_multiIDsCheckedWhenCheckedArrayOfArrays=undefined) {
    return({
      itemName: i_itemName,
      valueArray: i_valueArray,
      valuesAreStringsTF: i_valuesAreStringsTF,
      displayArray: i_displayArray,
      treeIDArray: i_treeIDArray,
      colorArray: i_colorArray,
      bgColorArray: i_bgColorArray, //undefined to turn off, array of all "cccccc" and "ffffff" for gray 'color' and normal white items
      hiddenUnlessCheckedTFArray: i_hiddenUnlessCheckedTFArray, //undefined to turn off, array of all true or false to enable/disable
      unableToHighlightOrClickTFArray: i_unableToHighlightOrClickTFArray, //undefined to turn off, array of all true or false to enable/disable
      optionDisplayOverwriteStringsArray: i_optionDisplayOverwriteStringsArray, //undefined to turn off, array of strings to enable/disable
      multiIDsCheckedWhenCheckedArrayOfArrays: i_multiIDsCheckedWhenCheckedArrayOfArrays
    });
  }


  get_num_values_from_value_display_arrays_obj_or_sws_data_obj(i_valueDisplayArraysObj) {
    if(i_valueDisplayArraysObj !== undefined) {
      if(JSFUNC.is_array(i_valueDisplayArraysObj.valueArray)) {
        return(i_valueDisplayArraysObj.valueArray.length);
      }
    }
    return(0);
  }

  get_first_sorted_value_from_value_display_arrays_obj_or_sws_data_obj(i_valueDisplayArraysObj, i_valueIf0Items) {
    const numValues = this.get_num_values_from_value_display_arrays_obj_or_sws_data_obj(i_valueDisplayArraysObj);
    if(numValues > 0) {
      return(i_valueDisplayArraysObj.valueArray[0]);
    }
    return(i_valueIf0Items);
  }

  value_is_in_value_display_arrays_obj_or_sws_data_obj(i_testValue, i_valueDisplayArraysObj) {
    if(i_valueDisplayArraysObj !== undefined) {
      if(JSFUNC.is_array(i_valueDisplayArraysObj.valueArray)) {
        return(JSFUNC.in_array(i_testValue, i_valueDisplayArraysObj.valueArray));
      }
    }
    return(false);
  }


  create_sws_data_obj_from_value_display_arrays_obj(i_valueDisplayArraysObj, i_swsOptionsObj=undefined) {
    //valueDisplayArraysObj is a precursor to the selectWithSearchDataObj
    // - valueDisplayArraysObj can be precalculated for a field to filter/sort the values, displays, tree indents, and colors
    // - selectWithSearchDataObj then takes the same fields and adds to it specifications for multiselect, search, add, etc for the select, which can utilize the same precalculated valueDisplayArraysObj (ex to create a select version and a multiselect version from the same data)

    //intialization if the i_valueDisplayArraysObj is not a valid obj
    var itemName = "";
    var valueArray = [];
    var valuesAreStringsTF = false;
    var displayArray = [];
    var swsOptionsObj = i_swsOptionsObj;
    if(i_valueDisplayArraysObj === undefined) {
      itemName = "--valueDisplayArraysObj not an obj--";
    }
    else if(!JSFUNC.is_array(i_valueDisplayArraysObj.valueArray)) {
      itemName = "--valueDisplayArraysObj valueArray is not an array--";
    }
    else if(!JSFUNC.is_array(i_valueDisplayArraysObj.displayArray)) {
      itemName = "--valueDisplayArraysObj displayArray is not an array--";
    }
    else {
      itemName = i_valueDisplayArraysObj.itemName;
      valueArray = i_valueDisplayArraysObj.valueArray;
      valuesAreStringsTF = i_valueDisplayArraysObj.valuesAreStringsTF;
      displayArray = i_valueDisplayArraysObj.displayArray;

      //i_swsOptionsObj can remain unchanged from the input (either an obj with fields or undefined) if the other pieces of i_valueDisplayArraysObj are all undefined
      if((i_valueDisplayArraysObj.treeIDArray !== undefined) || (i_valueDisplayArraysObj.colorArray !== undefined) || (i_valueDisplayArraysObj.bgColorArray !== undefined) || (i_valueDisplayArraysObj.hiddenUnlessCheckedTFArray !== undefined) || (i_valueDisplayArraysObj.unableToHighlightOrClickTFArray !== undefined) || (i_valueDisplayArraysObj.optionDisplayOverwriteStringsArray !== undefined) || (i_valueDisplayArraysObj.multiIDsCheckedWhenCheckedArrayOfArrays !== undefined)) {
        //initialize a new empty swsOptionsObj if i_swsOptionsObj is undefined, otherwise use the provided swsOptionsObj and add to it from the fields in i_valueDisplayArraysObj
        if(i_swsOptionsObj === undefined) {
          swsOptionsObj = {};
        }

        swsOptionsObj.treeIDArray = i_valueDisplayArraysObj.treeIDArray;
        swsOptionsObj.colorArray = i_valueDisplayArraysObj.colorArray;
        swsOptionsObj.bgColorArray = i_valueDisplayArraysObj.bgColorArray;
        swsOptionsObj.hiddenUnlessCheckedTFArray = i_valueDisplayArraysObj.hiddenUnlessCheckedTFArray;
        swsOptionsObj.unableToHighlightOrClickTFArray = i_valueDisplayArraysObj.unableToHighlightOrClickTFArray;
        swsOptionsObj.optionDisplayOverwriteStringsArray = i_valueDisplayArraysObj.optionDisplayOverwriteStringsArray;
        swsOptionsObj.multiIDsCheckedWhenCheckedArrayOfArrays = i_valueDisplayArraysObj.multiIDsCheckedWhenCheckedArrayOfArrays;
      }
    }

    return(this.create_sws_data_obj_from_value_array_and_display_array(itemName, valueArray, valuesAreStringsTF, displayArray, swsOptionsObj));
  }


  create_sws_data_obj_from_mapOfMaps_and_vd_column_names(i_itemName, i_valuesAndDisplaysMapOfMaps, i_valueColumnName, i_valuesAreStringsTF, i_displayColumnName, i_swsOptionsObj=undefined) {
    var valueArray = [];
    var displayArray = [];
    for(let valueAndDisplayMap of i_valuesAndDisplaysMapOfMaps.values()) {
      valueArray.push(valueAndDisplayMap.get(i_valueColumnName));
      displayArray.push(valueAndDisplayMap.get(i_displayColumnName));
    }
    return(this.create_sws_data_obj_from_value_array_and_display_array(i_itemName, valueArray, i_valuesAreStringsTF, displayArray, i_swsOptionsObj));
  }


  create_sws_data_obj_from_arrayOfObjs_and_vd_column_names(i_itemName, i_valuesAndDisplaysArrayOfObjs, i_valueColumnName, i_valuesAreStringsTF, i_displayColumnName, i_swsOptionsObj=undefined) {
    var valueArray = [];
    var displayArray = [];
    for(let valueAndDisplayObj of i_valuesAndDisplaysArrayOfObjs) {
      valueArray.push(valueAndDisplayObj[i_valueColumnName]);
      displayArray.push(valueAndDisplayObj[i_displayColumnName]);
    }
    return(this.create_sws_data_obj_from_value_array_and_display_array(i_itemName, valueArray, i_valuesAreStringsTF, displayArray, i_swsOptionsObj));
  }


  create_sws_data_obj_from_value_array_and_display_array(i_itemName, i_valueArray, i_valuesAreStringsTF, i_displayArray, i_swsOptionsObj=undefined) {
    //i_valueArray/i_displayArray - already in their intended sorted order (either alphabetized display or in some sort number order, independent of the input i_sortIntsArray)
    //for capture value valueSort - for all single selects the found matching value index number is used, for multiselects and sharedpercents the valueMask.toLowerCase() is used

    //ensure i_valuesAreStringsTF is either true or false
    var valuesAreStringsTF = false;
    if(i_valuesAreStringsTF) {
      valuesAreStringsTF = true;
    }

    //optional selectWithSearch settings that can be set in the i_swsOptionsObj input (set all defaults if not specified)
    var treeIDArray = undefined;
    var colorArray = undefined;
    var bgColorArray = undefined;
    var hiddenUnlessCheckedTFArray = undefined;
    var unableToHighlightOrClickTFArray = undefined;
    var optionDisplayOverwriteStringsArray = undefined;
    var multiIDsCheckedWhenCheckedArrayOfArrays = undefined;
    var unselectedDisplay = "--No " + i_itemName + " Selected--"; //default phrase shown when the selected value doesn't match any of the values in i_valueArray uses the i_itemName, can be overwritten by specifying this option
    var isMultiSelectTF = false;
    var hasSearchTF = false;
    var hasClearSelectionTF = false;
    var multiSelectHasAllSwitchTF = false;
    var selectAddTblName = undefined; //selectAddTblName is used for selectadd, selectmultiadd selects where any user can inline add a new entry to the select, if this is undefined, then adding a new entry is not available
    var selectAddNameFieldDbName = undefined;
    var selectAddSortFieldDbName = undefined;
    var selectContactCompanyTF = false;
    var selectContactPersonTF = false;
    var selectCaptureTF = false;
    var sharedPercentCaptureManagersTF = false;
    var optionsHeightEm = undefined;
    var includeColorBlockInValueMaskTF = undefined;
    if(i_swsOptionsObj !== undefined) {
      if(JSFUNC.is_array(i_swsOptionsObj.treeIDArray)) { treeIDArray = i_swsOptionsObj.treeIDArray; }
      if(JSFUNC.is_array(i_swsOptionsObj.colorArray)) { colorArray = i_swsOptionsObj.colorArray; }
      if(JSFUNC.is_array(i_swsOptionsObj.bgColorArray)) { bgColorArray = i_swsOptionsObj.bgColorArray; }
      if(JSFUNC.is_array(i_swsOptionsObj.hiddenUnlessCheckedTFArray)) { hiddenUnlessCheckedTFArray = i_swsOptionsObj.hiddenUnlessCheckedTFArray; }
      if(JSFUNC.is_array(i_swsOptionsObj.unableToHighlightOrClickTFArray)) { unableToHighlightOrClickTFArray = i_swsOptionsObj.unableToHighlightOrClickTFArray; }
      if(JSFUNC.is_array(i_swsOptionsObj.optionDisplayOverwriteStringsArray)) { optionDisplayOverwriteStringsArray = i_swsOptionsObj.optionDisplayOverwriteStringsArray; }
      if(JSFUNC.is_array(i_swsOptionsObj.multiIDsCheckedWhenCheckedArrayOfArrays)) { multiIDsCheckedWhenCheckedArrayOfArrays = i_swsOptionsObj.multiIDsCheckedWhenCheckedArrayOfArrays; }
      if(JSFUNC.is_string(i_swsOptionsObj.unselectedDisplay)) { unselectedDisplay = i_swsOptionsObj.unselectedDisplay; }
      if(i_swsOptionsObj.isMultiSelectTF) { isMultiSelectTF = true; }
      if(i_swsOptionsObj.hasSearchTF) { hasSearchTF = true; }
      if(i_swsOptionsObj.hasClearSelectionTF) { hasClearSelectionTF = true; }
      if(i_swsOptionsObj.multiSelectHasAllSwitchTF) { multiSelectHasAllSwitchTF = true; }
      if(JSFUNC.is_string(i_swsOptionsObj.selectAddTblName)) { selectAddTblName = i_swsOptionsObj.selectAddTblName; }
      if(JSFUNC.is_string(i_swsOptionsObj.selectAddNameFieldDbName)) { selectAddNameFieldDbName = i_swsOptionsObj.selectAddNameFieldDbName; }
      if(JSFUNC.is_string(i_swsOptionsObj.selectAddSortFieldDbName)) { selectAddSortFieldDbName = i_swsOptionsObj.selectAddSortFieldDbName; }
      if(i_swsOptionsObj.selectContactCompanyTF) { selectContactCompanyTF = true; }
      if(i_swsOptionsObj.selectContactPersonTF) { selectContactPersonTF = true; }
      if(i_swsOptionsObj.selectCaptureTF) { selectCaptureTF = true; }
      if(i_swsOptionsObj.sharedPercentCaptureManagersTF) { sharedPercentCaptureManagersTF = true; }
      if(JSFUNC.is_number_not_nan_gt_0(i_swsOptionsObj.optionsHeightEm)) { optionsHeightEm = i_swsOptionsObj.optionsHeightEm; }
      if(i_swsOptionsObj.includeColorBlockInValueMaskTF) { includeColorBlockInValueMaskTF = true; }
    }

    return({
      itemName: i_itemName,                                                             //required string
      valueArray: i_valueArray,                                                         //required array
      valuesAreStringsTF: valuesAreStringsTF,                                           //required TF
      displayArray: i_displayArray,                                                     //required array
      treeIDArray: treeIDArray,                                                         //optional (swsOptionsObj, also defined in valueDisplayArraysObj)
      colorArray: colorArray,                                                           //optional (swsOptionsObj, also defined in valueDisplayArraysObj)
      bgColorArray: bgColorArray,                                                       //optional (swsOptionsObj, also defined in valueDisplayArraysObj)
      hiddenUnlessCheckedTFArray: hiddenUnlessCheckedTFArray,                           //optional (swsOptionsObj, also defined in valueDisplayArraysObj)
      unableToHighlightOrClickTFArray: unableToHighlightOrClickTFArray,                 //optional (swsOptionsObj, also defined in valueDisplayArraysObj)
      optionDisplayOverwriteStringsArray: optionDisplayOverwriteStringsArray,           //optional (swsOptionsObj, also defined in valueDisplayArraysObj)
      multiIDsCheckedWhenCheckedArrayOfArrays: multiIDsCheckedWhenCheckedArrayOfArrays, //optional (swsOptionsObj, also defined in valueDisplayArraysObj)
      unselectedDisplay: unselectedDisplay,                                             //optional (swsOptionsObj)
      isMultiSelectTF: isMultiSelectTF,                                                 //optional (swsOptionsObj)
      hasSearchTF: hasSearchTF,                                                         //optional (swsOptionsObj)
      hasClearSelectionTF: hasClearSelectionTF,                                         //optional (swsOptionsObj)
      multiSelectHasAllSwitchTF: multiSelectHasAllSwitchTF,                             //optional (swsOptionsObj)
      selectAddTblName: selectAddTblName,                                               //optional (swsOptionsObj)
      selectAddNameFieldDbName: selectAddNameFieldDbName,                               //optional (swsOptionsObj)
      selectAddSortFieldDbName: selectAddSortFieldDbName,                               //optional (swsOptionsObj)
      selectContactCompanyTF: selectContactCompanyTF,                                   //optional (swsOptionsObj)
      selectContactPersonTF: selectContactPersonTF,                                     //optional (swsOptionsObj)
      selectCaptureTF: selectCaptureTF,                                                 //optional (swsOptionsObj)
      sharedPercentCaptureManagersTF: sharedPercentCaptureManagersTF,                   //optional (swsOptionsObj)
      optionsHeightEm: optionsHeightEm,                                                 //optional (swsOptionsObj)
      includeColorBlockInValueMaskTF: includeColorBlockInValueMaskTF                    //optional (swsOptionsObj)
    });
  }


  all_selectWithSearchDataObj_optional_field_names_array() {
    return(["treeIDArray", "colorArray", "bgColorArray", "hiddenUnlessCheckedTFArray", "unableToHighlightOrClickTFArray", "optionDisplayOverwriteStringsArray", "multiIDsCheckedWhenCheckedArrayOfArrays", "unselectedDisplay", "isMultiSelectTF", "hasSearchTF", "hasClearSelectionTF", "multiSelectHasAllSwitchTF", "selectAddTblName", "selectAddNameFieldDbName", "selectAddSortFieldDbName", "selectContactCompanyTF", "selectContactPersonTF", "selectCaptureTF", "sharedPercentCaptureManagersTF", "optionsHeightEm", "includeColorBlockInValueMaskTF"]);
  }


  create_stages_arrayOfObjs_from_capture_type_id(i_captureTypeID) {
    const o_tbl_a_capture_types = this.o_tbl_a_capture_types;
    const o_tbl_a_stages_pool = this.c_tbl_a_stages_pool;

    var captureTypeStagesArrayOfObjs = [];

    //get all stages in stage_ids_comma order from captureTypeID
    const captureTypeMapOrUndefined = o_tbl_a_capture_types.get(i_captureTypeID);
    if(captureTypeMapOrUndefined !== undefined) { //return 0 stages if captureTypeID does not exist
      const stageIDsComma = captureTypeMapOrUndefined.get("stage_ids_comma");
      const stageIDsArray = JSFUNC.convert_comma_list_to_int_array(stageIDsComma);
      for(let stageID of stageIDsArray) {
        var stageMapOrUndefined = o_tbl_a_stages_pool.get(stageID);
        if(stageMapOrUndefined !== undefined) { //skip stageIDs that are not in the stages pool
          var stageObj = JSFUNC.obj_from_map(stageMapOrUndefined);
          captureTypeStagesArrayOfObjs.push(stageObj);
        }
      }
    }

    //sort stages based on stage type (all active, won, lost, no bid, cancelled)
    const stageTypeIDsArrayOfArrays = [[1, 2, 3], [4], [5], [6], [7, 8]];
    var sortedCaptureTypeStagesArrayOfObjs = [];
    if(captureTypeStagesArrayOfObjs.length > 0) {
      for(let stageTypeIDsArray of stageTypeIDsArrayOfArrays) {
        for(let stageObj of captureTypeStagesArrayOfObjs) {
          if(JSFUNC.in_array(stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7, stageTypeIDsArray)) {
            sortedCaptureTypeStagesArrayOfObjs.push(stageObj);
          }
        }
      }
    }

    return(sortedCaptureTypeStagesArrayOfObjs);
  }


  create_select_stages_field_type_obj_within_capture_type_id(i_captureTypeID) {
    //initialize the value/display arrays
    var valueArray = [];
    var displayArray = [];
    var treeIDArray = []; //filled with all false
    var colorArray = [];

    //get the capture type map from the input captureTypeID to get the stageIDs in their ordered comma list
    const captureTypeStagesArrayOfObjs = this.create_stages_arrayOfObjs_from_capture_type_id(i_captureTypeID);
    for(let stageObj of captureTypeStagesArrayOfObjs) {
      valueArray.push(stageObj.id);
      displayArray.push(stageObj.name);
      treeIDArray.push(false);
      colorArray.push(stageObj.color);
    }

    const swsOptionsObj = {treeIDArray:treeIDArray, colorArray:colorArray, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array("Stage", valueArray, false, displayArray, swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }


  create_filefoldersystem_field_type_obj_from_tbl_name(i_ffsMapOfMaps, i_itemName, i_isMultiSelectTF, i_hasSearchTF, i_hasClearSelectionTF) {
    //need to convert:
    //{id:1, folder0_file1:1, parent_folder_id:-1, display_name:"G File 1"}
    //{id:2, folder0_file1:0, parent_folder_id:-1, display_name:"F Folder 2"}
    //{id:3, folder0_file1:1, parent_folder_id:4, display_name:"E File 3"}
    //{id:4, folder0_file1:0, parent_folder_id:-1, display_name:"D Folder 4"}
    //{id:5, folder0_file1:1, parent_folder_id:4, display_name:"C File 5"}
    //{id:6, folder0_file1:0, parent_folder_id:4, display_name:"B Folder 6"}
    //{id:7, folder0_file1:1, parent_folder_id:6, display_name:"A File 7"}
    //
    //into this particular expanded nested folder/file and alphabetic order (actual FFS only draws closed folders and files in currently viewing folder, this view is everything expanded):
    //  "D Folder 4" (treeID:"00")
    //      "B Folder 6" (treeID:"0000")
    //          "A File 7" (treeID:"000000")
    //      "C File 5" (treeID:"0000")
    //      "E File 3" (treeID:"0000")
    //  "F Folder 2" (treeID:"00")
    //  "G File 1" (treeID:"00")
    
    const filteredFFSArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(i_ffsMapOfMaps);
    
    //split files and folders into 2 separate arrays sorted by display name
    const foldersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(filteredFFSArrayOfObjs, "folder0_file1", 0, "display_name", true);
    const numFolders = foldersArrayOfObjs.length;
    const allFolderIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(foldersArrayOfObjs, "id"); //get all ids of folders so that each file can determine if it is in a valid folder or not
    const filesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(filteredFFSArrayOfObjs, "folder0_file1", 1, "display_name", true);
    
    var foldersAndFilesTreeArrayOfObjs = [];

    if(numFolders > 0) {
      //foldersArrayOfObjs: [
      //  {id:6, folder0_file1:0, parent_folder_id:2, display_name:"B Folder 6"},
      //  {id:4, folder0_file1:0, parent_folder_id:-1, display_name:"D Folder 4"},
      //  {id:2, folder0_file1:0, parent_folder_id:-1, display_name:"F Folder 2"}
      //]
      var remainingFolderIDsToProcessArray = JSFUNC.copy_array(allFolderIDsArray); //[6, 4, 2]
      
      //initially loop to find all root level -1 folders at treeLevel 1
      var prevTreeLevelHadAtLeast1FolderPlacedTF = false; //initialize this var and calculate it for the root level as well as all higher levels (if root level had 0 folders, no other folders can be subfolders and are just discarded and wont be included/drawn)
      var currentRootTreeIDInt = 0;
      for(let folderObj of foldersArrayOfObjs) {
        if(folderObj.parent_folder_id === -1) {
          //modify this folderObj to add tree position
          folderObj.treeID = JSFUNC.zero_pad_integer_from_left(currentRootTreeIDInt, 2);
          folderObj.treeLevel = 1;
          folderObj.newChildNextTreeIDInt = 0;
          
          //add the folder at the root level to the output list
          foldersAndFilesTreeArrayOfObjs.push(folderObj);

          //remove this folderID from the list of remaining folders to process
          remainingFolderIDsToProcessArray = JSFUNC.remove_all_values_from_array(folderObj.id, remainingFolderIDsToProcessArray);

          //mark that at least 1 root level folder exists
          prevTreeLevelHadAtLeast1FolderPlacedTF = true;

          //increment the treeID for the next root level folder (already in alphabetic order which will then be in numeric treeID order)
          currentRootTreeIDInt++;
          if(currentRootTreeIDInt > 99) {
            currentRootTreeIDInt = 99;
          }
        }
      }
      var numRemainingFoldersToProcess = remainingFolderIDsToProcessArray.length;
      
      //foldersAndFilesTreeArrayOfObjs: [
      //  {id:4, treeID:"00", treeLevel:1, newChildNextTreeIDInt:0, folder0_file1:0, parent_folder_id:-1, display_name:"D Folder 4"},
      //  {id:2, treeID:"01", treeLevel:1, newChildNextTreeIDInt:0, folder0_file1:0, parent_folder_id:-1, display_name:"F Folder 2"}
      //]

      //use a while loop to move through each treeLevel progressively (2-infinity) until either every folder is handled, or a level is reached where 0 new children of folders are found
      var currentTreeLevelProcessing = 2; //level 1 root handled manually above, start in this while loop tree levels 2 to infinity
      while((numRemainingFoldersToProcess > 0) && prevTreeLevelHadAtLeast1FolderPlacedTF) {
        prevTreeLevelHadAtLeast1FolderPlacedTF = false;
        var childFolderIDsInsertedThisLevelArray = [];

        //loop over and test every remaining folder to see if it has a parent at the previous tree level
        for(let remainingFolderID of remainingFolderIDsToProcessArray) {
          //fetch the remaining folderObj from its folderID
          var remainingFolderObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(foldersArrayOfObjs, "id", remainingFolderID);
          if(remainingFolderObj !== undefined) {
            //loop over every folder currently inserted at lower tree levels in the growing final output array
            for(let f = 0; f < foldersAndFilesTreeArrayOfObjs.length; f++) {
              var folderTreeObj = foldersAndFilesTreeArrayOfObjs[f];
              if(folderTreeObj.treeLevel === (currentTreeLevelProcessing - 1)) { //make sure this tested folder is at 1 tree level previous to the current tree level test
                if(remainingFolderObj.parent_folder_id === folderTreeObj.id) { //check if this folder is in fact the parent of the remaining folder being placed
                  //modify this child folderObj to add tree position
                  var newFolderChildTreeIDInt = folderTreeObj.newChildNextTreeIDInt;
                  if(folderTreeObj.newChildNextTreeIDInt > 99) {
                    newFolderChildTreeIDInt = 99;
                  }
                  remainingFolderObj.treeID = folderTreeObj.treeID + JSFUNC.zero_pad_integer_from_left(newFolderChildTreeIDInt, 2); //treeID is concat of parent folder treeID plus new child 2 digit portion
                  remainingFolderObj.treeLevel = currentTreeLevelProcessing;
                  remainingFolderObj.newChildNextTreeIDInt = 0;

                  //insert the subfolder and resort the array by treeID
                  foldersAndFilesTreeArrayOfObjs.push(remainingFolderObj);
                  JSFUNC.sort_arrayOfObjs(foldersAndFilesTreeArrayOfObjs, "treeID", true);

                  //note that this child folder was inserted so it can be removed from the remaining list outside of this loop
                  childFolderIDsInsertedThisLevelArray.push(remainingFolderObj.id);
                  
                  //modify the found parent folder to increment the newChildNextTreeIDInt for the next child found (folder or file)
                  folderTreeObj.newChildNextTreeIDInt++;

                  //set the flag to true to check another tree level as this newly inserted folder could be the parent of a remaining folder
                  prevTreeLevelHadAtLeast1FolderPlacedTF = true;

                  break;
                }
              }
            }
          }
        }

        //removed added child folderIDs and update the number of remaining folders to process
        remainingFolderIDsToProcessArray = JSFUNC.remove_all_values_from_array(childFolderIDsInsertedThisLevelArray, remainingFolderIDsToProcessArray);
        numRemainingFoldersToProcess = remainingFolderIDsToProcessArray.length;

        //increment the current tree level being processed for the next loop
        currentTreeLevelProcessing++;
      }
      
      //foldersAndFilesTreeArrayOfObjs: [
      //  {id:4, treeID:"00",   treeLevel:1, newChildNextTreeIDInt:1, folder0_file1:0, parent_folder_id:-1, display_name:"D Folder 4"},
      //  {id:6, treeID:"0000", treeLevel:2, newChildNextTreeIDInt:0, folder0_file1:0, parent_folder_id:4,  display_name:"B Folder 6"},
      //  {id:2, treeID:"01",   treeLevel:1, newChildNextTreeIDInt:0, folder0_file1:0, parent_folder_id:-1, display_name:"F Folder 2"}
      //]

      //loop over each folder to add color/bgColor/unable/multi values to each treeFolderObj
      for(let treeFolderObj of foldersAndFilesTreeArrayOfObjs) {
        treeFolderObj.color = "97a";
        treeFolderObj.bgColor = "eee";
        treeFolderObj.unableToHighlightOrClickTF = true;
      }
    }
    
    //loop over each file to place it among the folders in the final file/folder array
    for(let fileObj of filesArrayOfObjs) { //check each file
      //expand the fileObj into a treeFileObj
      fileObj.color = undefined;
      fileObj.bgColor = undefined;
      fileObj.unableToHighlightOrClickTF = false;
      
      var fileWasPlacedInAFolderTF = false;

      for(let f = 0; f < foldersAndFilesTreeArrayOfObjs.length; f++) {
        var fileFolderTreeObj = foldersAndFilesTreeArrayOfObjs[f];

        //if checking a folder and that folder is the parent of this file, inject the file into the next child slot of the folder
        if((fileFolderTreeObj.folder0_file1 === 0) && (fileObj.parent_folder_id === fileFolderTreeObj.id)) { //modify this child folderObj to add tree position
          //treeID is concat of parent folder treeID plus new child 2 digit portion
          var newFileChildTreeIDInt = fileFolderTreeObj.newChildNextTreeIDInt;
          if(fileFolderTreeObj.newChildNextTreeIDInt > 99) {
          newFileChildTreeIDInt = 99;
          }
          fileObj.treeID = fileFolderTreeObj.treeID + JSFUNC.zero_pad_integer_from_left(newFileChildTreeIDInt, 2);
          
          foldersAndFilesTreeArrayOfObjs.push(fileObj);
          JSFUNC.sort_arrayOfObjs(foldersAndFilesTreeArrayOfObjs, "treeID", true);

          //mark that this file was placed in a folder
          fileWasPlacedInAFolderTF = true;

          //modify the found parent folder to increment the newChildNextTreeIDInt for the next child found (folder or file)
          fileFolderTreeObj.newChildNextTreeIDInt++;

          break;
        }
      }
      
      //if the file was not placed in a folder (-1 parent root level, or parent is an invalid id number), put the file at the root level at the bottom
      if(!fileWasPlacedInAFolderTF) {
        fileObj.treeID = JSFUNC.zero_pad_integer_from_left(currentRootTreeIDInt, 2);
        foldersAndFilesTreeArrayOfObjs.push(fileObj);
        
        currentRootTreeIDInt++;
        if(currentRootTreeIDInt > 99) {
          currentRootTreeIDInt = 99;
        }
      }
    }
    
    //foldersAndFilesTreeArrayOfObjs: [
    //  {id:4, treeID:"00",     treeLevel:1,        newChildNextTreeIDInt:1,        folder0_file1:0, parent_folder_id:-1, display_name:"D Folder 4"},
    //  {id:6, treeID:"0000",   treeLevel:2,        newChildNextTreeIDInt:0,        folder0_file1:0, parent_folder_id:4,  display_name:"B Folder 6"},
    //  {id:7, treeID:"000000", treeLevel:undefined,newChildNextTreeIDInt:undefined,folder0_file1:1, parent_folder_id:6,  display_name:"A File 7"},
    //  {id:5, treeID:"0001",   treeLevel:undefined,newChildNextTreeIDInt:undefined,folder0_file1:1, parent_folder_id:4,  display_name:"C File 5"},
    //  {id:3, treeID:"0002",   treeLevel:undefined,newChildNextTreeIDInt:undefined,folder0_file1:1, parent_folder_id:4,  display_name:"E File 3"},
    //  {id:2, treeID:"01",     treeLevel:1,        newChildNextTreeIDInt:0,        folder0_file1:0, parent_folder_id:-1, display_name:"F Folder 2"},
    //  {id:3, treeID:"02",     treeLevel:undefined,newChildNextTreeIDInt:undefined,folder0_file1:1, parent_folder_id:-1, display_name:"G File 1"}
    //]

    const treeIDArray = JSFUNC.get_column_vector_from_arrayOfObjs(foldersAndFilesTreeArrayOfObjs, "treeID");
    const colorArray = JSFUNC.get_column_vector_from_arrayOfObjs(foldersAndFilesTreeArrayOfObjs, "color");
    const bgColorArray = JSFUNC.get_column_vector_from_arrayOfObjs(foldersAndFilesTreeArrayOfObjs, "bgColor");
    const unableToHighlightOrClickTFArray = JSFUNC.get_column_vector_from_arrayOfObjs(foldersAndFilesTreeArrayOfObjs, "unableToHighlightOrClickTF");
    const optionDisplayOverwriteStringsArray = undefined;
    const multiIDsCheckedWhenCheckedArrayOfArrays = undefined;
    const foldersAndFilesTreeMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs(foldersAndFilesTreeArrayOfObjs);
    const swsOptionsObj = {isMultiSelectTF:i_isMultiSelectTF, hasSearchTF:i_hasSearchTF, hasClearSelectionTF:i_hasClearSelectionTF, treeIDArray:treeIDArray, colorArray:colorArray, bgColorArray:bgColorArray, unableToHighlightOrClickTFArray:unableToHighlightOrClickTFArray, optionDisplayOverwriteStringsArray:optionDisplayOverwriteStringsArray, multiIDsCheckedWhenCheckedArrayOfArrays:multiIDsCheckedWhenCheckedArrayOfArrays};
    const selectWithSearchDataObj = this.create_sws_data_obj_from_mapOfMaps_and_vd_column_names(i_itemName, foldersAndFilesTreeMapOfMaps, "id", false, "display_name", swsOptionsObj);
    return(this.create_field_type_obj("select", selectWithSearchDataObj));
  }


  create_verticalswitch_field_type_obj_from_value_array_and_display_array(i_itemName, i_valueArray, i_valuesAreStringsTF, i_displayArray, i_optionsHeightEm=undefined) {
    var swsOptionsObj = undefined;
    if(JSFUNC.is_number_not_nan_gt_0(i_optionsHeightEm)) {
      swsOptionsObj = {optionsHeightEm:i_optionsHeightEm};
    }
    const selectWithSearchDataObj = this.create_sws_data_obj_from_value_array_and_display_array(i_itemName, i_valueArray, i_valuesAreStringsTF, i_displayArray, swsOptionsObj);
    return(this.create_field_type_obj("verticalswitch", selectWithSearchDataObj));
  }





  //============================================================================================================
  //expandedCaptureFieldMap (specifically related to tbl_captures/tbl_captures_fields using captureMaps, captureFieldMaps)
  //============================================================================================================
  create_expanded_capture_field_map_from_capture_field_map(i_captureFieldMap) {
    //required columns in i_captureFieldMap:
    //  - "input_type"
    //  - "db_name"
    //  - "display_name"
    //
    //this conversion works with tbl_captures_fields, tbl_a_contacts_companies_extra_fields, tbl_a_contacts_persons_extra_fields, tbl_a_teammates_extra_fields, tbl_a_competitors_extra_fields

    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;
    const c_companyPwinUsersCanEditTF = this.c_companyPwinUsersCanEditTF;

    //copy all fields from the original map to start the new expanded map
    var expandedCaptureFieldMap = JSFUNC.copy_map(i_captureFieldMap);

    //load existing db fields to use calculating other fields below
    var fieldDisplayName = i_captureFieldMap.get("display_name");
    var fieldDbName = i_captureFieldMap.get("db_name");
    var tblCapturesFieldsInputType = i_captureFieldMap.get("input_type"); //"fieldInputType" will be changed for select types from the original column "input_type" in tbl_captures_fields
    var tblCapturesFieldsSelectTblName = i_captureFieldMap.get("select_tbl_name");
    var adminSort = i_captureFieldMap.get("admin_sort");
    var fieldTextareaDetailsMaxLines = i_captureFieldMap.get("textarea_details_max_lines"); //used for details card textarea mask height, and for verticalswitch field edit options height
    var numDecimalsOrIntPositiveZeroPaddingNumDigits = i_captureFieldMap.get("num_decimals");
    var hoverText = i_captureFieldMap.get("hover_text");
    var displayNameStylingStringComma = i_captureFieldMap.get("display_name_styling_string_comma");
    var customCodewordNamesComma = i_captureFieldMap.get("codeword_names_comma");

    //compute TF flags used within this function of which system field this input field's db_name is
    var fieldIsCaptureIDTF = false;
    var fieldIsOpportunityNameTF = false;
    var fieldIsCodenameTF = false;
    var fieldIsCaptureManagersIDsColonPercentCommaTF = false;
    var fieldIsDivisionOwnersIDsColonPercentCommaTF = false;
    var fieldIsCaptureTypeIDTF = false;
    var fieldIsTblACaptureTypesPrimeSubTF = false;
    var fieldIsStageIDTF = false;
    var fieldIsContractTypeIDTF = false;
    var fieldIsIdiqCaptureIDTOLinkTF = false;
    var fieldIsContractOverallValueTF = false;
    var fieldIsPeriodOfPerformanceTF = false;
    var fieldIsContractsManagerUserIDTF = false;
    var fieldIsBudgetManagerUserIDTF = false;
    var fieldIsDescriptionOfWorkTF = false;
    var fieldIsSolicitationNumTF = false;
    var fieldIsNaicsCodeIDsCommaTF = false;
    var fieldIsCompetitionTypeBmSetAsideIDsCommaTF = false;
    var fieldIsCaptureFavoritesUpeXTF = false;
    var fieldIsCapturePriorityLevelIDTF = false;
    var fieldIsAddedDateTF = false;
    var fieldIsRfpDateTF = false;
    var fieldIsProposalDueDatetimeUtcTF = false;
    var fieldIsAwardDateTF = false;
    var fieldIsContractStartDateTF = false;
    var fieldIsContractEndDateTF = false;
    var fieldIsArchiveDateTF = false;
    var fieldIsLastChangedDateTF = false;
    var fieldIsLastStageDateTF = false;
    var fieldIsLastProgressDateTF = false;
    var fieldIsLastPwinDateTF = false;
    var fieldIsLastRfpDateTF = false;
    var fieldIsReasonsWonLostIDsColonPercentCommaTF = false;
    var fieldIsContractRevenueValueTF = false;
    var fieldIsAllocatedRevenueValueTF = false;
    var fieldIsAllocatedNetValueTF = false;
    var fieldIsAllocatedRevenuePerMonthTF = false;
    var fieldIsAllocatedNetPerMonthTF = false;
    var fieldIsPwinAdjustedContractOverallValueTF = false;
    var fieldIsPwinAdjustedContractRevenueValueTF = false;
    var fieldIsPwinAdjustedAllocatedRevenueValueTF = false;
    var fieldIsPwinAdjustedAllocatedNetValueTF = false;
    var fieldIsIdiqToAnticipatedValueTF = false;
    var fieldIsShapingTotalProgressTF = false;
    var fieldIsShapingStageProgressTF = false;
    var fieldIsPwinTF = false;
    var fieldIsOurPrimeSubTeammateDivisionIDTF = false;
    var fieldIsOurPrimeSubTeammateAllocationTF = false;
    var fieldIsPrimeContactCompanyIDTF = false;
    var fieldIsReqSbAllocationTF = false;
    var fieldIsNumTeammatesTF = false;
    var fieldIsNumLbTeammatesTF = false;
    var fieldIsNumSbTeammatesTF = false;
    var fieldIsTotalTeamAllocationTF = false;
    var fieldIsTotalLbAllocationTF = false;
    var fieldIsTotalSbAllocationTF = false;
    var fieldIsTotalTeamAllocationCovTF = false;
    var fieldIsTotalLbAllocationCovTF = false;
    var fieldIsTotalSbAllocationCovTF = false;
    var fieldIsOurIncumbentCompetitorDivisionIDTF = false;
    var fieldIsIncumbentContactCompanyIDsCommaTF = false;
    var fieldIsNotepadTF = false;
    var fieldIsRecentPinnedNotestampTF = false;
    var fieldIsNextActiveTaskTF = false;
    var fieldIsGcssOverwriteDataWithUpdates01TF = false;
    var fieldIsGcssIDTF = false;
    var fieldIsGcssGovwinIDStringTF = false;
    var fieldIsGcssFedcompIDStringTF = false;
    var fieldIsGcssOriginalPublishedDatetimeUtcTF = false;
    var fieldIsGcssLastUpdatedDatetimeUtcTF = false;
    var fieldIsGcssSamLinkTF = false;
    var fieldIsGcssGovwinLinkTF = false;
    var fieldIsGcssFedcompLinkTF = false;
    var fieldIsGcssBmDepartmentAgencyIDTF = false;
    var fieldIsGcssBmSubTierIDTF = false;
    var fieldIsGcssOfficeTF = false;
    var fieldIsGcssBmProductServiceCodeIDsCommaTF = false;
    var fieldIsGcssBmContractOpportunityTypeIDTF = false;
    var fieldIsGcssGovwinProcurementCommentTF = false;
    var fieldIsGcssGovwinDurationTF = false;
    var fieldIsGcssGovwinPrimaryWorkRequiredTF = false;
    var fieldIsGcssGovwinPriority1to5TF = false;
    var fieldIsGcssBmFedcompContractTypeIDTF = false;
    var fieldIsGcssAwardNumberTF = false;
    var fieldIsGcssPlaceOfPerformanceAddressTF = false;
    var fieldIsGcssPlaceOfPerformanceCityTF = false;
    var fieldIsGcssPlaceOfPerformanceBmStateIDsCommaTF = false;
    var fieldIsGcssPlaceOfPerformanceZipTF = false;
    var fieldIsGcssPlaceOfPerformanceProvinceTF = false;
    var fieldIsGcssPlaceOfPerformanceBmCountryIDsCommaTF = false;
    var fieldIsGcssContractingOfficeCityTF = false;
    var fieldIsGcssContractingOfficeBmStateIDTF = false;
    var fieldIsGcssContractingOfficeZipTF = false;
    var fieldIsGcssContractingOfficeProvinceTF = false;
    var fieldIsGcssContractingOfficeBmCountryIDTF = false;
    var fieldIsGcssContractingOfficePoc1NameEmailPhoneTF = false;
    var fieldIsGcssContractingOfficePoc2NameEmailPhoneTF = false;
    var fieldIsGcssAwardeeInformationTF = false;
    var fieldIsGcssDocumentLinksJsonStringTF = false;

    //for admin Details/Dates Fields separation of hardcoded vs custom fields (any of these true is a system hardcoded field, all left false means a user created custom field), categorize each field from tbl_captures_fields using different flags
    var detailsCardMandatoryTF = false;
    var detailsCardGcssTF = false;
    var advanceStageCardTF = false;
    var dealShapingCardTF = false;
    var teammatesCardTF = false;
    var competitorsCardTF = false;
    var notepadCardTF = false;

    //computed fields are computed automatically through CaptureExec from actions on other cards, cannot be manually edited by the user (or used as a field when importing) ["added_date", "last_changed_date" can be imported, but cannot be edited]
    var isComputedFieldTF = false;

    //fields that cannot be edited on the details (can be edited on another specific card like stage_id, or not at all like added_date)
    var isUneditableFieldTF = false;

    if(fieldDbName === "id") {                                                  fieldIsCaptureIDTF = true;                              detailsCardMandatoryTF = true;  isUneditableFieldTF = true; }
    else if(fieldDbName === "opportunity_name") {                               fieldIsOpportunityNameTF = true;                        detailsCardMandatoryTF = true; }
    else if(fieldDbName === "codename") {                                       fieldIsCodenameTF = true;                               detailsCardMandatoryTF = true; }
    else if(fieldDbName === "capture_managers_ids_colon_percent_comma") {       fieldIsCaptureManagersIDsColonPercentCommaTF = true;    detailsCardMandatoryTF = true; }
    else if(fieldDbName === "division_owners_ids_colon_percent_comma") {        fieldIsDivisionOwnersIDsColonPercentCommaTF = true;     detailsCardMandatoryTF = true; }
    else if(fieldDbName === "capture_type_id") {                                fieldIsCaptureTypeIDTF = true;                          detailsCardMandatoryTF = true; }
    else if(fieldDbName === "tbl_a_capture_types_prime_sub") {                  fieldIsTblACaptureTypesPrimeSubTF = true;               detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "stage_id") {                                       fieldIsStageIDTF = true;                                advanceStageCardTF = true;      isUneditableFieldTF = true; }
    else if(fieldDbName === "contract_type_id") {                               fieldIsContractTypeIDTF = true;                         detailsCardMandatoryTF = true; }
    else if(fieldDbName === "idiq_capture_id_TO_link") {                        fieldIsIdiqCaptureIDTOLinkTF = true;                    detailsCardMandatoryTF = true; }
    else if(fieldDbName === "contract_overall_value") {                         fieldIsContractOverallValueTF = true;                   detailsCardMandatoryTF = true; }
    else if(fieldDbName === "period_of_performance") {                          fieldIsPeriodOfPerformanceTF = true;                    detailsCardMandatoryTF = true; }
    else if(fieldDbName === "contracts_manager_user_id") {                      fieldIsContractsManagerUserIDTF = true;                 detailsCardMandatoryTF = true; }
    else if(fieldDbName === "budget_manager_user_id") {                         fieldIsBudgetManagerUserIDTF = true;                    detailsCardMandatoryTF = true; }
    else if(fieldDbName === "description_of_work") {                            fieldIsDescriptionOfWorkTF = true;                      detailsCardMandatoryTF = true; }
    else if(fieldDbName === "solicitation_num") {                               fieldIsSolicitationNumTF = true;                        detailsCardMandatoryTF = true; }
    else if(fieldDbName === "naics_code_ids_comma") {                           fieldIsNaicsCodeIDsCommaTF = true;                      detailsCardMandatoryTF = true; }
    else if(fieldDbName === "competition_type_bm_set_aside_ids_comma") {        fieldIsCompetitionTypeBmSetAsideIDsCommaTF = true;      detailsCardMandatoryTF = true; }
    else if(fieldDbName === "capture_favorites_upe_x") {                        fieldIsCaptureFavoritesUpeXTF = true;                   detailsCardMandatoryTF = true; }
    else if(fieldDbName === "capture_priority_level_id") {                      fieldIsCapturePriorityLevelIDTF = true;                 detailsCardMandatoryTF = true; }
    else if(fieldDbName === "added_date") {                                     fieldIsAddedDateTF = true;                              detailsCardMandatoryTF = true;  isUneditableFieldTF = true; }
    else if(fieldDbName === "rfp_date") {                                       fieldIsRfpDateTF = true;                                detailsCardMandatoryTF = true; }
    else if(fieldDbName === "proposal_due_datetime_utc") {                      fieldIsProposalDueDatetimeUtcTF = true;                 detailsCardMandatoryTF = true; }
    else if(fieldDbName === "award_date") {                                     fieldIsAwardDateTF = true;                              detailsCardMandatoryTF = true; }
    else if(fieldDbName === "contract_start_date") {                            fieldIsContractStartDateTF = true;                      detailsCardMandatoryTF = true; }
    else if(fieldDbName === "contract_end_date") {                              fieldIsContractEndDateTF = true;                        detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "archive_date") {                                   fieldIsArchiveDateTF = true;                            detailsCardMandatoryTF = true;  isUneditableFieldTF = true; }
    else if(fieldDbName === "last_changed_date") {                              fieldIsLastChangedDateTF = true;                        detailsCardMandatoryTF = true;  isUneditableFieldTF = true; }
    else if(fieldDbName === "last_stage_date") {                                fieldIsLastStageDateTF = true;                          detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "last_progress_date") {                             fieldIsLastProgressDateTF = true;                       detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "last_pwin_date") {                                 fieldIsLastPwinDateTF = true;                           detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "last_rfp_date") {                                  fieldIsLastRfpDateTF = true;                            detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "reasons_won_lost_ids_colon_percent_comma") {       fieldIsReasonsWonLostIDsColonPercentCommaTF = true;     advanceStageCardTF = true; }
    else if(fieldDbName === "contract_revenue_value") {                         fieldIsContractRevenueValueTF = true;                   detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "allocated_revenue_value") {                        fieldIsAllocatedRevenueValueTF = true;                  detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "allocated_net_value") {                            fieldIsAllocatedNetValueTF = true;                      detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "allocated_revenue_per_month") {                    fieldIsAllocatedRevenuePerMonthTF = true;               detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "allocated_net_per_month") {                        fieldIsAllocatedNetPerMonthTF = true;                   detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "pwin_adjusted_contract_overall_value") {           fieldIsPwinAdjustedContractOverallValueTF = true;       detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "pwin_adjusted_contract_revenue_value") {           fieldIsPwinAdjustedContractRevenueValueTF = true;       detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "pwin_adjusted_allocated_revenue_value") {          fieldIsPwinAdjustedAllocatedRevenueValueTF = true;      detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "pwin_adjusted_allocated_net_value") {              fieldIsPwinAdjustedAllocatedNetValueTF = true;          detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "idiq_to_anticipated_value") {                      fieldIsIdiqToAnticipatedValueTF = true;                 detailsCardMandatoryTF = true; }
    else if(fieldDbName === "shaping_total_progress") {                         fieldIsShapingTotalProgressTF = true;                   dealShapingCardTF = true;       isComputedFieldTF = true; }
    else if(fieldDbName === "shaping_stage_progress") {                         fieldIsShapingStageProgressTF = true;                   dealShapingCardTF = true;       isComputedFieldTF = true; }
    else if(fieldDbName === "pwin") {                                           fieldIsPwinTF = true;                                   detailsCardMandatoryTF = true; }
    else if(fieldDbName === "our_prime_sub_teammate_division_id") {             fieldIsOurPrimeSubTeammateDivisionIDTF = true;          teammatesCardTF = true; }
    else if(fieldDbName === "our_prime_sub_teammate_allocation") {              fieldIsOurPrimeSubTeammateAllocationTF = true;          teammatesCardTF = true; }
    else if(fieldDbName === "prime_contact_company_id") {                       fieldIsPrimeContactCompanyIDTF = true;                  teammatesCardTF = true;         isUneditableFieldTF = true; }
    else if(fieldDbName === "req_sb_allocation") {                              fieldIsReqSbAllocationTF = true;                        teammatesCardTF = true; }
    else if(fieldDbName === "num_teammates") {                                  fieldIsNumTeammatesTF = true;                           teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "num_lb_teammates") {                               fieldIsNumLbTeammatesTF = true;                         teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "num_sb_teammates") {                               fieldIsNumSbTeammatesTF = true;                         teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "total_team_allocation") {                          fieldIsTotalTeamAllocationTF = true;                    teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "total_lb_allocation") {                            fieldIsTotalLbAllocationTF = true;                      teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "total_sb_allocation") {                            fieldIsTotalSbAllocationTF = true;                      teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "total_team_allocation_cov") {                      fieldIsTotalTeamAllocationCovTF = true;                 teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "total_lb_allocation_cov") {                        fieldIsTotalLbAllocationCovTF = true;                   teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "total_sb_allocation_cov") {                        fieldIsTotalSbAllocationCovTF = true;                   teammatesCardTF = true;         isComputedFieldTF = true; }
    else if(fieldDbName === "our_incumbent_competitor_division_id") {           fieldIsOurIncumbentCompetitorDivisionIDTF = true;       competitorsCardTF = true; }
    else if(fieldDbName === "incumbent_contact_company_ids_comma") {            fieldIsIncumbentContactCompanyIDsCommaTF = true;        competitorsCardTF = true; }
    else if(fieldDbName === "notepad") {                                        fieldIsNotepadTF = true;                                notepadCardTF = true; }
    else if(fieldDbName === "recent_pinned_notestamp") {                        fieldIsRecentPinnedNotestampTF = true;                  notepadCardTF = true;           isComputedFieldTF = true; }
    else if(fieldDbName === "next_active_task") {                               fieldIsNextActiveTaskTF = true;                         detailsCardMandatoryTF = true;  isComputedFieldTF = true; }
    else if(fieldDbName === "gcss_overwrite_data_with_updates_01") {            fieldIsGcssOverwriteDataWithUpdates01TF = true;         detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_id") {                                        fieldIsGcssIDTF = true;                                 detailsCardGcssTF = true;       isUneditableFieldTF = true; }
    else if(fieldDbName === "gcss_govwin_id_string") {                          fieldIsGcssGovwinIDStringTF = true;                     detailsCardGcssTF = true;       isUneditableFieldTF = true; }
    else if(fieldDbName === "gcss_fedcomp_id_string") {                         fieldIsGcssFedcompIDStringTF = true;                    detailsCardGcssTF = true;       isUneditableFieldTF = true; }
    else if(fieldDbName === "gcss_original_published_datetime_utc") {           fieldIsGcssOriginalPublishedDatetimeUtcTF = true;       detailsCardGcssTF = true;       isUneditableFieldTF = true; }
    else if(fieldDbName === "gcss_last_updated_datetime_utc") {                 fieldIsGcssLastUpdatedDatetimeUtcTF = true;             detailsCardGcssTF = true;       isUneditableFieldTF = true; }
    else if(fieldDbName === "gcss_sam_link") {                                  fieldIsGcssSamLinkTF = true;                            detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_govwin_link") {                               fieldIsGcssGovwinLinkTF = true;                         detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_fedcomp_link") {                              fieldIsGcssFedcompLinkTF = true;                        detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_bm_department_agency_id") {                   fieldIsGcssBmDepartmentAgencyIDTF = true;               detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_bm_sub_tier_id") {                            fieldIsGcssBmSubTierIDTF = true;                        detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_office") {                                    fieldIsGcssOfficeTF = true;                             detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_bm_product_service_code_ids_comma") {         fieldIsGcssBmProductServiceCodeIDsCommaTF = true;       detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_bm_contract_opportunity_type_id") {           fieldIsGcssBmContractOpportunityTypeIDTF = true;        detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_govwin_procurement_comment") {                fieldIsGcssGovwinProcurementCommentTF = true;           detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_govwin_duration") {                           fieldIsGcssGovwinDurationTF = true;                     detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_govwin_primary_work_required") {              fieldIsGcssGovwinPrimaryWorkRequiredTF = true;          detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_govwin_priority_1to5") {                      fieldIsGcssGovwinPriority1to5TF = true;                 detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_bm_fedcomp_contract_type_id") {               fieldIsGcssBmFedcompContractTypeIDTF = true;            detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_award_number") {                              fieldIsGcssAwardNumberTF = true;                        detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_place_of_performance_address") {              fieldIsGcssPlaceOfPerformanceAddressTF = true;          detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_place_of_performance_city") {                 fieldIsGcssPlaceOfPerformanceCityTF = true;             detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_place_of_performance_bm_state_ids_comma") {   fieldIsGcssPlaceOfPerformanceBmStateIDsCommaTF = true;  detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_place_of_performance_zip") {                  fieldIsGcssPlaceOfPerformanceZipTF = true;              detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_place_of_performance_province") {             fieldIsGcssPlaceOfPerformanceProvinceTF = true;         detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_place_of_performance_bm_country_ids_comma") { fieldIsGcssPlaceOfPerformanceBmCountryIDsCommaTF = true;detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_contracting_office_city") {                   fieldIsGcssContractingOfficeCityTF = true;              detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_contracting_office_bm_state_id") {            fieldIsGcssContractingOfficeBmStateIDTF = true;         detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_contracting_office_zip") {                    fieldIsGcssContractingOfficeZipTF = true;               detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_contracting_office_province") {               fieldIsGcssContractingOfficeProvinceTF = true;          detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_contracting_office_bm_country_id") {          fieldIsGcssContractingOfficeBmCountryIDTF = true;       detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_contracting_office_poc1_name_email_phone") {  fieldIsGcssContractingOfficePoc1NameEmailPhoneTF = true;detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_contracting_office_poc2_name_email_phone") {  fieldIsGcssContractingOfficePoc2NameEmailPhoneTF = true;detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_awardee_information") {                       fieldIsGcssAwardeeInformationTF = true;                 detailsCardGcssTF = true; }
    else if(fieldDbName === "gcss_document_links_json_string") {                fieldIsGcssDocumentLinksJsonStringTF = true;            detailsCardGcssTF = true;       isUneditableFieldTF = true; }

    expandedCaptureFieldMap.set("fieldIsCaptureIDTF", fieldIsCaptureIDTF);
    expandedCaptureFieldMap.set("fieldIsOpportunityNameTF", fieldIsOpportunityNameTF);
    expandedCaptureFieldMap.set("fieldIsCodenameTF", fieldIsCodenameTF);
    expandedCaptureFieldMap.set("fieldIsCaptureManagersIDsColonPercentCommaTF", fieldIsCaptureManagersIDsColonPercentCommaTF);
    expandedCaptureFieldMap.set("fieldIsDivisionOwnersIDsColonPercentCommaTF", fieldIsDivisionOwnersIDsColonPercentCommaTF);
    expandedCaptureFieldMap.set("fieldIsCaptureTypeIDTF", fieldIsCaptureTypeIDTF);
    expandedCaptureFieldMap.set("fieldIsTblACaptureTypesPrimeSubTF", fieldIsTblACaptureTypesPrimeSubTF);
    expandedCaptureFieldMap.set("fieldIsStageIDTF", fieldIsStageIDTF);
    expandedCaptureFieldMap.set("fieldIsContractTypeIDTF", fieldIsContractTypeIDTF);
    expandedCaptureFieldMap.set("fieldIsIdiqCaptureIDTOLinkTF", fieldIsIdiqCaptureIDTOLinkTF);
    expandedCaptureFieldMap.set("fieldIsContractOverallValueTF", fieldIsContractOverallValueTF);
    expandedCaptureFieldMap.set("fieldIsPeriodOfPerformanceTF", fieldIsPeriodOfPerformanceTF);
    expandedCaptureFieldMap.set("fieldIsContractsManagerUserIDTF", fieldIsContractsManagerUserIDTF);
    expandedCaptureFieldMap.set("fieldIsBudgetManagerUserIDTF", fieldIsBudgetManagerUserIDTF);
    expandedCaptureFieldMap.set("fieldIsDescriptionOfWorkTF", fieldIsDescriptionOfWorkTF);
    expandedCaptureFieldMap.set("fieldIsSolicitationNumTF", fieldIsSolicitationNumTF);
    expandedCaptureFieldMap.set("fieldIsNaicsCodeIDsCommaTF", fieldIsNaicsCodeIDsCommaTF);
    expandedCaptureFieldMap.set("fieldIsCompetitionTypeBmSetAsideIDsCommaTF", fieldIsCompetitionTypeBmSetAsideIDsCommaTF);
    expandedCaptureFieldMap.set("fieldIsCaptureFavoritesUpeXTF", fieldIsCaptureFavoritesUpeXTF);
    expandedCaptureFieldMap.set("fieldIsCapturePriorityLevelIDTF", fieldIsCapturePriorityLevelIDTF);
    expandedCaptureFieldMap.set("fieldIsAddedDateTF", fieldIsAddedDateTF);
    expandedCaptureFieldMap.set("fieldIsRfpDateTF", fieldIsRfpDateTF);
    expandedCaptureFieldMap.set("fieldIsProposalDueDatetimeUtcTF", fieldIsProposalDueDatetimeUtcTF);
    expandedCaptureFieldMap.set("fieldIsAwardDateTF", fieldIsAwardDateTF);
    expandedCaptureFieldMap.set("fieldIsContractStartDateTF", fieldIsContractStartDateTF);
    expandedCaptureFieldMap.set("fieldIsContractEndDateTF", fieldIsContractEndDateTF);
    expandedCaptureFieldMap.set("fieldIsArchiveDateTF", fieldIsArchiveDateTF);
    expandedCaptureFieldMap.set("fieldIsLastChangedDateTF", fieldIsLastChangedDateTF);
    expandedCaptureFieldMap.set("fieldIsLastStageDateTF", fieldIsLastStageDateTF);
    expandedCaptureFieldMap.set("fieldIsLastProgressDateTF", fieldIsLastProgressDateTF);
    expandedCaptureFieldMap.set("fieldIsLastPwinDateTF", fieldIsLastPwinDateTF);
    expandedCaptureFieldMap.set("fieldIsLastRfpDateTF", fieldIsLastRfpDateTF);
    expandedCaptureFieldMap.set("fieldIsReasonsWonLostIDsColonPercentCommaTF", fieldIsReasonsWonLostIDsColonPercentCommaTF);
    expandedCaptureFieldMap.set("fieldIsContractRevenueValueTF", fieldIsContractRevenueValueTF);
    expandedCaptureFieldMap.set("fieldIsAllocatedRevenueValueTF", fieldIsAllocatedRevenueValueTF);
    expandedCaptureFieldMap.set("fieldIsAllocatedNetValueTF", fieldIsAllocatedNetValueTF);
    expandedCaptureFieldMap.set("fieldIsAllocatedRevenuePerMonthTF", fieldIsAllocatedRevenuePerMonthTF);
    expandedCaptureFieldMap.set("fieldIsAllocatedNetPerMonthTF", fieldIsAllocatedNetPerMonthTF);
    expandedCaptureFieldMap.set("fieldIsPwinAdjustedContractOverallValueTF", fieldIsPwinAdjustedContractOverallValueTF);
    expandedCaptureFieldMap.set("fieldIsPwinAdjustedContractRevenueValueTF", fieldIsPwinAdjustedContractRevenueValueTF);
    expandedCaptureFieldMap.set("fieldIsPwinAdjustedAllocatedRevenueValueTF", fieldIsPwinAdjustedAllocatedRevenueValueTF);
    expandedCaptureFieldMap.set("fieldIsPwinAdjustedAllocatedNetValueTF", fieldIsPwinAdjustedAllocatedNetValueTF);
    expandedCaptureFieldMap.set("fieldIsIdiqToAnticipatedValueTF", fieldIsIdiqToAnticipatedValueTF);
    expandedCaptureFieldMap.set("fieldIsShapingTotalProgressTF", fieldIsShapingTotalProgressTF);
    expandedCaptureFieldMap.set("fieldIsShapingStageProgressTF", fieldIsShapingStageProgressTF);
    expandedCaptureFieldMap.set("fieldIsPwinTF", fieldIsPwinTF);
    expandedCaptureFieldMap.set("fieldIsOurPrimeSubTeammateDivisionIDTF", fieldIsOurPrimeSubTeammateDivisionIDTF);
    expandedCaptureFieldMap.set("fieldIsOurPrimeSubTeammateAllocationTF", fieldIsOurPrimeSubTeammateAllocationTF);
    expandedCaptureFieldMap.set("fieldIsPrimeContactCompanyIDTF", fieldIsPrimeContactCompanyIDTF);
    expandedCaptureFieldMap.set("fieldIsReqSbAllocationTF", fieldIsReqSbAllocationTF);
    expandedCaptureFieldMap.set("fieldIsNumTeammatesTF", fieldIsNumTeammatesTF);
    expandedCaptureFieldMap.set("fieldIsNumLbTeammatesTF", fieldIsNumLbTeammatesTF);
    expandedCaptureFieldMap.set("fieldIsNumSbTeammatesTF", fieldIsNumSbTeammatesTF);
    expandedCaptureFieldMap.set("fieldIsTotalTeamAllocationTF", fieldIsTotalTeamAllocationTF);
    expandedCaptureFieldMap.set("fieldIsTotalLbAllocationTF", fieldIsTotalLbAllocationTF);
    expandedCaptureFieldMap.set("fieldIsTotalSbAllocationTF", fieldIsTotalSbAllocationTF);
    expandedCaptureFieldMap.set("fieldIsTotalTeamAllocationCovTF", fieldIsTotalTeamAllocationCovTF);
    expandedCaptureFieldMap.set("fieldIsTotalLbAllocationCovTF", fieldIsTotalLbAllocationCovTF);
    expandedCaptureFieldMap.set("fieldIsTotalSbAllocationCovTF", fieldIsTotalSbAllocationCovTF);
    expandedCaptureFieldMap.set("fieldIsOurIncumbentCompetitorDivisionIDTF", fieldIsOurIncumbentCompetitorDivisionIDTF);
    expandedCaptureFieldMap.set("fieldIsIncumbentContactCompanyIDsCommaTF", fieldIsIncumbentContactCompanyIDsCommaTF);
    expandedCaptureFieldMap.set("fieldIsNotepadTF", fieldIsNotepadTF);
    expandedCaptureFieldMap.set("fieldIsRecentPinnedNotestampTF", fieldIsRecentPinnedNotestampTF);
    expandedCaptureFieldMap.set("fieldIsNextActiveTaskTF", fieldIsNextActiveTaskTF);
    expandedCaptureFieldMap.set("fieldIsGcssOverwriteDataWithUpdates01TF", fieldIsGcssOverwriteDataWithUpdates01TF);
    expandedCaptureFieldMap.set("fieldIsGcssIDTF", fieldIsGcssIDTF);
    expandedCaptureFieldMap.set("fieldIsGcssGovwinIDStringTF", fieldIsGcssGovwinIDStringTF);
    expandedCaptureFieldMap.set("fieldIsGcssFedcompIDStringTF", fieldIsGcssFedcompIDStringTF);
    expandedCaptureFieldMap.set("fieldIsGcssOriginalPublishedDatetimeUtcTF", fieldIsGcssOriginalPublishedDatetimeUtcTF);
    expandedCaptureFieldMap.set("fieldIsGcssLastUpdatedDatetimeUtcTF", fieldIsGcssLastUpdatedDatetimeUtcTF);
    expandedCaptureFieldMap.set("fieldIsGcssSamLinkTF", fieldIsGcssSamLinkTF);
    expandedCaptureFieldMap.set("fieldIsGcssGovwinLinkTF", fieldIsGcssGovwinLinkTF);
    expandedCaptureFieldMap.set("fieldIsGcssFedcompLinkTF", fieldIsGcssFedcompLinkTF);
    expandedCaptureFieldMap.set("fieldIsGcssBmDepartmentAgencyIDTF", fieldIsGcssBmDepartmentAgencyIDTF);
    expandedCaptureFieldMap.set("fieldIsGcssBmSubTierIDTF", fieldIsGcssBmSubTierIDTF);
    expandedCaptureFieldMap.set("fieldIsGcssOfficeTF", fieldIsGcssOfficeTF);
    expandedCaptureFieldMap.set("fieldIsGcssBmProductServiceCodeIDsCommaTF", fieldIsGcssBmProductServiceCodeIDsCommaTF);
    expandedCaptureFieldMap.set("fieldIsGcssBmContractOpportunityTypeIDTF", fieldIsGcssBmContractOpportunityTypeIDTF);
    expandedCaptureFieldMap.set("fieldIsGcssGovwinProcurementCommentTF", fieldIsGcssGovwinProcurementCommentTF);
    expandedCaptureFieldMap.set("fieldIsGcssGovwinDurationTF", fieldIsGcssGovwinDurationTF);
    expandedCaptureFieldMap.set("fieldIsGcssGovwinPrimaryWorkRequiredTF", fieldIsGcssGovwinPrimaryWorkRequiredTF);
    expandedCaptureFieldMap.set("fieldIsGcssGovwinPriority1to5TF", fieldIsGcssGovwinPriority1to5TF);
    expandedCaptureFieldMap.set("fieldIsGcssBmFedcompContractTypeIDTF", fieldIsGcssBmFedcompContractTypeIDTF);
    expandedCaptureFieldMap.set("fieldIsGcssAwardNumberTF", fieldIsGcssAwardNumberTF);
    expandedCaptureFieldMap.set("fieldIsGcssPlaceOfPerformanceAddressTF", fieldIsGcssPlaceOfPerformanceAddressTF);
    expandedCaptureFieldMap.set("fieldIsGcssPlaceOfPerformanceCityTF", fieldIsGcssPlaceOfPerformanceCityTF);
    expandedCaptureFieldMap.set("fieldIsGcssPlaceOfPerformanceBmStateIDsCommaTF", fieldIsGcssPlaceOfPerformanceBmStateIDsCommaTF);
    expandedCaptureFieldMap.set("fieldIsGcssPlaceOfPerformanceZipTF", fieldIsGcssPlaceOfPerformanceZipTF);
    expandedCaptureFieldMap.set("fieldIsGcssPlaceOfPerformanceProvinceTF", fieldIsGcssPlaceOfPerformanceProvinceTF);
    expandedCaptureFieldMap.set("fieldIsGcssPlaceOfPerformanceBmCountryIDsCommaTF", fieldIsGcssPlaceOfPerformanceBmCountryIDsCommaTF);
    expandedCaptureFieldMap.set("fieldIsGcssContractingOfficeCityTF", fieldIsGcssContractingOfficeCityTF);
    expandedCaptureFieldMap.set("fieldIsGcssContractingOfficeBmStateIDTF", fieldIsGcssContractingOfficeBmStateIDTF);
    expandedCaptureFieldMap.set("fieldIsGcssContractingOfficeZipTF", fieldIsGcssContractingOfficeZipTF);
    expandedCaptureFieldMap.set("fieldIsGcssContractingOfficeProvinceTF", fieldIsGcssContractingOfficeProvinceTF);
    expandedCaptureFieldMap.set("fieldIsGcssContractingOfficeBmCountryIDTF", fieldIsGcssContractingOfficeBmCountryIDTF);
    expandedCaptureFieldMap.set("fieldIsGcssContractingOfficePoc1NameEmailPhoneTF", fieldIsGcssContractingOfficePoc1NameEmailPhoneTF);
    expandedCaptureFieldMap.set("fieldIsGcssContractingOfficePoc2NameEmailPhoneTF", fieldIsGcssContractingOfficePoc2NameEmailPhoneTF);
    expandedCaptureFieldMap.set("fieldIsGcssAwardeeInformationTF", fieldIsGcssAwardeeInformationTF);
    expandedCaptureFieldMap.set("fieldIsGcssDocumentLinksJsonStringTF", fieldIsGcssDocumentLinksJsonStringTF);

    //admin details/dates fields left side pool tables, separation of fields into different tables with different abilities to edit/remove
    expandedCaptureFieldMap.set("detailsCardMandatoryTF", detailsCardMandatoryTF);
    expandedCaptureFieldMap.set("detailsCardGcssTF", detailsCardGcssTF);
    expandedCaptureFieldMap.set("advanceStageCardTF", advanceStageCardTF);
    expandedCaptureFieldMap.set("dealShapingCardTF", dealShapingCardTF);
    expandedCaptureFieldMap.set("teammatesCardTF", teammatesCardTF);
    expandedCaptureFieldMap.set("competitorsCardTF", competitorsCardTF);
    expandedCaptureFieldMap.set("notepadCardTF", notepadCardTF);

    const detailsCardCustomTF = (!detailsCardMandatoryTF && !detailsCardGcssTF && !advanceStageCardTF && !dealShapingCardTF && !teammatesCardTF && !competitorsCardTF && !notepadCardTF);
    expandedCaptureFieldMap.set("detailsCardCustomTF", detailsCardCustomTF); //admin created custom capture fields are the only fields that can be deleted from the pool of fields

    //determine which changelog different fields will use
    var changelogDetailsTF = false;
    var changelogStageTF = false;
    var changelogProgressTF = false;
    var changelogPwinTF = false;
    if(fieldIsStageIDTF) { changelogStageTF = true; }
    else if(fieldIsShapingTotalProgressTF || fieldIsShapingStageProgressTF) { changelogProgressTF = true; }
    else if(fieldIsPwinTF) { changelogPwinTF = true; }
    else { changelogDetailsTF = true; }
    expandedCaptureFieldMap.set("changelogDetailsTF", changelogDetailsTF);
    expandedCaptureFieldMap.set("changelogStageTF", changelogStageTF);
    expandedCaptureFieldMap.set("changelogProgressTF", changelogProgressTF);
    expandedCaptureFieldMap.set("changelogPwinTF", changelogPwinTF);

    //initialize values set in the expanded capture field map
    var selectWithSearchDataObj = undefined;
    var selectTblNameIsBITMasterNaicsCodesTF = false;

    //initialize flags for input_type from tbl_captures_fields (determine if this field is any of the select/multiselect/sharedpercent types, separate them from their db_name)
    var fieldInputType = undefined;
    var fieldRequiresSwsDataObjTF = false;
    var isSharedPercentTF = false;
    var isMultiSelectTF = false;
    var isAddEntryTF = false;
    var usesOptionsHeightTF = false; //vertical switch option height calculated from textarea_details_max_lines column
    if(tblCapturesFieldsInputType === "select") {                     fieldInputType = "select";                fieldRequiresSwsDataObjTF = true; }
    else if(tblCapturesFieldsInputType === "selectmulti") {           fieldInputType = "select";                fieldRequiresSwsDataObjTF = true; isMultiSelectTF = true; }
    else if(tblCapturesFieldsInputType === "selectadd") {             fieldInputType = "select";                fieldRequiresSwsDataObjTF = true; isAddEntryTF = true; }
    else if(tblCapturesFieldsInputType === "selectmultiadd") {        fieldInputType = "select";                fieldRequiresSwsDataObjTF = true; isMultiSelectTF = true; isAddEntryTF = true; }
    else if(tblCapturesFieldsInputType === "sharedpercent") {         fieldInputType = "sharedpercent";         fieldRequiresSwsDataObjTF = true; isSharedPercentTF = true; isMultiSelectTF = true; } //all sharedpercent add new item components are always multiselects, its selectWithSearchDataObj refers to the field that will be added to the sharedpercent item
    else if(tblCapturesFieldsInputType === "sharedpercentadd") {      fieldInputType = "sharedpercent";         fieldRequiresSwsDataObjTF = true; isSharedPercentTF = true; isMultiSelectTF = true; isAddEntryTF = true; }
    else if(tblCapturesFieldsInputType === "verticalswitch") {        fieldInputType = "verticalswitch";        fieldRequiresSwsDataObjTF = true; }
    else if(tblCapturesFieldsInputType === "captureprioritylevel") {  fieldInputType = "captureprioritylevel";  fieldRequiresSwsDataObjTF = true; usesOptionsHeightTF = true; }
    else {                                                            fieldInputType = tblCapturesFieldsInputType; } //all other field types that are not select/sharedpercent, create_field_type_obj() uses the direct fieldInputType strings to determine the true/false flags

    //if this field was one of the select/sharedpercent field types that requires a selectWithSearchDataObj to be constructed and added to the fieldTypeObj
    if(fieldRequiresSwsDataObjTF) {
      var valueDisplayArraysObj = undefined;
      var unselectedDisplay = undefined; //default unselectedDisplay using itemName when undefined
      var hasSearchTF = true; //give every capture field select a search bar when edited
      var hasClearSelectionTF = true; //give every capture field select the clear selection button when edited
      var multiSelectHasAllSwitchTF = false;
      var selectAddTblName = undefined; //select add can only be applied to custom select fields (the fieldDbName will be "name" or "sort", referencing tbl_cap_[fieldDbName])
      var selectAddNameFieldDbName = undefined; //select add dbName of the column the new item name is applied to (usually "name")
      var selectAddSortFieldDbName = undefined; //select add dbName of the sort column for this tbl (undefined if there is no sort column)
      var selectContactCompanyTF = false;
      var selectContactPersonTF = false;
      var selectCaptureTF = false;
      var sharedPercentCaptureManagersTF = false;
      var optionsHeightEm = undefined;
      var includeColorBlockInValueMaskTF = undefined;

      if(tblCapturesFieldsSelectTblName === "tbl_bit_master_departments_agencies") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterDepartmentsAgencies;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_bit_master_sub_tiers") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterSubTiers;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_bit_master_naics_codes") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterNaicsCodesCombinedNames;
        selectTblNameIsBITMasterNaicsCodesTF = true;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_bit_master_set_asides") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterSetAsides;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_bit_master_contract_opportunity_types") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterContractOpportunityTypes;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_bit_master_fedcomp_contract_types") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterFedCompContractTypeNames;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_bit_master_product_service_codes") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterProductServiceCodes;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_bit_master_states") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterStatesA2AndName;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_bit_master_countries") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjBitMasterCountriesA3AndName;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_users") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjUsers;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_users_contracts") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjUsersWithContractsPower;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_users_budget") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjUsersWithBudgetPower;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_divisions") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjDivisions;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_capture_priority_levels") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjCaptureFlagPriorities;
        includeColorBlockInValueMaskTF = true;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_capture_types") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjCaptureTypes;
        hasClearSelectionTF = false; //hasClearSelectionTF is disabled for capture type to ensure you can't have no type with no cards on the screen where you couldn't change it back
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_capture_types_prime_sub") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjPrimeSub;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_contract_types") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjContractTypes;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_reasons_won_lost") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjReasonsWonLost;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_reasons_won") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjReasonsWonOrBoth;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_reasons_lost") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjReasonsLostOrBoth;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_a_stages_pool") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjStages;
        hasClearSelectionTF = false;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_captures") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjCaptures;
        selectCaptureTF = true;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_captures_idiq") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjIdiqCaptures;
        selectCaptureTF = true;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_g_contacts_companies") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjContactCompanies;
        selectContactCompanyTF = true;
      }
      else if(tblCapturesFieldsSelectTblName === "tbl_g_contacts_persons") {
        valueDisplayArraysObj = this.c_valueDisplayArraysObjContactPersons;
        selectContactPersonTF = true;
      }
      else if(tblCapturesFieldsSelectTblName === "gcss_overwrite_data_with_updates_01") { //hardcoded verticalswitch options for GCSS overwrite
        const gcssOverwriteValueArray = [1, 0];
        const gcssOverwrite1OptionText = "[default] Overwrite " + c_productStylingObj.productName + " data automatically with changes from the Data Source";
        const gcssOverwrite0OptionText = "Do not update " + c_productStylingObj.productName + " data (still sends notification/email listing new Government values)";
        const gcssOverwriteDisplayArray = [gcssOverwrite1OptionText, gcssOverwrite0OptionText];
        valueDisplayArraysObj = this.create_value_display_arrays_obj_from_value_array_and_display_array("GCSS Overwrite Setting", gcssOverwriteValueArray, false, gcssOverwriteDisplayArray);
      }
      else { //find the matching select arrays within c_allTblCapsMapOfComputedTblCapObjs using the db_name as the Map key
        const computedTblCapObj = this.c_allTblCapsMapOfComputedTblCapObjs.get(tblCapturesFieldsSelectTblName);
        if(computedTblCapObj !== undefined) { //if the dbName existed in the tbl_cap_ Map
          valueDisplayArraysObj = JSFUNC.copy_obj(computedTblCapObj.valueDisplayArraysObj);
          valueDisplayArraysObj.itemName = fieldDisplayName; //overwrite the valueDisplayArraysObj temp itemName with the field display_name
          if(isAddEntryTF) { //for fields in tbl_captures_fields, creating a new field only allows add entry select types to be associated with tbl_cap_ tbls (other system tbls like tbl_a_delete_capture_reasons are created as separate fieldTypeObjs to use in the system with their own selectAddTblName specifications)
            selectAddTblName = tblCapturesFieldsSelectTblName;
            selectAddNameFieldDbName = "name";
            selectAddSortFieldDbName = ((computedTblCapObj.tblCapHasSortColumnTF) ? ("sort") : (undefined));
          }
        }
      }

      //fieldInputType did not match any of the hardcoded or tbl_cap_ input types, return a blank value/display arrays obj
      if(valueDisplayArraysObj === undefined) { //invalid item name shows the db lowercase underscores name of the selectTblName
        valueDisplayArraysObj = this.create_value_display_arrays_obj_from_value_array_and_display_array(tblCapturesFieldsSelectTblName, [], false, []);
      }

      //determine select/selectmulti/sharedpercent/contactselect "unselected display" to show, 1. as valueMask when the select is not filled out, 2. inside the select box when editing a select when its not filled out
      if(valueDisplayArraysObj.valueArray === undefined) { //empty value/display array from a select field dbName that did not match any of the choices
        unselectedDisplay = "--Invalid Select (selectTblName: " + tblCapturesFieldsSelectTblName + ")--";
      }
      else if(fieldIsCaptureManagersIDsColonPercentCommaTF || fieldIsDivisionOwnersIDsColonPercentCommaTF) {
        unselectedDisplay = "--Unassigned--"; //for capture managers and division owners selection on capture details card, show --Unassigned-- when no user/division is selected
      }

      //determine if the field is a sharedPercent of Capture Managers (which puts the Shortcut Preset for Capture Managers below the shared percent)
      sharedPercentCaptureManagersTF = (isSharedPercentTF && fieldIsCaptureManagersIDsColonPercentCommaTF);

      //determine the optionsHeightEm from the textarea_details_max_lines value for a verticalswitch type
      if(usesOptionsHeightTF && JSFUNC.is_number_not_nan_gt_0(fieldTextareaDetailsMaxLines)) {
        optionsHeightEm = (fieldTextareaDetailsMaxLines * 1.5);
      }

      const swsOptionsObj = {
        unselectedDisplay: unselectedDisplay,
        isMultiSelectTF: isMultiSelectTF,
        hasSearchTF: hasSearchTF,
        hasClearSelectionTF: hasClearSelectionTF,
        multiSelectHasAllSwitchTF: multiSelectHasAllSwitchTF,
        selectAddTblName: selectAddTblName,
        selectAddNameFieldDbName: selectAddNameFieldDbName,
        selectAddSortFieldDbName: selectAddSortFieldDbName,
        selectContactCompanyTF: selectContactCompanyTF,
        selectContactPersonTF: selectContactPersonTF,
        selectCaptureTF: selectCaptureTF,
        sharedPercentCaptureManagersTF: sharedPercentCaptureManagersTF,
        optionsHeightEm: optionsHeightEm,
        includeColorBlockInValueMaskTF: includeColorBlockInValueMaskTF
      };

      //use the valueDisplayArraysObj to create the selectWithSearchDataObj
      selectWithSearchDataObj = this.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObj, swsOptionsObj);
    }


    //the fieldTypeObj - a large collection of true/false flags (avoids many string comparisons to fieldInputType) that can be created here for each capture field, while also being the generic solution for all fields outside of the tbl_captures/tbl_captures_fields combination
    //  1. tell the php database what this field's idsb value, column data type, and new blank value would be
    //  2. tells the masking function how to display a given raw value (valueMask, sort, isFilledOutTF)
    //  3. tells the edit components how to edit and/or mask the raw value (edit/save/cancel full interface, or just the edit component for a new form)
    const fieldTypeObj = this.create_field_type_obj(fieldInputType, selectWithSearchDataObj);
    expandedCaptureFieldMap.set("fieldTypeObj", fieldTypeObj);


    //set flags for identifying some specific select tblNames (used in specific codeword modifiers for specific fields like naics)
    expandedCaptureFieldMap.set("selectTblNameIsBITMasterNaicsCodesTF", selectTblNameIsBITMasterNaicsCodesTF);


    //if a particular capture field needs to override the default "blankValue" that is defined for its fieldTypeObj (so that when a new capture is created or imported, this value will be used instead)
    var fieldBlankValue = fieldTypeObj.blankValue;
    if(fieldIsOurPrimeSubTeammateAllocationTF) {
      fieldBlankValue = 100; //100% is the initialized value for our allocation instead of the standard 0 for a decimal field (all other teammate allocations [req/sb/lb] are 0 which is the default for decimal/int field types)
    }
    else if(tblCapturesFieldsSelectTblName === "gcss_overwrite_data_with_updates_01") {
      fieldBlankValue = 1; //blankValue for a verticalswitch type (or a select) is a 0, set this as a 1 for this GCSS Overwrite field (which could then be overwritten by an Admin for a field on a Capture Type Details Group)
    }
    expandedCaptureFieldMap.set("fieldBlankValue", fieldBlankValue);


    //add onto the created fieldTypeObj 'inputTypeMask' for select types to specify what items are in the select using itemName (used for Input Type column display in admin 7. Details/Dates Fields pool)
    var fieldTypeMask = fieldTypeObj.inputTypeMask;
    if(isAddEntryTF) { fieldTypeMask += " (with add)"; }
    if((selectWithSearchDataObj !== undefined) && (fieldInputType !== "captureprioritylevel")) { fieldTypeMask += " [" + selectWithSearchDataObj.itemName + "]"; }
    expandedCaptureFieldMap.set("fieldTypeMask", fieldTypeMask);


    //precalculation for tbl_captures_fields columns after input_type
    const hoverTextIsFilledOutTF = JSFUNC.string_is_filled_out_tf(hoverText);
    expandedCaptureFieldMap.set("hoverTextIsFilledOutTF", hoverTextIsFilledOutTF);

    const displayNamePresetStylingObj = this.get_preset_styling_obj_from_styling_string_comma_list_or_preset_colon_id_string(displayNameStylingStringComma);
    expandedCaptureFieldMap.set("displayNamePresetStylingObj", displayNamePresetStylingObj);


    //true/false flags (avoids many string comparisons to fieldInputType) created only for these capture fields that informs the function of how to get the raw value properly from a given captureMap
    //  1. by far the most common field will access the raw value directly from a capture column using the field db_name as the captureMap key (captureMap.get(fieldDbName))
    //  2. other fields (based on specific db_names) are computed fields from other columns that do not have their own column (examples include the teammate counts and percents)
    //  3. still other fields (based on specific fieldInputTypes) are accessed from other data sources other than the given captureMap (example of textarea types that get their full length data from the openCapture textarea data obj, the normal raw value in the capture map only contains the first 100 characters for textareas)
    var rawFromCaptureMapNotTextareaTF = false;
    var rawTextareaTF = false;
    var rawUpeCaptureFavoritesTF = false;
    var rawSelectPrimeSubTF = false;
    var rawContractRevenueValueTF = false;
    var rawAllocatedRevenueValueTF = false;
    var rawAllocatedNetValueTF = false;
    var rawAllocatedRevenuePerMonthTF = false;
    var rawAllocatedNetPerMonthTF = false;
    var rawPwinAdjustedContractOverallValueTF = false;
    var rawPwinAdjustedContractRevenueValueTF = false;
    var rawPwinAdjustedAllocatedRevenueValueTF = false;
    var rawPwinAdjustedAllocatedNetValueTF = false;
    var rawNumTeammatesTF = false;
    var rawTotalTeamAllocationTF = false;
    var rawTotalTeamAllocationCovTF = false;
    var rawTotalLBAllocationCovTF = false;
    var rawTotalSBAllocationCovTF = false;
    var rawRecentPinnedNotestampTF = false;
    var rawNextActiveTaskTF = false;

    if(fieldTypeObj.textareaTF) { rawTextareaTF = true; }
    else if(fieldTypeObj.captureFavoritesTF) { rawUpeCaptureFavoritesTF = true; } //capture favorites is not a tbl_captures column (is in tbl_a_users_per_email)
    else if(fieldIsTblACaptureTypesPrimeSubTF) { rawSelectPrimeSubTF = true; }
    else if(fieldIsContractRevenueValueTF) { rawContractRevenueValueTF = true; }
    else if(fieldIsAllocatedRevenueValueTF) { rawAllocatedRevenueValueTF = true; }
    else if(fieldIsAllocatedNetValueTF) { rawAllocatedNetValueTF = true; }
    else if(fieldIsAllocatedRevenuePerMonthTF) { rawAllocatedRevenuePerMonthTF = true; }
    else if(fieldIsAllocatedNetPerMonthTF) { rawAllocatedNetPerMonthTF = true; }
    else if(fieldIsPwinAdjustedContractOverallValueTF) { rawPwinAdjustedContractOverallValueTF = true; }
    else if(fieldIsPwinAdjustedContractRevenueValueTF) { rawPwinAdjustedContractRevenueValueTF = true; }
    else if(fieldIsPwinAdjustedAllocatedRevenueValueTF) { rawPwinAdjustedAllocatedRevenueValueTF = true; }
    else if(fieldIsPwinAdjustedAllocatedNetValueTF) { rawPwinAdjustedAllocatedNetValueTF = true; }
    else if(fieldIsNumTeammatesTF) { rawNumTeammatesTF = true; }
    else if(fieldIsTotalTeamAllocationTF) { rawTotalTeamAllocationTF = true; }
    else if(fieldIsTotalTeamAllocationCovTF) { rawTotalTeamAllocationCovTF = true; }
    else if(fieldIsTotalLbAllocationCovTF) { rawTotalLBAllocationCovTF = true; }
    else if(fieldIsTotalSbAllocationCovTF) { rawTotalSBAllocationCovTF = true; }
    else if(fieldIsRecentPinnedNotestampTF) { rawRecentPinnedNotestampTF = true; }
    else if(fieldIsNextActiveTaskTF) { rawNextActiveTaskTF = true; }
    else { rawFromCaptureMapNotTextareaTF = true; } //every other field has a raw column in tbl_captures (all of these above fields are either a textarea, a calculated field, or a user tbl field)

    expandedCaptureFieldMap.set("rawFromCaptureMapNotTextareaTF", rawFromCaptureMapNotTextareaTF);
    expandedCaptureFieldMap.set("rawTextareaTF", rawTextareaTF);
    expandedCaptureFieldMap.set("rawUpeCaptureFavoritesTF", rawUpeCaptureFavoritesTF);
    expandedCaptureFieldMap.set("rawSelectPrimeSubTF", rawSelectPrimeSubTF);
    expandedCaptureFieldMap.set("rawContractRevenueValueTF", rawContractRevenueValueTF);
    expandedCaptureFieldMap.set("rawAllocatedRevenueValueTF", rawAllocatedRevenueValueTF);
    expandedCaptureFieldMap.set("rawAllocatedNetValueTF", rawAllocatedNetValueTF);
    expandedCaptureFieldMap.set("rawAllocatedRevenuePerMonthTF", rawAllocatedRevenuePerMonthTF);
    expandedCaptureFieldMap.set("rawAllocatedNetPerMonthTF", rawAllocatedNetPerMonthTF);
    expandedCaptureFieldMap.set("rawPwinAdjustedContractOverallValueTF", rawPwinAdjustedContractOverallValueTF);
    expandedCaptureFieldMap.set("rawPwinAdjustedContractRevenueValueTF", rawPwinAdjustedContractRevenueValueTF);
    expandedCaptureFieldMap.set("rawPwinAdjustedAllocatedRevenueValueTF", rawPwinAdjustedAllocatedRevenueValueTF);
    expandedCaptureFieldMap.set("rawPwinAdjustedAllocatedNetValueTF", rawPwinAdjustedAllocatedNetValueTF);
    expandedCaptureFieldMap.set("rawNumTeammatesTF", rawNumTeammatesTF);
    expandedCaptureFieldMap.set("rawTotalTeamAllocationTF", rawTotalTeamAllocationTF);
    expandedCaptureFieldMap.set("rawTotalTeamAllocationCovTF", rawTotalTeamAllocationCovTF);
    expandedCaptureFieldMap.set("rawTotalLBAllocationCovTF", rawTotalLBAllocationCovTF);
    expandedCaptureFieldMap.set("rawTotalSBAllocationCovTF", rawTotalSBAllocationCovTF);
    expandedCaptureFieldMap.set("rawRecentPinnedNotestampTF", rawRecentPinnedNotestampTF);
    expandedCaptureFieldMap.set("rawNextActiveTaskTF", rawNextActiveTaskTF);


    //a flag marking which capture fields in tbl_captures_fields are actually real columns in tbl_captures, used in Create New Capture/Copy Capture/Admin Import/GCSS Import to set all data values being sent to php, and in OpenCapture "Copy Capture"
    const isTblCapturesColumnTF = (rawFromCaptureMapNotTextareaTF || rawTextareaTF);
    expandedCaptureFieldMap.set("isTblCapturesColumnTF", isTblCapturesColumnTF); //essentially not the fields that need special valueRaw treatment


    //decimal fields are all marked so that their number of decimal places in the raw value can be trimmed before being passed to the masking function (num places specified by this fieldObj for each field)
    const preMaskTrimDecimalsTF = (fieldTypeObj.hasTrimDecimalsOptionTF && (numDecimalsOrIntPositiveZeroPaddingNumDigits >= 0));
    expandedCaptureFieldMap.set("preMaskTrimDecimalsTF", preMaskTrimDecimalsTF); //decimal or a percent decimal (includes pwin/progress bars)


    //other fields require post processing of the masked value after masking depending on what their valueRaw was in other related fields in the captureMap
    expandedCaptureFieldMap.set("postMaskIntPositiveAsZeroPaddedStringTF", (fieldTypeObj.hasZeroPaddingOptionTF && (numDecimalsOrIntPositiveZeroPaddingNumDigits > 1))); //used for displaying captureID as a zero padded string "00103" instead of 103 (at least 2 digits must be specified since 1 digit looks like the normal int value and 0/-1 turn this zero padding off)
    expandedCaptureFieldMap.set("postMaskDateFormatDayMdyDaysUntilTF", fieldTypeObj.maskDateFormatDayMdyDaysUntilTF); //remove any days until boxes for closed stage captures
    expandedCaptureFieldMap.set("postMaskSelectIdiqCaptureTF", fieldIsIdiqCaptureIDTOLinkTF);
    expandedCaptureFieldMap.set("postMaskReasonsWonLostTF", fieldIsReasonsWonLostIDsColonPercentCommaTF);
    expandedCaptureFieldMap.set("postMaskPrimeContactCompanyTF", fieldIsPrimeContactCompanyIDTF);
    expandedCaptureFieldMap.set("postMaskIncumbentContactCompaniesTF", fieldIsIncumbentContactCompanyIDsCommaTF);
    expandedCaptureFieldMap.set("postMaskGcssIDTF", fieldIsGcssIDTF);
    expandedCaptureFieldMap.set("postMaskGcssGovWinIDStringTF", fieldIsGcssGovwinIDStringTF);
    expandedCaptureFieldMap.set("postMaskGcssFedCompIDStringTF", fieldIsGcssFedcompIDStringTF);
    expandedCaptureFieldMap.set("postMaskGcssGovWinPriority1to5TF", fieldIsGcssGovwinPriority1to5TF);

    //field value can be edited within a capture and has pencil edit icon show up next to it
    const canEditFieldTF = (!isUneditableFieldTF && !isComputedFieldTF && !(fieldIsPwinTF && !c_companyPwinUsersCanEditTF));
    expandedCaptureFieldMap.set("canEditFieldTF", canEditFieldTF);

    //cells that can be edited in the Capture Table cells, stage/progress/pwin/note stamps open the capture on the Advance Stage/Deal Shaping/Notepad cards
    const canEditFieldInCSTCellTF = (canEditFieldTF || (fieldIsStageIDTF || fieldIsShapingTotalProgressTF || fieldIsShapingStageProgressTF || fieldIsPwinTF || fieldIsRecentPinnedNotestampTF));
    expandedCaptureFieldMap.set("canEditFieldInCSTCellTF", canEditFieldInCSTCellTF);

    //field value can be selected in the list of capture fields on the Mass Edit feature for the Captures tab capture table
    const editableFieldsThatCantBeMassEdited = (fieldIsOurPrimeSubTeammateAllocationTF); //can not mass edit (editing our allocation on teammates card does complex teammate count math that is not handled in the update details field function)
    const uneditableFieldThatCanBeMassEdited = (fieldIsStageIDTF || fieldIsArchiveDateTF); //can mass edit stage and set archive date to today's date to mass archive
    const canMassEditFieldTF = ((canEditFieldTF && !editableFieldsThatCantBeMassEdited) || uneditableFieldThatCanBeMassEdited);
    expandedCaptureFieldMap.set("canMassEditFieldTF", canMassEditFieldTF);

    //field values that can not be used during capture import/update as a mapped CaptureExec field
    const notAvailableForImportTF = ((!fieldTypeObj.idsbIsValidTF) || isComputedFieldTF || fieldIsCaptureIDTF);
    expandedCaptureFieldMap.set("notAvailableForImportTF", notAvailableForImportTF);
    const notAvailableForUpdateTF = ((!fieldTypeObj.idsbIsValidTF) || isComputedFieldTF); //can use CaptureID for mapping
    expandedCaptureFieldMap.set("notAvailableForUpdateTF", notAvailableForUpdateTF);


    //determine if CST can have sum row column for this field
    const numericNonSumFieldTF = (fieldIsCaptureIDTF || fieldIsShapingTotalProgressTF || fieldIsShapingStageProgressTF || fieldIsPwinTF || fieldIsPeriodOfPerformanceTF || fieldIsTotalTeamAllocationTF || fieldIsTotalLbAllocationTF || fieldIsTotalSbAllocationTF || fieldIsTotalTeamAllocationCovTF || fieldIsTotalLbAllocationCovTF || fieldIsTotalSbAllocationCovTF || fieldIsOurPrimeSubTeammateAllocationTF || fieldIsReqSbAllocationTF || fieldIsGcssIDTF || fieldIsGcssIDTF || fieldIsGcssGovwinPriority1to5TF);
    const canCalculateCstSumRowTF = (fieldTypeObj.valueDisplayIsNumericTF && !numericNonSumFieldTF);
    expandedCaptureFieldMap.set("canCalculateCstSumRowTF", canCalculateCstSumRowTF);


    //determine capture summary table cell alignment for different field types
    var cstCellAlignClass = "textLeft";
    if(fieldTypeObj.moneyTF) {
      cstCellAlignClass = "textRight";
    }
    else if(fieldTypeObj.valueDisplayIsNumericTF || fieldTypeObj.valueDisplayIsDateOrDateTimeTF || fieldTypeObj.capturePriorityLevelTF || fieldTypeObj.captureFavoritesTF) {
      cstCellAlignClass = "textCenter";
    }
    expandedCaptureFieldMap.set("cstCellAlignClass", cstCellAlignClass);


    //for faster codeword searching, determine all of the valid codewords for this field and put them in an array (all lowercase)
    const customCodewordNamesArray = JSFUNC.convert_comma_list_to_array(customCodewordNamesComma);
    var lowercaseCodewordsArray = [fieldDbName.toLowerCase()]; //default for every field is the db_name
    for(let customCodewordName of customCodewordNamesArray) {
      lowercaseCodewordsArray.push(customCodewordName.toLowerCase());
    }
    lowercaseCodewordsArray = JSFUNC.unique(lowercaseCodewordsArray);
    expandedCaptureFieldMap.set("lowercaseCodewordsArray", lowercaseCodewordsArray);

    return(expandedCaptureFieldMap);
  }


  fetch_expanded_capture_field_map_from_field_db_name(i_fieldDbName) {
    //fetch a precomputed expanded capture field by matching the db_name
    const expandedCaptureFieldMap = JSFUNC.get_first_map_matching_field_value(this.c_tbl_captures_fields, "db_name", i_fieldDbName);
    if(expandedCaptureFieldMap !== undefined) {
      return(expandedCaptureFieldMap);
    }

    //field db_name does not exist in tbl_captures_fields, create an empty expandedCaptureFieldMap
    const doesNotExistCaptureFieldMap = new Map();
    doesNotExistCaptureFieldMap.set("id", -1);
    doesNotExistCaptureFieldMap.set("db_name", i_fieldDbName);
    doesNotExistCaptureFieldMap.set("display_name", "--Capture Field Does Not Exist (dbName: " + i_fieldDbName + ")--");
    return(this.create_expanded_capture_field_map_from_capture_field_map(doesNotExistCaptureFieldMap));
  }



  fetch_expanded_capture_field_map_from_field_id(i_fieldID) {
    const expandedCaptureFieldMap = this.c_tbl_captures_fields.get(i_fieldID);
    if(expandedCaptureFieldMap !== undefined) {
      return(expandedCaptureFieldMap);
    }

    //fieldID does not exist in tbl_captures_fields, create an empty expandedCaptureFieldMap
    const doesNotExistCaptureFieldMap = this.tbl_row_map_from_id("tbl_captures_fields", i_fieldID, "o");
    return(this.create_expanded_capture_field_map_from_capture_field_map(doesNotExistCaptureFieldMap));
  }














  //==============================================================================
  //valueRaw -> valueMask (using expandedCaptureFieldMaps/fieldTypeObjs)
  //==============================================================================
  int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(i_stringValueRaw, i_fieldTypeObj) {
    //for a field where int/decimal/string raw values are all represented together indescriminitely as strings, this uses the field's fieldTypeObj to convert all numeric fields from their string valueRaw into their correct numeric valueRaw format
    if(i_fieldTypeObj.valueRawIsNumericIntTF) {
      return(JSFUNC.str2int(i_stringValueRaw));
    }
    else if(i_fieldTypeObj.valueRawIsNumericTF) {
      return(JSFUNC.str2int_or_decimal(i_stringValueRaw));
    }
    return(i_stringValueRaw);
  }



  value_mask_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj, i_plainTextTF=false, i_htmlValueMaskMaxHeightEmOrUndefined=undefined) {
    const valueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj, i_plainTextTF, i_htmlValueMaskMaxHeightEmOrUndefined);
    if(i_plainTextTF) {
      return(valueMaskSortIfoObj.valueMaskPlainText);
    }
    return(valueMaskSortIfoObj.valueMask);
  }

  value_mask_plaintext_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj) {
    const valueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj, true); //i_plainTextTF flag is true to save computation time on the unecessary html mask value
    return(valueMaskSortIfoObj.valueMaskPlainText);
  }

  value_mask_changelog_plaintext_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj) {
    const valueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj, true); //i_plainTextTF flag is true to save computation time on the unecessary html mask value
    return(valueMaskSortIfoObj.valueMaskChangelogPlainText);
  }

  value_mask_no_click_links_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj) {
    const valueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj, false);
    return(valueMaskSortIfoObj.valueMaskNoClickLinks);
  }

  value_is_filled_out_tf_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj) {
    const valueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj, true); //i_plainTextTF flag is true to save computation time on the unecessary html mask value
    return(valueMaskSortIfoObj.isFilledOutTF);
  }


  value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_valueRaw, i_fieldTypeObj, i_plainTextTF=false, i_htmlValueMaskMaxHeightEmOrUndefined=undefined) {
    //i_valueRaw: raw value (as sotred in database) to be masked
    //i_fieldTypeObj: a fieldTypeObj that defines the field on how to mask its raw value
    //i_plainTextTF: [default false] if true, valueMask/valueMaskNoClickLinks will be the same as valuePlainText
    //i_htmlValueMaskMaxHeightEmOrUndefined: restricts the html height of any valueMask/valueMaskNoClickLinks that produces html output (like PWin cube or Progress bar)
    //
    //returns a valueMaskSortIfoObj containing 9 read-only fields:
    //  - valueRaw                    (the original CE raw database value for a given field type)
    //  - valueTrueRawIntegrations    (handles some CE raw value quirks, usually idential to valueRaw except for date/datetime fields where relative date is converted from "9005-01-04" => today + 4 years which is "2028-07-19", and date with duration (datetime raw) is converted to just a date)
    //  - valueMaskPlainText
    //  - valueMaskCsvXmlPlainText    (usually identical to valueMaskPlainText except in certain percent/date field type instances to work with excel or xml template exports)
    //  - valueMaskChangelogPlainText (is valueMaskPlainText except for website which displays the raw value (including both the web address and the mask))
    //  - valueMask                   (returns valueMaskPlainText if i_plainTextTF is true which saves computation time)
    //  - valueMaskNoClickLinks       (valueMask except hyperlinks from phone/email/website and links to captures in the system are displayed as non clickable black text)
    //  - valueSort
    //  - isFilledOutTF

    var valueTrueRawIntegrations = undefined;
    var valueMaskPlainText = undefined;
    var valueMaskCsvXmlPlainText = undefined; //copied from valueMaskPlainText at bottom of this function unless set in some cases
    var valueMaskChangelogPlainText = undefined; //copied from valueMaskPlainText at bottom of this function unless set in some cases
    var valueMask = undefined;
    var valueMaskNoClickLinks = undefined; //if left as undefined, valueMask is copied into this value
    var valueSort = undefined;
    var isFilledOutTF = undefined;

    const fieldTypeObjInputIsUndefinedTF = (i_fieldTypeObj === undefined);

    if(fieldTypeObjInputIsUndefinedTF) { //check to make sure a valid fieldTypeObj was provided
      valueMaskPlainText = "--DatabaseMobx valueMask fieldTypeObj undefined (valueRaw:" + i_valueRaw + ")--";
      valueMask = valueMaskPlainText;
      valueSort = JSFUNC.sort_max_string();
      isFilledOutTF = false;
    }
    else if(i_fieldTypeObj.textTF || i_fieldTypeObj.textareaTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        valueMaskPlainText = i_valueRaw;
        valueMask = i_valueRaw; //unchanged rawValue is returned as the mask
        valueSort = i_valueRaw; //unchanged values are sorted alphabetically
      }
      else {
        valueMaskPlainText = "--No Text Entered--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_string();
      }
    }
    else if(i_fieldTypeObj.selectTF || i_fieldTypeObj.sharedPercentTF || i_fieldTypeObj.selectContactCompanyTF || i_fieldTypeObj.selectContactPersonTF || i_fieldTypeObj.selectCaptureTF || i_fieldTypeObj.verticalSwitchTF || i_fieldTypeObj.capturePriorityLevelTF) {
      //all selects, multiselects, sharedpercents, and select contacts/captures (also verticalswitch fieldInputTypes use the same SWS data obj format, so masking can be done the same way as a select)
      const selectWithSearchDataObj = i_fieldTypeObj.selectWithSearchDataObj; //hardcoded or loaded from database tbls, all reference data provided through the selectWithSearchDataObj
      if((selectWithSearchDataObj === undefined) || !JSFUNC.is_array(selectWithSearchDataObj.valueArray)) { //valueArray being undefined is a flag that the underlying select data obj does not exist
        valueMaskPlainText = "--Select Data Table Does Not Exist (" + selectWithSearchDataObj.itemName + ")--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = (JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string());
        isFilledOutTF = false;
      }
      else { //valueArray is valid
        if(!(i_fieldTypeObj.sharedPercentTF || selectWithSearchDataObj.isMultiSelectTF)) { //single select (either string or int valueArray)
          const valueMaskPlainTextMaskSortIfoIhObj = this.value_mask_sort_ifo_ishtml_obj_from_select_value_raw_and_select_data_obj(i_valueRaw, undefined, selectWithSearchDataObj, i_plainTextTF);
          valueMaskPlainText = valueMaskPlainTextMaskSortIfoIhObj.valueMaskPlainText;
          valueMask = valueMaskPlainTextMaskSortIfoIhObj.valueMask;
          valueMaskNoClickLinks = valueMaskPlainTextMaskSortIfoIhObj.valueMaskNoClickLinks;
          valueSort = valueMaskPlainTextMaskSortIfoIhObj.valueSort;
          isFilledOutTF = valueMaskPlainTextMaskSortIfoIhObj.isFilledOutTF;
        }
        else { //multiSelect or sharedpercent
          if(!JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw)) { //multiselect/sharedpercent has 0 values selected (either "" or undefined)
            valueMaskPlainText = selectWithSearchDataObj.unselectedDisplay;
            valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
            valueSort = JSFUNC.sort_max_string();
            isFilledOutTF = false;
          }
          else if(selectWithSearchDataObj.multiSelectHasAllSwitchTF && (i_valueRaw === "all")) { //multiselect has the swsOption 'multiSelectHasAllSwitchTF' set to true, which changes masking when the valueRaw is 'all'
            valueMaskPlainText = "[All " + selectWithSearchDataObj.itemName + " Options Selected]";
            valueMask = valueMaskPlainText;
            valueSort = JSFUNC.sort_max_string();
            isFilledOutTF = true;
          }
          else { //multiselect/sharedpercent has at least 1 value (valid or not) selected
            var selectedValuesArrayOrIntsArrayOfObjs = undefined;
            if(i_fieldTypeObj.sharedPercentTF) { //sharedpercent valueRaw is a string colon comma list of int ids and int percent values "1:15,6:55,3:30"
              selectedValuesArrayOrIntsArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(i_valueRaw, "id", "percent0to100", false, "percent0to100", false); //sort the array by the percent0to100 descending
            }
            else if(selectWithSearchDataObj.valuesAreStringsTF) { //multiselect valueRaw string comma list with strings for values "admin,divexec,captureexec"
              selectedValuesArrayOrIntsArrayOfObjs = JSFUNC.convert_comma_list_to_array(i_valueRaw);
            }
            else { //multiselect valueRaw string comma list with ints for values "1,6,3"
              selectedValuesArrayOrIntsArrayOfObjs = JSFUNC.convert_comma_list_to_int_array(i_valueRaw);
            }

            if(selectedValuesArrayOrIntsArrayOfObjs.length === 1) { //a single item selected from a multiselect can be masked in the same way as a single select using the selected value
              const selectedValue = ((i_fieldTypeObj.sharedPercentTF) ? (selectedValuesArrayOrIntsArrayOfObjs[0]["id"]) : (selectedValuesArrayOrIntsArrayOfObjs[0])); //for sharedpercents, the percent is not added to the mask for a single selection, it is assumed/guaranteed to be 100%
              const valueMaskPlainTextMaskSortIfoIhObj = this.value_mask_sort_ifo_ishtml_obj_from_select_value_raw_and_select_data_obj(selectedValue, undefined, selectWithSearchDataObj, i_plainTextTF);
              valueMaskPlainText = valueMaskPlainTextMaskSortIfoIhObj.valueMaskPlainText;
              valueMask = valueMaskPlainTextMaskSortIfoIhObj.valueMask;
              valueMaskNoClickLinks = valueMaskPlainTextMaskSortIfoIhObj.valueMaskNoClickLinks;
              valueSort = valueMaskPlainTextMaskSortIfoIhObj.valueSort;
              isFilledOutTF = valueMaskPlainTextMaskSortIfoIhObj.isFilledOutTF;
            }
            else { //multiselect has more than 1 item selected, masking and sort are joined together in a comma list, isFilledOutTF is only true if every item is a valid selection
              isFilledOutTF = true; //initialized to true, if any of the selected values are not valid, is set to false
              var allSelectedMasksArePlainTextTF = true; //initialized to true, if any of the selected values have an html output (not set or a capture link), is set to false
              var valueMaskPlainTextsArray = [];
              var valueMasksArray = [];
              var valueMaskNoClickLinksArray = [];
              var valueSortsArray = [];
              var valueMaskIsHtmlTFArray = [];
              for(let selectedValueOrIntsObj of selectedValuesArrayOrIntsArrayOfObjs) {
                var selectedValue = selectedValueOrIntsObj;
                var stringToAppendToValueMaskPlainText = undefined;
                if(i_fieldTypeObj.sharedPercentTF) {
                  selectedValue = selectedValueOrIntsObj["id"];
                  stringToAppendToValueMaskPlainText = " (" + selectedValueOrIntsObj["percent0to100"] + "%)"; //add the percent value in parens if at least 2 values are selected in a shared percent
                }

                var selectedValueMaskPlainTextMaskSortIfoIhObj = this.value_mask_sort_ifo_ishtml_obj_from_select_value_raw_and_select_data_obj(selectedValue, stringToAppendToValueMaskPlainText, selectWithSearchDataObj, i_plainTextTF);

                if(!selectedValueMaskPlainTextMaskSortIfoIhObj.isFilledOutTF) {
                  isFilledOutTF = false; //if any of the multiselect values do not exist in their options list, mark it as not filled out
                }

                if(selectedValueMaskPlainTextMaskSortIfoIhObj.valueMaskIsHtmlTF) {
                  allSelectedMasksArePlainTextTF = false; //if any of the multiselect value masks are html tags, mark this flag as false
                }

                valueMaskPlainTextsArray.push(selectedValueMaskPlainTextMaskSortIfoIhObj.valueMaskPlainText);
                valueMasksArray.push(selectedValueMaskPlainTextMaskSortIfoIhObj.valueMask);
                valueMaskNoClickLinksArray.push(selectedValueMaskPlainTextMaskSortIfoIhObj.valueMaskNoClickLinks);
                valueSortsArray.push(selectedValueMaskPlainTextMaskSortIfoIhObj.valueSort);
                valueMaskIsHtmlTFArray.push(selectedValueMaskPlainTextMaskSortIfoIhObj.valueMaskIsHtmlTF);
              }

              valueMaskPlainText = valueMaskPlainTextsArray.join(", "); //join together multiple plaintext strings with commas and a space
              if(i_plainTextTF || allSelectedMasksArePlainTextTF) { //plainText is requested or all mask values are plaintext strings and can match the plaintext join
                valueMask = valueMaskPlainText;
                valueMaskNoClickLinks = valueMaskPlainText;
              }
              else { //there is at least 1 html item in this list, wrap all correctly filled out item strings in <font> tags so that all items are in tags
                if(i_fieldTypeObj.selectContactCompanyTF || i_fieldTypeObj.selectContactPersonTF) { //multiselect contact persons/companies filled out, wrap the contact bubbles in a div with flexwrap
                  valueMask = (
                    <div className="positionRelative displayFlexRow flexWrap">
                      {valueMasksArray.map((m_valueMaskContactBubbleHtml) =>
                        m_valueMaskContactBubbleHtml
                      )}
                    </div>
                  );
                }
                else {
                  valueMask = this.join_plaintext_and_font_tag_wrapped_items_in_font_tag(valueMasksArray, valueMaskIsHtmlTFArray);
                }
                valueMaskNoClickLinks = this.join_plaintext_and_font_tag_wrapped_items_in_font_tag(valueMaskNoClickLinksArray, valueMaskIsHtmlTFArray);
              }
              valueSort = valueSortsArray.join(","); //sort values are always plaintext and can be simply string joined together
            }
          }
        }
      }
    }
    else if(i_fieldTypeObj.intTF || i_fieldTypeObj.decimalTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        valueMaskPlainText = i_valueRaw;
        valueMask = i_valueRaw;
        valueSort = i_valueRaw; //unchanged values are sorted in numerical order
      }
      else {
        valueMaskPlainText = "--No Value Entered--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_mysqli_bigint();
      }
    }
    else if(i_fieldTypeObj.percentTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        valueMaskPlainText = i_valueRaw + "%";
        valueMask = valueMaskPlainText;
        if(i_fieldTypeObj.maskPercentProgressBarShapingTF) {
          if(i_valueRaw === -1) {
            valueMaskPlainText = "N/A";
          }

          if(!i_plainTextTF) {
            valueMask = this.progress_bar_html_from_percent_and_size_em(i_valueRaw, i_htmlValueMaskMaxHeightEmOrUndefined);
          }
        }
        else if(i_fieldTypeObj.maskPercentPwinCubeTF) {
          if(i_valueRaw === -1) {
            valueMaskPlainText = "N/A";
          }
          
          if(!i_plainTextTF) {
            valueMask = this.pwin_cube_svg_html_from_percent_and_size_em(i_valueRaw, i_htmlValueMaskMaxHeightEmOrUndefined);
          }
        }
        valueSort = i_valueRaw;
      }
      else {
        if(i_fieldTypeObj.maskPercentProgressBarShapingTF) {
          valueMaskPlainText = "--Progress Not Set--";
        }
        else if(i_fieldTypeObj.maskPercentPwinCubeTF) {
          valueMaskPlainText = "--PWin Not Set--";
        }
        else {
          valueMaskPlainText = "--No Percent Entered--";
        }
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_mysqli_bigint();
      }
    }
    else if(i_fieldTypeObj.moneyTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        const moneyNumDecimals = ((i_fieldTypeObj.moneyIncludeCentsTF) ? (2) : (0));
        valueMaskPlainText = JSFUNC.money(i_valueRaw, moneyNumDecimals, true);
        valueMask = valueMaskPlainText;
        valueSort = i_valueRaw;
      }
      else {
        valueMaskPlainText = "--No Value Entered--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_mysqli_bigint();
      }
    }
    else if(i_fieldTypeObj.emailTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        valueMaskPlainText = i_valueRaw;
        if(i_plainTextTF) {
          valueMask = valueMaskPlainText; //plainText email address without <a> tag
        }
        else {
          valueMask = (
            <a
              href={"mailto:" + i_valueRaw}
              target="_top"
              title={"mailto:" + i_valueRaw}
              onClick={(event) => event.stopPropagation()}>
              {i_valueRaw}
            </a>
          );

          valueMaskNoClickLinks = i_valueRaw;
        }
        valueSort = i_valueRaw;
      }
      else {
        valueMaskPlainText = "--No Email Address Entered--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_string();
      }
    }
    else if(i_fieldTypeObj.phoneTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        const phoneStripped = i_valueRaw.replace(/\s|#|-|[()]/g,''); //remove all characters space, #, -, (, and )
        valueMaskPlainText = i_valueRaw;
        if(i_plainTextTF) {
          valueMask = valueMaskPlainText; //plainText phone number without <a> tag
        }
        else {
          valueMask = (
            <a
              href={"tel:" + phoneStripped}
              target="_top"
              title={"tel:" + phoneStripped}
              onClick={(event) => event.stopPropagation()}>
              {i_valueRaw}
            </a>
          );

          valueMaskNoClickLinks = i_valueRaw;
        }
        valueSort = phoneStripped;
      }
      else {
        valueMaskPlainText = "--No Phone Number Entered--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_string();
      }
    }
    else if(i_fieldTypeObj.websiteTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        var [websiteMask, websiteWebAddress] = JSFUNC.website_determine_mask_website(i_valueRaw);
        valueMaskPlainText = ((websiteMask !== "") ? (websiteMask) : (websiteWebAddress)); //if a websiteMask is provided, print the websiteMask, otherwise print the websiteWebAddress
        valueMaskChangelogPlainText = i_valueRaw; //use full valueRaw for website when displaying in changelog entries as it includes both the [[Mask]] and the web address (hard to tell what changed if the mask stayed the same)
        if(i_plainTextTF) { //plainText web address without <a> tag
          valueMask = valueMaskPlainText;
        }
        else {
          if(websiteWebAddress === "") { //only websiteMask provided
            valueMask = websiteMask; //if no websiteWebAddress is provided, show the websiteMask plain text with no hyperlink
          }
          else { //websiteMask and websiteWebAddress are provided
            if(websiteMask === "") { //no websiteMask is provided, print the websiteWebAddress address as the websiteMask
              websiteMask = websiteWebAddress;
            }

            valueMask = (
              <a className="breakWord" target="_blank" rel="noopener noreferrer" href={websiteWebAddress} title={websiteWebAddress} onClick={(event) => event.stopPropagation()}>
                {websiteMask}
              </a>
            );

            valueMaskNoClickLinks = websiteMask;
          }
        }
        valueSort = ((websiteMask !== "") ? (websiteMask) : (websiteWebAddress));
      }
      else {
        valueMaskPlainText = "--No Web Address Entered--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_string();
      }
    }
    else if(i_fieldTypeObj.colorTF) {
      isFilledOutTF = (JSFUNC.is_string(i_valueRaw) && (i_valueRaw.length === 6));
      if(isFilledOutTF) {
        valueMaskPlainText = "#" + i_valueRaw;
        if(i_plainTextTF) {
          valueMask = valueMaskPlainText;
        }
        else {
          valueMask = (
            <div className="displayFlexRowVc" style={{width:"8.2em"}}>
              <div className="flex11a rMargin" style={{height:"1.2em", background:"#" + i_valueRaw, border:"solid 1px #999"}} />
              <div className="flex00a" style={{flexBasis:"4.5em"}}>
                <div className="font09 fontItalic fontTextLighter">
                  {"#" + i_valueRaw}
                </div>
              </div>
            </div>
          );
        }
        valueSort = i_valueRaw;
      }
      else {
        valueMaskPlainText = "--No Color Entered--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_string();
      }
    }
    else if(i_fieldTypeObj.dateTF || i_fieldTypeObj.dateTimeTF || i_fieldTypeObj.dateLocalFromRawDateTimeUtcTF) { //date/datetime/dateLocalFromRawDateTimeUtcTF
      //vars for display/raw value between the date/datetime types
      var rawIsDateTimeTF = (i_fieldTypeObj.dateTimeTF || i_fieldTypeObj.dateLocalFromRawDateTimeUtcTF);
      var displayIsDateTF = (i_fieldTypeObj.dateTF || i_fieldTypeObj.dateLocalFromRawDateTimeUtcTF);

      //determine if date or datetime is filled out
      isFilledOutTF = ((rawIsDateTimeTF) ? (JSFUNC.datetime_is_filled_out_tf(i_valueRaw)) : (JSFUNC.date_is_filled_out_tf(i_valueRaw)));

      //initialize jsDateObj for formats that use it for conversion
      var jsDateObj = undefined;

      if(i_fieldTypeObj.maskDateFormatDayMdyDaysUntilTF) { //dayMdyDaysUntil date/datetime display types
        //widths for dayMdyDaysUntil mask
        const dayWidthEm = 1.9; //longest string "Wed"
        const mdyWidthEm = 6.5; //longest string "Dec 22, 2022"
        const timeWidthEm = 4.4; //longest string "10:55 PM"
        const daysUntilWidthEm = 5.5; //longest string "10 months"
        const totalWidthEm = dayWidthEm + mdyWidthEm + ((displayIsDateTF) ? (0) : (timeWidthEm)) + daysUntilWidthEm;
        
        if(isFilledOutTF) { //date or datetime dayMdyDaysUntil is filled out
          jsDateObj = ((rawIsDateTimeTF) ? (JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(i_valueRaw)) : (JSFUNC.convert_mysqldate_to_jsdateobj(i_valueRaw)));
          const nowDateOrDateTimeUtc = ((rawIsDateTimeTF) ? (JSFUNC.now_datetime_utc()) : (JSFUNC.now_date()));
          const dateIsTodayOrUpcomingTF = (i_valueRaw >= nowDateOrDateTimeUtc);
          const day = JSFUNC.date_day(jsDateObj, false);
          const mdy = JSFUNC.get_MjY_date_from_jsdateobj_and_utctf(jsDateObj, false);
          const drawDaysUntilTF = ((dateIsTodayOrUpcomingTF || i_fieldTypeObj.maskDateFormatDaysUntilIncludeOverdueTF) && !i_fieldTypeObj.maskDateFormatHideDaysUntilBoxTF); //draw days until box if date is upcoming or overdue flag is on
          
          //initialize valueMaskPlainText with "Day Mth day, YYYY"
          valueMaskPlainText = day + " " + mdy;

          //time for datetime display
          var timeString = "";
          if(!displayIsDateTF) {
            timeString = JSFUNC.get_giA_time_with_59sec_not_set_from_jsdateobj_and_utctf(jsDateObj, false);
            valueMaskPlainText += " " + timeString;
          }

          //days until box
          var daysUntilDateObj = undefined;
          if(drawDaysUntilTF) {
            var dateLocal = i_valueRaw; //input is a filled out date, local date is input date with no time conversion
            if(rawIsDateTimeTF) { //for datetime, need to convert the utc time to local using the jsDateObj computed above from the raw mysqldatetimeutc input
              dateLocal = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false);
            }
            const numDaysUntilDate = JSFUNC.num_days_from_date1_to_date2(JSFUNC.now_date(), dateLocal);
            daysUntilDateObj = this.days_until_date_string_and_colors_obj(numDaysUntilDate, i_fieldTypeObj.maskDateFormatDaysUntilColorNumber);
            valueMaskPlainText += " [" + daysUntilDateObj.daysUntilDateString + "]";
          }

          if(i_plainTextTF) {
            valueMask = valueMaskPlainText;
          }
          else {
            valueMask = (
              <div className="displayFlexRowVc" style={{width:totalWidthEm + "em"}}>
                <div className="flex11a nowrap">
                  {day}
                </div>
                <div className="flex00a nowrap textRight" style={{flexBasis:mdyWidthEm + "em"}}>
                  {mdy}
                </div>
                {(!displayIsDateTF) &&
                  <div className="flex00a nowrap textCenter" style={{flexBasis:timeWidthEm + "em", paddingLeft:"0.1em"}}>
                    {timeString}
                  </div>
                }
                {(drawDaysUntilTF) ? (
                  <div className="flex00a displayFlexColumn" style={{flexBasis:daysUntilWidthEm + "em", height:"1.25em", paddingLeft:"0.4em"}}>
                    <div
                      className="flex11a displayFlexColumnHcVc overflowHidden border1"
                      style={{backgroundColor:"#" + daysUntilDateObj.bgColor, borderColor:"#eee #ddd #ddd #eee"}}>
                      <font className="" style={{color:"#" + daysUntilDateObj.fontColor}}>
                        {daysUntilDateObj.daysUntilDateString}
                      </font>
                    </div>
                  </div>
                ) : (
                  <div className="flex00a" style={{flexBasis:daysUntilWidthEm + "em"}} />
                )}
              </div>
            );
          }
        }
        else { //date or datetime dayMdyDaysUntil is not filled out
          valueMaskPlainText = ((displayIsDateTF) ? ("--Date Not Set--") : ("--Date/Time Not Set--"));
          if(i_plainTextTF) {
            valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
          }
          else { //not filled out date or datetime dayMdyDaysUntil mask has same width html as filled out mask
            valueMask = (
              <div className="displayFlexRowVc" style={{width:totalWidthEm + "em"}}>
                {this.not_filled_out_font_html(valueMaskPlainText, false)}
              </div>
            );
          }
        }
      }
      else { //other date/datetime formats (not dayMdyDaysUntil)
        if(isFilledOutTF) {
          //compute valueMaskPlainText for date or datetime (for other date/datetime formats, not dayMdyDaysUntil), valueMask at bottom is copy of valueMaskPlainText
          if(i_fieldTypeObj.maskDateFormatCompanyTF && displayIsDateTF && !rawIsDateTimeTF) { //can direct convert m/d/Y, Y-m-d, and M j, Y into company format from raw value Y-m-d input without using jsDateObj
            valueMaskPlainText = this.get_company_date_format_from_Ymd_date(i_valueRaw);
          }
          else if(i_fieldTypeObj.maskDateFormatYmdTF && displayIsDateTF && !rawIsDateTimeTF) { //Y-m-d
            valueMaskPlainText = i_valueRaw;
          }
          else { //all other date/datetime formats require conversion using jsDateObj
            //compute jsDateObj for date or datetime
            if(rawIsDateTimeTF) {
              if(i_fieldTypeObj.maskDateFormatNaturalTimeTF) { //datetime (natural with all 00-59 seconds) conversion to jsDateObj
                jsDateObj = JSFUNC.convert_natural_mysqldatetimeutc_to_jsdateobj(i_valueRaw);
              }
              else { //datetime (with CE special 58/59 seconds meaning the time is not filled out) conversion to jsDateObj
                jsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(i_valueRaw);
              }
            }
            else { //date conversion to jsDateObj
              jsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(i_valueRaw);
            }

            if(i_fieldTypeObj.maskDateFormatCompanyTF) { //Company Format datetime [or date from raw datetime] (company format date was direct converted above)
              valueMaskPlainText = JSFUNC.date_format_from_jsdateobj(jsDateObj, this.c_companyDateFormat, false); //date in company format (from raw datetime)
              if(!displayIsDateTF) { //add time for datetime display formats
                if(i_fieldTypeObj.maskDateFormatNaturalTimeTF) {
                  valueMaskPlainText += " " + JSFUNC.get_giA_time_natural_from_jsdateobj_and_utctf(jsDateObj);
                }
                else { //admin company format for date plus time like 2018-01-01 09:54 AM or 01/01/2018 09:54 AM or Jan 1, 2018 09:54 AM
                  valueMaskPlainText += " " + JSFUNC.get_giA_time_with_59sec_not_set_from_jsdateobj_and_utctf(jsDateObj);
                }
              }
            }
            else if(i_fieldTypeObj.maskDateFormatYmdTF) { //Y-m-d g:i A datetime (Y-m-d date was direct from raw value above with no conversion)
              if(displayIsDateTF) { //Y-m-d [converted from datetime]
                valueMaskPlainText = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false);
              }
              else {
                if(i_fieldTypeObj.maskDateFormatNaturalTimeTF) {
                  valueMaskPlainText = JSFUNC.get_YmdgiA_datetime_natural_from_jsdateobj_and_utctf(jsDateObj, false);
                }
                else {
                  valueMaskPlainText = JSFUNC.get_YmdgiA_datetime_from_jsdateobj_and_utctf(jsDateObj, false);
                }
              }
            }
            else if(i_fieldTypeObj.maskDateFormatMjYTF) { //M j, Y
              if(displayIsDateTF) { //M j, Y [converted from raw date or datetime]
                valueMaskPlainText = JSFUNC.get_MjY_date_from_jsdateobj_and_utctf(jsDateObj, false);
              }
              else { //M j, Y g:i A
                if(i_fieldTypeObj.maskDateFormatNaturalTimeTF) {
                  valueMaskPlainText = JSFUNC.get_MjYgiA_datetime_natural_from_jsdateobj_and_utctf(jsDateObj, false);
                }
                else {
                  valueMaskPlainText = JSFUNC.get_MjYgiA_datetime_from_jsdateobj_and_utctf(jsDateObj, false);
                }
              }
            }
            else if(i_fieldTypeObj.maskDateFormatDMjYTF) { //D M j, Y
              if(displayIsDateTF) { //D M j, Y [converted from raw date or datetime]
                valueMaskPlainText = JSFUNC.get_DMjY_date_from_jsdateobj_and_utctf(jsDateObj, false);
              }
              else { //D M j, Y g:i A
                if(i_fieldTypeObj.maskDateFormatNaturalTimeTF) {
                  valueMaskPlainText = JSFUNC.get_DMjYgiA_datetime_natural_from_jsdateobj_and_utctf(jsDateObj, false);
                }
                else {
                  valueMaskPlainText = JSFUNC.get_DMjYgiA_datetime_from_jsdateobj_and_utctf(jsDateObj, false);
                }
              }
            }
            else { //other unknown flag returns raw Y-m-d or Y-m-d H:i:s
              valueMaskPlainText = i_valueRaw;
            }
          }

          //for all date/datetime formats (not dayMdyDaysUntil) mask is just plaintext mask
          valueMask = valueMaskPlainText;
        }
        else { //date or datetime (format other than dayMdyDaysUntil) is not filled out
          valueMaskPlainText = ((i_fieldTypeObj.dateTimeTF) ? ("--Date/Time Not Set--") : ("--Date Not Set--"));
          valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        }
      }

      //sort for date or datetime for all date/datetime types
      if(isFilledOutTF) {
        if(rawIsDateTimeTF) { //datetime sort needs to be converted to local from utc
          //compute datetime jsDateObj for sort if it has not been computed above yet for masking
          if(jsDateObj === undefined) {
            jsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(i_valueRaw);
          }

          const timeIsSetTF = JSFUNC.ce_time_is_set_tf_from_jsdateobj(jsDateObj);
          if(timeIsSetTF) { //if a time is set, the sort is the exact local date and time
            valueSort = JSFUNC.get_YmdHis_datetime_from_jsdateobj_and_utctf(jsDateObj, false);
          }
          else { //if a time is not set (seconds are 58/59), then sort is just the local date with no time
            valueSort = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(jsDateObj, false);
          }
        }
        else { //filled out dates are sorted by their raw value Y-m-d "2018-04-15"
          valueSort = i_valueRaw;
        }
      }
      else { //not filled out date or datetime has sort of max date (time doesn't matter when dealing with a value as high as max_date compared to other real filled in dates/datetimes)
        valueSort = JSFUNC.sort_max_date();
      }
    }
    else if(i_fieldTypeObj.dateWithRelativeDateTF) { //relative date
      isFilledOutTF = JSFUNC.date_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        const fixedDateObj = JSFUNC.convert_relative_ymd_date_to_fixed_date_obj(i_valueRaw, this.c_companyDateFormat);
        if(fixedDateObj.isRelativeTF) { //relative date
          var fixedDateFormatted = fixedDateObj.fixedDateFormatted;
          var relativeOffsetString = "[Today's Date";
          if(fixedDateObj.relativeOffset < 0) {
            relativeOffsetString += " -" + (-1 * fixedDateObj.relativeOffset) + " " + fixedDateObj.relativeTypeMask;
          }
          else if(fixedDateObj.relativeOffset >= 0) {
            relativeOffsetString += " +" + fixedDateObj.relativeOffset + " " + fixedDateObj.relativeTypeMask;
          }
          relativeOffsetString += "]";

          valueTrueRawIntegrations = fixedDateObj.fixedDateYmd; //valueRaw for external integration systems should be the fixed date calculation, not "9001-01-01", which wont make sense to other systems
          valueMaskPlainText = fixedDateFormatted + " " + relativeOffsetString;
          valueMask = (<>{fixedDateFormatted + " "}<font className="fontItalic">{relativeOffsetString}</font></>);
        }
        else { //fixed date
          valueMaskPlainText = fixedDateObj.fixedDateFormatted;
          valueMask = valueMaskPlainText;
        }
        valueSort = fixedDateObj.fixedDateYmd;
      }
      else {
        valueMaskPlainText = "--Date Not Set--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_date();
      }
    }
    else if(i_fieldTypeObj.dateWithDurationTF) { //date with duration
      const dateWithDurationObj = JSFUNC.convert_date_with_duration_raw_datetime_to_date_with_duration_obj(i_valueRaw);
      isFilledOutTF = JSFUNC.date_is_filled_out_tf(dateWithDurationObj.dateYmd);
      if(isFilledOutTF) {
        var durationTypeDisplay = "day";
        if(dateWithDurationObj.days1Weeks2Months3Years4Int === 2) { durationTypeDisplay = "week"; }
        else if(dateWithDurationObj.days1Weeks2Months3Years4Int === 3) { durationTypeDisplay = "month"; }
        else if(dateWithDurationObj.days1Weeks2Months3Years4Int === 4) { durationTypeDisplay = "year"; }
        durationTypeDisplay = JSFUNC.plural(dateWithDurationObj.numDaysWeeksMonthsYearsInt, durationTypeDisplay, durationTypeDisplay + "s");

        const dateWithDurationJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(dateWithDurationObj.dateYmd);
        const dateWithDurationDateInCompanyFormat = JSFUNC.date_or_datetime_format_from_jsdateobj(dateWithDurationJsDateObj, this.c_companyDateFormat, "", false);
        const durationString = "[Duration: " + dateWithDurationObj.numDaysWeeksMonthsYearsInt + " " + durationTypeDisplay + "]";

        valueTrueRawIntegrations = dateWithDurationObj.dateYmd; //return the date instead of the full datetime for external integration systems (time portion provides the duration length, which only makes sense to CE)
        valueMaskPlainText = dateWithDurationDateInCompanyFormat + " " + durationString;
        valueMask = (<>{dateWithDurationDateInCompanyFormat + " "}<font className="fontItalic">{durationString}</font></>);
        valueSort = dateWithDurationObj.dateYmd;
      }
      else {
        valueMaskPlainText = "--Date Not Set--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_date();
      }
    }
    else if(i_fieldTypeObj.captureFavoritesTF) {
      isFilledOutTF = i_valueRaw; //the capture favorites valueRaw is a calculated true/false based on the userPerEmail's favorited captureIDs comma list
      if(isFilledOutTF) {
        valueMaskPlainText = "Favorited by " + UserMobx.c_userName;
        valueMask = valueMaskPlainText;
        valueSort = 1;
      }
      else {
        valueMaskPlainText = "--Not Favorited by " + UserMobx.c_userName + "--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_mysqli_int();
      }
    }
    else if(i_fieldTypeObj.notestampTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        valueMaskPlainText = i_valueRaw;
        valueMask = i_valueRaw;
        valueSort = i_valueRaw;
      }
      else {
        valueMaskPlainText = "--No Note Stamps--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_string();
      }
    }
    else if(i_fieldTypeObj.taskTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        valueMaskPlainText = i_valueRaw;
        valueMask = i_valueRaw;
        valueSort = i_valueRaw;
      }
      else {
        valueMaskPlainText = "--No Active Tasks--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_string();
      }
    }
    else if(i_fieldTypeObj.documentLinksTF) {
      isFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(i_valueRaw);
      if(isFilledOutTF) {
        var documentLinksArrayOfObjsOrUndefined = JSFUNC.parse_json_arrayOfObjs_string_into_arrayOfObjs_or_undefined(i_valueRaw);
        if(JSFUNC.is_array(documentLinksArrayOfObjsOrUndefined)) {
          JSFUNC.sort_arrayOfObjs(documentLinksArrayOfObjsOrUndefined, ["o", "d"], [true, false]);

          var documentDatesLocalArray = [];
          var documentTitlesArray = [];
          valueMaskPlainText = "";
          for(let documentLinkObj of documentLinksArrayOfObjsOrUndefined) {
            var documentDateLocal = JSFUNC.get_Ymd_date_local_from_natural_datetime_utc(documentLinkObj.d);
            documentDatesLocalArray.push(documentDateLocal);

            var documentUploadMjYgiADateTimeLocalPlainText = JSFUNC.get_MjYgiA_datetime_local_from_natural_datetime_utc(documentLinkObj.d);
            var documentTitle = documentLinkObj.n + "\nUploaded: " + documentUploadMjYgiADateTimeLocalPlainText;
            documentTitlesArray.push(documentTitle);

            if(valueMaskPlainText !== "") {
              valueMaskPlainText += "\n";
            }

            if(JSFUNC.is_number(documentLinkObj.o)) {
              valueMaskPlainText += documentLinkObj.o + ") ";
            }
            valueMaskPlainText +=  "[" + documentDateLocal + "] " + documentLinkObj.n;
          }

          valueMask = (
            <div className="border1ddd">
              {documentLinksArrayOfObjsOrUndefined.map((m_documentLinkObj, m_index) =>
                <div
                  className={"displayFlexRow hoverLighterBlueGradient " + ((m_index > 0) ? ("borderT1eee") : (""))}
                  title={documentTitlesArray[m_index]}>
                  {(JSFUNC.is_number(m_documentLinkObj.o)) &&
                    <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:"1.8em"}}>
                      <div className="flex00a nowrap" style={{height:"1.3em"}}>
                        <font className="font09 fontItalic fontTextLighter">
                          {m_documentLinkObj.o + ")"}
                        </font>
                      </div>
                    </div>
                  }
                  <div className="flex11a displayFlexRowVc tbMicroPad lrPad breakWord">
                    <font className="">
                      {m_documentLinkObj.n}
                    </font>
                  </div>
                  <div className="flex00a displayFlexColumnHcVc lrPad" style={{flexBasis:"6em"}}>
                    <div className="flex00a">
                      <font className="font09 fontItalic fontTextLighter">
                        {documentDatesLocalArray[m_index]}
                      </font>
                    </div>
                    <div className="flex00a" style={{height:"1.3em"}}>
                      <a className="breakWord" target="_blank" rel="noopener noreferrer" href={m_documentLinkObj.l} title={m_documentLinkObj.l} onClick={(event) => event.stopPropagation()}>
                        <font className="font09">{"download"}</font>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
          
          //valueMask has click hyperlinks to online docs, so use plaintext for noclicklinks var
          valueMaskNoClickLinks = valueMaskPlainText;
        }
        else { //documents links JSON could not be parsed, use raw JSON string
          valueMaskPlainText = i_valueRaw;
          valueMask = i_valueRaw;
          valueSort = i_valueRaw;
        }
      }
      else {
        valueMaskPlainText = "--No Document Links--";
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueSort = JSFUNC.sort_max_string();
      }
    }
    else if(i_fieldTypeObj.stylingStringTF) {
      const presetStylingObj = this.get_preset_styling_obj_from_styling_string_comma_list_or_preset_colon_id_string(i_valueRaw);
      isFilledOutTF = presetStylingObj.anyStylingAppliedTF;

      var valueMaskDisplayString = "";
      if(!presetStylingObj.anyStylingAppliedTF) {
        valueMaskPlainText = "--No Styling Applied--";
        valueMaskDisplayString = "--No Styling Applied--";
        valueSort = JSFUNC.sort_max_string();
      }
      else {
        if(presetStylingObj.presetTF) {
          valueMaskPlainText = "Preset '" + presetStylingObj.presetName + "' [" + i_valueRaw + "]";
          valueMaskDisplayString = presetStylingObj.presetName;
        }
        else {
          valueMaskPlainText = "Custom Style [" + i_valueRaw + "]";
          valueMaskDisplayString = "Style";
        }
        valueSort = i_valueRaw;
      }

      valueMask = (
        <font className={i_fieldTypeObj.stylingStringDefaultFontClassName}>
          <CEGeneralReact.StyledTextDiv p_stylingObj={presetStylingObj} p_nowrapTF={true}>
            {valueMaskDisplayString}
          </CEGeneralReact.StyledTextDiv>
        </font>
      );
    }
    else { //if all values are false or undefined
      isFilledOutTF = false;
      valueMaskPlainText = "--";
      valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
      valueSort = "";
    }

    //fill out valueTrueRawIntegrations, which most of the time is a copy of i_valueRaw unless specified in the if tree above
    if(valueTrueRawIntegrations === undefined) {
      valueTrueRawIntegrations = i_valueRaw;
    }

    //fill out valueMaskChangelogPlainText, which most of the time is a copy of valueMaskPlainText unless specified in the if tree above
    if(valueMaskChangelogPlainText === undefined) {
      valueMaskChangelogPlainText = valueMaskPlainText;
    }

    //fill out valueMaskNoClickLinks, which most of the time is a copy of valueMask unless specified in the if tree above
    if(valueMaskNoClickLinks === undefined) {
      valueMaskNoClickLinks = valueMask;
    }

    //fill out valueMaskCsvXmlPlainText, which most of the time is a copy of valueMaskPlainText unless specified in the if tree above
    valueMaskCsvXmlPlainText = valueMaskPlainText;
    if(!fieldTypeObjInputIsUndefinedTF) {
      if(i_fieldTypeObj.valueDisplayIsNumericTF) { //int/decimal/percent/money - excel numeric values need to be raw numbers and excel percents need to have their values divided by 100 (except for zero padded int positive string fields)
        if(i_fieldTypeObj.percentTF) { //percent - modify values to be x/100 for excel, also assume -1 is a flag for N/A and return 0
          if((i_valueRaw === -1) && (i_fieldTypeObj.maskPercentProgressBarShapingTF || i_fieldTypeObj.maskPercentPwinCubeTF)) {
            valueMaskCsvXmlPlainText = 0; //if a progress/pwin field has the N/A flag of -1.00000000, then return 0%;
          }
          else {
            valueMaskCsvXmlPlainText = (i_valueRaw / 100);
          }
        }
        else { //int/decimal/money - return the raw value with no masking (no '1.5%' or '$3,342.39')
          valueMaskCsvXmlPlainText = i_valueRaw;
        }
      }
      else if(i_fieldTypeObj.valueDisplayIsDateOrDateTimeTF) { //date/datetime/relativedate (note: might need to replace early years with 1900 since that is the limit of excel year date formula calculations)
        if(isFilledOutTF) {
          if(i_fieldTypeObj.dateLocalFromRawDateTimeUtcTF || i_fieldTypeObj.dateTimeTF) { //dateLocalFromRawDateTimeUtcTF || dateTimeTF
            valueMaskCsvXmlPlainText = JSFUNC.convert_mysqldatetimeutc_to_excel_datetime_local_or_date_local_if_time_not_set(i_valueRaw);
          }
          else { //dateTF || dateWithRelativeDateTF || dateWithDurationTF
            valueMaskCsvXmlPlainText = valueTrueRawIntegrations; //use the true raw value so that relative date uses "2024-08-05" instead of "9001-01-01", and dateWithDuration uses "2024-08-05" instead of "2024-08-05 00:03:05"
          }
        }
        else { //excel dates that are --not set-- need to return "" instead of "--not set--" to not mess up calculations in the spreadsheet, otherwise return "--Date Not Set--" as a string in valueMaskPlainText
          valueMaskCsvXmlPlainText = "";
        }
      }
    }

    return({
      valueRaw: i_valueRaw,
      valueTrueRawIntegrations: valueTrueRawIntegrations,
      valueMaskPlainText: valueMaskPlainText,
      valueMaskCsvXmlPlainText: valueMaskCsvXmlPlainText,
      valueMaskChangelogPlainText: valueMaskChangelogPlainText,
      valueMask: valueMask,
      valueMaskNoClickLinks: valueMaskNoClickLinks,
      valueSort: valueSort,
      isFilledOutTF: isFilledOutTF
    });
  }




  value_mask_sort_ifo_ishtml_obj_from_select_value_raw_and_select_data_obj(i_selectValueRaw, i_stringToAppendToValueMaskPlainText, i_selectWithSearchDataObj, i_plainTextTF) {
    //based on valuesAreStringsTF in i_selectWithSearchDataObj, i_selectValueRaw is either a string or an id integer attempting to match to a string or integer in valueArray (assumed that it has already been checked that the valueArray in i_selectWithSearchDataObj is a valid array)
    const o_tbl_captures = this.o_tbl_captures
    const c_archivedCaptureIDsArray = this.c_archivedCaptureIDsArray;
    
    //initialize output ifoObj
    var valueMaskPlainText = ""; //valueMaskCsvXmlPlainText and valueMaskChangelogPlainText are always valueMaskPlainText for these cases
    var valueMask = "";
    var valueMaskNoClickLinks = "";
    var valueSort = JSFUNC.sort_max_string();
    var isFilledOutTF = false;
    var valueMaskIsHtmlTF = false;

    if(i_selectWithSearchDataObj === undefined) {
      valueMaskPlainText = "--i_selectWithSearchDataObj is undefined--";
      valueMask = valueMaskPlainText;
      valueMaskNoClickLinks = valueMaskPlainText;
      valueSort = JSFUNC.sort_max_string();
      isFilledOutTF = false;
      valueMaskIsHtmlTF = false;
    }
    else {
      //---------------------------------------------------------------------------------------
      //fill out valueMaskPlainText, valueSort, isFilledOutTF
      if(i_selectWithSearchDataObj.selectCaptureTF && JSFUNC.in_array(i_selectValueRaw, c_archivedCaptureIDsArray)) { //selectCaptureTF - this linked capture is archived and is currently not loaded in this user's local js memory
        valueMaskPlainText = "--This Capture [ID: " + i_selectValueRaw + "] is archived. You can load archived Captures in your User Settings--";
        valueSort = JSFUNC.sort_max_string();
        isFilledOutTF = true;
      }
      else if(i_selectWithSearchDataObj.selectCaptureTF && JSFUNC.select_int_is_filled_out_tf(i_selectValueRaw) && (!o_tbl_captures.has(i_selectValueRaw))) { //selectCaptureTF - this linked capture is not laoded into this user's local js memory, either by division firewall restriction or this capture is deleted
        valueMaskPlainText = "--You do not have permission to view this Capture, or it has been deleted--";
        valueSort = JSFUNC.sort_max_string();
        isFilledOutTF = true;
      }
      
      //find the i_selectValueRaw within the valueArray in i_selectWithSearchDataObj
      const matchIndex = i_selectWithSearchDataObj.valueArray.indexOf(i_selectValueRaw); //the value could potentially be 0 or -1, but if that is one of the values in valueArray, then it is considered filled out
      if(matchIndex >= 0) { //exact string or int match of selectValueRaw was found in valueArray
        //get the corresponding match index entry from displayArray
        valueMaskPlainText = i_selectWithSearchDataObj.displayArray[matchIndex];

        if(valueMaskPlainText === "") { //the selected value has no display text, show this blank message in its place
          valueMaskPlainText = i_selectWithSearchDataObj.unselectedDisplay;
          valueSort = (JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string());
          isFilledOutTF = false;
        }
        else { //success finding the matching value/display
          //since the select valueArray/displayArray are already in the desired sort order (either alphabetic or some hidden numeric), using the found match index as the sort puts them in the correct order
          valueSort = JSFUNC.zero_pad_integer_from_left(matchIndex, 11); //"00000000015" for match index 15, "00000000015,0000000008,0000000001,0000000131" four example item zero padded match index numbers in a comma list (this function would return 0000000015 for one of them), this way multiple items can be sorted among each other and be in order in tiers
          isFilledOutTF = true;
        }
      }
      else { //selectValueRaw was not found in valueArray
        //different criteria for determining if a value was selected (even though it was a value that does not exist in valueArray) vs not being filled out with --No Item Selected-- displayed
        const selectWasFilledOutTF = ((i_selectWithSearchDataObj.valuesAreStringsTF) ? (JSFUNC.text_or_number_is_filled_out_tf(i_selectValueRaw)) : (JSFUNC.select_int_is_filled_out_tf(i_selectValueRaw)));
        if(!selectWasFilledOutTF) { //valueRaw is undefined, "", or -1 or 0
          valueMaskPlainText = i_selectWithSearchDataObj.unselectedDisplay;
          valueSort = (JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string());
          isFilledOutTF = false;
        }
        else { //valueRaw is 'filled out', but not in the valueArray
          valueMaskPlainText = "--" + i_selectWithSearchDataObj.itemName + " Does Not Exist (ID: " + i_selectValueRaw + ")--";
          valueSort = (JSFUNC.sort_max_string() + JSFUNC.sort_max_string());
          isFilledOutTF = false;
        }
      }

      //overwrite plaintext masking if a selectCaptureTF with special conditions
      var selectCaptureSpecialExceptionWithNoLinkTF = false; //if set to true, use the not filled out styling for valueMask, even through isFilledOutTF is true because it is filled out
      if(i_selectWithSearchDataObj.selectCaptureTF) {
        if(JSFUNC.in_array(i_selectValueRaw, c_archivedCaptureIDsArray)) { //selectCaptureTF - this linked capture is archived and is currently not loaded in this user's local js memory
          selectCaptureSpecialExceptionWithNoLinkTF = true;
          valueMaskPlainText = "--This Capture [ID: " + i_selectValueRaw + "] is archived. You can load archived Captures in your User Settings--";
          valueSort = JSFUNC.sort_max_string();
          isFilledOutTF = true;
        }
        else if(JSFUNC.select_int_is_filled_out_tf(i_selectValueRaw) && (!o_tbl_captures.has(i_selectValueRaw))) { //selectCaptureTF - this linked capture is not laoded into this user's local js memory, either by division firewall restriction or this capture is deleted
          selectCaptureSpecialExceptionWithNoLinkTF = true;
          valueMaskPlainText = "--You do not have permission to view this Capture, or it has been deleted--";
          valueSort = JSFUNC.sort_max_string();
          isFilledOutTF = true;
        }
      }

      //if i_stringToAppendToValueMaskPlainText is not undefined, append it to the end of valueMaskPlainText
      if(i_stringToAppendToValueMaskPlainText !== undefined) {
        valueMaskPlainText += i_stringToAppendToValueMaskPlainText;
      }

      //---------------------------------------------------------------------------------------
      //fill out valueMask, valueMaskNoClickLinks, valueMaskIsHtmlTF
      if(i_plainTextTF) { //plaintext flag on, forced non-html mask for title hover text, etc
        valueMask = valueMaskPlainText;
        valueMaskNoClickLinks = valueMaskPlainText;
        valueMaskIsHtmlTF = false;
      }
      else { //plaintext flag off
        if(!isFilledOutTF || selectCaptureSpecialExceptionWithNoLinkTF) { //select is not filled out (or selectCaptureTF has a special error case), mask has italic light text <font> tag around it
          valueMask = this.not_filled_out_font_html(valueMaskPlainText);
          valueMaskNoClickLinks = valueMask; //valueMaskNoClickLinks also has html with <font> tag
          valueMaskIsHtmlTF = true;
        }
        else { //select is filled out, use html mask if needed
          if(i_selectWithSearchDataObj.selectContactCompanyTF || i_selectWithSearchDataObj.selectContactPersonTF) { //contact bubble mask for filled out contact companies/persons
            valueMask = (
              <div className="positionRelative flex00a" style={{width:"15em"}}>
                <ContactsReact.ContactBubble
                  p_isPersonTF={i_selectWithSearchDataObj.selectContactPersonTF}
                  p_contactID={i_selectValueRaw}
                  p_iconSizeEm={undefined}
                  p_fontClass={undefined}
                  p_personMarkInvalidEmailTF={false}
                  f_onClickRemove={undefined}
                />
              </div>
            );
            valueMaskNoClickLinks = valueMaskPlainText; //valueMaskNoClickLinks is plaintext comma list of contact names "Company Name 1, Company Name 2" (since bubble has a click action on it)
            valueMaskIsHtmlTF = true;
          }
          else if(i_selectWithSearchDataObj.selectCaptureTF) { //filled out selectCaptureTF capture name as a clickable link with action to open that single capture
            valueMask = this.capture_name_as_link_to_open_capture_html(i_selectValueRaw, valueMaskPlainText);
            valueMaskNoClickLinks = valueMaskPlainText; //valueMaskNoClickLinks do not display capture link click html
            valueMaskIsHtmlTF = true;
          }
          else if(i_selectWithSearchDataObj.includeColorBlockInValueMaskTF) {
            valueMask = (
              <div className="displayFlexRow">
                <div className="flex00a displayFlexColumnHcVc rMargin">
                  <div className="flex00a border bevelBorderColors borderRadius05" style={{width:"1em", height:"1em", background:"#" + i_selectWithSearchDataObj.colorArray[matchIndex]}} />
                </div>
                <div className="flex11a displayFlexRowVc">
                  <LibraryReact.Nowrap>{valueMaskPlainText}</LibraryReact.Nowrap>
                </div>
              </div>
            );
            valueMaskNoClickLinks = valueMask; //do not display color block html for no click links
            valueMaskIsHtmlTF = true;
          }
          else { //normal masked select display from raw value is naturally plaintext (most common case)
            valueMask = valueMaskPlainText;
            valueMaskNoClickLinks = valueMaskPlainText;
            valueMaskIsHtmlTF = false;
          }
        }
      }
    }

    return({
      valueRaw: i_selectValueRaw,
      valueTrueRawIntegrations: i_selectValueRaw,
      valueMaskPlainText: valueMaskPlainText,
      valueMaskCsvXmlPlainText: valueMaskPlainText, //set valueMaskCsvXmlPlainText as valueMaskPlainText
      valueMaskChangelogPlainText: valueMaskPlainText, //set valueMaskChangelogPlainText as valueMaskPlainText always for these cases
      valueMask: valueMask,
      valueMaskNoClickLinks: valueMaskNoClickLinks,
      valueSort: valueSort,
      isFilledOutTF: isFilledOutTF,
      valueMaskIsHtmlTF: valueMaskIsHtmlTF
    });
  }


  get_company_date_format_from_Ymd_date(i_mysqlDateYmd) {
    //convert directly from input Y-m-d using substring(), avoiding the jsDateObj
    if(this.c_companyDateFormatIsmdYTF) { // m/d/Y
      return(JSFUNC.direct_get_mdY_date_from_Ymd_date(i_mysqlDateYmd));
    }

    if(this.c_companyDateFormatIsdmYTF) { // d/m/Y
      return(JSFUNC.direct_get_dmY_date_from_Ymd_date(i_mysqlDateYmd));
    }

    if(this.c_companyDateFormatIsYmdTF) { //Y-m-d (output matches raw input value format)
      return(i_mysqlDateYmd);
    }

    if(this.c_companyDateFormatIsMjYTF) { // M j, Y
      return(JSFUNC.direct_convert_mysqldate_to_MjY(i_mysqlDateYmd));
    }

    //other company date format
    const jsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(i_mysqlDateYmd);
    return(JSFUNC.date_or_datetime_format_from_jsdateobj(jsDateObj, this.c_companyDateFormat, "", false));
  }


  get_company_date_format_from_Ymdhis_datetimeutc_natural(i_mysqlDateTimeYmdhisNatural) {
    const mysqlLocalDateYmd = JSFUNC.get_Ymd_date_local_from_natural_datetime_utc(i_mysqlDateTimeYmdhisNatural);
    return(this.get_company_date_format_from_Ymd_date(mysqlLocalDateYmd));
  }


  days_until_date_string_and_colors_obj(i_numDaysUntilDate, i_daysUntilColorNumber) {
    const daysUntilDateString = JSFUNC.num_days_weeks_months_years_until_date_or_overdue_string(i_numDaysUntilDate);

    var dateUpcomingColorsArrayOfObjs = [];
    if(i_daysUntilColorNumber === 2) { //dayMdyDaysUntil color 2
      dateUpcomingColorsArrayOfObjs = this.c_dateUpcomingColors2ArrayOfObjs;
    }
    else { //dayMdyDaysUntil color 1
      dateUpcomingColorsArrayOfObjs = this.c_dateUpcomingColors1ArrayOfObjs;
    }

    var bgColor = "444444";
    var fontColor = "ffffff";
    for(let dateUpcomingColorObj of dateUpcomingColorsArrayOfObjs) { //this arrayOfObjs is sorted by days_before_due ASC, so the first time i_numDaysUntilDate is smaller than this value, that is the color
      if(i_numDaysUntilDate <= dateUpcomingColorObj.days_before_due) {
        bgColor = dateUpcomingColorObj.color;
        fontColor = dateUpcomingColorObj.font_color;
        break;
      }
    }

    return({
      daysUntilDateString: daysUntilDateString,
      bgColor: bgColor,
      fontColor: fontColor
    });
  }


  capture_name_as_link_to_open_capture_html(i_captureID, i_captureName) {
    return(
      <font
        className="fontBlueLight fontUnderline cursorPointer"
        title={"Click to open Capture '" + i_captureName + "'"}
        onClick={() => OpenCaptureMobx.a_open_single_capture(i_captureID)}>
        {i_captureName}
      </font>
    );
  }


  not_filled_out_font_html(i_notFilledOutMessage, i_plainTextTF=false) {
    if(i_plainTextTF) { //if the plain text flag is true, return the requested input message without the <font> tag around it (saves from having this if statement every time this is called below), plain text used for PPT templates/reports export and changelog entries
      return(i_notFilledOutMessage);
    }

    return(
      <font className="fontTextLighter fontItalic">
        {i_notFilledOutMessage}
      </font>
    );
  }


  error_font_html(i_errorMessage) {
    return(
      <font className="fontRed">
        {i_errorMessage}
      </font>
    );
  }


  join_plaintext_and_font_tag_wrapped_items_in_font_tag(i_valueMasksArray, i_valueMaskIsHtmlTFArray) {
    return(
      <font>
        {i_valueMasksArray.map((m_valueMask, m_index) =>
          (i_valueMaskIsHtmlTFArray[m_index]) ? (
            (m_index > 0) ? (
              <>
                <font key={"htmlcomma" + m_index}>{", "}</font>
                {m_valueMask}
              </>
            ) : (
              m_valueMask
            )
          ) : (
            <font key={"nothtml" + m_index}>
              {((m_index > 0) ? (", ") : ("")) + m_valueMask}
            </font>
          )
        )}
      </font>
    );
  }




  capture_field_value_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_expandedCaptureFieldMap, i_plainTextTF=false) {
    //a captureFieldValueObj is a valueMaskSortIfoCanEditObj with an extra field holding the expandedCaptureFieldMap

    //if the input expandedCaptureFieldMap is undefined, create a doesNotExist
    var expandedCaptureFieldMap = undefined;
    if(i_expandedCaptureFieldMap === undefined) {
      expandedCaptureFieldMap = this.fetch_expanded_capture_field_map_from_field_id(-1);
    }
    else {
      expandedCaptureFieldMap = i_expandedCaptureFieldMap;
    }

    //compute the captureValueObj using the provided captureMap and this particular field
    var captureFieldValueObj = this.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, expandedCaptureFieldMap, i_plainTextTF);

    //add the expandedCaptureFieldMap as a field to the captureValueObj
    captureFieldValueObj.expandedCaptureFieldMap = expandedCaptureFieldMap;

    return(captureFieldValueObj);
  }


  value_mask_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_expandedCaptureFieldMap) {
    const valueMaskSortIfoCanEditObj = this.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_expandedCaptureFieldMap);
    return(valueMaskSortIfoCanEditObj.valueMask);
  }

  value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_expandedCaptureFieldMap) {
    const plainTextTF = true;
    const valueMaskSortIfoCanEditObj = this.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_expandedCaptureFieldMap, plainTextTF);
    return(valueMaskSortIfoCanEditObj.valueMaskPlainText);
  }


  value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_expandedCaptureFieldMap, i_plainTextTF=false, i_htmlValueMaskMaxHeightEmOrUndefined=undefined) {
    //i_captureMap - a single inner map directly from o_tbl_captures
    //i_expandedCaptureFieldMap - a single inner map from the expanded c_tbl_captures_fields
    //
    //returns a "captureValueObj" based on the captureMap for this particular capture field, essentially a combination between
    //  - a fieldMap (a single capture field from the expanded tbl_captures_fields)
    //  - all information about the single value from that captureMap for this field (masked value, value used for sorting, if the value is considered filled out or empty, if the value can be edited)

    var fieldDbName = "--Unknown Field--";
    var valueSortIsNumericTF = false;
    if(i_expandedCaptureFieldMap !== undefined) {
      fieldDbName = i_expandedCaptureFieldMap.get("db_name");
      var fieldTypeObj = i_expandedCaptureFieldMap.get("fieldTypeObj");
      if(fieldTypeObj !== undefined) {
        valueSortIsNumericTF = fieldTypeObj.valueSortIsNumericTF;
      }
    }

    var invalidValueRawOrUndefined = undefined;
    var invalidValueTrueRawIntegrationsOrUndefined = undefined;
    var invalidValueMaskPlainText = undefined;
    var invalidValueSort = 0; //invalid valueSort 0 for numeric type fields
    if(i_captureMap === undefined) { //if the capture map provided is not valid, return undefined
      invalidValueRawOrUndefined = undefined;
      invalidValueMaskPlainText = "--Capture Does Not Exist--";
      if(!valueSortIsNumericTF) {
        invalidValueSort = JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string();
      }
    }
    else {
      //get the raw value for the specified field from the captureMap
      invalidValueRawOrUndefined = this.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_expandedCaptureFieldMap);
      if(invalidValueRawOrUndefined === undefined) {
        invalidValueMaskPlainText = "--Field Does Not Exist within Capture (dbName: " + fieldDbName + ")--";
        if(!valueSortIsNumericTF) {
          invalidValueSort = JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string() + JSFUNC.sort_max_string();
        }
      }
      else { //final valid masking, applies pre-mask value trim and post-mask overwrites that reference other dependent fields within the capture
        return(this.value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(invalidValueRawOrUndefined, i_captureMap, i_expandedCaptureFieldMap, i_plainTextTF, i_htmlValueMaskMaxHeightEmOrUndefined));
      }
    }

    invalidValueTrueRawIntegrationsOrUndefined = invalidValueRawOrUndefined;

    //invalid case (i_captureMap undefined, i_expandedCaptureFieldMap undefined, or valueRaw fetch undefined)
    const invalidValueMask = this.not_filled_out_font_html(invalidValueMaskPlainText, i_plainTextTF);

    return({
      valueRaw: invalidValueRawOrUndefined,
      valueTrueRawIntegrations: invalidValueTrueRawIntegrationsOrUndefined,
      valueMaskPlainText: invalidValueMaskPlainText,
      valueMaskCsvXmlPlainText: invalidValueMaskPlainText,
      valueMaskChangelogPlainText: invalidValueMaskPlainText,
      valueMask: invalidValueMask,
      valueMaskNoClickLinks: invalidValueMask,
      valueSort: invalidValueSort,
      isFilledOutTF: false,
      canEditValueTF: false
    });
  }



  capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_expandedCaptureFieldMap) {
    //i_captureMap - a single inner map directly from o_tbl_captures
    //i_expandedCaptureFieldMap - a single inner map from the expanded c_tbl_captures_fields
    //
    //returns:
    // - valueRaw (int or string)
    //    - from the captureMap for this particular capture field (for most cases, this is the raw value taken from the captureMap using the provided field db_name, some special fields are calculated or stored in other data maps)
    // - undefined
    //    - i_captureMap undefined
    //    - i_expandedCaptureFieldMap undefined
    //    - all of the i_expandedCaptureFieldMap raw true/false fields were false (every valid system or custom field should have exactly 1 of these flags set to true)

    if(i_captureMap === undefined) {
      return(undefined);
    }

    if(i_expandedCaptureFieldMap === undefined) {
      return(undefined);
    }

    if(i_expandedCaptureFieldMap.get("rawFromCaptureMapNotTextareaTF")) {
      var fieldDbName = i_expandedCaptureFieldMap.get("db_name");
      return(i_captureMap.get(fieldDbName)); //undefined returned if the value is not a field in the captureMap
    }
    else if(i_expandedCaptureFieldMap.get("rawTextareaTF")) { //if this field is a textarea type, the raw value only holds 100 characters with stripped tags plus "...", to get the full textarea with tags, load it from o_singleCaptureFullTextareaFieldsMap
      var fieldDbName = i_expandedCaptureFieldMap.get("db_name");
      var singleCaptureFullTextarea = this.o_singleCaptureFullTextareaFieldsMap.get(fieldDbName); //if a single capture is not currently open (like getting CST table values), this returns the shortened value from the captureMap
      if(singleCaptureFullTextarea !== undefined) {
        return(singleCaptureFullTextarea);
      }
      return(i_captureMap.get(fieldDbName));
    }
    else if(i_expandedCaptureFieldMap.get("rawUpeCaptureFavoritesTF")) { //calculate true/false if the input captureID is in the user's capture favorites list of captureIDs in tbl_a_users_per_email
      var captureID = i_captureMap.get("id");
      var userPerEmailHasFavoritedThisCaptureTF = JSFUNC.in_array(captureID, UserMobx.c_userPerEmailFavoritedCaptureIDsArray);
      return(userPerEmailHasFavoritedThisCaptureTF);
    }
    else if(i_expandedCaptureFieldMap.get("rawSelectPrimeSubTF")) {
      var captureTypeID = i_captureMap.get("capture_type_id");
      var captureTypeMap = this.o_tbl_a_capture_types.get(captureTypeID);
      if(captureTypeMap !== undefined) {
        return(captureTypeMap.get("prime1_sub2")); //returns 1 if capture type is prime, 2 if it is sub
      }
      return(-1); //returns -1 if capture_type_id is an invalid capture type
    }
    else if(i_expandedCaptureFieldMap.get("rawPwinAdjustedContractOverallValueTF")) {
      var captureContractOverallValue = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "contract_overall_value");
      var capturePwinWon100Lost0 = this.capture_pwin_percent_0to100_won100_lost0_from_capture_map(i_captureMap);
      return(captureContractOverallValue * (capturePwinWon100Lost0 / 100));
    }
    else if(i_expandedCaptureFieldMap.get("rawContractRevenueValueTF") || i_expandedCaptureFieldMap.get("rawPwinAdjustedContractRevenueValueTF")) {
      var contractTypeIsIDIQTF = this.contract_type_is_idiq_tf_from_capture_map(i_captureMap);
      if(contractTypeIsIDIQTF) {
        return(0);
      }

      var contractRevenueValue = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "contract_overall_value");
      var captureTypeIsPrimeTF = this.capture_type_is_prime_tf_from_capture_map(i_captureMap);
      if(!captureTypeIsPrimeTF) {
        var ourPrimeSubTeammateAllocation = this.capture_our_prime_sub_teammate_allocation_from_capture_map(i_captureMap);
        contractRevenueValue *= (ourPrimeSubTeammateAllocation / 100);
      }

      if(i_expandedCaptureFieldMap.get("rawPwinAdjustedContractRevenueValueTF")) {
        var capturePwinWon100Lost0 = this.capture_pwin_percent_0to100_won100_lost0_from_capture_map(i_captureMap);
        return(contractRevenueValue * (capturePwinWon100Lost0 / 100));
      }
      return(contractRevenueValue);
    }
    else if(i_expandedCaptureFieldMap.get("rawAllocatedRevenueValueTF") || i_expandedCaptureFieldMap.get("rawAllocatedRevenuePerMonthTF") || i_expandedCaptureFieldMap.get("rawPwinAdjustedAllocatedRevenueValueTF")) {
      var contractTypeIsIDIQTF = this.contract_type_is_idiq_tf_from_capture_map(i_captureMap);
      if(contractTypeIsIDIQTF) {
        return(0);
      }

      var ourPrimeSubTeammateAllocation = this.capture_our_prime_sub_teammate_allocation_from_capture_map(i_captureMap);
      var captureContractOverallValue = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "contract_overall_value");
      var allocatedRevenueValue = (captureContractOverallValue * (ourPrimeSubTeammateAllocation / 100));
      if(i_expandedCaptureFieldMap.get("rawAllocatedRevenuePerMonthTF")) {
        var capturePopMonths = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "period_of_performance");
        if(capturePopMonths <= 0) {
          return(0);
        }
        return(allocatedRevenueValue / capturePopMonths);
      }
      else if(i_expandedCaptureFieldMap.get("rawPwinAdjustedAllocatedRevenueValueTF")) {
        var capturePwinWon100Lost0 = this.capture_pwin_percent_0to100_won100_lost0_from_capture_map(i_captureMap);
        return(allocatedRevenueValue * (capturePwinWon100Lost0 / 100));
      }
      return(allocatedRevenueValue);
    }
    else if(i_expandedCaptureFieldMap.get("rawAllocatedNetValueTF") || i_expandedCaptureFieldMap.get("rawAllocatedNetPerMonthTF") || i_expandedCaptureFieldMap.get("rawPwinAdjustedAllocatedNetValueTF")) {
      var contractTypeIsIDIQTF = this.contract_type_is_idiq_tf_from_capture_map(i_captureMap);
      if(contractTypeIsIDIQTF) {
        return(0);
      }

      var ourPrimeSubTeammateAllocation = this.capture_our_prime_sub_teammate_allocation_from_capture_map(i_captureMap);
      var captureContractOverallValue = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "contract_overall_value");
      var allocatedNetValue = (captureContractOverallValue * (ourPrimeSubTeammateAllocation / 100));

      //subtract costs for this capture type
      var captureTypeID = i_captureMap.get("capture_type_id");
      var captureTypeObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_captureTypesArrayOfObjs, "id", captureTypeID);
      if(captureTypeObj !== undefined) {
        for(let revenueCostFieldDbName of captureTypeObj.revenueCostFieldDbNamesArray) {
          var captureCostValue = i_captureMap.get(revenueCostFieldDbName);
          if(JSFUNC.is_number(captureCostValue)) {
            allocatedNetValue -= captureCostValue;
          }
        }
      }

      if(i_expandedCaptureFieldMap.get("rawAllocatedNetPerMonthTF")) {
        var capturePopMonths = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "period_of_performance");
        if(capturePopMonths <= 0) {
          return(0);
        }
        return(allocatedNetValue / capturePopMonths);
      }
      else if(i_expandedCaptureFieldMap.get("rawPwinAdjustedAllocatedNetValueTF")) {
        var capturePwinWon100Lost0 = this.capture_pwin_percent_0to100_won100_lost0_from_capture_map(i_captureMap);
        return(allocatedNetValue * (capturePwinWon100Lost0 / 100));
      }
      return(allocatedNetValue);
    }
    else if(i_expandedCaptureFieldMap.get("rawNumTeammatesTF")) { //total number of teammates is the sum of the # SB teammates and the # LB teammates
      var captureNumLBTeammates = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "num_lb_teammates");
      var captureNumSBTeammates = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "num_sb_teammates");
      return(captureNumLBTeammates + captureNumSBTeammates);
    }
    else if(i_expandedCaptureFieldMap.get("rawTotalTeamAllocationTF")) {
      var captureTotalLBAllocation = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "total_lb_allocation");
      var captureTotalSBAllocation = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "total_sb_allocation");
      var rawTotalTeamAllocation = (captureTotalLBAllocation + captureTotalSBAllocation);
      var captureTypeIsPrimeTF = this.capture_type_is_prime_tf_from_capture_map(i_captureMap);
      if(captureTypeIsPrimeTF) { //only add in our allocation if the capture type is prime, for sub deals, our allocation is already included in the total lb or total sb allocation value above
        var ourPrimeSubTeammateAllocation = this.capture_our_prime_sub_teammate_allocation_from_capture_map(i_captureMap);
        rawTotalTeamAllocation += ourPrimeSubTeammateAllocation;
      }
      return(rawTotalTeamAllocation);
    }
    else if(i_expandedCaptureFieldMap.get("rawTotalTeamAllocationCovTF")) {
      var captureContractOverallValue = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "contract_overall_value");
      var captureTotalLBAllocation = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "total_lb_allocation");
      var captureTotalSBAllocation = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "total_sb_allocation");
      var rawTotalTeamAllocation = (captureTotalLBAllocation + captureTotalSBAllocation);
      var captureTypeIsPrimeTF = this.capture_type_is_prime_tf_from_capture_map(i_captureMap);
      if(captureTypeIsPrimeTF) { //only add in our allocation if the capture type is prime, for sub deals, our allocation is already included in the total lb or total sb allocation value above
        var ourPrimeSubTeammateAllocation = this.capture_our_prime_sub_teammate_allocation_from_capture_map(i_captureMap);
        rawTotalTeamAllocation += ourPrimeSubTeammateAllocation;
      }

      return(Math.round(captureContractOverallValue * (rawTotalTeamAllocation / 100)));
    }
    else if(i_expandedCaptureFieldMap.get("rawTotalLBAllocationCovTF")) {
      var captureContractOverallValue = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "contract_overall_value");
      var captureTotalLBAllocation = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "total_lb_allocation");
      return(Math.round(captureContractOverallValue * (captureTotalLBAllocation / 100)));
    }
    else if(i_expandedCaptureFieldMap.get("rawTotalSBAllocationCovTF")) {
      var captureContractOverallValue = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "contract_overall_value");
      var captureTotalSBAllocation = this.capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, "total_sb_allocation");
      return(Math.round(captureContractOverallValue * (captureTotalSBAllocation / 100)));
    }
    else if(i_expandedCaptureFieldMap.get("rawRecentPinnedNotestampTF")) {
      var captureID = i_captureMap.get("id");
      var firstPinnedOrMostRecentNoteStampObjOrUndefined = this.capture_most_recent_or_first_pinned_note_stamp_obj_or_undefined_from_capture_id(captureID);
      if(firstPinnedOrMostRecentNoteStampObjOrUndefined !== undefined) {
        var firstNotestampDateYmdLocal = this.get_company_date_format_from_Ymdhis_datetimeutc_natural(firstPinnedOrMostRecentNoteStampObjOrUndefined.created_datetime_utc);
        var firstNotestampContentString = "[" + firstNotestampDateYmdLocal + "] ";
        if(JSFUNC.string_is_filled_out_tf(firstPinnedOrMostRecentNoteStampObjOrUndefined.title)) {
          firstNotestampContentString += firstPinnedOrMostRecentNoteStampObjOrUndefined.title + ": ";
        }
        firstNotestampContentString += "'" + firstPinnedOrMostRecentNoteStampObjOrUndefined.body + "'";
        return(firstNotestampContentString);
      }
      return("");
    }
    else if(i_expandedCaptureFieldMap.get("rawNextActiveTaskTF")) {
      //fetch the next active task map and due date
      var captureID = i_captureMap.get("id");
      var nextActiveTaskMap = undefined;
      var nextActiveTaskDateDue = undefined;
      for(let activeTaskMap of this.o_tbl_u_tasks.values()) {
        var taskCaptureID = activeTaskMap.get("capture_id");
        if(taskCaptureID === captureID) { //this task is assigned to this capture
          var taskDateCompleted = activeTaskMap.get("date_completed");
          if(!JSFUNC.date_is_filled_out_tf(taskDateCompleted)) { //active task, not yet completed
            var taskDateDue = activeTaskMap.get("date_due");
            if(JSFUNC.date_is_filled_out_tf(taskDateDue)) { //due date is filled out
              if((nextActiveTaskDateDue === undefined) || (taskDateDue < nextActiveTaskDateDue)) { //if this task is due earlier than the temporary earliest task, replace it
                nextActiveTaskMap = activeTaskMap;
                nextActiveTaskDateDue = taskDateDue;
              }
            }
          }
        }
      }

      //if an active task was found for this capture, print the due date in brackets, followed by the description
      if(nextActiveTaskMap !== undefined) {
        var nextActiveTaskDueDate = nextActiveTaskMap.get("date_due");
        var nextActiveTaskDescription = nextActiveTaskMap.get("description");
        var nextActiveTaskDueDateMask = this.value_mask_from_value_raw_and_field_type_obj(nextActiveTaskDueDate, this.c_genericDateFieldTypeObj);
        return("[Due: " + nextActiveTaskDueDateMask + "] " + nextActiveTaskDescription);
      }

      //blank string (like text type) signifies that this task is not filled out and --"No Active Tasks--" is masked
      return("");
    }

    return(undefined);
  }


  capture_numeric_value_raw_from_capture_map_and_field_db_name(i_captureMap, i_fieldDbName) {
    //gets the numeric value of the requested field from the capture map, if the field does not exist (undefined), return 0 in place of the value (avoids NaN in post mask computations)
    var captureNumericValueRaw = i_captureMap.get(i_fieldDbName); //assumes i_captureMap has already been verified to not be undefined itself
    if(captureNumericValueRaw === undefined) {
      return(0);
    }
    return(captureNumericValueRaw);
  }


  value_mask_sort_ifo_canedit_obj_from_value_raw_and_capture_map_and_expanded_capture_field_map(i_valueRaw, i_captureMap, i_expandedCaptureFieldMap, i_plainTextTF=false, i_htmlValueMaskMaxHeightEmOrUndefined=undefined) {
    //this is a separate function for the case of updating the value of one of the capture fields and needing to mask it using that new value, not the old value stored in the captureMap for that field (like create new capture process)

    //if the field is a decimal type, trim the number of decimal places as specified in the fieldObj before passing it into masking
    var valueRaw = i_valueRaw;
    if(i_expandedCaptureFieldMap.get("preMaskTrimDecimalsTF")) {
      const fieldNumDecimals = i_expandedCaptureFieldMap.get("num_decimals"); //already been checked that this value is >= 0
      valueRaw = JSFUNC.round_number_to_num_decimals_if_needed(i_valueRaw, fieldNumDecimals);
    }

    //mask the raw value based on the field type (all the true/false flags stored in fieldTypeObj)
    const fieldTypeObj = i_expandedCaptureFieldMap.get("fieldTypeObj");
    const valueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(valueRaw, fieldTypeObj, i_plainTextTF, i_htmlValueMaskMaxHeightEmOrUndefined);
    var valueTrueRawIntegrations = valueMaskSortIfoObj.valueTrueRawIntegrations;
    var valueMaskPlainText = valueMaskSortIfoObj.valueMaskPlainText;
    var valueMaskCsvXmlPlainText = valueMaskSortIfoObj.valueMaskCsvXmlPlainText;
    var valueMaskChangelogPlainText = valueMaskSortIfoObj.valueMaskChangelogPlainText;
    var valueMask = valueMaskSortIfoObj.valueMask;
    var valueMaskNoClickLinks = valueMaskSortIfoObj.valueMaskNoClickLinks;
    var valueSort = valueMaskSortIfoObj.valueSort;
    var isFilledOutTF = valueMaskSortIfoObj.isFilledOutTF;
    var canEditValueTF = i_expandedCaptureFieldMap.get("canEditFieldTF");

    //post processing of the masked value depending on very specific fields that have interrelations to the values of other capture fields in this captureMap
    if(i_expandedCaptureFieldMap.get("postMaskIntPositiveAsZeroPaddedStringTF")) { //the "int_positive" field type double uses the tbl_captures_fields column "num_decimals" to represent specifying the number of digits as a zero padded string
      const numDecimalsOrIntPositiveZeroPaddingNumDigits = i_expandedCaptureFieldMap.get("num_decimals"); //0 or -1 display the int normally as an int, 1 or more converts it into a zero padded string, the sort value remains as the raw int and isFilledOutTF and canEditValueTF retain their original computed values (already been checked that num_decimals > 0)
      valueMaskPlainText = JSFUNC.zero_pad_integer_from_left(i_valueRaw, numDecimalsOrIntPositiveZeroPaddingNumDigits);
      valueMaskCsvXmlPlainText = i_valueRaw; //use raw value int for csv/xml
      valueMaskChangelogPlainText = valueMaskPlainText;
      valueMask = valueMaskPlainText;
      valueMaskNoClickLinks = valueMaskPlainText;
    }
    else if(i_expandedCaptureFieldMap.get("postMaskDateFormatDayMdyDaysUntilTF")) {
      const captureStageIsActiveTF = this.capture_stage_is_active_tf_from_capture_map(i_captureMap);
      if(!captureStageIsActiveTF) { //closed stage, remove overdue boxes
        var dateWithoutDaysUntilFieldTypeObj = JSFUNC.copy_obj(fieldTypeObj);
        dateWithoutDaysUntilFieldTypeObj.maskDateFormatHideDaysUntilBoxTF = true;
        const dateWithoutDaysUntilValueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_valueRaw, dateWithoutDaysUntilFieldTypeObj, i_plainTextTF, i_htmlValueMaskMaxHeightEmOrUndefined);
        valueMaskPlainText = dateWithoutDaysUntilValueMaskSortIfoObj.valueMaskPlainText;
        valueMaskCsvXmlPlainText = dateWithoutDaysUntilValueMaskSortIfoObj.valueMaskCsvXmlPlainText;
        valueMaskChangelogPlainText = dateWithoutDaysUntilValueMaskSortIfoObj.valueMaskChangelogPlainText;
        valueMask = dateWithoutDaysUntilValueMaskSortIfoObj.valueMask;
        valueMaskNoClickLinks = dateWithoutDaysUntilValueMaskSortIfoObj.valueMaskNoClickLinks;
        valueSort = dateWithoutDaysUntilValueMaskSortIfoObj.valueSort;
        isFilledOutTF = dateWithoutDaysUntilValueMaskSortIfoObj.isFilledOutTF;
      }
    }
    else if(i_expandedCaptureFieldMap.get("postMaskSelectIdiqCaptureTF")) { //if this field is the TO IDIQ link and this capture is not a task order contract type, set to uneditable "N/A"
      const captureContractTypeIsTaskOrderTF = this.contract_type_is_task_order_tf_from_capture_map(i_captureMap);
      if(!captureContractTypeIsTaskOrderTF) {
        valueMaskPlainText = "N/A";
        valueMaskCsvXmlPlainText = valueMaskPlainText;
        valueMaskChangelogPlainText = valueMaskPlainText;
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueMaskNoClickLinks = valueMask;
        valueSort = JSFUNC.sort_max_string();
        isFilledOutTF = true;
        canEditValueTF = false;
      }
    }
    else if(i_expandedCaptureFieldMap.get("postMaskReasonsWonLostTF")) { //for reasons won/lost, display N/A if the capture is not in a Won or Lost type stage
      const stageID = i_captureMap.get("stage_id");
      const stageMap = this.c_tbl_a_stages_pool.get(stageID);
      if(stageMap === undefined || (!stageMap.get("isWonStageTF") && !stageMap.get("isLostStageTF"))) { //if this capture is not in a won (4) or lost (5) type stage, set the reasons won/lost as N/A
        valueMaskPlainText = "N/A";
        valueMaskCsvXmlPlainText = valueMaskPlainText;
        valueMaskChangelogPlainText = valueMaskPlainText;
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueMaskNoClickLinks = valueMask;
        valueSort = JSFUNC.sort_max_string();
        isFilledOutTF = true;
        canEditValueTF = false;
      }
    }
    else if(i_expandedCaptureFieldMap.get("postMaskPrimeContactCompanyTF")) {
      const captureTypeIsPrimeTF = this.capture_type_is_prime_tf_from_capture_map(i_captureMap);
      if(captureTypeIsPrimeTF) { //if prime, display the masked values for the capture's 'Our Prime/Sub Teammate Division' field (our_prime_sub_teammate_division_id)
        const ourPrimeSubTeammateDivisionMaskSortIfoCanEditObj = this.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(i_captureMap, this.c_fieldMapOfOurPrimeSubTeammateDivisionID);
        valueMaskPlainText = ourPrimeSubTeammateDivisionMaskSortIfoCanEditObj.valueMaskPlainText;
        valueMaskCsvXmlPlainText = valueMaskPlainText;
        valueMaskChangelogPlainText = valueMaskPlainText;
        valueMask = ((i_plainTextTF) ? (valueMaskPlainText) : (ourPrimeSubTeammateDivisionMaskSortIfoCanEditObj.valueMask));
        valueMaskNoClickLinks = ((i_plainTextTF) ? (valueMaskPlainText) : (ourPrimeSubTeammateDivisionMaskSortIfoCanEditObj.valueMaskNoClickLinks));
        valueSort = ourPrimeSubTeammateDivisionMaskSortIfoCanEditObj.valueSort;
        isFilledOutTF = ourPrimeSubTeammateDivisionMaskSortIfoCanEditObj.isFilledOutTF;
        canEditValueTF = false;
      }
    }
    else if(i_expandedCaptureFieldMap.get("postMaskIncumbentContactCompaniesTF")) { //for selecting contact companies, if the selected contact_company_id value is -2, it is a special flag that the company will be 'our company' top level division name
      if(valueRaw !== "") { //most commonly for captures, this field will be blank, have a quick check before doing any work if this extra post masking is unnecessary
        if(valueRaw === "-2") { //simplest case where the incumbent is only our company, print the top 00 division name of our company for this case
          const ourIncumbentCompetitorDivisionID = i_captureMap.get("our_incumbent_competitor_division_id");
          const ourIncumbentCompetitorDivisionMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(ourIncumbentCompetitorDivisionID, this.c_selectDivisionFieldTypeObj);
          valueMaskPlainText = ourIncumbentCompetitorDivisionMaskSortIfoObj.valueMaskPlainText;
          valueMaskCsvXmlPlainText = valueMaskPlainText;
          valueMaskChangelogPlainText = valueMaskPlainText;
          valueMask = ourIncumbentCompetitorDivisionMaskSortIfoObj.valueMask;
          valueMaskNoClickLinks = ourIncumbentCompetitorDivisionMaskSortIfoObj.valueMaskNoClickLinks;
          valueSort = "0" + ourIncumbentCompetitorDivisionMaskSortIfoObj.valueMaskPlainText; //sort on top of all other lists of incumbents using a 0 in front of the name
          isFilledOutTF = true;
        }
        else {
          const incumbentContactCompanyIDsArray = JSFUNC.convert_comma_list_to_int_array(valueRaw);
          if(JSFUNC.in_array(-2, incumbentContactCompanyIDsArray)) { //if this list of multiple incumbent companies also contains -2 for our company, put our company name first, followed by all the other company names
            const ourIncumbentCompetitorDivisionID = i_captureMap.get("our_incumbent_competitor_division_id");
            const ourIncumbentCompetitorDivisionMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(ourIncumbentCompetitorDivisionID, this.c_selectDivisionFieldTypeObj);
            const incumbentContactCompanyIDsWithoutMinus2Array = JSFUNC.remove_all_values_from_array(-2, incumbentContactCompanyIDsArray);
            const incumbentContactCompanyIDsWithoutMinus2Comma = JSFUNC.convert_array_to_comma_list(incumbentContactCompanyIDsWithoutMinus2Array);
            const incumbentContactCompaniesWithoutUsMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(incumbentContactCompanyIDsWithoutMinus2Comma, this.c_selectMultiContactCompaniesFieldTypeObj);
            valueMaskPlainText = ourIncumbentCompetitorDivisionMaskSortIfoObj.valueMaskPlainText + ", " + incumbentContactCompaniesWithoutUsMaskSortIfoObj.valueMaskPlainText;
            valueMaskCsvXmlPlainText = valueMaskPlainText;
            valueMaskChangelogPlainText = valueMaskPlainText;
            valueMask = valueMaskPlainText;
            valueMaskNoClickLinks = valueMaskPlainText;
            valueSort = "0" + valueMaskPlainText;
            isFilledOutTF = true;
          }
        }
      }

      //incumbent company value cannot be edited on the details card
      canEditValueTF = false;
    }
    else if(i_expandedCaptureFieldMap.get("postMaskGcssIDTF") || i_expandedCaptureFieldMap.get("postMaskGcssGovWinIDStringTF") || i_expandedCaptureFieldMap.get("postMaskGcssFedCompIDStringTF")) {
      isFilledOutTF = JSFUNC.is_number_not_nan_gt_0(valueRaw);
      var dataSourceName = "SAM.gov";
      var notFilledOutSort = JSFUNC.sort_max_mysqli_int();
      var connectedBorderColorClass = "bevelBorderColors";
      var connectedBgClass = "bgLighterOrangeGradient";
      var connectedFontColorClass = "fontDarkOrange";
      var expandedCaptureFieldMap = this.c_fieldMapOfGcssID;
      if(i_expandedCaptureFieldMap.get("postMaskGcssGovWinIDStringTF")) {
        isFilledOutTF = JSFUNC.string_is_filled_out_tf(valueRaw);
        dataSourceName = "GovWin";
        notFilledOutSort = JSFUNC.sort_max_string();
        connectedBorderColorClass = "bevelBorderColorBlue";
        connectedBgClass = "bgLighterBlueGradient";
        connectedFontColorClass = "fontBlue";
        expandedCaptureFieldMap = this.c_fieldMapOfGcssGovWinIDString;
      }
      else if(i_expandedCaptureFieldMap.get("postMaskGcssFedCompIDStringTF")) {
        isFilledOutTF = JSFUNC.string_is_filled_out_tf(valueRaw);
        dataSourceName = "Federal Compass";
        notFilledOutSort = JSFUNC.sort_max_string();
        connectedBorderColorClass = "bevelBorderColors";
        connectedBgClass = "bgGcssFedCompLighterGradient";
        connectedFontColorClass = "fontGcssFedCompDark";
        expandedCaptureFieldMap = this.c_fieldMapOfGcssFedCompIDString;
      }

      if(!isFilledOutTF) { //if GCSS ID not filled out, write message that this is not a GCSS opp
        valueMaskPlainText = "--Not Imported from GCSS " + dataSourceName + "--";
        valueMaskCsvXmlPlainText = valueMaskPlainText;
        valueMaskChangelogPlainText = valueMaskPlainText;
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueMaskNoClickLinks = valueMask;
        valueSort = notFilledOutSort;
      }
      else { //add to mask 'Connected to datasource' and an 'unconnect' button
        valueMaskPlainText = valueRaw + " [Connected to " + dataSourceName + "]";
        valueMaskCsvXmlPlainText = valueMaskPlainText;
        valueMaskChangelogPlainText = valueMaskPlainText;
        valueMask = (
          <div className="displayFlexRowVc flexWrap">
            <div className="flex00a tbMicroMargin lrMargin">
              <font>
                {valueRaw}
              </font>
            </div>
            <div className={"flex00a tbMicroMargin lrMargin border " + connectedBorderColorClass + " borderRadius10 " + connectedBgClass + " tbMicroPad lrMedPad"}>
              <font className={"fontItalic " + connectedFontColorClass}>
                {"Connected to " + dataSourceName}
              </font>
            </div>
            <div className="flex00a lrMargin">
              <CEGeneralReact.ButtonWithConfirmBox
                p_buttonType="hyperlinkUnderline"
                p_buttonText="unconnect"
                p_buttonFontClass="font09"
                p_title={"Use this 'unconnect' button to stop receiving automatic updates from the " + dataSourceName + " opportunity that this Capture is connected to"}
                p_confirmType="confirm"
                p_confirmMessage={["Are you sure you want to unconnect this Capture from the " + dataSourceName + " opportunity?", "[You can always reestablish this connection in the GovCon Smart Search tab using the 'Merge with Existing Capture' button inside of an opportunity]"]}
                f_onClickConfirm={() => { OpenCaptureMobx.a_details_update_field_value(expandedCaptureFieldMap, expandedCaptureFieldMap.get("fieldBlankValue"), i_captureMap); }}
              />
            </div>
          </div>
        );
        valueMaskNoClickLinks = valueMaskPlainText; //no click links has just the plaintext for the CST and Search Result capture tables
      }
    }
    else if(i_expandedCaptureFieldMap.get("postMaskGcssGovWinPriority1to5TF")) {
      if(!JSFUNC.is_number_not_nan_gt_0(valueRaw)) {
        valueMaskPlainText = "--No Priority Specified--";
        valueMaskCsvXmlPlainText = valueMaskPlainText;
        valueMaskChangelogPlainText = valueMaskPlainText;
        valueMask = this.not_filled_out_font_html(valueMaskPlainText, i_plainTextTF);
        valueMaskNoClickLinks = valueMask;
        valueSort = JSFUNC.sort_max_mysqli_int();
        isFilledOutTF = false;
      }
    }

    //if the fieldTypeObj has a selectWithSearchDataObj, but its valueArray is not an array (likely undefined), then set this field as not editable
    if((fieldTypeObj.selectWithSearchDataObj !== undefined) && !JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.valueArray)) {
      canEditValueTF = false;
    }

    return({
      valueRaw: valueRaw,
      valueTrueRawIntegrations: valueTrueRawIntegrations,
      valueMaskPlainText: valueMaskPlainText,
      valueMaskCsvXmlPlainText: valueMaskCsvXmlPlainText,
      valueMaskChangelogPlainText: valueMaskChangelogPlainText,
      valueMask: valueMask,
      valueMaskNoClickLinks: valueMaskNoClickLinks,
      valueSort: valueSort,
      isFilledOutTF: isFilledOutTF,
      canEditValueTF: canEditValueTF
    });
  }






  //create new capture - generate fieldDbName/valueRaw/idsb arrays for new capture with all rules for default/blank values
  create_new_capture_field_names_values_idsb_and_log_arrays_obj_from_new_capture_obj(i_newCaptureObjOrUndefined=undefined, i_setAllLastChangedDatesToTodayUnlessOverwrittenTF=true) {
    //not every capture field dbName in i_newCaptureObjOrUndefined needs to be defined, only the user defined fields filled out (from Create New Capture, GCSS Import, Admin .csv Import, or creation in a connected Integration System)
    //format in the obj is {ce_field_db_name: "ceValueRaw"}
    //
    //i_newCaptureObjOrUndefined = {
    //  capture_managers_ids_colon_percent_comma: "1:100",
    //  division_owners_ids_colon_percent_comma: "3:100",
    //  opportunity_name: "Opp Name",
    //  stage_id: 1,
    //  ...
    //}
    //
    //5 ways that each tbl_captures field can be initialized through this function
    //  1) defined in i_newCaptureObjOrUndefined (Create New Capture, GCSS Import, or Admin .csv Import)
    //  2) an Admin Capture Type default value
    //  3) a special hardcoded default (ourPrimeSubTeammateDivisionIDFieldID, ourIncumbentCompetitorDivisionIDFieldID, ourPrimeSubTeammateAllocationFieldID, primeCompanyFieldID [as IDIQ capture])
    //  4) a set 'last' date to today's date
    //  5) a computed value (like contractEndDate)

    const o_tbl_captures = this.o_tbl_captures;
    const c_tbl_a_capture_types_details_fields = this.c_tbl_a_capture_types_details_fields;
    const c_tbl_captures_fields = this.c_tbl_captures_fields;
    const c_companyPrimeSBCertsCanVaryPerDivisionTF = this.c_companyPrimeSBCertsCanVaryPerDivisionTF;
    const c_companyUsingPwinTF = this.c_companyUsingPwinTF;
    const c_companyPwinIsCalculatedTF = this.c_companyPwinIsCalculatedTF;
    const c_companyPwinCalcMinThreshPwin = this.c_companyPwinCalcMinThreshPwin;
    const c_taskOrderContractTypeIDsArray = this.c_taskOrderContractTypeIDsArray;
    const c_topDivision00ID = this.c_topDivision00ID;
    const c_createNewCaptureAdminDefaultCaptureTypeIDOrM1 = this.c_createNewCaptureAdminDefaultCaptureTypeIDOrM1;
    const c_fieldMapOfCaptureID = this.c_fieldMapOfCaptureID;
    const c_fieldMapOfDivisionOwners = this.c_fieldMapOfDivisionOwners;
    const c_fieldMapOfCaptureType = this.c_fieldMapOfCaptureType;
    const c_fieldMapOfContractType = this.c_fieldMapOfContractType;
    const c_fieldMapOfStage = this.c_fieldMapOfStage;
    const c_fieldMapOfTotalShapingProgress = this.c_fieldMapOfTotalShapingProgress;
    const c_fieldMapOfStageShapingProgress = this.c_fieldMapOfStageShapingProgress;
    const c_fieldMapOfPwin = this.c_fieldMapOfPwin;
    const c_fieldMapOfContractStartDate = this.c_fieldMapOfContractStartDate;
    const c_fieldMapOfPeriodOfPerformance = this.c_fieldMapOfPeriodOfPerformance;
    const c_fieldMapOfContractEndDate = this.c_fieldMapOfContractEndDate;
    const c_fieldMapOfIdiqCaptureIDTOLink = this.c_fieldMapOfIdiqCaptureIDTOLink;
    const c_fieldMapOfOurPrimeSubTeammateDivisionID = this.c_fieldMapOfOurPrimeSubTeammateDivisionID;
    const c_fieldMapOfOurPrimeSubTeammateAllocation = this.c_fieldMapOfOurPrimeSubTeammateAllocation;
    const c_fieldMapOfPrimeCompany = this.c_fieldMapOfPrimeCompany;
    const c_fieldMapOfOurIncumbentCompetitorDivisionID = this.c_fieldMapOfOurIncumbentCompetitorDivisionID;
    const c_fieldMapOfLastChangedDate = this.c_fieldMapOfLastChangedDate;
    const c_fieldMapOfLastStageDate = this.c_fieldMapOfLastStageDate;
    const c_fieldMapOfLastProgressDate = this.c_fieldMapOfLastProgressDate;
    const c_fieldMapOfLastPWinDate = this.c_fieldMapOfLastPWinDate;
    const c_fieldMapOfLastRFPDate = this.c_fieldMapOfLastRFPDate;

    //preload special field id numbers used in this create new process (avoids string comparisons to field db_name values)
    const captureIDFieldID = c_fieldMapOfCaptureID.get("id");
    const divisionOwnersFieldDbName = c_fieldMapOfDivisionOwners.get("db_name");
    const divisionOwnersFieldTypeObj = c_fieldMapOfDivisionOwners.get("fieldTypeObj");
    const captureTypeFieldDbName = c_fieldMapOfCaptureType.get("db_name");
    const captureTypeFieldTypeObj = c_fieldMapOfCaptureType.get("fieldTypeObj");
    const contractTypeFieldDbName = c_fieldMapOfContractType.get("db_name");
    const stageFieldDbName = c_fieldMapOfStage.get("db_name");
    const pwinFieldID = c_fieldMapOfPwin.get("id");
    const contractStartDateFieldID = c_fieldMapOfContractStartDate.get("id");
    const periodOfPerformanceFieldID = c_fieldMapOfPeriodOfPerformance.get("id");
    const contractEndDateFieldID = c_fieldMapOfContractEndDate.get("id");
    const idiqCaptureIDTOLinkFieldID = c_fieldMapOfIdiqCaptureIDTOLink.get("id");
    const idiqCaptureIDTOLinkFieldDbName = c_fieldMapOfIdiqCaptureIDTOLink.get("db_name");
    const idiqCaptureIDTOLinkFieldTypeObj = c_fieldMapOfIdiqCaptureIDTOLink.get("fieldTypeObj");
    const ourPrimeSubTeammateDivisionIDFieldID = c_fieldMapOfOurPrimeSubTeammateDivisionID.get("id");
    const ourPrimeSubTeammateAllocationFieldID = c_fieldMapOfOurPrimeSubTeammateAllocation.get("id");
    const primeCompanyFieldID = c_fieldMapOfPrimeCompany.get("id");
    const ourIncumbentCompetitorDivisionIDFieldID = c_fieldMapOfOurIncumbentCompetitorDivisionID.get("id");
    const lastChangedDateFieldID = c_fieldMapOfLastChangedDate.get("id");
    const lastStageDateFieldID = c_fieldMapOfLastStageDate.get("id");
    const lastProgressDateFieldID = c_fieldMapOfLastProgressDate.get("id");
    const lastPWinDateFieldID = c_fieldMapOfLastPWinDate.get("id");
    const lastRFPDateFieldID = c_fieldMapOfLastRFPDate.get("id");

    const allLastDateFieldIDsArray = [lastChangedDateFieldID, lastStageDateFieldID, lastProgressDateFieldID, lastPWinDateFieldID, lastRFPDateFieldID];

    const todayDateYmd = JSFUNC.now_date();

    //modifiedNewCaptureObj is an obj with fieldDbNames and their set valueRaw values to set for the new Capture, any fields not specified get their default blankValue value used (also default values from the Capture Type are inserted)
    var modifiedNewCaptureObj = {}; //if no input is provided, use a blank obj with no fields defined
    if(i_newCaptureObjOrUndefined !== undefined) {
      modifiedNewCaptureObj = JSFUNC.copy_obj(i_newCaptureObjOrUndefined); //copy the obj as new fields are added/overwritten to this input
    }

    //initialize arrayOfObjs collecting all capture fields and their raw values (defined, default, or blank)
    var allFieldsWithDefinedDefaultOrBlankValueRawArrayOfObjs = [];

    //--------------------------------------
    //force Capture Type - get the valueRaw if provided in the input, otherwise use the first sorted Capture Type in the system (no need to look up the default_value as defining it is circular based on Capture Type), also compute a flag if it is prime for the loop below
    var forcedNewCaptureTypeID = modifiedNewCaptureObj[captureTypeFieldDbName];
    var inputCaptureTypeIsValidTF = false; //initialize as false, no need to compute this if Capture Type is undefined in the modifiedNewCaptureObj
    if(forcedNewCaptureTypeID !== undefined) {
      inputCaptureTypeIsValidTF = this.value_is_filled_out_tf_from_value_raw_and_field_type_obj(forcedNewCaptureTypeID, captureTypeFieldTypeObj);
    }

    //if the user set Capture Type is not a valid choice (or none was specified), try to select the first Capture Type by sort order in the system
    if(!inputCaptureTypeIsValidTF) {
      forcedNewCaptureTypeID = c_createNewCaptureAdminDefaultCaptureTypeIDOrM1; //get the first sorted Capture Type ID
      if(!JSFUNC.is_number_not_nan_gt_0(forcedNewCaptureTypeID)) {
        forcedNewCaptureTypeID = 0; //in case there are 0 Capture Types defined in the Admin system, set the ID to 0 and assume it is prime
      }
    }
    modifiedNewCaptureObj[captureTypeFieldDbName] = forcedNewCaptureTypeID; //insert or overwrite the new Capture Type into the input so that it can be treated like all the other fields in the loop below with the correct raw value

    var newCaptureIsPrimeTF = true; //default Capture Type as a prime type if captureTypeID is not specified in the modifiedNewCaptureObj
    if(JSFUNC.is_number_not_nan_gt_0(forcedNewCaptureTypeID)) {
      newCaptureIsPrimeTF = this.capture_type_is_prime_tf_from_capture_type_id(forcedNewCaptureTypeID);
    }
    //--------------------------------------

    //--------------------------------------
    //force Stage - get the valueRaw if provided in the input, if not a valid stage within the Capture Type determined above, use the first Stage in the order of the Capture Type from the Admin system setup of Stages
    var forcedNewStageID = modifiedNewCaptureObj[stageFieldDbName];
    const allStageIDsWithinCaptureTypeArray = this.get_all_stage_ids_array_from_capture_type_id(forcedNewCaptureTypeID);
    if(!JSFUNC.in_array(forcedNewStageID, allStageIDsWithinCaptureTypeArray)) { //if the input Stage (if any was provided) is one of the Stages of the input Capture Type, use it, otherwise use the first stage
      forcedNewStageID = 0; //if there are 0 stages, use stageID 0
      if(allStageIDsWithinCaptureTypeArray.length > 0) {
        forcedNewStageID = allStageIDsWithinCaptureTypeArray[0];
      }
    }
    modifiedNewCaptureObj[stageFieldDbName] = forcedNewStageID; //insert or overwrite the new Stage into the input so that it can be treated like all the other fields in the loop below with the correct raw value
    //--------------------------------------

    //Contract Type determine flags from input needed in loop below
    const newCaptureContractTypeIDOrUndefined = modifiedNewCaptureObj[contractTypeFieldDbName];
    var newCaptureContractTypeIsTaskOrderTF = false; //default Contract Type if none is specified as not a Task Order
    if(newCaptureContractTypeIDOrUndefined !== undefined) {
      newCaptureContractTypeIsTaskOrderTF = JSFUNC.in_array(newCaptureContractTypeIDOrUndefined, c_taskOrderContractTypeIDsArray); //verify Contract Type is a Task Order
    }

    //IDIQ Capture ID TO Link determine flags from input needed in loop below
    const newCaptureIDIQCaptureIDTOLinkOrUndefined = modifiedNewCaptureObj[idiqCaptureIDTOLinkFieldDbName];
    var newCaptureIDIQLinkContractTypeIsIDIQTF = false; //default new Capture is not TO and thus does not have an IDIQ linked Capture
    var newCaptureSpecifiedIDIQCaptureIDTOLinkTF = false;
    if(newCaptureIDIQCaptureIDTOLinkOrUndefined !== undefined) {
      newCaptureSpecifiedIDIQCaptureIDTOLinkTF = this.value_is_filled_out_tf_from_value_raw_and_field_type_obj(newCaptureIDIQCaptureIDTOLinkOrUndefined, idiqCaptureIDTOLinkFieldTypeObj);
      newCaptureIDIQLinkContractTypeIsIDIQTF = this.contract_type_is_idiq_tf_from_capture_id(newCaptureIDIQCaptureIDTOLinkOrUndefined);
    }

    //Division Owners get value set from input if provided to use in loop below
    const newCaptureDivisionOwnersIDsColonPercentCommaOrUndefined = modifiedNewCaptureObj[divisionOwnersFieldDbName];
    var newCaptureSpecifiedDivisionOwnersTF = false;
    if(newCaptureDivisionOwnersIDsColonPercentCommaOrUndefined !== undefined) {
      newCaptureSpecifiedDivisionOwnersTF = this.value_is_filled_out_tf_from_value_raw_and_field_type_obj(newCaptureDivisionOwnersIDsColonPercentCommaOrUndefined, divisionOwnersFieldTypeObj);
    }

    //add every field in a capture record to this insert using the default empty value for each field idsb, overwrite with the newly created field value if set
    for(let expandedCaptureFieldMap of c_tbl_captures_fields.values()) {
      //load parts of the expanded capture field map ("fieldBlankValue" not needed)
      var fieldID = expandedCaptureFieldMap.get("id");
      var fieldDbName = expandedCaptureFieldMap.get("db_name");
      var fieldDisplayName = expandedCaptureFieldMap.get("display_name");
      var fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");
      var fieldIsTblCapturesColumnTF = expandedCaptureFieldMap.get("isTblCapturesColumnTF");
      var changelogDetailsTF = expandedCaptureFieldMap.get("changelogDetailsTF");
      var changelogStageTF = expandedCaptureFieldMap.get("changelogStageTF");
      var changelogProgressTF = expandedCaptureFieldMap.get("changelogProgressTF");
      var changelogPwinTF = expandedCaptureFieldMap.get("changelogPwinTF");

      var fieldTypeObjIsValidTF = (fieldTypeObj !== undefined);
      var fieldIsNotCaptureIDTF = (fieldID !== captureIDFieldID); //don't use captureID (let the database autoincrement its own new captureID number)

      if(fieldTypeObjIsValidTF && fieldIsTblCapturesColumnTF && fieldTypeObj.idsbIsValidTF && fieldIsNotCaptureIDTF) { //only add fields that have a column in tbl_captures (also don't add invalid "x" idsb values if they show up but that should correlate with fieldIsTblCapturesColumnTF), do not add `id` captureID column even though it's a valid column as it is handled by the autoincrement of the database
        //initialize newValueRaw as undefined, but it is set along with includeNewValueInChangelogTF in every if branch
        var newValueRaw = undefined;
        var includeNewValueInChangelogTF = false; //include values that are filled out (leave off filled out values that don't need to show up in changelog like 'last' date fields)

        //determine the newValueRaw of this field for the new capture (plus changelog entries for fields filled out on the new capture form)
        if(modifiedNewCaptureObj.hasOwnProperty(fieldDbName)) { //this capture field was specified in the input modifiedNewCaptureObj
          newValueRaw = modifiedNewCaptureObj[fieldDbName]; //extract the new valueRaw specified for this field, this overrides any default_value or blankValue logic
          includeNewValueInChangelogTF = true;
        }
        else { //capture field not specified in input, 
          //try to fetch admin default value for this field (under this capture type determined at the top)
          var fieldHasAdminDefaultValueForCaptureTypeTF = false;
          var expandedCaptureTypeDetailsFieldRecordMap = JSFUNC.get_first_map_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "field_id"], [forcedNewCaptureTypeID, fieldID]);
          if(expandedCaptureTypeDetailsFieldRecordMap !== undefined) {
            if(expandedCaptureTypeDetailsFieldRecordMap.get("useDefaultTF")) { //this field has a default value set from the Admin for this Capture Type
              var defaultValueRawOrUndefined = expandedCaptureTypeDetailsFieldRecordMap.get("defaultValueRawOrUndefined"); //get the precalculated translated default value from the database default_value string
              if(defaultValueRawOrUndefined !== undefined) {
                fieldHasAdminDefaultValueForCaptureTypeTF = true;
                newValueRaw = defaultValueRawOrUndefined;
                includeNewValueInChangelogTF = true;
              }
            }
          }

          if(!fieldHasAdminDefaultValueForCaptureTypeTF) {
            if(JSFUNC.in_array(fieldID, [ourPrimeSubTeammateDivisionIDFieldID, ourIncumbentCompetitorDivisionIDFieldID])) { //special hardcoded default for our division id (both teammate and competitor), uses the highest percent value from division owners set in the create new capture form
              if(!c_companyPrimeSBCertsCanVaryPerDivisionTF) {
                newValueRaw = c_topDivision00ID; //top 00 division default if sb certs same for all divisions
              }
              else { //if sb certs can vary per division
                newValueRaw = c_topDivision00ID; //default value if no division owners were specified (top 00 division id)
                if(newCaptureSpecifiedDivisionOwnersTF) {
                  var divisionOwnersArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(newCaptureDivisionOwnersIDsColonPercentCommaOrUndefined, "divID", "percent0to100", false, "percent0to100", false);
                  if(divisionOwnersArrayOfObjs.length > 0) {
                    newValueRaw = divisionOwnersArrayOfObjs[0].divID; //get the first division from the sorted list which has the highest percent ownership
                  }
                }
              }
              includeNewValueInChangelogTF = false; //no changelog entry for our division (team or comp), since its standard per capture per the selected division
            }
            else if(fieldID === ourPrimeSubTeammateAllocationFieldID) { //special hardcoded default values based on selected capture type for the our_prime_sub_teammate_allocation field
              if(newCaptureIsPrimeTF) { //Prime captures
                newValueRaw = 100; //default value of 100% our allocation for all prime deals (and errors where the capture type prime/sub cannot be determined)
                if(newCaptureContractTypeIsTaskOrderTF && newCaptureIDIQLinkContractTypeIsIDIQTF) { //new Task Order captures copy their 'our allocation' percent from it's linked IDIQ
                  var idiqLinkCaptureMapOrUndefined = undefined;
                  if(newCaptureSpecifiedIDIQCaptureIDTOLinkTF) {
                    idiqLinkCaptureMapOrUndefined = o_tbl_captures.get(newCaptureIDIQCaptureIDTOLinkOrUndefined);
                  }
    
                  if(idiqLinkCaptureMapOrUndefined !== undefined) {
                    newValueRaw = this.capture_our_prime_sub_teammate_allocation_from_capture_map(idiqLinkCaptureMapOrUndefined);
                  }
                }
              }
              else { //Sub capture types are always 0%
                newValueRaw = 0;
              }
              includeNewValueInChangelogTF = false; //no changelog entry for our team allocation since its standard at 100 or 0
            }
            else if(fieldID === primeCompanyFieldID) {
              newValueRaw = -1; //no prime company selected as the default (for all prime deals and sub deals not copied from an IDIQ)
              if(!newCaptureIsPrimeTF && newCaptureContractTypeIsTaskOrderTF && newCaptureIDIQLinkContractTypeIsIDIQTF) { //if a sub deal task order with a linked IDIQ
                var idiqLinkCaptureMapOrUndefined = undefined;
                if(newCaptureSpecifiedIDIQCaptureIDTOLinkTF) {
                  idiqLinkCaptureMapOrUndefined = o_tbl_captures.get(newCaptureIDIQCaptureIDTOLinkOrUndefined);
                }
    
                if(idiqLinkCaptureMapOrUndefined !== undefined) {
                  newValueRaw = idiqLinkCaptureMapOrUndefined.get("prime_contact_company_id");
                  includeNewValueInChangelogTF = true;
                }
              }
            }
            else if(i_setAllLastChangedDatesToTodayUnlessOverwrittenTF && JSFUNC.in_array(fieldID, allLastDateFieldIDsArray)) { //if input flag is set to true to set all 'last' dates to today's date (since a new capture is being inserted with this prep function, all last changed dates should initialize to today)
              newValueRaw = todayDateYmd;
              includeNewValueInChangelogTF = false; //no changelog for the 'last' dates being updated to today (since this doesn't happen when a single field is updated either)
            }
            else { //default blank 'not filled out' newValueRaw for all fields non specified, non special fields
              newValueRaw = expandedCaptureFieldMap.get("fieldBlankValue");
              includeNewValueInChangelogTF = false; //no changelog for blank values
            }
          }
        }

        //add a field/valueRaw obj for this field
        allFieldsWithDefinedDefaultOrBlankValueRawArrayOfObjs.push({
          fieldID: fieldID,
          fieldDbName: fieldDbName,
          fieldDisplayName: fieldDisplayName,
          fieldTypeObj: fieldTypeObj,
          changelogDetailsTF: changelogDetailsTF,
          changelogStageTF: changelogStageTF,
          changelogProgressTF: changelogProgressTF,
          changelogPwinTF: changelogPwinTF,
          newValueRaw: newValueRaw,
          includeNewValueInChangelogTF: includeNewValueInChangelogTF
        });
      }
    }

    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
    //other restrictions or modifications for certain tbl_captures fields
    if(c_companyUsingPwinTF && c_companyPwinIsCalculatedTF) { //(save time looping through this loop since there's only 1 thing to check, remove if there's ever 2+ things)
      for(let fieldWithValueRawObj of allFieldsWithDefinedDefaultOrBlankValueRawArrayOfObjs) {
        //for PWin, if the value (set or default or blank) is below the calculated pwin minimum value, set it to that minimum
        if(fieldWithValueRawObj.fieldID === pwinFieldID) {
          if(c_companyUsingPwinTF && c_companyPwinIsCalculatedTF) {
            if(fieldWithValueRawObj.newValueRaw < c_companyPwinCalcMinThreshPwin) {
              fieldWithValueRawObj.newValueRaw = c_companyPwinCalcMinThreshPwin;
            }
          }
        }
      }
    }
    //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

    //++++++++++++++++++++++++++++++++++++
    //calculated capture fields (noneditable capturefields that have a column in tbl_captures, computed using other editable/importable capture fields, done here after other loops because all field values must be known before this computation)
    for(let fieldWithValueRawObj of allFieldsWithDefinedDefaultOrBlankValueRawArrayOfObjs) {
      if(fieldWithValueRawObj.fieldID === contractEndDateFieldID) { //contract_end_date calculated field
        //load CSD valueRaw from above loop
        var csdFieldWithValueRawObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(allFieldsWithDefinedDefaultOrBlankValueRawArrayOfObjs, "fieldID", contractStartDateFieldID);
        if(csdFieldWithValueRawObjOrUndefined !== undefined) {
          if(JSFUNC.date_is_filled_out_tf(csdFieldWithValueRawObjOrUndefined.newValueRaw)) {
            //load PoP valueRaw from above loop
            var popFieldWithValueRawObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(allFieldsWithDefinedDefaultOrBlankValueRawArrayOfObjs, "fieldID", periodOfPerformanceFieldID);
            if(popFieldWithValueRawObjOrUndefined !== undefined) {
              if(JSFUNC.is_number_not_nan_gte_0(popFieldWithValueRawObjOrUndefined.newValueRaw)) {
                //compute calculated CED from CSD + PoP
                fieldWithValueRawObj.newValueRaw = JSFUNC.compute_end_date_from_start_date_and_num_months(csdFieldWithValueRawObjOrUndefined.newValueRaw, popFieldWithValueRawObjOrUndefined.newValueRaw);
              }
            }
          }
        }
      }
    }
    //++++++++++++++++++++++++++++++++++++

    //initialize output arrays and capture raw values
    var fieldIDsArray = [];
    var fieldDbNamesArray = [];
    var rawValuesArray = [];
    var idsbArray = [];
    var changelogInsertsArrayOfObjs = []; //sends filled out values to the details (or stage or progress or pwin) changelogs
    var newCaptureIDIQCaptureIDTOLink = undefined;
    var newCaptureSelectedPrimeContactCompanyID = -1; //default that no Prime Contact Company is selected for the Teammates card
    var newCaptureOurPrimeSubTeammateAllocationPercent0to100 = 0; //record the Our Allocation value for this imported capture to that the prime teammate record can have an allocation of (100 - ourAlloc)

    for(let fieldWithValueRawObj of allFieldsWithDefinedDefaultOrBlankValueRawArrayOfObjs) {
      //add the extracted/computed values for this field to the output arrays
      fieldIDsArray.push(fieldWithValueRawObj.fieldID);
      fieldDbNamesArray.push(fieldWithValueRawObj.fieldDbName);
      rawValuesArray.push(fieldWithValueRawObj.newValueRaw);
      idsbArray.push(fieldWithValueRawObj.fieldTypeObj.idsb);

      //add all filled out capture details changelog entries
      if(fieldWithValueRawObj.includeNewValueInChangelogTF) {
        var newValueMaskSortIfoObj = this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(fieldWithValueRawObj.newValueRaw, fieldWithValueRawObj.fieldTypeObj, true); //mask the new value to determine if it is filled out and get the mask for the changelog (only need plaintext, so the i_plainTextTF flag can be set to true)
        if(newValueMaskSortIfoObj.isFilledOutTF) { //no need for changelog entry if the initial value from the input is it's not filled out value
          changelogInsertsArrayOfObjs.push({
            fieldID: fieldWithValueRawObj.fieldID,
            fieldDisplayName: fieldWithValueRawObj.fieldDisplayName,
            changelogDetailsTF: fieldWithValueRawObj.changelogDetailsTF,
            changelogStageTF: fieldWithValueRawObj.changelogStageTF,
            changelogProgressTF: fieldWithValueRawObj.changelogProgressTF,
            changelogPwinTF: fieldWithValueRawObj.changelogPwinTF,
            newValueRaw: fieldWithValueRawObj.newValueRaw,
            newValueMaskChangelogPlainText: newValueMaskSortIfoObj.valueMaskChangelogPlainText
          });
        }
      }

      //store the values for particular capture fields to use below in post processing code
      if(fieldWithValueRawObj.fieldID === idiqCaptureIDTOLinkFieldID) { newCaptureIDIQCaptureIDTOLink = fieldWithValueRawObj.newValueRaw; }
      else if(fieldWithValueRawObj.fieldID === primeCompanyFieldID) { newCaptureSelectedPrimeContactCompanyID = fieldWithValueRawObj.newValueRaw; }
      else if(fieldWithValueRawObj.fieldID === ourPrimeSubTeammateAllocationFieldID) { newCaptureOurPrimeSubTeammateAllocationPercent0to100 = fieldWithValueRawObj.newValueRaw; }
    }

    return({
      fieldIDsArray: fieldIDsArray,
      fieldDbNamesArray: fieldDbNamesArray,
      rawValuesArray: rawValuesArray,
      idsbArray: idsbArray,
      changelogInsertsArrayOfObjs: changelogInsertsArrayOfObjs,
      newCaptureIDIQCaptureIDTOLink: newCaptureIDIQCaptureIDTOLink,
      newCaptureContractTypeIsTaskOrderTF: newCaptureContractTypeIsTaskOrderTF,
      newCaptureIDIQLinkContractTypeIsIDIQTF: newCaptureIDIQLinkContractTypeIsIDIQTF,
      newCaptureSelectedPrimeContactCompanyID: newCaptureSelectedPrimeContactCompanyID,
      newCaptureOurPrimeSubTeammateAllocationPercent0to100: newCaptureOurPrimeSubTeammateAllocationPercent0to100
    });
  }









  //capture name format
  capture_name_plaintext_from_capture_id(i_captureID, i_forceCaptureIDInNameTFU=undefined) {
    const captureMap = this.o_tbl_captures.get(i_captureID);
    if(captureMap !== undefined) {
      return(this.capture_name_plaintext_from_capture_map(captureMap, i_forceCaptureIDInNameTFU));
    }
    return("--Capture Does Not Exist (ID:" + i_captureID + ")--");
  }

  capture_name_plaintext_from_capture_map(i_captureMap, i_forceCaptureIDInNameTFU=undefined) {
    //i_captureMap requires 3 fields to be set: "id", "codename", "opportunity_name"
    //output is always plaintext

    if(i_captureMap === undefined) {
      return("--Capture Does Not Exist--");
    }

    //fetching these 3 capture fields from the map must be done manually, trying to access c_fieldMapOfCaptureID/Codename/OpportunityName results in a MobX cycle for the IDIQ/TO Link field when building the select full of linked IDIQ capture names which call this function to create the names (that list of fields in c_tbl_captures_fields hasn't been fully built yet while building it)
    const captureID = i_captureMap.get("id");
    const captureCodename = i_captureMap.get("codename");
    const captureOpportunityName = i_captureMap.get("opportunity_name");

    return(this.capture_name_plaintext_from_capture_id_codename_opportunity_name(captureID, captureCodename, captureOpportunityName, i_forceCaptureIDInNameTFU));
  }

  capture_name_plaintext_from_capture_id_codename_opportunity_name(i_captureID, i_captureCodename, i_captureOpportunityName, i_forceCaptureIDInNameTFU=undefined) {
    //i_forceCaptureIDInNameTFU
    //  - true        regardless of c_companyCaptureNameFormat, the output capture name will have [ID: X] put at the beginning (used in delete multiple captures in capture table)
    //  - false       regardless of c_companyCaptureNameFormat, the output capture name will never have [ID: X] put at the beginning (could be used for sorting by name alphabetically)
    //  - undefined   inclusion of [ID: X] is dependent on c_companyCaptureNameFormat

  	//c_companyCaptureNameFormat:
  	//	"cn(opp)|cn|opp|id" / "[id]cn(opp)|[id]cn|[id]opp|[id]"
  	//	"opp(cn)|opp|cn|id" / "[id]opp(cn)|[id]opp|[id]cn|[id]"
  	//	"cn|opp|id" / "[id]cn|[id]opp|[id]"
  	//	"opp|cn|id" / "[id]opp|[id]cn|[id]"
  	//	"cn|id" / "[id]cn|[id]"
  	//	"opp|id" / "[id]opp|[id]"
  	//	"id"

    const c_companyCaptureNameFormat = this.c_companyCaptureNameFormat;
    const c_captureIDFieldZeroPaddingNumDigitsOrUndefined = this.c_captureIDFieldZeroPaddingNumDigitsOrUndefined;
    
    var captureIDPlainText = i_captureID; //manually replicate the process of determining if the captureID field is using zero padding display as a string or the normal raw int value
    if(captureIDPlainText === undefined) {
      captureIDPlainText = "--";
    }
    else {
      if(c_captureIDFieldZeroPaddingNumDigitsOrUndefined !== undefined) {
        captureIDPlainText = JSFUNC.zero_pad_integer_from_left(captureIDPlainText, c_captureIDFieldZeroPaddingNumDigitsOrUndefined);
      }
    }

    const captureIDString = "[Capture ID: " + captureIDPlainText + "]";

    const hasCodenameTF = JSFUNC.text_or_number_is_filled_out_tf(i_captureCodename);
    const hasOppNameTF = JSFUNC.text_or_number_is_filled_out_tf(i_captureOpportunityName);

    var cocoiTF = false;
    var cocoiIDTF = false;
    var ocociTF = false;
    var ocociIDTF = false;
    var coiTF = false;
    var coiIDTF = false;
    var ociTF = false;
    var ociIDTF = false;
    var ciTF = false;
    var ciIDTF = false;
    var oiTF = false;
    var oiIDTF = false;
    var iTF = false;
    if(c_companyCaptureNameFormat === "cn(opp)|cn|opp|id") { cocoiTF = true; }
    else if(c_companyCaptureNameFormat === "[id]cn(opp)|[id]cn|[id]opp|[id]") { cocoiIDTF = true; }
    else if(c_companyCaptureNameFormat === "opp(cn)|opp|cn|id") { ocociTF = true; }
    else if(c_companyCaptureNameFormat === "[id]opp(cn)|[id]opp|[id]cn|[id]") { ocociIDTF = true; }
    else if(c_companyCaptureNameFormat === "cn|opp|id") { coiTF = true; }
    else if(c_companyCaptureNameFormat === "[id]cn|[id]opp|[id]") { coiIDTF = true; }
    else if(c_companyCaptureNameFormat === "opp|cn|id") { ociTF = true; }
    else if(c_companyCaptureNameFormat === "[id]opp|[id]cn|[id]") { ociIDTF = true; }
    else if(c_companyCaptureNameFormat === "cn|id") { ciTF = true; }
    else if(c_companyCaptureNameFormat === "[id]cn|[id]") { ciIDTF = true; }
    else if(c_companyCaptureNameFormat === "opp|id") { oiTF = true; }
    else if(c_companyCaptureNameFormat === "[id]opp|[id]") { oiIDTF = true; }
    else if(c_companyCaptureNameFormat === "id") { iTF = true; }

  	//formats that start with [id]
  	var blankOrID = "";
    if(i_forceCaptureIDInNameTFU !== false) { //false never includes the [ID: X]
      if(i_forceCaptureIDInNameTFU || (cocoiIDTF || ocociIDTF || coiIDTF || ociIDTF || ciIDTF || oiIDTF)) { //true always includes it, undefined includes it if the company format has [id] specified
        blankOrID = "[ID: " + captureIDPlainText + "] ";
    	}
    }

    if(cocoiTF || cocoiIDTF) {
      if(hasCodenameTF && hasOppNameTF) { return(blankOrID + i_captureCodename + " (" + i_captureOpportunityName + ")"); }
      else if(hasCodenameTF) { return(blankOrID + i_captureCodename); }
      else if(hasOppNameTF) { return(blankOrID + i_captureOpportunityName); }
      return(captureIDString);
    }
    else if(ocociTF || ocociIDTF) {
      if(hasOppNameTF && hasCodenameTF) { return(blankOrID + i_captureOpportunityName + " (" + i_captureCodename + ")"); }
      else if(hasOppNameTF) { return(blankOrID + i_captureOpportunityName); }
      else if(hasCodenameTF) { return(blankOrID + i_captureCodename); }
      return(captureIDString);
    }
    else if(coiTF || coiIDTF) {
      if(hasCodenameTF) { return(blankOrID + i_captureCodename); }
      else if(hasOppNameTF) { return(blankOrID + i_captureOpportunityName); }
      return(captureIDString);
    }
    else if(ociTF || ociIDTF) {
      if(hasOppNameTF) { return(blankOrID + i_captureOpportunityName); }
      else if(hasCodenameTF) { return(blankOrID + i_captureCodename); }
      return(captureIDString);
    }
    else if(ciTF || ciIDTF) {
      if(hasCodenameTF) { return(blankOrID + i_captureCodename); }
      return(captureIDString);
    }
    else if(oiTF || oiIDTF) {
      if(hasOppNameTF) { return(blankOrID + i_captureOpportunityName); }
      return(captureIDString);
    }
    else if(iTF) {
      return(captureIDString);
    }

    return("--Invalid Capture Name Format '" + c_companyCaptureNameFormat + "'--");
  }



  capture_manager_user_ids_array_from_capture_id(i_captureID) {
    const captureMap = this.o_tbl_captures.get(i_captureID);
    return(this.capture_manager_user_ids_array_from_capture_map(captureMap));
  }

  capture_manager_user_ids_array_from_capture_map(i_captureMap) {
    var captureManagerUserIDsArray = [];
    if(i_captureMap !== undefined) {
      const captureManagerIDsColonPercentComma = this.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, this.c_fieldMapOfCaptureManagers);
      const captureManagersUserIDsPercentsArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(captureManagerIDsColonPercentComma, "id", "percent0to100", false, "percent0to100", false);
      captureManagerUserIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(captureManagersUserIDsPercentsArrayOfObjs, "id");
    }
    return(captureManagerUserIDsArray);
  }


  division_owner_division_ids_array_from_capture_map(i_captureMap) {
    var divisionOwnerDivisionIDsArray = [];
    if(i_captureMap !== undefined) {
      const divisionOwnerIDsColonPercentComma = this.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, this.c_fieldMapOfDivisionOwners);
      const divisionOwnerDivisionIDsPercentsArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(divisionOwnerIDsColonPercentComma, "id", "percent0to100", false, "percent0to100", false);
      divisionOwnerDivisionIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(divisionOwnerDivisionIDsPercentsArrayOfObjs, "id");
    }
    return(divisionOwnerDivisionIDsArray);
  }


  capture_type_is_prime_tf_from_capture_map(i_captureMap) {
    if(i_captureMap !== undefined) {
      const captureTypeID = i_captureMap.get("capture_type_id");
      return(this.capture_type_is_prime_tf_from_capture_type_id(captureTypeID));
    }
    return(true); //assumed prime if capture type does not exist or captureMap is undefined
  }

  capture_type_is_prime_tf_from_capture_type_id(i_captureTypeID) {
    const captureTypeMap = this.o_tbl_a_capture_types.get(i_captureTypeID);
    if(captureTypeMap !== undefined) {
      return(captureTypeMap.get("prime1_sub2") !== 2);
    }
    return(true); //assumed prime if capture type does not exist or captureMap is undefined
  }


  contract_type_is_idiq_tf_from_capture_id(i_captureID) {
    return(this.contract_type_is_idiq_tf_from_capture_map(this.o_tbl_captures.get(i_captureID)));
  }

  contract_type_is_idiq_tf_from_capture_map(i_captureMap) {
    if(i_captureMap !== undefined) {
      const contractTypeID = i_captureMap.get("contract_type_id");
      return(JSFUNC.in_array(contractTypeID, this.c_idiqContractTypeIDsArray));
    }
    return(false);
  }

  contract_type_is_task_order_tf_from_capture_map(i_captureMap) {
    if(i_captureMap !== undefined) {
      const contractTypeID = i_captureMap.get("contract_type_id");
      return(JSFUNC.in_array(contractTypeID, this.c_taskOrderContractTypeIDsArray));
    }
    return(false);
  }


  capture_stage_is_active_tf_from_capture_map(i_captureMap) {
    if(i_captureMap !== undefined) {
      const stageID = i_captureMap.get("stage_id");
      return(JSFUNC.in_array(stageID, this.c_activeStageIDsArray));
    }
    return(false);
  }

  capture_stage_is_closed_won_tf_from_capture_map(i_captureMap) {
    if(i_captureMap !== undefined) {
      const stageID = i_captureMap.get("stage_id");
      return(JSFUNC.in_array(stageID, this.c_closedWonStageIDsArray));
    }
    return(false);
  }


  capture_pwin_percent_0to100_won100_lost0_from_capture_map(i_captureMap) {
    if(i_captureMap === undefined) {
      return(0);
    }

    const captureStageID = i_captureMap.get("stage_id");

    //closed won stage captures are 100% PWin
    if(JSFUNC.in_array(captureStageID, this.c_closedWonStageIDsArray)) {
      return(100);
    }

    //closed lost, no bid, or cancelled stage captures are 0% PWin
    if(JSFUNC.in_array(captureStageID, this.c_lostNoBidOrCancelledStageIDsArray)) {
      return(0);
    }

    const capturePWinValueRaw = i_captureMap.get("pwin");

    //force negative N/A pwin values to 0 so that revenue card and divexec graph values are $0
    if((capturePWinValueRaw === undefined) || (capturePWinValueRaw < 0)) {
      return(0);
    }

    return(capturePWinValueRaw);
  }


  capture_our_prime_sub_teammate_allocation_from_capture_map(i_captureMap) {
    const ourPrimeSubTeammateAllocation = i_captureMap.get("our_prime_sub_teammate_allocation");
    if(JSFUNC.is_number(ourPrimeSubTeammateAllocation)) {
      return(ourPrimeSubTeammateAllocation);
    }
    return(0); //returned if capture value is undefined or not a number
  }


  capture_sorted_pinned_then_most_recent_created_note_stamps_arrayOfObjs_from_capture_id(i_captureID) {
    const o_tbl_c_notepad_note_stamps = this.o_tbl_c_notepad_note_stamps;

    const mostRecentlyCreatedCaptureNoteStampsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_c_notepad_note_stamps, "capture_id", i_captureID, "created_datetime_utc", false);
    const pinnedSortedCaptureNoteStampsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(mostRecentlyCreatedCaptureNoteStampsArrayOfObjs, "pinned_01", 1, "sort", true);
    const mostRecentlyCreatedUnpinnedCaptureNoteStampsArrayOfObs = JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_not_matching_single_field_value(mostRecentlyCreatedCaptureNoteStampsArrayOfObjs, "pinned_01", 1);
    return(JSFUNC.concat_arrays_or_values_into_new_array(pinnedSortedCaptureNoteStampsArrayOfObjs, mostRecentlyCreatedUnpinnedCaptureNoteStampsArrayOfObs));
  }


  capture_most_recent_or_first_pinned_note_stamp_obj_or_undefined_from_capture_id(i_captureID) {
    const pinnedSortedThenMostRecentCreatedCaptureNoteStampsArrayOfObjs = this.capture_sorted_pinned_then_most_recent_created_note_stamps_arrayOfObjs_from_capture_id(i_captureID);
    if(pinnedSortedThenMostRecentCreatedCaptureNoteStampsArrayOfObjs.length > 0) {
      return(pinnedSortedThenMostRecentCreatedCaptureNoteStampsArrayOfObjs[0]);
    }
    return(undefined);
  }




  //computing which users cannot access a capture based on division firewalling rules
  get_user_ids_array_that_cannot_access_single_capture_from_user_ids_array_and_capture_id(i_userIDsArray, i_captureID) {
    //from a full list of userIDs (i_userIDsArray), compute and return which userIDs would be firewalled off from the given captureID
    //this is used to remove contracts managers from the select list on captures (if they could be selected, they would get a notification to do work on a capture they can't load in their system)
    const captureMap = this.o_tbl_captures.get(i_captureID); //get the captureMap of the input captureID
    var userIDsArrayThatCannotAccessSingleCapture = [];
    for(let userID of i_userIDsArray) { //for each user, add their userID to the output array if the division firewalling cannot access the capture AND they don't have capture manager ownership of the capture
      var userCanAccessCaptureTF = this.user_can_access_capture_tf_from_capture_map_and_user_id(captureMap, userID);
      if(!userCanAccessCaptureTF) {
        userIDsArrayThatCannotAccessSingleCapture.push(userID);
      }
    }
    return(userIDsArrayThatCannotAccessSingleCapture);
  }

  user_can_access_capture_tf_from_capture_map_and_user_id(i_captureMap, i_userID) {
    //user can access a capture if:
    // 1) user is one of the capture managers OR
    // 2) if division firewalling is turned on and used for their division, and those firewalled divisions match any capture division owners

    //if the input captureMap is undefined (capture wasn't loaded in the logged in user's system), assume no one has access to it
    if(i_captureMap === undefined) {
      return(false);
    }

    //every user can access every capture in the system if there is no division firewalling
    if(!this.c_companyUsingDivisionFirewallingTF) {
      return(true);
    }

    //check if the user is a capture manager on this capture
    const captureManagerUserIDsArray = this.capture_manager_user_ids_array_from_capture_map(i_captureMap);
    if(JSFUNC.in_array(i_userID, captureManagerUserIDsArray)) {
      return(true);
    }

    //otherwise, have to check division firewalling against the capture division owners, get the combined userMap of the userID to get the user's divisionID
    var combinedUserMap = this.c_tbl_a_users.get(i_userID);
    if(combinedUserMap === undefined) { //if this user doesn't exist, return that they can't access this capture
      return(false);
    }

    //get the divisionMap of the user to determine firewalled divisionIDs
    var userDivisionID = combinedUserMap.get("division_id");
    var expandedDivisionMap = this.c_tbl_a_divisions.get(userDivisionID);
    if(expandedDivisionMap === undefined) { //if the user's division doesn't exist, return that they can't access this capture
      return(false);
    }

    //get division firewall rules
    var usersDivisionIsTopDivision00TF = expandedDivisionMap.get("isTopDivision00TF");
    var usersDivisionCalcFirewalledDivisionIDsArray = expandedDivisionMap.get("calcFirewalledDivisionIDsArray");
    var usersDivisionCalcFirewalledDivisionsIsFilledOutTF = expandedDivisionMap.get("calcFirewalledDivisionsIsFilledOutTF");
    var usersDivisionAllDivisionIDsAreSelectedForFirewallingTF = expandedDivisionMap.get("allDivisionIDsAreSelectedForFirewallingTF");

    //if user's division is the top 00 division, this user has access to every capture
    if(usersDivisionIsTopDivision00TF) {
      return(true);
    }

    //if every divisionID is selected for firewalling, then this user has access to every capture
    if(usersDivisionAllDivisionIDsAreSelectedForFirewallingTF) {
      return(true);
    }

    //if 0 divisions are set for firewalling, this user will load 0 captures, so definitely can't access this capture
    if(!usersDivisionCalcFirewalledDivisionsIsFilledOutTF) {
      return(false);
    }

    //user's division has some divisionIDs firewalled, but not all or none of them, get the capture division owners divisionIDs
    var divisionOwnerDivisionIDsArray = this.division_owner_division_ids_array_from_capture_map(i_captureMap);

    //check for firewalled division access to this capture
    return(JSFUNC.any_of_array1_is_in_array2(divisionOwnerDivisionIDsArray, usersDivisionCalcFirewalledDivisionIDsArray));
  }


  user_full_name_plaintext_from_user_per_email_id(i_userPerEmailID) {
    const c_productStylingObj = CaptureExecMobx.c_productStylingObj;

    const combinedUserMap = JSFUNC.get_first_map_matching_field_value(this.c_tbl_a_users, "user_per_email_id", i_userPerEmailID);
    if(combinedUserMap !== undefined) {
      return(combinedUserMap.get("fullName"));
    }
    return("--" + c_productStylingObj.productName + " User Does Not Exist (upeID: " + i_userPerEmailID + ")--");
  }

  user_name_mask_sort_ifo_obj_from_user_id(i_userID) {
    return(this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_userID, this.c_selectUserFieldTypeObj));
  }
  user_name_mask_from_user_id(i_userID) {
    return(this.value_mask_from_value_raw_and_field_type_obj(i_userID, this.c_selectUserFieldTypeObj));
  }
  user_name_mask_plaintext_from_user_id(i_userID) {
    return(this.value_mask_plaintext_from_value_raw_and_field_type_obj(i_userID, this.c_selectUserFieldTypeObj));
  }

  user_is_filled_out_tf_from_user_id(i_userID) {
    return(this.value_is_filled_out_tf_from_value_raw_and_field_type_obj(i_userID, this.c_selectUserFieldTypeObj));
  }

  user_name_mask_plaintext_from_user_id_and_alt_user_name_if_not_filled_out(i_userID, i_altUserNamePlainTextIfNotFilledOut) { //used when masking tbls that have 2 columns for user_id and the user_name recorded at the time the record was inserted (where the user's name may have changed or the user deleted since then)
    const userNameMaskSortIfoObj = this.user_name_mask_sort_ifo_obj_from_user_id(i_userID);
    if(userNameMaskSortIfoObj.isFilledOutTF) {
      return(userNameMaskSortIfoObj.valueMaskPlainText);
    }
    return(i_altUserNamePlainTextIfNotFilledOut);
  }

  user_names_comma_mask_sort_ifo_obj_from_user_ids_comma(i_userIDsComma) {
    return(this.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(i_userIDsComma, this.c_selectMultiUsersFieldTypeObj));
  }
  user_names_comma_mask_from_user_ids_comma(i_userIDsComma) {
    return(this.value_mask_from_value_raw_and_field_type_obj(i_userIDsComma, this.c_selectMultiUsersFieldTypeObj));
  }
  user_names_comma_mask_plaintext_from_user_ids_comma(i_userIDsComma) {
    return(this.value_mask_plaintext_from_value_raw_and_field_type_obj(i_userIDsComma, this.c_selectMultiUsersFieldTypeObj));
  }





  //capture type stages
  get_all_stage_ids_array_from_capture_type_id(i_captureTypeID) {
    if(i_captureTypeID !== undefined) {
      const selectStagesWithinCaptureTypeFieldTypeObj = this.create_select_stages_field_type_obj_within_capture_type_id(i_captureTypeID);
      if(selectStagesWithinCaptureTypeFieldTypeObj.selectWithSearchDataObj !== undefined) {
        if(JSFUNC.is_array(selectStagesWithinCaptureTypeFieldTypeObj.selectWithSearchDataObj.valueArray)) {
          return(selectStagesWithinCaptureTypeFieldTypeObj.selectWithSearchDataObj.valueArray);
        }
      }
    }
    return([]);
  }

  get_first_stage_id_or_undefined_from_capture_type_id(i_captureTypeID) {
    const allStageIDsWithinCaptureTypeArray = this.get_all_stage_ids_array_from_capture_type_id(i_captureTypeID);
    if(allStageIDsWithinCaptureTypeArray.length > 0) {
      return(allStageIDsWithinCaptureTypeArray[0]);
    }
    return(undefined);
  }






  //searching matching values in BIT Master tbls
  get_bit_master_state_id_or_undefined_from_state_name(i_stateNameOrA2String) {
    const stateNameOrA2StringLowercase = JSFUNC.str2lower(i_stateNameOrA2String);
    if(stateNameOrA2StringLowercase !== undefined) {
      for(let bitMasterStateMap of this.c_tbl_bit_master_states.values()) {
        if(JSFUNC.in_array(stateNameOrA2StringLowercase, bitMasterStateMap.get("allNameVariationsLowercaseArray"))) {
          return(bitMasterStateMap.get("id"));
        }
      }
    }
    return(undefined);
  }

  get_bit_master_country_id_or_undefined_from_country_name(i_countryNameOrA3String) {
    const countryNameOrA3StringLowercase = JSFUNC.str2lower(i_countryNameOrA3String);
    if(countryNameOrA3StringLowercase !== undefined) {
      for(let bitMasterCountryMap of this.c_tbl_bit_master_countries.values()) {
        if(JSFUNC.in_array(countryNameOrA3StringLowercase, bitMasterCountryMap.get("allNameVariationsLowercaseArray"))) {
          return(bitMasterCountryMap.get("id"));
        }
      }
    }
    return(undefined);
  }





  //determining styling objs from styling strings (which can load from admin tbl styling presetIDs)
  get_preset_styling_obj_from_styling_string_comma_list_or_preset_colon_id_string(i_stylingStringCommaListOrPresetColonIDString) {
    //i_stylingStringCommaListOrPresetColonIDString stored in style tbl columns are either in the format
    // - "bold,italic,color#333333,highlight#cccccc"    [custom styling]
    // - "presetID:3"                                   [preset styling is from tbl_a_styling_string_presets rowID 3, column `styling_string_comma_list`]
    //creates an expanded version of the stylingObj that includes preset fields

    var stylingStringCommaList = "";
    var presetTF = false;
    var presetID = -1;
    var presetExistsTF = false;
    var presetName = "";

    //if the input is a presetID, load the styling string from the preset tbl
    if(JSFUNC.string_is_filled_out_tf(i_stylingStringCommaListOrPresetColonIDString)) {
      if(i_stylingStringCommaListOrPresetColonIDString.substring(0, 9) === "presetID:") { //starts with presetID:
        presetTF = true;

        const presetIDString = i_stylingStringCommaListOrPresetColonIDString.substring(9, i_stylingStringCommaListOrPresetColonIDString.length);
        presetID = JSFUNC.str2int(presetIDString);

        presetExistsTF = this.o_tbl_a_styling_string_presets.has(presetID);

        const stylingStringPresetMap = this.tbl_row_map_from_id("tbl_a_styling_string_presets", presetID);
        presetName = stylingStringPresetMap.get("name");
        stylingStringCommaList = stylingStringPresetMap.get("styling_string_comma_list");
      }
      else { //custom styling comma string
        stylingStringCommaList = i_stylingStringCommaListOrPresetColonIDString;
      }
    }

    //compute stylingObj and add extra preset fields to it
    var presetStylingObj = JSFUNC.convert_styling_string_comma_list_to_styling_obj(stylingStringCommaList);
    presetStylingObj.presetTF = presetTF;
    presetStylingObj.presetID = presetID;
    presetStylingObj.presetExistsTF = presetExistsTF;
    presetStylingObj.presetName = presetName;
    
    return(presetStylingObj);
  }






  //FYR conversions using tbl_a_company "fyr_start_month" (number from 1 (Jan) to 12 (Dec))
  convert_date_Ymd_to_fyrYear_fyrMonth0to11_obj(i_dateYmd) {
    const jsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(i_dateYmd);
    const year = jsDateObj.getFullYear();
    const month0to11 = jsDateObj.getMonth();
    return(this.convert_year_month0to11_to_fyrYear_fyrMonth0to11_obj(year, month0to11));
  }

  convert_year_month0to11_to_fyrYear_fyrMonth0to11_obj(i_year, i_month0to11) {
    const c_companyFyrStartMonth1to12 = this.c_companyFyrStartMonth1to12;
    return(JSFUNC.compute_fyr_obj_from_year_and_month0to11_and_fyr_start_month0to11(i_year, i_month0to11, c_companyFyrStartMonth1to12));
  }






  progress_bar_html_from_percent_and_size_em(i_progressPercent0to100, i_sizeEm) {
    const sizeEm = JSFUNC.prop_value(i_sizeEm, 1.5);

    var progressPercent0to100 = -1;
    if(JSFUNC.is_number_not_nan(i_progressPercent0to100)) {
      progressPercent0to100 = i_progressPercent0to100;
    }

    const [progressColor, progressFontColor] = this.shaping_progress_or_pwin_color_from_percent0to100(i_progressPercent0to100, true);

    const roundedPercentString = JSFUNC.percent_fixed(progressPercent0to100, 0);

    var backgroundString = "#eee";
    var percentString = undefined;
    var fontColor = "#" + progressFontColor;
    if(progressPercent0to100 === -1) { //-1 is a flag for N/A entries (such as the stage progress in a stage that has not been reached yet)
      percentString = "N/A";
      fontColor = "#2a2a2a";
    }
    else if(progressPercent0to100 >= 0) {
      percentString = roundedPercentString;
      if(progressPercent0to100 === 0) {
        backgroundString = "#fff";
      }
      else if(progressPercent0to100 < 100) {
        backgroundString = "linear-gradient(90deg, #" + progressColor + " " + progressPercent0to100 + "%, #fff " + progressPercent0to100 + "%)";
      }
      else {
        backgroundString = "#" + progressColor;
      }
    }
    else {
      backgroundString = "#fff";
      percentString = roundedPercentString;
      fontColor = "#bd2326";
    }

    var fontClass = ""; //smallest font
    if(sizeEm < 1.5) { fontClass = "font09"; }
    else if(sizeEm < 1.8) { fontClass = ""; }
    else if(sizeEm < 2) { fontClass = "font11"; }
    else if(sizeEm < 2.3) { fontClass = "font12"; }
    else if(sizeEm < 2.7) { fontClass = "font13"; }
    else if(sizeEm < 3) { fontClass = "font14"; }
    else if(sizeEm < 3.5) { fontClass = "font15"; }
    else { fontClass = "font20"; }

    const borderRadiumEm = (sizeEm / 3);

    return(
      <div
        key={i_progressPercent0to100 + "-#" + progressColor + "-" + JSFUNC.key_rand()}
        className="displayFlexColumnHcVc overflowHidden border"
        style={{background:backgroundString, borderRadius:borderRadiumEm + "em", height:sizeEm + "em", borderLeftColor:"#ddd", borderTopColor:"#ddd", borderRightColor:"#aaa", borderBottomColor:"#aaa"}}>
        <font className={"fontBold fontTextLight " + fontClass} style={{color:fontColor}}>
          {percentString}
        </font>
      </div>
    );
  }

  pwin_cube_svg_html_from_percent_and_size_em(i_pwinPercent0to100, i_sizeEm) {
    const sizeEm = JSFUNC.prop_value(i_sizeEm, 2.25);
    const pwinPercent0to100 = ((JSFUNC.text_or_number_is_filled_out_tf(i_pwinPercent0to100)) ? (Math.round(i_pwinPercent0to100)) : (-1));

    const [pwinColor, pwinFontColor] = this.shaping_progress_or_pwin_color_from_percent0to100(i_pwinPercent0to100, false);

    var faceColor = "#" + pwinColor;
    var fontColor = "#" + pwinFontColor;
    var percentDisplay = pwinPercent0to100 + "%";
    if(pwinPercent0to100 === -1) {
      faceColor = "#ddd";
      fontColor = "#666";
      percentDisplay = "N/A";
    }

    const colorShift = 30;
    const edgeColor = "#aaa";

    const topLightColor = JSFUNC.color_add_decimal_rgb_values(faceColor, colorShift, colorShift, colorShift);
    const rightDarkColor = JSFUNC.color_add_decimal_rgb_values(faceColor, -1*colorShift, -1*colorShift, -1*colorShift);
    const sizePx = sizeEm * UserMobx.c_userFontSizePx;
    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx * 1.7);

    const slant3DX = 0.15;

    const x1 = 0.05; //left edge
    const x2 = x1 + slant3DX; //back left corner of top face
    const x3 = 0.80; //right edge
    const x4 = x3 + slant3DX; //right edge of right face
    const y1 = 0.05; //top edge of top face
    const y2 = 0.25; //top edge
    const y4 = 0.95; //bottom edge
    const y3 = y4 - (slant3DX * 1.7); //bottom right corner of right face

    const faceX1 = Math.round(widthPx * x1);
    const faceX2 = Math.round(widthPx * x3);
    const faceY1 = Math.round(heightPx * y2);
    const faceY2 = Math.round(heightPx * y4);
    const faceWidth = faceX2 - faceX1;
    const faceHeight = faceY2 - faceY1;

    const t1x = faceX1;
    const t2x = Math.round(widthPx * x2);
    const t3x = faceX2;
    const t4x = Math.round(widthPx * x4);
    const t1y = Math.round(heightPx * y1);
    const t2y = faceY1;
    const topPolyPoints = t1x + "," + t2y + " " + t2x + "," + t1y + " " + t4x + "," + t1y + " " + t3x + "," + t2y;

    const r1x = faceX2;
    const r2x = t4x;
    const r1y = t1y;
    const r2y = faceY1;
    const r3y = Math.round(heightPx * y3);
    const r4y = faceY2;
    const rightPolyPoints = r1x + "," + r4y + " " + r1x + "," + r2y + " " + r2x + "," + r1y + " " + r2x + "," + r3y;

    var textX = 0;
    if(pwinPercent0to100 >= 100) { //100%+
      textX = faceX1 + Math.round(faceWidth * 0.03);
    }
    else if(pwinPercent0to100 >= 10) { //10%-99%
      textX = faceX1 + Math.round(faceWidth * 0.18);
    }
    else if(pwinPercent0to100 >= 0) { //0%-9%
      textX = faceX1 + Math.round(faceWidth * 0.31);
    }
    else { //N/A
      textX = faceX1 + Math.round(faceWidth * 0.2);
    }

    const textY = faceY1 + Math.round(faceHeight * 0.72);
    const textFontSize = (sizeEm * 0.45) + "em";

    return(
      <svg width={widthPx + "px"} height={heightPx + "px"}>
        <rect x={faceX1 + "px"} y={faceY1 + "px"} width={faceWidth + "px"} height={faceHeight + "px"} style={{fill:faceColor, stroke:edgeColor, strokeWidth:"1"}} />
        <polygon points={topPolyPoints} style={{fill:topLightColor, stroke:edgeColor, strokeWidth:"1"}} />
        <polygon points={rightPolyPoints} style={{fill:rightDarkColor, stroke:edgeColor, strokeWidth:"1"}} />
        <text x={textX + "px"} y={textY + "px"} fill={fontColor} style={{fontSize:textFontSize}}>
          {percentDisplay}
        </text>
      </svg>
    );
  }

  shaping_progress_or_pwin_color_from_percent0to100(i_progressPercent0to100, i_shapingTruePwinFalse) {
    const colorsArrayOfObjs = ((i_shapingTruePwinFalse) ? (this.c_shapingProgressColorsArrayOfObjs) : (this.c_pwinColorsArrayOfObjs));
    var color = "eee";
    var fontColor = "2a2a2a";
    if((i_progressPercent0to100 !== undefined) && (i_progressPercent0to100 >= 0) && (i_progressPercent0to100 <= 100) && (colorsArrayOfObjs.length !== 0)) {
      for(let shapingProgressColorObj of colorsArrayOfObjs) {
        if(i_progressPercent0to100 < shapingProgressColorObj.percent_start) {
          return([color, fontColor]); //this percent threshold is above the value, so use the previous color
        }
        color = shapingProgressColorObj.color;
        fontColor = shapingProgressColorObj.font_color;
      }
    }
    return([color, fontColor]); //return the last highest percent color that ends at 100
  }




  //Generate Template obj (used on Capture Templates card and Divexec Excel Report Writer tab)
  generate_templates_within_folders_obj_from_admin_templates_ffs_tbl_name(i_adminTemplatesFileFolderSystemTblName, i_homeLevelName) {
    const adminSingleCaptureTemplatesTblRef = this.tbl_ref_from_tbl_name(i_adminTemplatesFileFolderSystemTblName);
    const allFoldersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(adminSingleCaptureTemplatesTblRef, "folder0_file1", 0, "display_name", true, true); //all folders are sorted by display_name so that they are alphabetic among each other within a common parent folder

    var orderedFolderIDsArray = JSFUNC.recursive_array_of_ids_under_node_in_tree(allFoldersArrayOfObjs, "parent_folder_id", -1);
    orderedFolderIDsArray.push(-1); //put the main top level files at the bottom

    var numTemplates = 0;
    var foldersArrayOfObjs = []; //all folders in FFS at all levels (including top level 'home' level), those that contain files and those that don't
    for(let folderID of orderedFolderIDsArray) {
      var folderNamesFromTopToNodeWithoutHomeArray = JSFUNC.tree_field_array_from_top_to_node(allFoldersArrayOfObjs, "parent_folder_id", folderID, "display_name");
      var folderNamesFromTopToNodeArray = JSFUNC.concat_arrays_or_values_into_new_array(i_homeLevelName, folderNamesFromTopToNodeWithoutHomeArray);
      var folderPathString = folderNamesFromTopToNodeArray.join(" > ");

      //get all uploaded files
      var filesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(adminSingleCaptureTemplatesTblRef, ["folder0_file1", "parent_folder_id", "fileupload0_onlinelink1"], [1, folderID, 0], "display_name", true, true);
      var numFiles = filesArrayOfObjs.length;

      numTemplates += numFiles;
      foldersArrayOfObjs.push({
        id: folderID,
        folderPathString: folderPathString,
        filesArrayOfObjs: filesArrayOfObjs,
        numFiles: numFiles
      });
    }

    return({
      numTemplates: numTemplates,
      foldersArrayOfObjs: foldersArrayOfObjs
    });
  }






  //insert all empty folders into a Capture from a Documents Card Folders Shortcut Preset ID
  insert_all_empty_folders_from_documents_card_shortcut_preset_id_into_capture_from_capture_id(i_shortcutPresetDocumentsCardFoldersPresetID, i_captureID, i_functionOnFinish=undefined) {
    const o_tbl_captures = this.o_tbl_captures;
    const o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem = this.o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem;

    //capture does not exist in local memory
    if(!o_tbl_captures.has(i_captureID)) {
      if(JSFUNC.is_function(i_functionOnFinish)) {
        i_functionOnFinish();
      }
      return;
    }

    const documentsCardFoldersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem, "shortcut_preset_id", i_shortcutPresetDocumentsCardFoldersPresetID);
    const numPresetFolders = documentsCardFoldersArrayOfObjs.length;

    //no folders in this presetID to insert
    if(numPresetFolders === 0) {
      if(JSFUNC.is_function(i_functionOnFinish)) {
        i_functionOnFinish();
      }
      return;
    }

    var atLeast1FolderIsASubfolderTF = false;

    //assign a unique string "rX" from the rowID of the preset FFS tbl to use to map the newly inserted folder rows with parent/child relationships in the capture documents card FFS ID numbering
    for(let documentsCardFolderObj of documentsCardFoldersArrayOfObjs) {
      if(JSFUNC.is_number_not_nan_gt_0(documentsCardFolderObj.parent_folder_id)) {
        atLeast1FolderIsASubfolderTF = true; //there is at least 1 subfolder where a folder has a parent_folder_id that is not -1 
      }
    }

    const jsDescription = JSFUNC.js_description_from_action("DatabaseMobx", "insert_all_empty_folders_from_documents_card_shortcut_preset_id_into_capture_from_capture_id", ["i_shortcutPresetDocumentsCardFoldersPresetID", "i_captureID"], [i_shortcutPresetDocumentsCardFoldersPresetID, i_captureID]);
    const C_CallPhpTblUIDInsertFolders = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var fieldNamesArray = ["capture_id", "folder0_file1", "parent_folder_id", "fileupload0_onlinelink1", "file_loc", "display_name", "keywords", "notes", "content_unique_lowercase", "upload_date", "upload_user_id", "upload_user_name", "access", "xml_type"];
    var idsbArray = ["i", "i", "i", "i", "s", "s", "s", "s", "s", "s", "i", "s", "s", "s"];
    for(let documentsCardFolderObj of documentsCardFoldersArrayOfObjs) {
      //copy all values from preset to the new capture insert array of values
      var valuesArray = [];
      for(let fieldName of fieldNamesArray) {
        var presetValue = documentsCardFolderObj[fieldName];

        if(fieldName === "capture_id") { //overwrite capture_id with the input captureID
          presetValue = i_captureID;
        }
        else if(fieldName === "parent_folder_id") { //overwrite parent_folder_id with -1 since the parent_folder_id values from the preset are ID numbers that do not match the IDs inserted into tbl_c_documents_filefoldersystem, setting to -1 at least assures that all inserted folders will be at the root level if parenting assignment fails
          presetValue = -1;
        }

        valuesArray.push(presetValue);
      }

      //insert a new record into the captures documents card FFS (with the wrong )
      C_CallPhpTblUIDInsertFolders.add_insert("tbl_c_documents_filefoldersystem", fieldNamesArray, valuesArray, idsbArray);
    }

    //after inserting all records with all parent relationships erased, take a 2nd pass to update each inserted record with calculated parent rowIDs
    if(atLeast1FolderIsASubfolderTF) { //only need this mapping/recalculation if there's at least 1 subfolder with a parent
      const functionOnSuccess = (i_parseResponse) => {
        var successfulInsertsInfoArrayOfObjs = [];

        //loop over every php insert to gather the capture documents row IDs
        for(let z = 0; z < numPresetFolders; z++) {
          var insertPrefix = "i" + z;
          var newlyInsertedCaptureDocumentsFfsRowIDOr0 = JSFUNC.str2int(i_parseResponse.outputObj[insertPrefix]);
          if(JSFUNC.is_number_not_nan_gt_0(newlyInsertedCaptureDocumentsFfsRowIDOr0)) {
            successfulInsertsInfoArrayOfObjs.push({
              insertPrefix: insertPrefix,
              presetTblRowID: documentsCardFoldersArrayOfObjs[z].id,
              presetTblParentFolderRowID: documentsCardFoldersArrayOfObjs[z].parent_folder_id,
              captureDocumentsFfsRowID: newlyInsertedCaptureDocumentsFfsRowIDOr0
            });
          }
        }

        //compute adjusted parent IDs for new capture documents FFS tbl
        var updatesArrayOfObjs = [];
        for(let insertInfoObj of successfulInsertsInfoArrayOfObjs) {
          if(insertInfoObj.presetTblParentFolderRowID > 0) { //if this folder in the original preset tbl is a child of a parent, find that parent
            var parentObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(successfulInsertsInfoArrayOfObjs, "presetTblRowID", insertInfoObj.presetTblParentFolderRowID);
            if(parentObjOrUndefined !== undefined) {
              updatesArrayOfObjs.push({
                captureDocumentsFfsRowID: insertInfoObj.captureDocumentsFfsRowID,
                captureDocumentsFfsParentFolderRowID: parentObjOrUndefined.captureDocumentsFfsRowID
              });
            }
          }
        }

        if(updatesArrayOfObjs.length > 0) { //if there is at least 1 update
          //update each capture documents inserted row with the adjusted parent row ID
          const C_CallPhpTblUIDUpdateParents = new JSPHP.ClassCallPhpTblUID(jsDescription);

          for(let updateObj of updatesArrayOfObjs) {
            C_CallPhpTblUIDUpdateParents.add_update("tbl_c_documents_filefoldersystem", updateObj.captureDocumentsFfsRowID, "parent_folder_id", updateObj.captureDocumentsFfsParentFolderRowID, "i");
          }
  
          if(JSFUNC.is_function(i_functionOnFinish)) {
            C_CallPhpTblUIDUpdateParents.add_function("onFinish", i_functionOnFinish);
          }
  
          C_CallPhpTblUIDUpdateParents.execute();
        }
        else { //no updates to make, call the finish function
          if(JSFUNC.is_function(i_functionOnFinish)) {
            i_functionOnFinish();
          }
        }
      }
      C_CallPhpTblUIDInsertFolders.add_function("onSuccess", functionOnSuccess);
    }
    else { //only need 1st insert pass, call onFinish function after that pass
      if(JSFUNC.is_function(i_functionOnFinish)) {
        C_CallPhpTblUIDInsertFolders.add_function("onFinish", i_functionOnFinish);
      }
    }

    C_CallPhpTblUIDInsertFolders.execute();
  }

}
export default new DatabaseMobx();
