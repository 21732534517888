//js code packages
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//generic components and js functions
import * as JSFUNC from "../../Library/JSFUNC.js";
import * as LibraryReact from "../../Library/LibraryReact.js";
import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import * as AdminReact from "../Admin/AdminReact.js";
import * as BudgetReact from "../Budget/BudgetReact.js";
import * as ContactsReact from "../Contacts/ContactsReact.js";
import * as RightPanelReact from "../RightPanel/RightPanelReact.js";
import * as TeammateContractsReact from "../TeammateContracts/TeammateContractsReact.js";


export const OpenCapture = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class OpenCapture extends Component {
  render() {
    const o_captureExecInSingleCaptureDirectAccessModeTF = this.props.CaptureExecMobx.o_captureExecInSingleCaptureDirectAccessModeTF;
    const c_currentMainContentTopEdgeDueToCommunicationBannersAndTopBar = this.props.CaptureExecMobx.c_currentMainContentTopEdgeDueToCommunicationBannersAndTopBar;
    const c_currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels = this.props.CaptureExecMobx.c_currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels;
    const o_copyCaptureState = this.props.OpenCaptureMobx.o_copyCaptureState;
    const o_deleteCaptureState = this.props.OpenCaptureMobx.o_deleteCaptureState;
    const c_singleCaptureIsOpenTF = this.props.OpenCaptureMobx.c_singleCaptureIsOpenTF;
    const c_numCapturesLoadedInSystem = this.props.DatabaseMobx.c_numCapturesLoadedInSystem;
    const c_userName = this.props.UserMobx.c_userName;

    const isCopyingCaptureTF = (o_copyCaptureState !== undefined);
    const isDeletingCaptureTF = (o_deleteCaptureState !== undefined);

    //shortcut if the capture is not open and no captures are in the copy/delete process, draw a simple single null for the open capture component
    if(!c_singleCaptureIsOpenTF && !isCopyingCaptureTF && !isDeletingCaptureTF) {
      return(null);
    }

    //for single capture direct access, if there are 0 captures currently in the system, it means this user does not have access (captureConsultant or division firewalling) to the requested capture
    if(o_captureExecInSingleCaptureDirectAccessModeTF && (c_numCapturesLoadedInSystem === 0)) {
      return(
        <CEGeneralReact.EmptyScreenGray p_fontClass="font11">
          <div>{c_userName + " does not have access to this Capture"}</div>
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    return(
      <div
        className={"positionAbsolute displayFlexColumn bgWhite " + ((o_captureExecInSingleCaptureDirectAccessModeTF) ? ("") : ("singleCaptureOverlayWithAnimation"))}
        style={{zIndex:3, top:c_currentMainContentTopEdgeDueToCommunicationBannersAndTopBar, right:c_currentMainContentAndOpenCaptureRightEdgeDueToRightAndHelpPanels, bottom:0, left:0, border:"solid 2px #18305a"}}>
        <OpenCaptureTopBar />
        <OpenCaptureSnapshotOrOpenCards />
      </div>
    );
  }
}));


const OpenCaptureTopBar = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class OpenCaptureTopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_getDirectAccessWebLinkFloatingBoxIsOpenTF: false,
      s_archiveCaptureFloatingBoxIsOpenTF: false
    };
  }

  onclick_close_single_capture = () => {
    this.props.OpenCaptureMobx.a_close_single_capture();
  }

  onselect_get_direct_access_web_link = () => {
    this.setState({s_getDirectAccessWebLinkFloatingBoxIsOpenTF:true});
  }

  onclick_cancel_get_direct_access_web_link = () => {
    this.setState({s_getDirectAccessWebLinkFloatingBoxIsOpenTF:false});
  }

  onselect_copy_capture = () => {
    this.props.OpenCaptureMobx.a_copy_capture_set_copy_state("copyOptions");
  }

  onselect_archive_capture = () => {
    this.setState({s_archiveCaptureFloatingBoxIsOpenTF:true});
  }

  onclick_cancel_archive_capture = () => {
    this.setState({s_archiveCaptureFloatingBoxIsOpenTF:false});
  }

  onselect_delete_capture = () => {
    this.props.OpenCaptureMobx.a_delete_capture_set_delete_state("delete");
  }

  render() {
    const s_getDirectAccessWebLinkFloatingBoxIsOpenTF = this.state.s_getDirectAccessWebLinkFloatingBoxIsOpenTF;
    const s_archiveCaptureFloatingBoxIsOpenTF = this.state.s_archiveCaptureFloatingBoxIsOpenTF;

    const o_captureExecInSingleCaptureDirectAccessModeTF = this.props.CaptureExecMobx.o_captureExecInSingleCaptureDirectAccessModeTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_copyCaptureState = this.props.OpenCaptureMobx.o_copyCaptureState;
    const o_deleteCaptureState = this.props.OpenCaptureMobx.o_deleteCaptureState;
    const c_captureExistsTF = this.props.OpenCaptureMobx.c_captureExistsTF;
    const c_captureName = this.props.OpenCaptureMobx.c_captureName;
    const c_captureIsArchivedTF = this.props.OpenCaptureMobx.c_captureIsArchivedTF;
    const c_bitSingleCaptureDirectAccessLinksTF = this.props.DatabaseMobx.c_bitSingleCaptureDirectAccessLinksTF;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isCopyingCaptureTF = (o_copyCaptureState !== undefined);
    const isDeletingCaptureTF = (o_deleteCaptureState !== undefined);

    const openCaptureTopBarDividerBorder = "solid 1px #b88";

    //create vertical dots menu
    var captureVerticalDotsComponent = null;
    if(c_captureExistsTF && c_userCanEditCaptureCardContentTF && !o_captureExecInSingleCaptureDirectAccessModeTF) {
      var openCaptureVerticalDotsMenuItemsArrayOfObjs = [];
      if(c_bitSingleCaptureDirectAccessLinksTF) { //if BITcompanies flag for allowing single capture direct access is turned on, add this option to the OpenCapture vertical dots
        openCaptureVerticalDotsMenuItemsArrayOfObjs.push({
          displayName: "Get Direct Access Web Link",
          functionOnClickMenuItem: this.onselect_get_direct_access_web_link
        });
      }

      openCaptureVerticalDotsMenuItemsArrayOfObjs.push({
        displayName: "Copy Capture",
        functionOnClickMenuItem: this.onselect_copy_capture
      });

      if(c_productStylingObj.openCaptureCanArchiveCaptureTF) { //archiving captures not available in SAM Gov Tracker
        openCaptureVerticalDotsMenuItemsArrayOfObjs.push({
          displayName: ((c_captureIsArchivedTF) ? ("Unarchive Capture") : ("Archive Capture")),
          functionOnClickMenuItem: this.onselect_archive_capture
        });
      }

      openCaptureVerticalDotsMenuItemsArrayOfObjs.push({
        displayName: ((c_combinedUserObj.powerHasAdminPowerTF) ? ("Delete Capture") : ("Request Admin to Delete Capture")),
        functionOnClickMenuItem: this.onselect_delete_capture
      });
      
      captureVerticalDotsComponent = (
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em", borderLeft:openCaptureTopBarDividerBorder}}>
          <CEGeneralReact.VerticalDotsMenu
            p_menuItemsArrayOfObjs={openCaptureVerticalDotsMenuItemsArrayOfObjs}
            p_dotsFontClass="fontWhite"
          />
        </div>
      );
    }

    return(
      <>
        <div className={"flex00a displayFlexRow " + c_productStylingObj.openCaptureTopBarBgClass} style={{flexBasis:"2.8em", borderBottom:"solid 1px #700"}}>
          {(!o_captureExecInSingleCaptureDirectAccessModeTF) &&
            <div
              className="flex00a hoverRedCapture displayFlexColumnHcVc overflowHidden cursorPointer"
              style={{flexBasis:"3em", borderRight:openCaptureTopBarDividerBorder}}
              title="Close this capture with all changes saved"
              onClick={this.onclick_close_single_capture}>
              <div className="displayFlexColumnHcVc font20 fontWhite" style={{height:"1.19em", width:"1em", lineHeight:"0em"}}>
                {"\u2794"}
              </div>
            </div>
          }
          <div className="flex11a displayFlexRowVc lrMedPad">
            <LibraryReact.MaxHeightWrap p_maxHeight="2.7em" p_fontClass="font13 fontWhite">
              {c_captureName}
            </LibraryReact.MaxHeightWrap>
          </div>
          <OpenCaptureConnectedToGCSSCompareButton />
          <OpenCaptureOtherUsersHaveSameCaptureOpenIndicator />
          {captureVerticalDotsComponent}
        </div>
        {(s_getDirectAccessWebLinkFloatingBoxIsOpenTF) &&
          <GetDirectAccessWebLinkFloatingBox f_onClickCancel={this.onclick_cancel_get_direct_access_web_link} />
        }
        {(isCopyingCaptureTF) &&
          <CopyOpenCaptureProcessFloatingBox />
        }
        {(s_archiveCaptureFloatingBoxIsOpenTF) &&
          <ArchiveCaptureFloatingBox f_onClickCancel={this.onclick_cancel_archive_capture} />
        }
        {(isDeletingCaptureTF) &&
          <DeleteOpenCaptureProcessFloatingBox />
        }
      </>
    )
  }
}));


const OpenCaptureConnectedToGCSSCompareButton = inject("OpenCaptureMobx")(observer(
class OpenCaptureConnectedToGCSSCompareButton extends Component {
  onclick_compare_open_capture_govwin_fields_to_gcss_database_sam_fields = () => {
    const c_solicitationNumberFieldValueObj = this.props.OpenCaptureMobx.c_solicitationNumberFieldValueObj;
    this.props.OpenCaptureMobx.a_capture_connected_to_gcss_fetch_single_sam_record_data(c_solicitationNumberFieldValueObj.valueRaw);
    this.props.OpenCaptureMobx.a_set_capture_connected_to_gcss_compare_floating_box_is_open_tf(true);
  }

  onclick_close_compare_gcss_govwin_to_sam_floating_box = () => {
    this.props.OpenCaptureMobx.a_set_capture_connected_to_gcss_compare_floating_box_is_open_tf(false);
  }
  
  render() {
    const o_captureConnectedToGcssCompareFloatingBoxIsOpenTF = this.props.OpenCaptureMobx.o_captureConnectedToGcssCompareFloatingBoxIsOpenTF;
    const c_gcssGovWinIDStringFieldValueObj = this.props.OpenCaptureMobx.c_gcssGovWinIDStringFieldValueObj;

    if(!c_gcssGovWinIDStringFieldValueObj.isFilledOutTF) {
      return(null);
    }

    return(
      <>
        <div
          className="flex00a displayFlexColumnHcVc lrPad"
          style={{borderLeft:"solid 1px #977"}}
          title={undefined}>
          <div className="textCenter">
            <font className="font09 fontItalic fontWhite">
              {"Connected To GovWin"}
            </font>
          </div>
          <div
            className="flex00a border bevelBorderColorBlue borderRadius10 bgLighterBlueGradient cursorPointer"
            style={{padding:"0.05em 0.6em"}}
            title="Click to bring up a quick comparison of this Capture's GovWin values to online values from SAM.gov"
            onClick={this.onclick_compare_open_capture_govwin_fields_to_gcss_database_sam_fields}>
            <font className="font09 fontBlue">
              {"Compare to SAM.gov"}
            </font>
          </div>
        </div>
        {(o_captureConnectedToGcssCompareFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="medium"
            p_title="Compare GovWin values in Capture to online SAM.gov values"
            f_onClickCancel={this.onclick_close_compare_gcss_govwin_to_sam_floating_box}>
            <OpenCaptureConnectedToGCSSCompareFloatingBoxContents />
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    )
  }
}));


const OpenCaptureConnectedToGCSSCompareFloatingBoxContents = inject("GCSSMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class OpenCaptureConnectedToGCSSCompareFloatingBoxContents extends Component {
  render() {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const o_captureConnectedToGcssLoadingFlagOrErrorMessageOrUndefined = this.props.OpenCaptureMobx.o_captureConnectedToGcssLoadingFlagOrErrorMessageOrUndefined;
    const c_captureConnectedToGcssGovWinAndSamFieldsWithValuesArrayOfObjs = this.props.OpenCaptureMobx.c_captureConnectedToGcssGovWinAndSamFieldsWithValuesArrayOfObjs;
    const c_solicitationNumberFieldValueObj = this.props.OpenCaptureMobx.c_solicitationNumberFieldValueObj;
    const c_gcssGovWinIDStringFieldValueObj = this.props.OpenCaptureMobx.c_gcssGovWinIDStringFieldValueObj;
    const k_cardIDDetails = this.props.DatabaseMobx.k_cardIDDetails;
    const c_fieldMapOfSolicitationNumber = this.props.DatabaseMobx.c_fieldMapOfSolicitationNumber;
    const c_fieldMapOfGcssGovWinIDString = this.props.DatabaseMobx.c_fieldMapOfGcssGovWinIDString;

    //SAM data loading from BIT SAM databases
    if(o_captureConnectedToGcssLoadingFlagOrErrorMessageOrUndefined === "loading") {
      return(
        <div className="flex11a displayFlexColumnHcVc">
          <div className="flex00a smallBottomMargin textCenter">
            <font className="font11 fontItalic fontTextLight">
              {"Loading comparison data from SAM.gov"}
            </font>
          </div>
          <CEGeneralReact.LoadingAnimation />
          <div className="flex00a smallTopMargin textCenter">
            <font className="font11 fontItalic fontTextLight">
              <font className="fontBold">{c_fieldMapOfGcssGovWinIDString.get("display_name") + ": "}</font>{c_gcssGovWinIDStringFieldValueObj.valueMaskPlainText}
            </font>
          </div>
          <div className="flex00a textCenter">
            <font className="font11 fontItalic fontTextLight">
              <font className="fontBold">{c_fieldMapOfSolicitationNumber.get("display_name") + ": "}</font>{c_solicitationNumberFieldValueObj.valueMaskPlainText}
            </font>
          </div>
        </div>
      );
    }

    //error fetching SAM data
    if(o_captureConnectedToGcssLoadingFlagOrErrorMessageOrUndefined !== undefined) {
      return(
        <div className="flex11a displayFlexColumnHcVc">
          <div className="flex00a textCenter" style={{maxWidth:"40em"}}>
            <div className="smallBottomMargin">
              <font className="font12 fontBold fontDarkRed">
                {"Issue fetching SAM.gov record"}
              </font>
            </div>
            <div className="">
              <font className="font11 fontDarkRed">
                {this.props.GCSSMobx.translate_gcss_error_message_to_user_display_error_message(o_captureConnectedToGcssLoadingFlagOrErrorMessageOrUndefined)}
              </font>
            </div>
          </div>
        </div>
      );
    }

    return(
      <>
        <div className="flex00a displayFlexRow borderB1bbb yScrollAlways" style={{flexBasis:"2.8em"}}>
          <div className="flex11a displayFlexColumnHcVc borderR1ddd textCenter" style={{flexBasis:"100em"}}>
            <font className="fontTextLight">
              {"Field"}
            </font>
          </div>
          <div className="flex11a displayFlexColumnHcVc borderR1ddd bgRedCapture textCenter" style={{flexBasis:"200em"}}>
            <div>
              <font className="font12 fontBold fontWhite">
                {"This Capture"}
              </font>
            </div>
            <div>
              <font className="fontItalic fontWhite">
                {"(Connected GovWin Values)"}
              </font>
            </div>
          </div>
          <div className="flex00a borderR1ddd" style={{flexBasis:"1em"}} />
          <div className="flex11a displayFlexColumnHcVc borderR1ddd bgOrangeGradient textCenter" style={{flexBasis:"200em"}}>
            <font className="font12 fontBold">
              {"SAM.gov Values"}
            </font>
          </div>
        </div>
        <div className="flex11a displayFlexColumn yScroll">
          {c_captureConnectedToGcssGovWinAndSamFieldsWithValuesArrayOfObjs.map((m_gcssDetailSectionObj) =>
            <>
              <div className="border1ddd displayFlexRow bgLighterBlue tbPad lrMedPad textCenter">
                <div className="flex11a" style={{flexBasis:"100em"}} />
                <div className="flex11a" style={{flexBasis:"400em"}}>
                  <font className="font11 fontBold fontBlue">
                    {m_gcssDetailSectionObj.sectionHeader}
                  </font>
                </div>
              </div>
              {m_gcssDetailSectionObj.gcssFieldsArrayOfObjs.map((m_gcssGovWinAndSamFieldObj) =>
                <div className="displayFlexRow borderB1ddd">
                  <div className="flex11a displayFlexRowVc borderR1ddd tbMicroPad lrPad" style={{flexBasis:"100em"}}>
                    <font className="fontTextLight">
                      {m_gcssGovWinAndSamFieldObj.fieldDisplayName}
                    </font>
                  </div>
                  <div className="flex11a borderR1ddd tbMicroPad lrPad" style={{flexBasis:"200em"}}>
                    {(m_gcssGovWinAndSamFieldObj.currentCaptureFieldValueObj !== undefined) &&
                      <DetailsItem
                        p_captureID={o_openCaptureID}
                        p_cardID={k_cardIDDetails}
                        p_captureFieldValueObj={m_gcssGovWinAndSamFieldObj.currentCaptureFieldValueObj}
                        p_valueIsEditableTFOverwrite={undefined}
                        p_isSnapshotTF={undefined}
                        p_fieldWidth={undefined}
                        p_containerClass={undefined}
                        p_fieldClass={undefined}
                        p_valueClass={undefined}
                        p_fieldValueVerticallyAlignedTF={undefined}
                        p_showFieldNameTF={false}
                      />
                    }
                  </div>
                  <div className="flex00a borderR1ddd" style={{flexBasis:"1em", background:m_gcssGovWinAndSamFieldObj.valuesMatchBgHashColor}} />
                  <div className="flex11a tbMicroPad lrPad" style={{flexBasis:"200em"}}>
                    {m_gcssGovWinAndSamFieldObj.samValueMaskSortIfoObj !== undefined &&
                      <font className="">
                        {m_gcssGovWinAndSamFieldObj.samValueMaskSortIfoObj.valueMask}
                      </font>
                    }
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}));


const OpenCaptureOtherUsersHaveSameCaptureOpenIndicator = inject("OpenCaptureMobx")(observer(
class OpenCaptureOtherUsersHaveSameCaptureOpenIndicator extends Component {
  render() {
    const c_userNamesWithSameCaptureOpenHoverString = this.props.OpenCaptureMobx.c_userNamesWithSameCaptureOpenHoverString;

    if(c_userNamesWithSameCaptureOpenHoverString === undefined) {
      return(null);
    }

    return(
      <div
        className="flex00a displayFlexColumnHcVc fontWhite"
        style={{flexBasis:"4em", borderLeft:"solid 1px #977"}}
        title={c_userNamesWithSameCaptureOpenHoverString}>
        <div style={{lineHeight:"0em", fontSize:"3em"}}>
          {"\u26ad"}
        </div>
      </div>
    )
  }
}));


const GetDirectAccessWebLinkFloatingBox = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class GetDirectAccessWebLinkFloatingBox extends Component { //props: f_onClickCancel
  constructor(props) {
    super(props);

    this.inputTextRef = React.createRef();

    this.state = {
      s_linkCopiedToClipboardTF: false
    }
  }

  componentDidMount() {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_captureDirectAccessLink = this.props.OpenCaptureMobx.c_captureDirectAccessLink;
    const c_bitJumbleKey = this.props.DatabaseMobx.c_bitJumbleKey;

    //if this capture does not have a 64 char link containing the company jumble and captureID generated yet, create a new one with random characters
    if(!(JSFUNC.is_string(c_captureDirectAccessLink) && (c_captureDirectAccessLink.length === 64))) {
      const openCaptureIDAsBase52String = JSFUNC.convert_int_to_base_52_string(o_openCaptureID);

      //"rrrrrrrrrr rrJrJrrrrr CrrrrrJrrr JrrrJrrCrr rrCrrrrJCr rrrrrCrrCr rrrr"
      var newDirectAccessLink64Chars = JSFUNC.generate_random_AZaz09_string(12); //"rrrrrrrrrrrr"
      newDirectAccessLink64Chars += c_bitJumbleKey.substring(0, 1); //J
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(1); //"r"
      newDirectAccessLink64Chars += c_bitJumbleKey.substring(1, 2); //J
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(5); //"rrrrr"
      newDirectAccessLink64Chars += openCaptureIDAsBase52String.substring(0, 1); //C
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(5); //"rrrrr"
      newDirectAccessLink64Chars += c_bitJumbleKey.substring(2, 3); //J
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(3); //"rrr"
      newDirectAccessLink64Chars += c_bitJumbleKey.substring(3, 4); //J
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(3); //"rrr"
      newDirectAccessLink64Chars += c_bitJumbleKey.substring(4, 5); //J
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(2); //"rr"
      newDirectAccessLink64Chars += openCaptureIDAsBase52String.substring(1, 2); //C
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(4); //"rrrr"
      newDirectAccessLink64Chars += openCaptureIDAsBase52String.substring(2, 3); //C
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(4); //"rrrr"
      newDirectAccessLink64Chars += c_bitJumbleKey.substring(5, 6); //J
      newDirectAccessLink64Chars += openCaptureIDAsBase52String.substring(3, 4); //C
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(6); //"rrrrrr"
      newDirectAccessLink64Chars += openCaptureIDAsBase52String.substring(4, 5); //C
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(2); //"rr"
      newDirectAccessLink64Chars += openCaptureIDAsBase52String.substring(5, 6); //C
      newDirectAccessLink64Chars += JSFUNC.generate_random_AZaz09_string(5); //"rrrrr"

      //update the capture record with the new 64 char direct access link
      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureReact", "GetDirectAccessWebLinkFloatingBox - componentDidMount", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_captures", o_openCaptureID, "direct_access_link", newDirectAccessLink64Chars, "s");
      C_CallPhpTblUID.execute();
    }
  }

  onclick_copy_link_to_clipboard = () => {
    this.inputTextRef.current.select();
    document.execCommand('copy');
    this.setState({s_linkCopiedToClipboardTF:true});
  }

  render() {
    const s_linkCopiedToClipboardTF = this.state.s_linkCopiedToClipboardTF;

    const c_productWebsiteAddressEndingSlashRemovedLiveOrDevOrUndefined = this.props.CaptureExecMobx.c_productWebsiteAddressEndingSlashRemovedLiveOrDevOrUndefined;
    const c_captureName = this.props.OpenCaptureMobx.c_captureName;
    const c_captureDirectAccessLink = this.props.OpenCaptureMobx.c_captureDirectAccessLink; //"rrrrrrrrrr rrJrJr|rr|r|r CrrrrrJ|rr|r JrrrJrrCrr rrCrrrrJCr rr|rrrCrrCr rrrr"
    const c_userIDAsBase52String = this.props.UserMobx.c_userIDAsBase52String; //"UUUUUU" at char indices [16, 19, 21, 30, 33, 57] in 70 char string

    var directAccessLinkForUser = "";
    if(c_productWebsiteAddressEndingSlashRemovedLiveOrDevOrUndefined === undefined) {
      directAccessLinkForUser = "--Unable to be Generated--";
    }
    else {
      if(JSFUNC.is_string(c_captureDirectAccessLink) && (c_captureDirectAccessLink.length === 64)) {
        //"rrrrrrrrrr rrJrJrUrrU rUrCrrrrrJ UrrUrJrrrJ rrCrrrrCrr rrJCrrrUrr rCrrCrrrrr"
        var directAccessLinkForUser70Chars = c_captureDirectAccessLink.substring(0, 16); //"rrrrrrrrrrrrJrJr"
        directAccessLinkForUser70Chars += c_userIDAsBase52String.substring(0, 1); //"U"
        directAccessLinkForUser70Chars += c_captureDirectAccessLink.substring(16, 18); //"rr"
        directAccessLinkForUser70Chars += c_userIDAsBase52String.substring(1, 2); //"U"
        directAccessLinkForUser70Chars += c_captureDirectAccessLink.substring(18, 19); //"r"
        directAccessLinkForUser70Chars += c_userIDAsBase52String.substring(2, 3); //"U"
        directAccessLinkForUser70Chars += c_captureDirectAccessLink.substring(19, 27); //"rCrrrrrJ"
        directAccessLinkForUser70Chars += c_userIDAsBase52String.substring(3, 4); //"U"
        directAccessLinkForUser70Chars += c_captureDirectAccessLink.substring(27, 29); //"rr"
        directAccessLinkForUser70Chars += c_userIDAsBase52String.substring(4, 5); //"U"
        directAccessLinkForUser70Chars += c_captureDirectAccessLink.substring(29, 52); //"rJrrrJrrCrrrrCrrrrJCrrr"
        directAccessLinkForUser70Chars += c_userIDAsBase52String.substring(5, 6); //"U"
        directAccessLinkForUser70Chars += c_captureDirectAccessLink.substring(52, 64); //"rrrCrrCrrrrr"
  
        directAccessLinkForUser = c_productWebsiteAddressEndingSlashRemovedLiveOrDevOrUndefined + "/?c=" + directAccessLinkForUser70Chars;
      }
      else {
        directAccessLinkForUser = "--Not Yet Generated--";
      }
    }
    

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="medium"
        p_title="Get Direct Access Web Link"
        f_onClickCancel={this.props.f_onClickCancel}>
        <div className="flex11a yScroll medFullPad">
          <div className="">
            <font className="fontItalic fontTextLight">
              {"Below is a direct web link generated for you that you can use to access and edit only this Capture '" + c_captureName + "' without having to log in."}
            </font>
          </div>
          <div className="smallTopMargin">
            <font className="fontItalic fontTextLight">
              {"This can be used to integrate CaptureExec inside a Microsoft Teams tab for this particular deal."}
            </font>
          </div>
          <div className="bigTopMargin lrMedPad">
            <input
              type="text"
              style={{width:"100%", padding:"0.4em", fontSize:"1.2em"}}
              ref={this.inputTextRef}
              value={directAccessLinkForUser}
            />
          </div>
          <div className="smallTopMargin displayFlexColumnHcVc">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Copy Link to Clipboard"
              f_onClick={this.onclick_copy_link_to_clipboard}
            />
            {(s_linkCopiedToClipboardTF) &&
              <div className="microTopMargin">
                <font className="fontItalic fontGreen">
                  {"Link copied!"}
                </font>
              </div>
            }
          </div>
        </div>
        <div className="flex00a displayFlexColumnHcVc borderT1ddd tbMedPad">
          <CEGeneralReact.CEButton
            p_type="gray"
            p_text="Close"
            f_onClick={this.props.f_onClickCancel}
          />
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


const CopyOpenCaptureProcessFloatingBox = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class CopyOpenCaptureProcessFloatingBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_copyCaptureCodename: this.props.OpenCaptureMobx.c_openCaptureMap.get("codename") + " - COPY",
      s_copyCaptureOpportunityName: this.props.OpenCaptureMobx.c_openCaptureMap.get("opportunity_name") + " - COPY"
    };
  }

  onchange_copy_codename = (i_newValue) => {
    this.setState({s_copyCaptureCodename:i_newValue});
  }

  onchange_copy_opportunity_name = (i_newValue) => {
    this.setState({s_copyCaptureOpportunityName:i_newValue});
  }

  onswitch_copy_stage_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_stage_tf(!this.props.OpenCaptureMobx.o_copyCaptureStageTF); }
  onswitch_copy_dates_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_dates_tf(!this.props.OpenCaptureMobx.o_copyCaptureDatesTF); }
  onswitch_copy_tasks_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_tasks_tf(!this.props.OpenCaptureMobx.o_copyCaptureTasksTF); }
  onswitch_copy_deal_shaping_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_deal_shaping_tf(!this.props.OpenCaptureMobx.o_copyCaptureDealShapingTF); }
  onswitch_copy_teammates_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_teammates_tf(!this.props.OpenCaptureMobx.o_copyCaptureTeammatesTF); }
  onswitch_copy_teammates_surveys_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_teammates_surveys_tf(!this.props.OpenCaptureMobx.o_copyCaptureTeammatesSurveysTF); }
  onswitch_copy_competitors_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_competitors_tf(!this.props.OpenCaptureMobx.o_copyCaptureCompetitorsTF); }
  onswitch_copy_proposal_themes_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_proposal_themes_tf(!this.props.OpenCaptureMobx.o_copyCaptureProposalThemesTF); }
  onswitch_copy_risks_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_risks_tf(!this.props.OpenCaptureMobx.o_copyCaptureRisksTF); }
  onswitch_copy_notepad_tf = () => { this.props.OpenCaptureMobx.a_copy_capture_set_copy_notepad_tf(!this.props.OpenCaptureMobx.o_copyCaptureNotepadTF); }

  onclick_copy_capture = () => {
    this.props.OpenCaptureMobx.a_copy_currently_open_capture_close_then_open_copied_capture(this.state.s_copyCaptureCodename, this.state.s_copyCaptureOpportunityName);
  }

  onclick_cancel_copy = () => {
    this.props.OpenCaptureMobx.a_copy_capture_set_copy_state(undefined);
  }

  render() {
    const s_copyCaptureCodename = this.state.s_copyCaptureCodename;
    const s_copyCaptureOpportunityName = this.state.s_copyCaptureOpportunityName;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const o_copyCaptureState = this.props.OpenCaptureMobx.o_copyCaptureState;
    const o_copyCaptureStageTF = this.props.OpenCaptureMobx.o_copyCaptureStageTF;
    const o_copyCaptureDatesTF = this.props.OpenCaptureMobx.o_copyCaptureDatesTF;
    const o_copyCaptureTasksTF = this.props.OpenCaptureMobx.o_copyCaptureTasksTF;
    const o_copyCaptureDealShapingTF = this.props.OpenCaptureMobx.o_copyCaptureDealShapingTF;
    const o_copyCaptureTeammatesTF = this.props.OpenCaptureMobx.o_copyCaptureTeammatesTF;
    const o_copyCaptureTeammatesSurveysTF = this.props.OpenCaptureMobx.o_copyCaptureTeammatesSurveysTF;
    const o_copyCaptureCompetitorsTF = this.props.OpenCaptureMobx.o_copyCaptureCompetitorsTF;
    const o_copyCaptureProposalThemesTF = this.props.OpenCaptureMobx.o_copyCaptureProposalThemesTF;
    const o_copyCaptureRisksTF = this.props.OpenCaptureMobx.o_copyCaptureRisksTF;
    const o_copyCaptureNotepadTF = this.props.OpenCaptureMobx.o_copyCaptureNotepadTF;
    const c_captureTypeVisibleCardIDsArray = this.props.OpenCaptureMobx.c_captureTypeVisibleCardIDsArray;

    const c_companyUsingCodenameTF = this.props.DatabaseMobx.c_companyUsingCodenameTF;
    const c_bitIdiqRapidResponseTF = this.props.DatabaseMobx.c_bitIdiqRapidResponseTF;
    const c_cardNameAdvanceStage = this.props.DatabaseMobx.c_cardNameAdvanceStage;
    const c_cardNameDates = this.props.DatabaseMobx.c_cardNameDates;
    const c_cardNameTasks = this.props.DatabaseMobx.c_cardNameTasks;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;
    const c_fieldMapOfCodename = this.props.DatabaseMobx.c_fieldMapOfCodename;
    const c_fieldMapOfOpportunityName = this.props.DatabaseMobx.c_fieldMapOfOpportunityName;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;

    var stateIsCopyOptionsTF = false;
    var stateIsCopyingTF = false;
    var stateIsFailedCaptureRow = false;
    var stateIsFailedCaptureData = false;
    if(o_copyCaptureState === "copyOptions") { stateIsCopyOptionsTF = true; }
    else if(o_copyCaptureState === "copying") { stateIsCopyingTF = true; }
    else if(o_copyCaptureState === "failedCaptureRow") { stateIsFailedCaptureRow = true; }
    else if(o_copyCaptureState === "failedCaptureData") { stateIsFailedCaptureData = true; }

    //copy state error messages confirm floating box
    if(stateIsFailedCaptureRow || stateIsFailedCaptureData) {
      var errorMessage = "Error copying the following capture\n\n";
      errorMessage += this.props.OpenCaptureMobx.o_copyCaptureOldNewNamesString + "\n\n";
      if(stateIsFailedCaptureRow) {
        errorMessage += "New capture field to be created. Try the Copy Capture operation again."
      }
      else {
        errorMessage += "The new capture was created (currently open), but the supplemental capture data (data on cards other than " + c_cardNameDetails + ", " + c_cardNameDates + ", and " + c_cardNameAdvanceStage + "). You can delete this new capture and try the copy again on the original capture."
      }
      errorMessage += "\n\nIf this message persists, please submit a ticket mentioning which capture you are trying to copy. Thank you!";

      return(
        <CEGeneralReact.ConfirmBox
          p_type="error"
          p_title="Error Copying Capture"
          f_onClickCancel={this.onclick_cancel_copy}
          f_onClickConfirm={this.onclick_cancel_copy}>
          {errorMessage}
        </CEGeneralReact.ConfirmBox>
      );
    }

    //copy options floating box
    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="copyCapture"
        p_title={"Copy Capture (ID: "  + o_openCaptureID + ")"}
        f_onClickCancel={this.onclick_cancel_copy}>
        <div className="flex11a yScroll medFullPad">
          <div className="">
            <font className="font11 fontItalic fontTextLight">
              {"This will create a new capture as a copy of this open capture. You can enter a new name for the copied capture below. All other fields on the " + c_cardNameDetails + " card will be copied directly. Use the switches below to choose which other cards will have their data copied."}
            </font>
          </div>
          {(c_companyUsingCodenameTF) &&
            <>
              <div className="medTopMargin">
                <font className="font11 fontBold fontTextLighter">
                  {"New " + c_fieldMapOfCodename.get("display_name")}
                </font>
              </div>
              <div className="microTopMargin">
                <LibraryReact.Text
                  p_value={s_copyCaptureCodename}
                  p_styleObj={{width:"100%"}}
                  p_tabIndex={1}
                  f_onChange={this.onchange_copy_codename}
                />
              </div>
            </>
          }
          <div className="medTopMargin">
            <font className="font11 fontBold fontTextLighter">
              {"New " + c_fieldMapOfOpportunityName.get("display_name")}
            </font>
          </div>
          <div className="microTopMargin hugeBottomMargin">
            <LibraryReact.Text
              p_value={s_copyCaptureOpportunityName}
              p_styleObj={{width:"100%"}}
              p_tabIndex={2}
              f_onChange={this.onchange_copy_opportunity_name}
            />
          </div>
          <div className="medTopMargin">
            <font className="font11 fontBold fontItalic fontBlue">
              {"All " + c_cardNameDetails + " Card fields are always copied"}
            </font>
          </div>
          <div className="medTopMargin">
            <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureDatesTF} p_onText="Copy All Dates" p_offText="Reset All Dates to --Not Set--" p_tabIndex={3} f_onSwitch={this.onswitch_copy_dates_tf} />
          </div>
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDTasks, c_captureTypeVisibleCardIDsArray)) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureTasksTF} p_onText={"Copy All " + c_cardNameTasks} p_offText={"Do not copy " + c_cardNameTasks} p_tabIndex={11} f_onSwitch={this.onswitch_copy_tasks_tf} />
            </div>
          }
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDAdvanceStage, c_captureTypeVisibleCardIDsArray)) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureStageTF} p_onText={"Copy Current " + c_fieldMapOfStage.get("display_name")} p_offText={"Reset to first " + c_fieldMapOfStage.get("display_name")} p_tabIndex={4} f_onSwitch={this.onswitch_copy_stage_tf} />
            </div>
          }
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDDealShaping, c_captureTypeVisibleCardIDsArray)) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureDealShapingTF} p_onText="Copy All Answers and Responses to Deal Shaping Questions" p_offText="Reset Deal Shaping Questions as Unanswered" p_tabIndex={5} f_onSwitch={this.onswitch_copy_deal_shaping_tf} />
            </div>
          }
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDTeammates, c_captureTypeVisibleCardIDsArray)) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureTeammatesTF} p_onText="Copy All Teammates with Allocations (any contract processes/documents will be reset)" p_offText="Do not copy Teammates" p_tabIndex={6} f_onSwitch={this.onswitch_copy_teammates_tf} />
            </div>
          }
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDTeammates, c_captureTypeVisibleCardIDsArray) && c_bitIdiqRapidResponseTF) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureTeammatesSurveysTF} p_onText="Copy All Teammate Surveys (with Questions) and Uploaded Invitation File Attachments" p_offText="Do not copy Teammate Surveys" p_tabIndex={7} f_onSwitch={this.onswitch_copy_teammates_surveys_tf} />
            </div>
          }
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDCompetitors, c_captureTypeVisibleCardIDsArray)) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureCompetitorsTF} p_onText="Copy All Competitors with Entered Information" p_offText="Do not copy Competitors" p_tabIndex={8} f_onSwitch={this.onswitch_copy_competitors_tf} />
            </div>
          }
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDProposalThemes, c_captureTypeVisibleCardIDsArray)) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureProposalThemesTF} p_onText="Copy All Proposal Themes" p_offText="Do not copy Proposal Themes" p_tabIndex={9} f_onSwitch={this.onswitch_copy_proposal_themes_tf} />
            </div>
          }
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDRiskAssessment, c_captureTypeVisibleCardIDsArray)) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureRisksTF} p_onText="Copy All Entered Risks" p_offText="Do not copy Risks" p_tabIndex={10} f_onSwitch={this.onswitch_copy_risks_tf} />
            </div>
          }
          {(JSFUNC.in_array(this.props.DatabaseMobx.k_cardIDNotepad, c_captureTypeVisibleCardIDsArray)) &&
            <div className="medTopMargin">
              <CEGeneralReact.SwitchWithTextAndConfirmBox p_isOnTF={o_copyCaptureNotepadTF} p_onText="Copy All Note Stamps and Notepad" p_offText="Do not copy Note Stamps or Notepad" p_tabIndex={11} f_onSwitch={this.onswitch_copy_notepad_tf} />
            </div>
          }
          <div className="hugeTopMargin">
            <font className="font11 fontItalic fontTextLighter">
              {"Note: no uploaded documents, created budgets, or conversations are included in the copy"}
            </font>
          </div>
        </div>
        <div className="flex00a displayFlexColumnHcVc borderT1ddd tbMedPad">
          {(stateIsCopyingTF) ? (
            <>
              <div className="smallBottomMargin">
                <font className="font11 fontItalic fontTextLighter">
                  {"Copying Capture..."}
                </font>
              </div>
              <CEGeneralReact.LoadingAnimation />
            </>
          ) : (
            <div className="displayFlexRow flexWrap">
              <div className="flex00a lrMedMargin">
                <CEGeneralReact.CEButton
                  p_type="captureRed"
                  p_text="Create This Copied Capture"
                  p_tabIndex={10}
                  f_onClick={this.onclick_copy_capture}
                />
              </div>
              <div className="flex00a lrMedMargin">
                <CEGeneralReact.CEButton
                  p_type="gray"
                  p_text="Cancel"
                  p_tabIndex={11}
                  f_onClick={this.onclick_cancel_copy}
                />
              </div>
            </div>
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


const ArchiveCaptureFloatingBox = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class ArchiveCaptureFloatingBox extends Component { //props: f_onClickCancel
  onclick_confirm_archive_capture = () => {
    const c_captureIsArchivedTF = this.props.OpenCaptureMobx.c_captureIsArchivedTF;
    const c_fieldMapOfArchiveDate = this.props.DatabaseMobx.c_fieldMapOfArchiveDate;

    var updatedArchiveDate = JSFUNC.blank_date();
    if(!c_captureIsArchivedTF) {
      updatedArchiveDate = JSFUNC.now_date();
    }
    this.props.OpenCaptureMobx.a_details_update_field_value(c_fieldMapOfArchiveDate, updatedArchiveDate);

    if(JSFUNC.is_function(this.props.f_onClickCancel)) {
      this.props.f_onClickCancel();
    }
  }

  render() {
    const c_captureName = this.props.OpenCaptureMobx.c_captureName;
    const c_captureIsArchivedTF = this.props.OpenCaptureMobx.c_captureIsArchivedTF;

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="confirmBox"
        p_title={((c_captureIsArchivedTF) ? ("Unarchive Capture") : ("Archive Capture"))}
        f_onClickCancel={this.props.f_onClickCancel}>
        <div className="flex11a yScroll medFullPad">
          {(c_captureIsArchivedTF) ? (
            <>
              <div className="medBottomMargin">{"Are you sure you want to unarchive Capture '" + c_captureName + "'?"}</div>
              <div>{"This will restore this Capture to be loaded when logging in, making it available for search, edit, and data summaries."}</div>
            </>
          ) : (
            <>
              <div className="medBottomMargin">{"Are you sure you want to archive Capture '" + c_captureName + "'?"}</div>
              <div>{"An archived Capture will not be loaded when logging in, and thus will not appear in searches, on dashboards, or in executive data summaries. An option will appear on your screen where you can choose to load all archived Captures and be able to view them."}</div>
            </>
          )}
        </div>
        <div className="flex00a displayFlexColumnHcVc borderT1ddd tbMedPad">
          <div className="flex00a displayFlexRow">
            <div className="flex00a lrMedPad">
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text={((c_captureIsArchivedTF) ? ("Unarchive Capture") : ("Archive Capture"))}
                f_onClick={this.onclick_confirm_archive_capture}
              />
            </div>
            <div className="flex00a lrMedPad">
              <CEGeneralReact.CEButton
                p_type="gray"
                p_text="Cancel"
                f_onClick={this.props.f_onClickCancel}
              />
            </div>
          </div>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


const DeleteOpenCaptureProcessFloatingBox = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class DeleteOpenCaptureProcessFloatingBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectedDeleteCaptureReasonIDsComma: "",
      s_deleteCaptureComment: ""
    }
  }

  onchange_delete_capture_reason = (i_newValue) => {
    this.setState({s_selectedDeleteCaptureReasonIDsComma:i_newValue});
  }

  onchange_delete_capture_comment = (i_newValue) => {
    this.setState({s_deleteCaptureComment:i_newValue});
  }

  onclick_delete_capture = () => {
    this.props.OpenCaptureMobx.a_delete_and_close_currently_open_capture();
    this.props.OpenCaptureMobx.a_delete_capture_set_delete_state(undefined);
  }

  onclick_request_admin_to_delete_capture = () => {
    this.props.OpenCaptureMobx.a_delete_capture_request_admin_delete_send_notification(this.state.s_selectedDeleteCaptureReasonIDsComma, this.state.s_deleteCaptureComment);
    this.props.OpenCaptureMobx.a_delete_capture_set_delete_state("deleteRequestMade");
  }

  onclick_cancel = () => {
    this.props.OpenCaptureMobx.a_delete_capture_set_delete_state(undefined);
  }

  render() {
    const s_selectedDeleteCaptureReasonIDsComma = this.state.s_selectedDeleteCaptureReasonIDsComma;
    const s_deleteCaptureComment = this.state.s_deleteCaptureComment;

    const o_deleteCaptureState = this.props.OpenCaptureMobx.o_deleteCaptureState;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_captureName = this.props.OpenCaptureMobx.c_captureName;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    var deleteCaptureComponent = null;
    if(c_combinedUserObj.powerHasAdminPowerTF) {
      deleteCaptureComponent = (
        <CEGeneralReact.ConfirmBox
          p_type="confirmDelete"
          p_title="Delete Capture"
          p_button1Name="Delete Capture"
          f_onClickConfirm={this.onclick_delete_capture}
          f_onClickCancel={this.onclick_cancel}>
          {"Are you sure you want to delete this capture?\n\n - Capture ID: " + o_openCaptureID + "\n - Capture Name: " + c_captureName + "\n\nThis will delete all capture data, answers to questions, information on all cards, and any uploaded documents for this capture."}
        </CEGeneralReact.ConfirmBox>
      );
    }
    else { //normal user can't delete a capture, but can make a request to the admin, which shows up as a notification for the admin
      if(o_deleteCaptureState === "deleteRequestMade") {
        deleteCaptureComponent = (
          <CEGeneralReact.ConfirmBox
            p_type="cancel"
            p_title="Request Admin to Delete Capture"
            p_button1Name={undefined}
            p_button2Name="Close"
            f_onClickCancel={this.onclick_cancel}>
            {"A request has been made to the Admin to delete capture (ID: " + o_openCaptureID + ") '" + c_captureName + "'"}
          </CEGeneralReact.ConfirmBox>
        );
      }
      else {
        deleteCaptureComponent = (
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="smallVertical"
            p_title="Request Admin to Delete Capture"
            f_onClickCancel={this.onclick_cancel}>
            <div className="flex11a yScroll medFullPad">
              <div>
                {"Only your CaptureExec Admin can delete captures from the system. Push 'Request' below to leave a notification on their dashboard for this capture to be deleted.\n\n - Capture ID: " + o_openCaptureID + "\n - Capture Name: " + c_captureName}
              </div>
              <div className="hugeTopMargin microBottomMargin">
                <font className="fontItalic fontTextLight">
                  {"Select a reason below for deleting this capture:"}
                </font>
              </div>
              <div>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiAddDeleteCaptureReasonsFieldTypeObj}
                  p_valueRaw={s_selectedDeleteCaptureReasonIDsComma}
                  p_tabIndex={1}
                  f_onChangeOrOnSelect={this.onchange_delete_capture_reason}
                />
              </div>
              <div className="hugeTopMargin microBottomMargin">
                <font className="fontItalic fontTextLight">
                  {"Comments (optional):"}
                </font>
              </div>
              <div style={{height:"8em"}}>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
                  p_valueRaw={s_deleteCaptureComment}
                  p_tabIndex={2}
                  f_onChangeOrOnSelect={this.onchange_delete_capture_comment}
                />
              </div>
            </div>
            <div className="flex00a displayFlexColumnHcVc borderT1ddd tbPad">
              <div className="flex00a displayFlexRow flexWrap">
                <div className="flex00a lrMedPad">
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text="Request"
                    p_tabIndex={3}
                    f_onClick={this.onclick_request_admin_to_delete_capture}
                  />
                </div>
                <div className="flex00a lrMedPad">
                  <CEGeneralReact.CEButton
                    p_type="gray"
                    p_text="Cancel"
                    p_tabIndex={4}
                    f_onClick={this.onclick_cancel}
                  />
                </div>
              </div>
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        );
      }
    }

    return(
      deleteCaptureComponent
    );
  }
}));



//=================================================================================================================================



const OpenCaptureSnapshotOrOpenCards = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class OpenCaptureSnapshotOrOpenCards extends Component {
  onselect_capture_type = (i_newValue) => {
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;
    this.props.OpenCaptureMobx.a_details_update_field_value(c_fieldMapOfCaptureType, i_newValue);
  }

  render() {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const o_openCardLeftID = this.props.OpenCaptureMobx.o_openCardLeftID;
    const o_openCardRightID = this.props.OpenCaptureMobx.o_openCardRightID;
    const o_openCardFullID = this.props.OpenCaptureMobx.o_openCardFullID;
    const c_captureExistsTF = this.props.OpenCaptureMobx.c_captureExistsTF;
    const c_captureTypeID = this.props.OpenCaptureMobx.c_captureTypeID;
    const c_captureTypeExistsTF = this.props.OpenCaptureMobx.c_captureTypeExistsTF;
    const c_captureTypeHas0CardsTF = this.props.OpenCaptureMobx.c_captureTypeHas0CardsTF;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    //if the capture does not exist, draw a blank screen
    if(!c_captureExistsTF) {
      return(
        <CEGeneralReact.EmptyScreenWhite p_fontClass="font13 fontBold fontTextLight">
          {"--Capture ID " + o_openCaptureID + " could not be loaded--"}
        </CEGeneralReact.EmptyScreenWhite>
      );
    }

    //if the capture type is not filled out properly, change the full screen to be able to edit the capture type for this open capture
    if(!c_captureTypeExistsTF) {
      const captureTypeFieldDisplayName = c_fieldMapOfCaptureType.get("display_name");
      return(
        <div className="flex11a yScroll medFullPad">
          <div className="">
            <font className="font13 fontItalic fontTextLight">
              {"This capture does not have its " + captureTypeFieldDisplayName + " field set. Without this, no cards can be drawn to edit data."}
            </font>
          </div>
          <div className="smallTopMargin">
            <font className="font13 fontBold fontTextLight">
              {"Please select the " + captureTypeFieldDisplayName + " below:"}
            </font>
          </div>
          <div className="smallTopMargin">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureTypeNoClearFieldTypeObj}
              p_valueRaw={c_captureTypeID}
              p_tabIndex={1}
              f_onChangeOrOnSelect={this.onselect_capture_type}
            />
          </div>
        </div>
      );
    }

    //if the selected capture type has 0 cards, show a message so that it's not just a blank white screen which is confusing
    if(c_captureTypeHas0CardsTF) {
      const c_captureTypeName = this.props.OpenCaptureMobx.c_captureTypeName;
      const captureTypeFieldDisplayName = c_fieldMapOfCaptureType.get("display_name");
      return(
        <div className="flex11a displayFlexColumnHcVc medFullPad yScroll">
          <div className="textCenter">
            <font className="font13 fontBold fontTextLight">
              {"--This Capture has no cards to display--"}
            </font>
          </div>
          <div className="smallTopMargin textCenter">
            <font className="font12 fontItalic fontTextLighter">
              <div>{"The " + captureTypeFieldDisplayName + " selected for this Capture, '" + c_captureTypeName + "', has no cards set for this screen"}</div>
              <div>{"Your CaptureExec Admin can use the System Setup 'Capture Cards' subtab to add, change, and reorder these cards"}</div>
            </font>
          </div>
        </div>
      );
    }

    //card is not open on the left or right or both left and right
    if(!o_openCardLeftID && !o_openCardRightID) {
      //card is open full, draw the card fullscreen
      if(o_openCardFullID) {
        return(
          <Card p_container="full" p_cardID={o_openCardFullID} />
        );
      }

      //no cards full/left/right are open, draw the snapshot fullscreen
      return(
        <SnapshotCardsContainer p_container="full" />
      );
    }

    //splitscreen, single card is open left or right, or both cards are open left and right
    return(
      <div className="flex11a displayFlexRow">
        <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
          {(o_openCardLeftID) ? (
            <Card p_container="left" p_cardID={o_openCardLeftID} />
          ) : (
            <SnapshotCardsContainer p_container="left" />
          )}
        </div>
        <div className="flex11a displayFlexColumn" style={{flexBasis:"200em"}}>
          {(o_openCardRightID) ? (
            <Card p_container="right" p_cardID={o_openCardRightID} />
          ) : (
            <SnapshotCardsContainer p_container="right" />
          )}
        </div>
      </div>
    );
  }
}));



const SnapshotCardsContainer = inject("CaptureExecMobx", "OpenCaptureMobx")(observer(
class SnapshotCardsContainer extends Component { //props: p_container
  render() {
    const p_container = this.props.p_container;

    const c_cardIDNotInSnapshotCardsContainer = this.props.OpenCaptureMobx.c_cardIDNotInSnapshotCardsContainer;
    const c_captureTypeVisibleCardIDsArray = this.props.OpenCaptureMobx.c_captureTypeVisibleCardIDsArray;

    //add cardIDs that are not currently open to an array (do not draw cards with IDs that are currently already open)
    var cardIDsToDrawArray = []; //if card id4 is currently open, this array is has the other cardIDs except 4
    var cardID = -1;
    for(let c = 0; c < c_captureTypeVisibleCardIDsArray.length; c++) {
      cardID = c_captureTypeVisibleCardIDsArray[c];
      if(cardID !== c_cardIDNotInSnapshotCardsContainer) {
        cardIDsToDrawArray.push(cardID);
      }
    }

    //number of cards per row based on which container the snapshotsContainer is currently housed and based on the o_mediaQueryFlag, need a random row key to force a full render each time the mediaQuery changes
    const numCardsPerRow = this.props.OpenCaptureMobx.snapshot_num_cards_per_row(p_container);

    //cardID matrix is 2D to represent rows and columns, -1 is put where an empty slot should be drawn at the end of the cards if a row is not finished
    const cardIDsRCMatrix = JSFUNC.get_rc_matrix_from_id_array_and_num_columns(cardIDsToDrawArray, numCardsPerRow);

    return(
      <div className="flex11a displayFlexColumn yScroll yScrollBottomPad">
        {cardIDsRCMatrix.map((rowCardIDsArray, rowIndex) =>
          <SnapshotCardsRow key={JSFUNC.rc_unique_row_key(numCardsPerRow, rowIndex)} p_rowCardIDsArray={rowCardIDsArray} />
        )}
      </div>
    );
  }
}));

const SnapshotCardsRow = inject("UserMobx")(observer(
class SnapshotCardsRow extends Component { //props: p_rowCardIDsArray
  render() {
    const userCaptureCardMinHeightEm = this.props.UserMobx.c_userCaptureCardMinHeightEm;
    const userCaptureCardMaxHeightEm = this.props.UserMobx.c_userCaptureCardMaxHeightEm;
    return(
      <div
        className="flex10a displayFlexRow"
        style={{flexBasis:userCaptureCardMinHeightEm + "em", maxHeight:userCaptureCardMaxHeightEm + "em"}}>
        {this.props.p_rowCardIDsArray.map((m_cardID) =>
          <Card key={m_cardID} p_cardID={m_cardID} p_container="snapshot" />
        )}
      </div>
    );
  }
}));

const Card = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class Card extends Component { //props: p_container, p_cardID
  onclick_card_launch_full = () => {
    this.props.OpenCaptureMobx.a_launch_card_full(this.props.p_cardID);
  }

  onclick_card_launch_left = () => {
    this.props.OpenCaptureMobx.a_launch_card_left(this.props.p_cardID);
  }

  onclick_card_launch_right = () => {
    this.props.OpenCaptureMobx.a_launch_card_right(this.props.p_cardID);
  }

  onclick_close_card = () => {
    const p_container = this.props.p_container;
    if(p_container === "left") { this.props.OpenCaptureMobx.a_close_card_left(); }
    else if(p_container === "right") { this.props.OpenCaptureMobx.a_close_card_right(); }
    else if(p_container === "full") { this.props.OpenCaptureMobx.a_close_card_full(); }
  }

  onclick_snapshot_card_contents = () => {
    if(this.props.OpenCaptureMobx.o_openCardLeftID) {
      this.props.OpenCaptureMobx.a_launch_card_right(this.props.p_cardID);
    }
    else if(this.props.OpenCaptureMobx.o_openCardRightID) {
      this.props.OpenCaptureMobx.a_launch_card_left(this.props.p_cardID);
    }
    else {
      this.props.OpenCaptureMobx.a_launch_card_full(this.props.p_cardID);
    }
  }

  render() {
    const p_container = this.props.p_container;
    const p_cardID = this.props.p_cardID;

    if(!p_cardID || p_cardID <= 0) { //negative number cardIDs are a flag to return an empty card slot with the same width as the others, but no shadow
      return(
        <div className="flex11a medFullMargin" style={{flexBasis:"100em"}} />
      );
    }

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;

    var isSnapshotTF = false;
    var isLeftTF = false;
    var isRightTF = false;
    var isFullTF = false;
    if(p_container === "snapshot") { isSnapshotTF = true; }
    else if(p_container === "left") { isLeftTF = true; }
    else if(p_container === "right") { isRightTF = true; }
    else if(p_container === "full") { isFullTF = true; }

    const cardLabel = this.props.DatabaseMobx.get_details_card_custom_or_ce_name(p_cardID);

    var cardComponent = null;
    if(p_cardID === this.props.DatabaseMobx.k_cardIDAdvanceStage) {        cardComponent = ((isSnapshotTF) ? (<AdvanceStageSnapshot />) :    (<AdvanceStage p_container={p_container} />));    }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDDates) {          cardComponent = ((isSnapshotTF) ? (<DatesSnapshot />) :           (<Dates p_container={p_container} />));           }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDTasks) {          cardComponent = ((isSnapshotTF) ? (<TasksSnapshot />) :           (<Tasks p_container={p_container} />));           }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDDetails) {        cardComponent = ((isSnapshotTF) ? (<DetailsSnapshot />) :         (<Details p_container={p_container} />));         }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDDealShaping) {    cardComponent = ((isSnapshotTF) ? (<DealShapingSnapshot />) :     (<DealShaping p_container={p_container} />));     }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDTeammates) {      cardComponent = ((isSnapshotTF) ? (<TeammatesSnapshot />) :       (<Teammates p_container={p_container} />));       }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDCompetitors) {    cardComponent = ((isSnapshotTF) ? (<CompetitorsSnapshot />) :     (<Competitors p_container={p_container} />));     }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDProposalThemes) { cardComponent = ((isSnapshotTF) ? (<ProposalThemesSnapshot />) :  (<ProposalThemes p_container={p_container} />));  }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDRiskAssessment) { cardComponent = ((isSnapshotTF) ? (<RiskAssessmentSnapshot />) :  (<RiskAssessment p_container={p_container} />));  }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDBudget) {         cardComponent = ((isSnapshotTF) ? (<BudgetSnapshot />) :          (<Budget p_container={p_container} />));          }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDConversations) {  cardComponent = ((isSnapshotTF) ? (<ConversationsSnapshot />) :   (<Conversations p_container={p_container} />));   }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDDocuments) {      cardComponent = ((isSnapshotTF) ? (<DocumentsSnapshot />) :       (<Documents p_container={p_container} />));       }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDTemplates) {      cardComponent = ((isSnapshotTF) ? (<TemplatesSnapshot />) :       (<Templates />));                                 }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDIDIQTaskOrders) { cardComponent = ((isSnapshotTF) ? (<IdiqTaskOrdersSnapshot />) :  (<IdiqTaskOrders />));                            }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDChangelog) {      cardComponent = ((isSnapshotTF) ? (<ChangeLogSnapshot />) :       (<ChangeLog />));                                 }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDNotepad) {        cardComponent = ((isSnapshotTF) ? (<NotepadSnapshot />) :         (<Notepad />));                                   }
    else if(p_cardID === this.props.DatabaseMobx.k_cardIDRevenue) {        cardComponent = ((isSnapshotTF) ? (<RevenueSnapshot />) :         (<Revenue />));                                   }

    return(
      <CardShell
        p_label={cardLabel}
        p_drawLeftRightButtonsTF={!c_isMobileOrTabletTF}
        f_onClickFull={((!isFullTF) ? (this.onclick_card_launch_full) : (undefined))}
        f_onClickLeft={((!isLeftTF) ? (this.onclick_card_launch_left) : (undefined))}
        f_onClickRight={((!isRightTF) ? (this.onclick_card_launch_right) : (undefined))}
        f_onClickClose={((!isSnapshotTF) ? (this.onclick_close_card) : (undefined))}
        f_onClickContent={((isSnapshotTF) ? (this.onclick_snapshot_card_contents) : (undefined))}>
        {cardComponent}
      </CardShell>
    );
  }
}));

export function CardShell(props) { //props: p_label, p_drawLeftRightButtonsTF, f_onClickFull, f_onClickLeft, f_onClickRight, f_onClickClose, f_onClickContent, children
  const drawLeftRightButtonsTF = JSFUNC.prop_value(props.p_drawLeftRightButtonsTF, true);
  return(
    <div className="flex11a displayFlexColumn medFullMargin boxShadowDarkGrayDarkBlueHover" style={{flexBasis:"100em", border:"solid 1px #e4e4e4"}}>
      <div className="flex00a displayFlexRowVc" style={{flexBasis:"2.8em", borderBottom: "solid 1px #888"}}>
        <div className="flex11a lrPad">
          <LibraryReact.Nowrap p_fontClass="font14 fontTextLighter">
            {props.p_label}
          </LibraryReact.Nowrap>
        </div>
        <CardLaunchButtonShell p_text="Full" f_onClick={props.f_onClickFull} />
        {(drawLeftRightButtonsTF) &&
          <CardLaunchButtonShell p_text="1/3" f_onClick={props.f_onClickLeft} />
        }
        {(drawLeftRightButtonsTF) &&
          <CardLaunchButtonShell p_text="2/3" f_onClick={props.f_onClickRight} />
        }
        <CardLaunchButtonShell p_text="X" f_onClick={props.f_onClickClose} />
      </div>
      <div
        className={"flex11a displayFlexColumn overflowHidden " + ((props.f_onClickContent !== undefined) ? ("cursorPointer") : (""))}
        onClick={props.f_onClickContent}>
        {props.children}
      </div>
    </div>
  );
}

function CardLaunchButtonShell(props) { //props: p_text, f_onClick
  const p_text = props.p_text;

  const canClickTF = (props.f_onClick !== undefined);
  const borderColor = ((canClickTF) ? ("#9cf") : ("#ccc"));
  const bgClass = ((canClickTF) ? ("cardLaunchButtonBgActive") : ("cardLaunchButtonBgInactive"));
  const cursorClass = ((canClickTF) ? ("cursorPointer") : (""));
  const fontColor = ((canClickTF) ? ("#55b") : ("#777"));

  return(
    <div
      className={"flex00a displayFlexColumnHcVc " + bgClass + " font08 " + cursorClass}
      style={{flexBasis:"2.6em", height:"2.2em", margin:"0 0.2em", border:"solid 1px " + borderColor, borderRadius:"0.6em", color:fontColor}}
      onClick={props.f_onClick}>
      {p_text}
    </div>
  );
}





//===================================================================================================================================
//--------Advance Stage-------------------------------------------------------------------------------------------
//===================================================================================================================================
const AdvanceStageSnapshot = inject("OpenCaptureMobx")(observer(
class AdvanceStageSnapshot extends Component {
  render() {
    const c_advanceStageCurrentStageNextStageObj = this.props.OpenCaptureMobx.c_advanceStageCurrentStageNextStageObj;

    const currentStageObj = c_advanceStageCurrentStageNextStageObj.currentStageObj;
    const nextStageObj = c_advanceStageCurrentStageNextStageObj.nextStageObj;

    const currentStageComponent = (
      <StageTimelineStage
        p_stageObj={currentStageObj}
        p_dragToChangeStageTF={false}
        p_indicatorIsHorizontalTF={true}
        p_mobileAdvanceStageActiveTF={false}
        f_onSelectMobileStageDeactivate={undefined}
      />
    );

    var nextStageComponent = null;
    if(nextStageObj !== undefined) {
      nextStageComponent = (
        <StageTimelineStage
          p_stageObj={nextStageObj}
          p_dragToChangeStageTF={false}
          p_indicatorIsHorizontalTF={true}
          p_mobileAdvanceStageActiveTF={false}
          f_onSelectMobileStageDeactivate={undefined}
        />
      );
    }

    return(
      <div className="flex11a overflowHidden smallFullMargin">
        {(nextStageObj !== undefined) ? (
          <div className="displayFlexRow">
            <div className="lrPad" style={{width:"50%"}}>
              {currentStageComponent}
            </div>
            <div className="lrPad" style={{width:"50%"}}>
              {nextStageComponent}
            </div>
          </div>
        ) : (
          <div className="displayFlexRow">
            <div style={{width:"25%"}} />
            <div className="lrPad" style={{width:"50%"}}>
              {currentStageComponent}
            </div>
            <div style={{width:"25%"}} />
          </div>
        )}
        <AdvanceStageRequirementsList p_isSnapshotTF={true} />
      </div>
    );
  }
}));

const AdvanceStage = inject("OpenCaptureMobx")(observer(
class AdvanceStage extends Component { //props: p_container
  render() {
    const p_container = this.props.p_container;

    return(
      <div className="flex11a yScroll yScrollBottomPad">
        <AdvanceStageTimeline p_container={p_container} />
        <div className="displayFlexColumnHcVc smallFullPad bgGrayGradient" style={{borderBottom:"solid 1px #ddd"}}>
          <font className="font12 fontBold fontTextLight">
            {"Requirements List"}
          </font>
        </div>
        <div className="lrMedMargin">
          <AdvanceStageRequirementsList p_container={p_container} p_isSnapshotTF={false} />
        </div>
      </div>
    );
  }
}));

const AdvanceStageTimeline = inject("CaptureExecMobx", "OpenCaptureMobx", "UserMobx")(observer(
class AdvanceStageTimeline extends Component { //props: p_container, p_activeTimelineTrueClosedDebriefFalse
  constructor(props) {
    super(props);
    this.state = {
      s_mobileAdvanceStageActiveTF: false //for mobile (can't drag to change stage) the "Advance the Stage" button opens all other stages to be clicked on, controlled by this flag
    };
  }

  onclick_mobile_advance_stage_button_toggle_active_tf = () => {
    this.setState((i_state, i_props) => ({
      s_mobileAdvanceStageActiveTF: (!i_state.s_mobileAdvanceStageActiveTF)
    }));
  }

  deactivate_mobile_advance_stage = () => {
    this.setState({s_mobileAdvanceStageActiveTF:false});
  }

  render() {
    const p_container = JSFUNC.prop_value(this.props.p_container, "full");

    const c_userCanAdvanceCaptureStageTF = this.props.UserMobx.c_userCanAdvanceCaptureStageTF;

    var stageTimelineAllowDragTF = this.props.OpenCaptureMobx.advance_stage_timeline_allow_drag_tf(p_container); //mobile cannot drag and drop
    var stageTimelineIsHorizontalTF = this.props.OpenCaptureMobx.advance_stage_timeline_is_horizontal_tf(p_container); //timeline switches vertical when too small

    const timelineDataObj = this.props.OpenCaptureMobx.c_advanceStageTimelineDataObj;
    const activeStagesArrayOfObjs = timelineDataObj.activeStagesArrayOfObjs;
    const wonStagesArrayOfObjs = timelineDataObj.wonStagesArrayOfObjs; const numWonStages = wonStagesArrayOfObjs.length;
    const lostStagesArrayOfObjs = timelineDataObj.lostStagesArrayOfObjs; const numLostStages = lostStagesArrayOfObjs.length;
    const noBidStagesArrayOfObjs = timelineDataObj.noBidStagesArrayOfObjs; const numNoBidStages = noBidStagesArrayOfObjs.length;
    const cancelledStagesArrayOfObjs = timelineDataObj.cancelledStagesArrayOfObjs; const numCancelledStages = cancelledStagesArrayOfObjs.length;
    const closedStagesArrayOfArrayOfObjs = [wonStagesArrayOfObjs, lostStagesArrayOfObjs, noBidStagesArrayOfObjs, cancelledStagesArrayOfObjs];
    const numClosedStagesArray = [numWonStages, numLostStages, numNoBidStages, numCancelledStages];
    const maxNumClosedStages = Math.max(numWonStages, numLostStages, numNoBidStages, numCancelledStages);
    const maxClosedStagesIndexArray = JSFUNC.array_fill_incrementing_0_to_nm1(maxNumClosedStages);

    //active stage timeline
    const stageTimelineFlexClass = ((stageTimelineIsHorizontalTF) ? ("displayFlexRowVc") : (""));
    const stageTimelineMargin = ((stageTimelineIsHorizontalTF) ? ("0") : ("10%")); //vertical timelines on small screens have a 10% margin for each individual stage to the edge

    //upper right vertical dots menu for using click to advance stage on screen sizes 3/4/5/6 (if dragging is impossible like on a tablet)
    const stageTimelineVerticalDotsMenuItemsArrayOfObjs = [{
      displayName: "Advance the Stage",
      functionOnClickMenuItem: this.onclick_mobile_advance_stage_button_toggle_active_tf
    }];

    return(
      <div className="bgLighterGrayGradient" style={{borderBottom:"solid 1px #bbb"}}>
        <div className="displayFlexRow">
          <div className="flex11a smallFullPad textCenter">
            <font className="font14 fontTextLight">
              {"Stage Timeline: "}<font className="fontBold">{this.props.OpenCaptureMobx.c_captureTypeMap.get("name")}</font>
            </font>
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
            <CEGeneralReact.VerticalDotsMenu
              p_menuItemsArrayOfObjs={stageTimelineVerticalDotsMenuItemsArrayOfObjs}
            />
          </div>
        </div>
        {(c_userCanAdvanceCaptureStageTF && !stageTimelineAllowDragTF) &&
          <div className="displayFlexColumnHcVc smallFullPad">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text={((!this.state.s_mobileAdvanceStageActiveTF) ? ("Advance the Stage") : ("Select the Stage to Advance to"))}
              p_tabIndex={1}
              f_onClick={this.onclick_mobile_advance_stage_button_toggle_active_tf}
            />
          </div>
        }
        <div className={stageTimelineFlexClass} style={{marginLeft:stageTimelineMargin, marginRight:stageTimelineMargin}}>
          {activeStagesArrayOfObjs.map((stageObj) =>
            <div key={stageObj.id} className="flex11a smallFullMargin" style={{flexBasis:"100em"}}>
              <StageTimelineStage
                p_stageObj={stageObj}
                p_dragToChangeStageTF={true}
                p_indicatorIsHorizontalTF={stageTimelineIsHorizontalTF}
                p_mobileAdvanceStageActiveTF={this.state.s_mobileAdvanceStageActiveTF}
                f_onSelectMobileStageDeactivate={this.deactivate_mobile_advance_stage}
              />
            </div>
          )}
          <div className="flex11a" style={{flexBasis:(100 * maxNumClosedStages) + "em"}}>
            {[0,1,2,3].map((m_closedTypeIndex) =>
              <div className={stageTimelineFlexClass}>
                {maxClosedStagesIndexArray.map((m_closedStageIndex) =>
                  <div className="flex11a smallFullMargin" style={{flexBasis:"100em"}}>
                    {(m_closedStageIndex < numClosedStagesArray[m_closedTypeIndex]) &&
                      <StageTimelineStage
                        p_stageObj={closedStagesArrayOfArrayOfObjs[m_closedTypeIndex][m_closedStageIndex]}
                        p_dragToChangeStageTF={true}
                        p_indicatorIsHorizontalTF={stageTimelineIsHorizontalTF}
                        p_mobileAdvanceStageActiveTF={this.state.s_mobileAdvanceStageActiveTF}
                        f_onSelectMobileStageDeactivate={this.deactivate_mobile_advance_stage}
                      />
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div style={{height:"2em"}} />
      </div>
    );
  }
}));


export const StageTimelineStage = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class StageTimelineStage extends Component { //props: p_stageObj, p_minimizedStageTF, p_dragToChangeStageTF, p_indicatorIsHorizontalTF, p_advanceColorIsStageColorTF, p_mobileAdvanceStageActiveTF, p_customBorderHashColor, p_customTitle, f_onSelectMobileStageDeactivate
  constructor(props) {
    super(props);
    this.state = {
      s_dragoverTF: false,
      s_cantAdvanceWarningMessage: undefined //fill this with the cannot advance warning message when trying to advance to bring up the full screen confirmation box, pushing OK makes this field undefined again
    };
  }

  ondragover_stage = (i_isDragOverTF) => {
    this.setState({s_dragoverTF:i_isDragOverTF});
  }

  ondrop_stage = (i_dragStageID) => {
    this.advance_to_selected_stage();
  }

  onclick_mobile_selectable_stage = () => {
    this.advance_to_selected_stage();
    if(this.props.f_onSelectMobileStageDeactivate) {
      this.props.f_onSelectMobileStageDeactivate();
    }
  }

  advance_to_selected_stage = () => {
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const p_stageObj = this.props.p_stageObj;

    const canAdvanceToThisStageTF = ((p_stageObj.pastCurrentFutureFlag === "past") || (p_stageObj.pastCurrentFutureFlag === "futureOpen"));
    if(!canAdvanceToThisStageTF) { //this stage has unmet requirements and cannot be advanced to, bring up the warning box to say this
      this.setState({
        s_cantAdvanceWarningMessage: 'You have required fields that need to be filled in. Use the Requirements List section below to update the information.'
      });
    }
    else { //advance to dropped-on stage
      this.props.OpenCaptureMobx.a_details_update_field_value(c_fieldMapOfStage, p_stageObj.id);
    }
  }

  onclick_cannot_advance_warning_ok = () => {
    this.setState({s_cantAdvanceWarningMessage:undefined}); //dismisses the cannot advance stage warning box by pushing the ok button
  }

  render() {
    const s_dragoverTF = this.state.s_dragoverTF;
    const s_cantAdvanceWarningMessage = this.state.s_cantAdvanceWarningMessage;

    const p_stageObj = this.props.p_stageObj;
    const p_minimizedStageTF = JSFUNC.prop_value(this.props.p_minimizedStageTF, false);
    const p_dragToChangeStageTF = JSFUNC.prop_value(this.props.p_dragToChangeStageTF, false);
    const p_indicatorIsHorizontalTF = JSFUNC.prop_value(this.props.p_indicatorIsHorizontalTF, true);
    const p_advanceColorIsStageColorTF = JSFUNC.prop_value(this.props.p_advanceColorIsStageColorTF, false);
    const p_mobileAdvanceStageActiveTF = JSFUNC.prop_value(this.props.p_mobileAdvanceStageActiveTF, false);
    const p_customBorderHashColor = this.props.p_customBorderHashColor;
    const p_customTitle = this.props.p_customTitle;

    const c_companyRestrictAdvanceCaptureStageUserNamesMaskSortIfoObj = this.props.DatabaseMobx.c_companyRestrictAdvanceCaptureStageUserNamesMaskSortIfoObj;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const c_userCanAdvanceCaptureStageTF = this.props.UserMobx.c_userCanAdvanceCaptureStageTF;

    const userCanDragToChangeStageTF = (c_userCanAdvanceCaptureStageTF && p_dragToChangeStageTF);

    const isPastStageTF = (p_stageObj.pastCurrentFutureFlag === "past");
    const isCurrentStageTF = (p_stageObj.pastCurrentFutureFlag === "current");
    const isFutureOpenStageTF = (p_stageObj.pastCurrentFutureFlag === "futureOpen");
    const isFutureLockedStageTF = (p_stageObj.pastCurrentFutureFlag === "futureLocked");

    const stageIsDraggableTF = (userCanDragToChangeStageTF && isCurrentStageTF); //can only drag the current stage icon in the card (not snapshot), when the timeline is horizontal, and when it's the current stage
    const stageIsDroppableTF = (userCanDragToChangeStageTF && !isCurrentStageTF); //stage can be advanced to by dropping if not snapshot, horizontal timeline, and not the current stage
    const stageMobileIsSelectableTF = (p_mobileAdvanceStageActiveTF && !isCurrentStageTF);

    const stageNameBoxClassFlex = ((p_indicatorIsHorizontalTF) ? (" displayFlexColumn") : (" displayFlexRow"));
    const stageNameBoxClassBgCurrent = ((isCurrentStageTF) ? (" bgBlueGradient") : (""));
    const stageNameBoxClassDraggable = ((stageIsDraggableTF) ? (" bgBlueGradientHover cursorGrab") : (""));
    const stageNameBoxClassBgMobileCursor = ((stageMobileIsSelectableTF) ? (" cursorPointer") : (""));
    
    //border color
    var borderColor = "#333";
    if(p_customBorderHashColor !== undefined) {
      borderColor = p_customBorderHashColor;
    }
    else if(s_dragoverTF || stageMobileIsSelectableTF) {
      borderColor = ((isPastStageTF || isFutureOpenStageTF) ? ("#9d9") : ("#d99"));
    }
    else if(isPastStageTF) {
      borderColor = "#ddd";
    }
    else if(isCurrentStageTF) {
      borderColor = "#4a8bbd";
    }
    else if(isFutureOpenStageTF || isFutureLockedStageTF) {
      borderColor = "#bbb";
    }

    const boxHeightEm = 4;
    const triangleBorderEm = (boxHeightEm / 2);
    const triangleBackground = ((p_stageObj.isActiveStageTF) ? ("transparent") : (borderColor));

    //title
    var stageTitle = undefined;
    if(JSFUNC.string_is_filled_out_tf(p_customTitle)) { //separate full custom title input for 'Priority View' use of stage timeline boxes
      stageTitle = p_customTitle;
    }
    else {
      stageTitle = c_fieldMapOfStage.get("display_name") + " '" + p_stageObj.name + "'";

      //drag instructions addon for hover title
      if(p_dragToChangeStageTF) {
        if(c_userCanAdvanceCaptureStageTF) {
          stageTitle += "\n[Drag/drop current blue stage block to change to a new stage]";
        }
        else {
          stageTitle += "\n[Stage can only be changed by " + c_companyRestrictAdvanceCaptureStageUserNamesMaskSortIfoObj.valueMaskPlainText + "]";
        }
      }

      //custom title addon from Admin Stages Pool table
      if(JSFUNC.string_is_filled_out_tf(p_stageObj.hover_text)) {
        stageTitle += "\n\n" + p_stageObj.hover_text;
      }
    }
    

    //font class
    var stageFontClass = "fontBold fontTextLight"; //future and invalid stages have normal font
    if(isPastStageTF) {
      stageFontClass = "fontItalic fontTextLighter"
    }
    else if(isCurrentStageTF) {
      stageFontClass = "fontBold fontWhite"
    }

    //future open/locked color bar
    var futureOpenLockedColorBarComponent = null; //no bar for past/current stages
    if(isFutureOpenStageTF || isFutureLockedStageTF || p_advanceColorIsStageColorTF) {
      const futureOpenLockedFlexBasis = ((p_indicatorIsHorizontalTF) ? ("0.35em") : ("0.6em"));

      var futureOpenLockedColor = "#999";
      if(p_advanceColorIsStageColorTF) {
        futureOpenLockedColor = "#" + p_stageObj.color;
      }
      else { //for future stages green indicator if no requirements remain, red otherwise and prevent from advancing stage
        if(isFutureLockedStageTF) { //future locked, red
          futureOpenLockedColor = "#b44";
        }
        else {
          futureOpenLockedColor = "#4b4"; //future unlocked, green
        }
      }

      futureOpenLockedColorBarComponent = (
        <div className="flex00a" style={{flexBasis:futureOpenLockedFlexBasis, backgroundColor:futureOpenLockedColor}} />
      );
    }

    return(
      <div
        className="displayFlexRow positionRelative"
        style={{height:boxHeightEm + "em"}}
        title={stageTitle}>
        <CEGeneralReact.CE3Drag3Drop3Shell3
          p_uniqueString="stage"
          p_itemID={p_stageObj.id}
          p_draggableTF={stageIsDraggableTF}
          p_droppableTF={stageIsDroppableTF}
          p_dropZoneIsInvisibleOverlayTF={true}
          p_dropZoneOversizeWidthEm={undefined}
          p_class={"flex11a " + stageNameBoxClassFlex + stageNameBoxClassBgCurrent + stageNameBoxClassDraggable + stageNameBoxClassBgMobileCursor}
          p_styleObj={{border:"solid 2px " + borderColor, backgroundColor:"#fff"}}
          p_title={stageTitle}
          p_dragOverClass={undefined}
          p_dragOverStyleObj={{backgroundColor:"rgba(0,100,120,0.1)"}}
          f_isDragOverTF={this.ondragover_stage}
          f_onDropMatchingItem={this.ondrop_stage}
          f_onClick={((stageMobileIsSelectableTF) ? (this.onclick_mobile_selectable_stage) : (undefined))}>
          {(p_minimizedStageTF) ? (
            <div className="flex11a" />
          ) : (
            <div className={"flex11a displayFlexRow " + ((isCurrentStageTF) ? ("border bevelBorderDarkColors") : (""))}>
              {(stageIsDraggableTF) &&
                <div className="flex00a displayFlexColumnHcVc" style={{marginLeft:"0.25em"}}>
                  <CEGeneralReact.SvgDraggableDots />
                </div>
              }
              <div className="flex11a displayFlexRowVc lrPad textCenter">
                <LibraryReact.MaxHeightWrap p_maxHeight="2.6em" p_fontClass={"font11 " + stageFontClass}>
                  {p_stageObj.name}
                </LibraryReact.MaxHeightWrap>
              </div>
            </div>
          )}
          {futureOpenLockedColorBarComponent}
        </CEGeneralReact.CE3Drag3Drop3Shell3>
        {(!p_minimizedStageTF) &&
          <>
            <div className="flex00a" style={{flexBasis:triangleBorderEm + "em", background:triangleBackground}} />
            {(p_stageObj.isActiveStageTF) &&
              <div
                className="positionAbsolute"
                style={{right:"-" + triangleBorderEm + "em", width:"0", height:"0", border:"solid " + triangleBorderEm + "em", borderColor:"transparent transparent transparent " + borderColor}}
              />
            }
          </>
        }
        {(s_cantAdvanceWarningMessage) &&
          <CEGeneralReact.ConfirmBox
            p_type="ok"
            p_title={"Cannot Advance to stage '" + p_stageObj.name + "'"}
            f_onClickConfirm={this.onclick_cannot_advance_warning_ok}>
            {s_cantAdvanceWarningMessage}
          </CEGeneralReact.ConfirmBox>
        }
      </div>
    );
  }
}));

const AdvanceStageRequirementsList = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class AdvanceStageRequirementsList extends Component { //props: p_container, p_isSnapshotTF
  render() {
    const p_container = this.props.p_container;
    const p_isSnapshotTF = this.props.p_isSnapshotTF;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const currentStageIsActiveTFU = this.props.OpenCaptureMobx.c_advanceStageCurrentStageIsActiveTFU;
    const c_advanceStageCurrentStageNextStageObj = this.props.OpenCaptureMobx.c_advanceStageCurrentStageNextStageObj;

    const currentStageObj = c_advanceStageCurrentStageNextStageObj.currentStageObj;
    const nextStageObj = c_advanceStageCurrentStageNextStageObj.nextStageObj;

    //current stage does not exist
    if(currentStageIsActiveTFU === undefined) {
      return(
        <div className="smallTopMargin">
          <div>
            {"Capture is in a stage that was removed from this Capture Type process by your CaptureExec admin"}
          </div>
          <div className="microTopMargin">
            <font className="fontRed">
              {" - Advance to a valid stage to continue the capture process"}
            </font>
          </div>
        </div>
      );
    }

    //current stage is active
    if(currentStageIsActiveTFU === true) {
      //current stage is pre-active or active
      const numUncompletedRequiredDates = currentStageObj.reqDatesFieldsArrayOfObjs.length;
      const numUncompletedRequiredRevenueCosts = currentStageObj.reqRevenueFieldsArrayOfObjs.length;
      const numUncompletedRequiredDetails = currentStageObj.reqDetailsFieldsArrayOfObjs.length;
      const numUncompletedRequiredDetailsDatesRevenueHiddenFields = currentStageObj.reqDetailsDatesRevenueHiddenFieldsArrayOfObjs.length;
      const numUnansweredRequiredQuestions = currentStageObj.reqShapingQuestionsArrayOfObjs.length;

      const toAdvanceToStageTextComponent = (
        <div key="toAdvanceTo" className="smallTopMargin">
          <font className={((p_isSnapshotTF) ? ("") : ("font12"))}>
            {"To advance to "}<font className="fontBold">{nextStageObj.name}</font>
          </font>
        </div>
      );

      //no requirements remaining
      if((numUncompletedRequiredDates === 0) && (numUncompletedRequiredDetails === 0) && (numUncompletedRequiredRevenueCosts === 0) && (numUncompletedRequiredDetailsDatesRevenueHiddenFields === 0) && (numUnansweredRequiredQuestions === 0)) {
        return(
          <>
            {toAdvanceToStageTextComponent}
            <div key="noRemainingReqs" className={((p_isSnapshotTF) ? ("microTopMargin") : ("smallTopMargin"))}>
              <font className={((p_isSnapshotTF) ? ("") : ("font11")) + " fontItalic fontTextLighter"}>
                {" - No requirements to advance to " + nextStageObj.name}
              </font>
            </div>
          </>
        );
      }

      //requirements do remain, write the full list split into levels
      const fieldLabelsArray = ["'Dates' field", "'Details' field", "'Revenue Cost' field", "hidden field (only visible to Capture Managers)", "'Deal Shaping' question"];
      const filledOutTextArray = ["filled out", "filled out", "filled out", "filled out", "answered"];
      const uncompletedFieldsArrayArray = [currentStageObj.reqDatesFieldsArrayOfObjs, currentStageObj.reqDetailsFieldsArrayOfObjs, currentStageObj.reqRevenueFieldsArrayOfObjs, currentStageObj.reqDetailsDatesRevenueHiddenFieldsArrayOfObjs, currentStageObj.reqShapingQuestionsArrayOfObjs];
      const numUncompletedFieldsArray = [numUncompletedRequiredDates, numUncompletedRequiredDetails, numUncompletedRequiredRevenueCosts, numUncompletedRequiredDetailsDatesRevenueHiddenFields, numUnansweredRequiredQuestions];
      const allowReqFieldEditTFArray = [true, true, true, false, true];

      return(
        <>
          {toAdvanceToStageTextComponent}
          {fieldLabelsArray.map((m_fieldLabel, m_index) =>
            (numUncompletedFieldsArray[m_index] > 0) &&
            <>
              <div key={m_fieldLabel} className={((p_isSnapshotTF) ? ("microTopMargin") : ("medTopMargin"))}>
                <font className={((p_isSnapshotTF) ? ("") : ("font11")) + " fontItalic"}>
                  {" - " + numUncompletedFieldsArray[m_index] + " required " + m_fieldLabel + ((numUncompletedFieldsArray[m_index] === 1) ? ("") : ("s")) + " not yet " + filledOutTextArray[m_index]}
                </font>
              </div>
              {(!p_isSnapshotTF && allowReqFieldEditTFArray[m_index]) &&
                uncompletedFieldsArrayArray[m_index].map((m_uncompletedFieldObj) =>
                  <RequirementDetailsDealShapingItem
                    key={m_fieldLabel + m_uncompletedFieldObj.itemID}
                    p_requirementObj={m_uncompletedFieldObj}
                  />
                )
              }
            </>
          )}
        </>
      );
    }


    //current stage is closed (debrief questions)
    const currentClosedStageTextComponent = (
      <div className="smallTopMargin">
        <font className={((p_isSnapshotTF) ? ("") : ("font12"))}>
          {"Capture is closed in stage "}<font className="fontBold">{currentStageObj.name}</font>
        </font>
      </div>
    );

    const numUnansweredDebriefQuestionsInCurrentStage = currentStageObj.numUnansweredDebriefQuestionsInCurrentStage;

    const c_reasonsWonLostCaptureFieldValueObj = this.props.OpenCaptureMobx.c_reasonsWonLostCaptureFieldValueObj;
    const c_advanceStageClosedStageTeammateRatingsTeammatesObj = this.props.OpenCaptureMobx.c_advanceStageClosedStageTeammateRatingsTeammatesObj;

    const numSelectedTeammatesNotYetReviewed = c_advanceStageClosedStageTeammateRatingsTeammatesObj.numSelectedTeammatesNotYetReviewed;

    if(p_isSnapshotTF) {
      var snapshotClosedDebriefTodoListComponent = null;
      if(!c_reasonsWonLostCaptureFieldValueObj.isFilledOutTF || (numUnansweredDebriefQuestionsInCurrentStage > 0) || (numSelectedTeammatesNotYetReviewed > 0)) {
        snapshotClosedDebriefTodoListComponent = (
          <>
            {(!c_reasonsWonLostCaptureFieldValueObj.isFilledOutTF) &&
              <div className="microTopMargin">
                <font className="fontRed">
                  {" - " + c_reasonsWonLostCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name") + " must be filled out"}
                </font>
              </div>
            }
            {(numUnansweredDebriefQuestionsInCurrentStage > 0) &&
              <div className="microTopMargin">
                <font className="fontRed">
                  {" - " + numUnansweredDebriefQuestionsInCurrentStage + " Required debrief " + JSFUNC.plural(numUnansweredDebriefQuestionsInCurrentStage, "question", "questions") + " not yet answered"}
                </font>
              </div>
            }
            {(numSelectedTeammatesNotYetReviewed > 0) &&
              <div className="microTopMargin">
                <font className="fontRed">
                  {" - " + numSelectedTeammatesNotYetReviewed + " " + JSFUNC.plural(numSelectedTeammatesNotYetReviewed, "Teammate", "Teammates") + " not yet reviewed"}
                </font>
              </div>
            }
          </>
        );
      }
      else {
        snapshotClosedDebriefTodoListComponent = (
          <div className="microTopMargin">
            <font className="fontItalic fontTextLighter">
              {"Debrief has been completed"}
            </font>
          </div>
        );
      }

      return(
        <>
          {currentClosedStageTextComponent}
          {snapshotClosedDebriefTodoListComponent}
        </>
      );
    }

    //closed stage debrief questions (don't include Reasons Won/lost when the capture is No Bid or Cancelled)
    const groupNamesArray = ["Reasons Won/Lost", "Debrief Questions", "Teammate Ratings"]; //groupID [rc index] 0-reason won/lost, 1-debrief deal shaping questions for this closed stage, 2-teammate ratings

    var groupIndicesInDebriefArray = [];
    if(currentStageObj.isWonOrLostStageTF) { groupIndicesInDebriefArray.push(0); } //include reasons won/lost only if stage is won or lost (not no bid or cancelled)
    groupIndicesInDebriefArray.push(1); //always add debrief questions
    if(c_productStylingObj.cardTeammatesTF) { groupIndicesInDebriefArray.push(2); } //only include teammate ratings if the Teammate Card is part of the system (otherwise unable to rate them so it's always blank)

    const detailsNumGroupsPerRow = this.props.OpenCaptureMobx.advance_stage_close_debrief_num_groups_per_row(p_container);
    const groupIDsRCMatrix = JSFUNC.get_rc_matrix_from_id_array_and_num_columns(groupIndicesInDebriefArray, detailsNumGroupsPerRow);
    const detailsItemFixedFieldWidthTF = this.props.OpenCaptureMobx.details_item_fixed_field_width_tf("full");
    const reasonsWonLostDetailsItemFieldWidth = ((detailsItemFixedFieldWidthTF) ? ("15em") : ("40%"));

    return(
      <>
        {currentClosedStageTextComponent}
        {groupIDsRCMatrix.map((groupIDsRowArray, rowIndex) =>
          <div
            key={JSFUNC.rc_unique_row_key(detailsNumGroupsPerRow, rowIndex)}
            className="flex00a displayFlexRow">
            {groupIDsRowArray.map((groupID) =>
              <div
                className={"flex11a medFullMargin medFullPad " + ((groupID >= 0) ? ("border1bbb") : (""))}
                style={{flexBasis:"100em"}}>
                {(groupID >= 0) &&
                  <div className="medBottomMargin textCenter">
                    <div className="font14 fontBold fontTextLighter">
                      {groupNamesArray[groupID]}
                    </div>
                  </div>
                }
                {(groupID === 0) &&
                  <DetailsItem
                    p_captureID={o_openCaptureID}
                    p_cardID={this.props.DatabaseMobx.k_cardIDAdvanceStage}
                    p_captureFieldValueObj={c_reasonsWonLostCaptureFieldValueObj}
                    p_isSnapshotTF={false}
                    p_fieldWidth={reasonsWonLostDetailsItemFieldWidth}
                    p_fieldValueVerticallyAlignedTF={false}
                  />
                }
                {(groupID === 1) &&
                  ((currentStageObj.reqShapingQuestionsArrayOfObjs.length === 0) ? (
                    <div>
                      <font className="fontItalic fontTextLighter">
                        {"No debrief questions for this capture type in stage " + currentStageObj.name}
                      </font>
                    </div>
                  ) : (
                    currentStageObj.reqShapingQuestionsArrayOfObjs.map((m_questionAnswerObj) =>
                      <DealShapingQuestion
                        key={m_questionAnswerObj.id}
                        p_captureID={this.props.OpenCaptureMobx.o_openCaptureID}
                        p_cardID={this.props.DatabaseMobx.k_cardIDAdvanceStage}
                        p_questionAnswerObj={m_questionAnswerObj}
                        p_isClosedStageDebriefQuestionTF={true}
                      />
                    )
                  ))
                }
                {(groupID === 2) &&
                  <AdvanceStageClosedStageTeammateRatingsCardContent />
                }
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}));

const RequirementDetailsDealShapingItem = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class RequirementDetailsDealShapingItem extends Component { //props: p_requirementObj
  onclick_requirement_item = () => { //for small screens with no split screen available, close advance stage and open the card with the edit fullscreen
    const requirementObj = this.props.p_requirementObj;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    if(c_isMobileOrTabletTF) {
      this.props.OpenCaptureMobx.a_launch_card_full(requirementObj.cardID);
    }
    else { //reduce the advance stage card to left 1/3 and open the card that has the item to be edited on it in the right 2/3
      this.props.OpenCaptureMobx.a_launch_card_left(this.props.DatabaseMobx.k_cardIDAdvanceStage);
      this.props.OpenCaptureMobx.a_launch_card_right(requirementObj.cardID);
    }
    this.props.CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id(this.props.OpenCaptureMobx.ce_edit_item_string(requirementObj.cardID, requirementObj.itemID));
  }

  render() {
    const requirementObj = this.props.p_requirementObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <div
        className={"displayFlexRow microTopMargin border bevelBorderColors hoverLighterBlueGradient smallFullPad " + ((c_userCanEditCaptureCardContentTF) ? ("cursorPointer") : (""))}
        style={{height:"2.8em", marginLeft:"2em"}}
        onClick={((c_userCanEditCaptureCardContentTF) ? (this.onclick_requirement_item) : (undefined))}>
        <div className="flex00a displayFlexColumnHcVc lrPad rMargin" style={{borderRight:"solid 1px #f2f2f2"}}>
          <font className="font20 fontBold fontBlue">
            {"\u21aa"}
          </font>
        </div>
        <div className="flex11a displayFlexRowVc">
          <LibraryReact.MaxHeightWrap p_maxHeight="2.5em" p_fontClass="fontBlue">
            {requirementObj.itemName}
          </LibraryReact.MaxHeightWrap>
        </div>
      </div>
    );
  }
}));

const AdvanceStageClosedStageTeammateRatingsCardContent = inject("OpenCaptureMobx")(observer(
class AdvanceStageClosedStageTeammateRatingsCardContent extends Component { //props:
  render() {
    const c_advanceStageClosedStageTeammateRatingsTeammatesObj = this.props.OpenCaptureMobx.c_advanceStageClosedStageTeammateRatingsTeammatesObj;

    const ascstrSelectedTeammatesArrayOfObjs = c_advanceStageClosedStageTeammateRatingsTeammatesObj.ascstrSelectedTeammatesArrayOfObjs;
    const ascstrNotSelectedTeammatesArrayOfObjs = c_advanceStageClosedStageTeammateRatingsTeammatesObj.ascstrNotSelectedTeammatesArrayOfObjs;
    const numTeammates = c_advanceStageClosedStageTeammateRatingsTeammatesObj.numTeammates;
    const numSelectedTeammates = c_advanceStageClosedStageTeammateRatingsTeammatesObj.numSelectedTeammates;
    const numNotSelectedTeammates = c_advanceStageClosedStageTeammateRatingsTeammatesObj.numNotSelectedTeammates;
    const numTeammatesRatingsQuestions = c_advanceStageClosedStageTeammateRatingsTeammatesObj.numTeammatesRatingsQuestions;
    const numSelectedTeammatesNotYetReviewed = c_advanceStageClosedStageTeammateRatingsTeammatesObj.numSelectedTeammatesNotYetReviewed;

    if(numTeammates === 0) {
      return(
        <div className="">
          <font className="fontItalic fontTextLighter">
            {"There were no Teammates added to this Capture to review"}
          </font>
        </div>
      );
    }

    if(numTeammatesRatingsQuestions === 0) {
      return(
        <div className="">
          <font className="fontItalic fontTextLighter">
            {"Your CaptureExec Admin has not created any Teammate Rating Questions yet"}
          </font>
        </div>
      );
    }

    return(
      <>
        {(numSelectedTeammates > 0) &&
          <>
            <div className="smallBottomMargin">
              <font className="font12 fontBold fontItalic fontTextLight">
                {"On Team (" + numSelectedTeammates + ")"}
              </font>
            </div>
            {ascstrSelectedTeammatesArrayOfObjs.map((m_ascstrTeammateObj) =>
              <AdvanceStageClosedStageTeammateRatingsSingleTeammate
                p_ascstrTeammateObj={m_ascstrTeammateObj}
                p_onTeamTF={true}
              />
            )}
          </>
        }
        {(numNotSelectedTeammates > 0) &&
          <>
            {(numSelectedTeammates > 0) &&
              <div className="hugeTopMargin" />
            }
            <div className="smallBottomMargin">
              <font className="font12 fontBold fontItalic fontTextLight">
                {"Not Selected for Team (" + numNotSelectedTeammates + ")"}
              </font>
            </div>
            {ascstrNotSelectedTeammatesArrayOfObjs.map((m_ascstrTeammateObj) =>
              <AdvanceStageClosedStageTeammateRatingsSingleTeammate
                p_ascstrTeammateObj={m_ascstrTeammateObj}
                p_onTeamTF={false}
              />
            )}
          </>
        }
      </>
    );
  }
}));

const AdvanceStageClosedStageTeammateRatingsSingleTeammate = inject("OpenCaptureMobx", "UserMobx")(observer(
class AdvanceStageClosedStageTeammateRatingsSingleTeammate extends Component { //props: p_ascstrTeammateObj, p_onTeamTF
  render() {
    const ascstrTeammateObj = this.props.p_ascstrTeammateObj;
    const onTeamTF = this.props.p_onTeamTF;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const teammateObj = ascstrTeammateObj.teammateObj;
    const reviewedTF = ascstrTeammateObj.reviewedTF;
    const tblCTeammatesRatingsQuestionnaireSubmissionObj = ascstrTeammateObj.tblCTeammatesRatingsQuestionnaireSubmissionObj;
    const teammateOverallCaptureRating = ascstrTeammateObj.teammateOverallCaptureRating;

    var reviewedFontClass = "";
    var reviewedText = "";
    if(reviewedTF) {
      reviewedFontClass = "fontItalic fontDarkGreen";
      reviewedText = "Reviewed  " + JSFUNC.round_number_to_num_decimals_if_needed(teammateOverallCaptureRating, 1) + " / 5";
    }
    else {
      reviewedFontClass = "fontRed" + ((onTeamTF) ? (" fontBold") : (""));
      reviewedText = "Not Yet Reviewed";
    }

    return(
      <div className="displayFlexRowVc smallBottomMargin bgLightestGray border1bbb borderRadius05" style={{height:"3em"}}>
        <div className="flex11a lrPad">
          <ContactsReact.ContactBubble
             p_isPersonTF={false}
             p_contactID={teammateObj.contact_company_id}
           />
        </div>
        <div className="flex00a lrPad textCenter" style={{flexBasis:"10em"}}>
          <font className={reviewedFontClass}>
            {reviewedText}
          </font>
        </div>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="flex00a lrPad">
            <TeammateRatingsEditQuestionnaireButtonWithFloatingBox
              p_teammateObj={teammateObj}
              p_teammatesRatingsQuestionnaireSubmissionRowObj={tblCTeammatesRatingsQuestionnaireSubmissionObj}
            />
          </div>
        }
      </div>
    );
  }
}));



//===================================================================================================================================
//--------Tasks-------------------------------------------------------------------------------------------
//===================================================================================================================================
const TasksSnapshot = inject("OpenCaptureMobx")(observer(
class TasksSnapshot extends Component {
  render() {
    //message if the admin has not added any date fields for this capture
    const numActiveTasks = this.props.OpenCaptureMobx.c_tasksNumActive;
    const numCompletedTasks = this.props.OpenCaptureMobx.c_tasksNumCompleted;

    var taskCountText = undefined;
    if((numActiveTasks === 0) && (numCompletedTasks === 0)) {
      taskCountText = "0 Tasks created";
    }
    else {
      taskCountText = numActiveTasks + " Active " + JSFUNC.plural(numActiveTasks, "Task", "Tasks") + ", " + numCompletedTasks + " Completed";
    }

    return(
      <div className="flex11a overflowHidden smallFullMargin">
        <div className="microBottomPad microBottomMargin" style={{borderBottom:"solid 1px #eee"}}>
          <font className="fontItalic fontTextLighter">
            {taskCountText}
          </font>
        </div>
        <TasksGroupingWithLabel p_type123456={1} p_container="snapshot" />
        <TasksGroupingWithLabel p_type123456={2} p_container="snapshot" />
      </div>
    );
  }
}));

const Tasks = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class Tasks extends Component { //props: p_container
  onclick_task = (i_taskObj) => { //if changing the screen size while edit task is open, this prevents the item from being derendered if it was flipped the wrong way (the edit would seemingly disappear)
    this.props.OpenCaptureMobx.a_tasks_set_mobile_showing_active_tf(!i_taskObj.isCompletedTF);
  }

  onclick_mobile_toggle_active_completed = () => { //small mobile screens can toggle between active and completed tasks
    this.props.OpenCaptureMobx.a_tasks_set_mobile_showing_active_tf(!this.props.OpenCaptureMobx.o_tasksMobileShowingActiveTF);
  }

  render() {
    const p_container = this.props.p_container;

    const mobileShowingActiveTF = this.props.OpenCaptureMobx.o_tasksMobileShowingActiveTF;
    const numActiveTasks = this.props.OpenCaptureMobx.c_tasksNumActive;
    const numCompletedTasks = this.props.OpenCaptureMobx.c_tasksNumCompleted;

    const activeLeftCompletedRightTF = this.props.OpenCaptureMobx.tasks_active_completed_is_split_screen_tf(p_container);

    const activeTasksComponent = (
      [1, 2, 3].map((i_type123456) =>
        <TasksGroupingWithLabel key={i_type123456} p_type123456={i_type123456} p_container={p_container} f_onClickTask={this.onclick_task} />
      )
    );

    const closedTasksComponent = (
      [4, 5, 6].map((i_type123456) =>
        <TasksGroupingWithLabel key={i_type123456} p_type123456={i_type123456} p_container={p_container} f_onClickTask={this.onclick_task} />
      )
    );

    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a medFullPad bgPanelLightGray displayFlexRow flexWrap" style={{borderBottom:"solid 1px #999"}}>
          <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"1em"}}>
            <RightPanelReact.TasksAddNewButton
              p_initRelevanceCaptureID={this.props.OpenCaptureMobx.o_openCaptureID}
              p_cardID={this.props.DatabaseMobx.k_cardIDTasks}
              p_tabIndex={1}
            />
          </div>
          {(!activeLeftCompletedRightTF) &&
            <div className="flex11a displayFlexColumnHcVc smallFullMargin" style={{flexBasis:"1em"}}>
              <CEGeneralReact.CEButton
                p_type="add"
                p_text={((mobileShowingActiveTF) ? ("Show Completed Tasks (" + numCompletedTasks + ")") : ("Show Active Tasks (" + (numActiveTasks) + ")"))}
                p_tabIndex={2}
                f_onClick={this.onclick_mobile_toggle_active_completed}
              />
            </div>
          }
        </div>
        {((activeLeftCompletedRightTF) ? (
          <div className="flex11a displayFlexRow">
            <div className="flex11a smallFullMargin yScroll yScrollBottomPad smallTopPad lrPad" style={{flexBasis:"100em", border:"solid 2px #ccc"}}>
              {activeTasksComponent}
            </div>
            <div className="flex11a smallFullMargin yScroll yScrollBottomPad smallTopPad lrPad" style={{flexBasis:"100em", border:"solid 2px #ccc"}}>
              {closedTasksComponent}
            </div>
          </div>
        ) : (
          <div className="flex11a smallFullMargin yScroll yScrollBottomPad smallTopPad lrPad" style={{flexBasis:"100em", border:"solid 2px #ccc"}}>
            {((mobileShowingActiveTF) ? (
              activeTasksComponent
            ) : (
              closedTasksComponent
            ))}
          </div>
        ))}
      </div>
    );
  }
}))

const TasksGroupingWithLabel = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TasksGroupingWithLabel extends Component { //props: p_type123456, p_container, f_onClickTask
  render() {
    const p_type123456 = this.props.p_type123456;
    const p_container = this.props.p_container;

    const k_cardIDTasks = this.props.DatabaseMobx.k_cardIDTasks;

    const isSnapshotTF = (p_container === "snapshot");

    var tasksArrayOfObjs = [];
    if(p_type123456 === 1) { tasksArrayOfObjs = this.props.OpenCaptureMobx.c_myActiveCaptureTasksArrayOfObjs; }
    else if(p_type123456 === 2) { tasksArrayOfObjs = this.props.OpenCaptureMobx.c_activeCaptureTasksIAssignedToOthersArrayOfObjs; }
    else if(p_type123456 === 3) { tasksArrayOfObjs = this.props.OpenCaptureMobx.c_activeCaptureTasksOthersAssignedToOthersArrayOfObjs; }
    else if(p_type123456 === 4) { tasksArrayOfObjs = this.props.OpenCaptureMobx.c_myCompletedCaptureTasksArrayOfObjs; }
    else if(p_type123456 === 5) { tasksArrayOfObjs = this.props.OpenCaptureMobx.c_completedCaptureTasksIAssignedToOthersArrayOfObjs; }
    else if(p_type123456 === 6) { tasksArrayOfObjs = this.props.OpenCaptureMobx.c_completedCaptureTasksOthersAssignedToOthersArrayOfObjs; }

    const isCompletedTF = (p_type123456 >= 4);
    const activeCompletedString = ((isCompletedTF) ? ("Completed") : ("Active"));

    var label = "";
    if(JSFUNC.in_array(p_type123456, [1, 4])) { label = "My " + activeCompletedString + " Tasks"; }
    else if(JSFUNC.in_array(p_type123456, [2, 5])) { label = activeCompletedString + " Tasks I assigned to Others"; }
    else if(JSFUNC.in_array(p_type123456, [3, 6])) { label = activeCompletedString + " Tasks Others have assigned to Others"; }
    label += " (" + tasksArrayOfObjs.length + ")";

    return(
      <div className={((isSnapshotTF) ? ("bigBottomMargin") : ("hugeBottomMargin"))}>
        <div>
          <font className={((isSnapshotTF) ? ("font11") : ("font12")) + " fontItalic fontTextLighter"}>
            {label}
          </font>
        </div>
        {((isSnapshotTF) ? (
          <RightPanelReact.TasksGrouping
            p_container={this.props.p_container}
            p_cardID={k_cardIDTasks}
            p_tasksArrayOfObjs={tasksArrayOfObjs}
            p_isCompletedTF={isCompletedTF}
          />
        ) : (
          <RightPanelReact.TasksGrouping
            p_container={this.props.p_container}
            p_cardID={k_cardIDTasks}
            p_tasksArrayOfObjs={tasksArrayOfObjs}
            p_isCompletedTF={isCompletedTF}
            f_onClickTask={this.props.f_onClickTask}
          />
        ))}
      </div>
    );
  }
}))










//===================================================================================================================================
//--------Dates-------------------------------------------------------------------------------------------
//===================================================================================================================================
const DatesSnapshot = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class DatesSnapshot extends Component { //props:
  render() {
    //message if the admin has not added any date fields for this capture
    const c_datesCardTotalNumDates = this.props.OpenCaptureMobx.c_datesCardTotalNumDates;
    const c_upcomingDatesArrayOfObjs = this.props.OpenCaptureMobx.c_upcomingDatesArrayOfObjs;
    const c_notsetDatesArrayOfObjs = this.props.OpenCaptureMobx.c_notsetDatesArrayOfObjs;
    const c_pastDatesArrayOfObjs = this.props.OpenCaptureMobx.c_pastDatesArrayOfObjs;
    const c_allDatesFixedArrayOfObjs = this.props.OpenCaptureMobx.c_allDatesFixedArrayOfObjs;
    const c_cardNameDates = this.props.DatabaseMobx.c_cardNameDates;
    const c_userDatesCardIsFixedAdminSingleColumnTF = this.props.UserMobx.c_userDatesCardIsFixedAdminSingleColumnTF;

    if(c_datesCardTotalNumDates === 0) {
      return(
        <div className="flex11a displayFlexColumnHcVc overflowHidden medFullPad textCenter">
          <font className="fontItalic fontTextLighter">
            {"Your CaptureExec Admin has not designated any '" + c_cardNameDates + " Card' fields"}
          </font>
        </div>
      );
    }

    var upcomingNotSetPastDatesArrayOfArraysOfObjs = [];
    var upcomingNotSetPastKeysArray = [];
    var upcomingNotSetPastFieldFontColorClassesArray = [];
    var upcomingNotSetPastValueFontColorClassesArray = [];
    if(c_userDatesCardIsFixedAdminSingleColumnTF) {
      upcomingNotSetPastDatesArrayOfArraysOfObjs = [c_allDatesFixedArrayOfObjs];
      upcomingNotSetPastKeysArray = ["allFixed"];
      upcomingNotSetPastFieldFontColorClassesArray = ["fontBlue"];
      upcomingNotSetPastValueFontColorClassesArray = [""];
    }
    else {
      upcomingNotSetPastDatesArrayOfArraysOfObjs = [c_upcomingDatesArrayOfObjs, c_notsetDatesArrayOfObjs, c_pastDatesArrayOfObjs];
      upcomingNotSetPastKeysArray = ["upcoming", "notset", "past"];
      upcomingNotSetPastFieldFontColorClassesArray = ["fontBlue", "fontBlueLight", "fontBlueLighter"];
      upcomingNotSetPastValueFontColorClassesArray = ["", "fontTextLight", "fontTextLighter"];
    }

    return(
      <div className="flex11a overflowHidden smallFullMargin">
        {upcomingNotSetPastDatesArrayOfArraysOfObjs.map((m_datesArrayOfObjs, m_sectionIndex) =>
          <div key={upcomingNotSetPastKeysArray[m_sectionIndex]}>
            {(m_sectionIndex > 0 && upcomingNotSetPastDatesArrayOfArraysOfObjs[m_sectionIndex-1].length > 0) &&
              <div className="microTopMargin microBottomMargin" style={{borderTop:"solid 1px #ddd"}}></div>
            }
            {m_datesArrayOfObjs.map((m_dateObj) =>
              <DetailsItem
                key={m_dateObj.id}
                p_captureID={this.props.OpenCaptureMobx.o_openCaptureID}
                p_cardID={this.props.DatabaseMobx.k_cardIDDates}
                p_captureFieldValueObj={m_dateObj}
                p_isSnapshotTF={true}
                p_fieldWidth="12em"
                p_fieldClass={upcomingNotSetPastFieldFontColorClassesArray[m_sectionIndex]}
                p_valueClass={upcomingNotSetPastValueFontColorClassesArray[m_sectionIndex]}
                p_fieldValueVerticallyAlignedTF={false}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}));

const Dates = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class Dates extends Component { //props: p_container
  render() {
    const p_container = this.props.p_container;

    const c_datesCardTotalNumDates = this.props.OpenCaptureMobx.c_datesCardTotalNumDates;
    const c_upcomingDatesArrayOfObjs = this.props.OpenCaptureMobx.c_upcomingDatesArrayOfObjs;
    const c_notsetDatesArrayOfObjs = this.props.OpenCaptureMobx.c_notsetDatesArrayOfObjs;
    const c_pastDatesArrayOfObjs = this.props.OpenCaptureMobx.c_pastDatesArrayOfObjs;
    const c_allDatesFixedArrayOfObjs = this.props.OpenCaptureMobx.c_allDatesFixedArrayOfObjs;
    const c_cardNameDates = this.props.DatabaseMobx.c_cardNameDates;
    const c_userDatesCardIsFixedAdminSingleColumnTF = this.props.UserMobx.c_userDatesCardIsFixedAdminSingleColumnTF;

    //message if the admin has not added any date fields for this capture
    if(c_datesCardTotalNumDates === 0) {
      return(
        <div className="flex11a displayFlexColumnHcVc overflowHidden medFullPad textCenter">
          <font className="fontItalic fontTextLighter">
            {"Your CaptureExec Admin has not designated any '" + c_cardNameDates + " Card' fields"}
          </font>
        </div>
      );
    }

    var upcomingNotSetPastDatesArrayOfArraysOfObjs = [];
    var upcomingNotSetPastLabelsArray = [];
    var detailsNumGroupsPerRow = 1;
    if(c_userDatesCardIsFixedAdminSingleColumnTF) {
      upcomingNotSetPastDatesArrayOfArraysOfObjs = [c_allDatesFixedArrayOfObjs];
      upcomingNotSetPastLabelsArray = ["Dates"];
    }
    else {
      upcomingNotSetPastDatesArrayOfArraysOfObjs = [c_upcomingDatesArrayOfObjs, c_notsetDatesArrayOfObjs, c_pastDatesArrayOfObjs];
      upcomingNotSetPastLabelsArray = ["Upcoming Dates", "Dates Not Yet Set", "Past Dates"];
      detailsNumGroupsPerRow = this.props.OpenCaptureMobx.dates_num_groups_per_row(p_container);
    }
    const numGroups = upcomingNotSetPastDatesArrayOfArraysOfObjs.length;
    const groupIDsRCMatrix = JSFUNC.get_rc_matrix_from_num_items_and_columns(numGroups, detailsNumGroupsPerRow);

    const isSnapshotTF = (p_container === "snapshot");
    const dividerLineTopBottomMarginClass = ((isSnapshotTF) ? ("microTopMargin microBottomMargin") : ("bigTopMargin"));

    //const datesItemFixedFieldWidthTF = this.props.OpenCaptureMobx.dates_item_fixed_field_width_tf(p_container);
    const fieldWidth = "13em";//((datesItemFixedFieldWidthTF) ? ("13em") : ("max"));

    return(
      <div className="flex11a displayFlexColumn yScroll yScrollBottomPad bgLightesterGray">
        {groupIDsRCMatrix.map((groupIDsRowArray, rowIndex) =>
          <div key={JSFUNC.rc_unique_row_key(detailsNumGroupsPerRow, rowIndex)} className="flex00a displayFlexRow">
            {groupIDsRowArray.map((groupID) =>
              ((groupID >= 0) ? (
                <DetailsGroupShell p_groupName={upcomingNotSetPastLabelsArray[groupID]} p_groupNameStylingStringComma={undefined}>
                  {upcomingNotSetPastDatesArrayOfArraysOfObjs[groupID].map((dateObj) =>
                    <DetailsItem
                      key={dateObj.fieldID}
                      p_captureID={this.props.OpenCaptureMobx.o_openCaptureID}
                      p_cardID={this.props.DatabaseMobx.k_cardIDDates}
                      p_captureFieldValueObj={dateObj}
                      p_isSnapshotTF={false}
                      p_fieldWidth={fieldWidth}
                      p_fieldClass="fontBlue"
                      p_fieldValueVerticallyAlignedTF={false}
                    />
                  )}
                </DetailsGroupShell>
              ) : (
                <DetailsGroupShell />
              ))
            )}
          </div>
        )}
      </div>
    );
  }
}));




//===================================================================================================================================
//--------Details-------------------------------------------------------------------------------------------
//===================================================================================================================================
const DetailsSnapshot = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class DetailsSnapshot extends Component {
  render() {
    const detailsSnapshotCaptureFieldValuesArrayOfObjs = this.props.OpenCaptureMobx.c_detailsSnapshotCaptureFieldValuesArrayOfObjs;
    return(
      <div className="flex11a overflowHidden smallFullMargin">
        {detailsSnapshotCaptureFieldValuesArrayOfObjs.map((m_captureFieldValueObj) =>
          <DetailsItem
            key={m_captureFieldValueObj.expandedCaptureFieldMap.get("id")}
            p_captureID={this.props.OpenCaptureMobx.o_openCaptureID}
            p_cardID={this.props.DatabaseMobx.k_cardIDDetails}
            p_captureFieldValueObj={m_captureFieldValueObj}
            p_isSnapshotTF={true}
            p_fieldWidth="11em"
            p_fieldValueVerticallyAlignedTF={false}
          />
        )}
      </div>
    );
  }
}))

const Details = inject("OpenCaptureMobx")(observer(
class Details extends Component { //props: p_container
  render() {
    const p_container = this.props.p_container;

    //groupID here is the index 0-(L-1) needed for the variables c_detailsGroupsArrayOfObjs and c_detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs
    const numGroups = this.props.OpenCaptureMobx.c_detailsGroupsArrayOfObjs.length;
    const detailsNumGroupsPerRow = this.props.OpenCaptureMobx.details_num_groups_per_row(p_container);
    const fieldWidth = ((this.props.OpenCaptureMobx.details_item_fixed_field_width_tf(p_container)) ? ("15em") : ("40%"));
    const groupIDsRCMatrix = JSFUNC.get_rc_matrix_from_num_items_and_columns(numGroups, detailsNumGroupsPerRow);

    return(
      <div className="flex11a displayFlexColumn yScroll yScrollBottomPad bgLightesterGray">
        {groupIDsRCMatrix.map((groupIDsRowArray, rowIndex) =>
          <div key={JSFUNC.rc_unique_row_key(detailsNumGroupsPerRow, rowIndex)} className="flex00a displayFlexRow">
            {groupIDsRowArray.map((groupID) =>
              <DetailsGroup key={groupID} p_groupID={groupID} p_fieldWidth={fieldWidth} />
            )}
          </div>
        )}
      </div>
    );
  }
}));

const DetailsGroup = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class DetailsGroup extends Component { //props: p_groupID, p_fieldWidth
  render() {
    const groupID = this.props.p_groupID;
    const p_fieldWidth = this.props.p_fieldWidth;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const userIsCaptureManagerOfOpenCaptureTF = this.props.OpenCaptureMobx.c_userIsCaptureManagerOfOpenCaptureTF;
    const detailsGroupsArrayOfObjs = this.props.OpenCaptureMobx.c_detailsGroupsArrayOfObjs;
    const detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs = this.props.OpenCaptureMobx.c_detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs;
    const k_cardIDDetails = this.props.DatabaseMobx.k_cardIDDetails;

    const isBlankGroupTF = (groupID < 0);
    const groupIDOutOfGroupsArrayRange = (groupID >= detailsGroupsArrayOfObjs.length);
    const groupIDOutOfCaptureValuesArrayRange = (groupID >= detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs.length);

    if(isBlankGroupTF || groupIDOutOfGroupsArrayRange || groupIDOutOfCaptureValuesArrayRange) {
      return(
        <DetailsGroupShell />
      );
    }

    const detailsGroupObj = detailsGroupsArrayOfObjs[groupID];
    const captureValuesInGroupArrayOfObjs = detailsGroupsOfCaptureFieldValueArrayOfArrayOfObjs[groupID];

    return(
      <DetailsGroupShell p_groupName={detailsGroupObj.name} p_groupNameStylingStringComma={detailsGroupObj.group_name_styling_string_comma}>
        {captureValuesInGroupArrayOfObjs.map((m_captureFieldValueObj) =>
          (m_captureFieldValueObj.fieldIsVisibleToUserTF) &&
          <DetailsItem
            key={m_captureFieldValueObj.expandedCaptureFieldMap.get("id")}
            p_captureID={o_openCaptureID}
            p_cardID={k_cardIDDetails}
            p_captureFieldValueObj={m_captureFieldValueObj}
            p_isSnapshotTF={false}
            p_fieldWidth={p_fieldWidth}
            p_fieldValueVerticallyAlignedTF={false}
          />
        )}
      </DetailsGroupShell>
    );
  }
}));

export const DetailsGroupShell = inject("DatabaseMobx")(observer(
class DetailsGroupShell extends Component { //props: p_groupName, p_groupNameStylingStringComma, children
  render() {
    const p_groupName = this.props.p_groupName;
    const p_groupNameStylingStringComma = this.props.p_groupNameStylingStringComma;

    const groupCardMargin = "0.5em";

    if(p_groupName === undefined) {
      return(
        <div className="flex11a bgLightesterGray" style={{flexBasis:"1em", margin:groupCardMargin}} />
      );
    }

    const groupNamePresetStylingObj = this.props.DatabaseMobx.get_preset_styling_obj_from_styling_string_comma_list_or_preset_colon_id_string(p_groupNameStylingStringComma);

    return(
      <div className="flex11a bgWhite" style={{flexBasis:"1em", border:"solid 2px #d0e1f5", borderRadius:"0.5em", margin:groupCardMargin}}>
        <div className={"medFullMargin " + ((groupNamePresetStylingObj.highlightTF) ? ("border bevelBorderColors") : ("")) + " textCenter"}>
          <font className="font14 fontBold fontTextLighter">
            <CEGeneralReact.StyledTextDiv p_stylingObj={groupNamePresetStylingObj} p_nowrapTF={true}>
              {p_groupName}
            </CEGeneralReact.StyledTextDiv>
          </font>
        </div>
        <div className="lrMedMargin smallTopMargin medBottomMargin">
          {this.props.children}
        </div>
      </div>
    );
  }
}));


export const DetailsItem = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class DetailsItem extends Component { //props: p_captureID, p_cardID, p_captureFieldValueObj, p_valueIsEditableTFOverwrite, p_isSnapshotTF, p_fieldWidth, p_containerClass, p_fieldClass, p_valueClass, p_fieldValueVerticallyAlignedTF, p_showFieldNameTF
  onsave_changed_item = (i_changedValue) => {
    const p_captureID = this.props.p_captureID;
    const p_captureFieldValueObj = this.props.p_captureFieldValueObj;
    this.props.OpenCaptureMobx.a_details_update_field_value(p_captureFieldValueObj.expandedCaptureFieldMap, i_changedValue, p_captureID);
  }

  render() {
    const p_captureID = this.props.p_captureID;
    const p_cardID = this.props.p_cardID;
    const p_captureFieldValueObj = this.props.p_captureFieldValueObj;
    const p_valueIsEditableTFOverwrite = this.props.p_valueIsEditableTFOverwrite;
    const p_isSnapshotTF = JSFUNC.prop_value(this.props.p_isSnapshotTF, false);
    const p_fieldWidth = this.props.p_fieldWidth;
    const p_containerClass = this.props.p_containerClass;
    const p_fieldClass = JSFUNC.prop_value(this.props.p_fieldClass, "fontBlue");
    const p_valueClass = this.props.p_valueClass;
    const p_fieldValueVerticallyAlignedTF = this.props.p_fieldValueVerticallyAlignedTF;
    const p_showFieldNameTF = JSFUNC.prop_value(this.props.p_showFieldNameTF, true);

    const c_captureContractsManagerUserIDsToNotIncludeArray = this.props.OpenCaptureMobx.c_captureContractsManagerUserIDsToNotIncludeArray;
    const c_captureBudgetManagerUserIDsToNotIncludeArray = this.props.OpenCaptureMobx.c_captureBudgetManagerUserIDsToNotIncludeArray;
    const c_captureReasonsWonLostIDsToNotIncludeArrayOrUndefined = this.props.OpenCaptureMobx.c_captureReasonsWonLostIDsToNotIncludeArrayOrUndefined;
    const c_fieldMapOfContractsManager = this.props.DatabaseMobx.c_fieldMapOfContractsManager;
    const c_fieldMapOfBudgetManager = this.props.DatabaseMobx.c_fieldMapOfBudgetManager;
    const c_fieldMapOfReasonsWonLost = this.props.DatabaseMobx.c_fieldMapOfReasonsWonLost;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;
    const c_userDatesCardIsFixedAdminSingleColumnTF = this.props.UserMobx.c_userDatesCardIsFixedAdminSingleColumnTF;

    const isDatesCardTF = (p_cardID === this.props.DatabaseMobx.k_cardIDDates);
    const isDetailsCardTF = (p_cardID === this.props.DatabaseMobx.k_cardIDDetails);

    const expandedCaptureFieldMap = p_captureFieldValueObj.expandedCaptureFieldMap;
    const fieldID = expandedCaptureFieldMap.get("id");
    const fieldDisplayName = expandedCaptureFieldMap.get("display_name");
    const hoverText = expandedCaptureFieldMap.get("hover_text");
    const textAreaMaxLines = expandedCaptureFieldMap.get("textarea_details_max_lines");
    const displayNamePresetStylingObj = expandedCaptureFieldMap.get("displayNamePresetStylingObj");
    const fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");

    const contractsManagerFieldID = c_fieldMapOfContractsManager.get("id");
    const budgetManagerFieldID = c_fieldMapOfBudgetManager.get("id");
    const reasonsWonLostFieldID = c_fieldMapOfReasonsWonLost.get("id");

    //do not draw a blank or line if using the past/not set/upcoming 3 column auto sorted dates card
    if(isDatesCardTF) {
      if(!c_userDatesCardIsFixedAdminSingleColumnTF && JSFUNC.in_array(fieldID, [-10, -11])) {
        return(null);
      }
    }


    if(fieldID === -10) {
      return(<div style={{height:((p_isSnapshotTF) ? ("1.2") : ("1.8")) + "em"}} />);
    }

    if(fieldID === -11) {
      return(<div className="borderT1Blue" style={{margin:((p_isSnapshotTF) ? ("0.4") : ("0.6")) + "em 1.5em"}} />);
    }

    var valueMask = p_captureFieldValueObj.valueMask;
    if(p_isSnapshotTF) {
      valueMask = p_captureFieldValueObj.valueMaskNoClickLinks;
    }

    var valueIsEditableTFU = undefined; //if the User cannot make any edits to fields, make all DetailsItem fields uneditable with no edit pencil or spacing for the edit pencil using undefined
    if(c_userCanEditCaptureCardContentTF) {
      valueIsEditableTFU = ((p_isSnapshotTF) ? (undefined) : (p_captureFieldValueObj.canEditValueTF));
      if(p_valueIsEditableTFOverwrite !== undefined) {
        valueIsEditableTFU = p_valueIsEditableTFOverwrite;
      }
    }

    var valuesToNotIncludeArray = undefined;
    if(fieldID === contractsManagerFieldID) { //for selecting a contracts manager, don't include those who don't have access to this capture
      valuesToNotIncludeArray = c_captureContractsManagerUserIDsToNotIncludeArray;
    }
    else if(fieldID === budgetManagerFieldID) { //for selecting a budget manager, don't include those who don't have access to this capture
      valuesToNotIncludeArray = c_captureBudgetManagerUserIDsToNotIncludeArray;
    }
    else if(fieldID === reasonsWonLostFieldID) {
      valuesToNotIncludeArray = c_captureReasonsWonLostIDsToNotIncludeArrayOrUndefined;
    }

    var detailsItemContainerClass = ((JSFUNC.is_string(p_containerClass)) ? (p_containerClass) : (""));
    if(p_isSnapshotTF) {
      detailsItemContainerClass += " tbNanoPad";
    }

    //apply field display name styling in addition to default style from p_fieldClass input 
    var fieldClass = p_fieldClass;
    var fieldFontColor = undefined;
    var fieldHighlightColor = undefined;
    if(displayNamePresetStylingObj !== undefined) {
      if(displayNamePresetStylingObj.boldTF) { fieldClass += " fontBold"; }
      if(displayNamePresetStylingObj.italicTF) { fieldClass += " fontItalic"; }

      if(displayNamePresetStylingObj.fontColorTF) {
        fieldFontColor = displayNamePresetStylingObj.fontSelectedColor;
      }

      if(displayNamePresetStylingObj.highlightTF) {
        fieldHighlightColor = displayNamePresetStylingObj.highlightSelectedColor;
      }
    }

    var textareaMaxHeight = undefined;
    if(p_isSnapshotTF && isDetailsCardTF) {
      textareaMaxHeight = "nowrap";
    }
    else if(fieldTypeObj.textareaTF) {
      textareaMaxHeight = ((textAreaMaxLines * 1.1) - 0.2) + "em";
    }

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={p_captureID + "-" + p_cardID + "-" + fieldID}
        p_fieldDisplayName={((p_showFieldNameTF) ? (fieldDisplayName) : (undefined))}
        p_fieldTypeObj={fieldTypeObj}
        p_valueRaw={p_captureFieldValueObj.valueRaw}
        p_valueMask={valueMask}
        p_valueIsEditableTFU={valueIsEditableTFU}
        p_valuesToNotIncludeArray={valuesToNotIncludeArray}
        p_containerClass={detailsItemContainerClass}
        p_fieldClass={fieldClass}
        p_fieldFontColor={fieldFontColor}
        p_fieldHighlightColor={fieldHighlightColor}
        p_fieldWidth={p_fieldWidth}
        p_fieldNowrapTF={p_isSnapshotTF}
        p_fieldHoverText={hoverText}
        p_fieldDashedUnderlineTF={true}
        p_valueClass={p_valueClass}
        p_valueMaxHeight={textareaMaxHeight}
        p_fieldValueVerticallyAlignedTF={p_fieldValueVerticallyAlignedTF}
        p_floatingBoxTitle={"Editing " + fieldDisplayName}
        f_onSaveChanged={this.onsave_changed_item}
      />
    );
  }
}));



//===================================================================================================================================
//--------Deal Shaping-------------------------------------------------------------------------------------------
//===================================================================================================================================
const DealShapingSnapshot = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class DealShapingSnapshot extends Component { //props:
  render() {
    const c_captureShapingTotalProgress = this.props.OpenCaptureMobx.c_captureShapingTotalProgress;
    const c_captureShapingStageProgress = this.props.OpenCaptureMobx.c_captureShapingStageProgress;
    const c_capturePwin = this.props.OpenCaptureMobx.c_capturePwin;
    const c_companyUsingPwinTF = this.props.DatabaseMobx.c_companyUsingPwinTF;
    const c_fieldMapOfTotalShapingProgress = this.props.DatabaseMobx.c_fieldMapOfTotalShapingProgress;
    const c_fieldMapOfStageShapingProgress = this.props.DatabaseMobx.c_fieldMapOfStageShapingProgress;
    const c_pwinCaptureFieldValueObj = this.props.OpenCaptureMobx.c_pwinCaptureFieldValueObj;

    const pwinFieldDisplayName = c_pwinCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name");
    const progressBarSizeEm = 1.7;
    const paddingString = "1em 2em";

    return(
      <>
        <div className="flex11a displayFlexColumnVc" style={{flexBasis:"130em", padding:paddingString}}>
          <div className="displayFlexRowVc">
            <div className="flex00a rMargin" style={{flexBasis:"12em"}}>
              <font className="font11 fontTextLighter">
                {c_fieldMapOfTotalShapingProgress.get("display_name") + ":"}
              </font>
            </div>
            <div className="flex11a microTopMargin">
              {this.props.DatabaseMobx.progress_bar_html_from_percent_and_size_em(c_captureShapingTotalProgress, progressBarSizeEm)}
            </div>
          </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a rMargin" style={{flexBasis:"12em"}}>
              <font className="font11 fontTextLighter">
                {c_fieldMapOfStageShapingProgress.get("display_name") + ":"}
              </font>
            </div>
            <div className="flex11a microTopMargin">
              {this.props.DatabaseMobx.progress_bar_html_from_percent_and_size_em(c_captureShapingStageProgress, progressBarSizeEm)}
            </div>
          </div>
        </div>
        {(c_companyUsingPwinTF) &&
          <div key="pwin" className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em", borderTop:"solid 1px #eee"}}>
            <div className="displayFlexRowVc">
              <div className="flex00a rMargin">
                <font className="font11 fontTextLighter">
                  {pwinFieldDisplayName + ":"}
                </font>
              </div>
              <div className="flex00a microTopMargin">
                {this.props.DatabaseMobx.pwin_cube_svg_html_from_percent_and_size_em(c_capturePwin, 3)}
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}));

const DealShaping = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class DealShaping extends Component { //props: p_container
  onsave_pwin = (i_newPwinManualValue) => {
    const c_fieldMapOfPwin = this.props.DatabaseMobx.c_fieldMapOfPwin;
    this.props.OpenCaptureMobx.a_details_update_field_value(c_fieldMapOfPwin, i_newPwinManualValue);
  }

  onselect_tag = (i_tagID) => {
    this.props.OpenCaptureMobx.a_deal_shaping_apply_tag_id(i_tagID);
  }

  onclick_view_print_capture_questions_and_answers = () => {
    const c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined = this.props.CaptureExecMobx.c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_captureName = this.props.OpenCaptureMobx.c_captureName;
    const c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs = this.props.OpenCaptureMobx.c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs;

    if(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined !== undefined) {
      const maxNumCols = 3;
      var q = 1; //question sort number across all stages

      var viewPrintQuestionsHtmlString = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml">';
      viewPrintQuestionsHtmlString += '<head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /><title>' + c_productStylingObj.productName + ' - Capture Deal Shaping Questions/Answers</title><meta name="viewport" content="width=device-width, initial-scale=1.0"/>';
      viewPrintQuestionsHtmlString += '<style>td{padding:2px;}</style></head>';
      viewPrintQuestionsHtmlString += '<body style="box-sizing:border-box; margin:0; padding:3px; border:0; background:#ffffff; font-family:sans-serif; font-size:12px; color:#000000">';
      viewPrintQuestionsHtmlString += '<table style="width:850px; border-collapse:collapse; border-spacing:0;">';
      viewPrintQuestionsHtmlString += '<tr><td colspan="' + maxNumCols + '" style="padding-bottom:5px; font-size:14px;">Deal Shaping Questions/Answers for Capture \'' + c_captureName + '\'</td></tr>';
      for(let questionsPerStageObj of c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs) {
        var stageObj = questionsPerStageObj.stageObj;
        var questionsArrayOfObjs = questionsPerStageObj.questionsArrayOfObjs;

        viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc; background:#ccc; font-size:15px;"><td colspan="' + maxNumCols + '">' + stageObj.name + '</td></tr>';

        for(let questionAnswerObj of questionsArrayOfObjs) {
          //question row
          viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc;">';
          viewPrintQuestionsHtmlString += '<td style="width:20px">' + q + '</td>';
          viewPrintQuestionsHtmlString += '<td colspan="2">' + questionAnswerObj.name + '</td>';
          viewPrintQuestionsHtmlString += '</tr>';

          //answer row
          viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc;">';
          viewPrintQuestionsHtmlString += '<td colspan="2" style="width:70px"></td>';
          viewPrintQuestionsHtmlString += '<td>' + questionAnswerObj.answerValueMaskPlainText + '</td>';
          viewPrintQuestionsHtmlString += '</tr>';

          q++;
        }
      }

      viewPrintQuestionsHtmlString += '</table>';
      viewPrintQuestionsHtmlString += '</body>';
      viewPrintQuestionsHtmlString += '</html>';

      const jsWindowNewTab = window.open(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined, "_blank");
      jsWindowNewTab.document.write(viewPrintQuestionsHtmlString);
    }
  }

  render() {
    const p_container = this.props.p_container; //p_container acts as a fullLeftRightFlag

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_dealShapingAppliedTagIDsArray = this.props.OpenCaptureMobx.o_dealShapingAppliedTagIDsArray;
    const c_captureShapingTotalProgress = this.props.OpenCaptureMobx.c_captureShapingTotalProgress;
    const c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs = this.props.OpenCaptureMobx.c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs;
    const c_pwinCaptureFieldValueObj = this.props.OpenCaptureMobx.c_pwinCaptureFieldValueObj;
    const c_fieldMapOfTotalShapingProgress = this.props.DatabaseMobx.c_fieldMapOfTotalShapingProgress;
    const c_companyUsingPwinTF = this.props.DatabaseMobx.c_companyUsingPwinTF;
    const c_companyPwinIsCalculatedTF = this.props.DatabaseMobx.c_companyPwinIsCalculatedTF;

    const pwinWrapsTF = this.props.OpenCaptureMobx.deal_shaping_pwin_wraps_tf(p_container);
    const tagsWrapsTF = this.props.OpenCaptureMobx.deal_shaping_tags_wraps_tf(p_container);

    const dealShapingAppliedTagIDsSlicedArray = o_dealShapingAppliedTagIDsArray.slice();

    const tppAndTagsContainerFlexClass = ((tagsWrapsTF) ? ("displayFlexColumn") : ("displayFlexRow"));
    const tppFlexClass = ((tagsWrapsTF) ? ("") : ("flex11a"));
    const tppFlexBasis = ((tagsWrapsTF) ? (undefined) : ("125em"));
    const tagsFlexClass = ((tagsWrapsTF) ? ("") : ("flex11a"));
    const tagsFlexBasis = ((tagsWrapsTF) ? (undefined) : ("100em"));

    const tppDisplayClass = ((pwinWrapsTF) ? ("displayFlexColumn") : ("displayFlexRow"));
    const progressFlexClass = ((pwinWrapsTF) ? ("") : ("flex11a"));
    const progressFlexBasis = ((pwinWrapsTF) ? (undefined) : ("200em"));
    const pwinFlexClass = ((pwinWrapsTF) ? ("flex00a") : ("flex11a"));
    const pwinFlexBasis = ((pwinWrapsTF) ? (undefined) : ("100em"));

    const tpptLabelFontClass = ((c_isMobileOrTabletTF) ? ("font11") : ("font13")) + " fontBold fontItalic fontTextLight";
    const progressBarFlexClass = ((c_isMobileOrTabletTF) ? ("flex11a") : ("flex00a"));
    const progressBarFlexBasis = ((c_isMobileOrTabletTF) ? (undefined) : ("17em"));
    const progressBarSizeEm = ((c_isMobileOrTabletTF) ? (1.8) : (2.1));

    //upper right vertical dots menu for printing questions/answers
    const dealShapingVerticalDotsMenuItemsArrayOfObjs = [{
      displayName: "View/Print Questions and Answers",
      functionOnClickMenuItem: this.onclick_view_print_capture_questions_and_answers
    }];

    //count the number of questions with the filters applied
    var totalNumFilteredQuestions = 0;
    for(let questionsPerStageObj of c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs) {
      totalNumFilteredQuestions += questionsPerStageObj.numQuestions;
    }

    var questionsPerStageComponent = null;
    if(totalNumFilteredQuestions === 0) {
      const numAppliedTags = dealShapingAppliedTagIDsSlicedArray.length;
      var noQuestionsMessage = undefined;
      if(numAppliedTags === 0) { //if there are 0 filtered questions when there are no tags applied, then there are 0 questions for this capture type
        noQuestionsMessage = "Your CaptureExec Admin has not yet added any Deal Shaping questions for Capture Type '" + this.props.OpenCaptureMobx.c_captureTypeMap.get("name") + "'";
      }
      else {
        const AppliedTagIDsComma = JSFUNC.convert_array_to_comma_list(dealShapingAppliedTagIDsSlicedArray);
        const appliedTagNamesComma = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(AppliedTagIDsComma, this.props.DatabaseMobx.c_selectMultiDealShapingQuestionTagsFieldTypeObj);
        noQuestionsMessage = "There are 0 Deal Shaping questions that match ";
        if(numAppliedTags === 1) {
          noQuestionsMessage += " the selected tag '" + appliedTagNamesComma + "'";
        }
        else {
          noQuestionsMessage += " any of the selected tags '" + appliedTagNamesComma + "'";
        }
      }

      questionsPerStageComponent = (
        <CEGeneralReact.EmptyScreenGray>
          {noQuestionsMessage}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else {
      questionsPerStageComponent = (
        <div className="flex11a yScroll">
          {c_dealShapingQuestionsPerActiveStageFilteredByTagsArrayOfObjs.map((m_questionsPerActiveStageObj) =>
            <DealShapingCardActiveStageQuestionsPerStage
              key={m_questionsPerActiveStageObj.id}
              p_container={p_container}
              p_questionsPerActiveStageObj={m_questionsPerActiveStageObj}
            />
          )}
        </div>
      );
    }

    return(
      <div className="flex11a displayFlexColumn bgCardGrayBg">
        <div className="flex00a displayFlexRow" style={{backgroundColor:"#eee", borderBottom:"solid 1px #99a"}}>
          <div className={"flex11a " + tppAndTagsContainerFlexClass + " tbMedPad"}>
            <div className={tppFlexClass + " " + tppDisplayClass} style={{flexBasis:tppFlexBasis}}>
              <div className={progressFlexClass + " displayFlexRowVc lrPad"} style={{flexBasis:progressFlexBasis}}>
                <div className="flex00a rMargin">
                  <font className={tpptLabelFontClass}>
                    {c_fieldMapOfTotalShapingProgress.get("display_name") + ":"}
                  </font>
                </div>
                <div className={progressBarFlexClass} style={{flexBasis:progressBarFlexBasis}}>
                  {this.props.DatabaseMobx.progress_bar_html_from_percent_and_size_em(c_captureShapingTotalProgress, progressBarSizeEm)}
                </div>
              </div>
              {(c_companyUsingPwinTF) &&
                <div className={pwinFlexClass + " displayFlexRowVc lrPad"} style={{flexBasis:pwinFlexBasis, borderLeft:"solid 1px #ddd"}}>
                  <div className="flex00a" style={{flexBasis:"15em"}}>
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(this.props.DatabaseMobx.k_cardIDDealShaping, c_pwinCaptureFieldValueObj.expandedCaptureFieldMap.get("id"))}
                      p_fieldDisplayName={c_pwinCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
                      p_fieldTypeObj={c_pwinCaptureFieldValueObj.expandedCaptureFieldMap.get("fieldTypeObj")}
                      p_valueRaw={c_pwinCaptureFieldValueObj.valueRaw}
                      p_valueMask={c_pwinCaptureFieldValueObj.valueMask}
                      p_valueIsEditableTFU={((c_companyPwinIsCalculatedTF) ? (undefined) : (true))}
                      p_valuesToNotIncludeArray={undefined}
                      p_containerClass={undefined}
                      p_fieldClass={tpptLabelFontClass}
                      p_fieldWidth="4em"
                      p_fieldNowrapTF={false}
                      p_fieldHoverText={undefined}
                      p_fieldDashedUnderlineTF={undefined}
                      p_valueClass="lrMargin"
                      p_valueMaxHeight={undefined}
                      p_fieldValueVerticallyAlignedTF={true}
                      p_floatingBoxTitle={undefined}
                      f_onSaveChanged={this.onsave_pwin}
                    />
                  </div>
                  <div className="flex11a" />
                </div>
              }
            </div>
            <div className={tagsFlexClass + " displayFlexRow flexWrap lrPad"} style={{flexBasis:tagsFlexBasis, borderLeft:"solid 1px #ddd"}}>
              <div className="flex00a microTopMargin microBottomMargin rMargin" style={{flexBasis:"19em"}}>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectNoClearDealShapingQuestionTagsFieldTypeObj}
                  p_valueRaw={-1}
                  p_valuesToNotIncludeArray={dealShapingAppliedTagIDsSlicedArray}
                  p_selectInitOptionsBoxOpenTF={false}
                  p_tabIndex={2}
                  p_title={undefined}
                  p_errorTF={false}
                  f_onChangeOrOnSelect={this.onselect_tag}
                />
              </div>
              {this.props.OpenCaptureMobx.c_dealShapingAppliedTagsArrayOfObjs.map((tagObj) =>
                <DealShapingTag key={tagObj.id} p_tagObj={tagObj} />
              )}
            </div>
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
            <CEGeneralReact.VerticalDotsMenu
              p_menuItemsArrayOfObjs={dealShapingVerticalDotsMenuItemsArrayOfObjs}
            />
          </div>
        </div>
        {questionsPerStageComponent}
      </div>
    );
  }
}));

const DealShapingTag = inject("OpenCaptureMobx")(observer(
class DealShapingTag extends Component { //props: p_tagObj
  onclick_remove_tag = () => {
    this.props.OpenCaptureMobx.a_deal_shaping_remove_tag_id(this.props.p_tagObj.id);
  }

  render() {
    return(
      <div
        className="flex00a displayFlexRowVc lrMargin lrMedPad microTopMargin microBottomMargin cursorPointer"
        style={{height:"1.6em", backgroundColor:"#def", border:"solid 1px #9df", borderRadius:"1em"}}
        title={((JSFUNC.text_or_number_is_filled_out_tf(this.props.p_tagObj.hover_text)) ? (this.props.p_tagObj.hover_text) : (undefined))}
        onClick={this.onclick_remove_tag}>
        <div className="flex11a microTopMargin microBottomMargin fontBlueLight fontItalic">
          {this.props.p_tagObj.name}
        </div>
        <div className="flex00a fontBlueLight fontItalic" style={{marginLeft:"1em"}}>
          {"x"}
        </div>
      </div>
    );
  }
}));

const DealShapingCardActiveStageQuestionsPerStage = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class DealShapingCardActiveStageQuestionsPerStage extends Component { //props: p_container, p_questionsPerActiveStageObj
  render() {
    const p_container = this.props.p_container;
    const p_questionsPerActiveStageObj = this.props.p_questionsPerActiveStageObj; //"stageObj", "questionsArrayOfObjs", "numQuestions", "stageProgress"

    const numQuestions = p_questionsPerActiveStageObj.numQuestions;

    //if there's no questions in a stage, don't draw it, including when filtering by tags
    if(numQuestions === 0) {
      return(null);
    }

    const stageObj = p_questionsPerActiveStageObj.stageObj;
    const questionsArrayOfObjs = p_questionsPerActiveStageObj.questionsArrayOfObjs;
    const stageProgress = p_questionsPerActiveStageObj.stageProgress;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_captureStageID = this.props.OpenCaptureMobx.c_captureStageID;

    //determine if this stage is the current stage
    const isCurrentStageTF = (c_captureStageID === stageObj.id);

    //compute an rc matrix of question index numbersh to put column by column into individual question rows
    const dealShapingColumnsPerStage = this.props.OpenCaptureMobx.deal_shaping_num_columns_per_stage(p_container);
    const questionIndicesRCMatrix = JSFUNC.get_cr_matrix_from_num_items_and_columns(numQuestions, dealShapingColumnsPerStage);

    const stageNameBgColor = ((isCurrentStageTF) ? ("#4a8bbd") : ("#d0d0d3"));
    const stageNameBgColor2 = ((isCurrentStageTF) ? ("#005da3") : ("#c0c0c3"));
    const questionsBgColor = "#f9f9fa";
    const stageNameFontClass = ((isCurrentStageTF) ? ("fontWhite") : ("fontTextLight"));

    return(
      <div
        className="medTopMargin hugeBottomMargin lrMargin"
        style={{background:"linear-gradient(" + stageNameBgColor + " 0, " + stageNameBgColor2 + " 3.1em, " + questionsBgColor + " 2.2em)", border:"solid 2px #99b", borderRadius:"0.6em"}}>
        <div className="displayFlexRowVc medTopMargin medBottomMargin lrMargin">
          <div className="flex00a" style={{flexBasis:"8em"}}>
            {this.props.DatabaseMobx.progress_bar_html_from_percent_and_size_em(stageProgress, 1.6)}
          </div>
          <div className="flex11a lrMargin">
            <LibraryReact.Nowrap p_fontClass={"font13 fontBold " + stageNameFontClass}>
              {stageObj.name}
            </LibraryReact.Nowrap>
          </div>
        </div>
        <div className="displayFlexRow tbMedPad">
          {questionIndicesRCMatrix.map((questionIndicesColumnArray, columnIndex) =>
            <div
              key={JSFUNC.rc_unique_row_key(dealShapingColumnsPerStage, columnIndex)}
              className="flex11a tbPad lrMedPad"
              style={{flexBasis:"100em", borderLeft:((columnIndex > 0) ? ("solid 1px #cce") : ("none"))}}>
              {questionIndicesColumnArray.map((questionIndex) =>
                (questionIndex >= 0) &&
                <DealShapingQuestion
                  key={questionsArrayOfObjs[questionIndex].id}
                  p_captureID={o_openCaptureID}
                  p_cardID={this.props.DatabaseMobx.k_cardIDDealShaping}
                  p_questionAnswerObj={questionsArrayOfObjs[questionIndex]}
                  p_isClosedStageDebriefQuestionTF={false}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}));

const DealShapingQuestion = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class DealShapingQuestion extends Component { //p_captureID, p_cardID, p_questionAnswerObj, p_isClosedStageDebriefQuestionTF
  onsave_changed_answer = (i_newValue) => {
    const p_questionAnswerObj = this.props.p_questionAnswerObj;
    const selectTrueTextareaFalse = (p_questionAnswerObj.answer_select1_text2_textarea3 === 1);
    this.props.OpenCaptureMobx.a_deal_shaping_update_answer(p_questionAnswerObj.id, selectTrueTextareaFalse, i_newValue);
  }

  render() {
    //const p_captureID = this.props.p_captureID; //unused
    const p_cardID = this.props.p_cardID;
    const p_questionAnswerObj = this.props.p_questionAnswerObj;
    const p_isClosedStageDebriefQuestionTF = JSFUNC.prop_value(this.props.p_isClosedStageDebriefQuestionTF, false);

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    //if this is a closed stage capture and this Deal Shaping Question is a debrief question on the Advance Stage Card (impossible to have closed stages on Deal Shaping Card), change the fieldTypeObj here to remove the scored percent slider from text/textarea quesions
    var dealShapingQuestionFieldTypeObj = p_questionAnswerObj.fieldTypeObj;
    if(p_isClosedStageDebriefQuestionTF) {
      if(dealShapingQuestionFieldTypeObj.scoredTextTF) {
        dealShapingQuestionFieldTypeObj = this.props.DatabaseMobx.c_scoredTextScoreHiddenTypeQuestionFieldTypeObj;
      }
      else if(dealShapingQuestionFieldTypeObj.scoredTextareaTF) {
        dealShapingQuestionFieldTypeObj = this.props.DatabaseMobx.c_scoredTextareaScoreHiddenTypeQuestionFieldTypeObj;
      }
    }

    //question name display styling from Admin style settings
    var fieldClass = "lrMargin fontBlue";
    if(p_questionAnswerObj.presetStylingObj.boldTF) { fieldClass += " fontBold"; }
    if(p_questionAnswerObj.presetStylingObj.italicTF) { fieldClass += " fontItalic"; }

    var fieldFontColor = undefined;
    if(p_questionAnswerObj.presetStylingObj.fontColorTF) {
      fieldFontColor = p_questionAnswerObj.presetStylingObj.fontSelectedColor;
    }

    var fieldHighlightColor = undefined;
    if(p_questionAnswerObj.presetStylingObj.highlightTF) {
      fieldHighlightColor = p_questionAnswerObj.presetStylingObj.highlightSelectedColor;
    }

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(p_cardID, p_questionAnswerObj.id)}
        p_fieldDisplayName={p_questionAnswerObj.name}
        p_fieldTypeObj={dealShapingQuestionFieldTypeObj}
        p_valueRaw={p_questionAnswerObj.answerValueRaw}
        p_valueMask={p_questionAnswerObj.answerValueMask}
        p_valueIsEditableTFU={c_userCanEditCaptureCardContentTF}
        p_valuesToNotIncludeArray={undefined}
        p_containerClass="bgWhite smallTopMargin smallBottomMargin smallFullPad border bevelBorderColors"
        p_fieldClass={fieldClass}
        p_fieldFontColor={fieldFontColor}
        p_fieldHighlightColor={fieldHighlightColor}
        p_fieldWidth="45%"
        p_fieldNowrapTF={false}
        p_fieldHoverText={p_questionAnswerObj.hover_text}
        p_fieldDashedUnderlineTF={true}
        p_valueClass={undefined}
        p_valueMaxHeight={((p_questionAnswerObj.answerIsTextareaTF) ? ("4.2em") : (undefined))}
        p_fieldValueVerticallyAlignedTF={false}
        p_floatingBoxTitle="Editing Deal Shaping Answer"
        f_onSaveChanged={this.onsave_changed_answer}
      />
    );
  }
}));










//===================================================================================================================================
//--------Teammates-------------------------------------------------------------------------------------------
//===================================================================================================================================
const TeammatesSnapshot = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammatesSnapshot extends Component { //props:
  render() {
    const contractsManagerCaptureFieldValueObj = this.props.OpenCaptureMobx.c_contractsManagerCaptureFieldValueObj;
    const captureTypeMap = this.props.OpenCaptureMobx.c_captureTypeMap;
    const c_captureTypeIsPrimeTF = this.props.OpenCaptureMobx.c_captureTypeIsPrimeTF;
    const fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;
    const fieldMapOfContractsManager = this.props.DatabaseMobx.c_fieldMapOfContractsManager;

    return(
      <>
        <div className="flex11a lrMedPad overflowHidden">
          <div className="microTopMargin textCenter">
            <font className="fontTextLight fontItalic">{fieldMapOfCaptureType.get("display_name") + ": "}</font>
            <font className="fontTextLight">{captureTypeMap.get("name")}</font>
          </div>
          <div className="textCenter">
            <font className="fontBlue">
              <font className="font11">{"You are " + ((c_captureTypeIsPrimeTF) ? ("the") : ("a")) + " "}</font>
              <font className="font11 fontBold">{((c_captureTypeIsPrimeTF) ? ("Prime") : ("Sub"))}</font>
            </font>
          </div>
          <TeammatesAllocationCircleGraph p_isSnapshotTF={true} />
        </div>
        <div className="flex00a tbMicroPad textCenter" style={{borderTop:"solid 1px #ddd"}}>
          <font className="fontTextLighter fontItalic">{fieldMapOfContractsManager.get("display_name") + ": "}</font>
          <font className="fontTextLighter">{contractsManagerCaptureFieldValueObj.valueMask}</font>
        </div>
      </>
    );
  }
}))

const Teammates = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class Teammates extends Component { //props: p_container
  onclick_teammates_left_tab = (i_selectedTabDbName) => {
    this.props.OpenCaptureMobx.a_teammates_set_left_tab_selected(i_selectedTabDbName);
  }

  onclick_close_teammate_contract = () => {
    this.props.OpenCaptureMobx.a_teammates_contract_close();
  }

  render() {
    const p_container = this.props.p_container;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_teammatesLeftTabSelected = this.props.OpenCaptureMobx.o_teammatesLeftTabSelected;
    const c_captureTypeIsPrimeTF = this.props.OpenCaptureMobx.c_captureTypeIsPrimeTF;
    const teammatesIsSplitScreenTF = this.props.OpenCaptureMobx.teammates_is_split_screen_tf(p_container);
    const c_bitIdiqRapidResponseTF = this.props.DatabaseMobx.c_bitIdiqRapidResponseTF;

    const darkGrayBorder1 = "solid 1px #888";

    var selectedTeammateTabIsTeammateSelectionTF = false;
    var selectedTeammateTabIsSmallBusinessRequirementsTF = false;
    var selectedTeammateTabIsSurveysCapabilitiesGapAnalysisTF = false;
    if(o_teammatesLeftTabSelected === "teammateSelection") { selectedTeammateTabIsTeammateSelectionTF = true; }
    else if(o_teammatesLeftTabSelected === "sbRequirements") { selectedTeammateTabIsSmallBusinessRequirementsTF = true; }
    else if(o_teammatesLeftTabSelected === "surveysCapabilitiesGapAnalysis") { selectedTeammateTabIsSurveysCapabilitiesGapAnalysisTF = true; }

    //left tabs (not included for sub capture type deals unless the survey system is available)
    var teammateCardTabsListComponent = null;
    if(c_captureTypeIsPrimeTF || c_bitIdiqRapidResponseTF) {
      var teammatesLeftTabDbNamesArray = ["teammateSelection"];
      var teammatesLeftTabDisplayNamesArray = ["Teammate Selection"];
      if(c_captureTypeIsPrimeTF) {
        teammatesLeftTabDbNamesArray.push("sbRequirements");
        teammatesLeftTabDisplayNamesArray.push("Small Business Requirements");
      }
      if(c_bitIdiqRapidResponseTF) {
        teammatesLeftTabDbNamesArray.push("surveysCapabilitiesGapAnalysis");
        teammatesLeftTabDisplayNamesArray.push("Surveys - Capabilities/Gap Analysis");
      }

      teammateCardTabsListComponent = (
        <div className="flex00a displayFlexRow tbPad lrMedPad" style={{borderBottom:darkGrayBorder1, background:"#789"}}>
          <CEGeneralReact.TabsList
            p_tabDbNamesArray={teammatesLeftTabDbNamesArray}
            p_tabDisplayNamesArray={teammatesLeftTabDisplayNamesArray}
            p_selectedTabDbName={o_teammatesLeftTabSelected}
            p_tabHeight="2.5em"
            p_textMaxHeight="2.4em"
            p_tabWidth={((c_isMobileOrTabletTF) ? (undefined) : ("25em"))}
            p_selectedBgClass="bgTeammatesSubtabSelected"
            p_selectedFontClass="font11 fontBold"
            p_unselectedBgClass="bgTeammatesSubtab hoverLighterBlueGradient"
            p_unselectedFontClass="font11 fontBold fontTextLight"
            f_onSelect={this.onclick_teammates_left_tab}
          />
        </div>
      );
    }

    var teammateCardContentComponent = null;
    if(selectedTeammateTabIsSurveysCapabilitiesGapAnalysisTF) { //Surveys tab is single page no split screen
      teammateCardContentComponent = (<TeammatesSurveysCapabilitiesGapAnalysis />);
    }
    else { //teammate selection and SBreqs have split screen left/right or top/bottom depending on screen width
      var leftTabContentComponent = null;
      var leftTabSplitScreenScrollClassString = "";
      if(selectedTeammateTabIsTeammateSelectionTF) {
        leftTabContentComponent = (<TeammatesLeftTeammateSelection p_container={p_container} />);
        leftTabSplitScreenScrollClassString = "yScrollAlways"; //yScrollAlways for teammate selection screen so that adding teammates beyond the page length doesn't make the screen jump when the scroll bar is drawn
      }
      else if(selectedTeammateTabIsSmallBusinessRequirementsTF) {
        leftTabContentComponent = (<DataEntryRFPRequiredAllocations />);
        leftTabSplitScreenScrollClassString = "yScroll";
      }

      const rightContentComponent = (<TeammatesRightSummary />);

      if(teammatesIsSplitScreenTF) {
        teammateCardContentComponent = (
          <div className="flex11a displayFlexRow bgCardGrayBg">
            <div className={"flex11a displayFlexColumn " + leftTabSplitScreenScrollClassString} style={{flexBasis:"150em", borderRight:darkGrayBorder1}}>
              {leftTabContentComponent}
            </div>
            <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
              {rightContentComponent}
            </div>
          </div>
        );
      }
      else {
        teammateCardContentComponent = (
          <div className="flex11a yScroll yScrollBottomPad bgCardGrayBg">
            {leftTabContentComponent}
            <div className="tbMedMargin lrMargin" style={{border:darkGrayBorder1}} />
            {rightContentComponent}
          </div>
        );
      }
    }



    return(
      <>
        {teammateCardTabsListComponent}
        {teammateCardContentComponent}
      </>
    );
  }
}));

const TeammatesLeftTeammateSelection = inject("OpenCaptureMobx", "UserMobx")(observer(
class TeammatesLeftTeammateSelection extends Component { //props: p_container
  render() {
    const p_container = this.props.p_container;

    const c_captureTypeIsPrimeTF = this.props.OpenCaptureMobx.c_captureTypeIsPrimeTF;
    const c_teammatesUsMinus2CombinedTeammateObj = this.props.OpenCaptureMobx.c_teammatesUsMinus2CombinedTeammateObj;
    const c_teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs = this.props.OpenCaptureMobx.c_teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs;
    const c_teammatesSelectedTeammatesArrayOfObjs = this.props.OpenCaptureMobx.c_teammatesSelectedTeammatesArrayOfObjs;
    const c_teammatesNotSelectedTeammatesArrayOfObjs = this.props.OpenCaptureMobx.c_teammatesNotSelectedTeammatesArrayOfObjs;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const numSubCaptureTypePrimeTeammates = c_teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs.length;
    const numSelectedTeammates = (c_teammatesSelectedTeammatesArrayOfObjs.length + ((c_captureTypeIsPrimeTF) ? (0) : (1)));
    const numNotSelectedTeammates = c_teammatesNotSelectedTeammatesArrayOfObjs.length;

    var primeCompanyComponent = null;
    if(c_captureTypeIsPrimeTF) { //prime capture type, Us/Our Division is the prime (cannot be removed)
      primeCompanyComponent = (
        <TeammateItem
          key={"usPrime"}
          p_container={p_container}
          p_teammateObj={c_teammatesUsMinus2CombinedTeammateObj}
        />
      );
    }
    else { //sub capture type, a selected company (or companies) can be added as prime, if none are added yet, a special blue add button is shown
      if(numSubCaptureTypePrimeTeammates === 0) {
        primeCompanyComponent = (
          <TeammateItemContainer p_usLbSbIvPcFlag="pc" p_isExpandedTF={false}>
            <div className="flex11a displayFlexColumnHcVc">
              <TeammatesSubCaptureTypeAddNewPrimeTeammateButton />
            </div>
          </TeammateItemContainer>
        );
      }
      else {
        primeCompanyComponent = c_teammatesSubCaptureTypeAllPrimeCombinedCompaniesArrayOfObjs.map((m_teammateObj) =>
          <TeammateItem
            key={"otherPrime" + m_teammateObj.id}
            p_container={p_container}
            p_teammateObj={m_teammateObj}
          />
        )
      }
    }

    return(
      <div className="lrPad">
        <div className="medTopMargin" />
        {(c_userCanEditCaptureCardContentTF) &&
          <TeammatesAddNewTeammatesButton />
        }
        <TeammatesListLabel p_text="Prime Company" />
        {primeCompanyComponent}
        <div style={{marginTop:"2.5em"}} />
        <TeammatesListLabel p_text={"Sub Companies (" + numSelectedTeammates + ")"} />
        {(!c_captureTypeIsPrimeTF) &&
          <TeammateItem
            key={"usSub"}
            p_container={p_container}
            p_teammateObj={c_teammatesUsMinus2CombinedTeammateObj}
          />
        }
        {c_teammatesSelectedTeammatesArrayOfObjs.map((m_teammateObj) =>
          <TeammateItem
            key={"selectedTeam" + m_teammateObj.id}
            p_container={p_container}
            p_teammateObj={m_teammateObj}
          />
        )}
        {(numNotSelectedTeammates > 0) &&
          <>
            <div style={{marginTop:"2.5em"}} />
            <TeammatesListLabel p_text={"Not Selected for Team (" + numNotSelectedTeammates + ")"} />
            {c_teammatesNotSelectedTeammatesArrayOfObjs.map((m_teammateObj) =>
              <TeammateItem
              key={"notSelectedTeam" + m_teammateObj.id}
              p_container={p_container}
                p_teammateObj={m_teammateObj}
              />
            )}
          </>
        }
      </div>
    );
  }
}));

const TeammatesSubCaptureTypeAddNewPrimeTeammateButton = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammatesSubCaptureTypeAddNewPrimeTeammateButton extends Component { //props:
  onadd_selected_prime_contact_company = (newContactCompanyIDToAdd) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const newContactCompanyIDToAddString = JSFUNC.num2str(newContactCompanyIDToAdd);
    const teammateIsPrimeOnSubCaptureTypeTF = true;
    this.props.OpenCaptureMobx.a_teammates_insert_new_teammates(o_openCaptureID, newContactCompanyIDToAddString, teammateIsPrimeOnSubCaptureTypeTF);
  }

  render() {
    const c_teammatesContactCompanyIDsArray = this.props.OpenCaptureMobx.c_teammatesContactCompanyIDsArray;
    const selectContactCompanyFieldTypeObj = this.props.DatabaseMobx.c_selectContactCompanyFieldTypeObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    if(c_userCanEditCaptureCardContentTF) {
      return(
        <CEGeneralReact.CaptureExecFieldAddNewItemsButton
          p_buttonType="gray"
          p_buttonText="Select a Contact Company as the Prime"
          p_addInstructions="Select a Contact Company to add as the Prime"
          p_fieldTypeObj={selectContactCompanyFieldTypeObj}
          p_valuesToNotIncludeArray={c_teammatesContactCompanyIDsArray}
          f_onAddSelectedItems={this.onadd_selected_prime_contact_company}
        />
      );
    }

    return(
      <div className="flex00a textCenter">
        <font className="">
          {"Select a Contact Company as the Prime"}
        </font>
      </div>
    );
  }
}));

const TeammatesAddNewTeammatesButton = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammatesAddNewTeammatesButton extends Component { //props:
  onadd_selected_contact_companies = (newContactCompanyIDsToAddComma) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const teammateIsPrimeOnSubCaptureTypeTF = false;
    this.props.OpenCaptureMobx.a_teammates_insert_new_teammates(o_openCaptureID, newContactCompanyIDsToAddComma, teammateIsPrimeOnSubCaptureTypeTF);
  }

  render() {
    const c_teammatesContactCompanyIDsArray = this.props.OpenCaptureMobx.c_teammatesContactCompanyIDsArray;
    const c_selectMultiContactCompaniesFieldTypeObj = this.props.DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj;

    return(
      <CEGeneralReact.CaptureExecFieldAddNewItemsButton
        p_buttonType="blue"
        p_buttonText="Add Teammate(s)"
        p_addInstructions="Select Teammate Companies to add to this Capture"
        p_fieldTypeObj={c_selectMultiContactCompaniesFieldTypeObj}
        p_valuesToNotIncludeArray={c_teammatesContactCompanyIDsArray}
        f_onAddSelectedItems={this.onadd_selected_contact_companies}
      />
    );
  }
}));

function TeammatesListLabel(props) { //props: p_text
  return(
    <div className="smallBottomMargin">
      <font className="font13 fontBold fontItalic fontTextLight">
        {props.p_text}
      </font>
    </div>
  );
}

const TeammateItem = inject("OpenCaptureMobx")(observer(
class TeammateItem extends Component { //props: p_container, p_teammateObj
  render() {
    const p_container = this.props.p_container;
    const p_teammateObj = this.props.p_teammateObj;

    const o_teammatesExpandedTeammateID = this.props.OpenCaptureMobx.o_teammatesExpandedTeammateID;

    var teammateUsLbSbIvPcFlag = "iv";
    var teammateIsExpandedTF = false;
    var teammateCollapseOrExpandedContentsComponent = null;
    if(p_teammateObj !== undefined) {
      teammateUsLbSbIvPcFlag = p_teammateObj.usLbSbIvPcFlag;
      teammateIsExpandedTF = (p_teammateObj.id === o_teammatesExpandedTeammateID);
      if(!teammateIsExpandedTF) {
        teammateCollapseOrExpandedContentsComponent = (<TeammateCollapsedContents p_container={p_container} p_teammateObj={p_teammateObj} />);
      }
      else {
        teammateCollapseOrExpandedContentsComponent = (<TeammateExpandedContents p_container={p_container} p_teammateObj={p_teammateObj} />);
      }
    }

    return(
      <TeammateItemContainer p_usLbSbIvPcFlag={teammateUsLbSbIvPcFlag} p_isExpandedTF={teammateIsExpandedTF}>
        {teammateCollapseOrExpandedContentsComponent}
      </TeammateItemContainer>
    );
  }
}));

const TeammateItemContainer = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammateItemContainer extends Component { //props: p_usLbSbIvPcFlag, p_isExpandedTF, children
  render() {
    const p_usLbSbIvPcFlag = this.props.p_usLbSbIvPcFlag;
    const p_isExpandedTF = JSFUNC.prop_value(this.props.p_isExpandedTF, false);

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_teammatesExpandedTeammateID = this.props.OpenCaptureMobx.o_teammatesExpandedTeammateID;
    const c_teammatesOurPrimeSubTeammateDivisionMap = this.props.OpenCaptureMobx.c_teammatesOurPrimeSubTeammateDivisionMap;

    var teammateHeight = "auto";
    if(!p_isExpandedTF && !c_isMobileTF) {
      teammateHeight = "4.8em";
    }

    var bgColorClass = "bgTeammatesTeammateBgExpanded";
    if(p_isExpandedTF) {
      bgColorClass = "ticketResponseFromBITUnread";
    }
    else if(o_teammatesExpandedTeammateID > 0 && !p_isExpandedTF) {
      bgColorClass = "bgTeammatesTeammateBgExpanded"; //bgTeammatesTeammateBgCollapsed
    }

    var businessSizeColorClass = "bgWhite";
    var colorTitle = "";
    if(p_usLbSbIvPcFlag === "us") {
      businessSizeColorClass = "bgTeammatesUs";
      colorTitle = "Our Company: " + c_teammatesOurPrimeSubTeammateDivisionMap.get("name");
    }
    else if(p_usLbSbIvPcFlag === "lb") {
      businessSizeColorClass = "bgTeammatesLargeBusiness";
      colorTitle = "Large Business Teammate";
    }
    else if(p_usLbSbIvPcFlag === "sb") {
      businessSizeColorClass = "bgTeammatesSmallBusiness";
      colorTitle = "Small Business Teammate";
    }
    else if(p_usLbSbIvPcFlag === "iv") {
      businessSizeColorClass = "bgTeammatesInvalidBusinessType";
      colorTitle = "Invalid Business Type";
    }
    else if(p_usLbSbIvPcFlag === "pc") {
      businessSizeColorClass = "bgTeammatesPrimeCompany";
      colorTitle = this.props.DatabaseMobx.c_fieldMapOfPrimeCompany.get("display_name");
    }

    return(
      <div className={"displayFlexRow medBottomMargin " + bgColorClass} style={{height:teammateHeight, border:"solid 1px #bbb", borderRadius:"0.5em"}}>
        <div className={"flex00a rMargin " + businessSizeColorClass} style={{flexBasis:"0.7em", borderRadius:"0.5em 0 0 0.5em"}} title={colorTitle} />
        {this.props.children}
      </div>
    );
  }
}));

const TeammateCollapsedContents = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammateCollapsedContents extends Component { //props: p_container, p_teammateObj
  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_captureIncumbentContactCompanyIDsArray = this.props.OpenCaptureMobx.c_captureIncumbentContactCompanyIDsArray;
    const c_bitIdiqRapidResponseTF = this.props.DatabaseMobx.c_bitIdiqRapidResponseTF;

    const displayOnTeamCheckBoxTF = (p_teammateObj.teammateRowExistsTF && !p_teammateObj.isUsTF && !p_teammateObj.isPrimeOnSubCaptureTypeTF);
    const workshareTypeFontClass = "font09 " + ((p_teammateObj.workshareTypeIsFilledOutTF) ? ("fontTextLight") : ("fontItalic fontTextLightester"));
    const teammateIsMarkedAsIncumbentTF = JSFUNC.in_array(p_teammateObj.contact_company_id, c_captureIncumbentContactCompanyIDsArray);

    const onTeamCheckboxRatingAndNameComponent = (
      <>
        {(displayOnTeamCheckBoxTF) &&
          <div className="flex00a rMargin">
            <TeammateOnTeamCheckBox p_teammateObj={p_teammateObj} />
          </div>
        }
        <div className="flex11a rMargin">
          <TeammateRatingAndName p_teammateObj={p_teammateObj} />
          {(!c_isMobileOrTabletTF) &&
            <div className="displayFlexRow microTopMargin">
              <div className="flex11a displayFlexRowVc lrPad">
                <LibraryReact.Nowrap p_fontClass={workshareTypeFontClass}>
                  {p_teammateObj.workshareTypeLabel}
                </LibraryReact.Nowrap>
              </div>
              {(teammateIsMarkedAsIncumbentTF) &&
                <div className="flex00a displayFlexColumnHcVc tbMicroPad">
                  <div className="displayFlexColumnHcVc border borderColorDarkGreen textCenter" style={{width:"5em", height:"1.2em"}}>
                    <font className="font09 fontItalic fontDarkGreen">
                      {"Incumbent"}
                    </font>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </>
    );

    var allocationComponent = null;
    if(p_teammateObj.teammateRowExistsTF) {
      allocationComponent = (<TeammateAllocationWithInlineEdit p_teammateObj={p_teammateObj} p_displayIsLargeTF={p_teammateObj.allocationDisplayIsLargeTF} />);
    }

    var contractsListComponent = null;
    if(p_teammateObj.teammateRowExistsTF && p_teammateObj.hasContractsTF) {
      contractsListComponent = (
        <div className={"flex00a displayFlexRowHcVc " + ((c_isMobileTF) ? ("medTopMargin medBottomMargin") : ("lrMargin"))}>
          <TeammateContractsList p_teammateObj={p_teammateObj} p_teammateExpandedTF={false} />
        </div>
      );
    }

    var expandCollapseComponent = null;
    if(p_teammateObj.teammateRowExistsTF) {
      expandCollapseComponent = (
        <div className="flex00a displayFlexColumnHcVc lrMargin">
          <TeammateExpandCollapseButton p_teammateObj={p_teammateObj} />
        </div>
      );
    }

    var deleteMenuComponent = (
      <TeammateItemVerticalDotsMenuInContainer p_teammateObj={p_teammateObj} />
    );

    //mobile
    if(c_isMobileTF) {
      return(
        <>
          <div className="flex11a">
            <div className="displayFlexRowVc medTopMargin">
              {onTeamCheckboxRatingAndNameComponent}
            </div>
            <div className="displayFlexRowHcVc medTopMargin">
              {allocationComponent}
            </div>
            {contractsListComponent}
          </div>
          {expandCollapseComponent}
          {deleteMenuComponent}
        </>
      );
    }

    //standard
    return(
      <>
        <div className="flex11a displayFlexRowVc">
          {onTeamCheckboxRatingAndNameComponent}
          {allocationComponent}
          {contractsListComponent}
          {(p_teammateObj.teammateRowExistsTF && c_bitIdiqRapidResponseTF) &&
            <TeammateSurveysList p_teammateObj={p_teammateObj} />
          }
        </div>
        {expandCollapseComponent}
        {deleteMenuComponent}
      </>
    );
  }
}));

const TeammateExpandedContents = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammateExpandedContents extends Component { //props: p_container, p_teammateObj
  render() {
    const p_container = this.props.p_container;
    const p_teammateObj = this.props.p_teammateObj;

    const c_bitIdiqRapidResponseTF = this.props.DatabaseMobx.c_bitIdiqRapidResponseTF;

    var tileIDsArray = [1, 2, 3, 5, 6]; //remove Surveys tile if not using Rapid Response
    if(c_bitIdiqRapidResponseTF) {
      tileIDsArray = [1, 2, 3, 4, 5, 6];
    }

    if(!p_teammateObj.isUsTF) { //only add the ratings tile if it is not us
      tileIDsArray.push(7);
    }

    const numTilesPerRow = this.props.OpenCaptureMobx.teammates_expanded_num_tiles_per_row(p_container);
    const tileIDsRCMatrix = JSFUNC.get_rc_matrix_from_id_array_and_num_columns(tileIDsArray, numTilesPerRow);

    return(
      <div className="flex11a">
        <div className="displayFlexRowVc smallTopMargin">
          {(!p_teammateObj.isUsTF && !p_teammateObj.isPrimeOnSubCaptureTypeTF) &&
            <div className="flex00a rMargin">
              <TeammateOnTeamCheckBox p_teammateObj={p_teammateObj} />
            </div>
          }
          <div className="flex11a rMargin">
            <TeammateRatingAndName p_teammateObj={p_teammateObj} />
          </div>
          <div className="flex00a displayFlexColumnHcVc rMargin">
            <TeammateExpandCollapseButton p_teammateObj={p_teammateObj} />
          </div>
          <TeammateItemVerticalDotsMenuInContainer p_teammateObj={p_teammateObj} />
        </div>
        <div className="rMargin">
          {tileIDsRCMatrix.map((m_rowTileIDsArray, rowIndex) =>
            <div key={JSFUNC.rc_unique_row_key(numTilesPerRow, rowIndex)} className="displayFlexRow">
              {m_rowTileIDsArray.map((m_tileID) =>
                <TeammateExpandedTile key={m_tileID} p_tileID={m_tileID} p_teammateObj={p_teammateObj} p_container={p_container} />
              )}
            </div>
          )}
        </div>
        <div className="smallBottomMargin" />
      </div>
    );
  }
}));


const TeammateOnTeamCheckBox = inject("OpenCaptureMobx", "UserMobx")(observer(
class TeammateOnTeamCheckBox extends Component { //props: p_teammateObj
  onclick_select_teammate_checkbox = () => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const newSelectedValue = ((!p_teammateObj.isSelectedTF) ? (1) : (0));
    this.props.OpenCaptureMobx.a_teammates_update_teammate_field(o_openCaptureID, p_teammateObj, "selected_01", "Select Teammate On/Off Team", newSelectedValue, "i");
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <>
        <div>
          <font className="fontTextLighter fontItalic">
            {"On Team"}
          </font>
        </div>
        <div className="displayFlexColumnHc microTopMargin">
          <LibraryReact.CheckBox
            p_u0_s1_p2_du3_ds4={p_teammateObj.selected_01}
            p_sizeEm={1.8}
            p_title="Mark this teammate as selected for team (teammate included in allocation calculations)"
            f_onClick={((c_userCanEditCaptureCardContentTF) ? (this.onclick_select_teammate_checkbox) : (undefined))}
          />
        </div>
      </>
    );
  }
}));


const TeammateRatingAndName = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammateRatingAndName extends Component { //props: p_teammateObj, p_writeBusinessSizeBelowTF
  render() {
    const p_teammateObj = this.props.p_teammateObj;
    const p_writeBusinessSizeBelowTF = this.props.p_writeBusinessSizeBelowTF;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;

    //our company ("us") teammate rating and name, ability to select which division (which includes sb certifications if specified by admin system) is the company being used here
    if(p_teammateObj.isUsTF) {
      const ourPrimeSubTeammateDivisionIDCaptureFieldValueObj = this.props.OpenCaptureMobx.c_ourPrimeSubTeammateDivisionIDCaptureFieldValueObj;
      const c_teammatesOurPrimeSubTeammateDivisionMap = this.props.OpenCaptureMobx.c_teammatesOurPrimeSubTeammateDivisionMap;

      const divisionIsLargeBusinessTF = c_teammatesOurPrimeSubTeammateDivisionMap.get("divisionIsLargeBusinessTF");
      const divisionIsSmallBusinessTF = c_teammatesOurPrimeSubTeammateDivisionMap.get("divisionIsSmallBusinessTF");
      const calcPrimeSBCertificationsShortNamesMask = c_teammatesOurPrimeSubTeammateDivisionMap.get("calcPrimeSBCertificationsShortNamesMask");
      const calcPrimeSBCertificationsMaskPlainText = c_teammatesOurPrimeSubTeammateDivisionMap.get("calcPrimeSBCertificationsMaskPlainText");

      var businessSizeLabel = undefined;
      var businessSizeTitle = undefined;
      if(divisionIsLargeBusinessTF) {
        businessSizeLabel = "Large Business";
      }
      else if(divisionIsSmallBusinessTF) {
        businessSizeLabel = "Small Business Certifications: ";
        businessSizeTitle = calcPrimeSBCertificationsMaskPlainText;
      }
      else {
        businessSizeLabel = "Invalid Business Type";
      }

      return(
        <div className="lrPad">
          <div className="microBottomMargin">
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={this.props.DatabaseMobx.k_cardIDTeammates}
              p_captureFieldValueObj={ourPrimeSubTeammateDivisionIDCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_valueClass="font12 fontBold fontBlue"
              p_showFieldNameTF={false}
            />
          </div>
          {(false) &&
            <div title={businessSizeTitle}>
              <LibraryReact.Nowrap p_fontClass="microTopMargin">
                <font className="fontItalic fontTextLighter">
                  {businessSizeLabel}
                </font>
                {(divisionIsSmallBusinessTF) &&
                  calcPrimeSBCertificationsShortNamesMask
                }
              </LibraryReact.Nowrap>
            </div>
          }
        </div>
      );
    }


    const teammateRating = p_teammateObj.contactCompanyObj.tr_total;
    const numTeammateReviews = p_teammateObj.contactCompanyObj.tr_num_reviews;
    const teammateRatingRound2Decimals = JSFUNC.round_number_to_num_decimals_if_needed(teammateRating, 2);
    var ratingTitle = "";
    if(numTeammateReviews === 0) {
      ratingTitle = "'" + p_teammateObj.teammateNamePlainText + "' has not yet been reviewed as a teammate on any prior captures";
    }
    else {
      ratingTitle = "'" + p_teammateObj.teammateNamePlainText + "' has a teammate rating of " + teammateRatingRound2Decimals + " based on reviews from " + numTeammateReviews + " past " + JSFUNC.plural(numTeammateReviews, "capture", "captures");
    }

    var businessSizeLabel = undefined;
    if(p_writeBusinessSizeBelowTF) {
      if(p_teammateObj.businessTypeIsLargeTF) {
        businessSizeLabel = "Large Business";
      }
      else if(p_teammateObj.businessTypeIsSmallTF) {
        businessSizeLabel = "SB Certifications: " + p_teammateObj.sbCertificationsMask;
      }
      else {
        businessSizeLabel = "Invalid Business Type";
      }
    }

    return(
      <>
        <div className="displayFlexRowVc">
          <div className="flex00a lrMargin" title={ratingTitle}>
            <CEGeneralReact.RatingScoreBox1to5 p_rating={teammateRating} p_sizeEm={2.5} />
          </div>
          <div className="flex11a">
            <ContactsReact.ContactBubble
              p_isPersonTF={false}
              p_contactID={p_teammateObj.contactCompanyObj.id}
              p_iconSizeEm={2}
              p_fontClass="font11 fontBold fontTextLight"
            />
          </div>
        </div>
        {(p_writeBusinessSizeBelowTF) &&
          <div className="microTopMargin lrMargin">
            <font className="fontItalic fontTextLight">
              {businessSizeLabel}
            </font>
          </div>
        }
      </>
    );
  }
}));

class TeammateExpandedTile extends Component { //props: p_tileID, p_teammateObj, p_container
  render() {
    const tileID = this.props.p_tileID;
    const p_teammateObj = this.props.p_teammateObj;
    const p_container = this.props.p_container;

    if(tileID < 0) {
      return(
        <div className="flex11a smallFullMargin" style={{flexBasis:"1em"}} />
      );
    }

    var tileTitle = "Tile ID" + tileID;
    var tileComponent = null;
    if(tileID === 1) {
      tileTitle = "Workshare Allocation";
      tileComponent = (<TeammateExpandedTileWorkshareAllocation p_teammateObj={p_teammateObj} />);
    }
    else if(tileID === 2) {
      tileTitle = "Contracts/Agreements";
      tileComponent = (<TeammateExpandedTileContractsManagerAndContractsList p_teammateObj={p_teammateObj} />);
    }
    else if(tileID === 3) {
      tileTitle = "Teammate Attributes";
      tileComponent = (<TeammateExpandedTileAttributes p_teammateObj={p_teammateObj} p_container={p_container} />);
    }
    else if(tileID === 4) {
      tileTitle = "Surveys";
      tileComponent = (<TeammateExpandedTileSurveys p_teammateObj={p_teammateObj} />);
    }
    else if(tileID === 5) {
      tileTitle = "Information";
      tileComponent = (<TeammateExpandedTileAllExtraFieldsList p_teammateObj={p_teammateObj} />);
    }
    else if(tileID === 6) {
      tileTitle = "Contacts";
      tileComponent = (<TeammateExpandedTileContacts p_teammateObj={p_teammateObj} />);
    }
    else if(tileID === 7) {
      tileTitle = "Ratings";
      tileComponent = (<TeammateExpandedTileRatings p_teammateObj={p_teammateObj} />);
    }

    return(
      <div className="flex11a smallFullMargin smallFullPad borderRadius05 bgWhite" style={{flexBasis:"1em", border:"solid 1px #bbb"}}>
        <div className="smallBottomMargin">
          <font className="font12 fontItalic fontTextLight">
            {tileTitle}
          </font>
        </div>
        {tileComponent}
      </div>
    );
  }
}


const TeammateExpandedTileWorkshareAllocation = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammateExpandedTileWorkshareAllocation extends Component { //props: p_teammateObj
  onsavechanged_teammate_workshare_type = (i_newValue) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesWorkshareTypeFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareTypeFieldDisplayName;
    this.props.OpenCaptureMobx.a_teammates_update_teammate_field(o_openCaptureID, p_teammateObj, "workshare_type_id", c_teammatesWorkshareTypeFieldDisplayName, i_newValue, "i");
  }

  onsavechanged_teammate_workshare_number_of_ftes = (i_newValue) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesWorkshareNumFtesFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareNumFtesFieldDisplayName;
    this.props.OpenCaptureMobx.a_teammates_update_teammate_field(o_openCaptureID, p_teammateObj, "workshare_number_of_ftes", c_teammatesWorkshareNumFtesFieldDisplayName, i_newValue, "i");
  }

  onsavechanged_teammate_workshare_notes = (i_newValue) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesWorkshareNotesFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareNotesFieldDisplayName;
    this.props.OpenCaptureMobx.a_teammates_update_teammate_field(o_openCaptureID, p_teammateObj, "workshare_notes", c_teammatesWorkshareNotesFieldDisplayName, i_newValue, "s");
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const k_cardIDTeammates = this.props.DatabaseMobx.k_cardIDTeammates;
    const c_teammatesAllocOfCovFieldDisplayName = this.props.DatabaseMobx.c_teammatesAllocOfCovFieldDisplayName;
    const c_teammatesWorkshareTypeFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareTypeFieldDisplayName;
    const c_teammatesWorkshareNumFtesFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareNumFtesFieldDisplayName;
    const c_teammatesWorkshareNotesFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareNotesFieldDisplayName;
    const c_selectTeammatesWorkshareTypeFieldTypeObj = this.props.DatabaseMobx.c_selectTeammatesWorkshareTypeFieldTypeObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const containerClass = "tbMargin";
    const fieldClass = "fontBlue";
    const fieldWidth = "10em";
    return(
      <div className="smallTopMargin">
        <div className="bigBottomMargin">
          <div className="displayFlexRowVc">
            <div className="flex00a" style={{flexBasis:fieldWidth}}>
              <font className={fieldClass}>
                {c_teammatesAllocOfCovFieldDisplayName}
              </font>
            </div>
            <div className="flex11a">
              <TeammateAllocationWithInlineEdit p_teammateObj={p_teammateObj} p_displayIsLargeTF={p_teammateObj.isSelectedTF} />
            </div>
          </div>
        </div>
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(k_cardIDTeammates, p_teammateObj.id + "worksharetype")}
          p_fieldDisplayName={c_teammatesWorkshareTypeFieldDisplayName}
          p_fieldTypeObj={c_selectTeammatesWorkshareTypeFieldTypeObj}
          p_valueRaw={p_teammateObj.workshare_type_id}
          p_valueIsEditableTFU={c_userCanEditCaptureCardContentTF}
          p_containerClass={containerClass}
          p_fieldClass={fieldClass}
          p_fieldWidth={fieldWidth}
          p_fieldValueVerticallyAlignedTF={true}
          f_onSaveChanged={this.onsavechanged_teammate_workshare_type}
        />
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(k_cardIDTeammates, p_teammateObj.id + "worksharenumFTEs")}
          p_fieldDisplayName={c_teammatesWorkshareNumFtesFieldDisplayName}
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
          p_valueRaw={p_teammateObj.workshare_number_of_ftes}
          p_valueIsEditableTFU={c_userCanEditCaptureCardContentTF}
          p_containerClass={containerClass}
          p_fieldClass={fieldClass}
          p_fieldWidth={fieldWidth}
          p_fieldValueVerticallyAlignedTF={true}
          f_onSaveChanged={this.onsavechanged_teammate_workshare_number_of_ftes}
        />
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(k_cardIDTeammates, p_teammateObj.id + "worksharenotes")}
          p_fieldDisplayName={c_teammatesWorkshareNotesFieldDisplayName}
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
          p_valueRaw={p_teammateObj.workshare_notes}
          p_valueIsEditableTFU={c_userCanEditCaptureCardContentTF}
          p_containerClass={containerClass}
          p_fieldClass={fieldClass}
          p_fieldWidth={fieldWidth}
          p_valueMaxHeight="4.2em"
          p_fieldValueVerticallyAlignedTF={true}
          p_floatingBoxTitle={"Workshare Notes for Teammate '" + p_teammateObj.teammateNamePlainText + "'"}
          f_onSaveChanged={this.onsavechanged_teammate_workshare_notes}
        />
      </div>
    );
  }
}));



const TeammateExpandedTileAttributes = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammateExpandedTileAttributes extends Component { //props: p_teammateObj, p_container
  render() {
    const p_teammateObj = this.props.p_teammateObj;
    const p_container = this.props.p_container;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;

    var labelsArray = ["Size"];
    var dataArray = [p_teammateObj.businessSizeLabel];
    if(p_teammateObj.businessTypeIsSmallTF) {
      labelsArray.push("SB Certifications");
      dataArray.push(p_teammateObj.sbCertificationsMask);
    }
    labelsArray.push("Capabilities");
    dataArray.push(p_teammateObj.capabilitiesMask);

    return(
      <div>
        {labelsArray.map((m_label, m_index) =>
          <div key={m_label} className={"displayFlexRowVc " + ((m_index > 0) ? ("smallTopMargin") : (""))}>
            <div className="flex00a" style={{flexBasis:"9em"}}>
              <font className="fontBlue">
                {m_label}
              </font>
            </div>
            <div className="flex11a">
              {dataArray[m_index]}
            </div>
          </div>
        )}
        <div className="smallTopMargin">
          <CompetitorExpandedTileIncumbent
            p_teammateTrueCompetitorFalse={true}
            p_container={p_container}
            p_captureID={o_openCaptureID}
            p_contactCompanyID={p_teammateObj.contact_company_id}
          />
        </div>
      </div>
    );
  }
}));



const TeammateExpandedTileContacts = inject("OpenCaptureMobx", "UserMobx")(observer(
class TeammateExpandedTileContacts extends Component { //props: p_teammateObj
  onadd_selected_contact_persons = (i_newContactPersonIDsToAddComma) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_add_contact_person(o_openCaptureID, p_teammateObj.id, p_teammateObj.teammateNamePlainText, p_teammateObj.contact_person_ids_comma, i_newContactPersonIDsToAddComma);
  }

  onremove_teammate_contact_person = (i_contactPersonID) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_remove_contact_person(o_openCaptureID, p_teammateObj.id, p_teammateObj.teammateNamePlainText, p_teammateObj.contact_person_ids_comma, i_contactPersonID);
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <div>
        <ContactsReact.MultiAddContactBubblesVerticalList
          p_canEditTF={c_userCanEditCaptureCardContentTF}
          p_isPersonTF={true}
          p_addedContactIDsComma={p_teammateObj.contact_person_ids_comma}
          p_addButtonType="add"
          p_addButtonText="Add Teammate Contact Person(s)"
          p_addInstructions={undefined}
          p_tabIndex={undefined}
          p_initialZoomedCompanyID={p_teammateObj.contact_company_id}
          f_onAddContacts={this.onadd_selected_contact_persons}
          f_onRemoveContact={this.onremove_teammate_contact_person}
        />
      </div>
    );
  }
}));



const TeammateExpandedTileContractsManagerAndContractsList = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammateExpandedTileContractsManagerAndContractsList extends Component { //props: p_teammateObj
  onchange_teammate_contracts_manager_contact_person = (i_contactPersonID) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_update_teammate_field(o_openCaptureID, p_teammateObj, "teammate_contracts_manager_contact_person_id", "Teammate Contracts Manager", i_contactPersonID, "i");
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <>
        <div className="bigBottomMargin">
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"cID" + o_openCaptureID + "_tID" + p_teammateObj.id + "_tcmcp"}
            p_fieldDisplayName="Teammate Contracts Manager"
            p_fieldTypeObj={this.props.DatabaseMobx.c_selectContactPersonFieldTypeObj}
            p_valueRaw={p_teammateObj.teammate_contracts_manager_contact_person_id}
            p_valueIsEditableTFU={((c_userCanEditCaptureCardContentTF) ? (true) : (undefined))}
            p_fieldClass="fontBlue"
            p_fieldWidth="15em"
            p_fieldNowrapTF={false}
            p_floatingBoxTitle="Select the Teammate Contracts Manager"
            f_onSaveChanged={this.onchange_teammate_contracts_manager_contact_person}
          />
        </div>
        <TeammateContractsList
          p_teammateObj={p_teammateObj}
          p_teammateExpandedTF={true}
        />
      </>
    );
  }
}));



const TeammateContractsList = inject("OpenCaptureMobx")(observer(
class TeammateContractsList extends Component { //props: p_teammateObj, p_teammateExpandedTF
  render() {
    const p_teammateObj = this.props.p_teammateObj;
    const p_teammateExpandedTF = this.props.p_teammateExpandedTF;

    var teammateContractItemMode = "captureTeammateCollapsed";
    if(p_teammateExpandedTF) {
      teammateContractItemMode = "captureTeammateExpanded";
    }

    return(
      p_teammateObj.expandedTeammateContractsArrayOfObjs.map((m_expandedTeammateContractObj) =>
        <TeammateContractsReact.TeammateContractItem
          key={m_expandedTeammateContractObj.teammate_contract_type_id}
          p_mode={teammateContractItemMode}
          p_expandedTeammateContractObj={m_expandedTeammateContractObj}
          p_teammateObj={p_teammateObj}
        />
      )
    );
  }
}));


const TeammateExpandedTileAllExtraFieldsList = inject("DatabaseMobx")(observer(
class TeammateExpandedTileAllExtraFieldsList extends Component { //props: p_teammateObj
  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const c_teammatesExtraFieldsArrayOfObjs = this.props.DatabaseMobx.c_teammatesExtraFieldsArrayOfObjs;

    return(
      c_teammatesExtraFieldsArrayOfObjs.map((m_teammateExtraFieldObj) =>
        <TeammateExpandedTileExtraField
          key={m_teammateExtraFieldObj.id}
          p_teammateObj={p_teammateObj}
          p_teammateExtraFieldObj={m_teammateExtraFieldObj}
        />
      )
    );
  }
}));

const TeammateExpandedTileExtraField = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammateExpandedTileExtraField extends Component { //props: p_teammateObj, p_teammateExtraFieldObj
  onsavechanged_teammate_extra_field = (i_newValue) => {
    const p_teammateObj = this.props.p_teammateObj;
    const p_teammateExtraFieldObj = this.props.p_teammateExtraFieldObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_update_teammate_field(o_openCaptureID, p_teammateObj, p_teammateExtraFieldObj.db_name, p_teammateExtraFieldObj.display_name, i_newValue, p_teammateExtraFieldObj.fieldTypeObj.idsb);
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;
    const p_teammateExtraFieldObj = this.props.p_teammateExtraFieldObj;

    const k_cardIDTeammates = this.props.DatabaseMobx.k_cardIDTeammates;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(k_cardIDTeammates, p_teammateObj.id + p_teammateExtraFieldObj.db_name)}
        p_fieldDisplayName={p_teammateExtraFieldObj.display_name}
        p_fieldTypeObj={p_teammateExtraFieldObj.fieldTypeObj}
        p_valueRaw={p_teammateObj[p_teammateExtraFieldObj.db_name]}
        p_valueIsEditableTFU={((c_userCanEditCaptureCardContentTF) ? (true) : (undefined))}
        p_containerClass="smallFullMargin"
        p_fieldClass="fontBlue"
        p_fieldWidth="10em"
        p_fieldHoverText={p_teammateExtraFieldObj.hover_text}
        p_fieldDashedUnderlineTF={true}
        p_valueMaxHeight={undefined}
        p_fieldValueVerticallyAlignedTF={false}
        p_floatingBoxTitle={p_teammateExtraFieldObj.display_name + " for Teammate '" + p_teammateObj.teammateNamePlainText + "'"}
        f_onSaveChanged={this.onsavechanged_teammate_extra_field}
      />
    );
  }
}));


const TeammateExpandedTileRatings = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammateExpandedTileRatings extends Component { //props: p_teammateObj
  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj = this.props.OpenCaptureMobx.c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj;
    const c_teammatesRatingsExpandedTeammateOverallCaptureRating = this.props.OpenCaptureMobx.c_teammatesRatingsExpandedTeammateOverallCaptureRating;
    const c_teammatesRatingsExpandedTeammateMostRecentReviewDate = this.props.OpenCaptureMobx.c_teammatesRatingsExpandedTeammateMostRecentReviewDate
    const c_teammatesRatingsQuestionsArrayOfObjs = this.props.DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    if(c_teammatesRatingsQuestionsArrayOfObjs.length === 0) {
      return(
        <div className="">
          <font className="fontItalic fontTextLighter">
            {"--Your CaptureExec admin has not created any teammate rating questions yet--"}
          </font>
        </div>
      );
    }

    var mostRecentReviewDateComponent = null;
    if(JSFUNC.date_is_filled_out_tf(c_teammatesRatingsExpandedTeammateMostRecentReviewDate)) {
      mostRecentReviewDateComponent = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(c_teammatesRatingsExpandedTeammateMostRecentReviewDate, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    }
    else {
      mostRecentReviewDateComponent = (
        <font className="fontItalic fontTextLighter">
          {"N/A"}
        </font>
      );
    }

    var reviewedByComponent = null;
    if((c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj !== undefined) && JSFUNC.date_is_filled_out_tf(c_teammatesRatingsExpandedTeammateMostRecentReviewDate)) {
      reviewedByComponent = (
        <div className="microTopMargin textCenter">
          <font className="fontTextLight">
            {"Latest Review By: "}
            {this.props.DatabaseMobx.user_name_mask_from_user_id(c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj.reviewed_by_user_id)}
          </font>
        </div>
      );
    }

    return(
      <>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="displayFlexColumnHcVc medBottomMargin">
            <TeammateRatingsEditQuestionnaireButtonWithFloatingBox
              p_teammateObj={p_teammateObj}
              p_teammatesRatingsQuestionnaireSubmissionRowObj={c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj}
            />
          </div>
        }
        <div className="textCenter">
          <font className="fontTextLight">
            {"Overall Rating for this Capture: "}
            <CEGeneralReact.RatingNA1To5OutOf5Display p_rating1to5NAm1={c_teammatesRatingsExpandedTeammateOverallCaptureRating} />
          </font>
        </div>
        <div className="microTopMargin textCenter">
          <font className="fontTextLight">
            {"Latest Review Date: "}
            {mostRecentReviewDateComponent}
          </font>
        </div>
        {reviewedByComponent}
        <div className="medTopMargin" />
        {c_teammatesRatingsQuestionsArrayOfObjs.map((m_teammatesRatingsQuestionObj) =>
          <div className="smallBottomMargin smallFullPad bgLightesterGray border1ddd borderRadius05">
            <div className="displayFlexRow">
              <div className="flex11a">
                {m_teammatesRatingsQuestionObj.name}
              </div>
              <div className="flex00a smallBottomPad">
                <CEGeneralReact.RatingNA1To5CELogos
                  p_rating1to5NAm1={((c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj === undefined) ? (-1) : (c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj["q" + m_teammatesRatingsQuestionObj.id + "a"]))}
                  p_canEditTF={false}
                  f_onSelectRating={undefined}
                />
              </div>
            </div>
            {((c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj !== undefined) && (c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj["q" + m_teammatesRatingsQuestionObj.id + "c"] !== "")) &&
              <div className="">
                <font className="fontItalic fontTextLighter">
                  {"Comments: " + c_teammatesRatingsExpandedTeammateQuestionnaireSubmissionRowObj["q" + m_teammatesRatingsQuestionObj.id + "c"]}
                </font>
              </div>
            }
          </div>
        )}
      </>
    );
  }
}));

class TeammateRatingsEditQuestionnaireButtonWithFloatingBox extends Component { //props: p_teammateObj, p_teammatesRatingsQuestionnaireSubmissionRowObj
  constructor(props) {
    super(props);
    this.state = {
      s_editTeammateRatingsFloatingBoxIsOpenTF: false
    };
  }

  onclick_edit_teammate_ratings = () => {
    this.setState({s_editTeammateRatingsFloatingBoxIsOpenTF:true});
  }

  onclick_close_teammate_ratings_questionnaire = () => {
    this.setState({s_editTeammateRatingsFloatingBoxIsOpenTF:false});
  }

  render() {
    const editTeammateRatingsFloatingBoxIsOpenTF = this.state.s_editTeammateRatingsFloatingBoxIsOpenTF;

    const p_teammateObj = this.props.p_teammateObj;
    const p_teammatesRatingsQuestionnaireSubmissionRowObj = this.props.p_teammatesRatingsQuestionnaireSubmissionRowObj;

    return(
      <>
        <CEGeneralReact.CEButton
          p_type="add"
          p_text="Edit Teammate Ratings"
          f_onClick={this.onclick_edit_teammate_ratings}
        />
        {(editTeammateRatingsFloatingBoxIsOpenTF) &&
          <TeammateRatingsEditQuestionnaireFloatingBox
            p_teammateObj={p_teammateObj}
            p_teammatesRatingsQuestionnaireSubmissionRowObj={p_teammatesRatingsQuestionnaireSubmissionRowObj}
            f_onClose={this.onclick_close_teammate_ratings_questionnaire}
          />
        }
      </>
    );
  }
}

const TeammateRatingsEditQuestionnaireFloatingBox = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammateRatingsEditQuestionnaireFloatingBox extends Component { //props: p_teammateObj, p_teammatesRatingsQuestionnaireSubmissionRowObj, f_onClose
  constructor(props) {
    super(props);

    const p_teammateObj = this.props.p_teammateObj;
    const p_teammatesRatingsQuestionnaireSubmissionRowObj = this.props.p_teammatesRatingsQuestionnaireSubmissionRowObj;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesRatingsQuestionsArrayOfObjs = this.props.DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    var localQuestionnaireSubmissionsRowObj = {};
    if(p_teammatesRatingsQuestionnaireSubmissionRowObj !== undefined) { //submission row for this cpature/teammate exists, copy the obj for local manipulation
      localQuestionnaireSubmissionsRowObj = JSFUNC.copy_obj(p_teammatesRatingsQuestionnaireSubmissionRowObj);
    }
    else { //submission row does not exist, fill an empty one with all N/A for each question rating and comment
      localQuestionnaireSubmissionsRowObj.id = -1;
      localQuestionnaireSubmissionsRowObj.capture_id = o_openCaptureID;
      localQuestionnaireSubmissionsRowObj.teammate_id = p_teammateObj.id;
      localQuestionnaireSubmissionsRowObj.contact_company_id = p_teammateObj.contact_company_id;
      localQuestionnaireSubmissionsRowObj.datetime_utc = JSFUNC.blank_datetime();
      for(let teammatesRatingsQuestionObj of c_teammatesRatingsQuestionsArrayOfObjs) {
        localQuestionnaireSubmissionsRowObj["q" + teammatesRatingsQuestionObj.id + "a"] = -1; //N/A for ratings
        localQuestionnaireSubmissionsRowObj["q" + teammatesRatingsQuestionObj.id + "c"] = ""; //blank comments
      }
    }

    this.state = {
      s_localQuestionnaireSubmissionsRowObj: localQuestionnaireSubmissionsRowObj,
      s_anyLocalChangeWereMadeTF: false
    };
  }

  onclick_save_teammate_rating_questionnaire = () => {
    const localQuestionnaireSubmissionsRowObj = this.state.s_localQuestionnaireSubmissionsRowObj;
    const anyLocalChangeWereMadeTF = this.state.s_anyLocalChangeWereMadeTF;
    const p_teammateObj = this.props.p_teammateObj;
    if(anyLocalChangeWereMadeTF) {
      const functionOnSuccess = () => {
        this.props.OpenCaptureMobx.a_teammates_ratings_recompute_contact_teammate_ratings_from_contact_company_id(p_teammateObj.contact_company_id); //this function runs in the background after you save the ratings floating box with changes
        this.onclick_close_teammate_ratings_questionnaire();
      }
      const functionOnError = () => {
        this.onclick_close_teammate_ratings_questionnaire();
      }
      this.props.OpenCaptureMobx.a_teammates_ratings_update_or_insert_questionnaire_submission(localQuestionnaireSubmissionsRowObj, functionOnSuccess, functionOnError);
    }
    else { //push save button with no local changes made, no changes to save to db, so just close floating box
      this.onclick_close_teammate_ratings_questionnaire();
    }
  }

  onclick_close_teammate_ratings_questionnaire = () => {
    if(JSFUNC.is_function(this.props.f_onClose)) {
      this.props.f_onClose();

      this.setState({
        s_localQuestionnaireSubmissionsRowObj: {},
        s_anyLocalChangeWereMadeTF: false
      });
    }
  }

  onselect_question_rating_update_local_state = (i_questionID, i_newValue) => {
    var updatedLocalQuestionnaireSubmissionsRowObj = this.state.s_localQuestionnaireSubmissionsRowObj;
    updatedLocalQuestionnaireSubmissionsRowObj["q" + i_questionID + "a"] = i_newValue;
    this.setState({
      s_localQuestionnaireSubmissionsRowObj: updatedLocalQuestionnaireSubmissionsRowObj,
      s_anyLocalChangeWereMadeTF: true
    });
  }

  onchange_question_comment_update_local_state = (i_questionID, i_newValue) => {
    var updatedLocalQuestionnaireSubmissionsRowObj = this.state.s_localQuestionnaireSubmissionsRowObj;
    updatedLocalQuestionnaireSubmissionsRowObj["q" + i_questionID + "c"] = i_newValue;
    this.setState({
      s_localQuestionnaireSubmissionsRowObj: updatedLocalQuestionnaireSubmissionsRowObj,
      s_anyLocalChangeWereMadeTF: true
    });
  }

  render() {
    const localQuestionnaireSubmissionsRowObj = this.state.s_localQuestionnaireSubmissionsRowObj;
    const anyLocalChangeWereMadeTF = this.state.s_anyLocalChangeWereMadeTF;

    const p_teammateObj = this.props.p_teammateObj;
    const p_teammatesRatingsQuestionnaireSubmissionRowObj = this.props.p_teammatesRatingsQuestionnaireSubmissionRowObj;

    const c_teammatesRatingsQuestionsArrayOfObjs = this.props.DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    const adminHas0RatingsQuestionsTF = (c_teammatesRatingsQuestionsArrayOfObjs.length === 0);

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title={"Teammate Rating Questionnaire for '" + p_teammateObj.teammateNamePlainText + "'"}
        p_cancelConfirmationMessage={((anyLocalChangeWereMadeTF) ? ("Are you sure you want to cancel changes made to this Teammate Rating Questionnaire for '" + p_teammateObj.teammateNamePlainText + "'?") : (undefined))}
        f_onClickSave={((adminHas0RatingsQuestionsTF) ? (undefined) : (this.onclick_save_teammate_rating_questionnaire))}
        f_onClickCancel={this.onclick_close_teammate_ratings_questionnaire}>
        {(adminHas0RatingsQuestionsTF) ? (
          <div className="flex11a displayFlexColumnHcVc">
            <font className="fontItalic fontTextLighter">
              {"Your CaptureExec Admin has not set up any Teammate Rating Questions to evaluate Teammate performance yet"}
            </font>
          </div>
        ) : (
          <div className="flex11a medFullPad yScroll">
            <div className="smallBottomMargin">
              <font className="fontItalic fontTextLighter">
                {"Rate '" + p_teammateObj.teammateNamePlainText + "' as a Teammate for each category below on a scale of 1-5 or 'N/A' for Not Applicable"}
              </font>
            </div>
            {c_teammatesRatingsQuestionsArrayOfObjs.map((m_teammatesRatingsQuestionObj) =>
              <div className="smallBottomMargin border1bbb borderRadius05 bgLightestGray medFullPad">
                <TeammateRatingsSingleQuestionRatingsRow
                  p_teammatesRatingsQuestionObj={m_teammatesRatingsQuestionObj}
                  p_localQuestionRating={((localQuestionnaireSubmissionsRowObj === undefined) ? (-1) : (localQuestionnaireSubmissionsRowObj["q" + m_teammatesRatingsQuestionObj.id + "a"]))}
                  p_localQuestionComment={((localQuestionnaireSubmissionsRowObj === undefined) ? ("") : (localQuestionnaireSubmissionsRowObj["q" + m_teammatesRatingsQuestionObj.id + "c"]))}
                  f_onSelectQuestionRating={this.onselect_question_rating_update_local_state}
                  f_onChangeQuestionComment={this.onchange_question_comment_update_local_state}
                />
              </div>
            )}
          </div>
        )}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const TeammateRatingsSingleQuestionRatingsRow = inject("OpenCaptureMobx")(observer(
class TeammateRatingsSingleQuestionRatingsRow extends Component { //props: p_teammatesRatingsQuestionObj, p_localQuestionRating, p_localQuestionComment, f_onSelectQuestionRating
  onselect_teammate_question_rating = (i_newValue) => {
    if(JSFUNC.is_function(this.props.f_onSelectQuestionRating)) {
      this.props.f_onSelectQuestionRating(this.props.p_teammatesRatingsQuestionObj.id, i_newValue);
    }
  }

  onchange_teammate_question_comment = (i_newValue) => {
    if(JSFUNC.is_function(this.props.f_onChangeQuestionComment)) {
      this.props.f_onChangeQuestionComment(this.props.p_teammatesRatingsQuestionObj.id, i_newValue);
    }
  }

  render() {
    const teammatesRatingsQuestionObj = this.props.p_teammatesRatingsQuestionObj;
    const localQuestionRating = this.props.p_localQuestionRating;
    const localQuestionComment = this.props.p_localQuestionComment;

    return(
      <>
        <div className="displayFlexRow">
          <div className="flex11a">
            {teammatesRatingsQuestionObj.name}
          </div>
          <div className="flex00a smallBottomPad">
            <CEGeneralReact.RatingNA1To5CELogos
              p_rating1to5NAm1={localQuestionRating}
              p_canEditTF={true}
              f_onSelectRating={this.onselect_teammate_question_rating}
            />
          </div>
        </div>
        <div className="">
          <font className="fontItalic fontTextLighter">
            {"Comments (optional)"}
          </font>
        </div>
        <div className="">
          <LibraryReact.Textarea
            p_value={localQuestionComment}
            p_styleObj={{height:"4em"}}
            f_onChange={this.onchange_teammate_question_comment}
          />
        </div>
      </>
    );
  }
}));


const TeammateAllocationWithInlineEdit = inject("OpenCaptureMobx")(observer(
class TeammateAllocationWithInlineEdit extends Component { //props: p_teammateObj, p_displayIsLargeTF
  onsave_teammate_allocation_percent = (i_newTeammateAllocationPercent0to100) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_update_teammate_field(o_openCaptureID, p_teammateObj, "allocation_percent", "Teammate Allocation Percent", i_newTeammateAllocationPercent0to100, "d");
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;
    const p_displayIsLargeTF = this.props.p_displayIsLargeTF;

    return(
      <EditSaveCancelTeammateAllocation
        p_teammateID={p_teammateObj.id}
        p_allocationPercent0to100={p_teammateObj.allocation_percent}
        p_displayIsLargeTF={p_displayIsLargeTF}
        f_onSaveChangedAllocation={this.onsave_teammate_allocation_percent}
      />
    );
  }
}));


const TeammateExpandCollapseButton = inject("OpenCaptureMobx")(observer(
class TeammateExpandCollapseButton extends Component { //props: p_teammateObj
  onclick_expand_collapse_teammate = () => {
    const p_teammateObj = this.props.p_teammateObj;
    this.props.OpenCaptureMobx.a_teammates_expand_collapse_teammate(p_teammateObj.id);
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const o_teammatesExpandedTeammateID = this.props.OpenCaptureMobx.o_teammatesExpandedTeammateID;

    const isExpandedTF = (p_teammateObj.id === o_teammatesExpandedTeammateID);

    return(
      <CEGeneralReact.ButtonExpandCollapse
        p_isExpandedTF={isExpandedTF}
        p_sizeEm={2.2}
        f_onClick={this.onclick_expand_collapse_teammate}
      />
    );
  }
}));

const TeammateItemVerticalDotsMenuInContainer = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammateItemVerticalDotsMenuInContainer extends Component { //props: p_teammateObj
  constructor(props) {
    super(props);
    this.state = {
      s_selectDifferentContactCompanyFloatingBoxIsOpenTF: false
    }
  }

  ondelete_teammate = () => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_delete_teammate(o_openCaptureID, p_teammateObj);
  }

  onclick_change_contact_company = () => {
    this.setState({s_selectDifferentContactCompanyFloatingBoxIsOpenTF:true});
  }

  onclick_close_select_different_contact_company_floating_box = () => {
    this.setState({s_selectDifferentContactCompanyFloatingBoxIsOpenTF:false});
  }

  onsave_different_contact_company = (i_selectedContactCompanyID) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_update_teammate_field(o_openCaptureID, p_teammateObj, "contact_company_id", "Contact Company", i_selectedContactCompanyID, "i");
  }

  render() {
    const s_selectDifferentContactCompanyFloatingBoxIsOpenTF = this.state.s_selectDifferentContactCompanyFloatingBoxIsOpenTF;

    const p_teammateObj = this.props.p_teammateObj;

    const c_teammatesContactCompanyIDsArray = this.props.OpenCaptureMobx.c_teammatesContactCompanyIDsArray;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    if(!c_userCanEditCaptureCardContentTF) {
      return(
        <div className="flex00a" style={{flexBasis:"0.8em"}} />
      );
    }

    if(!p_teammateObj.teammateRowExistsTF || p_teammateObj.isUsTF) {
      return(
        <div className="flex00a" style={{flexBasis:"2em"}} />
      );
    }

    var teammateDeleteMessage = "Are you sure you want to remove '" + p_teammateObj.teammateNamePlainText + "' as a potential teammate for this capture?";
    teammateDeleteMessage += "\n\nNote: this will also delete all contract processes for this teammate, including any files uploaded for each individual contract type, as well as all Survey data for this Teammate.";
    const teammateItemVerticalDotsMenuItemsArrayOfObjs = [
      {
        displayName: "Delete",
        confirmType: "confirmDelete",
        confirmMessage: teammateDeleteMessage,
        functionOnClickConfirmButton: this.ondelete_teammate
      },
      {
        displayName: "Change Contact Company",
        confirmType: undefined,
        functionOnClickMenuItem: this.onclick_change_contact_company
      }
    ];

    const allTeammatesContactCompanyIDsWithoutSelectedTeammateContactCompanyIDArray = JSFUNC.remove_all_values_from_array(p_teammateObj.contact_company_id, c_teammatesContactCompanyIDsArray);

    return(
      <>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <CEGeneralReact.VerticalDotsMenu
            p_menuItemsArrayOfObjs={teammateItemVerticalDotsMenuItemsArrayOfObjs}
            p_dotsFontClass={undefined}
            p_tabIndex={undefined}
          />
        </div>
        {(s_selectDifferentContactCompanyFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="smallVertical"
            p_title="Select a Different Contact Company"
            f_onClickSave={this.onclick_close_select_different_contact_company_floating_box}>
            <div className="flex11a displayFlexColumn medFullPad yScroll">
              <div className="">
                <font className="">
                  {"Here you can change which Contact Company is set for this particular 'Teammate Record' (percent ownership, contracts, surveys, etc)."}
                </font>
              </div>
              <div className="medTopMargin">
                <font className="fontBold">
                  {"This is not a common operation."}
                </font>
              </div>
              <div className="medTopMargin">
                <font className="">
                  {"Normally, the entire Teammate Record should be deleted and a new one created to make this change (which deletes all contracts/surveys with it). This changing function should only be used if there is an issue where the original Contact was deleted and '--No Company--' is being shown."}
                </font>
              </div>
              <div className="medTopMargin">
                <font className="">
                  {"You can use this edit below to change the Contact Compnay for this Teammate Record."}
                </font>
              </div>
              <div className="hugeTopMargin">
                <CEGeneralReact.CaptureExecFieldEditSaveCancel
                  p_ceEditItemString={"teammateSelectDifferentContactCompany" + p_teammateObj.id}
                  p_isContactFieldTF={false}
                  p_fieldDisplayName="Teammate Contact Company"
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectContactCompanyFieldTypeObj}
                  p_valueRaw={p_teammateObj.contact_company_id}
                  p_valueIsEditableTFU={true}
                  p_valuesToNotIncludeArray={allTeammatesContactCompanyIDsWithoutSelectedTeammateContactCompanyIDArray}
                  p_fieldClass="fontBlue"
                  p_fieldWidth="15em"
                  p_floatingBoxTitle="Select a Different Contact Company"
                  f_onSaveChanged={this.onsave_different_contact_company}
                />
              </div>
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));


const EditSaveCancelTeammateAllocation = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class EditSaveCancelTeammateAllocation extends Component { //props: p_teammateID, p_allocationPercent0to100, p_displayIsLargeTF, f_onSaveChangedAllocation
  onclick_edit_icon = () => {
    this.props.CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id(this.alloc_edit_item_string());
  }

  onclick_save_icon = (i_newAllocationPercentValue) => {
    if(this.props.f_onSaveChangedAllocation && (this.props.p_allocationPercent0to100 !== i_newAllocationPercentValue)) { //if the value was changed from the original allocation percent value, execute the save function before closing the edit
      this.props.f_onSaveChangedAllocation(i_newAllocationPercentValue);
    }
    this.props.CaptureExecMobx.a_close_item_editing();
  }

  onclick_cancel_icon = () => {
    this.props.CaptureExecMobx.a_close_item_editing();
  }

  compute_allocation_of_cov = (i_allocationPercent0to100) => {
    const allocationOfCOV = this.props.OpenCaptureMobx.c_captureOverallContractValue * (i_allocationPercent0to100 / 100);
    return(allocationOfCOV.toFixed(0));
  }

  alloc_edit_item_string() {
    return(this.props.OpenCaptureMobx.ce_edit_item_string(this.props.DatabaseMobx.k_cardIDTeammates, "tID" + this.props.p_teammateID + "_alloc"));
  }

  render() {
    const p_displayIsLargeTF = JSFUNC.prop_value(this.props.p_displayIsLargeTF, true);

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isEditingTF = this.props.CaptureExecMobx.is_editing_item_tf(this.alloc_edit_item_string());

    //normal display with edit icon
    if(!isEditingTF) {
      const allocNumDecimals = this.props.DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateAllocation.get("num_decimals"); //for our allocation and teammates allocation, use the number of decimals specified by the capture field our_prime_sub_teammate_allocation
      const allocationPercentMask = JSFUNC.round_number_to_num_decimals_if_needed(this.props.p_allocationPercent0to100, allocNumDecimals) + "%";
      const allocationOfCOVMask = JSFUNC.money_short(this.compute_allocation_of_cov(this.props.p_allocationPercent0to100));

      const percentFontClass = ((p_displayIsLargeTF) ? ("font13 fontBold") : ("font11 fontItalic fontTextLight"));
      const moneyFontClass = ((p_displayIsLargeTF) ? ("font11 fontTextLight") : ("fontItalic fontTextLight"));

      return(
        <div className="displayFlexColumnHc" style={{width:"13em"}}>
          <div className="flex00a displayFlexRow">
            {(c_userCanEditCaptureCardContentTF) &&
              <>
                <CEGeneralReact.EditSaveCancelIcon
                  p_iconType="edit"
                  p_tabIndex={-1}
                  f_onClick={this.onclick_edit_icon}
                />
                <div className="flex00a" style={{flexBasis:"0.3em"}} />
              </>
            }
            <div className="flex00a">
              <font className={percentFontClass}>
                {allocationPercentMask}
              </font>
            </div>
          </div>
          <div className="flex00a microTopMargin">
            <font className={moneyFontClass}>
              {allocationOfCOVMask}
            </font>
          </div>
        </div>
      );
    }

    //edit mode where percent and money can either be edited, save and cancel icons
    return(
      <EditingTeammateAllocation
        p_allocationPercent0to100={this.props.p_allocationPercent0to100}
        f_onClickSave={this.onclick_save_icon}
        f_onClickCancel={this.onclick_cancel_icon}
      />
    );
  }
}));

const EditingTeammateAllocation = inject("OpenCaptureMobx")(observer(
class EditingTeammateAllocation extends Component { //props: p_allocationPercent0to100, f_onClickSave, f_onClickCancel
  constructor(props) {
    super(props);
    this.state = {
      s_allocationPercentValue: this.props.p_allocationPercent0to100,
      s_allocationOfCOVValue: this.compute_allocation_of_cov(this.props.p_allocationPercent0to100)
    }
  }

  onchange_allocation_percent = (i_newValue) => {
    this.setState({
      s_allocationPercentValue: i_newValue,
      s_allocationOfCOVValue: this.compute_allocation_of_cov(i_newValue)
    });
  }

  onchange_allocation_money = (i_newValue) => {
    this.setState({
      s_allocationPercentValue: this.compute_allocation_percent_from_fractional_cov_value(i_newValue),
      s_allocationOfCOVValue: i_newValue
    });
  }

  onclick_save = () => {
    if(this.props.f_onClickSave) {
      this.props.f_onClickSave(this.state.s_allocationPercentValue);
    }
  }

  onclick_cancel = () => {
    if(this.props.f_onClickCancel) {
      this.props.f_onClickCancel();
    }
  }

  compute_allocation_of_cov = (i_allocationPercent0to100) => {
    const allocationOfCOV = this.props.OpenCaptureMobx.c_captureOverallContractValue * (i_allocationPercent0to100 / 100);
    return(allocationOfCOV.toFixed(0));
  }

  compute_allocation_percent_from_fractional_cov_value = (i_allocationOfCOV) => {
    const captureCOV = this.props.OpenCaptureMobx.c_captureOverallContractValue;
    if(!JSFUNC.text_or_number_is_filled_out_tf(captureCOV) || captureCOV === 0) {
      return(0);
    }
    return((i_allocationOfCOV / captureCOV) * 100);
  }

  render() {
    return(
      <div className="displayFlexColumnHc microTopPad microBottomPad bgCaptureItemEditing" style={{width:"13em", border:"solid 1px #ddd"}}>
        <div className="flex00a displayFlexRow">
          <CEGeneralReact.EditSaveCancelIcon
            p_iconType="save"
            p_tabIndex={3}
            f_onClick={this.onclick_save}
          />
          <CEGeneralReact.EditSaveCancelIcon
            p_iconType="cancel"
            p_tabIndex={4}
            f_onClick={this.onclick_cancel}
          />
          <div className="flex00a" style={{flexBasis:"0.3em"}} />
          <div className="flex00a" style={{flexBasis:"5em"}}>
            <LibraryReact.Percent
              p_value={this.state.s_allocationPercentValue}
              p_min={0}
              p_max={100}
              p_blankValue={0}
              p_decimalsTF={true}
              p_styleObj={{width:"6em"}}
              p_tabIndex={1}
              f_onChange={this.onchange_allocation_percent}
              f_onKeyDownEnter={this.onclick_save}
            />
          </div>
        </div>
        <div className="flex00a smallTopMargin">
          <LibraryReact.Money
            p_value={this.state.s_allocationOfCOVValue}
            p_centsTF={true}
            p_styleObj={{width:"10em"}}
            p_tabIndex={2}
            f_onChange={this.onchange_allocation_money}
            f_onKeyDownEnter={this.onclick_save}
          />
        </div>
      </div>
    );
  }
}));

const TeammateExpandedTileSurveys = inject("OpenCaptureMobx", "UserMobx")(observer(
class TeammateExpandedTileSurveys extends Component { //props: p_teammateObj
  onadd_selected_survey_contact_persons = (i_newContactPersonIDsToAddComma) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_surveys_add_contact_persons(o_openCaptureID, p_teammateObj.id, p_teammateObj.teammateNamePlainText, p_teammateObj.surveys_contact_person_ids_comma, i_newContactPersonIDsToAddComma);
  }

  onremove_survey_contact_person = (i_contactPersonID) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_surveys_remove_contact_person(o_openCaptureID, p_teammateObj.id, p_teammateObj.teammateNamePlainText, p_teammateObj.surveys_contact_person_ids_comma, i_contactPersonID);
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <>
        <div className="flex00a border1ddd borderRadius10 lrMargin smallBottomMargin tbPad lrMedPad">
          <div className="smallBottomMargin">
            <font className="fontItalic">
              {"Contact Person(s) to Receive Survey Email Invitations"}
            </font>
          </div>
          <ContactsReact.MultiAddContactBubblesVerticalList
            p_canEditTF={c_userCanEditCaptureCardContentTF}
            p_isPersonTF={true}
            p_addedContactIDsComma={p_teammateObj.surveys_contact_person_ids_comma}
            p_addButtonType="add"
            p_addButtonText="Add Contact Person(s)"
            p_personMarkInvalidEmailTF={true}
            p_initialZoomedCompanyID={p_teammateObj.contact_company_id}
            f_onAddContacts={this.onadd_selected_survey_contact_persons}
            f_onRemoveContact={this.onremove_survey_contact_person}
          />
        </div>
        <div className="flex00a border1ddd borderRadius10 lrMargin tbPad lrMedPad">
          <div className="tbPad">
            <font className="fontItalic">
              {"Surveys"}
            </font>
          </div>
          {(p_teammateObj.surveysAndResponseTimesArrayOfObjs.length === 0) ? (
            <div className="textCenter">
              <font className="fontItalic fontTextLighter">
                {"--No Surveys Created Yet--"}
              </font>
            </div>
          ) : (
            p_teammateObj.surveysAndResponseTimesArrayOfObjs.map((m_surveyAndResponseTimeObj) =>
              <TeammateSurveysListItem
                p_teammateObj={p_teammateObj}
                p_surveyAndResponseTimeObj={m_surveyAndResponseTimeObj}
              />
            )
          )}
        </div>
      </>
    );
  }
}));

const TeammateSurveysList = inject("OpenCaptureMobx")(observer(
class TeammateSurveysList extends Component { //props: p_teammateObj
  render() {
    const p_teammateObj = this.props.p_teammateObj;

    if(p_teammateObj.surveysAndResponseTimesArrayOfObjs.length === 0) {
      return(null);
    }

    return(
      <div className="flex00a displayFlexColumnHc lrMedPad">
        <div className="flex00a textCenter">
          <font className="fontItalic">
            {"Surveys"}
          </font>
        </div>
        <div className="flex00a displayFlexRow">
          {p_teammateObj.surveysAndResponseTimesArrayOfObjs.map((m_surveyAndResponseTimeObj) =>
            <TeammateSurveysListLight
              p_teammateObj={p_teammateObj}
              p_surveyAndResponseTimeObj={m_surveyAndResponseTimeObj}
            />
          )}
        </div>
      </div>
    );
  }
}));

const TeammateSurveysListLight = inject("OpenCaptureMobx", "UserMobx")(observer(
class TeammateSurveysListLight extends Component { //props: p_teammateObj, p_surveyAndResponseTimeObj
  onclick_teammate_survey_button = () => {
    const p_teammateObj = this.props.p_teammateObj;
    const p_surveyAndResponseTimeObj = this.props.p_surveyAndResponseTimeObj;
    this.props.OpenCaptureMobx.a_teammates_set_floating_box_survey_teammate_id_and_survey_id(p_teammateObj.id, p_surveyAndResponseTimeObj.surveyObj.id);
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;
    const p_surveyAndResponseTimeObj = this.props.p_surveyAndResponseTimeObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const surveyObj = p_surveyAndResponseTimeObj.surveyObj;
    const responseTimeObj = p_surveyAndResponseTimeObj.responseTimeObj;

    var lightHoverTitle = "Teammate: " + p_teammateObj.teammateNamePlainText;
    lightHoverTitle += "\nSurvey: " + surveyObj.title;
    lightHoverTitle += "\nStatus: " + responseTimeObj.responseStatus;
    lightHoverTitle += "\nSent Date: " + ((responseTimeObj.surveyIsSentTF) ? (responseTimeObj.sentDateTimeLocal) : ("unsent"));
    lightHoverTitle += "\nDue Date: " + ((responseTimeObj.surveyIsSentTF) ? (responseTimeObj.dueDateTimeLocal) : ("unsent"));
    lightHoverTitle += "\nFirst Opened Date: " + ((responseTimeObj.surveyIsFirstOpenedTF) ? (responseTimeObj.firstOpenedDateTimeLocal) : ((responseTimeObj.surveyIsSentTF) ? ("not yet opened") : ("unsent")));
    lightHoverTitle += "\nCompleted Date: " + ((responseTimeObj.surveyIsCompletedTF) ? (responseTimeObj.completedDateTimeLocal) : ((responseTimeObj.surveyIsSentTF) ? ("not yet completed") : ("unsent")));

    return(
      <div
        className={"flex00a displayFlexColumn " + ((c_userCanEditCaptureCardContentTF) ? ("cursorPointer") : (""))}
        style={{height:"2.6em", width:"1em", padding:"0.1em 0.05em"}}
        title={lightHoverTitle}
        onClick={((c_userCanEditCaptureCardContentTF) ? (this.onclick_teammate_survey_button) : (undefined))}>
        <div className="flex11a border bevelBorderColors" style={{borderRadius:"0.75em", background:"#" + responseTimeObj.responseBgColor}} />
      </div>
    );
  }
}));

const TeammateSurveysListItem = inject("OpenCaptureMobx", "UserMobx")(observer(
class TeammateSurveysListItem extends Component { //props: p_teammateObj, p_surveyAndResponseTimeObj
  onclick_teammate_survey_item = () => {
    const p_teammateObj = this.props.p_teammateObj;
    const p_surveyAndResponseTimeObj = this.props.p_surveyAndResponseTimeObj;
    this.props.OpenCaptureMobx.a_teammates_set_floating_box_survey_teammate_id_and_survey_id(p_teammateObj.id, p_surveyAndResponseTimeObj.surveyObj.id);
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;
    const p_surveyAndResponseTimeObj = this.props.p_surveyAndResponseTimeObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const surveyObj = p_surveyAndResponseTimeObj.surveyObj;
    const responseTimeObj = p_surveyAndResponseTimeObj.responseTimeObj;

    return(
      <div
        className={"flex00a displayFlexRow smallFullMargin border bevelBorderColors hoverLighterBlueGradient " + ((c_userCanEditCaptureCardContentTF) ? ("cursorPointer") : (""))}
        style={{height:"3em"}}
        onClick={((c_userCanEditCaptureCardContentTF) ? (this.onclick_teammate_survey_item) : (undefined))}>
        <div className="flex11a displayFlexRowVc lrPad" title={surveyObj.title}>
          <LibraryReact.MaxHeightWrap p_maxHeight="2.8em" p_fontClass="fontBold fontTextLight">
            {surveyObj.title}
          </LibraryReact.MaxHeightWrap>
        </div>
        <div className="flex00a displayFlexRowVc" style={{flexBasis:"10em"}}>
          <div className="flex00a lrPad">
            <TeammateSurveysListLight
              p_teammateObj={p_teammateObj}
              p_surveyAndResponseTimeObj={p_surveyAndResponseTimeObj}
            />
          </div>
          <div className="flex11a lrPad">
            <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLight">
              {responseTimeObj.responseStatus}
            </LibraryReact.Nowrap>
          </div>
        </div>
      </div>
    );
  }
}));

export const TeammatesFloatingBoxSurvey = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammatesFloatingBoxSurvey extends Component { //props:
  constructor(props) {
    super(props);

    //initial selected files are all files uploaded for this survey (unless the survey has been sent out before, then just use the subset of files selected for when that survey was sent out)
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;
    var initialSelectedFileIDsArray = [];
    if(c_teammatesFloatingBoxSurveyObj !== undefined) {
      initialSelectedFileIDsArray = c_teammatesFloatingBoxSurveyObj.initialSelectedFileIDsArray;
    }

    this.state = {
      s_preparingToSendEmailTF: false,
      s_responseDueDateTimeUTC: JSFUNC.blank_datetime(),
      s_selectedFileIDsArray: initialSelectedFileIDsArray,
      s_surveyFloatingBoxInvitationText: "",
      s_sentEmailSuccessOrError: undefined, //undefined - no message, "success" - green success message that email was sent, "error" - red message that email was not sent
      s_localChangedQuestionTextResponsesArrayOfObjs: []
    };
  }

  onclick_save_close_survey_floating_box = () => {
    this.onsave_our_survey_all_text_unsaved_responses();
    this.onclick_close_prepare_to_send_email();
    this.props.OpenCaptureMobx.a_teammates_set_floating_box_survey_teammate_id_and_survey_id(undefined, undefined);
  }

  onclick_open_prepare_to_send_email = () => {
    //get the initial value for the invitation text from the survey record
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;
    var initialSelectedFileIDsArray = [];
    var surveyFloatingBoxInvitationText = "";
    if(c_teammatesFloatingBoxSurveyObj !== undefined) {
      initialSelectedFileIDsArray = c_teammatesFloatingBoxSurveyObj.initialSelectedFileIDsArray;
      const surveyObj = c_teammatesFloatingBoxSurveyObj.surveyObj;
      if(surveyObj !== undefined) {
        surveyFloatingBoxInvitationText = surveyObj.invitation_text;
      }
    }

    this.setState({
      s_preparingToSendEmailTF: true,
      s_responseDueDateTimeUTC: JSFUNC.blank_datetime(),
      s_selectedFileIDsArray: initialSelectedFileIDsArray,
      s_surveyFloatingBoxInvitationText: surveyFloatingBoxInvitationText,
      s_sentEmailSuccessOrError: undefined
    });
  }

  onclick_close_prepare_to_send_email = () => {
    this.setState({
      s_preparingToSendEmailTF: false,
      s_responseDueDateTimeUTC: JSFUNC.blank_datetime(),
      s_selectedFileIDsArray: [],
      s_surveyFloatingBoxInvitationText: "",
      s_sentEmailSuccessOrError: undefined
    });
  }

  onadd_selected_survey_contact_persons = (i_newContactPersonIDsToAddComma) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;
    if(c_teammatesFloatingBoxSurveyObj !== undefined) {
      const combinedTeammateObj = c_teammatesFloatingBoxSurveyObj.combinedTeammateObj;
      this.props.OpenCaptureMobx.a_teammates_surveys_add_contact_persons(o_openCaptureID, combinedTeammateObj.id, combinedTeammateObj.teammateNamePlainText, combinedTeammateObj.surveys_contact_person_ids_comma, i_newContactPersonIDsToAddComma);
    }
  }

  onremove_survey_contact_person = (i_contactPersonID) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;
    if(c_teammatesFloatingBoxSurveyObj !== undefined) {
      const combinedTeammateObj = c_teammatesFloatingBoxSurveyObj.combinedTeammateObj;
      this.props.OpenCaptureMobx.a_teammates_surveys_remove_contact_person(o_openCaptureID, combinedTeammateObj.id, combinedTeammateObj.teammateNamePlainText, combinedTeammateObj.surveys_contact_person_ids_comma, i_contactPersonID);
    }
  }

  onchange_response_due_datetime_utc = (i_dateTimeUTC) => {
    this.setState({s_responseDueDateTimeUTC:i_dateTimeUTC});
  }

  onchange_selected_file_ids_array = (i_selectedFileIDsArray) => {
    this.setState({s_selectedFileIDsArray:i_selectedFileIDsArray});
  }

  onchange_survey_floating_box_invitation_text = (i_newValue) => {
    this.setState({s_surveyFloatingBoxInvitationText:i_newValue});
  }

  onclick_send_out_survey_button = () => {
    const s_responseDueDateTimeUTC = this.state.s_responseDueDateTimeUTC;
    const s_selectedFileIDsArray = this.state.s_selectedFileIDsArray;
    const s_surveyFloatingBoxInvitationText = this.state.s_surveyFloatingBoxInvitationText;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;

    if(c_teammatesFloatingBoxSurveyObj !== undefined) {
      this.props.OpenCaptureMobx.a_teammates_set_surveys_working_on_sending_survey_tf(true);

      const combinedTeammateObj = c_teammatesFloatingBoxSurveyObj.combinedTeammateObj;
      const surveyObj = c_teammatesFloatingBoxSurveyObj.surveyObj;

      const teammateSurveysContactPersonIDsComma = combinedTeammateObj.surveys_contact_person_ids_comma;
      const surveyID = surveyObj.id;
      const teammateID = combinedTeammateObj.id;

      const functionOnSuccess = () => {
        this.setState({s_sentEmailSuccessOrError:"success"});
        this.onclick_close_prepare_to_send_email();
        this.props.OpenCaptureMobx.a_teammates_set_surveys_working_on_sending_survey_tf(false);
      };

      const functionOnError = () => {
        this.setState({s_sentEmailSuccessOrError:"error"});
        this.onclick_close_prepare_to_send_email();
        this.props.OpenCaptureMobx.a_teammates_set_surveys_working_on_sending_survey_tf(false);
      };

      this.props.OpenCaptureMobx.a_teammates_floating_box_survey_send_out_survey_email(o_openCaptureID, surveyID, teammateID, s_responseDueDateTimeUTC, s_selectedFileIDsArray, teammateSurveysContactPersonIDsComma, s_surveyFloatingBoxInvitationText, functionOnSuccess, functionOnError);
    }
  }

  onchange_our_survey_question_text_response = (i_questionID, i_newValue) => {
    var updatedLocalChangedQuestionTextResponsesArrayOfObjs = this.state.s_localChangedQuestionTextResponsesArrayOfObjs;
    var questionIDExistedTF = false;
    for(let questionTextResponseObj of updatedLocalChangedQuestionTextResponsesArrayOfObjs) {
      if(questionTextResponseObj.id === i_questionID) {
        questionTextResponseObj.text = i_newValue;
        questionIDExistedTF = true;
        break;
      }
    }

    if(!questionIDExistedTF) {
      updatedLocalChangedQuestionTextResponsesArrayOfObjs.push({
        id: i_questionID,
        text: i_newValue
      });
    }

    this.setState({s_localChangedQuestionTextResponsesArrayOfObjs:updatedLocalChangedQuestionTextResponsesArrayOfObjs});
  }

  onsave_our_survey_question_text_response = (i_questionID) => {
    const s_localChangedQuestionTextResponsesArrayOfObjs = this.state.s_localChangedQuestionTextResponsesArrayOfObjs;
    var updatedLocalChangedQuestionTextResponsesArrayOfObjs = [];
    for(let questionTextResponseObj of s_localChangedQuestionTextResponsesArrayOfObjs) {
      if(questionTextResponseObj.id !== i_questionID) {
        updatedLocalChangedQuestionTextResponsesArrayOfObjs.push(questionTextResponseObj);
      }
    }
    this.setState({s_localChangedQuestionTextResponsesArrayOfObjs:updatedLocalChangedQuestionTextResponsesArrayOfObjs});
  }

  onclick_our_floating_box_survey_finish_survey_button = () => {
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;
    if(c_teammatesFloatingBoxSurveyObj !== undefined) {
      const responseTimeObj = c_teammatesFloatingBoxSurveyObj.responseTimeObj;
      const nowDateTimeUTC = JSFUNC.now_datetime_utc();
      this.props.OpenCaptureMobx.a_teammates_floating_box_survey_update_respone_times_completed_datetime_utc(responseTimeObj, nowDateTimeUTC);
      this.onsave_our_survey_all_text_unsaved_responses();
    }
  }

  onclick_our_floating_box_survey_unfinish_survey_button = () => {
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;
    if(c_teammatesFloatingBoxSurveyObj !== undefined) {
      const responseTimeObj = c_teammatesFloatingBoxSurveyObj.responseTimeObj;
      const blankDateTime = JSFUNC.blank_datetime();
      this.props.OpenCaptureMobx.a_teammates_floating_box_survey_update_respone_times_completed_datetime_utc(responseTimeObj, blankDateTime);
    }
  }

  onsave_our_survey_all_text_unsaved_responses = () => {
    const s_localChangedQuestionTextResponsesArrayOfObjs = this.state.s_localChangedQuestionTextResponsesArrayOfObjs;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;
    if(c_teammatesFloatingBoxSurveyObj !== undefined) {
      const combinedTeammateObj = c_teammatesFloatingBoxSurveyObj.combinedTeammateObj;
      if(combinedTeammateObj !== undefined && combinedTeammateObj.id > 0) {
        this.props.OpenCaptureMobx.a_teammates_floating_box_survey_update_or_insert_unsaved_answer_text_responses(o_openCaptureID, combinedTeammateObj.id, s_localChangedQuestionTextResponsesArrayOfObjs);
        this.setState({s_localChangedQuestionTextResponsesArrayOfObjs:[]});
      }
    }
  }

  render() {
    const s_preparingToSendEmailTF = this.state.s_preparingToSendEmailTF;
    const s_responseDueDateTimeUTC = this.state.s_responseDueDateTimeUTC;
    const s_selectedFileIDsArray = this.state.s_selectedFileIDsArray;
    const s_surveyFloatingBoxInvitationText = this.state.s_surveyFloatingBoxInvitationText;
    const s_sentEmailSuccessOrError = this.state.s_sentEmailSuccessOrError;
    const s_localChangedQuestionTextResponsesArrayOfObjs = this.state.s_localChangedQuestionTextResponsesArrayOfObjs;

    const o_teammatesFloatingBoxSurveyTeammateID = this.props.OpenCaptureMobx.o_teammatesFloatingBoxSurveyTeammateID;
    const o_teammatesFloatingBoxSurveySurveyID = this.props.OpenCaptureMobx.o_teammatesFloatingBoxSurveySurveyID;
    const o_teammatesSurveysWorkingOnSendingSurveyTF = this.props.OpenCaptureMobx.o_teammatesSurveysWorkingOnSendingSurveyTF;
    const c_teammatesFloatingBoxSurveyObj = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyObj;
    const c_teammatesFloatingBoxSurveyIsOpenTF = this.props.OpenCaptureMobx.c_teammatesFloatingBoxSurveyIsOpenTF;
    const c_cardNameTeammates = this.props.DatabaseMobx.c_cardNameTeammates;

    if(!c_teammatesFloatingBoxSurveyIsOpenTF) {
      return(null);
    }

    const combinedTeammateObj = c_teammatesFloatingBoxSurveyObj.combinedTeammateObj;
    const teammateSurveysContactPersonsAllEmailsValidTF = c_teammatesFloatingBoxSurveyObj.teammateSurveysContactPersonsAllEmailsValidTF;
    const surveyObj = c_teammatesFloatingBoxSurveyObj.surveyObj;
    const responseTimeObj = c_teammatesFloatingBoxSurveyObj.responseTimeObj;
    const fileFolderSystemMapOfMaps = c_teammatesFloatingBoxSurveyObj.fileFolderSystemMapOfMaps;
    const initialSelectedFileIDsArray = c_teammatesFloatingBoxSurveyObj.initialSelectedFileIDsArray;
    const questionResponsesArrayOfObjs = c_teammatesFloatingBoxSurveyObj.questionResponsesArrayOfObjs;

    const numQuestions = questionResponsesArrayOfObjs.length;

    //check that the selected due date/time is valid
    const nowDateTimeUTC = JSFUNC.now_datetime_utc();
    const responseDueDateTimeIsFilledOutAndLaterThanNowTF = (JSFUNC.datetime_is_filled_out_tf(s_responseDueDateTimeUTC) && (s_responseDueDateTimeUTC > nowDateTimeUTC));

    //check that the invitation text is filled out
    const invitationTextIsFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(s_surveyFloatingBoxInvitationText);

    const canSendOutSurveyTF = (responseDueDateTimeIsFilledOutAndLaterThanNowTF && teammateSurveysContactPersonsAllEmailsValidTF && invitationTextIsFilledOutTF);

    var sendOutSurveyErrorMessage = undefined;
    if(!responseDueDateTimeIsFilledOutAndLaterThanNowTF) {
      sendOutSurveyErrorMessage = "Response Due Date must be filled out with a date later than today";
    }
    else if(!teammateSurveysContactPersonsAllEmailsValidTF) {
      sendOutSurveyErrorMessage = "Contact Person(s) must all have valid email addresses to send the survey to";
    }
    else if(!invitationTextIsFilledOutTF) {
      sendOutSurveyErrorMessage = "The Invitation Text for the survey email must be filled out";
    }

    var insideFloatingBoxComponent = null;
    if(combinedTeammateObj === undefined) {
      insideFloatingBoxComponent = (
        <CEGeneralReact.EmptyScreenGray>
          {"Selected Teammate Does Not Exist (ID: " + o_teammatesFloatingBoxSurveyTeammateID + ")"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else if(surveyObj === undefined) {
      insideFloatingBoxComponent = (
        <CEGeneralReact.EmptyScreenGray>
          {"Selected Survey Does Not Exist (ID: " + o_teammatesFloatingBoxSurveySurveyID + ")"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else if(combinedTeammateObj.id < 0) { //case of trying to open our survey and the teammate -2 row does not exist in the database
      insideFloatingBoxComponent = (
        <CEGeneralReact.EmptyScreenGray>
          {"Data initialization for Our Company not created yet for this capture. Please try refreshing and logging back into the system, and return to this capture's " + c_cardNameTeammates + " card"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else {
      insideFloatingBoxComponent = (
        <div className="flex11a yScroll medFullPad">
          <div className="tbMargin lrMedMargin medFullPad bgContactsLighterYellow border1ddd borderRadius10">
            <div className="microBottomMargin textCenter">
              <font className="font11 fontItalic fontTextLighter">{"Teammate"}</font>
            </div>
            <div className="textCenter">
              <font className="font16 fontBold fontTextLight">{combinedTeammateObj.teammateNamePlainText}</font>
            </div>
          </div>
          <div className="displayFlexColumnHcVc">
            <div className="tbMargin medFullPad bgWhite border1ddd" style={{width:"80%"}}>
              <div className="microBottomMargin textCenter">
                <font className="font11 fontItalic fontTextLighter">{"Survey"}</font>
              </div>
              <div className="smallBottomMargin textCenter">
                <font className="font16 fontBlue fontTextLight">{surveyObj.title}</font>
              </div>
              <div className="smallBottomMargin displayFlexColumnHcVc">
                <div className="displayFlexColumnHcVc border bevelBorderColors" style={{width:"60%", height:"2em", background:"#" + responseTimeObj.responseBgColor}}>
                  <font className={"font11 fontBold " + responseTimeObj.responseFontClass}>{responseTimeObj.responseStatus}</font>
                </div>
              </div>
              <div className="smallBottomMargin textCenter">
                <font className="font11">
                  <font className="">{"Survey Sent Date: "}</font>
                  {(responseTimeObj.surveyIsSentTF) ? (
                    <font className="fontBold fontTextLight">{responseTimeObj.sentDateTimeLocal}</font>
                  ) : (
                    <font className="fontItalic fontTextLighter">{"unsent"}</font>
                  )}
                </font>
              </div>
              <div className="smallBottomMargin textCenter">
                <font className="font11">
                  <font className="">{"Survey Due Date: "}</font>
                  {(responseTimeObj.surveyIsSentTF) ? (
                    <font className="fontBold fontTextLight">{responseTimeObj.dueDateTimeLocal}</font>
                  ) : (
                    <font className="fontItalic fontTextLighter">{"unsent"}</font>
                  )}
                </font>
              </div>
              <div className="smallBottomMargin textCenter">
                <font className="font11">
                  <font className="">{"Survey Completed Date: "}</font>
                  {(responseTimeObj.surveyIsCompletedTF) ? (
                    <font className="fontBold fontTextLight">{responseTimeObj.completedDateTimeLocal}</font>
                  ) : (
                    <font className="fontItalic fontTextLighter">{(responseTimeObj.surveyIsSentTF) ? ("not yet completed") : ("unsent")}</font>
                  )}
                </font>
              </div>
              <div className="medBottomMargin displayFlexColumnHcVc">
                <div className="borderT1ddd" style={{width:"70%"}} />
              </div>
              {(!s_preparingToSendEmailTF) ? (
                <>
                  {((s_sentEmailSuccessOrError === "success") || (s_sentEmailSuccessOrError === "error")) &&
                    <div className="displayFlexColumnHcVc smallBottomMargin">
                      <div className="tbPad lrMedPad border1ddd textCenter" style={{width:"50%", background:((s_sentEmailSuccessOrError === "success") ? ("#dfd") : ("#fdd"))}}>
                        <font className={"fontItalic " + ((s_sentEmailSuccessOrError === "success") ? ("") : ("fontRed"))}>
                          {((s_sentEmailSuccessOrError === "success") ? ("Survey invitation(s) successfully sent") : ("There was an error processing this survey form. No invitations were emailed out. Please retry this process."))}
                        </font>
                      </div>
                    </div>
                  }
                  {(numQuestions > 0) &&
                    <div className="displayFlexColumnHcVc">
                      <CEGeneralReact.CEButton
                        p_type="add"
                        p_text="Prepare to Email Out This Survey"
                        f_onClick={this.onclick_open_prepare_to_send_email}
                      />
                    </div>
                  }
                </>
              ) : (
                <div className="border1bbb borderRadius10 bgLightesterGray lrMedMargin medFullPad">
                  <div className="microBottomMargin">
                    <font className="fontItalic fontTextLight">
                      {"Enter Required Response Due Date/Time:"}
                    </font>
                  </div>
                  <div className="displayFlexRow">
                    <div className="flex00a">
                      <LibraryReact.DateTime
                        p_valueDateTimeUTC={s_responseDueDateTimeUTC}
                        p_isSingleLineTF={true}
                        p_tabIndex={200}
                        p_errorTF={(!responseDueDateTimeIsFilledOutAndLaterThanNowTF)}
                        f_onChange={this.onchange_response_due_datetime_utc}
                      />
                    </div>
                    <div className="flex11a" />
                  </div>
                  <div className="hugeTopMargin" />
                  <TeammatesSurveysSelectDocumentsForEmail
                    p_fileFolderSystemMapOfMaps={fileFolderSystemMapOfMaps}
                    p_selectedFileIDsArray={s_selectedFileIDsArray}
                    f_onChange={this.onchange_selected_file_ids_array}
                  />
                  <div className="displayFlexRow border hugeTopMargin lrPad bgLighterBlue bevelBorderColorBlue" style={{minHeight:"5em"}}>
                    <div className="flex11a displayFlexColumnVc lrPad" style={{flexBasis:"100em"}}>
                      <div className="flex00a tbPad lrMedPad borderRadius10 bgLightestestGray">
                        <font className="font11 fontBold">
                          {combinedTeammateObj.teammateNamePlainText}
                        </font>
                      </div>
                    </div>
                    <div className="flex11a displayFlexColumnVc lrPad" style={{flexBasis:"150em"}}>
                      <div className="flex00a tbPad">
                        <div className="smallBottomMargin">
                          <font className="fontItalic">
                            {"Contact Person(s) to Receive Survey Email Invitations"}
                          </font>
                        </div>
                        <ContactsReact.MultiAddContactBubblesVerticalList
                          p_isPersonTF={true}
                          p_addedContactIDsComma={combinedTeammateObj.surveys_contact_person_ids_comma}
                          p_addButtonType="add"
                          p_addButtonText="Add Contact Person(s)"
                          p_errorIfEmptyTF={true}
                          p_personMarkInvalidEmailTF={true}
                          p_initialZoomedCompanyID={combinedTeammateObj.contact_company_id}
                          f_onAddContacts={this.onadd_selected_survey_contact_persons}
                          f_onRemoveContact={this.onremove_survey_contact_person}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="hugeTopMargin">
                    <font className="fontItalic fontTextLight">
                      {"Fill out the content of the survey invitation email:"}
                    </font>
                  </div>
                  <div className="displayFlexColumn microTopMargin" style={{height:"10em"}}>
                    <LibraryReact.Textarea
                      p_value={s_surveyFloatingBoxInvitationText}
                      p_class="flex11a"
                      p_tabIndex={210}
                      p_errorTF={(!invitationTextIsFilledOutTF)}
                      f_onChange={this.onchange_survey_floating_box_invitation_text}
                    />
                  </div>
                  <div className="hugeTopMargin displayFlexRowHcWrap">
                    {(o_teammatesSurveysWorkingOnSendingSurveyTF) ? (
                      <div className="flex00a displayFlexColumnHcVc textCenter">
                        <div className="flex00a smallBottomMargin">
                          <font className="fontItalic fontTextLighter">
                            {"Sending out Teammate Survey"}
                          </font>
                        </div>
                        <CEGeneralReact.LoadingAnimation />
                      </div>
                    ) : (
                      <>
                        <div className="flex00a lrMedPad">
                          <CEGeneralReact.CEButton
                            p_type={((canSendOutSurveyTF) ? ("blue") : ("blueDisabled"))}
                            p_text="Send Out This Survey"
                            p_title={sendOutSurveyErrorMessage}
                            p_tabIndex={211}
                            f_onClick={((canSendOutSurveyTF) ? (this.onclick_send_out_survey_button) : (undefined))}
                          />
                        </div>
                        <div className="flex00a lrMedPad">
                          <CEGeneralReact.CEButton
                            p_type="gray"
                            p_text="Cancel"
                            p_tabIndex={212}
                            f_onClick={this.onclick_close_prepare_to_send_email}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {(!canSendOutSurveyTF) &&
                    <CEGeneralReact.ErrorText p_class="microTopMargin textCenter" p_text={sendOutSurveyErrorMessage} />
                  }
                </div>
              )}
            </div>
          </div>
          <div className="bigBottomMargin" />
          <div className="medFullPad">
            {(numQuestions === 0) &&
              <div className="textCenter">
                <font className="fontItalic fontTextLighter">
                  {"This Survey does not have any questions yet"}
                </font>
              </div>
            }
            {questionResponsesArrayOfObjs.map((m_questionResponseObj, m_index) =>
              <TeammatesFloatingBoxSurveyQuestion
                p_questionResponseObj={m_questionResponseObj}
                p_canEditAnswersTF={combinedTeammateObj.isUsTF}
                p_tabIndex={(m_index + 1)}
                f_onChangeTextResponse={this.onchange_our_survey_question_text_response}
                f_onSaveTextResponse={this.onsave_our_survey_question_text_response}
              />
            )}
            {(combinedTeammateObj.isUsTF && (numQuestions > 0)) &&
              <div className="displayFlexColumnHcVc medTopMargin">
                {(JSFUNC.datetime_is_filled_out_tf(responseTimeObj.completed_datetime_utc)) ? (
                  <div
                    className="displayFlexColumnHcVc bgLightGray border bevelBorderColors cursorPointer"
                    style={{width:"24em", height:"3em"}}
                    title="Click to reset our survey as --Not Completed--"
                    onClick={this.onclick_our_floating_box_survey_unfinish_survey_button}>
                    <font className="font15 fontItalic fontTextLight">
                      {"Survey Marked as Finished"}
                    </font>
                  </div>
                ) : (
                  <div
                    className="displayFlexColumnHcVc bgDarkBlue hoverBlueGradient border bevelBorderColors cursorPointer"
                    style={{width:"24em", height:"3em"}}
                    onClick={this.onclick_our_floating_box_survey_finish_survey_button}>
                    <font className="font15 fontWhite">
                      {"Mark Our Survey as Finished"}
                    </font>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="teammateSurvey"
        p_title="Teammate Survey"
        f_onClickSave={this.onclick_save_close_survey_floating_box}>
        {insideFloatingBoxComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const TeammatesFloatingBoxSurveyQuestion = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammatesFloatingBoxSurveyQuestion extends Component { //props: p_questionResponseObj, p_canEditAnswersTF, p_tabIndex, f_onChangeTextResponse, f_onSaveTextResponse
  constructor(props) {
    super(props);

    const p_questionResponseObj = this.props.p_questionResponseObj;

    this.state = {
      s_comment: p_questionResponseObj.comment,
      s_commentIsChangedTF: false,
      s_commentSavedAtLeastOnceTF: false
    };
  }

  onselect_rating_0to5 = (i_newRating) => {
    const p_questionResponseObj = this.props.p_questionResponseObj;
    this.onsave_answer(i_newRating, p_questionResponseObj.comment);
  }

  onchange_comment = (i_newComment) => {
    const p_questionResponseObj = this.props.p_questionResponseObj;

    this.setState({
      s_comment: i_newComment,
      s_commentIsChangedTF: true
    });

    if(JSFUNC.is_function(this.props.f_onChangeTextResponse)) {
      this.props.f_onChangeTextResponse(p_questionResponseObj.id, i_newComment);
    }
  }

  onclick_save_comment = () => {
    const s_comment = this.state.s_comment;
    const p_questionResponseObj = this.props.p_questionResponseObj;

    this.onsave_answer(p_questionResponseObj.rating, s_comment);

    this.setState({
      s_commentIsChangedTF: false,
      s_commentSavedAtLeastOnceTF: true
    });

    if(JSFUNC.is_function(this.props.f_onSaveTextResponse)) {
      this.props.f_onSaveTextResponse(p_questionResponseObj.id);
    }
  }

  onsave_answer = (i_newRating, i_newComment) => {
    const p_questionResponseObj = this.props.p_questionResponseObj;
    const o_teammatesFloatingBoxSurveyTeammateID = this.props.OpenCaptureMobx.o_teammatesFloatingBoxSurveyTeammateID;
    this.props.OpenCaptureMobx.a_teammates_floating_box_survey_update_or_insert_answer_rating_and_comment(p_questionResponseObj.capture_id, o_teammatesFloatingBoxSurveyTeammateID, p_questionResponseObj.id, i_newRating, i_newComment);
  }

  render() {
    const s_comment = this.state.s_comment;
    const s_commentIsChangedTF = this.state.s_commentIsChangedTF;
    const s_commentSavedAtLeastOnceTF = this.state.s_commentSavedAtLeastOnceTF;

    const p_questionResponseObj = this.props.p_questionResponseObj;
    const p_canEditAnswersTF = JSFUNC.prop_value(this.props.p_canEditAnswersTF, false);
    const p_tabIndex = this.props.p_tabIndex;

    var questionAndAnswerComponent = null;
    if(p_questionResponseObj.typeIsInstructionsBlockTF) {
      questionAndAnswerComponent = (
        <div className="border1bbb bgDarkGray tbMedPad lrBigPad">
          <font className="font11 fontBold fontWhite">
            {p_questionResponseObj.name}
          </font>
        </div>
      );
    }
    else {
      const ynButtonClass = "flex00a displayFlexColumnHcVc bgLightestGray border bevelBorderColors";
      const ynButtonHeight = "2.4em";
      const ynLabelFontClass = "font11 fontBold fontTextLight";
      const yesSelectedTF = (p_questionResponseObj.rating === 1);
      const noSelectedTF = (p_questionResponseObj.rating === 0);

      questionAndAnswerComponent = (
        <div className="border1bbb borderRadius10 bgLightestGray tbMedPad lrBigPad">
          <div className="smallBottomMargin">
            <font className="font11 fontBold fontTextLight">
              {p_questionResponseObj.name}
            </font>
          </div>
          {(p_questionResponseObj.typeIsYesNoTF) &&
            <div className="smallBottomMargin">
              <SurveyYesNoOrScale0to5Buttons
                p_yesnoTrueScale0to5False={true}
                p_selectedRating={p_questionResponseObj.rating}
                p_canEditTF={p_canEditAnswersTF}
                f_onClickYesnoOrScale0to5Button={this.onselect_rating_0to5}
              />
            </div>
          }
          {(p_questionResponseObj.typeIsScale05TF) &&
            <div className="smallBottomMargin">
              <SurveyYesNoOrScale0to5Buttons
                p_yesnoTrueScale0to5False={false}
                p_selectedRating={p_questionResponseObj.rating}
                p_canEditTF={p_canEditAnswersTF}
                f_onClickYesnoOrScale0to5Button={this.onselect_rating_0to5}
              />
            </div>
          }
          {(p_canEditAnswersTF && (p_questionResponseObj.typeIsYesNoTF || p_questionResponseObj.typeIsScale05TF)) &&
            <div className="lrPad microBottomMargin">
              <font className="fontItalic fontTextLighter">
                {"Comments (optional)"}
              </font>
            </div>
          }
          {(p_canEditAnswersTF) ? (
            <div className="lrPad">
              <div className="displayFlexColumn" style={{height:((p_questionResponseObj.typeIsTextResponseTF) ? ("10em") : ("5em"))}}>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
                  p_valueRaw={s_comment}
                  p_tabIndex={p_tabIndex}
                  f_onChangeOrOnSelect={this.onchange_comment}
                />
              </div>
              {(s_commentIsChangedTF) &&
                <div className="smallTopMargin">
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text="Save Changes"
                    f_onClick={this.onclick_save_comment}
                  />
                </div>
              }
              {(!s_commentIsChangedTF && s_commentSavedAtLeastOnceTF) &&
                <div className="microTopMargin">
                  <font className="font09 fontItalic fontTextLighter">
                    {"Text changes saved for this question"}
                  </font>
                </div>
              }
            </div>
          ) : (
            <div className="lrPad">
              <font className="">
                {s_comment}
              </font>
            </div>
          )}
        </div>
      );
    }

    return(
      <>
        {questionAndAnswerComponent}
        <div className="medBottomMargin" />
      </>
    );
  }
}));


function SurveyYesNoOrScale0to5Buttons(props) { //props: p_yesnoTrueScale0to5False, p_selectedRating, p_canEditTF, f_onClickYesnoOrScale0to5Button(i_rating)
  const yesnoTrueScale0to5False = props.p_yesnoTrueScale0to5False;
  const selectedRating = props.p_selectedRating;
  const canEditTF = props.p_canEditTF;

  var ratingsArray = [];
  var displayArray = [];
  var bgClassArray = [];
  if(yesnoTrueScale0to5False) {
    ratingsArray = [1, 0]; //1-Yes, 0-No, -1 (or answer row doesn't exist) - Neither Selected
    displayArray = ["Yes", "No"];
    bgClassArray = ["bgGreen", "bgRed"];
  }
  else {
    ratingsArray = [0, 1, 2, 3, 4, 5]; //0 1 2 3 4 5 for ratings, -1 (or answer row doesn't exist) - Neither Selected
    displayArray = [0, 1, 2, 3, 4, 5];
    bgClassArray = ["bgGreen", "bgGreen", "bgGreen", "bgGreen", "bgGreen", "bgGreen"];
  }


  return(
    <div className="displayFlexRow flexWrap">
      {ratingsArray.map((m_rating, m_index) =>
        <SurveyRatingButton
          p_rating={m_rating}
          p_selectedRating={selectedRating}
          p_displayText={displayArray[m_index]}
          p_selectedBgColor={bgClassArray[m_index]}
          p_canEditTF={canEditTF}
          f_onClick={props.f_onClickYesnoOrScale0to5Button}
        />
      )}
    </div>
  );
}


class SurveyRatingButton extends Component { //props: p_rating, p_selectedRating, p_displayText, p_selectedBgColor, p_canEditTF, f_onClick(i_rating)
  onclick_button = () => {
    const rating = this.props.p_rating;
    const selectedRating = this.props.p_selectedRating;
    if(JSFUNC.is_function(this.props.f_onClick)) {
      const newRating = ((selectedRating === rating) ? (-1) : (rating)); //if already Yes, change to not set (-1), otherwise change to Yes
      this.props.f_onClick(newRating);
    }
  }

  render() {
    const rating = this.props.p_rating;
    const selectedRating = this.props.p_selectedRating;
    const displayText = this.props.p_displayText;
    const selectedBgColor = this.props.p_selectedBgColor;
    const canEditTF = this.props.p_canEditTF;

    const buttonIsSelectedTF = (selectedRating === rating);
    const bgClass = ((buttonIsSelectedTF) ? (selectedBgColor) : ("bgLightGrayGradient"));
    const fontClass = ((buttonIsSelectedTF) ? ("fontWhite") : ("fontTextLighter"));

    return(
      <div
        className={"flex00a displayFlexColumnHcVc tbMicroMargin lrMargin border bevelBorderColors borderRadius05 " + bgClass + " " + ((canEditTF) ? ("cursorPointer") : (""))}
        style={{height:"2.6em", width:"6.2em"}}
        onClick={((canEditTF) ? (this.onclick_button) : (undefined))}>
        <font className={"font11 fontBold " + fontClass}>
          {displayText}
        </font>
      </div>
    );
  }
}


const DataEntryRFPRequiredAllocations = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class DataEntryRFPRequiredAllocations extends Component { //props:
  onadd_sb_certification_type = (i_selectedSBCertificationIDsComma) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_insert_new_sb_certifications(o_openCaptureID, i_selectedSBCertificationIDsComma);
  }

  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesSBCertificationIDsArray = this.props.OpenCaptureMobx.c_teammatesSBCertificationIDsArray;
    const c_teammatesSBCertificationAllocationsArrayOfObjs = this.props.OpenCaptureMobx.c_teammatesSBCertificationAllocationsArrayOfObjs;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const subContainerWidths = ((c_isMobileTF) ? ("100%") : ("40em"));
    const fieldWidth = ((c_isMobileTF) ? ("50%") : ("23em"));
    const labelFontClass = "font12 fontItalic fontTextLight";

    const totalSBAllocationComponent = (
      <div className="lrMargin medTopMargin bgLightesterGray medFullPad" style={{width:subContainerWidths}}>
        <div className="">
          <font className={labelFontClass}>
            {"Total Small Business Allocation"}
          </font>
        </div>
        <div className="displayFlexRowVc smallTopMargin lrPad microTopPad microBottomPad border bevelBorderColors bgWhite" style={{borderRadius:"0.2em"}}>
          <div className="flex11a rMargin">
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={this.props.DatabaseMobx.k_cardIDTeammates}
              p_captureFieldValueObj={this.props.OpenCaptureMobx.c_sbAllocationCaptureFieldValueObj}
              p_fieldValueVerticallyAlignedTF={true}
              p_isSnapshotTF={false}
              p_fieldWidth={fieldWidth}
            />
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em", height:"2.7em"}} />
        </div>
      </div>
    );

    const sbCertificationsComponent = (
      <div className="lrMargin medTopMargin bgLightesterGray medFullPad" style={{width:subContainerWidths}}>
        <div className="">
          <font className={labelFontClass}>
            {"Small Business Certification Allocations"}
          </font>
        </div>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="medTopMargin">
            <CEGeneralReact.CaptureExecFieldAddNewItemsButton
              p_buttonType="blue"
              p_buttonText="Add Small Business Certification Requirement(s)"
              p_addInstructions="Select Small Business Certification(s) to add to this Capture"
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj}
              p_valuesToNotIncludeArray={c_teammatesSBCertificationIDsArray}
              p_tabIndex={undefined}
              f_onAddSelectedItems={this.onadd_sb_certification_type}
            />
          </div>
        }
        <div className="medTopMargin">
          {(c_teammatesSBCertificationAllocationsArrayOfObjs.length === 0) ? (
            <div className="textCenter">
              <font className="fontItalic fontTextLighter">
                {"--No Small Business Certifications have been added for this capture yet--"}
              </font>
            </div>
          ) : (
            c_teammatesSBCertificationAllocationsArrayOfObjs.map((m_sbCertificationAllocationObj) =>
              <SmallBusinessCertificationsAllocationEdit
                key={m_sbCertificationAllocationObj.id}
                p_sbCertificationAllocationObj={m_sbCertificationAllocationObj}
                p_fieldWidth={fieldWidth}
              />
            )
          )}
        </div>
      </div>
    );

    if(o_mediaQueryFlag >= 5) {
      return(
        <div className="displayFlexRow">
          <div className="flex11a displayFlexColumnHc" style={{flexBasis:"100em"}}>
            {totalSBAllocationComponent}
          </div>
          <div className="flex11a displayFlexColumnHc" style={{flexBasis:"100em"}}>
            {sbCertificationsComponent}
          </div>
        </div>
      );
    }

    return(
      <>
        <div className={((c_isMobileTF) ? (undefined) : ("displayFlexColumnHc"))}>
          {totalSBAllocationComponent}
        </div>
        <div style={{marginTop:"2em"}} />
        <div className="displayFlexColumnHc">
          {sbCertificationsComponent}
        </div>
      </>
    );
  }
}));


const SmallBusinessCertificationsAllocationEdit = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class SmallBusinessCertificationsAllocationEdit extends Component { //p_sbCertificationAllocationObj, p_fieldWidth
  onsave_changed_allocation = (i_newValue) => {
    this.props.OpenCaptureMobx.a_teammates_update_sb_certification_allocation(this.props.OpenCaptureMobx.o_openCaptureID, this.props.p_sbCertificationAllocationObj, i_newValue);
  }

  ondelete_sb_certification_allocation = () => {
    this.props.OpenCaptureMobx.a_teammates_delete_sb_certification_allocation(this.props.OpenCaptureMobx.o_openCaptureID, this.props.p_sbCertificationAllocationObj);
  }

  render() {
    const p_sbCertificationAllocationObj = this.props.p_sbCertificationAllocationObj;
    const p_fieldWidth = this.props.p_fieldWidth;

    const k_cardIDTeammates = this.props.DatabaseMobx.k_cardIDTeammates;
    const c_genericPercentDecimal0to100FieldTypeObj = this.props.DatabaseMobx.c_genericPercentDecimal0to100FieldTypeObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const allocationPercent = p_sbCertificationAllocationObj.allocation_percent;
    const fieldDisplay = p_sbCertificationAllocationObj.sbCertificationObj.shortNameAndNamePlainText;

    return(
      <div className="displayFlexRowVc microTopMargin microBottomMargin lrPad microTopPad microBottomPad border bevelBorderColors bgWhite" style={{borderRadius:"0.2em"}}>
        <div className="flex11a rMargin">
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(k_cardIDTeammates, "sbCert" + p_sbCertificationAllocationObj.id)}
            p_fieldDisplayName={fieldDisplay}
            p_fieldTypeObj={c_genericPercentDecimal0to100FieldTypeObj}
            p_valueRaw={allocationPercent}
            p_valueIsEditableTFU={c_userCanEditCaptureCardContentTF}
            p_containerClass="smallTopMargin smallBottomMargin"
            p_fieldClass="fontBlue"
            p_fieldWidth={p_fieldWidth}
            p_valueMaxHeight={undefined}
            p_fieldValueVerticallyAlignedTF={true}
            p_floatingBoxTitle={"Editing " + fieldDisplay}
            f_onSaveChanged={this.onsave_changed_allocation}
          />
        </div>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em"}}>
            <CEGeneralReact.DeleteMenu
              p_message="Are you sure you want to remove this Small Business Certification Type requirement from this capture?"
              p_tabIndex={-1}
              f_onDelete={this.ondelete_sb_certification_allocation}
            />
          </div>
        }
      </div>
    );
  }
}));




const TeammatesSurveysCapabilitiesGapAnalysis = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammatesSurveysCapabilitiesGapAnalysis extends Component { //props:
  constructor(props) {
    super(props);
    this.state = {
      s_editQuestionsPreviewSurveyFloatingBoxIsOpenTF: false,
      s_editingInvitationTextTF: false
    };
  }

  componentDidMount() {
    const o_teammatesSurveysSelectedSurveyID = this.props.OpenCaptureMobx.o_teammatesSurveysSelectedSurveyID;
    const c_teammatesSurveysIDsArray = this.props.OpenCaptureMobx.c_teammatesSurveysIDsArray;
    const c_teammatesSurveysNumSurveys = this.props.OpenCaptureMobx.c_teammatesSurveysNumSurveys;

    //if the currently selected survey is not a valid survey for this capture, reset to have the first survey in the list selected
    if(c_teammatesSurveysNumSurveys > 0) {
      if(!JSFUNC.in_array(o_teammatesSurveysSelectedSurveyID, c_teammatesSurveysIDsArray)) {
        this.props.OpenCaptureMobx.a_teammates_surveys_select_survey(c_teammatesSurveysIDsArray[0]);
      }
    }
  }

  onclick_create_new_survey = (i_newSurveyTitle) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_surveys_create_new_empty_survey_from_new_title(o_openCaptureID, i_newSurveyTitle);
  }

  onclick_import_csv_data_multiinsert_survey_question_names_and_types = (i_csvDataArrayOfArrays, i_runThisFunctionUponSuccess, i_runThisFunctionUponError) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;

    if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      const surveyID = c_teammatesSurveysSelectedExpandedSurveyObj.id;
      const surveyTitle = c_teammatesSurveysSelectedExpandedSurveyObj.title;
      var newQuestionSort = (c_teammatesSurveysSelectedExpandedSurveyObj.questionsArrayOfObjs.length + 1);
      if(i_csvDataArrayOfArrays.length > 0) {
        var newSortValuesArray = [];
        var newQuestionTypeIDsArray = [];
        var newQuestionNamesArray = [];
        for(let csvDataRowArray of i_csvDataArrayOfArrays) {
          var csvStringQuestionName = csvDataRowArray[0];
          var csvStringQuestionTypeID = csvDataRowArray[1];

          var newQuestionTypeID = 2; //default assignment of Scale0to5
          if(csvStringQuestionTypeID === "1") { newQuestionTypeID = 1; }
          else if(csvStringQuestionTypeID === "3") { newQuestionTypeID = 3; }
          else if(csvStringQuestionTypeID === "4") { newQuestionTypeID = 4; }

          newSortValuesArray.push(newQuestionSort);
          newQuestionTypeIDsArray.push(newQuestionTypeID);
          newQuestionNamesArray.push(csvStringQuestionName);

          newQuestionSort++;
        }

        this.props.OpenCaptureMobx.a_teammates_surveys_insert_csv_imported_new_questions(o_openCaptureID, surveyID, surveyTitle, newSortValuesArray, newQuestionTypeIDsArray, newQuestionNamesArray, i_runThisFunctionUponSuccess, i_runThisFunctionUponError);
      }
    }
  }

  onopenclose_import_survey_questions_floating_box_tf = (i_openTrueClosedFalse) => {
    this.props.OpenCaptureMobx.a_teammates_surveys_edit_questions_set_import_questions_floating_box_is_open_tf(i_openTrueClosedFalse);
  }

  reset_state_close_questions_and_invitation_edits = () => {
    this.setState({
      s_editQuestionsPreviewSurveyFloatingBoxIsOpenTF: false,
      s_editingInvitationTextTF: false
    });
    this.props.OpenCaptureMobx.a_teammates_surveys_edit_questions_set_add_new_question_floating_box_is_open_tf(false);
  }

  onclick_add_question_to_survey = () => {
    this.setState({
      s_editQuestionsPreviewSurveyFloatingBoxIsOpenTF: false,
      s_editingInvitationTextTF: false
    });
    this.props.OpenCaptureMobx.a_teammates_surveys_edit_questions_set_add_new_question_floating_box_is_open_tf(true);
  }

  onchange_surveys_tab = (i_newValue) => {
    this.props.OpenCaptureMobx.a_teammates_surveys_select_capabilities_gap_analysis_subtab(i_newValue);
    this.reset_state_close_questions_and_invitation_edits();
  }

  onconfirm_delete_all_survey_questions = () => {
    this.props.OpenCaptureMobx.a_teammates_surveys_delete_all_questions_from_currently_selected_survey();
  }

  onclick_close_add_question_to_survey_floating_box = () => {
    this.props.OpenCaptureMobx.a_teammates_surveys_edit_questions_set_add_new_question_floating_box_is_open_tf(false);
  }

  onclick_open_preview_survey_floating_box = () => {
    this.setState({s_editQuestionsPreviewSurveyFloatingBoxIsOpenTF:true});
  }

  onclick_close_preview_survey_floating_box = () => {
    this.setState({s_editQuestionsPreviewSurveyFloatingBoxIsOpenTF:false});
  }

  onperform_ffs_action = (i_actionText, i_descriptionText) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const k_cardIDDocuments = this.props.DatabaseMobx.k_cardIDDocuments;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureReact - TeammatesSurveysCapabilitiesGapAnalysis", "onperform_ffs_action", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_changelog_details(o_openCaptureID, k_cardIDDocuments, "tsffs", i_actionText, i_descriptionText);
    C_CallPhpTblUID.execute();
  }

  onclick_edit_invitation_text = () => {
    this.setState({s_editingInvitationTextTF:true});
  }

  onclick_close_edit_invitation_text = () => {
    this.setState({s_editingInvitationTextTF:false});
  }

  render() {
    const s_editQuestionsPreviewSurveyFloatingBoxIsOpenTF = this.state.s_editQuestionsPreviewSurveyFloatingBoxIsOpenTF;
    const s_editingInvitationTextTF = this.state.s_editingInvitationTextTF;

    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab = this.props.OpenCaptureMobx.o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab;
    const o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF = this.props.OpenCaptureMobx.o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF;
    const c_captureName = this.props.OpenCaptureMobx.c_captureName;
    const c_teammatesSurveysArrayOfObjs = this.props.OpenCaptureMobx.c_teammatesSurveysArrayOfObjs;
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const numSurveys = c_teammatesSurveysArrayOfObjs.length;
    const isOnlySurveyTF = (numSurveys === 1);
    const selectedExpandedSurveyObjIsSetTF = (c_teammatesSurveysSelectedExpandedSurveyObj !== undefined);

    var numSurveyQuestions = 0;
    var numSurveyDocuments = 0;
    if(selectedExpandedSurveyObjIsSetTF) {
      numSurveyQuestions = c_teammatesSurveysSelectedExpandedSurveyObj.questionsArrayOfObjs.length;
      numSurveyDocuments = c_teammatesSurveysSelectedExpandedSurveyObj.fileFolderSystemMapOfMaps.size;
    }

    const importSurveyQuestionsFromCsvInstructionsHtml = (
      <CEGeneralReact.BulletList
        p_linesArray={["The first column of the csv will be the text of each new Question", "The second column will be numbers 1-4 for each question type (1 - Yes/No, 2 - Scale 0-5, 3 - Text Response, 4 - Instructions Block)", "If no second column is provided, every imported question becomes the default Scale 0-5 type"]}
        p_fontClass="fontItalic fontTextLight"
        p_dashFontClass="fontTextLight"
      />
    );

    return(
      <>
        <div className="flex11a displayFlexRow">
          <div className="flex11a displayFlexColumn borderR1bbb" style={{flexBasis:"100em", minWidth:"16em"}}>
            <div className="flex00a" style={{borderBottom:"solid 1px #888", background:"#678", padding:"0.6em 1.5em"}}>
              <CEGeneralReact.TabsList
                p_tabDbNamesArray={["surveyResultsMatrix", "editSurveyQuestions", "uploadSurveyDocuments", "invitationText"]}
                p_tabDisplayNamesArray={["Survey Results Matrix", "Edit Questions (" + numSurveyQuestions + ")", "Attach Documents (" + numSurveyDocuments + ")", "Invitation Text"]}
                p_selectedTabDbName={o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab}
                p_tabHeight="1.6em"
                p_textMaxHeight="1.5em"
                p_tabWidth={undefined}
                p_selectedBgClass="bgGoldGradient"
                p_selectedFontClass="fontBold"
                p_unselectedBgClass="bgLightGrayGradient"
                p_unselectedFontClass="fontBold fontTextLight"
                p_rowFlexWrapTF={true}
                p_verticalTabsTF={true}
                p_borderRadiusClass="borderRadius10"
                f_onSelect={this.onchange_surveys_tab}
              />
            </div>
            <div className="flex11a displayFlexColumn bgLighterGray">
              <div className="flex00a displayFlexColumnHcVc tbPad textCenter">
                <font className="font13 fontBold fontTextLight">
                  {"Surveys"}
                </font>
              </div>
              {(c_userCanEditCaptureCardContentTF) &&
                <div className="flex00a displayFlexColumnHcVc medBottomPad">
                  <CEGeneralReact.CaptureExecFieldAddNewItemsButton
                    p_buttonType="blue"
                    p_buttonText="Create New Survey"
                    p_addInstructions="Enter a Title for this new Teammate Survey"
                    p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                    f_onAddSelectedItems={this.onclick_create_new_survey}
                  />
                </div>
              }
              <div className="flex11a yScroll yScrollBottomPad">
                {c_teammatesSurveysArrayOfObjs.map((m_teammatesSurveyObj, m_index) =>
                  <TeammatesSurveysSurveyButton
                    p_teammatesSurveyObj={m_teammatesSurveyObj}
                    p_isOnlyItemTF={isOnlySurveyTF}
                    p_isLastItemTF={(m_index === (numSurveys - 1))}
                    f_onClick={this.reset_state_close_questions_and_invitation_edits}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex11a displayFlexColumn bgLightestGray" style={{flexBasis:"600em"}}>
            {(!selectedExpandedSurveyObjIsSetTF) ? (
              <CEGeneralReact.EmptyScreenGray>
                {((numSurveys === 0) ? ("No Teammate Surveys have been created yet for this capture") : ("Select a Teammate Survey on the left to edit the questions on the survey"))}
              </CEGeneralReact.EmptyScreenGray>
            ) : (
              <>
                {(o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab === "surveyResultsMatrix") &&
                  <TeammatesSurveysResultsMatrix />
                }
                {(o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab === "editSurveyQuestions") &&
                  <>
                    {(c_userCanEditCaptureCardContentTF) &&
                      <div className="flex00a displayFlexRow smallTopMargin lrMedPad">
                        <div className="flex11a" />
                        <div className="flex00a displayFlexRow border1bbb bgLighterGray">
                          <div className="flex00a displayFlexRowVc lrMedMargin">
                            <TeammatesSurveysCopyQuestionsFromExistingCaptureButtonWithFloatingBox />
                          </div>
                          <div className="flex00a displayFlexRowVc lrMedMargin">
                            <CEGeneralReact.ImportDataFromCsvFileButtonWithFloatingBoxInterface
                              p_buttonLabel="Import Questions from .csv File"
                              p_importTitle={"Add Survey Questions imported from .csv file to Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "'"}
                              p_instructionsHtmlOrUndefined={importSurveyQuestionsFromCsvInstructionsHtml}
                              p_existingValuesArray={[]}
                              p_maxNumColumnsInCsvOrUndefined={2}
                              p_maxNumColumnsToShow={2}
                              p_combineAll2DDataInto1DTF={false}
                              p_markAndPreventDuplicateEntriesTF={false}
                              f_onClickImportCsvData={this.onclick_import_csv_data_multiinsert_survey_question_names_and_types}
                              f_onOpenCloseFloatingBoxTF={this.onopenclose_import_survey_questions_floating_box_tf}
                            />
                          </div>
                          <div className="flex00a displayFlexColumnHcVc" style={{width:"2em"}}>
                            <CEGeneralReact.VerticalDotsMenu
                              p_menuItemsArrayOfObjs={[
                                {
                                  displayName: "Delete All Survey Questions",
                                  confirmType: "confirmDelete",
                                  confirmTitle: "Delete All Survey Questions",
                                  confirmMessage: "Are you sure you want to delete all Questions in Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "'?",
                                  functionOnClickConfirmButton: this.onconfirm_delete_all_survey_questions
                                }
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    <div className="flex00a microTopPad smallBottomPad textCenter">
                      <font className="font12 fontBold fontTextLighter">
                        {"Questions on Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "'"}
                      </font>
                    </div>
                    <div className="flex00a displayFlexRowHcVc medBottomPad">
                      {(c_userCanEditCaptureCardContentTF) &&
                        <div className="flex00a lrMedPad">
                          <CEGeneralReact.CEButton
                            p_type="add"
                            p_text="Add Question to Survey"
                            f_onClick={this.onclick_add_question_to_survey}
                          />
                        </div>
                      }
                      <div
                        className="flex00a lrMedPad cursorPointer textCenter hoverFontUnderline"
                        onClick={this.onclick_open_preview_survey_floating_box}>
                        <font className="fontBlue">
                          {"Preview Survey"}
                        </font>
                      </div>
                      {(s_editQuestionsPreviewSurveyFloatingBoxIsOpenTF) &&
                        <TeammatesSurveysEditQuestionsPreviewSurveyFloatingBox
                          p_captureID={o_openCaptureID}
                          p_captureName={c_captureName}
                          p_surveyID={c_teammatesSurveysSelectedExpandedSurveyObj.id}
                          p_surveyTitle={c_teammatesSurveysSelectedExpandedSurveyObj.title}
                          p_surveyDocumentsMapOfMaps={c_teammatesSurveysSelectedExpandedSurveyObj.fileFolderSystemMapOfMaps}
                          f_onClickClose={this.onclick_close_preview_survey_floating_box}
                        />
                      }
                    </div>
                    {(numSurveyQuestions === 0) ? (
                      <CEGeneralReact.EmptyScreenGray>
                        {"No Questions have been added to Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "' yet"}
                      </CEGeneralReact.EmptyScreenGray>
                    ) : (
                      <div className="flex11a lrBigPad yScroll yScrollBottomPad">
                        {c_teammatesSurveysSelectedExpandedSurveyObj.questionsArrayOfObjs.map((m_questionObj, m_index) =>
                          <TeammatesSurveysEditQuestionItem
                            p_questionObj={m_questionObj}
                            p_isOnlyItemTF={(numSurveyQuestions === 1)}
                            p_isLastItemTF={(m_index === (numSurveyQuestions - 1))}
                          />
                        )}
                      </div>
                    )}
                  </>
                }
                {(o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab === "uploadSurveyDocuments") &&
                  <>
                    {(c_userCanEditCaptureCardContentTF) &&
                      <div className="flex00a displayFlexRow smallTopMargin">
                        {(o_mediaQueryFlag >= 4) &&
                          <div className="flex11a" style={{flexBasis:"100em"}} />
                        }
                        <div className="flex11a lrMedMargin border1bbb borderRadius05 bgLighterGray tbPad lrBigPad" style={{flexBasis:"100em"}}>
                          <TeammatesSurveysDocumentsSubtabSelectFilesFromDocumentsCard />
                        </div>
                      </div>
                    }
                    <div className="flex11a displayFlexColumn medFullMargin border1bbb">
                      <CEGeneralReact.FileFolderSystem
                        p_tblName="tbl_c_teammates_surveys_filefoldersystem"
                        p_tblMapOfMaps={c_teammatesSurveysSelectedExpandedSurveyObj.fileFolderSystemMapOfMaps}
                        p_tblFilterValuesObj={{capture_id:o_openCaptureID, survey_id:c_teammatesSurveysSelectedExpandedSurveyObj.id}}
                        p_title={"Documents Available to Attach with Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "'"}
                        p_defaultXmlType={undefined}
                        p_allowedExtsArray={undefined}
                        p_disableUploadFilesTF={false}
                        p_disableCreateOnlineLinkDocsTF={false}
                        p_disableCreateFoldersTF={true}
                        p_viewWideTrueNarrowFalse={false}
                        p_navigateToFolderID={undefined}
                        f_onPerformFFSAction={this.onperform_ffs_action}
                        f_onNavigateToFolder={undefined}
                      />
                    </div>
                  </>
                }
                {(o_teammatesSurveysSelectedCapabilitiesGapAnalysisSubtab === "invitationText") &&
                  <>
                    <div className="flex00a tbPad textCenter">
                      <font className="font12 fontBold fontTextLighter">
                        {"Invitation Text in Email Sent for Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "'"}
                      </font>
                    </div>
                    <div className="flex11a medFullPad yScroll">
                      {(s_editingInvitationTextTF) ? (
                        <TeammatesSurveysInvitationTextEdit
                          p_teammatesSurveysSelectedExpandedSurveyObj={c_teammatesSurveysSelectedExpandedSurveyObj}
                          f_onClose={this.onclick_close_edit_invitation_text}
                        />
                      ) : (
                        <>
                          {(c_userCanEditCaptureCardContentTF) &&
                            <div className="displayFlexColumnHcVc smallBottomMargin">
                              <CEGeneralReact.CEButton
                                p_type="add"
                                p_text="Edit Invitation Text"
                                f_onClick={this.onclick_edit_invitation_text}
                              />
                            </div>
                          }
                          <div className="medFullPad bgAlmostWhite border1ddd">
                            <font className="">
                              {c_teammatesSurveysSelectedExpandedSurveyObj.invitation_text}
                            </font>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                }
              </>
            )}
          </div>
        </div>
        {(o_teammatesSurveysEditQuestionsAddNewQuestionFloatingBoxIsOpenTF) &&
          <TeammatesSurveysAddNewQuestionFloatingBox
            f_onClickClose={this.onclick_close_add_question_to_survey_floating_box}
          />
        }
      </>
    );
  }
}));

const TeammatesSurveysAddNewQuestionFloatingBox = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammatesSurveysAddNewQuestionFloatingBox extends Component { //props: f_onClickClose
  constructor(props) {
    super(props);
    this.state = {
      s_addNewQuestionFloatingBoxIsWorkingTF: false,
      s_addNewQuestionFloatingBoxInsertErrorTF: false,
      s_newQuestionTypeID: 1,
      s_newQuestionName: ""
    };
  }

  onselect_new_question_type = (i_newValue) => {
    this.setState({s_newQuestionTypeID:i_newValue});
  }

  onchange_new_question_name = (i_newValue) => {
    this.setState({s_newQuestionName:i_newValue});
  }

  onclick_confirm_add_question_to_survey = () => {
    const s_newQuestionTypeID = this.state.s_newQuestionTypeID;
    const s_newQuestionName = this.state.s_newQuestionName;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;

    if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      const surveyID = c_teammatesSurveysSelectedExpandedSurveyObj.id;
      const surveyTitle = c_teammatesSurveysSelectedExpandedSurveyObj.title;
      const newQuestionSort = (c_teammatesSurveysSelectedExpandedSurveyObj.questionsArrayOfObjs.length + 1);

      const functionOnBeginWork = () => {
        this.setState({s_addNewQuestionFloatingBoxIsWorkingTF:true});
      };

      const functionOnSuccess = () => {
        if(JSFUNC.is_function(this.props.f_onClickClose)) {
          this.props.f_onClickClose();
        }
      };

      const functionOnError = () => {
        this.setState({
          s_addNewQuestionFloatingBoxIsWorkingTF: false,
          s_addNewQuestionFloatingBoxInsertErrorTF: true
        });
      };

      this.props.OpenCaptureMobx.a_teammates_surveys_add_new_question(o_openCaptureID, surveyID, surveyTitle, newQuestionSort, s_newQuestionTypeID, s_newQuestionName, functionOnBeginWork, functionOnSuccess, functionOnError);
    }
  }

  render() {
    const s_addNewQuestionFloatingBoxIsWorkingTF = this.state.s_addNewQuestionFloatingBoxIsWorkingTF;
    const s_addNewQuestionFloatingBoxInsertErrorTF = this.state.s_addNewQuestionFloatingBoxInsertErrorTF;
    const s_newQuestionTypeID = this.state.s_newQuestionTypeID;
    const s_newQuestionName = this.state.s_newQuestionName;

    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;

    if(c_teammatesSurveysSelectedExpandedSurveyObj === undefined) {
      return(null);
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title={"Add Question to Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "'"}
        p_cancelConfirmationMessage={((s_newQuestionName === "") ? (undefined) : ("Are you sure you want to cancel adding this new Survey Question?"))}
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex11a displayFlexColumn medFullPad">
          <div className="flex00a microBottomMargin">
            <font className="fontItalic fontTextLight">
              {"Question Type"}
            </font>
          </div>
          <div className="flex00a hugeBottomMargin">
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={[1,2,3,4]}
              p_tabDisplayNamesArray={["Yes/No", "Scale 0-5", "Text Response", "Survey Instructions Block"]}
              p_selectedTabDbName={s_newQuestionTypeID}
              p_tabHeight="2.9em"
              p_textMaxHeight="2.8em"
              p_tabWidth="10em"
              p_unselectedFontClass="fontBold fontTextLight"
              p_unselectedBgClass="bgLighterGrayGradient"
              p_selectedBgClass="bgOrangeGradient"
              p_rowFlexWrapTF={true}
              p_tabSpacing="0.4em"
              f_onSelect={this.onselect_new_question_type}
            />
          </div>
          <div className="flex00a microBottomMargin">
            <font className="fontItalic fontTextLight">
              {"Question Text"}
            </font>
          </div>
          <div className="flex11a">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
              p_valueRaw={s_newQuestionName}
              p_tabIndex={1}
              p_focusTF={true}
              f_onChangeOrOnSelect={this.onchange_new_question_name}
            />
          </div>
        </div>
        <div className="flex00a displayFlexColumnHcVc tbMedPad borderT1bbb">
          {(s_addNewQuestionFloatingBoxIsWorkingTF) ? (
            <CEGeneralReact.LoadingAnimation />
          ) : (
            <>
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text="Add Question"
                p_tabIndex={2}
                f_onClick={this.onclick_confirm_add_question_to_survey}
              />
              {(s_addNewQuestionFloatingBoxInsertErrorTF) &&
                <CEGeneralReact.ErrorText p_class="smallTopMargin" p_text="Failed to add new Question to database, please try again." />
              }
            </>
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const TeammatesSurveysEditQuestionsPreviewSurveyFloatingBox = inject("OpenCaptureMobx")(observer(
class TeammatesSurveysEditQuestionsPreviewSurveyFloatingBox extends Component { //props: p_captureID, p_captureName, p_surveyID, p_surveyTitle, p_surveyDocumentsMapOfMaps, f_onClickClose
  render() {
    const p_captureID = this.props.p_captureID;
    const p_captureName = this.props.p_captureName;
    const p_surveyID = this.props.p_surveyID;
    const p_surveyTitle = this.props.p_surveyTitle;
    const p_surveyDocumentsMapOfMaps = this.props.p_surveyDocumentsMapOfMaps;

    const teammateIDOrUndefined = undefined; //undefined teammate means there will be no answers filled in
    const previewQuestionResponsesArrayOfObjs = this.props.OpenCaptureMobx.teammate_surveys_build_question_responses_arrayOfObjs_from_capture_id_survey_id_teammate_id(p_captureID, p_surveyID, teammateIDOrUndefined);

    const numSurveyDocuments = p_surveyDocumentsMapOfMaps.size;

    var surveyDocumentNamesArray = [];
    var surveyDocumentFileLocsArray = [];
    for(let surveyDocumentMap of p_surveyDocumentsMapOfMaps.values()) {
      surveyDocumentNamesArray.push(surveyDocumentMap.get("display_name"));
      surveyDocumentFileLocsArray.push(surveyDocumentMap.get("file_loc"));
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="teammateSurvey"
        p_title={"Preview of Teammate Survey '" + p_surveyTitle + "'"}
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex11a yScroll" style={{paddingLeft:"4em", paddingRight:"4em", paddingBottom:"4em"}}>
          <div className="hugeTopPad textCenter">
            <font className="fontItalic fontTextLight">
              {"Survey"}
            </font>
          </div>
          <div className="textCenter">
            <font className="font15 fontBold fontTextLight">
              {p_surveyTitle}
            </font>
          </div>
          <div className="bigTopPad textCenter">
            <font className="fontItalic fontTextLight">
              {"Opportunity Name"}
            </font>
          </div>
          <div className="hugeBottomPad textCenter">
            <font className="font14 fontBold fontTextLight">
              {p_captureName}
            </font>
          </div>
          {(numSurveyDocuments > 0) &&
            <div className="hugeBottomPad displayFlexColumnHcVc">
              <div className="border1bbb bgLighterBlue medFullPad" style={{width:"50%"}}>
                <div className="bigBottomPad textCenter">
                  <font className="font11 fontDarkBlue">
                    {numSurveyDocuments + " " + JSFUNC.plural(numSurveyDocuments, "File", "Files") + " Attached to this Survey for Download"}
                  </font>
                </div>
                {surveyDocumentNamesArray.map((m_surveyDocumentName, m_index) =>
                  <div className="displayFlexRow microBottomPad">
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"10em"}}>
                      <CEGeneralReact.CEButton
                        p_type="add"
                        p_text="Download File"
                        p_fontClass="font10"
                        f_onClick={() => { JSPHP.check_file_exists_on_server_call_download_php_and_offer_download("uploads", surveyDocumentFileLocsArray[m_index], m_surveyDocumentName, undefined, "OpenCaptureMobx - TeammatesSurveysEditQuestionsPreviewSurveyFloatingBox - Preview Survey - Download Document"); }}
                      />
                    </div>
                    <div className="flex11a displayFlexRowVc">
                      <font className="fontBold fontTextLighter">
                        {m_surveyDocumentName}
                      </font>
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
          {previewQuestionResponsesArrayOfObjs.map((m_questionResponseObj) =>
            <TeammatesFloatingBoxSurveyQuestion
              p_questionResponseObj={m_questionResponseObj}
              p_canEditAnswersTF={true}
              p_tabIndex={undefined}
              f_onChangeTextResponse={undefined}
              f_onSaveTextResponse={undefined}
            />
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));



const TeammatesSurveysResultsMatrix = inject("CaptureExecMobx", "OpenCaptureMobx", "UserMobx")(observer(
class TeammatesSurveysResultsMatrix extends Component { //props:
  onclick_open_send_survey_to_multiple_teammates_floating_box = () => {
    this.props.OpenCaptureMobx.a_teammates_surveys_survey_results_matrix_set_prepare_survey_to_multiple_teammates_floating_box_is_open_tf(true);
  }

  onclick_close_send_survey_to_multiple_teammates_floating_box = () => {
    this.props.OpenCaptureMobx.a_teammates_surveys_survey_results_matrix_set_prepare_survey_to_multiple_teammates_floating_box_is_open_tf(false);
  }

  onchange_results_matrix_filter_multiselect = (i_resultsMatrixFilterIDsComma) => {
    this.props.OpenCaptureMobx.a_teammates_surveys_results_matrix_set_filter_ids_comma(i_resultsMatrixFilterIDsComma);
  }

  onclick_export_survey_matrix_to_excel_xml = () => {
    this.props.OpenCaptureMobx.a_teammates_surveys_survey_results_matrix_export_survey_matrix_to_excel_xml();
  }

  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF = this.props.OpenCaptureMobx.o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF;
    const o_teammatesSurveysResultsMatrixFilterIDsComma = this.props.OpenCaptureMobx.o_teammatesSurveysResultsMatrixFilterIDsComma;
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;
    const c_teammatesSurveysResultsMatrixObj = this.props.OpenCaptureMobx.c_teammatesSurveysResultsMatrixObj;
    const c_teammatesSurveysResultsMatrixMultiSelectFilterFieldTypeObj = this.props.OpenCaptureMobx.c_teammatesSurveysResultsMatrixMultiSelectFilterFieldTypeObj;
    const c_teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs = this.props.OpenCaptureMobx.c_teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    if(c_teammatesSurveysSelectedExpandedSurveyObj === undefined) {
      return(
        <div className="flex11a displayFlexColumnHcVc bgWhite">
          <div className="fontItalic fontTextLight">
            {"Select a Survey on the left to view the combined results from all teammates"}
          </div>
        </div>
      );
    }

    const allFilterOptionsSelectedTF = c_teammatesSurveysResultsMatrixObj.allFilterOptionsSelectedTF;
    const usAndTeammatesArrayOfObjs = c_teammatesSurveysResultsMatrixObj.usAndTeammatesArrayOfObjs;
    const questionResponsesArrayOfObjs = c_teammatesSurveysResultsMatrixObj.questionResponsesArrayOfObjs;
    const atLeast1TeammateHasAnswered1QuestionTF = c_teammatesSurveysResultsMatrixObj.atLeast1TeammateHasAnswered1QuestionTF;
    const teamStrengthScore = c_teammatesSurveysResultsMatrixObj.teamStrengthScore;
    const teamStrengthBgColor = c_teammatesSurveysResultsMatrixObj.teamStrengthBgColor;

    const questionMaxAnswerColumnWidthEm = ((c_isMobileOrTabletTF) ? (0.8) : (1));
    const questionTextColumnWidthEm = ((c_isMobileOrTabletTF) ? (15) : (28));
    const questionMaxAnswerColumnWidth = questionMaxAnswerColumnWidthEm + "em";
    const questionTextColumnWidth = questionTextColumnWidthEm + "em";
    const questionMaxAnswerAndTextColumnWidth = (questionMaxAnswerColumnWidthEm + questionTextColumnWidthEm) + "em";
    const teammateColumnWidth = "4em";
    const surveyDateRowHeight = "1.5em";
    const questionRowHeight = "3.4em";
    const questionTextMaxHeight = "3.1em";

    const surveyMatrixMenuArrayOfObjs = [
      {
        displayName: "Export Survey Matrix to Excel .xml",
        functionOnClickMenuItem: this.onclick_export_survey_matrix_to_excel_xml
      }
    ]

    return(
      <>
        <div className="flex00a displayFlexRowVc flexWrap tbMicroPad">
          <div className="flex00a lrBigMargin" style={{flexBasis:"20em"}}>
            <div className="">
              <font className="fontItalic fontTextLighter">
                {"Filter Questions by best response"}
              </font>
            </div>
            <div className="microTopMargin">
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={c_teammatesSurveysResultsMatrixMultiSelectFilterFieldTypeObj}
                p_valueRaw={o_teammatesSurveysResultsMatrixFilterIDsComma}
                p_selectDisplayMaskOverwrite={((allFilterOptionsSelectedTF) ? ("--Display All Questions--") : (undefined))}
                f_onChangeOrOnSelect={this.onchange_results_matrix_filter_multiselect}
              />
            </div>
          </div>
          <div
            className="flex00a displayFlexRowHcVc lrBigMargin border1ddd bgLightestBlue tbMicroPad"
            style={{flexBasis:"14em"}}
            title={"Your calculated 'Team Strength' for this Survey is " + teamStrengthScore + "\nThis represents the highest score (0-5) given by any teammate on each question, averaged together, where the highest score of 5 would mean all capabilities/gaps are met through this combination of teammates"}>
            <div className="flex00a">
              <font className="font11 fontBold fontItalic fontTextLight">
                {"Team Strength"}
              </font>
            </div>
            <div className="flex00a">
              <CEGeneralReact.SvgTriangleWithNumber p_sizeEm={3} p_color={teamStrengthBgColor} p_number={teamStrengthScore} />
            </div>
          </div>
          {(c_userCanEditCaptureCardContentTF) &&
            <div className="flex00a lrBigMargin displayFlexColumnHcVc" style={{flexBasis:"21.5em"}}>
              {(questionResponsesArrayOfObjs.length > 0) &&
                <div className="displayFlexColumnHcVc">
                  <CEGeneralReact.CEButton
                    p_type="add"
                    p_text="Prepare Survey to Multiple Teammates"
                    f_onClick={this.onclick_open_send_survey_to_multiple_teammates_floating_box}
                  />
                </div>
              }
            </div>
          }
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
            <CEGeneralReact.VerticalDotsMenu p_menuItemsArrayOfObjs={surveyMatrixMenuArrayOfObjs} />
          </div>
        </div>
        <div className="smallTopMargin microBottomMargin lrPad">
          <font className="font11 fontBold fontTextLighter">
            {"Results Matrix for Survey '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "'"}
          </font>
        </div>
        <div className="flex11a xyScroll hugeBottomPad lrPad">
          <div className="displayFlexRow" style={{height:"10em"}}>
            <div className="flex00a displayFlexRow borderR1bbb" style={{flexBasis:questionMaxAnswerAndTextColumnWidth}}>
              <div className="flex11a" />
              <div className="flex00a displayFlexColumnHcVc" style={{marginRight:"0.8em"}}>
                <div className="displayFlexColumn border1ddd borderRadius05 bgAlmostWhite tbMicroPad lrPad" style={{width:"14em"}}>
                  <div className="flex00a microBottomMargin textCenter">
                    <font className="font09 fontBold fontItalic fontTextLighter">
                      {"Survey Status Colors"}
                    </font>
                  </div>
                  {c_teammatesSurveysResultsMatrixStatusLegendsArrayOfObjs.map((m_responseColorObj) =>
                    <div className="displayFlexRowVc">
                      <div className="flex00a border bevelBorderColors borderRadius05" style={{flexBasis:"1.5em", height:"0.8em", background:"#" + m_responseColorObj.responseBgColor}} />
                      <div className="flex11a lrPad">
                        <font className="font09 fontItalic fontTextLighter">
                          {m_responseColorObj.responseStatus}
                        </font>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {usAndTeammatesArrayOfObjs.map((m_teammateObj) =>
              <TeammatesSurveysResultsMatrixTeammateOpenFullSurveyShell
                p_teammateObj={m_teammateObj}
                p_teammatesSurveysSelectedExpandedSurveyObj={c_teammatesSurveysSelectedExpandedSurveyObj}
                p_divClass={"flex00a positionRelative borderR1bbb borderT1bbb bgWhite hoverLighterBlueGradient " + ((c_userCanEditCaptureCardContentTF) ? ("cursorPointer") : (""))}
                p_styleObj={{flexBasis:teammateColumnWidth}}>
                <div className="positionAbsolute" style={{transform:"rotate(280deg)", left:"-2.5em", top:"4em", width:"9.5em"}}>
                  <LibraryReact.Nowrap p_fontClass={"fontBold " + ((m_teammateObj.isUsTF) ? ("fontBlue") : (""))}>
                    {m_teammateObj.name}
                  </LibraryReact.Nowrap>
                </div>
              </TeammatesSurveysResultsMatrixTeammateOpenFullSurveyShell>
            )}
          </div>
          <div className="displayFlexRow" style={{height:surveyDateRowHeight}}>
            <SRMDateLabel p_text="Survey Status" p_width={questionMaxAnswerAndTextColumnWidth} />
            {usAndTeammatesArrayOfObjs.map((m_teammateObj) =>
              <div className="flex00a displayFlexColumn borderT1bbb borderR1bbb bgWhite" style={{flexBasis:teammateColumnWidth}} title={"Status: " + m_teammateObj.selectedSurveyResponseTimeObj.responseStatus + "\nContact Person(s) to Receive Survey: " + m_teammateObj.surveyContactPersonsMaskPlainText}>
                <div className="flex11a displayFlexColumnHcVc border bevelBorderColors borderRadius05" style={{margin:"0.25em 0.25em", background:"#" + m_teammateObj.selectedSurveyResponseTimeObj.responseBgColor}} />
              </div>
            )}
          </div>
          <div className="displayFlexRow" style={{height:surveyDateRowHeight}}>
            <SRMDateLabel p_text="Sent Date" p_width={questionMaxAnswerAndTextColumnWidth} />
            {usAndTeammatesArrayOfObjs.map((m_teammateObj) =>
              ((m_teammateObj.responseExistsAndSurveyIsSentTF) ? (
                <div className="flex00a displayFlexColumnHcVc borderT1bbb borderR1bbb bgWhite" style={{flexBasis:teammateColumnWidth}} title={"Survey emailed: " + m_teammateObj.selectedSurveyResponseTimeObj.sentDateTimeLocal + " to '" + m_teammateObj.name + "'"}>
                  <font className="">
                    {m_teammateObj.selectedSurveyResponseTimeObj.sentDateTimeLocalShort}
                  </font>
                </div>
              ) : (
                <SRMUnsent p_teammateObj={m_teammateObj} p_width={teammateColumnWidth} />
              ))
            )}
          </div>
          <div className="displayFlexRow" style={{height:surveyDateRowHeight}}>
            <SRMDateLabel p_text="Due Date" p_width={questionMaxAnswerAndTextColumnWidth} />
            {usAndTeammatesArrayOfObjs.map((m_teammateObj) =>
              ((m_teammateObj.responseExistsAndSurveyIsSentTF) ? (
                <div className="flex00a displayFlexColumnHcVc borderT1bbb borderR1bbb bgWhite" style={{flexBasis:teammateColumnWidth}} title={"Survey due: " + m_teammateObj.selectedSurveyResponseTimeObj.dueDateTimeLocal + " from '" + m_teammateObj.name + "'"}>
                  <font className="">
                    {m_teammateObj.selectedSurveyResponseTimeObj.dueDateTimeLocalShort}
                  </font>
                </div>
              ) : (
                <SRMUnsent p_teammateObj={m_teammateObj} p_width={teammateColumnWidth} />
              ))
            )}
          </div>
          <div className="displayFlexRow" style={{height:surveyDateRowHeight}}>
            <SRMDateLabel p_text="First Opened Date" p_width={questionMaxAnswerAndTextColumnWidth} />
            {usAndTeammatesArrayOfObjs.map((m_teammateObj) =>
              ((m_teammateObj.responseExistsAndSurveyIsSentTF) ? (
                <div className="flex00a displayFlexColumnHcVc borderT1bbb borderR1bbb bgWhite" style={{flexBasis:teammateColumnWidth}} title={"'" + m_teammateObj.name + "' " + ((m_teammateObj.selectedSurveyResponseTimeObj.surveyIsFirstOpenedTF) ? ("opened: " + m_teammateObj.selectedSurveyResponseTimeObj.firstOpenedDateTimeLocal) : ("has not yet responded"))}>
                  <font className={((m_teammateObj.selectedSurveyResponseTimeObj.surveyIsFirstOpenedTF) ? ("") : ("font09 fontItalic fontTextLightester"))}>
                    {((m_teammateObj.selectedSurveyResponseTimeObj.surveyIsFirstOpenedTF) ? (m_teammateObj.selectedSurveyResponseTimeObj.firstOpenedDateTimeLocalShort) : ("-"))}
                  </font>
                </div>
              ) : (
                <SRMUnsent p_teammateObj={m_teammateObj} p_width={teammateColumnWidth} />
              ))
            )}
          </div>
          <div className="displayFlexRow" style={{height:surveyDateRowHeight}}>
            <SRMDateLabel p_text="Completed Date" p_width={questionMaxAnswerAndTextColumnWidth} />
            {usAndTeammatesArrayOfObjs.map((m_teammateObj) =>
              ((m_teammateObj.responseExistsAndSurveyIsSentTF) ? (
                <div className="flex00a displayFlexColumnHcVc borderT1bbb borderR1bbb bgWhite" style={{flexBasis:teammateColumnWidth}} title={"'" + m_teammateObj.name + "' " + ((m_teammateObj.selectedSurveyResponseTimeObj.surveyIsCompletedTF) ? ("completed: " + m_teammateObj.selectedSurveyResponseTimeObj.completedDateTimeLocal) : ("has not yet responded"))}>
                  <font className={((m_teammateObj.selectedSurveyResponseTimeObj.surveyIsCompletedTF) ? ("") : ("font09 fontItalic fontTextLightester"))}>
                    {((m_teammateObj.selectedSurveyResponseTimeObj.surveyIsCompletedTF) ? (m_teammateObj.selectedSurveyResponseTimeObj.completedDateTimeLocalShort) : ("-"))}
                  </font>
                </div>
              ) : (
                <SRMUnsent p_teammateObj={m_teammateObj} p_width={teammateColumnWidth} />
              ))
            )}
          </div>
          <div className="displayFlexRow">
            <div className="flex00a borderT1bbb" style={{flexBasis:questionMaxAnswerColumnWidth}} />
            <div className="flex00a borderT1bbb" style={{flexBasis:questionTextColumnWidth}} />
            {usAndTeammatesArrayOfObjs.map((m_teammateObj) =>
              <div className="flex00a borderT1bbb" style={{flexBasis:teammateColumnWidth}} />
            )}
          </div>
          {questionResponsesArrayOfObjs.map((m_questionResponseObj) =>
            (!m_questionResponseObj.typeIsInstructionsBlockTF) &&
            <div className="displayFlexRow" style={{height:questionRowHeight}}>
              <div
                className="flex00a borderB1bbb borderL1bbb borderR1bbb"
                style={{flexBasis:questionMaxAnswerColumnWidth, background:((atLeast1TeammateHasAnswered1QuestionTF && (m_questionResponseObj.maxAnswerColumnBg !== undefined)) ? ("#" + m_questionResponseObj.maxAnswerColumnBg) : (undefined))}}
              />
              <div className="flex00a displayFlexRowVc borderB1bbb borderR1bbb bgWhite lrPad" style={{flexBasis:questionTextColumnWidth}} title={m_questionResponseObj.name}>
                <LibraryReact.MaxHeightWrap p_maxHeight={questionTextMaxHeight} p_fontClass="fontTextLight fontItalic">
                  {m_questionResponseObj.name}
                </LibraryReact.MaxHeightWrap>
              </div>
              {m_questionResponseObj.teammateAnswersArrayOfObjs.map((m_teammateAnswerObj, m_index) =>
                <TeammatesSurveysResultsMatrixCell
                  p_teammatesSurveysSelectedExpandedSurveyObj={c_teammatesSurveysSelectedExpandedSurveyObj}
                  p_questionResponseObj={m_questionResponseObj}
                  p_teammateAnswerObj={m_teammateAnswerObj}
                  p_teammateObj={usAndTeammatesArrayOfObjs[m_index]}
                  p_teammateColumnWidth={teammateColumnWidth}
                />
              )}
            </div>
          )}
        </div>
        {(o_teammatesSurveysResultsMatrixPrepareSurveyToMultipleTeammatesFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="teammateSurvey"
            p_title={"Send Survey to Multiple Teammates '" + c_teammatesSurveysSelectedExpandedSurveyObj.title + "'"}
            f_onClickCancel={this.onclick_close_send_survey_to_multiple_teammates_floating_box}>
            <TeammatesSurveysSendToMultipleTeammatesFloatingBoxContents
              p_teammatesSurveysSelectedExpandedSurveyObj={c_teammatesSurveysSelectedExpandedSurveyObj}
              p_teammatesSurveysResultsMatrixObj={c_teammatesSurveysResultsMatrixObj}
              f_onClickClose={this.onclick_close_send_survey_to_multiple_teammates_floating_box}
            />
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));

function SRMDateLabel(props) { //props: p_text, p_width
  return(
    <div className="flex00a displayFlexColumnVc borderL1bbb borderT1bbb borderR1bbb bgWhite lrPad textRight" style={{flexBasis:props.p_width}}>
      <font className="fontBold fontTextLight">
        {props.p_text}
      </font>
    </div>
  )
}

function SRMUnsent(props) { //props: p_teammateObj, p_width
  return(
    <div
      className="flex00a displayFlexColumnHcVc borderT1bbb borderR1bbb bgWhite"
      style={{flexBasis:props.p_width}}
      title={"Survey not yet emailed to '" + props.p_teammateObj.name + "'"}>
      <font className="font09 fontItalic fontTextLightester">
        {"unsent"}
      </font>
    </div>
  );
}

const TeammatesSurveysResultsMatrixTeammateOpenFullSurveyShell = inject("OpenCaptureMobx", "UserMobx")(observer(
class TeammatesSurveysResultsMatrixTeammateOpenFullSurveyShell extends Component { //props: p_teammateObj, p_teammatesSurveysSelectedExpandedSurveyObj, p_divClass, p_styleObj, children
  onclick_open_teammate_full_survey = () => {
    const p_teammateObj = this.props.p_teammateObj;
    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;
    this.props.OpenCaptureMobx.a_teammates_set_floating_box_survey_teammate_id_and_survey_id(p_teammateObj.id, p_teammatesSurveysSelectedExpandedSurveyObj.id);
  }

  render() {
    const p_teammateObj = this.props.p_teammateObj;
    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;
    const p_divClass = this.props.p_divClass;
    const p_styleObj = this.props.p_styleObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <div
        className={p_divClass}
        style={p_styleObj}
        title={"Teammate '" + p_teammateObj.name + "'\n[Click to send survey or view responses]"}
        onClick={((c_userCanEditCaptureCardContentTF) ? (this.onclick_open_teammate_full_survey) : (undefined))}>
        {this.props.children}
      </div>
    );
  }
}));

const TeammatesSurveysResultsMatrixCell = inject("OpenCaptureMobx")(observer(
class TeammatesSurveysResultsMatrixCell extends Component { //props: p_teammatesSurveysSelectedExpandedSurveyObj, p_questionResponseObj, p_teammateAnswerObj, p_teammateObj, p_teammateColumnWidth
  constructor(props) {
    super(props);
    this.state = {
      s_viewCommentFloatingBoxIsOpenTF: false
    };
  }

  onclick_cell = () => {
    this.setState({s_viewCommentFloatingBoxIsOpenTF:true});
  }

  onclick_close_view_comment_floating_box = () => {
    this.setState({s_viewCommentFloatingBoxIsOpenTF:false});
  }

  render() {
    const viewCommentFloatingBoxIsOpenTF = this.state.s_viewCommentFloatingBoxIsOpenTF;

    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;
    const p_questionResponseObj = this.props.p_questionResponseObj;
    const teammateAnswerObj = this.props.p_teammateAnswerObj;
    const p_teammateObj = this.props.p_teammateObj;
    const teammateColumnWidth = this.props.p_teammateColumnWidth;

    if(teammateAnswerObj === undefined) {
      return(
        <div className="flex00a displayFlexColumnHcVc borderB1bbb borderR1bbb bgWhite" style={{flexBasis:teammateColumnWidth}} />
      );
    }

    var ynOrScale05HasCommentTF = ((p_questionResponseObj.typeIsYesNoTF || p_questionResponseObj.typeIsScale05TF) && (teammateAnswerObj.comment !== ""));

    var cellDisplayClass = "displayFlexColumnHcVc";
    var cellBackground = "#fff";
    var cellComponent = null;
    var cellTitleRatingLabel = undefined;
    var cellTitleRatingAnswer = undefined;
    if(p_questionResponseObj.typeIsYesNoTF) {
      cellTitleRatingLabel = "Answer: ";
      const ynCircleBackground = this.props.OpenCaptureMobx.teammates_survey_results_matrix_yn_color_from_rating(teammateAnswerObj.rating);
      if(teammateAnswerObj.rating === 0) {
        cellComponent = (
          <div className="displayFlexColumnHcVc" style={{height:"1.5em", width:"1.5em", borderRadius:"0.8em", background:"#" + ynCircleBackground}}>
            <font className="fontWhite">
              {"N"}
            </font>
          </div>
        );
        cellTitleRatingAnswer = "No";
      }
      else if(teammateAnswerObj.rating === 1) {
        cellComponent = (
          <div className="displayFlexColumnHcVc" style={{height:"1.5em", width:"1.5em", borderRadius:"0.8em", background:"#" + ynCircleBackground}}>
            <font className="fontWhite">
              {"Y"}
            </font>
          </div>
        );
        cellTitleRatingAnswer = "Yes";
      }
      else {
        cellComponent = (
          <font className="fontTextLighter">
            {"-"}
          </font>
        );
        cellTitleRatingAnswer = "--None Selected--";
      }
    }
    else if(p_questionResponseObj.typeIsScale05TF) {
      cellTitleRatingLabel = "Rating: ";
      cellBackground = "#" + this.props.OpenCaptureMobx.teammates_survey_results_matrix_scale05_color_from_rating(teammateAnswerObj.rating);

      var scale05FontClass = "";
      var scale05Number = "";
      if(JSFUNC.is_number(teammateAnswerObj.rating) && (teammateAnswerObj.rating >= 0) && (teammateAnswerObj.rating <= 5)) {
        scale05FontClass = "fontBold";
        scale05Number = teammateAnswerObj.rating;
        cellTitleRatingAnswer = teammateAnswerObj.rating;
      }
      else {
        scale05FontClass = "fontTextLighter";
        scale05Number = "-";
        cellTitleRatingAnswer = "--None Selected--";
      }

      cellComponent = (
        <font className={scale05FontClass}>
          {scale05Number}
        </font>
      );
    }
    else if(p_questionResponseObj.typeIsTextResponseTF) {
      cellDisplayClass = "displayFlexRowVc lrMicroPad";
      cellComponent = (
        <LibraryReact.Nowrap p_fontClass="fontTextLight">
          {teammateAnswerObj.comment}
        </LibraryReact.Nowrap>
      );
    }

    var cellTitle = p_teammateObj.name;
    if(cellTitleRatingLabel !== undefined) {
      cellTitle += "\n" + cellTitleRatingLabel + cellTitleRatingAnswer;
    }
    cellTitle += "\nComment: " + teammateAnswerObj.comment;

    return(
      <>
        <div
          className={"flex00a positionRelative borderB1bbb borderR1bbb cursorPointer " + cellDisplayClass}
          style={{flexBasis:teammateColumnWidth, background:cellBackground}}
          title={cellTitle}
          onClick={this.onclick_cell}>
          {cellComponent}
          {(ynOrScale05HasCommentTF) &&
            <div className="positionAbsolute" style={{bottom:"0.5em", width:"2em", borderTop:"dashed 2px #666"}} />
          }
        </div>
        {(viewCommentFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="broadcastReader"
            p_title={"Survey '" + p_teammatesSurveysSelectedExpandedSurveyObj.title + "' Teammate Response Answer"}
            f_onClickCancel={this.onclick_close_view_comment_floating_box}>
            <div className="flex11a yScroll medFullPad">
              <div className="">
                <font className="font11">
                  <font className="fontBold">{"Teammate: "}</font>{p_teammateObj.name}
                </font>
              </div>
              <div className="medTopMargin medFullPad bgLighterGray border1bbb">
                <font className="fontItalic fontTextLight">
                  {p_questionResponseObj.name}
                </font>
              </div>
              {(cellTitleRatingLabel !== undefined) &&
                <div className="medTopMargin">
                  <font className="fontBold fontTextLight">{cellTitleRatingLabel}</font>
                  <font className="">
                    {cellTitleRatingAnswer}
                  </font>
                </div>
              }
              <div className="medTopMargin">
                <font className="fontBold fontTextLight">{"Comment: "}</font>
                <font className="">
                  {teammateAnswerObj.comment}
                </font>
              </div>
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));

const TeammatesSurveysSendToMultipleTeammatesFloatingBoxContents = inject("OpenCaptureMobx")(observer(
class TeammatesSurveysSendToMultipleTeammatesFloatingBoxContents extends Component { //props: p_teammatesSurveysSelectedExpandedSurveyObj, p_teammatesSurveysResultsMatrixObj, f_onClickClose
  constructor(props) {
    super(props);

    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;
    const p_teammatesSurveysResultsMatrixObj = this.props.p_teammatesSurveysResultsMatrixObj;

    this.state = {
      s_responseDueDateTimeUTC: JSFUNC.blank_datetime(),
      s_selectedFileIDsArray: JSFUNC.get_column_vector_from_mapOfMaps(p_teammatesSurveysSelectedExpandedSurveyObj.fileFolderSystemMapOfMaps, "id"),
      s_selectedTeammateIDsArray: JSFUNC.get_column_vector_from_arrayOfObjs(p_teammatesSurveysResultsMatrixObj.usAndTeammatesArrayOfObjs, "id"),
      s_invitationText: p_teammatesSurveysSelectedExpandedSurveyObj.invitation_text,
      s_workingOnSendingEmailsTF: false,
      s_workingOnSendingEmailsTotalNumTeammates: undefined,
      s_workingOnSendingEmailsCurrentTeammateNumber: undefined,
      s_workingOnSendingEmailsCurrentTeammateName: undefined,
      s_finishedSendingEmailsTF: false,
      s_successfullyEmailedTeammateNamesArray: [],
      s_errorEmailedTeammateNamesArray: []
    }
  }

  onchange_response_due_datetime_utc = (i_dateTimeUTC) => {
    this.setState({s_responseDueDateTimeUTC:i_dateTimeUTC});
  }

  onchange_selected_file_ids_array = (i_selectedFileIDsArray) => {
    this.setState({s_selectedFileIDsArray:i_selectedFileIDsArray});
  }

  onclick_check_uncheck_all_teammates = () => {
    const s_selectedTeammateIDsArray = this.state.s_selectedTeammateIDsArray;
    const p_teammatesSurveysResultsMatrixObj = this.props.p_teammatesSurveysResultsMatrixObj;
    const usAndTeammatesArrayOfObjs = p_teammatesSurveysResultsMatrixObj.usAndTeammatesArrayOfObjs;

    var updatedSelectedTeammateIDsArray = []; //all teammates are selected, set them all to unselected
    if(s_selectedTeammateIDsArray.length < usAndTeammatesArrayOfObjs.length) { //none or not all of the teammates are selected, select them all
      updatedSelectedTeammateIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(usAndTeammatesArrayOfObjs, "id");
    }
    this.setState({s_selectedTeammateIDsArray:updatedSelectedTeammateIDsArray});
  }

  onadd_selected_teammate_id = (i_teammateID) => {
    var updatedSelectedTeammateIDsArray = JSFUNC.copy_array(this.state.s_selectedTeammateIDsArray);
    updatedSelectedTeammateIDsArray.push(i_teammateID);
    this.setState({s_selectedTeammateIDsArray:updatedSelectedTeammateIDsArray});
  }

  onremove_selected_teammate_id = (i_teammateID) => {
    const updatedSelectedTeammateIDsArray = JSFUNC.remove_all_values_from_array(i_teammateID, this.state.s_selectedTeammateIDsArray);
    this.setState({s_selectedTeammateIDsArray:updatedSelectedTeammateIDsArray});
  }

  onchange_invitation_text = (i_newValue) => {
    this.setState({s_invitationText:i_newValue});
  }

  onclick_send_out_survey_to_multi_teammates_button = () => {
    const s_responseDueDateTimeUTC = this.state.s_responseDueDateTimeUTC;
    const s_selectedFileIDsArray = this.state.s_selectedFileIDsArray;
    const s_selectedTeammateIDsArray = this.state.s_selectedTeammateIDsArray;
    const s_invitationText = this.state.s_invitationText;

    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;
    const p_teammatesSurveysResultsMatrixObj = this.props.p_teammatesSurveysResultsMatrixObj;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;

    const surveyID = p_teammatesSurveysSelectedExpandedSurveyObj.id;
    var currentlySendingTeammateIndex = 0;
    const totalNumTeammates = s_selectedTeammateIDsArray.length;
    const successfullyEmailedTeammateNamesArray = [];
    const errorEmailedTeammateNamesArray = [];

    this.setState({
      s_workingOnSendingEmailsTF: true,
      s_workingOnSendingEmailsTotalNumTeammates: totalNumTeammates,
      s_workingOnSendingEmailsCurrentTeammateNumber: 1,
      s_workingOnSendingEmailsCurrentTeammateName: "",
      s_finishedSendingEmailsTF: false,
      s_successfullyEmailedTeammateNamesArray: successfullyEmailedTeammateNamesArray,
      s_errorEmailedTeammateNamesArray: errorEmailedTeammateNamesArray
    });

    this.recursive_send_survey_emails(o_openCaptureID, surveyID, s_responseDueDateTimeUTC, s_selectedFileIDsArray, s_selectedTeammateIDsArray, s_invitationText, currentlySendingTeammateIndex, successfullyEmailedTeammateNamesArray, errorEmailedTeammateNamesArray);
  }

  recursive_send_survey_emails = (i_captureID, i_surveyID, i_dueDateTimeUTC, i_selectedFileIDsArray, i_selectedTeammateIDsArray, i_invitationText, i_currentTeammateIndex, i_successfullyEmailedTeammateNamesArray, i_errorEmailedTeammateNamesArray) => {
    const p_teammatesSurveysResultsMatrixObj = this.props.p_teammatesSurveysResultsMatrixObj;

    const usAndTeammatesArrayOfObjs = p_teammatesSurveysResultsMatrixObj.usAndTeammatesArrayOfObjs;

    if(i_currentTeammateIndex >= i_selectedTeammateIDsArray.length) { //recursive loop is finished
      this.setState({
        s_workingOnSendingEmailsTF: false,
        s_workingOnSendingEmailsTotalNumTeammates: undefined,
        s_workingOnSendingEmailsCurrentTeammateNumber: undefined,
        s_workingOnSendingEmailsCurrentTeammateName: undefined,
        s_finishedSendingEmailsTF: true,
        s_successfullyEmailedTeammateNamesArray: i_successfullyEmailedTeammateNamesArray,
        s_errorEmailedTeammateNamesArray: i_errorEmailedTeammateNamesArray
      });
    }
    else {
      const teammateID = i_selectedTeammateIDsArray[i_currentTeammateIndex];

      var teammateObj = undefined;
      for(let searchTeammateObj of usAndTeammatesArrayOfObjs) {
        if(searchTeammateObj.id === teammateID) {
          teammateObj = searchTeammateObj;
          break;
        }
      }

      if(teammateObj !== undefined) {
        this.setState({
          s_workingOnSendingEmailsCurrentTeammateNumber: (i_currentTeammateIndex + 1),
          s_workingOnSendingEmailsCurrentTeammateName: teammateObj.name
        });

        const timeoutFunctionSendEmail = () => {
          const updatedTeammateIndex = (i_currentTeammateIndex + 1);

          const functionOnSuccess = (i_parseResponse) => {
            var updatedSuccessfullyEmailedTeammateNamesArray = JSFUNC.copy_array(i_successfullyEmailedTeammateNamesArray);
            updatedSuccessfullyEmailedTeammateNamesArray.push(teammateObj.name);
            this.recursive_send_survey_emails(i_captureID, i_surveyID, i_dueDateTimeUTC, i_selectedFileIDsArray, i_selectedTeammateIDsArray, i_invitationText, updatedTeammateIndex, updatedSuccessfullyEmailedTeammateNamesArray, i_errorEmailedTeammateNamesArray);
          };

          const functionOnError = () => {
            var updatedErrorEmailedTeammateNamesArray = JSFUNC.copy_array(i_errorEmailedTeammateNamesArray);
            updatedErrorEmailedTeammateNamesArray.push(teammateObj.name);
            this.recursive_send_survey_emails(i_captureID, i_surveyID, i_dueDateTimeUTC, i_selectedFileIDsArray, i_selectedTeammateIDsArray, i_invitationText, updatedTeammateIndex, i_successfullyEmailedTeammateNamesArray, updatedErrorEmailedTeammateNamesArray);
          };

          this.props.OpenCaptureMobx.a_teammates_floating_box_survey_send_out_survey_email(i_captureID, i_surveyID, teammateID, i_dueDateTimeUTC, i_selectedFileIDsArray, teammateObj.surveys_contact_person_ids_comma, i_invitationText, functionOnSuccess, functionOnError);
        }

        //pause for 0.1 seconds between each email
        setTimeout(timeoutFunctionSendEmail, 100);
      }
    }
  }

  onclick_close_multi_teammate_survey_floating_box = () => {
    if(JSFUNC.is_function(this.props.f_onClickClose)) {
      this.props.f_onClickClose();
    }
  }

  render() {
    const s_responseDueDateTimeUTC = this.state.s_responseDueDateTimeUTC;
    const s_selectedTeammateIDsArray = this.state.s_selectedTeammateIDsArray;
    const s_invitationText = this.state.s_invitationText;
    const s_selectedFileIDsArray = this.state.s_selectedFileIDsArray;
    const s_workingOnSendingEmailsTF = this.state.s_workingOnSendingEmailsTF;
    const s_workingOnSendingEmailsTotalNumTeammates = this.state.s_workingOnSendingEmailsTotalNumTeammates;
    const s_workingOnSendingEmailsCurrentTeammateNumber = this.state.s_workingOnSendingEmailsCurrentTeammateNumber;
    const s_workingOnSendingEmailsCurrentTeammateName = this.state.s_workingOnSendingEmailsCurrentTeammateName;
    const s_finishedSendingEmailsTF = this.state.s_finishedSendingEmailsTF;
    const s_successfullyEmailedTeammateNamesArray = this.state.s_successfullyEmailedTeammateNamesArray;
    const s_errorEmailedTeammateNamesArray = this.state.s_errorEmailedTeammateNamesArray;

    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;
    const p_teammatesSurveysResultsMatrixObj = this.props.p_teammatesSurveysResultsMatrixObj;

    if(s_workingOnSendingEmailsTF || s_finishedSendingEmailsTF) {
      return(
        <div className="flex11a yScroll medFullPad">
          <div className="displayFlexColumnHcVc">
            <div className="tbMargin medFullPad bgWhite border1ddd" style={{width:"80%"}}>
              {(s_workingOnSendingEmailsTF) &&
                <div className="medBottomMargin displayFlexColumnHcVc">
                  <div className="smallBottomMargin textCenter">
                    <font className="font11 fontItalic fontTextLight">
                      {"Sending Survey Invitations to Teammates"}
                    </font>
                  </div>
                  <CEGeneralReact.LoadingAnimation />
                  {(s_workingOnSendingEmailsCurrentTeammateNumber !== undefined) &&
                    <>
                      <div className="smallTopMargin textCenter">
                        <font className="fontItalic fontTextLight">
                          {"Teammate " + s_workingOnSendingEmailsCurrentTeammateNumber + " of " + s_workingOnSendingEmailsTotalNumTeammates}
                        </font>
                      </div>
                      <div className="microTopMargin textCenter">
                        <font className="">
                          {s_workingOnSendingEmailsCurrentTeammateName}
                        </font>
                      </div>
                    </>
                  }
                </div>
              }
              {(s_finishedSendingEmailsTF) &&
                <div className="medBottomMargin textCenter">
                  <font className="font11 fontItalic fontTextLight">
                    {"Finished Sending Survey Invitations"}
                  </font>
                </div>
              }
              {(s_errorEmailedTeammateNamesArray.length > 0) &&
                <div className="hugeBottomMargin">
                  <div className="smallBottomMargin">
                    <font className="">
                      {"Survey was Unsuccessful Sending To (please retry for these Teammates):"}
                    </font>
                  </div>
                  {s_errorEmailedTeammateNamesArray.map((m_teammateName) =>
                    <div className="microBottomMargin smallFullPad border bevelBorderColors bgLightRed">
                      <font className="fontBold fontRed">
                        {m_teammateName}
                      </font>
                    </div>
                  )}
                </div>
              }
              {(s_successfullyEmailedTeammateNamesArray.length > 0) &&
                <div className="hugeBottomMargin">
                  <div className="smallBottomMargin">
                    <font className="">
                      {"Survey Successfully Sent To:"}
                    </font>
                  </div>
                  {s_successfullyEmailedTeammateNamesArray.map((m_teammateName) =>
                    <div className="microBottomMargin smallFullPad border bevelBorderColors bgLightGreen">
                      <font className="fontBold fontTextLight">
                        {m_teammateName}
                      </font>
                    </div>
                  )}
                </div>
              }
              {(s_finishedSendingEmailsTF) &&
                <div className="displayFlexColumnHcVc">
                  <CEGeneralReact.CEButton
                    p_type="gray"
                    p_text="Close Window"
                    f_onClick={this.onclick_close_multi_teammate_survey_floating_box}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      );
    }

    const usAndTeammatesArrayOfObjs = p_teammatesSurveysResultsMatrixObj.usAndTeammatesArrayOfObjs;

    //compute the status of the check/uncheck all checkbox
    const numTeammatesSelected = s_selectedTeammateIDsArray.length;
    var checkUncheckAllU0S1P2 = 2;
    if(numTeammatesSelected === 0) {
      checkUncheckAllU0S1P2 = 0;
    }
    else if(numTeammatesSelected === usAndTeammatesArrayOfObjs.length) {
      checkUncheckAllU0S1P2 = 1;
    }

    var sendOutSurveyErrorMessage = undefined;

    //check that invitation text is filled out
    const invitationTextIsFilledOutTF = JSFUNC.text_or_number_is_filled_out_tf(s_invitationText);
    if(!invitationTextIsFilledOutTF) {
      sendOutSurveyErrorMessage = "The Invitation Text for the survey email must be filled out";
    }

    //check that all selected contact persons are valid
    var multiTeammateSurveysContactPersonsAllEmailsValidTF = true;
    if(numTeammatesSelected === 0) { //must have at least 1 teammate selected to send this survey out to
      multiTeammateSurveysContactPersonsAllEmailsValidTF = false;
      sendOutSurveyErrorMessage = "At least 1 Teammate must be selected to send this survey invitation to";
    }
    else {
      for(let teammateObj of usAndTeammatesArrayOfObjs) {
        if(JSFUNC.in_array(teammateObj.id, s_selectedTeammateIDsArray)) { //if this teammate is currently selected to receive the survey email, check that its contacts have valid emails
          if(!teammateObj.surveyContactPersonsAllEmailsValidTF) {
            multiTeammateSurveysContactPersonsAllEmailsValidTF = false;
            break;
          }
        }
      }

      if(!multiTeammateSurveysContactPersonsAllEmailsValidTF) {
        sendOutSurveyErrorMessage = "All selected Teammates must have at least 1 Contact Person specified with a valid email address to send this survey invitation to";
      }
    }

    //check that the selected due date/time is valid
    const nowDateTimeUTC = JSFUNC.now_datetime_utc();
    const responseDueDateTimeIsFilledOutAndLaterThanNowTF = (JSFUNC.datetime_is_filled_out_tf(s_responseDueDateTimeUTC) && (s_responseDueDateTimeUTC > nowDateTimeUTC));
    if(!responseDueDateTimeIsFilledOutAndLaterThanNowTF) {
      sendOutSurveyErrorMessage = "Response Due Date/Time must be filled out with a date later than today";
    }

    const canSendOutSurveyTF = (responseDueDateTimeIsFilledOutAndLaterThanNowTF && multiTeammateSurveysContactPersonsAllEmailsValidTF && invitationTextIsFilledOutTF);

    return(
      <div className="flex11a yScroll medFullPad">
        <div className="displayFlexColumnHcVc">
          <div className="tbMargin medFullPad bgWhite border1ddd" style={{width:"80%"}}>
            <div className="microBottomMargin textCenter">
              <font className="font11 fontItalic fontTextLighter">{"Survey"}</font>
            </div>
            <div className="medBottomMargin textCenter">
              <font className="font16 fontBlue fontTextLight">{p_teammatesSurveysSelectedExpandedSurveyObj.title}</font>
            </div>
            <div className="border1bbb borderRadius10 bgLightesterGray lrMedMargin medFullPad">
              <div className="microBottomMargin">
                <font className="fontItalic fontTextLight">
                  {"Enter Required Response Due Date/Time:"}
                </font>
              </div>
              <div className="displayFlexRow">
                <div className="flex00a">
                  <LibraryReact.DateTime
                    p_valueDateTimeUTC={s_responseDueDateTimeUTC}
                    p_isSingleLineTF={true}
                    p_errorTF={(!responseDueDateTimeIsFilledOutAndLaterThanNowTF)}
                    f_onChange={this.onchange_response_due_datetime_utc}
                  />
                </div>
                <div className="flex11a" />
              </div>
              <div className="hugeTopMargin" />
              <TeammatesSurveysSelectDocumentsForEmail
                p_fileFolderSystemMapOfMaps={p_teammatesSurveysSelectedExpandedSurveyObj.fileFolderSystemMapOfMaps}
                p_selectedFileIDsArray={s_selectedFileIDsArray}
                f_onChange={this.onchange_selected_file_ids_array}
              />
              <div className="hugeTopMargin">
                <font className="fontItalic fontTextLight">
                  {"Select the Teammate(s) to receive this survey invitation by email:"}
                </font>
              </div>
              <div className="displayFlexRow tbMicroMargin lrMargin">
                <div className="flex00a displayFlexRowVc border1ddd borderRadius10 bgLightestGray tbMicroPad lrPad" style={{flexBasis:"20em"}}>
                  <div className="flex00a lrPad">
                    <LibraryReact.CheckBox
                      p_u0_s1_p2_du3_ds4={checkUncheckAllU0S1P2}
                      p_sizeEm={1.5}
                      p_title={"Check/Uncheck all Teammates"}
                      f_onClick={this.onclick_check_uncheck_all_teammates}
                    />
                  </div>
                  <div className="flex11a lrPad">
                    <font className="fontItalic fontTextLight">
                      {"Check/Uncheck all Teammates"}
                    </font>
                  </div>
                </div>
                <div className="flex11a" />
              </div>
              {usAndTeammatesArrayOfObjs.map((m_teammateObj) =>
                <TeammatesSurveysSendToMultipleTeammatesTeammateRow
                  p_teammatesSurveysSelectedExpandedSurveyObj={p_teammatesSurveysSelectedExpandedSurveyObj}
                  p_teammateObj={m_teammateObj}
                  p_isSelectedTF={JSFUNC.in_array(m_teammateObj.id, s_selectedTeammateIDsArray)}
                  f_onCheck={this.onadd_selected_teammate_id}
                  f_onUncheck={this.onremove_selected_teammate_id}
                />
              )}
              <div className="hugeTopMargin">
                <font className="fontItalic fontTextLight">
                  {"Fill out the content of the survey invitation email:"}
                </font>
              </div>
              <div className="displayFlexColumn microTopMargin" style={{height:"10em"}}>
                <LibraryReact.Textarea
                  p_value={s_invitationText}
                  p_class="flex11a"
                  p_errorTF={(!invitationTextIsFilledOutTF)}
                  f_onChange={this.onchange_invitation_text}
                />
              </div>
              <div className="hugeTopMargin medBottomMargin displayFlexColumnHcVc">
                <CEGeneralReact.CEButton
                  p_type={((canSendOutSurveyTF) ? ("blue") : ("blueDisabled"))}
                  p_text="Send Out This Survey"
                  p_title={sendOutSurveyErrorMessage}
                  f_onClick={((canSendOutSurveyTF) ? (this.onclick_send_out_survey_to_multi_teammates_button) : (undefined))}
                />
                {(!canSendOutSurveyTF) &&
                  <CEGeneralReact.ErrorText p_class="microTopMargin lrMedPad textCenter" p_text={sendOutSurveyErrorMessage} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}));

const TeammatesSurveysSendToMultipleTeammatesTeammateRow = inject("OpenCaptureMobx")(observer(
class TeammatesSurveysSendToMultipleTeammatesTeammateRow extends Component { //props: p_teammatesSurveysSelectedExpandedSurveyObj, p_teammateObj, p_isSelectedTF, f_onCheck, f_onUncheck
  onclick_multiple_teammate_checkbox = () => {
    const p_teammateObj = this.props.p_teammateObj;
    if(this.props.p_isSelectedTF) {
      if(JSFUNC.is_function(this.props.f_onUncheck)) {
        this.props.f_onUncheck(p_teammateObj.id);
      }
    }
    else {
      if(JSFUNC.is_function(this.props.f_onCheck)) {
        this.props.f_onCheck(p_teammateObj.id);
      }
    }
  }

  onadd_selected_survey_contact_persons = (i_newContactPersonIDsToAddComma) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_surveys_add_contact_persons(o_openCaptureID, p_teammateObj.id, p_teammateObj.name, p_teammateObj.surveys_contact_person_ids_comma, i_newContactPersonIDsToAddComma);
  }

  onremove_survey_contact_person = (i_contactPersonID) => {
    const p_teammateObj = this.props.p_teammateObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_teammates_surveys_remove_contact_person(o_openCaptureID, p_teammateObj.id, p_teammateObj.name, p_teammateObj.surveys_contact_person_ids_comma, i_contactPersonID);
  }

  render() {
    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;
    const p_teammateObj = this.props.p_teammateObj;
    const isSelectedTF = this.props.p_isSelectedTF;

    var rowBgClass = "bgLightestGray bevelBorderColors";
    var teammateNameBgClass = "bgLightesterGray";
    var teammateNameFontClass = "fontBold fontTextLightester";
    if(isSelectedTF) {
      rowBgClass = "bgLighterBlue bevelBorderColorBlue";
      teammateNameBgClass = "bgLightestestGray";
      teammateNameFontClass = "fontBold";
    }

    return(
      <div className={"displayFlexRow border medBottomMargin lrPad " + rowBgClass} style={{minHeight:"5em"}}>
        <div className="flex00a displayFlexColumnHcVc lrPad">
          <LibraryReact.CheckBox
            p_u0_s1_p2_du3_ds4={((isSelectedTF) ? (1) : (0))}
            p_sizeEm={2}
            p_title={"Check this box to include '" + p_teammateObj.name + "' to receive an invite to fill out survey '" + p_teammatesSurveysSelectedExpandedSurveyObj.title + "'"}
            f_onClick={this.onclick_multiple_teammate_checkbox}
          />
        </div>
        <div className="flex11a displayFlexColumnVc lrPad" style={{flexBasis:"100em"}}>
          <div className={"flex00a tbPad lrMedPad borderRadius10 " + teammateNameBgClass}>
            <font className={"font11 " + teammateNameFontClass}>
              {p_teammateObj.name}
            </font>
          </div>
        </div>
        <div className="flex11a displayFlexColumnVc lrPad" style={{flexBasis:"150em"}}>
          <div className="flex00a tbPad">
            <div className="smallBottomMargin">
              <font className="fontItalic">
                {"Contact Person(s) to Receive Survey Email Invitations"}
              </font>
            </div>
            <ContactsReact.MultiAddContactBubblesVerticalList
              p_isPersonTF={true}
              p_addedContactIDsComma={p_teammateObj.surveys_contact_person_ids_comma}
              p_addButtonType="add"
              p_addButtonText="Add Contact Person(s)"
              p_errorIfEmptyTF={isSelectedTF}
              p_personMarkInvalidEmailTF={true}
              p_initialZoomedCompanyID={p_teammateObj.contact_company_id}
              f_onAddContacts={this.onadd_selected_survey_contact_persons}
              f_onRemoveContact={this.onremove_survey_contact_person}
            />
          </div>
        </div>
      </div>
    );
  }
}));

class TeammatesSurveysSelectDocumentsForEmail extends Component { //props: p_fileFolderSystemMapOfMaps, p_selectedFileIDsArray, f_onChange
  onclick_check_uncheck_all_files = () => {
    const fileFolderSystemMapOfMaps = this.props.p_fileFolderSystemMapOfMaps;
    const p_selectedFileIDsArray = this.props.p_selectedFileIDsArray;

    var updatedSelectedFileIDsArray = []; //all files are selected, set them all to unselected
    if(p_selectedFileIDsArray.length < fileFolderSystemMapOfMaps.size) { //none or not all of the files are selected, select them all
      updatedSelectedFileIDsArray = JSFUNC.get_column_vector_from_mapOfMaps(fileFolderSystemMapOfMaps, "id");
    }

    if(JSFUNC.is_function(this.props.f_onChange)) {
      this.props.f_onChange(updatedSelectedFileIDsArray);
    }
  }

  oncheck_file = (i_fileID) => {
    const p_selectedFileIDsArray = this.props.p_selectedFileIDsArray;
    var updatedSelectedDocumentIDsArray = JSFUNC.copy_array(p_selectedFileIDsArray);
    updatedSelectedDocumentIDsArray.push(i_fileID);
    if(JSFUNC.is_function(this.props.f_onChange)) {
      this.props.f_onChange(updatedSelectedDocumentIDsArray);
    }
  }

  onuncheck_file = (i_fileID) => {
    const p_selectedFileIDsArray = this.props.p_selectedFileIDsArray;
    const updatedSelectedDocumentIDsArray = JSFUNC.remove_all_values_from_array(i_fileID, p_selectedFileIDsArray);
    if(JSFUNC.is_function(this.props.f_onChange)) {
      this.props.f_onChange(updatedSelectedDocumentIDsArray);
    }
  }

  render() {
    const fileFolderSystemMapOfMaps = this.props.p_fileFolderSystemMapOfMaps;
    const p_selectedFileIDsArray = this.props.p_selectedFileIDsArray;

    const fileFolderSystemArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(fileFolderSystemMapOfMaps);
    const totalNumFiles = fileFolderSystemArrayOfObjs.length

    const numFilesSelected = p_selectedFileIDsArray.length;
    var checkUncheckAllU0S1P2 = 2;
    if(numFilesSelected === 0) {
      checkUncheckAllU0S1P2 = 0;
    }
    else if(numFilesSelected === totalNumFiles) {
      checkUncheckAllU0S1P2 = 1;
    }

    return(
      <>
        <div className="microBottomMargin">
          <font className="fontItalic fontTextLight">
            {"Select files (uploaded to this survey in the 'Documents' subtab) to include for recipients to download "}
          </font>
        </div>
        {(totalNumFiles === 0) &&
          <div className="displayFlexColumnHcVc textCenter">
            <font className="fontItalic fontTextLighter">
              {"--No Files Uploaded to this Survey--"}
            </font>
          </div>
        }
        {(totalNumFiles >= 2) &&
          <div className="displayFlexRow tbMicroMargin lrMargin">
            <div className="flex00a displayFlexRowVc border1ddd borderRadius10 bgLightestGray tbMicroPad lrPad" style={{flexBasis:"20em"}}>
              <div className="flex00a lrPad">
                <LibraryReact.CheckBox
                  p_u0_s1_p2_du3_ds4={checkUncheckAllU0S1P2}
                  p_sizeEm={1.5}
                  p_title={"Check/Uncheck all Files"}
                  f_onClick={this.onclick_check_uncheck_all_files}
                />
              </div>
              <div className="flex11a lrPad">
                <font className="fontItalic fontTextLight">
                  {"Check/Uncheck all Files"}
                </font>
              </div>
            </div>
            <div className="flex11a" />
          </div>
        }
        {fileFolderSystemArrayOfObjs.map((m_fileObj) =>
          <TeammatesSurveysSelectDocumentsForEmailFileRow
            p_fileObj={m_fileObj}
            p_isSelectedTF={JSFUNC.in_array(m_fileObj.id, p_selectedFileIDsArray)}
            f_onCheck={this.oncheck_file}
            f_onUncheck={this.onuncheck_file}
          />
        )}
      </>
    );
  }
}

class TeammatesSurveysSelectDocumentsForEmailFileRow extends Component { //props: p_fileObj, p_isSelectedTF, f_onCheck, f_onUncheck
  onclick_survey_file_checkbox = () => {
    const fileObj = this.props.p_fileObj;
    const isSelectedTF = this.props.p_isSelectedTF;
    if(isSelectedTF) {
      if(JSFUNC.is_function(this.props.f_onUncheck)) {
        this.props.f_onUncheck(fileObj.id);
      }
    }
    else {
      if(JSFUNC.is_function(this.props.f_onCheck)) {
        this.props.f_onCheck(fileObj.id);
      }
    }
  }

  render() {
    const fileObj = this.props.p_fileObj;
    const isSelectedTF = this.props.p_isSelectedTF;

    return(
      <div className={"displayFlexRowVc border bevelBorderColors microBottomMargin tbMicroPad lrPad " + ((isSelectedTF) ? ("bgLightGreen") : ("bgLighterGreen"))}>
        <div className="flex00a lrMedPad">
          <LibraryReact.CheckBox
            p_u0_s1_p2_du3_ds4={((isSelectedTF) ? (1) : (0))}
            p_sizeEm={1.2}
            p_title={"Check this box to include file '" + fileObj.display_name + "' as a downloadable attachment on the survey"}
            f_onClick={this.onclick_survey_file_checkbox}
          />
        </div>
        <div className="flex11a lrPad">
          <font className={((isSelectedTF) ? ("") : ("fontTextLightester"))}>
            {fileObj.display_name}
          </font>
        </div>
      </div>
    );
  }
}




const TeammatesSurveysDocumentsSubtabSelectFilesFromDocumentsCard = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammatesSurveysDocumentsSubtabSelectFilesFromDocumentsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectedDocumentsCardFileIDsComma: ""
    };
  }

  onselect_document_files = (i_selectedFileIDsComma) => {
    this.setState({s_selectedDocumentsCardFileIDsComma:i_selectedFileIDsComma});
  }

  onclick_include_file_from_documents_card_with_survey_documents = () => {
    //add the selected files as uploads to the teammate survey
    this.props.OpenCaptureMobx.a_teammates_surveys_documents_include_files_from_documents_card(this.state.s_selectedDocumentsCardFileIDsComma);

    //reset the selected documents to 'none selected' for next time user opens this select of Capture Documents (remaining docs that have not been added yet)
    this.onselect_document_files("");
  }

  render() {
    const s_selectedDocumentsCardFileIDsComma = this.state.s_selectedDocumentsCardFileIDsComma;

    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;
    const c_teammatesSurveysSelectMultiDocumentsCardAllFilesFieldTypeObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectMultiDocumentsCardAllFilesFieldTypeObj;
    const c_documentsAllFilesMapOfMaps = this.props.OpenCaptureMobx.c_documentsAllFilesMapOfMaps;
    const c_cardNameDocuments = this.props.DatabaseMobx.c_cardNameDocuments;

    //determine files from the Documents Card that have already been added to the survey file system
    var documentsCardFileIDsAlreadyAddedToSurveyDocumentsArray = [];
    if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      const allDocumentsCardFileLocsArray = JSFUNC.get_column_vector_from_mapOfMaps(c_documentsAllFilesMapOfMaps, "file_loc");
      for(let surveyFileMap of c_teammatesSurveysSelectedExpandedSurveyObj.fileFolderSystemMapOfMaps.values()) {
        var surveyFileLoc = surveyFileMap.get("file_loc");
        for(let documentsCardFileMap of c_documentsAllFilesMapOfMaps.values()) {
          if(documentsCardFileMap.get("file_loc") === surveyFileLoc) {
            documentsCardFileIDsAlreadyAddedToSurveyDocumentsArray.push(documentsCardFileMap.get("id"));
            break;
          }
        }
      }
    }


    return(
      <>
        <div className="displayFlexColumnHcVc textCenter">
          <font className="font11 fontBold fontTextLighter">
            {"Attach Files from " + c_cardNameDocuments + " Card"}
          </font>
        </div>
        <div className="displayFlexRowVc microTopMargin">
          <div className="flex11a">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={c_teammatesSurveysSelectMultiDocumentsCardAllFilesFieldTypeObj}
              p_valueRaw={s_selectedDocumentsCardFileIDsComma}
              p_valuesToNotIncludeArray={documentsCardFileIDsAlreadyAddedToSurveyDocumentsArray}
              f_onChangeOrOnSelect={this.onselect_document_files}
            />
          </div>
          <div className="flex00a" style={{flexBasis:"1em"}} />
          <div className="flex00a">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Include File(s)"
              f_onClick={this.onclick_include_file_from_documents_card_with_survey_documents}
            />
          </div>
        </div>
      </>
    );
  }
}));


const TeammatesSurveysSurveyButton = inject("OpenCaptureMobx", "UserMobx")(observer(
class TeammatesSurveysSurveyButton extends Component { //props: p_teammatesSurveyObj, p_isOnlyItemTF, p_isLastItemTF, f_onClick
  onclick_survey_button = () => {
    this.props.OpenCaptureMobx.a_teammates_surveys_select_survey(this.props.p_teammatesSurveyObj.id);
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick();
    }
  }

  onsave_edit_survey_title = (i_newValue) => {
    const teammatesSurveyObj = this.props.p_teammatesSurveyObj;
    this.props.OpenCaptureMobx.a_teammates_surveys_update_survey_title(teammatesSurveyObj.capture_id, teammatesSurveyObj.id, teammatesSurveyObj.title, i_newValue);
  }

  ondelete_survey = () => {
    this.props.OpenCaptureMobx.a_teammates_surveys_delete_survey(this.props.p_teammatesSurveyObj);
  }

  render() {
    const teammatesSurveyObj = this.props.p_teammatesSurveyObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const teammatesSurveysSelectedSurveyID = this.props.OpenCaptureMobx.o_teammatesSurveysSelectedSurveyID;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const surveyIsSelectedTF = (teammatesSurveyObj.id === teammatesSurveysSelectedSurveyID);

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={c_userCanEditCaptureCardContentTF}
        p_uniqueString="teammatesSurveysSurveyButton"
        p_itemID={teammatesSurveyObj.id}
        p_itemSort={teammatesSurveyObj.sort}
        p_itemSortColumnWidth="2em"
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="tbMicroPad lrPad"
        p_itemClass={"border bevelBorderColors " + ((surveyIsSelectedTF) ? ("bgOrangeGradient") : ("bgLightGrayGradient hoverLighterBlueGradient"))}
        p_itemStyleObj={{height:"4.4em"}}
        p_lastItemExtraDropZoneHeight="4em"
        p_tblName="tbl_c_teammates_surveys"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={["capture_id"]}
        p_filterValuesArray={[teammatesSurveyObj.capture_id]}
        f_onDropForeignItem={undefined}>
        <div
          className={"flex11a displayFlexRowVc lrMedPad " + ((surveyIsSelectedTF) ? ("") : ("cursorPointer"))}
          title={teammatesSurveyObj.title}
          onClick={((surveyIsSelectedTF) ? (undefined) : (this.onclick_survey_button))}>
          <LibraryReact.MaxHeightWrap p_maxHeight="4em" p_fontClass="font12 fontBold">
            {teammatesSurveyObj.title}
          </LibraryReact.MaxHeightWrap>
        </div>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
            <CEGeneralReact.VerticalDotsMenu
              p_menuItemsArrayOfObjs={[
                {
                  displayName:"Edit Survey Title",
                  confirmType: "inputText",
                  confirmTitle: "Edit Survey Title",
                  confirmTextInitialValue: teammatesSurveyObj.title,
                  confirmButton1Name: "Save",
                  confirmMessage: "Enter a new title for this Teammate Survey",
                  functionOnClickConfirmButton:this.onsave_edit_survey_title
                },
                {
                  displayName: "Delete Survey",
                  confirmType: "confirmDelete",
                  confirmMessage: "Are you sure you want to delete Teammate Survey '" + teammatesSurveyObj.title + "'?\n\nThis will also delete:\n - All questions created in this survey\n - All documents uploaded for attachments to this survey\n - All answer responses to this survey from teammates",
                  functionOnClickConfirmButton: this.ondelete_survey
                }
              ]}
            />
          </div>
        }
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));

const TeammatesSurveysCopyQuestionsFromExistingCaptureButtonWithFloatingBox = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammatesSurveysCopyQuestionsFromExistingCaptureButtonWithFloatingBox extends Component { //props:
  constructor(props) {
    super(props);
    this.state = {
      s_selectedCaptureID: -1,
      s_selectedSurveyID: -1,
      s_selectedAlsoCopyInvitationTextTF: false,
      s_loadingSelectedCaptureSurveysFromDatabaseTFU: undefined, //undefined - not started or error, false - loading, true - loaded successfully
      s_errorMessage: "",
      s_loadedSurveysArrayOfObjs: [],
      s_loadedQuestionsArrayOfObjs: [],
      s_copyingQuestionsTF: false,
      s_successMessage: ""
    };
  }

  onclick_copy_survey_questions_button_open_floating_box = () => {
    this.setState({
      s_selectedCaptureID: -1,
      s_selectedSurveyID: -1,
      s_selectedAlsoCopyInvitationTextTF: false,
      s_loadingSelectedCaptureSurveysFromDatabaseTFU: undefined,
      s_errorMessage: "",
      s_loadedSurveysArrayOfObjs: [],
      s_loadedQuestionsArrayOfObjs: [],
      s_copyingQuestionsTF: false,
      s_successMessage: ""
    });
    this.props.OpenCaptureMobx.a_teammates_surveys_edit_questions_set_copy_questions_floating_box_is_open_tf(true);
  }

  onclick_close_copy_survey_questions_floating_box = () => {
    this.setState({
      s_selectedCaptureID: -1,
      s_selectedSurveyID: -1,
      s_selectedAlsoCopyInvitationTextTF: false,
      s_loadingSelectedCaptureSurveysFromDatabaseTFU: undefined,
      s_errorMessage: "",
      s_loadedSurveysArrayOfObjs: [],
      s_loadedQuestionsArrayOfObjs: [],
      s_copyingQuestionsTF: false,
      s_successMessage: ""
    });
    this.props.OpenCaptureMobx.a_teammates_surveys_edit_questions_set_copy_questions_floating_box_is_open_tf(false);
  }

  onselect_teammate_survey_copy_questions_capture_id = (i_captureID) => {
    this.setState({
      s_selectedCaptureID: i_captureID,
      s_selectedSurveyID: -1,
      s_loadedSelectedCaptureSurveysArrayOfObjs: [],
      s_errorMessage: "",
      s_loadedSurveysArrayOfObjs: [],
      s_loadedQuestionsArrayOfObjs: [],
      s_copyingQuestionsTF: false,
      s_successMessage: ""
    });

    //if a valid captureID was selected (didn't clear the select)
    if(JSFUNC.select_int_is_filled_out_tf(i_captureID)) {
      //set loading flag
      this.setState({s_loadingSelectedCaptureSurveysFromDatabaseTFU:false});

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureReact - TeammatesSurveysCopyQuestionsFromExistingCaptureButtonWithFloatingBox", "onselect_teammate_survey_copy_questions_capture_id", ["i_captureID"], [i_captureID]);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadTeammateSurveysAndAllQuestionsFromCaptureID", jsDescription);
      C_CallPhpScript.add_post_var("i_captureID", i_captureID);
      C_CallPhpScript.add_return_vars(["surveysMatrix", "questionsMatrix"]);

      const functionOnSuccess = (i_parseResponse) => {
        var loadedSurveysArrayOfObjs = i_parseResponse.surveysMatrix;
        var loadedQuestionsArrayOfObjs = i_parseResponse.questionsMatrix;

        JSFUNC.sort_arrayOfObjs(loadedSurveysArrayOfObjs, "sort", true);
        JSFUNC.sort_arrayOfObjs(loadedQuestionsArrayOfObjs, "sort", true);

        var preSelectedSurveyID = -1;
        if(loadedSurveysArrayOfObjs.length > 0) {
          preSelectedSurveyID = loadedSurveysArrayOfObjs[0].id;
        }

        this.setState({
          s_selectedSurveyID: preSelectedSurveyID,
          s_loadingSelectedCaptureSurveysFromDatabaseTFU: true,
          s_errorMessage: "",
          s_loadedSurveysArrayOfObjs: loadedSurveysArrayOfObjs,
          s_loadedQuestionsArrayOfObjs: loadedQuestionsArrayOfObjs
        });
      }
      C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

      const functionOnError = () => {
        this.setState({
          s_loadingSelectedCaptureSurveysFromDatabaseTFU: undefined,
          s_errorMessage: "There was an error loading Surveys from the selected Capture"
        });
      }
      C_CallPhpScript.add_function("onError", functionOnError);
      C_CallPhpScript.execute();
    }
  }

  onselect_teammate_survey_copy_questions_survey_id = (i_surveyID) => {
    this.setState({s_selectedSurveyID:i_surveyID});
  }

  onclick_teammate_survey_copy_questions_also_copy_invitation_text_checkbox = () => {
    const s_selectedAlsoCopyInvitationTextTF = this.state.s_selectedAlsoCopyInvitationTextTF;
    this.setState({s_selectedAlsoCopyInvitationTextTF:!s_selectedAlsoCopyInvitationTextTF});
  }

  onclick_copy_questions_button = () => {
    const selectedSurveyID = this.state.s_selectedSurveyID;
    const s_selectedAlsoCopyInvitationTextTF = this.state.s_selectedAlsoCopyInvitationTextTF;
    const loadedSurveysArrayOfObjs = this.state.s_loadedSurveysArrayOfObjs;
    const loadedQuestionsArrayOfObjs = this.state.s_loadedQuestionsArrayOfObjs;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;

    if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      this.setState({s_copyingQuestionsTF:true});

      var numQuestionsToCopy = 0;

      const jsDescription = JSFUNC.js_description_from_action("OpenCaptureReact - TeammatesSurveysCopyQuestionsFromExistingCaptureButtonWithFloatingBox", "onclick_copy_questions_button", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //insert new records for copied questions
      const fieldNamesArray = ["capture_id", "survey_id", "sort", "question_type_id", "name"];
      const idsbArray = ["i", "i", "i", "i", "s"];
      var newSortNumber = (c_teammatesSurveysSelectedExpandedSurveyObj.questionsArrayOfObjs.length + 1);
      for(let loadedQuestionObj of loadedQuestionsArrayOfObjs) { //loop through loaded survey questions
        if(loadedQuestionObj.survey_id === selectedSurveyID) { //filter for selected surveyID
          var valuesArray = [o_openCaptureID, c_teammatesSurveysSelectedExpandedSurveyObj.id, newSortNumber, loadedQuestionObj.question_type_id, loadedQuestionObj.name];
          C_CallPhpTblUID.add_insert("tbl_c_teammates_surveys_questions", fieldNamesArray, valuesArray, idsbArray);

          numQuestionsToCopy++;
          newSortNumber++;
        }
      }

      //if the box is checked to copy the invitation text, edit the survey row
      if(s_selectedAlsoCopyInvitationTextTF) {
        //get original invitation text from selected capture/survey
        const selectedSurveyObjToCopyFrom = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(loadedSurveysArrayOfObjs, "id", selectedSurveyID);
        if(selectedSurveyObjToCopyFrom !== undefined) {
          C_CallPhpTblUID.add_update("tbl_c_teammates_surveys", c_teammatesSurveysSelectedExpandedSurveyObj.id, "invitation_text", selectedSurveyObjToCopyFrom.invitation_text, "s");
        }
      }

      const functionOnSuccess = (i_parseResponse) => {
        this.setState({
          s_selectedCaptureID: -1,
          s_selectedSurveyID: -1,
          s_loadingSelectedCaptureSurveysFromDatabaseTFU: undefined,
          s_errorMessage: "",
          s_loadedSurveysArrayOfObjs: [],
          s_loadedQuestionsArrayOfObjs: [],
          s_copyingQuestionsTF: false,
          s_successMessage: "Successfully copied " + numQuestionsToCopy + " " + JSFUNC.plural(numQuestionsToCopy, "Question", "Questions")
        });
        this.props.OpenCaptureMobx.a_teammates_surveys_edit_questions_set_copy_questions_floating_box_is_open_tf(false);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      const functionOnError = (i_parseResponse) => {
        this.setState({
          s_selectedCaptureID: -1,
          s_selectedSurveyID: -1,
          s_selectedAlsoCopyInvitationTextTF: false,
          s_loadingSelectedCaptureSurveysFromDatabaseTFU: undefined,
          s_errorMessage: "Error copying Questions, please try again",
          s_loadedSurveysArrayOfObjs: [],
          s_loadedQuestionsArrayOfObjs: [],
          s_copyingQuestionsTF: false,
          s_successMessage: ""
        });
        this.props.OpenCaptureMobx.a_teammates_surveys_edit_questions_set_copy_questions_floating_box_is_open_tf(false);
      }
      C_CallPhpTblUID.add_function("onError", functionOnError);

      C_CallPhpTblUID.execute();
    }
  }

  render() {
    const selectedCaptureID = this.state.s_selectedCaptureID;
    const selectedSurveyID = this.state.s_selectedSurveyID;
    const s_selectedAlsoCopyInvitationTextTF = this.state.s_selectedAlsoCopyInvitationTextTF;
    const loadingSelectedCaptureSurveysFromDatabaseTFU = this.state.s_loadingSelectedCaptureSurveysFromDatabaseTFU;
    const errorMessage = this.state.s_errorMessage;
    const loadedSurveysArrayOfObjs = this.state.s_loadedSurveysArrayOfObjs;
    const loadedQuestionsArrayOfObjs = this.state.s_loadedQuestionsArrayOfObjs;
    const copyingQuestionsTF = this.state.s_copyingQuestionsTF;
    const successMessage = this.state.s_successMessage;

    const o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF = this.props.OpenCaptureMobx.o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF;
    const c_captureName = this.props.OpenCaptureMobx.c_captureName;
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;
    const c_selectAllCapturesNoClearFieldTypeObj = this.props.DatabaseMobx.c_selectAllCapturesNoClearFieldTypeObj;

    if(c_teammatesSurveysSelectedExpandedSurveyObj === undefined) {
      return(null);
    }

    const validCaptureIDSelectedTF = JSFUNC.select_int_is_filled_out_tf(selectedCaptureID);
    const validSurveyIDSelectedTF = JSFUNC.select_int_is_filled_out_tf(selectedSurveyID);

    var selectSurveyComponent = null;
    var selectedSurveyQuestionsComponent = null;
    var copyQuestionsOrCancelButtonsComponent = null;
    if(loadingSelectedCaptureSurveysFromDatabaseTFU === true) { //successfully loaded survey data
      var surveyIDsArray = [];
      var surveyTitlesAndQuestionsCountsArray = [];
      for(let loadedSurveyObj of loadedSurveysArrayOfObjs) {
        //count number of questions in each survey
        var surveyNumQuestions = 0;
        for(let loadedQuestionObj of loadedQuestionsArrayOfObjs) {
          if(loadedQuestionObj.survey_id === loadedSurveyObj.id) {
            surveyNumQuestions++;
          }
        }

        surveyIDsArray.push(loadedSurveyObj.id);
        surveyTitlesAndQuestionsCountsArray.push(loadedSurveyObj.title + " [" + surveyNumQuestions + " " + JSFUNC.plural(surveyNumQuestions, "Question", "Questions") +"]");
      }

      const surveysSwsOptionsObj = undefined;
      const surveysSelectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Survey", surveyIDsArray, false, surveyTitlesAndQuestionsCountsArray, surveysSwsOptionsObj);
      const selectSurveysFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", surveysSelectWithSearchDataObj);

      selectSurveyComponent = (
        <>
          <div className="flex00a lrMedMargin">
            <font className="fontItalic">
              {"Select which Survey (from the selected Capture) you want to copy Questions from:"}
            </font>
          </div>
          <div className="flex00a smallTopMargin lrMedMargin">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={selectSurveysFieldTypeObj}
              p_valueRaw={selectedSurveyID}
              f_onChangeOrOnSelect={this.onselect_teammate_survey_copy_questions_survey_id}
            />
          </div>
        </>
      );

      //if a single surveyID has been selected from the list, draw its questions
      if(validSurveyIDSelectedTF) {
        //filter the questions for only ones on the selected surveyID
        const selectedSurveyLoadedQuestionsArrayOfObjs = JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_matching_single_field_value(loadedQuestionsArrayOfObjs, "survey_id", selectedSurveyID);
        const numSelectedSurveyLoadedQuestions = selectedSurveyLoadedQuestionsArrayOfObjs.length;
        if(numSelectedSurveyLoadedQuestions === 0) {
          selectedSurveyQuestionsComponent = (
            <div className="smallBottomMargin textCenter">
              <font className="font12 fontItalic fontTextLighter">
                {"There are 0 Questions in this selected Capture Survey"}
              </font>
            </div>
          );
        }
        else {
          selectedSurveyQuestionsComponent = (
            selectedSurveyLoadedQuestionsArrayOfObjs.map((m_selectedSurveyLQuestionObj) =>
              <div
                className={"borderB1ddd tbMicroPad lrPad " + ((m_selectedSurveyLQuestionObj.question_type_id === 4) ? ("bgDarkGray") : (""))}
                style={{marginLeft:((m_selectedSurveyLQuestionObj.question_type_id === 4) ? (undefined) : ("1em"))}}>
                <LibraryReact.Nowrap p_fontClass={((m_selectedSurveyLQuestionObj.question_type_id === 4) ? ("fontWhite") : ("fontTextLight"))}>
                  {m_selectedSurveyLQuestionObj.name}
                </LibraryReact.Nowrap>
              </div>
            )
          );
        }

        if(copyingQuestionsTF) {
          copyQuestionsOrCancelButtonsComponent = (
            <div className="displayFlexColumnHcVc">
              <div className="smallBottomMargin">
                <font className="fontItalic">
                  {"Copying Survey Questions..."}
                </font>
              </div>
              <CEGeneralReact.LoadingAnimation />
            </div>
          );
        }
        else {
          copyQuestionsOrCancelButtonsComponent = (
            <>
              <div className="flex00a displayFlexColumnHcVc borderT1ddd borderB1ddd bgLightestGray tbPad">
                <div className="flex00a displayFlexRowVc">
                  <div className="flex00a lrPad">
                    <LibraryReact.CheckBox
                      p_u0_s1_p2_du3_ds4={((s_selectedAlsoCopyInvitationTextTF) ? (1) : (0))}
                      p_sizeEm={undefined}
                      f_onClick={this.onclick_teammate_survey_copy_questions_also_copy_invitation_text_checkbox}
                    />
                  </div>
                  <div className="flex00a lrPad">
                    <font className="fontItalic">
                      {"Also Copy Invitation Text?"}
                    </font>
                  </div>
                </div>
              </div>
              <div className="flex00a displayFlexRowHcVc tbPad">
                {(numSelectedSurveyLoadedQuestions > 0) &&
                  <div className="flex00a lrMedPad">
                    <CEGeneralReact.CEButton
                      p_type="blue"
                      p_text="Copy Questions"
                      f_onClick={this.onclick_copy_questions_button}
                    />
                  </div>
                }
                <div className="flex00a lrMedPad">
                  <CEGeneralReact.CEButton
                    p_type="gray"
                    p_text="Cancel"
                    f_onClick={this.onclick_close_copy_survey_questions_floating_box}
                  />
                </div>
              </div>
            </>

          );
        }
      }
    }
    else if(loadingSelectedCaptureSurveysFromDatabaseTFU === false) { //loading surveys and questions from database
      selectSurveyComponent = (
        <div className="displayFlexColumnHcVc">
          <div className="smallBottomMargin">
            <font className="fontItalic">
              {"Loading Capture Surveys..."}
            </font>
          </div>
          <CEGeneralReact.LoadingAnimation />
        </div>
      );
    }
    else { //undefined - not yet started or database error message
      if(errorMessage !== "") {
        selectSurveyComponent = (
          <div className="textCenter">
            <CEGeneralReact.ErrorText p_text={errorMessage} />
          </div>
        );
      }
      else if(successMessage !== "") {
        selectSurveyComponent = (
          <div className="textCenter">
            <font className="fontDarkGreen">
              {successMessage}
            </font>
          </div>
        );
      }
    }

    return(
      <>
        <CEGeneralReact.CEButton
          p_type="add"
          p_text="Copy Questions from another Survey"
          f_onClick={this.onclick_copy_survey_questions_button_open_floating_box}
        />
        {(o_teammatesSurveysEditQuestionsCopyQuestionsFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="medium"
            p_title="Copy Survey Questions from another Survey"
            f_onClickCancel={this.onclick_close_copy_survey_questions_floating_box}>
            <div className="flex00a smallTopMargin border1bbb bgLightestGray tbPad lrMedPad textCenter" style={{margin:"0.4em 10%"}}>
              <div>
                <font className="fontItalic fontTextLighter">
                  {"Use this feature to select a previously built Survey on another Capture, and copy those questions into this Survey. If this survey already contains questions, the new copied questions will be added after them."}
                </font>
              </div>
              <div>
                <font className="fontItalic fontTextLighter">{"This Capture: "}</font>
                <font className="">{c_captureName}</font>
              </div>
              <div>
                <font className="fontItalic fontTextLighter">{"This Survey: "}</font>
                <font className="">{c_teammatesSurveysSelectedExpandedSurveyObj.title}</font>
              </div>
            </div>
            <div className="flex00a medTopMargin lrMedMargin">
              <font className="fontItalic">
                {"Select which Capture you want to copy existing Survey Questions from:"}
              </font>
            </div>
            <div className="flex00a smallTopMargin lrMedMargin">
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={c_selectAllCapturesNoClearFieldTypeObj}
                p_valueRaw={selectedCaptureID}
                f_onChangeOrOnSelect={this.onselect_teammate_survey_copy_questions_capture_id}
              />
            </div>
            <div className="flex00a bigTopMargin">
              {selectSurveyComponent}
            </div>
            <div className="flex11a yScroll medTopMargin smallTopPad yScrollBottomPad lrMedPad">
              {selectedSurveyQuestionsComponent}
            </div>
            {copyQuestionsOrCancelButtonsComponent}
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));

const TeammatesSurveysEditQuestionItem = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammatesSurveysEditQuestionItem extends Component { //props: p_questionObj, p_isOnlyItemTF, p_isLastItemTF
  constructor(props) {
    super(props);
    this.state = {
      s_editingQuestionTF: false,
      s_oldQuestionName: "",
      s_questionName: ""
    };
  }

  onclick_open_edit_question = () => {
    const p_questionObj = this.props.p_questionObj;
    this.setState({
      s_editingQuestionTF: true,
      s_oldQuestionName: p_questionObj.name,
      s_questionName: p_questionObj.name
    });
  }

  onselect_edit_question_type = (i_questionTypeID) => {
    const p_questionObj = this.props.p_questionObj;
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;
    if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      this.props.OpenCaptureMobx.a_teammates_surveys_update_question_type_id(c_teammatesSurveysSelectedExpandedSurveyObj.title, p_questionObj, i_questionTypeID);
    }
  }

  onchange_question_name = (i_newValue) => {
    this.setState({s_questionName:i_newValue});
  }

  onclick_save_edit_question_text = () => {
    const s_oldQuestionName = this.state.s_oldQuestionName;
    const s_questionName = this.state.s_questionName;

    const p_questionObj = this.props.p_questionObj;

    if(s_questionName !== s_oldQuestionName) { //no need to update database if question name was not changed while this edit was open
      const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;
      if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
        this.props.OpenCaptureMobx.a_teammates_surveys_update_question_text(c_teammatesSurveysSelectedExpandedSurveyObj.title, p_questionObj, s_questionName);
      }
    }

    this.setState({
      s_editingQuestionTF: false,
      s_oldQuestionName: "",
      s_questionName: ""
    });
  }

  ondelete_question = () => {
    const p_questionObj = this.props.p_questionObj;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_teammatesSurveysSelectedExpandedSurveyObj = this.props.OpenCaptureMobx.c_teammatesSurveysSelectedExpandedSurveyObj;

    if(c_teammatesSurveysSelectedExpandedSurveyObj !== undefined) {
      const surveyID = c_teammatesSurveysSelectedExpandedSurveyObj.id;
      this.props.OpenCaptureMobx.a_teammates_surveys_delete_question(o_openCaptureID, surveyID, c_teammatesSurveysSelectedExpandedSurveyObj.title, p_questionObj);
    }
  }

  render() {
    const s_editingQuestionTF = this.state.s_editingQuestionTF;
    const s_oldQuestionName = this.state.s_oldQuestionName;
    const s_questionName = this.state.s_questionName;

    const p_questionObj = this.props.p_questionObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const teammatesSurveysSelectedSurveyID = this.props.OpenCaptureMobx.o_teammatesSurveysSelectedSurveyID;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const questionTypeName = this.props.OpenCaptureMobx.teammates_surveys_question_type_name_from_question_type_id(p_questionObj.question_type_id);
    const bgDarkTF = (p_questionObj.question_type_id === 4);

    return(
      <>
        <CEGeneralReact.CEDragToResortItemWithinDbTbl
          p_canResortTF={c_userCanEditCaptureCardContentTF}
          p_uniqueString="teammatesSurveysQuestionItem"
          p_itemID={p_questionObj.id}
          p_itemSort={p_questionObj.sort}
          p_itemSortColumnWidth="2em"
          p_itemSortNumberFontClass={undefined}
          p_isOnlyItemTF={p_isOnlyItemTF}
          p_isLastItemTF={p_isLastItemTF}
          p_outerPadClass="tbMicroPad"
          p_itemClass="border bevelBorderColors borderRadius05 bgWhite"
          p_itemStyleObj={{height:"3.6em"}}
          p_lastItemExtraDropZoneHeight="3em"
          p_tblName="tbl_c_teammates_surveys_questions"
          p_tblSortFieldDbName="sort"
          p_filterFieldNamesArray={["capture_id", "survey_id"]}
          p_filterValuesArray={[p_questionObj.capture_id, teammatesSurveysSelectedSurveyID]}
          f_onDropForeignItem={undefined}>
          <div
            className="flex11a displayFlexRow hoverLighterBlueGradient cursorPointer"
            onClick={this.onclick_open_edit_question}>
            <div className="flex11a displayFlexRow tbPad">
              <div className={"flex11a displayFlexRowVc lrMedPad " + ((bgDarkTF) ? ("bgDarkGray") : (""))} title={p_questionObj.name}>
                <LibraryReact.MaxHeightWrap p_maxHeight="3em" p_fontClass={"font11 " + ((bgDarkTF) ? ("fontItalic fontWhite") : (""))}>
                  {p_questionObj.name}
                </LibraryReact.MaxHeightWrap>
              </div>
            </div>
            <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"15em"}} title={questionTypeName}>
              <div className="">
                <font className="fontItalic fontTextLighter">
                  {"Question Type"}
                </font>
              </div>
              <div className="microTopMargin">
                <font className="font11 fontBold fontTextLighter">
                  {questionTypeName}
                </font>
              </div>
            </div>
          </div>
          {(c_userCanEditCaptureCardContentTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
              <CEGeneralReact.DeleteMenu
                p_message={"Are you sure you want to delete Question '" + p_questionObj.name + "'?"}
                f_onDelete={this.ondelete_question}
              />
            </div>
          }
        </CEGeneralReact.CEDragToResortItemWithinDbTbl>
        {(s_editingQuestionTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="smallVertical"
            p_title="Edit Question"
            f_onClickSave={((c_userCanEditCaptureCardContentTF) ? (this.onclick_save_edit_question_text) : (undefined))}
            f_onClickCancel={((c_userCanEditCaptureCardContentTF) ? (undefined) : (this.onclick_save_edit_question_text))}>
            <div className="flex11a displayFlexColumn medFullPad">
              <div className="flex00a microBottomMargin">
                <font className="fontItalic fontTextLight">
                  {"Question Type"}
                </font>
              </div>
              <div className="flex00a hugeBottomMargin">
                <CEGeneralReact.TabsList
                  p_tabDbNamesArray={[1,2,3,4]}
                  p_tabDisplayNamesArray={["Yes/No", "Scale 0-5", "Text Response", "Survey Instructions Block"]}
                  p_selectedTabDbName={p_questionObj.question_type_id}
                  p_tabHeight="2.9em"
                  p_textMaxHeight="2.8em"
                  p_tabWidth="10em"
                  p_unselectedFontClass="fontBold fontTextLight"
                  p_unselectedBgClass="bgLighterGrayGradient"
                  p_selectedBgClass="bgOrangeGradient"
                  p_rowFlexWrapTF={true}
                  p_canEditTF={c_userCanEditCaptureCardContentTF}
                  p_tabSpacing="0.4em"
                  f_onSelect={this.onselect_edit_question_type}
                />
              </div>
              <div className="flex00a microBottomMargin">
                <font className="fontItalic fontTextLight">
                  {"Question Text"}
                </font>
              </div>
              {(c_userCanEditCaptureCardContentTF) ? (
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
                  p_valueRaw={s_questionName}
                  p_tabIndex={1}
                  f_onChangeOrOnSelect={this.onchange_question_name}
                />
              ) : (
                <div className="flex11a border1bbb bgWhite smallFullPad yScroll">
                  <font className="">
                    {s_questionName}
                  </font>
                </div>
              )}
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));


const TeammatesSurveysInvitationTextEdit = inject("OpenCaptureMobx")(observer(
class TeammatesSurveysInvitationTextEdit extends Component { //props: p_teammatesSurveysSelectedExpandedSurveyObj, f_onClose
  constructor(props) {
    super(props);

    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;

    this.state = {
      s_invitationText: p_teammatesSurveysSelectedExpandedSurveyObj.invitation_text
    }
  }

  onchange_invitation_text = (i_newValue) => {
    this.setState({s_invitationText:i_newValue});
  }

  onclick_save_invitation_text = () => {
    const s_invitationText = this.state.s_invitationText;
    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;
    this.props.OpenCaptureMobx.a_teammates_surveys_update_survey_invitation_text(p_teammatesSurveysSelectedExpandedSurveyObj.capture_id, p_teammatesSurveysSelectedExpandedSurveyObj.id, s_invitationText);
    this.onclick_cancel_invitation_text();
  }

  onclick_cancel_invitation_text = () => {
    if(JSFUNC.is_function(this.props.f_onClose)) {
      this.props.f_onClose();
    }
  }

  render() {
    const s_invitationText = this.state.s_invitationText;

    const p_teammatesSurveysSelectedExpandedSurveyObj = this.props.p_teammatesSurveysSelectedExpandedSurveyObj;

    return(
      <>
        <div className="" style={{height:"10em"}}>
          <LibraryReact.Textarea
            p_value={s_invitationText}
            p_tabIndex={1}
            f_onChange={this.onchange_invitation_text}
          />
        </div>
        <div className="displayFlexRowVc flexWrap smallTopMargin">
          <div className="flex00a rMargin">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Save Invitation Text"
              f_onClick={this.onclick_save_invitation_text}
            />
          </div>
          <div className="flex00a lrMargin">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text="Cancel"
              f_onClick={this.onclick_cancel_invitation_text}
            />
          </div>
          <div className="flex11a" />
          <div className="flex00a lrMargin">
            <AdminReact.ViewAllTemplateCodewordsButtonWithFloatingBox />
          </div>
        </div>
      </>
    );
  }
}));






const TeammatesRightSummary = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class TeammatesRightSummary extends Component { //props:
  render() {
    const c_captureTypeIsPrimeTF = this.props.OpenCaptureMobx.c_captureTypeIsPrimeTF;
    const c_captureOurPrimeSubTeammateAllocationPercent0to100 = this.props.OpenCaptureMobx.c_captureOurPrimeSubTeammateAllocationPercent0to100;
    const c_captureTotalSmallBusinessAllocationPercent0to100 = this.props.OpenCaptureMobx.c_captureTotalSmallBusinessAllocationPercent0to100;
    const c_selectedLargeBusinessTeammatesArrayOfObjs = this.props.OpenCaptureMobx.c_selectedLargeBusinessTeammatesArrayOfObjs;
    const c_selectedSmallBusinessTeammatesArrayOfObjs = this.props.OpenCaptureMobx.c_selectedSmallBusinessTeammatesArrayOfObjs;
    const c_teammatesSBCertificationAllocationsArrayOfObjs = this.props.OpenCaptureMobx.c_teammatesSBCertificationAllocationsArrayOfObjs;
    const c_teammatesOurPrimeSubTeammateDivisionMap = this.props.OpenCaptureMobx.c_teammatesOurPrimeSubTeammateDivisionMap;

    const c_contractOverallValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_contractOverallValueCaptureFieldValueObj;

    const covFieldDisplayName = c_contractOverallValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name");
    const captureCovValueRaw = c_contractOverallValueCaptureFieldValueObj.valueRaw;
    const captureCovValueMask = c_contractOverallValueCaptureFieldValueObj.valueMask;

    const nameColWidthEm = 20;
    const allocColWidthEm = 10;
    const percentColWidthEm = 10;
    const sbCertsColWidthEm = 30;

    const tblObj = {
      titleFontClass: "font11 fontBold fontTextLight",
      subTitleFontClass: "fontBold",
      rowHeight: "1.5em",
      nameColWidth: nameColWidthEm + "em",
      allocColWidth: allocColWidthEm + "em",
      percentColWidth: percentColWidthEm + "em",
      sbCertsColWidth: sbCertsColWidthEm + "em"
    };

    const ourDivisionName = c_teammatesOurPrimeSubTeammateDivisionMap.get("name");
    const ourDivisionIsSmallBusinessTF = c_teammatesOurPrimeSubTeammateDivisionMap.get("divisionIsSmallBusinessTF");

    var totalUsLargeAndLBPercent = 0;
    var totalUsSmallAndSBPercent = 0;
    if(JSFUNC.text_or_number_is_filled_out_tf(c_captureOurPrimeSubTeammateAllocationPercent0to100)) {
      if(ourDivisionIsSmallBusinessTF) {
        totalUsSmallAndSBPercent += c_captureOurPrimeSubTeammateAllocationPercent0to100;
      }
      else {
        totalUsLargeAndLBPercent += c_captureOurPrimeSubTeammateAllocationPercent0to100;
      }
    }

    for(let lbTeammateObj of c_selectedLargeBusinessTeammatesArrayOfObjs) {
      if(JSFUNC.text_or_number_is_filled_out_tf(lbTeammateObj.allocation_percent)) {
        totalUsLargeAndLBPercent += lbTeammateObj.allocation_percent;
      }
    }

    for(let sbTeammateObj of c_selectedSmallBusinessTeammatesArrayOfObjs) {
      if(JSFUNC.text_or_number_is_filled_out_tf(sbTeammateObj.allocation_percent)) {
        totalUsSmallAndSBPercent += sbTeammateObj.allocation_percent;
      }
    }

    return(
      <div className="flex11a xyScroll yScrollBottomPad bgWhite">
        <div className="tbMargin lrMedMargin bgWhite" style={{width:(nameColWidthEm + allocColWidthEm + percentColWidthEm + sbCertsColWidthEm + 3) + "em"}}>
          <div className="smallTopMargin">
            <font className="font12 fontTextLight">
              <font className="fontItalic">{covFieldDisplayName + ": "}</font>{captureCovValueMask}
            </font>
          </div>
          <div className="smallTopMargin">
            <TeammatesAllocationCircleGraph p_isSnapshotTF={false} />
          </div>
          {(c_captureTypeIsPrimeTF) &&
            <>
              <div className="smallTopMargin">
                <div className="" style={{width:(nameColWidthEm + allocColWidthEm + percentColWidthEm) + "em", border:"solid 1px #ddd"}}>
                  <div className="lrPad bgPanelLightGray" style={{height:tblObj.rowHeight}}>
                    <font className={tblObj.titleFontClass}>{"Large Business Teammates"}</font>
                  </div>
                  <div className="displayFlexRow borderB1ddd" style={{height:tblObj.rowHeight}}>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.nameColWidth, borderRight:"solid 1px #eee"}}><font className={tblObj.subTitleFontClass}>{"Company Name"}</font></div>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.allocColWidth, borderRight:"solid 1px #eee"}}><font className={tblObj.subTitleFontClass}>{"$ Allocation"}</font></div>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.percentColWidth}}><font className={tblObj.subTitleFontClass}>{"% Allocation"}</font></div>
                  </div>
                  {(!ourDivisionIsSmallBusinessTF) &&
                    <TeammatesRightTableRow p_tblObj={tblObj} p_captureCovValueRaw={captureCovValueRaw} p_usLbSbTotalFlag="us" p_name={ourDivisionName} p_percent={c_captureOurPrimeSubTeammateAllocationPercent0to100} />
                  }
                  {c_selectedLargeBusinessTeammatesArrayOfObjs.map((m_lbTeammateObj) =>
                    <TeammatesRightTableRow p_tblObj={tblObj} p_captureCovValueRaw={captureCovValueRaw} p_usLbSbTotalFlag="lb" p_name={m_lbTeammateObj.teammateNamePlainText} p_percent={m_lbTeammateObj.allocation_percent} />
                  )}
                  <TeammatesRightTableRow p_tblObj={tblObj} p_captureCovValueRaw={captureCovValueRaw} p_usLbSbTotalFlag="totalLB" p_name="Total" p_percent={totalUsLargeAndLBPercent} />
                </div>
              </div>
              <div className="bigTopMargin">
                <div className="" style={{width:(nameColWidthEm + allocColWidthEm + percentColWidthEm + sbCertsColWidthEm) + "em", border:"solid 1px #ddd"}}>
                  <div className="lrPad bgPanelLightGray" style={{height:tblObj.rowHeight}}>
                    <font className={tblObj.titleFontClass}>{"Small Business Teammates"}</font>
                  </div>
                  <div className="displayFlexRow borderB1ddd" style={{height:tblObj.rowHeight}}>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.nameColWidth, borderRight:"solid 1px #eee"}}><font className={tblObj.subTitleFontClass}>{"Company Name"}</font></div>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.allocColWidth, borderRight:"solid 1px #eee"}}><font className={tblObj.subTitleFontClass}>{"$ Allocation"}</font></div>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.percentColWidth}}><font className={tblObj.subTitleFontClass}>{"% Allocation"}</font></div>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.sbCertsColWidth}}><font className={tblObj.subTitleFontClass}>{"SB Certifications"}</font></div>
                  </div>
                  {(ourDivisionIsSmallBusinessTF) &&
                    <TeammatesRightTableRow p_tblObj={tblObj} p_captureCovValueRaw={captureCovValueRaw} p_usLbSbTotalFlag="us" p_name={ourDivisionName} p_percent={c_captureOurPrimeSubTeammateAllocationPercent0to100} p_sbCerts={c_teammatesOurPrimeSubTeammateDivisionMap.get("calcPrimeSBCertificationsShortNamesMask")} />
                  }
                  {c_selectedSmallBusinessTeammatesArrayOfObjs.map((m_sbTeammateObj) =>
                    <TeammatesRightTableRow p_tblObj={tblObj} p_captureCovValueRaw={captureCovValueRaw} p_usLbSbTotalFlag="sb" p_name={m_sbTeammateObj.teammateNamePlainText} p_percent={m_sbTeammateObj.allocation_percent} p_sbCerts={m_sbTeammateObj.sbCertificationsMask} />
                  )}
                  <TeammatesRightTableRow p_tblObj={tblObj} p_captureCovValueRaw={captureCovValueRaw} p_usLbSbTotalFlag="totalSB" p_name="Total" p_percent={totalUsSmallAndSBPercent} p_sbCerts="" p_totalOutOfPercent={c_captureTotalSmallBusinessAllocationPercent0to100} />
                </div>
              </div>
              <div className="bigTopMargin">
                <div className="" style={{width:(nameColWidthEm + allocColWidthEm + percentColWidthEm) + "em", border:"solid 1px #ddd"}}>
                  <div className="lrPad bgPanelLightGray" style={{height:tblObj.rowHeight}}>
                    <font className={tblObj.titleFontClass}>{"Small Business Certification Requirements"}</font>
                  </div>
                  <div className="displayFlexRow borderB1ddd" style={{height:tblObj.rowHeight}}>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.nameColWidth, borderRight:"solid 1px #eee"}}><font className={tblObj.subTitleFontClass}>{"SB Certification"}</font></div>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.allocColWidth, borderRight:"solid 1px #eee"}}><font className={tblObj.subTitleFontClass}>{"$ Allocation"}</font></div>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:tblObj.percentColWidth}}><font className={tblObj.subTitleFontClass}>{"% Allocation"}</font></div>
                  </div>
                  {c_teammatesSBCertificationAllocationsArrayOfObjs.map((m_sbCertObj, m_index) =>
                    <TeammatesRightTableRow p_tblObj={tblObj} p_captureCovValueRaw={captureCovValueRaw} p_usLbSbTotalFlag="totalCert" p_name={m_sbCertObj.sbCertificationObj.shortNameAndNamePlainText} p_percent={m_sbCertObj.sbCertAchievedPercent} p_totalOutOfPercent={m_sbCertObj.allocation_percent} />
                  )}
                </div>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}));

const TeammatesRightTableRow = inject("DatabaseMobx")(observer(
class TeammatesRightTableRow extends Component { //props: p_tblObj, p_captureCovValueRaw, p_usLbSbTotalFlag, p_name, p_percent, p_sbCerts, p_totalOutOfPercent
  render() {
    const tblObj = this.props.p_tblObj;
    var captureCovValueRaw = this.props.p_captureCovValueRaw;
    const usLbSbTotalFlag = this.props.p_usLbSbTotalFlag;
    const name = this.props.p_name;
    const percent = this.props.p_percent;
    const sbCerts = this.props.p_sbCerts;
    const totalOutOfPercent = this.props.p_totalOutOfPercent;

    var rowBgClass = "";
    var businessSizeColorClass = undefined;
    var isTotalTF = false;
    var usePercentColorBarTF = false;
    var totalColor1 = "#bbb";
    var totalColor2 = "#ddd";
    var fontColor = undefined;
    if(usLbSbTotalFlag === "us") {
      businessSizeColorClass = "bgTeammatesUs";
      rowBgClass = "bgLighterBlue fontBlue fontBold";
    }
    else if(usLbSbTotalFlag === "lb") {
      businessSizeColorClass = "bgTeammatesLargeBusiness";
    }
    else if(usLbSbTotalFlag === "sb") {
      businessSizeColorClass = "bgTeammatesSmallBusiness";
    }
    else if(usLbSbTotalFlag === "total") {
      isTotalTF = true;
      usePercentColorBarTF = true;
      if(percent > 99.99999999 && percent < 100.00000001) {
        totalColor1 = "#ccf1cc";
        totalColor2 = "#ccf1cc";
        fontColor = "#080";
      }
      else if(percent > 100.00000001) {
        totalColor1 = "#fdd";
        totalColor2 = "#fdd";
        fontColor = "#b00";
      }
    }
    else if(usLbSbTotalFlag === "totalLB" || usLbSbTotalFlag === "totalSB" || usLbSbTotalFlag === "totalCert") {
      isTotalTF = (usLbSbTotalFlag === "totalLB" || usLbSbTotalFlag === "totalSB");
      usePercentColorBarTF = (usLbSbTotalFlag === "totalSB" || usLbSbTotalFlag === "totalCert");
      if(percent < (totalOutOfPercent - 0.00000001)) {
        totalColor1 = "#daa";
        totalColor2 = "#edd";
        fontColor = "#b00";
      }
      else {
        totalColor1 = "#ccf1cc";
        totalColor2 = "#ccf1cc";
        fontColor = "#080";
      }
    }

    if(!JSFUNC.text_or_number_is_filled_out_tf(captureCovValueRaw)) {
      captureCovValueRaw = 0;
    }

    var allocMask = "$0";
    if(JSFUNC.text_or_number_is_filled_out_tf(percent) && percent > 0) {
      const alloc = ((percent / 100) * captureCovValueRaw);
      allocMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(alloc, this.props.DatabaseMobx.c_genericMoneyFieldTypeObj);
    }

    const allocNumDecimals = this.props.DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateAllocation.get("num_decimals"); //for our allocation and teammates allocation, use the number of decimals specified by the capture field our_prime_sub_teammate_allocation

    return(
      <div
        className={"displayFlexRow hoverLighterBlueGradient " + rowBgClass}
        style={{height:tblObj.rowHeight, borderTop:((isTotalTF) ? ("solid 1px #eee") : (undefined))}}>
        <div className="flex00a displayFlexRow" style={{flexBasis:tblObj.nameColWidth}}>
          {(businessSizeColorClass !== undefined) &&
            <div className={"flex00a " + businessSizeColorClass} style={{flexBasis:"0.5em"}} />
          }
          <div className="flex11a displayFlexRowVc lrPad" title={this.props.p_name}>
            <LibraryReact.Nowrap p_fontClass={((isTotalTF) ? ("fontBold fontItalic fontTextLight") : (undefined))}>
              {this.props.p_name}
            </LibraryReact.Nowrap>
          </div>
        </div>
        <div className="flex11a displayFlexRowVc">
          <div className="flex00a lrPad" style={{flexBasis:tblObj.allocColWidth}}>
            <div className="textRight">
              {allocMask}
            </div>
          </div>
          <div className="flex00a textCenter lrPad" style={{flexBasis:tblObj.percentColWidth}}>
            {(usePercentColorBarTF) ? (
              <LibraryReact.TwoColorDiv
                p_bgColor={totalColor1}
                p_color2={totalColor2}
                p_color2Percent={((percent / totalOutOfPercent) * 100)}
                p_color2Direction={90}
                p_class="fontBold"
                p_styleObj={{color:fontColor}}>
                {JSFUNC.round_number_to_num_decimals_if_needed(percent, allocNumDecimals) + "% / " + JSFUNC.round_number_to_num_decimals_if_needed(totalOutOfPercent, allocNumDecimals) + "%"}
              </LibraryReact.TwoColorDiv>
            ) : (
              JSFUNC.round_number_to_num_decimals_if_needed(percent, allocNumDecimals) + "%"
            )}
          </div>
          {(sbCerts !== undefined) &&
            <div className="flex00a lrPad" style={{flexBasis:tblObj.sbCertsColWidth}}>
              <LibraryReact.Nowrap>
                {sbCerts}
              </LibraryReact.Nowrap>
            </div>
          }
        </div>
      </div>
    );
  }
}));


const TeammatesAllocationCircleGraph = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammatesAllocationCircleGraph extends Component { //props: p_isSnapshotTF
  render() {
    const p_isSnapshotTF = this.props.p_isSnapshotTF;

    const c_captureTypeIsPrimeTF = this.props.OpenCaptureMobx.c_captureTypeIsPrimeTF;
    const c_captureOurPrimeSubTeammateAllocationPercent0to100 = this.props.OpenCaptureMobx.c_captureOurPrimeSubTeammateAllocationPercent0to100;
    const c_primeCompanyCaptureFieldValueObj = this.props.OpenCaptureMobx.c_primeCompanyCaptureFieldValueObj;
    const c_teammatesSubCaptureTypeAllPrimesTotalPercent = this.props.OpenCaptureMobx.c_teammatesSubCaptureTypeAllPrimesTotalPercent;
    const c_teammatesSelectedLargeBusinessesTotalPercent = this.props.OpenCaptureMobx.c_teammatesSelectedLargeBusinessesTotalPercent;
    const c_teammatesSelectedSmallBusinessesTotalPercent = this.props.OpenCaptureMobx.c_teammatesSelectedSmallBusinessesTotalPercent;
    const c_selectedLargeBusinessTeammatesArrayOfObjs = this.props.OpenCaptureMobx.c_selectedLargeBusinessTeammatesArrayOfObjs;
    const c_selectedSmallBusinessTeammatesArrayOfObjs = this.props.OpenCaptureMobx.c_selectedSmallBusinessTeammatesArrayOfObjs;
    const c_teammatesOurPrimeSubTeammateDivisionMap = this.props.OpenCaptureMobx.c_teammatesOurPrimeSubTeammateDivisionMap;
    const c_fieldMapOfOurPrimeSubTeammateAllocation = this.props.DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateAllocation;
    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const primeColor = "886633";
    const ourColor = "005da3";
    const ourName = c_teammatesOurPrimeSubTeammateDivisionMap.get("name");
    const allocNumDecimals = c_fieldMapOfOurPrimeSubTeammateAllocation.get("num_decimals"); //for our allocation and teammates allocation, use the number of decimals specified by the capture field our_prime_sub_teammate_allocation

    //initialize graph categories (Prime/Our/LB/SB)
    var categoriesArrayOfObjs = [];

    //Prime
    if(!c_captureTypeIsPrimeTF) {
      var primeCompanyLegendLabel = "";
      if(c_primeCompanyCaptureFieldValueObj.isFilledOutTF) {
        primeCompanyLegendLabel = c_primeCompanyCaptureFieldValueObj.valueMaskPlainText + " (+ Prime's Teammates)";
      }
      else {
        primeCompanyLegendLabel = c_primeCompanyCaptureFieldValueObj.valueMask; //--No Prime Company Selected-- displayed in light italics font tag
      }

      categoriesArrayOfObjs.push({percent0to100:c_teammatesSubCaptureTypeAllPrimesTotalPercent, color:primeColor, label:primeCompanyLegendLabel});//alert("p: " + c_teammatesSubCaptureTypeAllPrimesTotalPercent)
    }

    //Our
    categoriesArrayOfObjs.push({percent0to100:c_captureOurPrimeSubTeammateAllocationPercent0to100, color:ourColor, label:ourName});

    //LB
    const numSelectedLBTeammates = c_selectedLargeBusinessTeammatesArrayOfObjs.length;
    const largeBusinessesLabel = "(" + numSelectedLBTeammates + ") " + ((p_isSnapshotTF) ? ("LB") : ("Large Business")) + " " + JSFUNC.plural(numSelectedLBTeammates, "Teammate", "Teammates");
    categoriesArrayOfObjs.push({percent0to100:c_teammatesSelectedLargeBusinessesTotalPercent, color:"884488", label:largeBusinessesLabel});

    //SB
    const numSelectedSBTeammates = c_selectedSmallBusinessTeammatesArrayOfObjs.length;
    const smallBusinessesLabel = "(" + numSelectedSBTeammates + ") " + ((p_isSnapshotTF) ? ("SB") : ("Small Business")) + " " + JSFUNC.plural(numSelectedSBTeammates, "Teammate", "Teammates");
    categoriesArrayOfObjs.push({percent0to100:c_teammatesSelectedSmallBusinessesTotalPercent, color:"229999", label:smallBusinessesLabel});

    return(
      <LibraryReact.CircleGraph
        p_categoriesArrayOfObjs={categoriesArrayOfObjs}
        p_heightPx={(11 * c_userFontSizePx)}
        p_titlesTF={(!p_isSnapshotTF)}
        p_legendTF={true}
        p_legendTitle={undefined}
        p_legendTotalValueLabel={undefined}
        p_legendTotalClickReturnValue={undefined}
        p_fontSizePx={c_userFontSizePx}
        p_vacantLabel="Unallocated"
        p_vacantColor={"333333"}
        p_maxNumDecimals={allocNumDecimals}
        p_innerCircleSize0to1={0.65}
        p_innerCircleColor="e3e3e3"
        p_innerCircleTotalLabelTF={true}
        f_onClickSegment={undefined}
      />
    );
  }
}));









//===================================================================================================================================
//--------Competitors-------------------------------------------------------------------------------------------
//===================================================================================================================================
const CompetitorsSnapshot = inject("OpenCaptureMobx")(observer(
class CompetitorsSnapshot extends Component {
  render() {
    const c_competitorsArrayOfObjs = this.props.OpenCaptureMobx.c_competitorsArrayOfObjs;
    const c_captureWeAreAnIncumbentTF = this.props.OpenCaptureMobx.c_captureWeAreAnIncumbentTF;
    const c_ourIncumbentCompetitorDivisionIDCaptureFieldValueObj = this.props.OpenCaptureMobx.c_ourIncumbentCompetitorDivisionIDCaptureFieldValueObj;

    const numCompetitors = c_competitorsArrayOfObjs.length;

    return(
      <div className="flex11a displayFlexRow">
        <div className="flex11a displayFlexColumnHcVc textCenter" style={{flexBasis:"100em", borderRight:"solid 1px #eee"}}>
          <div className="font30 fontItalic fontTextLightester">
            {numCompetitors}
          </div>
          <font className="font12 fontItalic fontTextLighter">
            <div>{JSFUNC.plural(numCompetitors, "Competitor", "Competitors")}</div>
            <div>{"Identified"}</div>
          </font>
        </div>
        <div className="flex11a overflowHidden" style={{flexBasis:"175em"}}>
          {(c_captureWeAreAnIncumbentTF) &&
            <div className="tbMicroPad lrPad textCenter">
              <div className="">
                <font className="fontBlue fontItalic">
                  {"We are the Incumbent"}
                </font>
              </div>
              <LibraryReact.Nowrap p_fontClass="fontBlue fontItalic">
                {c_ourIncumbentCompetitorDivisionIDCaptureFieldValueObj.valueMaskPlainText}
              </LibraryReact.Nowrap>
            </div>
          }
          {c_competitorsArrayOfObjs.map((m_competitorObj) =>
            <CompetitorSnapshotItem
              key={m_competitorObj.id}
              p_competitorObj={m_competitorObj}
            />
          )}
        </div>
      </div>
    );
  }
}));

const CompetitorSnapshotItem = inject("OpenCaptureMobx")(observer(
class CompetitorSnapshotItem extends Component { //props: p_competitorObj
  render() {
    const p_competitorObj = this.props.p_competitorObj;

    const c_captureIncumbentContactCompanyIDsArray = this.props.OpenCaptureMobx.c_captureIncumbentContactCompanyIDsArray;

    const competitorIsMarkedAsIncumbentTF = JSFUNC.in_array(p_competitorObj.contact_company_id, c_captureIncumbentContactCompanyIDsArray);

    return(
      <div
        className={"displayFlexColumn smallFullMargin lrPad border " + ((competitorIsMarkedAsIncumbentTF) ? ("borderColorDarkRed") : ("bevelBorderColors"))}
        style={{height:"3em", backgroundColor:"#fbfbfb"}}>
        {(competitorIsMarkedAsIncumbentTF) &&
          <div className="flex00a textCenter">
            <font className="font09 fontItalic fontDarkRed">
              {"Incumbent"}
            </font>
          </div>
        }
        <div className={"flex11a " + ((competitorIsMarkedAsIncumbentTF) ? ("") : ("displayFlexColumnVc"))}>
          <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLight">
            {p_competitorObj.sort + ". " + p_competitorObj.contactCompanyObj.abbrOrLegalName}
          </LibraryReact.Nowrap>
        </div>
      </div>
    );
  }
}));

const Competitors = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class Competitors extends Component { //props: p_container
  onadd_selected_competitor_contact_companies = (i_contactCompanyIDsComma) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_competitors_insert_new_competitors(o_openCaptureID, i_contactCompanyIDsComma);
  }

  render() {
    const p_container = this.props.p_container;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_competitorsArrayOfObjs = this.props.OpenCaptureMobx.c_competitorsArrayOfObjs;
    const c_competitorsContactCompanyIDsArray = this.props.OpenCaptureMobx.c_competitorsContactCompanyIDsArray;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const weAreIncumbentBoxIsFullWidthTF = this.props.OpenCaptureMobx.competitors_we_are_incumbent_box_is_full_width_tf(p_container);

    const numCompetitors = c_competitorsArrayOfObjs.length;
    const usFakeCompetitorObj = {id:-2, contact_company_id:-2}; //fake competitorObj for us as the incumbent with special contact_company_id of -2 for the incumbent

    return(
      <div className="flex11a displayFlexColumn bgCardGrayBg">
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="flex00a tbMedPad" style={{borderBottom:"solid 1px #999"}}>
            <CEGeneralReact.CaptureExecFieldAddNewItemsButton
              p_buttonType="blue"
              p_buttonText="Add Competitor(s)"
              p_addInstructions="Select Competitor Companies to add to this Capture"
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj}
              p_valuesToNotIncludeArray={c_competitorsContactCompanyIDsArray}
              p_tabIndex={1}
              f_onAddSelectedItems={this.onadd_selected_competitor_contact_companies}
            />
          </div>
        }
        <div className="flex11a yScroll yScrollBottomPad smallTopMargin">
          <div className="displayFlexRow lrMedMargin smallBottomMargin">
            <div className="flex11a lrMedPad tbMedPad border bevelBorderColors borderRadius05 bgLighterBlueGradient rMargin" style={{flexBasis:((weAreIncumbentBoxIsFullWidthTF) ? ("1000em") : ("75em"))}}>
              <CompetitorExpandedTileIncumbent
                p_teammateTrueCompetitorFalse={false}
                p_container={p_container}
                p_captureID={o_openCaptureID}
                p_contactCompanyID={usFakeCompetitorObj.contact_company_id}
              />
            </div>
            <div className="flex11a" style={{flexBasis:"100em"}} />
          </div>
          {c_competitorsArrayOfObjs.map((m_competitorObj, m_index) =>
            <CompetitorItem
              key={m_competitorObj.id}
              p_container={p_container}
              p_captureID={o_openCaptureID}
              p_competitorObj={m_competitorObj}
              p_isOnlyItemTF={(numCompetitors === 1)}
              p_isLastItemTF={(m_index === (numCompetitors - 1))}
            />
          )}
        </div>
      </div>
    );
  }
}));

const CompetitorItem = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class CompetitorItem extends Component { //props: p_container, p_captureID, p_competitorObj, p_isOnlyItemTF, p_isLastItemTF
  onclick_expand_collapse_competitor = () => {
    const p_competitorObj = this.props.p_competitorObj;
    this.props.OpenCaptureMobx.a_set_expanded_competitor_id(p_competitorObj.id);
  }

  ondelete_competitor = () => {
    const p_captureID = this.props.p_captureID;
    const p_competitorObj = this.props.p_competitorObj;
    this.props.OpenCaptureMobx.a_competitors_delete_competitor(p_captureID, p_competitorObj);
  }

  render() {
    const p_container = this.props.p_container;
    const p_captureID = this.props.p_captureID;
    const p_competitorObj = this.props.p_competitorObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const o_competitorExpandedID = this.props.OpenCaptureMobx.o_competitorExpandedID;
    const c_captureIncumbentContactCompanyIDsArray = this.props.OpenCaptureMobx.c_captureIncumbentContactCompanyIDsArray;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isExpandedTF = (o_competitorExpandedID === p_competitorObj.id);
    const competitorIsMarkedAsIncumbentTF = JSFUNC.in_array(p_competitorObj.contact_company_id, c_captureIncumbentContactCompanyIDsArray);

    var bgColorClass = "bgTeammatesTeammateBgExpanded";
    if(isExpandedTF) {
      bgColorClass = "ticketResponseFromBITUnread";
    }

    const collapsedShowSwotTF = this.props.OpenCaptureMobx.competitors_collapsed_show_swot_tf(p_container);
    const swotArrayOfObjs = [
      {letter: "S", db_name: "strengths", display_name:"Strengths"},
      {letter: "W", db_name: "weaknesses", display_name:"Weaknesses"},
      {letter: "O", db_name: "opportunities", display_name:"Opportunities"},
      {letter: "T", db_name: "threats", display_name:"Threats"}
    ];

    const ratingAndNameComponent = (
      <div className="displayFlexRowVc">
        <div className="flex11a">
          <ContactsReact.ContactBubble
            p_isPersonTF={false}
            p_contactID={p_competitorObj.contactCompanyObj.id}
            p_iconSizeEm={2}
            p_fontClass="font11 fontBold fontTextLight"
          />
        </div>
      </div>
    );

    var competitorsTeammateNamesCommaList = "";
    if(!isExpandedTF) {
      competitorsTeammateNamesCommaList = this.props.DatabaseMobx.value_mask_no_click_links_from_value_raw_and_field_type_obj(p_competitorObj.teammates_contact_company_ids_comma, this.props.DatabaseMobx.c_selectMultiContactCompaniesFieldTypeObj);
    }

    const expandCollapseDeleteComponent = (
      <div className="displayFlexRowVc">
        <div className="flex00a lrMedPad">
          <CEGeneralReact.ButtonExpandCollapse
            p_isExpandedTF={isExpandedTF}
            p_sizeEm={2.2}
            f_onClick={this.onclick_expand_collapse_competitor}
          />
        </div>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="flex00a rMargin">
            <CEGeneralReact.DeleteMenu
              p_message={"Are you sure you want to remove competitor '" + p_competitorObj.contactCompanyObj.name + "' from this capture?"}
              f_onDelete={this.ondelete_competitor}
            />
          </div>
        }
      </div>
    );

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={c_userCanEditCaptureCardContentTF}
        p_uniqueString="competitor"
        p_itemID={p_competitorObj.id}
        p_itemSort={p_competitorObj.sort}
        p_itemSortColumnWidth={undefined}
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="lrMedPad tbPad"
        p_itemClass={"border bevelBorderLightColors borderRadius05 " + bgColorClass}
        p_itemStyleObj={undefined}
        p_lastItemExtraDropZoneHeight="3em"
        p_tblName="tbl_c_competitors"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={["capture_id"]}
        p_filterValuesArray={[p_captureID]}>
        {(!isExpandedTF) ? (
          <div className="flex11a displayFlexRow" style={{height:"5em"}}>
            <div className="flex11a displayFlexRowVc lrMargin" style={{flexBasis:"200em"}}>
              <div className="flex11a">
                {ratingAndNameComponent}
                <div className="smallTopMargin displayFlexRowVc">
                  <div className="flex00a rMargin">
                    <font className="fontItalic fontTextLighter">
                      {"Teammates:"}
                    </font>
                  </div>
                  <div className="flex11a">
                    <LibraryReact.Nowrap>
                      {competitorsTeammateNamesCommaList}
                    </LibraryReact.Nowrap>
                  </div>
                </div>
              </div>
              <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"8em"}}>
                {(competitorIsMarkedAsIncumbentTF) &&
                  <div className="flex00a smallFullPad border borderColorDarkRed">
                    <font className="fontBold fontDarkRed">
                      {"Incumbent"}
                    </font>
                  </div>
                }
              </div>
            </div>
            {(collapsedShowSwotTF) &&
              swotArrayOfObjs.map((m_swotObj) =>
                <CompetitorCollapsedSwot p_letter={m_swotObj.letter} p_fieldDbName={m_swotObj.db_name} p_competitorObj={p_competitorObj} />
              )
            }
            <div className="flex00a displayFlexColumnHcVc borderL1ddd">
              {expandCollapseDeleteComponent}
            </div>
          </div>
        ) : (
          <div className="flex11a smallBottomPad">
            <div className="displayFlexRowVc" style={{height:"5em"}}>
              <div className="flex11a smallFullPad">
                {ratingAndNameComponent}
              </div>
              <div className="flex00a borderL1ddd">
                {expandCollapseDeleteComponent}
              </div>
            </div>
            <CompetitorExpandedContents p_container={p_container} p_captureID={p_captureID} p_competitorObj={p_competitorObj} />
          </div>
        )}
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));

const CompetitorExpandedContents = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class CompetitorExpandedContents extends Component { //props: p_container, p_captureID, p_competitorObj
  render() {
    const p_container = this.props.p_container;
    const p_captureID = this.props.p_captureID;
    const p_competitorObj = this.props.p_competitorObj;

    const competitorsExpandedSingleColumnTrueTwoColumnsFalse = this.props.OpenCaptureMobx.competitors_expanded_single_column_true_two_columns_false(p_container);

    const tileMarginClass = "smallFullMargin";
    const tileClass = "smallBottomMargin smallFullPad bgAlmostWhite border1ddd borderRadius05";
    const labelContainerClass = "smallBottomMargin";
    const labelFontClass = "font12 fontItalic fontTextLighter";

    const swotArrayOfObjs = [
      {letter: "S", db_name: "strengths", display_name:"Strengths"},
      {letter: "W", db_name: "weaknesses", display_name:"Weaknesses"},
      {letter: "O", db_name: "opportunities", display_name:"Opportunities"},
      {letter: "T", db_name: "threats", display_name:"Threats"}
    ];

    var expandedLeftSideTileLabelsArray = [];
    var expandedLeftSideTileComponentsArray = [];
    expandedLeftSideTileLabelsArray.push("Incumbent");
    expandedLeftSideTileComponentsArray.push(<CompetitorExpandedTileIncumbent key="inc" p_teammateTrueCompetitorFalse={false} p_container={p_container} p_captureID={p_captureID} p_contactCompanyID={p_competitorObj.contact_company_id} />);
    expandedLeftSideTileLabelsArray.push("Competitor's Teammates");
    expandedLeftSideTileComponentsArray.push(<CompetitorExpandedTileContacts key="team" p_competitorObj={p_competitorObj} />);
    expandedLeftSideTileLabelsArray.push("Information");
    expandedLeftSideTileComponentsArray.push(<CompetitorExpandedTileAllExtraFieldsList key="info" p_captureID={p_captureID} p_competitorObj={p_competitorObj} />);

    const expandedLeftSideTilesComponent = (
      <LibraryReact.TileComponents
        p_componentsArray={expandedLeftSideTileComponentsArray}
        p_componentLabelsArray={expandedLeftSideTileLabelsArray}
        p_numTilesPerRow={1}
        p_tileMarginClass={tileMarginClass}
        p_tileClass={tileClass}
        p_labelContainerClass={labelContainerClass}
        p_labelFontClass={labelFontClass}
      />
    );

    var expandedRightSideTileLabelsArray = [];
    var expandedRightSideTileComponentsArray = [];
    for(let swotObj of swotArrayOfObjs) {
      expandedRightSideTileLabelsArray.push(swotObj.display_name);
      expandedRightSideTileComponentsArray.push(<CompetitorSwotCard key={swotObj.letter} p_captureID={p_captureID} p_competitorObj={p_competitorObj} p_dbName={swotObj.db_name} p_displayName={swotObj.display_name} />);
    }

    const expandedRightSideTilesComponent = (
      <LibraryReact.TileComponents
        p_componentsArray={expandedRightSideTileComponentsArray}
        p_componentLabelsArray={expandedRightSideTileLabelsArray}
        p_numTilesPerRow={1}
        p_tileMarginClass={tileMarginClass}
        p_tileClass={tileClass}
        p_labelContainerClass={labelContainerClass}
        p_labelFontClass={labelFontClass}
      />
    );

    if(competitorsExpandedSingleColumnTrueTwoColumnsFalse) {
      return(
        <>
          {expandedLeftSideTilesComponent}
          {expandedRightSideTilesComponent}
        </>
      );
    }

    return(
      <div className="displayFlexRow">
        <div className="flex11a" style={{flexBasis:"100em"}}>
          {expandedLeftSideTilesComponent}
        </div>
        <div className="flex11a" style={{flexBasis:"100em"}}>
          {expandedRightSideTilesComponent}
        </div>
      </div>
    );
  }
}));

const CompetitorCollapsedSwot = inject("DatabaseMobx")(observer(
class CompetitorCollapsedSwot extends Component { //props: p_letter, p_fieldDbName, p_competitorObj
  render() {
    const letter = this.props.p_letter;
    const fieldDbName = this.props.p_fieldDbName;
    const p_competitorObj = this.props.p_competitorObj;

    const swotTextMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(p_competitorObj[fieldDbName], this.props.DatabaseMobx.c_genericTextFieldTypeObj);

    return(
      <div className="flex11a displayFlexRowVc" style={{flexBasis:"100em", borderLeft:"solid 1px #ccc"}}>
        <div className="flex00a displayFlexColumnHcVc overflowHidden" style={{flexBasis:"4em", height:"4em"}}>
          <font className="fontItalic" style={{fontSize:"3em", color:"#ccc"}}>
            {letter}
          </font>
        </div>
        <div className="flex11a rMargin">
          <LibraryReact.MaxHeightWrap p_maxHeight="4.2em">
            {swotTextMask}
          </LibraryReact.MaxHeightWrap>
        </div>
      </div>
    );
  }
}));


const CompetitorExpandedTileIncumbent = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class CompetitorExpandedTileIncumbent extends Component { //props: p_teammateTrueCompetitorFalse, p_container, p_captureID, p_contactCompanyID
  onswitch_competitor_is_incumbent = () => {
    const p_captureID = this.props.p_captureID;
    const p_contactCompanyID = this.props.p_contactCompanyID;

    const c_captureIncumbentContactCompanyIDsArray = this.props.OpenCaptureMobx.c_captureIncumbentContactCompanyIDsArray;
    const c_teammatesAllCombinedTeammatesArrayOfObjs = this.props.OpenCaptureMobx.c_teammatesAllCombinedTeammatesArrayOfObjs;
    const c_competitorsArrayOfObjs = this.props.OpenCaptureMobx.c_competitorsArrayOfObjs;
    const c_fieldMapOfIncumbentCompanies = this.props.DatabaseMobx.c_fieldMapOfIncumbentCompanies;

    var updatedIncumbentContactCompanyIDsArray = [];
    var updatedIncumbentContactCompanyIDsComma = "";
    if(JSFUNC.in_array(p_contactCompanyID, c_captureIncumbentContactCompanyIDsArray)) { //competitor was already selected as an incumbent, remove their id from the list to turn the switch off
      updatedIncumbentContactCompanyIDsArray = JSFUNC.remove_all_values_from_array(p_contactCompanyID, c_captureIncumbentContactCompanyIDsArray);
    }
    else { //competitor incumbent switch is being turned on, loop through all teammates/competitors to add them to the incumbent list (so that they are recorded in the comma list in the order they appear on the teammates/competitors card)
      //always put our company first in the list (since the switch is above all other added competitors on the competitors card)
      if((p_contactCompanyID === -2) || JSFUNC.in_array(-2, c_captureIncumbentContactCompanyIDsArray)) {
        updatedIncumbentContactCompanyIDsArray.push(-2);
      }

      //check/add all incumbent teammates
      for(let teammateObj of c_teammatesAllCombinedTeammatesArrayOfObjs) {
        if(!JSFUNC.in_array(teammateObj.contact_company_id, updatedIncumbentContactCompanyIDsArray)) { //if not yet added to the updated array (don't want to double count the same company on the teammate and competitor card)
          if((teammateObj.contact_company_id === p_contactCompanyID) || JSFUNC.in_array(teammateObj.contact_company_id, c_captureIncumbentContactCompanyIDsArray)) { //teammate that just got switched on, or this competitor was already switched on
            updatedIncumbentContactCompanyIDsArray.push(teammateObj.contact_company_id);
          }
        }
      }

      //check/add all incumbent competitors
      for(let competitorObj of c_competitorsArrayOfObjs) {
        if(!JSFUNC.in_array(competitorObj.contact_company_id, updatedIncumbentContactCompanyIDsArray)) { //if not yet added to the updated array (don't want to double count the same company on the teammate and competitor card)
          if((competitorObj.contact_company_id === p_contactCompanyID) || JSFUNC.in_array(competitorObj.contact_company_id, c_captureIncumbentContactCompanyIDsArray)) { //competitor that just got switched on, or this competitor was already switched on
            updatedIncumbentContactCompanyIDsArray.push(competitorObj.contact_company_id);
          }
        }
      }
    }

    updatedIncumbentContactCompanyIDsComma = JSFUNC.convert_array_to_comma_list(updatedIncumbentContactCompanyIDsArray);
    this.props.OpenCaptureMobx.a_details_update_field_value(c_fieldMapOfIncumbentCompanies, updatedIncumbentContactCompanyIDsComma, p_captureID);
  }

  render() {
    const p_teammateTrueCompetitorFalse = this.props.p_teammateTrueCompetitorFalse;
    const p_container = this.props.p_container;
    const p_captureID = this.props.p_captureID;
    const p_contactCompanyID = this.props.p_contactCompanyID;

    const c_captureIncumbentContactCompanyIDsArray = this.props.OpenCaptureMobx.c_captureIncumbentContactCompanyIDsArray;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const weAreIncumbentBoxLabelsAreWrappedTF = this.props.OpenCaptureMobx.competitors_we_are_incumbent_box_labels_are_wrapped_tf(p_container);

    const tileIsUsTF = (p_contactCompanyID === -2);
    const competitorIsMarkedAsIncumbentTF = JSFUNC.in_array(p_contactCompanyID, c_captureIncumbentContactCompanyIDsArray);

    var labelText = "";
    var labelFontClass = "";
    var switchLabelWidth = undefined;
    var ourIncumbentDivisionFieldWidth = undefined;
    var switchSizeEm = 4;
    var switchOnColor = "999";
    if(p_teammateTrueCompetitorFalse) { //teammate styling
      labelText = "Teammate is Incumbent?";
      labelFontClass = "fontBlue";
      switchLabelWidth = "12em";
      ourIncumbentDivisionFieldWidth = "12em";
      switchSizeEm = 3.5;
      switchOnColor = "373";
    }
    else { //competitor styling
      labelText = ((tileIsUsTF) ? ("We are the") : ("Competitor is")) + " Incumbent for this Capture?";
      labelFontClass = ((tileIsUsTF) ? ("font12 fontTextLighter") : ("font11 fontTextLight"));
      if(weAreIncumbentBoxLabelsAreWrappedTF) {
        switchLabelWidth = "8em";
        ourIncumbentDivisionFieldWidth = "8em";
      }
      else {
        switchLabelWidth = ((tileIsUsTF) ? ("22em") : ("13em"));
        ourIncumbentDivisionFieldWidth = "16em";
      }
      switchSizeEm = 4;
      switchOnColor = ((tileIsUsTF) ? ("005da3") : ("aa4444"));
    }
    

    return(
      <>
        <div className="displayFlexRowVc">
          <div className="flex00a rMargin" style={{flexBasis:switchLabelWidth}}>
            <font className={labelFontClass}>
              {labelText}
            </font>
          </div>
          <div className="flex11a lrMargin">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={competitorIsMarkedAsIncumbentTF}
              p_sizeEm={switchSizeEm}
              p_onColor={switchOnColor}
              p_onText="Incumbent"
              p_offText="Not the Incumbent"
              f_onSwitch={((c_userCanEditCaptureCardContentTF) ? (this.onswitch_competitor_is_incumbent) : (undefined))}
            />
          </div>
        </div>
        {(tileIsUsTF && competitorIsMarkedAsIncumbentTF) &&
          <DetailsItem
            p_captureID={p_captureID}
            p_cardID={this.props.DatabaseMobx.k_cardIDCompetitors}
            p_captureFieldValueObj={this.props.OpenCaptureMobx.c_ourIncumbentCompetitorDivisionIDCaptureFieldValueObj}
            p_fieldWidth={ourIncumbentDivisionFieldWidth}
            p_containerClass="smallTopMargin"
            p_fieldClass={undefined}
            p_valueClass={undefined}
          />
        }
      </>
    );
  }
}));


const CompetitorExpandedTileContacts = inject("OpenCaptureMobx", "UserMobx")(observer(
class CompetitorExpandedTileContacts extends Component { //props: p_competitorObj
  onadd_selected_competitor_teammate_contact_companies = (i_newContactCompanyIDsToAddComma) => {
    const p_competitorObj = this.props.p_competitorObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_competitors_add_teammate_contact_companies(o_openCaptureID, p_competitorObj, i_newContactCompanyIDsToAddComma);
  }

  onremove_competitor_teammate_contact_company  = (i_removedContactCompanyID) => {
    const p_competitorObj = this.props.p_competitorObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_competitors_remove_teammate_contact_company(o_openCaptureID, p_competitorObj, i_removedContactCompanyID);
  }

  render() {
    const p_competitorObj = this.props.p_competitorObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <ContactsReact.MultiAddContactBubblesVerticalList
        p_canEditTF={c_userCanEditCaptureCardContentTF}
        p_isPersonTF={false}
        p_addedContactIDsComma={p_competitorObj.teammates_contact_company_ids_comma}
        p_addButtonType="add"
        p_addButtonText="Add Contact Companies"
        p_addInstructions={"Teammate companies for competitor '" + p_competitorObj.contactCompanyObj.abbrOrLegalName + "'"}
        f_onAddContacts={this.onadd_selected_competitor_teammate_contact_companies}
        f_onRemoveContact={this.onremove_competitor_teammate_contact_company}
      />
    );
  }
}));


const CompetitorSwotCard = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class CompetitorSwotCard extends Component { //props: p_captureID, p_competitorObj, p_fieldID, p_displayName, p_dbName
  onsave_changed_item = (i_changedValue) => {
    const p_captureID = this.props.p_captureID;
    const p_competitorObj = this.props.p_competitorObj;
    const p_displayName = this.props.p_displayName;
    const p_dbName = this.props.p_dbName;
    this.props.OpenCaptureMobx.a_competitors_update_changed_field_value(p_captureID, p_competitorObj, p_dbName, p_displayName, "s", i_changedValue);
  }

  render() {
    const p_captureID = this.props.p_captureID;
    const p_competitorObj = this.props.p_competitorObj;
    const p_displayName = this.props.p_displayName;
    const p_dbName = this.props.p_dbName;

    const k_cardIDCompetitors = this.props.DatabaseMobx.k_cardIDCompetitors;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(k_cardIDCompetitors, p_competitorObj.id + p_dbName)}
        p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
        p_valueRaw={p_competitorObj[p_dbName]}
        p_valueIsEditableTFU={((c_userCanEditCaptureCardContentTF) ? (true) : (undefined))}
        p_containerClass="rMargin"
        p_floatingBoxTitle={p_displayName + " for competitor '" + p_competitorObj.contactCompanyObj.abbrOrLegalName + "'"}
        f_onSaveChanged={this.onsave_changed_item}
      />
    );
  }
}));



const CompetitorExpandedTileAllExtraFieldsList = inject("OpenCaptureMobx")(observer(
class CompetitorExpandedTileAllExtraFieldsList extends Component { //props: p_captureID, p_competitorObj
  render() {
    const p_captureID = this.props.p_captureID;
    const p_competitorObj = this.props.p_competitorObj;

    const c_competitorsExtraFieldsArrayOfObjs = this.props.OpenCaptureMobx.c_competitorsExtraFieldsArrayOfObjs;

    return(
      c_competitorsExtraFieldsArrayOfObjs.map((m_competitorExtraFieldObj) =>
        <CompetitorExpandedTileExtraField
          key={m_competitorExtraFieldObj.id}
          p_captureID={p_captureID}
          p_competitorObj={p_competitorObj}
          p_competitorExtraFieldObj={m_competitorExtraFieldObj}
        />
      )
    );
  }
}));


const CompetitorExpandedTileExtraField = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class CompetitorExpandedTileExtraField extends Component { //props: p_captureID, p_competitorObj, p_competitorExtraFieldObj
  onsavechanged_competitor_extra_field = (i_newValue) => {
    const p_captureID = this.props.p_captureID;
    const p_competitorObj = this.props.p_competitorObj;
    const p_competitorExtraFieldObj = this.props.p_competitorExtraFieldObj;
    this.props.OpenCaptureMobx.a_competitors_update_changed_field_value(p_captureID, p_competitorObj, p_competitorExtraFieldObj.db_name, p_competitorExtraFieldObj.display_name, p_competitorExtraFieldObj.fieldTypeObj.idsb, i_newValue);
  }

  render() {
    const p_captureID = this.props.p_captureID;
    const p_competitorObj = this.props.p_competitorObj;
    const p_competitorExtraFieldObj = this.props.p_competitorExtraFieldObj;

    const k_cardIDCompetitors = this.props.DatabaseMobx.k_cardIDCompetitors;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={this.props.OpenCaptureMobx.ce_edit_item_string(k_cardIDCompetitors, p_competitorObj.id + p_competitorExtraFieldObj.db_name)}
        p_fieldDisplayName={p_competitorExtraFieldObj.display_name}
        p_fieldTypeObj={p_competitorExtraFieldObj.fieldTypeObj}
        p_valueRaw={p_competitorObj[p_competitorExtraFieldObj.db_name]}
        p_valueIsEditableTFU={((c_userCanEditCaptureCardContentTF) ? (true) : (undefined))}
        p_containerClass="smallFullMargin"
        p_fieldClass="fontBlue"
        p_fieldWidth="10em"
        p_fieldHoverText={p_competitorExtraFieldObj.hover_text}
        p_fieldDashedUnderlineTF={true}
        p_valueMaxHeight={undefined}
        p_fieldValueVerticallyAlignedTF={true}
        p_floatingBoxTitle={p_competitorExtraFieldObj.display_name + " for Competitor '" + p_competitorObj.contactCompanyObj.abbrOrLegalName + "'"}
        f_onSaveChanged={this.onsavechanged_competitor_extra_field}
      />
    );
  }
}));




//===================================================================================================================================
//--------Proposal Themes-------------------------------------------------------------------------------------------
//===================================================================================================================================
const ProposalThemesSnapshot = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class ProposalThemesSnapshot extends Component {
  render() {
    const c_companyUsingPTDifferentiatorsTF = this.props.DatabaseMobx.c_companyUsingPTDifferentiatorsTF;
    const c_companyUsingPTWinThemesTF = this.props.DatabaseMobx.c_companyUsingPTWinThemesTF;
    const c_companyUsingPTGhostThemesTF = this.props.DatabaseMobx.c_companyUsingPTGhostThemesTF;

    const c_ptDifferentiatorsArrayOfObjs = this.props.OpenCaptureMobx.c_ptDifferentiatorsArrayOfObjs;
    const c_ptWinThemesArrayOfObjs = this.props.OpenCaptureMobx.c_ptWinThemesArrayOfObjs;
    const c_ptGhostThemesArrayOfObjs = this.props.OpenCaptureMobx.c_ptGhostThemesArrayOfObjs;

    const usingToolTFArray = [c_companyUsingPTDifferentiatorsTF, c_companyUsingPTWinThemesTF, c_companyUsingPTGhostThemesTF];
    const numEntriesArray = [c_ptDifferentiatorsArrayOfObjs.length, c_ptWinThemesArrayOfObjs.length, c_ptGhostThemesArrayOfObjs.length];
    const labelsArray = ["Differentiator", "Win Theme", "Ghost Theme"];
    const countFontColorsArray = ["#469", "#985", "#777"];
    const labelFontColorsArray = ["#abc", "#cb8", "#afafaf"];

    return(
      <div className="flex11a displayFlexColumn smallFullPad">
        {usingToolTFArray.map((m_usingToolTF, m_index) =>
          (m_usingToolTF) &&
          <div className="flex11a displayFlexRowVc" style={{flexBasis:"100em", borderBottom:"solid 1px #eee"}}>
            <div className="flex00a lrMedMargin textRight" style={{flexBasis:"3em", color:countFontColorsArray[m_index]}}>
              <LibraryReact.Nowrap p_fontClass="font20">
                {numEntriesArray[m_index]}
              </LibraryReact.Nowrap>
            </div>
            <div className="flex11a" style={{color:labelFontColorsArray[m_index]}}>
              <LibraryReact.Nowrap p_fontClass="font13 fontItalic">
                {JSFUNC.plural(numEntriesArray[m_index], labelsArray[m_index], labelsArray[m_index] + "s")}
              </LibraryReact.Nowrap>
            </div>
          </div>
        )}
      </div>
    );
  }
}));

const ProposalThemes = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class ProposalThemes extends Component { //props: p_container
  render() {
    const p_container = this.props.p_container;

    const c_companyUsingPTDifferentiatorsTF = this.props.DatabaseMobx.c_companyUsingPTDifferentiatorsTF;
    const c_companyUsingPTWinThemesTF = this.props.DatabaseMobx.c_companyUsingPTWinThemesTF;
    const c_companyUsingPTGhostThemesTF = this.props.DatabaseMobx.c_companyUsingPTGhostThemesTF;
    
    const proposalThemesIsVerticalTF = this.props.OpenCaptureMobx.proposal_themes_is_vertical_tf(p_container);

    const usingToolTFArray = [c_companyUsingPTDifferentiatorsTF, c_companyUsingPTWinThemesTF, c_companyUsingPTGhostThemesTF];
    const labelsArray = ["Differentiators", "Win Themes", "Ghost Themes"];
    const winTrueGhostFalseArray = [false, true, false];
    const labelBgColorsArray = ["#469", "#985", "#777"];
    const toolBgColorsArray = ["#dde6fc", "#faf8e0", "#f3f3f3"];

    if(proposalThemesIsVerticalTF) { //for small width screens
      return(
        <div className="flex11a yScroll yScrollBottomPad">
          {usingToolTFArray.map((m_usingToolTF, m_index) =>
            (m_usingToolTF) &&
            <div className="hugeBottomPad" style={{background:toolBgColorsArray[m_index], borderBottom:"solid 1px #bbb"}}>
              <div className="smallBottomMargin">
                <ProposalThemesTitle p_title={labelsArray[m_index]} p_bgColor={labelBgColorsArray[m_index]} />
              </div>
              {(m_index === 0) ? (
                <Differentiators p_container={p_container} />
              ) : (
                <ThemesTable p_container={p_container} p_winTrueGhostFalse={winTrueGhostFalseArray[m_index]} />
              )}
            </div>
          )}
        </div>
      );
    }

    return(
      <div className="flex11a displayFlexRow">
        {usingToolTFArray.map((m_usingToolTF, m_index) =>
          (m_usingToolTF) &&
          <div className="flex11a displayFlexColumn" style={{flexBasis:"100em", background:toolBgColorsArray[m_index], borderRight:"solid 1px #bbb"}}>
            <div className="flex00a" style={{borderBottom:"solid 1px #eee"}}>
              <ProposalThemesTitle p_title={labelsArray[m_index]} p_bgColor={labelBgColorsArray[m_index]} />
            </div>
            <div className="flex11a yScroll yScrollBottomPad smallTopPad lrPad">
              {(m_index === 0) ? (
                <Differentiators p_container={p_container} />
              ) : (
                <ThemesTable p_container={p_container} p_winTrueGhostFalse={winTrueGhostFalseArray[m_index]} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}));

function ProposalThemesTitle(props) { //props: p_title, p_bgColor
  return(
    <div className="smallFullPad" style={{background:props.p_bgColor, color:"#fff"}}>
      <LibraryReact.Nowrap p_fontClass="font12">
        {props.p_title}
      </LibraryReact.Nowrap>
    </div>
  );
}



const Differentiators = inject("OpenCaptureMobx", "UserMobx")(observer(
class Differentiators extends Component { //props: p_container
  onclick_add_differentiator = () => {
    this.props.OpenCaptureMobx.a_pt_differentiators_set_id_adding_or_editing(-1);
  }

  onclose_add_new_differentiator = () => {
    this.props.OpenCaptureMobx.a_pt_differentiators_set_id_adding_or_editing(undefined);
  }

  render() {
    const p_container = this.props.p_container;
    
    const o_ptDifferentiatorIDAddingOrEditing = this.props.OpenCaptureMobx.o_ptDifferentiatorIDAddingOrEditing;
    const c_ptDifferentiatorsArrayOfObjs = this.props.OpenCaptureMobx.c_ptDifferentiatorsArrayOfObjs;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const addingDifferentiatorTF = (o_ptDifferentiatorIDAddingOrEditing === -1);

    const numDifferentiators = c_ptDifferentiatorsArrayOfObjs.length;
    const isOnlyItemTF = (numDifferentiators === 1);

    return(
      <>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="displayFlexColumnHcVc tbMargin">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Add a Differentiator"
              f_onClick={this.onclick_add_differentiator}
            />
            {(addingDifferentiatorTF) &&
              <DifferentiatorAddNewOrEditDifferentiatorFloatingBox
                p_editDifferentiatorObj={undefined}
                f_onClose={this.onclose_add_new_differentiator}
              />
            }
          </div>
        }
        {(numDifferentiators === 0) ? (
          <div className="tbMedMargin textCenter">
            <font className="fontItalic fontTextLighter">
              {"No Differentiators Added Yet"}
            </font>
          </div>
        ) : (
          <div className="flex11a yScroll yScrollBottomPad">
            {c_ptDifferentiatorsArrayOfObjs.map((m_differentiatorObj, m_index) =>
              <DifferentiatorItem
                key={m_differentiatorObj.id}
                p_container={p_container}
                p_differentiatorObj={m_differentiatorObj}
                p_isOnlyItemTF={isOnlyItemTF}
                p_isLastItemTF={(m_index === (numDifferentiators - 1))}
              />
            )}
          </div>
        )}
      </>
    );
  }
}));

const DifferentiatorItem = inject("OpenCaptureMobx", "UserMobx")(observer(
class DifferentiatorItem extends Component { //props: p_container, p_differentiatorObj, p_isOnlyItemTF, p_isLastItemTF
  onclick_edit_differentiator = () => {
    const p_differentiatorObj = this.props.p_differentiatorObj;
    this.props.OpenCaptureMobx.a_pt_differentiators_set_id_adding_or_editing(p_differentiatorObj.id);
  }

  onclose_edit_differentiator = () => {
    this.props.OpenCaptureMobx.a_pt_differentiators_set_id_adding_or_editing(undefined);
  }

  ondelete_differentiator = () => {
    const p_differentiatorObj = this.props.p_differentiatorObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_differentiators_delete_differentiator(o_openCaptureID, p_differentiatorObj);
  }

  render() {
    const p_container = this.props.p_container
    const differentiatorObj = this.props.p_differentiatorObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const o_ptDifferentiatorIDAddingOrEditing = this.props.OpenCaptureMobx.o_ptDifferentiatorIDAddingOrEditing;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const editingDifferentiatorTF = (o_ptDifferentiatorIDAddingOrEditing === differentiatorObj.id);

    const differentiatorItemHeight = "8.6em";

    return(
      <>
        <CEGeneralReact.CEDragToResortItemWithinDbTbl
          p_canResortTF={c_userCanEditCaptureCardContentTF}
          p_uniqueString="differentiators"
          p_itemID={differentiatorObj.id}
          p_itemSort={differentiatorObj.sort}
          p_itemSortColumnWidth={undefined}
          p_itemSortNumberFontClass={undefined}
          p_isOnlyItemTF={p_isOnlyItemTF}
          p_isLastItemTF={p_isLastItemTF}
          p_outerPadClass="medTopPad lrMedPad"
          p_itemClass="smallFullPad bgLightestBlue hoverLighterBlueGradient"
          p_itemStyleObj={{height:differentiatorItemHeight, border:"solid 1px #ddd", borderRadius:"3em"}}
          p_lastItemExtraDropZoneHeight={differentiatorItemHeight}
          p_tblName="tbl_c_pt_differentiators"
          p_tblSortFieldDbName="sort"
          p_filterFieldNamesArray={["capture_id"]}
          p_filterValuesArray={[o_openCaptureID]}>
          <div className="flex11a displayFlexRow cursorPointer" onClick={this.onclick_edit_differentiator}>
            <div className="flex11a displayFlexRowVc lrMargin" style={{flexBasis:"100em"}}>
              <LibraryReact.MaxHeightWrap p_maxHeight="6em">
                {differentiatorObj.differentiatorName}
              </LibraryReact.MaxHeightWrap>
            </div>
            <div className="flex11a displayFlexColumn tbMargin lrPad bgAlmostWhite border bevelBorderColors" style={{flexBasis:"250em", borderRadius:"0.4em"}}>
              <div className="flex00a textCenter">
                <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                  {"Justification"}
                </LibraryReact.Nowrap>
              </div>
              <div className="flex11a displayFlexRowVc">
                <LibraryReact.MaxHeightWrap p_maxHeight="5.3em">
                  {(JSFUNC.text_or_number_is_filled_out_tf(differentiatorObj.justification)) ? (
                    differentiatorObj.justification
                  ) : (
                    <font className="fontItalic fontTextLighter">
                      {"--No Text Entered--"}
                    </font>
                  )}
                </LibraryReact.MaxHeightWrap>
              </div>
            </div>
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em", marginLeft:"0.3em"}}>
            {(c_userCanEditCaptureCardContentTF) &&
              <CEGeneralReact.DeleteMenu
                p_message={"Are you sure you want to remove this Differentiator?\n\n'" + differentiatorObj.differentiatorName + "'"}
                f_onDelete={this.ondelete_differentiator}
              />
            }
          </div>
        </CEGeneralReact.CEDragToResortItemWithinDbTbl>
        {(editingDifferentiatorTF) &&
          <DifferentiatorAddNewOrEditDifferentiatorFloatingBox
            p_editDifferentiatorObj={differentiatorObj}
            f_onClose={this.onclose_edit_differentiator}
          />
        }
      </>
    );
  }
}));

const DifferentiatorAddNewOrEditDifferentiatorFloatingBox = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class DifferentiatorAddNewOrEditDifferentiatorFloatingBox extends Component { //props: p_editDifferentiatorObj, f_onClose
  constructor(props) {
    super(props);

    const p_editDifferentiatorObj = this.props.p_editDifferentiatorObj;

    var differentiator = ""; //initialization for a new differentiator
    var justification = "";
    if(p_editDifferentiatorObj !== undefined) { //editing an existing differentiator obj if it is provided (if not provided, creating a new differentiator)
      differentiator = p_editDifferentiatorObj.differentiator_id;
      justification = p_editDifferentiatorObj.justification;
    }

    this.state = {
      s_initDifferentiator: differentiator,
      s_initJustification: justification,
      s_updatedDifferentiator: differentiator,
      s_updatedJustification: justification
    }
  }

  onsave_differentiator_item = () => {
    const s_initDifferentiator = this.state.s_initDifferentiator;
    const s_initJustification = this.state.s_initJustification;
    const s_updatedDifferentiator = this.state.s_updatedDifferentiator;
    const s_updatedJustification = this.state.s_updatedJustification;

    const p_editDifferentiatorObj = this.props.p_editDifferentiatorObj;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;

    if(p_editDifferentiatorObj === undefined) { //adding a new item
      this.props.OpenCaptureMobx.a_differentiators_insert_new_differentiator(o_openCaptureID, s_updatedDifferentiator, s_updatedJustification);
    }
    else { //editing an item
      var newDifferentiator = ((s_updatedDifferentiator !== s_initDifferentiator) ? (s_updatedDifferentiator) : (undefined));
      var newJustification = ((s_updatedJustification !== s_initJustification) ? (s_updatedJustification) : (undefined));
      this.props.OpenCaptureMobx.a_differentiators_update_differentiator(o_openCaptureID, p_editDifferentiatorObj.id, newDifferentiator, newJustification);
    }

    if(this.props.f_onClose) {
      this.props.f_onClose();
    }
  }

  oncancel_differentiator_item = () => {
    if(this.props.f_onClose) {
      this.props.f_onClose();
    }
  }
  onchange_differentiator = (i_newValue) => { this.setState({s_updatedDifferentiator:i_newValue}); }
  onchange_justification = (i_newValue) => { this.setState({s_updatedJustification:i_newValue}); }

  render() {
    const s_initDifferentiator = this.state.s_initDifferentiator;
    const s_initJustification = this.state.s_initJustification;
    const s_updatedDifferentiator = this.state.s_updatedDifferentiator;
    const s_updatedJustification = this.state.s_updatedJustification;

    const p_editDifferentiatorObj = this.props.p_editDifferentiatorObj;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isAddingNewDifferentiatorTF = (p_editDifferentiatorObj === undefined);
    const anyFieldIsUpdatedTF = ((s_initDifferentiator !== s_updatedDifferentiator) || (s_initJustification !== s_updatedJustification));

    var differentiatorIDsToNotIncludeArray = undefined;
    if(isAddingNewDifferentiatorTF) {
      differentiatorIDsToNotIncludeArray = this.props.OpenCaptureMobx.c_ptDifferentiatorIDsAlreadyAddedArray;
    }
    else {
      differentiatorIDsToNotIncludeArray = JSFUNC.remove_all_values_from_array(p_editDifferentiatorObj.differentiator_id, this.props.OpenCaptureMobx.c_ptDifferentiatorIDsAlreadyAddedArray);
    }

    var differentiatorComponent = null;
    var justificationComponent = null;
    if(c_userCanEditCaptureCardContentTF) {
      differentiatorComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectAddPTDifferentiatorsFieldTypeObj}
          p_valueRaw={s_updatedDifferentiator}
          p_valuesToNotIncludeArray={differentiatorIDsToNotIncludeArray}
          p_tabIndex={2}
          f_onChangeOrOnSelect={this.onchange_differentiator}
        />
      );
  
      justificationComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
          p_valueRaw={s_updatedJustification}
          p_tabIndex={1}
          f_onChangeOrOnSelect={this.onchange_justification}
        />
      );
    }
    else {
      differentiatorComponent = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(s_updatedDifferentiator, this.props.DatabaseMobx.c_selectAddPTDifferentiatorsFieldTypeObj);

      justificationComponent = (
        <div className="flex11a border1bbb bgWhite smallFullPad yScroll">
          <font className="">
            {s_updatedJustification}
          </font>
        </div>
      );
    }

    var floatingBoxContentComponent = null;
    if(c_isMobileOrTabletTF) {
      floatingBoxContentComponent = (
        <div className="flex11a yScroll yScrollBottomPad">
          <div className="bigTopMargin microBottomMargin lrMargin">
            <font className="font11 fontBlue">
              {"Differentiator"}
            </font>
          </div>
          <div className="flex11a displayFlexColumn lrMargin" style={{height:"15em"}}>
            {differentiatorComponent}
          </div>
          <div className="bigTopMargin microBottomMargin lrMargin">
            <font className="font11 fontBlue">
              {"Justification"}
            </font>
          </div>
          <div className="flex11a displayFlexColumn lrMargin" style={{height:"15em"}}>
            {justificationComponent}
          </div>
        </div>
      );
    }
    else {
      floatingBoxContentComponent = (
        <div className="flex11a displayFlexRow">
          <div className="flex11a displayFlexColumn medFullPad" style={{flexBasis:"100em", borderRight:"solid 1px #eee"}}>
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBlue">
                {"Differentiator"}
              </font>
            </div>
            {differentiatorComponent}
          </div>
          <div className="flex11a displayFlexColumn medFullPad" style={{flexBasis:"200em"}}>
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBlue">
                {"Justification"}
              </font>
            </div>
            {justificationComponent}
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="themes"
        p_title={((isAddingNewDifferentiatorTF) ? ("Adding New Differentiator"): ("Editing Differentiator"))}
        p_cancelConfirmationMessage={((anyFieldIsUpdatedTF) ? ("Are you sure you want to cancel editing this Differentiator? Any entered data will be lost.") : (undefined))}
        f_onClickSave={((c_userCanEditCaptureCardContentTF) ? (this.onsave_differentiator_item) : (undefined))}
        f_onClickCancel={this.oncancel_differentiator_item}>
        {floatingBoxContentComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));





const ThemesTable = inject("OpenCaptureMobx", "UserMobx")(observer(
class ThemesTable extends Component { //props: p_container, p_winTrueGhostFalse
  onclick_add_theme = () => {
    if(this.props.p_winTrueGhostFalse) {
      this.props.OpenCaptureMobx.a_pt_win_themes_set_id_adding_or_editing(-1);
    }
    else {
      this.props.OpenCaptureMobx.a_pt_ghost_themes_set_id_adding_or_editing(-1);
    }
  }

  onclose_add_new_theme = () => {
    if(this.props.p_winTrueGhostFalse) {
      this.props.OpenCaptureMobx.a_pt_win_themes_set_id_adding_or_editing(undefined);
    }
    else {
      this.props.OpenCaptureMobx.a_pt_ghost_themes_set_id_adding_or_editing(undefined);
    }
  }

  render() {
    const p_container = this.props.p_container;
    const p_winTrueGhostFalse = this.props.p_winTrueGhostFalse;

    const o_ptWinThemeIDAddingOrEditing = this.props.OpenCaptureMobx.o_ptWinThemeIDAddingOrEditing;
    const o_ptGhostThemeIDAddingOrEditing = this.props.OpenCaptureMobx.o_ptGhostThemeIDAddingOrEditing;
    const c_ptWinThemesArrayOfObjs = this.props.OpenCaptureMobx.c_ptWinThemesArrayOfObjs;
    const c_ptGhostThemesArrayOfObjs = this.props.OpenCaptureMobx.c_ptGhostThemesArrayOfObjs;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const addingThemeTF = ((p_winTrueGhostFalse) ? (o_ptWinThemeIDAddingOrEditing === -1) : (o_ptGhostThemeIDAddingOrEditing === -1));
    const themesArrayOfObjs = ((p_winTrueGhostFalse) ? (c_ptWinThemesArrayOfObjs) : (c_ptGhostThemesArrayOfObjs));
    const winGhostString = ((p_winTrueGhostFalse) ? ("Win") : ("Ghost"));

    const numThemes = themesArrayOfObjs.length;
    const isOnlyItemTF = (numThemes === 1);

    return(
      <>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="displayFlexColumnHcVc tbMargin">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text={"Add a " + winGhostString + " Theme"}
              f_onClick={this.onclick_add_theme}
            />
            {(addingThemeTF) &&
              <ThemeAddNewOrEditThemeFloatingBox
                p_winTrueGhostFalse={p_winTrueGhostFalse}
                p_editThemeObj={undefined}
                f_onClose={this.onclose_add_new_theme}
              />
            }
          </div>
        }
        {(numThemes === 0) ? (
          <div className="tbMedMargin textCenter">
            <font className="fontItalic fontTextLighter">
              {"No " + winGhostString + " Themes Written Yet"}
            </font>
          </div>
        ) : (
          <div className="flex11a yScroll yScrollBottomPad">
            {themesArrayOfObjs.map((m_themeObj, m_index) =>
              <ThemeItem
                key={m_themeObj.id}
                p_container={p_container}
                p_winTrueGhostFalse={p_winTrueGhostFalse}
                p_themeObj={m_themeObj}
                p_isOnlyItemTF={isOnlyItemTF}
                p_isLastItemTF={(m_index === (numThemes - 1))}
              />
            )}
          </div>
        )}
      </>
    );
  }
}));

const ThemeItem = inject("OpenCaptureMobx", "UserMobx")(observer(
class ThemeItem extends Component { //props: p_container, p_winTrueGhostFalse, p_themeObj, p_isOnlyItemTF, p_isLastItemTF
  onclick_edit_theme = () => {
    const p_winTrueGhostFalse = this.props.p_winTrueGhostFalse;
    const p_themeObj = this.props.p_themeObj;
    if(p_winTrueGhostFalse) {
      this.props.OpenCaptureMobx.a_pt_win_themes_set_id_adding_or_editing(p_themeObj.id);
    }
    else {
      this.props.OpenCaptureMobx.a_pt_ghost_themes_set_id_adding_or_editing(p_themeObj.id);
    }
  }

  onclose_edit_theme = () => {
    const p_winTrueGhostFalse = this.props.p_winTrueGhostFalse;
    if(p_winTrueGhostFalse) {
      this.props.OpenCaptureMobx.a_pt_win_themes_set_id_adding_or_editing(undefined);
    }
    else {
      this.props.OpenCaptureMobx.a_pt_ghost_themes_set_id_adding_or_editing(undefined);
    }
  }

  ondelete_theme = () => {
    const p_winTrueGhostFalse = this.props.p_winTrueGhostFalse;
    const p_themeObj = this.props.p_themeObj;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    this.props.OpenCaptureMobx.a_themes_delete_theme(o_openCaptureID, p_winTrueGhostFalse, p_themeObj);
  }

  render() {
    const p_container = this.props.p_container;
    const p_winTrueGhostFalse = this.props.p_winTrueGhostFalse;
    const p_themeObj = this.props.p_themeObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const o_ptWinThemeIDAddingOrEditing = this.props.OpenCaptureMobx.o_ptWinThemeIDAddingOrEditing;
    const o_ptGhostThemeIDAddingOrEditing = this.props.OpenCaptureMobx.o_ptGhostThemeIDAddingOrEditing;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const editingThemeTF = ((p_winTrueGhostFalse) ? (o_ptWinThemeIDAddingOrEditing === p_themeObj.id) : (o_ptGhostThemeIDAddingOrEditing === p_themeObj.id));
    const tblName = ((p_winTrueGhostFalse) ? ("tbl_c_pt_win_themes") : ("tbl_c_pt_ghost_themes"));
    const winGhostString = ((p_winTrueGhostFalse) ? ("Win") : ("Ghost"));

    const themeItemHeight = "10em";

    return(
      <>
        <CEGeneralReact.CEDragToResortItemWithinDbTbl
          p_canResortTF={c_userCanEditCaptureCardContentTF}
          p_uniqueString="themes"
          p_itemID={p_themeObj.id}
          p_itemSort={p_themeObj.sort}
          p_itemSortColumnWidth={undefined}
          p_itemSortNumberFontClass={undefined}
          p_isOnlyItemTF={p_isOnlyItemTF}
          p_isLastItemTF={p_isLastItemTF}
          p_outerPadClass="medTopPad lrMedPad"
          p_itemClass="smallFullPad bgWhite hoverLighterBlueGradient"
          p_itemStyleObj={{height:themeItemHeight, border:"solid 1px #ddd", borderRadius:"0.5em"}}
          p_lastItemExtraDropZoneHeight={themeItemHeight}
          p_tblName={tblName}
          p_tblSortFieldDbName="sort"
          p_filterFieldNamesArray={["capture_id"]}
          p_filterValuesArray={[o_openCaptureID]}>
          <div className="flex11a displayFlexRow cursorPointer" onClick={this.onclick_edit_theme}>
            <ThemeTextarea p_label={winGhostString + " Theme"} p_text={p_themeObj.theme} />
            <ThemeTextarea p_label="Justification" p_text={p_themeObj.justification} />
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em", marginLeft:"0.3em"}}>
            {(c_userCanEditCaptureCardContentTF) &&
              <CEGeneralReact.DeleteMenu
                p_message={"Are you sure you want to remove this Theme?\n\n'" + p_themeObj.theme + "'"}
                f_onDelete={this.ondelete_theme}
              />
            }
          </div>
        </CEGeneralReact.CEDragToResortItemWithinDbTbl>
        {(editingThemeTF) &&
          <ThemeAddNewOrEditThemeFloatingBox
            p_winTrueGhostFalse={p_winTrueGhostFalse}
            p_editThemeObj={p_themeObj}
            f_onClose={this.onclose_edit_theme}
          />
        }
      </>
    );
  }
}));

function ThemeTextarea(props) { //props: p_label, p_text
  return(
    <div className="flex11a displayFlexColumn lrPad" style={{flexBasis:"100em", borderRight:"solid 1px #eee"}}>
      <div className="flex00a textCenter">
        <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
          {props.p_label}
        </LibraryReact.Nowrap>
      </div>
      <div className="flex11a displayFlexRowVc microTopMargin">
        <LibraryReact.MaxHeightWrap p_maxHeight="7.7em">
          {(JSFUNC.text_or_number_is_filled_out_tf(props.p_text)) ? (
            props.p_text
          ) : (
            <font className="fontItalic fontTextLighter">
              {"--No Text Entered--"}
            </font>
          )}
        </LibraryReact.MaxHeightWrap>
      </div>
    </div>
  );
}

const ThemeAddNewOrEditThemeFloatingBox = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class ThemeAddNewOrEditThemeFloatingBox extends Component { //props: p_winTrueGhostFalse, p_editThemeObj, f_onClose
  constructor(props) {
    super(props);

    const p_editThemeObj = this.props.p_editThemeObj;
    var theme = ""; //initialization for a new theme
    var justification = "";
    if(p_editThemeObj !== undefined) { //editing an existing theme obj if it is provided (if not provided, creating a new theme)
      theme = p_editThemeObj.theme;
      justification = p_editThemeObj.justification;
    }

    this.state = {
      s_initTheme: theme,
      s_initJustification: justification,
      s_updatedTheme: theme,
      s_updatedJustification: justification
    }
  }

  onsave_theme_item = () => {
    const s_initTheme = this.state.s_initTheme;
    const s_initJustification = this.state.s_initJustification;
    const s_updatedTheme = this.state.s_updatedTheme;
    const s_updatedJustification = this.state.s_updatedJustification;

    const p_winTrueGhostFalse = this.props.p_winTrueGhostFalse;
    const p_editThemeObj = this.props.p_editThemeObj;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;

    if(p_editThemeObj === undefined) { //adding a new item
      this.props.OpenCaptureMobx.a_themes_insert_new_theme(o_openCaptureID, p_winTrueGhostFalse, s_updatedTheme, s_updatedJustification);
    }
    else { //editing an item

      var newTheme = ((s_updatedTheme !== s_initTheme) ? (s_updatedTheme) : (undefined));
      var newJustification = ((s_updatedJustification !== s_initJustification) ? (s_updatedJustification) : (undefined));
      this.props.OpenCaptureMobx.a_themes_update_theme(o_openCaptureID, p_winTrueGhostFalse, p_editThemeObj.id, newTheme, newJustification);
    }

    if(JSFUNC.is_function(this.props.f_onClose)) {
      this.props.f_onClose();
    }
  }

  oncancel_theme_item = () => {
    if(JSFUNC.is_function(this.props.f_onClose)) {
      this.props.f_onClose();
    }
  }
  onchange_theme = (i_newValue) => { this.setState({s_updatedTheme:i_newValue}); }
  onchange_justification = (i_newValue) => { this.setState({s_updatedJustification:i_newValue}); }

  render() {
    const s_initTheme = this.state.s_initTheme;
    const s_initJustification = this.state.s_initJustification;
    const s_updatedTheme = this.state.s_updatedTheme;
    const s_updatedJustification = this.state.s_updatedJustification;

    const p_winTrueGhostFalse = this.props.p_winTrueGhostFalse;
    const p_editThemeObj = this.props.p_editThemeObj;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isAddingNewThemeTF = (p_editThemeObj === undefined);
    const anyFieldIsUpdatedTF = ((s_initTheme !== s_updatedTheme) || (s_initJustification !== s_updatedJustification));

    const winGhostString = ((p_winTrueGhostFalse) ? ("Win") : ("Ghost"));

    var themeComponent = null;
    var justificationComponent = null;
    if(c_userCanEditCaptureCardContentTF) {
      themeComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
          p_valueRaw={s_updatedTheme}
          p_tabIndex={1}
          f_onChangeOrOnSelect={this.onchange_theme}
        />
      );
  
      justificationComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
          p_valueRaw={s_updatedJustification}
          p_tabIndex={2}
          f_onChangeOrOnSelect={this.onchange_justification}
        />
      );
    }
    else {
      themeComponent = (
        <div className="flex11a border1bbb bgWhite smallFullPad yScroll">
          <font className="">
            {s_updatedTheme}
          </font>
        </div>
      );

      justificationComponent = (
        <div className="flex11a border1bbb bgWhite smallFullPad yScroll">
          <font className="">
            {s_updatedJustification}
          </font>
        </div>
      );
    }

    var floatingBoxContentComponent = null;
    if(c_isMobileOrTabletTF) {
      floatingBoxContentComponent = (
        <div className="flex11a yScroll yScrollBottomPad">
          <div className="bigTopMargin microBottomMargin lrMargin">
            <font className="font11 fontBlue">
              {winGhostString + " Theme"}
            </font>
          </div>
          <div className="flex11a displayFlexColumn lrMargin" style={{height:"15em"}}>
            {themeComponent}
          </div>
          <div className="bigTopMargin microBottomMargin lrMargin">
            <font className="font11 fontBlue">
              {"Justification"}
            </font>
          </div>
          <div className="flex11a displayFlexColumn lrMargin" style={{height:"15em"}}>
            {justificationComponent}
          </div>
        </div>
      );
    }
    else {
      floatingBoxContentComponent = (
        <div className="flex11a displayFlexRow">
          <div className="flex11a displayFlexColumn medFullPad" style={{flexBasis:"100em", borderRight:"solid 1px #eee"}}>
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBlue">
                {winGhostString + " Theme"}
              </font>
            </div>
            {themeComponent}
          </div>
          <div className="flex11a displayFlexColumn medFullPad" style={{flexBasis:"100em"}}>
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBlue">
                {"Justification"}
              </font>
            </div>
            {justificationComponent}
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="themes"
        p_title={((isAddingNewThemeTF) ? ("Adding New " + winGhostString + " Theme"): ("Editing " + winGhostString + " Theme"))}
        p_cancelConfirmationMessage={((anyFieldIsUpdatedTF) ? ("Are you sure you want to cancel editing this Theme? Any entered data will be lost.") : (undefined))}
        f_onClickSave={((c_userCanEditCaptureCardContentTF) ? (this.onsave_theme_item) : (undefined))}
        f_onClickCancel={this.oncancel_theme_item}>
        {floatingBoxContentComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));









//===================================================================================================================================
//--------Risk Assessment-------------------------------------------------------------------------------------------
//===================================================================================================================================


const RiskAssessmentSnapshot = inject("OpenCaptureMobx")(observer(
class RiskAssessmentSnapshot extends Component {
  render() {
    const c_risksArrayOfObjs = this.props.OpenCaptureMobx.c_risksArrayOfObjs;

    const numRisks = c_risksArrayOfObjs.length;

    if(numRisks === 0) {
      return(
        <div className="flex11a displayFlexColumnHcVc overflowHidden medFullPad textCenter">
          <font className="fontItalic fontTextLighter">
            {"No Risks Identified Yet"}
          </font>
        </div>
      );
    }

    return(
      <div className="flex11a overflowHidden tbMargin lrMedMargin">
        <div className="smallBottomMargin textCenter">
          <font className="font11 fontItalic fontTextLighter">
            {numRisks + " " + JSFUNC.plural(numRisks, "Risk", "Risks") + " Identified"}
          </font>
        </div>
        {c_risksArrayOfObjs.map((m_riskObj) =>
          <div key={m_riskObj.id} className="displayFlexRow microTopMargin microBottomMargin tbPad lrPad" style={{border:"solid 1px #f4f4f4", borderRadius:"0.3em"}}>
            <div className="flex00a" style={{flexBasis:"9em"}}>
              <div className="displayFlexRowVc">
                <div className="flex00a" style={{flexBasis:"1.5em"}}>
                  <font className="fontItalic fontTextLighter">
                    {"P: "}
                  </font>
                </div>
                <div className="flex11a" style={{color:"#" + m_riskObj.probabilityRiskLevelColor}}>
                  <LibraryReact.Nowrap>
                    {m_riskObj.probabilityRiskLevelName}
                  </LibraryReact.Nowrap>
                </div>
              </div>
              <div className="displayFlexRowVc microTopMargin">
                <div className="flex00a" style={{flexBasis:"1.5em"}}>
                  <font className="fontItalic fontTextLighter">
                    {"I: "}
                  </font>
                </div>
                <div className="flex11a" style={{color:"#" + m_riskObj.impactRiskLevelColor}}>
                  <LibraryReact.Nowrap>
                    {m_riskObj.impactRiskLevelName}
                  </LibraryReact.Nowrap>
                </div>
              </div>
            </div>
            <div className="flex11a displayFlexRowVc" style={{borderLeft:"solid 1px #eee", paddingLeft:"0.4em"}}>
              <LibraryReact.MaxHeightWrap p_maxHeight="2.2em" p_fontClass="fontItalic fontTextLighter">
                {m_riskObj.identified_risk}
              </LibraryReact.MaxHeightWrap>
            </div>
          </div>
        )}
      </div>
    );
  }
}));

const RiskAssessment = inject("OpenCaptureMobx", "UserMobx")(observer(
class RiskAssessment extends Component { //props: p_container
  constructor(props) {
    super(props);
    this.state = {
      s_addingRiskTF: false
    }
  }

  onclick_add_risk = () => {
    this.setState({s_addingRiskTF:true});
  }

  onclose_add_new_risk = () => {
    this.setState({s_addingRiskTF:false});
  }

  render() {
    const s_addingRiskTF = this.state.s_addingRiskTF;

    const p_container = this.props.p_container;

    const c_risksArrayOfObjs = this.props.OpenCaptureMobx.c_risksArrayOfObjs;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const risksIsSplitScreenTF = this.props.OpenCaptureMobx.risks_is_split_screen_tf(p_container);

    const numRisks = c_risksArrayOfObjs.length;
    const isOnlyItemTF = (numRisks === 1);

    var risksLeftSideComponent = (
      <>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="flex00a displayFlexColumnHcVc medFullPad bgLightestGray" style={{borderBottom:"solid 1px #bbb"}}>
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Add an Identified Risk"
              f_onClick={this.onclick_add_risk}
            />
            {(s_addingRiskTF) &&
              <RiskAddNewOrEditRiskFloatingBox
                p_editRiskObj={undefined}
                f_onClose={this.onclose_add_new_risk}
              />
            }
          </div>
        }
        {(numRisks === 0) ? (
          <CEGeneralReact.EmptyScreenGray>
            {"No Risks Identified Yet"}
          </CEGeneralReact.EmptyScreenGray>
        ) : (
          <div className={"flex11a " + ((risksIsSplitScreenTF) ? ("yScroll yScrollBottomPad") : (""))}>
            {c_risksArrayOfObjs.map((m_riskObj, m_index) =>
              <RiskItem
                key={m_riskObj.id}
                p_container={p_container}
                p_riskObj={m_riskObj}
                p_isOnlyItemTF={isOnlyItemTF}
                p_isLastItemTF={(m_index === (numRisks - 1))}
              />
            )}
          </div>
        )}
      </>
    );

    var risksRightSideComponent = (
      <div className="smallFullMargin">
        <RiskAssessmentProbabilityImpactMatrix p_risksArrayOfObjs={this.props.OpenCaptureMobx.c_risksArrayOfObjs} />
      </div>
    );

    if(risksIsSplitScreenTF) {
      return(
        <div className="flex11a displayFlexRow">
          <div className="flex11a displayFlexColumn" style={{flexBasis:"125em", borderRight:"solid 1px #bbb"}}>
            {risksLeftSideComponent}
          </div>
          <div className="flex11a yScroll yScrollBottomPad" style={{flexBasis:"100em"}}>
            {risksRightSideComponent}
          </div>
        </div>
      );
    }

    return(
      <div className="flex11a yScroll yScrollBottomPad">
        {risksLeftSideComponent}
        <div style={{borderTop:"solid 1px #bbb"}}>
          {risksRightSideComponent}
        </div>
      </div>
    );
  }
}));

export const RiskAssessmentProbabilityImpactMatrix = inject("DatabaseMobx")(observer(
class RiskAssessmentProbabilityImpactMatrix extends Component { //props: p_risksArrayOfObjs
  render() {
    const p_risksArrayOfObjs = this.props.p_risksArrayOfObjs;

    const c_valueDisplayArraysObjRisksProbabilityLevels = this.props.DatabaseMobx.c_valueDisplayArraysObjRisksProbabilityLevels; //probability is vertical Y axis
    const c_valueDisplayArraysObjRisksImpactLevels = this.props.DatabaseMobx.c_valueDisplayArraysObjRisksImpactLevels; //impact is hoizontal X axis

    const numRisks = p_risksArrayOfObjs.length;

    const probIDsArray = c_valueDisplayArraysObjRisksProbabilityLevels.valueArray;
    const probNamesArray = c_valueDisplayArraysObjRisksProbabilityLevels.displayArray;
    const probColorsArray = c_valueDisplayArraysObjRisksProbabilityLevels.colorArray;
    const numProbs = probIDsArray.length;

    var reverseProbIndicesArray = [];
    for(let p = (numProbs - 1); p >= 0; p--) {
      reverseProbIndicesArray.push(p);
    }

    const impactIDsArray = c_valueDisplayArraysObjRisksImpactLevels.valueArray;
    const impactNamesArray = c_valueDisplayArraysObjRisksImpactLevels.displayArray;
    const impactColorsArray = c_valueDisplayArraysObjRisksImpactLevels.colorArray;
    const numImpacts = impactIDsArray.length;

    if(numProbs === 0 || numImpacts === 0) {
      return(
        <>
          <div className="textCenter">
            <font className="font14 fontItalic fontTextLight">
              {"Risk Assessment Probability/Impact Matrix"}
            </font>
          </div>
          <div className="displayFlexColumnHcVc smallFullPad bgLighterGray" style={{height:"40em"}}>
            <font className="fontItalic fontTextLighter">
              {"Your CaptureExec Admin has not specified any " + ((numProbs === 0) ? ("Probability") : ("Impact")) + " Risk Levels. A two-dimensional Risk Matrix cannot be drawn."}
            </font>
          </div>
        </>
      );
    }

    //cell colors
    const max0to255 = 240;
    const min0to255 = 100;
    const rTL = max0to255; const gTL = max0to255; const bTL = min0to255;
    const rTR = max0to255; const gTR = min0to255; const bTR = min0to255;
    const rBR = max0to255; const gBR = max0to255; const bBR = min0to255;
    const rBL = min0to255; const gBL = max0to255; const bBL = min0to255;
    var colorsMatrix = [];
    for(let p = 0; p < numProbs; p++) {
      colorsMatrix.push([]);
      var y = p / (numProbs - 1);
      for(let i = 0; i < numImpacts; i++) {
        var x = i / (numImpacts - 1);
        colorsMatrix[p][i] = JSFUNC.color_2d_interpolation(x, y, rTL, gTL, bTL, rTR, gTR, bTR, rBR, gBR, bBR, rBL, gBL, bBL);
      }
    }

    //counting number of matching risks
    var numRisksMatrix = [];
    for(let p = 0; p < numProbs; p++) {
      numRisksMatrix.push([]);
      for(let i = 0; i < numImpacts; i++) {
        var numMatchingRisks = 0;
        for(let r = 0; r < numRisks; r++) {
          if((p_risksArrayOfObjs[r].probability_risk_level_id === probIDsArray[p]) && (p_risksArrayOfObjs[r].impact_risk_level_id === impactIDsArray[i])) {
            numMatchingRisks++;
          }
        }
        numRisksMatrix[p][i] = numMatchingRisks;
      }
    }

    const yLabelContainerWidth = "6em";
    const axisLabelContainerHeight = "2.5em";
    const cellLabelsFontClass = "font11 fontBold";
    const axisLabelsFontClass = "font13 fontItalic fontTextLight";

    return(
      <>
        <div className="textCenter">
          <font className="font14 fontItalic fontTextLight">
            {"Risk Assessment Probability/Impact Matrix"}
          </font>
        </div>
        <div className="displayFlexRow" style={{height:"40em"}}>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:axisLabelContainerHeight}}>
            <font className={axisLabelsFontClass} style={{transform:"rotate(270deg)"}}>
              {"Probability"}
            </font>
          </div>
          <div className="flex11a displayFlexColumn">
            {reverseProbIndicesArray.map((m_pIndex) =>
              <div className="flex11a displayFlexRow" style={{flexBasis:"100em"}}>
                <div className="flex00a displayFlexRowVc overflowHidden" style={{flexBasis:yLabelContainerWidth}}>
                  <font className={cellLabelsFontClass} style={{color:"#" + probColorsArray[m_pIndex]}}>
                    {probNamesArray[m_pIndex]}
                  </font>
                </div>
                {impactIDsArray.map((m_impactID, m_iIndex) =>
                  <div className="flex11a displayFlexColumnHcVc border bevelBorderColors" style={{flexBasis:"100em", backgroundColor:"#" + colorsMatrix[m_pIndex][m_iIndex]}}>
                    {(numRisksMatrix[m_pIndex][m_iIndex] > 0) &&
                      <div className="displayFlexColumnHcVc" style={{background:"#66d", height:"3em", width:"3em", border:"solid 1px #44a", borderRadius:"3em"}}>
                        <font className="font14 fontBold fontWhite">
                          {numRisksMatrix[m_pIndex][m_iIndex]}
                        </font>
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
            <div className="flex00a displayFlexRow smallTopMargin">
              <div className="flex00a displayFlexRow" style={{flexBasis:yLabelContainerWidth}} />
              {impactIDsArray.map((m_impactID, m_iIndex) =>
                <div className="flex11a overflowHidden textCenter" style={{flexBasis:"100em"}}>
                  <font className={cellLabelsFontClass} style={{color:"#" + impactColorsArray[m_iIndex]}}>
                    {impactNamesArray[m_iIndex]}
                  </font>
                </div>
              )}
            </div>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:axisLabelContainerHeight}}>
              <font className={axisLabelsFontClass}>
                {"Impact"}
              </font>
            </div>
          </div>
        </div>
      </>
    );
  }
}));

const RiskItem = inject("OpenCaptureMobx", "UserMobx")(observer(
class RiskItem extends Component { //props: p_container, p_riskObj, p_isOnlyItemTF, p_isLastItemTF
  constructor(props) {
    super(props);
    this.state = {
      s_editingRiskTF: false
    }
  }

  onclick_edit_risk = () => {
    this.setState({s_editingRiskTF:true});
  }

  onclose_edit_risk = () => {
    this.setState({s_editingRiskTF:false});
  }

  ondelete_risk = () => {
    this.props.OpenCaptureMobx.a_risks_delete_risk(this.props.OpenCaptureMobx.o_openCaptureID, this.props.p_riskObj);
  }

  render() {
    const s_editingRiskTF = this.state.s_editingRiskTF;

    const p_container = this.props.p_container
    const p_riskObj = this.props.p_riskObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isCompactTF = this.props.OpenCaptureMobx.risk_item_is_compact_tf(p_container);

    const riskItemHeight = ((isCompactTF) ? ("10em") : ("6.4em"));

    return(
      <>
        <CEGeneralReact.CEDragToResortItemWithinDbTbl
          p_canResortTF={c_userCanEditCaptureCardContentTF}
          p_uniqueString="risks"
          p_itemID={p_riskObj.id}
          p_itemSort={p_riskObj.sort}
          p_itemSortColumnWidth={undefined}
          p_itemSortNumberFontClass={undefined}
          p_isOnlyItemTF={p_isOnlyItemTF}
          p_isLastItemTF={p_isLastItemTF}
          p_outerPadClass="medTopPad lrMedPad"
          p_itemClass="smallFullPad hoverLighterBlueGradient"
          p_itemStyleObj={{height:riskItemHeight, border:"solid 1px #ddd", borderRadius:"0.5em"}}
          p_lastItemExtraDropZoneHeight="3em"
          p_tblName="tbl_c_risks"
          p_tblSortFieldDbName="sort"
          p_filterFieldNamesArray={["capture_id"]}
          p_filterValuesArray={[this.props.OpenCaptureMobx.o_openCaptureID]}>
          <div className="flex11a displayFlexRow cursorPointer" onClick={this.onclick_edit_risk}>
            {(isCompactTF) ? (
              <>
                <div className="flex11a displayFlexColumn lrPad" style={{flexBasis:"300em", borderRight:"solid 1px #eee"}}>
                  <RiskLevel p_label="Category" p_name={p_riskObj.categoryName} p_color={undefined} p_compactTF={isCompactTF} />
                  <RiskLevel p_label="Probability of Occurance" p_name={p_riskObj.probabilityRiskLevelName} p_color={p_riskObj.probabilityRiskLevelColor} p_compactTF={isCompactTF} />
                  <RiskLevel p_label="Potential Risk Impact" p_name={p_riskObj.impactRiskLevelName} p_color={p_riskObj.impactRiskLevelColor} p_compactTF={isCompactTF} />
                </div>
                <RiskTextarea p_label="Identified Risk" p_text={p_riskObj.identified_risk} p_compactTF={isCompactTF} p_width="200em" />
                <RiskTextarea p_label="Mitigation Approach" p_text={p_riskObj.mitigation_approach} p_compactTF={isCompactTF} p_width="200em" />
              </>
            ) : (
              <>
                <RiskTextarea p_label="Category" p_text={p_riskObj.categoryName} p_compactTF={isCompactTF} p_width="100em" />
                <div className="flex11a displayFlexColumn lrPad" style={{flexBasis:"300em", borderRight:"solid 1px #eee"}}>
                  <RiskLevel p_label="Probability of Occurance" p_name={p_riskObj.probabilityRiskLevelName} p_color={p_riskObj.probabilityRiskLevelColor} p_compactTF={isCompactTF} />
                  <RiskLevel p_label="Potential Risk Impact" p_name={p_riskObj.impactRiskLevelName} p_color={p_riskObj.impactRiskLevelColor} p_compactTF={isCompactTF} />
                </div>
                <RiskTextarea p_label="Identified Risk" p_text={p_riskObj.identified_risk} p_compactTF={isCompactTF} p_width="300em" />
                <RiskTextarea p_label="Mitigation Approach" p_text={p_riskObj.mitigation_approach} p_compactTF={isCompactTF} p_width="300em" />
              </>
            )}
          </div>
          {(c_userCanEditCaptureCardContentTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em", marginLeft:"0.3em"}}>
              <CEGeneralReact.DeleteMenu
                p_message={"Are you sure you want to remove this Risk Item?\n\n'" + p_riskObj.identified_risk + "'"}
                f_onDelete={this.ondelete_risk}
              />
            </div>
          }
        </CEGeneralReact.CEDragToResortItemWithinDbTbl>
        {(s_editingRiskTF) &&
          <RiskAddNewOrEditRiskFloatingBox
            p_editRiskObj={p_riskObj}
            f_onClose={this.onclose_edit_risk}
          />
        }
      </>
    );
  }
}));

function RiskLevel(props) { //props: p_label, p_name, p_color, p_compactTF
  if(props.p_compactTF) {
    return(
      <div className="flex11a displayFlexRowVc" style={{flexBasis:"100em"}}>
        <div className="flex11a">
          <div className="textCenter">
            <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
              {props.p_label}
            </LibraryReact.Nowrap>
          </div>
          <div className="textCenter">
            <LibraryReact.Nowrap>
              {(props.p_color !== undefined) ? (
                <font className="font11 fontBold" style={{color:"#" + props.p_color}}>
                  {props.p_name}
                </font>
              ) : (
                props.p_name
              )}
            </LibraryReact.Nowrap>
          </div>
        </div>
      </div>
    );
  }

  return(
    <div className="flex11a displayFlexRowVc" style={{flexBasis:"100em"}}>
      <div className="flex00a" style={{flexBasis:"7em"}}>
        <font className="fontItalic fontTextLighter">
          {props.p_label}
        </font>
      </div>
      <div className="flex11a">
        <LibraryReact.Nowrap>
          {(props.p_color !== undefined) ? (
            <font className="font13 fontBold" style={{color:"#" + props.p_color}}>
              {props.p_name}
            </font>
          ) : (
            props.p_name
          )}
        </LibraryReact.Nowrap>
      </div>
    </div>
  );
}

function RiskTextarea(props) { //props: p_label, p_text, p_compactTF, p_width
  return(
    <div className="flex11a displayFlexColumn lrPad" style={{flexBasis:props.p_width, borderRight:"solid 1px #eee"}}>
      <div className="flex00a textCenter">
        <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
          {props.p_label}
        </LibraryReact.Nowrap>
      </div>
      <div className="flex11a displayFlexRowVc microTopMargin">
        <LibraryReact.MaxHeightWrap p_maxHeight={((props.p_compactTF) ? ("7.7em") : ("4.2em"))}>
          {(JSFUNC.text_or_number_is_filled_out_tf(props.p_text)) ? (
            props.p_text
          ) : (
            <font className="fontItalic fontTextLighter">
              {"--No Text Entered--"}
            </font>
          )}
        </LibraryReact.MaxHeightWrap>
      </div>
    </div>
  );
}

const RiskAddNewOrEditRiskFloatingBox = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class RiskAddNewOrEditRiskFloatingBox extends Component { //props: p_editRiskObj, f_onClose
  constructor(props) {
    super(props);

    const p_editRiskObj = this.props.p_editRiskObj;

    var categoryID = -1; //initialization for a new risk
    var probability = -1;
    var impact = -1;
    var risk = "";
    var mitigation = "";
    if(p_editRiskObj !== undefined) { //editing an existing risk obj if it is provided (if not provided, creating a new risk)
      categoryID = p_editRiskObj.risk_category_id;
      probability = p_editRiskObj.probability_risk_level_id;
      impact = p_editRiskObj.impact_risk_level_id;
      risk = p_editRiskObj.identified_risk;
      mitigation = p_editRiskObj.mitigation_approach;
    }

    this.state = {
      s_initCategoryID: categoryID,
      s_initProbability: probability,
      s_initImpact: impact,
      s_initRisk: risk,
      s_initMitigation: mitigation,
      s_updatedCategoryID: categoryID,
      s_updatedProbability: probability,
      s_updatedImpact: impact,
      s_updatedRisk: risk,
      s_updatedMitigation: mitigation
    }
  }

  onsave_risk_item = () => {
    const s_initCategoryID = this.state.s_initCategoryID;
    const s_initProbability = this.state.s_initProbability;
    const s_initImpact = this.state.s_initImpact;
    const s_initRisk = this.state.s_initRisk;
    const s_initMitigation = this.state.s_initMitigation;
    const s_updatedCategoryID = this.state.s_updatedCategoryID;
    const s_updatedProbability = this.state.s_updatedProbability;
    const s_updatedImpact = this.state.s_updatedImpact;
    const s_updatedRisk = this.state.s_updatedRisk;
    const s_updatedMitigation = this.state.s_updatedMitigation;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;

    if(this.props.p_editRiskObj === undefined) { //adding a new item
      this.props.OpenCaptureMobx.a_risks_insert_new_risk(o_openCaptureID, s_updatedCategoryID, s_updatedProbability, s_updatedImpact, s_updatedRisk, s_updatedMitigation);
    }
    else { //editing an item
      const p_editRiskObj = this.props.p_editRiskObj;

      var newCategoryID = ((s_updatedCategoryID !== s_initCategoryID) ? (s_updatedCategoryID) : (undefined)); //values are undefined if they have not been changed in this edit
      var newProbability = ((s_updatedProbability !== s_initProbability) ? (s_updatedProbability) : (undefined));
      var newImpact = ((s_updatedImpact !== s_initImpact) ? (s_updatedImpact) : (undefined));
      var newRisk = ((s_updatedRisk !== s_initRisk) ? (s_updatedRisk) : (undefined));
      var newMitigation = ((s_updatedMitigation !== s_initMitigation) ? (s_updatedMitigation) : (undefined));
      this.props.OpenCaptureMobx.a_risks_update_risk(o_openCaptureID, p_editRiskObj.id, newCategoryID, newProbability, newImpact, newRisk, newMitigation);
    }

    if(this.props.f_onClose) {
      this.props.f_onClose();
    }
  }

  oncancel_risk_item = () => {
    if(this.props.f_onClose) {
      this.props.f_onClose();
    }
  }

  onchange_category_id = (i_newValue) => { this.setState({s_updatedCategoryID:i_newValue}); }
  onchange_probability = (i_newValue) => { this.setState({s_updatedProbability:i_newValue}); }
  onchange_impact = (i_newValue) => { this.setState({s_updatedImpact:i_newValue}); }
  onchange_risk = (i_newValue) => { this.setState({s_updatedRisk:i_newValue}); }
  onchange_mitigation = (i_newValue) => { this.setState({s_updatedMitigation:i_newValue}); }

  render() {
    const s_initCategoryID = this.state.s_initCategoryID;
    const s_initProbability = this.state.s_initProbability;
    const s_initImpact = this.state.s_initImpact;
    const s_initRisk = this.state.s_initRisk;
    const s_initMitigation = this.state.s_initMitigation;
    const s_updatedCategoryID = this.state.s_updatedCategoryID;
    const s_updatedProbability = this.state.s_updatedProbability;
    const s_updatedImpact = this.state.s_updatedImpact;
    const s_updatedRisk = this.state.s_updatedRisk;
    const s_updatedMitigation = this.state.s_updatedMitigation;

    const p_editRiskObj = this.props.p_editRiskObj;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isAddingNewRiskTF = (p_editRiskObj === undefined);
    const anyFieldIsUpdatedTF = ((s_initCategoryID !== s_updatedCategoryID) || (s_initProbability !== s_updatedProbability) || (s_initImpact !== s_updatedImpact) || (s_initRisk !== s_updatedRisk) || (s_initMitigation !== s_updatedMitigation));

    var categoryComponent = null;
    var probabilityComponent = null;
    var impactComponent = null;
    var riskComponent = null;
    var mitigationComponent = null;
    if(c_userCanEditCaptureCardContentTF) {
      categoryComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectRisksCategoriesFieldTypeObj}
          p_valueRaw={s_updatedCategoryID}
          p_tabIndex={3}
          f_onChangeOrOnSelect={this.onchange_category_id}
        />
      );
  
      probabilityComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectRisksProbabilityLevelFieldTypeObj}
          p_valueRaw={s_updatedProbability}
          p_tabIndex={4}
          f_onChangeOrOnSelect={this.onchange_probability}
        />
      );
  
      impactComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectRisksImpactLevelFieldTypeObj}
          p_valueRaw={s_updatedImpact}
          p_tabIndex={5}
          f_onChangeOrOnSelect={this.onchange_impact}
        />
      );
  
      riskComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
          p_valueRaw={s_updatedRisk}
          p_tabIndex={1}
          f_onChangeOrOnSelect={this.onchange_risk}
        />
      );
  
      mitigationComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
          p_valueRaw={s_updatedMitigation}
          p_tabIndex={2}
          f_onChangeOrOnSelect={this.onchange_mitigation}
        />
      );
    }
    else {
      categoryComponent = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(s_updatedCategoryID, this.props.DatabaseMobx.c_selectRisksCategoriesFieldTypeObj);
      probabilityComponent = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(s_updatedProbability, this.props.DatabaseMobx.c_selectRisksProbabilityLevelFieldTypeObj);
      impactComponent = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(s_updatedImpact, this.props.DatabaseMobx.c_selectRisksImpactLevelFieldTypeObj);
  
      riskComponent = (
        <div className="flex11a border1bbb bgWhite smallFullPad yScroll">
          <font className="">
            {s_updatedRisk}
          </font>
        </div>
      );
  
      mitigationComponent = (
        <div className="flex11a border1bbb bgWhite smallFullPad yScroll">
          <font className="">
            {s_updatedMitigation}
          </font>
        </div>
      );
    }
    

    var floatingBoxContentComponent = null;
    if(c_isMobileOrTabletTF) {
      floatingBoxContentComponent = (
        <div className="flex11a yScroll yScrollBottomPad">
          <div className="smallTopMargin lrMargin">
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBlue">
                {"Category"}
              </font>
            </div>
            {categoryComponent}
            <div className="flex00a bigTopMargin microBottomMargin">
              <font className="font11 fontBlue">
                {"Probability of Occurance"}
              </font>
            </div>
            {probabilityComponent}
            <div className="flex00a bigTopMargin microBottomMargin">
              <font className="font11 fontBlue">
                {"Potential Risk Impact"}
              </font>
            </div>
            {impactComponent}
          </div>
          <div className="bigTopMargin microBottomMargin lrMargin">
            <font className="font11 fontBlue">
              {"Identified Risk"}
            </font>
          </div>
          <div className="flex11a displayFlexColumn lrMargin" style={{height:"15em"}}>
            {riskComponent}
          </div>
          <div className="bigTopMargin microBottomMargin lrMargin">
            <font className="font11 fontBlue">
              {"Mitigation Approach"}
            </font>
          </div>
          <div className="flex11a displayFlexColumn lrMargin" style={{height:"15em"}}>
            {mitigationComponent}
          </div>
        </div>
      );
    }
    else {
      floatingBoxContentComponent = (
        <div className="flex11a displayFlexRow">
          <div className="flex11a yScroll medFullPad" style={{flexBasis:"100em", borderRight:"solid 1px #eee"}}>
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBlue">
                {"Category"}
              </font>
            </div>
            {categoryComponent}
            <div className="flex00a hugeTopMargin microBottomMargin">
              <font className="font11 fontBlue">
                {"Probability of Occurance"}
              </font>
            </div>
            {probabilityComponent}
            <div className="flex00a hugeTopMargin microBottomMargin">
              <font className="font11 fontBlue">
                {"Potential Risk Impact"}
              </font>
            </div>
            {impactComponent}
          </div>
          <div className="flex11a displayFlexColumn medFullPad" style={{flexBasis:"100em", borderRight:"solid 1px #eee"}}>
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBlue">
                {"Identified Risk"}
              </font>
            </div>
            {riskComponent}
          </div>
          <div className="flex11a displayFlexColumn medFullPad" style={{flexBasis:"100em"}}>
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBlue">
                {"Mitigation Approach"}
              </font>
            </div>
            {mitigationComponent}
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="risks"
        p_title={((isAddingNewRiskTF) ? ("Adding New Risk Item"): ("Editing Risk Item"))}
        p_cancelConfirmationMessage={((anyFieldIsUpdatedTF) ? ("Are you sure you want to cancel editing this Risk item? Any entered data will be lost.") : (undefined))}
        f_onClickSave={((c_userCanEditCaptureCardContentTF) ? (this.onsave_risk_item) : (undefined))}
        f_onClickCancel={this.oncancel_risk_item}>
        {floatingBoxContentComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));







//===================================================================================================================================
//--------Budget-------------------------------------------------------------------------------------------
//===================================================================================================================================
const BudgetSnapshot = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class BudgetSnapshot extends Component {
  render() {
    const c_budgetManagerCaptureFieldValueObj = this.props.OpenCaptureMobx.c_budgetManagerCaptureFieldValueObj;
    const c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs = this.props.OpenCaptureMobx.c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs;

    const c_fieldMapOfBudgetManager = this.props.DatabaseMobx.c_fieldMapOfBudgetManager;

    return(
      <>
        <div className="flex11a overflowHidden tbMargin lrMedMargin">
          {c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs.map((m_expandedBudgetCategoryObj) =>
            <div className="displayFlexRowVc smallTopMargin">
              <div className="flex00a" style={{flexBasis:"6em", borderRight:"solid 1px #eee"}}>
                <LibraryReact.MaxHeightWrap p_maxHeight="2.7em" p_fontClass="fontBold fontItalic fontTextLighter">
                  {m_expandedBudgetCategoryObj.name}
                </LibraryReact.MaxHeightWrap>
              </div>
              <div className="flex11a">
                <BudgetReact.BudgetBarGraph
                  p_initialBudget={m_expandedBudgetCategoryObj.totalApproved}
                  p_amountSpent={m_expandedBudgetCategoryObj.totalSpent}
                  p_heightEm={3.3}
                  p_fontSizeClass=""
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex00a tbMicroPad textCenter" style={{borderTop:"solid 1px #ddd"}}>
          <font className="fontTextLighter fontItalic">{c_fieldMapOfBudgetManager.get("display_name") + ": "}</font>
          <font className="fontTextLighter">{c_budgetManagerCaptureFieldValueObj.valueMask}</font>
        </div>
      </>
    );
  }
}));

const Budget = inject("OpenCaptureMobx")(observer(
class Budget extends Component { //props: p_container
  onclick_mobile_return_to_budget_categories = () => {
    this.props.OpenCaptureMobx.a_budget_set_selected_budget_category_id(undefined);
  }

  onclick_mobile_view_funding_requests = () => {
    this.props.OpenCaptureMobx.a_budget_set_mobile_page("funding");
  }

  onclick_mobile_view_expenses = () => {
    this.props.OpenCaptureMobx.a_budget_set_mobile_page("expenses");
  }

  render() {
    const p_container = this.props.p_container;

    const o_budgetMobilePage = this.props.OpenCaptureMobx.o_budgetMobilePage;
    const c_budgetSelectedExpandedBudgetCategoryObj = this.props.OpenCaptureMobx.c_budgetSelectedExpandedBudgetCategoryObj;

    const smallSingleSwitchedScreensTF = this.props.OpenCaptureMobx.budget_uses_small_single_switched_screens_tf(p_container);
    const budgetIsSelectedTF = (c_budgetSelectedExpandedBudgetCategoryObj !== undefined);

    //mobile
    if(smallSingleSwitchedScreensTF) {
      if(!budgetIsSelectedTF) {
        return(
          <BudgetSelectBudgetManagerAndBudget />
        );
      }

      const mobilePageIsExpensesTF = (o_budgetMobilePage === "expenses");
      return(
        <>
          <div className="flex00a displayFlexRow smallFullPad" style={{borderBottom:"solid 1px #bca996"}}>
            <div className="flex00a">
              <CEGeneralReact.CEButton
                p_type="add"
                p_text="Budgets"
                f_onClick={this.onclick_mobile_return_to_budget_categories}
              />
            </div>
            <div className="flex11a" />
            <div className="flex00a">
              <CEGeneralReact.CEButton
                p_type="add"
                p_text={((mobilePageIsExpensesTF) ? ("Funding") : ("Expenses"))}
                f_onClick={((mobilePageIsExpensesTF) ? (this.onclick_mobile_view_funding_requests) : (this.onclick_mobile_view_expenses))}
              />
            </div>
          </div>
          <div className="flex00a tbMicroPad lrPad textCenter" style={{borderBottom:"solid 1px #bca996"}}>
            <LibraryReact.Nowrap p_fontClass="font11 fontBold fontLightBrown">
              {c_budgetSelectedExpandedBudgetCategoryObj.name}
            </LibraryReact.Nowrap>
          </div>
          {(mobilePageIsExpensesTF) ? (
            <BudgetSpentExpenses p_isCompactTF={true} />
          ) : (
            <BudgetManageFundingRequests p_isCompactTF={true} />
          )}
        </>
      );
    }

    //large screen
    return(
      <div className="flex11a displayFlexRow">
        <div className="flex00a displayFlexColumn" style={{flexBasis:"25em", borderRight:"solid 1px #bca996"}}>
          <BudgetSelectBudgetManagerAndBudget />
        </div>
        {(budgetIsSelectedTF) ? (
          <div className="flex11a displayFlexColumn">
            <div className="flex00a tbMicroPad lrPad textCenter" style={{borderBottom:"solid 1px #bbb"}}>
              <LibraryReact.Nowrap p_fontClass="font13 fontBold fontLightBrown">
                {c_budgetSelectedExpandedBudgetCategoryObj.name}
              </LibraryReact.Nowrap>
            </div>
            <div className="flex11a displayFlexRow">
              <div className="flex11a displayFlexColumn" style={{flexBasis:"100em", borderRight:"solid 1px #bbb"}}>
                <BudgetManageFundingRequests p_isCompactTF={false} />
              </div>
              <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
                <BudgetSpentExpenses p_isCompactTF={false} />
              </div>
            </div>
          </div>
        ) : (
          <CEGeneralReact.EmptyScreenGray>
            {"Select a Budget Category on the left to request funding and enter expenses"}
          </CEGeneralReact.EmptyScreenGray>
        )}
      </div>
    );
  }
}));

const BudgetSelectBudgetManagerAndBudget = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class BudgetSelectBudgetManagerAndBudget extends Component { //props:
  render() {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs = this.props.OpenCaptureMobx.c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs;
    const c_budgetManagerCaptureFieldValueObj = this.props.OpenCaptureMobx.c_budgetManagerCaptureFieldValueObj;
    const k_cardIDDetails = this.props.DatabaseMobx.k_cardIDDetails;
    const c_fieldMapOfBudgetManager = this.props.DatabaseMobx.c_fieldMapOfBudgetManager;

    return(
      <div className="flex11a displayFlexColumn bgLighterBrown">
        <div className="flex00a smallFullPad" style={{borderBottom:"solid 1px #bca996"}}>
          <div className="microBottomMargin">
            <font className="fontItalic fontTextLight">
              {c_fieldMapOfBudgetManager.get("display_name") + " for this Capture"}
            </font>
          </div>
          <DetailsItem
            p_captureID={o_openCaptureID}
            p_cardID={k_cardIDDetails}
            p_captureFieldValueObj={c_budgetManagerCaptureFieldValueObj}
            p_isSnapshotTF={false}
            p_fieldClass="fontBlue"
            p_showFieldNameTF={false}
          />
        </div>
        <div className="flex00a smallFullPad" style={{borderBottom:"solid 1px #bca996"}}>
          <font className="font12 fontItalic fontTextLight">
            {"Select a Budget"}
          </font>
        </div>
        <div className="flex11a yScroll yScrollBottomPad">
          {c_budgetCaptureTypeExpandedBudgetCategoriesArrayOfObjs.map((m_expandedBudgetCategoryObj) =>
            <BudgetBudgetCategory
              p_expandedBudgetCategoryObj={m_expandedBudgetCategoryObj}
            />
          )}
        </div>
      </div>
    );
  }
}));

const BudgetBudgetCategory = inject("OpenCaptureMobx")(observer(
class BudgetBudgetCategory extends Component { //props: p_expandedBudgetCategoryObj
  onclick_budget_category = () => {
    this.props.OpenCaptureMobx.a_budget_set_selected_budget_category_id(this.props.p_expandedBudgetCategoryObj.id);
    this.props.OpenCaptureMobx.a_budget_set_mobile_page("funding");
  }

  render() {
    const p_expandedBudgetCategoryObj = this.props.p_expandedBudgetCategoryObj;

    const o_budgetSelectedBudgetCategoryID = this.props.OpenCaptureMobx.o_budgetSelectedBudgetCategoryID;

    const budgetIsSelectedTF = (p_expandedBudgetCategoryObj.id === o_budgetSelectedBudgetCategoryID);

    return(
      <div
        className={"medFullPad border bevelBorderColors " + ((budgetIsSelectedTF) ? ("bgLightBlueGradient") : ("bgLighterGray hoverLighterBlueGradient cursorPointer"))}
        onClick={((budgetIsSelectedTF) ? (undefined) : (this.onclick_budget_category))}>
        <div className="smallBottomMargin">
          <LibraryReact.Nowrap p_fontClass="font12 fontBold fontItalic fontTextLighter">
            {p_expandedBudgetCategoryObj.name}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex11a">
          <BudgetReact.BudgetBarGraph
            p_initialBudget={p_expandedBudgetCategoryObj.totalApproved}
            p_amountSpent={p_expandedBudgetCategoryObj.totalSpent}
            p_heightEm={5}
            p_fontSizeClass=""
          />
        </div>
      </div>
    );
  }
}));

const BudgetManageFundingRequests = inject("OpenCaptureMobx", "BudgetMobx", "UserMobx")(observer(
class BudgetManageFundingRequests extends Component { //props: p_isCompactTF
  onclick_create_new_empty_funding_request = () => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_budgetSelectedExpandedBudgetCategoryObj = this.props.OpenCaptureMobx.c_budgetSelectedExpandedBudgetCategoryObj;

    const functionOnSuccess = (i_newFundingRequestID) => {
      this.props.OpenCaptureMobx.a_budget_set_editing_funding_request_id(i_newFundingRequestID);
    }

    const functionOnError = () => {
      alert("Error creating a new Funding Request. Please try again.");
    }

    this.props.OpenCaptureMobx.a_budget_create_new_empty_funding_request(o_openCaptureID, c_budgetSelectedExpandedBudgetCategoryObj.id, functionOnSuccess, functionOnError);
  }

  onclick_return_unspent_funding = () => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_budgetSelectedExpandedBudgetCategoryObj = this.props.OpenCaptureMobx.c_budgetSelectedExpandedBudgetCategoryObj;
    this.props.OpenCaptureMobx.a_budget_create_new_funding_request_return_unspent_funding(o_openCaptureID, c_budgetSelectedExpandedBudgetCategoryObj.id, c_budgetSelectedExpandedBudgetCategoryObj.totalRemaining);
  }

  render() {
    const p_isCompactTF = this.props.p_isCompactTF;

    const c_budgetSelectedExpandedBudgetCategoryObj = this.props.OpenCaptureMobx.c_budgetSelectedExpandedBudgetCategoryObj;
    const c_budgetManagerCaptureFieldValueObj = this.props.OpenCaptureMobx.c_budgetManagerCaptureFieldValueObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const fundingRequestsNr0ArrayOfObjs = this.props.OpenCaptureMobx.c_budgetSelectedBudgetCategoryFundingRequestsNr0ArrayOfObjs; //not yet requested by capture manager (created but not sent out yet)
    const fundingRequestsRq1ArrayOfObjs = this.props.OpenCaptureMobx.c_budgetSelectedBudgetCategoryFundingRequestsRq1ArrayOfObjs; //requested, waiting for budget manager approval or rejection
    const fundingRequestsRj2ArrayOfObjs = this.props.OpenCaptureMobx.c_budgetSelectedBudgetCategoryFundingRequestsRj2ArrayOfObjs; //rejected by budget manager, can be resubmitted by capture manager
    const fundingRequestsRs3ArrayOfObjs = this.props.OpenCaptureMobx.c_budgetSelectedBudgetCategoryFundingRequestsRs3ArrayOfObjs; //resubmitted, waiting for budget manager approval or rejection
    const fundingRequestsAp4ArrayOfObjs = this.props.OpenCaptureMobx.c_budgetSelectedBudgetCategoryFundingRequestsAp4ArrayOfObjs; //approved by budget manager

    const frNr0LabelColorObj = this.props.BudgetMobx.funding_request_label_and_color_obj_from_status_number(0);
    const frRq1LabelColorObj = this.props.BudgetMobx.funding_request_label_and_color_obj_from_status_number(1);
    const frRj2LabelColorObj = this.props.BudgetMobx.funding_request_label_and_color_obj_from_status_number(2);
    const frRs3LabelColorObj = this.props.BudgetMobx.funding_request_label_and_color_obj_from_status_number(3);
    const frAp4LabelColorObj = this.props.BudgetMobx.funding_request_label_and_color_obj_from_status_number(4);

    var boxMarginClass = "medTopMargin";
    var boxPaddingClass = "tbMedPad";
    var remainingBoxWidth = "25em";
    var remainingMoneyFontClass = "font22";
    var remainingLabelFontClass = "font16";
    var fundingBoxWidth = "20em";
    var fundingMoneyFontClass = "font16";
    var fundingLabelFontClass = "font12";
    if(p_isCompactTF) {
      boxMarginClass = "tbMargin";
      boxPaddingClass = "tbPad";
      remainingBoxWidth = "20em";
      remainingMoneyFontClass = "font17";
      remainingLabelFontClass = "font13";
      fundingBoxWidth = "15em";
      fundingMoneyFontClass = "font13";
      fundingLabelFontClass = "font11";
    }

    const remainingFontColorClass = ((c_budgetSelectedExpandedBudgetCategoryObj.isOverspentTF) ? ("fontDarkRed") : ("fontDarkGreen"));

    var requestFundingButtonComponent = null;
    if(c_budgetManagerCaptureFieldValueObj.isFilledOutTF) {
      requestFundingButtonComponent = (
        <CEGeneralReact.CEButton
          p_type="blue"
          p_text={((c_budgetSelectedExpandedBudgetCategoryObj.numFundingRequests === 0) ? ("Request Initial Funding") : ("Request Additional Funding"))}
          f_onClick={this.onclick_create_new_empty_funding_request}
        />
      );
    }
    else {
      requestFundingButtonComponent = (
        <font className="fontRed">
          {"A Budget Manager for this capture must first be selected before requesting funding"}
        </font>
      );
    }

    return(
      <>
        <div className="flex11a yScroll yScrollBottomPad">
          <div className={"displayFlexColumnHcVc " + boxMarginClass}>
            <div className={"displayFlexColumnHcVc border1bbb bgLighterGray " + boxPaddingClass} style={{width:remainingBoxWidth}}>
              <div className="">
                <font className={"fontBold " + remainingMoneyFontClass + " " + remainingFontColorClass}>
                  {JSFUNC.money(Math.abs(c_budgetSelectedExpandedBudgetCategoryObj.totalRemaining), 0, true)}
                </font>
              </div>
              <div className="">
                <font className={remainingLabelFontClass + " " + remainingFontColorClass}>
                  {((c_budgetSelectedExpandedBudgetCategoryObj.isOverspentTF) ? ("Overspent") : ("Remaining"))}
                </font>
              </div>
            </div>
          </div>
          <div className={"displayFlexRow " + boxMarginClass}>
            <div className="flex11a displayFlexColumnHcVc">
              <div className={"displayFlexColumnHcVc border1bbb bgLighterGray " + boxPaddingClass} style={{width:fundingBoxWidth}}>
                <div className="">
                  <font className={"fontBold fontTextLighter " + fundingMoneyFontClass}>
                    {JSFUNC.money(c_budgetSelectedExpandedBudgetCategoryObj.totalApproved, 0, true)}
                  </font>
                </div>
                <div className="">
                  <font className={"fontTextLighter " + fundingLabelFontClass}>
                    {"Total Approved Funding"}
                  </font>
                </div>
              </div>
            </div>
            <div className="flex11a displayFlexColumnHcVc">
              <div className={"displayFlexColumnHcVc bgLighterGray " + boxPaddingClass} style={{border:"solid 1px #bbb", width:fundingBoxWidth}}>
                <div className="">
                  <font className={"fontBold fontTextLightester " + fundingMoneyFontClass}>
                    {JSFUNC.money(c_budgetSelectedExpandedBudgetCategoryObj.totalPending, 0, true)}
                  </font>
                </div>
                <div className="">
                  <font className={"fontTextLightester " + fundingLabelFontClass}>
                    {"Pending Approval"}
                  </font>
                </div>
              </div>
            </div>
          </div>
          {(c_userCanEditCaptureCardContentTF) &&
            <div className="displayFlexColumnHcVc medTopMargin">
              {requestFundingButtonComponent}
            </div>
          }
          <div className="lrMedPad">
            <FundingRequestHeaderAndItems
              p_fundingRequestsArrayOfObjs={fundingRequestsNr0ArrayOfObjs}
              p_headerText={frNr0LabelColorObj.label}
              p_headerColor={frNr0LabelColorObj.color}
            />
            <FundingRequestHeaderAndItems
              p_fundingRequestsArrayOfObjs={fundingRequestsRq1ArrayOfObjs}
              p_headerText={frRq1LabelColorObj.label}
              p_headerColor={frRq1LabelColorObj.color}
            />
            <FundingRequestHeaderAndItems
              p_fundingRequestsArrayOfObjs={fundingRequestsRj2ArrayOfObjs}
              p_headerText={frRj2LabelColorObj.label}
              p_headerColor={frRj2LabelColorObj.color}
            />
            <FundingRequestHeaderAndItems
              p_fundingRequestsArrayOfObjs={fundingRequestsRs3ArrayOfObjs}
              p_headerText={frRs3LabelColorObj.label}
              p_headerColor={frRs3LabelColorObj.color}
            />
            <FundingRequestHeaderAndItems
              p_fundingRequestsArrayOfObjs={fundingRequestsAp4ArrayOfObjs}
              p_headerText={frAp4LabelColorObj.label}
              p_headerColor={frAp4LabelColorObj.color}
            />
          </div>
        </div>
        {(c_userCanEditCaptureCardContentTF && (c_budgetSelectedExpandedBudgetCategoryObj.totalRemaining > 0)) &&
          <div className="flex00a displayFlexColumnHcVc tbPad borderT1ddd">
            <CEGeneralReact.ButtonWithConfirmBox
              p_buttonType="add"
              p_buttonText="Return Unspent Funding"
              p_confirmType="confirm"
              p_confirmMessage={"Confirm returning " + JSFUNC.money(c_budgetSelectedExpandedBudgetCategoryObj.totalRemaining, 0, true) + " of unspent funding?"}
              f_onClickConfirm={this.onclick_return_unspent_funding}
            />
          </div>
        }
        <BudgetEditingFundingRequestFloatingBox />
      </>
    );
  }
}));

function FundingRequestHeaderAndItems(props) { //props: p_fundingRequestsArrayOfObjs, p_headerText, p_headerColor
  const fundingRequestsArrayOfObjs = props.p_fundingRequestsArrayOfObjs;
  const headerText = props.p_headerText;
  const headerColor = props.p_headerColor;

  const numFundingRequests = fundingRequestsArrayOfObjs.length;

  if(numFundingRequests === 0) {
    return(null);
  }

  return(
    <>
      <div className="displayFlexRow hugeTopMargin smallBottomMargin">
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <div className="border bevelBorderColors" style={{height:"1.4em", width:"1.1em", background:"#" + headerColor}} />
        </div>
        <div className="flex11a displayFlexRowVc">
          <font className="font12 fontBold fontItalic fontTextLighter">
            {numFundingRequests + " Funding " + JSFUNC.plural(numFundingRequests, "Request", "Requests") + " " + headerText}
          </font>
        </div>
      </div>
      {fundingRequestsArrayOfObjs.map((m_fundingRequestObj) =>
        <FundingRequestItem
          key={m_fundingRequestObj.id + "ne" + m_fundingRequestObj.numExpenses}
          p_fundingRequestObj={m_fundingRequestObj}
        />
      )}
    </>
  );
}

const FundingRequestItem = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class FundingRequestItem extends Component { //props: p_fundingRequestObj
  onclick_open_funding_request_edit = () => {
    this.props.OpenCaptureMobx.a_budget_set_editing_funding_request_id(this.props.p_fundingRequestObj.id);
  }

  onclick_confirm_delete_funding_request = () => {
    this.props.OpenCaptureMobx.a_budget_delete_funding_request(this.props.p_fundingRequestObj);
  }

  render() {
    const p_fundingRequestObj = this.props.p_fundingRequestObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const requestedDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_fundingRequestObj.rq_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const approvedDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_fundingRequestObj.ap_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const totalExpensesMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_fundingRequestObj.totalExpenses, this.props.DatabaseMobx.c_genericMoneyFieldTypeObj);

    const numExpensesString = p_fundingRequestObj.numExpenses +  " " + JSFUNC.plural(p_fundingRequestObj.numExpenses, "Expense", "Expenses") + " Listed";

    return(
      <div
        className="displayFlexRow border bevelBorderColors hoverLighterBlueGradient cursorPointer"
        style={{height:"3.3em"}}
        onClick={this.onclick_open_funding_request_edit}>
        <div className="flex00a displayFlexColumnHcVc lrPad" style={{flexBasis:"8em"}}>
          <div className="microBottomMargin">
            <font className="fontItalic fontTextLighter">
              {"Requested"}
            </font>
          </div>
          <div className="">
            <font className="">
              {requestedDateMaskSortIfoObj.valueMask}
            </font>
          </div>
        </div>
        <div className="flex00a displayFlexColumnHcVc lrPad" style={{flexBasis:"8em"}}>
          <div className="microBottomMargin">
            <font className="fontItalic fontTextLighter">
              {"Approved"}
            </font>
          </div>
          <div className="">
            <font className="">
              {approvedDateMaskSortIfoObj.valueMask}
            </font>
          </div>
        </div>
        <div className="flex11a displayFlexRowVc lrMedPad" title={numExpensesString}>
          <LibraryReact.MaxHeightWrap p_maxHeight="2.6em" p_fontClass="fontTextLight">
            {numExpensesString}
          </LibraryReact.MaxHeightWrap>
        </div>
        <div className="flex00a displayFlexColumnVc lrPad textRight" style={{flexBasis:"8em"}}>
          <font className="font11">
            {totalExpensesMaskSortIfoObj.valueMask}
          </font>
        </div>
        {(c_userCanEditCaptureCardContentTF) ? (
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
            <CEGeneralReact.DeleteMenu
              p_message="Delete this Funding Request?"
              f_onDelete={this.onclick_confirm_delete_funding_request}
            />
          </div>
        ) : (
          <div className="flex00a" style={{flexBasis:"0.8em"}} />
        )}
      </div>
    );
  }
}));

const BudgetEditingFundingRequestFloatingBox = inject("OpenCaptureMobx", "DatabaseMobx", "BudgetMobx", "UserMobx")(observer(
class BudgetEditingFundingRequestFloatingBox extends Component { //props:
  onsave_expense_cm_notes = (i_newValue) => {
    const c_budgetEditingFundingRequestObj = this.props.OpenCaptureMobx.c_budgetEditingFundingRequestObj;
    this.props.OpenCaptureMobx.a_budget_update_funding_request_field(c_budgetEditingFundingRequestObj.id, "cm_notes", i_newValue, "s");
  }

  onclick_submit_funding_request = () => {
    const c_budgetEditingFundingRequestObj = this.props.OpenCaptureMobx.c_budgetEditingFundingRequestObj;
    this.props.BudgetMobx.a_budget_update_funding_request_status(c_budgetEditingFundingRequestObj, 1, "", this.onclick_close_editing_funding_request);
  }

  onclick_resubmit_rejected_funding_request = () => {
    const c_budgetEditingFundingRequestObj = this.props.OpenCaptureMobx.c_budgetEditingFundingRequestObj;
    this.props.BudgetMobx.a_budget_update_funding_request_status(c_budgetEditingFundingRequestObj, 3, "", this.onclick_close_editing_funding_request);
  }

  onclick_close_editing_funding_request = () => {
    this.props.OpenCaptureMobx.a_budget_set_editing_funding_request_id(undefined);
  }

  render() {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_budgetSelectedExpandedBudgetCategoryObj = this.props.OpenCaptureMobx.c_budgetSelectedExpandedBudgetCategoryObj;
    const c_budgetEditingFundingRequestObj = this.props.OpenCaptureMobx.c_budgetEditingFundingRequestObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    if(c_budgetEditingFundingRequestObj === undefined) {
      return(null);
    }

    const expensesArrayOfObjs = c_budgetEditingFundingRequestObj.expensesArrayOfObjs;
    const captureManagerCanAddEditDeleteExpensesTF = (c_userCanEditCaptureCardContentTF && JSFUNC.in_array(c_budgetEditingFundingRequestObj.nr0_rq1_rj2_rs3_ap4, [0, 2]));
    const fundingRequestLabelColorObj = this.props.BudgetMobx.funding_request_label_and_color_obj_from_status_number(c_budgetEditingFundingRequestObj.nr0_rq1_rj2_rs3_ap4);

    const requestedDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(c_budgetEditingFundingRequestObj.rq_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const rejectedDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(c_budgetEditingFundingRequestObj.rj_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const resubmittedDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(c_budgetEditingFundingRequestObj.rs_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    const approvalDateMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(c_budgetEditingFundingRequestObj.ap_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);

    const expenseTotalMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(c_budgetEditingFundingRequestObj.totalExpenses, this.props.DatabaseMobx.c_genericMoneyFieldTypeObj);

    const topLabelWidths = "7em";
    const topLabelFontClass = "fontItalic fontTextLighter";

    var submitRequestButtonsComponent = null;
    if(captureManagerCanAddEditDeleteExpensesTF) {
      var submitFundingRequestButtonComponent = null;
      if(c_budgetEditingFundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 0) {
        submitFundingRequestButtonComponent = (
          <CEGeneralReact.ButtonWithConfirmBox
            p_buttonType="blue"
            p_buttonText="Submit Funding Request"
            p_confirmType="confirm"
            p_confirmMessage={"Confirm requesting " + expenseTotalMaskSortIfoObj.valueMaskPlainText + " for budget '" + c_budgetEditingFundingRequestObj.budgetCategoryName + "'?"}
            f_onClickConfirm={this.onclick_submit_funding_request}
          />
        );
      }
      else if(c_budgetEditingFundingRequestObj.nr0_rq1_rj2_rs3_ap4 === 2) {
        submitFundingRequestButtonComponent = (
          <CEGeneralReact.ButtonWithConfirmBox
            p_buttonType="blue"
            p_buttonText="Resubmit Updated Funding Request"
            p_confirmType="confirm"
            p_confirmMessage={"Confirm requesting " + expenseTotalMaskSortIfoObj.valueMaskPlainText + " for budget '" + c_budgetEditingFundingRequestObj.budgetCategoryName + "'?"}
            f_onClickConfirm={this.onclick_resubmit_rejected_funding_request}
          />
        );
      }

      submitRequestButtonsComponent = (
        <div className="flex00a tbPad borderT1ddd">
          <div className="lrMargin">
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"budgetFundingRequest" + c_budgetEditingFundingRequestObj.id + "cm_notes"}
              p_fieldDisplayName="Notes for Budget Manager (Optional)"
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
              p_valueRaw={c_budgetEditingFundingRequestObj.cm_notes}
              p_valueIsEditableTFU={((c_userCanEditCaptureCardContentTF) ? (true) : (undefined))}
              p_fieldClass="fontBlue"
              p_fieldWidth="17em"
              p_valueMaxHeight="nowrap"
              p_floatingBoxTitle="Editing Funding Request Notes to Budget Manager"
              f_onSaveChanged={this.onsave_expense_cm_notes}
            />
          </div>
          <div className="displayFlexRowHcVc smallTopMargin">
            <div className="flex00a lrMedMargin">
              {submitFundingRequestButtonComponent}
            </div>
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="budgetFundingRequest"
        p_title="Editing Funding Request"
        f_onClickSave={this.onclick_close_editing_funding_request}>
        <div className="flex11a yScroll smallFullPad">
          <div className="displayFlexRowVc">
            <div className="flex00a" style={{flexBasis:topLabelWidths}}>
              <font className={topLabelFontClass}>
                {"Status:"}
              </font>
            </div>
            <div className="flex00a displayFlexColumnVc" style={{flexBasis:"1.5em"}}>
              <div className="border bevelBorderColors" style={{height:"1.1em", width:"0.9em", background:"#" + fundingRequestLabelColorObj.color}} />
            </div>
            <div className="flex11a">
              <font className="fontBold fontItalic fontTextLighter">
                {fundingRequestLabelColorObj.label}
              </font>
            </div>
          </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:topLabelWidths}}>
              <font className={topLabelFontClass}>
                {"Requested:"}
              </font>
            </div>
            <div className="flex00a">
              {requestedDateMaskSortIfoObj.valueMask}
            </div>
            <div className="flex11a lrMedPad">
              {(JSFUNC.select_int_is_filled_out_tf(c_budgetEditingFundingRequestObj.rq_user_id)) &&
                <font className="fontItalic fontTextLight">
                  {"by "}
                  {this.props.DatabaseMobx.user_name_mask_from_user_id(c_budgetEditingFundingRequestObj.rq_user_id)}
                </font>
              }
            </div>
          </div>
          {(rejectedDateMaskSortIfoObj.isFilledOutTF) &&
            <div className="displayFlexRowVc smallTopMargin">
              <div className="flex00a" style={{flexBasis:topLabelWidths}}>
                <font className={topLabelFontClass}>
                  {"Rejected:"}
                </font>
              </div>
              <div className="flex00a">
                {rejectedDateMaskSortIfoObj.valueMask}
              </div>
              <div className="flex11a lrMedPad">
                {(JSFUNC.select_int_is_filled_out_tf(c_budgetEditingFundingRequestObj.rj_user_id)) &&
                  <font className="fontItalic fontTextLight">
                    {"by "}
                    {this.props.DatabaseMobx.user_name_mask_from_user_id(c_budgetEditingFundingRequestObj.rj_user_id)}
                  </font>
                }
              </div>
            </div>
          }
          {(resubmittedDateMaskSortIfoObj.isFilledOutTF) &&
            <div className="displayFlexRowVc smallTopMargin">
              <div className="flex00a" style={{flexBasis:topLabelWidths}}>
                <font className={topLabelFontClass}>
                  {"Resubmitted:"}
                </font>
              </div>
              <div className="flex00a">
                {resubmittedDateMaskSortIfoObj.valueMask}
              </div>
              <div className="flex11a lrMedPad">
                {(JSFUNC.select_int_is_filled_out_tf(c_budgetEditingFundingRequestObj.rs_user_id)) &&
                  <font className="fontItalic fontTextLight">
                    {"by "}
                    {this.props.DatabaseMobx.user_name_mask_from_user_id(c_budgetEditingFundingRequestObj.rs_user_id)}
                  </font>
                }
              </div>
            </div>
          }
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:topLabelWidths}}>
              <font className={topLabelFontClass}>
                {"Approved:"}
              </font>
            </div>
            <div className="flex00a">
              {approvalDateMaskSortIfoObj.valueMask}
            </div>
            <div className="flex11a lrMedPad">
              {(JSFUNC.select_int_is_filled_out_tf(c_budgetEditingFundingRequestObj.ap_user_id)) &&
                <font className="fontItalic fontTextLight">
                  {"by "}
                  {this.props.DatabaseMobx.user_name_mask_from_user_id(c_budgetEditingFundingRequestObj.ap_user_id)}
                </font>
              }
            </div>
          </div>
          {(c_budgetEditingFundingRequestObj.bm_notes !== "") &&
            <div className="displayFlexRowVc smallTopMargin">
              <div className="flex00a" style={{flexBasis:"14em"}}>
                <font className={topLabelFontClass}>
                  {"Notes from Budget Manager:"}
                </font>
              </div>
              <div className="flex11a">
                {c_budgetEditingFundingRequestObj.bm_notes}
              </div>
            </div>
          }
          <div className="displayFlexColumnHcVc microTopMargin">
            <font className="font20 fontBold fontTextLighter">
              {expenseTotalMaskSortIfoObj.valueMask}
            </font>
          </div>
          <div className="displayFlexColumnHcVc smallTopMargin">
            <font className="font13 fontTextLighter">
              {"Requested from Estimated Expenses Entered Below"}
            </font>
          </div>
          <div className="medTopMargin">
            {(captureManagerCanAddEditDeleteExpensesTF) &&
              <BudgetReact.BudgetAddExpenseButtonWithInlineAdd
                p_buttonText="Add an Estimated Expense"
                p_captureID={o_openCaptureID}
                p_budgetCategoryID={c_budgetSelectedExpandedBudgetCategoryObj.id}
                p_fundingRequestID={c_budgetEditingFundingRequestObj.id}
              />
            }
          </div>
          <div className="medFullMargin">
            {expensesArrayOfObjs.map((m_expenseObj) =>
              <BudgetReact.BudgetExpenseItemWithEdit
                key={m_expenseObj.id}
                p_expenseObj={m_expenseObj}
                p_label="Funding Request Expense Estimate"
                p_canEditDeleteTF={captureManagerCanAddEditDeleteExpensesTF}
              />
            )}
          </div>
        </div>
        {submitRequestButtonsComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const BudgetSpentExpenses = inject("OpenCaptureMobx", "UserMobx")(observer(
class BudgetSpentExpenses extends Component { //props: p_isCompactTF
  render() {
    const p_isCompactTF = this.props.p_isCompactTF;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_budgetSelectedExpandedBudgetCategoryObj = this.props.OpenCaptureMobx.c_budgetSelectedExpandedBudgetCategoryObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    var boxMarginClass = "tbMedMargin";
    var boxPaddingClass = "tbMedPad";
    var remainingBoxWidth = "25em";
    var remainingMoneyFontClass = "font22";
    var remainingLabelFontClass = "font16";
    if(p_isCompactTF) {
      boxMarginClass = "tbMargin";
      boxPaddingClass = "tbPad";
      remainingBoxWidth = "20em";
      remainingMoneyFontClass = "font17";
      remainingLabelFontClass = "font13";
    }

    return(
      <div className="flex11a yScroll yScrollBottomPad">
        <div className={"displayFlexColumnHcVc " + boxMarginClass}>
          <div className={"displayFlexColumnHcVc bgLighterGray " + boxPaddingClass} style={{border:"solid 1px #bbb", width:remainingBoxWidth}}>
            <div className="">
              <font className={"fontBold fontTextLighter " + remainingMoneyFontClass}>
                {JSFUNC.money(c_budgetSelectedExpandedBudgetCategoryObj.totalSpent, 0, true)}
              </font>
            </div>
            <div className="">
              <font className={"fontTextLighter " + remainingLabelFontClass}>
                {"Spent"}
              </font>
            </div>
          </div>
        </div>
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="medTopMargin">
            <BudgetReact.BudgetAddExpenseButtonWithInlineAdd
              p_buttonText="Add a Spent Expense"
              p_captureID={o_openCaptureID}
              p_budgetCategoryID={c_budgetSelectedExpandedBudgetCategoryObj.id}
              p_fundingRequestID={-1} //this expense is not part of a funding request
            />
          </div>
        }
        <div className="medFullMargin">
          {c_budgetSelectedExpandedBudgetCategoryObj.expensesArrayOfObjs.map((m_expenseObj) =>
            <BudgetReact.BudgetExpenseItemWithEdit
              key={m_expenseObj.id}
              p_expenseObj={m_expenseObj}
              p_label="Spent Expense"
              p_canEditDeleteTF={c_userCanEditCaptureCardContentTF}
            />
          )}
        </div>
      </div>
    );
  }
}));












//===================================================================================================================================
//--------Conversations-------------------------------------------------------------------------------------------
//===================================================================================================================================
const ConversationsSnapshot = inject("OpenCaptureMobx")(observer(
class ConversationsSnapshot extends Component {
  render() {
    const numUpcoming = this.props.OpenCaptureMobx.c_upcomingConversationsArrayOfObjs.length;
    const numNotSet = this.props.OpenCaptureMobx.c_notsetConversationsArrayOfObjs.length;
    const numPast = this.props.OpenCaptureMobx.c_pastConversationsArrayOfObjs.length;
    const numTotal = numUpcoming + numNotSet + numPast;

    //centered display for no upcoming conversations
    if(numTotal === 0 || numUpcoming === 0) {
      const noConversationsMessage = "No Conversations recorded yet";
      const conversationsButNoneUpcomingMessage = numTotal + " Recorded Conversation" + ((numTotal === 1) ? ("") : ("s"));
      return(
        <div className="flex11a displayFlexColumnHcVc fontItalic fontTextLighter">
          {((numTotal === 0) ? (noConversationsMessage) : (conversationsButNoneUpcomingMessage))}
        </div>
      );
    }

    return(
      <div className="flex11a overflowHidden">
        <div className="smallFullMargin fontItalic fontTextLighter">
          {numTotal + " Recorded " + JSFUNC.plural(numTotal, "Conversation", "Conversations") + ", " + numUpcoming + " Upcoming"}
        </div>
        {this.props.OpenCaptureMobx.c_upcomingConversationsArrayOfObjs.map((m_conversationObj) =>
          <ConversationSnapshotItem
            key={m_conversationObj.id}
            p_conversationObj={m_conversationObj}
          />
        )}
      </div>
    );
  }
}));

const ConversationSnapshotItem = inject("DatabaseMobx")(observer(
class ConversationSnapshotItem extends Component { //p_conversationObj
  render() {
    const p_conversationObj = this.props.p_conversationObj;
    
    return(
      <div className="displayFlexRow smallTopPad smallBottomPad" style={{borderTop:"solid 1px #eee"}}>
        <div className="flex00a lrMargin" style={{flexBasis:"45%"}}>
          <LibraryReact.Nowrap>
            {p_conversationObj.contactPersonNamesComma}
          </LibraryReact.Nowrap>
        </div>
        {p_conversationObj.dateTimeMask}
      </div>
    );
  }
}));

const Conversations = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class Conversations extends Component { //props: p_container
  onclick_create_new_conversation = () => {
    this.props.CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id(this.new_conversation_edit_item_string());
  }

  new_conversation_edit_item_string() {
    return(this.props.OpenCaptureMobx.ce_edit_item_string(this.props.DatabaseMobx.k_cardIDConversations, -1)); //-1 for the conversation id is a flag that a new conversation not yet in the database is being created
  }

  render() {
    const p_container = this.props.p_container;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const conversationsIsSplitScreenTF = this.props.OpenCaptureMobx.conversations_upcoming_completed_is_split_screen_tf(p_container);
    const isCreatingNewConversationTF = this.props.CaptureExecMobx.is_editing_item_tf(this.new_conversation_edit_item_string());

    const upcomingConversationsArrayOfObjs = this.props.OpenCaptureMobx.c_upcomingConversationsArrayOfObjs;
    const notsetConversationsArrayOfObjs = this.props.OpenCaptureMobx.c_notsetConversationsArrayOfObjs;
    const pastConversationsArrayOfObjs = this.props.OpenCaptureMobx.c_pastConversationsArrayOfObjs;

    const upcomingLabel = "Upcoming Conversations";
    const dateNotSetLabel = "Conversations With Date Not Set";
    const pastLabel = "Past Conversations";

    return(
      <div className="flex11a displayFlexColumn">
        {(c_userCanEditCaptureCardContentTF) &&
          <div className="flex00a displayFlexColumnHcVc medFullPad bgPanelLightGray" style={{borderBottom:"solid 1px #999"}}>
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Create a New Conversation"
              p_tabIndex={1}
              f_onClick={this.onclick_create_new_conversation}
            />
            {(isCreatingNewConversationTF) &&
              <ConversationsAddNewOrEditConversationFloatingBox
                p_captureID={o_openCaptureID}
              />
            }
          </div>
        }
        {(conversationsIsSplitScreenTF) ? (
          <div className="flex11a displayFlexRow">
            <div className="flex11a displayFlexColumn" style={{flexBasis:"100em", borderRight:"solid 1px #eee"}}>
              <ConversationListsWithTitles
                p_container={p_container}
                p_captureID={o_openCaptureID}
                p_conversationsArrayOfArrayOfObjs={[upcomingConversationsArrayOfObjs, notsetConversationsArrayOfObjs]}
                p_labelsArray={[upcomingLabel, dateNotSetLabel]}
                p_hideEmptyListsTFArrayOrUndefined={[false, true]}
              />
            </div>
            <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
              <ConversationListsWithTitles
                p_container={p_container}
                p_captureID={o_openCaptureID}
                p_conversationsArrayOfArrayOfObjs={[pastConversationsArrayOfObjs]}
                p_labelsArray={[pastLabel]}
              />
            </div>
          </div>
        ) : (
          <ConversationListsWithTitles
            p_container={p_container}
            p_captureID={o_openCaptureID}
            p_conversationsArrayOfArrayOfObjs={[upcomingConversationsArrayOfObjs, notsetConversationsArrayOfObjs, pastConversationsArrayOfObjs]}
            p_labelsArray={[upcomingLabel, dateNotSetLabel, pastLabel]}
          />
        )}
      </div>
    );
  }
}));

function ConversationListsWithTitles(props) { //props: p_container, p_captureID, p_conversationsArrayOfArrayOfObjs, p_labelsArray, p_hideEmptyListsTFArrayOrUndefined
  const p_container = props.p_container;
  const p_captureID = props.p_captureID;
  const p_conversationsArrayOfArrayOfObjs = props.p_conversationsArrayOfArrayOfObjs;
  const p_labelsArray = props.p_labelsArray;
  const p_hideEmptyListsTFArrayOrUndefined = props.p_hideEmptyListsTFArrayOrUndefined;

  var hideEmptyListsTFArray = p_hideEmptyListsTFArrayOrUndefined;
  if(hideEmptyListsTFArray === undefined) {
    hideEmptyListsTFArray = JSFUNC.array_fill(p_conversationsArrayOfArrayOfObjs.length, false);
  }

  return(
    <div className="flex11a yScroll yScrollBottomPad smallTopMargin">
      {p_conversationsArrayOfArrayOfObjs.map((m_conversationsArrayOfObjs, m_index) =>
        <div key={p_labelsArray[m_index]}>
          {(!hideEmptyListsTFArray[m_index] || (m_conversationsArrayOfObjs.length > 0)) &&
            <div className={"lrMargin smallBottomMargin " + ((m_index > 0) ? ("hugeTopMargin") : (""))}>
              <div className="font12 fontItalic fontBlueLight">
                {p_labelsArray[m_index] + " (" + m_conversationsArrayOfObjs.length + ")"}
              </div>
            </div>
          }
          {m_conversationsArrayOfObjs.map((m_conversationObj) =>
            <ConversationItem
              key={m_conversationObj.id}
              p_container={p_container}
              p_captureID={p_captureID}
              p_conversationObj={m_conversationObj}
            />
          )}
        </div>
      )}
    </div>
  );
}

const ConversationItem = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class ConversationItem extends Component { //props: p_container, p_captureID, p_conversationObj
  onclick_conversation_open_edit = () => {
    this.props.CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id(this.conversation_edit_item_string());
  }

  onclick_confirm_delete_conversation = () => {
    const p_conversationObj = this.props.p_conversationObj;
    this.props.OpenCaptureMobx.a_conversations_delete_conversation(p_conversationObj);
  }

  conversation_edit_item_string() {
    const p_conversationObj = this.props.p_conversationObj;
    return(this.props.OpenCaptureMobx.ce_edit_item_string(this.props.DatabaseMobx.k_cardIDConversations, p_conversationObj.id));
  }

  render() {
    const p_container = this.props.p_container;
    const p_captureID = this.props.p_captureID;
    const p_conversationObj = this.props.p_conversationObj;

    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isEditingTF = this.props.CaptureExecMobx.is_editing_item_tf(this.conversation_edit_item_string());
    const fieldsLeftDescriptionRightTF = this.props.OpenCaptureMobx.conversations_item_fields_left_description_right_tf(p_container);

    return(
      <>
        <div
          className={((fieldsLeftDescriptionRightTF) ? ("displayFlexRow") : ("displayFlexRowVc")) + " medFullMargin border bevelBorderColors borderRadius05 bgConversationsLightestBlueGradientLR hoverLighterBlueGradient cursorPointer"}
          style={{height:((fieldsLeftDescriptionRightTF) ? ("4em") : ("5em"))}}
          onClick={this.onclick_conversation_open_edit}>
          {(fieldsLeftDescriptionRightTF) ? (
            <>
              <div className="flex11a displayFlexColumnVc lrMedPad" style={{flexBasis:"100em", borderRight:"solid 1px #ccc"}}>
                <div>
                  {p_conversationObj.dateTimeMask}
                </div>
                <div className="smallTopMargin">
                  <LibraryReact.Nowrap>
                    {p_conversationObj.contactPersonNamesComma}
                  </LibraryReact.Nowrap>
                </div>
              </div>
              <div className="flex11a displayFlexRowVc lrMedPad" style={{flexBasis:"240em"}}>
                <LibraryReact.MaxHeightWrap p_maxHeight="2.9em">
                  {p_conversationObj.notes}
                </LibraryReact.MaxHeightWrap>
              </div>
            </>
          ) : (
            <div className="flex11a lrMedPad">
              <div>
                {p_conversationObj.dateTimeMask}
              </div>
              <div className="microTopMargin">
                <LibraryReact.Nowrap>
                  {p_conversationObj.contactPersonNamesComma}
                </LibraryReact.Nowrap>
              </div>
              <div className="microTopMargin">
                <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                  {p_conversationObj.notes}
                </LibraryReact.Nowrap>
              </div>
            </div>
          )}
          {(c_userCanEditCaptureCardContentTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
              <CEGeneralReact.DeleteMenu
                p_message="Are you sure you want to delete this Conversation?"
                f_onDelete={this.onclick_confirm_delete_conversation}
              />
            </div>
          }
        </div>
        {(isEditingTF) &&
          <ConversationsAddNewOrEditConversationFloatingBox
            p_captureID={p_captureID}
            p_conversationObj={p_conversationObj}
          />
        }
      </>
    );
  }
}));

const ConversationsAddNewOrEditConversationFloatingBox = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class ConversationsAddNewOrEditConversationFloatingBox extends Component { //props: p_captureID, p_conversationObj
  constructor(props) {
    super(props);

    const p_conversationObj = this.props.p_conversationObj;
    var datetimeUTC = JSFUNC.now_datetime_utc(); //initialization for a new conversation
    var contactPersonIDsComma = "";
    var notes = "";
    if(p_conversationObj !== undefined) { //editing an existing conversation obj if it is provided (if not provided, creating a new conversation)
      datetimeUTC = p_conversationObj.datetime_utc;
      contactPersonIDsComma = p_conversationObj.contact_person_ids_comma;
      notes = p_conversationObj.notes;
    }

    this.state = {
      s_initDatetimeUTC: datetimeUTC,
      s_updatedDatetimeUTC: datetimeUTC,
      s_initContactPersonIDsComma: contactPersonIDsComma,
      s_updatedContactPersonIDsComma: contactPersonIDsComma,
      s_initNotes: notes,
      s_updatedNotes: notes
    }
  }

  onsave_conversation_item = () => {
    const initDatetimeUTC = this.state.s_initDatetimeUTC;
    const updatedDatetimeUTC = this.state.s_updatedDatetimeUTC;
    const initContactPersonIDsComma = this.state.s_initContactPersonIDsComma;
    const updatedContactPersonIDsComma = this.state.s_updatedContactPersonIDsComma;
    const initNotes = this.state.s_initNotes;
    const updatedNotes = this.state.s_updatedNotes;

    const p_captureID = this.props.p_captureID;
    const p_conversationObj = this.props.p_conversationObj;

    if(p_conversationObj === undefined) { //adding a new item
      this.props.OpenCaptureMobx.a_conversations_insert_new_conversation(p_captureID, updatedDatetimeUTC, updatedContactPersonIDsComma, updatedNotes);
    }
    else { //editing an item
      var newDatetimeUTC = ((updatedDatetimeUTC !== initDatetimeUTC) ? (updatedDatetimeUTC) : (undefined)); //values are undefined if they have not been changed in this edit
      var newContactPersonIDsComma = ((updatedContactPersonIDsComma !== initContactPersonIDsComma) ? (updatedContactPersonIDsComma) : (undefined));
      var newNotes = ((updatedNotes !== initNotes) ? (updatedNotes) : (undefined));
      this.props.OpenCaptureMobx.a_conversations_update_conversation(p_captureID, p_conversationObj.id, newDatetimeUTC, newContactPersonIDsComma, newNotes);
    }
    this.onclose_conversation_item();
  }

  onclose_conversation_item = () => {
    this.props.CaptureExecMobx.a_close_item_editing();
  }

  onchange_datetimeUTC = (i_newValue) => { this.setState({s_updatedDatetimeUTC:i_newValue}); }
  onchange_contact_person_ids_comma = (i_newValue) => { this.setState({s_updatedContactPersonIDsComma:i_newValue}); }
  onchange_notes = (i_newValue) => { this.setState({s_updatedNotes:i_newValue}); }

  render() {
    const initDatetimeUTC = this.state.s_initDatetimeUTC;
    const updatedDatetimeUTC = this.state.s_updatedDatetimeUTC;
    const initContactPersonIDsComma = this.state.s_initContactPersonIDsComma;
    const updatedContactPersonIDsComma = this.state.s_updatedContactPersonIDsComma;
    const initNotes = this.state.s_initNotes;
    const updatedNotes = this.state.s_updatedNotes;

    const p_captureID = this.props.p_captureID;
    const p_conversationObj = this.props.p_conversationObj;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const isAddingNewConversationTF = (p_conversationObj === undefined);
    const anyFieldIsUpdatedTF = ((initDatetimeUTC !== updatedDatetimeUTC) || (initContactPersonIDsComma !== updatedContactPersonIDsComma) || (initNotes !== updatedNotes));

    const dateTimeComponent = (
      <>
        <div className="displayFlexColumnHcVc tbMedPad borderB1ddd">
          <font className="font13">
            {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(updatedDatetimeUTC, this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil1FieldTypeObj)}
          </font>
        </div>
        {(c_userCanEditCaptureCardContentTF) &&
          <>
            <div className="medTopMargin smallBottomMargin">
              <font className="font11 fontItalic fontTextLight">
                {"Conversation Date/Time:"}
              </font>
            </div>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateTimeFieldTypeObj}
              p_valueRaw={updatedDatetimeUTC}
              p_tabIndex={2}
              f_onChangeOrOnSelect={this.onchange_datetimeUTC}
            />
          </>
        }
      </>
    );

    const contactPersonsComponent = (
      <>
        <div className="smallBottomMargin">
          <font className="font11 fontItalic fontTextLight">
            {"Contact(s) Involved:"}
          </font>
        </div>
        <ContactsReact.MultiAddContactBubblesVerticalList
          p_canEditTF={c_userCanEditCaptureCardContentTF}
          p_isPersonTF={true}
          p_addedContactIDsComma={updatedContactPersonIDsComma}
          p_addButtonType="add"
          p_addButtonText="Add Contact(s) to Conversation"
          p_addInstructions="Select Persons Involved in this Conversation"
          p_tabIndex={20}
          f_onChange={this.onchange_contact_person_ids_comma}
        />
      </>
    );

    const notesComponent = (
      <>
        <div className="flex00a smallBottomMargin">
          <font className="font11 fontItalic fontTextLight">
            {"Notes:"}
          </font>
        </div>
        {(c_userCanEditCaptureCardContentTF) ? (
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
            p_valueRaw={updatedNotes}
            p_tabIndex={1}
            f_onChangeOrOnSelect={this.onchange_notes}
          />
        ) : (
          <div className="flex11a border1bbb bgWhite smallFullPad yScroll">
            <font className="">
              {updatedNotes}
            </font>
          </div>
        )}
      </>
    );

    var floatingBoxContentComponent = null;
    if(c_isMobileTF) {
      floatingBoxContentComponent = (
        <div className="flex11a yScroll yScrollBottomPad smallFullPad bgConversationsLightestBlue">
          {dateTimeComponent}
          <div className="hugeTopMargin" />
          {contactPersonsComponent}
          <div className="hugeTopMargin displayFlexColumn" style={{height:"20em"}}>
            {notesComponent}
          </div>
        </div>
      );
    }
    else {
      floatingBoxContentComponent = (
        <div className="flex11a displayFlexRow">
          <div className="flex00a yScroll yScrollBottomPad smallFullPad bgConversationsLightestBlueGradientLR" style={{flexBasis:"25em", borderRight:"solid 1px #ddd"}}>
            {dateTimeComponent}
            <div className="hugeTopMargin" />
            {contactPersonsComponent}
          </div>
          <div className="flex11a displayFlexColumn smallFullPad" style={{flexBasis:"100em"}}>
            {notesComponent}
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="conversations"
        p_title={((isAddingNewConversationTF) ? ("Create New Conversation"): ("Editing Conversation"))}
        p_cancelConfirmationMessage={((anyFieldIsUpdatedTF) ? ("Are you sure you want to cancel " + ((isAddingNewConversationTF) ? ("creating"): ("editing")) + " this Conversation? Any entered data will be lost.") : (undefined))}
        f_onClickSave={((c_userCanEditCaptureCardContentTF) ? (this.onsave_conversation_item) : (undefined))}
        f_onClickCancel={this.onclose_conversation_item}>
        {floatingBoxContentComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));







//===================================================================================================================================
//--------Templates-------------------------------------------------------------------------------------------
//===================================================================================================================================
const TemplatesSnapshot = inject("OpenCaptureMobx")(observer(
class TemplatesSnapshot extends Component {
  render() {
    const numTemplates = this.props.OpenCaptureMobx.c_singleCaptureTemplatesWithinFoldersObj.numTemplates;
    return(
      <div className="flex11a displayFlexColumnHcVc">
        <div className="positionRelative" style={{boxShadow:"0.1em 0.1em 0.3em 0.1em #aac"}}>
          <CEGeneralReact.SvgPresentation p_sizeEm={12} />
          <div className="positionAbsolute displayFlexColumnHcVc" style={{top:"0%", width:"100%", height:"100%"}}>
            <div>
              <font className="font20 fontItalic fontTextLightester">
                {numTemplates}
              </font>
            </div>
            <div>
              <font className="font11 fontItalic fontTextLighter">
                {JSFUNC.plural(numTemplates, "Template", "Templates")}
              </font>
            </div>
            <div>
              <font className="font11 fontItalic fontTextLighter">
                {"Available"}
              </font>
            </div>
          </div>
        </div>
      </div>
    );
  }
}));

const Templates = inject("OpenCaptureMobx")(observer(
class Templates extends Component {
  render() {
    const singleCaptureTemplatesWithinFoldersObj = this.props.OpenCaptureMobx.c_singleCaptureTemplatesWithinFoldersObj;
    const numTemplates = singleCaptureTemplatesWithinFoldersObj.numTemplates;
    const foldersArrayOfObjs = singleCaptureTemplatesWithinFoldersObj.foldersArrayOfObjs;
    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a smallFullMargin textCenter">
          <font className="font11 fontBold fontTextLight">
            {"Click the 'Generate' button next to a template below to download a copy populated with the latest data from this capture"}
          </font>
        </div>
        {(numTemplates === 0) ? (
          <CEGeneralReact.EmptyScreenGray>
            {"Your CaptureExec Admin has not created any single capture templates yet"}
          </CEGeneralReact.EmptyScreenGray>
        ) : (
          <div className="flex11a yScroll yScrollBottomPad">
            {foldersArrayOfObjs.map((m_folderObj, m_folderIndex) =>
              (m_folderObj.numFiles > 0) &&
              <div key={m_folderObj.id} className="" style={{marginBottom:"4em"}}>
                <div className="medFullPad" style={{background:"#f6f0ff", borderTop:"solid 1px #ccc", borderBottom:"solid 1px #758"}}>
                  <font className="font12 fontBold fontItalic fontTextLighter">
                    {m_folderObj.folderPathString}
                  </font>
                </div>
                {m_folderObj.filesArrayOfObjs.map((m_templateObj, m_fileIndex) =>
                  <TemplateItem key={m_templateObj.id} p_templateObj={m_templateObj} p_tabIndex={(m_fileIndex + 2)} />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}));

const TemplateItem = inject("OpenCaptureMobx")(observer(
class TemplateItem extends Component { //props: p_templateObj, p_tabIndex
  render() {
    const p_templateObj = this.props.p_templateObj;
    const p_tabIndex = this.props.p_tabIndex;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_captureName = this.props.OpenCaptureMobx.c_captureName;

    return(
      <div className="displayFlexRow tbPad hoverLighterBlueGradient" style={{height:"4.5em", borderBottom:"solid 1px #ccc"}}>
        <div className="flex00a displayFlexRowVc lrMedPad borderR1ddd">
          <CEGeneralReact.GenerateAndDownloadSingleCaptureTemplateButtonProcess
            p_fromOpenCaptureTrueAdminFalse={true}
            p_captureID={o_openCaptureID}
            p_singleCaptureTemplateID={p_templateObj.id}
            p_buttonText="Generate/Download Template"
            p_buttonTitle={"Generates a .xml file (for ppt, word, excel) using the data from this single capture (" + c_captureName + ") to populate the codewords within this Template file (" + p_templateObj.display_name + ").  The resulting file will be offered for download automatically."}
            p_tabIndex={p_tabIndex}
            p_canClickButtonTF={true}
          />
        </div>
        <div className="flex11a displayFlexRowVc lrMedPad borderR1ddd" style={{flexBasis:"100em"}}>
          <LibraryReact.MaxHeightWrap p_maxHeight="4em" p_fontClass="font13 fontBold fontBlueLighter">
            {p_templateObj.display_name}
          </LibraryReact.MaxHeightWrap>
        </div>
        <div className="flex11a displayFlexRowVc lrMedPad" style={{flexBasis:"100em"}}>
          <LibraryReact.MaxHeightWrap p_maxHeight="4em" p_fontClass="font11 fontItalic fontTextLighter">
            {p_templateObj.notes}
          </LibraryReact.MaxHeightWrap>
        </div>
      </div>
    );
  }
}));



//===================================================================================================================================
//--------Documents-------------------------------------------------------------------------------------------
//===================================================================================================================================
const DocumentsSnapshot = inject("OpenCaptureMobx")(observer(
class DocumentsSnapshot extends Component {
  render() {
    const numDocuments = this.props.OpenCaptureMobx.c_documentsNumFiles;
    return(
      <div className="flex11a displayFlexColumnHcVc">
        <div className="positionRelative" style={{boxShadow:"0.1em 0.1em 0.3em 0.1em #aac"}}>
          <CEGeneralReact.SvgFile p_sizeEm={11} p_color="888a99" p_innerColor="f9f9f9" p_thinEdgeTF={true} />
          <div className="positionAbsolute displayFlexColumnHcVc" style={{top:"5%", width:"100%", height:"100%"}}>
            <div>
              <font className="font20 fontItalic fontTextLightester">
                {numDocuments}
              </font>
            </div>
            <div>
              <font className="font11 fontItalic fontTextLighter">
                {JSFUNC.plural(numDocuments, "Document", "Documents")}
              </font>
            </div>
            <div>
              <font className="font11 fontItalic fontTextLighter">
                {"Uploaded"}
              </font>
            </div>
          </div>
        </div>
      </div>
    );
  }
}))

const Documents = inject("CaptureExecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class Documents extends Component { //props: p_container
  onperform_ffs_action = (i_actionText, i_descriptionText) => {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const k_cardIDDocuments = this.props.DatabaseMobx.k_cardIDDocuments;

    const jsDescription = JSFUNC.js_description_from_action("OpenCaptureReact - Documents", "onperform_ffs_action", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_changelog_details(o_openCaptureID, k_cardIDDocuments, "dffs", i_actionText, i_descriptionText);
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_container = this.props.p_container;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;

    const documentsCardViewWideTrueNarrowFalse = this.props.OpenCaptureMobx.documents_ffs_is_wide_tf(p_container);

    return(
      <CEGeneralReact.FileFolderSystem
        p_tblName="tbl_c_documents_filefoldersystem"
        p_tblMapOfMaps={this.props.OpenCaptureMobx.c_documentsFileFolderSystemMapOfMaps}
        p_tblFilterValuesObj={{capture_id:o_openCaptureID}}
        p_title={undefined}
        p_defaultXmlType={undefined}
        p_allowedExtsArray={undefined}
        p_disableUploadFilesTF={false}
        p_disableCreateOnlineLinkDocsTF={false}
        p_disableCreateFoldersTF={false}
        p_viewWideTrueNarrowFalse={documentsCardViewWideTrueNarrowFalse}
        p_navigateToFolderID={this.props.CaptureExecMobx.o_navigateToFolderIDTblCDocumentsFileFolderSystem}
        f_onPerformFFSAction={this.onperform_ffs_action}
        f_onNavigateToFolder={this.props.CaptureExecMobx.a_set_navigate_to_folder_id_tbl_c_documents_filefoldersystem}
      />
    );
  }
}))




//===================================================================================================================================
//--------IDIQ Task Orders-------------------------------------------------------------------------------------------
//===================================================================================================================================
const IdiqTaskOrdersSnapshot = inject("OpenCaptureMobx")(observer(
class IdiqTaskOrdersSnapshot extends Component {
  render() {
    const c_idiqTaskOrdersObj = this.props.OpenCaptureMobx.c_idiqTaskOrdersObj;

    const idiqObj = c_idiqTaskOrdersObj.idiqObj;
    const taskOrdersArrayOfObjs = c_idiqTaskOrdersObj.taskOrdersArrayOfObjs;

    //oen capture is single award contract or invalid contract type, only contract type name is displayed light gray in middle of the card
    if((idiqObj === undefined) || !JSFUNC.is_array(taskOrdersArrayOfObjs)) {
      return(
        <div className="flex11a overflowHidden smallFullMargin displayFlexColumnHcVc fontItalic fontTextLighter">
          {"Contract Type: "}<font className="fontBold">{c_idiqTaskOrdersObj.contractTypeName}</font>
        </div>
      );
    }

    const numTaskOrders = taskOrdersArrayOfObjs.length;

    //open capture is IDIQ or Task Order contract type
    return(
      <div className="flex11a overflowHidden smallFullMargin">
        <div className="textCenter">
          <LibraryReact.Nowrap>
            {"Contract Type: "}<font className="fontBold">{c_idiqTaskOrdersObj.contractTypeName}</font>
          </LibraryReact.Nowrap>
        </div>
        <IdiqTaskOrdersLabelDividerRow p_idiqTaskOrderFlag="idiq" p_isSnapshotTF={true} p_idiqTaskOrdersObj={c_idiqTaskOrdersObj} />
        <IdiqTaskOrderSnapshotCapture p_captureObj={idiqObj} />
        <IdiqTaskOrdersLabelDividerRow p_idiqTaskOrderFlag="taskOrders" p_isSnapshotTF={true} p_idiqTaskOrdersObj={c_idiqTaskOrdersObj} />
        {(numTaskOrders === 0) &&
          <div className="fontItalic fontTextLighter">
            {"No Task Orders linked to this IDIQ"}
          </div>
        }
        {taskOrdersArrayOfObjs.map((m_taskOrderObj) =>
          <IdiqTaskOrderSnapshotCapture key={m_taskOrderObj.id} p_captureObj={m_taskOrderObj} />
        )}
      </div>
    );
  }
}))

function IdiqTaskOrderSnapshotCapture(props) { //props: p_captureObj
  const fontBoldClass = ((props.p_captureObj.isCurrentlyOpenCaptureTF) ? (" fontBold") : (""));
  return(
    <div className={"displayFlexRow" + fontBoldClass}>
      <div className="flex11a">
        <LibraryReact.Nowrap>{props.p_captureObj.captureFullName}</LibraryReact.Nowrap>
      </div>
      <div className="flex00a textRight" style={{flexBasis:"5em"}}>
        <LibraryReact.Nowrap>{JSFUNC.money_short(props.p_captureObj.contractValueRaw)}</LibraryReact.Nowrap>
      </div>
    </div>
  );
}

const IdiqTaskOrders = inject("OpenCaptureMobx")(observer(
class IdiqTaskOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_idiqTOsSortColumnDbName: "captureFullName",
      s_idiqTOsSortIsAscTF: true
    }
  }

  onclick_column_header = (i_columnDbName) => {
    const s_idiqTOsSortColumnDbName = this.state.s_idiqTOsSortColumnDbName;
    const s_idiqTOsSortIsAscTF = this.state.s_idiqTOsSortIsAscTF;

    if(i_columnDbName === s_idiqTOsSortColumnDbName) { //clicked column already sorted, flip the direction of the sort
      const updatedSortIsAscTF = (!s_idiqTOsSortIsAscTF);
      this.setState({s_idiqTOsSortIsAscTF:updatedSortIsAscTF});
    }
    else {
      this.setState({
        s_idiqTOsSortColumnDbName: i_columnDbName,
        s_idiqTOsSortIsAscTF: true
      });
    }
  }

  onclick_idiq_task_order_capture = (i_captureIDClicked) => {
    this.props.OpenCaptureMobx.a_open_single_capture(i_captureIDClicked);
  }

  render() {
    const s_idiqTOsSortColumnDbName = this.state.s_idiqTOsSortColumnDbName;
    const s_idiqTOsSortIsAscTF = this.state.s_idiqTOsSortIsAscTF;

    const c_idiqTaskOrdersObj = this.props.OpenCaptureMobx.c_idiqTaskOrdersObj;

    const contractTypeName = c_idiqTaskOrdersObj.contractTypeName;
    const idiqObj = c_idiqTaskOrdersObj.idiqObj;
    const taskOrdersArrayOfObjs = c_idiqTaskOrdersObj.taskOrdersArrayOfObjs;

    //oen capture is single award contract or invalid contract type, only contract type name is displayed light gray in middle of the card
    if(idiqObj === undefined) {
      return(
        <div className="flex11a yScroll yScrollBottomPad smallFullMargin">
          <div>
            {"Contract Type: "}<font className="fontBold">{contractTypeName}</font>
          </div>
          <div className="medTopMargin fontItalic fontTextLighter">
            {"IDIQ/Task Order information does not apply to Single Award Contracts"}
          </div>
        </div>
      );
    }

    //open capture is IDIQ or Task Order contract type
    const flex01Array = [1,0,1,1,1];
    const flexBasisEmArray = [125,13,50,100,100];
    const columnKeysArray = ["cn", "cov", "s", "co", "do"];
    const columnDbNamesArray = ["captureFullName", "contractValue", "stageName", "captureTeam", "divisionOwners"]
    var columnDisplayNamesArray = ["Capture Name", "Contract Overall Value", "Stage", "Capture Owners", "Division Owners"];
    for(let c = 0; c < columnKeysArray.length; c++) {
      if(columnDbNamesArray[c] === s_idiqTOsSortColumnDbName) {
        columnDisplayNamesArray[c] += " " + ((s_idiqTOsSortIsAscTF) ? (" \u25BC") : (" \u25B2"));
      }
    }
    const alignArray = ["left", "right", "left", "left", "left"];

    JSFUNC.sort_arrayOfObjs(taskOrdersArrayOfObjs, s_idiqTOsSortColumnDbName, s_idiqTOsSortIsAscTF);

    return(
      <div className="flex11a yScroll yScrollBottomPad smallFullMargin">
        <div className="smallBottomMargin textCenter fontItalic fontTextLighter">
          {"Click a capture below to switch to that capture"}
        </div>
        <CEGeneralReact.ColumnHeaderRow
          p_flex01Array={flex01Array}
          p_flexBasisEmArray={flexBasisEmArray}
          p_columnKeysArray={columnKeysArray}
          p_columnDbNamesArray={columnDbNamesArray}
          p_columnDisplayNamesArray={columnDisplayNamesArray}
          f_onClickHeader={this.onclick_column_header}
        />
        <IdiqTaskOrdersLabelDividerRow p_idiqTaskOrderFlag="idiq" p_isSnapshotTF={false} p_idiqTaskOrdersObj={c_idiqTaskOrdersObj} />
        <CEGeneralReact.DataRow
          p_itemID={idiqObj.id}
          p_flex01Array={flex01Array}
          p_flexBasisEmArray={flexBasisEmArray}
          p_columnKeysArray={columnKeysArray}
          p_dataArray={[idiqObj.captureFullName, idiqObj.contractValue, idiqObj.stageName, idiqObj.captureTeam, idiqObj.divisionOwners]}
          p_alignArray={alignArray}
          p_class={((idiqObj.isCurrentlyOpenCaptureTF) ? ("fontBold") : ("hoverLighterBlueGradient cursorPointer"))}
          p_title={((idiqObj.isCurrentlyOpenCaptureTF) ? (undefined) : ("Open capture '" + idiqObj.captureFullName + "'"))}
          f_onClick={((idiqObj.isCurrentlyOpenCaptureTF) ? (undefined) : (this.onclick_idiq_task_order_capture))}
        />
        <div className="hugeBottomMargin" />
        <IdiqTaskOrdersLabelDividerRow p_idiqTaskOrderFlag="taskOrders" p_isSnapshotTF={false} p_idiqTaskOrdersObj={c_idiqTaskOrdersObj} />
        {(taskOrdersArrayOfObjs.length === 0) &&
          <div className="fontItalic fontTextLighter">
            {"No Task Orders linked to this IDIQ"}
          </div>
        }
        {taskOrdersArrayOfObjs.map((m_taskOrderObj) =>
          <CEGeneralReact.DataRow
            key={m_taskOrderObj.id}
            p_itemID={m_taskOrderObj.id}
            p_flex01Array={flex01Array}
            p_flexBasisEmArray={flexBasisEmArray}
            p_columnKeysArray={columnKeysArray}
            p_dataArray={[m_taskOrderObj.captureFullName, m_taskOrderObj.contractValue, m_taskOrderObj.stageName, m_taskOrderObj.captureTeam, m_taskOrderObj.divisionOwners]}
            p_alignArray={alignArray}
            p_class={((m_taskOrderObj.isCurrentlyOpenCaptureTF) ? ("bgLighterGray fontBold") : ("hoverLighterBlueGradient cursorPointer"))}
            p_title={((m_taskOrderObj.isCurrentlyOpenCaptureTF) ? (undefined) : ("Open capture '" + m_taskOrderObj.captureFullName + "'"))}
            f_onClick={((m_taskOrderObj.isCurrentlyOpenCaptureTF) ? (undefined) : (this.onclick_idiq_task_order_capture))}
          />
        )}
      </div>
    );
  }
}))

function IdiqTaskOrdersLabelDividerRow(props) { //props: p_idiqTaskOrderFlag, p_isSnapshotTF, p_idiqTaskOrdersObj
  const p_idiqTaskOrderFlag = props.p_idiqTaskOrderFlag;
  const p_isSnapshotTF = props.p_isSnapshotTF;
  const p_idiqTaskOrdersObj = props.p_idiqTaskOrdersObj;

  const numTaskOrders = p_idiqTaskOrdersObj.taskOrdersArrayOfObjs.length;

  var label = "--IDIQ/Task Order--"; //invalid flag
  if(p_idiqTaskOrderFlag === "idiq") {
    label = "IDIQ";
  }
  else if(p_idiqTaskOrderFlag === "taskOrders") {
    var taskOrdersTotalMasked = "--";
    if(p_isSnapshotTF) {
      taskOrdersTotalMasked = " (" + JSFUNC.money_short(p_idiqTaskOrdersObj.taskOrdersContractValueRawTotal) + ")";
    }
    else {
      taskOrdersTotalMasked = " totaling " + JSFUNC.money(p_idiqTaskOrdersObj.taskOrdersContractValueRawTotal, 0, true);
    }
    label = (numTaskOrders + " Task Order" + ((numTaskOrders === 1) ? ("") : ("s")) + taskOrdersTotalMasked);
  }

  return(
    <div className="displayFlexRowVc smallTopMargin smallBottomMargin fontItalic fontTextLighter">
      <div className="flex00a" style={{flexBasis:"2em", borderBottom:"solid 1px #ddd"}} />
      <div className="flex00a">
        {label}
      </div>
      <div className="flex11a" style={{borderBottom:"solid 1px #ddd"}} />
    </div>
  );
}





//===================================================================================================================================
//--------Changelog-------------------------------------------------------------------------------------------
//===================================================================================================================================
const ChangeLogSnapshot = inject("OpenCaptureMobx")(observer(
class ChangeLogSnapshot extends Component {
  render() {
    return(
      <div className="flex11a overflowHidden smallFullMargin displayFlexColumnHcVc fontItalic fontTextLighter">
        {"Open to view Changelog"}
      </div>
    );
  }
}));

const ChangeLog = inject("OpenCaptureMobx")(observer(
class ChangeLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_clickedChangelogRowKey: -1
    }
  }

  onclick_column_header = (i_columnDbName) => {
    const o_changelogSortColumnDbName = this.props.OpenCaptureMobx.o_changelogSortColumnDbName;
    const o_changelogSortIsAscTF = this.props.OpenCaptureMobx.o_changelogSortIsAscTF;

    if(i_columnDbName === o_changelogSortColumnDbName) { //clicked column already sorted, flip the direction of the sort
      const updatedSortIsAscTF = (!o_changelogSortIsAscTF);
      this.props.OpenCaptureMobx.a_set_changelog_sort_is_asc_tf(updatedSortIsAscTF);
    }
    else {
      this.props.OpenCaptureMobx.a_set_changelog_sort_column_db_name(i_columnDbName);
      this.props.OpenCaptureMobx.a_set_changelog_sort_is_asc_tf(true);
    }
  }

  onclick_changelog_row = (i_changelogIDClicked) => {
    const s_clickedChangelogRowKey = this.state.s_clickedChangelogRowKey;

    var updatedClickedChangelogRowID = -1;
    if(i_changelogIDClicked !== s_clickedChangelogRowKey) {
      updatedClickedChangelogRowID = i_changelogIDClicked;
    }
    this.setState({s_clickedChangelogRowKey:updatedClickedChangelogRowID});
  }

  render() {
    const s_clickedChangelogRowKey = this.state.s_clickedChangelogRowKey;

    const o_changelogSortColumnDbName = this.props.OpenCaptureMobx.o_changelogSortColumnDbName;
    const o_changelogSortIsAscTF = this.props.OpenCaptureMobx.o_changelogSortIsAscTF;
    const c_changelogFilteredSortedItemsOnSelectedPageArrayOfObjs = this.props.OpenCaptureMobx.c_changelogFilteredSortedItemsOnSelectedPageArrayOfObjs;

    const flex01Array = [0,1,1,1,1];
    const flexBasisEmArray = [11.5,100,200,300,100];
    const columnKeysArray = ["dly", "dcn", "f", "v", "u"];
    const columnDbNamesArray = ["dateTimeUtc", "detailsCardName", "field", "value", "user"];
    var columnDisplayNamesArray = ["Date", "Card", "Field", "New Value", "Changed By"];
    for(let c = 0; c < columnDbNamesArray.length; c++) {
      if(columnDbNamesArray[c] === o_changelogSortColumnDbName) {
        columnDisplayNamesArray[c] += " " + ((o_changelogSortIsAscTF) ? (" \u25BC") : (" \u25B2"));
      }
    }

    return(
      <>
        <ChangelogFilterControls />
        <CEGeneralReact.ColumnHeaderRow
          p_flex01Array={flex01Array}
          p_flexBasisEmArray={flexBasisEmArray}
          p_columnKeysArray={columnKeysArray}
          p_columnDbNamesArray={columnDbNamesArray}
          p_columnDisplayNamesArray={columnDisplayNamesArray}
          f_onClickHeader={this.onclick_column_header}
        />
        <div className="flex11a yScroll yScrollBottomPad">
          {c_changelogFilteredSortedItemsOnSelectedPageArrayOfObjs.map((m_changelogObj) =>
            <CEGeneralReact.DataRow
              key={m_changelogObj.key}
              p_itemID={m_changelogObj.key}
              p_flex01Array={flex01Array}
              p_flexBasisEmArray={flexBasisEmArray}
              p_columnKeysArray={columnKeysArray}
              p_dataArray={[m_changelogObj.dateTimeLocalMask, m_changelogObj.detailsCardName, m_changelogObj.field, m_changelogObj.value, m_changelogObj.user]}
              p_class={((m_changelogObj.key === s_clickedChangelogRowKey) ? ("bgLighterBlue") : (undefined))}
              p_heightLimit={((m_changelogObj.key === s_clickedChangelogRowKey) ? (undefined) : ("2em"))}
              p_title="[Click to view all content in this data row]"
              f_onClick={this.onclick_changelog_row}
            />
          )}
        </div>
      </>
    );
  }
}));

const ChangelogFilterControls = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class ChangelogFilterControls extends Component { //props:
  onclick_increment_current_page_number = () => {
    this.props.OpenCaptureMobx.a_changelog_increment_current_page_number();
  }

  onclick_decrement_current_page_number = () => {
    this.props.OpenCaptureMobx.a_changelog_decrement_current_page_number();
  }

  onchange_changelog_filter_capture_card_id = (i_selectedCaptureCardID) => {
    this.props.OpenCaptureMobx.a_set_changelog_filter_capture_card_ids_comma(i_selectedCaptureCardID);
    this.props.OpenCaptureMobx.a_set_changelog_current_page_number(1);
  }

  onchange_changelog_filter_capture_field_id = (i_selectedCaptureFieldID) => {
    this.props.OpenCaptureMobx.a_set_changelog_filter_capture_field_id(i_selectedCaptureFieldID);
    this.props.OpenCaptureMobx.a_set_changelog_current_page_number(1);
  }

  onchange_changelog_filter_user_id = (i_selectedUserID) => {
    this.props.OpenCaptureMobx.a_set_changelog_filter_user_id(i_selectedUserID);
    this.props.OpenCaptureMobx.a_set_changelog_current_page_number(1);
  }

  onchange_changelog_filter_date_min = (i_selectedDate) => {
    this.props.OpenCaptureMobx.a_set_changelog_filter_date_min(i_selectedDate);
    this.props.OpenCaptureMobx.a_set_changelog_current_page_number(1);
  }

  onchange_changelog_filter_date_max = (i_selectedDate) => {
    this.props.OpenCaptureMobx.a_set_changelog_filter_date_max(i_selectedDate);
    this.props.OpenCaptureMobx.a_set_changelog_current_page_number(1);
  }

  render() {
    const o_changelogFilterCaptureCardIDsComma = this.props.OpenCaptureMobx.o_changelogFilterCaptureCardIDsComma;
    const o_changelogFilterCaptureFieldID = this.props.OpenCaptureMobx.o_changelogFilterCaptureFieldID;
    const o_changelogFilterUserID = this.props.OpenCaptureMobx.o_changelogFilterUserID;
    const o_changelogFilterDateMin = this.props.OpenCaptureMobx.o_changelogFilterDateMin;
    const o_changelogFilterDateMax = this.props.OpenCaptureMobx.o_changelogFilterDateMax;
    const c_changelogFilteredTotalNumItems = this.props.OpenCaptureMobx.c_changelogFilteredTotalNumItems;
    const c_changelogCurrentPageFirstItemNumber = this.props.OpenCaptureMobx.c_changelogCurrentPageFirstItemNumber;
    const c_changelogCurrentPageLastItemNumber = this.props.OpenCaptureMobx.c_changelogCurrentPageLastItemNumber;
    const c_changelogCanIncrementCurrentPageNumberTF = this.props.OpenCaptureMobx.c_changelogCanIncrementCurrentPageNumberTF;
    const c_changelogCanDecrementCurrentPageNumberTF = this.props.OpenCaptureMobx.c_changelogCanDecrementCurrentPageNumberTF;
    const c_changelogSelectUserIDsToNotIncludeArray = this.props.OpenCaptureMobx.c_changelogSelectUserIDsToNotIncludeArray;

    const pageButtonWidthEm = 2.2;
    const pageButtonHeightEm = 1.4;
    const pageButtonCanClickBgClass = "bgPurpleGradient hoverLightPurpleGradient";
    const pageButtonCanClickFontClass = "fontWhite";
    const pageButtonCantClickBgClass = "bgLightestBlue";
    const pageButtonCantClickFontClass = "fontTextLighter";

    return(
      <div className="flex00a displayFlexRow flexWrap borderB1bbb bgLightesterGray tbPad">
        <ChangelogControlContainer p_label="Pages of Changelog Entries" p_width="17em">
          <div className="displayFlexRowVc bgAlmostWhite border bevelBorderColors tbMicroPad lrMedPad">
            <div className="flex00a">
              <CEGeneralReact.PageIncrementOrDecrementButton
                p_incTrueDecFalse={false}
                p_canClickTF={c_changelogCanDecrementCurrentPageNumberTF}
                p_widthEm={pageButtonWidthEm}
                p_heightEm={pageButtonHeightEm}
                p_canClickBgClass={pageButtonCanClickBgClass}
                p_canClickFontClass={pageButtonCanClickFontClass}
                p_cantClickBgClass={pageButtonCantClickBgClass}
                p_cantClickFontClass={pageButtonCantClickFontClass}
                f_onClick={this.onclick_decrement_current_page_number}
              />
            </div>
            <div className="flex11a lrPad textCenter">
              <font className="">
                {c_changelogCurrentPageFirstItemNumber + " - " + c_changelogCurrentPageLastItemNumber + " of " + c_changelogFilteredTotalNumItems}
              </font>
            </div>
            <div className="flex00a">
              <CEGeneralReact.PageIncrementOrDecrementButton
                p_incTrueDecFalse={true}
                p_canClickTF={c_changelogCanIncrementCurrentPageNumberTF}
                p_widthEm={pageButtonWidthEm}
                p_heightEm={pageButtonHeightEm}
                p_canClickBgClass={pageButtonCanClickBgClass}
                p_canClickFontClass={pageButtonCanClickFontClass}
                p_cantClickBgClass={pageButtonCantClickBgClass}
                p_cantClickFontClass={pageButtonCantClickFontClass}
                f_onClick={this.onclick_increment_current_page_number}
              />
            </div>
          </div>
        </ChangelogControlContainer>
        <ChangelogControlContainer p_label="Filter by Card" p_width="24em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_changelogFilterCaptureCardIDsComma, this.props.DatabaseMobx.c_selectMultiChangelogCaptureCardsFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiChangelogCaptureCardsFieldTypeObj}
            p_valueRaw={o_changelogFilterCaptureCardIDsComma}
            p_tabIndex={2}
            f_onChangeOrOnSelect={this.onchange_changelog_filter_capture_card_id}
          />
        </ChangelogControlContainer>
        <ChangelogControlContainer p_label="Filter by Capture Field" p_width="24em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_changelogFilterCaptureFieldID, this.props.DatabaseMobx.c_selectCaptureFieldFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureFieldFieldTypeObj}
            p_valueRaw={o_changelogFilterCaptureFieldID}
            p_tabIndex={3}
            f_onChangeOrOnSelect={this.onchange_changelog_filter_capture_field_id}
          />
        </ChangelogControlContainer>
        <ChangelogControlContainer p_label="Filter by User" p_width="22em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_changelogFilterUserID, this.props.DatabaseMobx.c_selectUserFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_selectUserFieldTypeObj}
            p_valueRaw={o_changelogFilterUserID}
            p_valuesToNotIncludeArray={c_changelogSelectUserIDsToNotIncludeArray}
            p_tabIndex={4}
            f_onChangeOrOnSelect={this.onchange_changelog_filter_user_id}
          />
        </ChangelogControlContainer>
        <ChangelogControlContainer p_label="Date Min" p_width={undefined} p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_changelogFilterDateMin, this.props.DatabaseMobx.c_genericDateFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
            p_valueRaw={o_changelogFilterDateMin}
            p_tabIndex={5}
            f_onChangeOrOnSelect={this.onchange_changelog_filter_date_min}
          />
        </ChangelogControlContainer>
        <ChangelogControlContainer p_label="Date Max" p_width={undefined} p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_changelogFilterDateMax, this.props.DatabaseMobx.c_genericDateFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
            p_valueRaw={o_changelogFilterDateMax}
            p_tabIndex={6}
            f_onChangeOrOnSelect={this.onchange_changelog_filter_date_max}
          />
        </ChangelogControlContainer>
      </div>
    );
  }
}));

function ChangelogControlContainer(props) { //props: p_label, p_width, p_isFilledOutTF, children
  const p_label = props.p_label;
  const p_width = props.p_width;
  const p_isFilledOutTF = props.p_isFilledOutTF;

  return(
    <div
      className={"flex00a lrMargin " + ((p_isFilledOutTF) ? ("bgLightPurpleGradient") : ("")) + " tbMicroPad lrMedPad"}
      style={{width:p_width}}>
      <div className="microBottomMargin textCenter">
        <font className="font09 fontItalic fontTextLight">
          {p_label}
        </font>
      </div>
      <div>
        {props.children}
      </div>
    </div>
  );
}






//===================================================================================================================================
//--------Notepad-------------------------------------------------------------------------------------------
//===================================================================================================================================
const NotepadSnapshot = inject("OpenCaptureMobx")(observer(
class NotepadSnapshot extends Component {
  render() {
    const c_notepadCaptureFieldValueObj = this.props.OpenCaptureMobx.c_notepadCaptureFieldValueObj;
    const c_notepadExpandedSortedNoteStampsArrayOfObjs = this.props.OpenCaptureMobx.c_notepadExpandedSortedNoteStampsArrayOfObjs;

    const noteStampsHasContentTF = (c_notepadExpandedSortedNoteStampsArrayOfObjs.length > 0);
    const notepadHasContentTF = c_notepadCaptureFieldValueObj.isFilledOutTF;

    var noteStampsSnapshotComponent = null;
    if(noteStampsHasContentTF) {
      noteStampsSnapshotComponent = (
        <div className="flex11a overflowHidden tbMicroMargin lrMedMargin" style={{flexBasis:"100em"}}>
          {c_notepadExpandedSortedNoteStampsArrayOfObjs.map((m_noteStampObj) =>
            <div className="displayFlexRow">
              <div className="flex00a displayFlexRowVc" style={{flexBasis:"0.9em"}}>
                <div className={"border1bbb borderRadius10 " + ((m_noteStampObj.pinnedTF) ? ("bgBlueGradient") : ("bgLightGray"))} style={{width:"0.6em", height:"0.6em"}} />
              </div>
              <div className="flex11a">
                <LibraryReact.Nowrap>
                  <font className="fontBold fontTextLight">{m_noteStampObj.title}</font>{"  "}<font className="fontTextLight">{JSFUNC.trim_string_max_chars(m_noteStampObj.body, 100)}</font>
                </LibraryReact.Nowrap>
              </div>
            </div>
          )}
        </div>
      );
    }

    var notepadSnapshotComponent = null;
    if(notepadHasContentTF) {
      notepadSnapshotComponent = (
        <div className="flex11a overflowHidden tbMicroMargin lrMedMargin" style={{flexBasis:"100em"}}>
          <font className="fontTextLight">
            {c_notepadCaptureFieldValueObj.valueMaskPlainText}
          </font>
        </div>
      );
    }

    return(
      <>
        {noteStampsSnapshotComponent}
        {(noteStampsHasContentTF && notepadHasContentTF) &&
          <div className="borderT1ddd" />
        }
        {notepadSnapshotComponent}
      </>
    );
  }
}));

const Notepad = inject("CaptureExecMobx", "UserMobx")(observer(
class Notepad extends Component {
  onselect_notepad_mobile_tab = (i_selectedTabDbName) => {
    this.props.UserMobx.a_update_user_per_email_field("notepad_selected_tab_db_name", i_selectedTabDbName, "s");
  }

  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_userNotepadSelectedTabDbName = this.props.UserMobx.c_userNotepadSelectedTabDbName;

    if(c_isMobileOrTabletTF) {
      var notepadMobileContentComponent = null;
      if(c_userNotepadSelectedTabDbName === "noteStamps") {
        notepadMobileContentComponent = (<NotepadNoteStamps />);
      }
      else if(c_userNotepadSelectedTabDbName === "notepad") {
        notepadMobileContentComponent = (<NotepadNotepad />);
      }

      return(
        <>
          <div className="flex00a displayFlexColumnHcVc borderB1bbb tbPad">
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={["noteStamps", "notepad"]}
              p_tabDisplayNamesArray={["Note Stamps", "Notepad"]}
              p_selectedTabDbName={c_userNotepadSelectedTabDbName}
              p_tabHeight="1.8em"
              p_textMaxHeight="1.7em"
              p_tabWidth="9em"
              p_selectedBgClass="bgDarkGrayGradient"
              p_selectedFontClass="fontWhite"
              p_unselectedBgClass="bgLighterGrayGradient"
              p_unselectedFontClass="fontTextLight"
              p_canEditTF={true}
              p_tabSpacing="0.4em"
              f_onSelect={this.onselect_notepad_mobile_tab}
            />
          </div>
          {notepadMobileContentComponent}
        </>
      );
    }

    return(
      <div className="flex11a displayFlexRow">
        <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
          <NotepadNoteStamps />
        </div>
        <div className="bgGray" style={{flexBasis:"1em", borderLeft:"solid 1px #888", borderRight:"solid 1px #888"}} />
        <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
          <NotepadNotepad />
        </div>
      </div>
    );
  }
}));


const NotepadNoteStamps = inject("CaptureExecMobx", "OpenCaptureMobx", "UserMobx")(observer(
class NotepadNoteStamps extends Component {
  onchange_note_stamps_search_text = (i_newValueString) => {
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_search_text(i_newValueString);
  }

  onclick_open_create_new_note_stamp_floating_box = () => {
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_new_edit_read_floating_box_flag("new");
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_editing_or_reading_note_stamp_obj_or_undefined(undefined);
  }

  render() {
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_notepadNoteStampsSearchText = this.props.OpenCaptureMobx.o_notepadNoteStampsSearchText;
    const o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined = this.props.OpenCaptureMobx.o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined;
    const o_notepadNoteStampsEditingOrReadingNoteStampObjOrUndefined = this.props.OpenCaptureMobx.o_notepadNoteStampsEditingOrReadingNoteStampObjOrUndefined;
    const c_notepadNumFilteredNoteStamps = this.props.OpenCaptureMobx.c_notepadNumFilteredNoteStamps;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const largeScreenNoteStampsTopLrWidthEm = 12;

    const noteStampsSearchTextIsFilledOutTF = JSFUNC.string_is_filled_out_tf(o_notepadNoteStampsSearchText);
    var noteStampsSearchResultsMessage = "";
    if(noteStampsSearchTextIsFilledOutTF) {
      noteStampsSearchResultsMessage = c_notepadNumFilteredNoteStamps + " " + JSFUNC.plural(c_notepadNumFilteredNoteStamps, "Note Stamp", "Note Stamps") + " match search term '" + o_notepadNoteStampsSearchText + "'";
    }

    const noteStampsTitleComponent = (
      <NotepadSideTitles p_text="Note Stamps" />
    );

    const noteStampsSearchComponent = (
      <CEGeneralReact.SearchInputTextWithClearX
        p_inputText={o_notepadNoteStampsSearchText}
        p_placeholderText="Search Note Stamps..."
        p_includeSearchIconTF={true}
        p_heightEm={2.1}
        p_tabIndex={undefined}
        f_onChange={this.onchange_note_stamps_search_text}
      />
    );

    const noteStampsViewButtonsComponent = (
      <div className="flex00a displayFlexRowVc">
        <div className="flex00a nowrap">
          <font className="fontItalic fontTextLight">
            {"View:"}
          </font>
        </div>
        <div className="flex00a" style={{marginLeft:"0.4em"}}>
          <NoteStampsViewButtonShell p_buttonViewFlag="full" />
        </div>
        <div className="flex00a" style={{marginLeft:"0.2em"}}>
          <NoteStampsViewButtonShell p_buttonViewFlag="cards2col" />
        </div>
        {(!c_isMobileTF) &&
          <div className="flex00a" style={{marginLeft:"0.2em"}}>
            <NoteStampsViewButtonShell p_buttonViewFlag="cards3col" />
          </div>
        }
      </div>
    );

    return(
      <div className="flex11a displayFlexColumn bgLighterGray">
        <div className="flex00a displayFlexRow smallTopMargin">
          <div className="flex00a displayFlexRowVc lrMedPad" style={{flexBasis:largeScreenNoteStampsTopLrWidthEm + "em"}}>
            {noteStampsTitleComponent}
          </div>
          <div className="flex11a displayFlexColumnHcVc">
            {(c_userCanEditCaptureCardContentTF) &&
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text="Create a new Note Stamp"
                f_onClick={this.onclick_open_create_new_note_stamp_floating_box}
              />
            }
          </div>
          <div className="flex00a displayFlexRow lrMedPad" style={{flexBasis:largeScreenNoteStampsTopLrWidthEm + "em"}}>
            <div className="flex11a" />
            {noteStampsViewButtonsComponent}
          </div>
        </div>
        <div className="flex00a displayFlexRow smallTopMargin">
          <div className="flex00a displayFlexRowVc" style={{width:"25%", minWidth:"17em"}}>
            <div className="flex00a lrPad" style={{width:"100%"}}>
                {noteStampsSearchComponent}
              </div>
          </div>
          <div className="flex11a displayFlexColumnVc">
            {(noteStampsSearchTextIsFilledOutTF) &&
              <div className="flex00a lrPad">
                <LibraryReact.MaxHeightWrap p_maxHeight="2.2em" p_fontClass="fontItalic">
                  {noteStampsSearchResultsMessage}
                </LibraryReact.MaxHeightWrap>
              </div>
            }
          </div>
        </div>
        <div className="flex11a displayFlexColumn smallTopMargin borderT1bbb bgLightGray smallTopPad medBottomPad lrMedPad yScroll">
          <AllNoteStampItems />
        </div>
        {(o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined !== undefined) &&
          <NoteStampNewEditReadFloatingBox
            p_newEditReadFlag={o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined}
            p_noteStampObjOrUndefined={o_notepadNoteStampsEditingOrReadingNoteStampObjOrUndefined}
          />
        }
      </div>
    );
  }
}));

const NoteStampsViewButtonShell = inject("UserMobx")(observer(
class NoteStampsViewButtonShell extends Component { //props: p_buttonViewFlag
  onclick_note_stamps_view_flag = () => {
    const p_buttonViewFlag = this.props.p_buttonViewFlag;
    this.props.UserMobx.a_update_user_per_email_field("notepad_note_stamps_view_flag", p_buttonViewFlag, "s");
  }

  render() {
    const p_buttonViewFlag = this.props.p_buttonViewFlag;

    const c_userNotepadNoteStampsViewFlag = this.props.UserMobx.c_userNotepadNoteStampsViewFlag;

    const selectedTF = (p_buttonViewFlag === c_userNotepadNoteStampsViewFlag);

    const pictureBoxesBorder = "solid 1px #" + ((selectedTF) ? ("dddddd") : ("999999"));
    var pictureBoxesComponent = null;
    if(JSFUNC.in_array(p_buttonViewFlag, ["cards2col", "cards3col"])) {
      var rowIndicesArray = [0, 1];
      var colIndicesArray = [0, 1];
      if(p_buttonViewFlag === "cards3col") {
        rowIndicesArray = [0, 1, 2];
        colIndicesArray = [0, 1, 2];
      }

      pictureBoxesComponent = (
        <>
          {rowIndicesArray.map((m_row) =>
            <div key={"r" + m_row} className="flex11a displayFlexRow" style={{flexBasis:"100em"}}>
              {colIndicesArray.map((m_col) =>
                <div key={"c" + m_col} className="flex11a" style={{flexBasis:"100em", margin:"0.075em", border:pictureBoxesBorder}} />
              )}
            </div>
          )}
        </>
      );
    }
    else {
      pictureBoxesComponent = (
        <div className="flex11a" style={{margin:"0.05em", border:pictureBoxesBorder}} />
      );
    }

    return(
      <div
        className={"displayFlexColumn border bevelBorderColors " + ((selectedTF) ? ("bgBlueGradient") : ("bgLightGrayGradient hoverLighterBlueGradient cursorPointer"))}
        style={{width:"2.3em", height:"2.1em", padding:"0.2em"}}
        onClick={this.onclick_note_stamps_view_flag}>
        {pictureBoxesComponent}
      </div>
    );
  }
}));


const AllNoteStampItems = inject("OpenCaptureMobx", "UserMobx")(observer(
class AllNoteStampItems extends Component {
  render() {
    const c_notepadExpandedSortedFilteredNoteStampsArrayOfObjs = this.props.OpenCaptureMobx.c_notepadExpandedSortedFilteredNoteStampsArrayOfObjs;
    const c_userNotepadNoteStampsViewFlag = this.props.UserMobx.c_userNotepadNoteStampsViewFlag;

    const numNoteStamps = c_notepadExpandedSortedFilteredNoteStampsArrayOfObjs.length;
    const anyNoteStampIsOnlyItemTF = (numNoteStamps === 1);

    if(JSFUNC.in_array(c_userNotepadNoteStampsViewFlag, ["cards2col", "cards3col"])) {
      const noteStampIDsToDrawArray = JSFUNC.get_column_vector_from_arrayOfObjs(c_notepadExpandedSortedFilteredNoteStampsArrayOfObjs, "id");

      var numNoteStampsPerRow = 2;
      var noteStampHeightEm = 22;
      if(c_userNotepadNoteStampsViewFlag === "cards3col") {
        numNoteStampsPerRow = 3;
        noteStampHeightEm = 17;
      }

      const noteStampsRCMatrixOfObjs = JSFUNC.get_rc_matrix_of_objs_or_undefined_from_id_array_and_num_columns_and_arrayOfObjs_and_id_field_name(noteStampIDsToDrawArray, numNoteStampsPerRow, c_notepadExpandedSortedFilteredNoteStampsArrayOfObjs, "id");
  
      return(
        <div className="flex11a displayFlexColumn yScroll yScrollBottomPad">
          {noteStampsRCMatrixOfObjs.map((m_rowNoteStampsArrayOfObjs, m_rowIndex) =>
            <div
              key={JSFUNC.key_rand()}
              className="flex10a displayFlexRow"
              style={{flexBasis:noteStampHeightEm + "em", maxHeight:noteStampHeightEm + "em"}}>
              {m_rowNoteStampsArrayOfObjs.map((m_noteStampObjOrUndefined) =>
                <NoteStampItem
                  key={((m_noteStampObjOrUndefined !== undefined) ? (m_noteStampObjOrUndefined.id) : (-1))}
                  p_isCardTF={true}
                  p_isInReadingPaneTF={false}
                  p_noteStampObjOrUndefined={m_noteStampObjOrUndefined}
                  p_isOnlyItemTF={anyNoteStampIsOnlyItemTF}
                  p_isLastItemTF={(false)}
                />
              )}
            </div>
          )}
        </div>
      );
    }

    return(
      c_notepadExpandedSortedFilteredNoteStampsArrayOfObjs.map((m_noteStampObj, m_index) =>
        <NoteStampItem
          key={m_noteStampObj.id}
          p_isCardTF={false}
          p_isInReadingPaneTF={false}
          p_noteStampObjOrUndefined={m_noteStampObj}
          p_isOnlyItemTF={anyNoteStampIsOnlyItemTF}
          p_isLastItemTF={(m_index === (numNoteStamps - 1))}
        />
      )
    );
  }
}));


const NoteStampItem = inject("OpenCaptureMobx", "UserMobx")(observer(
class NoteStampItem extends Component { //props: p_isCardTF, p_isInReadingPaneTF, p_noteStampObjOrUndefined
  onclick_toggle_pin_note_stamp = () => {
    const p_noteStampObjOrUndefined = this.props.p_noteStampObjOrUndefined;
    if(p_noteStampObjOrUndefined !== undefined) {
      const updatedPinned01 = ((p_noteStampObjOrUndefined.pinnedTF) ? (0) : (1));
      this.props.OpenCaptureMobx.a_notepad_pin_or_unpin_note_stamp(p_noteStampObjOrUndefined.id, updatedPinned01);
    }
  }

  onclick_edit_note_stamp_item = () => {
    const p_noteStampObjOrUndefined = this.props.p_noteStampObjOrUndefined;
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_new_edit_read_floating_box_flag("edit");
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_editing_or_reading_note_stamp_obj_or_undefined(p_noteStampObjOrUndefined);
  }

  onclick_close_edit_note_stamp_floating_box = () => {
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_new_edit_read_floating_box_flag(undefined);
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_editing_or_reading_note_stamp_obj_or_undefined(undefined);
  }

  onconfirm_delete_note_stamp = () => {
    const p_noteStampObjOrUndefined = this.props.p_noteStampObjOrUndefined;
    this.props.OpenCaptureMobx.a_notepad_delete_note_stamp(p_noteStampObjOrUndefined);
  }

  onclick_read_note_stamp_item = () => {
    const p_noteStampObjOrUndefined = this.props.p_noteStampObjOrUndefined;
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_new_edit_read_floating_box_flag("read");
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_editing_or_reading_note_stamp_obj_or_undefined(p_noteStampObjOrUndefined);
  }

  render() {
    const p_isCardTF = this.props.p_isCardTF;
    const p_isInReadingPaneTF = this.props.p_isInReadingPaneTF;
    const p_noteStampObjOrUndefined = this.props.p_noteStampObjOrUndefined;

    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    if(p_noteStampObjOrUndefined === undefined) {
      if(p_isCardTF) {
        return(
          <div className="flex11a" style={{flexBasis:"100em"}} />
        );
      }
      return(null);
    }

    var containerClass = "medBottomMargin";
    var containerFlexBasis = undefined;
    if(p_isCardTF) {
      containerClass = "flex11a smallFullMargin overflowHidden";
      containerFlexBasis = "100em";
    }

    var pinSizeEm = 1.1;
    var innerSizeEm = 0.7;
    var pinBgClass = "bgLightGray " + ((c_userCanEditCaptureCardContentTF) ? ("hoverLightBlueGradient") : (""));
    var pinHoverText = "Pin this Note Stamp to the top";
    if(p_noteStampObjOrUndefined.pinnedTF) {
      pinSizeEm = 1.4;
      innerSizeEm = 1;
      pinBgClass = "bgBlueGradient";
      pinHoverText = "Unpin this Note Stamp";
    }

    return(
      <div
        className={containerClass + " displayFlexColumn border1bbb borderRadius10 bgLightestestGray " + ((!p_isInReadingPaneTF) ? ("hoverBgWhite hoverBorderColorBlue") : (""))}
        style={{flexBasis:containerFlexBasis}}>
        <CEGeneralReact.CEDragToResortItemWithinDbTbl
          p_canResortTF={(c_userCanEditCaptureCardContentTF && !p_isInReadingPaneTF)}
          p_uniqueString="notepadNoteStamps"
          p_itemID={p_noteStampObjOrUndefined.id}
          p_itemSort={((!p_isInReadingPaneTF) ? (p_noteStampObjOrUndefined.pinnedSortNumberOrUndefined) : (undefined))}
          p_itemSortColumnWidth="2em"
          p_itemSortNumberContainerClass="bgDarkBlue"
          p_itemSortNumberFontClass="fontWhite"
          p_isOnlyItemTF={undefined}
          p_isLastItemTF={undefined}
          p_outerPadClass={undefined}
          p_itemClass="flex00a displayFlexRow"
          p_itemStyleObj={undefined}
          p_lastItemExtraDropZoneHeight={undefined}
          p_tblName="tbl_c_notepad_note_stamps"
          p_tblSortFieldDbName="sort"
          p_filterFieldNamesArray={["capture_id", "pinned_01"]}
          p_filterValuesArray={[o_openCaptureID, 1]}
          f_onDropForeignItem={undefined}>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:((!p_isInReadingPaneTF) ? ("2em") : ("0.8em"))}}>
            {(!p_isInReadingPaneTF) &&
              <div
                className={"flex00a displayFlexColumnHcVc border1bbb borderRadius10 " + pinBgClass + " " + ((c_userCanEditCaptureCardContentTF) ? ("cursorPointer") : (""))}
                style={{width:pinSizeEm + "em", height:pinSizeEm + "em"}}
                title={pinHoverText}
                onClick={((c_userCanEditCaptureCardContentTF) ? (this.onclick_toggle_pin_note_stamp) : (undefined))}>
                <div className="flex00a displayFlexColumnHcVc border1bbb borderRadius10" style={{width:innerSizeEm + "em", height:innerSizeEm + "em"}} />
              </div>
            }
          </div>
          <div className="flex11a displayFlexRow flexWrap microTopPad">
            <div className="flex00a displayFlexColumnVc" title={p_noteStampObjOrUndefined.createdDateTimeByUserNameInfoPlainText}>
              {(p_isInReadingPaneTF) ? (
                <>
                  <div className="flex00a">
                    <font className="fontTextLighter">
                      {"Created by " + p_noteStampObjOrUndefined.createdByUserNameMaskPlainText}
                    </font>
                  </div>
                  <div className="flex00a nowrap">
                    <font className="font13 fontBold fontBlue">
                      {p_noteStampObjOrUndefined.createdDateLocalMask}
                    </font>
                  </div>
                </>
              ) : (
                <div className="flex00a nowrap">
                  <font className="font12 fontBold fontBlue">
                    {p_noteStampObjOrUndefined.createdDateLocalMask}
                  </font>
                </div>
              )}
            </div>
            <div className="flex11a" />
            <div className="flex00a displayFlexColumnVc" title={p_noteStampObjOrUndefined.lastEditedDateTimeByUserNameInfoPlainText}>
              {(p_isInReadingPaneTF) ? (
                <>
                  <div className="flex00a textRight">
                    <font className="fontItalic fontTextLighter">
                      {"Last Edited by " + p_noteStampObjOrUndefined.lastEditedByUserNameMaskPlainText}
                    </font>
                  </div>
                  <div className="flex00a textRight nowrap">
                    <font className="font11 fontItalic fontTextLight">
                      {p_noteStampObjOrUndefined.lastEditedDateLocalMask}
                    </font>
                  </div>
                </>
              ) : (
                <div className="flex00a nowrap">
                  <font className="font09 fontItalic fontTextLight">
                    {"edited " + p_noteStampObjOrUndefined.lastEditedDateLocalMask}
                  </font>
                </div>
              )}
            </div>
          </div>
          <div className="flex00a" style={{flexBasis:"0.4em"}}></div>
          {(c_userCanEditCaptureCardContentTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:((p_isInReadingPaneTF) ? (2.2) : (1.8)) + "em"}}>
              <CEGeneralReact.EditSaveCancelIcon
                p_iconType="edit"
                p_title="Click to edit the Title and Content of this Note Stamp"
                f_onClick={this.onclick_edit_note_stamp_item}
              />
            </div>
          }
          {(c_userCanEditCaptureCardContentTF) &&
            ((!p_isInReadingPaneTF) ? (
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
                <CEGeneralReact.DeleteMenu
                  p_message={"Are you sure you want to delete this Note Stamp?\n\n" + p_noteStampObjOrUndefined.infoBlockPlainText}
                  f_onDelete={this.onconfirm_delete_note_stamp}
                />
              </div>
            ) : (
              <div className="flex00a" style={{flexBasis:"0.8em", height:"2em"}} />
            ))
          }
        </CEGeneralReact.CEDragToResortItemWithinDbTbl>
        <div
          className={"flex11a " + ((!p_isInReadingPaneTF) ? ("cursorPointer") : (""))}
          title={((!p_isInReadingPaneTF) ? ("Click to open this Note Stamp in a reading pane") : (undefined))}
          onClick={((!p_isInReadingPaneTF) ? (this.onclick_read_note_stamp_item) : (undefined))}>
          {(p_noteStampObjOrUndefined.titleIsFilledOutTF) &&
            <div
              className="borderT1ddd borderB1ddd textCenter"
              style={{margin:((p_isInReadingPaneTF) ? (0.7) : (0.25)) + "em 15%", padding:((p_isInReadingPaneTF) ? (0.2) : (0.05)) + "em 0"}}>
              <font className={((p_isInReadingPaneTF) ? ("font12") : ("font11")) + " fontBold"}>
                {p_noteStampObjOrUndefined.title}
              </font>
            </div>
          }
          <div className="smallBottomPad lrMedPad">
            <font className="">
              {p_noteStampObjOrUndefined.body}
            </font>
          </div>
        </div>
      </div>
    );
  }
}));


const NoteStampNewEditReadFloatingBox = inject("OpenCaptureMobx")(observer(
class NoteStampNewEditReadFloatingBox extends Component { //props: p_newEditReadFlag, p_noteStampObjOrUndefined
  constructor(props) {
    super(props);

    const p_noteStampObjOrUndefined = this.props.p_noteStampObjOrUndefined;

    //if in edit mode, initialize the input fields with the current values
    var initialNoteStampTitle = "";
    var initialNoteStampBody = "";
    if(p_noteStampObjOrUndefined !== undefined) {
      initialNoteStampTitle = p_noteStampObjOrUndefined.title;
      initialNoteStampBody = p_noteStampObjOrUndefined.body;
    }

    this.state = {
      s_noteStampTitle: initialNoteStampTitle,
      s_noteStampBody: initialNoteStampBody,
      s_anyLocalChangeWereMadeTF: false
    };
  }

  onchange_title = (i_newValueString) => {
    this.setState({
      s_noteStampTitle: i_newValueString,
      s_anyLocalChangeWereMadeTF: true
    });
  }

  onchange_body = (i_newValueString) => {
    this.setState({
      s_noteStampBody: i_newValueString,
      s_anyLocalChangeWereMadeTF: true
    });
  }

  onclick_create_new_note_stamp = () => {
    const s_noteStampTitle = this.state.s_noteStampTitle;
    const s_noteStampBody = this.state.s_noteStampBody;

    this.props.OpenCaptureMobx.a_notepad_insert_new_note_stamp(s_noteStampTitle, s_noteStampBody);
    this.onclick_close_create_new_note_stamp_floating_box();
  }

  onclick_save_edited_note_stamp = () => {
    const s_noteStampTitle = this.state.s_noteStampTitle;
    const s_noteStampBody = this.state.s_noteStampBody;

    const p_noteStampObjOrUndefined = this.props.p_noteStampObjOrUndefined;

    if(p_noteStampObjOrUndefined !== undefined) {
      this.props.OpenCaptureMobx.a_notepad_update_note_stamp(s_noteStampTitle, s_noteStampBody, p_noteStampObjOrUndefined);
    }

    this.onclick_close_create_new_note_stamp_floating_box();
  }

  onclick_close_create_new_note_stamp_floating_box = () => {
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_new_edit_read_floating_box_flag(undefined);
    this.props.OpenCaptureMobx.a_notepad_set_note_stamps_editing_or_reading_note_stamp_obj_or_undefined(undefined);
  }

  render() {
    const s_noteStampTitle = this.state.s_noteStampTitle;
    const s_noteStampBody = this.state.s_noteStampBody;
    const s_anyLocalChangeWereMadeTF = this.state.s_anyLocalChangeWereMadeTF;

    const p_newEditReadFlag = this.props.p_newEditReadFlag;
    const p_noteStampObjOrUndefined = this.props.p_noteStampObjOrUndefined;

    var createNewTF = false;
    var editTF = false;
    var readTF = false;
    if(p_newEditReadFlag === "new") { createNewTF = true; }
    else if(p_newEditReadFlag === "edit") { editTF = true; }
    else if(p_newEditReadFlag === "read") { readTF = true; }

    if(!(createNewTF || editTF || readTF)) {
      return(null);
    }

    var floatingBoxTitle = "";
    if(createNewTF) {
      floatingBoxTitle = "Create a new Note Stamp";
    }
    else if(editTF) {
      floatingBoxTitle = "Edit Note Stamp";
    }
    else if(readTF) {
      floatingBoxTitle = "Read Note Stamp";
    }

    var cancelConfirmationMessageOrUndefined = undefined;
    if(s_anyLocalChangeWereMadeTF) {
      if(createNewTF) {
        cancelConfirmationMessageOrUndefined = "Are you sure you want to cancel creating this new Note Stamp? All entered data will not be saved.";
      }
      else if(editTF) {
        cancelConfirmationMessageOrUndefined = "Are you sure you want to cancel editing this Note Stamp? Any current changes will not be saved.";
      }
    }

    var floatingBoxContentComponent = null;
    if(readTF) {
      floatingBoxContentComponent = (
        <div className="flex11a displayFlexColumn medFullPad">
          <NoteStampItem
            p_isCardTF={false}
            p_isInReadingPaneTF={true}
            p_noteStampObjOrUndefined={p_noteStampObjOrUndefined}
            p_isOnlyItemTF={true}
            p_isLastItemTF={true}
          />
        </div>
      );
    }
    else {
      floatingBoxContentComponent = (
        <>
          <div className="flex11a displayFlexColumn medFullPad">
            <div className="flex00a microBottomMargin">
              <font className="font11 fontBold fontTextLight">
                {"Title (Optional)"}
              </font>
            </div>
            <div className="flex00a">
              <LibraryReact.Text
                p_value={s_noteStampTitle}
                p_styleObj={{width:"100%"}}
                p_tabIndex={((editTF) ? (2) : (1))}
                f_onChange={this.onchange_title}
              />
            </div>
            <div className="flex00a medTopMargin microBottomMargin">
              <font className="font11 fontBold fontTextLight">
                {"Note"}
              </font>
            </div>
            <div className="flex11a">
              <LibraryReact.Textarea
                p_value={s_noteStampBody}
                p_styleObj={{width:"100%", height:"100%"}}
                p_tabIndex={((editTF) ? (1) : (2))}
                f_onChange={this.onchange_body}
              />
            </div>
          </div>
          {(createNewTF) &&
            <div className="flex00a displayFlexColumnHcVc borderT1bbb tbMedPad">
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text="Create Note Stamp"
                p_tabIndex={3}
                f_onClick={this.onclick_create_new_note_stamp}
              />
            </div>
          }
        </>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="medium"
        p_title={floatingBoxTitle}
        p_cancelConfirmationMessage={cancelConfirmationMessageOrUndefined}
        f_onClickSave={((editTF) ? (this.onclick_save_edited_note_stamp) : (undefined))}
        f_onClickCancel={this.onclick_close_create_new_note_stamp_floating_box}>
        {floatingBoxContentComponent}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


const NotepadNotepad = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class NotepadNotepad extends Component { //props: 
  constructor(props) {
    super(props);
    this.state = {
      s_notepadLocal: this.props.OpenCaptureMobx.c_notepadCaptureFieldValueObj.valueRaw,
      s_unsavedChangesTF: false
    };
  }

  onchange_notepad = (i_newValue) => {
    this.setState({
      s_notepadLocal: i_newValue,
      s_unsavedChangesTF: true
    });
  }

  onclick_notepad_save_changes = () => {
    const s_notepadLocal = this.state.s_notepadLocal;

    const c_fieldMapOfNotepad = this.props.DatabaseMobx.c_fieldMapOfNotepad;

    this.setState({s_unsavedChangesTF:false});

    this.props.OpenCaptureMobx.a_details_update_field_value(c_fieldMapOfNotepad, s_notepadLocal);
  }

  render() {
    const s_notepadLocal = this.state.s_notepadLocal;
    const s_unsavedChangesTF = this.state.s_unsavedChangesTF;

    const o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined = this.props.OpenCaptureMobx.o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    return(
      <div className="flex11a displayFlexColumn bgLighterGray">
        <div className="flex00a displayFlexRow" style={{flexBasis:"3em"}}>
          <div className="flex11a displayFlexRowVc lrMedPad">
            <NotepadSideTitles p_text="Notepad" />
          </div>
          {(c_userCanEditCaptureCardContentTF) &&
            <div className="flex00a displayFlexRowVc lrMedPad">
              {(s_unsavedChangesTF) ? (
                <CEGeneralReact.CEButton
                  p_type="blue"
                  p_text="Click to Save Changes"
                  f_onClick={this.onclick_notepad_save_changes}
                />
              ) : (
                <font className="font11 fontItalic fontTextLight">
                  {"All Changes Saved"}
                </font>
              )}
            </div>
          }
        </div>
        <div className="flex11a displayFlexColumn lrMedPad medBottomPad microTopPad">
          {((c_userCanEditCaptureCardContentTF) ? (
            <LibraryReact.Textarea
              p_value={s_notepadLocal}
              p_class="flex11a font11"
              p_styleObj={{width:"100%"}}
              p_tabIndex={((o_notepadNoteStampsNewEditReadFloatingBoxFlagOrUndefined !== undefined) ? (undefined) : (1))}
              f_onChange={this.onchange_notepad}
            />
          ) : (
            <div className="flex11a border1bbb bgWhite smallFullPad yScroll">
              <font className="">
                {s_notepadLocal}
              </font>
            </div>
          ))}
        </div>
      </div>
    );
  }
}));

function NotepadSideTitles(props) { //props: p_text
  return(
    <LibraryReact.Nowrap p_fontClass="font14 fontBold fontTextLight">
      {props.p_text}
    </LibraryReact.Nowrap>
  );
}



//===================================================================================================================================
//--------Revenue-------------------------------------------------------------------------------------------
//===================================================================================================================================
const RevenueSnapshot = inject("OpenCaptureMobx")(observer(
class RevenueSnapshot extends Component {
  render() {
    const c_contractOverallValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_contractOverallValueCaptureFieldValueObj;
    const c_allocatedNetValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_allocatedNetValueCaptureFieldValueObj;

    const moneyFieldValueFontClass = "font13 fontTextLight";
    const moneyFieldDisplayNameFontClass = "font11";

    return(
      <div className="flex11a displayFlexColumnHcVc">
        <div className="">
          <font className={moneyFieldValueFontClass}>
            {c_contractOverallValueCaptureFieldValueObj.valueMask}
          </font>
        </div>
        <div className="displayFlexColumnHcVc">
          <font className={moneyFieldDisplayNameFontClass}>
            {c_contractOverallValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
          </font>
        </div>
        <div className="hugeTopMargin">
          <font className="font25 fontBlue">
            {c_allocatedNetValueCaptureFieldValueObj.valueMask}
          </font>
        </div>
        <div className="displayFlexColumnHcVc">
          <font className={moneyFieldDisplayNameFontClass}>
            {c_allocatedNetValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
          </font>
        </div>
      </div>
    );
  }
}));

const Revenue = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class Revenue extends Component {
  render() {
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_captureTypeIsPrimeTF = this.props.OpenCaptureMobx.c_captureTypeIsPrimeTF;
    const c_contractTypeIsTaskOrderTF = this.props.OpenCaptureMobx.c_contractTypeIsTaskOrderTF;
    const c_captureStageIsWonTF = this.props.OpenCaptureMobx.c_captureStageIsWonTF;
    const c_captureStageIsLostNoBidOrCancelledTF = this.props.OpenCaptureMobx.c_captureStageIsLostNoBidOrCancelledTF;
    const c_captureOurPrimeSubTeammateAllocationPercent0to100 = this.props.OpenCaptureMobx.c_captureOurPrimeSubTeammateAllocationPercent0to100;
    const c_captureTypeCaptureFieldValueObj = this.props.OpenCaptureMobx.c_captureTypeCaptureFieldValueObj;
    const c_contractTypeCaptureFieldValueObj = this.props.OpenCaptureMobx.c_contractTypeCaptureFieldValueObj;
    const c_idiqCaptureIDTOLinkCaptureFieldValueObj = this.props.OpenCaptureMobx.c_idiqCaptureIDTOLinkCaptureFieldValueObj;
    const c_stageCaptureFieldValueObj = this.props.OpenCaptureMobx.c_stageCaptureFieldValueObj;
    const c_contractOverallValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_contractOverallValueCaptureFieldValueObj;
    const c_contractRevenueValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_contractRevenueValueCaptureFieldValueObj;
    const c_allocatedRevenueValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_allocatedRevenueValueCaptureFieldValueObj;
    const c_allocatedNetValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_allocatedNetValueCaptureFieldValueObj;
    const c_allocatedRevenuePerMonthCaptureFieldValueObj = this.props.OpenCaptureMobx.c_allocatedRevenuePerMonthCaptureFieldValueObj;
    const c_allocatedNetPerMonthCaptureFieldValueObj = this.props.OpenCaptureMobx.c_allocatedNetPerMonthCaptureFieldValueObj;
    const c_pwinAdjustedContractOverallValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_pwinAdjustedContractOverallValueCaptureFieldValueObj;
    const c_pwinAdjustedContractRevenueValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_pwinAdjustedContractRevenueValueCaptureFieldValueObj;
    const c_pwinAdjustedAllocatedRevenueValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_pwinAdjustedAllocatedRevenueValueCaptureFieldValueObj;
    const c_pwinAdjustedAllocatedNetValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_pwinAdjustedAllocatedNetValueCaptureFieldValueObj;
    const c_idiqTOAnticipatedValueCaptureFieldValueObj = this.props.OpenCaptureMobx.c_idiqTOAnticipatedValueCaptureFieldValueObj;
    const c_periodOfPerformanceCaptureFieldValueObj = this.props.OpenCaptureMobx.c_periodOfPerformanceCaptureFieldValueObj;
    const c_contractStartDateCaptureFieldValueObj = this.props.OpenCaptureMobx.c_contractStartDateCaptureFieldValueObj;
    const c_contractEndDateCaptureFieldValueObj = this.props.OpenCaptureMobx.c_contractEndDateCaptureFieldValueObj;
    const c_pwinCaptureFieldValueObj = this.props.OpenCaptureMobx.c_pwinCaptureFieldValueObj;
    const c_revenueCostCaptureFieldValuesArrayOfObjs = this.props.OpenCaptureMobx.c_revenueCostCaptureFieldValuesArrayOfObjs;
    const c_revenuePopFyrTableArrayOfObjs = this.props.OpenCaptureMobx.c_revenuePopFyrTableArrayOfObjs;
    const c_idiqTaskOrdersObj = this.props.OpenCaptureMobx.c_idiqTaskOrdersObj;

    const k_cardIDRevenue = this.props.DatabaseMobx.k_cardIDRevenue;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfPwin = this.props.DatabaseMobx.c_fieldMapOfPwin;
    const c_fieldMapOfIdiqCaptureIDTOLink = this.props.DatabaseMobx.c_fieldMapOfIdiqCaptureIDTOLink;
    const c_fieldMapOfOurPrimeSubTeammateAllocation = this.props.DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateAllocation;
    const c_companyUsingPwinTF = this.props.DatabaseMobx.c_companyUsingPwinTF;
    const c_companyPwinIsCalculatedTF = this.props.DatabaseMobx.c_companyPwinIsCalculatedTF;

    const captureTypeHasAtLeast1CostFieldTF = (c_revenueCostCaptureFieldValuesArrayOfObjs.length > 0);
    const numTaskOrders = c_idiqTaskOrdersObj.taskOrdersArrayOfObjs.length;

    const moneyFieldValueFontClass = "font16 fontTextLight";
    const moneyFieldDisplayNameFontClass = "font14";

    const fyrColumnWidth = "5em";

    const pwinAdjustedCaptureFieldValuesArrayOfObjs = [
      c_pwinAdjustedContractOverallValueCaptureFieldValueObj,
      c_pwinAdjustedContractRevenueValueCaptureFieldValueObj,
      c_pwinAdjustedAllocatedRevenueValueCaptureFieldValueObj,
      c_pwinAdjustedAllocatedNetValueCaptureFieldValueObj
    ];

    const contractRevenueValueComponent = (
      <>
        <div className="displayFlexColumnHcVc hugeTopMargin">
          <font className={moneyFieldValueFontClass}>
            {c_contractRevenueValueCaptureFieldValueObj.valueMask}
          </font>
        </div>
        <div className="displayFlexColumnHcVc">
          <font className={moneyFieldDisplayNameFontClass}>
            {c_contractRevenueValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
          </font>
        </div>
      </>
    );

    const contractTypeCaptureTypeBoxesComponent = (
      <div className="displayFlexRowHcWrap hugeTopMargin">
        <div className="flex00a lrMedMargin medFullPad bgLightestGray border1bbb borderRadius10" style={{flexBasis:"35em"}}>
          <div>
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={k_cardIDRevenue}
              p_captureFieldValueObj={c_contractTypeCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_fieldWidth="8em"
              p_containerClass={undefined}
              p_fieldClass="font11 fontBlue"
              p_valueClass="font11"
              p_fieldValueVerticallyAlignedTF={false}
              p_showFieldNameTF={true}
            />
          </div>
          {(c_contractTypeIsTaskOrderTF) &&
            <div className="microTopMargin">
              <DetailsItem
                p_captureID={o_openCaptureID}
                p_cardID={k_cardIDRevenue}
                p_captureFieldValueObj={c_idiqCaptureIDTOLinkCaptureFieldValueObj}
                p_isSnapshotTF={false}
                p_fieldWidth="8em"
                p_containerClass={undefined}
                p_fieldClass="font11 fontBlue"
                p_valueClass="font11"
                p_fieldValueVerticallyAlignedTF={false}
                p_showFieldNameTF={true}
              />
            </div>
          }
        </div>
        <div className="flex00a lrMedMargin medFullPad bgLightestGray border1bbb borderRadius10" style={{flexBasis:"35em"}}>
          <div>
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={k_cardIDRevenue}
              p_captureFieldValueObj={c_captureTypeCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_fieldWidth="8em"
              p_containerClass={undefined}
              p_fieldClass="font11 fontBlue"
              p_valueClass="font11"
              p_fieldValueVerticallyAlignedTF={true}
              p_showFieldNameTF={true}
            />
          </div>
          <div className="displayFlexColumnHcVc microTopMargin">
            <font className="font14 fontBlue">
              {((c_captureTypeIsPrimeTF) ? ("You are the Prime") : ("You are a Sub"))}
            </font>
          </div>
          <div className="displayFlexColumnHcVc microTopMargin" title={"The value for '" + c_fieldMapOfOurPrimeSubTeammateAllocation.get("display_name") + "' can only be updated on the 'Teammates' Capture Card in the blue section for our company"}>
            <div className="flex00a displayFlexRowVc">
              <div className="flex00a">
                <font className="font12 fontTextLight">
                  {c_fieldMapOfOurPrimeSubTeammateAllocation.get("display_name") + ": "}
                </font>
              </div>
              <div className="flex00a">
                <font className="font13 fontBold fontTextLight">
                  {JSFUNC.round_number_to_num_decimals_if_needed(c_captureOurPrimeSubTeammateAllocationPercent0to100, 2) + "%"}
                </font>
              </div>
            </div>

          </div>
        </div>
      </div>
    );

    return(
      <div className="flex11a yScroll yScrollBottomPad">
        <div className="displayFlexColumnHcVc bigTopMargin">
          <div className="" style={{width:"18em", marginLeft:"6em"}}>
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={k_cardIDRevenue}
              p_captureFieldValueObj={c_contractOverallValueCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_fieldWidth={undefined}
              p_containerClass={undefined}
              p_fieldClass={undefined}
              p_valueClass={moneyFieldValueFontClass}
              p_fieldValueVerticallyAlignedTF={true}
              p_showFieldNameTF={false}
            />
          </div>
        </div>
        <div className="displayFlexColumnHcVc">
          <font className={moneyFieldDisplayNameFontClass}>
            {c_contractOverallValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
          </font>
        </div>
        {(c_captureTypeIsPrimeTF) ? (
          <>
            {contractRevenueValueComponent}
            {contractTypeCaptureTypeBoxesComponent}
          </>
        ) : (
          <>
            {contractTypeCaptureTypeBoxesComponent}
            {contractRevenueValueComponent}
          </>
        )}
        <div className="displayFlexColumnHcVc hugeTopMargin">
          <font className={moneyFieldValueFontClass}>
            {c_allocatedRevenueValueCaptureFieldValueObj.valueMask}
          </font>
        </div>
        <div className="displayFlexColumnHcVc">
          <font className={moneyFieldDisplayNameFontClass}>
            {c_allocatedRevenueValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
          </font>
        </div>
        <div className="hugeTopMargin" style={{marginLeft:"15%", marginRight:"15%", borderTop:"solid 1px #bbb"}} />
        <div className="displayFlexColumnHcVc smallTopMargin">
          <font className="font12 fontItalic fontTextLighter">
            {((captureTypeHasAtLeast1CostFieldTF) ? ("Costs Against " + c_allocatedRevenueValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")) : ("No Costs Against Revenues"))}
          </font>
        </div>
        {(captureTypeHasAtLeast1CostFieldTF) &&
          <div className="displayFlexColumnHcVc smallTopMargin">
            <div style={{width:"30em"}}>
              {c_revenueCostCaptureFieldValuesArrayOfObjs.map((m_revenueCostCaptureFieldValueObj) =>
                <DetailsItem
                  p_captureID={o_openCaptureID}
                  p_cardID={k_cardIDRevenue}
                  p_captureFieldValueObj={m_revenueCostCaptureFieldValueObj}
                  p_isSnapshotTF={false}
                  p_fieldWidth="13em"
                  p_containerClass={undefined}
                  p_fieldClass="font11 fontTextLight"
                  p_valueClass={"font11 " + ((m_revenueCostCaptureFieldValueObj.valueRaw > 0) ? ("fontDarkRed") : ((m_revenueCostCaptureFieldValueObj.valueRaw < 0) ? ("fontGreen") : ("")))}
                  p_fieldValueVerticallyAlignedTF={true}
                  p_showFieldNameTF={true}
                />
              )}
            </div>
          </div>
        }
        <div className="smallTopMargin" style={{marginLeft:"15%", marginRight:"15%", borderTop:"solid 1px #bbb"}} />
        <div className="displayFlexColumnHcVc hugeTopMargin">
          <font className="font35 fontBlue">
            {c_allocatedNetValueCaptureFieldValueObj.valueMask}
          </font>
        </div>
        <div className="displayFlexColumnHcVc">
          <font className={moneyFieldDisplayNameFontClass}>
            {c_allocatedNetValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
          </font>
        </div>
        <div className="displayFlexRowHcWrap hugeTopMargin">
          <div className="flex00a lrMedMargin medBottomMargin smallFullPad border1bbb" style={{flexBasis:"35em"}}>
            <div className="displayFlexColumnHcVc tbPad">
              <font className="font18 fontItalic fontTextLightester">
                {"PoP"}
              </font>
            </div>
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={k_cardIDRevenue}
              p_captureFieldValueObj={c_periodOfPerformanceCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_fieldWidth="10em"
              p_containerClass={undefined}
              p_fieldClass="fontTextLight"
              p_valueClass=""
              p_fieldValueVerticallyAlignedTF={true}
              p_showFieldNameTF={true}
            />
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={k_cardIDRevenue}
              p_captureFieldValueObj={c_contractStartDateCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_fieldWidth="10em"
              p_containerClass={undefined}
              p_fieldClass="fontTextLight"
              p_valueClass=""
              p_fieldValueVerticallyAlignedTF={true}
              p_showFieldNameTF={true}
            />
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={k_cardIDRevenue}
              p_captureFieldValueObj={c_contractEndDateCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_fieldWidth="10em"
              p_containerClass={undefined}
              p_fieldClass="fontTextLight"
              p_valueClass=""
              p_fieldValueVerticallyAlignedTF={true}
              p_showFieldNameTF={true}
            />
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={k_cardIDRevenue}
              p_captureFieldValueObj={c_allocatedRevenuePerMonthCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_fieldWidth="15em"
              p_containerClass={undefined}
              p_fieldClass="font11 fontTextLight"
              p_valueClass="font11"
              p_fieldValueVerticallyAlignedTF={true}
              p_showFieldNameTF={true}
            />
            <DetailsItem
              p_captureID={o_openCaptureID}
              p_cardID={k_cardIDRevenue}
              p_captureFieldValueObj={c_allocatedNetPerMonthCaptureFieldValueObj}
              p_isSnapshotTF={false}
              p_fieldWidth="15em"
              p_containerClass={undefined}
              p_fieldClass="font11 fontTextLight"
              p_valueClass="font11"
              p_fieldValueVerticallyAlignedTF={true}
              p_showFieldNameTF={true}
            />
            {(c_revenuePopFyrTableArrayOfObjs.length === 0) ? (
              <div className="hugeTopMargin">
                <font className="fontItalic fontTextLighter">
                  {c_periodOfPerformanceCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name") + " and " + c_contractStartDateCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name") + " must be filled in to show the FYR distribution table"}
                </font>
              </div>
            ) : (
              <>
                <div className="hugeTopMargin">
                  <font className="font11 fontItalic fontTextLight">
                    {"FYR Distribution Table"}
                  </font>
                </div>
                <div className="displayFlexRow">
                  <div className="flex00a" style={{flexBasis:fyrColumnWidth}}>
                    <font className="displayFlexColumnHcVc">
                      {"FYR"}
                    </font>
                  </div>
                  <div className="flex11a" style={{flexBasis:"100em"}}>
                    <font className="displayFlexColumnHcVc">
                      {c_allocatedRevenueValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
                    </font>
                  </div>
                  <div className="flex11a" style={{flexBasis:"100em"}}>
                    <font className="displayFlexColumnHcVc">
                      {c_allocatedNetValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
                    </font>
                  </div>
                </div>
                {c_revenuePopFyrTableArrayOfObjs.map((m_revenuePopFyrTableObj) =>
                  <div className="displayFlexRow hoverLighterBlueGradient">
                    <div className="flex00a" style={{flexBasis:fyrColumnWidth}}>
                      <font className="displayFlexColumnHcVc">
                        {m_revenuePopFyrTableObj.fyr}
                      </font>
                    </div>
                    <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
                      <font className="">
                        {m_revenuePopFyrTableObj.revenueMask}
                      </font>
                    </div>
                    <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
                      <font className="">
                        {m_revenuePopFyrTableObj.netMask}
                      </font>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {(c_idiqTaskOrdersObj.isIDIQContractTF || c_idiqTaskOrdersObj.isTaskOrderContractTF) &&
            <div className="flex00a lrMedMargin medBottomMargin smallFullPad border1bbb" style={{flexBasis:"35em"}}>
              <div className="displayFlexColumnHcVc tbPad">
                <font className="font18 fontItalic fontTextLightester">
                  {"IDIQ / Task Orders"}
                </font>
              </div>
              <div className="displayFlexColumnHcVc">
                <font className="fontItalic fontTextLighter">
                  {c_fieldMapOfIdiqCaptureIDTOLink.get("display_name")}
                </font>
              </div>
              <div className="displayFlexRow tbMicroPad">
                {(c_idiqTaskOrdersObj.idiqObj !== undefined) ? (
                  <>
                    <div className="flex11a lrPad" style={{flexBasis:"200em"}} title={c_idiqTaskOrdersObj.idiqObj.captureFullName}>
                      <LibraryReact.Nowrap p_fontClass="fontTextLight">
                        {c_idiqTaskOrdersObj.idiqObj.captureFullName}
                      </LibraryReact.Nowrap>
                    </div>
                    <div className="flex11a lrPad" style={{flexBasis:"75em"}} title={c_idiqTaskOrdersObj.idiqObj.stageName}>
                      <LibraryReact.Nowrap p_fontClass="">
                        {c_idiqTaskOrdersObj.idiqObj.stageName}
                      </LibraryReact.Nowrap>
                    </div>
                    <div className="flex11a lrPad textRight" style={{flexBasis:"100em"}} title={c_idiqTaskOrdersObj.idiqObj.contractValue}>
                      <LibraryReact.Nowrap p_fontClass="">
                        {c_idiqTaskOrdersObj.idiqObj.contractValue}
                      </LibraryReact.Nowrap>
                    </div>
                  </>
                ) : (
                  <div className="flex11a lrPad">
                    <font className="fontDarkRed">
                      {"--No IDIQ Vehicle found--"}
                    </font>
                  </div>
                )}
              </div>
              <div className="medTopMargin">
                {(c_idiqTaskOrdersObj.isIDIQContractTF) ? (
                  <div className="smallFullPad bgLightestGray border1bbb borderRadius05">
                    <DetailsItem
                      p_captureID={o_openCaptureID}
                      p_cardID={k_cardIDRevenue}
                      p_captureFieldValueObj={c_idiqTOAnticipatedValueCaptureFieldValueObj}
                      p_isSnapshotTF={false}
                      p_fieldWidth="17em"
                      p_containerClass={undefined}
                      p_fieldClass="font11 fontBlue"
                      p_valueClass="font11"
                      p_fieldValueVerticallyAlignedTF={false}
                      p_showFieldNameTF={true}
                    />
                  </div>
                ) : (
                  <font className="fontItalic fontTextLighter">
                    {c_idiqTOAnticipatedValueCaptureFieldValueObj.expandedCaptureFieldMap.get("display_name") + ": " + c_idiqTaskOrdersObj.idiqObj.anticipatedValueMaskPlainText}
                  </font>
                )}
              </div>
              <div className="microTopMargin">
                <font className="fontItalic fontTextLighter">
                  {"Total Task Orders Won: "}<font className="fontGreen">{"$" + JSFUNC.money(c_idiqTaskOrdersObj.wonTaskOrdersContractValueRawTotal, 0)}</font>
                </font>
              </div>
              {(c_idiqTOAnticipatedValueCaptureFieldValueObj.valueRaw > 0) &&
                <div className="microTopMargin">
                  <font className="fontItalic fontTextLighter">
                    {Math.round((c_idiqTaskOrdersObj.wonTaskOrdersContractValueRawTotal / c_idiqTOAnticipatedValueCaptureFieldValueObj.valueRaw) * 100) + "% of Anticipated Value"}
                  </font>
                </div>
              }
              <div className="displayFlexColumnHcVc hugeTopMargin">
                <font className="fontItalic fontTextLighter">
                  {numTaskOrders + " Task " + JSFUNC.plural(numTaskOrders, "Order", "Orders") + " " + ((c_idiqTaskOrdersObj.isIDIQContractTF) ? ("under this IDIQ") : ("under the same IDIQ Vehicle"))}
                </font>
              </div>
              {c_idiqTaskOrdersObj.taskOrdersArrayOfObjs.map((m_taskOrderObj) =>
                <div className="displayFlexRow tbMicroPad">
                  <div className="flex11a lrPad" style={{flexBasis:"200em"}} title={m_taskOrderObj.captureFullName}>
                    <LibraryReact.Nowrap p_fontClass="fontTextLight">
                      {m_taskOrderObj.captureFullName}
                    </LibraryReact.Nowrap>
                  </div>
                  <div className="flex11a lrPad" style={{flexBasis:"75em"}} title={m_taskOrderObj.stageName}>
                    <LibraryReact.Nowrap p_fontClass="">
                      {m_taskOrderObj.stageName}
                    </LibraryReact.Nowrap>
                  </div>
                  <div className="flex11a lrPad textRight" style={{flexBasis:"100em"}} title={m_taskOrderObj.contractValue}>
                    <LibraryReact.Nowrap p_fontClass="">
                      {m_taskOrderObj.contractValue}
                    </LibraryReact.Nowrap>
                  </div>
                </div>
              )}
            </div>
          }
          {(c_companyUsingPwinTF) &&
            <div className="flex00a lrMedMargin medBottomMargin smallFullPad border1bbb" style={{flexBasis:"35em"}}>
              <div className="displayFlexColumnHcVc tbPad">
                <font className="font18 fontItalic fontTextLightester">
                  {c_fieldMapOfPwin.get("display_name")}
                </font>
              </div>
              <DetailsItem
                p_captureID={o_openCaptureID}
                p_cardID={k_cardIDRevenue}
                p_captureFieldValueObj={c_pwinCaptureFieldValueObj}
                p_valueIsEditableTFOverwrite={((c_companyPwinIsCalculatedTF) ? (false) : (undefined))}
                p_isSnapshotTF={false}
                p_fieldWidth="8em"
                p_containerClass={undefined}
                p_fieldClass="font11 fontTextLight"
                p_valueClass="font11"
                p_fieldValueVerticallyAlignedTF={true}
                p_showFieldNameTF={true}
              />
              <div className="microTopMargin">
                <font className="fontTextLight">
                  {c_fieldMapOfStage.get("display_name") + ": " + c_stageCaptureFieldValueObj.valueMask}
                </font>
              </div>
              {(c_captureStageIsWonTF || c_captureStageIsLostNoBidOrCancelledTF) &&
                <div className="microTopMargin">
                  <font className="fontItalic fontTextLighter">
                    {c_fieldMapOfPwin.get("display_name") + " effective value set to " + ((c_captureStageIsWonTF) ? ("100%") : ("0%")) + " because Capture current " + c_fieldMapOfStage.get("display_name") + " type is " + ((c_captureStageIsWonTF) ? ("'Won'") : ("either 'Lost', 'No Bid', or 'Cancelled'"))}
                  </font>
                </div>
              }
              <div className="medTopMargin" />
              {pwinAdjustedCaptureFieldValuesArrayOfObjs.map((m_pwinAdjustedCAptureFieldValueObj) =>
                <div className="displayFlexRowVc">
                  <div className="flex11a lrPad" style={{flexBasis:"250em"}} title={m_pwinAdjustedCAptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}>
                    <LibraryReact.Nowrap p_fontClass="font11 fontTextLight">
                      {m_pwinAdjustedCAptureFieldValueObj.expandedCaptureFieldMap.get("display_name")}
                    </LibraryReact.Nowrap>
                  </div>
                  <div className="flex11a lrPad textRight" style={{flexBasis:"100em"}} title={m_pwinAdjustedCAptureFieldValueObj.valueMaskPlainText}>
                    <LibraryReact.Nowrap p_fontClass="font11 fontTextLight">
                      {m_pwinAdjustedCAptureFieldValueObj.valueMask}
                    </LibraryReact.Nowrap>
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    );
  }
}));
