import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import ContactsMobx from '../Contacts/ContactsMobx.js';
import OpenCaptureMobx from '../OpenCapture/OpenCaptureMobx.js';

class TeammateContractsMobx {
  //========================================================================================
  //observable values
  //contractsexec workload and contracts todo
  o_captureContractsFilterFlag = "activeWonCapturesInProcessContracts"; //"activeWonCapturesInProcessContracts", "activeWonCapturesFullyExecutedCancelledContracts", "lostNoBidCancelledCapturesAllContracts"

  //contractsexec workload
  o_execExpandedUserIDsArray = [];
  o_execSelectedReassignContractsUserID = undefined;
  o_execCheckedCaptureIDsArray = [];

  //contracts manager todo

  //teammate contract floating box
  o_openContractFromContractsTodoTF = false; //this determines who is watching the process (capture/false) and who is allowed to advance the statuses (contracts/true)
  o_openContractCaptureID = undefined;
  o_openContractTeammateID = undefined;
  o_openContractTypeID = undefined;
  o_mobileAdvancingStatusTF = false; //set to true when the mobile/tablet button is pushed above the status timeline to advance the status by clicking the desired status
  o_contractProcessTabOpen = "sendEmail"; //"sendEmail", "history"
  o_selectedContractTemplateID = undefined;

  //metrics
  o_metricsFilterDivisionIDsCommaOrAll = "all";
  o_metricsFilterContractsUserIDsCommaOrAll = "all";

  constructor() {
    makeObservable(this, {
      o_captureContractsFilterFlag: observable,
      o_execExpandedUserIDsArray: observable,
      o_execSelectedReassignContractsUserID: observable,
      o_execCheckedCaptureIDsArray: observable,
      o_openContractFromContractsTodoTF: observable,
      o_openContractCaptureID: observable,
      o_openContractTeammateID: observable,
      o_openContractTypeID: observable,
      o_mobileAdvancingStatusTF: observable,
      o_contractProcessTabOpen: observable,
      o_selectedContractTemplateID: observable,
      o_metricsFilterDivisionIDsCommaOrAll: observable,
      o_metricsFilterContractsUserIDsCommaOrAll: observable,

      c_statusProcessNotStartedObj: computed,
      c_statusCancelledObj: computed,
      c_statusGenerateSendAgreementObj: computed,
      c_statusFullyExecutedObj: computed,
      c_statusesTeammateIsReviewingArrayOfObjs: computed,
      c_statusesWeAreReviewingArrayOfObjs: computed,
      c_statusInvalidObj: computed,
      c_activeWonCapturesInProcessContractsFilterButtonName: computed,
      c_activeWonCapturesFullyExecutedCancelledContractsFilterButtonName: computed,
      c_lostNoBidCancelledCapturesAllContractsFilterButtonName: computed,
      c_execDataObj: computed,
      c_todoCaptureIDsAssignedToLoggedInContractsUserArray: computed,
      c_todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs: computed,
      c_todoInProcessContractsDataObj: computed,
      c_todoFullyExecutedCancelledContractsDataObj: computed,
      c_todoLostNoBidCancelledCaptureContractsDataObj: computed,
      c_todoInProcessNumContracts: computed,
      c_todoFullyExecutedCancelledNumContracts: computed,
      c_todoLostNoBidCancelledCaptureNumContracts: computed,
      c_openTeammateContractObj: computed,
      c_captureMap: computed,
      c_captureFullName: computed,
      c_captureContractsManagerValueMaskSortIfoCanEditObj: computed,
      c_captureContractRequestedByUserIDIsCurrentlyLoggedInUserTF: computed,
      c_openContractCaptureContractsManagerUserIDsToNotIncludeArray: computed,
      c_teammateContractChangeLogArrayOfObjs: computed,
      c_teammatesContractsFileFolderSystemMapOfMaps: computed,
      c_selectAllContractTemplatesFFSFieldTypeObj: computed,
      c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj: computed,

      a_exec_toggle_expand_collapse_user: action,
      a_exec_set_selected_reassign_contracts_user_id: action,
      a_exec_reassign_checked_captures_to_selected_contracts_user: action,
      a_exec_user_check_or_uncheck_all_captures: action,
      a_exec_check_or_uncheck_capture: action,
      a_contractsexec_workload_and_contracts_todo_set_capture_contracts_filter_flag: action,
      a_open_teammate_contract: action,
      a_close_teammate_contract: action,
      a_set_mobile_advance_status_tf: action,
      a_set_contract_process_tab_open: action,
      a_create_or_update_contract_from_pns_or_c_to_gsa: action,
      a_update_teammate_contract_status: action,
      a_update_teammate_contract_need_by_date: action,
      a_update_teammate_contract_agreement_type: action,
      a_contracts_generate_upload_contract_from_template_add_process_history: action,
      a_contracts_add_note_to_process_history: action,
      a_set_selected_contract_template_id: action,
      a_set_teammate_contracts_manager_contact_person: action,
      a_send_notification_after_files_uploads: action,
      a_metrics_set_filter_division_ids_comma_or_all: action,
      a_metrics_set_filter_contracts_user_ids_comma_or_all: action
    });
  }





  //========================================================================================
  //computed values
  //hardcoded teammate contract statuses
  get c_statusProcessNotStartedObj() {
    const statusProcessNotStartedMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_statuses", 1); //Process Not Started is hardcoded id 1
    return(JSFUNC.obj_from_map(statusProcessNotStartedMap));
  }
  get c_statusCancelledObj() {
    const statusCancelledMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_statuses", 2); //Cancelled is hardcoded id 2
    return(JSFUNC.obj_from_map(statusCancelledMap));
  }
  get c_statusGenerateSendAgreementObj() {
    const statusGenerateSendAgreementMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_statuses", 3); //Generate/Send Agreement is hardcoded id 3
    return(JSFUNC.obj_from_map(statusGenerateSendAgreementMap));
  }
  get c_statusFullyExecutedObj() {
    const statusFullyExecutedMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_statuses", 4); //Fully Executed is hardcoded id 4
    return(JSFUNC.obj_from_map(statusFullyExecutedMap));
  }
  get c_statusesTeammateIsReviewingArrayOfObjs() {
    const tblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_teammates_contract_statuses");
    const statusesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(tblRef, "hc0_they1_we2", 1);
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(statusesMapOfMaps, "sort", true));
  }
  get c_statusesWeAreReviewingArrayOfObjs() {
    const tblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_teammates_contract_statuses");
    const statusesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(tblRef, "hc0_they1_we2", 2);
    return(JSFUNC.arrayOfObjs_from_mapOfMaps(statusesMapOfMaps, "sort", true));
  }
  get c_statusInvalidObj() {
    if(this.c_openTeammateContractObj !== undefined) {
      var allValidStatusIDsArray = [this.c_statusGenerateSendAgreementObj.id, this.c_statusFullyExecutedObj.id];
      for(let statusObj of this.c_statusesTeammateIsReviewingArrayOfObjs) {
        allValidStatusIDsArray.push(statusObj.id);
      }
      for(let statusObj of this.c_statusesWeAreReviewingArrayOfObjs) {
        allValidStatusIDsArray.push(statusObj.id);
      }

      if(!JSFUNC.in_array(this.c_openTeammateContractObj.status_id, allValidStatusIDsArray)) {
        const statusInvalidMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_statuses", this.c_openTeammateContractObj.status_id);
        return(JSFUNC.obj_from_map(statusInvalidMap));
      }
    }
    return(undefined);
  }


  //contractsexec workload and contracts todo
  get c_activeWonCapturesInProcessContractsFilterButtonName() {
    return("In-Process Agreements");
  }
  get c_activeWonCapturesFullyExecutedCancelledContractsFilterButtonName() {;
    return(this.c_statusFullyExecutedObj.name + " and " + this.c_statusCancelledObj.name + " Agreements");
  }
  get c_lostNoBidCancelledCapturesAllContractsFilterButtonName() {
    return("Lost, No Bid, or Cancelled Captures");
  }


  //contractsexec workload
  get c_execDataObj() { //all contracts and contractsexecs in divisions in or below the one this contractsexec user is in
    //determine the selected filter flag to filter the captures/contracts
    var filterIsActiveWonCapturesInProcessContractsTF = false;
    var filterIsActiveWonCapturesFullyExecutedCancelledContractsTF = false;
    var filterIsLostNoBidCancelledCapturesAllContractsTF = false;
    if(this.o_captureContractsFilterFlag === "activeWonCapturesInProcessContracts") {
      filterIsActiveWonCapturesInProcessContractsTF = true;
    }
    else if(this.o_captureContractsFilterFlag === "activeWonCapturesFullyExecutedCancelledContracts") {
      filterIsActiveWonCapturesFullyExecutedCancelledContractsTF = true;
    }
    else if(this.o_captureContractsFilterFlag === "lostNoBidCancelledCapturesAllContracts") {
      filterIsLostNoBidCancelledCapturesAllContractsTF = true;
    }

    //get stageIDs for all active and won captures
    const allActiveAndWonStageIDsArray = [].concat(DatabaseMobx.c_activeStageIDsArray, DatabaseMobx.c_closedWonStageIDsArray);

    //get this contractsexec user's division tree
    const contractsExecDivisionID = UserMobx.c_userDivisionID;
    const childrenDivisionIDsArray = DatabaseMobx.division_and_all_children_division_ids_array(contractsExecDivisionID);

    const cancelledStatusID = this.c_statusCancelledObj.id;
    const fullyExecutedStatusID = this.c_statusFullyExecutedObj.id;

    var contractsUsersArrayOfObjs = [];
    var userIDsToNotIncludeArray = [];
    for(let combinedUserMap of DatabaseMobx.c_combinedUsersNotDeactivatedMapOfMaps.values()) { //loop through combined user maps looking for contracts/contractsexec users
      if(combinedUserMap.get("powerHasContractsPowerTF")) {
        var userID = combinedUserMap.get("user_id");
        var userFullName = combinedUserMap.get("fullName");
        var userDivisionID = combinedUserMap.get("division_id");

        var userIsInDivisionTree = JSFUNC.in_array(userDivisionID, childrenDivisionIDsArray);
        if(!userIsInDivisionTree) { //keep an array of contracts users not in the division tree of this exec to exclude them from the reassign selection list
          userIDsToNotIncludeArray.push(userID);
        }
        else { //contracts user within the exec division tree
          var numContractsOverdue = 0;
          var numContractsDueWithinWeek = 0;
          var numContractsDueLonger = 0;
          var numContractsFullyExecutedCancelled = 0;

          //add newly constructed data fields for contracts this user has assigned to them
          var capturesArrayOfObjs = []; //will sort these captures by most overdue need by date on top after looping through
          for(let captureMap of DatabaseMobx.o_tbl_captures.values()) {
            if(captureMap.get("contracts_manager_user_id") === userID) { //verify this capture is assigned to this contracts/contractsexec user
              //make sure the stageID of this capture matches the selected contracts filter button
              const captureStageID = captureMap.get("stage_id");
              var stageMatchesFilter = false;
              if(filterIsActiveWonCapturesInProcessContractsTF || filterIsActiveWonCapturesFullyExecutedCancelledContractsTF) { //active and won stages
                stageMatchesFilter = JSFUNC.in_array(captureStageID, allActiveAndWonStageIDsArray);
              }
              else if(filterIsLostNoBidCancelledCapturesAllContractsTF) { //lost, no bid, cancelled stages (and stages that do not exist or are not set)
                stageMatchesFilter = (!JSFUNC.in_array(captureStageID, allActiveAndWonStageIDsArray));
              }

              if(stageMatchesFilter) {
                var captureID = captureMap.get("id");
                var captureName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);

                var earliestNeedByDate = JSFUNC.sort_max_date(); //9999 date will sort to the bottom for captures that have 0 in process contracts, the lowest date (most overdue) will be on top

                var expandedTeammateContractsArrayOfObjs = [];
                for(let tblCTeammatesContractsRowMap of DatabaseMobx.o_tbl_c_teammates_contracts.values()) {
                  var contractCaptureID = tblCTeammatesContractsRowMap.get("capture_id");
                  if(contractCaptureID === captureID) { //only include teammate conracts for this captureID
                    //include all contracts if the switch is flipped, do not show FE/C if the switch is off
                    var contractStatusID = tblCTeammatesContractsRowMap.get("status_id");
                    var contractIsFullyExecutedOrCancelledTF = (contractStatusID === cancelledStatusID || contractStatusID === fullyExecutedStatusID);
                    if(filterIsLostNoBidCancelledCapturesAllContractsTF || (filterIsActiveWonCapturesInProcessContractsTF && !contractIsFullyExecutedOrCancelledTF) || (filterIsActiveWonCapturesFullyExecutedCancelledContractsTF && contractIsFullyExecutedOrCancelledTF)) {
                      //compute the expanded teammate contract obj for this contract record
                      var contractTeammateID = tblCTeammatesContractsRowMap.get("teammate_id");
                      var contractTypeID = tblCTeammatesContractsRowMap.get("teammate_contract_type_id");
                      var contractNeedByDate = tblCTeammatesContractsRowMap.get("date_needed");
                      var expandedTeammateContractObj = this.expanded_teammate_contract_obj_from_capture_id_and_teammate_id_and_contract_type_id(contractCaptureID, contractTeammateID, contractTypeID);

                      var tblCTeammatesRowMap = DatabaseMobx.tbl_row_map_from_id("tbl_c_teammates", contractTeammateID);
                      expandedTeammateContractObj.teammateObj = this.create_combined_teammate_obj_from_tbl_c_teammates_row_map(tblCTeammatesRowMap);

                      var dateNeededSort = ((JSFUNC.date_is_filled_out_tf(contractNeedByDate)) ? (contractNeedByDate) : (JSFUNC.sort_max_date()));
                      if(dateNeededSort < earliestNeedByDate) {
                        earliestNeedByDate = dateNeededSort;
                      }

                      if(contractIsFullyExecutedOrCancelledTF) {
                        numContractsFullyExecutedCancelled++;
                      }
                      else {
                        var numDaysUntilDateNeeded = JSFUNC.num_days_from_date1_to_date2(CaptureExecMobx.o_nowDate, contractNeedByDate);
                        if(numDaysUntilDateNeeded < 0) {
                          numContractsOverdue++;
                        }
                        else if(numDaysUntilDateNeeded <= 7) {
                          numContractsDueWithinWeek++;
                        }
                        else {
                          numContractsDueLonger++;
                        }
                      }

                      expandedTeammateContractObj.dateNeededSort = dateNeededSort;

                      expandedTeammateContractsArrayOfObjs.push(expandedTeammateContractObj);
                    }
                  }
                }
                JSFUNC.sort_arrayOfObjs(expandedTeammateContractsArrayOfObjs, "dateNeededSort", true);

                capturesArrayOfObjs.push({
                  id: captureID,
                  captureFullName: captureName,
                  earliestNeedByDate: earliestNeedByDate,
                  expandedTeammateContractsArrayOfObjs: expandedTeammateContractsArrayOfObjs
                });
              }
            }
          }
          JSFUNC.sort_arrayOfObjs(capturesArrayOfObjs, "earliestNeedByDate", true); //sort the captures for each user by the earliest need by date

          //create the contracts user obj and append it to the array of contracts users
          contractsUsersArrayOfObjs.push({
            user_id: userID,
            fullName: userFullName,
            numContractsOverdue: numContractsOverdue,
            numContractsDueWithinWeek: numContractsDueWithinWeek,
            numContractsDueLonger: numContractsDueLonger,
            numContractsFullyExecutedCancelled: numContractsFullyExecutedCancelled,
            capturesArrayOfObjs: capturesArrayOfObjs
          });
        }
      }
    }
    contractsUsersArrayOfObjs.sort(JSFUNC.sort_by_asc("sortName"));
    return({
      contractsUsersArrayOfObjs: contractsUsersArrayOfObjs,
      userIDsToNotIncludeArray: userIDsToNotIncludeArray
    });
  }

  //contracts manager todo
  get c_todoCaptureIDsAssignedToLoggedInContractsUserArray() {
    const capturesTblRef = DatabaseMobx.tbl_ref_from_tbl_name("tbl_captures");
    const capturesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(capturesTblRef, "contracts_manager_user_id", UserMobx.o_userID);
    return(JSFUNC.get_column_vector_from_mapOfMaps(capturesMapOfMaps, "id"));
  }

  get c_todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs() {
    var todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs = [];
    for(let tblCTeammatesContractsRowMap of DatabaseMobx.o_tbl_c_teammates_contracts.values()) {
      var contractCaptureID = tblCTeammatesContractsRowMap.get("capture_id");
      if(JSFUNC.in_array(contractCaptureID, this.c_todoCaptureIDsAssignedToLoggedInContractsUserArray)) {
        var contractTeammateID = tblCTeammatesContractsRowMap.get("teammate_id");
        var contractTypeID = tblCTeammatesContractsRowMap.get("teammate_contract_type_id");
        var expandedTeammateContractObj = this.expanded_teammate_contract_obj_from_capture_id_and_teammate_id_and_contract_type_id(contractCaptureID, contractTeammateID, contractTypeID);

        var tblCTeammatesRowMap = DatabaseMobx.tbl_row_map_from_id("tbl_c_teammates", contractTeammateID);
        expandedTeammateContractObj.teammateObj = this.create_combined_teammate_obj_from_tbl_c_teammates_row_map(tblCTeammatesRowMap);

        todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs.push(expandedTeammateContractObj);
      }
    }
    JSFUNC.sort_arrayOfObjs(todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs, "date_needed", true);
    return(todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs);
  }

  get c_todoInProcessContractsDataObj() {
    return(this.create_todo_contracts_data_obj_from_contracts_filter_flag("activeWonCapturesInProcessContracts"));
  }

  get c_todoFullyExecutedCancelledContractsDataObj() {
    return(this.create_todo_contracts_data_obj_from_contracts_filter_flag("activeWonCapturesFullyExecutedCancelledContracts"));
  }

  get c_todoLostNoBidCancelledCaptureContractsDataObj() {
    return(this.create_todo_contracts_data_obj_from_contracts_filter_flag("lostNoBidCancelledCapturesAllContracts"));
  }

  get c_todoInProcessNumContracts() {
    if(this.c_todoInProcessContractsDataObj === undefined) {
      return(0);
    };
    return(this.c_todoInProcessContractsDataObj.numContracts);
  }

  get c_todoFullyExecutedCancelledNumContracts() {
    if(this.c_todoFullyExecutedCancelledContractsDataObj === undefined) {
      return(0);
    };
    return(this.c_todoFullyExecutedCancelledContractsDataObj.numContracts);
  }

  get c_todoLostNoBidCancelledCaptureNumContracts() {
    if(this.c_todoLostNoBidCancelledCaptureContractsDataObj === undefined) {
      return(0);
    };
    return(this.c_todoLostNoBidCancelledCaptureContractsDataObj.numContracts);
  }

  //teammate contract floating box
  get c_openTeammateContractObj() {
    const o_openContractCaptureID = this.o_openContractCaptureID;
    const o_openContractTeammateID = this.o_openContractTeammateID;
    const o_openContractTypeID = this.o_openContractTypeID;

    if((o_openContractCaptureID === undefined) || (o_openContractTeammateID === undefined) || (o_openContractTypeID === undefined)) {
      return(undefined);
    }

    var openExpandedTeammateContractObj = this.expanded_teammate_contract_obj_from_capture_id_and_teammate_id_and_contract_type_id(o_openContractCaptureID, o_openContractTeammateID, o_openContractTypeID);
    openExpandedTeammateContractObj.teammateObj = this.create_combined_teammate_obj_from_teammate_id(o_openContractTeammateID);
    return(openExpandedTeammateContractObj);
  }

  get c_captureMap() {
    if(this.c_openTeammateContractObj === undefined) {
      return(undefined);
    }
    return(DatabaseMobx.o_tbl_captures.get(this.o_openContractCaptureID));
  }

  get c_captureFullName() {
    if(this.c_captureMap === undefined) {
      return(undefined);
    }
    return(DatabaseMobx.capture_name_plaintext_from_capture_map(this.c_captureMap));
  }

  get c_captureContractsManagerValueMaskSortIfoCanEditObj() {
    return(DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(this.c_captureMap, DatabaseMobx.c_fieldMapOfContractsManager));
  }

  get c_captureContractRequestedByUserIDIsCurrentlyLoggedInUserTF() {
    const c_openTeammateContractObj = this.c_openTeammateContractObj;
    const o_userID = UserMobx.o_userID;
    if(c_openTeammateContractObj !== undefined) {
      return(c_openTeammateContractObj.requested_by_user_id === o_userID);
    }
    return(false);
  }

  get c_openContractCaptureContractsManagerUserIDsToNotIncludeArray() { //when selecting a contracts manager for this open capture, remove users that would be firewalled off from this capture (if they could be selected, they would get a notification to do work on a capture they can't load in their system)
    return(DatabaseMobx.get_user_ids_array_that_cannot_access_single_capture_from_user_ids_array_and_capture_id(DatabaseMobx.c_userIDsOfAllContractsPowerUsersNotDeactivatedArray, this.o_openContractCaptureID));
  }

  get c_teammateContractChangeLogArrayOfObjs() { //when a_open_teammate_contract() was called, DatabaseMobx.o_tbl_c_log_teammate_contracts was loaded with only change log entries from this capture/teammate/contract type, sorted by date DESC
    const captureID = this.c_openTeammateContractObj.capture_id;
    const teammateID = this.c_openTeammateContractObj.teammate_id;
    const teammateContractTypeID = this.c_openTeammateContractObj.teammate_contract_type_id;

    const mapOfMaps = DatabaseMobx.o_tbl_c_log_teammate_contracts;
    const fieldNamesArray = ["capture_id", "teammate_id", "contract_type_id"];
    const valuesArray = [captureID, teammateID, teammateContractTypeID];
    const sortFieldName = "id";
    const sortIsAscTF = false;
    var teammateContractChangeLogArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(mapOfMaps, fieldNamesArray, valuesArray, sortFieldName, sortIsAscTF);
    for(let teammateContractChangeLogObj of teammateContractChangeLogArrayOfObjs) {
      teammateContractChangeLogObj.dateTimeMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(teammateContractChangeLogObj.datetime_utc, DatabaseMobx.c_genericDateTimeMjYgiANaturalFieldTypeObj);
    }
    return(teammateContractChangeLogArrayOfObjs);
  }

  get c_teammatesContractsFileFolderSystemMapOfMaps() {
    if(this.c_openTeammateContractObj === undefined) { //no need to get files ready unless a contract window has been opened to view the files and send emails
      return(new Map());
    }
    const captureID = this.c_openTeammateContractObj.capture_id;
    const teammateID = this.c_openTeammateContractObj.teammate_id;
    const teammateContractTypeID = this.c_openTeammateContractObj.teammate_contract_type_id;
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_c_teammates_contracts_filefoldersystem, ["capture_id", "teammate_id", "contract_type_id"], [captureID, teammateID, teammateContractTypeID]));
  }

  get c_selectAllContractTemplatesFFSFieldTypeObj() {
    return(DatabaseMobx.create_filefoldersystem_field_type_obj_from_tbl_name(DatabaseMobx.o_tbl_a_teammates_contract_templates_filefoldersystem, "Contract Template", false, true, true));
  }

  get c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj() {
    const o_openContractTypeID = this.o_openContractTypeID;
    const c_openTeammateContractObj = this.c_openTeammateContractObj;
    const o_tbl_a_teammates_contract_templates_filefoldersystem = DatabaseMobx.o_tbl_a_teammates_contract_templates_filefoldersystem;

    if(c_openTeammateContractObj === undefined) {
      return({});
    }

    const contractTemplatesFFSMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(o_tbl_a_teammates_contract_templates_filefoldersystem, "contract_type_id", o_openContractTypeID);
    const itemName = c_openTeammateContractObj.typeShortName + " Contract Template";
    return(DatabaseMobx.create_filefoldersystem_field_type_obj_from_tbl_name(contractTemplatesFFSMapOfMaps, itemName, false, true, true));
  }







  create_combined_teammate_obj_from_teammate_id(i_teammateID) {
    const tblCTeammatesRowMap = DatabaseMobx.tbl_row_map_from_id("tbl_c_teammates", i_teammateID);
    return(this.create_combined_teammate_obj_from_tbl_c_teammates_row_map(tblCTeammatesRowMap));
  }

  create_combined_teammate_obj_from_tbl_c_teammates_row_map(i_tblCTeammatesRowMap) {
    const tblCTeammatesRowObj = JSFUNC.obj_from_map(i_tblCTeammatesRowMap);
    return(this.create_combined_teammate_obj_from_tbl_c_teammates_row_obj(tblCTeammatesRowObj));
  }

  create_combined_teammate_obj_from_tbl_c_teammates_row_obj(i_tblCTeammatesRowObj) {
    const c_teammatesWorkshareTypeFieldDisplayName = DatabaseMobx.c_teammatesWorkshareTypeFieldDisplayName;
    const c_fieldMapOfOurPrimeSubTeammateAllocation = DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateAllocation;
    const c_selectTeammatesWorkshareTypeFieldTypeObj = DatabaseMobx.c_selectTeammatesWorkshareTypeFieldTypeObj;

    var teammateObj = JSFUNC.copy_obj(i_tblCTeammatesRowObj);

    //capture map
    const captureMap = DatabaseMobx.o_tbl_captures.get(teammateObj.capture_id);

    //capture contract overall value
    var captureCovValueRaw = 0;
    if(captureMap !== undefined) {
      captureCovValueRaw = captureMap.get("contract_overall_value");
    }

    //teammate contact company
    var contactCompanyObj = this.teammate_contact_company_obj_from_contact_company_id_and_capture_map(teammateObj.contact_company_id, captureMap);
    teammateObj.contactCompanyObj = contactCompanyObj;
    teammateObj.teammateNamePlainText = ContactsMobx.contact_name_plaintext_from_contact_obj(contactCompanyObj);

    //teammate is us
    teammateObj.isUsTF = (teammateObj.contact_company_id === -2);

    //teammate is prime
    const teammateCaptureIsPrimeTF = DatabaseMobx.capture_type_is_prime_tf_from_capture_map(captureMap);
    teammateObj.isPrimeTF = (teammateObj.prime_01 === 1);
    teammateObj.isPrimeOnSubCaptureTypeTF = (teammateObj.isPrimeTF && !teammateCaptureIsPrimeTF);

    //teammate is selected
    teammateObj.isSelectedTF = (teammateObj.selected_01 === 1);

    //teammate allocation percent formatting
    const allocNumDecimals = c_fieldMapOfOurPrimeSubTeammateAllocation.get("num_decimals"); //for our allocation and teammates allocation, use the number of decimals specified by the capture field our_prime_sub_teammate_allocation
    teammateObj.allocationPercentMask = JSFUNC.round_number_to_num_decimals_if_needed(teammateObj.allocation_percent, allocNumDecimals) + "%";
    teammateObj.allocationOfCOV = captureCovValueRaw * (teammateObj.allocation_percent / 100);
    teammateObj.allocationOfCOVMask = JSFUNC.money_short(teammateObj.allocationOfCOV.toFixed(0));
    teammateObj.allocationDisplayIsLargeTF = (teammateObj.isUsTF || teammateObj.isSelectedTF); //bold font on allocation widget when a teammate is selected as 'on the team'
    
    //teammate workshare type masking
    const workshareTypeMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(teammateObj.workshare_type_id, c_selectTeammatesWorkshareTypeFieldTypeObj);
    teammateObj.workshareTypeMask = workshareTypeMaskSortIfoObj.valueMask;
    teammateObj.workshareTypeMaskPlainText = workshareTypeMaskSortIfoObj.valueMaskPlainText;
    teammateObj.workshareTypeIsFilledOutTF = workshareTypeMaskSortIfoObj.isFilledOutTF;

    var workshareTypeLabel = c_teammatesWorkshareTypeFieldDisplayName + ": ";
    if(workshareTypeMaskSortIfoObj.isFilledOutTF) {
      workshareTypeLabel += workshareTypeMaskSortIfoObj.valueMaskPlainText;
      if(JSFUNC.is_number_not_nan_gt_0(teammateObj.workshare_number_of_ftes)) {
        workshareTypeLabel += " [" + teammateObj.workshare_number_of_ftes + " FTEs]"
      }
    }
    else {
      workshareTypeLabel += "-";
    }
    teammateObj.workshareTypeLabel = workshareTypeLabel;

    //teammate contact company business type size
    teammateObj.businessTypeIsSmallTF = contactCompanyObj.businessTypeIsSmallTF;
    teammateObj.businessTypeIsLargeTF = contactCompanyObj.businessTypeIsLargeTF;
    teammateObj.businessTypeIsInvalidTF = contactCompanyObj.businessTypeIsInvalidTF;

    var usLbSbIvPcFlag = undefined;
    if(teammateObj.isUsTF) { usLbSbIvPcFlag = "us"; }
    else if(teammateObj.isPrimeTF) { usLbSbIvPcFlag = "pc"; }
    else if(teammateObj.businessTypeIsLargeTF) { usLbSbIvPcFlag = "lb"; }
    else if(teammateObj.businessTypeIsSmallTF) { usLbSbIvPcFlag = "sb"; }
    else { usLbSbIvPcFlag = "iv"; }
    teammateObj.usLbSbIvPcFlag = usLbSbIvPcFlag;

    var businessSizeLabel = undefined;
    var businessSizeLabelShort = undefined;
    if(teammateObj.businessTypeIsLargeTF) {
      businessSizeLabel = "Large Business";
      businessSizeLabelShort = "LB";
    }
    else if(teammateObj.businessTypeIsSmallTF) {
      businessSizeLabel = "Small Business";
      businessSizeLabelShort = "SB";
    }
    else {
      businessSizeLabel = "Invalid Business Size";
      businessSizeLabelShort = "--";
    }
    teammateObj.businessSizeLabel = businessSizeLabel;
    teammateObj.businessSizeLabelShort = businessSizeLabelShort;

    //teammate sb certifications
    teammateObj.teammateSBCertificationsBmSetAsideIDsArray = JSFUNC.convert_comma_list_to_int_array(contactCompanyObj.sb_certifications_bm_set_aside_ids_comma);
    teammateObj.sbCertificationsMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(contactCompanyObj.sb_certifications_bm_set_aside_ids_comma, DatabaseMobx.c_selectMultiBitMasterSetAsidesShortNamesFieldTypeObj);

    //teammate capabilities
    teammateObj.capabilitiesMask = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(contactCompanyObj.capability_ids_comma, DatabaseMobx.c_selectMultiCapabilitiesFieldTypeObj);

    //teammate contact persons
    teammateObj.contactPersonIDsArray = JSFUNC.convert_comma_list_to_int_array(teammateObj.contact_person_ids_comma);

    return(teammateObj);
  }

  teammate_contact_company_obj_from_contact_company_id_and_capture_id(i_contactCompanyIDOrMinus2ForOurDivision, i_captureID) {
    const captureMap = DatabaseMobx.o_tbl_captures.get(i_captureID);
    return(this.teammate_contact_company_obj_from_contact_company_id_and_capture_map(i_contactCompanyIDOrMinus2ForOurDivision, captureMap));
  }

  teammate_contact_company_obj_from_contact_company_id_and_capture_map(i_contactCompanyIDOrMinus2ForOurDivision, i_captureMap) {
    var contactCompanyObj = {};
    if(i_contactCompanyIDOrMinus2ForOurDivision === -2) {
      var ourPrimeSubTeammateDivisionNamePlainText = undefined;
      var businessTypeID = -1;
      var sbCertificationsBmSetAsideIDsComma = "";
      if(i_captureMap !== undefined) {
        const ourPrimeSubTeammateDivisionID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateDivisionID);
        const divisionMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_divisions", ourPrimeSubTeammateDivisionID);
        ourPrimeSubTeammateDivisionNamePlainText = divisionMap.get("name");
        businessTypeID = divisionMap.get("business_type_id");
        sbCertificationsBmSetAsideIDsComma = divisionMap.get("prime_sb_certifications_bm_set_aside_ids_comma");
      }
      else {
        ourPrimeSubTeammateDivisionNamePlainText = "--Capture Does Not Exist--";
      }

      //return a contact company obj with some of the fields overwritten and all the others (and custom ones) left undefined
      contactCompanyObj = {
        id: -2,
        name: ourPrimeSubTeammateDivisionNamePlainText,
        legal_name: ourPrimeSubTeammateDivisionNamePlainText,
        abbreviated_name: ourPrimeSubTeammateDivisionNamePlainText,
        abbrOrLegalName: ourPrimeSubTeammateDivisionNamePlainText,
        business_type_id: businessTypeID,
        sb_certifications_bm_set_aside_ids_comma: sbCertificationsBmSetAsideIDsComma
      };
    }
    else { //return the full contact company obj from the contacts system
      const isPersonTF = false;
      contactCompanyObj = ContactsMobx.contact_company_or_person_obj_from_id(isPersonTF, i_contactCompanyIDOrMinus2ForOurDivision);
    }

    return(contactCompanyObj);
  }



  expanded_teammate_contract_obj_from_capture_id_and_teammate_id_and_contract_type_id(i_captureID, i_teammateID, i_teammateContractTypeID) {
    //teammate contract fields once the request process is started (does not exist if not yet requested, starts at "pns" for status) for this contract type (NDA, TA, SubK) and teammateID
    const teammateContractMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_c_teammates_contracts, ["capture_id", "teammate_id", "teammate_contract_type_id"], [i_captureID, i_teammateID, i_teammateContractTypeID]);

    var expandedTeammateContractObj = undefined;
    if(teammateContractMap === undefined) { //the process has not been started yet
      expandedTeammateContractObj = {
        id: -1,
        capture_id: i_captureID,
        teammate_id: i_teammateID,
        teammate_contract_type_id: i_teammateContractTypeID,
        agreement_type_id: -1,
        status_id: this.c_statusProcessNotStartedObj.id, //if the teammate contract record does not exist, it is assumed the process is not yet started
        requested_by_user_id: -1,
        date_requested: JSFUNC.blank_date(),
        date_needed: JSFUNC.blank_date(),
        date_completed: JSFUNC.blank_date()
      };
    }
    else { //the contract record exists, thus the process has previously been started
      expandedTeammateContractObj = JSFUNC.obj_from_map(teammateContractMap); //this includes the capture_id
    }

    //teammate contract type fields (id, name, short_name, admin_sort), look up from tbl_a_teammates_contract_types using i_teammateContractTypeID
    const contractTypeMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_types", i_teammateContractTypeID);
    expandedTeammateContractObj.typeName = contractTypeMap.get("name");
    expandedTeammateContractObj.typeShortName = contractTypeMap.get("short_name");
    expandedTeammateContractObj.typeNameAndShortName = expandedTeammateContractObj.typeName + " (" + expandedTeammateContractObj.typeShortName + ")"; //create a longer contract type name combining the name and short_name

    //teammate contract status
    const statusMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_statuses", expandedTeammateContractObj.status_id);
    expandedTeammateContractObj.statusName = statusMap.get("name");
    expandedTeammateContractObj.statusColor = statusMap.get("color");
    expandedTeammateContractObj.statusIsProcessNotStartedTF = (expandedTeammateContractObj.status_id === this.c_statusProcessNotStartedObj.id);
    expandedTeammateContractObj.statusIsCancelledTF = (expandedTeammateContractObj.status_id === this.c_statusCancelledObj.id);
    expandedTeammateContractObj.statusIsGenerateSendAgreementTF = (expandedTeammateContractObj.status_id === this.c_statusGenerateSendAgreementObj.id);
    expandedTeammateContractObj.statusIsFullyExecutedTF = (expandedTeammateContractObj.status_id === this.c_statusFullyExecutedObj.id);
    
    expandedTeammateContractObj.statusIsActiveTF = (!expandedTeammateContractObj.statusIsProcessNotStartedTF && !expandedTeammateContractObj.statusIsCancelledTF && !expandedTeammateContractObj.statusIsFullyExecutedTF);

    //need by date masking
    expandedTeammateContractObj.maskDateNeededDayMdyDaysUntil1 = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(expandedTeammateContractObj.date_needed, DatabaseMobx.c_genericDateDayMdyDaysUntil1FieldTypeObj);
    expandedTeammateContractObj.maskDateNeededDayMdyDaysUntil1Overdue = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(expandedTeammateContractObj.date_needed, DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj);

    var needByDateMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(expandedTeammateContractObj.date_needed,  DatabaseMobx.c_genericDateFieldTypeObj);
    expandedTeammateContractObj.maskDateNeeded = needByDateMaskSortIfoObj.valueMask;
    expandedTeammateContractObj.maskDateNeededPlainText = needByDateMaskSortIfoObj.valueMaskPlainText;

    return(expandedTeammateContractObj);
  }



  create_todo_contracts_data_obj_from_contracts_filter_flag(i_captureContractsFilterFlag) {
    //get stageIDs for all active and won captures
    const allActiveAndWonStageIDsArray = DatabaseMobx.c_activeAndClosedWonStageIDsArray;

    //initialize the output data obj
    var dataObj = {
      numContracts: -1,
      viewFilterName: "",
      sectionNamesArray: [],
      sectionContractsArrayOfArrayOfObjs: []
    };

    //different categories for each filter type
    if(i_captureContractsFilterFlag === "activeWonCapturesInProcessContracts") {
      var inProcessStatusIDsArray = [this.c_statusGenerateSendAgreementObj.id];
      for(let statusObj of this.c_statusesTeammateIsReviewingArrayOfObjs) {
        inProcessStatusIDsArray.push(statusObj.id);
      }
      for(let statusObj of this.c_statusesWeAreReviewingArrayOfObjs) {
        inProcessStatusIDsArray.push(statusObj.id);
      }

      var overdueCAOO = [];
      var todayCAOO = [];
      var tomorrowCAOO = [];
      var weekCAOO = [];
      var longerCAOO = [];
      for(let expandedTeammateContractObj of this.c_todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs) {
        //verify that this contract is in process (not fully executed or cancelled)
        if(!(expandedTeammateContractObj.statusIsFullyExecutedTF || expandedTeammateContractObj.statusIsCancelledTF)) {
          //verify the capture stage is active/won
          var captureMap = DatabaseMobx.o_tbl_captures.get(expandedTeammateContractObj.capture_id);
          if(captureMap !== undefined) {
            var stageID = captureMap.get("stage_id");
            if(JSFUNC.in_array(stageID, allActiveAndWonStageIDsArray)) {
              //indicate that the overdue box is included when displaying the need by date in the todo item
              expandedTeammateContractObj.needByDateIncludeOverdueTF = true;

              //split the contract into the date needed time bucket categories
              var numDaysUntilDateNeeded = JSFUNC.num_days_from_date1_to_date2(CaptureExecMobx.o_nowDate, expandedTeammateContractObj.date_needed);
              if(numDaysUntilDateNeeded < 0) {
                overdueCAOO.push(expandedTeammateContractObj);
              }
              else if(numDaysUntilDateNeeded === 0) {
                todayCAOO.push(expandedTeammateContractObj);
              }
              else if(numDaysUntilDateNeeded === 1) {
                tomorrowCAOO.push(expandedTeammateContractObj);
              }
              else if(numDaysUntilDateNeeded <= 7) {
                weekCAOO.push(expandedTeammateContractObj);
              }
              else {
                longerCAOO.push(expandedTeammateContractObj);
              }
            }
          }
        }
      }
      dataObj.viewFilterName = this.c_activeWonCapturesInProcessContractsFilterButtonName;
      dataObj.sectionNamesArray = ["Overdue", "Due Today", "Due Tomorrow", "Due within a Week", "Longer than a Week"];
      dataObj.sectionContractsArrayOfArrayOfObjs = [overdueCAOO, todayCAOO, tomorrowCAOO, weekCAOO, longerCAOO];
    }
    else if(i_captureContractsFilterFlag === "activeWonCapturesFullyExecutedCancelledContracts") {
      var feaContractsArrayOfObjs = [];
      var cContractsArrayOfObjs = [];
      for(let expandedTeammateContractObj of this.c_todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs) {
        //verify the contract status is fully executed or cancelled
        if(expandedTeammateContractObj.statusIsFullyExecutedTF || expandedTeammateContractObj.statusIsCancelledTF) {
          //verify the capture stage is active/won
          var captureMap = DatabaseMobx.o_tbl_captures.get(expandedTeammateContractObj.capture_id);
          if(captureMap !== undefined) {
            var stageID = captureMap.get("stage_id");
            if(JSFUNC.in_array(stageID, allActiveAndWonStageIDsArray)) {
              //indicate that the overdue box is not included when displaying the need by date in the todo item
              expandedTeammateContractObj.needByDateIncludeOverdueTF = false;

              //split the contracts into fully executed or cancelled categories
              if(expandedTeammateContractObj.statusIsFullyExecutedTF) {
                feaContractsArrayOfObjs.push(expandedTeammateContractObj);
              }
              else if(expandedTeammateContractObj.statusIsCancelledTF) {
                cContractsArrayOfObjs.push(expandedTeammateContractObj);
              }
            }
          }
        }
      }
      dataObj.viewFilterName = this.c_activeWonCapturesFullyExecutedCancelledContractsFilterButtonName;
      dataObj.sectionNamesArray = [this.c_statusFullyExecutedObj.name + " Agreements", this.c_statusCancelledObj.name + " Agreements"];
      dataObj.sectionContractsArrayOfArrayOfObjs = [feaContractsArrayOfObjs, cContractsArrayOfObjs];
    }
    else if(i_captureContractsFilterFlag === "lostNoBidCancelledCapturesAllContracts") {
      var allContractsInLostNoBidCancelledCapturesArrayOfObjs = [];
      for(let expandedTeammateContractObj of this.c_todoExpandedTeammateContractsFromCapturesAssignedToUserArrayOfObjs) {
        //verify the capture stage is lost, no bid, or cancelled
        var captureMap = DatabaseMobx.o_tbl_captures.get(expandedTeammateContractObj.capture_id);
        if(captureMap !== undefined) {
          var stageID = captureMap.get("stage_id");
          if(!JSFUNC.in_array(stageID, allActiveAndWonStageIDsArray)) {
            //indicate that the overdue box is not included when displaying the need by date in the todo item
            expandedTeammateContractObj.needByDateIncludeOverdueTF = false;

            allContractsInLostNoBidCancelledCapturesArrayOfObjs.push(expandedTeammateContractObj);
          }
        }
      }
      dataObj.viewFilterName = this.c_lostNoBidCancelledCapturesAllContractsFilterButtonName;
      dataObj.sectionNamesArray = ["All Agreements in Lost, No Bid, or Cancelled Captures"];
      dataObj.sectionContractsArrayOfArrayOfObjs = [allContractsInLostNoBidCancelledCapturesArrayOfObjs];
    }

    //compute number of contracts from all sections
    var numContracts = 0;
    for(let s = 0; s < dataObj.sectionContractsArrayOfArrayOfObjs.length; s++) {
      numContracts += dataObj.sectionContractsArrayOfArrayOfObjs[s].length;
    }
    dataObj.numContracts = numContracts;

    return(dataObj);
  }





  //========================================================================================
  //action methods
  //contractsexec workload and contracts todo
  a_contractsexec_workload_and_contracts_todo_set_capture_contracts_filter_flag(i_newValue) {
    this.o_captureContractsFilterFlag = i_newValue;
  }



  //contractsexec workload
  a_exec_toggle_expand_collapse_user(i_userID) {
    const removedTF = this.o_execExpandedUserIDsArray.remove(i_userID);
    if(!removedTF) {
      this.o_execExpandedUserIDsArray.push(i_userID);
    }
  }

  a_exec_set_selected_reassign_contracts_user_id(i_selectedContractsUserID) {
    this.o_execSelectedReassignContractsUserID = i_selectedContractsUserID;
  }

  a_exec_reassign_checked_captures_to_selected_contracts_user(i_execCheckedCaptureIDsArray, i_execSelectedReassignContractsUserID) {
    var captureIDsNotAccessibleByNewContractsUserArray = [];

    for(let checkedCaptureID of i_execCheckedCaptureIDsArray) {
      var captureMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_captures, "id", checkedCaptureID);
      if(captureMap !== undefined) {
        //verify if this user has access to this capture
        var newContractsManagerCanAccessCaptureTF = DatabaseMobx.user_can_access_capture_tf_from_capture_map_and_user_id(captureMap, i_execSelectedReassignContractsUserID);
        if(newContractsManagerCanAccessCaptureTF) { //new contracts manager can access this capture
          //get old contracts manager user id for this capture to avoid updating the database with the same value
          var oldContractsUserID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfContractsManager);
          if(i_execSelectedReassignContractsUserID !== oldContractsUserID) {
            //update this capture with the new contracts manager user id
            OpenCaptureMobx.a_details_update_field_value(DatabaseMobx.c_fieldMapOfContractsManager, i_execSelectedReassignContractsUserID, captureMap);
          }
        }
        else { //new contracts manager can't access this capture (division firewalling restriction), don't reassign and collect the capture name
          captureIDsNotAccessibleByNewContractsUserArray.push(checkedCaptureID);
        }
      }
    }

    //reset the check marks all to blank
    this.o_execCheckedCaptureIDsArray.replace([]);

    return(captureIDsNotAccessibleByNewContractsUserArray);
  }

  a_exec_user_check_or_uncheck_all_captures(i_capturesArrayOfObjs) {
    const oldCheckedCaptureIDsArray = this.o_execCheckedCaptureIDsArray.slice();
    var allCapturesCheckedTF = true;
    var userCaptureIDsArray = [];
    for(let captureObj of i_capturesArrayOfObjs) {
      if(!JSFUNC.in_array(captureObj.id, oldCheckedCaptureIDsArray)) {
        allCapturesCheckedTF = false;
      }
      userCaptureIDsArray.push(captureObj.id);
    }

    var newCheckedCaptureIDsArray = [];
    if(allCapturesCheckedTF) {
      for(let oldCaptureID of oldCheckedCaptureIDsArray) {
        if(!JSFUNC.in_array(oldCaptureID, userCaptureIDsArray)) {
          newCheckedCaptureIDsArray.push(oldCaptureID);
        }
      }
    }
    else {
      newCheckedCaptureIDsArray = JSFUNC.merge_unique(oldCheckedCaptureIDsArray, userCaptureIDsArray);
    }

    this.o_execCheckedCaptureIDsArray.replace(newCheckedCaptureIDsArray);
  }

  a_exec_check_or_uncheck_capture(i_captureID) {
    const removedTF = this.o_execCheckedCaptureIDsArray.remove(i_captureID);
    if(!removedTF) {
      this.o_execCheckedCaptureIDsArray.push(i_captureID);
    }
  }




  //teammate contract floating box
  a_open_teammate_contract(i_captureID, i_teammateID, i_teammateContractTypeID) {
    const c_userCanManageTeammateContractsProcessTF = UserMobx.c_userCanManageTeammateContractsProcessTF;

    this.o_openContractFromContractsTodoTF = c_userCanManageTeammateContractsProcessTF;
    this.o_openContractCaptureID = i_captureID;
    this.o_openContractTeammateID = i_teammateID;
    this.o_openContractTypeID = i_teammateContractTypeID;
    this.o_contractProcessTabOpen = ((c_userCanManageTeammateContractsProcessTF) ? ("sendEmail") : ("history"));

    //load the teammate contract changelog for this specific capture/teammate/contract type
    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_open_teammate_contract", ["i_captureID", "i_teammateID", "i_teammateContractTypeID"], [i_captureID, i_teammateID, i_teammateContractTypeID]);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadSingleTeammateContractChangeLogData", jsDescription);

    C_CallPhpScript.add_post_var("i_captureID", i_captureID);
    C_CallPhpScript.add_post_var("i_teammateID", i_teammateID);
    C_CallPhpScript.add_post_var("i_teammateContractTypeID", i_teammateContractTypeID);
    C_CallPhpScript.add_return_vars("teammateContractChangeLogMatrix");

    const functionOnSuccess = (i_parseResponse) => {
      const clearOldMapDataFirstTF = true;
      DatabaseMobx.a_insert_or_update_local_data_map("tbl_c_log_teammate_contracts", i_parseResponse.teammateContractChangeLogMatrix, clearOldMapDataFirstTF, jsDescription);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
  }


  a_close_teammate_contract() {
    this.o_openContractFromContractsTodoTF = false;
    this.o_openContractCaptureID = undefined;
    this.o_openContractTeammateID = undefined;
    this.o_openContractTypeID = undefined;
    this.o_contractProcessTabOpen = "sendEmail";

    //clear local teammate contract changelog
    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_close_teammate_contract", [], []);
    DatabaseMobx.a_clear_local_tbls_from_tbl_names_array(["tbl_c_log_teammate_contracts"], jsDescription)
  }


  a_set_mobile_advance_status_tf(i_newValueTF) {
    this.o_mobileAdvancingStatusTF = i_newValueTF;
  }

  a_set_contract_process_tab_open(i_tab) {
    this.o_contractProcessTabOpen = i_tab;
  }


  a_create_or_update_contract_from_pns_or_c_to_gsa(i_selectedNeedByDate, i_selectedAgreementTypeID, i_createRequestCommentText) {
    //capture manager creates or restarts contract process from PNS (process not started) or Cancelled to Generate/Send Agreement status for selected contracts manager
    const o_openContractCaptureID = this.o_openContractCaptureID;
    const o_openContractTeammateID = this.o_openContractTeammateID;
    const o_openContractTypeID = this.o_openContractTypeID;
    const c_openTeammateContractObj = this.c_openTeammateContractObj;
    const c_statusGenerateSendAgreementObj = this.c_statusGenerateSendAgreementObj;
    const c_captureContractsManagerValueMaskSortIfoCanEditObj = this.c_captureContractsManagerValueMaskSortIfoCanEditObj;
    const o_userID = UserMobx.o_userID;
    const c_userName = UserMobx.c_userName;

    if(c_openTeammateContractObj === undefined) {
      return;
    }

    const maskSelectedNeedByDatePlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_selectedNeedByDate, DatabaseMobx.c_genericDateFieldTypeObj);
    const commentIsFilledOutTF = JSFUNC.string_is_filled_out_tf(i_createRequestCommentText);

    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_create_or_update_contract_from_pns_or_c_to_gsa", ["i_selectedNeedByDate", "i_selectedAgreementTypeID", "i_createRequestCommentText"], [i_selectedNeedByDate, i_selectedAgreementTypeID, i_createRequestCommentText]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //insert/update teammate contract record with new status
    const whereFieldNamesArray = ["capture_id", "teammate_id", "teammate_contract_type_id"];
    const whereValuesArray = [o_openContractCaptureID, o_openContractTeammateID, o_openContractTypeID];
    const whereValueIdsbArray = ["i", "i", "i"];
    const fieldNamesArray = ["agreement_type_id", "status_id", "requested_by_user_id", "date_requested", "date_needed", "date_completed"];
    const valuesArray = [i_selectedAgreementTypeID, c_statusGenerateSendAgreementObj.id, o_userID, JSFUNC.now_date(), i_selectedNeedByDate, JSFUNC.blank_date()];
    const isdbArray = ["i", "i", "i", "s", "s", "s"];
    C_CallPhpTblUID.add_update_existing_otherwise_insert("tbl_c_teammates_contracts", whereFieldNamesArray, whereValuesArray, whereValueIdsbArray, fieldNamesArray, valuesArray, isdbArray);

    //teammate changelog entry (process history)
    const changeLogValue = "Need by date of " + maskSelectedNeedByDatePlainText + " requested to execute " + c_openTeammateContractObj.typeNameAndShortName + " for teammate '" + c_openTeammateContractObj.teammateObj.teammateNamePlainText + "', assigned to " + c_captureContractsManagerValueMaskSortIfoCanEditObj.valueMaskPlainText + " in Contracts Dept";
    C_CallPhpTblUID.add_changelog_teammate_contracts(o_openContractCaptureID, o_openContractTeammateID, o_openContractTypeID, "Initiate Contract Request", changeLogValue);

    //make another process history record for the comment if provided
    if(commentIsFilledOutTF) {
      C_CallPhpTblUID.add_changelog_teammate_contracts(o_openContractCaptureID, o_openContractTeammateID, o_openContractTypeID, "Comment from Capture Manager", i_createRequestCommentText);
    }

    //add a notification to the contracts manager assigned
    const contractsManagerUserID = c_captureContractsManagerValueMaskSortIfoCanEditObj.valueRaw;
    var notificationMessage = "New Teammate Contract request";
    notificationMessage += "\n\nRequested By: " + c_userName;
    notificationMessage += "\n" + this.teammate_contract_notification_contract_info_lines(i_selectedNeedByDate);
    if(commentIsFilledOutTF) {
      notificationMessage += "\n\nComment: " + i_createRequestCommentText;
    }
    const notificationClickAction = this.create_teammate_contracts_notification_click_action_string(true);
    C_CallPhpTblUID.add_notifications_with_emails(contractsManagerUserID, false, notificationMessage, notificationClickAction);

    C_CallPhpTblUID.execute();
  }

  a_update_teammate_contract_status(i_newStatusObj, i_contractsManagerStatusChangeNotes) {
    //contracts manager can change/cancel status, capture manager can just cancel status
    const o_openContractFromContractsTodoTF = this.o_openContractFromContractsTodoTF;

    if(this.c_openTeammateContractObj === undefined) {
      return;
    }

    const statusChangeNoteIsFilledOutTF = JSFUNC.string_is_filled_out_tf(i_contractsManagerStatusChangeNotes);
    const processCancelledTF = (i_newStatusObj.id === this.c_statusCancelledObj.id);

    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_update_teammate_contract_status", ["i_newStatusObj", "i_contractsManagerStatusChangeNotes"], [i_newStatusObj, i_contractsManagerStatusChangeNotes]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var fieldNamesArray = ["status_id"];
    var valuesArray = [i_newStatusObj.id]; //change the status to the new statusID
    var idsbArray = ["i"];
    if(i_newStatusObj.id === this.c_statusFullyExecutedObj.id) { //if moving to fully executed, set the completed date
      fieldNamesArray.push("date_completed");
      valuesArray.push(JSFUNC.now_date());
      idsbArray.push("s");
    }
    C_CallPhpTblUID.add_update("tbl_c_teammates_contracts", this.c_openTeammateContractObj.id, fieldNamesArray, valuesArray, idsbArray);

    //changelog entry
    var changeLogAction = "Advance Contract Status";
    if(processCancelledTF) {
      changeLogAction = "Contract Process Cancelled";
    }
    else if(this.c_openTeammateContractObj.statusIsCancelledTF) { //if the status was changed to GSA from cancelled, show different message
      changeLogAction = "Cancelled Contract Process Restarted";
    }
    var changeLogValue = "Status changed from '" + this.c_openTeammateContractObj.statusName + "' to '" + i_newStatusObj.name + "'";
    if(statusChangeNoteIsFilledOutTF) {
      changeLogValue += " [Note: '" + i_contractsManagerStatusChangeNotes + "']";
    }
    C_CallPhpTblUID.add_changelog_teammate_contracts(this.o_openContractCaptureID, this.o_openContractTeammateID, this.o_openContractTypeID, changeLogAction, changeLogValue);

    //add a notification to the contracts manager assigned
    const notifyUserIDsArray = this.teammate_contract_notification_send_to_user_ids_array();
    var notificationMessage = "";
    if(processCancelledTF) { //if moving to cancelled, have a different message line
      notificationMessage = "A requested Teammate Contract process has been Cancelled";
      notificationMessage += "\n\nCancelled By: " + UserMobx.c_userName;
    }
    else {
      notificationMessage = "Teammate Contract Status updated from '" + this.c_openTeammateContractObj.statusName + "' to '" + i_newStatusObj.name + "'";
      notificationMessage += "\n\nChanged By: " + UserMobx.c_userName;
    }
    notificationMessage += "\n" + this.teammate_contract_notification_contract_info_lines();
    if(statusChangeNoteIsFilledOutTF) {
      notificationMessage += "\nNote: " + i_contractsManagerStatusChangeNotes;
    }
    const notificationClickAction = this.create_teammate_contracts_notification_click_action_string(!o_openContractFromContractsTodoTF);
    C_CallPhpTblUID.add_notifications_with_emails(notifyUserIDsArray, false, notificationMessage, notificationClickAction);

    C_CallPhpTblUID.execute();
  }


  a_update_teammate_contract_need_by_date(i_oldNeedByDate, i_newNeedByDate) {
    const maskedOldNeedByDate = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_oldNeedByDate, DatabaseMobx.c_genericDateFieldTypeObj);
    const maskedNewNeedByDate = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_newNeedByDate, DatabaseMobx.c_genericDateFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_update_teammate_contract_need_by_date", ["i_oldNeedByDate", "i_newNeedByDate"], [i_oldNeedByDate, i_newNeedByDate]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_update("tbl_c_teammates_contracts", this.c_openTeammateContractObj.id, "date_needed", i_newNeedByDate, "s");

    C_CallPhpTblUID.add_changelog_teammate_contracts(this.o_openContractCaptureID, this.o_openContractTeammateID, this.o_openContractTypeID, "Changed Need By Date", "Need By Date changed from " + maskedOldNeedByDate + " to " + maskedNewNeedByDate + " by " + UserMobx.c_userName);

    //add a notification to the contracts manager assigned
    const contractsManagerUserID = this.c_captureContractsManagerValueMaskSortIfoCanEditObj.valueRaw;
    var notificationMessage = "Teammate Contract Need By Date has been changed from '" + maskedOldNeedByDate + "' to '" + maskedNewNeedByDate + "'";
    notificationMessage += "\n\nChanged By: " + UserMobx.c_userName;
    notificationMessage += "\n" + this.teammate_contract_notification_contract_info_lines(i_newNeedByDate);
    const notificationClickAction = this.create_teammate_contracts_notification_click_action_string(true);
    C_CallPhpTblUID.add_notifications_with_emails(contractsManagerUserID, false, notificationMessage, notificationClickAction);

    C_CallPhpTblUID.execute();
  }

  a_update_teammate_contract_agreement_type(i_oldAgreementTypeID, i_newAgreementTypeID) {
    const o_openContractFromContractsTodoTF = this.o_openContractFromContractsTodoTF;

    const maskedOldAgreementType = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_oldAgreementTypeID, DatabaseMobx.c_selectTeammatesContractAgreementTypeFieldTypeObj);
    const maskedNewAgreementType = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_newAgreementTypeID, DatabaseMobx.c_selectTeammatesContractAgreementTypeFieldTypeObj);
    
    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_update_teammate_contract_agreement_type", ["i_oldAgreementTypeID", "i_newAgreementTypeID"], [i_oldAgreementTypeID, i_newAgreementTypeID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    
    C_CallPhpTblUID.add_update("tbl_c_teammates_contracts", this.c_openTeammateContractObj.id, "agreement_type_id", i_newAgreementTypeID, "i");
    
    C_CallPhpTblUID.add_changelog_teammate_contracts(this.o_openContractCaptureID, this.o_openContractTeammateID, this.o_openContractTypeID, "Changed Agreement Type", "Agreement Type changed from " + maskedOldAgreementType + " to " + maskedNewAgreementType + " by " + UserMobx.c_userName);
    
    //add a notification to the contracts manager assigned
    if(!o_openContractFromContractsTodoTF) { //only send notification if the capture manager made this change
      const contractsManagerUserID = this.c_captureContractsManagerValueMaskSortIfoCanEditObj.valueRaw;
      var notificationMessage = "Teammate Contract Agreement Type has been changed from '" + maskedOldAgreementType + "' to '" + maskedNewAgreementType + "'";
      notificationMessage += "\n\nChanged By: " + UserMobx.c_userName;
      notificationMessage += "\n" + this.teammate_contract_notification_contract_info_lines();
      const notificationClickAction = this.create_teammate_contracts_notification_click_action_string(true);
      C_CallPhpTblUID.add_notifications_with_emails(contractsManagerUserID, false, notificationMessage, notificationClickAction);
    }
    
    C_CallPhpTblUID.execute();
  }

  a_contracts_generate_upload_contract_from_template_add_process_history(i_generateContractUploadSuccessMessage) {
    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_contracts_generate_upload_contract_from_template_add_process_history", ["i_generateContractUploadSuccessMessage"], [i_generateContractUploadSuccessMessage]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_changelog_teammate_contracts(this.o_openContractCaptureID, this.o_openContractTeammateID, this.o_openContractTypeID, "Generated Contract from Template", i_generateContractUploadSuccessMessage);
    C_CallPhpTblUID.execute();
  }

  a_contracts_add_note_to_process_history(i_noteText) {
    const o_openContractFromContractsTodoTF = this.o_openContractFromContractsTodoTF;

    if(JSFUNC.string_is_filled_out_tf(i_noteText)) {
      const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_contracts_add_note_to_process_history", ["i_noteText"], [i_noteText]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      var historySubject = "Note from Capture Manager";
      if(o_openContractFromContractsTodoTF) { //if contracts person sent message, notify all capture managers
        historySubject = "Note from Contracts Manager";
      }

      C_CallPhpTblUID.add_changelog_teammate_contracts(this.o_openContractCaptureID, this.o_openContractTeammateID, this.o_openContractTypeID, historySubject, "'" + i_noteText + "'");

      const notifyUserIDsArray = this.teammate_contract_notification_send_to_user_ids_array();
      var notificationMessage = historySubject + " regarding a Teammate Contract";
      notificationMessage += "\n\nNote from: " + UserMobx.c_userName;
      notificationMessage += "\n" + this.teammate_contract_notification_contract_info_lines();
      notificationMessage += "\nNote: '" + i_noteText + "'";
      const notificationClickAction = this.create_teammate_contracts_notification_click_action_string(!o_openContractFromContractsTodoTF);
      C_CallPhpTblUID.add_notifications_with_emails(notifyUserIDsArray, false, notificationMessage, notificationClickAction);
      
      C_CallPhpTblUID.execute();
    }
  }

  a_set_selected_contract_template_id(i_contractTemplateID) {
    this.o_selectedContractTemplateID = i_contractTemplateID;
  }


  a_set_teammate_contracts_manager_contact_person(i_contactPersonID) {
    if(this.c_openTeammateContractObj === undefined) {
      return;
    }

    const contactPersonName = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_contactPersonID, DatabaseMobx.c_selectContactPersonFieldTypeObj);

    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_set_teammate_contracts_manager_contact_person", ["i_contactPersonID"], [i_contactPersonID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_c_teammates", this.o_openContractTeammateID, "teammate_contracts_manager_contact_person_id", i_contactPersonID, "i");
    C_CallPhpTblUID.add_changelog_teammate_contracts(this.o_openContractCaptureID, this.o_openContractTeammateID, this.o_openContractTypeID, "Set Teammate Contracts Manager", contactPersonName);
    C_CallPhpTblUID.execute();
  }

  a_send_notification_after_files_uploads(i_actionText, i_descriptionText) {
    const o_openContractFromContractsTodoTF = this.o_openContractFromContractsTodoTF;
    const c_captureContractsManagerValueMaskSortIfoCanEditObj = this.c_captureContractsManagerValueMaskSortIfoCanEditObj;

    const contractsManagerUserID = c_captureContractsManagerValueMaskSortIfoCanEditObj.valueRaw; //contracts manager userID, capture manager made this change, notification is sent to contracts manager, to be opened by contracts manager

    const jsDescription = JSFUNC.js_description_from_action("TeammateContractsMobx", "a_send_notification_after_files_uploads", ["i_actionText", "i_descriptionText"], [i_actionText, i_descriptionText]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //process history changelog for file upload from either contracts or capture
    C_CallPhpTblUID.add_changelog_teammate_contracts(this.o_openContractCaptureID, this.o_openContractTeammateID, this.o_openContractTypeID, i_actionText, i_descriptionText);

    //only notify contracts manager if capture manager uploads/deletes a file
    if(!o_openContractFromContractsTodoTF) {
      var notificationMessage = UserMobx.c_userName + " has made changes to the File/Folder system of a Teammate Contract request";
      notificationMessage += "\n\n" + this.teammate_contract_notification_contract_info_lines();
      notificationMessage += "\nAction: " + i_actionText;
      notificationMessage += "\n" + i_descriptionText;
      const notificationClickAction = this.create_teammate_contracts_notification_click_action_string(true);
      C_CallPhpTblUID.add_notifications_with_emails(contractsManagerUserID, false, notificationMessage, notificationClickAction);
    }

    C_CallPhpTblUID.execute();
  }






  a_metrics_set_filter_division_ids_comma_or_all(i_divisionIDsCommaOrAll) {
    this.o_metricsFilterDivisionIDsCommaOrAll = i_divisionIDsCommaOrAll;
  }

  a_metrics_set_filter_contracts_user_ids_comma_or_all(i_contractsUserIDsCommaOrAll) {
    this.o_metricsFilterContractsUserIDsCommaOrAll = i_contractsUserIDsCommaOrAll;
  }









  create_teammate_contracts_notification_click_action_string(i_openAsContractsManagerTF) {
    return(((i_openAsContractsManagerTF) ? ("openTeammateContractAsContractsManager") : ("openTeammateContractAsCapture")) + ":c" + this.o_openContractCaptureID + ",t" + this.o_openContractTeammateID + ",ct" + this.o_openContractTypeID);
  }


  teammate_contract_notification_send_to_user_ids_array() {
    const o_openContractFromContractsTodoTF = this.o_openContractFromContractsTodoTF;
    if(o_openContractFromContractsTodoTF) {
      return(DatabaseMobx.capture_manager_user_ids_array_from_capture_id(this.o_openContractCaptureID)); //contracts person sends notifications to all capture managers
    }
    return(this.c_captureContractsManagerValueMaskSortIfoCanEditObj.valueRaw); //capture manager sends notification to contracts manager
  }


  teammate_contract_notification_contract_info_lines(i_newNeedByDateValueRaw=undefined) {
    const c_openTeammateContractObj = this.c_openTeammateContractObj;
    const c_captureFullName = this.c_captureFullName;

    if((c_openTeammateContractObj === undefined) || (c_captureFullName === undefined)) {
      return("");
    }

    var needByDateValueMaskPlainText = c_openTeammateContractObj.maskDateNeededPlainText;
    if(i_newNeedByDateValueRaw !== undefined) {
      needByDateValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_newNeedByDateValueRaw, DatabaseMobx.c_genericDateFieldTypeObj);
    }

    var contractInfoLines = "Contract Type: " + c_openTeammateContractObj.typeShortName;
    contractInfoLines += "\nNeed By Date: " + needByDateValueMaskPlainText;
    contractInfoLines += "\nCapture: " + c_captureFullName;
    contractInfoLines += "\nTeammate: " + c_openTeammateContractObj.teammateObj.teammateNamePlainText;
    return(contractInfoLines);
  }



  get_all_teammates_with_active_contracts_for_single_capture_from_capture_id(i_captureID) {
    //used by OpenCaptureMobx a_details_update_field_value() when updating the contracts manager on a capture (all active contracts for all teammates need to be switched to the changed manager)
    
    const c_statusProcessNotStartedObj = this.c_statusProcessNotStartedObj;
    const c_statusCancelledObj = this.c_statusCancelledObj;
    const c_statusFullyExecutedObj = this.c_statusFullyExecutedObj;
    const o_tbl_c_teammates = DatabaseMobx.o_tbl_c_teammates;
    const o_tbl_c_teammates_contracts = DatabaseMobx.o_tbl_c_teammates_contracts;

    const nonActiveTeammateContractStatusIDsArray = [c_statusProcessNotStartedObj.id, c_statusCancelledObj.id, c_statusFullyExecutedObj.id]; //generate/send, plus all custom statuses are considered 'active' contracts

    //initialize output arrayOfObjs of all teammates with all of their active contracts
    var teammatesWithActiveContractsArrayOfObjs = [];

    //loop over every teammate on every capture, filtering for the input i_captureID only
    for(let teammateMap of o_tbl_c_teammates.values()) {
      if(teammateMap.get("capture_id") === i_captureID) {
        var teammateID = teammateMap.get("id");

        //initialize active contracts for this teammate on this capture
        var activeContractsArrayOfObjs = [];

        //loop over every teammate contract, filtering those from this teammateID (and also this captureID, which should always match if the teammateIDs match)
        for(let teammateContractMap of o_tbl_c_teammates_contracts.values()) {
          if(teammateContractMap.get("capture_id") === i_captureID) {
            if(teammateContractMap.get("teammate_id") === teammateID) {
              var statusID = teammateContractMap.get("status_id");

              if(!JSFUNC.in_array(statusID, nonActiveTeammateContractStatusIDsArray)) { //if this teammate contract is 'active'
                var contractTypeID = teammateContractMap.get("teammate_contract_type_id");
                var contractTypeMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_types", contractTypeID);

                var statusMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_statuses", statusID);

                var needByDateValueRaw = teammateContractMap.get("date_needed");
                var needByDateValueMaskPlainText = DatabaseMobx.get_company_date_format_from_Ymd_date(needByDateValueRaw);

                activeContractsArrayOfObjs.push({
                  contractTypeID: contractTypeID,
                  contractTypeShortNamePlainText: contractTypeMap.get("short_name"),
                  statusNamePlainText: statusMap.get("name"),
                  needByDatePlainText: needByDateValueMaskPlainText
                });
              }
            }
          }
        }

        //if this capture teammate has at least 1 active contract, push a teammate obj that has the teammateID and name, plus an arrayOfObjs with all its active contracts
        if(activeContractsArrayOfObjs.length > 0) {
          var contactCompanyID = teammateMap.get("contact_company_id");
          teammatesWithActiveContractsArrayOfObjs.push({
            teammateID: teammateID,
            teammateContactCompanyNamePlainText: ContactsMobx.contact_name_from_id(false, contactCompanyID),
            activeContractsArrayOfObjs: activeContractsArrayOfObjs
          });
        }
      }
    }

    return(teammatesWithActiveContractsArrayOfObjs);
  }


}
export default new TeammateContractsMobx();
