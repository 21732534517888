import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import CapturesMobx from '../Captures/CapturesMobx.js';
import TeammateContractsMobx from '../TeammateContracts/TeammateContractsMobx.js';

class ContactsMobx {
  //========================================================================================
  //observable values
  //selecting contacts in floating box
  o_initialSelectedContactIDsArray = [];
  o_currentSelectedContactIDsArray = [];

  //left side divisions tree
  o_leftSideState = "treeWithSearch"; //"treeWithSearch", "advancedCompanySearch", "exportContactsToCsv", "selectParentDivision"
  o_searchText = "";
  o_orgsExpandedCompanyIDsArray = [];
  o_leftSideNumOrgsDrawnLimit = 50;
  o_leftSideZoomedCompanyID = undefined; //undefined - show all orgs collapsed in tree (default view), single org id - this org has every division expanded all other orgs are filtered out completely

  o_advSearchCompanyFilterSelectedBusinessTypeIDsComma = "";
  o_advSearchCompanyFilterSelectedSBCertificationIDsComma = "";
  o_advSearchCompanyFilterSelectedCapabilityIDsComma = "";
  o_advSearchCompanyFilterSelectedNaicsCodeIDsComma = "";
  o_advSearchCompanyFilterSelectedContactCompanyExtraFieldID = -1;
  o_advSearchCompanyFilterContactCompanyExtraFieldSearchText = "";
  o_advSearchCaptureFilterSelectedDateFieldID = -1;
  o_advSearchCaptureFilterSelectedEarliestDate = JSFUNC.blank_date();
  o_advSearchNumResultsDrawnLimit = 50;
  o_advSearchGeneratingCsvOfMatchingCompaniesDataTF = false;

  //right side single open contact/new contact
  o_rightSideState = "empty"; //"empty", "viewEditSingleContact", "createNewContact", "savingNewContact", "advancedSearchCapturesAnalysis"
  o_openContactID = undefined;
  o_openContactIsPersonTF = undefined;
  o_openContactTeammatesRatingsCapturesFloatingBoxOpenTF = undefined;
  o_openContactSubtabLoadingFromDatabaseMessageOrUndefined = undefined;
  o_openContactTeammatesRatingsSubmissionsArrayOfObjs = [];
  o_openContactPersonConversationsArrayOfObjs = [];
  o_openContactPersonConversationsFilterByCaptureNameText = "";
  o_openContactPersonConversationsFilterByDateMin = JSFUNC.blank_date();
  o_openContactPersonConversationsFilterByDateMax = JSFUNC.blank_date();
  o_openContactPersonConversationsFilterByNotesText = "";
  o_openContactLoadingDocsTF = undefined;
  o_openContactViewEditSingleContactSelectedTabDbName = "contactInfo"; //"contactInfo", "teammateRating", "contactConversations", "viewDocs"
  o_newContactObj = undefined;
  o_advSearchTeamingCategory = undefined; //"theirAsOurWins", "theirAsOurActive", "theirAsOurLosses", "usAsTheirWins", "usAsTheirActive", "usAsTheirLosses"
  o_advSearchCaptureIDsArray = [];

  constructor() {
    makeObservable(this, {
      o_initialSelectedContactIDsArray: observable,
      o_currentSelectedContactIDsArray: observable,
      o_leftSideState: observable,
      o_searchText: observable,
      o_orgsExpandedCompanyIDsArray: observable,
      o_leftSideNumOrgsDrawnLimit: observable,
      o_leftSideZoomedCompanyID: observable,
      o_advSearchCompanyFilterSelectedBusinessTypeIDsComma: observable,
      o_advSearchCompanyFilterSelectedSBCertificationIDsComma: observable,
      o_advSearchCompanyFilterSelectedCapabilityIDsComma: observable,
      o_advSearchCompanyFilterSelectedNaicsCodeIDsComma: observable,
      o_advSearchCompanyFilterSelectedContactCompanyExtraFieldID: observable,
      o_advSearchCompanyFilterContactCompanyExtraFieldSearchText: observable,
      o_advSearchCaptureFilterSelectedDateFieldID: observable,
      o_advSearchCaptureFilterSelectedEarliestDate: observable,
      o_advSearchNumResultsDrawnLimit: observable,
      o_rightSideState: observable,
      o_openContactID: observable,
      o_openContactIsPersonTF: observable,
      o_openContactTeammatesRatingsCapturesFloatingBoxOpenTF: observable,
      o_openContactSubtabLoadingFromDatabaseMessageOrUndefined: observable,
      o_openContactTeammatesRatingsSubmissionsArrayOfObjs: observable,
      o_openContactPersonConversationsArrayOfObjs: observable,
      o_openContactPersonConversationsFilterByCaptureNameText: observable,
      o_openContactPersonConversationsFilterByDateMin: observable,
      o_openContactPersonConversationsFilterByDateMax: observable,
      o_openContactPersonConversationsFilterByNotesText: observable,
      o_openContactLoadingDocsTF: observable,
      o_openContactViewEditSingleContactSelectedTabDbName: observable,
      o_newContactObj: observable,
      o_advSearchTeamingCategory: observable,
      o_advSearchCaptureIDsArray: observable,

      c_allCompaniesArrayOfObjs: computed,
      c_allPersonsArrayOfObjs: computed,
      c_allCompanyIDsArray: computed,
      c_allOrgsArrayOfObjs: computed,
      c_createdByGcssImportContactPersonsArrayOfObjs: computed,
      c_unassignedPersonsArrayOfObjs: computed,
      c_companyFieldsArrayOfObjs: computed,
      c_personFieldsArrayOfObjs: computed,
      c_selectMultiContactsFloatingBoxIsSplitScreenTF: computed,
      c_searchResultsCompaniesArrayOfObjs: computed,
      c_searchResultsPersonsArrayOfObjs: computed,
      c_openContactObj: computed,
      c_openContactName: computed,
      c_openContactFieldsArrayOfObjs: computed,
      c_openContactAllChildrenIDsArray: computed,
      c_openContactCompanyTeammateCaptureIDsArray: computed,
      c_openContactCompanyTeammateArchivedCaptureIDsArray: computed,
      c_openContactCompanyTeammatesRatingsCapturesArrayOfObjs: computed,
      c_openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs: computed,
      c_openContactCompanyTeammatesRatingsCapturesNotReviewedArrayOfObjs: computed,
      c_openContactPersonExpandedConversationsPerCaptureArrayOfObjs: computed,
      c_openContactCompanyFileFolderSystemMapOfMaps: computed,
      c_openContactPersonNumFilteredConversations: computed,
      c_openContactCompanyFileFolderSystemNumFiles: computed,
      c_openContactPersonFileFolderSystemMapOfMaps: computed,
      c_openContactPersonFileFolderSystemNumFiles: computed,
      c_advSearchExpandedSelectedTeammatesArrayOfObjs: computed,
      c_advSearchEarliestDateFilteredCapturesArrayOfObjs: computed,
      c_advSearchEarliestDateFilteredCaptureIDsArray: computed,
      c_advSearchAllContactCompaniesArrayOfObjs: computed,
      c_advSearchBusinessTypeFilterIsActiveTF: computed,
      c_advSearchSBCertificationsFilterIsActiveTF: computed,
      c_advSearchCapabilitiesFilterIsActiveTF: computed,
      c_advSearchNaicsCodesFilterIsActiveTF: computed,
      c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF: computed,
      c_advSearchCaptureDateFilterIsActiveTF: computed,
      c_advSearchCompanyFilterSelectedBusinessTypeIDsArray: computed,
      c_advSearchCompanyFilterSelectedSBCertificationIDsArray: computed,
      c_advSearchCompanyFilterSelectedCapabilityIDsArray: computed,
      c_advSearchCompanyFilterSelectedNaicsCodeIDsArray: computed,
      c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined: computed,
      c_advSearchAnyFilterIsActiveTF: computed,
      c_advSearchFilteredContactCompaniesArrayOfObjs: computed,
      c_advSearchAnalysisCapturesArrayOfObjs: computed,
      c_advSearchAnalysisScatterDataObj: computed,

      a_initialize_initial_and_current_selected_contact_ids_arrays: action,
      a_initialize_contacts_system: action,
      a_set_left_side_state: action,
      a_set_right_side_state: action,
      a_set_search_text: action,
      a_expand_collapse_company: action,
      a_set_left_side_num_orgs_drawn_to_unlimited: action,
      a_set_left_side_zoomed_org_id_from_is_person_tf_and_contact_id: action,
      a_set_left_side_zoomed_org_id_from_company_id: action,
      a_set_left_side_zoomed_org_id_from_person_id: action,
      a_set_left_side_zoomed_org_id_from_org_id: action,
      a_open_contact: action,
      a_close_contact: action,
      a_set_open_contact_teammates_ratings_captures_floating_box_open_tf: action,
      a_set_open_contact_subtab_loading_from_database_message_or_undefined: action,
      a_set_open_contact_teammates_ratings_submissions_arrayOfObjs: action,
      a_contact_person_conversations_load_all_person_conversation_history: action,
      a_set_open_contact_person_conversations_arrayOfObjs: action,
      a_set_open_contact_person_conversations_filter_by_capture_name_text: action,
      a_set_open_contact_person_conversations_filter_by_date_min: action,
      a_set_open_contact_person_conversations_filter_by_date_max: action,
      a_set_open_contact_person_conversations_filter_by_notes_text: action,
      a_set_open_contact_loading_docs_tf: action,
      a_set_open_contact_view_edit_single_contact_selected_tab_db_name: action,
      a_set_selected_contact_ids_array: action,
      a_select_contact: action,
      a_remove_contact_from_selection: action,
      a_delete_contact: action,
      a_update_contact_field: action,
      a_update_contact_parent_company_field: action,
      a_insert_new_contact_from_contacts_system_creation_and_open_on_success: action,
      a_insert_new_contact_from_new_contact_obj: action,
      a_initialize_new_contact: action,
      a_update_new_contact_field_local: action,
      a_update_new_contact_parent_company_field_local: action,
      a_export_contacts_to_csv_for_download: action,
      a_open_contacts_advanced_search_left_side: action,
      a_contacts_advanced_search_set_company_filter_selected_business_type_ids_comma: action,
      a_contacts_advanced_search_set_company_filter_selected_sb_certifications_bm_set_aside_ids_comma: action,
      a_contacts_advanced_search_set_company_filter_selected_capability_ids_comma: action,
      a_contacts_advanced_search_set_company_filter_selected_naics_code_ids_comma: action,
      a_contacts_advanced_search_set_company_filter_selected_contact_company_extra_field_id: action,
      a_contacts_advanced_search_set_company_filter_contact_company_extra_field_search_text: action,
      a_contacts_advanced_search_set_capture_filter_selected_date_field_id: action,
      a_contacts_advanced_search_set_capture_filter_selected_earliest_date: action,
      a_contacts_advanced_search_set_num_results_drawn_to_initial_value: action,
      a_contacts_advanced_search_set_num_results_drawn_to_unlimited: action,
      a_contacts_advanced_search_set_generating_csv_of_matching_companies_data_tf: action,
      a_contacts_advanced_search_download_csv_of_matching_companies_data: action,
      a_contacts_advanced_search_open_captures_analysis: action
    });
  }


  //========================================================================================
  //contacts system computed values
  get c_allCompaniesArrayOfObjs() {
    const subdivsAndPersonsTF = true;

    var allCompaniesArrayOfObjs = [];
    for(let contactCompanyMap of DatabaseMobx.o_tbl_g_contacts_companies.values()) {
      allCompaniesArrayOfObjs.push(this.contact_company_obj_from_tbl_g_contacts_companies_row_map(contactCompanyMap, subdivsAndPersonsTF, contactCompanyMap.get("id")));
    }

    //sort all contacts by name asc
    const contactNameField = this.contact_name_field(false);
    JSFUNC.sort_arrayOfObjs(allCompaniesArrayOfObjs, contactNameField, true);

    return(allCompaniesArrayOfObjs);
  }

  get c_allPersonsArrayOfObjs() {
    var allPersonsArrayOfObjs = [];
    for(let contactPersonMap of DatabaseMobx.o_tbl_g_contacts_persons.values()) {
      allPersonsArrayOfObjs.push(this.contact_person_obj_from_tbl_g_contacts_persons_row_map(contactPersonMap, contactPersonMap.get("id")));
    }
    JSFUNC.sort_arrayOfObjs(allPersonsArrayOfObjs, "sortName", true); //sort all contacts by lastname asc
    return(allPersonsArrayOfObjs);
  }

  get c_allCompanyIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_allCompaniesArrayOfObjs, "id"));
  }

  get c_allOrgsArrayOfObjs() { //top division of each organization has the field org_topdiv_id set as -1, all subdivisions point to their parent company using this field to reference the id number
    const filterFunction = (i_contactCompanyObj) => { return(i_contactCompanyObj.org_topdiv_id === -1); };
    const sortFunction = JSFUNC.sort_by_asc("divNameParenOrgNameLower"); //list the organizations alphabetically
    return(this.c_allCompaniesArrayOfObjs.filter(filterFunction).sort(sortFunction));
  }

  get c_createdByGcssImportContactPersonsArrayOfObjs() { //all persons with either no contact company assigned, or the id assigned does not exist
    const filterFunction = (i_personObj) => { return(i_personObj.contact_company_id === -2); }; //-2 parent companyID for a person means it is a GCSS Import
    const sortFunction = JSFUNC.sort_by_asc("sortName");
    return(this.c_allPersonsArrayOfObjs.filter(filterFunction).sort(sortFunction));
  }

  get c_unassignedPersonsArrayOfObjs() { //all persons with either no contact company assigned, or the id assigned does not exist
    const filterFunction = (i_personObj) => { return(!JSFUNC.in_array(i_personObj.contact_company_id, this.c_allCompanyIDsArray) && (i_personObj.contact_company_id !== -2)); }; //-2 parent companyID for a person means it is a GCSS Import
    const sortFunction = JSFUNC.sort_by_asc("sortName");
    return(this.c_allPersonsArrayOfObjs.filter(filterFunction).sort(sortFunction));
  }

  //extra fields for persons/companies
  get c_companyFieldsArrayOfObjs() {
    var companyFieldsArrayOfObjs = [];

    companyFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-1, "legal_name", "Legal Name", DatabaseMobx.c_genericTextFieldTypeObj));
    companyFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-2, "abbreviated_name", "Abbreviated Name", DatabaseMobx.c_genericTextFieldTypeObj));
    companyFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-3, "business_type_id", "Business Type", DatabaseMobx.c_selectBusinessTypeFieldTypeObj));
    companyFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-4, "sb_certifications_bm_set_aside_ids_comma", "Small Business Certification(s)", DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj));
    companyFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-5, "capability_ids_comma", "Capabilities", DatabaseMobx.c_selectMultiAddCapabilitiesFieldTypeObj));
    companyFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-6, "naics_code_ids_comma", "NAICS Code(s)", DatabaseMobx.c_selectMultiBitMasterNaicsCodesCombinedNamesFieldTypeObj));

    for(let extraFieldObj of DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs) {
      companyFieldsArrayOfObjs.push(extraFieldObj);
    }

    companyFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-7, "org_topdiv_id", "Parent Company", DatabaseMobx.c_selectContactCompanyFieldTypeObj));
    companyFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-8, "tree_id", "Tree ID", DatabaseMobx.c_genericTextFieldTypeObj));

    return(companyFieldsArrayOfObjs);
  }

  get c_personFieldsArrayOfObjs() {
    var personFieldsArrayOfObjs = [];

    personFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-1, "first_name", "First Name", DatabaseMobx.c_genericTextFieldTypeObj));
    personFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-2, "last_name", "Last Name", DatabaseMobx.c_genericTextFieldTypeObj));
    personFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-3, "title", "Title", DatabaseMobx.c_genericTextFieldTypeObj));
    personFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-4, "email", "Email", DatabaseMobx.c_genericEmailFieldTypeObj));
    personFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-5, "phone", "Phone", DatabaseMobx.c_genericPhoneFieldTypeObj));

    for(let extraFieldObj of DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs) {
      personFieldsArrayOfObjs.push(extraFieldObj);
    }

    personFieldsArrayOfObjs.push(this.create_contact_hardcoded_expanded_field_obj(-6, "contact_company_id", "Company/Division", DatabaseMobx.c_selectContactCompanyFieldTypeObj));

    return(personFieldsArrayOfObjs);
  }

  create_contact_hardcoded_expanded_field_obj(i_id, i_dbName, i_displayName, i_fieldTypeObj) {
    return({
      id: i_id,
      db_name: i_dbName,
      display_name: i_displayName,
      fieldTypeObj: i_fieldTypeObj
    });
  }


  get c_selectMultiContactsFloatingBoxIsSplitScreenTF() {
    return(!CaptureExecMobx.c_isMobileOrTabletTF);
  }


  get c_searchResultsCompaniesArrayOfObjs() {
    if(this.o_searchText.length === 0) {
      return([]);
    }

    const searchTextLower = this.o_searchText.toLowerCase();
    const filterFunction = (i_companyObj) => { //check if search text is contained within full name (case insensitive)
      return(i_companyObj.divNameParenOrgNameLower.indexOf(searchTextLower) !== -1);
    };
    return(this.c_allCompaniesArrayOfObjs.filter(filterFunction));
  }

  get c_searchResultsPersonsArrayOfObjs() {
    if(this.o_searchText.length === 0) {
      return([]);
    }

    const searchTextLower = this.o_searchText.toLowerCase();
    const filterFunction = (i_personObj) => { //check if search text is contained within full name and title (case insensitive)
      return(i_personObj.firstNameSpaceLastNameLower.indexOf(searchTextLower) !== -1 || i_personObj.lastNameLower.indexOf(searchTextLower) !== -1 || i_personObj.titleLower.indexOf(searchTextLower) !== -1 || i_personObj.parentCompanyAbbrOrLegalNameLower.indexOf(searchTextLower) !== -1);
    };
    return(this.c_allPersonsArrayOfObjs.filter(filterFunction));
  }

  get c_openContactObj() {
    if(this.o_openContactIsPersonTF !== undefined && this.o_openContactID > 0) {
      return(this.contact_company_or_person_obj_from_id(this.o_openContactIsPersonTF, this.o_openContactID));
    }
    return(undefined);
  }

  get c_openContactName() {
    if(this.c_openContactObj === undefined) {
      return(undefined);
    }
    return(this.contact_name_plaintext_from_contact_obj(this.c_openContactObj));
  }

  get c_openContactFieldsArrayOfObjs() {
    if(this.c_openContactObj === undefined) {
      return(undefined);
    }
    return(this.contact_fields_arrayOfObjs(this.c_openContactObj.isPersonTF));
  }

  get c_openContactAllChildrenIDsArray() {
    if(this.c_openContactObj === undefined) {
      return(undefined);
    }

    var openContactSelfAndAllChildrenIDsArray = [this.c_openContactObj.id]; //start with the open contactID, add all children in same org to this list

    //only compute children if this is a company
    if(!this.c_openContactObj.isPersonTF) {
      const openContactTreeID = this.c_openContactObj.tree_id;
      const allCompaniesInOldOrgArrayOfObjs = this.all_companies_within_org_id_arrayOfObjs(this.c_openContactObj.organizationID);
      for(let companyInOrgObj of allCompaniesInOldOrgArrayOfObjs) {
        if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(openContactTreeID, companyInOrgObj.tree_id)) {
          openContactSelfAndAllChildrenIDsArray.push(companyInOrgObj.id);
        }
      }
    }

    return(openContactSelfAndAllChildrenIDsArray);
  }

  get c_openContactCompanyTeammateCaptureIDsArray() {
    var openContactCompanyTeammateCaptureIDsArray = [];
    if((this.c_openContactObj !== undefined) && (!this.c_openContactObj.isPersonTF)) {
      var uniqueCaptureIDsArray = [];
      for(let teammateMap of DatabaseMobx.o_tbl_c_teammates.values()) {
        var teammateContactCompanyID = teammateMap.get("contact_company_id");
        if(teammateContactCompanyID === this.c_openContactObj.id) {
          var teammateCaptureID = teammateMap.get("capture_id");
          if(!JSFUNC.in_array(teammateCaptureID, uniqueCaptureIDsArray)) {
            uniqueCaptureIDsArray.push(teammateCaptureID);
            openContactCompanyTeammateCaptureIDsArray.push(teammateCaptureID);
          }
        }
      }
    }
    return(openContactCompanyTeammateCaptureIDsArray);
  }

  get c_openContactCompanyTeammateArchivedCaptureIDsArray() {
    const c_openContactCompanyTeammateCaptureIDsArray = this.c_openContactCompanyTeammateCaptureIDsArray;
    const c_archivedCaptureIDsArray = DatabaseMobx.c_archivedCaptureIDsArray;

    var openContactCompanyTeammateArchivedCaptureIDsArray = [];
    for(let captureID of c_openContactCompanyTeammateCaptureIDsArray) {
      if(JSFUNC.in_array(captureID, c_archivedCaptureIDsArray)) {
        openContactCompanyTeammateArchivedCaptureIDsArray.push(captureID);
      }
    }
    return(openContactCompanyTeammateArchivedCaptureIDsArray);
  }

  get c_openContactCompanyTeammatesRatingsCapturesArrayOfObjs() {
    const openContactTeammatesRatingsSubmissionsArrayOfObjs = this.o_openContactTeammatesRatingsSubmissionsArrayOfObjs;
    const openContactCompanyTeammateCaptureIDsArray = this.c_openContactCompanyTeammateCaptureIDsArray;
    const o_tbl_captures = DatabaseMobx.o_tbl_captures;
    const c_fieldMapOfStage = DatabaseMobx.c_fieldMapOfStage;

    var openContactCompanyTeammatesRatingsCapturesArrayOfObjs = [];
    for(let captureID of openContactCompanyTeammateCaptureIDsArray) {
      var captureMapOrUndefined = o_tbl_captures.get(captureID);

      var captureNamePlainText = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMapOrUndefined);
      var captureNameLowerCase = captureNamePlainText.toLowerCase();

      var captureStageID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_fieldMapOfStage);
      var captureStageMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_stages_pool", captureStageID);
      var captureStageName = captureStageMap.get("name");
      var captureStageColor = captureStageMap.get("color");

      var teammatesRatingsCaptureObj = {};

      var reviewedTF = false;
      var sortDateTimeUtc = JSFUNC.blank_datetime(); //create a sort column that places all reviewed captures on top sorted by most recent date on top, followed by unreviewed captures alphabetically by captureName
      for(let teammatesRatingSubmissionObj of openContactTeammatesRatingsSubmissionsArrayOfObjs) {
        if(teammatesRatingSubmissionObj.capture_id === captureID) {
          reviewedTF = true;
          sortDateTimeUtc = teammatesRatingsCaptureObj.datetime_utc;
          teammatesRatingsCaptureObj = JSFUNC.copy_obj(teammatesRatingSubmissionObj);
          teammatesRatingsCaptureObj.reviewedByUserNameMask = DatabaseMobx.user_name_mask_from_user_id(teammatesRatingsCaptureObj.reviewed_by_user_id);
          teammatesRatingsCaptureObj.reviewDate = DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(JSFUNC.convert_mysqldate_or_mysqldatetimeutc_to_mysqldatelocal(teammatesRatingsCaptureObj.datetime_utc), DatabaseMobx.c_genericDateFieldTypeObj);
          break;
        }
      }

      teammatesRatingsCaptureObj.captureID = captureID;
      teammatesRatingsCaptureObj.captureNamePlainText = captureNamePlainText;
      teammatesRatingsCaptureObj.captureNameLowerCase = captureNameLowerCase;
      teammatesRatingsCaptureObj.captureStageName = captureStageName;
      teammatesRatingsCaptureObj.captureStageColor = captureStageColor;
      teammatesRatingsCaptureObj.reviewedTF = reviewedTF;
      teammatesRatingsCaptureObj.sortDateTimeUtc = sortDateTimeUtc; 

      openContactCompanyTeammatesRatingsCapturesArrayOfObjs.push(teammatesRatingsCaptureObj);
    }

    //sort the array of captures
    JSFUNC.sort_arrayOfObjs(openContactCompanyTeammatesRatingsCapturesArrayOfObjs, ["sortDateTimeUtc", "captureNameLowerCase"], [false, true]);

    return(openContactCompanyTeammatesRatingsCapturesArrayOfObjs);
  }

  get c_openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs() {
    var openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs = [];
    for(let teammatesRatingsCaptureObj of this.c_openContactCompanyTeammatesRatingsCapturesArrayOfObjs) {
      if(teammatesRatingsCaptureObj.reviewedTF) {
        openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs.push(teammatesRatingsCaptureObj);
      }
    }
    return(openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs);
  }

  get c_openContactCompanyTeammatesRatingsCapturesNotReviewedArrayOfObjs() {
    var openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs = [];
    for(let teammatesRatingsCaptureObj of this.c_openContactCompanyTeammatesRatingsCapturesArrayOfObjs) {
      if(!teammatesRatingsCaptureObj.reviewedTF) {
        openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs.push(teammatesRatingsCaptureObj);
      }
    }
    return(openContactCompanyTeammatesRatingsCapturesReviewedArrayOfObjs);
  }


  get c_openContactPersonExpandedConversationsPerCaptureArrayOfObjs() {
    const o_openContactPersonConversationsArrayOfObjs = this.o_openContactPersonConversationsArrayOfObjs;
    const o_tbl_captures = DatabaseMobx.o_tbl_captures;
    const c_genericDateYmdFromRawDateTimeUtcFieldTypeObj = DatabaseMobx.c_genericDateYmdFromRawDateTimeUtcFieldTypeObj;
    const c_genericDateTimeNaturalFieldTypeObj = DatabaseMobx.c_genericDateTimeNaturalFieldTypeObj;

    //loop through all conversations for this contact person loaded from database, compute extra fields to create an 'expanded' conversation obj
    var openContactPersonExpandedConversationsArrayOfObjs = [];
    for(let conversationObj of o_openContactPersonConversationsArrayOfObjs) {
      var expandedConversationObj = JSFUNC.copy_obj(conversationObj);

      expandedConversationObj.dateTimeLocalValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(conversationObj.datetime_utc, c_genericDateTimeNaturalFieldTypeObj);
      expandedConversationObj.dateYmdLocalValueMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(conversationObj.datetime_utc, c_genericDateYmdFromRawDateTimeUtcFieldTypeObj);

      openContactPersonExpandedConversationsArrayOfObjs.push(expandedConversationObj);
    }

    //sort all conversations mixed across many captures by date desc
    JSFUNC.sort_arrayOfObjs(openContactPersonExpandedConversationsArrayOfObjs, "datetime_utc", false);

    //get all unique captureIDs for each conversation (only include captureIDs that exist as real captures in o_tbl_captures)
    var allUniqueCaptureIDsArray = [];
    for(let expandedConversationObj of openContactPersonExpandedConversationsArrayOfObjs) {
      if(!JSFUNC.in_array(expandedConversationObj.capture_id, allUniqueCaptureIDsArray)) { //only add unique captureIDs to the list
        if(o_tbl_captures.has(expandedConversationObj.capture_id)) { //only include captureIDs that exist and are currently loaded into the system (archived captures thus excluded)
          allUniqueCaptureIDsArray.push(expandedConversationObj.capture_id);
        }
      }
    }

    //for each captureID, create a filtered list of matching conversations (already in desc date order)
    var openContactPersonExpandedConversationsPerCaptureArrayOfObjs = [];
    for(let captureID of allUniqueCaptureIDsArray) {
      //get capture info
      var captureNamePlainText = DatabaseMobx.capture_name_plaintext_from_capture_id(captureID);
      var captureNamePlainTextLowercase = captureNamePlainText.toLowerCase();

      //get all conversations for this single captureID
      var mostRecentDateTimeUtc = undefined;
      var captureExpandedConversationsArrayOfObjs = [];
      for(let expandedConversationObj of openContactPersonExpandedConversationsArrayOfObjs) {
        if(expandedConversationObj.capture_id === captureID) {
          if(mostRecentDateTimeUtc === undefined) { //record the datetime of the first conversation record, since all convos are sorted by date desc, the most recent will be first
            mostRecentDateTimeUtc = expandedConversationObj.datetime_utc;
          }
          
          captureExpandedConversationsArrayOfObjs.push(expandedConversationObj);
        }
      }

      //if there are 0 records, put 0000-00-00 00:00:00 as the most recent datetime for sorting the captures
      if(mostRecentDateTimeUtc === undefined) {
        mostRecentDateTimeUtc = JSFUNC.blank_datetime();
      }

      openContactPersonExpandedConversationsPerCaptureArrayOfObjs.push({
        captureID: captureID,
        captureNamePlainText: captureNamePlainText,
        captureNamePlainTextLowercase: captureNamePlainTextLowercase,
        mostRecentDateTimeUtc: mostRecentDateTimeUtc,
        captureExpandedConversationsArrayOfObjs: captureExpandedConversationsArrayOfObjs
      });
    }

    return(openContactPersonExpandedConversationsPerCaptureArrayOfObjs);
  }

  get c_openContactPersonFilteredExpandedConversationsPerCaptureArrayOfObjs() {
    const o_openContactPersonConversationsFilterByCaptureNameText = this.o_openContactPersonConversationsFilterByCaptureNameText;
    const o_openContactPersonConversationsFilterByDateMin = this.o_openContactPersonConversationsFilterByDateMin;
    const o_openContactPersonConversationsFilterByDateMax = this.o_openContactPersonConversationsFilterByDateMax;
    const o_openContactPersonConversationsFilterByNotesText = this.o_openContactPersonConversationsFilterByNotesText;
    const c_openContactPersonExpandedConversationsPerCaptureArrayOfObjs = this.c_openContactPersonExpandedConversationsPerCaptureArrayOfObjs;

    const filterByCaptureNameIsFilledOutTF = JSFUNC.string_is_filled_out_tf(o_openContactPersonConversationsFilterByCaptureNameText);
    const filterByDateMinIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_openContactPersonConversationsFilterByDateMin);
    const filterByDateMaxIsFilledOutTF = JSFUNC.date_is_filled_out_tf(o_openContactPersonConversationsFilterByDateMax);
    const filterByNotesIsFilledOutTF = JSFUNC.string_is_filled_out_tf(o_openContactPersonConversationsFilterByNotesText);

    //if none of the filters are currently filled out (most common state), return the entire set of captures/conversations unfiltered
    if(!filterByCaptureNameIsFilledOutTF && !filterByDateMinIsFilledOutTF && !filterByDateMaxIsFilledOutTF && !filterByNotesIsFilledOutTF) {
      return(c_openContactPersonExpandedConversationsPerCaptureArrayOfObjs);
    }

    //convert the input search terms to lowercase before looping through the captures/conversations
    var filterByCaptureNameLowercase = "";
    if(filterByCaptureNameIsFilledOutTF) {
      filterByCaptureNameLowercase = o_openContactPersonConversationsFilterByCaptureNameText.toLowerCase();
    }

    var filterByNotesLowercase = "";
    if(filterByNotesIsFilledOutTF) {
      filterByNotesLowercase = o_openContactPersonConversationsFilterByNotesText.toLowerCase();
    }

    var openContactPersonFilteredExpandedConversationsPerCaptureArrayOfObjs = [];
    for(let expandedConversationsPerCaptureObj of c_openContactPersonExpandedConversationsPerCaptureArrayOfObjs) {
      var includeCaptureTF = true;

      //determine if the capture name filter includes this capture or not
      if(includeCaptureTF && filterByCaptureNameIsFilledOutTF) {
        if(!JSFUNC.input_lowercase_string_contains_lowercase_search_term_string_tf(expandedConversationsPerCaptureObj.captureNamePlainTextLowercase, filterByCaptureNameLowercase)) {
          includeCaptureTF = false;
        }
      }

      //if this capture is included, use the other filters to filter the conversations by date and notes, if 0 conversations remain in a capture, remove the whole capture obj from the filtered list
      if(includeCaptureTF) {
        var captureFilteredExpandedConversationsArrayOfObjs = [];
        for(let expandedConversationObj of expandedConversationsPerCaptureObj.captureExpandedConversationsArrayOfObjs) {
          var includeConversationTF = true;

          if(includeConversationTF && filterByDateMinIsFilledOutTF) { //conversation date min filter
            if(!(expandedConversationObj.dateYmdLocalValueMaskSortIfoObj.valueMaskPlainText >= o_openContactPersonConversationsFilterByDateMin)) {
              includeConversationTF = false;
            }
          }

          if(includeConversationTF && filterByDateMaxIsFilledOutTF) { //conversation date max filter
            if(!(expandedConversationObj.dateYmdLocalValueMaskSortIfoObj.valueMaskPlainText <= o_openContactPersonConversationsFilterByDateMax)) {
              includeConversationTF = false;
            }
          }

          if(includeConversationTF && filterByNotesIsFilledOutTF) { //conversation notes filter
            if(!JSFUNC.input_string_converted_to_lowercase_contains_lowercase_search_term_string_tf(expandedConversationObj.notes, filterByNotesLowercase)) {
              includeConversationTF = false;
            }
          }

          if(includeConversationTF) {
            captureFilteredExpandedConversationsArrayOfObjs.push(expandedConversationObj);
          }
        }

        //if the single capture has at least 1 conversation after filtering for dates/notes, include the capture with those filtered conversations
        if(captureFilteredExpandedConversationsArrayOfObjs.length > 0) {
          openContactPersonFilteredExpandedConversationsPerCaptureArrayOfObjs.push({
            captureID: expandedConversationsPerCaptureObj.captureID,
            captureNamePlainText: expandedConversationsPerCaptureObj.captureNamePlainText,
            captureNamePlainTextLowercase: expandedConversationsPerCaptureObj.captureNamePlainTextLowercase,
            mostRecentDateTimeUtc: expandedConversationsPerCaptureObj.mostRecentDateTimeUtc,
            captureExpandedConversationsArrayOfObjs: captureFilteredExpandedConversationsArrayOfObjs
          });
        }
      }
    }
    return(openContactPersonFilteredExpandedConversationsPerCaptureArrayOfObjs);
  }

  get c_openContactPersonNumFilteredConversations() {
    const c_openContactPersonFilteredExpandedConversationsPerCaptureArrayOfObjs = this.c_openContactPersonFilteredExpandedConversationsPerCaptureArrayOfObjs;

    var numFilteredConversations = 0;
    for(let expandedConversationsPerCaptureObj of c_openContactPersonFilteredExpandedConversationsPerCaptureArrayOfObjs) {
      numFilteredConversations += expandedConversationsPerCaptureObj.captureExpandedConversationsArrayOfObjs.length;
    }
    return(numFilteredConversations);
  }


  get c_openContactCompanyFileFolderSystemMapOfMaps() {
    if((this.c_openContactObj === undefined) || (this.o_openContactIsPersonTF !== false)) {
      return(new Map());
    }
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_g_contacts_companies_filefoldersystem, "contact_company_id", this.o_openContactID));
  }

  get c_openContactCompanyFileFolderSystemNumFiles() {
    const filesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.c_openContactCompanyFileFolderSystemMapOfMaps, "folder0_file1", 1);
    return(filesMapOfMaps.size);
  }

  get c_openContactPersonFileFolderSystemMapOfMaps() {
    if((this.c_openContactObj === undefined) || (this.o_openContactIsPersonTF === false)) {
      return(new Map());
    }
    return(JSFUNC.filtered_mapOfMaps_from_matching_field_value(DatabaseMobx.o_tbl_g_contacts_persons_filefoldersystem, "contact_person_id", this.o_openContactID));
  }

  get c_openContactPersonFileFolderSystemNumFiles() {
    const filesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.c_openContactPersonFileFolderSystemMapOfMaps, "folder0_file1", 1);
    return(filesMapOfMaps.size);
  }



  get c_advSearchExpandedSelectedTeammatesArrayOfObjs() {
    var advSearchExpandedSelectedTeammatesArrayOfObjs = [];
    for(let teammateMap of DatabaseMobx.o_tbl_c_teammates.values()) {
      if(DatabaseMobx.o_tbl_captures.has(teammateMap.get("capture_id"))) {
        if(teammateMap.get("selected_01") === 1) { //only include teammtes that have been selected for the team on each capture
          var combinedTeammateObj = TeammateContractsMobx.create_combined_teammate_obj_from_tbl_c_teammates_row_map(teammateMap);

          //add extra fields to the tbl_c_teammates record based on the captureID
          var captureStageIsActiveTypeTF = false;
          var captureStageIsWonTypeTF = false;
          var captureStageIsLostTypeTF = false;
          var captureStageIsNoBidOrCancelledTypeTF = false;
          var captureMap = DatabaseMobx.o_tbl_captures.get(combinedTeammateObj.capture_id);
          if(captureMap !== undefined) {
            var stageID = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfStage);
            var stageMap = DatabaseMobx.c_tbl_a_stages_pool.get(stageID);
            if(stageMap !== undefined) {
              captureStageIsActiveTypeTF = (!(stageMap.get("isClosedStageTF")));
              captureStageIsWonTypeTF = stageMap.get("isWonStageTF");
              captureStageIsLostTypeTF = stageMap.get("isLostStageTF");
              captureStageIsNoBidOrCancelledTypeTF = stageMap.get("isNotSubmittedStageTF");
            }
          }

          combinedTeammateObj.captureTypeIsPrimeTF = DatabaseMobx.capture_type_is_prime_tf_from_capture_map(captureMap);
          combinedTeammateObj.captureStageIsActiveTypeTF = captureStageIsActiveTypeTF;
          combinedTeammateObj.captureStageIsWonTypeTF = captureStageIsWonTypeTF;
          combinedTeammateObj.captureStageIsLostTypeTF = captureStageIsLostTypeTF;
          combinedTeammateObj.captureStageIsNoBidOrCancelledTypeTF = captureStageIsNoBidOrCancelledTypeTF;

          advSearchExpandedSelectedTeammatesArrayOfObjs.push(combinedTeammateObj);
        }
      }
    }

    JSFUNC.sort_arrayOfObjs(advSearchExpandedSelectedTeammatesArrayOfObjs, "allocation_percent", false);

    return(advSearchExpandedSelectedTeammatesArrayOfObjs);
  }

  get c_advSearchEarliestDateFilteredCapturesArrayOfObjs() {
    const c_tbl_captures_fields = DatabaseMobx.c_tbl_captures_fields;

    //filter the captures if the date filter is filled out
    var filteredCapturesMapOfMaps = new Map();
    if(this.c_advSearchCaptureDateFilterIsActiveTF) {
      const filtersArrayOfObjs = [
        {capture_field_id:this.o_advSearchCaptureFilterSelectedDateFieldID, operator:"gte", value:this.o_advSearchCaptureFilterSelectedEarliestDate}
      ];
      const expandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs_and_expanded_captures_fields_mapOfMaps(filtersArrayOfObjs, c_tbl_captures_fields);
      filteredCapturesMapOfMaps = CapturesMobx.get_filtered_captures_mapOfMaps_from_captures_mapOfMaps_and_expanded_filters_arrayOfObjs(DatabaseMobx.o_tbl_captures, expandedFiltersArrayOfObjs);
    }
    else { //use all captures in system
      filteredCapturesMapOfMaps = DatabaseMobx.o_tbl_captures;
    }

    var advSearchAllSubCaptureIDsAndStageIDsArrayOfObjs = [];
    for(let [captureID, captureMap] of filteredCapturesMapOfMaps) {
      var captureTypeIsPrimeTF = DatabaseMobx.capture_type_is_prime_tf_from_capture_map(captureMap);

      var stageID = captureMap.get("stage_id");
      var stageIsClosedWonTF = JSFUNC.in_array(stageID, DatabaseMobx.c_closedWonStageIDsArray);
      var stageIsActiveTF = JSFUNC.in_array(stageID, DatabaseMobx.c_activeStageIDsArray);
      var stageIsClosedLostTF = JSFUNC.in_array(stageID, DatabaseMobx.c_closedLostStageIDsArray);

      var primeContactCompanyID = captureMap.get("prime_contact_company_id");

      advSearchAllSubCaptureIDsAndStageIDsArrayOfObjs.push({
        captureID: captureID,
        captureMap: captureMap,
        captureTypeIsPrimeTF: captureTypeIsPrimeTF,
        stageIsClosedWonTF: stageIsClosedWonTF,
        stageIsActiveTF: stageIsActiveTF,
        stageIsClosedLostTF: stageIsClosedLostTF,
        primeContactCompanyID: primeContactCompanyID
      });
    }
    return(advSearchAllSubCaptureIDsAndStageIDsArrayOfObjs);
  }

  get c_advSearchEarliestDateFilteredCaptureIDsArray() {
    return(JSFUNC.get_column_vector_from_arrayOfObjs(this.c_advSearchEarliestDateFilteredCapturesArrayOfObjs, "captureID"));
  }

  get c_advSearchAllContactCompaniesArrayOfObjs() {
    //loop through each contact company, counting the captures they were teammates on in separate categories
    var allContactCompaniesArrayOfObjs = [];
    for(let companyObj of this.c_allCompaniesArrayOfObjs) {
      var wonCaptureIDsWePrimeTheySubArray = [];
      var activeCaptureIDsWePrimeTheySubArray = [];
      var lostCaptureIDsWePrimeTheySubArray = [];
      var wonCaptureIDsTheyPrimeWeSubArray = [];
      var activeCaptureIDsTheyPrimeWeSubArray = [];
      var lostCaptureIDsTheyPrimeWeSubArray = [];

      //prime capture types where contact was teammate
      for(let expandedTeammateObj of this.c_advSearchExpandedSelectedTeammatesArrayOfObjs) {
        if(expandedTeammateObj.contact_company_id === companyObj.id) { //find all teammate records that use this contact company
          if(expandedTeammateObj.captureTypeIsPrimeTF) { //prime capture type
            if(JSFUNC.in_array(expandedTeammateObj.capture_id, this.c_advSearchEarliestDateFilteredCaptureIDsArray)) { //in a capture that matches the earliest date filter
              if(expandedTeammateObj.captureStageIsWonTypeTF) { //won stage
                wonCaptureIDsWePrimeTheySubArray.push(expandedTeammateObj.capture_id);
              }
              else if(expandedTeammateObj.captureStageIsActiveTypeTF) {
                activeCaptureIDsWePrimeTheySubArray.push(expandedTeammateObj.capture_id);
              }
              else if(expandedTeammateObj.captureStageIsLostTypeTF) {
                lostCaptureIDsWePrimeTheySubArray.push(expandedTeammateObj.capture_id);
              }
            }
          }
        }
      }

      //sub capture types where contact was prime
      for(let captureObj of this.c_advSearchEarliestDateFilteredCapturesArrayOfObjs) {
        if(!captureObj.captureTypeIsPrimeTF) { //sub capture type
          if(companyObj.id === captureObj.primeContactCompanyID) {
            if(captureObj.stageIsClosedWonTF) {
              wonCaptureIDsTheyPrimeWeSubArray.push(captureObj.captureID);
            }
            if(captureObj.stageIsActiveTF) {
              activeCaptureIDsTheyPrimeWeSubArray.push(captureObj.captureID);
            }
            if(captureObj.stageIsClosedLostTF) {
              lostCaptureIDsTheyPrimeWeSubArray.push(captureObj.captureID);
            }
          }
        }
      }

      //copy all fields in the contact company obj
      var advSearchFilterContactCompanyObj = JSFUNC.copy_obj(companyObj);

      //convert some field comma list to arrays for easier filter comparisons
      advSearchFilterContactCompanyObj.sbCertificationsBmSetAsideIDsArray = JSFUNC.convert_comma_list_to_int_array(advSearchFilterContactCompanyObj.sb_certifications_bm_set_aside_ids_comma);
      advSearchFilterContactCompanyObj.capabilityIDsArray = JSFUNC.convert_comma_list_to_int_array(advSearchFilterContactCompanyObj.capability_ids_comma);
      advSearchFilterContactCompanyObj.naicsCodeIDsArray = JSFUNC.convert_comma_list_to_int_array(advSearchFilterContactCompanyObj.naics_code_ids_comma);

      advSearchFilterContactCompanyObj.wonCaptureIDsWePrimeTheySubArray = wonCaptureIDsWePrimeTheySubArray;
      advSearchFilterContactCompanyObj.activeCaptureIDsWePrimeTheySubArray = activeCaptureIDsWePrimeTheySubArray;
      advSearchFilterContactCompanyObj.lostCaptureIDsWePrimeTheySubArray = lostCaptureIDsWePrimeTheySubArray;
      advSearchFilterContactCompanyObj.wonCaptureIDsTheyPrimeWeSubArray = wonCaptureIDsTheyPrimeWeSubArray;
      advSearchFilterContactCompanyObj.activeCaptureIDsTheyPrimeWeSubArray = activeCaptureIDsTheyPrimeWeSubArray;
      advSearchFilterContactCompanyObj.lostCaptureIDsTheyPrimeWeSubArray = lostCaptureIDsTheyPrimeWeSubArray;

      advSearchFilterContactCompanyObj.numWonCapturesWePrimeTheySub = wonCaptureIDsWePrimeTheySubArray.length;
      advSearchFilterContactCompanyObj.numActiveCapturesWePrimeTheySub = activeCaptureIDsWePrimeTheySubArray.length;
      advSearchFilterContactCompanyObj.numLostCapturesWePrimeTheySub = lostCaptureIDsWePrimeTheySubArray.length;
      advSearchFilterContactCompanyObj.numWonCapturesTheyPrimeWeSub = wonCaptureIDsTheyPrimeWeSubArray.length;
      advSearchFilterContactCompanyObj.numActiveCapturesTheyPrimeWeSub = activeCaptureIDsTheyPrimeWeSubArray.length;
      advSearchFilterContactCompanyObj.numLostCapturesTheyPrimeWeSub = lostCaptureIDsTheyPrimeWeSubArray.length;

      advSearchFilterContactCompanyObj.numWonCapturesTotal = (advSearchFilterContactCompanyObj.numWonCapturesWePrimeTheySub + advSearchFilterContactCompanyObj.numWonCapturesTheyPrimeWeSub);
      advSearchFilterContactCompanyObj.numActiveCapturesTotal = (advSearchFilterContactCompanyObj.numActiveCapturesWePrimeTheySub + advSearchFilterContactCompanyObj.numActiveCapturesTheyPrimeWeSub);
      advSearchFilterContactCompanyObj.numLostCapturesTotal = (advSearchFilterContactCompanyObj.numLostCapturesWePrimeTheySub + advSearchFilterContactCompanyObj.numLostCapturesTheyPrimeWeSub);

      allContactCompaniesArrayOfObjs.push(advSearchFilterContactCompanyObj);
    }

    const sortFieldNamesArray = ["numWonCapturesTotal", "numActiveCapturesTotal", "numLostCapturesTotal", "name"];
    const sortDirectionsTFArray = [false, false, false, true];
    JSFUNC.sort_arrayOfObjs(allContactCompaniesArrayOfObjs, sortFieldNamesArray, sortDirectionsTFArray);

    return(allContactCompaniesArrayOfObjs);
  }

  get c_advSearchBusinessTypeFilterIsActiveTF() { return(JSFUNC.selectmulti_is_filled_out_tf(this.o_advSearchCompanyFilterSelectedBusinessTypeIDsComma)); }
  get c_advSearchSBCertificationsFilterIsActiveTF() { return(JSFUNC.selectmulti_is_filled_out_tf(this.o_advSearchCompanyFilterSelectedSBCertificationIDsComma)); }
  get c_advSearchCapabilitiesFilterIsActiveTF() { return(JSFUNC.selectmulti_is_filled_out_tf(this.o_advSearchCompanyFilterSelectedCapabilityIDsComma)); }
  get c_advSearchNaicsCodesFilterIsActiveTF() { return(JSFUNC.selectmulti_is_filled_out_tf(this.o_advSearchCompanyFilterSelectedNaicsCodeIDsComma)); }
  get c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF() { return(JSFUNC.select_int_is_filled_out_tf(this.o_advSearchCompanyFilterSelectedContactCompanyExtraFieldID)) }

  get c_advSearchCaptureDateFilterIsActiveTF() {
    return(JSFUNC.select_int_is_filled_out_tf(this.o_advSearchCaptureFilterSelectedDateFieldID) && JSFUNC.date_is_filled_out_tf(this.o_advSearchCaptureFilterSelectedEarliestDate));
  }

  get c_advSearchCompanyFilterSelectedBusinessTypeIDsArray() { return(JSFUNC.convert_comma_list_to_int_array(this.o_advSearchCompanyFilterSelectedBusinessTypeIDsComma)); }
  get c_advSearchCompanyFilterSelectedSBCertificationIDsArray() { return(JSFUNC.convert_comma_list_to_int_array(this.o_advSearchCompanyFilterSelectedSBCertificationIDsComma)); }
  get c_advSearchCompanyFilterSelectedCapabilityIDsArray() { return(JSFUNC.convert_comma_list_to_int_array(this.o_advSearchCompanyFilterSelectedCapabilityIDsComma)); }
  get c_advSearchCompanyFilterSelectedNaicsCodeIDsArray() { return(JSFUNC.convert_comma_list_to_int_array(this.o_advSearchCompanyFilterSelectedNaicsCodeIDsComma)); }

  get c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined() {
    if(this.c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF) {
      return(DatabaseMobx.o_tbl_a_contacts_companies_extra_fields.get(this.o_advSearchCompanyFilterSelectedContactCompanyExtraFieldID));
    }
    return(undefined);
  }

  get c_advSearchAnyFilterIsActiveTF() {
    return(this.c_advSearchBusinessTypeFilterIsActiveTF || this.c_advSearchSBCertificationsFilterIsActiveTF || this.c_advSearchCapabilitiesFilterIsActiveTF || this.c_advSearchNaicsCodesFilterIsActiveTF || this.c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF || this.c_advSearchCaptureDateFilterIsActiveTF);
  }

  get c_advSearchFilteredContactCompaniesArrayOfObjs() {
    if(!this.c_advSearchAnyFilterIsActiveTF) {
      return([]);
    }

    //get lowercase contact company extra fields selected field and search term if that filter is currently applied
    var selectedContactCompanyExtraFieldIsValidTF = false;
    var selectedContactCompanyExtraFieldDbName = undefined;
    var contactCompanyExtraFieldSearchTextLowercase = "";
    var contactCompanyExtraFieldSearchTextIsNotBlankTF = false;
    if(this.c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF) {
      if(JSFUNC.text_or_number_is_filled_out_tf(this.o_advSearchCompanyFilterContactCompanyExtraFieldSearchText)) {
        if(this.c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined !== undefined) { //if a valid contact company extra field was selected to be the filter
          selectedContactCompanyExtraFieldIsValidTF = true;
          selectedContactCompanyExtraFieldDbName = this.c_advSearchCompanyFilterSelectedContactCompanyExtraFieldMapOrUndefined.get("db_name");
          contactCompanyExtraFieldSearchTextLowercase = this.o_advSearchCompanyFilterContactCompanyExtraFieldSearchText.toLowerCase();
          contactCompanyExtraFieldSearchTextIsNotBlankTF = true;
        }
      }
    }

    var advSearchFilteredContactCompaniesArrayOfObjs = [];
    for(let advSearchFilterContactCompanyObj of this.c_advSearchAllContactCompaniesArrayOfObjs) {
      var contactMatchesAllActiveFiltersTF = true;

      if(contactMatchesAllActiveFiltersTF && this.c_advSearchBusinessTypeFilterIsActiveTF) {
        contactMatchesAllActiveFiltersTF = JSFUNC.in_array(advSearchFilterContactCompanyObj.business_type_id, this.c_advSearchCompanyFilterSelectedBusinessTypeIDsArray);
      }

      if(contactMatchesAllActiveFiltersTF && this.c_advSearchSBCertificationsFilterIsActiveTF) {
        contactMatchesAllActiveFiltersTF = JSFUNC.any_of_array1_is_in_array2(advSearchFilterContactCompanyObj.sbCertificationsBmSetAsideIDsArray, this.c_advSearchCompanyFilterSelectedSBCertificationIDsArray);
      }

      if(contactMatchesAllActiveFiltersTF && this.c_advSearchCapabilitiesFilterIsActiveTF) {
        contactMatchesAllActiveFiltersTF = JSFUNC.any_of_array1_is_in_array2(advSearchFilterContactCompanyObj.capabilityIDsArray, this.c_advSearchCompanyFilterSelectedCapabilityIDsArray);
      }

      if(contactMatchesAllActiveFiltersTF && this.c_advSearchNaicsCodesFilterIsActiveTF) {
        contactMatchesAllActiveFiltersTF = JSFUNC.any_of_array1_is_in_array2(advSearchFilterContactCompanyObj.naicsCodeIDsArray, this.c_advSearchCompanyFilterSelectedNaicsCodeIDsArray);
      }

      if(contactMatchesAllActiveFiltersTF && this.c_advSearchContactCompanyExtraFieldSearchTextFilterIsActiveTF) {
        contactMatchesAllActiveFiltersTF = false;
        if(selectedContactCompanyExtraFieldIsValidTF) {
          if(contactCompanyExtraFieldSearchTextIsNotBlankTF) {
            var contactCompanyExtraFieldValue = advSearchFilterContactCompanyObj[selectedContactCompanyExtraFieldDbName];
            if(JSFUNC.is_string(contactCompanyExtraFieldValue)) {
              contactMatchesAllActiveFiltersTF = JSFUNC.input_string_converted_to_lowercase_contains_lowercase_search_term_string_tf(contactCompanyExtraFieldValue, contactCompanyExtraFieldSearchTextLowercase);
            }
          }
        }
      }

      if(contactMatchesAllActiveFiltersTF) {
        advSearchFilteredContactCompaniesArrayOfObjs.push(advSearchFilterContactCompanyObj);
      }
    }
    return(advSearchFilteredContactCompaniesArrayOfObjs);
  }

  get c_advSearchAnalysisCapturesArrayOfObjs() {
    var advSearchAnalysisCapturesArrayOfObjs = [];
    if(this.o_openContactID > 0) {
      const todayJsDateObj = new Date();
      const averageNumDaysPerMonth = (365.25 / 12);

      for(let captureID of this.o_advSearchCaptureIDsArray) {
        var captureMap = DatabaseMobx.o_tbl_captures.get(captureID);
        if(captureMap !== undefined) {
          var captureTypeIsPrimeTF = DatabaseMobx.capture_type_is_prime_tf_from_capture_map(captureMap);

          var captureName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);

          var awardDateValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfAwardDate);
          var numMonthsTodayToAwardDate = undefined;
          if(awardDateValueMaskSortIfoCanEditObj.isFilledOutTF) {
            var awardDateJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(awardDateValueMaskSortIfoCanEditObj.valueRaw);
            var numDaysTodayToAwardDate = JSFUNC.num_days_from_jsDateObj1_to_jsDateObj2(todayJsDateObj, awardDateJsDateObj);
            numMonthsTodayToAwardDate = (numDaysTodayToAwardDate / averageNumDaysPerMonth);
          }

          var covValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfContractOverallValue);
          var covValueRaw = 0;
          if(covValueMaskSortIfoCanEditObj.isFilledOutTF) {
            covValueRaw = covValueMaskSortIfoCanEditObj.valueRaw;
          }

          var stageValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfStage);

          var expandedTeammateObj = {};
          var ourDivisionMinus2TeammateObj = {};
          var ourPrimeSubTeammateDivisionValueMaskSortIfoCanEditObj = undefined;
          var ourPrimeSubTeammateAllocationValueMaskSortIfoCanEditObj = undefined;
          var ourAllocationMoneyShort = undefined;
          if(captureTypeIsPrimeTF) { //we are prime, get teammate record as a sub
            expandedTeammateObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_advSearchExpandedSelectedTeammatesArrayOfObjs, ["capture_id", "contact_company_id"], [captureID, this.o_openContactID]);
          }
          else {
            ourDivisionMinus2TeammateObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(this.c_advSearchExpandedSelectedTeammatesArrayOfObjs, ["capture_id", "contact_company_id"], [captureID, -2]);
            if(ourDivisionMinus2TeammateObj === undefined) {
              ourDivisionMinus2TeammateObj = {};
            }
            ourPrimeSubTeammateDivisionValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateDivisionID);
            ourPrimeSubTeammateAllocationValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfOurPrimeSubTeammateAllocation);
            ourAllocationMoneyShort = JSFUNC.money_short(covValueRaw * (ourPrimeSubTeammateAllocationValueMaskSortIfoCanEditObj.valueRaw / 100));
          }

          advSearchAnalysisCapturesArrayOfObjs.push({
            expandedTeammateObj: expandedTeammateObj,
            ourDivisionMinus2TeammateObj: ourDivisionMinus2TeammateObj,
            ourPrimeSubTeammateDivisionValueMaskSortIfoCanEditObj: ourPrimeSubTeammateDivisionValueMaskSortIfoCanEditObj,
            ourPrimeSubTeammateAllocationValueMaskSortIfoCanEditObj: ourPrimeSubTeammateAllocationValueMaskSortIfoCanEditObj,
            ourAllocationMoneyShort: ourAllocationMoneyShort,
            captureID: captureID,
            captureMap: captureMap,
            captureName: captureName,
            captureTypeIsPrimeTF: captureTypeIsPrimeTF,
            awardDateIsFilledOutTF: awardDateValueMaskSortIfoCanEditObj.isFilledOutTF,
            awardDateValueRaw: awardDateValueMaskSortIfoCanEditObj.valueRaw,
            awardDateValueMask: awardDateValueMaskSortIfoCanEditObj.valueMask,
            awardDateValueMaskPlainText: awardDateValueMaskSortIfoCanEditObj.valueMaskPlainText,
            awardDateValueSort: awardDateValueMaskSortIfoCanEditObj.valueSort,
            numMonthsTodayToAwardDate: numMonthsTodayToAwardDate,
            covValueRaw: covValueRaw,
            covValueMask: covValueMaskSortIfoCanEditObj.valueMask,
            covValueMaskPlainText: covValueMaskSortIfoCanEditObj.valueMaskPlainText,
            stageValueMask: stageValueMaskSortIfoCanEditObj.valueMask
          });
        }
      }

      JSFUNC.sort_arrayOfObjs(advSearchAnalysisCapturesArrayOfObjs, "awardDateValueSort", false);
    }
    return(advSearchAnalysisCapturesArrayOfObjs);
  }

  get c_advSearchAnalysisScatterDataObj() {
    const captureIDFieldDisplayName = DatabaseMobx.c_fieldMapOfCaptureID.get("display_name");
    const awardDateFieldDisplayName = DatabaseMobx.c_fieldMapOfAwardDate.get("display_name");

    var maxXValue = -999999999999;
    var xyPointsArrayOfObjs = [];
    for(let advSearchAnalysisCaptureObj of this.c_advSearchAnalysisCapturesArrayOfObjs) {
      if(advSearchAnalysisCaptureObj.awardDateIsFilledOutTF) {
        xyPointsArrayOfObjs.push({
          x: advSearchAnalysisCaptureObj.numMonthsTodayToAwardDate,
          y: advSearchAnalysisCaptureObj.covValueRaw,
          title: captureIDFieldDisplayName + ": " + advSearchAnalysisCaptureObj.captureID + "\n" + advSearchAnalysisCaptureObj.captureName + "\n" + advSearchAnalysisCaptureObj.covValueMaskPlainText + "\n" + awardDateFieldDisplayName + ": " + advSearchAnalysisCaptureObj.awardDateValueMaskPlainText,
          clickReturnValue: advSearchAnalysisCaptureObj.captureID
        });

        if(advSearchAnalysisCaptureObj.numMonthsTodayToAwardDate > maxXValue) {
          maxXValue = advSearchAnalysisCaptureObj.numMonthsTodayToAwardDate;
        }
      }
    }

    var color = "aed";
    var label = "Active";
    if(JSFUNC.in_array(this.o_advSearchTeamingCategory, ["theirAsOurWins", "usAsTheirWins"])) {
      color = "66f666";
      label = "Wins";
    }
    else if(JSFUNC.in_array(this.o_advSearchTeamingCategory, ["theirAsOurLosses", "usAsTheirLosses"])) {
      color = "e77";
      label = "Losses";
    }

    return({
      xyPointsArrayOfObjs: xyPointsArrayOfObjs,
      label: label,
      color: color,
      maxXValue: maxXValue
    });
  }









  //========================================================================================
  //actions
  a_initialize_initial_and_current_selected_contact_ids_arrays(i_selectMode, i_selectedContactIDOrContactIDsComma) {
    const [isSelectingPersonsTFU, isMultiSelectTFU] = this.is_person_and_is_multiselect_tfu_from_select_mode(i_selectMode);
    var initialSelectedContactIDsArray = undefined;
    if(isMultiSelectTFU) { //multiselect input expects initial value to be a string comma list of contactIDs
      initialSelectedContactIDsArray = JSFUNC.convert_comma_list_to_int_array(i_selectedContactIDOrContactIDsComma);
    }
    else { //single select input expects initial value to be a single contactID as an int, -1 if nothing is selected, or undefined meaning nothing is selected
      if(JSFUNC.select_int_is_filled_out_tf(i_selectedContactIDOrContactIDsComma)) {
        initialSelectedContactIDsArray = [i_selectedContactIDOrContactIDsComma]; //create an array of the 1 selected contactID
      }
      else {
        initialSelectedContactIDsArray = [];
      }
    }

    //set the initial and current selected arrays to this initial value array
    this.o_initialSelectedContactIDsArray = initialSelectedContactIDsArray;
    this.o_currentSelectedContactIDsArray = initialSelectedContactIDsArray;
  }

  a_initialize_contacts_system() {
    this.o_leftSideState = "treeWithSearch"; //"treeWithSearch", "advancedCompanySearch" (testing), "exportContactsToCsv" (testing)
    this.o_searchText = "";
    this.o_orgsExpandedCompanyIDsArray = [];
    this.o_leftSideNumOrgsDrawnLimit = 50;
    this.o_leftSideZoomedCompanyID = undefined;
    this.o_advSearchCompanyFilterSelectedBusinessTypeIDsComma = ""; //"", "1,2,3" (testing)
    this.o_advSearchCaptureFilterSelectedDateFieldID = DatabaseMobx.c_fieldMapOfAwardDate.get("id");
    this.o_advSearchCaptureFilterSelectedEarliestDate = JSFUNC.blank_date();
    this.o_advSearchNumResultsDrawnLimit = 50;

    this.o_rightSideState = "empty"; //"empty", "viewEditSingleContact", "advancedSearchCapturesAnalysis"
    this.o_openContactID = undefined; //undefined, 2
    this.o_openContactIsPersonTF = undefined; //undefined, false
    this.o_openContactTeammatesRatingsCapturesFloatingBoxOpenTF = false;
    this.o_openContactSubtabLoadingFromDatabaseMessageOrUndefined = undefined;
    this.o_openContactTeammatesRatingsSubmissionsArrayOfObjs = [];
    this.o_openContactPersonConversationsArrayOfObjs = [];
    this.o_openContactPersonConversationsFilterByCaptureNameText = "";
    this.o_openContactPersonConversationsFilterByDateMin = JSFUNC.blank_date();
    this.o_openContactPersonConversationsFilterByDateMax = JSFUNC.blank_date();
    this.o_openContactPersonConversationsFilterByNotesText = "";
    this.o_openContactLoadingDocsTF = false;
    this.o_openContactViewEditSingleContactSelectedTabDbName = "contactInfo";
    this.o_advSearchTeamingCategory = undefined; //undefined, "usAsTheirActive"
    this.o_advSearchCaptureIDsArray = []; //[], [1], [1,2,3,4]

    //reset variable in CaptureExecMobx for which field is currently being edited
    CaptureExecMobx.o_itemEditingContactFieldUniqueString = undefined;
  }

  a_set_left_side_state(i_newStateString) {
    this.o_leftSideState = i_newStateString;
  }

  a_set_right_side_state(i_newStateString) {
    this.o_rightSideState = i_newStateString;
  }

  a_set_search_text(i_changedValue) {
    this.o_searchText = i_changedValue;
  }

  a_expand_collapse_company(i_companyID) {
    if(JSFUNC.in_array(i_companyID, this.o_orgsExpandedCompanyIDsArray)) { //the org is currently expanded, remove the id to collapse it
      //find all subdivisions below the company being collapsed and collapse all of their id numbers as well
      var companyIDsToRemoveArray = [i_companyID]; //initialize
      var companyObj = this.contact_company_or_person_obj_from_id(false, i_companyID);
      var allOrgDivisionsArrayOfObjs = undefined;
      if(companyObj.org_topdiv_id === -1) { //if this is a top org division, find all subdivision ids and remove them all
        allOrgDivisionsArrayOfObjs = this.all_companies_within_org_id_arrayOfObjs(i_companyID);
        var allOrgDivisionIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(allOrgDivisionsArrayOfObjs, "id");
        companyIDsToRemoveArray = JSFUNC.merge_unique(companyIDsToRemoveArray, allOrgDivisionIDsArray); //add the top org id to be removed with all of the subdivision ids
      }
      else { //a division was collapsed, only collapse that division and all of its subdivisions based on tree_id
        var collapsingCompanyTreeID = companyObj.tree_id;
        var collapsingCompanyTreeIDLength = collapsingCompanyTreeID.length;
        allOrgDivisionsArrayOfObjs = this.all_companies_within_org_id_arrayOfObjs(companyObj.org_topdiv_id);
        for(let orgDivisionObj of allOrgDivisionsArrayOfObjs) {
          var orgDivisionTreeID = orgDivisionObj.tree_id;
          if(orgDivisionTreeID.substring(0, collapsingCompanyTreeIDLength) === collapsingCompanyTreeID) {
            companyIDsToRemoveArray.push(orgDivisionObj.id);
          }
        }
      }
      this.o_orgsExpandedCompanyIDsArray = JSFUNC.remove_all_values_from_array(companyIDsToRemoveArray, this.o_orgsExpandedCompanyIDsArray);
    }
    else {
      this.o_orgsExpandedCompanyIDsArray.push(i_companyID);
    }
  }

  a_set_left_side_num_orgs_drawn_to_unlimited() {
    this.o_leftSideNumOrgsDrawnLimit = JSFUNC.sort_max_mysqli_int();
  }

  a_set_left_side_zoomed_org_id_from_is_person_tf_and_contact_id(i_isPersonTF, i_contactID) {
    if(i_isPersonTF) {
      this.a_set_left_side_zoomed_org_id_from_person_id(i_contactID);
    }
    else {
      this.a_set_left_side_zoomed_org_id_from_company_id(i_contactID);
    }
  }

  a_set_left_side_zoomed_org_id_from_company_id(i_contactCompanyID) {
    //find the contact company obj from the input id
    var contactCompanyObj = undefined;
    for(let tempContactCompanyObj of this.c_allCompaniesArrayOfObjs) {
      if(tempContactCompanyObj.id === i_contactCompanyID) {
        contactCompanyObj = tempContactCompanyObj;
        break;
      }
    }

    //if the company is found, us its organizationID to set the zoomed org
    if(contactCompanyObj !== undefined) {
      this.a_set_left_side_zoomed_org_id_from_org_id(contactCompanyObj.organizationID);
    }
  }

  a_set_left_side_zoomed_org_id_from_person_id(i_contactPersonID) {
    //find the contact company obj from the input id
    var contactPersonObj = undefined;
    for(let tempContactPersonObj of this.c_allPersonsArrayOfObjs) {
      if(tempContactPersonObj.id === i_contactPersonID) {
        contactPersonObj = tempContactPersonObj;
        break;
      }
    }

    //if the company is found, us its organizationID to set the zoomed org
    if(contactPersonObj !== undefined) {
      this.a_set_left_side_zoomed_org_id_from_org_id(contactPersonObj.organizationID);
    }
  }

  a_set_left_side_zoomed_org_id_from_org_id(i_orgCompanyID) {
    this.o_leftSideZoomedCompanyID = i_orgCompanyID;

    const allDivisionsInOrgArrayOfObjs = this.all_companies_within_org_id_arrayOfObjs(i_orgCompanyID);
    var zoomedOrgsExpandedCompanyIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(allDivisionsInOrgArrayOfObjs, "id");
    zoomedOrgsExpandedCompanyIDsArray.push(i_orgCompanyID); //add the top org itself as expanded
    this.o_orgsExpandedCompanyIDsArray = zoomedOrgsExpandedCompanyIDsArray;
  }

  a_open_contact(i_isPersonTF, i_contactID) {
    if(!((this.o_openContactIsPersonTF === i_isPersonTF) && (this.o_openContactID === i_contactID))) { //no need to reopen the contact if it's already open
      //set contacts system flags to open contact on right side
      this.o_rightSideState = "viewEditSingleContact";
      this.o_openContactIsPersonTF = i_isPersonTF;
      this.o_openContactTeammatesRatingsCapturesFloatingBoxOpenTF = false;
      this.o_openContactSubtabLoadingFromDatabaseMessageOrUndefined = undefined;
      this.o_openContactTeammatesRatingsSubmissionsArrayOfObjs = [];
      this.o_openContactPersonConversationsArrayOfObjs = [];
      this.o_openContactPersonConversationsFilterByCaptureNameText = "";
      this.o_openContactPersonConversationsFilterByDateMin = JSFUNC.blank_date();
      this.o_openContactPersonConversationsFilterByDateMax = JSFUNC.blank_date();
      this.o_openContactPersonConversationsFilterByNotesText = "";
      this.o_openContactLoadingDocsTF = true;
      this.o_openContactViewEditSingleContactSelectedTabDbName = "contactInfo";
      this.o_openContactID = i_contactID;

      //load only the documents for this contact from the server
      const loadContactDocsDatabaseApiString = ((i_isPersonTF) ? ("openContactPersonLoadFilteredDocs") : ("openContactCompanyLoadFilteredDocs"));
      const contactDocsFfsTblName = ((i_isPersonTF) ? ("tbl_g_contacts_persons_filefoldersystem") : ("tbl_g_contacts_companies_filefoldersystem"));
      const loadContactDocsDatabaseApiInputString = ((i_isPersonTF) ? ("i_contactPersonID") : ("i_contactCompanyID"));

      const jsDescription = JSFUNC.js_description_from_action("ContactsMobx", "a_open_contact", ["i_isPersonTF", "i_contactID"], [i_isPersonTF, i_contactID]);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript(loadContactDocsDatabaseApiString, jsDescription);
      C_CallPhpScript.add_post_var(loadContactDocsDatabaseApiInputString, i_contactID);
      C_CallPhpScript.add_return_vars("docsMatrix");

      const functionOnSuccess = (i_parseResponse) => {
        const clearOldMapDataFirstTF = true;
        DatabaseMobx.a_insert_or_update_local_data_map(contactDocsFfsTblName, i_parseResponse.docsMatrix, clearOldMapDataFirstTF, jsDescription);
      }
      C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

      const functionOnFinish = () => {
        this.a_set_open_contact_loading_docs_tf(false);
      }
      C_CallPhpScript.add_function("onFinish", functionOnFinish);

      C_CallPhpScript.execute();
    }
  }

  a_close_contact() {
    //if the right side was selecting the parent company for a contact, force the left side back to treeWithSearch when closing
    if(this.o_leftSideState === "selectParentDivision") {
      this.o_leftSideState = "treeWithSearch";
    }

    this.o_rightSideState = "empty";
    this.o_openContactIsPersonTF = undefined;
    this.o_openContactTeammatesRatingsCapturesFloatingBoxOpenTF = undefined;
    this.o_openContactSubtabLoadingFromDatabaseMessageOrUndefined = undefined;
    this.o_openContactTeammatesRatingsSubmissionsArrayOfObjs = [];
    this.o_openContactPersonConversationsArrayOfObjs = [];
    this.o_openContactPersonConversationsFilterByCaptureNameText = "";
    this.o_openContactPersonConversationsFilterByDateMin = JSFUNC.blank_date();
    this.o_openContactPersonConversationsFilterByDateMax = JSFUNC.blank_date();
    this.o_openContactPersonConversationsFilterByNotesText = "";
    this.o_openContactLoadingDocsTF = undefined;
    this.o_openContactViewEditSingleContactSelectedTabDbName = "contactInfo";
    this.o_openContactID = undefined;
  }

  a_set_open_contact_teammates_ratings_captures_floating_box_open_tf(i_newValueTF) {
    this.o_openContactTeammatesRatingsCapturesFloatingBoxOpenTF = i_newValueTF;

    if(i_newValueTF && (this.c_openContactObj !== undefined)) { //opening the floating box, need to load the submissions records from the database
      this.a_set_open_contact_subtab_loading_from_database_message_or_undefined("Loading Teammate Reviews");

      var columnDbNamesToLoadArray = ["id", "capture_id", "reviewed_by_user_id", "datetime_utc"];
      for(let teammatesRatingsQuestionObj of DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs) {
        columnDbNamesToLoadArray.push("q" + teammatesRatingsQuestionObj.id + "a");
        columnDbNamesToLoadArray.push("q" + teammatesRatingsQuestionObj.id + "c");
      }
      const columnDbNamesToLoadComma = JSFUNC.convert_array_to_comma_list(columnDbNamesToLoadArray);

      const jsDescription = JSFUNC.js_description_from_action("ContactsMobx", "a_set_open_contact_teammates_ratings_captures_floating_box_open_tf", ["i_newValueTF"], [i_newValueTF]);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadTeammateRatingsFromContactCompanyID", jsDescription);

      C_CallPhpScript.add_post_var("i_contactCompanyID", this.c_openContactObj.id);
      C_CallPhpScript.add_post_var("i_columnDbNamesToLoadComma", columnDbNamesToLoadComma);

      C_CallPhpScript.add_return_vars("teammatesRatingsQuestionnaireSubmissionsMatrix");
      
      const functionOnSuccess = (i_parseResponse) => {
        this.a_set_open_contact_teammates_ratings_submissions_arrayOfObjs(i_parseResponse.teammatesRatingsQuestionnaireSubmissionsMatrix);
      }
      C_CallPhpScript.add_function("onSuccess", functionOnSuccess);
  
      const functionOnFinish = () => {
        this.a_set_open_contact_subtab_loading_from_database_message_or_undefined(undefined);
      }
      C_CallPhpScript.add_function("onFinish", functionOnFinish);

      C_CallPhpScript.execute();
    }
    else { //closing the floating box, clear the submissions records from local memory
      this.a_set_open_contact_subtab_loading_from_database_message_or_undefined(undefined);
      this.a_set_open_contact_teammates_ratings_submissions_arrayOfObjs([]);
    }
  }

  a_set_open_contact_subtab_loading_from_database_message_or_undefined(i_newValueStringOrUndefined) {
    this.o_openContactSubtabLoadingFromDatabaseMessageOrUndefined = i_newValueStringOrUndefined;
  }

  a_set_open_contact_teammates_ratings_submissions_arrayOfObjs(i_arrayOfObjs) {
    this.o_openContactTeammatesRatingsSubmissionsArrayOfObjs = i_arrayOfObjs;
  }


  a_contact_person_conversations_load_all_person_conversation_history(i_contactPersonID) {
    const c_openContactObj = this.c_openContactObj;

    const contactPersonName = this.contact_name_plaintext_from_contact_obj(c_openContactObj);
    this.a_set_open_contact_subtab_loading_from_database_message_or_undefined("Loading all conversations for Contact Person '" + contactPersonName + "'");

    const jsDescription = JSFUNC.js_description_from_action("ContactsMobx", "a_contact_person_conversations_load_all_person_conversation_history", ["i_contactPersonID"], [i_contactPersonID]);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadConversationsFromContactPersonID", jsDescription);

    C_CallPhpScript.add_post_var("i_contactPersonID", i_contactPersonID);

    C_CallPhpScript.add_return_vars("conversationsMatrix");

    const functionOnSuccess = (i_parseResponse) => {
      this.a_set_open_contact_person_conversations_arrayOfObjs(i_parseResponse.conversationsMatrix);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnFinish = () => {
      this.a_set_open_contact_subtab_loading_from_database_message_or_undefined(undefined);
    }
    C_CallPhpScript.add_function("onFinish", functionOnFinish);

    C_CallPhpScript.execute();
  }

  a_set_open_contact_person_conversations_arrayOfObjs(i_arrayOfObjs) {
    this.o_openContactPersonConversationsArrayOfObjs = i_arrayOfObjs;
  }

  a_set_open_contact_person_conversations_filter_by_capture_name_text(i_newValueString) {
    this.o_openContactPersonConversationsFilterByCaptureNameText = i_newValueString;
  }

  a_set_open_contact_person_conversations_filter_by_date_min(i_newValueDateYmd) {
    this.o_openContactPersonConversationsFilterByDateMin = i_newValueDateYmd;
  }

  a_set_open_contact_person_conversations_filter_by_date_max(i_newValueDateYmd) {
    this.o_openContactPersonConversationsFilterByDateMax = i_newValueDateYmd;
  }

  a_set_open_contact_person_conversations_filter_by_notes_text(i_newValueString) {
    this.o_openContactPersonConversationsFilterByNotesText = i_newValueString;
  }


  a_set_open_contact_loading_docs_tf(i_newValueTF) {
    this.o_openContactLoadingDocsTF = i_newValueTF;
  }


  a_set_open_contact_view_edit_single_contact_selected_tab_db_name(i_newValue) {
    const c_openContactObj = this.c_openContactObj;

    this.o_openContactViewEditSingleContactSelectedTabDbName = i_newValue;

    //if opening the contact person conversations tab, load the conversations data
    if(i_newValue === "contactConversations") {
      this.a_contact_person_conversations_load_all_person_conversation_history(c_openContactObj.id);
    }
  }

  a_set_selected_contact_ids_array(i_newContactIDsArray) {
    this.o_currentSelectedContactIDsArray = i_newContactIDsArray;
  }

  a_select_contact(i_contactID, i_selectMode) { //toggle switch when clicking a select contact checkbox, unknown if the box is checked or not
    if(JSFUNC.in_array(i_contactID, this.o_currentSelectedContactIDsArray)) { //if the contactID has already been selected, remove all occurances of it to unselect it
      this.a_remove_contact_from_selection(i_contactID);
    }
    else {
      const [isSelectingPersonsTFU, isMultiSelectTFU] = this.is_person_and_is_multiselect_tfu_from_select_mode(i_selectMode);
      if(isMultiSelectTFU || this.o_currentSelectedContactIDsArray.length === 0) { //add the first selected contact, or add more if in multiselect mode
        this.o_currentSelectedContactIDsArray.push(i_contactID);
      }
      else { //do not allow adding a 2nd selected contact if this is a single select mode, instead overwrite the previous selection with the new one
        this.o_currentSelectedContactIDsArray = [i_contactID];
      }

      //also open the newly selected contact on the right side when selecting a contact
      this.a_open_contact(isSelectingPersonsTFU, i_contactID);
    }
  }

  a_remove_contact_from_selection(i_contactIdToRemove) {
    this.o_currentSelectedContactIDsArray = JSFUNC.remove_all_values_from_array(i_contactIdToRemove, this.o_currentSelectedContactIDsArray);
  }

  a_delete_contact(i_isPersonTF, i_contactID) {
    //if the contact being deleted is currently open being viewed, close it before deleting
    if(this.o_openContactIsPersonTF === i_isPersonTF && this.o_openContactID === i_contactID) {
      this.a_close_contact();
    }

    const tblName = this.contacts_tbl_name_from_is_person_tf(i_isPersonTF);

    const jsDescription = JSFUNC.js_description_from_action("ContactsMobx", "a_delete_contact", ["i_contactID", "i_isPersonTF"], [i_contactID, i_isPersonTF]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_delete(tblName, i_contactID); //no sort column for contacts (sorted by name), no need to resort after delete

    //deleting a contact company requires recalculating teammate counts/allocations for all captures that had this contact as a teammate
    if(!i_isPersonTF) {
      const functionOnSuccess = (i_parseResponse) => {
        JSPHP.recalculate_and_update_capture_teammate_counts_and_allocations_from_contact_company_id(i_contactID);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    C_CallPhpTblUID.execute();
  }

  a_update_contact_field(i_isPersonTF, i_contactID, i_fieldDbName, i_newValue, i_fieldIdsb) {
    const updatedFieldIsBusinessTypeIDTF = (i_fieldDbName === "business_type_id");

    const tblName = this.contacts_tbl_name_from_is_person_tf(i_isPersonTF);

    var fieldNamesArray = [i_fieldDbName];
    var valuesArray = [i_newValue];
    var valuesIdsbArray = [i_fieldIdsb];

    //if setting the business size to large, erase any sb certifications selected back to ""
    if(updatedFieldIsBusinessTypeIDTF) {
      //look up the business type based on the i_newValue (business_type_id)
      const businessTypeMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_business_types", i_newValue);
      if(businessTypeMap !== undefined) {
        if(businessTypeMap.get("is_sb_type_01") !== 1) {
          fieldNamesArray.push("sb_certifications_bm_set_aside_ids_comma");
          valuesArray.push("");
          valuesIdsbArray.push("s");
        }
      }
    }

    const jsDescription = JSFUNC.js_description_from_action("ContactsMobx", "a_update_contact_field", ["i_isPersonTF", "i_contactID", "i_fieldDbName", "i_newValue", "i_fieldIdsb"], [i_isPersonTF, i_contactID, i_fieldDbName, i_newValue, i_fieldIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_update(tblName, i_contactID, fieldNamesArray, valuesArray, valuesIdsbArray);

    //updating business type for a contact company requires recalculating teammate counts/allocations for all captures that have this contact as a teammate
    if(!i_isPersonTF && updatedFieldIsBusinessTypeIDTF) {
      const functionOnSuccess = (i_parseResponse) => {
        JSPHP.recalculate_and_update_capture_teammate_counts_and_allocations_from_contact_company_id(i_contactID);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    C_CallPhpTblUID.execute();
  }

  a_update_contact_parent_company_field(i_newParentContactCompanyIDSelected) {
    if(this.c_openContactObj !== undefined) { //only perform this update if the openContactObj is defined to reference as the company/person being moved
      const isPersonTF = this.c_openContactObj.isPersonTF;
      const contactID = this.c_openContactObj.id;
      const tblName = this.contacts_tbl_name_from_is_person_tf(isPersonTF);

      const jsDescription = JSFUNC.js_description_from_action("ContactsMobx", "a_update_contact_parent_company_field", ["i_newParentContactCompanyIDSelected"], [i_newParentContactCompanyIDSelected]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      if(isPersonTF) { //changing a person's parent company
        C_CallPhpTblUID.add_update(tblName, contactID, "contact_company_id", i_newParentContactCompanyIDSelected, "i");
      }
      else { //changing a company's parent company (moves all children with them to the new location)
        const oldTreeIDLength = this.c_openContactObj.tree_id.length;
        var newOrgID = undefined;
        var newTreeID = undefined;
        if(i_newParentContactCompanyIDSelected <= 0) { //this company should be set as a top level company
          newOrgID = contactID;
          newTreeID = "";
          C_CallPhpTblUID.add_update(tblName, contactID, ["org_topdiv_id", "tree_id"], [-1, ""], ["i", "s"]);
        }
        else { //get info about parent company selected
          const newParentCompanyObj = this.contact_company_or_person_obj_from_id(false, i_newParentContactCompanyIDSelected);
          if(newParentCompanyObj.id > 0) { //if parent exists
            newOrgID = newParentCompanyObj.organizationID;
            const allCompaniesInNewOrgArrayOfObjs = this.all_companies_within_org_id_arrayOfObjs(newOrgID);
            newTreeID = JSFUNC.get_new_child_tree_id_from_arrayOfObjs(allCompaniesInNewOrgArrayOfObjs, newParentCompanyObj.tree_id);
            C_CallPhpTblUID.add_update(tblName, contactID, ["org_topdiv_id", "tree_id"], [newOrgID, newTreeID], ["i", "s"]);
          }
        }

        if(newTreeID !== undefined) {
          for(let childID of this.c_openContactAllChildrenIDsArray) {
            var childContactCompanyObj = this.contact_company_or_person_obj_from_id(false, childID);
            if(childContactCompanyObj.id > 0) {
              var childOldTreeID = childContactCompanyObj.tree_id;
              var childNewTreeID = newTreeID + childOldTreeID.substring(oldTreeIDLength, childOldTreeID.length);
              C_CallPhpTblUID.add_update(tblName, childContactCompanyObj.id, ["org_topdiv_id", "tree_id"], [newOrgID, childNewTreeID], ["i", "s"]);
            }
          }
        }
      }

      C_CallPhpTblUID.execute();
    }
  }

  a_insert_new_contact_from_contacts_system_creation_and_open_on_success() {
    const o_newContactObj = this.o_newContactObj;

    this.a_set_right_side_state("savingNewContact");

    const functionOnSuccess = (i_newlyInsertedContactID) => {
      //open newly created contact, sets the right side state to viewing the newly created contact
      this.a_open_contact(o_newContactObj.isPersonTF, i_newlyInsertedContactID);

      //set left side to selecting the parent division if a contact person was created
      if(o_newContactObj.isPersonTF) {
        this.a_set_left_side_state("selectParentDivision");
      }
    }

    const functionOnError = () => {
      this.a_set_right_side_state("empty");
    }

    this.a_insert_new_contact_from_new_contact_obj(o_newContactObj, functionOnSuccess, functionOnError);
  }

  a_insert_new_contact_from_new_contact_obj(i_newContactObj, i_functionOnSuccess=undefined, i_functionOnError=undefined) {
    const c_teammatesRatingsQuestionsArrayOfObjs = DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    const isPersonTF = i_newContactObj.isPersonTF;

    const jsDescription = JSFUNC.js_description_from_action("ContactsMobx", "a_insert_new_contact_from_new_contact_obj", ["i_newContactObj"], [i_newContactObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const tblName = this.contacts_tbl_name_from_is_person_tf(isPersonTF);
    const fieldsArrayOfObjs = this.contact_fields_arrayOfObjs(isPersonTF);

    var fieldDbNamesArray = [];
    var newValuesArray = [];
    var valuesIdsbArray = [];
    for(let contactExpandedFieldObj of fieldsArrayOfObjs) {
      fieldDbNamesArray.push(contactExpandedFieldObj.db_name);
      newValuesArray.push(i_newContactObj[contactExpandedFieldObj.db_name]);
      valuesIdsbArray.push(contactExpandedFieldObj.fieldTypeObj.idsb);
    }

    //insert initial values for the teammate ratings for contact companies
    if(!isPersonTF) {
      fieldDbNamesArray.push("tr_total");
      newValuesArray.push(-1); //initialize all rating values to -1 for N/A
      valuesIdsbArray.push("d");

      fieldDbNamesArray.push("tr_num_reviews");
      newValuesArray.push(0);
      valuesIdsbArray.push("i");

      for(let teammatesRatingsQuestionObj of c_teammatesRatingsQuestionsArrayOfObjs) {
        fieldDbNamesArray.push(teammatesRatingsQuestionObj.contactCompaniesRatingColumnDbName);
        newValuesArray.push(-1); //initialize all rating values to -1 for N/A
        valuesIdsbArray.push("d");
      }
    }

    C_CallPhpTblUID.add_insert(tblName, fieldDbNamesArray, newValuesArray, valuesIdsbArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedContactID = i_parseResponse.outputObj.i0;
      if(JSFUNC.is_number(newlyInsertedContactID) && (newlyInsertedContactID > 0)) { //i0 > 0 is a successful insert, "0" is returned if the insert failed
        if(JSFUNC.is_function(i_functionOnSuccess)) {
          i_functionOnSuccess(newlyInsertedContactID);
        }
      }
      else { //insert failed
        if(JSFUNC.is_function(i_functionOnError)) {
          i_functionOnError();
        }
      }
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.add_function("onError", i_functionOnError);

    C_CallPhpTblUID.execute();
  }

  a_initialize_new_contact(i_isPersonTF) {
    this.o_newContactObj = this.create_initialized_contact_obj(i_isPersonTF);
  }

  create_initialized_contact_obj(i_isPersonTF) {
    var newContactObj = {isPersonTF:i_isPersonTF};

    //initialize new contactObj with the blankValue for every field
    const contactFieldsArrayOfObjs = this.contact_fields_arrayOfObjs(i_isPersonTF);
    for(let contactExpandedFieldObj of contactFieldsArrayOfObjs) {
      newContactObj[contactExpandedFieldObj.db_name] = contactExpandedFieldObj.fieldTypeObj.blankValue;
    }

    //set a few other field initial values manually
    if(i_isPersonTF) {
      newContactObj.contact_company_id = -1;
    }
    else {
      newContactObj.org_topdiv_id = -1;
      newContactObj.tree_id = "";
      newContactObj.business_type_id = -1;
    }

    return(newContactObj);
  }

  a_update_new_contact_field_local(i_fieldDbName, i_newValue) {
    if(this.o_newContactObj !== undefined) {
      this.o_newContactObj[i_fieldDbName] = i_newValue;
      if(!this.o_newContactObj.isPersonTF && i_fieldDbName === "business_type_id") {
        const businessTypeMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_business_types", i_newValue);
        if(businessTypeMap !== undefined) {
          if(businessTypeMap.get("is_sb_type_01") !== 1) {
            this.o_newContactObj.sb_certifications_bm_set_aside_ids_comma = "";
          }
        }
      }
    }
  }

  a_update_new_contact_parent_company_field_local(i_newParentContactCompanyIDSelected) {
    if(this.o_newContactObj !== undefined) {
      if(this.o_newContactObj.isPersonTF) { //selecting parent for newly created  contact person
        this.o_newContactObj.contact_company_id = i_newParentContactCompanyIDSelected;
      }
      else { //selecting parent for newly created contact company
        if(i_newParentContactCompanyIDSelected <= 0) { //selected that this should be its own new top level division (-1), thus the treeID is ""
          this.o_newContactObj.org_topdiv_id = -1;
          this.o_newContactObj.tree_id = "";
        }
        else {
          const newParentCompanyObj = this.contact_company_or_person_obj_from_id(false, i_newParentContactCompanyIDSelected);
          if(newParentCompanyObj.id > 0) { //only compute the new treeID if the company selected was a valid company found within the array of all contact companies (-1 id returned from --Company Does Not Exist-- record), this should always be found when selecting a parent
            const allCompaniesInNewOrgArrayOfObjs = this.all_companies_within_org_id_arrayOfObjs(newParentCompanyObj.organizationID);
            const newTreeID = JSFUNC.get_new_child_tree_id_from_arrayOfObjs(allCompaniesInNewOrgArrayOfObjs, newParentCompanyObj.tree_id);

            this.o_newContactObj.org_topdiv_id = newParentCompanyObj.organizationID;
            this.o_newContactObj.tree_id = newTreeID;
          }
        }
      }
    }
  }






  //export
  a_export_contacts_to_csv_for_download(i_exportTopLevelContactCompaniesTF, i_exportSubdivisionContactCompaniesTF, i_exportContactPersonsTF, i_functionOnFinish) {
    const c_allPersonsArrayOfObjs = this.c_allPersonsArrayOfObjs;
    const c_allOrgsArrayOfObjs = this.c_allOrgsArrayOfObjs;
    const c_companyFieldsArrayOfObjs = this.c_companyFieldsArrayOfObjs;
    const c_personFieldsArrayOfObjs = this.c_personFieldsArrayOfObjs;

    var headerRowArray = [];
    var contactsArrayOfArrays = [];

    if(!i_exportTopLevelContactCompaniesTF && !i_exportSubdivisionContactCompaniesTF && i_exportContactPersonsTF) { //contact persons only
      //person only header
      headerRowArray = this.export_contacts_add_person_field_display_names_to_array(headerRowArray);
      contactsArrayOfArrays.push(headerRowArray);

      //person only data
      for(let personObj of c_allPersonsArrayOfObjs) {
        var personDataRowArray = this.export_contacts_add_person_data_values_to_array_from_person_obj([], personObj);
        contactsArrayOfArrays.push(personDataRowArray);
      }
    }
    else { //contact companies with or without persons
      //header
      headerRowArray = this.export_contacts_add_company_field_display_names_to_array(headerRowArray);
      if(i_exportContactPersonsTF) {
        headerRowArray = this.export_contacts_add_person_field_display_names_to_array(headerRowArray);
      }
      contactsArrayOfArrays.push(headerRowArray);

      //data
      for(let orgObj of c_allOrgsArrayOfObjs) {
        if(i_exportTopLevelContactCompaniesTF) {
          //top level org
          contactsArrayOfArrays.push(this.export_contacts_add_person_empty_strings_to_array(this.export_contacts_add_company_data_values_to_array_from_company_obj([], orgObj)));

          //persons under org
          if(i_exportContactPersonsTF) {
            const orgPersonsArrayOfObjs = this.all_persons_within_company_id_arrayOfObjs(orgObj.id);
            for(let personObj of orgPersonsArrayOfObjs) {
              contactsArrayOfArrays.push(this.export_contacts_add_person_data_values_to_array_from_person_obj(this.export_contacts_add_company_empty_strings_to_array([]), personObj));
            }
          }
        }

        if(i_exportSubdivisionContactCompaniesTF) {
          //all divisions under org
          const allDivisionsInOrgArrayOfObjs = this.all_companies_within_org_id_arrayOfObjs(orgObj.id);
          for(let divisionObj of allDivisionsInOrgArrayOfObjs) {
            //subdivision
            contactsArrayOfArrays.push(this.export_contacts_add_person_empty_strings_to_array(this.export_contacts_add_company_data_values_to_array_from_company_obj([], divisionObj)));

            //persons under subdivision
            if(i_exportContactPersonsTF) {
              var divisionPersonsArrayOfObjs = this.all_persons_within_company_id_arrayOfObjs(divisionObj.id);
              for(let personObj of divisionPersonsArrayOfObjs) {
                contactsArrayOfArrays.push(this.export_contacts_add_person_data_values_to_array_from_person_obj(this.export_contacts_add_company_empty_strings_to_array([]), personObj));
              }
            }
          }
        }
      }

      //unassigned persons
      if(i_exportContactPersonsTF) {
        const unassignedCompanyObj = {id:-1,  legal_name:"Persons Not Assigned to a Company"};
        contactsArrayOfArrays.push(this.export_contacts_add_person_empty_strings_to_array(this.export_contacts_add_company_data_values_to_array_from_company_obj([], unassignedCompanyObj)));

        const unassignedPersonsArrayOfObjs = this.all_persons_within_company_id_arrayOfObjs(unassignedCompanyObj.id);
        for(let personObj of unassignedPersonsArrayOfObjs) {
          contactsArrayOfArrays.push(this.export_contacts_add_person_data_values_to_array_from_person_obj(this.export_contacts_add_company_empty_strings_to_array([]), personObj));
        }
      }

    }

    const csvString = JSFUNC.convert_data_table_to_csv_string(contactsArrayOfArrays);
    const downloadFileNameAndExt = "CaptureExec Contacts " + JSFUNC.now_date() + ".csv";
    JSFUNC.browser_offer_file_download_from_file_data_string(csvString, downloadFileNameAndExt);

    if(JSFUNC.is_function(i_functionOnFinish)) {
      i_functionOnFinish();
    }
  }

  export_contacts_add_person_field_display_names_to_array(i_array) {
    const c_personFieldsArrayOfObjs = this.c_personFieldsArrayOfObjs;
    for(let personFieldObj of c_personFieldsArrayOfObjs) {
      i_array.push(personFieldObj.display_name);
    }
    return(i_array);
  }

  export_contacts_add_company_field_display_names_to_array(i_array) {
    const c_companyFieldsArrayOfObjs = this.c_companyFieldsArrayOfObjs;
    for(let companyFieldObj of c_companyFieldsArrayOfObjs) {
      if(companyFieldObj.db_name !== "tree_id") {
        i_array.push(companyFieldObj.display_name);
      }
    }
    return(i_array);
  }

  export_contacts_add_person_data_values_to_array_from_person_obj(i_array, i_personObj) {
    const c_personFieldsArrayOfObjs = this.c_personFieldsArrayOfObjs;
    for(let personFieldObj of c_personFieldsArrayOfObjs) {
      var personValueMaskPlainText = i_personObj[personFieldObj.db_name];
      if(personFieldObj.db_name === "contact_company_id") {
        personValueMaskPlainText = i_personObj.organizationAbbrOrLegalName;
        if(!i_personObj.parentCompanyIsOrganizationTF) {
          personValueMaskPlainText += " [" + i_personObj.parentCompanyAbbrOrLegalName + "]";
        }
      }
      i_array.push(personValueMaskPlainText);
    }
    return(i_array);
  }

  export_contacts_add_company_data_values_to_array_from_company_obj(i_array, i_companyObj) {
    const c_companyFieldsArrayOfObjs = this.c_companyFieldsArrayOfObjs;
    for(let companyFieldObj of c_companyFieldsArrayOfObjs) {
      if(companyFieldObj.db_name !== "tree_id") {
        var companyValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(i_companyObj[companyFieldObj.db_name], companyFieldObj.fieldTypeObj);
        i_array.push(companyValueMaskPlainText);
      }
    }
    return(i_array);
  }

  export_contacts_add_person_empty_strings_to_array(i_array) {
    const c_personFieldsArrayOfObjs = this.c_personFieldsArrayOfObjs;
    for(let personFieldObj of c_personFieldsArrayOfObjs) {
      i_array.push("");
    }
    return(i_array);
  }

  export_contacts_add_company_empty_strings_to_array(i_array) {
    const c_companyFieldsArrayOfObjs = this.c_companyFieldsArrayOfObjs;
    for(let companyFieldObj of c_companyFieldsArrayOfObjs) {
      if(companyFieldObj.db_name !== "tree_id") {
        i_array.push("");
      }
    }
    return(i_array);
  }







  //advanced search
  a_open_contacts_advanced_search_left_side() {
    this.a_initialize_contacts_system();
    this.a_set_left_side_state("advancedCompanySearch");
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_company_filter_selected_business_type_ids_comma(i_newValue) {
    this.o_advSearchCompanyFilterSelectedBusinessTypeIDsComma = i_newValue;
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_company_filter_selected_sb_certifications_bm_set_aside_ids_comma(i_newValue) {
    this.o_advSearchCompanyFilterSelectedSBCertificationIDsComma = i_newValue;
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_company_filter_selected_capability_ids_comma(i_newValue) {
    this.o_advSearchCompanyFilterSelectedCapabilityIDsComma = i_newValue;
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_company_filter_selected_naics_code_ids_comma(i_newValue) {
    this.o_advSearchCompanyFilterSelectedNaicsCodeIDsComma = i_newValue;
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_company_filter_selected_contact_company_extra_field_id(i_newValueInt) {
    this.o_advSearchCompanyFilterSelectedContactCompanyExtraFieldID = i_newValueInt;
    this.o_advSearchCompanyFilterContactCompanyExtraFieldSearchText = "";
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_company_filter_contact_company_extra_field_search_text(i_newValueString) {
    this.o_advSearchCompanyFilterContactCompanyExtraFieldSearchText = i_newValueString;
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_capture_filter_selected_date_field_id(i_newValue) {
    this.o_advSearchCaptureFilterSelectedDateFieldID = i_newValue;
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_capture_filter_selected_earliest_date(i_newValue) {
    this.o_advSearchCaptureFilterSelectedEarliestDate = i_newValue;
    this.a_contacts_advanced_search_set_num_results_drawn_to_initial_value();
  }

  a_contacts_advanced_search_set_num_results_drawn_to_initial_value() {
    this.o_advSearchNumResultsDrawnLimit = 50;
  }

  a_contacts_advanced_search_set_num_results_drawn_to_unlimited() {
    this.o_advSearchNumResultsDrawnLimit = JSFUNC.sort_max_mysqli_int();
  }

  a_contacts_advanced_search_set_generating_csv_of_matching_companies_data_tf(i_newValueTF) {
    this.o_advSearchGeneratingCsvOfMatchingCompaniesDataTF = i_newValueTF;
  }

  a_contacts_advanced_search_download_csv_of_matching_companies_data() {
    const c_advSearchFilteredContactCompaniesArrayOfObjs = this.c_advSearchFilteredContactCompaniesArrayOfObjs;

    this.a_contacts_advanced_search_set_generating_csv_of_matching_companies_data_tf(true);

    const advSearchMatchingCompaniesCsvColumnNamesArray = ["Company/Division Name", "Parent Organization", "[We Prime] # Wins", "[We Prime] # Active", "[We Prime] # Losses", "[We Sub] # Wins", "[We Sub] # Active", "[We Sub] # Losses"];

    var advSearchMatchingCompaniesCsvDataArrayOfArrays = [advSearchMatchingCompaniesCsvColumnNamesArray];
    for(let advSearchFilterContactCompanyObj of c_advSearchFilteredContactCompaniesArrayOfObjs) {
      var contactName = this.contact_name_plaintext_from_contact_obj(advSearchFilterContactCompanyObj);
      var isTopCompanyTF = this.contact_is_top_org_tf(advSearchFilterContactCompanyObj);
      var parentOrganizationName = "";
      if(!isTopCompanyTF) {
        parentOrganizationName = advSearchFilterContactCompanyObj.organizationAbbrOrLegalName;
      }

      var advSearchCompanyCsvDataArray = [
        contactName,
        parentOrganizationName,
        advSearchFilterContactCompanyObj.numWonCapturesWePrimeTheySub,
        advSearchFilterContactCompanyObj.numActiveCapturesWePrimeTheySub,
        advSearchFilterContactCompanyObj.numLostCapturesWePrimeTheySub,
        advSearchFilterContactCompanyObj.numWonCapturesTheyPrimeWeSub,
        advSearchFilterContactCompanyObj.numActiveCapturesTheyPrimeWeSub,
        advSearchFilterContactCompanyObj.numLostCapturesTheyPrimeWeSub
      ];

      advSearchMatchingCompaniesCsvDataArrayOfArrays.push(advSearchCompanyCsvDataArray);
    }

    const csvString = JSFUNC.convert_data_table_to_csv_string(advSearchMatchingCompaniesCsvDataArrayOfArrays);
    const downloadFileNameAndExt = JSFUNC.now_date() + " Contacts Advanced Search Matching Companies.csv";

    JSFUNC.browser_offer_file_download_from_file_data_string(csvString, downloadFileNameAndExt);

    this.a_contacts_advanced_search_set_generating_csv_of_matching_companies_data_tf(false);
  }

  a_contacts_advanced_search_open_captures_analysis(i_contactID, i_advSearchTeamingCategory, i_captureIDsArray) {
    this.o_rightSideState = "advancedSearchCapturesAnalysis";
    this.o_openContactIsPersonTF = false;
    this.o_openContactID = i_contactID;
    this.o_advSearchTeamingCategory = i_advSearchTeamingCategory;
    this.o_advSearchCaptureIDsArray = i_captureIDsArray;
  }








  //other non-action methods
  contact_company_or_person_obj_from_id(i_isPersonTF, i_contactCompanyOrPersonID) {
    if(i_isPersonTF) {
      return(this.contact_person_obj_from_id(i_contactCompanyOrPersonID));
    }
    return(this.contact_company_obj_from_id(i_contactCompanyOrPersonID, false));
  }

  contact_person_obj_from_id(i_contactPersonID) {
    const contactPersonMap = DatabaseMobx.o_tbl_g_contacts_persons.get(i_contactPersonID);
    return(this.contact_person_obj_from_tbl_g_contacts_persons_row_map(contactPersonMap, i_contactPersonID));
  }

  contact_person_obj_from_tbl_g_contacts_persons_row_map(i_contactPersonMap, i_contactPersonID=undefined) {
    const c_allCompanyIDsArray = this.c_allCompanyIDsArray;

    const contactPersonExistsTF = (i_contactPersonMap !== undefined);

    var doesNotExistString = undefined;

    var firstName = undefined;
    var lastName = undefined;
    var title = undefined;
    var email = undefined;
    var phone = undefined;
    var contactCompanyID = undefined;
    var fullName = undefined;
    var sortName = undefined;
    var organizationID = undefined;
    var organizationAbbrOrLegalName = undefined;
    var parentCompanyAbbrOrLegalName = undefined;
    var firstNameSpaceLastNameLower = undefined;
    var lastNameLower = undefined;
    var titleLower = undefined;
    var parentCompanyAbbrOrLegalNameLower = undefined;
    var parentCompanyIsOrganizationTF = undefined;

    if(contactPersonExistsTF) {
      firstName = i_contactPersonMap.get("first_name");
      lastName = i_contactPersonMap.get("last_name");
      title = i_contactPersonMap.get("title");
      email =i_contactPersonMap.get("email");
      phone = i_contactPersonMap.get("phone");
      contactCompanyID = i_contactPersonMap.get("contact_company_id");

      //check if person's contact company ID matches to an existing valid contact company, otherwise, overwrite it with a -1 to indicate that this person is unassigned
      if(!JSFUNC.in_array(contactCompanyID, c_allCompanyIDsArray)) {
        contactCompanyID = -1;
      }

      const firstNameSpaceLastName = JSFUNC.full_name_from_first_name_last_name(firstName, lastName);
      const lastNameFirstName = lastName + firstName;

      fullName = firstNameSpaceLastName;
      sortName = JSFUNC.str2lower(lastNameFirstName);
      firstNameSpaceLastNameLower = JSFUNC.str2lower(firstNameSpaceLastName);
      lastNameLower = JSFUNC.str2lower(lastName);
      titleLower = JSFUNC.str2lower(title);

      const contactCompanyObj = this.contact_company_obj_from_id(contactCompanyID, false);

      organizationID = contactCompanyObj.organizationID;
      organizationAbbrOrLegalName = contactCompanyObj.organizationAbbrOrLegalName;
      parentCompanyAbbrOrLegalName = contactCompanyObj.abbrOrLegalName;
      parentCompanyAbbrOrLegalNameLower = contactCompanyObj.divNameParenOrgNameLower;
      parentCompanyIsOrganizationTF = contactCompanyObj.isTopLevelCompanyTF;
    }
    else {
      if(JSFUNC.select_int_is_filled_out_tf(i_contactPersonID)) {
        doesNotExistString = "--Contact Person Does Not Exist (ID: " + i_contactPersonID + ")--";
      }
      else {
        doesNotExistString = "--No Contact Person Selected--";
      }

      firstName = doesNotExistString;
      lastName = doesNotExistString;
      title = doesNotExistString;
      email = doesNotExistString;
      phone = doesNotExistString;
      contactCompanyID = -1;
      fullName = doesNotExistString;
      sortName = doesNotExistString;
      firstNameSpaceLastNameLower = doesNotExistString;
      lastNameLower = doesNotExistString;
      titleLower = doesNotExistString;
      organizationID = -1;
      organizationAbbrOrLegalName = doesNotExistString;
      parentCompanyAbbrOrLegalName = doesNotExistString;
      parentCompanyAbbrOrLegalNameLower = doesNotExistString;
      parentCompanyIsOrganizationTF = true;
    }

    var contactPersonObj = {
      existsTF: contactPersonExistsTF,
      id: i_contactPersonID,
      first_name: firstName,
      last_name: lastName,
      title: title,
      email: email,
      phone: phone,
      contact_company_id: contactCompanyID,
      isPersonTF: true,
      fullName: fullName,
      sortName: sortName,
      firstNameSpaceLastNameLower: firstNameSpaceLastNameLower,
      lastNameLower: lastNameLower,
      titleLower: titleLower,
      organizationID: organizationID,
      organizationAbbrOrLegalName: organizationAbbrOrLegalName,
      parentCompanyAbbrOrLegalName: parentCompanyAbbrOrLegalName,
      parentCompanyAbbrOrLegalNameLower: parentCompanyAbbrOrLegalNameLower,
      parentCompanyIsOrganizationTF: parentCompanyIsOrganizationTF
    };

    //add extra custom contact person fields created by the admin
    for(let contactsPersonsFieldObj of DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs) { //loop through each extra field (all are text type inputs)
      contactPersonObj[contactsPersonsFieldObj.db_name] = ((contactPersonExistsTF) ? (i_contactPersonMap.get(contactsPersonsFieldObj.db_name)) : (doesNotExistString));
    }

    return(contactPersonObj);
  }

  contact_company_obj_from_id(i_contactCompanyID, i_subdivsAndPersonsTF=false) {
    const contactCompanyMap = DatabaseMobx.o_tbl_g_contacts_companies.get(i_contactCompanyID);
    return(this.contact_company_obj_from_tbl_g_contacts_companies_row_map(contactCompanyMap, i_subdivsAndPersonsTF, i_contactCompanyID));
  }

  contact_company_obj_from_tbl_g_contacts_companies_row_map(i_contactCompanyMap, i_subdivsAndPersonsTF=false, i_contactCompanyID=undefined) {
    const contactCompanyExistsTF = (i_contactCompanyMap !== undefined);

    var doesNotExistString = undefined;

    var contactCompanyID = undefined;
    var orgTopDivID = undefined;
    var treeID = undefined;
    var legalName = undefined;
    var abbrName = undefined;
    var businessTypeID = undefined;
    var sbCertificationsBmSetAsideIDsComma = undefined;
    var capabilityIDsComma = undefined;
    var naicsCodeIDsComma = undefined;
    var isTopLevelCompanyTF = undefined;
    var organizationID = undefined;
    var organizationAbbrOrLegalName = undefined;
    var legalNameLower = undefined;
    var abbrNameLower = undefined;
    var abbrOrLegalName = undefined;
    var divNameParenOrgNameLower = undefined;
    var numOrgDivisions = undefined;
    var numPersons = undefined;
    var businessTypeIsLargeTF = undefined;
    var businessTypeIsSmallTF = undefined;
    var businessTypeIsInvalidTF = undefined;
    var teammateRatingTotalRating = undefined;
    var teammateRatingNumReviews = undefined;

    if(contactCompanyExistsTF) {
      //load existing db fields ot use in new calculated fields
      contactCompanyID = i_contactCompanyMap.get("id");
      orgTopDivID = i_contactCompanyMap.get("org_topdiv_id"); //all subdivisions of an organization have and org_topdiv_id value equal to the top organization id number, if this value is set to -1, it is a flag that this division is the top level of the organization
      treeID = i_contactCompanyMap.get("tree_id");
      legalName = i_contactCompanyMap.get("legal_name");
      abbrName = i_contactCompanyMap.get("abbreviated_name");
      businessTypeID = i_contactCompanyMap.get("business_type_id");
      sbCertificationsBmSetAsideIDsComma = i_contactCompanyMap.get("sb_certifications_bm_set_aside_ids_comma");
      capabilityIDsComma = i_contactCompanyMap.get("capability_ids_comma");
      naicsCodeIDsComma = i_contactCompanyMap.get("naics_code_ids_comma");
      teammateRatingTotalRating = i_contactCompanyMap.get("tr_total");
      teammateRatingNumReviews = i_contactCompanyMap.get("tr_num_reviews");

      //get the lowercase versions of abbr/legal name for searches
      legalNameLower = JSFUNC.str2lower(legalName, "--Legal Name not a Contact Field--");
      abbrNameLower = JSFUNC.str2lower(abbrName, "--Abbr Name not a Contact Field--");

      //full name for a contact company, the abbreviated name takes priority over the legal name if filled out
      abbrOrLegalName = this.contact_company_abbr_name_or_legal_name_from_abbr_name_and_legal_name(abbrName, legalName);

      isTopLevelCompanyTF = (orgTopDivID <= 0); //this company is a top level company (an "organization") where org_topdiv_id is -1
      organizationID = ((isTopLevelCompanyTF) ? (contactCompanyID) : (orgTopDivID));

      //get name of this div's top level organization (if this is an org, the name is the same as its own name)
      var organizationAbbrOrLegalName = undefined;
      var divNameParenOrgName = JSFUNC.full_name_from_first_name_last_name(abbrName, legalName); //"Org Abbr Name Org Legal Name" or "Div Abbr Name Div Legal Name (Org Abbr Name Org Legal Name)"
      if(!isTopLevelCompanyTF) {
        const organizationMap = DatabaseMobx.tbl_row_map_from_id("tbl_g_contacts_companies", organizationID, "o");
        const orgLegalName = organizationMap.get("legal_name");
        const orgAbbrName = organizationMap.get("abbreviated_name");
        organizationAbbrOrLegalName = this.contact_company_abbr_name_or_legal_name_from_abbr_name_and_legal_name(orgAbbrName, orgLegalName);
        divNameParenOrgName += " (" + JSFUNC.full_name_from_first_name_last_name(orgAbbrName, orgLegalName) + ")";
      }
      else {
        organizationAbbrOrLegalName = abbrOrLegalName;
      }
      divNameParenOrgNameLower = divNameParenOrgName.toLowerCase();

      //person/division counts only calculated for top organization records
      numOrgDivisions = -1;
      numPersons = -1;
      if(i_subdivsAndPersonsTF) {
        if(isTopLevelCompanyTF) { //top org calculated only
          numOrgDivisions = 0;
          var orgCompanyIDsArray = []; //all divisionIDs under the org, also include the orgID
          for(let contactCompanyMap of DatabaseMobx.o_tbl_g_contacts_companies.values()) {
            if(contactCompanyMap.get("org_topdiv_id") === contactCompanyID) {
              numOrgDivisions++;
              orgCompanyIDsArray.push(contactCompanyMap.get("id"));
            }
          }

          numPersons = 0;
          for(let contactPersonMap of DatabaseMobx.o_tbl_g_contacts_persons.values()) {
            if(JSFUNC.in_array(contactPersonMap.get("contact_company_id"), orgCompanyIDsArray)) {
              numPersons++;
            }
          }
        }
      }

      //determine if business type is a small business type from business_type_id
      var smallLargeInvalidBusinessTypeTFObj = DatabaseMobx.determine_small_large_invalid_business_type_tf_obj_from_business_type_id(businessTypeID);
      businessTypeIsLargeTF = smallLargeInvalidBusinessTypeTFObj.businessTypeIsLargeTF;
      businessTypeIsSmallTF = smallLargeInvalidBusinessTypeTFObj.businessTypeIsSmallTF;
      businessTypeIsInvalidTF = smallLargeInvalidBusinessTypeTFObj.businessTypeIsInvalidTF;
    }
    else {
      if(JSFUNC.select_int_is_filled_out_tf(i_contactCompanyID)) {
        doesNotExistString = "--Contact Company Does Not Exist (ID: " + i_contactCompanyID + ")--";
      }
      else {
        doesNotExistString = "--No Contact Company Selected--";
      }

      orgTopDivID = -1;
      treeID = "00";
      legalName = doesNotExistString;
      abbrName = doesNotExistString;
      businessTypeID = -1;
      sbCertificationsBmSetAsideIDsComma = "";
      capabilityIDsComma = "";
      naicsCodeIDsComma = "";
      isTopLevelCompanyTF = true;
      organizationID = -1;
      organizationAbbrOrLegalName = doesNotExistString;
      legalNameLower = doesNotExistString;
      abbrNameLower = doesNotExistString;
      abbrOrLegalName = doesNotExistString;
      divNameParenOrgNameLower = doesNotExistString;
      numOrgDivisions = -1;
      numPersons = -1;
      businessTypeIsLargeTF = false;
      businessTypeIsSmallTF = false;
      businessTypeIsInvalidTF = true;
      teammateRatingTotalRating = -1;
      teammateRatingNumReviews = 0;
    }

    var contactCompanyObj = {
      existsTF: contactCompanyExistsTF,
      id: contactCompanyID,
      org_topdiv_id: orgTopDivID,
      tree_id: treeID,
      legal_name: legalName,
      abbreviated_name: abbrName,
      business_type_id: businessTypeID,
      sb_certifications_bm_set_aside_ids_comma: sbCertificationsBmSetAsideIDsComma,
      capability_ids_comma: capabilityIDsComma,
      naics_code_ids_comma: naicsCodeIDsComma,
      isPersonTF: false,
      isTopLevelCompanyTF: isTopLevelCompanyTF,
      organizationID: organizationID,
      organizationAbbrOrLegalName: organizationAbbrOrLegalName,
      legalNameLower: legalNameLower,
      abbrNameLower: abbrNameLower,
      abbrOrLegalName: abbrOrLegalName,
      divNameParenOrgNameLower: divNameParenOrgNameLower,
      numOrgDivisions: numOrgDivisions,
      numPersons: numPersons,
      businessTypeIsLargeTF: businessTypeIsLargeTF,
      businessTypeIsSmallTF: businessTypeIsSmallTF,
      businessTypeIsInvalidTF: businessTypeIsInvalidTF,
      tr_total: teammateRatingTotalRating,
      tr_num_reviews: teammateRatingNumReviews
    };

    //add individual teammate rating question average ratings
    for(let teammatesRatingsQuestionObj of DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs) {
      var questionRatingColumnDbName = "tr_q" + teammatesRatingsQuestionObj.id;
      contactCompanyObj[questionRatingColumnDbName] = ((contactCompanyExistsTF) ? (i_contactCompanyMap.get(questionRatingColumnDbName)) : (undefined));
    }

    //add extra custom contact person fields created by the admin
    for(let contactsCompaniesFieldObj of DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs) { //loop through each extra field (all are text type inputs)
      contactCompanyObj[contactsCompaniesFieldObj.db_name] = ((contactCompanyExistsTF) ? (i_contactCompanyMap.get(contactsCompaniesFieldObj.db_name)) : (doesNotExistString));
    }

    return(contactCompanyObj);
  }

  contact_name_from_id(i_isPersonTF, i_contactCompanyOrPersonID) {
    const contactObj = this.contact_company_or_person_obj_from_id(i_isPersonTF, i_contactCompanyOrPersonID);
    return(this.contact_name_plaintext_from_contact_obj(contactObj));
  }

  contact_name_plaintext_from_contact_obj(i_contactObj) {
    if(i_contactObj === undefined) {
      return("--Invalid Contact Obj--");
    }
    const contactNameField = this.contact_name_field(i_contactObj.isPersonTF);
    return(i_contactObj[contactNameField]);
  }

  contact_name_field(i_isPersonTF) { //contact name is constructed in DatabaseMobx when building the extra fields into the contacts raw data maps
    return((i_isPersonTF) ? ("fullName") : ("abbrOrLegalName"));
  }

  contact_person_email_from_contact_person_obj(i_contactPersonObj) {
    if((i_contactPersonObj === undefined) || (!i_contactPersonObj.isPersonTF)) {
      return("--Invalid Contact Person Obj--");
    }
    return(i_contactPersonObj.email);
  }

  parent_company_field_db_name(i_isPersonTF) {
    return((i_isPersonTF) ? ("contact_company_id") : ("org_topdiv_id"));
  }

  is_person_and_is_multiselect_tfu_from_select_mode(i_selectMode) { //returns [isSelectingPersonsTFU, isMultiSelectTFU]
    if(i_selectMode === "select_contact_person") { return([true, false]); }
    if(i_selectMode === "select_contact_company") { return([false, false]); }
    if(i_selectMode === "selectmulti_contact_persons") { return([true, true]); }
    if(i_selectMode === "selectmulti_contact_companies") { return([false, true]); }
    return([undefined, undefined]); //no_select or invalid options
  }

  person_or_company_string_from_is_person_and_is_multi_tf(i_isPersonTF, i_isMultiSelectTF) {
    if(i_isPersonTF === undefined || i_isMultiSelectTF === undefined) {
      return("NoSelect");
    }

    if(i_isMultiSelectTF) {
      return((i_isPersonTF) ? ("Persons") : ("Companies"));
    }
    return((i_isPersonTF) ? ("Person") : ("Company"));
  }

  contacts_tbl_name_from_is_person_tf(i_isPersonTF) {
    if(i_isPersonTF === undefined) {
      return("no_select");
    }
    return((i_isPersonTF) ? ("tbl_g_contacts_persons") : ("tbl_g_contacts_companies"));
  }

  contact_fields_arrayOfObjs(i_isPersonTF, i_fieldDbNamesToNotIncludeArray=undefined) {
    if(i_isPersonTF === undefined) {
      return([]);
    }

    const contactFieldsArrayOfObjs = (i_isPersonTF) ? (this.c_personFieldsArrayOfObjs) : (this.c_companyFieldsArrayOfObjs);
    if(!JSFUNC.is_array(i_fieldDbNamesToNotIncludeArray)) {
      return(contactFieldsArrayOfObjs); //all fields (from persons or companies)
    }

    //don't include specified fields that match db_name in i_fieldDbNamesToNotIncludeArray
    var modifiedContactFieldsArrayOfObjs = [];
    for(let contactFieldObj of contactFieldsArrayOfObjs) {
      if(!JSFUNC.in_array(contactFieldObj.db_name, i_fieldDbNamesToNotIncludeArray)) {
        modifiedContactFieldsArrayOfObjs.push(contactFieldObj);
      }
    }
    return(modifiedContactFieldsArrayOfObjs);
  }

  contact_company_abbr_name_or_legal_name_from_abbr_name_and_legal_name(i_abbrName, i_legalName) {
    if(JSFUNC.text_or_number_is_filled_out_tf(i_abbrName)) {
      return(i_abbrName);
    }
    else if(JSFUNC.text_or_number_is_filled_out_tf(i_legalName)) {
      return(i_legalName);
    }
    return("--Company With No Legal or Abbrivated Names Given--");
  }

  all_companies_within_org_id_arrayOfObjs(i_orgCompanyID) {
    if(i_orgCompanyID <= 0) {
      return([]);
    }

    const filterFunction = (i_companyObj) => { return(i_companyObj.org_topdiv_id === i_orgCompanyID); };
    const sortFunction = JSFUNC.sort_by_asc("tree_id");
    return(this.c_allCompaniesArrayOfObjs.filter(filterFunction).sort(sortFunction));
  }

  all_persons_within_company_id_arrayOfObjs(i_companyID) {
    if(JSFUNC.is_number(i_companyID)) {
      if(i_companyID > 0) {
        const filterFunction = (i_personObj) => { return(i_personObj.contact_company_id === i_companyID); };
        const sortFunction = JSFUNC.sort_by_asc("sortName");
        return(this.c_allPersonsArrayOfObjs.filter(filterFunction).sort(sortFunction));
      }

      if(i_companyID === -2) { //Persons Created from GCSS Import special company grouping
        return(this.c_createdByGcssImportContactPersonsArrayOfObjs)
      }
    }

    //Unassigned Persons special company grouping (unassigned persons usually have a parent companyID of -1 or 0)
    return(this.c_unassignedPersonsArrayOfObjs);
  }

  all_expanded_companies_within_org_tree_id_array(i_orgCompanyID) {
    if(i_orgCompanyID <= 0) {
      return([]);
    }

    const filterFunction = (i_companyObj) => { return((i_companyObj.org_topdiv_id === i_orgCompanyID) && this.company_item_is_expanded_tf(i_companyObj.id)); };
    const allExpandedCompaniesWithinOrgArrayOfObjs = this.c_allCompaniesArrayOfObjs.filter(filterFunction);
    return(JSFUNC.get_column_vector_from_arrayOfObjs(allExpandedCompaniesWithinOrgArrayOfObjs, "tree_id"));
  }

  parent_company_id_from_company_obj(i_childCompanyObj) {
    if(i_childCompanyObj.org_topdiv_id <= 0) { //org_topdiv_id of -1 is a flag that this division is a top organization, so its parent companyID is -1
      return(-1);
    }

    const childTreeID = i_childCompanyObj.tree_id;
    const childTreeIDLength = childTreeID.length;
    const childOrganizationID = i_childCompanyObj.org_topdiv_id;
    if(childTreeIDLength <= 2) { //treeID length 2 like "01" or "02", the parent division is the organization it is a part of, which has already been precomputed as the organizationID
      return(childOrganizationID);
    }

    const parentTreeID = childTreeID.substring(0, childTreeIDLength - 2);
    for(let companyObj of this.c_allCompaniesArrayOfObjs) {
      if(companyObj.organizationID === childOrganizationID && companyObj.tree_id === parentTreeID) {
        return(companyObj.id);
      }
    }
    return(0); //child does not have a direct parent flag
  }

  contact_is_selected_tf(i_contactID) {
    return(JSFUNC.in_array(i_contactID, this.o_currentSelectedContactIDsArray));
  }

  company_item_is_expanded_tf(i_companyID) {
    return(JSFUNC.in_array(i_companyID, this.o_orgsExpandedCompanyIDsArray));
  }

  contact_is_top_org_tf(i_contactObj) {
    if(i_contactObj === undefined) {
      return(false);
    }
    return(!i_contactObj.isPersonTF && (i_contactObj.org_topdiv_id <= 0));
  }

  get_changed_output_selected_value_or_unchanged_undefined(i_selectMode) {
    //current selected contacts are the same as they were initially, return undefined to signal a cancel operation without saving new data
    if(JSFUNC.array1_equals_array2_tf(this.o_initialSelectedContactIDsArray, this.o_currentSelectedContactIDsArray)) {
      return(undefined);
    }

    //convert the selectedContactIDsArray into the output format required based on the selectMode (single int for select, comma list string for selectmulti)
    const [isSelectingPersonsTFU, isMultiSelectTFU] = this.is_person_and_is_multiselect_tfu_from_select_mode(i_selectMode);

    //string comma list of contactIDs for multiselect
    if(isMultiSelectTFU) {
      return(JSFUNC.convert_array_to_comma_list(this.o_currentSelectedContactIDsArray));
    }

    //single int contactID for single selects
    const numSelectedContacts = this.o_currentSelectedContactIDsArray.length;
    if(numSelectedContacts >= 1) { //if somehow more than 1 contact was selected (should be impossible), only return the first contactID selected, otherwise return the single contact selected as intended
      return(this.o_currentSelectedContactIDsArray[0]);
    }
    return(-1); //if no items were selected, return -1 as a flag for input selects that no items were selected
  }



  //searching the contacts system for a matching abbr/legal name
  find_first_lowecase_exact_match_contact_company_id_or_undefined_from_name_string(i_legalOrAbbrNameToSearch) {
    //if the input is not a string, return no match
    if(!JSFUNC.is_string(i_legalOrAbbrNameToSearch)) {
      return(undefined);
    }

    //if the input is an empty string, return no match
    if(i_legalOrAbbrNameToSearch === "") {
      return(undefined);
    }

    //convert the input name to lowercase
    const legalOrAbbrNameToSearchLower = i_legalOrAbbrNameToSearch.toLowerCase();

    //compare the input name against every contact legal and abbr name (lowercase)
    for(let companyObj of this.c_allCompaniesArrayOfObjs) {
      if((legalOrAbbrNameToSearchLower === companyObj.legalNameLower) || (legalOrAbbrNameToSearchLower === companyObj.abbrNameLower)) {
        return(companyObj.id);
      }
    }

    //search did not match any contact company names in the contacts system, return undefined
    return(undefined);
  }

  find_multiple_lowercase_exact_match_contact_company_ids_comma_or_undefined_from_comma_list_of_names_string(i_legalOrAbbrNamesToSearchComma) {
    //if the input is not a string, return no match
    if(!JSFUNC.is_string(i_legalOrAbbrNamesToSearchComma)) {
      return(undefined);
    }

    //if the input string is blank, return no match
    if(i_legalOrAbbrNamesToSearchComma === "") {
      return(undefined);
    }

    //convert the input name to lowercase
    var legalOrAbbrNamesToSearchCommaLower = i_legalOrAbbrNamesToSearchComma.toLowerCase();

    //replace any ", inc" or ", llc" with ZZZZZ so that the comma is not mistaken for commas separating company names
    legalOrAbbrNamesToSearchCommaLower = legalOrAbbrNamesToSearchCommaLower.replace(", inc", "ZZZZZ inc");
    legalOrAbbrNamesToSearchCommaLower = legalOrAbbrNamesToSearchCommaLower.replace(",inc", "ZZZZZinc");
    legalOrAbbrNamesToSearchCommaLower = legalOrAbbrNamesToSearchCommaLower.replace(", llc", "ZZZZZ llc");
    legalOrAbbrNamesToSearchCommaLower = legalOrAbbrNamesToSearchCommaLower.replace(",llc", "ZZZZZllc");

    //break the comma list into multiple company names
    var companyNamesWithZsArray = JSFUNC.convert_comma_list_with_or_without_spaces_to_array_blank_entries_removed(legalOrAbbrNamesToSearchCommaLower);

    //for each company name identified in the input comma list, search for it within the contact company records to return an id number
    var matchingContactCompanyIDsComma = "";
    for(let companyNameWithZs of companyNamesWithZsArray) {
      var companyName = companyNameWithZs.replace("ZZZZZ", ","); //convert back any ZZZZZ into a single comma
      var matchingContactCompanyID = this.find_first_lowecase_exact_match_contact_company_id_or_undefined_from_name_string(companyName);
      if(matchingContactCompanyID !== undefined) {
        if(matchingContactCompanyIDsComma !== "") { matchingContactCompanyIDsComma += ","; }
        matchingContactCompanyIDsComma += matchingContactCompanyID;
      }
    }

    if(matchingContactCompanyIDsComma === "") {
      return(undefined);
    }

    return(matchingContactCompanyIDsComma);
  }

}
export default new ContactsMobx();
