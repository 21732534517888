//js code packages
import React, { Component, useState } from 'react';
import { observer, inject } from 'mobx-react';

//generic components and js functions
import * as LibraryReact from "../../Library/LibraryReact.js";
import * as JSFUNC from "../../Library/JSFUNC.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";

import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import * as CapturesReact from "../Captures/CapturesReact.js";
import * as ContactsReact from "../Contacts/ContactsReact.js";
import * as OpenCaptureReact from "../OpenCapture/OpenCaptureReact.js";
import * as RightPanelReact from "../RightPanel/RightPanelReact.js";
import * as TeammateContractsReact from "../TeammateContracts/TeammateContractsReact.js";
import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';


export const AdminTabUserActivity = inject("AdminMobx")(observer(
class AdminTabUserActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_exportUserListFloatingBoxIsOpenTF: false,
      s_exportUserListSortAlphabeticFalseLastLoginDateTrue: false,
      s_exportUserListGroupUsersByDivisionTF: false,
      s_writingCsvFileTF: false
    }
  }

  onclick_open_export_user_list_to_csv = () => {
    this.setState({s_exportUserListFloatingBoxIsOpenTF:true});
  }

  onclick_close_export_user_list_to_csv = () => {
    this.setState({s_exportUserListFloatingBoxIsOpenTF:false});
  }

  onswitch_export_user_list_sort = () => {
    this.setState({s_exportUserListSortAlphabeticFalseLastLoginDateTrue:!this.state.s_exportUserListSortAlphabeticFalseLastLoginDateTrue});
  }

  onswitch_export_user_list_group_by_division = () => {
    this.setState({s_exportUserListGroupUsersByDivisionTF:!this.state.s_exportUserListGroupUsersByDivisionTF});
  }

  onclick_export_user_list_to_csv = () => {
    const s_exportUserListFloatingBoxIsOpenTF = this.state.s_exportUserListFloatingBoxIsOpenTF;
    const s_exportUserListSortAlphabeticFalseLastLoginDateTrue = this.state.s_exportUserListSortAlphabeticFalseLastLoginDateTrue;
    const s_exportUserListGroupUsersByDivisionTF = this.state.s_exportUserListGroupUsersByDivisionTF;
    const s_writingCsvFileTF = this.state.s_writingCsvFileTF;

    const c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs = this.props.AdminMobx.c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs;

    this.setState({s_writingCsvFileTF:true});

    const exportUsersColumnHeaderNamesArray = ["User Name", "Email", "User Power", "Division", "# Logins", "Last Login Date", "# Captures"];
    const numExportUsersColumns = exportUsersColumnHeaderNamesArray.length;

    var exportUsersArrayOfObjs = [];
    for(let expandedUserPerEmailObj of c_allExpandedUserPowersWithinUsersPerEmailsArrayOfObjs) {
      for(let combinedUserObj of expandedUserPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs) {
        var lastLoginDateDisplay = "-";
        if(JSFUNC.datetime_is_filled_out_tf(combinedUserObj.last_login_datetime_utc)) {
          lastLoginDateDisplay = JSFUNC.get_MjYgiA_datetime_local_from_natural_datetime_utc(combinedUserObj.last_login_datetime_utc);
        }

        exportUsersArrayOfObjs.push({
          userID: combinedUserObj.id,
          userFullName: combinedUserObj.fullName,
          userSortName: combinedUserObj.sortName,
          userEmail: combinedUserObj.email,
          userPowerDisplayName: combinedUserObj.powerDisplayName,
          divisionID: combinedUserObj.division_id,
          divisionName: combinedUserObj.divisionName,
          numLogins: combinedUserObj.num_logins,
          lastLoginDateSort: combinedUserObj.last_login_datetime_utc,
          lastLoginDateDisplay: lastLoginDateDisplay,
          numCaptures: combinedUserObj.numCapturesOwnedByUserPower
        });
      }
    }

    var sortField = "userSortName";
    var sortAscTF = true;
    if(s_exportUserListSortAlphabeticFalseLastLoginDateTrue) {
      sortField = "lastLoginDateSort";
      sortAscTF = false;
    }

    JSFUNC.sort_arrayOfObjs(exportUsersArrayOfObjs, sortField, sortAscTF);

    var userListArrayOfArrays = [];
    userListArrayOfArrays.push(exportUsersColumnHeaderNamesArray);

    if(s_exportUserListGroupUsersByDivisionTF) {
      var userIDsInValidDivisionsArray = [];
      for(let expandedDivisionObj of this.props.AdminMobx.c_adminAllDivisionsArrayOfObjs) {
        var blankRowArray = JSFUNC.array_fill(numExportUsersColumns, "");
        userListArrayOfArrays.push(blankRowArray);

        var divisionLabelRowArray = JSFUNC.array_fill(numExportUsersColumns, "");
        divisionLabelRowArray[0] = expandedDivisionObj.name;
        userListArrayOfArrays.push(divisionLabelRowArray);

        for(let exportUserObj of exportUsersArrayOfObjs) {
          if(exportUserObj.divisionID === expandedDivisionObj.id) {
            userListArrayOfArrays.push(this.convert_export_user_obj_to_user_array(exportUserObj));
            userIDsInValidDivisionsArray.push(exportUserObj.userID);
          }
        }
      }

      var blankRowArray = JSFUNC.array_fill(numExportUsersColumns, "");
      userListArrayOfArrays.push(blankRowArray);

      var divisionLabelRowArray = JSFUNC.array_fill(numExportUsersColumns, "");
      divisionLabelRowArray[0] = "--Unassigned Division--";
      userListArrayOfArrays.push(divisionLabelRowArray);

      for(let exportUserObj of exportUsersArrayOfObjs) {
        if(!JSFUNC.in_array(exportUserObj.userID, userIDsInValidDivisionsArray)) {
          userListArrayOfArrays.push(this.convert_export_user_obj_to_user_array(exportUserObj));
        }
      }
    }
    else {
      for(let exportUserObj of exportUsersArrayOfObjs) {
        userListArrayOfArrays.push(this.convert_export_user_obj_to_user_array(exportUserObj));
      }
    }

    const csvString = JSFUNC.convert_data_table_to_csv_string(userListArrayOfArrays);
    const downloadFileNameAndExt = JSFUNC.now_date() + " User Activity User List.csv";

    JSFUNC.browser_offer_file_download_from_file_data_string(csvString, downloadFileNameAndExt);

    this.setState({s_writingCsvFileTF:false});
  }

  convert_export_user_obj_to_user_array = (i_exportUserObj) => {
    return([i_exportUserObj.userFullName, i_exportUserObj.userEmail, i_exportUserObj.userPowerDisplayName, i_exportUserObj.divisionName, i_exportUserObj.numLogins, i_exportUserObj.lastLoginDateDisplay, i_exportUserObj.numCaptures]);
  }

  render() {
    const s_exportUserListFloatingBoxIsOpenTF = this.state.s_exportUserListFloatingBoxIsOpenTF;
    const s_exportUserListSortAlphabeticFalseLastLoginDateTrue = this.state.s_exportUserListSortAlphabeticFalseLastLoginDateTrue;
    const s_exportUserListGroupUsersByDivisionTF = this.state.s_exportUserListGroupUsersByDivisionTF;
    const s_writingCsvFileTF = this.state.s_writingCsvFileTF;

    const activityUsersArrayOfObjs = this.props.AdminMobx.c_activityUsersArrayOfObjs;

    return(
      <>
        <div className="flex00a displayFlexRow bgLightestGray borderB1bbb" style={{flexBasis:"2.5em"}}>
          <div className="flex00a displayFlexRowVc lrMedPad">
            <font className="font13 fontBold fontTextLighter">
              {"User Activity"}
            </font>
          </div>
          <div className="flex11a" />
          <div className="flex00a displayFlexColumnHcVc lrMedPad">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Export User List to .csv"
              f_onClick={this.onclick_open_export_user_list_to_csv}
            />
          </div>
        </div>
        <div className="flex11a yScroll">
          <div className="medFullMargin border1bbb">
            {activityUsersArrayOfObjs.map((m_activityUserObj) =>
              <UserActivityUserRow p_activityUserObj={m_activityUserObj} />
            )}
          </div>
        </div>
        {(s_exportUserListFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="smallVertical"
            p_title="Export User List to .csv File for Download"
            f_onClickCancel={this.onclick_close_export_user_list_to_csv}>
            <div className="flex11a yScroll medFullPad">
              <div className="microBottomMargin">
                <font className="fontItalic">
                  {"Sort User List by Name or by Last Login Date:"}
                </font>
              </div>
              <CEGeneralReact.SwitchWithTextAndConfirmBox
                p_isOnTF={s_exportUserListSortAlphabeticFalseLastLoginDateTrue}
                p_onText="User list will be sorted by last login date"
                p_offText="User list will be sorted alphabetically by last name"
                f_onSwitch={this.onswitch_export_user_list_sort}
              />
              <div className="medTopMargin microBottomMargin">
                <font className="fontItalic">
                  {"Show all Users in single list or place into individual Divisions:"}
                </font>
              </div>
              <CEGeneralReact.SwitchWithTextAndConfirmBox
                p_isOnTF={s_exportUserListGroupUsersByDivisionTF}
                p_onText="Users places into separate groupings of their assigned Division"
                p_offText="Single list of all Users"
                f_onSwitch={this.onswitch_export_user_list_group_by_division}
              />
            </div>
            <div className="flex00a displayFlexRowHcVc borderT1bbb" style={{flexBasis:"3em"}}>
              {(s_writingCsvFileTF) ? (
                <CEGeneralReact.LoadingAnimation />
              ) : (
                <>
                  <div className="flex00a lrMedPad">
                    <CEGeneralReact.CEButton
                      p_type="blue"
                      p_text="Export User List to .csv"
                      f_onClick={this.onclick_export_user_list_to_csv}
                    />
                  </div>
                  <div className="flex00a lrMedPad">
                    <CEGeneralReact.CEButton
                      p_type="gray"
                      p_text="Cancel"
                      f_onClick={this.onclick_close_export_user_list_to_csv}
                    />
                  </div>
                </>
              )}
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));

const UserActivityUserRow = inject("AdminMobx")(observer(
class UserActivityUserRow extends Component { //props: p_activityUserObj
  onclick_num_tickets_container = () => {
    const activityUserObj = this.props.p_activityUserObj;

    const activityShowTicketsUserIDsArray = this.props.AdminMobx.o_activityShowTicketsUserIDsArray;

    var updatedActivityShowTicketsUserIDsArray = [];
    if(JSFUNC.in_array(activityUserObj.user_id, activityShowTicketsUserIDsArray)) {
      updatedActivityShowTicketsUserIDsArray = JSFUNC.remove_all_values_from_array(activityUserObj.user_id, activityShowTicketsUserIDsArray);
    }
    else {
      updatedActivityShowTicketsUserIDsArray = JSFUNC.copy_array(activityShowTicketsUserIDsArray);
      updatedActivityShowTicketsUserIDsArray.push(activityUserObj.user_id);
    }
    this.props.AdminMobx.a_activity_set_show_tickets_user_ids_array(updatedActivityShowTicketsUserIDsArray);
  }

  render() {
    const activityUserObj = this.props.p_activityUserObj;

    const activityShowTicketsUserIDsArray = this.props.AdminMobx.o_activityShowTicketsUserIDsArray;

    const showTicketsTF = JSFUNC.in_array(activityUserObj.user_id, activityShowTicketsUserIDsArray);

    return(
      <>
        <div
          className={"displayFlexRow borderB1ddd hoverLighterBlueGradient " + ((activityUserObj.lastLoginIsTodayTF) ? ("bgLightGreen") : (""))}
          style={{height:"2.2em"}}>
          <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
            <LibraryReact.Nowrap>
              {activityUserObj.fullName}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
            <LibraryReact.Nowrap p_fontClass="fontItalic">
              {activityUserObj.divisionName}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a displayFlexRowVc" style={{flexBasis:"4em"}}>
            <font className="fontItalic fontTextLightester">
              {"# Logins"}
            </font>
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"4em"}}>
            {activityUserObj.num_logins}
          </div>
          <div className="flex00a" style={{flexBasis:"1em"}} />
          <div className="flex00a displayFlexRowVc" style={{flexBasis:"5.5em"}}>
            <font className="fontItalic fontTextLightester">
              {"Last Login"}
            </font>
          </div>
          <div className="flex00a displayFlexRowVc" style={{flexBasis:"12em"}}>
            {activityUserObj.lastLoginDateTimeMjYgiA}
          </div>
          <div
            className={"flex00a displayFlexRow " + ((activityUserObj.hasTicketTF) ? ("cursorPointer") : (""))}
            style={{flexBasis:"19em"}}
            title={((activityUserObj.hasTicketTF) ? ("Click to view Tickets sent to BIT Solutions") : (undefined))}
            onClick={((activityUserObj.hasTicketTF) ? (this.onclick_num_tickets_container) : (undefined))}>
            <div className="flex00a displayFlexRowVc" style={{flexBasis:"6em"}}>
              <font className="fontItalic fontTextLightester">
                {"Open Tickets"}
              </font>
            </div>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
              {activityUserObj.numOpenTickets}
            </div>
            <div className="flex00a displayFlexRowVc" style={{flexBasis:"7em"}}>
              <font className="fontItalic fontTextLightester">
                {"Closed Tickets"}
              </font>
            </div>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
              {activityUserObj.numClosedTickets}
            </div>
          </div>
        </div>
        {(showTicketsTF && activityUserObj.numOpenTickets > 0) &&
          <UserActivityTicketsList
            p_ticketsArrayOfObjs={activityUserObj.openTicketsArrayOfObjs}
            p_label="Open Tickets"
          />
        }
        {(showTicketsTF && activityUserObj.numClosedTickets > 0) &&
          <UserActivityTicketsList
            p_ticketsArrayOfObjs={activityUserObj.closedTicketsArrayOfObjs}
            p_label="Closed Tickets"
          />
        }
      </>
    );
  }
}));

function UserActivityTicketsList(props) { //props: p_ticketsArrayOfObjs, p_label
  const ticketsArrayOfObjs = props.p_ticketsArrayOfObjs;
  const label = props.p_label;
  return(
    <div className="smallTopPad hugeBottomPad lrMedPad bgLightGray">
      <div className="tbMicroPad">
        <font className="font11 fontBold fontItalic fontTextLight">
          {label}
        </font>
      </div>
      {ticketsArrayOfObjs.map((m_ticketObj) =>
        <div className="smallBottomMargin tbPad bgLighterGray border1bbb">
          <div className="displayFlexRow">
            <div className="flex00a lrPad" style={{flexBasis:"6em"}}>
              <font className="">
                {m_ticketObj.initialDateLocalYmdDate}
              </font>
            </div>
            <div className="flex11a lrPad">
              <font className="">
                {m_ticketObj.initial_message}
              </font>
            </div>
          </div>
          {m_ticketObj.responsesArrayOfObjs.map((m_responseObj) =>
            <div className="displayFlexRow">
              <div className="flex00a" style={{flexBasis:"2em"}} />
              <div className={"flex11a displayFlexRow tbMicroMargin lrMedMargin tbMicroPad lrPad border bevelBorderColors borderRadius10 " + ((m_responseObj.isBITTF) ? ("bgLighterBlue") : ("bgLightestGray"))}>
                <div className="flex00a lrPad" style={{flexBasis:"6em"}}>
                  <font className="">
                    {m_responseObj.responseDateLocalYmdDate}
                  </font>
                </div>
                <div className="flex11a lrPad">
                  <font className="">
                    {(m_responseObj.isBITTF) &&
                      <font className="fontItalic fontBlue">{"(BIT Solutions) "}</font>
                    }
                    {m_responseObj.message}
                  </font>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}






//*****************************************************************************************************************************************************************
//*****************************************************************************************************************************************************************
//*****************************************************************************************************************************************************************
//*****************************************************************************************************************************************************************
//*****************************************************************************************************************************************************************
//*****************************************************************************************************************************************************************





export const AdminSubtabDivisions = inject("CaptureExecMobx", "CapturesMobx")(observer(
class AdminSubtabDivisions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_creatingNewDivisionTF: false,
      s_editingDivisionSettingsTF: false
    };
  }

  onclick_open_create_new_division_floating_box = () => {
    this.setState({s_creatingNewDivisionTF:true});
  }

  onclick_close_create_new_division_floating_box = () => {
    this.setState({s_creatingNewDivisionTF:false});
  }

  onclick_open_edit_division_settings_floating_box = () => {
    this.setState({s_editingDivisionSettingsTF:true});
  }

  onclick_close_edit_division_settings_floating_box = () => {
    this.setState({s_editingDivisionSettingsTF:false});
  }

  render() {
    const s_creatingNewDivisionTF = this.state.s_creatingNewDivisionTF;
    const s_editingDivisionSettingsTF = this.state.s_editingDivisionSettingsTF;
    
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_captureTableCurrentlyProcessingCapturesTF = this.props.CapturesMobx.c_captureTableCurrentlyProcessingCapturesTF;

    return (
      <div className="flex11a yScroll yScrollBottomPad">
        {(c_productStylingObj.setupCompanyCanCreateDivisionsTF || c_productStylingObj.setupCompanyCanEditDivisionSettingsTF) &&
          <div className="displayFlexRow bgLighterBlue borderB1ddd" style={{height:"4em"}}>
            <div className="flex11a displayFlexRowVc lrMedPad">
              {(c_productStylingObj.setupCompanyCanCreateDivisionsTF) &&
                <CEGeneralReact.CEButton
                  p_type="blue"
                  p_text="Create New Division"
                  f_onClick={this.onclick_open_create_new_division_floating_box}
                />
              }
            </div>
            {(c_productStylingObj.setupCompanyCanEditDivisionSettingsTF) &&
              <div className="flex00a displayFlexColumnHcVc lrMedPad borderL1ddd">
                <CEGeneralReact.CEButton
                  p_type="add"
                  p_text="Edit General Division Settings"
                  f_onClick={this.onclick_open_edit_division_settings_floating_box}
                />
              </div>
            }
          </div>
        }
        {(c_captureTableCurrentlyProcessingCapturesTF) ? (
          <CEGeneralReact.EmptyScreenGrayWithLoadingAnimation>
            {"Processing Captures"}
          </CEGeneralReact.EmptyScreenGrayWithLoadingAnimation>
        ) : (
          <DivisionsListWithUnassignedMatchingCapturesBox />
        )}
        {(s_creatingNewDivisionTF) &&
          <DivisionsCreateNewDivisionFloatingBox
            f_onClose={this.onclick_close_create_new_division_floating_box}
          />
        }
        {(s_editingDivisionSettingsTF) &&
          <DivisionsEditDivisionSettingsFloatingBox
            f_onClose={this.onclick_close_edit_division_settings_floating_box}
          />
        }
      </div>
    );
  }
}));

const DivisionsListWithUnassignedMatchingCapturesBox = inject("AdminMobx", "DatabaseMobx")(observer(
class DivisionsListWithUnassignedMatchingCapturesBox extends Component {
  render() {
    const c_adminAllDivisionsArrayOfObjs = this.props.AdminMobx.c_adminAllDivisionsArrayOfObjs;
    const c_divisionsUnassignedMatchingCapturesObj = this.props.AdminMobx.c_divisionsUnassignedMatchingCapturesObj;
    const c_fieldMapOfDivisionOwners = this.props.DatabaseMobx.c_fieldMapOfDivisionOwners;

    return (
      <>
        <div className="smallTopMargin" />
        {c_adminAllDivisionsArrayOfObjs.map((m_divisionObj) =>
          <AdminDivision
            key={m_divisionObj.id}
            p_divisionObj={m_divisionObj}
          />
        )}
        <div className="hugeTopMargin lrMedMargin">
          <UnassignedMatchingCapturesBoxAndCountButton
            p_unassignedMatchingCapturesObj={c_divisionsUnassignedMatchingCapturesObj}
            p_fieldDisplayName={c_fieldMapOfDivisionOwners.get("display_name")}
          />
        </div>
      </>
    );
  }
}));

const DivisionsEditDivisionSettingsFloatingBox = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class DivisionsEditDivisionSettingsFloatingBox extends Component {
  onsave_company_prime_sb_certs_can_vary_per_division = () => {
    const c_companyPrimeSBCertsCanVaryPerDivisionTF = this.props.DatabaseMobx.c_companyPrimeSBCertsCanVaryPerDivisionTF;
    const newValue01 = ((c_companyPrimeSBCertsCanVaryPerDivisionTF) ? (0) : (1));
    this.props.AdminMobx.a_divisions_update_company_prime_sb_certs_can_vary_per_division(newValue01);
  }

  onsave_company_using_division_firewalling = () => {
    const c_companyUsingDivisionFirewallingTF = this.props.DatabaseMobx.c_companyUsingDivisionFirewallingTF;
    const newValue01 = ((c_companyUsingDivisionFirewallingTF) ? (0) : (1));
    this.props.AdminMobx.a_divisions_update_company_using_division_firewalling(newValue01);
  }

  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_companyPrimeSBCertsCanVaryPerDivisionTF = this.props.DatabaseMobx.c_companyPrimeSBCertsCanVaryPerDivisionTF;
    const c_companyUsingDivisionFirewallingTF = this.props.DatabaseMobx.c_companyUsingDivisionFirewallingTF;
    const c_cardNameTeammates = this.props.DatabaseMobx.c_cardNameTeammates;

    return (
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditItem"
        p_title="Edit General Division Settings"
        f_onClickSave={this.props.f_onClose}>
        <div className="flex11a yScroll medFullPad">
          <div className="smallBottomMargin">
            <font className="font12 fontBold fontDarkBlue">
              {"Small Business Certifications per Division"}
            </font>
          </div>
          <div className="medBottomMargin">
            <div><font className="fontItalic fontTextLight">{"On the Capture " + c_cardNameTeammates + " Card, one of our Divisions can be selected to represent 'Our Company', whether it's the Prime, or a Sub based on the selected Capture Type. This switch determines the Small Business Certifications associated with each Division."}</font></div>
            <div className="lrMedPad"><font className="fontItalic fontTextLight">{"- When the switch is off, all divisions in " + c_productStylingObj.productName + " have the same SB Certs specified in the top level Division."}</font></div>
            <div className="lrMedPad"><font className="fontItalic fontTextLight">{"- When the switch is on, each division can define their own SB Certs."}</font></div>
            <div className="smallTopMargin"><font className="fontItalic fontTextLight">{"[Note: when turning this switch off, any unique SB Cert settings for each division will be lost and overwritten with the settings in the top level Division when turned back on]"}</font></div>
          </div>
          <div className="">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={c_companyPrimeSBCertsCanVaryPerDivisionTF}
              p_onText="When teaming, our Small Business Certifications can vary per division"
              p_offText="When teaming, our Small Business Certifications are the same for every division, as defined at the top level division"
              p_switchToOffMessage="Note: when turning this switch off, any unique SB Cert settings for each division will be lost and overwritten with the settings in the top level Division when turned back on"
              f_onSwitch={this.onsave_company_prime_sb_certs_can_vary_per_division}
            />
          </div>
          <div className="borderB1bbb hugeTopMargin hugeBottomMargin" />
          <div className="smallBottomMargin">
            <font className="font12 fontBold fontDarkBlue">
              {"Division Firewalling"}
            </font>
          </div>
          <div className="medBottomMargin">
            <div><font className="fontItalic fontTextLight">{"Every " + c_productStylingObj.productName + " user loads Captures from your database after entering their company code, email, and password during login."}</font></div>
            <div className="lrMedPad"><font className="fontItalic fontTextLight">{"- When this switch is off, every user always loads every Capture in the system upon login. This way, all current and historical Captures are available in searches and data summaries."}</font></div>
            <div className="lrMedPad"><font className="fontItalic fontTextLight">{"- When the switch is on, each Division has the option to 'firewall' which Captures are loaded when a user logs in. With firewalling active, as a rule, each user always loads every Capture they are listed as an owner of. Additionally, each user will load any Captures owned by the selected Divisions checked off for their Division's firewall settings."}</font></div>
            <div className="smallTopMargin"><font className="fontItalic fontTextLight">{"[Note: when turning this switch off, any unique firewalled division settings will be lost and overwritten by the default structure when turned back on]"}</font></div>
          </div>
          <div className="">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={c_companyUsingDivisionFirewallingTF}
              p_onText="Each Division chooses firewall preferences"
              p_offText="No firewalling, always load all captures"
              p_switchToOffMessage="Note: when turning this switch off, any unique firewalled division settings will be lost and overwritten by the default structure when turned back on"
              f_onSwitch={this.onsave_company_using_division_firewalling}
            />
          </div>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const AdminDivision = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminDivision extends Component { //props: p_divisionObj
  constructor(props) {
    super(props);
    this.state = {
      s_editingDivisionTF: false,
      s_findMySamUeiFloatingBoxIsOpenTF: false,
      s_showDivisionUsersFloatingBoxIsOpenTF: false
    };
  }

  onclick_division_edit = () => {
    this.setState({s_editingDivisionTF:true});
  }

  onclick_close_division_edit = () => {
    this.setState({s_editingDivisionTF:false});
  }

  onsave_division_name = (i_newValue) => { this.update_division_field("name", i_newValue, "s"); }
  onsave_division_nickname = (i_newValue) => { this.update_division_field("nickname", i_newValue, "s"); }
  onsave_division_color = (i_newValue) => { this.update_division_field("color", i_newValue, "s"); }
  onsave_business_type = (i_newValue) => { this.update_division_field("business_type_id", i_newValue, "i"); }
  onsave_prime_sb_certifications = (i_newValue) => { this.update_division_field("prime_sb_certifications_bm_set_aside_ids_comma", i_newValue, "s"); }
  onsave_firewalled_divisions = (i_newValue) => { this.update_division_field("firewalled_division_ids_comma", i_newValue, "s"); }
  onsave_integration_prefix = (i_newValue) => { this.update_division_field("integration_new_capture_integration_unique_id_prefix", i_newValue, "s"); }

  update_division_field = (i_fieldName, i_value, i_idsb) => {
    const p_divisionObj = this.props.p_divisionObj;

    var updatedValue = i_value;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminDivision", "update_division_field", ["i_fieldName", "i_value", "i_idsb"], [i_fieldName, i_value, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //if changing the firewalled divisions for this division, if every division is selected, put "-1" in the database instead of listing every divisionID so that if new divisions are added by the admin, they are automatically included
    if(i_fieldName === "firewalled_division_ids_comma") {
      const allDivisionIDsAreSelectedTF = JSFUNC.determine_if_every_id_within_mapOfMaps_is_preset_in_input_int_comma_list_tf(this.props.DatabaseMobx.o_tbl_a_divisions, "id", i_value);
      if(allDivisionIDsAreSelectedTF) {
        updatedValue = "-1";
      }
    }

    C_CallPhpTblUID.add_update("tbl_a_divisions", p_divisionObj.id, i_fieldName, updatedValue, i_idsb);

    //if changing business type from lb to sb or sb to lb, need to recalculate teammate counts and allocations for every capture with this division set as the our_prime_sub_teammate_division_id
    if(i_fieldName === "business_type_id") {
      const smallLargeInvalidBusinessTypeTFObj = this.props.DatabaseMobx.determine_small_large_invalid_business_type_tf_obj_from_business_type_id(updatedValue); //updated business type value
      if(smallLargeInvalidBusinessTypeTFObj.businessTypeIsSmallTF !== p_divisionObj.divisionIsSmallBusinessTF) { //if the division business size was changed from sb to lb/iv or from lb/iv to sb, it may need a recalc
        var ourDivisionIDsToRecalculateArray = [p_divisionObj.id]; //if the company can have different business types per division, only need to recalculate this division that was edited
        if(p_divisionObj.isTopDivision00TF && !this.props.DatabaseMobx.c_companyPrimeSBCertsCanVaryPerDivisionTF) { //if every division gets its business type from the top 00 division and that is being edited, all divisions need the recalc
          ourDivisionIDsToRecalculateArray = this.props.DatabaseMobx.c_allDivisionIDsArray; //array of every divisionID in company
        }

        const functionOnSuccess = (i_parseResponse) => {
          JSPHP.recalculate_and_update_capture_teammate_counts_and_allocations_from_division_id_or_ids_array(ourDivisionIDsToRecalculateArray);
        }
        C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
      }
    }

    C_CallPhpTblUID.execute();
  }

  onsave_division_parent_division = (i_newParentDivisionID) => {
    const p_divisionObj = this.props.p_divisionObj;
    const c_adminAllDivisionsArrayOfObjs = this.props.AdminMobx.c_adminAllDivisionsArrayOfObjs;

    var newParentDivisionTreeID = "00";
    for(let parentDivisionObj of c_adminAllDivisionsArrayOfObjs) {
      if(parentDivisionObj.id === i_newParentDivisionID) {
        newParentDivisionTreeID = parentDivisionObj.tree_id;
      }
    }

    const movedDivisionTreeID = p_divisionObj.tree_id;
    const movedDivisionTreeIDLength = movedDivisionTreeID.length;
    const newChildTreeID = JSFUNC.get_new_child_tree_id_from_arrayOfObjs(c_adminAllDivisionsArrayOfObjs, newParentDivisionTreeID);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminDivision", "onsave_division_parent_division", ["i_newParentDivisionID"], [i_newParentDivisionID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    for(let selfAndChildrenDivisionObj of c_adminAllDivisionsArrayOfObjs) {
      if(JSFUNC.in_array(selfAndChildrenDivisionObj.id, p_divisionObj.selfChildrenAndSubchildrenIDsArray)) {
        var updatedDivisionNewTreeID = newChildTreeID + selfAndChildrenDivisionObj.tree_id.substring(movedDivisionTreeIDLength, selfAndChildrenDivisionObj.tree_id.length);
        C_CallPhpTblUID.add_update("tbl_a_divisions", selfAndChildrenDivisionObj.id, "tree_id", updatedDivisionNewTreeID, "s");
      }
    }

    C_CallPhpTblUID.execute();
  }

  onsave_division_sibling_order = (i_newSiblingOrder) => {
    const p_divisionObj = this.props.p_divisionObj;
    const c_adminAllDivisionsArrayOfObjs = this.props.AdminMobx.c_adminAllDivisionsArrayOfObjs;

    const selectedParentDivisionID = JSFUNC.get_parent_item_id_from_child_tree_id(c_adminAllDivisionsArrayOfObjs, p_divisionObj.tree_id);
    const selectedParentDivisionMap = this.props.DatabaseMobx.o_tbl_a_divisions.get(selectedParentDivisionID);
    if(selectedParentDivisionMap !== undefined) {
      const selectedParentDivisionTreeID = selectedParentDivisionMap.get("tree_id");

      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminDivision", "onsave_division_sibling_order", ["i_newSiblingOrder"], [i_newSiblingOrder]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //all siblings that are not the one that was just resorted, leave a gap where the new one will be placed
      var oldSiblingOrder = 1;
      for(let siblingDivisionObj of c_adminAllDivisionsArrayOfObjs) {
        if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(selectedParentDivisionTreeID, siblingDivisionObj.tree_id, true)) {
          if(siblingDivisionObj.id !== p_divisionObj.id) {
            if(oldSiblingOrder === i_newSiblingOrder) {
              oldSiblingOrder++;
            }
            var updatedSiblingTreeID = selectedParentDivisionTreeID + JSFUNC.zero_pad_integer_from_left(oldSiblingOrder, 2);
            const updatedSiblingTreeIDLength = updatedSiblingTreeID.length;

            C_CallPhpTblUID.add_update("tbl_a_divisions", siblingDivisionObj.id, "tree_id", updatedSiblingTreeID, "s");

            for(let subchildrenDivisionObj of c_adminAllDivisionsArrayOfObjs) {
              if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(siblingDivisionObj.tree_id, subchildrenDivisionObj.tree_id, false)) {
                var updatedSubchildNewTreeID = updatedSiblingTreeID + subchildrenDivisionObj.tree_id.substring(updatedSiblingTreeIDLength, subchildrenDivisionObj.tree_id.length);
                C_CallPhpTblUID.add_update("tbl_a_divisions", subchildrenDivisionObj.id, "tree_id", updatedSubchildNewTreeID, "s");
              }
            }

            oldSiblingOrder++;
          }
        }
      }

      //put the moved division (and children/subchildren) into the gap at the newSiblingOrder
      var updatedSiblingTreeID = selectedParentDivisionTreeID + JSFUNC.zero_pad_integer_from_left(i_newSiblingOrder, 2);
      const updatedSiblingTreeIDLength = updatedSiblingTreeID.length;

      C_CallPhpTblUID.add_update("tbl_a_divisions", p_divisionObj.id, "tree_id", updatedSiblingTreeID, "s");

      for(let subchildrenDivisionObj of c_adminAllDivisionsArrayOfObjs) {
        if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(p_divisionObj.tree_id, subchildrenDivisionObj.tree_id, false)) {
          var updatedSubchildNewTreeID = updatedSiblingTreeID + subchildrenDivisionObj.tree_id.substring(updatedSiblingTreeIDLength, subchildrenDivisionObj.tree_id.length);
          C_CallPhpTblUID.add_update("tbl_a_divisions", subchildrenDivisionObj.id, "tree_id", updatedSubchildNewTreeID, "s");
        }
      }

      C_CallPhpTblUID.execute();
    }
  }

  onclick_open_find_my_sam_uei_floating_box = () => {
    this.setState({s_findMySamUeiFloatingBoxIsOpenTF:true});
  }

  onclick_close_find_my_sam_uei_floating_box = () => {
    this.setState({s_findMySamUeiFloatingBoxIsOpenTF:false});
  }

  onclick_select_sam_uei_company = (i_samUei, i_samUeiCompanyName) => {
    const p_divisionObj = this.props.p_divisionObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminDivision", "onclick_select_sam_uei_company", ["i_samUei", "i_samUeiCompanyName"], [i_samUei, i_samUeiCompanyName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_divisions", p_divisionObj.id, ["sam_uei", "sam_uei_company_name"], [i_samUei, i_samUeiCompanyName], ["s", "s"]);
    C_CallPhpTblUID.execute();

    this.onclick_close_find_my_sam_uei_floating_box();
  }

  onclick_open_show_division_users_floating_box = () => {
    this.setState({s_showDivisionUsersFloatingBoxIsOpenTF:true});
  }

  onclick_close_show_division_users_floating_box = () => {
    this.setState({s_showDivisionUsersFloatingBoxIsOpenTF:false});
  }

  ondelete_division = () => {
    const p_divisionObj = this.props.p_divisionObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminDivision", "ondelete_division", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //delete the division record
    C_CallPhpTblUID.add_delete("tbl_a_divisions", p_divisionObj.id); //tree_id is the sort column, this delete does not resort and leaves a hole in the sorting, inserting a new division does the full recalculation of tree_ids

    const functionOnSuccess = (i_parseResponse) => {
      //insert an admin changelog entry
      const C_CallPhpTblUIDAfterDeleteDivision = new JSPHP.ClassCallPhpTblUID(jsDescription);
      const adminChangelogValue = p_divisionObj.name;
      C_CallPhpTblUIDAfterDeleteDivision.add_changelog_admin(1001, adminChangelogValue);
      C_CallPhpTblUIDAfterDeleteDivision.execute();
      
      //if the division to delete was a small business type, it will change to a large type in its deleted --dne-- future form, thus teammates need to be recounted after this delete for all captures that have this division as our division
      if(p_divisionObj.divisionIsSmallBusinessTF) {
        JSPHP.recalculate_and_update_capture_teammate_counts_and_allocations_from_division_id_or_ids_array(p_divisionObj.id);
      }
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  render() {
    const editingDivisionTF = this.state.s_editingDivisionTF;
    const s_findMySamUeiFloatingBoxIsOpenTF = this.state.s_findMySamUeiFloatingBoxIsOpenTF;
    const s_showDivisionUsersFloatingBoxIsOpenTF = this.state.s_showDivisionUsersFloatingBoxIsOpenTF;

    const p_divisionObj = this.props.p_divisionObj;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_adminAllDivisionsArrayOfObjs = this.props.AdminMobx.c_adminAllDivisionsArrayOfObjs;
    const c_usersPerEmailNotDeactivatedArrayOfObjs = this.props.AdminMobx.c_usersPerEmailNotDeactivatedArrayOfObjs;
    const c_bitUsing3rdPartyIntegrationTF = this.props.DatabaseMobx.c_bitUsing3rdPartyIntegrationTF;
    const c_companyUsingDivisionFirewallingTF = this.props.DatabaseMobx.c_companyUsingDivisionFirewallingTF;
    const companyPrimeSBCertsCanVaryPerDivisionTF = this.props.DatabaseMobx.c_companyPrimeSBCertsCanVaryPerDivisionTF;

    const canEditBusinessTypeTF = (p_divisionObj.isTopDivision00TF || companyPrimeSBCertsCanVaryPerDivisionTF);
    const canDeleteDivisionTF = (p_divisionObj.selfChildrenAndSubchildrenIDsArray.length === 1);
    const canMoveDivisionTF = (p_divisionObj.tree_id.length > 2);

    var selectedParentDivisionID = -1;
    var oldSiblingOrder = -1;
    var siblingOrderArray = [];
    var siblingOrderDisplayArray = [];
    if(editingDivisionTF && canMoveDivisionTF) {
      selectedParentDivisionID = JSFUNC.get_parent_item_id_from_child_tree_id(c_adminAllDivisionsArrayOfObjs, p_divisionObj.tree_id);
      const selectedParentDivisionMap = this.props.DatabaseMobx.o_tbl_a_divisions.get(selectedParentDivisionID);
      if(selectedParentDivisionMap !== undefined) {
        const selectedParentDivisionTreeID = selectedParentDivisionMap.get("tree_id");
        var siblingOrder = 1;
        for(let childrenDivisionObj of c_adminAllDivisionsArrayOfObjs) {
          if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(selectedParentDivisionTreeID, childrenDivisionObj.tree_id, true)) {
            if(p_divisionObj.id === childrenDivisionObj.id) {
              oldSiblingOrder = siblingOrder;
            }
            siblingOrderArray.push(siblingOrder);
            siblingOrderDisplayArray.push(JSFUNC.num2str(siblingOrder));
            siblingOrder++;
          }
        }
      }
    }

    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Sibling Order", siblingOrderArray, false, siblingOrderDisplayArray, swsOptionsObj);
    const selectSiblingOrderFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

    return (
      <div className="displayFlexRow lrMedMargin">
        {p_divisionObj.indentsArray.map((i_indentNum) =>
          <div key={"ind" + i_indentNum} className="flex00a" style={{flexBasis:"1.6em", borderLeft:"solid 1px #ddd", marginLeft:"0.8em"}} />
        )}
        <div
          className="flex11a displayFlexRow microTopMargin microBottomMargin lrPad hoverLighterBlueGradient cursorPointer"
          style={{flexBasis:"100em", height:"3em", backgroundColor:"#f5f2f0", border:"solid 1px #cba", borderRadius:"0.5em"}}
          onClick={this.onclick_division_edit}>
          <div className="flex00a smallFullMargin border bevelBorderColors" style={{flexBasis:"1em", background:"#" + p_divisionObj.color}} />
          <div className="flex11a displayFlexColumnVc lrPad" style={{flexBasis:"100em"}}>
            <div className="">
              <LibraryReact.Nowrap p_fontClass="font11">
                {p_divisionObj.name}
              </LibraryReact.Nowrap>
            </div>
            {(p_divisionObj.nickname !== "") &&
              <div style={{marginLeft:"1em"}}>
                <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                  {p_divisionObj.nickname}
                </LibraryReact.Nowrap>
              </div>
            }
          </div>
          {(canEditBusinessTypeTF) &&
            <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:"20em"}}>
              <div>
                <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                  {((p_divisionObj.divisionIsSmallBusinessTF) ? ("Small Business Certifications:") : ("Large Business"))}
                </LibraryReact.Nowrap>
              </div>
              {(p_divisionObj.divisionIsSmallBusinessTF) &&
                <div className="microTopMargin">
                  <LibraryReact.Nowrap p_fontClass={"fontTextLight " + ((p_divisionObj.calcPrimeSBCertificationsIsFilledOutTF) ? ("") : ("fontItalic"))}>
                    {p_divisionObj.calcPrimeSBCertificationsShortNamesMask}
                  </LibraryReact.Nowrap>
                </div>
              }
            </div>
          }
          {(c_companyUsingDivisionFirewallingTF) &&
            <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:"20em"}}>
              <div>
                <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                  {((p_divisionObj.isTopDivision00TF) ? ("Top Division always loads all Captures") : ("Firewalled Divisions:"))}
                </LibraryReact.Nowrap>
              </div>
              {(!p_divisionObj.isTopDivision00TF) &&
                <div className="microTopMargin">
                  <LibraryReact.Nowrap p_fontClass={"fontTextLight " + ((p_divisionObj.calcFirewalledDivisionsIsFilledOutTF) ? ("") : ("fontItalic"))}>
                    {p_divisionObj.calcFirewalledDivisionsMask}
                  </LibraryReact.Nowrap>
                </div>
              }
            </div>
          }
          <div
            className="flex00a displayFlexColumnVc lrPad"
            style={{flexBasis:"12em"}}
            title={"SAM UEI: '" + ((p_divisionObj.calcSamUeiWithUphillRollIsFilledOutTF) ? (p_divisionObj.calcSamUeiWithUphillRoll + "' [" + p_divisionObj.calcCompanyNameWithUphillRoll + "]" + ((p_divisionObj.calcSamUeiIsFromUphillTF) ? (" (inherited from '" + p_divisionObj.calcSamUeiIsFromUphillDivisionName + "'") : (""))) : ("--No SAM UEI Selected--")) + "\n\nThis is your company's SAM.gov 'Unique Entity ID', used in the " + c_productStylingObj.productName + " GovCon Smart Search for the AI powered CE.A.I. opportunities search tool"}>
            <div>
              <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                {"SAM UEI:"}
              </LibraryReact.Nowrap>
            </div>
            <div className="microTopMargin">
              <LibraryReact.Nowrap p_fontClass={((p_divisionObj.calcSamUeiIsFromUphillTF) ? ("fontTextLightester") : ("fontTextLight"))}>
                {((p_divisionObj.calcSamUeiWithUphillRollIsFilledOutTF) ? (p_divisionObj.calcSamUeiWithUphillRoll + " [" + p_divisionObj.calcCompanyNameWithUphillRoll + "]") : (""))}
              </LibraryReact.Nowrap>
            </div>
          </div>
          {(c_bitUsing3rdPartyIntegrationTF) &&
            <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:"6em"}} title={((p_divisionObj.calcIntegrationPrefixIsFilledOutTF) ? ("3rd Party Integration system new capture unique ID prefix: '" + p_divisionObj.integration_new_capture_integration_unique_id_prefix + "'") : (""))}>
              <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                {((p_divisionObj.calcIntegrationPrefixIsFilledOutTF) ? ("[" + p_divisionObj.integration_new_capture_integration_unique_id_prefix + "]") : (""))}
              </LibraryReact.Nowrap>
            </div>
          }
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"10em"}}>
            <div className="">
              <font className="fontItalic fontTextLighter">
                {"# Captures"}
              </font>
            </div>
            <CapturesReact.NumberOfAssignedCapturesCountButton
              p_partialNumMatchingCaptures={p_divisionObj.numCapturesOwnedByDivision}
              p_matchingCaptureIDsArray={p_divisionObj.capturesOwnedByDivisionCaptureIDsArray}
              p_floatingBoxTitle={"Captures Owned by Division '" + p_divisionObj.name + "'"}
            />
          </div>
          <div
            className="flex00a displayFlexColumnHcVc"
            style={{flexBasis:"10em"}}
            title={p_divisionObj.numUsersAssigned + " " + c_productStylingObj.productName + " Users are assigned to division '" + p_divisionObj.name + "'"}>
            <div className="">
              <font className="fontItalic fontTextLighter">
                {"# Users"}
              </font>
            </div>
            <LibraryReact.InteractiveDiv
              p_class="displayFlexColumnHcVc border bevelBorderColors borderRadius10 bgLightGrayGradient hoverLighterGrayGradient cursorPointer textCenter"
              p_styleObj={{height:"1.3em", width:"3.5em"}}
              p_title={"View all Users assigned to Division '" + p_divisionObj.name + "'"}
              f_onClick={this.onclick_open_show_division_users_floating_box}>
              <font className="fontBold">
                {p_divisionObj.numUsersAssigned}
              </font>
            </LibraryReact.InteractiveDiv>
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
            {(p_divisionObj.tree_id.length > 2) &&
              <CEGeneralReact.DeleteMenu
                p_menuItemText="Delete"
                p_message={((canDeleteDivisionTF) ? ("Are you sure you want to delete Division '" + p_divisionObj.name + "'?") : ("Division '" + p_divisionObj.name + "' cannot be deleted until all of its child divisions have first been deleted."))}
                p_noDeleteTF={!canDeleteDivisionTF}
                f_onDelete={this.ondelete_division}
              />
            }
          </div>
        </div>
        {(editingDivisionTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="addEditDeleteTableEditItem"
            p_title={"Editing Division " + p_divisionObj.name}
            f_onClickSave={this.onclick_close_division_edit}>
            <div className="flex11a yScroll medFullPad">
              <DivisionsAddEditFloatingBoxSectionLabel p_label="Attributes" p_dividerLineAboveTF={false} />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "name"}
                p_fieldDisplayName="Division Name"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={p_divisionObj.name}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="17em"
                f_onSaveChanged={this.onsave_division_name}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "nickname"}
                p_fieldDisplayName="Division Abbr Name (optional)"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={p_divisionObj.nickname}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="17em"
                f_onSaveChanged={this.onsave_division_nickname}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "color"}
                p_fieldDisplayName="Color (represents division in graphs)"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericColorFieldTypeObj}
                p_valueRaw={p_divisionObj.color}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="17em"
                f_onSaveChanged={this.onsave_division_color}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "businesstype"}
                p_fieldDisplayName="Division Business Type"
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectBusinessTypeFieldTypeObj}
                p_valueRaw={p_divisionObj.business_type_id}
                p_valueMask={((canEditBusinessTypeTF) ? (undefined) : (this.props.DatabaseMobx.not_filled_out_font_html("Value is set at top level for all divisions")))}
                p_valueIsEditableTFU={canEditBusinessTypeTF}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="17em"
                f_onSaveChanged={this.onsave_business_type}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "sbcerts"}
                p_fieldDisplayName="Small Business Certifications"
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj}
                p_valueRaw={p_divisionObj.prime_sb_certifications_bm_set_aside_ids_comma}
                p_valueMask={((canEditBusinessTypeTF) ? (((p_divisionObj.divisionIsSmallBusinessTF) ? (undefined) : (this.props.DatabaseMobx.not_filled_out_font_html("N/A")))) : (this.props.DatabaseMobx.not_filled_out_font_html("Value is set at top level for all divisions")))}
                p_valueIsEditableTFU={(canEditBusinessTypeTF && p_divisionObj.divisionIsSmallBusinessTF)}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="17em"
                f_onSaveChanged={this.onsave_prime_sb_certifications}
              />
              <div className="displayFlexRowVc">
                <div className="flex11a">
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "samuei"}
                    p_fieldDisplayName="SAM.gov Unique Entity ID (UEI)"
                    p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                    p_valueRaw={((p_divisionObj.calcSamUeiWithUphillRollIsFilledOutTF) ? (p_divisionObj.calcSamUeiWithUphillRoll + " [" + p_divisionObj.calcCompanyNameWithUphillRoll + "]") : (""))}
                    p_valueIsEditableTFU={false}
                    p_containerClass="tbMargin"
                    p_fieldClass="fontBlue"
                    p_fieldWidth="17em"
                  />
                </div>
                <div className="flex00a lrMedPad">
                  <CEGeneralReact.CEButton
                    p_type="add"
                    p_text="Find My UEI"
                    f_onClick={this.onclick_open_find_my_sam_uei_floating_box}
                  />
                  {(s_findMySamUeiFloatingBoxIsOpenTF) &&
                    <CEGeneralReact.FloatingBoxWithSaveCancel
                      p_trblFlag="large"
                      p_title={"Find My SAM.gov UEI for Division '" + p_divisionObj.name + "'"}
                      f_onClickCancel={this.onclick_close_find_my_sam_uei_floating_box}>
                      <DivisionsFindMyUeiFloatingBoxContents
                        p_divisionObj={p_divisionObj}
                        f_onClickSelectSamUeiCompany={this.onclick_select_sam_uei_company}
                      />
                    </CEGeneralReact.FloatingBoxWithSaveCancel>
                  }
                </div>
              </div>
              {(canMoveDivisionTF) &&
                <>
                  <DivisionsAddEditFloatingBoxSectionLabel p_label="Org Chart Positioning" p_dividerLineAboveTF={true} />
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "parentDiv"}
                    p_fieldDisplayName="Parent Division"
                    p_fieldTypeObj={this.props.DatabaseMobx.c_selectDivisionFieldTypeObj}
                    p_valueRaw={selectedParentDivisionID}
                    p_containerClass="tbMargin"
                    p_fieldClass="fontBlue"
                    p_fieldWidth="17em"
                    p_valueIsEditableTFU={true}
                    p_valuesToNotIncludeArray={p_divisionObj.selfChildrenAndSubchildrenIDsArray}
                    f_onSaveChanged={this.onsave_division_parent_division}
                  />
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "siblingOrder"}
                    p_fieldDisplayName="Order Among Sibling Divisions"
                    p_fieldTypeObj={selectSiblingOrderFieldTypeObj}
                    p_valueRaw={oldSiblingOrder}
                    p_containerClass="tbMargin"
                    p_fieldClass="fontBlue"
                    p_fieldWidth="17em"
                    p_valueIsEditableTFU={true}
                    f_onSaveChanged={this.onsave_division_sibling_order}
                  />
                </>
              }
              {(c_companyUsingDivisionFirewallingTF && !p_divisionObj.isTopDivision00TF) &&
                <>
                  <DivisionsAddEditFloatingBoxSectionLabel p_label="Firewalling" p_dividerLineAboveTF={true} />
                  <div className="microTopMargin">
                    <font className="fontItalic fontTextLighter">
                      {"Select every company/division/department that a user assigned to this division (assigned on the System Setup 'Users' subtab) can view, edit, or search for captures that have these divisions assigned to the '" + this.props.DatabaseMobx.c_fieldMapOfDivisionOwners.get("display_name") + "' field"}
                    </font>
                  </div>
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "firewalleddivisions"}
                    p_fieldDisplayName="Firewalled Divisions"
                    p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiAdminAllDivisionsIncludingFirewalledFieldTypeObj}
                    p_valueRaw={p_divisionObj.calcFirewalledDivisionIDsComma}
                    p_valueMask={p_divisionObj.calcFirewalledDivisionsMask}
                    p_valueIsEditableTFU={true}
                    p_containerClass="tbMargin"
                    p_fieldClass="fontBlue"
                    p_fieldWidth="17em"
                    f_onSaveChanged={this.onsave_firewalled_divisions}
                  />
                </>
              }
              {(c_bitUsing3rdPartyIntegrationTF) &&
                <>
                  <DivisionsAddEditFloatingBoxSectionLabel p_label="3rd Party Integration Options" p_dividerLineAboveTF={true} />
                  <div className="microTopMargin">
                    <font className="fontItalic fontTextLighter">
                      {"If using a 3rd Party Integration to synchronize systems like VantagePoint, this option per Division can specify a 'prefix' to apply to the unique ID in the integrated system when a new capture is created in " + c_productStylingObj.productName}
                    </font>
                  </div>
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString={"adminDivisionEdit_dID" + p_divisionObj.id + "integrationPrefix"}
                    p_fieldDisplayName="Integration New Capture Prefix"
                    p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                    p_valueRaw={p_divisionObj.integration_new_capture_integration_unique_id_prefix}
                    p_valueIsEditableTFU={true}
                    p_containerClass="tbMargin"
                    p_fieldClass="fontBlue"
                    p_fieldWidth="17em"
                    f_onSaveChanged={this.onsave_integration_prefix}
                  />
                </>
              }
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
        {(s_showDivisionUsersFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="smallVertical"
            p_title={"Viewing All Users assigned to Division " + p_divisionObj.name}
            f_onClickCancel={this.onclick_close_show_division_users_floating_box}>
            <div className="flex11a yScroll medFullPad">
              {c_usersPerEmailNotDeactivatedArrayOfObjs.map((m_userPerEmailNotDeactivatedObj) =>
                m_userPerEmailNotDeactivatedObj.upeMultiLoginCombinedUsersArrayOfObjs.map((m_combinedUserObj) =>
                  (m_combinedUserObj.division_id === p_divisionObj.id) &&
                  <div className="displayFlexRowVc microBottomMargin border1ddd bgLightestBlue tbPad">
                    <div className="flex11a lrPad" style={{flexBasis:"100em"}}>
                      <font className="">
                        {m_combinedUserObj.firstNameLastName}
                      </font>
                    </div>
                    <div className="flex11a lrPad" style={{flexBasis:"100em"}}>
                      <font className="fontItalic">
                        {m_combinedUserObj.powerDisplayName}
                      </font>
                    </div>
                  </div>
                )
              )}
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </div>
    );
  }
}));

function DivisionsAddEditFloatingBoxSectionLabel(props) { //p_label, p_dividerLineAboveTF
  return(
    <div className={((props.p_dividerLineAboveTF) ? ("bigTopMargin borderT1ddd smallTopPad") : (""))}>
      <font className="font13 fontBold fontItalic fontDarkBlue">
        {props.p_label}
      </font>
    </div>
  );
}


const DivisionsFindMyUeiFloatingBoxContents = inject("AdminMobx")(observer(
class DivisionsFindMyUeiFloatingBoxContents extends Component { //props: p_divisionObj, f_onClickSelectSamUeiCompany(i_samUei, i_samUeiCompanyName)
  constructor(props) {
    super(props);
    this.state = {
      s_companyNameSearchTerm: ""
    };
  }

  onchange_company_name_search_term = (i_newValue) => {
    this.setState({s_companyNameSearchTerm:i_newValue})
  }

  onclick_conduct_search_button = () => {
    const s_companyNameSearchTerm = this.state.s_companyNameSearchTerm;
    this.props.AdminMobx.a_divisions_find_my_uei_search_sam_companies(s_companyNameSearchTerm);
  }

  onclick_clear_previous_sam_uei_and_company_name_values = () => {
    if(JSFUNC.is_function(this.props.f_onClickSelectSamUeiCompany)) {
      this.props.f_onClickSelectSamUeiCompany("", "");
    }
  }

  render() {
    const s_companyNameSearchTerm = this.state.s_companyNameSearchTerm;

    const p_divisionObj = this.props.p_divisionObj;
    
    const o_divisionsFindMyUeiLoadingFlag = this.props.AdminMobx.o_divisionsFindMyUeiLoadingFlag;
    const o_divisionsFindMyUeiPreviousCompanyNameSearchTerm = this.props.AdminMobx.o_divisionsFindMyUeiPreviousCompanyNameSearchTerm;
    const o_divisionsFindMyUeiSamUeiCompaniesArrayOfObjs = this.props.AdminMobx.o_divisionsFindMyUeiSamUeiCompaniesArrayOfObjs;

    const samUeiTblColumnDbNamesArray = [
      "company_name", "sam_uei", "cage_codes_semi", "aac_codes_semi", "cities_semi", 
      "states_semi", "countries_semi", "entity_structures_semi", "primary_naics_codes_semi", "business_types_semi", 
      "naics_codes_semi", "product_service_codes_semi", "exception_naics_codes_semi", "history_departments_semi", "history_agency_ids_semi", 
      "history_office_ids_semi", "history_naics_codes_semi", "history_product_service_codes_semi", "history_setaside_codes_semi", "history_pop_states_semi", 
      "history_parent_description_semi"
    ];

    const samUeiTblColumnDisplayNamesArray = [
      "Company Name", "SAM UEI", "Cage Codes", "AAC Codes", "Cities", 
      "States", "Countries", "Entity Structures", "Primary NAICS Codes", "Business Types", 
      "NAICS codes", "Product Service Codes", "Exception NAICS Codes", "Departments (history)", "Agency IDs (history)", 
      "Office IDs (history)", "NAICS Codes (history)", "Product Service Codes (history)", "Set-Aside Codes (history)", "PoP States (history)", 
      "Parent Description (history)"
    ];

    const samUeiTblColumnWidthsArray = [
      25, 10, 10, 10, 10, 
      10, 10, 10, 10, 10, 
      10, 10, 10, 10, 10, 
      10, 10, 10, 10, 10, 
      25
    ];

    const numMatchingResults = o_divisionsFindMyUeiSamUeiCompaniesArrayOfObjs.length;

    var resultsComponent = null;
    if(o_divisionsFindMyUeiLoadingFlag === "results") { //normal search results table
      if(numMatchingResults === 0) {
        resultsComponent = (
          <CEGeneralReact.EmptyScreenWhite>
            <div className="smallBottomMargin">
              {"0 results match your search '" + o_divisionsFindMyUeiPreviousCompanyNameSearchTerm + "'"}
            </div>
          </CEGeneralReact.EmptyScreenWhite>
        );
      }
      else {
        resultsComponent = (
          <>
            <div className="flex00a medFullPad">
              <font className="">
                {numMatchingResults + " " + JSFUNC.plural(numMatchingResults, "Result", "Results") + " match Company Name search '" + o_divisionsFindMyUeiPreviousCompanyNameSearchTerm + "'"}
              </font>
            </div>
            <div className="flex11a displayFlexColumn medFullPad xyScroll">
              <div className="flex00a displayFlexRow" style={{flexBasis:"3em"}}>
                <div className="flex00a bgLightGrayGradient" style={{flexBasis:"8em"}} />
                {samUeiTblColumnDisplayNamesArray.map((m_samUeiTblColumnDisplayName, m_index) =>
                  <div className="flex00a displayFlexRowVc borderR1bbb bgLightGrayGradient textCenter" style={{flexBasis:samUeiTblColumnWidthsArray[m_index] + "em"}} title={m_samUeiTblColumnDisplayName}>
                    <LibraryReact.MaxHeightWrap p_maxHeight="2.7em" p_fontClass="fontBold fontTextLight">
                      {m_samUeiTblColumnDisplayName}
                    </LibraryReact.MaxHeightWrap>
                  </div>
                )}
              </div>
              {o_divisionsFindMyUeiSamUeiCompaniesArrayOfObjs.map((m_samUeiCompanyObj) =>
                <DivisionsFindMyUeiResultsSingleCompanyRow
                  p_samUeiCompanyObj={m_samUeiCompanyObj}
                  p_samUeiTblColumnDbNamesArray={samUeiTblColumnDbNamesArray}
                  p_samUeiTblColumnWidthsArray={samUeiTblColumnWidthsArray}
                  f_onClickSelectSamUeiCompany={this.props.f_onClickSelectSamUeiCompany}
                />
              )}
            </div>
          </>
        );
      }
    }
    else if(o_divisionsFindMyUeiLoadingFlag === "loading") { //loading
      resultsComponent = (
        <CEGeneralReact.EmptyScreenWhite>
          <div className="smallBottomMargin">
            {"Searching SAM.gov UEI Companies for '" + s_companyNameSearchTerm + "'..."}
          </div>
          <CEGeneralReact.LoadingAnimation />
        </CEGeneralReact.EmptyScreenWhite>
      );
    }
    else if(o_divisionsFindMyUeiLoadingFlag === "error") { //error conducting search
      resultsComponent = (
        <CEGeneralReact.EmptyScreenWhite>
          <font className="fontDarkRed">
            {"SAM UEI Companies database error occured during search"}
          </font>
        </CEGeneralReact.EmptyScreenWhite>
      );
    }
    else { //init
      resultsComponent = (
        <CEGeneralReact.EmptyScreenWhite>
          <div className="smallBottomMargin">
            {"Enter some or all of your Company Name and click 'Search' to begin. Only the first 100 matching results will be returned"}
          </div>
        </CEGeneralReact.EmptyScreenWhite>
      );
    }

    return(
      <>
        <div className="flex00a displayFlexRow borderB1bbb medFullPad">
          <div className="flex00a displayFlexColumnVc" style={{flexBasis:"10em"}}>
            <font className="">
              {"Search for your company by name: "}
            </font>
          </div>
          <div className="flex11a displayFlexColumnVc" style={{maxWidth:"30em"}}>
            <CEGeneralReact.SearchInputTextWithClearX
              p_inputText={s_companyNameSearchTerm}
              p_placeholderText="Search SAM.gov Companies on File..."
              p_includeSearchIconTF={true}
              p_heightEm={2.2}
              p_tabIndex={1}
              f_onKeyDownEnter={((o_divisionsFindMyUeiLoadingFlag === "loading") ? (undefined) : (this.onclick_conduct_search_button))}
              f_onChange={((o_divisionsFindMyUeiLoadingFlag === "loading") ? (undefined) : (this.onchange_company_name_search_term))}
            />
          </div>
          <div className="flex00a displayFlexColumnHcVc lrMedPad" title="Conduct a search to find the SAM UEI matching your Company">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Search"
              f_onClick={this.onclick_conduct_search_button}
            />
          </div>
          <div className="flex11a" />
          {(p_divisionObj.calcSamUeiWithUphillRollIsFilledOutTF && !p_divisionObj.calcSamUeiIsFromUphillTF) &&
            <div className="flex00a displayFlexColumnHcVc lrMedPad" title="Clear out the previously selected SAM UEI value for this Company/Division">
              <CEGeneralReact.CEButton
                p_type="add"
                p_text="Clear"
                f_onClick={this.onclick_clear_previous_sam_uei_and_company_name_values}
              />
            </div>
          }
        </div>
        {resultsComponent}
      </>
    );
  }
}));


const DivisionsFindMyUeiResultsSingleCompanyRow = inject("AdminMobx", "DatabaseMobx")(observer(
class DivisionsFindMyUeiResultsSingleCompanyRow extends Component { //props: p_samUeiCompanyObj, p_samUeiTblColumnDbNamesArray, p_samUeiTblColumnWidthsArray, f_onClickSelectSamUeiCompany(i_samUei, i_samUeiCompanyName)
  onclick_select_sam_uei_company = () => {
    const p_samUeiCompanyObj = this.props.p_samUeiCompanyObj;
    if(JSFUNC.is_function(this.props.f_onClickSelectSamUeiCompany)) {
      this.props.f_onClickSelectSamUeiCompany(p_samUeiCompanyObj.sam_uei, p_samUeiCompanyObj.company_name);
    }
  }

  render() {
    const p_samUeiCompanyObj = this.props.p_samUeiCompanyObj;
    const p_samUeiTblColumnDbNamesArray = this.props.p_samUeiTblColumnDbNamesArray;
    const p_samUeiTblColumnWidthsArray = this.props.p_samUeiTblColumnWidthsArray;

    return(
      <div className="flex00a displayFlexRow borderB1ddd" style={{flexBasis:"2.5em"}}>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"8em"}}>
          <CEGeneralReact.CEButton
            p_type="add"
            p_text="Select"
            f_onClick={this.onclick_select_sam_uei_company}
          />
        </div>
        {p_samUeiTblColumnDbNamesArray.map((m_samUeiTblColumnDbName, m_index) =>
          <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:p_samUeiTblColumnWidthsArray[m_index] + "em"}} title={p_samUeiCompanyObj[m_samUeiTblColumnDbName]}>
            <LibraryReact.Nowrap p_fontClass="">
              {p_samUeiCompanyObj[m_samUeiTblColumnDbName]}
            </LibraryReact.Nowrap>
          </div>
        )}
      </div>
    );
  }
}));



const DivisionsCreateNewDivisionFloatingBox = inject("AdminMobx", "DatabaseMobx")(observer(
class DivisionsCreateNewDivisionFloatingBox extends Component { //props: f_onClose
  constructor(props) {
    super(props);
    this.state = {
      s_divisionName: "",
      s_divisionNickname: "",
      s_divisionColor: "005da3",
      s_divisionBusinessTypeID: this.props.DatabaseMobx.c_firstBusinessTypeID,
      s_divisionPrimeSBCertificationsBmSetAsideIDsComma: "",
      s_parentDivisionID: -1,
      s_siblingOrder: 1,
      s_showErrorsTF: false,
      s_isWorkingTF: false
    };
  }

  onchange_division_name = (i_newValue) => { this.setState({s_divisionName:i_newValue}); }
  onchange_division_nickname = (i_newValue) => { this.setState({s_divisionNickname:i_newValue}); }
  onchange_division_color = (i_newValue) => { this.setState({s_divisionColor:i_newValue}); }
  onchange_division_business_type = (i_newValue) => { this.setState({s_divisionBusinessTypeID:i_newValue}); }
  onchange_division_sb_certifications = (i_newValue) => { this.setState({s_divisionPrimeSBCertificationsBmSetAsideIDsComma:i_newValue}); }
  onchange_parent_division_id = (i_newValue) => {
    this.setState({
      s_parentDivisionID: i_newValue,
      s_siblingOrder: 1
    });
  }
  onchange_sibling_order = (i_newValue) => { this.setState({s_siblingOrder:i_newValue}); }

  onclick_create_new_division = () => {
    const s_divisionName = this.state.s_divisionName;
    const s_divisionNickname = this.state.s_divisionNickname;
    const s_divisionColor = this.state.s_divisionColor;
    const s_divisionBusinessTypeID = this.state.s_divisionBusinessTypeID;
    const s_divisionPrimeSBCertificationsBmSetAsideIDsComma = this.state.s_divisionPrimeSBCertificationsBmSetAsideIDsComma;
    const s_parentDivisionID = this.state.s_parentDivisionID;
    const s_siblingOrder = this.state.s_siblingOrder;

    const selectedParentDivisionMap = this.props.DatabaseMobx.o_tbl_a_divisions.get(s_parentDivisionID);
    if(this.division_name_error_tf() || this.parent_division_error_tf() || this.sibling_order_error_tf() || (selectedParentDivisionMap === undefined)) {
      this.setState({s_showErrorsTF:true});
    }
    else {
      this.setState({s_isWorkingTF:true});

      const allOldDivisionsArrayOfObjs = this.props.AdminMobx.c_adminAllDivisionsArrayOfObjs;

      //determine new division treeID (parent + selected order)
      const parentTreeID = selectedParentDivisionMap.get("tree_id");
      const newDivisionTreeID = parentTreeID + JSFUNC.zero_pad_integer_from_left(s_siblingOrder, 2);

      //determine all existing treeIDs that need to have their treeID changed
      var divisionsToUpdateArrayOfObjs = [];
      for(let oldDivisionObj of allOldDivisionsArrayOfObjs) {
        var oldDivisionTreeID = oldDivisionObj.tree_id;
        if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(parentTreeID, oldDivisionTreeID, true)) { //verify that this division being checked is a direct child of the parent selected to assign the new one under
          var oldDivisionTreeIDLength = oldDivisionTreeID.length;
          var oldDivisionTreeIDLast2Digits = oldDivisionTreeID.substring(oldDivisionTreeIDLength - 2);
          var divisionSiblingOrder = JSFUNC.str2int(oldDivisionTreeIDLast2Digits);
          if(divisionSiblingOrder >= s_siblingOrder) { //same or further than the new sibling order needs to be changed by +1
            var changedSiblingOrder = (divisionSiblingOrder + 1);
            var changedSiblingTreeID = parentTreeID + JSFUNC.zero_pad_integer_from_left(changedSiblingOrder, 2);
            for(let changedDivisionObj of allOldDivisionsArrayOfObjs) {
              if(changedDivisionObj.tree_id === oldDivisionTreeID) { //this is the sibling division being changed
                divisionsToUpdateArrayOfObjs.push({divisionID:changedDivisionObj.id, updatedTreeID:changedSiblingTreeID});
              }
              else if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(oldDivisionTreeID, changedDivisionObj.tree_id, false)) { //this is one of the children/subchildren of the changed division, needs the front part of the treeID changed
                var changedChildTreeID = changedSiblingTreeID + changedDivisionObj.tree_id.substring(oldDivisionTreeIDLength);
                divisionsToUpdateArrayOfObjs.push({divisionID:changedDivisionObj.id, updatedTreeID:changedChildTreeID});
              }
            }
          }
        }
      }

      const jsDescription = JSFUNC.js_description_from_action("AdminReact - DivisionsCreateNewDivisionFloatingBox", "onclick_create_new_division", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const fieldNamesArray = ["tree_id", "name", "nickname", "color", "business_type_id", "prime_sb_certifications_bm_set_aside_ids_comma", "firewalled_division_ids_comma", "sam_uei", "sam_uei_company_name", "integration_new_capture_integration_unique_id_prefix"];
      const valuesArray = [newDivisionTreeID, s_divisionName, s_divisionNickname, s_divisionColor, s_divisionBusinessTypeID, s_divisionPrimeSBCertificationsBmSetAsideIDsComma, "", "", "", ""];
      const idsbArray = ["s", "s", "s", "s", "i", "s", "s", "s", "s", "s"];
      C_CallPhpTblUID.add_insert("tbl_a_divisions", fieldNamesArray, valuesArray, idsbArray);

      for(let divisionToUpdateObj of divisionsToUpdateArrayOfObjs) {
        C_CallPhpTblUID.add_update("tbl_a_divisions", divisionToUpdateObj.divisionID, "tree_id", divisionToUpdateObj.updatedTreeID, "s");
      }

      const functionOnSuccess = (i_parseResponse) => {
        const newlyInsertedDivisionID = i_parseResponse.outputObj.i0;

        this.setState({s_isWorkingTF:false});
        
        const C_CallPhpTblUIDUpdateNewlyInsertedDivision = new JSPHP.ClassCallPhpTblUID(jsDescription);

        //after the new division is inserted, insert an admin changelog entry
        const adminChangelogValue = s_divisionName;
        C_CallPhpTblUIDUpdateNewlyInsertedDivision.add_changelog_admin(1000, adminChangelogValue);
        
        //also, if this company is using firewalling, use the new divisionID created to fill in the firewalledDivisionIDsComma so that this new division can at least see itself
        if(this.props.DatabaseMobx.c_companyUsingDivisionFirewallingTF) {
          const newlyInsertedDivisionIDString = JSFUNC.num2str(newlyInsertedDivisionID); //must convert single int to a string as first entry into this comma list (doesn't matter for database, but needs to be correct type for internal memory)
          C_CallPhpTblUIDUpdateNewlyInsertedDivision.add_update("tbl_a_divisions", newlyInsertedDivisionID, "firewalled_division_ids_comma", newlyInsertedDivisionIDString, "s");
        }

        C_CallPhpTblUIDUpdateNewlyInsertedDivision.execute();

        this.onclick_close_create_new_division();
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      C_CallPhpTblUID.execute();
    }
  }



  onclick_close_create_new_division = () => {
    if(this.props.f_onClose) {
      this.props.f_onClose();
    }
  }

  division_name_error_tf = () => { return(this.state.s_divisionName === ""); }
  parent_division_error_tf = () => { return(!JSFUNC.in_array(this.state.s_parentDivisionID, JSFUNC.get_column_vector_from_mapOfMaps(this.props.DatabaseMobx.o_tbl_a_divisions, "id"))); }
  sibling_order_error_tf = () => { return(this.state.s_siblingOrder <= 0); }

  render() {
    const selectedParentDivisionID = this.state.s_parentDivisionID;
    const selectedSiblingOrder = this.state.s_siblingOrder;

    const c_adminAllDivisionsArrayOfObjs = this.props.AdminMobx.c_adminAllDivisionsArrayOfObjs;
    const companyPrimeSBCertsCanVaryPerDivisionTF = this.props.DatabaseMobx.c_companyPrimeSBCertsCanVaryPerDivisionTF;

    const selectedDivisionBusinessTypeMap = this.props.DatabaseMobx.o_tbl_a_business_types.get(this.state.s_divisionBusinessTypeID);
    var selectedDivisionBusinessTypeIsSmallTF = false;
    if(selectedDivisionBusinessTypeMap !== undefined) {
      selectedDivisionBusinessTypeIsSmallTF = (selectedDivisionBusinessTypeMap.get("is_sb_type_01") === 1);
    }

    var divisionNameError = undefined;
    var parentDivisionError = undefined;
    var siblingOrderError = undefined;
    if(this.state.s_showErrorsTF) { //pushed the create button for the first time
      if(this.division_name_error_tf()) { divisionNameError = "New Division name cannot be blank"; }
      if(this.parent_division_error_tf()) { parentDivisionError = "A parent division (or the top position) must be selected for this new division"; }
      if(this.sibling_order_error_tf()) { siblingOrderError = "The new Division must have its sibling order selected"; }
    }

    //compute the number of sibling positions based on the selected parent
    const selectedParentDivisionMap = this.props.DatabaseMobx.o_tbl_a_divisions.get(selectedParentDivisionID);
    var order = 1;
    var childCount = 0;
    var siblingOrderArray = [];
    var siblingOrderDisplayArray = [];
    if(selectedParentDivisionMap !== undefined) {
      const parentTreeID = selectedParentDivisionMap.get("tree_id");
      for(let divisionObj of c_adminAllDivisionsArrayOfObjs) {
        if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(parentTreeID, divisionObj.tree_id, true)) {
          childCount++;

          if(childCount === 1) {
            siblingOrderArray.push(order);
            siblingOrderDisplayArray.push(order + " - before '" + divisionObj.name + "'");
            order++;
          }

          siblingOrderArray.push(order);
          siblingOrderDisplayArray.push(order + " - after '" + divisionObj.name + "'");
          order++;
        }
      }

      if(siblingOrderArray.length === 0) { //this selected parent division does not have any children yet
        siblingOrderArray.push(1);
        siblingOrderDisplayArray.push("1 - this will be the first subdivision under '" + selectedParentDivisionMap.get("name") + "'")
      }
    }

    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Sibling Order", siblingOrderArray, false, siblingOrderDisplayArray, swsOptionsObj);
    const selectSiblingOrderFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);


    var order = 1;
    var childCount = 0;
    var visualDivisionsArrayOfObjs = undefined;
    if(selectedParentDivisionMap !== undefined) {
      const parentTreeID = selectedParentDivisionMap.get("tree_id");

      var numNewDivisionIndents = Math.ceil(parentTreeID.length / 2); //parent # indents + 1
      var indentsArray = [];
      for(let i = 0; i < numNewDivisionIndents; i++) {
        indentsArray.push(i);
      }
      var newDivisionObj = {id:-1, name:this.state.s_divisionName, color:this.state.s_divisionColor, indentsArray:indentsArray, tree_id:parentTreeID + "99"};

      visualDivisionsArrayOfObjs = [];
      for(let divisionObj of c_adminAllDivisionsArrayOfObjs) {
        if(JSFUNC.tree_id_is_child_of_parent_tree_id_tf(parentTreeID, divisionObj.tree_id, true)) {
          childCount++;

          if(childCount === 1) {
            if(selectedSiblingOrder === order) {
              newDivisionObj.tree_id = parentTreeID + "00";
            }
            order++;
          }

          visualDivisionsArrayOfObjs.push(divisionObj);

          if(selectedSiblingOrder === order) {
            newDivisionObj.tree_id = divisionObj.tree_id + "9999";
          }

          order++;
        }
        else {
          visualDivisionsArrayOfObjs.push(divisionObj);
        }
      }

      visualDivisionsArrayOfObjs.push(newDivisionObj);
      JSFUNC.sort_arrayOfObjs(visualDivisionsArrayOfObjs, "tree_id", true);
    }

    if(visualDivisionsArrayOfObjs === undefined) {
      visualDivisionsArrayOfObjs = c_adminAllDivisionsArrayOfObjs;
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditItem"
        p_title="Creating a New Division"
        f_onClickCancel={this.onclick_close_create_new_division}>
        <div className="flex11a displayFlexColumn">
          <div className="flex11a yScroll medFullPad">
            <DivisionsAddEditFloatingBoxSectionLabel p_label="Attributes" p_dividerLineAboveTF={false} />
            <div className="displayFlexRowVc tbMargin">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                <font className="fontBlue">
                  {"Division Name"}
                </font>
              </div>
              <div className="flex11a" style={{flexBasis:"150em"}}>
                <LibraryReact.Text
                  p_value={this.state.s_divisionName}
                  p_styleObj={{width:"100%"}}
                  p_tabIndex={1}
                  p_errorTF={(divisionNameError !== undefined)}
                  f_onChange={this.onchange_division_name}
                />
                {(divisionNameError !== undefined) &&
                  <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={divisionNameError} />
                }
              </div>
            </div>
            <div className="displayFlexRowVc tbMargin">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                <font className="fontBlue">
                  {"Division Abbr Name (optional)"}
                </font>
              </div>
              <div className="flex11a" style={{flexBasis:"150em"}}>
                <LibraryReact.Text
                  p_value={this.state.s_divisionNickname}
                  p_styleObj={{width:"100%"}}
                  p_tabIndex={2}
                  p_errorTF={false}
                  f_onChange={this.onchange_division_nickname}
                />
              </div>
            </div>
            <div className="displayFlexRowVc tbMargin">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                <font className="fontBlue">
                  {"Color (represents division in graphs)"}
                </font>
              </div>
              <div className="flex11a" style={{flexBasis:"150em"}}>
                <LibraryReact.Color
                  p_value={this.state.s_divisionColor}
                  p_styleObj={{width:"100%"}}
                  p_tabIndex={3}
                  p_errorTF={false}
                  f_onChange={this.onchange_division_color}
                />
              </div>
            </div>
            <div className="displayFlexRowVc tbMargin">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                <font className="fontBlue">
                  {"Division Business Type"}
                </font>
              </div>
              <div className="flex11a" style={{flexBasis:"150em"}}>
                {(companyPrimeSBCertsCanVaryPerDivisionTF) ? (
                  <CEGeneralReact.GenericInputOrSelectFromInputType
                    p_fieldTypeObj={this.props.DatabaseMobx.c_selectBusinessTypeFieldTypeObj}
                    p_valueRaw={this.state.s_divisionBusinessTypeID}
                    p_tabIndex={4}
                    f_onChangeOrOnSelect={this.onchange_division_business_type}
                  />
                ) : (
                  <font className="fontItalic fontTextLighter">
                    {"Value is set at top level for all divisions"}
                  </font>
                )}
              </div>
            </div>
            <div className="displayFlexRowVc tbMargin">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                <font className="fontBlue">
                  {"Small Business Certifications"}
                </font>
              </div>
              <div className="flex11a" style={{flexBasis:"150em"}}>
                {(companyPrimeSBCertsCanVaryPerDivisionTF) ? (
                  (selectedDivisionBusinessTypeIsSmallTF) ? (
                    <CEGeneralReact.GenericInputOrSelectFromInputType
                      p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiBitMasterSetAsidesFieldTypeObj}
                      p_valueRaw={this.state.s_divisionPrimeSBCertificationsBmSetAsideIDsComma}
                      p_tabIndex={5}
                      f_onChangeOrOnSelect={this.onchange_division_sb_certifications}
                    />
                  ) : (
                    <font className="fontItalic fontTextLighter">
                      {"N/A"}
                    </font>
                  )
                ) : (
                  <font className="fontItalic fontTextLighter">
                    {"Value is set at top level for all divisions"}
                  </font>
                )}
              </div>
            </div>
            <DivisionsAddEditFloatingBoxSectionLabel p_label="Org Chart Positioning" p_dividerLineAboveTF={true} />
            <div className="displayFlexRowVc tbMargin">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                <font className="fontBlue">
                  {"Parent Division"}
                </font>
              </div>
              <div className="flex11a" style={{flexBasis:"150em"}}>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectDivisionFieldTypeObj}
                  p_valueRaw={selectedParentDivisionID}
                  p_tabIndex={7}
                  p_errorTF={(parentDivisionError !== undefined)}
                  f_onChangeOrOnSelect={this.onchange_parent_division_id}
                />
                {(parentDivisionError !== undefined) &&
                  <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={parentDivisionError} />
                }
              </div>
            </div>
            <div className="displayFlexRowVc tbMargin">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                <font className="fontBlue">
                  {"Order Among Sibling Divisions"}
                </font>
              </div>
              <div className="flex11a" style={{flexBasis:"150em"}}>
                {(this.parent_division_error_tf()) ? (
                  <div className="flex11a" style={{flexBasis:"100em", height:"1.7em"}}>
                    <font className="fontItalic fontTextLighter">
                      {"--Select a Parent Division first--"}
                    </font>
                  </div>
                ) : (
                  <CEGeneralReact.GenericInputOrSelectFromInputType
                    p_fieldTypeObj={selectSiblingOrderFieldTypeObj}
                    p_valueRaw={selectedSiblingOrder}
                    p_tabIndex={8}
                    p_errorTF={(siblingOrderError !== undefined)}
                    f_onChangeOrOnSelect={this.onchange_sibling_order}
                  />
                )}
                {(siblingOrderError !== undefined) &&
                  <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={siblingOrderError} />
                }
              </div>
            </div>
            <div className="hugeTopMargin hugeBottomMargin">
              {visualDivisionsArrayOfObjs.map((m_divisionObj) =>
                <div key={m_divisionObj.id} className="displayFlexRow lrMargin">
                  {m_divisionObj.indentsArray.map((i_indentNum) =>
                    <div className="flex00a" style={{flexBasis:"0.9em", borderLeft:"solid 1px #ddd", marginLeft:"0.6em"}} />
                  )}
                  <div
                    className="flex11a displayFlexRow microTopMargin microBottomMargin lrPad"
                    style={{height:"1.4em", background:((m_divisionObj.id > 0) ? ("#f5f2f0") : ("#cec")), border:"solid 1px " + ((m_divisionObj.id > 0) ? ("#cba") : ("#3b3")), borderRadius:"0.2em"}}>
                    <div className="flex00a microTopMargin microBottomMargin border bevelBorderColors" style={{flexBasis:"0.8em", background:"#" + m_divisionObj.color}} />
                    <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
                      <LibraryReact.Nowrap p_fontClass="">
                        {m_divisionObj.name}
                      </LibraryReact.Nowrap>
                    </div>
                  </div>
                </div>
              )}
          </div>
          </div>
        </div>
        <div className="flex00a displayFlexColumnHcVc tbMedPad borderT1ddd">
          {(this.state.s_isWorkingTF) ? (
            <CEGeneralReact.PhpWorkingMessage p_text="Creating Division..." />
          ) : (
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Create New Division"
              p_tabIndex={9}
              f_onClick={this.onclick_create_new_division}
            />
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));




function UnassignedMatchingCapturesBoxAndCountButton(props) { //props: p_unassignedMatchingCapturesObj, p_fieldDisplayName
  const unassignedMatchingCapturesObj = props.p_unassignedMatchingCapturesObj;
  const fieldDisplayName = props.p_fieldDisplayName;

  if(unassignedMatchingCapturesObj.numMatchingCaptures === 0) {
    return(null);
  }

  return(
    <div className="displayFlexRow bgLighterGray lrMedPad" style={{width:"22em", height:"4em", border:"solid 1px #aaa"}}>
      <div className="flex11a displayFlexRowVc">
        <font className="font11 fontTextLight">
          {"Captures with unassigned " + fieldDisplayName}
        </font>
      </div>
      <div className="flex00a displayFlexColumnHcVc">
        <div className="">
          <font className="fontItalic fontTextLight">
            {"# Captures"}
          </font>
        </div>
        <CapturesReact.NumberOfAssignedCapturesCountButton
          p_partialNumMatchingCaptures={unassignedMatchingCapturesObj.partialNumMatchingCaptures}
          p_matchingCaptureIDsArray={unassignedMatchingCapturesObj.matchingCaptureIDsArray}
          p_floatingBoxTitle={"All Captures with unassigned " + fieldDisplayName}
        />
      </div>
    </div>
  );
}





//=================================================================================================================================================
//=================================================================================================================================================
//=================================================================================================================================================




export const AdminSubtabUsers = inject("CaptureExecMobx", "AdminMobx", "CapturesMobx", "DatabaseMobx")(observer(
class AdminSubtabUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_editUserSettingsFloatingBoxIsOpenTF: false,
      s_creatingNewUserTF: false
    };
  }

  onclick_open_create_new_user_floating_box = () => {
    this.setState({s_creatingNewUserTF:true});
  }

  onclick_close_create_new_user_floating_box = () => {
    this.setState({s_creatingNewUserTF:false});
  }

  onclick_users_view_flag_tab = (i_newValue) => {
    this.props.AdminMobx.a_set_users_view_flag(i_newValue);
  }

  onclick_open_edit_user_settings_floating_box = () => {
    this.setState({s_editUserSettingsFloatingBoxIsOpenTF:true});
  }

  onclick_close_edit_user_settings_floating_box = () => {
    this.setState({s_editUserSettingsFloatingBoxIsOpenTF:false});
  }

  onsave_new_user_default_password = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("new_user_default_password", i_newValue, "s");
  }

  onsave_days_between_user_password_change = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("days_between_user_password_change", i_newValue, "i");
  }

  onsave_capture_consultants_can_view_parent_idiqs = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_consultants_can_view_parent_idiqs_01", i_newValue, "i");
  }

  render() {
    const s_editUserSettingsFloatingBoxIsOpenTF = this.state.s_editUserSettingsFloatingBoxIsOpenTF;
    const s_creatingNewUserTF = this.state.s_creatingNewUserTF;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_usersViewFlag = this.props.AdminMobx.o_usersViewFlag;
    const c_usersNumLicensesUsed = this.props.AdminMobx.c_usersNumLicensesUsed;
    const c_usersTotalNumNotDeactivatedSuperAdminsAdded = this.props.AdminMobx.c_usersTotalNumNotDeactivatedSuperAdminsAdded;
    const c_usersUsingMaxNumSuperAdminsTF = this.props.AdminMobx.c_usersUsingMaxNumSuperAdminsTF;
    const c_usersNumNotDeactivatedCaptureConsultantsUsed = this.props.AdminMobx.c_usersNumNotDeactivatedCaptureConsultantsUsed;
    const c_usersNumLicensesRemaining = this.props.AdminMobx.c_usersNumLicensesRemaining;
    const c_usersCanAddNewUserTF = this.props.AdminMobx.c_usersCanAddNewUserTF;
    const c_captureTableCurrentlyProcessingCapturesTF = this.props.CapturesMobx.c_captureTableCurrentlyProcessingCapturesTF;
    const c_bitNumLicensesPurchased = this.props.DatabaseMobx.c_bitNumLicensesPurchased;
    const c_bitEnterpriseLicenseTF = this.props.DatabaseMobx.c_bitEnterpriseLicenseTF;
    const c_bitConsultantsLicenseCost = this.props.DatabaseMobx.c_bitConsultantsLicenseCost;
    const c_bitLicenseNumMonths = this.props.DatabaseMobx.c_bitLicenseNumMonths;
    const c_bitLicenseStartDate = this.props.DatabaseMobx.c_bitLicenseStartDate;
    const c_bitSuperAdminsMaxNum = this.props.DatabaseMobx.c_bitSuperAdminsMaxNum;

    var licenseExpirationDateMask = undefined;
    if(c_bitLicenseStartDate !== undefined && c_bitLicenseNumMonths !== undefined) {
      const licenseExpirationDate = JSFUNC.compute_end_date_from_start_date_and_num_months(c_bitLicenseStartDate, c_bitLicenseNumMonths);
      licenseExpirationDateMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(licenseExpirationDate, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
    }
    else {
      licenseExpirationDateMask = "--";
    }

    const totalLicensesPurchasedMask = ((c_bitEnterpriseLicenseTF) ? ("Enterprise License") : (c_bitNumLicensesPurchased + " User License"));

    var usersListComponent = null;
    if(c_captureTableCurrentlyProcessingCapturesTF) {
      usersListComponent = (<CEGeneralReact.EmptyScreenGrayWithLoadingAnimation>{"Processing Captures"}</CEGeneralReact.EmptyScreenGrayWithLoadingAnimation>);
    }
    else if(o_usersViewFlag === "DivisionTreeUsersSingleEdit") {
      usersListComponent = (<UsersNotDeactivatedUsersPerDivisionTree />);
    }
    else {
      usersListComponent = (<AdminUsersPerEmailList />);
    }

    return (
      <div className="flex11a yScroll yScrollBottomPad">
        <div className="displayFlexRow flexWrap">
          {(c_productStylingObj.setupUsersShowLicenseExpirationTF) &&
            <div className="flex00a displayFlexColumnHcVc lrMedPad">
              <div className="">
                <font className="font12 fontItalic fontTextLighter">
                  {"License Expiration:"}
                </font>
              </div>
              <div className="">
                <font className="font12 fontBold fontTextLight">
                  {licenseExpirationDateMask}
                </font>
              </div>
              <div className="">
                <font className="fontItalic fontTextLighter">
                  {totalLicensesPurchasedMask}
                </font>
              </div>
            </div>
          }
          <div className="flex00a displayFlexColumnHcVc lrMedPad">
            {(!c_bitEnterpriseLicenseTF) &&
              <>
                <div className="">
                  <font className="fontItalic fontTextLighter">
                    {c_usersNumLicensesUsed + " / " + c_bitNumLicensesPurchased + " Licenses Used"}
                  </font>
                </div>
                {(c_usersUsingMaxNumSuperAdminsTF) &&
                  <div className="">
                    <font className="fontItalic fontTextLighter">
                      {c_usersTotalNumNotDeactivatedSuperAdminsAdded + " / " + c_bitSuperAdminsMaxNum + " Super Admins Assigned"}
                    </font>
                  </div>
                }
                <div className="">
                  <font className="font12 fontBold fontTextLight">
                    {c_usersNumLicensesRemaining}
                  </font>
                  <font className="font12 fontTextLight">
                    {" " + JSFUNC.plural(c_usersNumLicensesRemaining, "License", "Licenses") + " Remaining"}
                  </font>
                </div>
                {((c_bitConsultantsLicenseCost === 0) && (c_usersNumNotDeactivatedCaptureConsultantsUsed > 0)) &&
                  <div className="">
                    <font className="font09 fontItalic fontTextLighter">
                      {c_usersNumNotDeactivatedCaptureConsultantsUsed + " Capture " + JSFUNC.plural(c_usersNumNotDeactivatedCaptureConsultantsUsed, "Consultant", "Consultants")}
                    </font>
                  </div>
                }
              </>
            }
          </div>
          <div className="flex11a displayFlexRowVc lrMedPad">
            {(c_usersCanAddNewUserTF) ? (
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text="Add New User"
                f_onClick={this.onclick_open_create_new_user_floating_box}
              />
            ) : (
              <div className="border1 bevelBorderColorBlue borderRadius05 microFullPad textCenter" style={{maxWidth:"17em"}}>
                <font className="fontBold">
                  {"For additional licenses, contact support@bitsolutionsllc.com"}
                </font>
              </div>
            )}
          </div>
          <div className="flex00a displayFlexColumnHcVc smallFullPad">
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={["lastNameAlphabeticList", "DivisionTreeUsersSingleEdit"]}
              p_tabDisplayNamesArray={["View as List\n(Alphabetic by\nLast Name)", "View as\nDivision Tree"]}
              p_selectedTabDbName={o_usersViewFlag}
              p_tabHeight="4em"
              p_textMaxHeight="3.8em"
              p_tabWidth="8em"
              p_unselectedFontClass="fontTextLighter"
              p_unselectedBgClass="bgLightesterGrayGradient hoverLighterBlueGradient fontTextLight"
              p_selectedBgClass="bgLightGoldGradient"
              p_selectedFontClass="fontTextLight"
              f_onSelect={this.onclick_users_view_flag_tab}
            />
          </div>
          <UsersUnassignedMatchingCapturesBox />
          <div className="flex00a displayFlexColumnHcVc borderL1ddd lrMedPad">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Edit General User Settings"
              f_onClick={this.onclick_open_edit_user_settings_floating_box}
            />
          </div>
        </div>
        {usersListComponent}
        {(s_editUserSettingsFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="smallVertical"
            p_title="Edit General User Settings"
            f_onClickSave={this.onclick_close_edit_user_settings_floating_box}>
            <div className="flex11a displayFlexColumn medFullPad yScroll">
              <div className="smallBottomMargin">
                <font className="font12 fontBold fontDarkBlue">
                  {"Passwords"}
                </font>
              </div>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="adminUsersUserDefaultPassword"
                p_fieldDisplayName="New User Default Password"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={this.props.DatabaseMobx.c_companyNewUserDefaultPassword}
                p_valueIsEditableTFU={true}
                p_containerClass="microBottomMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="18em"
                f_onSaveChanged={this.onsave_new_user_default_password}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="adminUsersDaysBetweenUserPasswordChange"
                p_fieldDisplayName="Days Between User Password Change"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
                p_valueRaw={this.props.DatabaseMobx.c_companyDaysBetweenUserPasswordChange}
                p_valueIsEditableTFU={true}
                p_containerClass="microBottomMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="18em"
                f_onSaveChanged={this.onsave_days_between_user_password_change}
              />
              <div className="hugeTopMargin smallBottomMargin">
                <font className="font12 fontBold fontDarkBlue">
                  {"Capture Consultants"}
                </font>
              </div>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="adminUsers"
                p_fieldDisplayName="Capture Consultant IDIQ Visibility"
                p_fieldTypeObj={this.props.AdminMobx.c_usersSettingsSelectCaptureConsultantsCanViewParentIDIQsFieldTypeObj}
                p_valueRaw={this.props.DatabaseMobx.c_companyCaptureConsultantsCanViewParentIDIQs01}
                p_valueIsEditableTFU={true}
                p_containerClass="microBottomMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="18em"
                f_onSaveChanged={this.onsave_capture_consultants_can_view_parent_idiqs}
              />
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
        {(s_creatingNewUserTF) &&
          <UsersCreateNewUserPower
            p_includeUpeTF={true}
            p_userPerEmailObj={undefined}
            f_onClose={this.onclick_close_create_new_user_floating_box}
          />
        }
      </div>
    );
  }
}));



const UsersUnassignedMatchingCapturesBox = inject("AdminMobx", "DatabaseMobx")(observer(
class UsersUnassignedMatchingCapturesBox extends Component {
  render() {
    const c_usersUnassignedMatchingCapturesObj = this.props.AdminMobx.c_usersUnassignedMatchingCapturesObj;
    const c_fieldMapOfCaptureManagers = this.props.DatabaseMobx.c_fieldMapOfCaptureManagers;

    if(!JSFUNC.is_number_not_nan_gte_0(c_usersUnassignedMatchingCapturesObj.numMatchingCaptures)) {
      return(null);
    }

    return (
      <div className="flex00a displayFlexColumnVc borderL1ddd lrPad">
        <UnassignedMatchingCapturesBoxAndCountButton
          p_unassignedMatchingCapturesObj={c_usersUnassignedMatchingCapturesObj}
          p_fieldDisplayName={c_fieldMapOfCaptureManagers.get("display_name")}
        />
      </div>
    );
  }
}));


const UsersDeactivatedUsersSectionTitle = inject("CaptureExecMobx")(observer(
class UsersDeactivatedUsersSectionTitle extends Component {
  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    return (
      <>
        <div className="lrMedPad microBottomMargin">
          <font className="font15 fontItalic fontTextLighter">
            {"Deactivated Users"}
          </font>
        </div>
        <div className="lrMedPad bigBottomMargin">
          <font className="fontItalic fontTextLighter">
            {"These users can no longer log into " + c_productStylingObj.productName + " and do not take up any licenses, however, their names and past activity are kept intact throughout the system."}
          </font>
        </div>
      </>
    );
  }
}));


const UsersNotDeactivatedUsersPerDivisionTree = inject("AdminMobx", "DatabaseMobx")(observer(
class UsersNotDeactivatedUsersPerDivisionTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_editUserSettingsFloatingBoxIsOpenTF: false
    };
  }

  onclick_division_view_expand_user_row = (i_userID) => {
    this.setState({s_divisionViewUserIDExpandedOrUndefined:i_userID});
  }

  render() {
    const s_divisionViewUserIDExpandedOrUndefined = this.state.s_divisionViewUserIDExpandedOrUndefined;

    const c_usersPerEmailDeactivatedArrayOfObjs = this.props.AdminMobx.c_usersPerEmailDeactivatedArrayOfObjs;
    const c_userNotDeactivatedUsersPerDivisionTreeArrayOfObjs = this.props.AdminMobx.c_userNotDeactivatedUsersPerDivisionTreeArrayOfObjs;

    return (
      <>
        {c_userNotDeactivatedUsersPerDivisionTreeArrayOfObjs.map((m_expandedDivisionObj) =>
          <div key={m_expandedDivisionObj.id} className="displayFlexRow lrMedMargin">
            {m_expandedDivisionObj.indentsArray.map((i_indentNum) =>
              <div key={"ind" + i_indentNum} className="flex00a borderL1ddd" style={{flexBasis:"1.6em", marginLeft:"0.8em"}} />
            )}
            <div className="flex11a tbMicroMargin lrPad" style={{backgroundColor:"#f5f2f0", border:"solid 1px #cba", borderRadius:"0.5em"}}>
              <div className="displayFlexRow" style={{height:"2.5em"}}>
                <div className="flex00a smallFullMargin border bevelBorderColors" style={{flexBasis:"1em", background:"#" + m_expandedDivisionObj.color}} />
                <div className="flex11a displayFlexColumnVc lrPad" style={{flexBasis:"100em"}}>
                  <LibraryReact.Nowrap p_fontClass="font11">
                    <font className="fontBold fontTextLight">{m_expandedDivisionObj.name}</font>
                    <font className="fontTextLight">{" (Licenses: " + m_expandedDivisionObj.userLicensesUsed + " in division, " + m_expandedDivisionObj.allSubdivisionUserLicensesUsed + " including all subdivisions)"}</font>
                  </LibraryReact.Nowrap>
                </div>
              </div>
              {m_expandedDivisionObj.superExpandedUsersArrayOfObjs.map((m_superExpandedUserObj) =>
                <AdminDivisionViewSimpleUserRowWithClick
                  key={m_superExpandedUserObj.id}
                  p_isExpandedShowingFullUserPerEmailTF={(s_divisionViewUserIDExpandedOrUndefined === m_superExpandedUserObj.user_id)}
                  p_userPerEmailObj={m_superExpandedUserObj.userPerEmailObj}
                  p_expandedUserObj={m_superExpandedUserObj}
                  f_onClick={this.onclick_division_view_expand_user_row}
                />
              )}
            </div>
          </div>
        )}
        {(c_usersPerEmailDeactivatedArrayOfObjs.length > 0) &&
          <div className="enormousTopMargin bigTopPad borderT1bbb">
            <UsersDeactivatedUsersSectionTitle />
            {c_usersPerEmailDeactivatedArrayOfObjs.map((m_userPerEmailObj) =>
              <div key={m_userPerEmailObj.id} className="displayFlexRowVc tbMicroMargin lrMedMargin border1bbb bgLightestGray smallFullPad">
                <font className="fontItalic fontTextLight">
                  {m_userPerEmailObj.firstNameLastName}
                </font>
              </div>
            )}
          </div>
        }
      </>
    );
  }
}));


class AdminDivisionViewSimpleUserRowWithClick extends Component { //props: p_isExpandedShowingFullUserPerEmailTF, p_userPerEmailObj, p_expandedUserObj, f_onClick(i_userID)
  onclick_collapsed_user_row = () => {
    const p_isExpandedShowingFullUserPerEmailTF = this.props.p_isExpandedShowingFullUserPerEmailTF;
    const p_expandedUserObj = this.props.p_expandedUserObj;

    if(JSFUNC.is_function(this.props.f_onClick)) {
      var updatedUserIDOrUndefined = undefined;
      if(!p_isExpandedShowingFullUserPerEmailTF) {
        updatedUserIDOrUndefined = p_expandedUserObj.user_id;
      }
      this.props.f_onClick(updatedUserIDOrUndefined);
    }
  }

  render() {
    const p_isExpandedShowingFullUserPerEmailTF = this.props.p_isExpandedShowingFullUserPerEmailTF;
    const p_userPerEmailObj = this.props.p_userPerEmailObj;
    const p_expandedUserObj = this.props.p_expandedUserObj;

    return(
      <div className="tbMicroMargin lrMedMargin border bevelBorderColorBlue bgLightestBlue">
        <div
          className="displayFlexRowVc hoverLightBlueGradient smallFullPad cursorPointer"
          title={"Click to open/close edit for User '" + p_userPerEmailObj.firstNameLastName + "'"}
          onClick={this.onclick_collapsed_user_row}>
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <font className="fontBlue">
              {p_userPerEmailObj.firstNameLastName}
            </font>
            <font className="fontItalic fontTextLight">
              {" (" + p_expandedUserObj.powerDisplayName + ")"}
            </font>
            {(p_expandedUserObj.bitNonBillableUserTF) &&
              <font className="fontItalic fontTextLight">
                {" [BIT Non-Billable User]"}
              </font>
            }
          </div>
          <div className="flex11a" style={{flexBasis:"200em"}}>
            <font className="fontTextLight">
              {"License: " + p_expandedUserObj.powerLicenseCost}
            </font>
          </div>
        </div>
        {(p_isExpandedShowingFullUserPerEmailTF) &&
          <AdminUserPerEmail p_userPerEmailObj={p_userPerEmailObj} />
        }
      </div>
    );
  }
}


const AdminUsersPerEmailList = inject("AdminMobx")(observer(
class AdminUsersPerEmailList extends Component {
  render() {
    const c_usersPerEmailNotDeactivatedArrayOfObjs = this.props.AdminMobx.c_usersPerEmailNotDeactivatedArrayOfObjs;
    const c_usersPerEmailDeactivatedArrayOfObjs = this.props.AdminMobx.c_usersPerEmailDeactivatedArrayOfObjs;

    return (
      <>
        {c_usersPerEmailNotDeactivatedArrayOfObjs.map((m_userPerEmailObj) =>
          <AdminUserPerEmail
            key={m_userPerEmailObj.id}
            p_userPerEmailObj={m_userPerEmailObj}
          />
        )}
        {(c_usersPerEmailDeactivatedArrayOfObjs.length > 0) &&
          <div className="enormousTopMargin bigTopPad borderT1bbb">
            <UsersDeactivatedUsersSectionTitle />
            {c_usersPerEmailDeactivatedArrayOfObjs.map((m_userPerEmailObj) =>
              <AdminUserPerEmail
                key={m_userPerEmailObj.id}
                p_userPerEmailObj={m_userPerEmailObj}
              />
            )}
          </div>
        }
      </>
    );
  }
}));


const AdminUserPerEmail = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx", "UserMobx")(observer(
class AdminUserPerEmail extends Component { //props: p_userPerEmailObj
  constructor(props) {
    super(props);
    this.state = {
      s_editingUserPerEmailTF: false,
      s_creatingNewUserPowerTF: false,
      s_welcomeEmailSentSuccessMessageTF: false,
      s_reassignUserCustomCreationsFloatingBoxIsOpenTF: false
    };
  }

  onclick_upe_name_email_edit = () => {
    this.setState({s_editingUserPerEmailTF:true});
  }

  onclick_close_upe_name_email_edit = () => {
    this.setState({s_editingUserPerEmailTF:false});
  }

  onsave_upe_first_name = (i_newValue) => {
    this.update_upe_field("first_name", i_newValue, "s");
  }

  onsave_upe_last_name = (i_newValue) => {
    this.update_upe_field("last_name", i_newValue, "s");
  }

  onsave_upe_email = (i_newValue) => {
    this.update_upe_field("email", i_newValue, "s");
  }

  onclick_lockedout_switch = () => {
    const newValue01 = ((this.props.p_userPerEmailObj.lockedout === 1) ? (0) : (1));
    this.update_upe_field("lockedout", newValue01, "i");
  }

  onclick_deactivated_switch = () => {
    const p_userPerEmailObj = this.props.p_userPerEmailObj;
    
    const newValue01 = ((p_userPerEmailObj.disabled === 1) ? (0) : (1));

    //if switching from deactivated to activated, but this user has a reason blocking reactivation, display the reason and do not perform the update
    if((newValue01 === 0) && (p_userPerEmailObj.userCannotBeReactivatedReasonTextOrUndefined !== undefined)) {
      alert(p_userPerEmailObj.userCannotBeReactivatedReasonTextOrUndefined);
    }
    else { //otherwise update the deactivated field toggle
      //set up admin changelog record for deactivation/reactivation
      var adminActionCode = 0;
      if(newValue01 === 0) { //new value is 'not deactivated', so reactivation
        adminActionCode = 1103;
      }
      else { //deactivation
        adminActionCode = 1102;
      }

      const upePowerDisplayNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(p_userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs, "powerDisplayName");
      const upePowerDisplayNamesDisplayComma = JSFUNC.convert_array_to_display_comma_list(upePowerDisplayNamesArray);
      const adminChangelogValue = p_userPerEmailObj.firstNameLastName + " (" + upePowerDisplayNamesDisplayComma + ")";

      this.update_upe_field("disabled", newValue01, "i", adminActionCode, adminChangelogValue);
    }
  }

  onclick_create_public_switch = () => {
    const p_userPerEmailObj = this.props.p_userPerEmailObj;
    const newValue01 = ((p_userPerEmailObj.can_create_public_01 === 1) ? (0) : (1));
    this.update_upe_field("can_create_public_01", newValue01, "i");
  }

  update_upe_field = (i_fieldName, i_value, i_idsb, i_adminActionCode=-1, i_adminChangelogValue="") => {
    const p_userPerEmailObj = this.props.p_userPerEmailObj;
    
    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminUserPerEmail", "update_upe_field", ["i_fieldName", "i_value", "i_idsb"], [i_fieldName, i_value, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    C_CallPhpTblUID.add_update("tbl_a_users_per_email", p_userPerEmailObj.id, i_fieldName, i_value, i_idsb);

    //insert an admin changelog entry if an action/value is provided
    if(i_adminActionCode > 0) {
      const functionOnSuccess = (i_parseResponse) => {
        const C_CallPhpTblUIDAfterUpdateUserPerEmailField = new JSPHP.ClassCallPhpTblUID(jsDescription);
        C_CallPhpTblUIDAfterUpdateUserPerEmailField.add_changelog_admin(i_adminActionCode, i_adminChangelogValue);
        C_CallPhpTblUIDAfterUpdateUserPerEmailField.execute();
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    C_CallPhpTblUID.execute();
  }

  onclick_add_user_power = () => {
    this.setState({s_creatingNewUserPowerTF:true});
  }

  onclick_close_new_user_power = () => {
    this.setState({s_creatingNewUserPowerTF:false});
  }

  onconfirm_resend_user_per_email_welcome_email = () => {
    this.update_upe_field("password", this.props.DatabaseMobx.c_companyNewUserDefaultPassword, "s");
    this.onclick_send_user_welcome_email();
  }

  ondelete_user_per_email_and_all_powers = () => {
    const p_userPerEmailObj = this.props.p_userPerEmailObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminUserPerEmail", "ondelete_user_per_email_and_all_powers", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //delete all user powers associated with this userPerEmail
    const userIDsToDeleteArray = JSFUNC.get_column_vector_from_arrayOfObjs(p_userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs, "user_id");
    C_CallPhpTblUID.add_delete("tbl_a_users", userIDsToDeleteArray); //users are sorted by power sort and division_tree id, no resort needed when deleting

    //delete the userPerEmail record
    C_CallPhpTblUID.add_delete("tbl_a_users_per_email", this.props.p_userPerEmailObj.id); //users are sorted by power sort and division_tree id, no resort needed when deleting

    const functionOnSuccess = (i_parseResponse) => {
      //insert an admin changelog entry
      const C_CallPhpTblUIDAfterDeleteUserPerEmailWithUsers = new JSPHP.ClassCallPhpTblUID(jsDescription);
      const deletedUserPerEmailPowerDisplayNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(p_userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs, "powerDisplayName");
      const deletedUserPerEmailPowerDisplayNamesDisplayComma = JSFUNC.convert_array_to_display_comma_list(deletedUserPerEmailPowerDisplayNamesArray);
      const adminChangelogValue = p_userPerEmailObj.firstNameLastName + " (" + deletedUserPerEmailPowerDisplayNamesDisplayComma + ")";
      C_CallPhpTblUIDAfterDeleteUserPerEmailWithUsers.add_changelog_admin(1101, adminChangelogValue);
      C_CallPhpTblUIDAfterDeleteUserPerEmailWithUsers.execute();
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  onclick_send_user_welcome_email = () => {
    const p_userPerEmailObj = this.props.p_userPerEmailObj;

    const k_onPremiseCustomerTF = this.props.CaptureExecMobx.k_onPremiseCustomerTF;
    const c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined = this.props.CaptureExecMobx.c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_companyNewUserDefaultPassword = this.props.DatabaseMobx.c_companyNewUserDefaultPassword;
    const o_userCompanyCode = this.props.UserMobx.o_userCompanyCode;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;
    const c_userName = this.props.UserMobx.c_userName;

    if(p_userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs.length > 0) {
      const upeCombinedUserObj = p_userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs[0]; //use the first of any mulitlogins for the user power since they are ordered with admin first, divexec next, which are the most important, most likely brand new users will only have 1 power to send the email to

      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminUserPerEmail", "onclick_send_user_welcome_email", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      
      //captureexec.com (on-premise has a different URL so tell them to go there)
      const captureExecSiteLinkDisplay = ((k_onPremiseCustomerTF) ? (o_userCompanyCode + " Internal " + c_productStylingObj.productName + " Site") : (c_productStylingObj.productShortWebAddress));

      const welcomeEmailSubject = "Welcome to " + c_productStylingObj.productName + " - Leading the Innovation of Integrated Pipeline Management";

      var welcomeEmailBody = "Hello " + p_userPerEmailObj.first_name + "!<br /><br />";
      welcomeEmailBody += "The Admin of your " + c_productStylingObj.productName + " system has added you as " + ((upeCombinedUserObj.powerDisplayNameRequiresAnTrueAFalse) ? ("an") : ("a")) + " " + upeCombinedUserObj.powerDisplayName + ".";
      welcomeEmailBody += " We're really excited to begin this journey together with you and look forward to many wins in your future!";
      welcomeEmailBody += "<br /><br />Below is the link to " + c_productStylingObj.productName + ", along with your login credentials and a one-time password to get started:<br /><br /><br />";
      if(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined !== undefined) {
        welcomeEmailBody += '<a href="' + c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined + '">' + captureExecSiteLinkDisplay + '</a><br />';
      }
      else {
        welcomeEmailBody += captureExecSiteLinkDisplay + '<br />';
      }
      welcomeEmailBody += "Company Code: " + o_userCompanyCode + "<br />";
      welcomeEmailBody += "Email: " + p_userPerEmailObj.email + "<br />";
      welcomeEmailBody += "One-Time Password: " + c_companyNewUserDefaultPassword + "<br /><br /><br />";
      welcomeEmailBody += "We at BIT Solutions are here to provide your organization the most comprehensive, intuitive, and seamless experience that proactively guides each deal from inception to win.  ";
      welcomeEmailBody += "Our mission is simple, to never be satisfied as a simple data entry CRM, but to be so much more...to continuously automate, evolve, and collaborate with you to provide the most powerful, user-friendly software on the market.<br /><br />";
      welcomeEmailBody += " - BIT Solutions<br /><br /><br />";
      welcomeEmailBody += "Your " + c_productStylingObj.productName + " Admin:<br />";
      welcomeEmailBody += c_userName + " (" + c_combinedUserObj.email + ")";

      const fromEmail = "";
      const fromFullName = "";
      const notificationClickActionString = "";
      
      C_CallPhpTblUID.add_email(p_userPerEmailObj.email, "", welcomeEmailSubject, welcomeEmailBody, fromEmail, fromFullName, notificationClickActionString);

      C_CallPhpTblUID.add_update("tbl_a_users_per_email", p_userPerEmailObj.id, "welcome_email_sent_01", 1, "i");

      const functionOnFinish = () => {
        this.setState({s_welcomeEmailSentSuccessMessageTF:true});
      }
      C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

      C_CallPhpTblUID.execute();
    }
  }

  onclick_dismiss_send_user_welcome_email = () => {
    const p_userPerEmailObj = this.props.p_userPerEmailObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminUserPerEmail", "onclick_dismiss_send_user_welcome_email", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_users_per_email", p_userPerEmailObj.id, "welcome_email_sent_01", 1, "i");
    C_CallPhpTblUID.execute();
  }

  onclick_open_reassign_user_custom_creations_floating_box = () => {
    this.setState({s_reassignUserCustomCreationsFloatingBoxIsOpenTF:true});
  }

  onclick_close_reassign_user_custom_creations_floating_box = () => {
    this.setState({s_reassignUserCustomCreationsFloatingBoxIsOpenTF:false});
  }

  render() {
    const s_editingUserPerEmailTF = this.state.s_editingUserPerEmailTF;
    const s_creatingNewUserPowerTF = this.state.s_creatingNewUserPowerTF;
    const s_welcomeEmailSentSuccessMessageTF = this.state.s_welcomeEmailSentSuccessMessageTF;
    const s_reassignUserCustomCreationsFloatingBoxIsOpenTF = this.state.s_reassignUserCustomCreationsFloatingBoxIsOpenTF;

    const p_userPerEmailObj = this.props.p_userPerEmailObj;

    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_usersCanAddNewUserTF = this.props.AdminMobx.c_usersCanAddNewUserTF;
    const o_userPerEmailID = this.props.UserMobx.o_userPerEmailID;

    const userLockedOutTF = (p_userPerEmailObj.lockedout === 1);
    const userWelcomeEmailNotYetSentTF = (p_userPerEmailObj.welcome_email_sent_01 === 0);
    const upeNameAndSwitchesWideTrueStackedFalse = (o_mediaQueryFlag >= 5);

    var userPerEmailVerticalDotsMenuItemsArrayOfObjs = [];
    userPerEmailVerticalDotsMenuItemsArrayOfObjs.push({
      displayName: "Resend Welcome Email",
      confirmType: "confirm",
      confirmTitle: "Resend Welcome Email",
      confirmButton1Name: "Resend Welcome Email",
      confirmButton1Type: "green",
      confirmMessage: "This action will reset the password for '" + p_userPerEmailObj.firstNameLastName + "' to the company default and resend them the " + c_productStylingObj.productName + " Welcome Email with instructions and a link to log in.",
      functionOnClickConfirmButton: this.onconfirm_resend_user_per_email_welcome_email
    });
    if(c_productStylingObj.setupUsersReassignUserCustomCreationsTF) {
      userPerEmailVerticalDotsMenuItemsArrayOfObjs.push({
        displayName: "Reassign User Custom Creations",
        functionOnClickMenuItem: this.onclick_open_reassign_user_custom_creations_floating_box
      });
    }
    if(p_userPerEmailObj.id !== o_userPerEmailID) { //can't delete yourself
      var deleteUserPerEmailMessage = "Are you sure you want to delete User '" + p_userPerEmailObj.firstNameLastName + "'?";
      deleteUserPerEmailMessage += "\n\nThis will permanently erase any references of ownership and changelogs connected with their name. It is recommended to preserve history by instead Deactivating this User with the 'Deactivate' switch in this User row. This will free up the licenses while retaining their name referenced across the system.";
      userPerEmailVerticalDotsMenuItemsArrayOfObjs.push({
        displayName: "Delete User",
        confirmType: "confirmDelete",
        confirmTitle: "Delete User",
        confirmButton1Name: "Delete User",
        confirmMessage: deleteUserPerEmailMessage,
        functionOnClickConfirmButton: this.ondelete_user_per_email_and_all_powers
      });
    }

    const upeNameAndEmailComponent = (
      <div
        className="flex00a displayFlexRow smallFullPad hoverLighterBlueGradient cursorPointer"
        style={{backgroundColor:((p_userPerEmailObj.upeDeactivatedTF) ? ("#e4e4e4") : ("#f5ebd0")), border:"solid 1px #cba", borderRadius:"0.5em"}}
        title={"Click to edit the Name, or Email used to log in, for '" + p_userPerEmailObj.firstNameLastName + "'"}
        onClick={this.onclick_upe_name_email_edit}>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
          <LibraryReact.Nowrap p_fontClass="">
            {p_userPerEmailObj.firstNameLastName}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
          <LibraryReact.Nowrap p_fontClass="fontItalic">
            {p_userPerEmailObj.email}
          </LibraryReact.Nowrap>
        </div>
      </div>
    );
    
    var lockedOutSwitchTitle = "";
    if(userLockedOutTF) {
      lockedOutSwitchTitle += p_userPerEmailObj.firstNameLastName + " is currently locked out of the system and cannot log in.\n\n";
    }
    lockedOutSwitchTitle += "If this switch is on, the user entered the wrong password 5 times in a row and has locked their account. Turn this switch off to reset that and allow them to log in again.";

    var deactivatedSwitchTitle = p_userPerEmailObj.firstNameLastName + " ";
    if(p_userPerEmailObj.upeDeactivatedTF) {
      deactivatedSwitchTitle += "has been deactivated from your CaptureExec system and cannot log in, however all of their data and previous changes remain in the system visible to other Users. They currently do not take up any licenses in the system.\n\nYou can reactivate " + p_userPerEmailObj.firstNameLastName + " by turning off this deactivation switch, as long as there are enough licenses remaining to allow this."
    }
    else {
      deactivatedSwitchTitle += "is an active User of CaptureExec, taking up " + p_userPerEmailObj.upeNumLicensesUsed + " " + JSFUNC.plural(p_userPerEmailObj.upeNumLicensesUsed, "license", "licenses") + ".\n\nThey can be deactivated by turning on this switch, which prevents them from logging in and frees up their used licenses, while retaining their data and changes to the system. (deleteing a User, in contrast, would erase their name attached to changes they made in the system)";
    }

    var addNewUserPowerButtonTitle = "Add another User Power for '" + p_userPerEmailObj.firstNameLastName + "'";
    addNewUserPowerButtonTitle += "\n(A user can have multiple power levels to choose from when logging in, for example a single user could log in as an admin, a divexec for Division A, a divexec for Division B, and a captureexec. Each multiple login counts towards the number of licenses used.)";

    const switchesAndAddUserPowerButtonComponent = (
      <>
        <UserPerEmailRowSwitchWithLabel
          p_isOnTF={userLockedOutTF}
          p_onColor="bd2326"
          p_switchToOnMessage={undefined}
          p_switchToOnButtonName={undefined}
          p_label="Locked Out"
          p_title={lockedOutSwitchTitle}
          f_onClick={this.onclick_lockedout_switch}
        />
        {(c_productStylingObj.setupUsersDeactivedSwitchTF) &&
          <UserPerEmailRowSwitchWithLabel
          p_isOnTF={p_userPerEmailObj.upeDeactivatedTF}
            p_onColor="222"
            p_switchToOnMessage={"You are about to deactivate User '" + p_userPerEmailObj.firstNameLastName + "' from the system. This action will move their name to the bottom of this page under the 'Deactivated Users' section. This will make any licenses they were previously using available to give to other users.\n\nAll Captures and data throughout the system that this User interacted with in the past will continue to display their name for historical preservation (which would be erased if the User was deleted).\n\nIf you choose, you can reassign their Captures to other Capture Managers by clicking the blue capture count button on the right side of this User row."}
            p_switchToOnButtonName="Deactivate User"
            p_label="Deactivated"
            p_title={deactivatedSwitchTitle}
            f_onClick={this.onclick_deactivated_switch}
          />
        }
        {(c_productStylingObj.setupUsersCreatePublicSwitchTF) &&
          <UserPerEmailRowSwitchWithLabel
            p_isOnTF={(p_userPerEmailObj.can_create_public_01 === 1)}
            p_onColor="769"
            p_switchToOnMessage={undefined}
            p_switchToOnButtonName={undefined}
            p_label="Create Public"
            p_title="If this switch is off, the user cannot create Public buttons or graphs"
            f_onClick={this.onclick_create_public_switch}
          />
        }
        {(c_productStylingObj.setupUsersCanAddUserPowerTF) &&
          <div className="flex00a displayFlexColumnHcVc tbPad" style={{flexBasis:"11.5em"}}>
            {(c_usersCanAddNewUserTF && !p_userPerEmailObj.upeDeactivatedTF) &&
              <CEGeneralReact.CEButton
                p_type="add"
                p_text="Add User Power"
                p_title={addNewUserPowerButtonTitle}
                f_onClick={this.onclick_add_user_power}
              />
            }
          </div>
        }
      </>
    );

    var sendWelcomEmailWithDismissComponent = null;
    if(userWelcomeEmailNotYetSentTF && !p_userPerEmailObj.upeDeactivatedTF) {
      sendWelcomEmailWithDismissComponent = (
        <>
          <div className="flex00a displayFlexRowVc smallTopMargin">
            <div className="flex00a">
              <CEGeneralReact.CEButton
                p_type="green"
                p_text="Send Welcome Email"
                p_title={"Click to send a one time welcome email to '" + p_userPerEmailObj.email + "' giving them a link to the " + c_productStylingObj.productName + " along with their company code, email, and password to use to get started"}
                f_onClick={this.onclick_send_user_welcome_email}
              />
            </div>
            <div className="flex00a lrMedPad">
              <CEGeneralReact.CEButton
                p_type="hyperlinkUnderline"
                p_text="dismiss"
                p_title="Click 'dismiss' to skip sending a welcome email to this new user"
                f_onClick={this.onclick_dismiss_send_user_welcome_email}
              />
            </div>
            <div className="flex11a" />
          </div>
          {(s_welcomeEmailSentSuccessMessageTF) &&
            <div className="flex00a microTopMargin">
              <font className="fontItalic fontDarkGreen">
                {c_productStylingObj.productName + " Welcome Email has been sent to " + p_userPerEmailObj.email}
              </font>
            </div>
          }
        </>
      );
    }

    return(
      <>
        <div className="displayFlexRow tbMicroMargin lrMedMargin bgUserTan" style={{border:"solid 1px #cba", borderRadius:"0.5em"}}>
          {(upeNameAndSwitchesWideTrueStackedFalse) ? (
            <>
              <div className="flex11a displayFlexColumnVc tbMicroPad lrMedPad" style={{flexBasis:"100em"}}>
                {upeNameAndEmailComponent}
                {sendWelcomEmailWithDismissComponent}
              </div>
              {switchesAndAddUserPowerButtonComponent}
            </>
          ) : (
            <div className="flex11a displayFlexColumn tbPad lrMedPad" style={{flexBasis:"100em"}}>
              {upeNameAndEmailComponent}
              <div className="lrPad smallTopMargin">
                <div className="flex00a displayFlexRowVc flexWrap">
                  {switchesAndAddUserPowerButtonComponent}
                </div>
                {sendWelcomEmailWithDismissComponent}
              </div>
            </div>
          )}
          <div className="flex11a displayFlexColumnVc" style={{flexBasis:((upeNameAndSwitchesWideTrueStackedFalse) ? ("190") : ("180")) + "em"}}>
            {p_userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs.map((m_upeCombinedUserObj) =>
              <UserPowerRow
                key={m_upeCombinedUserObj.user_id}
                p_userPerEmailObj={p_userPerEmailObj}
                p_upeCombinedUserObj={m_upeCombinedUserObj}
              />
            )}
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
            <CEGeneralReact.VerticalDotsMenu
              p_menuItemsArrayOfObjs={userPerEmailVerticalDotsMenuItemsArrayOfObjs}
            />
          </div>
        </div>
        {(s_editingUserPerEmailTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="addEditDeleteTableEditItem"
            p_title={"Editing User " + p_userPerEmailObj.firstNameLastName}
            f_onClickSave={this.onclick_close_upe_name_email_edit}>
            <div className="flex11a yScroll medFullPad">
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminUserPerEmailEdit_uID" + p_userPerEmailObj.user_id + "first_name"}
                p_fieldDisplayName="User First Name"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={p_userPerEmailObj.first_name}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="15em"
                f_onSaveChanged={this.onsave_upe_first_name}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminUserPerEmailEdit_uID" + p_userPerEmailObj.user_id + "last_name"}
                p_fieldDisplayName="User Last Name"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={p_userPerEmailObj.last_name}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="15em"
                f_onSaveChanged={this.onsave_upe_last_name}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminUserPerEmailEdit_uID" + p_userPerEmailObj.user_id + "email"}
                p_fieldDisplayName="Email"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={p_userPerEmailObj.email}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="15em"
                f_onSaveChanged={this.onsave_upe_email}
              />
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
        {(s_creatingNewUserPowerTF) &&
          <UsersCreateNewUserPower
            p_includeUpeTF={false}
            p_userPerEmailObj={p_userPerEmailObj}
            f_onClose={this.onclick_close_new_user_power}
          />
        }
        {(s_reassignUserCustomCreationsFloatingBoxIsOpenTF) &&
          <UsersReassignUserCustomCreationsFloatingBox
            p_userPerEmailObj={p_userPerEmailObj}
            f_onClose={this.onclick_close_reassign_user_custom_creations_floating_box}
          />
        }
      </>
    );
  }
}));


function UserPerEmailRowSwitchWithLabel(props) { //props: p_isOnTF, p_onColor, p_switchToOnMessage, p_switchToOnButtonName, p_label, p_title, f_onClick
  const p_isOnTF = props.p_isOnTF;
  const p_onColor = props.p_onColor;
  const p_switchToOnMessage = props.p_switchToOnMessage;
  const p_switchToOnButtonName = props.p_switchToOnButtonName;
  const p_label = props.p_label;
  const p_title = props.p_title;

  const switchSizeEm = 2.8;
  const switchOffColor = "bbb";

  return(
    <div
      className="flex00a displayFlexColumnHcVc"
      style={{flexBasis:"5.5em"}}
      title={p_title}>
      <div className="flex00a displayFlexRowVc nowrap">
        <font className="font09 fontItalic fontTextLighter">
          {p_label}
        </font>
      </div>
      {((p_switchToOnMessage !== undefined) && (p_switchToOnButtonName !== undefined)) ? (
        <CEGeneralReact.SwitchWithTextAndConfirmBox
          p_isOnTF={p_isOnTF}
          p_sizeEm={switchSizeEm}
          p_onColor={p_onColor}
          p_offColor={switchOffColor}
          p_switchToOnMessage={p_switchToOnMessage}
          p_switchToOnButtonName={p_switchToOnButtonName}
          f_onSwitch={props.f_onClick}
        />
      ) : (
        <LibraryReact.Switch
          p_isOnTF={p_isOnTF}
          p_sizeEm={switchSizeEm}
          p_onColor={p_onColor}
          p_offColor={switchOffColor}
          f_onClick={props.f_onClick}
        />
      )}
    </div>
  );
}


const UsersCreateNewUserPower = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx", "UserMobx")(observer(
class UsersCreateNewUserPower extends Component { //props: p_includeUpeTF, p_userPerEmailObj, f_onClose
  constructor(props) {
    super(props);

    const c_productIsSamGovTrackerTF = this.props.CaptureExecMobx.c_productIsSamGovTrackerTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_usersPreselectedColumnPresetIDIfOnly1ChoiceOrm1 = this.props.AdminMobx.c_usersPreselectedColumnPresetIDIfOnly1ChoiceOrm1;
    const c_usersPreselectedSortPresetIDIfOnly1ChoiceOrm1 = this.props.AdminMobx.c_usersPreselectedSortPresetIDIfOnly1ChoiceOrm1;

    //initialize user's selected divisionID
    var initialDivisionID = -1; //can make more than 1 division, initialize with no division selected for the new user
    if(!c_productStylingObj.setupCompanyCanCreateDivisionsTF) { //company forced to have exactly 1 division in admin, initialize to that divisionID (usually ID:1)
      initialDivisionID = this.props.DatabaseMobx.c_topDivision00ID;
    }

    var initialMasterButtonsDefaultColumnPresetID = c_usersPreselectedColumnPresetIDIfOnly1ChoiceOrm1;
    var initialMasterButtonsDefaultSortPresetID = c_usersPreselectedSortPresetIDIfOnly1ChoiceOrm1;
    if(c_productIsSamGovTrackerTF) {
      initialMasterButtonsDefaultColumnPresetID = 1;
      initialMasterButtonsDefaultSortPresetID = 1;
    }

    this.state = {
      s_firstName: "",
      s_lastName: "",
      s_email: "",
      s_powerName: "",
      s_divisionID: initialDivisionID,
      s_altName: "",
      s_showErrorsTF: false,
      s_isWorkingTF: false
    };
  }

  onchange_first_name = (i_newValue) => { this.setState({s_firstName:i_newValue}); }
  onchange_last_name = (i_newValue) => { this.setState({s_lastName:i_newValue}); }
  onchange_email = (i_newValue) => { this.setState({s_email:i_newValue}); }
  onchange_power_name = (i_newValue) => { this.setState({s_powerName:i_newValue}); }
  onchange_division_id = (i_newValue) => { this.setState({s_divisionID:i_newValue}); }
  onchange_alt_name = (i_newValue) => { this.setState({s_altName:i_newValue}); }

  onclick_create_new_user = () => {
    const s_firstName = this.state.s_firstName;
    const s_lastName = this.state.s_lastName;
    const s_email = this.state.s_email;

    const p_includeUpeTF = this.props.p_includeUpeTF;
    const p_userPerEmailObj = this.props.p_userPerEmailObj;

    if(p_includeUpeTF) {
      if(this.first_name_error_tf() || this.last_name_error_tf() || this.email_unique_error_tf() || this.email_error_tf() || this.power_error_tf() || this.division_error_tf()) {
        this.setState({s_showErrorsTF:true});
      }
      else {
        this.setState({s_isWorkingTF:true});

        const c_companyNewUserDefaultPassword = this.props.DatabaseMobx.c_companyNewUserDefaultPassword;

        const todayDateYmd = JSFUNC.now_date();
        const todayMinus2YearsDateYmd = JSFUNC.date_add_months(todayDateYmd, -24);
        const todayPlus3YearsDateYmd = JSFUNC.date_add_months(todayDateYmd, 36);

        const jsDescription = JSFUNC.js_description_from_action("AdminReact - UsersCreateNewUserPower", "onclick_create_new_user", [], []);
        const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

        var fieldNamesArray = [];
        var valuesArray = [];
        var idsbArray = [];
        fieldNamesArray.push("email");                                            valuesArray.push(s_email);                        idsbArray.push("s");
        fieldNamesArray.push("first_name");                                       valuesArray.push(s_firstName);                    idsbArray.push("s");
        fieldNamesArray.push("last_name");                                        valuesArray.push(s_lastName);                     idsbArray.push("s");
        fieldNamesArray.push("disabled");                                         valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("lockedout");                                        valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("no_bit_mass_emails_01");                            valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("can_create_public_01");                             valuesArray.push(1);                              idsbArray.push("i");
        fieldNamesArray.push("code_update_01");                                   valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("font_size_px");                                     valuesArray.push(12);                             idsbArray.push("i");
        fieldNamesArray.push("left_nav_0collapsed_1expanded");                    valuesArray.push(1);                              idsbArray.push("i");
        fieldNamesArray.push("right_panel_width_em");                             valuesArray.push(28);                             idsbArray.push("i");
        fieldNamesArray.push("notification_email_01");                            valuesArray.push(1);                              idsbArray.push("i");
        fieldNamesArray.push("archived_captures_preference");                     valuesArray.push("noLoadArchivedWithPrompt");     idsbArray.push("s");
        fieldNamesArray.push("capture_card_min_height_em");                       valuesArray.push(20);                             idsbArray.push("i");
        fieldNamesArray.push("capture_card_max_height_em");                       valuesArray.push(35);                             idsbArray.push("i");
        fieldNamesArray.push("dates_card_three_cols_0_single_col_1");             valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("recently_visited_capture_ids_comma");               valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("favorited_capture_ids_comma");                      valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("cst_row_height_em");                                valuesArray.push(5);                              idsbArray.push("d");
        fieldNamesArray.push("cst_edit_cells_mode_01");                           valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("stage_view_box_size");                              valuesArray.push(2);                              idsbArray.push("i");
        fieldNamesArray.push("stage_view_selected_capture_type_ids_comma");       valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("stage_view_minimized_stage_ids_comma");             valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("priority_view_minimized_priority_ids_comma");       valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("capture_chart_start_date");                         valuesArray.push(todayMinus2YearsDateYmd);        idsbArray.push("s");
        fieldNamesArray.push("capture_chart_end_date");                           valuesArray.push(todayPlus3YearsDateYmd);         idsbArray.push("s");
        fieldNamesArray.push("capture_chart_progress_size");                      valuesArray.push(10);                             idsbArray.push("i");
        fieldNamesArray.push("capture_chart_gantt_size");                         valuesArray.push(2);                              idsbArray.push("i");
        fieldNamesArray.push("notepad_selected_tab_db_name");                     valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("notepad_note_stamps_view_flag");                    valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("gcss_search_results_compact0_large1");              valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("gcss_search_results_sort_gcss_field_db_name");      valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("gcss_search_results_sort_desc0_asc1");              valuesArray.push(1);                              idsbArray.push("i");
        fieldNamesArray.push("gcss_ids_marked_for_import_comma");                 valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("gcss_govwin_ids_marked_for_import_comma");          valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("gcss_fedcomp_ids_marked_for_import_comma");         valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("gcss_sam0_govwin1");                                valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("gcss_cexai_filters_and0_or1");                      valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("gcss_cexai_selected_filters_auto_search_ids_comma");valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("gcss_cexai_positive_gcss_ids_comma");               valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("gcss_cexai_negative_gcss_ids_comma");               valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("sync_user_ce_actions_in_integration_system_01");    valuesArray.push(1);                              idsbArray.push("i");
        fieldNamesArray.push("password");                                         valuesArray.push(c_companyNewUserDefaultPassword);idsbArray.push("s");
        fieldNamesArray.push("password_last_changed_date");                       valuesArray.push(JSFUNC.now_date());              idsbArray.push("s");
        fieldNamesArray.push("welcome_email_sent_01");                            valuesArray.push(0);                              idsbArray.push("i");
        fieldNamesArray.push("last_refresh_datetime_utc");                        valuesArray.push(JSFUNC.blank_datetime());        idsbArray.push("s");
        fieldNamesArray.push("refresh_data_comma");                               valuesArray.push("");                             idsbArray.push("s");
        fieldNamesArray.push("currently_open_capture_id");                        valuesArray.push(-1);                             idsbArray.push("i");

        C_CallPhpTblUID.add_insert("tbl_a_users_per_email", fieldNamesArray, valuesArray, idsbArray);

        const functionOnSuccess = (i_parseResponse) => {
          const newlyCreatedUserPerEmailID = i_parseResponse.outputObj.i0;
          const newlyCreatedUserFirstNameLastName = JSFUNC.full_name_from_first_name_last_name(s_firstName, s_lastName);
          this.insert_user_power_record(newlyCreatedUserPerEmailID, newlyCreatedUserFirstNameLastName);
        }
        C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

        C_CallPhpTblUID.execute();
      }
    }
    else {
      if(this.power_error_tf() || this.division_error_tf()) {
        this.setState({s_showErrorsTF:true});
      }
      else {
        this.setState({s_isWorkingTF:true});
        this.insert_user_power_record(p_userPerEmailObj.id, p_userPerEmailObj.firstNameLastName);
      }
    }
  }

  insert_user_power_record = (i_userPerEmailID, i_userFirstNameLastName) => {
    const s_powerName = this.state.s_powerName;
    const s_divisionID = this.state.s_divisionID;
    const s_altName = this.state.s_altName;

    const c_allDivisionIDsComma = this.props.DatabaseMobx.c_allDivisionIDsComma;
    const c_closedWonStageIDsArray = this.props.DatabaseMobx.c_closedWonStageIDsArray;

    const initialDivexecMoneyFieldID = this.props.DatabaseMobx.c_fieldMapOfContractRevenueValue.get("id");

    const closedWonStageIDsComma = JSFUNC.convert_array_to_comma_list(c_closedWonStageIDsArray);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - UsersCreateNewUserPower", "insert_user_power_record", ["i_userPerEmailID", "i_userFirstNameLastName"], [i_userPerEmailID, i_userFirstNameLastName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var fieldNamesArray = [];
    var valuesArray = [];
    var idsbArray = [];
    fieldNamesArray.push("user_per_email_id");                      valuesArray.push(i_userPerEmailID);           idsbArray.push("i");
    fieldNamesArray.push("division_id");                            valuesArray.push(s_divisionID);               idsbArray.push("i");
    fieldNamesArray.push("power_name");                             valuesArray.push(s_powerName);                idsbArray.push("s");
    fieldNamesArray.push("bit_non_billable_user_01");               valuesArray.push(0);                          idsbArray.push("i");
    fieldNamesArray.push("alt_full_name");                          valuesArray.push(s_altName);                  idsbArray.push("s");
    fieldNamesArray.push("num_logins");                             valuesArray.push(0);                          idsbArray.push("i");
    fieldNamesArray.push("last_login_datetime_utc");                valuesArray.push(JSFUNC.blank_datetime());    idsbArray.push("s");
    fieldNamesArray.push("last_login_timezone_string");             valuesArray.push("");                         idsbArray.push("s");
    fieldNamesArray.push("last_login_browser_type");                valuesArray.push("");                         idsbArray.push("s");
    fieldNamesArray.push("last_login_mediaqueryflag");              valuesArray.push(-1);                         idsbArray.push("i");
    fieldNamesArray.push("start_tab_name");                         valuesArray.push("");                         idsbArray.push("s");
    fieldNamesArray.push("quick_access_master_preset_ids_comma");   valuesArray.push("-101,-102,-103");           idsbArray.push("s");
    fieldNamesArray.push("quick_access_master_preset_id_selected"); valuesArray.push(-101);                       idsbArray.push("i");
    fieldNamesArray.push("gantt_date_preset_id_selected");          valuesArray.push(-1);                         idsbArray.push("i");
    fieldNamesArray.push("divexec_filter_division_ids_comma");      valuesArray.push(c_allDivisionIDsComma);      idsbArray.push("s");
    fieldNamesArray.push("divexec_goals_latest_year");              valuesArray.push(0);                          idsbArray.push("i");
    fieldNamesArray.push("divexec_goals_num_years");                valuesArray.push(10);                         idsbArray.push("i");
    fieldNamesArray.push("divexec_graphs_start_date");              valuesArray.push("8004-01-04");               idsbArray.push("s");
    fieldNamesArray.push("divexec_graphs_end_date");                valuesArray.push("9001-01-04");               idsbArray.push("s");
    fieldNamesArray.push("divexec_performance_money_field_id");     valuesArray.push(initialDivexecMoneyFieldID); idsbArray.push("i");
    fieldNamesArray.push("divexec_finproj_custom0_manual1");        valuesArray.push(1);                          idsbArray.push("i");
    fieldNamesArray.push("divexec_finproj_filter_preset_id");       valuesArray.push(-1);                         idsbArray.push("i");
    fieldNamesArray.push("divexec_finproj_division_ids_comma");     valuesArray.push(c_allDivisionIDsComma);      idsbArray.push("s");
    fieldNamesArray.push("divexec_finproj_stage_ids_comma");        valuesArray.push(closedWonStageIDsComma);     idsbArray.push("s");
    fieldNamesArray.push("divexec_finproj_money_field_id");         valuesArray.push(initialDivexecMoneyFieldID); idsbArray.push("i");
    fieldNamesArray.push("divexec_hotbits_fixed0_calendar1");       valuesArray.push(0);                          idsbArray.push("i");
    fieldNamesArray.push("divexec_critthresh_money_field_id");      valuesArray.push(initialDivexecMoneyFieldID); idsbArray.push("i");

    C_CallPhpTblUID.add_insert("tbl_a_users", fieldNamesArray, valuesArray, idsbArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyCreatedUserID = i_parseResponse.outputObj.i0;
      
      //insert an admin changelog entry
      const C_CallPhpTblUIDAfterCreateNewUserPower = new JSPHP.ClassCallPhpTblUID(jsDescription);
      const newlyCreatedUserPowerDataObj = DatabaseMobx.power_data_obj_from_power_name(s_powerName);
      const adminChangelogValue = i_userFirstNameLastName + " (" + newlyCreatedUserPowerDataObj.displayName + ")";
      C_CallPhpTblUIDAfterCreateNewUserPower.add_changelog_admin(1100, adminChangelogValue);
      C_CallPhpTblUIDAfterCreateNewUserPower.execute();
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    const functionOnFinish = () => {
      this.setState({s_isWorkingTF:false});
      this.onclick_close_create_new_user();
    }
    C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

    C_CallPhpTblUID.execute();
  }

  onclick_close_create_new_user = () => {
    if(this.props.f_onClose) {
      this.props.f_onClose();
    }
  }

  first_name_error_tf = () => { return(this.state.s_firstName === ""); }
  last_name_error_tf = () => { return(this.state.s_lastName === ""); }
  email_unique_error_tf = () => { return(JSFUNC.in_array(this.state.s_email, this.props.AdminMobx.c_usersAllUserEmailsArray)); }
  email_error_tf = () => { return(JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(this.state.s_email) !== undefined); }
  power_error_tf = () => { return(!JSFUNC.in_array(this.state.s_powerName, this.props.DatabaseMobx.c_allUserPowersDbNamesArray)); }
  division_error_tf = () => { return(this.state.s_divisionID <= 0); }

  render() {
    const s_firstName = this.state.s_firstName;
    const s_lastName = this.state.s_lastName;
    const s_email = this.state.s_email;
    const s_powerName = this.state.s_powerName;
    const s_divisionID = this.state.s_divisionID;
    const s_altName = this.state.s_altName;
    const s_showErrorsTF = this.state.s_showErrorsTF;
    const s_isWorkingTF = this.state.s_isWorkingTF;

    const p_includeUpeTF = this.props.p_includeUpeTF;
    const p_userPerEmailObj = this.props.p_userPerEmailObj;

    const c_productIsSamGovTrackerTF = this.props.CaptureExecMobx.c_productIsSamGovTrackerTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_usersMaxNumSuperAdminsAddedTF = this.props.AdminMobx.c_usersMaxNumSuperAdminsAddedTF;
    const c_usersNumLicensesRemaining = this.props.AdminMobx.c_usersNumLicensesRemaining;
    const c_usersAddNewUserPerEmailPowerDbNamesToNotIncludeArray = this.props.AdminMobx.c_usersAddNewUserPerEmailPowerDbNamesToNotIncludeArray;

    const userOrUserPowerString = ((p_includeUpeTF) ? ("User") : ("User Power"));

    var firstNameError = undefined;
    var lastNameError = undefined;
    var emailError = undefined;
    var powerError = undefined;
    var divisionError = undefined;
    if(s_showErrorsTF) { //pushed the create button for the first time
      if(this.first_name_error_tf()) { firstNameError = "First Name cannot be blank"; }
      if(this.last_name_error_tf()) { lastNameError = "Last Name cannot be blank"; }
      if(this.email_unique_error_tf()) { emailError = "Email '" + s_email + "' has already been added as a user. If you would like to add another power multi-login for this user, close this form and click the gray 'Add User Power' button within that user's row"; }
      if(this.email_error_tf()) { emailError = "Email must be a valid email address (" + JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(s_email) + ")"; }
      if(this.power_error_tf()) { powerError = "Must select a Power for this User"; }
      if(this.division_error_tf()) { divisionError = "Must select a valid division (if none exist, a division must be created on the 'Company/Divisions' Admin subtab)"; }
    }

    var createNewUserSelectPowersToNotIncludeArray = undefined;
    var selectDivisionIDsAlreadyAddedArray = undefined;
    if(p_includeUpeTF) {
      createNewUserSelectPowersToNotIncludeArray = c_usersAddNewUserPerEmailPowerDbNamesToNotIncludeArray;
    }
    else { //adding a new user power to an existing UPE (precalculated which powers to not include in the select options)
      createNewUserSelectPowersToNotIncludeArray = p_userPerEmailObj.createNewUserSelectPowersToNotIncludeArray;
      selectDivisionIDsAlreadyAddedArray = p_userPerEmailObj.upeAllUserDivisionIDsPerPowerNameObj[s_powerName];
    }

    const altNameHoverHelpIconTextArray = [
      "This field will rarely be filled out when creating new Users.",
      "User names are automatically displayed in " + c_productStylingObj.productName + " as 'Firstname Lastname'. This Alternate Name field is used instead of Firstname Lastname if filled out.",
      "Some examples of when to use this field:",
      "1) If a single User has multiple power logins, the name is expanded automatically to distinguish them (like 'Firstname Lastname (Divexec: Sample Division)'), this Alternate Name gives you the option to make a different or much shorter name for this particular User's single power level.",
      "2) When using consultants, you could enter their first and last name, followed by their company so that all users can see where they are from"
    ];

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditItem"
        p_title={"Creating a New " + userOrUserPowerString}
        f_onClickCancel={this.onclick_close_create_new_user}>
        <div className="flex11a yScroll medFullPad">
          {(p_includeUpeTF) &&
            <>
              <CreateNewUserEditFieldShell p_fieldDisplayName="First Name">
                <LibraryReact.Text
                  p_value={s_firstName}
                  p_styleObj={{width:"100%"}}
                  p_tabIndex={1}
                  p_errorTF={(firstNameError !== undefined)}
                  f_onChange={this.onchange_first_name}
                />
                {(firstNameError !== undefined) &&
                  <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={firstNameError} />
                }
              </CreateNewUserEditFieldShell>
              <CreateNewUserEditFieldShell p_fieldDisplayName="Last Name">
                <LibraryReact.Text
                  p_value={s_lastName}
                  p_styleObj={{width:"100%"}}
                  p_tabIndex={2}
                  p_errorTF={(lastNameError !== undefined)}
                  f_onChange={this.onchange_last_name}
                />
                {(lastNameError !== undefined) &&
                  <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={lastNameError} />
                }
              </CreateNewUserEditFieldShell>
              <CreateNewUserEditFieldShell p_fieldDisplayName="Email (used to log in)">
                <LibraryReact.Text
                  p_value={s_email}
                  p_styleObj={{width:"100%"}}
                  p_tabIndex={3}
                  p_errorTF={(emailError !== undefined)}
                  f_onChange={this.onchange_email}
                />
                {(emailError !== undefined) &&
                  <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={emailError} />
                }
              </CreateNewUserEditFieldShell>
            </>
          }
          <CreateNewUserEditFieldShell p_fieldDisplayName="User Power">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectUserPowerWithSuperAdminLicenseCostFieldTypeObj}
              p_valueRaw={s_powerName}
              p_valuesToNotIncludeArray={createNewUserSelectPowersToNotIncludeArray}
              p_tabIndex={4}
              p_errorTF={(powerError !== undefined)}
              f_onChangeOrOnSelect={this.onchange_power_name}
            />
            {(powerError !== undefined) &&
              <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={powerError} />
            }
            {(c_usersNumLicensesRemaining === 0) &&
              <div className="microTopMargin">
                <div className="fontItalic fontDarkBlue">
                  {"With 0 licenses remaining, User Power options that cost additional licenses have been removed. Contact support@bitsolutionsllc.com for pricing on additional licenses."}
                </div>
              </div>
            }
            {(c_usersMaxNumSuperAdminsAddedTF) &&
              <div className="microTopMargin">
                <div className="fontItalic fontDarkBlue">
                  {"'Super Admin' has been removed as a User Power option as the maximum number have already been added."}
                </div>
              </div>
            }
          </CreateNewUserEditFieldShell>
          {(c_productStylingObj.setupCompanyCanCreateDivisionsTF) &&
            <CreateNewUserEditFieldShell p_fieldDisplayName="User's Division">
              {(this.power_error_tf()) ? (
                <font className="fontItalic fontTextLighter">
                  {"--Select a User Power first--"}
                </font>
              ) : (
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectDivisionFieldTypeObj}
                  p_valueRaw={s_divisionID}
                  p_valuesToNotIncludeArray={selectDivisionIDsAlreadyAddedArray}
                  p_tabIndex={5}
                  p_errorTF={(divisionError !== undefined)}
                  f_onChangeOrOnSelect={this.onchange_division_id}
                />
              )}
              {(divisionError !== undefined) &&
                <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={divisionError} />
              }
            </CreateNewUserEditFieldShell>
          }
          {(c_productStylingObj.setupUsersCreateUserAltNameTF) &&
            <CreateNewUserEditFieldShell p_fieldDisplayName="Alternative Name to Display (optional)" p_hoverHelpIconTextArray={altNameHoverHelpIconTextArray}>
              <LibraryReact.Text
                p_value={s_altName}
                p_styleObj={{width:"100%"}}
                p_tabIndex={6}
                p_errorTF={false}
                f_onChange={this.onchange_alt_name}
              />
            </CreateNewUserEditFieldShell>
          }
          <div className="borderT1bbb" style={{margin:"2em 0"}} />
          <CreateOrEditUserUserPowerDescriptions />
        </div>
        <div className="flex00a displayFlexColumnHcVc tbMedPad borderT1ddd">
          {(s_isWorkingTF) ? (
            <CEGeneralReact.PhpWorkingMessage p_text={"Creating " + userOrUserPowerString + "..."} />
          ) : (
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Create New User"
              p_tabIndex={9}
              f_onClick={this.onclick_create_new_user}
            />
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


function CreateNewUserEditFieldShell(props) { //props: p_fieldDisplayName, p_hoverHelpIconTextArray, children
  const p_fieldDisplayName = props.p_fieldDisplayName;
  const p_hoverHelpIconTextArray = props.p_hoverHelpIconTextArray;

  var fieldDisplayNameComponent = null;
  if(!JSFUNC.is_array(p_hoverHelpIconTextArray)) {
    fieldDisplayNameComponent = (
      <font className="fontBlue">
        {p_fieldDisplayName}
      </font>
    );
  }
  else {
    fieldDisplayNameComponent = (
      <>
        <div className="flex11a">
          <font className="fontBlue">
            {p_fieldDisplayName}
          </font>
        </div>
        <div className="flex00a lrPad">
          <CEGeneralReact.HelpHoverIconWithFloatingBox>
            {p_hoverHelpIconTextArray}
          </CEGeneralReact.HelpHoverIconWithFloatingBox>
        </div>
      </>
    );
  }

  return(
    <div className="displayFlexRowVc tbMargin">
      <div className="flex11a displayFlexRowVc" style={{flexBasis:"100em"}}>
        {fieldDisplayNameComponent}
      </div>
      <div className="flex11a" style={{flexBasis:"200em"}}>
        {props.children}
      </div>
    </div>
  );
}


const CreateOrEditUserUserPowerDescriptions = inject("DatabaseMobx")(observer(
class CreateOrEditUserUserPowerDescriptions extends Component { //props:
  render() {
    const c_allUserPowersDataArrayOfObjs = this.props.DatabaseMobx.c_allUserPowersDataArrayOfObjs;

    return(
      <div className="medFullMargin border1bbb borderRadius10 bgLightestGray medFullPad">
        <div className="medBottomMargin textCenter">
          <font className="font11 fontBold fontTextLight">
            {"Descriptions of each User Power option"}
          </font>
        </div>
        <div className="medBottomMargin lrHugeMargin border bevelBorderColors borderRadius10 bgGray tbPad lrMedPad textCenter">
          <font className="fontWhite">
            {"All User Powers can do full Capture Management as part of the process"}
          </font>
        </div>
        {c_allUserPowersDataArrayOfObjs.map((m_userPowerDataObj) =>
          <div
            key={m_userPowerDataObj.id}
            className="displayFlexRow borderT1eee borderB1ddd tbPad hoverLightestBlueGradient"
            style={{minHeight:"4.4em"}}>
            <div className="flex00a lrMargin border bevelBorderColors" style={{flexBasis:"1.5em", background:m_userPowerDataObj.hashColor}} />
            <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:"11em"}}>
              <font className="fontItalic fontBold fontTextLight">
                {m_userPowerDataObj.displayName}
              </font>
            </div>
            <div className="flex11a displayFlexRowVc lrPad">
              <font className="fontItalic fontTextLight">
                {m_userPowerDataObj.description}
              </font>
            </div>
          </div>
        )}
      </div>
    );
  }
}));


const UserPowerRow = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx", "UserMobx")(observer(
class UserPowerRow extends Component { //props: p_userPerEmailObj, p_upeCombinedUserObj
  constructor(props) {
    super(props);
    this.state = {
      s_editingUserPowerTF: false
    };
  }

  onclick_edit_user_power_row = () => {
    this.setState({s_editingUserPowerTF:true});
  }

  onclick_close_edit_user_power = () => {
    this.setState({s_editingUserPowerTF:false});
  }

  ondelete_user_power = () => {
    const p_upeCombinedUserObj = this.props.p_upeCombinedUserObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - UserPowerRow", "ondelete_user_power", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_users", p_upeCombinedUserObj.user_id); //users are sorted by power sort and division_tree id, no resort needed when deleting
    C_CallPhpTblUID.execute();
  }

  onsave_user_power_power = (i_powerName) => {
    this.update_user_power_field("power_name", i_powerName, "s");
  }

  onsave_user_power_division = (i_divisionID) => {
    this.update_user_power_field("division_id", i_divisionID, "i");
  }

  onsave_user_power_alt_full_name = (i_altName) => {
    this.update_user_power_field("alt_full_name", i_altName, "s");
  }

  update_user_power_field = (i_fieldName, i_value, i_idsb) => {
    const p_upeCombinedUserObj = this.props.p_upeCombinedUserObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - UserPowerRow", "update_user_power_field", ["i_fieldName", "i_value", "i_idsb"], [i_fieldName, i_value, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_users", p_upeCombinedUserObj.user_id, i_fieldName, i_value, i_idsb);
    C_CallPhpTblUID.execute();
  }

  render() {
    const s_editingUserPowerTF = this.state.s_editingUserPowerTF;

    const p_userPerEmailObj = this.props.p_userPerEmailObj;
    const p_upeCombinedUserObj = this.props.p_upeCombinedUserObj;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_userID = this.props.UserMobx.o_userID;

    const userNumPowers = p_userPerEmailObj.upeMultiLoginCombinedUsersArrayOfObjs.length;
    const canDeleteUserPowerTF = ((userNumPowers > 1) && (p_upeCombinedUserObj.user_id !== o_userID)); //can't delete the last power of a userPerEmail, also can't delete yourself

    //modified power name shows up only in this Admin User table when a user has tbl_a_users bit_non_billable_user_01 marked as 1
    var modifiedUserPowerRowPowerDisplayName = p_upeCombinedUserObj.powerDisplayName;
    var modifiedUserPowerRowPowerDisplayNamePlainText = p_upeCombinedUserObj.powerDisplayName;
    if(p_upeCombinedUserObj.bitNonBillableUserTF) {
      modifiedUserPowerRowPowerDisplayName = (
        <font>{p_upeCombinedUserObj.powerDisplayName}<font className="fontItalic">{" [BIT Non-Billable User]"}</font></font>
      );
      modifiedUserPowerRowPowerDisplayNamePlainText += " [BIT Non-Billable User]";
    }

    var userPowerRowTitle = p_userPerEmailObj.firstNameLastName + " (" + p_userPerEmailObj.email + ")";
    userPowerRowTitle += "\nUser Power: " + modifiedUserPowerRowPowerDisplayNamePlainText;
    userPowerRowTitle += "\nDivision: " + p_upeCombinedUserObj.divisionName;
    if(JSFUNC.string_is_filled_out_tf(p_upeCombinedUserObj.alt_full_name)) {
      userPowerRowTitle += "\nAlternative Name: " + p_upeCombinedUserObj.alt_full_name;
    }
    userPowerRowTitle += "\n[Click to edit this User's Power, Division";
    if(c_productStylingObj.setupUsersCreateUserAltNameTF) {
      userPowerRowTitle += ", or Alternative Name to Display"
    }
    userPowerRowTitle += "]";

    return (
      <div
        className="flex00a displayFlexRow lrMedMargin microTopMargin microBottomMargin lrPad hoverLighterBlueGradient cursorPointer"
        style={{height:"2.2em", backgroundColor:((p_userPerEmailObj.upeDeactivatedTF) ? ("#ddd") : ("#cdf")), border:"solid 1px #cba", borderRadius:"0.5em"}}
        title={userPowerRowTitle}
        onClick={this.onclick_edit_user_power_row}>
        <div className="flex00a smallFullMargin" style={{flexBasis:"0.9em", background:p_upeCombinedUserObj.powerHashColor}} />
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"250em", maxWidth:"20em"}}>
          <LibraryReact.Nowrap p_fontClass="">
            {modifiedUserPowerRowPowerDisplayName}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"150em"}}>
          <LibraryReact.Nowrap p_fontClass="fontItalic">
            {p_upeCombinedUserObj.divisionName}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}}>
          <LibraryReact.Nowrap p_fontClass="fontItalic">
            {p_upeCombinedUserObj.alt_full_name}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex00a displayFlexRowVc lrPad">
          <font className="fontItalic fontTextLighter">
            {"# Captures"}
          </font>
        </div>
        <div className="flex00a displayFlexRowVc lrPad">
          <CapturesReact.NumberOfAssignedCapturesCountButton
            p_partialNumMatchingCaptures={p_upeCombinedUserObj.numCapturesOwnedByUserPower}
            p_matchingCaptureIDsArray={p_upeCombinedUserObj.capturesOwnedByUserPowerCaptureIDsArray}
            p_floatingBoxTitle={"Captures Owned by User '" + p_userPerEmailObj.firstNameLastName + " (" + p_upeCombinedUserObj.powerDisplayName + " - " + p_upeCombinedUserObj.divisionName + ")'"}
          />
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          {(canDeleteUserPowerTF) &&
            <CEGeneralReact.DeleteMenu
              p_menuItemText="Delete"
              p_message={"Are you sure you want to remove the following User from " + c_productStylingObj.productName + "?\n\nUser: " + p_userPerEmailObj.firstNameLastName + "\nUser Power/Division: " + p_upeCombinedUserObj.powerDisplayName + " (" + p_upeCombinedUserObj.divisionName + ")"}
              f_onDelete={this.ondelete_user_power}
            />
          }
        </div>
        {(s_editingUserPowerTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="addEditDeleteTableEditItem"
            p_title={"Editing " + p_upeCombinedUserObj.powerDisplayName + " (" + p_upeCombinedUserObj.divisionName + ") User Power for " + p_userPerEmailObj.firstNameLastName}
            f_onClickSave={this.onclick_close_edit_user_power}>
            <div className="flex11a yScroll medFullPad">
              {(p_upeCombinedUserObj.bitNonBillableUserTF) &&
                <div
                  className="displayFlexColumnHcVc medBottomMargin"
                  title="This is a BIT Solutions created User that you are not being billed for and you are not able to create yourself. If you have any questions, please contact support@bitsolutionsllc.com">
                  <div className="displayFlexColumnHcVc border1 bevelBorderColorBlue borderRadius05 bgLightBlue tbMicroPad lrMedPad textCenter" style={{minWidth:"10em", width:"50%"}}>
                    <font className="font11 fontItalic fontWhite">
                      {"BIT Non-Billable User"}
                    </font>
                  </div>
                </div>
              }
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminUserEdit_uID" + p_upeCombinedUserObj.user_id + "power"}
                p_fieldDisplayName="User Power"
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectUserPowerWithSuperAdminLicenseCostFieldTypeObj}
                p_valueRaw={p_upeCombinedUserObj.power_name}
                p_valueIsEditableTFU={true}
                p_valuesToNotIncludeArray={p_upeCombinedUserObj.editUserSelectPowersToNotIncludeArray}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="10em"
                f_onSaveChanged={this.onsave_user_power_power}
              />
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={"adminUserEdit_uID" + p_upeCombinedUserObj.user_id + "division_id"}
                p_fieldDisplayName="User's Division"
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectDivisionFieldTypeObj}
                p_valueRaw={p_upeCombinedUserObj.division_id}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="10em"
                f_onSaveChanged={this.onsave_user_power_division}
              />
              {(c_productStylingObj.setupUsersCreateUserAltNameTF) &&
                <CEGeneralReact.CaptureExecFieldEditSaveCancel
                  p_ceEditItemString={"adminUserEdit_uID" + p_upeCombinedUserObj.user_id + "alt_full_name"}
                  p_fieldDisplayName="Alternative Name to Display (optional)"
                  p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                  p_valueRaw={p_upeCombinedUserObj.alt_full_name}
                  p_valueIsEditableTFU={true}
                  p_containerClass="tbMargin"
                  p_fieldClass="fontBlue"
                  p_fieldWidth="10em"
                  f_onSaveChanged={this.onsave_user_power_alt_full_name}
                />
              }
              <div style={{marginTop:"3em"}} />
              <CreateOrEditUserUserPowerDescriptions />
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </div>
    );
  }
}));





const UsersReassignUserCustomCreationsFloatingBox = inject("AdminMobx", "DatabaseMobx", "UserMobx")(observer(
class UsersReassignUserCustomCreationsFloatingBox extends Component { //props: p_userPerEmailObj, f_onClose
  //user_id: trend/daily graphs/pages, master presets, column presets, filter presets, sort presets, gantt date presets
  //user_per_email_id: GCSS Auto Searches
  constructor(props) {
    super(props);
    this.state = {
      s_reassignToUserID: -1,
      s_triedFirstSaveTF: false
    };
  }

  onselect_to_user = (i_selectedToUserID) => {
    this.setState({s_reassignToUserID:i_selectedToUserID});
  }

  onclick_reassign_custom_creations = () => {
    const s_reassignToUserID = this.state.s_reassignToUserID;

    this.setState({s_triedFirstSaveTF:true});

    if(JSFUNC.select_int_is_filled_out_tf(s_reassignToUserID)) {
      const customCreationTblInfoArrayOfObjs = this.get_custom_creations_tbl_info_arrayOfObjs();

      const jsDescription = JSFUNC.js_description_from_action("AdminReact - UsersReassignUserCustomCreationsFloatingBox", "onclick_reassign_custom_creations", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      for(let customCreationTblInfo of customCreationTblInfoArrayOfObjs) {
        var tblDataMapOfMaps = this.props.DatabaseMobx["o_" + customCreationTblInfo.tblMapOfMapsName];
        for(let dataMap of tblDataMapOfMaps.values()) {
          if(JSFUNC.in_array(dataMap.get(customCreationTblInfo.filterFieldDbName), customCreationTblInfo.filterValuesArray)) {
            var rowID = dataMap.get("id");
            if(customCreationTblInfo.newValueInt > 0) {
              C_CallPhpTblUID.add_update(customCreationTblInfo.tblMapOfMapsName, rowID, customCreationTblInfo.filterFieldDbName, customCreationTblInfo.newValueInt, "i");
            }
          }
        }
      }

      C_CallPhpTblUID.execute();
    }
  }

  get_custom_creations_tbl_info_arrayOfObjs = () => {
    const s_reassignToUserID = this.state.s_reassignToUserID;

    const p_userPerEmailObj = this.props.p_userPerEmailObj;

    const fromUserIDsArray = this.props.DatabaseMobx.get_all_user_ids_from_user_per_email_id(p_userPerEmailObj.id);

    var reassignToUserPerEmailID = -1; //in case it can't match the user to a userPerEmail
    for(let combinedUserMap of this.props.DatabaseMobx.c_tbl_a_users.values()) {
      if(combinedUserMap.get("user_id") === s_reassignToUserID) {
        reassignToUserPerEmailID = combinedUserMap.get("user_per_email_id");
        break;
      }
    }

    return([
      {tblDisplayName:"Master Presets",                 tblMapOfMapsName:"tbl_f_capture_table_presets", filterFieldDbName:"user_id", filterValuesArray:fromUserIDsArray, p_displayFieldDbName:"name", newValueInt:s_reassignToUserID},
      {tblDisplayName:"Column Presets",                 tblMapOfMapsName:"tbl_f_cst_column_presets",    filterFieldDbName:"user_id", filterValuesArray:fromUserIDsArray, p_displayFieldDbName:"name", newValueInt:s_reassignToUserID},
      {tblDisplayName:"Filter Presets",                 tblMapOfMapsName:"tbl_f_filter_presets",        filterFieldDbName:"user_id", filterValuesArray:fromUserIDsArray, p_displayFieldDbName:"name", newValueInt:s_reassignToUserID},
      {tblDisplayName:"Sort Presets",                   tblMapOfMapsName:"tbl_f_sort_presets",          filterFieldDbName:"user_id", filterValuesArray:fromUserIDsArray, p_displayFieldDbName:"name", newValueInt:s_reassignToUserID},
      {tblDisplayName:"Gantt Date Presets",             tblMapOfMapsName:"tbl_f_gantt_date_presets",    filterFieldDbName:"user_id", filterValuesArray:fromUserIDsArray, p_displayFieldDbName:"name", newValueInt:s_reassignToUserID},
      {tblDisplayName:"Daily Snapshot Pages of Graphs", tblMapOfMapsName:"tbl_d_daily_snapshot_pages",  filterFieldDbName:"user_id", filterValuesArray:fromUserIDsArray, p_displayFieldDbName:"name", newValueInt:s_reassignToUserID},
      {tblDisplayName:"Trend Analyzer Pages of Graphs", tblMapOfMapsName:"tbl_d_trend_analyzer_pages",  filterFieldDbName:"user_id", filterValuesArray:fromUserIDsArray, p_displayFieldDbName:"name", newValueInt:s_reassignToUserID},
      {tblDisplayName:"GCSS Auto Searches",             tblMapOfMapsName:"tbl_u_gcss_auto_searches",    filterFieldDbName:"user_per_email_id", filterValuesArray:[p_userPerEmailObj.id], p_displayFieldDbName:"name", newValueInt:reassignToUserPerEmailID}
    ]);
  }

  render() {
    const s_reassignToUserID = this.state.s_reassignToUserID;
    const s_triedFirstSaveTF = this.state.s_triedFirstSaveTF;

    const p_userPerEmailObj = this.props.p_userPerEmailObj;

    const selectedToUserErrorTF = (s_triedFirstSaveTF && !JSFUNC.select_int_is_filled_out_tf(s_reassignToUserID));

    const customCreationTblInfoArrayOfObjs = this.get_custom_creations_tbl_info_arrayOfObjs();

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="medium"
        p_title={"Reassign User Custom Creations from User '" + p_userPerEmailObj.firstNameLastName + "'"}
        f_onClickCancel={this.props.f_onClose}>
        <div className="flex11a yScroll medFullPad">
          <div className="smallBottomMargin">
            <font className="font11 fontBold fontTextLight">
              {"Select User to reassign all the custom creations to:"}
            </font>
          </div>
          <div className="" style={{maxWidth:"25em"}}>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectUserFieldTypeObj}
              p_valueRaw={s_reassignToUserID}
              p_errorTF={selectedToUserErrorTF}
              f_onChangeOrOnSelect={this.onselect_to_user}
            />
            {(selectedToUserErrorTF) &&
              <CEGeneralReact.ErrorText p_text="Select a User to reassign the custom creations to" />
            }
          </div>
          <div className="bigTopMargin lrMedMargin border1bbb borderRadius05 bgLightestGray medFullPad">
            {(customCreationTblInfoArrayOfObjs.map((m_customCreationTblInfoObj) =>
              <UserCustomCreationsList
                p_text={m_customCreationTblInfoObj.tblDisplayName}
                p_dataMapOfMaps={this.props.DatabaseMobx["o_" + m_customCreationTblInfoObj.tblMapOfMapsName]}
                p_filterFieldDbName={m_customCreationTblInfoObj.filterFieldDbName}
                p_filterValuesArray={m_customCreationTblInfoObj.filterValuesArray}
                p_displayFieldDbName={m_customCreationTblInfoObj.p_displayFieldDbName}
              />
            ))}
          </div>
        </div>
        <div className="displayFlexRowHcVc borderT1bbb tbMedPad">
          <div className="flex00a lrMedPad">
            <CEGeneralReact.CEButton
              p_text="Reassign Custom Creations"
              p_type="blue"
              f_onClick={this.onclick_reassign_custom_creations}
            />
          </div>
          <div className="flex00a lrMedPad">
            <CEGeneralReact.CEButton
              p_text="Cancel"
              p_type="gray"
              f_onClick={this.props.f_onClose}
            />
          </div>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

function UserCustomCreationsList(props) { //p_text, p_dataMapOfMaps, p_filterFieldDbName, p_filterValuesArray, p_displayFieldDbName
  const p_text = props.p_text;
  const p_dataMapOfMaps = props.p_dataMapOfMaps;
  const p_filterFieldDbName = props.p_filterFieldDbName;
  const p_filterValuesArray = props.p_filterValuesArray;
  const p_displayFieldDbName = props.p_displayFieldDbName;

  var dataValuesArray = [];
  var testArray = [];
  for(let dataMap of p_dataMapOfMaps.values()) {
    //testArray.push("[" + JSFUNC.print_array(p_filterValuesArray) + "] " + p_filterFieldDbName + ": " + dataMap.get(p_filterFieldDbName) + ", " + p_displayFieldDbName + ": " + dataMap.get(p_displayFieldDbName));
    if(JSFUNC.in_array(dataMap.get(p_filterFieldDbName), p_filterValuesArray)) {
      dataValuesArray.push(dataMap.get(p_displayFieldDbName));
    }
  }
  /*{testArray.map((m_test) =>
    <div className="">
      <font className="fontDarkRed">
        {" - " + m_test}
      </font>
    </div>
  )}*/

  return(
    <>
      <div className="smallBottomMargin">
        <font className="font11 fontBold fontBlue">
          {p_text + " (" + dataValuesArray.length + ")"}
        </font>
      </div>
      {dataValuesArray.map((m_dataValue) =>
        <div className="">
          <font className="fontDarkBlue">
            {" - " + m_dataValue}
          </font>
        </div>
      )}
      <div className="bigBottomMargin" />
    </>
  );
}






//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabCaptureTypes = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabCaptureTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectedCaptureTypeIDToCopy: -1
    };
  }

  onselect_admin_create_new_capture_default_capture_type = (i_selectedCreateNewCaptureDefaultCaptureTypeID) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("create_new_capture_default_capture_type_id", i_selectedCreateNewCaptureDefaultCaptureTypeID, "i");
  }

  onselect_capture_type_id_to_copy = (i_newValue) => {
    this.setState({s_selectedCaptureTypeIDToCopy:i_newValue});
  }

  onclick_create_new_capture_type_from_copy = () => {
    this.props.AdminMobx.a_capture_type_create_new_from_copy(this.state.s_selectedCaptureTypeIDToCopy);
    this.setState({s_selectedCaptureTypeIDToCopy:-1}); //reset the selected capture type to copy to nothing selected
  }

  render() {
    const selectedCaptureTypeIDToCopy = this.state.s_selectedCaptureTypeIDToCopy;

    const c_adminCaptureTypesWithMatchingCapturesArrayOfObjs = this.props.AdminMobx.c_adminCaptureTypesWithMatchingCapturesArrayOfObjs;
    const c_captureTypesUnassignedMatchingCapturesObj = this.props.AdminMobx.c_captureTypesUnassignedMatchingCapturesObj;
    const o_tbl_a_capture_types_details_fields = this.props.DatabaseMobx.o_tbl_a_capture_types_details_fields;
    const o_tbl_a_capture_types_shaping_questions = this.props.DatabaseMobx.o_tbl_a_capture_types_shaping_questions;
    const o_tbl_a_capture_types_debrief_questions = this.props.DatabaseMobx.o_tbl_a_capture_types_debrief_questions;
    const c_companyCreateNewCaptureDefaultCaptureTypeID = this.props.DatabaseMobx.c_companyCreateNewCaptureDefaultCaptureTypeID;
    const c_cardNameDates = this.props.DatabaseMobx.c_cardNameDates;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;
    const c_cardNameDealShaping = this.props.DatabaseMobx.c_cardNameDealShaping;
    const c_captureTypesArrayOfObjs = this.props.DatabaseMobx.c_captureTypesArrayOfObjs;
    const c_captureTypeNamesArray = this.props.DatabaseMobx.c_captureTypeNamesArray;

    const functionDeleteCaptureTypeCleanup = (i_C_CallPhpTblUID, i_captureTypeRowObj) => {
      const captureTypeID = i_captureTypeRowObj.id;

      //remove all details (and dates) card assignments of fields for this capture type (no need for resorting as entire capture type is removed)
      const captureTypeDetailsFieldsIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(o_tbl_a_capture_types_details_fields, "capture_type_id", captureTypeID, "id");
      i_C_CallPhpTblUID.add_delete("tbl_a_capture_types_details_fields", captureTypeDetailsFieldsIDsToDeleteArray); //deletes every details field in the capture type (all groups) no need to resort

      //remove all deal shaping question assignments for this capture type
      const captureTypeShapingQuestionsIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(o_tbl_a_capture_types_shaping_questions, "capture_type_id", captureTypeID, "id");
      i_C_CallPhpTblUID.add_delete("tbl_a_capture_types_shaping_questions", captureTypeShapingQuestionsIDsToDeleteArray); //deletes every active shaping question in the capture type (all stages) no need to resort

      //remove all deal shaping debrief question assignments for this capture type
      const captureTypeDebriefQuestionsIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(o_tbl_a_capture_types_debrief_questions, "capture_type_id", captureTypeID, "id");
      i_C_CallPhpTblUID.add_delete("tbl_a_capture_types_debrief_questions", captureTypeDebriefQuestionsIDsToDeleteArray); //deletes every debrief closed shaping question in the capture type (all stages) no need to resort
    };

    const aedTableObj = {
      tblName: "tbl_a_capture_types",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: "60em",
      itemName: "Capture Type",
      deleteItemNameFieldDbName: "name",
      itemDeleteFunctionsArray: [functionDeleteCaptureTypeCleanup],
      newItemConstantFieldNamesArray: ["card_ids_comma"],
      newItemConstantValuesArray: ["1,2,3,4,5,6,7,8,9,10,11,12,13,14,15"],
      newItemConstantIdsbArray: ["s"],
      unassignedMatchingCapturesObj: c_captureTypesUnassignedMatchingCapturesObj,
      fieldsArrayOfObjs: [
        {width:"300em", dbName:"name", displayName:"Capture Type Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"prime1_sub2", displayName:"Prime/Sub", fieldTypeObj:this.props.DatabaseMobx.c_selectPrimeSubFieldTypeObj, initialValue:1, requiredNotEmptyTF:true},
        {width:"100em", displayName:"# Assigned Captures", matchingCapturesObjTF:true},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    var valueArray = [-1, 0];
    var displayArray = ["|No Capture Type Initially Selected|", "|Use First Capture Type in Admin Pool|" + ((c_captureTypeNamesArray.length > 0) ? (" (" + c_captureTypeNamesArray[0] + ")") : (""))];
    for(let captureTypeObj of c_captureTypesArrayOfObjs) {
      valueArray.push(captureTypeObj.id);
      displayArray.push(captureTypeObj.name);
    }
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Default Capture Type", valueArray, false, displayArray, swsOptionsObj);
    const selectAdminCreateNewCaptureDefaultCaptureTypeFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

    return (
      <>
        <CaptureTypeSelectionBoxes />
        <div className="flex11a yScroll yScrollBottomPad">
          <div className="displayFlexRow flexWrap">
            <div className="flex00a medFullMargin">
              <AddEditDeleteTable
                p_dataArrayOfObjs={c_adminCaptureTypesWithMatchingCapturesArrayOfObjs}
                p_aedTableObj={aedTableObj}
              />
            </div>
            <div className="flex00a medFullMargin" style={{flexBasis:"35em"}}>
              <div className="border1bbb borderRadius10 bgLightestGray medFullPad">
                <div className="displayFlexColumnHcVc microBottomMargin textCenter">
                  <font className="font11 fontBold fontTextLight">
                    {"Create New Capture: pre-selected default Capture Type"}
                  </font>
                </div>
                <div className="smallBottomMargin">
                  <font className="fontItalic fontTextLight">
                    {"When in the 'Create New Capture' process on the 'Captures' tab, this selection below determines which Capture Type (if any) is pre-selected for the User as a convenience"}
                  </font>
                </div>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={selectAdminCreateNewCaptureDefaultCaptureTypeFieldTypeObj}
                  p_valueRaw={c_companyCreateNewCaptureDefaultCaptureTypeID}
                  f_onChangeOrOnSelect={this.onselect_admin_create_new_capture_default_capture_type}
                />
              </div>
            </div>
          </div>
          <div className="lrMedMargin hugeTopMargin borderT1bbb" />
          <div className="lrMedMargin hugeTopMargin borderRadius10 bgLightesterGray medFullPad" style={{border:"solid 1px #66a", width:"38em"}}>
            <div className="smallBottomMargin textCenter">
              <font className="font12 fontBold fontBlue">
                {"Create Full Copy of Existing Capture Type"}
              </font>
            </div>
            <div className="microBottomMargin">
              <font className="fontItalic fontTextLight">
                {"Creates a new entry in the Capture Type pool above with name 'Copy of __'"}
              </font>
            </div>
            <div className="smallBottomMargin">
              <font className="fontItalic fontTextLight">
                {"Copies all settings from these Admin 'System Setup' menus:\n - Capture Cards\n - Stages\n - " + c_cardNameDetails + " Card Groups\n - " + c_cardNameDetails + "/" + c_cardNameDates + " Fields\n - " + c_cardNameDealShaping + " Questions\n - Budgets"}
              </font>
            </div>
            <div className="medBottomMargin">
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureTypeFieldTypeObj}
                p_valueRaw={selectedCaptureTypeIDToCopy}
                f_onChangeOrOnSelect={this.onselect_capture_type_id_to_copy}
              />
            </div>
            {(JSFUNC.select_int_is_filled_out_tf(selectedCaptureTypeIDToCopy)) &&
              <div className="displayFlexColumnHcVc">
                <CEGeneralReact.CEButton
                  p_type="add"
                  p_text="Copy Selected Capture Type"
                  f_onClick={this.onclick_create_new_capture_type_from_copy}
                />
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================




export const AdminSubtabCaptureCards = inject("CaptureExecMobx", "AdminMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class AdminSubtabCaptureCards extends Component {
  render() {
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeExistsTF = this.props.AdminMobx.c_selectedCaptureTypeExistsTF;
    const c_selectedCaptureTypeCaptureCardsArrayOfObjs = this.props.AdminMobx.c_selectedCaptureTypeCaptureCardsArrayOfObjs;
    const c_notSelectedCaptureTypeDetailsCardsArrayOfObjs = this.props.AdminMobx.c_notSelectedCaptureTypeDetailsCardsArrayOfObjs;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    const numCardsAdded = c_selectedCaptureTypeCaptureCardsArrayOfObjs.length;
    const numCardsNotAdded = c_notSelectedCaptureTypeDetailsCardsArrayOfObjs.length;

    return (
      <div className="flex11a displayFlexColumn">
        <CaptureTypeSelectionBoxes />
        <div className="flex11a displayFlexRow">
          <div className="flex00a yScroll yScrollBottomPad" style={{flexBasis:"32em", borderRight:"solid 1px #ccc"}}>
            <div className="smallFullMargin medFullPad bgAdminCaptureTypeTableReceiving" style={{border:"solid 1px #ddd", borderRadius:"1em"}}>
              {(c_selectedCaptureTypeExistsTF) ? (
                <>
                  <div className="smallBottomMargin">
                    <font className="font12 fontItalic">
                      {"Cards available inside captures with " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
                    </font>
                  </div>
                  {(numCardsAdded === 0) ? (
                    <div className="smallFullMargin textCenter">
                      <font className="fontItalic fontTextLight">
                        {"No capture cards have been added yet for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
                      </font>
                    </div>
                  ) : (
                    c_selectedCaptureTypeCaptureCardsArrayOfObjs.map((m_captureCardObj, m_index) =>
                      <SelectedCaptureCardItem
                        key={c_selectedCaptureTypeObj.id + "-" + m_captureCardObj.cardID}
                        p_captureCardIndex={m_index}
                        p_captureCardObj={m_captureCardObj}
                        p_isOnlyItemTF={(numCardsAdded === 1)}
                        p_isLastItemTF={(m_index === (numCardsAdded - 1))}
                      />
                    )
                  )}
                  <div className="bigTopMargin smallBottomMargin">
                    <font className="font12 fontItalic">
                      {"Cards removed from this " + c_fieldMapOfCaptureType.get("display_name")}
                    </font>
                  </div>
                  {(numCardsNotAdded === 0) ? (
                    <div className="smallFullMargin textCenter">
                      <font className="fontItalic fontTextLight">
                        {"All capture cards have been added"}
                      </font>
                    </div>
                  ) : (
                    c_notSelectedCaptureTypeDetailsCardsArrayOfObjs.map((m_captureCardObj) =>
                      <NotSelectedCaptureCardItem
                        key={m_captureCardObj.cardID}
                        p_captureCardObj={m_captureCardObj}
                      />
                    )
                  )}
                </>
              ) : (
                <div className="smallBottomMargin">
                  <font className="font12 fontItalic fontTextLight">
                    {"--Capture Cards cannot be added until at least one Capture Type is created and selected using the 'Capture Type' subtab--"}
                  </font>
                </div>
              )}
            </div>
          </div>
          <div className="flex11a displayFlexColumn">
            <div className="flex00a microTopMargin smallBottomMargin lrPad textCenter">
              <font className="font13 fontItalic">
                {"Example Selected Capture Snapshot Layout "}
              </font>
              <font className="fontItalic fontTextLighter">
                {"(users will see different numbers of cards per row based on their screen width)"}
              </font>
            </div>
            <div className="flex11a yScroll yScrollBottomPad lrMedPad">
              {(c_selectedCaptureTypeExistsTF) &&
                <div className="displayFlexRow flexWrap">
                  {c_selectedCaptureTypeCaptureCardsArrayOfObjs.map((m_captureCardObj) =>
                    <div className="positionRelative" style={{height:"243px", width:"363px"}}>
                      <LibraryReact.Image
                        p_src={c_productWebsiteGraphicsSubfolderAddress + "/cardsnapshot_" + m_captureCardObj.adminPictureFileName + ".png"}
                        p_alt={"Capture Card - " + m_captureCardObj.cardName}
                        p_styleObj={{opacity:"0.66"}}
                      />
                      {(m_captureCardObj.cardNameIsCustomTF) &&
                        <div className="positionAbsolute bgWhite" style={{top:"10px", left:"10px", width:"215px", height:"20px"}}>
                          <LibraryReact.Nowrap p_styleObj={{fontSize:"16px", color:"#9f9f9f"}}>
                            {m_captureCardObj.cardName}
                          </LibraryReact.Nowrap>
                        </div>
                      }
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}));


const SelectedCaptureCardItem = inject("AdminMobx", "DatabaseMobx")(observer(
class SelectedCaptureCardItem extends Component { //props: p_captureCardIndex, p_captureCardID, p_isOnlyItemTF, p_isLastItemTF
  onclick_remove_capture_card = () => {
    const p_captureCardObj = this.props.p_captureCardObj;

    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeCardIDsArray = this.props.AdminMobx.c_selectedCaptureTypeCardIDsArray;

    const newSelectedCaptureTypeCardIDsArray = JSFUNC.remove_all_values_from_array(p_captureCardObj.cardID, c_selectedCaptureTypeCardIDsArray);
    const newSelectedCaptureTypeCardIDsComma = JSFUNC.convert_array_to_comma_list(newSelectedCaptureTypeCardIDsArray);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - SelectedCaptureCardItem", "onclick_remove_capture_card", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types", c_selectedCaptureTypeObj.id, "card_ids_comma", newSelectedCaptureTypeCardIDsComma, "s");
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_captureCardIndex = this.props.p_captureCardIndex;
    const p_captureCardObj = this.props.p_captureCardObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const k_blueItemCollectionShellItemStylingObj = this.props.AdminMobx.k_blueItemCollectionShellItemStylingObj;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    return (
      <CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="adminCaptureCards"
        p_itemIndex={p_captureCardIndex}
        p_itemID={p_captureCardObj.cardID}
        p_itemSortColumnWidth={undefined}
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass={k_blueItemCollectionShellItemStylingObj.outerPadClass}
        p_itemClass="displayFlexRow border bevelBorderColors borderRadius05 bgAlmostWhite"
        p_itemStyleObj={{height:k_blueItemCollectionShellItemStylingObj.rowHeightEm + "em"}}
        p_lastItemExtraDropZoneHeight={k_blueItemCollectionShellItemStylingObj.lastItemExtraDropZoneHeightEm + "em"}
        p_tblName="tbl_a_capture_types"
        p_tblRowID={c_selectedCaptureTypeObj.id}
        p_tblCommaListColumnName="card_ids_comma"
        p_commaList={c_selectedCaptureTypeObj.card_ids_comma}>
        <div className="flex11a displayFlexColumnVc lrPad">
          {p_captureCardObj.cardName}
        </div>
        <CaptureCardItemRenameVerticalDots p_captureCardObj={p_captureCardObj} />
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove Capture Card '" + p_captureCardObj.cardName + "' for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
            f_onClick={this.onclick_remove_capture_card}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl>
    );
  }
}));


const NotSelectedCaptureCardItem = inject("AdminMobx", "DatabaseMobx")(observer(
class NotSelectedCaptureCardItem extends Component { //props: p_captureCardObj
  onadd_capture_card = () => {
    const p_captureCardObj = this.props.p_captureCardObj;

    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeCardIDsArray = this.props.AdminMobx.c_selectedCaptureTypeCardIDsArray;

    var newSelectedCaptureTypeCardIDsArray = JSFUNC.copy_array(c_selectedCaptureTypeCardIDsArray);
    newSelectedCaptureTypeCardIDsArray.push(p_captureCardObj.cardID);
    const newSelectedCaptureTypeCardIDsComma = JSFUNC.convert_array_to_comma_list(newSelectedCaptureTypeCardIDsArray);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabCaptureCards", "onadd_capture_card", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types", c_selectedCaptureTypeObj.id, "card_ids_comma", newSelectedCaptureTypeCardIDsComma, "s");
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_captureCardObj = this.props.p_captureCardObj;

    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    return (
      <div class="displayFlexRow microFullMargin microTopPad microBottomPad border bevelBorderColors borderRadius05 bgLighterGray" style={{height:"2.4em"}}>
        <div className="flex11a displayFlexRowVc lrPad">
          {p_captureCardObj.cardName}
        </div>
        <CaptureCardItemRenameVerticalDots p_captureCardObj={p_captureCardObj} />
        <div className="flex00a displayFlexColumnHcVc lrPad">
          <CEGeneralReact.CEButton
            p_type="add"
            p_text="Add Card"
            p_title={"Take card '" + p_captureCardObj.cardName + "' off of the capture screen for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
            f_onClick={this.onadd_capture_card}
          />
        </div>
      </div>
    );
  }
}));


const CaptureCardItemRenameVerticalDots = inject("CaptureExecMobx")(observer(
class CaptureCardItemRenameVerticalDots extends Component { //props: p_captureCardObj
  onsave_changed_custom_capture_card_name = (i_newCaptureCardNameString) => {
    const p_captureCardObj = this.props.p_captureCardObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - CaptureCardItemRenameVerticalDots", "onsave_changed_custom_capture_card_name", ["i_newCaptureCardNameString"], [i_newCaptureCardNameString]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const tblName = "tbl_a_capture_cards_custom_names";
    if(i_newCaptureCardNameString === p_captureCardObj.ceCardName) { //if the exact CE version of the card name was entered, delete the custom record to use the natural default CE card name
      C_CallPhpTblUID.add_delete(tblName, p_captureCardObj.cardID);
    }
    else { //insert/update custom name record
      const whereFieldNameOrNamesArray = ["id"];
      const whereValueOrValuesArray = [p_captureCardObj.cardID];
      const whereValueIdsbOrIdsbArray = ["i"];
      const fieldNameOrFieldNamesArray = ["name"];
      const valueOrValuesArray = [i_newCaptureCardNameString];
      const valueIdsbOrIdsbArray = ["s"];
      const oldValueOrValuesArray = undefined;
      const performUpdateIfExistsTF = true;
      C_CallPhpTblUID.add_update_existing_otherwise_insert(tblName, whereFieldNameOrNamesArray, whereValueOrValuesArray, whereValueIdsbOrIdsbArray, fieldNameOrFieldNamesArray, valueOrValuesArray, valueIdsbOrIdsbArray, oldValueOrValuesArray, performUpdateIfExistsTF);
    }

    C_CallPhpTblUID.execute();
  }

  render() {
    const p_captureCardObj = this.props.p_captureCardObj;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    const captureCardRenameMenuItemsArrayOfObjs = [
      {
        displayName: "Rename Capture Card",
        confirmType: "inputText",
        confirmTitle: "Rename Capture Card",
        confirmTextInitialValue: p_captureCardObj.cardName,
        confirmButton1Name: "Rename",
        confirmMessage: "You can change the name of this " + c_productStylingObj.productName + " '" + p_captureCardObj.ceCardName + "' Capture Card to a custom name throughout the system",
        functionOnClickConfirmButton: this.onsave_changed_custom_capture_card_name
      }
    ];

    return (
      <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.2em"}}>
        <CEGeneralReact.VerticalDotsMenu
          p_menuItemsArrayOfObjs={captureCardRenameMenuItemsArrayOfObjs}
        />
      </div>
    );
  }
}));



//=================================================================================================================================================================
//=================================================================================================================================================================


export const AdminSubtabStages = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabStages extends Component {
  ondrop_stage_pool_into_capture_type = (i_draggedStageID) => {
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeStageIDsArray = this.props.AdminMobx.c_selectedCaptureTypeStageIDsArray;

    if(!JSFUNC.in_array(i_draggedStageID, c_selectedCaptureTypeStageIDsArray)) { //must be adding a unique stageID to this capture type that is not already added
      var newSelectedCaptureTypeStageIDsArray = JSFUNC.copy_array(c_selectedCaptureTypeStageIDsArray);
      newSelectedCaptureTypeStageIDsArray.push(i_draggedStageID);
      const newSelectedCaptureTypeCardIDsComma = JSFUNC.convert_array_to_comma_list(newSelectedCaptureTypeStageIDsArray);

      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabStages", "ondrop_stage_pool_into_capture_type", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_a_capture_types", c_selectedCaptureTypeObj.id, "stage_ids_comma", newSelectedCaptureTypeCardIDsComma, "s");
      C_CallPhpTblUID.execute();
    }
  }

  onremove_all_stages_from_capture_type = () => {
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeStageIDsArray = this.props.AdminMobx.c_selectedCaptureTypeStageIDsArray;
    if(c_selectedCaptureTypeStageIDsArray.length > 0) { //if there are any stages to remove
      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabStages", "onremove_all_stages_from_capture_type", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_a_capture_types", c_selectedCaptureTypeObj.id, "stage_ids_comma", "", "s");
      C_CallPhpTblUID.execute();
    }
  }

  onswitch_restrict_advance_capture_stage = (i_newValue01) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("restrict_advance_capture_stage_01", i_newValue01, "i");
  }

  onchange_restrict_advance_capture_stage_users = (i_selectedUserPerEmailIDsComma) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("restrict_advance_capture_stage_user_per_email_ids_comma", i_selectedUserPerEmailIDsComma, "s");
  }

  render() {
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeStageIDsArray = this.props.AdminMobx.c_selectedCaptureTypeStageIDsArray;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;
    const c_companyRestrictAdvanceCaptureStage01 = this.props.DatabaseMobx.c_companyRestrictAdvanceCaptureStage01;
    const c_companyRestrictAdvanceCaptureStageTF = this.props.DatabaseMobx.c_companyRestrictAdvanceCaptureStageTF;
    const c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma = this.props.DatabaseMobx.c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma;

    const stageFieldDisplayName = c_fieldMapOfStage.get("display_name");

    const numStagesAdded = c_selectedCaptureTypeStageIDsArray.length;

    const functionDeleteStageFromPoolCleanup = (i_C_CallPhpTblUID, i_stageRowObj) => {
      const stageID = i_stageRowObj.id; //deleted stageID

      //find any assignments of this stageID on the details card (tbl_a_capture_types stage_ids_comma column) for any capture type and remove them
      for(let [captureTypeID, captureTypeMap] of this.props.DatabaseMobx.o_tbl_a_capture_types) {
        var oldStageIDsComma = captureTypeMap.get("stage_ids_comma");
        var newStageIDsComma = JSFUNC.remove_all_values_from_comma_list(stageID, oldStageIDsComma);
        if(newStageIDsComma !== oldStageIDsComma) { //if the stageID was removed, update the field with the changed comma list of remaining fieldIDs
          i_C_CallPhpTblUID.add_update("tbl_a_capture_types", captureTypeID, "stage_ids_comma", newStageIDsComma, "s");
        }
      }
    }

    const aedTableObj = {
      tblName: "tbl_a_stages_pool",
      dragToResortFieldDbName: "admin_sort",
      canAlwaysDragResortTF: true,
      initialSortedFieldDbName: "admin_sort",
      tableWidth: "100%",
      itemName: "Stage",
      deleteItemNameFieldDbName: "name",
      idsAlreadyAddedArray: c_selectedCaptureTypeStageIDsArray,
      itemDeleteFunctionsArray: [functionDeleteStageFromPoolCleanup],
      fieldsArrayOfObjs: [
        {width:"300em", dbName:"name", displayName:"Stage Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"300em", dbName:"pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7", displayName:"Stage Type", fieldTypeObj:this.props.DatabaseMobx.c_selectStageType1234567FieldTypeObj, initialValue:1, cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"color", displayName:"DivExec Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"999999", cellHoverTF:true},
        {width:"100em", dbName:"hover_text", displayName:"Hover Text", fieldTypeObj:this.props.DatabaseMobx.c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF:true},
        {width:"3em", dbName:"admin_sort", displayName:"Admin Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    return(
      <div className="flex11a displayFlexColumn">
        <CaptureTypeSelectionBoxes />
        {(stageFieldDisplayName !== "Stage") &&
          <>
            <div className="smallTopMargin textCenter">
              <font className="font12 fontBold fontBlue">
                {"Stage is referred to as '" + stageFieldDisplayName + "' in this system"}
              </font>
            </div>
            <div className="textCenter">
              <font className="font09 fontTextLightester">
                {"This can be edited on the System Setup 'Details/Dates Fields' subtab"}
              </font>
            </div>
          </>
        }
        <div className="flex11a displayFlexRow">
          <div className="flex11a yScroll medFullPad" style={{flexBasis:"130em"}}>
            <div className="smallBottomMargin">
              <font className="font14 fontItalic">
                {"Stages Pool: All unique Stages used in every " + c_fieldMapOfCaptureType.get("display_name")}
              </font>
            </div>
            <AddEditDeleteTable
              p_dataArrayOfObjs={this.props.AdminMobx.c_stagesPoolArrayOfObjs}
              p_aedTableObj={aedTableObj}
              f_onClickAddItem={this.ondrop_stage_pool_into_capture_type}
            />
            <div className="tbHugeMargin borderT1bbb" />
            <div className="lrMedMargin border1bbb bgLightestGray bigFullPad" style={{maxWidth:"50em"}}>
              <div className="smallBottomMargin textCenter">
                <font className="font11 fontBold fontTextLight">
                  {"Use this switch to restrict the ability to advance the Stage of a Capture to select Users"}
                </font>
              </div>
              <div className="lrMedMargin">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("NAICS Inclusion Option", [0, 1], false, ["[default] Anyone that can access a particular Capture can advance its Stage", "Only selected Users below can advance Capture Stages"], 2)}
                  p_valueRaw={c_companyRestrictAdvanceCaptureStage01}
                  f_onChangeOrOnSelect={this.onswitch_restrict_advance_capture_stage}
                />
              </div>
              {(c_companyRestrictAdvanceCaptureStageTF) &&
                <div className="bigTopMargin border1bbb borderRadius05 bgLightesterGray medFullPad">
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString="adminStagesRestrictUsers"
                    p_fieldDisplayName="Only Users who can advance Stage"
                    p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiUsersPerEmailNotDeactivatedFieldTypeObj}
                    p_valueRaw={c_companyRestrictAdvanceCaptureStageUserPerEmailIDsComma}
                    p_valueIsEditableTFU={true}
                    p_containerClass="tbPad"
                    p_fieldClass="fontBlue"
                    p_fieldWidth="40%"
                    f_onSaveChanged={this.onchange_restrict_advance_capture_stage_users}
                  />
                </div>
              }
            </div>
            <div className="tbHugeMargin" />
          </div>
          <div className="flex11a xyScroll medFullPad" style={{flexBasis:"100em"}}>
            <BlueItemCollectionShell
              p_isReceivingTF={true}
              p_title={"Stage Timeline for captures with " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
              p_dropUniqueString="AddEditDeleteTableRow_tbl_a_stages_pool"
              p_dropNotAllowedDraggerIDsArray={c_selectedCaptureTypeStageIDsArray}
              p_dropMessage="[Drag/drop stages from the pool]"
              p_dropAllowDropMessage="[Drop stage to add to Stage Timeline]"
              p_dropNotAllowDropMessage="[Cannot have duplicate stages in a Stage Timeline]"
              f_onClickSetAsReceiving={undefined}
              f_onDrop={this.ondrop_stage_pool_into_capture_type}
              f_onRemoveAllItems={this.onremove_all_stages_from_capture_type}>
              {c_selectedCaptureTypeStageIDsArray.map((m_stageID, m_index) =>
                <AddedStageItem
                  key={c_selectedCaptureTypeObj.id + "_" + m_stageID}
                  p_selectedCaptureTypeObj={c_selectedCaptureTypeObj}
                  p_stageIndex={m_index}
                  p_stageID={m_stageID}
                  p_isOnlyItemTF={(numStagesAdded === 1)}
                  p_isLastItemTF={(m_index === (numStagesAdded - 1))}
                />
              )}
            </BlueItemCollectionShell>
          </div>
        </div>
      </div>
    );
  }
}));


export const AddedStageItem = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AddedStageItem extends Component { //props: p_selectedCaptureTypeObj, p_stageIndex, p_stageID, p_isOnlyItemTF, p_isLastItemTF
  onclick_remove_stage_from_capture_type = () => {
    const p_stageID = this.props.p_stageID; //removed stageID
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const selectedCaptureTypeStageIDsArray = this.props.AdminMobx.c_selectedCaptureTypeStageIDsArray;

    const newSelectedCaptureTypeStageIDsArray = JSFUNC.remove_all_values_from_array(p_stageID, selectedCaptureTypeStageIDsArray);
    const newSelectedCaptureTypeStageIDsComma = JSFUNC.convert_array_to_comma_list(newSelectedCaptureTypeStageIDsArray);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AddedStageItem", "onclick_remove_stage_from_capture_type", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types", c_selectedCaptureTypeObj.id, "stage_ids_comma", newSelectedCaptureTypeStageIDsComma, "s");
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_selectedCaptureTypeObj = this.props.p_selectedCaptureTypeObj;
    const p_stageIndex = this.props.p_stageIndex;
    const p_stageID = this.props.p_stageID;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const k_blueItemCollectionShellItemStylingObj = this.props.AdminMobx.k_blueItemCollectionShellItemStylingObj;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    const stageMap = this.props.DatabaseMobx.tbl_row_map_from_id("tbl_a_stages_pool", p_stageID);

    const stageName = stageMap.get("name");
    const stageColor = stageMap.get("color");
    const stageType1234567 = stageMap.get("pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7");
    const stageTypeName = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(stageType1234567, this.props.DatabaseMobx.c_selectStageType1234567FieldTypeObj);

    var stageTypeColumnFlexClass = "flex00a";
    var stageTypeColumnWidthEm = 24;
    if(o_mediaQueryFlag <= 4) {
      stageTypeColumnFlexClass = "flex11a";
      stageTypeColumnWidthEm = 100;
    }

    return(
      <CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="captureTypeStage"
        p_itemIndex={p_stageIndex}
        p_itemID={p_stageID}
        p_itemSortColumnWidth="2em"
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass={k_blueItemCollectionShellItemStylingObj.outerPadClass}
        p_itemClass="displayFlexRow border bevelBorderColors borderRadius05 bgAlmostWhite"
        p_itemStyleObj={{height:k_blueItemCollectionShellItemStylingObj.rowHeightEm + "em"}}
        p_lastItemExtraDropZoneHeight={k_blueItemCollectionShellItemStylingObj.lastItemExtraDropZoneHeightEm + "em"}
        p_tblName="tbl_a_capture_types"
        p_tblRowID={p_selectedCaptureTypeObj.id}
        p_tblCommaListColumnName="stage_ids_comma"
        p_commaList={p_selectedCaptureTypeObj.stage_ids_comma}>
        <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}} title={stageName}>
          <LibraryReact.Nowrap p_fontClass="">
            {stageName}
          </LibraryReact.Nowrap>
        </div>
        <div className={stageTypeColumnFlexClass + " displayFlexRowVc lrPad"} style={{flexBasis:stageTypeColumnWidthEm + "em"}} title={stageTypeName}>
          <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
            {stageTypeName}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"4em"}}>
          <div className="border bevelBorderColors" style={{width:"3em", height:"0.9em", background:"#" + stageColor}} />
        </div>
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove Stage '" + stageName + "' from stage timeline for " + c_fieldMapOfCaptureType.get("display_name") + " '" + p_selectedCaptureTypeObj.name + "'"}
            f_onClick={this.onclick_remove_stage_from_capture_type}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabDetailsCardGroups = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabDetailsCardGroups extends Component {
  ondrop_details_card_group_into_capture_type = (i_draggedDetailsCardGroupID) => {
    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;
    const c_selectedCaptureTypeDetailsCardGroupIDsArray = this.props.AdminMobx.c_selectedCaptureTypeDetailsCardGroupIDsArray;

    if(!JSFUNC.in_array(i_draggedDetailsCardGroupID, c_selectedCaptureTypeDetailsCardGroupIDsArray)) { //must be adding a unique details card group to this capture type that is not already added
      var newSelectedCaptureTypeDetailsCardGroupIDsArray = JSFUNC.copy_array(c_selectedCaptureTypeDetailsCardGroupIDsArray);
      newSelectedCaptureTypeDetailsCardGroupIDsArray.push(i_draggedDetailsCardGroupID);
      const newSelectedCaptureTypeDetailsCardGroupIDsComma = JSFUNC.convert_array_to_comma_list(newSelectedCaptureTypeDetailsCardGroupIDsArray);

      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabDetailsCardGroups", "ondrop_details_card_group_into_capture_type", ["i_draggedDetailsCardGroupID"], [i_draggedDetailsCardGroupID]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_a_capture_types", o_selectedCaptureTypeID, "details_group_ids_comma", newSelectedCaptureTypeDetailsCardGroupIDsComma, "s");
      C_CallPhpTblUID.execute();
    }
  }

  onremove_all_details_card_groups_from_capture_type = () => {
    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;
    const c_selectedCaptureTypeDetailsCardGroupIDsArray = this.props.AdminMobx.c_selectedCaptureTypeDetailsCardGroupIDsArray;
    if(c_selectedCaptureTypeDetailsCardGroupIDsArray.length > 0) {
      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabDetailsCardGroups", "onremove_all_details_card_groups_from_capture_type", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_a_capture_types", o_selectedCaptureTypeID, "details_group_ids_comma", "", "s");
      C_CallPhpTblUID.execute();
    }
  }

  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeExistsTF = this.props.AdminMobx.c_selectedCaptureTypeExistsTF;
    const c_selectedCaptureTypeDetailsCardGroupIDsArray = this.props.AdminMobx.c_selectedCaptureTypeDetailsCardGroupIDsArray;
    const c_selectedCaptureTypeDetailsCardGroupsArrayOfObjs = this.props.AdminMobx.c_selectedCaptureTypeDetailsCardGroupsArrayOfObjs;
    const o_tbl_a_capture_types = this.props.DatabaseMobx.o_tbl_a_capture_types;
    const o_tbl_a_capture_types_details_fields = this.props.DatabaseMobx.o_tbl_a_capture_types_details_fields;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    const numDetailsCardGroupsAdded = c_selectedCaptureTypeDetailsCardGroupIDsArray.length;

    const functionDeleteDetailsGroupFromPoolCleanup = (i_C_CallPhpTblUID, i_detailsGroupRowObj) => {
      const groupID = i_detailsGroupRowObj.id; //deleted groupID

      //remove all details fields assigned to this group in any capture type
      const captureTypeDetailsFieldsIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(o_tbl_a_capture_types_details_fields, "group_id", groupID, "id");
      i_C_CallPhpTblUID.add_delete("tbl_a_capture_types_details_fields", captureTypeDetailsFieldsIDsToDeleteArray); //no resorting needed since all fields from the entire group will be removed

      //find any assignments of this groupID on the details card (tbl_a_capture_types details_group_ids_comma column) for any capture type and remove them
      for(let [captureTypeID, captureTypeMap] of o_tbl_a_capture_types) {
        var oldDetailsGroupIDsComma = captureTypeMap.get("details_group_ids_comma");
        var newDetailsGroupIDsComma = JSFUNC.remove_all_values_from_comma_list(groupID, oldDetailsGroupIDsComma);
        if(newDetailsGroupIDsComma !== oldDetailsGroupIDsComma) { //if the groupID was removed, update the field with the changed comma list of remaining fieldIDs
          i_C_CallPhpTblUID.add_update("tbl_a_capture_types", captureTypeID, "details_group_ids_comma", newDetailsGroupIDsComma, "s");
        }
      }
    }

    const aedTableObj = {
      tblName: "tbl_a_details_groups_pool",
      dragToResortFieldDbName: "admin_sort",
      canAlwaysDragResortTF: true,
      initialSortedFieldDbName: "admin_sort",
      tableWidth: "100%",
      itemName: c_cardNameDetails + " Card Group",
      deleteItemNameFieldDbName: "name",
      idsAlreadyAddedArray: c_selectedCaptureTypeDetailsCardGroupIDsArray,
      itemDeleteFunctionsArray: [functionDeleteDetailsGroupFromPoolCleanup],
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"name", displayName:"Group Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"5em", fixedWidthTF:true, dbName:"group_name_styling_string_comma", displayName:"Style", fieldTypeObj:this.props.DatabaseMobx.c_genericStylingStringFontDarkGrayBoldFieldTypeObj, initialValue:"", cellCenterTF:true, cellHoverTF:true, cellDisplayBlankIfNotFilledOutTF:true},
        {width:"3em", dbName:"admin_sort", displayName:"Admin Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    const includeExampleDetailsCardLayoutTF = (o_mediaQueryFlag >= 4);
    const detailsNumGroupsPerRow = ((o_mediaQueryFlag >= 5) ? (3) : (2));
    const groupIDsRCMatrix = JSFUNC.get_rc_matrix_from_num_items_and_columns(numDetailsCardGroupsAdded, detailsNumGroupsPerRow);

    return(
      <div className="flex11a displayFlexColumn">
        <CaptureTypeSelectionBoxes />
        <div className="flex11a displayFlexRow">
          <div className="flex11a yScroll medFullPad" style={{flexBasis:"100em"}}>
            <div className="smallBottomMargin">
              <font className="font14 fontItalic">
                {"All unique " + c_cardNameDetails + " Card Groups used in every " + c_fieldMapOfCaptureType.get("display_name")}
              </font>
            </div>
            <AddEditDeleteTable
              p_dataArrayOfObjs={this.props.AdminMobx.c_detailsCardGroupsPoolArrayOfObjs}
              p_aedTableObj={aedTableObj}
              f_onClickAddItem={this.ondrop_details_card_group_into_capture_type}
            />
          </div>
          <div className="flex11a displayFlexRow" style={{flexBasis:((includeExampleDetailsCardLayoutTF) ? ("200em") : ("100em"))}}>
            <div className="flex11a yScroll yScrollBottomPad lrPad" style={{flexBasis:"100em"}}>
              <BlueItemCollectionShell
                p_isReceivingTF={true}
                p_title={c_cardNameDetails + " Card Groups for captures with " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
                p_dropUniqueString="AddEditDeleteTableRow_tbl_a_details_groups_pool"
                p_dropNotAllowedDraggerIDsArray={c_selectedCaptureTypeDetailsCardGroupIDsArray}
                p_dropMessage="[Drag/drop groups from the pool]"
                p_dropAllowDropMessage={"[Drop group to add to " + c_cardNameDetails + " Card]"}
                p_dropNotAllowDropMessage={"[Cannot have duplicate groups on the " + c_cardNameDetails + " Card]"}
                f_onClickSetAsReceiving={undefined}
                f_onDrop={this.ondrop_details_card_group_into_capture_type}
                f_onRemoveAllItems={this.onremove_all_details_card_groups_from_capture_type}>
                {c_selectedCaptureTypeDetailsCardGroupsArrayOfObjs.map((m_detailsCardGroupObj, m_index) =>
                  <AddedDetailsCardGroupItem
                    key={c_selectedCaptureTypeObj.id + "-" + m_detailsCardGroupObj.id}
                    p_detailsCardGroupObj={m_detailsCardGroupObj}
                    p_detailsCardGroupIndex={m_index}
                    p_isOnlyItemTF={(numDetailsCardGroupsAdded === 1)}
                    p_isLastItemTF={(m_index === (numDetailsCardGroupsAdded - 1))}
                  />
                )}
              </BlueItemCollectionShell>
            </div>
            {(includeExampleDetailsCardLayoutTF) &&
              <div className="flex11a displayFlexColumn yScroll" style={{flexBasis:"150em", borderLeft:"solid 1px #ccc"}}>
                <div className="flex00a smallFullMargin">
                  <div>
                    <font className="font14 fontItalic fontTextLighter">
                      {"Example " + c_cardNameDetails + " Card Layout"}
                    </font>
                  </div>
                  <div>
                    <font className="fontItalic fontTextLighter">
                      {"Note that users will see a different number of " + c_cardNameDetails + " Groups per row inside the details card based on their screen width"}
                    </font>
                  </div>
                </div>
                <div className="flex11a" style={{marginBottom:"5em"}}>
                  <OpenCaptureReact.CardShell
                    p_label={c_cardNameDetails}
                    p_drawLeftRightButtonsTF={true}>
                    {(c_selectedCaptureTypeExistsTF) &&
                      <div className="flex11a displayFlexColumn bgLightesterGray">
                        {groupIDsRCMatrix.map((m_groupIDsRowArray, m_rowIndex) =>
                          <div key={JSFUNC.rc_unique_row_key(detailsNumGroupsPerRow, m_rowIndex)} className="flex00a displayFlexRow">
                            {m_groupIDsRowArray.map((m_groupID) =>
                              <OpenCaptureReact.DetailsGroupShell
                                key={m_groupID}
                                p_groupName={((m_groupID >= 0) ? (c_selectedCaptureTypeDetailsCardGroupsArrayOfObjs[m_groupID].name) : (undefined))}
                                p_groupNameStylingStringComma={((m_groupID >= 0) ? (c_selectedCaptureTypeDetailsCardGroupsArrayOfObjs[m_groupID].group_name_styling_string_comma) : (undefined))}>
                                <ExampleFieldValue />
                                <ExampleFieldValue />
                                <ExampleFieldValue />
                              </OpenCaptureReact.DetailsGroupShell>
                            )}
                          </div>
                        )}
                      </div>
                    }
                  </OpenCaptureReact.CardShell>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}));

function ExampleFieldValue(props) {
  return(
    <div className="displayFlexRow">
      <div className="flex11a" style={{flexBasis:"100em"}}>
        <font className="fontBlueLighter">
          {"Example Field"}
        </font>
      </div>
      <div className="flex11a" style={{flexBasis:"100em"}}>
        <font className="fontTextLightester">
          {"Example Value"}
        </font>
      </div>
    </div>
  );
}


export const AddedDetailsCardGroupItem = inject("AdminMobx", "DatabaseMobx")(observer(
class AddedDetailsCardGroupItem extends Component { //props: p_detailsCardGroupObj, p_detailsCardGroupIndex, p_isOnlyItemTF, p_isLastItemTF
  onclick_remove_details_card_group_from_capture_type = () => {
    const removedDetailsCardGroupID = this.props.p_detailsCardGroupObj.id;

    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const selectedCaptureTypeDetailsCardGroupIDsArray = this.props.AdminMobx.c_selectedCaptureTypeDetailsCardGroupIDsArray;

    const newSelectedCaptureTypeDetailsCardGroupIDsArray = JSFUNC.remove_all_values_from_array(removedDetailsCardGroupID, selectedCaptureTypeDetailsCardGroupIDsArray);
    const newSelectedCaptureTypeDetailsCardGroupIDsComma = JSFUNC.convert_array_to_comma_list(newSelectedCaptureTypeDetailsCardGroupIDsArray);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AddedDetailsCardGroupItem", "onclick_remove_details_card_group_from_capture_type", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types", c_selectedCaptureTypeObj.id, "details_group_ids_comma", newSelectedCaptureTypeDetailsCardGroupIDsComma, "s");
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_detailsCardGroupObj = this.props.p_detailsCardGroupObj;
    const p_detailsCardGroupIndex = this.props.p_detailsCardGroupIndex;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const k_blueItemCollectionShellItemStylingObj = this.props.AdminMobx.k_blueItemCollectionShellItemStylingObj;
    const c_fieldsAddedToDetailsCardGroupsObj = this.props.AdminMobx.c_fieldsAddedToDetailsCardGroupsObj;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    var captureTypeDetailsFieldsDisplayNamesListString = "";
    for(let fieldsPerGroupObj of c_fieldsAddedToDetailsCardGroupsObj.fieldsPerGroupArrayOfObjs) {
      if(fieldsPerGroupObj.detailsCardGroupObj.id === p_detailsCardGroupObj.id) {
        for(let expandedCaptureTypeDetailsFieldObj of fieldsPerGroupObj.expandedCaptureTypeDetailsFieldsArrayOfObjs) {
          captureTypeDetailsFieldsDisplayNamesListString += "\n - " + expandedCaptureTypeDetailsFieldObj.fieldDisplayName;
        }
      }
    }

    if(captureTypeDetailsFieldsDisplayNamesListString === "") {
      captureTypeDetailsFieldsDisplayNamesListString = "\n--0 Fields are added to this Group--";
    }
    else {
      captureTypeDetailsFieldsDisplayNamesListString = "\nFields added to this Group:" + captureTypeDetailsFieldsDisplayNamesListString;
    }

    return(
      <CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="captureTypeDetailsCardGroup"
        p_itemIndex={p_detailsCardGroupIndex}
        p_itemID={p_detailsCardGroupObj.id}
        p_itemSortColumnWidth="2em"
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass={k_blueItemCollectionShellItemStylingObj.outerPadClass}
        p_itemClass="displayFlexRow border bevelBorderColors borderRadius05 bgAlmostWhite"
        p_itemStyleObj={{height:k_blueItemCollectionShellItemStylingObj.rowHeightEm + "em"}}
        p_lastItemExtraDropZoneHeight={k_blueItemCollectionShellItemStylingObj.lastItemExtraDropZoneHeightEm + "em"}
        p_tblName="tbl_a_capture_types"
        p_tblRowID={c_selectedCaptureTypeObj.id}
        p_tblCommaListColumnName="details_group_ids_comma"
        p_commaList={c_selectedCaptureTypeObj.details_group_ids_comma}>
        <div className="flex11a displayFlexRowVc lrPad">
          {p_detailsCardGroupObj.name}
        </div>
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          <CEGeneralReact.DeleteMenu
            p_message={"Are you sure you want to remove group '" + p_detailsCardGroupObj.name + "' from " + c_cardNameDetails + " Card for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'?\n" + captureTypeDetailsFieldsDisplayNamesListString}
            p_menuItemText="Remove Group"
            f_onDelete={this.onclick_remove_details_card_group_from_capture_type}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabDetailsDatesFields = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabDetailsDatesFields extends Component {
  componentDidMount() {
    this.props.AdminMobx.a_fields_initialize_selected_receiving_details_card_group();
  }

  onchange_fields_search_filter_text = (i_newValue) => {
    this.props.AdminMobx.a_fields_set_search_filter_text(i_newValue);
  }

  onclick_add_capture_field_to_receiving_details_group = (i_fieldID) => { //click the add button in the fields pool, add this field to the receiving details card group or dates card
    const o_fieldsSelectedTabSnapshotGroupsDates = this.props.AdminMobx.o_fieldsSelectedTabSnapshotGroupsDates;
    const fieldsSelectedReceivingDetailsCardGroupID = this.props.AdminMobx.o_fieldsSelectedReceivingDetailsCardGroupID;
    if(o_fieldsSelectedTabSnapshotGroupsDates === "detailsCardSnapshot") {
      this.props.AdminMobx.a_fields_add_field_to_receiving_details_snapshot(i_fieldID);
    }
    else if(o_fieldsSelectedTabSnapshotGroupsDates === "detailsCardGroups") {
      this.props.AdminMobx.a_fields_add_field_to_receiving_details_card_group(fieldsSelectedReceivingDetailsCardGroupID, i_fieldID);
    }
    else if(o_fieldsSelectedTabSnapshotGroupsDates === "datesCard") {
      this.props.AdminMobx.a_fields_add_field_to_receiving_details_card_group(0, i_fieldID);
    }
    else if(o_fieldsSelectedTabSnapshotGroupsDates === "revenueCard") {
      this.props.AdminMobx.a_fields_add_field_to_receiving_details_card_group(-1, i_fieldID);
    }
  }

  onclick_add_blank_space_to_receiving_details_group = () => {
    this.onclick_add_capture_field_to_receiving_details_group(-10);
  }

  onclick_add_line_break_to_receiving_details_group = () => {
    this.onclick_add_capture_field_to_receiving_details_group(-11);
  }

  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const o_fieldsSelectedTabSnapshotGroupsDates = this.props.AdminMobx.o_fieldsSelectedTabSnapshotGroupsDates;
    const c_fieldsAddedToDetailsSnapshotObj = this.props.AdminMobx.c_fieldsAddedToDetailsSnapshotObj;
    const c_fieldsAddedToDetailsCardGroupsObj = this.props.AdminMobx.c_fieldsAddedToDetailsCardGroupsObj;
    const c_fieldsAddedToDatesCardObj = this.props.AdminMobx.c_fieldsAddedToDatesCardObj;
    const c_fieldsAddedToRevenueCardObj = this.props.AdminMobx.c_fieldsAddedToRevenueCardObj;
    const o_tbl_a_capture_types_details_fields = this.props.DatabaseMobx.o_tbl_a_capture_types_details_fields;
    const c_cardNameAdvanceStage = this.props.DatabaseMobx.c_cardNameAdvanceStage;
    const c_cardNameDates = this.props.DatabaseMobx.c_cardNameDates;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;
    const c_cardNameDealShaping = this.props.DatabaseMobx.c_cardNameDealShaping;
    const c_cardNameRevenue = this.props.DatabaseMobx.c_cardNameRevenue;

    var receivingTitle = undefined;
    var fieldIDsAlreadyAddedArray = undefined;
    var receivingGroupsComponent = null;
    var exampleSnapshotCardComponent = null;
    if(o_fieldsSelectedTabSnapshotGroupsDates === "detailsCardGroups") {
      receivingTitle = "Capture Fields in Groups on " + c_cardNameDetails + " Card";
      fieldIDsAlreadyAddedArray = c_fieldsAddedToDetailsCardGroupsObj.fieldIDsAlreadyAddedArray;
      receivingGroupsComponent = (
        c_fieldsAddedToDetailsCardGroupsObj.fieldsPerGroupArrayOfObjs.map((m_fieldsPerGroupObj) =>
          <DetailsCardGroupOfAddedCaptureFields
            key={m_fieldsPerGroupObj.detailsCardGroupObj.id}
            p_fieldsPerGroupObj={m_fieldsPerGroupObj}
            p_fieldIDsAlreadyAddedArray={fieldIDsAlreadyAddedArray}
            p_fieldsSelectedReceivingDetailsCardGroupID={this.props.AdminMobx.o_fieldsSelectedReceivingDetailsCardGroupID}
            p_canResortTF={true}
          />
        )
      );
    }
    else if(o_fieldsSelectedTabSnapshotGroupsDates === "detailsCardSnapshot") {
      receivingTitle = "Capture Fields in " + c_cardNameDetails + " Card Snapshot on Capture Summary";
      fieldIDsAlreadyAddedArray = c_fieldsAddedToDetailsSnapshotObj.fieldIDsAlreadyAddedArray;
      receivingGroupsComponent = (<DetailsSnapshotOfAddedCaptureFields />);
      exampleSnapshotCardComponent = (
        <>
          <div className="medTopMargin">
            <font className="font13 fontItalic fontTextLighter">
              {"Example " + c_cardNameDetails + " Card Snapshot"}
            </font>
          </div>
          <div className="displayFlexColumnHcVc">
            <div className="flex00a displayFlexColumn" style={{flexBasis:"22em", width:"30em"}}>
              <OpenCaptureReact.CardShell p_label={c_cardNameDetails}>
                <div className="flex11a overflowHidden smallFullMargin">
                  {c_fieldsAddedToDetailsSnapshotObj.snapshotExampleCaptureFieldValuesArrayOfObjs.map((m_snapshotExampleCaptureFieldValueObj) =>
                    <OpenCaptureReact.DetailsItem
                      p_captureID={-1}
                      p_cardID={this.props.DatabaseMobx.k_cardIDDetails}
                      p_captureFieldValueObj={m_snapshotExampleCaptureFieldValueObj}
                      p_isSnapshotTF={true}
                      p_fieldWidth="11em"
                    />
                  )}
                </div>
              </OpenCaptureReact.CardShell>
            </div>
          </div>
        </>
      );
    }
    else if(o_fieldsSelectedTabSnapshotGroupsDates === "datesCard") {
      receivingTitle = "Date Fields on " + c_cardNameDates + " Card";
      fieldIDsAlreadyAddedArray = c_fieldsAddedToDatesCardObj.fieldIDsAlreadyAddedArray;
      receivingGroupsComponent = (
        <DetailsCardGroupOfAddedCaptureFields
          p_fieldsPerGroupObj={c_fieldsAddedToDatesCardObj}
          p_fieldIDsAlreadyAddedArray={fieldIDsAlreadyAddedArray}
          p_fieldsSelectedReceivingDetailsCardGroupID={0}
          p_canResortTF={true}
        />
      );
    }
    else if(o_fieldsSelectedTabSnapshotGroupsDates === "revenueCard") {
      receivingTitle = "Money Fields as Costs on " + c_cardNameRevenue + " Card";
      fieldIDsAlreadyAddedArray = c_fieldsAddedToRevenueCardObj.fieldIDsAlreadyAddedArray;
      receivingGroupsComponent = (
        <DetailsCardGroupOfAddedCaptureFields
          p_fieldsPerGroupObj={c_fieldsAddedToRevenueCardObj}
          p_fieldIDsAlreadyAddedArray={fieldIDsAlreadyAddedArray}
          p_fieldsSelectedReceivingDetailsCardGroupID={-1}
          p_canResortTF={true}
        />
      );
    }

    const fieldsDataArrayOfArrayOfObjs = [
      this.props.AdminMobx.c_filteredFieldsOnDetailsCardCustomArrayOfObjs,
      this.props.AdminMobx.c_filteredFieldsOnDetailsCardMandatoryArrayOfObjs,
      this.props.AdminMobx.c_filteredFieldsOnDetailsCardGcssArrayOfObjs,
      this.props.AdminMobx.c_filteredFieldsOnNotepadCardArrayOfObjs,
      this.props.AdminMobx.c_filteredFieldsOnAdvanceStageCardArrayOfObjs,
      this.props.AdminMobx.c_filteredFieldsOnDealShapingCardArrayOfObjs,
      this.props.AdminMobx.c_filteredFieldsOnTeammatesCardArrayOfObjs,
      this.props.AdminMobx.c_filteredFieldsOnCompetitorsCardArrayOfObjs
    ];

    const canAddNewItemsTFArray = [true, false, false, false, false, false, false, false];
    const canDeleteItemsTFArray = [true, false, false, false, false, false, false, false];
    const dragToResortFieldDbNameArray = ["admin_sort", "admin_sort", "admin_sort", undefined, undefined, undefined, undefined, undefined];
    const maskResortFieldDbNameArray = ["fakeSort", "fakeSort", "fakeSort", undefined, undefined, undefined, undefined, undefined];
    const initialSortedFieldDbNameArray = ["admin_sort", "admin_sort", "admin_sort", "display_name", "display_name", "display_name", "display_name", "display_name"];
    const idsAlreadyAddedArrayArray = [fieldIDsAlreadyAddedArray, fieldIDsAlreadyAddedArray, fieldIDsAlreadyAddedArray, fieldIDsAlreadyAddedArray, undefined, undefined, undefined, undefined];

    const functionDeleteTblCapturesColumnAndTblCapTable = (i_C_CallPhpTblUID, i_captureFieldRowObj) => {
      //remove the data column from tbl_captures for this deleted field
      i_C_CallPhpTblUID.add_alter_db_tbl_delete_column("tbl_captures", i_captureFieldRowObj.db_name);

      //check if this field has a tbl_cap_ select table associated with it, if so, check all the fields to see if any other fields use that table, if this field is the only user, delete the tbl_cap table completely
      if(JSFUNC.text_or_number_is_filled_out_tf(i_captureFieldRowObj.select_tbl_name)) {
        if(this.props.DatabaseMobx.tbl_name_is_tbl_cap(i_captureFieldRowObj.select_tbl_name)) { //ensure the tblName being deleted is a tbl_cap tbl, not an admin tbl
          var deleteTblCapTableTF = true;
          for(let fieldMap of this.props.DatabaseMobx.o_tbl_captures_fields.values()) {
            if(fieldMap.get("id") !== i_captureFieldRowObj.id) { //check all other fields other than this one
              if(fieldMap.get("select_tbl_name") === i_captureFieldRowObj.select_tbl_name) { //if any other field is using this tbl_cap table data, do not delete it
                deleteTblCapTableTF = false;
              }
            }
          }

          if(deleteTblCapTableTF) {
            i_C_CallPhpTblUID.add_db_delete_tbl_cap_table(i_captureFieldRowObj.select_tbl_name);
          }
        }
      }

      //find any assignments of this fieldID on details groups or dates card (tbl_a_capture_types_details_fields) for any capture type and remove them
      for(let [captureTypeDetailsFieldRecordID, captureTypeDetailsFieldRecordMap] of o_tbl_a_capture_types_details_fields) { //must have individual deletes within a loop so that post delete resorting among the different capture_type_id/group_id combinations can properly happen
        if(captureTypeDetailsFieldRecordMap.get("field_id") === i_captureFieldRowObj.id) { //delete this record if the field_id matches the fieldID of the details field that was just deleted (this table covers all capture types and both the details card and dates card (group 0))
          var resortSortColumnName = "sort";
          var resortFilterFieldNameOrFieldNamesArray = ["capture_type_id", "group_id"];
          var resortFilterValueOrValuesArray = [captureTypeDetailsFieldRecordMap.get("capture_type_id"), captureTypeDetailsFieldRecordMap.get("group_id")];
          i_C_CallPhpTblUID.add_delete("tbl_a_capture_types_details_fields", captureTypeDetailsFieldRecordID, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
        }
      }

      //find any assignments of this fieldID on the details snapshot card (tbl_a_capture_types details_snapshot_field_ids_comma column) for any capture type and remove them
      for(let [captureTypeID, captureTypeMap] of this.props.DatabaseMobx.o_tbl_a_capture_types) {
        var oldDetailsSnapshotFieldIDsComma = captureTypeMap.get("details_snapshot_field_ids_comma");
        var newDetailsSnapshotFieldIDsComma = JSFUNC.remove_all_values_from_comma_list(i_captureFieldRowObj.id, oldDetailsSnapshotFieldIDsComma);
        if(newDetailsSnapshotFieldIDsComma !== oldDetailsSnapshotFieldIDsComma) { //if the fieldID was removed, update the field with the changed comma list of remaining fieldIDs
          i_C_CallPhpTblUID.add_update("tbl_a_capture_types", captureTypeID, "details_snapshot_field_ids_comma", newDetailsSnapshotFieldIDsComma, "s");
        }
      }
    };
    const itemDeleteFunctionsArrayArray = [[functionDeleteTblCapturesColumnAndTblCapTable], undefined, undefined, undefined, undefined, undefined, undefined, undefined];

    const keysArray = ["dc", "dm", "dg", "np", "as", "ds", "tm", "cp"];

    const tableTitlesArray = [
      "User Capture Fields",
      "System Capture Fields",
      "GovCon Smart Search Import Fields",
      "'Notepad' Card",
      "'" + c_cardNameAdvanceStage + "' Card (cannot be added to the " + c_cardNameDetails + "/" + c_cardNameDates + " cards)",
      "'" + c_cardNameDealShaping + "' Card (cannot be added to the " + c_cardNameDetails + "/" + c_cardNameDates + " card)",
      "'Teammates' Card (cannot be added to the " + c_cardNameDetails + "/" + c_cardNameDates + " card)",
      "'Competitors' Card (cannot be added to the " + c_cardNameDetails + "/" + c_cardNameDates + " card)"
    ];

    var numOptionsColumnWidth = "7em";
    var hoverTextColumnWidth = "10em";
    var styleColumnWidth = "5em";
    if(o_mediaQueryFlag <= 2) {
      numOptionsColumnWidth = "3em";
      hoverTextColumnWidth = "3em";
      styleColumnWidth = "3em";
    }
    else if(o_mediaQueryFlag <= 3) {
      numOptionsColumnWidth = "4em";
      hoverTextColumnWidth = "4em";
      styleColumnWidth = "4em";
    }
    else if(o_mediaQueryFlag <= 4) {
      numOptionsColumnWidth = "5em";
      hoverTextColumnWidth = "6em";
      styleColumnWidth = "5em";
    }

    var aedTableObjsArray = [];
    for(let f = 0; f < fieldsDataArrayOfArrayOfObjs.length; f++) {
      var aedTableObj = {
        tblName: "tbl_captures_fields",
        canAddNewItemsTF: canAddNewItemsTFArray[f],
        canDeleteItemsTF: canDeleteItemsTFArray[f],
        dragToResortFieldDbName: dragToResortFieldDbNameArray[f],
        maskResortFieldDbName: maskResortFieldDbNameArray[f],
        canAlwaysDragResortTF: true,
        initialSortedFieldDbName: initialSortedFieldDbNameArray[f],
        tableWidth: "100%",
        itemName: "Capture Field",
        deleteItemNameFieldDbName: "display_name",
        searchFieldDbName: undefined,
        idsAlreadyAddedArray: idsAlreadyAddedArrayArray[f],
        itemDeleteFunctionsArray: itemDeleteFunctionsArrayArray[f],
        fieldsArrayOfObjs: [
          {width:"400em", dbName:"display_name", displayName:"Display Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
          {width:"300em", dbName:"fieldTypeMask", displayName:"Input Type", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, rawValueDisplayedFieldIsReadOnlyOnFormTF:true}, //text input so that the raw value (actually precalculated masked value) is simply displayed with no masking
          {width:numOptionsColumnWidth, fixedWidthTF:true, dbName:"numOptions", displayName:"# Options", fieldTypeObj:undefined, initialValue:undefined, cellCenterTF:true, rawValueDisplayedFieldNotOnFormTF:true},
          {width:undefined, dbName:"optionsArrayOfObjs", displayName:"Options for 'Select' field types", fieldTypeObj:{editOptionsForSelectFieldTF:true}, initialValue:undefined},
          {width:hoverTextColumnWidth, fixedWidthTF:true, dbName:"hover_text", displayName:"Hover Help Text", fieldTypeObj:this.props.DatabaseMobx.c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF:true, cellDisplayBlankIfNotFilledOutTF:true},
          {width:styleColumnWidth, fixedWidthTF:true, dbName:"display_name_styling_string_comma", displayName:"Style", fieldTypeObj:this.props.DatabaseMobx.c_genericStylingStringFontBlueFieldTypeObj, initialValue:"", cellCenterTF:true, cellHoverTF:true, cellDisplayBlankIfNotFilledOutTF:true}
        ]
      };

      aedTableObjsArray.push(aedTableObj);
    }

    return (
      <div className="flex11a displayFlexColumn">
        <CaptureTypeSelectionBoxes />
        <div className="flex11a displayFlexRow">
          <div className="flex11a displayFlexColumn rMargin" style={{flexBasis:"140em", borderRight:"solid 1px #ba9"}}>
            <div className="flex00a displayFlexRow" style={{flexBasis:"3em", background:"#ddccbb", borderBottom:"solid 1px #ba9"}}>
              <div className="flex00a displayFlexColumnVc lrMedPad" style={{flexBasis:"25em"}}>
                <CEGeneralReact.SearchInputTextWithClearX
                  p_inputText={this.props.AdminMobx.o_fieldsSearchFilterText}
                  p_placeholderText="Filter All Capture Fields..."
                  p_includeSearchIconTF={false}
                  p_heightEm={2.2}
                  p_tabIndex={1}
                  f_onChange={this.onchange_fields_search_filter_text}
                />
              </div>
              <div className="flex11a" />
            </div>
            <div className="flex11a yScroll smallTopPad lrPad hugeBottomPad">
              {fieldsDataArrayOfArrayOfObjs.map((m_fieldsDataArrayOfObjs, m_index) =>
                <div key={keysArray[m_index]} className={((m_index > 0) ? ("hugeTopMargin") : ("")) + " smallBottomMargin"}>
                  <div className="microBottomMargin">
                    <font className="font14 fontItalic">
                      {tableTitlesArray[m_index]}
                    </font>
                  </div>
                  <AddEditDeleteTable
                    p_dataArrayOfObjs={m_fieldsDataArrayOfObjs}
                    p_aedTableObj={aedTableObjsArray[m_index]}
                    f_onClickAddItem={this.onclick_add_capture_field_to_receiving_details_group}
                  />
                </div>
              )}
              <div className="hugeTopMargin smallBottomMargin">
                <div className="microBottomMargin">
                  <font className="font14 fontItalic">
                    {"Add Blank Spaces or Line Breaks Between Fields"}
                  </font>
                </div>
                <div className="displayFlexRowHcVc bgLightGray medFullPad border bevelBorderColors borderRadius10">
                  <div className="flex00a lrMedPad">
                    <CEGeneralReact.CEButton
                      p_type="add"
                      p_text="Add Blank Space ->"
                      f_onClick={this.onclick_add_blank_space_to_receiving_details_group}
                    />
                  </div>
                  <div className="flex00a lrMedPad">
                    <CEGeneralReact.CEButton
                      p_type="add"
                      p_text="Add Line Break ->"
                      f_onClick={this.onclick_add_line_break_to_receiving_details_group}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
            <div className="flex00a displayFlexRow medFullPad" style={{background:"#ddccbb"}}>
              <DetailsGroupOrDatesCardTab p_snapshotGroupsDatesTab="detailsCardGroups" />
              <DetailsGroupOrDatesCardTab p_snapshotGroupsDatesTab="detailsCardSnapshot" />
              <DetailsGroupOrDatesCardTab p_snapshotGroupsDatesTab="datesCard" />
              <DetailsGroupOrDatesCardTab p_snapshotGroupsDatesTab="revenueCard" />
            </div>
            <div className="flex11a yScroll">
              <div className="smallTopMargin smallFullPad" style={{border:"solid 1px #bbb"}}>
                <div className="">
                  <font className="font13 fontBold fontItalic fontTextLight">
                    {receivingTitle}
                  </font>
                </div>
                {receivingGroupsComponent}
                {exampleSnapshotCardComponent}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}));

const DetailsGroupOrDatesCardTab = inject("AdminMobx", "DatabaseMobx")(observer(
class DetailsGroupOrDatesCardTab extends Component { //props: p_snapshotGroupsDatesTab
  onclick_details_snapshot_details_groups_dates_card_tab = () => {
    this.props.AdminMobx.a_fields_set_search_filter_text("");
    this.props.AdminMobx.a_fields_set_selected_tab_snapshot_groups_dates(this.props.p_snapshotGroupsDatesTab);
  }

  render() {
    const snapshotGroupsDatesTab = this.props.p_snapshotGroupsDatesTab;
    const o_fieldsSelectedTabSnapshotGroupsDates = this.props.AdminMobx.o_fieldsSelectedTabSnapshotGroupsDates;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;
    const c_cardNameDates = this.props.DatabaseMobx.c_cardNameDates;
    const c_cardNameRevenue = this.props.DatabaseMobx.c_cardNameRevenue;

    var label = undefined;
    if(snapshotGroupsDatesTab === "detailsCardGroups") {
      label = c_cardNameDetails + " Card (Groups)";
    }
    else if(snapshotGroupsDatesTab === "detailsCardSnapshot") {
      label = c_cardNameDetails + " Card Snapshot";
    }
    else if(snapshotGroupsDatesTab === "datesCard") {
      label = c_cardNameDates + " Card";
    }
    else if(snapshotGroupsDatesTab === "revenueCard") {
      label = c_cardNameRevenue + " Card";
    }

    const selectedTF = (snapshotGroupsDatesTab === o_fieldsSelectedTabSnapshotGroupsDates);

    return(
      <div
        className={"flex11a displayFlexRowVc lrMargin lrMedPad border bevelBorderDarkColors textCenter " + ((selectedTF) ? ("bgGreenGradient") : ("bgDarkGrayGradient hoverLightGreenGradient cursorPointer"))}
        style={{flexBasis:"100em", height:"3.3em", borderRadius:"2em"}}
        onClick={((this.props.p_selectedTF) ? (undefined) : (this.onclick_details_snapshot_details_groups_dates_card_tab))}>
        <LibraryReact.MaxHeightWrap p_maxHeight="2.9em" p_fontClass="font12 fontItalic fontWhite">
          {label}
        </LibraryReact.MaxHeightWrap>
      </div>
    );
  }
}));



export const DetailsSnapshotOfAddedCaptureFields = inject("AdminMobx")(observer(
class DetailsSnapshotOfAddedCaptureFields extends Component { //props:
  ondrop_field_from_pool_into_group_drop_zone = (i_draggedFieldID) => {
    const c_fieldsAddedToDetailsSnapshotObj = this.props.AdminMobx.c_fieldsAddedToDetailsSnapshotObj;
    if(!JSFUNC.in_array(i_draggedFieldID, c_fieldsAddedToDetailsSnapshotObj.fieldIDsAlreadyAddedArray)) {
      this.props.AdminMobx.a_fields_add_field_to_receiving_details_snapshot(i_draggedFieldID);
    }
  }

  onremove_all_details_snapshot_fields_from_capture_type = () => {
    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;
    const c_selectedCaptureTypeDetailsSnapshotFieldIDsArray = this.props.AdminMobx.c_selectedCaptureTypeDetailsSnapshotFieldIDsArray;
    if(c_selectedCaptureTypeDetailsSnapshotFieldIDsArray.length > 0) {
      const jsDescription = JSFUNC.js_description_from_action("AdminReact - DetailsSnapshotOfAddedCaptureFields", "onremove_all_details_snapshot_fields_from_capture_type", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_a_capture_types", o_selectedCaptureTypeID, "details_snapshot_field_ids_comma", "", "s");
      C_CallPhpTblUID.execute();
    }
  }

  render() {
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_fieldsAddedToDetailsSnapshotObj = this.props.AdminMobx.c_fieldsAddedToDetailsSnapshotObj;

    const snapshotFieldsArrayOfObjs = c_fieldsAddedToDetailsSnapshotObj.snapshotFieldsArrayOfObjs;
    const fieldIDsAlreadyAddedArray = c_fieldsAddedToDetailsSnapshotObj.fieldIDsAlreadyAddedArray;
    const numFields = snapshotFieldsArrayOfObjs.length;

    return (
      <BlueItemCollectionShell
        p_isReceivingTF={true}
        p_dropUniqueString="AddEditDeleteTableRow_tbl_captures_fields"
        p_dropNotAllowedDraggerIDsArray={fieldIDsAlreadyAddedArray}
        p_dropMessage="[Drag/drop capture fields from the pool]"
        p_dropAllowDropMessage={"[Drop field to add to Details Snapshot]"}
        p_dropNotAllowDropMessage="[Cannot add a field already added to one of the groups]"
        f_onDrop={this.ondrop_field_from_pool_into_group_drop_zone}
        f_onRemoveAllItems={this.onremove_all_details_snapshot_fields_from_capture_type}>
        {snapshotFieldsArrayOfObjs.map((m_snapshotFieldObj, m_index) =>
          <DetailsSnapshotAddedCaptureFieldItem
            key={c_selectedCaptureTypeObj.id + "-" + m_snapshotFieldObj.fieldID + "-" + (m_index + 1)}
            p_snapshotFieldObj={m_snapshotFieldObj}
            p_snapshotFieldIndex={m_index}
            p_isOnlyItemTF={(numFields === 1)}
            p_isLastItemTF={(m_index === (numFields - 1))}
          />
        )}
      </BlueItemCollectionShell>
    );
  }
}));


export const DetailsSnapshotAddedCaptureFieldItem = inject("AdminMobx", "DatabaseMobx")(observer(
class DetailsSnapshotAddedCaptureFieldItem extends Component { //props: p_snapshotFieldObj, p_snapshotFieldIndex, p_isOnlyItemTF, p_isLastItemTF
  onclick_remove_field_from_capture_type_details_snapshot_card = () => {
    this.props.AdminMobx.a_fields_remove_field_from_details_snapshot(this.props.p_snapshotFieldObj.fieldID);
  }

  render() {
    const snapshotFieldObj = this.props.p_snapshotFieldObj;
    const snapshotFieldIndex = this.props.p_snapshotFieldIndex;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    var addedCaptureFieldItemContentComponent = null;
    if(snapshotFieldObj.fieldID === -10) {
      addedCaptureFieldItemContentComponent = (
        <div className="flex11a displayFlexColumnHcVc">
          {"[Blank Space]"}
        </div>
      );
    }
    else if(snapshotFieldObj.fieldID === -11) {
      addedCaptureFieldItemContentComponent = (
        <div className="flex11a displayFlexColumnVc">
          <div className="borderT1Blue" style={{margin:"0 1.5em"}} />
        </div>
      );
    }
    else {
      addedCaptureFieldItemContentComponent = (
        <div className="flex11a displayFlexRowVc lrPad" title={snapshotFieldObj.fieldDisplayName}>
          <LibraryReact.Nowrap>
            {snapshotFieldObj.fieldDisplayName}
          </LibraryReact.Nowrap>
        </div>
      );
    }

    return(
      <CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="captureTypeDetailsSnapshotField"
        p_itemIndex={snapshotFieldIndex}
        p_itemID={snapshotFieldObj.fieldID}
        p_itemSortColumnWidth="3em"
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="microFullPad"
        p_itemClass="displayFlexRow border bevelBorderColors borderRadius05 bgAlmostWhite"
        p_itemStyleObj={{height:"2.4em"}}
        p_lastItemExtraDropZoneHeight="1.8em"
        p_tblName="tbl_a_capture_types"
        p_tblRowID={c_selectedCaptureTypeObj.id}
        p_tblCommaListColumnName="details_snapshot_field_ids_comma"
        p_commaList={c_selectedCaptureTypeObj.details_snapshot_field_ids_comma}>
        {addedCaptureFieldItemContentComponent}
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove Capture Field '" + snapshotFieldObj.fieldDisplayName + "' from Details Snapshot Card for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
            f_onClick={this.onclick_remove_field_from_capture_type_details_snapshot_card}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl>
    );
  }
}));



export const DetailsCardGroupOfAddedCaptureFields = inject("AdminMobx")(observer(
class DetailsCardGroupOfAddedCaptureFields extends Component { //props: p_fieldsPerGroupObj, p_fieldIDsAlreadyAddedArray, p_fieldsSelectedReceivingDetailsCardGroupID, p_canResortTF
  onclick_set_receiving_details_card_group_id = () => {
    this.props.AdminMobx.a_fields_set_receiving_details_card_group_id(this.props.p_fieldsPerGroupObj.detailsCardGroupObj.id);
  }

  ondrop_field_from_pool_into_group_drop_zone = (i_draggedFieldID) => {
    const p_fieldsPerGroupObj = this.props.p_fieldsPerGroupObj;
    if(!JSFUNC.in_array(i_draggedFieldID, this.props.p_fieldIDsAlreadyAddedArray)) {
      this.props.AdminMobx.a_fields_add_field_to_receiving_details_card_group(p_fieldsPerGroupObj.detailsCardGroupObj.id, i_draggedFieldID);
    }
  }

  onremove_all_details_fields_in_group_from_capture_type = () => {
    const p_fieldsPerGroupObj = this.props.p_fieldsPerGroupObj;
    
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx - DetailsCardGroupOfAddedCaptureFields", "onremove_all_details_fields_in_group_from_capture_type", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const allCaptureTypeDetailsFieldRowIDsInGroupArray = JSFUNC.get_column_vector_from_arrayOfObjs(p_fieldsPerGroupObj.expandedCaptureTypeDetailsFieldsArrayOfObjs, "id");
    C_CallPhpTblUID.add_delete("tbl_a_capture_types_details_fields", allCaptureTypeDetailsFieldRowIDsInGroupArray);

    C_CallPhpTblUID.execute();
  }

  render() {
    const p_fieldsPerGroupObj = this.props.p_fieldsPerGroupObj;
    const fieldIDsAlreadyAddedArray = this.props.p_fieldIDsAlreadyAddedArray;
    const fieldsSelectedReceivingDetailsCardGroupID = this.props.p_fieldsSelectedReceivingDetailsCardGroupID;
    const p_canResortTF = this.props.p_canResortTF;

    const detailsCardGroupObj = p_fieldsPerGroupObj.detailsCardGroupObj;
    const expandedCaptureTypeDetailsFieldsArrayOfObjs = p_fieldsPerGroupObj.expandedCaptureTypeDetailsFieldsArrayOfObjs;
    const numFields = expandedCaptureTypeDetailsFieldsArrayOfObjs.length;

    return (
      <BlueItemCollectionShell
        p_isReceivingTF={(detailsCardGroupObj.id === fieldsSelectedReceivingDetailsCardGroupID)}
        p_title={((detailsCardGroupObj.id > 0) ? ("Group Name: '" + detailsCardGroupObj.name + "'") : (undefined))}
        p_dropUniqueString="AddEditDeleteTableRow_tbl_captures_fields"
        p_dropNotAllowedDraggerIDsArray={fieldIDsAlreadyAddedArray}
        p_dropMessage="[Drag/drop capture fields from the pool]"
        p_dropAllowDropMessage={"[Drop field to add to group '" + detailsCardGroupObj.name + "']"}
        p_dropNotAllowDropMessage="[Cannot add a field already added to one of the groups]"
        f_onClickSetAsReceiving={this.onclick_set_receiving_details_card_group_id}
        f_onDrop={this.ondrop_field_from_pool_into_group_drop_zone}
        f_onRemoveAllItems={this.onremove_all_details_fields_in_group_from_capture_type}>
        {expandedCaptureTypeDetailsFieldsArrayOfObjs.map((m_expandedCaptureTypeDetailsFieldObj, m_index) =>
          <DetailsCardGroupAddedCaptureFieldItem
            key={m_expandedCaptureTypeDetailsFieldObj.id}
            p_detailsCardGroupObj={detailsCardGroupObj}
            p_expandedCaptureTypeDetailsFieldObj={m_expandedCaptureTypeDetailsFieldObj}
            p_canResortTF={p_canResortTF}
            p_sortNumber={(m_index + 1)}
            p_isOnlyItemTF={(numFields === 1)}
            p_isLastItemTF={(m_index === (numFields - 1))}
          />
        )}
      </BlueItemCollectionShell>
    );
  }
}));


export const DetailsCardGroupAddedCaptureFieldItem = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class DetailsCardGroupAddedCaptureFieldItem extends Component { //props: p_detailsCardGroupObj, p_expandedCaptureTypeDetailsFieldObj, p_canResortTF, p_sortNumber, p_isOnlyItemTF, p_isLastItemTF
  constructor(props) {
    super(props);
    this.state = {
      s_editingAddedCaptureTypeFieldRecordTF: false
    };
  }

  onclick_capture_type_details_card_group_field_item_to_edit = () => {
    this.setState({s_editingAddedCaptureTypeFieldRecordTF:true});
  }

  onclick_close_capture_type_details_card_group_field_item_to_edit = () => {
    this.setState({s_editingAddedCaptureTypeFieldRecordTF:false});
  }

  onclick_remove_field_from_capture_type_details_card_group = () => {
    const p_expandedCaptureTypeDetailsFieldObj = this.props.p_expandedCaptureTypeDetailsFieldObj;
    this.props.AdminMobx.a_fields_remove_field_from_details_card_group(p_expandedCaptureTypeDetailsFieldObj.id, p_expandedCaptureTypeDetailsFieldObj.capture_type_id, p_expandedCaptureTypeDetailsFieldObj.group_id);
  }

  render() {
    const p_detailsCardGroupObj = this.props.p_detailsCardGroupObj;
    const p_expandedCaptureTypeDetailsFieldObj = this.props.p_expandedCaptureTypeDetailsFieldObj;
    const p_canResortTF = this.props.p_canResortTF;
    const p_sortNumber = this.props.p_sortNumber;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const k_blueItemCollectionShellItemStylingObj = this.props.AdminMobx.k_blueItemCollectionShellItemStylingObj;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;

    const s_editingAddedCaptureTypeFieldRecordTF = this.state.s_editingAddedCaptureTypeFieldRecordTF;

    //hover title
    var captureFieldNameTitle = "[Click to edit '" + p_expandedCaptureTypeDetailsFieldObj.fieldDisplayName + "' field options]\n";

    if(p_expandedCaptureTypeDetailsFieldObj.errorIfNotFilledOutTF) {
      captureFieldNameTitle += "\n[B] Field cannot be left blank when creating a new Capture"
    }

    captureFieldNameTitle += "\n[D] Default Value: " + ((p_expandedCaptureTypeDetailsFieldObj.useDefaultTF) ? (p_expandedCaptureTypeDetailsFieldObj.defaultValueMaskSortIfoObjOrUndefined.valueMaskPlainText) : ("--none--"));
    
    if(p_expandedCaptureTypeDetailsFieldObj.private1CaptureManagersOnlyTF || p_expandedCaptureTypeDetailsFieldObj.private2AdminsOnlyTF) {
      captureFieldNameTitle += "\n[pC/pA] Privacy: ";
      if(p_expandedCaptureTypeDetailsFieldObj.private0EveryoneTF) { captureFieldNameTitle += "--none--"; }
      else if(p_expandedCaptureTypeDetailsFieldObj.private1CaptureManagersOnlyTF) { captureFieldNameTitle += "Capture Managers only"; }
      else if(p_expandedCaptureTypeDetailsFieldObj.private2AdminsOnlyTF) { captureFieldNameTitle += "Admins only"; }
    }

    if(p_expandedCaptureTypeDetailsFieldObj.errorIfMultipleSelectedTF) {
      captureFieldNameTitle += "\n[M] Multiple options cannot be selected"
    }

    if(p_expandedCaptureTypeDetailsFieldObj.errorIfSelectedNotAllowedIDsIsFilledOutTF) {
      captureFieldNameTitle += "\n[O] Option(s) cannot be selected: " + p_expandedCaptureTypeDetailsFieldObj.errorIfSelectedNotAllowedOptionDisplayNamesComma;
    }

    captureFieldNameTitle += "\n[Req] Required in " + c_fieldMapOfStage.get("display_name") + ": " + ((p_expandedCaptureTypeDetailsFieldObj.requiredToAdvanceStageTF) ? (p_expandedCaptureTypeDetailsFieldObj.requiredInStageNameMaskSortIfoObj.valueMaskPlainText) : ("--none--"));
    
    //display within drag to resort item shell
    var addedCaptureFieldItemContentComponent = null;
    if(p_expandedCaptureTypeDetailsFieldObj.isBlankSpaceTF) {
      addedCaptureFieldItemContentComponent = (
        <div className="flex11a displayFlexColumnHcVc">
          {"[Blank Space]"}
        </div>
      );
    }
    else if(p_expandedCaptureTypeDetailsFieldObj.isLineBreakTF) {
      addedCaptureFieldItemContentComponent = (
        <div className="flex11a displayFlexColumnVc">
          <div className="borderT1Blue" style={{margin:"0 1.5em"}} />
        </div>
      );
    }
    else {
      addedCaptureFieldItemContentComponent = (
        <div
          className="flex11a displayFlexRow cursorPointer"
          title={captureFieldNameTitle}
          onClick={this.onclick_capture_type_details_card_group_field_item_to_edit}>
          <div className="flex11a displayFlexRowVc lrPad">
            <LibraryReact.Nowrap>
              {p_expandedCaptureTypeDetailsFieldObj.fieldDisplayName}
            </LibraryReact.Nowrap>
          </div>
          <AddedFieldOptionIndicator
            p_onTF={p_expandedCaptureTypeDetailsFieldObj.errorIfNotFilledOutTF}
            p_letter="B"
          />
          <AddedFieldOptionIndicator
            p_onTF={p_expandedCaptureTypeDetailsFieldObj.useDefaultTF}
            p_letter="D"
          />
          <AddedFieldOptionIndicator
            p_onTF={p_expandedCaptureTypeDetailsFieldObj.private1CaptureManagersOnlyTF || p_expandedCaptureTypeDetailsFieldObj.private2AdminsOnlyTF}
            p_letter={((p_expandedCaptureTypeDetailsFieldObj.private2AdminsOnlyTF) ? ("pA") : ("pC"))}
          />
          <AddedFieldOptionIndicator
            p_onTF={p_expandedCaptureTypeDetailsFieldObj.errorIfMultipleSelectedTF}
            p_letter="M"
          />
          <AddedFieldOptionIndicator
            p_onTF={p_expandedCaptureTypeDetailsFieldObj.errorIfSelectedNotAllowedIDsIsFilledOutTF}
            p_letter="O"
          />
          <div className="flex00a displayFlexRowVc" style={{flexBasis:"10em", marginLeft:"0.4em"}}>
            <div className="flex00a" style={{flexBasis:"2em", marginRight:"0.2em"}}>
              <font className={((p_expandedCaptureTypeDetailsFieldObj.requiredToAdvanceStageTF) ? ("font09 fontBold fontItalic fontBlue") : ("font09 fontItalic fontTextLightest"))}>{"Req:"}</font>
            </div>
            <div className="flex11a">
              <LibraryReact.Nowrap>
                {((p_expandedCaptureTypeDetailsFieldObj.requiredToAdvanceStageTF) ? (p_expandedCaptureTypeDetailsFieldObj.requiredInStageNameMaskSortIfoObj.valueMask) : ("-"))}
              </LibraryReact.Nowrap>
            </div>
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={p_canResortTF}
        p_uniqueString="captureTypeDetailsField"
        p_itemID={p_expandedCaptureTypeDetailsFieldObj.id}
        p_itemSort={((p_canResortTF) ? (p_sortNumber) : (undefined))}
        p_itemSortColumnWidth="3em"
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass={k_blueItemCollectionShellItemStylingObj.outerPadClass}
        p_itemClass="displayFlexRow border bevelBorderColors borderRadius05 bgAlmostWhite hoverLighterBlueGradient"
        p_itemStyleObj={{height:k_blueItemCollectionShellItemStylingObj.rowHeightEm + "em"}}
        p_lastItemExtraDropZoneHeight={k_blueItemCollectionShellItemStylingObj.lastItemExtraDropZoneHeightEm + "em"}
        p_tblName="tbl_a_capture_types_details_fields"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={["capture_type_id", "group_id"]}
        p_filterValuesArray={[p_expandedCaptureTypeDetailsFieldObj.capture_type_id, p_expandedCaptureTypeDetailsFieldObj.group_id]}>
        {addedCaptureFieldItemContentComponent}
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove Capture Field '" + p_expandedCaptureTypeDetailsFieldObj.fieldDisplayName + "' from '" + p_detailsCardGroupObj.name + "' for Capture Type '" + c_selectedCaptureTypeObj.name + "'"}
            f_onClick={this.onclick_remove_field_from_capture_type_details_card_group}
          />
        </div>
        {(s_editingAddedCaptureTypeFieldRecordTF) &&
          <AddedDetailsCardCaptureFieldEditFloatingBox
            p_expandedCaptureTypeDetailsFieldObj={p_expandedCaptureTypeDetailsFieldObj}
            f_onClickClose={this.onclick_close_capture_type_details_card_group_field_item_to_edit}
          />
        }
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));

function AddedFieldOptionIndicator(props) { //props: p_onTF, p_letter
  const p_onTF = props.p_onTF;
  const p_letter = props.p_letter;

  return(
    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.2em"}}>
      {(p_onTF) && 
        <div className="flex00a displayFlexColumnHcVc bgLightGray textCenter" style={{width:"1.1em", height:"1.2em"}}>
          <font className="font09 fontBold fontItalic fontBlue">
            {p_letter}
          </font>
        </div>
      }
    </div>
  )
}


const AddedDetailsCardCaptureFieldEditFloatingBox = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AddedDetailsCardCaptureFieldEditFloatingBox extends Component { //props: p_expandedCaptureTypeDetailsFieldObj, f_onClickClose
  onselect_error_if_not_filled_out = (i_newValue01) => {
    this.update_capture_type_field_record_field_value("error_if_not_filled_out_01", i_newValue01, "i");
  }

  onselect_error_if_multiple_selected = (i_newValue01) => {
    this.update_capture_type_field_record_field_value("error_if_multiple_selected_01", i_newValue01, "i");
  }

  onselect_not_allowed_select_options = (i_selectedNotAllowedOptionIDsComma) => {
    this.update_capture_type_field_record_field_value("error_if_selected_not_allowed_ids_comma", i_selectedNotAllowedOptionIDsComma, "s");
  }

  onswitch_use_default = () => {
    const p_expandedCaptureTypeDetailsFieldObj = this.props.p_expandedCaptureTypeDetailsFieldObj;
    const newUseDefault01 = ((p_expandedCaptureTypeDetailsFieldObj.useDefaultTF) ? (0) : (1));
    this.update_capture_type_field_record_field_value("use_default_01", newUseDefault01, "i");
  }

  onsave_default_value = (i_newValue) => {
    const newDefaultValueString = JSFUNC.num2str(i_newValue);
    this.update_capture_type_field_record_field_value("default_value", newDefaultValueString, "s");
  }

  onswitch_required = () => {
    const p_expandedCaptureTypeDetailsFieldObj = this.props.p_expandedCaptureTypeDetailsFieldObj;
    
    const c_selectedCaptureTypeStageIDsArray = this.props.AdminMobx.c_selectedCaptureTypeStageIDsArray;

    //if switching to on, set the stage to the first stage in this capture type's timeline, otherwise if turning off, set the stage to -1
    var newRec1Req3 = 1;
    var newStageIDRelevant = -1;
    if(p_expandedCaptureTypeDetailsFieldObj.recommended1TF) { //switch recommended to required
      newRec1Req3 = 3;
      if(c_selectedCaptureTypeStageIDsArray.length > 0) {
        newStageIDRelevant = c_selectedCaptureTypeStageIDsArray[0]; //default to first stage when turning on the required flag
      }
    }
    else { //switch required to recommended
      newRec1Req3 = 1;
      newStageIDRelevant = -1;
    }

    this.update_capture_type_field_record_field_value(["rec1_reqsig2_req3", "stage_id_relevant"], [newRec1Req3, newStageIDRelevant], ["i", "i"]);
  }

  onsave_stage_id_relevant = (i_newValue) => {
    this.update_capture_type_field_record_field_value("stage_id_relevant", i_newValue, "i");
  }

  onselect_only_capture_managers_can_view_field = (i_newValue012) => {
    this.update_capture_type_field_record_field_value("only_capture_managers_can_view_field_01", i_newValue012, "i");
  }

  update_capture_type_field_record_field_value = (i_fieldName, i_value, i_idsb) => {
    const p_expandedCaptureTypeDetailsFieldObj = this.props.p_expandedCaptureTypeDetailsFieldObj;
    const allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs = this.props.AdminMobx.c_allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs;

    //get all captureTypesDetailsFields rowIDs that match this fieldID for this capture type (keeps requirements/defaults/privacy synchronized across same field added to details/dates/revenue cards)
    var captureTypesDetailsFieldsRowIDsToUpdateArray = [p_expandedCaptureTypeDetailsFieldObj.id]; //start with the id of the row being edited
    for(let allFieldsCaptureTypeDetailsFieldObj of allFieldsAddedToDetailsDatesRevenueCardsForSelectedCaptureTypeArrayOfObjs) {
      if((allFieldsCaptureTypeDetailsFieldObj.id !== p_expandedCaptureTypeDetailsFieldObj.id) && (allFieldsCaptureTypeDetailsFieldObj.field_id === p_expandedCaptureTypeDetailsFieldObj.field_id)) { //if the fieldIDs match, but its not the same entry as the one we're currently editing
        captureTypesDetailsFieldsRowIDsToUpdateArray.push(allFieldsCaptureTypeDetailsFieldObj.id);
      }
    }

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AddedDetailsCardCaptureFieldEditFloatingBox", "update_capture_type_field_record_field_value", ["i_fieldName", "i_value", "i_idsb"], [i_fieldName, i_value, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types_details_fields", captureTypesDetailsFieldsRowIDsToUpdateArray, i_fieldName, i_value, i_idsb);
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_expandedCaptureTypeDetailsFieldObj = this.props.p_expandedCaptureTypeDetailsFieldObj;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectStageWithinSelectedCaptureTypeFieldTypeObj = this.props.AdminMobx.c_selectStageWithinSelectedCaptureTypeFieldTypeObj;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;

    const stageFieldDisplayName = c_fieldMapOfStage.get("display_name");
    const editItemString = "adminDDF_fID" + p_expandedCaptureTypeDetailsFieldObj.id;

    var fieldIsSelectTF = false;
    var fieldIsMultiSelectTF = false;
    var fieldIsSharedPercentTF = false;
    if(p_expandedCaptureTypeDetailsFieldObj.fieldTypeObj !== undefined) {
      fieldIsSelectTF = p_expandedCaptureTypeDetailsFieldObj.fieldTypeObj.selectTF;
      if(fieldIsSelectTF) {
        if(p_expandedCaptureTypeDetailsFieldObj.fieldTypeObj.selectWithSearchDataObj !== undefined) {
          fieldIsMultiSelectTF = p_expandedCaptureTypeDetailsFieldObj.fieldTypeObj.selectWithSearchDataObj.isMultiSelectTF;
        }
      }
      fieldIsSharedPercentTF = p_expandedCaptureTypeDetailsFieldObj.fieldTypeObj.sharedPercentTF;
    }

    const errorIfNotFilledOutValueArray = [0, 1];
    const errorIfNotFilledOutDisplayArray = ["[default] Field may be saved as empty/unassigned", "New Capture (and edit) will not allow this field to be empty/unassigned"];
    const errorIfNotFilledOutSelectFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Details Field Not Filled Out Option", errorIfNotFilledOutValueArray, false, errorIfNotFilledOutDisplayArray, 1.7);

    const detailsFieldPrivateValueArray = [0, 1, 2];
    const detailsFieldPrivateDisplayArray = ["[default] Field can be viewed by anyone who can access the Capture", "Only assigned Capture Managers of a Capture can view this field and its data", "Only " + c_productStylingObj.productName + " Admins can view this field and its data"];
    const detailsFieldPrivateSelectFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Details Field Privacy Option", detailsFieldPrivateValueArray, false, detailsFieldPrivateDisplayArray, 1.7);
    
    var selectFieldTypesOptionsSectionComponent = null;
    if(fieldIsSelectTF || fieldIsSharedPercentTF) {
      const errorIfMultipleSelectedValueArray = [0, 1];
      const errorIfMultipleSelectedDisplayArray = ["[default] Can select multiple options from multi-select", "Can only select 1 option at most"];
      const errorIfMultipleSelectedSelectFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Details Field Not Filled Out Option", errorIfMultipleSelectedValueArray, false, errorIfMultipleSelectedDisplayArray, 1.7);
  
      const forcedSelectMultiOptionsSwsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
      const forcedSelectMultiOptionsFieldTypeObj = this.props.DatabaseMobx.create_new_copy_field_type_obj_from_old_field_type_obj_and_field_input_type_and_sws_options_obj(p_expandedCaptureTypeDetailsFieldObj.fieldTypeObj, "select", forcedSelectMultiOptionsSwsOptionsObj);
  
      selectFieldTypesOptionsSectionComponent = (
        <>
          <AddedDetailsCardCaptureFieldSectionTitle>
            {"Field Options for Select Field Types"}
          </AddedDetailsCardCaptureFieldSectionTitle>
          {(fieldIsMultiSelectTF || fieldIsSharedPercentTF) &&
            <AddedDetailsCardCaptureFieldOptionContainer p_optionName="[M] Multi-Select" p_optionDescription="Field can select multiple options (Multi-Select or Shared Percent field types only)">
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={errorIfMultipleSelectedSelectFieldTypeObj}
                p_valueRaw={p_expandedCaptureTypeDetailsFieldObj.error_if_multiple_selected_01}
                f_onChangeOrOnSelect={this.onselect_error_if_multiple_selected}
              />
            </AddedDetailsCardCaptureFieldOptionContainer>
          }
          <AddedDetailsCardCaptureFieldOptionContainer p_optionName="[O] Not Allowed Options" p_optionDescription="Specify select options that are not allowed">
            <div className="bgLightestestGray smallFullPad">
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString={editItemString + "not"}
                p_fieldDisplayName={undefined}
                p_fieldTypeObj={forcedSelectMultiOptionsFieldTypeObj}
                p_valueRaw={p_expandedCaptureTypeDetailsFieldObj.error_if_selected_not_allowed_ids_comma}
                p_valueMask={((JSFUNC.selectmulti_is_filled_out_tf(p_expandedCaptureTypeDetailsFieldObj.error_if_selected_not_allowed_ids_comma)) ? (undefined) : ("--[default] All Options Allowed--"))}
                p_valueIsEditableTFU={true}
                p_containerClass=""
                p_fieldClass="fontBlue"
                p_fieldWidth="10em"
                f_onSaveChanged={this.onselect_not_allowed_select_options}
              />
            </div>
          </AddedDetailsCardCaptureFieldOptionContainer>
        </>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditItem"
        p_title={"Field Options for '" + p_expandedCaptureTypeDetailsFieldObj.fieldDisplayName + "'"}
        f_onClickSave={this.props.f_onClickClose}>
        <div className="flex11a yScroll medFullPad">
          <div className="smallBottomMargin border1bbb bgLighterGray smallFullPad">
            <div className="textCenter">
              <font className="font14 fontBold fontDarkBlue">
                {"Capture Field: '" + p_expandedCaptureTypeDetailsFieldObj.fieldDisplayName + "'"}
              </font>
            </div>
            <div className="textCenter">
              <font className="fontItalic fontTextLight">
                {"Added to " + c_cardNameDetails + " Card for Capture Type"}
              </font>
            </div>
            <div className="textCenter">
              <font className="fontBold fontDarkOrange">
                {c_selectedCaptureTypeObj.name}
              </font>
            </div>
          </div>
          <AddedDetailsCardCaptureFieldSectionTitle p_topSpaceTF={false}>
            {"Field Options"}
          </AddedDetailsCardCaptureFieldSectionTitle>
          <AddedDetailsCardCaptureFieldOptionContainer p_optionName="[B] Field not allowed to be blank" p_optionDescription={undefined}>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={errorIfNotFilledOutSelectFieldTypeObj}
              p_valueRaw={p_expandedCaptureTypeDetailsFieldObj.error_if_not_filled_out_01}
              f_onChangeOrOnSelect={this.onselect_error_if_not_filled_out}
            />
          </AddedDetailsCardCaptureFieldOptionContainer>
          <AddedDetailsCardCaptureFieldOptionContainer p_optionName="[D] Default Value" p_optionDescription="If this switch is turned on, when a new Capture is created (or imported), this field will have its value automatically filled in with the default value specified here. Otherwise the field will be initialized with a standard empty value.">
            <div className="bgLightestestGray smallFullPad">
              <CEGeneralReact.SwitchWithTextAndConfirmBox
                p_isOnTF={p_expandedCaptureTypeDetailsFieldObj.useDefaultTF}
                p_sizeEm={3}
                p_onText="New Capture initializes Field with Default Value"
                p_offText="[default] Field is Empty in a New Capture"
                f_onSwitch={this.onswitch_use_default}
              />
              {(p_expandedCaptureTypeDetailsFieldObj.useDefaultTF) &&
                <CEGeneralReact.CaptureExecFieldEditSaveCancel
                  p_ceEditItemString={editItemString + "default_value"}
                  p_fieldDisplayName="Default Value"
                  p_fieldTypeObj={p_expandedCaptureTypeDetailsFieldObj.fieldTypeObj}
                  p_valueRaw={this.props.DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(p_expandedCaptureTypeDetailsFieldObj.default_value, p_expandedCaptureTypeDetailsFieldObj.fieldTypeObj)}
                  p_valueIsEditableTFU={true}
                  p_containerClass="medTopMargin"
                  p_fieldClass="fontBlue"
                  p_fieldWidth="7em"
                  f_onSaveChanged={this.onsave_default_value}
                />
              }
            </div>
          </AddedDetailsCardCaptureFieldOptionContainer>
          <AddedDetailsCardCaptureFieldOptionContainer p_optionName="[pC/pA] Privacy" p_optionDescription="Option to hide this field for certain Users">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={detailsFieldPrivateSelectFieldTypeObj}
              p_valueRaw={p_expandedCaptureTypeDetailsFieldObj.only_capture_managers_can_view_field_01}
              f_onChangeOrOnSelect={this.onselect_only_capture_managers_can_view_field}
            />
          </AddedDetailsCardCaptureFieldOptionContainer>
          {selectFieldTypesOptionsSectionComponent}
          <AddedDetailsCardCaptureFieldSectionTitle>
            {"Required to Advance " + stageFieldDisplayName}
          </AddedDetailsCardCaptureFieldSectionTitle>
          <AddedDetailsCardCaptureFieldOptionContainer p_optionName={"[Req] Required to be filled out to advance " + stageFieldDisplayName} p_optionDescription={"Marking a field as 'required' in a specified " + stageFieldDisplayName + " means that if a Capture is in that " + stageFieldDisplayName + " and this field is not filled out, the system prevents advancing to any further " + stageFieldDisplayName + " until this field is filled out."}>
            <div className="bgLightestestGray smallFullPad">
              <CEGeneralReact.SwitchWithTextAndConfirmBox
                p_isOnTF={p_expandedCaptureTypeDetailsFieldObj.required3TF}
                p_onText={"Field is required to be filled out to advance past selected " + stageFieldDisplayName}
                p_offText={"[default] Field is not required to advance " + stageFieldDisplayName}
                f_onSwitch={this.onswitch_required}
              />
              {(p_expandedCaptureTypeDetailsFieldObj.required3TF) &&
                <CEGeneralReact.CaptureExecFieldEditSaveCancel
                  p_ceEditItemString={editItemString + "stage_id_relevant"}
                  p_fieldDisplayName={stageFieldDisplayName}
                  p_fieldTypeObj={c_selectStageWithinSelectedCaptureTypeFieldTypeObj}
                  p_valueRaw={p_expandedCaptureTypeDetailsFieldObj.stage_id_relevant}
                  p_valueIsEditableTFU={true}
                  p_containerClass="medTopMargin"
                  p_fieldClass="fontBlue"
                  p_fieldWidth="7em"
                  f_onSaveChanged={this.onsave_stage_id_relevant}
                />
              }
            </div>
          </AddedDetailsCardCaptureFieldOptionContainer>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

function AddedDetailsCardCaptureFieldSectionTitle(props) { //props: p_topSpaceTF, children
  const p_topSpaceTF = JSFUNC.prop_value(props.p_topSpaceTF, true);
  return(
    <div className={((p_topSpaceTF) ? ("massiveTopMargin") : ("")) + " microBottomMargin borderT1bbb borderB1bbb bgDarkBlue smallFullPad"}>
      <font className="font12 fontWhite">
        {props.children}
      </font>
    </div>
  );
}

function AddedDetailsCardCaptureFieldOptionContainer(props) { //props: p_optionName, p_optionDescription, p_children
  return(
    <div className="smallTopMargin bigBottomMargin border1bbb borderRadius05 bgLightGray medFullPad" style={{marginLeft:"0.6em"}}>
      {JSFUNC.string_is_filled_out_tf(props.p_optionName) &&
        <div className="microBottomMargin">
          <font className="fontBold">
            {props.p_optionName}
          </font>
        </div>
      }
      {JSFUNC.string_is_filled_out_tf(props.p_optionDescription) &&
        <div className="smallBottomMargin">
          <font className="fontItalic">
            {props.p_optionDescription}
          </font>
        </div>
      }
      {props.children}
    </div>
  );
}







const AddEditDeleteTableAddNewCaptureFieldFloatingBox = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AddEditDeleteTableAddNewCaptureFieldFloatingBox extends Component { //props: p_aedTableObj, f_onClickClose
  constructor(props) {
    super(props);

    this.state = {
      s_processStep: "fieldType", //"fieldType", "fieldFields", "chooseSelectDataSource", "createOptionsForSelectType"
      s_selectedFieldInputType: undefined,
      s_selectedFieldInputTypeDisplay: undefined,
      s_selectedFieldTypeObj: undefined,
      s_newCaptureFieldName: "",
      s_textareaMaxLines: 0,
      s_verticalSwitchExampleValue: 1,
      s_numDecimals: -1,
      s_hoverText: "",
      s_displayNameStylingStringComma: "",
      s_codewordNamesComma: undefined, //undefined means there are 0 new codeword boxes open, if a new one is added, this changes to "" to signify 1 entry box that is empty
      s_newCaptureFieldNameErrorMessage: undefined,
      s_phpIsCreatingNewCaptureFieldTF: false,
      s_newlyCreatedCaptureFieldID: -1,
      s_selectExistingSourceDataTblNameOrNew: "newTblCapName", //undefined if the choice has not been made yet, "newTblCapName"/"newTblCapSort" for creating new options and a cap tbl, "existing_tbl_name" for choosing an existing select dataset for the options (system tbl or a different tbl_cap)
      s_newlyCreatedTblCapTblName: undefined
    }
  }

  onclick_choose_field_input_type = (i_selectedFieldInputType, i_selectedFieldInputTypeDisplay, i_selectedFieldTypeObj) => {
    this.setState({
      s_selectedFieldInputType: i_selectedFieldInputType,
      s_selectedFieldInputTypeDisplay: i_selectedFieldInputTypeDisplay,
      s_selectedFieldTypeObj: i_selectedFieldTypeObj,
      s_textareaMaxLines: ((i_selectedFieldInputType === "verticalswitch") ? (1) : (0))
    });
  }

  onclick_proceed_to_define_field_fields = () => {
    this.setState({s_processStep:"fieldFields"});
  }

  onclick_return_to_select_field_input_type = () => {
    this.setState({
      s_processStep: "fieldType",
      s_selectedFieldInputType: undefined,
      s_selectedFieldInputTypeDisplay: undefined,
      s_selectedFieldTypeObj: undefined,
    });
  }

  onchange_new_capture_field_name = (i_newValue) => {
    var newCaptureFieldName = i_newValue;
    var newCaptureFieldNameErrorMessage = undefined;
    if(i_newValue.length > 50) {
      newCaptureFieldName = i_newValue.substring(0, 51);
      newCaptureFieldNameErrorMessage = "Capture Field Name has a maximum length requirement of 50 characters";
    }
    else { //loop through all existing fields to check that the new dbName created will be unique
      const newCaptureFieldDbName = JSFUNC.db_name_from_display_name(newCaptureFieldName);
      if(JSFUNC.in_array(newCaptureFieldDbName, this.props.AdminMobx.c_allFieldDbNamesArray)) {
        newCaptureFieldNameErrorMessage = "Capture Field Name '" + newCaptureFieldName + "' already exists. Each field must have a unique name.";
      }
    }

    this.setState({
      s_newCaptureFieldName: newCaptureFieldName,
      s_newCaptureFieldNameErrorMessage: newCaptureFieldNameErrorMessage
    });
  }

  onswitch_textarea_max_lines = () => {
    const newTextareaMaxLines = ((this.state.s_textareaMaxLines > 0) ? (0) : (10)); //default of 10 lines when turned on (0 is the flag for turned off)
    this.onchange_textarea_max_lines(newTextareaMaxLines);
  }

  onswitch_num_decimals = () => {
    const s_selectedFieldTypeObj = this.state.s_selectedFieldTypeObj;
    const s_numDecimals = this.state.s_numDecimals;

    var newNumDecimals = -1; //if the switch is already on, turn it off
    if(s_numDecimals < 0) { //turn the switch on to a default value
      if(s_selectedFieldTypeObj.hasZeroPaddingOptionTF) { //if the selected field type for this new capture field is "int_positive", can use this decimals/digits value to specify the number of zero padded digits, default to 5 where ID 103 -> "00103"
        newNumDecimals = 5;
      }
      else { //default of 2 decimal places when turned on (-1 is the flag for turned off)
        newNumDecimals = 2;
      }

    }
    this.onchange_num_decimals(newNumDecimals);
  }

  onchange_textarea_max_lines = (i_newValue) => { this.setState({s_textareaMaxLines:i_newValue}); }
  onchange_verticalswitch_example_value = (i_newValue) => { this.setState({s_verticalSwitchExampleValue:i_newValue}); }
  onchange_num_decimals = (i_newValue) => { this.setState({s_numDecimals:i_newValue}); }
  onchange_hover_text = (i_newValue) => { this.setState({s_hoverText:i_newValue}); }
  onchange_display_name_styling_string_comma = (i_newValue) => { this.setState({s_displayNameStylingStringComma:i_newValue}); }
  onchange_codeword_names_comma = (i_newValue) => { this.setState({s_codewordNamesComma:i_newValue}); }

  onclick_create_new_generic_capture_field_or_proceed_to_select_data_source = () => {
    if(this.state.s_newCaptureFieldName === "") {
      this.setState({s_newCaptureFieldNameErrorMessage: "The new Capture Field name cannot be blank"});
    }
    else if(this.state.s_newCaptureFieldNameErrorMessage === undefined) {
      if(this.state.s_selectedFieldTypeObj.requiresSelectWithSearchDataObjTF) {
        this.setState({s_processStep: "chooseSelectDataSource"});
      }
      else {
        this.create_new_capture_field_from_state("close");
      }
    }
  }

  create_new_capture_field_from_state = (i_onFinishProcessStepOrClose) => {
    this.setState({s_phpIsCreatingNewCaptureFieldTF:true});

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - AddEditDeleteTableAddNewCaptureFieldFloatingBox", "create_new_capture_field_from_state", ["i_onFinishProcessStepOrClose"], [i_onFinishProcessStepOrClose]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const selectedFieldTypeObj = this.state.s_selectedFieldTypeObj;

    var tblCapturesFieldsDisplayName = this.state.s_newCaptureFieldName;
    var tblCapturesFieldsDbName = JSFUNC.db_name_from_display_name(tblCapturesFieldsDisplayName);
    var tblCapturesFieldsInputType = this.state.s_selectedFieldInputType;
    var tblCapturesFieldsAdminSort = JSFUNC.sort_max_mysqli_int();
    var tblCapturesFieldsTextareaMaxLines = this.state.s_textareaMaxLines;
    var tblCapturesFieldsNumDecimals = this.state.s_numDecimals;
    var tblCapturesFieldsHoverText = this.state.s_hoverText;
    var tblCapturesFieldsDisplayNameStylingStringComma = this.state.s_displayNameStylingStringComma;
    var tblCapturesFieldsCodewordNamesComma = this.state.s_codewordNamesComma;
    var selectExistingSourceDataTblNameOrNew = this.state.s_selectExistingSourceDataTblNameOrNew;

    //set the textarea max lines to 0 if it is "" or a negative number
    if(!JSFUNC.is_number(tblCapturesFieldsTextareaMaxLines) || (tblCapturesFieldsTextareaMaxLines < 0)) {
      tblCapturesFieldsTextareaMaxLines = 0;
    }

    //the codewordNamesComma is initialized as undefined, which actually represents "" to go to the database
    if(tblCapturesFieldsCodewordNamesComma === undefined) {
      tblCapturesFieldsCodewordNamesComma = "";
    }

    //if creating a select with a new options dataset, create the tbl_cap table
    var tblCapturesFieldsSelectTblName = ""; //blank for all non-select field types
    if(selectedFieldTypeObj.requiresSelectWithSearchDataObjTF) {
      if(selectExistingSourceDataTblNameOrNew === "newTblCapName" || selectExistingSourceDataTblNameOrNew === "newTblCapSort") { //creating a new tbl_cap
        var snLetter = undefined;
        var hasSortTF = false;
        if(selectExistingSourceDataTblNameOrNew === "newTblCapSort") {
          snLetter = "s";
          hasSortTF = true;
        }
        else {
          snLetter = "n";
        }
        const newTblCapName = "tbl_cap_" + snLetter + "_" + tblCapturesFieldsDbName; //tbl_cap_n_db_name or tbl_cap_s_db_name
        C_CallPhpTblUID.add_db_create_tbl_cap_table(newTblCapName, hasSortTF);

        tblCapturesFieldsSelectTblName = newTblCapName;

        //set the state to know the new tbl_cap table name to use when populating the aedTable to add options
        this.setState({s_newlyCreatedTblCapTblName:newTblCapName});
      }
      else { //using an existing tbl_cap (chosen tblName is stored in state)
        tblCapturesFieldsSelectTblName = selectExistingSourceDataTblNameOrNew;
      }
    }

    //create the tbl_captures_field record
    const fieldNamesArray = ["display_name", "db_name", "input_type", "select_tbl_name", "admin_sort", "textarea_details_max_lines", "num_decimals", "hover_text", "display_name_styling_string_comma", "codeword_names_comma"];
    const valuesArray = [tblCapturesFieldsDisplayName, tblCapturesFieldsDbName, tblCapturesFieldsInputType, tblCapturesFieldsSelectTblName, tblCapturesFieldsAdminSort, tblCapturesFieldsTextareaMaxLines , tblCapturesFieldsNumDecimals, tblCapturesFieldsHoverText, tblCapturesFieldsDisplayNameStylingStringComma, tblCapturesFieldsCodewordNamesComma];
    const idsbArray = ["s", "s", "s", "s", "i", "i", "i", "s", "s", "s"];
    const resortSortColumnName = "admin_sort";
    const resortFilterFieldNameOrFieldNamesArray = [];
    const resortFilterValueOrValuesArray = [];
    C_CallPhpTblUID.add_insert("tbl_captures_fields", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);

    //alter tbl_captures to add a new column to the table with this new field dbName as the column name
    C_CallPhpTblUID.add_alter_db_tbl_insert_column("tbl_captures", tblCapturesFieldsDbName, selectedFieldTypeObj.mysqlColumnDataType, selectedFieldTypeObj.blankValue);

    //move to the next step in the process once complete
    const functionOnSuccess = (i_parseResponse) => {
      this.setState({s_newlyCreatedCaptureFieldID:i_parseResponse.outputObj.i0});
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    const functionOnFinish = () => {
      this.setState({s_phpIsCreatingNewCaptureFieldTF:false});

      if(i_onFinishProcessStepOrClose === "close") {
        if(this.props.f_onClickClose) {
          this.props.f_onClickClose();
        }
      }
      else {
        this.setState({s_processStep:i_onFinishProcessStepOrClose});
      }
    }
    C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

    C_CallPhpTblUID.execute();
  }

  onclick_select_choose_create_new_tbl_cap_name = () => {
    const newValue = ((this.state.s_selectExistingSourceDataTblNameOrNew === "newTblCapName") ? (undefined) : ("newTblCapName"));
    this.setState({s_selectExistingSourceDataTblNameOrNew:newValue});
  }

  onclick_select_choose_create_new_tbl_cap_sort = () => {
    const newValue = ((this.state.s_selectExistingSourceDataTblNameOrNew === "newTblCapSort") ? (undefined) : ("newTblCapSort"));
    this.setState({s_selectExistingSourceDataTblNameOrNew:newValue});
  }

  onclick_select_existing_data_table_choice = (i_newValue) => {
    this.setState({s_selectExistingSourceDataTblNameOrNew:i_newValue});
  }

  onclick_create_new_select_capture_field_proceed_to_create_options = () => {
    const selectExistingSourceDataTblNameOrNew = this.state.s_selectExistingSourceDataTblNameOrNew;
    const onFinishProcessStepOrClose = ((selectExistingSourceDataTblNameOrNew === "newTblCapName" || selectExistingSourceDataTblNameOrNew === "newTblCapSort") ? ("createOptionsForSelectType") : ("close"));
    this.create_new_capture_field_from_state(onFinishProcessStepOrClose);
  }

  render() {
    const processStep = this.state.s_processStep;
    const selectedFieldInputType = this.state.s_selectedFieldInputType;
    const selectedFieldInputTypeDisplay = this.state.s_selectedFieldInputTypeDisplay;
    const selectedFieldTypeObj = this.state.s_selectedFieldTypeObj;
    const newCaptureFieldName = this.state.s_newCaptureFieldName;
    const textareaMaxLines = this.state.s_textareaMaxLines;
    const s_verticalSwitchExampleValue = this.state.s_verticalSwitchExampleValue;
    const numDecimals = this.state.s_numDecimals;
    const s_hoverText = this.state.s_hoverText;
    const s_displayNameStylingStringComma = this.state.s_displayNameStylingStringComma;
    const codewordNamesComma = this.state.s_codewordNamesComma;
    const newCaptureFieldNameErrorMessage = this.state.s_newCaptureFieldNameErrorMessage;
    const s_phpIsCreatingNewCaptureFieldTF = this.state.s_phpIsCreatingNewCaptureFieldTF;
    const newlyCreatedCaptureFieldID = this.state.s_newlyCreatedCaptureFieldID;
    const selectExistingSourceDataTblNameOrNew = this.state.s_selectExistingSourceDataTblNameOrNew;
    const newlyCreatedTblCapTblName = this.state.s_newlyCreatedTblCapTblName;

    const p_aedTableObj = this.props.p_aedTableObj;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_cardNameDetails = this.props.DatabaseMobx.c_cardNameDetails;

    var processInstructions = undefined;
    var processComponent = null;
    var useSaveTrueCancelFalse = false; //icon in top right corner of floating box, can cancel during process, but should be a save when finally adding options to a newly created select tbl_cap
    if(processStep === "fieldType") {
      processInstructions = "First, choose the Input Type for this new Capture Field, then proceed to the next step at the bottom (click the pencil icon next to each 'Example Field' to test out the edit interface.)";
      const selectFieldTypeIsTwoColumnTF = (this.props.CaptureExecMobx.o_mediaQueryFlag >= 4);
      const textChoices = (
        <div className="hugeBottomMargin">
          <NewCaptureFieldTypeDivider p_label="'Text' Field Input Types" />
          {["text", "textarea", "email", "phone", "website"].map((m_fieldInputType) =>
            <NewCaptureFieldTypeChoice key={m_fieldInputType} p_fieldInputType={m_fieldInputType} p_selectedFieldInputType={selectedFieldInputType} f_onClick={this.onclick_choose_field_input_type} />
          )}
        </div>
      );
      const numericChoices = (
        <div className="hugeBottomMargin">
          <NewCaptureFieldTypeDivider p_label="'Numeric' Field Input Types" />
          {["int", "int_positive", "decimal", "decimal_positive", "percent_int", "percent_int_0to100", "percent_decimal", "percent_decimal_0to100", "money", "money_cents"].map((m_fieldInputType) =>
            <NewCaptureFieldTypeChoice key={m_fieldInputType} p_fieldInputType={m_fieldInputType} p_selectedFieldInputType={selectedFieldInputType} f_onClick={this.onclick_choose_field_input_type} />
          )}
        </div>
      );
      const otherChoices = (
        <div className="hugeBottomMargin">
          <NewCaptureFieldTypeDivider p_label="'Other' Field Input Types" />
          {["color"].map((m_fieldInputType) =>
            <NewCaptureFieldTypeChoice key={m_fieldInputType} p_fieldInputType={m_fieldInputType} p_selectedFieldInputType={selectedFieldInputType} f_onClick={this.onclick_choose_field_input_type} />
          )}
        </div>
      );
      const dateChoices = (
        <div className="hugeBottomMargin">
          <NewCaptureFieldTypeDivider p_label="'Date' Field Input Types" />
          {["date", "date_DMjY", "date_dayMdyDaysUntil1", "date_dayMdyDaysUntil1Overdue", "date_dayMdyDaysUntil2", "date_dayMdyDaysUntil2Overdue", "dateWithDuration"].map((m_fieldInputType) =>
            <NewCaptureFieldTypeChoice key={m_fieldInputType} p_fieldInputType={m_fieldInputType} p_selectedFieldInputType={selectedFieldInputType} f_onClick={this.onclick_choose_field_input_type} />
          )}
        </div>
      );
      const dateTimeChoices = (
        <div className="hugeBottomMargin">
          <NewCaptureFieldTypeDivider p_label="'Date/Time' Field Input Types" />
          {["datetime", "datetime_MjYgiA", "datetime_dayMdyDaysUntil1", "datetime_dayMdyDaysUntil1Overdue", "datetime_dayMdyDaysUntil2", "datetime_dayMdyDaysUntil2Overdue"].map((m_fieldInputType) =>
            <NewCaptureFieldTypeChoice key={m_fieldInputType} p_fieldInputType={m_fieldInputType} p_selectedFieldInputType={selectedFieldInputType} f_onClick={this.onclick_choose_field_input_type} />
          )}
        </div>
      );
      const selectChoices = (
        <div className="hugeBottomMargin">
          <NewCaptureFieldTypeDivider p_label="'Select' Field Input Types" />
          {["select", "selectmulti", "sharedpercent", "selectadd", "selectmultiadd", "sharedpercentadd", "verticalswitch"].map((m_fieldInputType) =>
            <NewCaptureFieldTypeChoice key={m_fieldInputType} p_fieldInputType={m_fieldInputType} p_selectedFieldInputType={selectedFieldInputType} f_onClick={this.onclick_choose_field_input_type} />
          )}
        </div>
      );

      processComponent = (
        <div className="flex11a displayFlexColumn">
          <div className="flex11a yScroll yScrollBottomPad bgLighterGray">
            {(selectFieldTypeIsTwoColumnTF) ? (
              <div className="displayFlexRow">
                <div className="flex11a lrMedPad" style={{flexBasis:"100em", borderRight:"solid 3px #ddd"}}>
                  {textChoices}
                  {numericChoices}
                  {otherChoices}
                </div>
                <div className="flex11a lrMedPad" style={{flexBasis:"100em", borderRight:"solid 3px #ddd"}}>
                  {dateChoices}
                  {dateTimeChoices}
                </div>
                <div className="flex11a lrMedPad" style={{flexBasis:"100em"}}>
                  {selectChoices}
                </div>
              </div>
            ) : (
              <div className="lrMedPad">
                {textChoices}
                {numericChoices}
                {otherChoices}
                {dateChoices}
                {dateTimeChoices}
                {selectChoices}
              </div>
            )}
          </div>
          <div className="flex00a displayFlexRowHcVc tbPad borderT1ddd">
            {(selectedFieldInputType !== undefined) &&
              <div className="lrMedPad">
                <CEGeneralReact.CEButton p_type="blue" p_text="Proceed to Next Step Defining the Capture Field" f_onClick={this.onclick_proceed_to_define_field_fields} />
              </div>
            }
            <div className="lrMedPad">
              <CEGeneralReact.CEButton p_type="gray" p_text="Cancel" f_onClick={this.props.f_onClickClose} />
            </div>
          </div>
        </div>
      );
    }
    else if(processStep === "fieldFields") {
      processInstructions = "Next, name this new Capture Field and fill out any other information about it";
      processComponent = (
        <div className="flex11a displayFlexColumn">
          <div className="flex11a yScroll yScrollBottomPad">
            <div className="smallFullPad borderB1ddd">
              <font className="font12 fontItalic">{"Input Type for this new Capture Field: "}</font>
              <font className="font14 fontBold fontTextLighter">{selectedFieldInputTypeDisplay}</font>
            </div>
            <div className="medFullPad">
              <div className="displayFlexRowVc border1bbb borderRadius05 bgLighterGray medFullPad">
                <div className="flex00a rMargin">
                  <font className="font12 fontBlue">
                    {"New Capture Field Name"}
                  </font>
                </div>
                <div className="flex11a lrMargin">
                  <LibraryReact.Text
                    p_value={newCaptureFieldName}
                    p_styleObj={{maxWidth:"30em", width:"100%"}}
                    p_tabIndex={1}
                    p_placeholder="Enter a name for this new Capture Field..."
                    p_errorTF={(newCaptureFieldNameErrorMessage !== undefined)}
                    f_onChange={this.onchange_new_capture_field_name}
                  />
                </div>
              </div>
              {(newCaptureFieldNameErrorMessage !== undefined) &&
                <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={newCaptureFieldNameErrorMessage} />
              }
              {((selectedFieldTypeObj !== undefined) && selectedFieldTypeObj.textareaTF) &&
                <>
                  <div className="massiveTopMargin microBottomMargin">
                    <font className="font12">
                      {"Textarea Field Maximum # of Lines Displayed on " + c_cardNameDetails + " Card"}
                    </font>
                  </div>
                  <CEGeneralReact.BulletList
                    p_linesArray={["If left off (0 lines), the field will display the full text in the " + c_cardNameDetails + " Card regardless of how long it is", "If turned on, the text will be cut off at a fixed number of lines", "This will not affect how the data is stored or edited in any way, but can be used to ensure other Details fields are not pushed off the screen by an exceptionally long block of text"]}
                    p_fontClass="fontItalic fontTextLighter"
                    p_dashFontClass="fontTextLighter"
                  />
                  <div className="displayFlexRowVc smallTopMargin">
                    <div className="flex00a">
                      <LibraryReact.Switch
                        p_isOnTF={(textareaMaxLines > 0)}
                        f_onClick={this.onswitch_textarea_max_lines}
                      />
                    </div>
                    {(textareaMaxLines > 0) ? (
                      <>
                        <div className="flex00a lrMargin">
                          <font className="font11">
                            {"Displayed text has a maximum height of "}
                          </font>
                        </div>
                        <div className="flex00a">
                          <LibraryReact.Integer
                            p_value={textareaMaxLines}
                            p_min={0}
                            p_max={JSFUNC.sort_max_mysqli_int()}
                            p_styleObj={{width:"5em"}}
                            f_onChange={this.onchange_textarea_max_lines}
                          />
                        </div>
                        <div className="flex11a lrMargin">
                          <font className="font11">
                            {" lines on the " + c_cardNameDetails + " Card"}
                          </font>
                        </div>
                      </>
                    ) : (
                      <div className="flex11a lrMedMargin">
                        <font className="font11">
                          {"Full text is displayed with no limit"}
                        </font>
                      </div>
                    )}
                  </div>
                </>
              }
              {((selectedFieldTypeObj !== undefined) && selectedFieldTypeObj.verticalSwitchTF) &&
                <div className="displayFlexRow massiveTopMargin">
                  <div className="flex00a" style={{flexBasis:"20em"}}>
                    <div className="microBottomMargin">
                      <font className="font12">
                        {"Vertical Switch height of each option when edited"}
                      </font>
                    </div>
                    <div className="displayFlexRowVc">
                      <div className="flex00a lrMargin">
                        <font className="font11">
                          {"# lines displayed:"}
                        </font>
                      </div>
                      <div className="flex00a">
                        <LibraryReact.Integer
                          p_value={textareaMaxLines}
                          p_min={0}
                          p_max={JSFUNC.sort_max_mysqli_int()}
                          p_styleObj={{width:"5em"}}
                          f_onChange={this.onchange_textarea_max_lines}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex00a" style={{flexBasis:"28em", marginLeft:"2em"}}>
                    <CEGeneralReact.GenericInputOrSelectFromInputType
                      p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Example Option", [1,2,3], false, ["Example Option #1", "Example Option #2 with a significantly longer text line to show how this Vertical Switch option box when editing is affected by the maximum height setting that you can select here.", "Example Option #3"], (textareaMaxLines * 1.5))}
                      p_valueRaw={s_verticalSwitchExampleValue}
                      f_onChangeOrOnSelect={this.onchange_verticalswitch_example_value}
                    />
                  </div>
                </div>
              }
              {((selectedFieldTypeObj !== undefined) && (selectedFieldTypeObj.hasTrimDecimalsOptionTF || selectedFieldTypeObj.hasZeroPaddingOptionTF)) &&
                <>
                  <div className="massiveTopMargin microBottomMargin">
                    <font className="font12">
                      {((selectedFieldTypeObj.hasZeroPaddingOptionTF) ? ("Integer (Positive only) Field Optional Zero-Padded # of Digits") : ("Decimal Field Maximum # of Decimal Places Displayed on " + c_cardNameDetails + " Card"))}
                    </font>
                  </div>
                  <CEGeneralReact.BulletList
                    p_linesArray={[
                      "If turned off, the field will display " + ((selectedFieldTypeObj.hasZeroPaddingOptionTF) ? ("the normal integer number (like 103)") : ("the full number of entered decimal places (up to 9)")),
                      "If turned on, the displayed number will be " + ((selectedFieldTypeObj.hasZeroPaddingOptionTF) ? ("zero-padded to the specified number of digits (like 00103 for 5 digits)") : ("cut off at a fixed number of decimal places")),
                      "This will not affect how the data is stored or edited in any way"
                    ]}
                    p_fontClass="fontItalic fontTextLighter"
                    p_dashFontClass="fontTextLighter"
                  />
                  <div className="displayFlexRowVc smallTopMargin">
                    <div className="flex00a">
                      <LibraryReact.Switch
                        p_isOnTF={(numDecimals >= 0)}
                        f_onClick={this.onswitch_num_decimals}
                      />
                    </div>
                    {(numDecimals >= 0) ? (
                      <>
                        <div className="flex00a lrMargin">
                          <font className="font11">
                            {"Displayed number has " + ((selectedFieldTypeObj.hasZeroPaddingOptionTF) ? ("") : ("a maximum of"))}
                          </font>
                        </div>
                        <div className="flex00a">
                          <LibraryReact.Integer
                            p_value={numDecimals}
                            p_min={0}
                            p_max={9}
                            p_styleObj={{width:"5em"}}
                            f_onChange={this.onchange_num_decimals}
                          />
                        </div>
                        <div className="flex11a lrMargin">
                          <font className="font11">
                            {((selectedFieldTypeObj.hasZeroPaddingOptionTF) ? ("digits with zero-padding if necessary") : ("decimal places"))}
                          </font>
                        </div>
                      </>
                    ) : (
                      <div className="flex11a lrMedMargin">
                        <font className="font11">
                          {((selectedFieldTypeObj.hasZeroPaddingOptionTF) ? ("Normal number is displayed") : ("Full number is displayed with all entered decimal places"))}
                        </font>
                      </div>
                    )}
                  </div>
                </>
              }
              <div className="massiveTopMargin">
                <font className="font12">
                  {"(optional) Capture Field Hover Help Text"}
                </font>
              </div>
              <div className="microTopMargin">
                <font className="fontItalic fontTextLighter">
                  {"This text will show if the user hovers their mouse over the blue name of the field on the " + c_cardNameDetails + " Card"}
                </font>
              </div>
              <div className="smallTopMargin" style={{maxWidth:"30em"}}>
                <LibraryReact.Textarea
                  p_value={s_hoverText}
                  p_styleObj={{height:"6em"}}
                  p_tabIndex={3}
                  p_placeholder="Enter the hover help text..."
                  f_onChange={this.onchange_hover_text}
                />
              </div>
              {(false) &&
                <>
                  <div className="massiveTopMargin">
                    <font className="font12">
                      {"(optional) Report/Template Codeword Alternatives"}
                    </font>
                  </div>
                  <div className="microTopMargin">
                    <CEGeneralReact.BulletList
                      p_linesArray={[
                        "By default, when referencing a particular capture field to place into a Template or Report, two options are provided to place into the template (surrounded by double square brackets [[ ... ]]) to be replaced:",
                        " 1. [[contract_overall_value]] - an all lowercase version of the field name with underscores",
                        " 2. [[Contract Overall Value]] - the full name of the field (capitalization does not matter)",
                        "This optional alternative codewords interface allows the admin to specify extra alternative field codewords to use in Template and Reports to reference fields",
                        "An example for Contract Overall Value could be the admin would type 'COV' as an alternative codeword below (do not type the quotes). Then, in the Templates, using [[cov]] or [[COV]] would get that value"
                      ]}
                      p_fontClass="fontItalic fontTextLighter"
                      p_dashFontClass="fontTextLighter"
                    />
                  </div>
                  <div className="smallTopMargin">
                    <CreateNewFieldCodewordAlternativesAddEdit
                      p_newCaptureFieldName={newCaptureFieldName}
                      p_codewordNamesComma={codewordNamesComma}
                      f_onChange={this.onchange_codeword_names_comma}
                    />
                  </div>
                </>
              }
            </div>
          </div>
          <div className="flex00a displayFlexRow tbPad borderT1ddd">
            <div className="flex00a displayFlexColumnHcVc lrMedPad borderR1ddd">
              <CEGeneralReact.CEButton p_type="add" p_text="Go Back" f_onClick={this.onclick_return_to_select_field_input_type} />
            </div>
            <div className="flex11a displayFlexColumnHcVc">
              {(s_phpIsCreatingNewCaptureFieldTF) ? (
                <CEGeneralReact.PhpWorkingMessage p_text="Creating New Capture Field..." />
              ) : (
                (newCaptureFieldNameErrorMessage !== undefined) ? (
                  <font className="fontItalic fontTextLighter">
                    {"Cannot create this Capture Field until a unique Field Name is entered above"}
                  </font>
                ) : (
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text={((selectedFieldTypeObj && selectedFieldTypeObj.requiresSelectWithSearchDataObjTF) ? ("Proceed to Next Step Choosing Select Source Data") : ("Create This Capture Field"))}
                    f_onClick={this.onclick_create_new_generic_capture_field_or_proceed_to_select_data_source}
                  />
                )
              )}
            </div>
          </div>
        </div>
      );
    }
    else if(processStep === "chooseSelectDataSource") {
      const selectedNewTblCapNameTF = (selectExistingSourceDataTblNameOrNew === "newTblCapName");
      const selectedNewTblCapSortTF = (selectExistingSourceDataTblNameOrNew === "newTblCapSort");
      const selectedExistingTableTF = (!selectedNewTblCapNameTF && !selectedNewTblCapSortTF);

      var bottomButtonComponent = null;
      if(s_phpIsCreatingNewCaptureFieldTF) {
        bottomButtonComponent = (<CEGeneralReact.PhpWorkingMessage p_text="Creating New Capture Field..." />);
      }
      else if(selectExistingSourceDataTblNameOrNew === undefined) {
        bottomButtonComponent = (<font className="font12 fontItalic">{"Choose the Source Data Above for this new Select Capture Field"}</font>);
      }
      else {
        bottomButtonComponent = (
          <CEGeneralReact.CEButton
            p_type="blue"
            p_text={((selectedExistingTableTF) ? ("Create This Capture Field") : ("Proceed to Next Step Creating New Select Options"))}
            f_onClick={this.onclick_create_new_select_capture_field_proceed_to_create_options}
          />
        );
      }
      
      const c_valueDisplayArraysObjCaptures = this.props.DatabaseMobx.c_valueDisplayArraysObjCaptures;
      const cmSwsDataObj = this.props.DatabaseMobx.c_fieldMapOfCaptureManagers.get("fieldTypeObj").selectWithSearchDataObj;
      const doSwsDataObj = this.props.DatabaseMobx.c_fieldMapOfDivisionOwners.get("fieldTypeObj").selectWithSearchDataObj;

      //start with contact tbls and users/divisions
      var existingSystemTblsArrayOfObjs = [
        {tblName:"tbl_captures", tblDisplayName:"Captures", valueArray:c_valueDisplayArraysObjCaptures.valueArray, displayArray:c_valueDisplayArraysObjCaptures.displayArray, numOptions:c_valueDisplayArraysObjCaptures.valueArray.length},
        {tblName:"tbl_g_contacts_persons", tblDisplayName:"Contact Persons", valueArray:[1], displayArray:["--All Contact Persons in the Contacts System--"], numOptions:this.props.DatabaseMobx.o_tbl_g_contacts_persons.size},
        {tblName:"tbl_g_contacts_companies", tblDisplayName:"Contact Companies", valueArray:[1], displayArray:["--All Contact Companies in the Contacts System--"], numOptions:this.props.DatabaseMobx.o_tbl_g_contacts_companies.size},
        {tblName:"tbl_a_users", tblDisplayName:c_productStylingObj.productName + " Users", valueArray:cmSwsDataObj.valueArray, displayArray:cmSwsDataObj.displayArray, numOptions:cmSwsDataObj.valueArray.length},
        {tblName:"tbl_a_divisions", tblDisplayName:"Divisions", valueArray:doSwsDataObj.valueArray, displayArray:doSwsDataObj.displayArray, numOptions:doSwsDataObj.valueArray.length}
      ];

      //add contact company field admin tbls to be able to point to
      const adminSelectTblLabelsArray = [
        "Contact Company Business Types",
        "Contact Company Set-Asides (Small Business Certifications)",
        "Contact Company Capabilities",
        "Contact Company NAICS Codes"
      ];
      const adminSelectTblNamesArray = [
        "tbl_a_business_types",
        "tbl_bit_master_set_asides",
        "tbl_a_capabilities",
        "tbl_bit_master_naics_codes"
      ];
      for(let t = 0; t < adminSelectTblNamesArray.length; t++) {
        var tblName = adminSelectTblNamesArray[t];
        var tblLabel = adminSelectTblLabelsArray[t];
        var tblRef = this.props.DatabaseMobx.tbl_ref_from_tbl_name(tblName);
        var valueArray = JSFUNC.get_column_vector_from_mapOfMaps(tblRef, "id");
        var displayArray = JSFUNC.get_column_vector_from_mapOfMaps(tblRef, ((tblName === "tbl_bit_master_naics_codes") ? ("title") : ("name")));
        existingSystemTblsArrayOfObjs.push({tblName:tblName, tblDisplayName:tblLabel, valueArray:valueArray, displayArray:displayArray, numOptions:tblRef.size});
      }

      //add all custom tbl_cap tbls
      var existingCustomCapTblsArrayOfObjs = [];
      for(let fieldMap of this.props.DatabaseMobx.c_tbl_captures_fields.values()) {
        var fieldDbName = fieldMap.get("db_name");
        var selectTblName = fieldMap.get("select_tbl_name");
        var selectTblNameFirst10Chars = selectTblName.substring(0, 10);
        if(selectTblNameFirst10Chars === "tbl_cap_n_" || selectTblNameFirst10Chars === "tbl_cap_s_") {
          var displayName = fieldMap.get("display_name");
          var fieldTypeObj = fieldMap.get("fieldTypeObj");
          var selectTblName = fieldMap.get("select_tbl_name");
          if((fieldTypeObj !== undefined) && (fieldTypeObj.selectWithSearchDataObj !== undefined) && JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.valueArray)) {
            var existingTableObj = {
              tblName: selectTblName,
              tblDisplayName: displayName,
              valueArray: fieldTypeObj.selectWithSearchDataObj.valueArray,
              displayArray: fieldTypeObj.selectWithSearchDataObj.displayArray,
              numOptions: fieldTypeObj.selectWithSearchDataObj.valueArray.length
            };
            existingCustomCapTblsArrayOfObjs.push(existingTableObj);
          }
        }
      }
      JSFUNC.sort_arrayOfObjs(existingCustomCapTblsArrayOfObjs, "tblDisplayName", true);

      //find the selected existing tbl if one is currently selected
      var selectedExistingTableObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(existingSystemTblsArrayOfObjs, "tblName", selectExistingSourceDataTblNameOrNew);
      if(selectedExistingTableObj === undefined) {
        selectedExistingTableObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(existingCustomCapTblsArrayOfObjs, "tblName", selectExistingSourceDataTblNameOrNew);
      }

      processInstructions = "For this new Select field, choose whether you will create a new list of options, or point to an existing set of options";
      processComponent = (
        <div className="flex11a displayFlexColumn">
          <div className="flex00a medFullPad">
            <font className="font16 fontItalic">{"New Select Field: "}</font>
            <font className="font16 fontBold fontBlue">{newCaptureFieldName}</font>
          </div>
          <div className="flex11a displayFlexRow medBottomPad lrMedPad">
            <NewCaptureSelectFieldSplitPageShell p_label="Create a New List of Options (most common)">
              <div className="flex11a yScroll medFullPad">
                <NewCaptureSelectFieldCreateNewListOfOptionsButton
                  p_selectedTF={selectedNewTblCapNameTF}
                  p_label="Create new list of options sorted alphabetically"
                  p_subLabel="The options list is manually entered (or imported from a .csv) in the next step. The list is automatically sorted alphabetically."
                  f_onClick={this.onclick_select_choose_create_new_tbl_cap_name}
                />
                <div className="bigTopMargin" />
                <NewCaptureSelectFieldCreateNewListOfOptionsButton
                  p_selectedTF={selectedNewTblCapSortTF}
                  p_label="Create new list of options with separate sort column"
                  p_subLabel="The options list is manually entered (or imported from a .csv) in the next step. There is an extra column for the list to be sorted non-alphabetically."
                  f_onClick={this.onclick_select_choose_create_new_tbl_cap_sort}
                />
              </div>
            </NewCaptureSelectFieldSplitPageShell>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"6em"}}>
              <font className="font20 fontItalic fontTextLighter">
                {"OR"}
              </font>
            </div>
            <NewCaptureSelectFieldSplitPageShell p_label="Point to an Existing Set of Options">
              <div className="flex11a displayFlexRow">
                <div className="flex11a yScroll medFullPad borderR1bbb" style={{flexBasis:"100em"}}>
                  {(!JSFUNC.in_array(selectedFieldInputType, ["selectadd", "selectmultiadd", "sharedpercentadd"])) &&
                    <>
                      <div className="smallBottomMargin">
                        <font className="font14 fontItalic fontBlue">
                          {"System Datasets"}
                        </font>
                      </div>
                      {existingSystemTblsArrayOfObjs.map((m_tblObj) =>
                        <NewCaptureSelectFieldExistingDataTableChoice
                          p_tblName={m_tblObj.tblName}
                          p_tblDisplayName={m_tblObj.tblDisplayName}
                          p_numOptions={m_tblObj.numOptions}
                          p_selectedTblName={selectExistingSourceDataTblNameOrNew}
                          f_onClick={this.onclick_select_existing_data_table_choice}
                        />
                      )}
                      <div className="hugeTopMargin" />
                    </>
                  }
                  <div className="smallBottomMargin">
                    <font className="font14 fontItalic fontBlue">
                      {"User Made Datasets"}
                    </font>
                  </div>
                  {existingCustomCapTblsArrayOfObjs.map((m_tblObj) =>
                    <NewCaptureSelectFieldExistingDataTableChoice
                      p_tblName={m_tblObj.tblName}
                      p_tblDisplayName={m_tblObj.tblDisplayName}
                      p_numOptions={m_tblObj.numOptions}
                      p_selectedTblName={selectExistingSourceDataTblNameOrNew}
                      f_onClick={this.onclick_select_existing_data_table_choice}
                    />
                  )}
                </div>
                <div className="flex11a yScroll medFullPad" style={{flexBasis:"100em"}}>
                  <div className="smallBottomMargin">
                    <font className="font14 fontItalic fontTextLighter">
                      {"Click an Existing Dataset to view its Options"}
                    </font>
                  </div>
                  {(selectedExistingTableObj !== undefined) &&
                    <div className="medFullPad bgWhite border1bbb">
                      <div className="medBottomMargin">
                        <font className="font13 fontItalic fontTextLight">
                          {"Options for '" + selectedExistingTableObj.tblDisplayName + "':"}
                        </font>
                      </div>
                      {selectedExistingTableObj.displayArray.map((m_display, m_index) =>
                        <div key={selectedExistingTableObj.valueArray[m_index]} className="tbMicroMargin">
                          <font className="">
                            {m_display}
                          </font>
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            </NewCaptureSelectFieldSplitPageShell>
          </div>
          <div className="flex00a displayFlexRow tbPad borderT1ddd">
            <div className="flex00a displayFlexColumnHcVc lrMedPad borderR1ddd">
              <CEGeneralReact.CEButton p_type="add" p_text="Go Back" f_onClick={this.onclick_proceed_to_define_field_fields} />
            </div>
            <div className="flex11a displayFlexColumnHcVc">
              {bottomButtonComponent}
            </div>
          </div>
        </div>
      );
    }
    else if(processStep === "createOptionsForSelectType") {
      var tblCapAedTableComponent = null;
      const tblCapMapOfMaps = this.props.DatabaseMobx.tbl_ref_from_tbl_name(newlyCreatedTblCapTblName);
      if(tblCapMapOfMaps === undefined) {
        tblCapAedTableComponent = (
          <div>
            <font className="font12 fontItalic fontTextLighter">
              {"--The reference table for this new field was not created successfully (" + newlyCreatedTblCapTblName + ")--"}
            </font>
          </div>
        );
      }
      else {
        const selectTblCapOptionsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(tblCapMapOfMaps);

        var selectTblCapsAedTableObj = undefined;
        if(selectExistingSourceDataTblNameOrNew === "newTblCapSort") {
          selectTblCapsAedTableObj = {
            tblName: newlyCreatedTblCapTblName,
            dragToResortFieldDbName: "sort",
            initialSortedFieldDbName: "sort",
            itemName: "Option",
            deleteItemNameFieldDbName: "name",
            searchFieldDbName: "name",
            addNewItemInlineTextInputFieldDbName: "name",
            importFromCsvIntoFieldDbName: "name",
            fieldsArrayOfObjs: [
              {width:"100em", dbName:"name", displayName:"Option", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true},
              {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
            ]
          };
        }
        else {
          selectTblCapsAedTableObj = {
            tblName: newlyCreatedTblCapTblName,
            initialSortedFieldDbName: "name",
            itemName: "Option",
            deleteItemNameFieldDbName: "name",
            searchFieldDbName: "name",
            addNewItemInlineTextInputFieldDbName: "name",
            importFromCsvIntoFieldDbName: "name",
            fieldsArrayOfObjs: [
              {width:"100em", dbName:"name", displayName:"Option", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true},
            ]
          };
        }

        tblCapAedTableComponent = (
          <AddEditDeleteTable
            p_dataArrayOfObjs={selectTblCapOptionsArrayOfObjs}
            p_aedTableObj={selectTblCapsAedTableObj}
          />
        );
      }

      processInstructions = "Finally, for this new custom Select field, add the Options that will appear in the field's pull-down menu for users to choose from";
      processComponent = (
        <div className="flex11a displayFlexColumn">
          <div className="flex11a displayFlexRow yScroll medFullPad">
            <div className="flex11a" style={{flexBasis:"100em"}} />
            <div className="flex11a" style={{flexBasis:"200em"}}>
              {tblCapAedTableComponent}
            </div>
            <div className="flex11a" style={{flexBasis:"100em"}} />
          </div>
          <div className="flex00a displayFlexColumnHcVc tbPad borderT1ddd">
            <CEGeneralReact.CEButton p_type="blue" p_text="Complete Process and Close" f_onClick={this.props.f_onClickClose} />
          </div>
        </div>
      );
      useSaveTrueCancelFalse = true;
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableNewCaptureField"
        p_title={"Adding a New " + p_aedTableObj.itemName}
        f_onClickSave={((useSaveTrueCancelFalse) ? (this.props.f_onClickClose) : (undefined))}
        f_onClickCancel={((useSaveTrueCancelFalse) ? (undefined) : (this.props.f_onClickClose))}>
        <div className="flex11a displayFlexColumn">
          <NewCaptureFieldOrDealShapingQuestionInstructionsBar p_label={processInstructions} />
          {processComponent}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


function NewCaptureFieldOrDealShapingQuestionInstructionsBar(props) { //props: p_label
  return(
    <div className="flex00a smallFullPad" style={{background:"linear-gradient(#edb,#dca)", borderBottom:"solid 1px #ba8"}}>
      <font className="font14 fontItalic fontText">
        {props.p_label}
      </font>
    </div>
  );
}


function NewCaptureFieldTypeDivider(props) { //props: p_label
  return(
    <div className="smallFullPad bgDarkGrayGradient fontWhite">
      <LibraryReact.Nowrap p_fontClass="font14 fontItalic">
        {props.p_label}
      </LibraryReact.Nowrap>
    </div>
  );
}

function NewCaptureSelectFieldSplitPageShell(props) { //props: p_label, children
  return(
    <div className="flex11a displayFlexColumn border1bbb" style={{flexBasis:"100em"}}>
      <div className="flex00a displayFlexColumn borderB1bbb tbMedPad bgLightGray textCenter">
        <font className="font17 fontBold fontItalic fontBlue">
          {props.p_label}
        </font>
      </div>
      <div className="flex11a displayFlexColumn bgLighterGray">
        {props.children}
      </div>
    </div>
  );
}

function NewCaptureSelectFieldCreateNewListOfOptionsButton (props) { //props: p_selectedTF, p_label, p_subLabel, f_onClick
  const selectedTF = props.p_selectedTF;
  const label = props.p_label;
  const subLabel = props.p_subLabel;
  return(
    <div
      className={"displayFlexColumnHcVc border bevelBorderColors " + ((selectedTF) ? ("ticketResponseFromBITUnread") : ("bgAlmostWhite hoverLighterBlueGradient cursorPointer"))}
      style={{height:"10em", padding:"0 5em"}}
      onClick={props.f_onClick}>
      <div>
        <font className="font15 fontBold fontTextLighter">
          {label}
        </font>
      </div>
      <div className="smallTopMargin">
        <font className="font12 fontItalic fontTextLighter">
          {subLabel}
        </font>
      </div>
    </div>
  )
}


const NewCaptureFieldTypeChoice = inject("DatabaseMobx")(observer(
class NewCaptureFieldTypeChoice extends Component { //props: p_fieldInputType, p_selectedFieldInputType, f_onClick
  constructor(props) {
    super(props);

    const fieldInputType = this.props.p_fieldInputType;

    const c_cardNameDates = this.props.DatabaseMobx.c_cardNameDates;

    var typeDescriptionArray = [];
    var fieldTypeObj = this.props.DatabaseMobx.c_genericTextFieldTypeObj;
    var exampleValueInit = "";
    if(fieldInputType === "text") {
      typeDescriptionArray = ["Best for short input of text data", "Editing has single line of input", "Can store approx 2,000 words, but is not ideal to use when typing more than ~15 words"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericTextFieldTypeObj;
      exampleValueInit = "Short sample text";
    }
    else if(fieldInputType === "textarea") {
      typeDescriptionArray = ["Best for long input of text data", "Editing has large input box in a separate window", "Can store approx 500,000 words"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericTextareaFieldTypeObj;
      exampleValueInit = "Long sample text to show the use of the textarea. This input can have multiple lines and multiple paragraphs.";
    }
    else if(fieldInputType === "email") {
      typeDescriptionArray = ["Displays email address with link to your native email system"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericEmailFieldTypeObj;
      exampleValueInit = "email.address@example.com";
    }
    else if(fieldInputType === "phone") {
      typeDescriptionArray = ["Displays phone number with link to you native dialing system"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericPhoneFieldTypeObj;
      exampleValueInit = "(555) 555-5555";
    }
    else if(fieldInputType === "website") {
      typeDescriptionArray = ["Displays a hyperlink that opens the specified website in a new tab", "Allows option for a user defined mask displayed instead of the address"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericWebsiteFieldTypeObj;
      exampleValueInit = "[[BIT Solutions Homepage]]http://www.bitsolutionsllc.com";
    }
    else if(fieldInputType === "int") {
      typeDescriptionArray = ["Numeric input of zero, positive, and negative whole numbers", "Can store up to 19 digits"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericIntFieldTypeObj;
      exampleValueInit = 2300;
    }
    else if(fieldInputType === "int_positive") {
      typeDescriptionArray = ["Numeric input of zero and positive whole numbers", "Can store up to 19 digits", "Optional zero-padding fixed # digits can be specified"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj;
      exampleValueInit = 2300;
    }
    else if(fieldInputType === "decimal") {
      typeDescriptionArray = ["Numeric input of zero, positive, and negative decimal or whole numbers", "Can store up to 9 leading digits and/or 9 decimals"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDecimalFieldTypeObj;
      exampleValueInit = 2300.47;
    }
    else if(fieldInputType === "decimal_positive") {
      typeDescriptionArray = ["Numeric input of zero and positive decimal or whole numbers", "Can store up to 9 leading digits and/or 9 decimals"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDecimalPositiveFieldTypeObj;
      exampleValueInit = 2300.47;
    }
    else if(fieldInputType === "percent_int") {
      typeDescriptionArray = ["Numeric input of zero, positive, and negative whole numbers, automatically masked with a '%'", "Can store up to 19 digits"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericPercentIntFieldTypeObj;
      exampleValueInit = 145;
    }
    else if(fieldInputType === "percent_int_0to100") {
      typeDescriptionArray = ["Numeric input of whole numbers between 0 and 100, automatically masked with a '%'"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj;
      exampleValueInit = 5;
    }
    else if(fieldInputType === "percent_decimal") {
      typeDescriptionArray = ["Numeric input of zero, positive, and negative decimal or whole numbers, automatically masked with a '%'", "Can store up to 9 leading digits and/or 9 decimals"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericPercentDecimalFieldTypeObj;
      exampleValueInit = 145.47;
    }
    else if(fieldInputType === "percent_decimal_0to100") {
      typeDescriptionArray = ["Numeric input of decimal or whole numbers between 0 and 100, automatically masked with a '%'"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericPercentDecimal0to100FieldTypeObj;
      exampleValueInit = 5.47;
    }
    else if(fieldInputType === "money") {
      typeDescriptionArray = ["Numeric input of zero, positive, and negative whole numbers, automatically masked with '$' and commas", "Can store up to 19 digits"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericMoneyFieldTypeObj;
      exampleValueInit = 67000000;
    }
    else if(fieldInputType === "money_cents") {
      typeDescriptionArray = ["Numeric input of zero, positive, and negative decimal or whole numbers, automatically masked with '$' and commas", "Can store up to 9 leading digits and/or 2 decimals"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericMoneyCentsFieldTypeObj;
      exampleValueInit = 67.89;
    }
    else if(fieldInputType === "color") {
      typeDescriptionArray = ["Allows choice of any user defined color"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericColorFieldTypeObj;
      exampleValueInit = "bfd5db";
    }
    else if(fieldInputType === "date") {
      typeDescriptionArray = ["Standard date (Day, Month, Year) displayed in 'company format' (which can be changed in Admin 'System' tab)", "Note: when placed onto the Capture " + c_cardNameDates + " Card, this field type will be automatically formatted into the 'Date (days until due)' format when viewed on the " + c_cardNameDates + " Card"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateFieldTypeObj;
      exampleValueInit = JSFUNC.now_date();
    }
    else if(fieldInputType === "date_DMjY") {
      typeDescriptionArray = ["Date displayed in format 'Day MMM dd, YYYY'"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateDMjYFieldTypeObj;
      exampleValueInit = JSFUNC.now_date();
    }
    else if(fieldInputType === "date_dayMdyDaysUntil1") {
      typeDescriptionArray = ["Date displayed in format 'Day MMM dd, YYYY', with a 'days until' box", "No 'days until' box drawn if date is passed", "Colors for the (color 1) 'days until' box can be changed in the Admin 'Upcoming Date Colors' tab"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1FieldTypeObj;
      exampleValueInit = JSFUNC.now_date();
    }
    else if(fieldInputType === "date_dayMdyDaysUntil1Overdue") {
      typeDescriptionArray = ["Same as Date Upcoming (color 1)", "'Overdue' displayed in 'days until' box if date is passed"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj;
      exampleValueInit = JSFUNC.now_date();
    }
    else if(fieldInputType === "date_dayMdyDaysUntil2") {
      typeDescriptionArray = ["Same as Date Upcoming (color 1)", "The 'days until' box uses (color 2)"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil2FieldTypeObj;
      exampleValueInit = JSFUNC.now_date();
    }
    else if(fieldInputType === "date_dayMdyDaysUntil2Overdue") {
      typeDescriptionArray = ["Same as Date Upcoming (color 1)", "The 'days until' box uses (color 2) and 'Overdue' displayed if date is passed"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil2OverdueFieldTypeObj;
      exampleValueInit = JSFUNC.now_date();
    }
    else if(fieldInputType === "dateWithDuration") {
      typeDescriptionArray = ["Standard date displayed in 'company format'", "Has an extra option to specify a 'duration' of the event in days/weeks/months/years", "Shows the duration width on the Gantt Chart view"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateWithDurationFieldTypeObj;
      exampleValueInit = JSFUNC.now_date() + " 00:00:00";
    }
    else if(fieldInputType === "datetime") {
      typeDescriptionArray = ["Standard date and time (Date, Month, Year, Hours, Minutes) displayed in 'company format' (which can be changed in Admin 'System' tab)"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateTimeFieldTypeObj;
      exampleValueInit = JSFUNC.now_datetime_utc();
    }
    else if(fieldInputType === "datetime_MjYgiA") {
      typeDescriptionArray = ["Date/Time displayed in format 'Month Date, Year Hours:Minutes AM/PM'"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateTimeMjYgiAFieldTypeObj;
      exampleValueInit = JSFUNC.now_datetime_utc();
    }
    else if(fieldInputType === "datetime_dayMdyDaysUntil1") {
      typeDescriptionArray = ["Date/Time displayed in format 'Day MMM dd, YYYY hh:mm A/P', with a 'days until' box", "No 'days until' box drawn if date is passed", "Colors for the (color 1) 'days until' box can be changed in the Admin 'Upcoming Date Colors' tab"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil1FieldTypeObj;
      exampleValueInit = JSFUNC.now_datetime_utc();
    }
    else if(fieldInputType === "datetime_dayMdyDaysUntil1Overdue") {
      typeDescriptionArray = ["Same as Date/Time Upcoming (color 1)", "'Overdue' displayed in 'days until' box if date is passed"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil1OverdueFieldTypeObj;
      exampleValueInit = JSFUNC.now_datetime_utc();
    }
    else if(fieldInputType === "datetime_dayMdyDaysUntil2") {
      typeDescriptionArray = ["Same as Date/Time Upcoming (color 1)", "The 'days until' box uses (color 2)"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil2FieldTypeObj;
      exampleValueInit = JSFUNC.now_datetime_utc();
    }
    else if(fieldInputType === "datetime_dayMdyDaysUntil2Overdue") {
      typeDescriptionArray = ["Same as Date/Time Upcoming (color 1)", "The 'days until' box uses (color 2) and 'Overdue' displayed if date is passed"];
      fieldTypeObj = this.props.DatabaseMobx.c_genericDateTimeDayMdyDaysUntil2OverdueFieldTypeObj;
      exampleValueInit = JSFUNC.now_datetime_utc();
    }
    else if(fieldInputType === "select") {
      typeDescriptionArray = ["Creates a drop-down list of admin defined 'options'", "Admin can define an unlimited number of options to choose from. A search is provided to filter the choices", "Users can select only 1 option from the list (or clear the selection and leave it 'unselected')"];
      const vA = [1, 2, 3, 4];
      const dA = ["Sample Option #1", "Sample Option #2", "Sample Option #3", "Sample Option #4"];
      const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
      const selectSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Option", vA, false, dA, swsOptionsObj);
      fieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectSwsDataObj);
      exampleValueInit = 1;
    }
    else if(fieldInputType === "selectmulti") {
      typeDescriptionArray = ["Creates a Select (described above)", "Users can select multiple options simultaneously", "All selected choices are displayed separated by commas"];
      const vA = [1, 2, 3, 4];
      const dA = ["Sample Multi Option #1", "Sample Multi Option #2", "Sample Multi Option #3", "Sample Multi Option #4"];
      const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
      const selectSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Option", vA, false, dA, swsOptionsObj);
      fieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectSwsDataObj);
      exampleValueInit = "3,1";
    }
    else if(fieldInputType === "sharedpercent") {
      typeDescriptionArray = ["Essentially a Multi-Select (described above) with user defined percent allocations (totalling up to 100%)", "All allocations must total 100% before the Shared Percent can be saved"];
      const vA = [1, 2, 3, 4];
      const dA = ["Sample Percent Option #1", "Sample Percent Option #2", "Sample Percent Option #3", "Sample Percent Option #4"];
      const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
      const selectSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Option", vA, false, dA, swsOptionsObj);
      fieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("sharedpercent", selectSwsDataObj);
      exampleValueInit = "4:75,1:20,3:5";
    }
    else if(fieldInputType === "selectadd") {
      typeDescriptionArray = ["Creates a Select (described above)", "Users can add new entries to the table of options without the admin's help"];
      const vA = [1, 2, 3, 4];
      const dA = ["Sample Option #1", "Sample Option #2", "Sample Option #3", "Sample Option #4"];
      const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true, selectAddTblName:"example", selectAddNameFieldDbName:"name"};
      const selectSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Option", vA, false, dA, swsOptionsObj);
      fieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectSwsDataObj);
      exampleValueInit = 1;
    }
    else if(fieldInputType === "selectmultiadd") {
      typeDescriptionArray = ["Creates a Multi-Select (described above)", "Users can add new entries to the table of options without the admin's help"];
      const vA = [1, 2, 3, 4];
      const dA = ["Sample Multi Option #1", "Sample Multi Option #2", "Sample Multi Option #3", "Sample Multi Option #4"];
      const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true, selectAddTblName:"example", selectAddNameFieldDbName:"name"};
      const selectSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Option", vA, false, dA, swsOptionsObj);
      fieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectSwsDataObj);
      exampleValueInit = "3,1";
    }
    else if(fieldInputType === "sharedpercentadd") {
      typeDescriptionArray = ["Essentially a Multi-Select (described above) with user defined percent allocations (totalling up to 100%)", "All allocations must total 100% before the Shared Percent can be saved", "Users can add new entries to the table of options without the admin's help"];
      const vA = [1, 2, 3, 4];
      const dA = ["Sample Percent Option #1", "Sample Percent Option #2", "Sample Percent Option #3", "Sample Percent Option #4"];
      const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true, selectAddTblName:"example", selectAddNameFieldDbName:"name"};
      const selectSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Option", vA, false, dA, swsOptionsObj);
      fieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("sharedpercent", selectSwsDataObj);
      exampleValueInit = "4:75,1:20,3:5";
    }
    else if(fieldInputType === "verticalswitch") {
      typeDescriptionArray = ["Identical function to a 'Select', but presented as a radio button list of the options", "Good when selecting between 2 or 3 options"];
      const vA = [1, 2, 3];
      const dA = ["Sample Vertical Option #1", "Sample Vertical Option #2", "Sample Vertical Option #3"];
      fieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Option", vA, false, dA, undefined);
      exampleValueInit = 1;
    }

    var fieldTypeDisplay = fieldTypeObj.inputTypeMask;
    if(JSFUNC.in_array(fieldInputType, ["selectadd", "selectmultiadd", "sharedpercentadd"])) {
      fieldTypeDisplay += " (with add)";
    }

    this.state = {
      s_fieldTypeDisplay: fieldTypeDisplay,
      s_typeDescriptionArray: typeDescriptionArray,
      s_fieldTypeObj: fieldTypeObj,
      s_exampleValue: exampleValueInit
    };
  }

  onchange_example_field = (i_newValue) => {
    this.setState({s_exampleValue:i_newValue});
  }

  onclick_field_type_choice = () => {
    if(this.props.f_onClick) {
      const newSelectedFieldType = ((this.props.p_fieldInputType === this.props.p_selectedFieldInputType) ? (undefined) : (this.props.p_fieldInputType))
      this.props.f_onClick(newSelectedFieldType, this.state.s_fieldTypeDisplay, this.state.s_fieldTypeObj);
    }
  }

  render() {
    const fieldTypeDisplay = this.state.s_fieldTypeDisplay;
    const typeDescriptionArray = this.state.s_typeDescriptionArray;
    const fieldTypeObj = this.state.s_fieldTypeObj;
    const exampleValue = this.state.s_exampleValue;

    const fieldInputType = this.props.p_fieldInputType;
    const selectedFieldInputType = this.props.p_selectedFieldInputType;

    const typeIsSelectedTF = (fieldInputType === selectedFieldInputType);

    return(
      <div
        className={"smallTopMargin smallFullPad border bevelBorderColors " + ((typeIsSelectedTF) ? ("ticketResponseFromBITUnread") : ("bgAlmostWhite hoverLighterBlueGradient cursorPointer"))}
        onClick={this.onclick_field_type_choice}>
        <div className="displayFlexRow">
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <div className="smallBottomMargin">
              <font className="font15 fontBold fontTextLighter">
                {fieldTypeDisplay}
              </font>
            </div>
          </div>
          <div className="flex11a" style={{flexBasis:"150em"}}>
            {typeDescriptionArray.map((m_descriptionLine) =>
              <div className="displayFlexRow microTopMargin">
                <div className="flex00a lrMargin">
                  <font className="fontTextLighter">
                    {"-"}
                  </font>
                </div>
                <div className="flex11a">
                  <font className="fontItalic fontTextLighter">
                    {m_descriptionLine}
                  </font>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"addNewCaptureFieldTypeExample" + fieldInputType}
            p_fieldDisplayName={"Example Field"}
            p_fieldTypeObj={fieldTypeObj}
            p_valueRaw={exampleValue}
            p_valueIsEditableTFU={true}
            p_fieldClass="fontBlue"
            p_fieldWidth="7em"
            p_fieldNowrapTF={false}
            p_valueMaxHeight="4em"
            p_fieldValueVerticallyAlignedTF={true}
            p_floatingBoxTitle={"Example " + fieldTypeDisplay + " Field"}
            f_onSaveChanged={this.onchange_example_field}
          />
        </div>
      </div>
    );
  }
}));

class NewCaptureSelectFieldExistingDataTableChoice extends Component { //props: p_tblName, p_tblDisplayName, p_numOptions, p_selectedTblName, f_onClick
  onclick_select_options_data_tbl_choice = () => {
    const tblName = this.props.p_tblName;
    const selectedTblName = this.props.p_selectedTblName;
    if(JSFUNC.is_function(this.props.f_onClick)) {
      const newSelectedTblName = ((tblName === selectedTblName) ? (undefined) : (tblName))
      this.props.f_onClick(newSelectedTblName);
    }
  }

  render() {
    const tblName = this.props.p_tblName;
    const tblDisplayName = this.props.p_tblDisplayName;
    const numOptions = this.props.p_numOptions;
    const selectedTblName = this.props.p_selectedTblName;

    const tblIsSelectedTF = (tblName === selectedTblName);

    return(
      <div
        className={"smallTopMargin smallFullPad border bevelBorderColors " + ((tblIsSelectedTF) ? ("ticketResponseFromBITUnread") : ("bgAlmostWhite hoverLighterBlueGradient cursorPointer"))}
        onClick={this.onclick_select_options_data_tbl_choice}>
        <div className="displayFlexRowVc">
          <div className="flex11a">
            <font className="font15 fontBold fontTextLighter">
              {tblDisplayName}
            </font>
            {(tblName === "tbl_g_contacts_persons" || tblName === "tbl_g_contacts_companies") &&
              <div className="microTopMargin">
                <font className="fontItalic fontTextLighter">
                  {"Uses special Contacts interface when selecting Contacts"}
                </font>
              </div>
            }
          </div>
          <div className="flex00a" style={{flexBasis:"10em"}}>
            <div className="textCenter">
              <font className="fontItalic fontTextLighter">
                {"# Options"}
              </font>
            </div>
            <div className="microTopMargin textCenter">
              <font className="font11 fontItalic fontTextLight">
                {numOptions}
              </font>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



class CreateNewFieldCodewordAlternativesAddEdit extends Component { //props: p_newCaptureFieldName, p_codewordNamesComma, f_onChange
  onclick_add_new_alt_codeword = () => {
    const oldCodewordNamesComma = this.props.p_codewordNamesComma;
    var newCodewordNamesComma = undefined;
    if(oldCodewordNamesComma === undefined) {
      newCodewordNamesComma = "";
    }
    else {
      newCodewordNamesComma = oldCodewordNamesComma + ",";
    }
    this.call_onchange(newCodewordNamesComma);
  }

  onchange_codeword = (i_newValue, i_index) => {
    const oldCodewordNamesComma = this.props.p_codewordNamesComma;
    if(JSFUNC.is_string(oldCodewordNamesComma)) {
      var oldCodewordNamesArray = JSFUNC.convert_comma_list_to_array(oldCodewordNamesComma);
      oldCodewordNamesArray[i_index] = i_newValue;
      const newCodewordNamesComma = JSFUNC.convert_array_to_comma_list(oldCodewordNamesArray);
      this.call_onchange(newCodewordNamesComma);
    }
    else {
      this.call_onchange(undefined);
    }
  }

  onremove_codeword = (i_index) => {
    const oldCodewordNamesComma = this.props.p_codewordNamesComma;
    if(JSFUNC.is_string(oldCodewordNamesComma)) {
      var oldCodewordNamesArray = JSFUNC.convert_comma_list_to_array(oldCodewordNamesComma);
      var newCodewordNamesArray = [];
      for(let i = 0; i < oldCodewordNamesArray.length; i++) {
        if(i !== i_index) {
          newCodewordNamesArray.push(oldCodewordNamesArray[i]);
        }
      }

      if(newCodewordNamesArray.length === 0) {
        this.call_onchange(undefined);
      }
      else {
        this.call_onchange(JSFUNC.convert_array_to_comma_list(newCodewordNamesArray));
      }
    }
    else {
      this.call_onchange(undefined);
    }
  }

  call_onchange = (i_newValue) => {
    if(this.props.f_onChange) {
      this.props.f_onChange(i_newValue);
    }
  }

  render() {
    const newCaptureFieldName = this.props.p_newCaptureFieldName;
    const codewordNamesComma = this.props.p_codewordNamesComma;
    var codewordNamesArray = []; //default for undefined
    if(codewordNamesComma === "") {
      codewordNamesArray = [""];
    }
    else {
      codewordNamesArray = JSFUNC.convert_comma_list_to_array(codewordNamesComma);
    }

    return(
      <>
        <CEGeneralReact.CEButton
          p_type="add"
          p_text="Add Alternative Template Codeword(s)"
          f_onClick={this.onclick_add_new_alt_codeword}
        />
        <div className="smallTopMargin">
          <font className="font11 fontItalic fontTextLight">
            {"[[" + JSFUNC.db_name_from_display_name(newCaptureFieldName) + "]]"}
          </font>
        </div>
        {codewordNamesArray.map((m_codeword, m_index) =>
          <AlternativeCodewordInput
            p_codeword={m_codeword}
            p_index={m_index}
            f_onChange={this.onchange_codeword}
            f_onRemove={this.onremove_codeword}
          />
        )}
      </>
    );
  }
}

const AlternativeCodewordInput = inject("DatabaseMobx")(observer(
class AlternativeCodewordInput extends Component { //props: p_codeword, p_index, f_onChange, f_onRemove
  onchange_codeword = (i_newValue) => {
    if(this.props.f_onChange) {
      this.props.f_onChange(i_newValue, this.props.p_index);
    }
  }

  onclick_remove_codeword = () => {
    if(this.props.f_onRemove) {
      this.props.f_onRemove(this.props.p_index);
    }
  }

  render() {
    const codeword = this.props.p_codeword;
    return(
      <div className="displayFlexRowVc smallTopMargin">
        <div className="lrMargin">
          <font className="font11 fontItalic fontTextLight">
            {"[["}
          </font>
        </div>
        <div className="displayFlexRowVc">
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
            p_valueRaw={codeword}
            f_onChangeOrOnSelect={this.onchange_codeword}
          />
        </div>
        <div className="lrMargin">
          <font className="font11 fontItalic fontTextLight">
            {"]]"}
          </font>
        </div>
        <div className="lrMedMargin">
          <CEGeneralReact.RemoveItemButton
            f_onClick={this.onclick_remove_codeword}
          />
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabDetailsFieldsOptions = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabDetailsFieldsOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_exampleIDIQVehicleCaptureID: -1,
      s_exampleCapturePriorityLevelID: 1,
      s_exampleCaptureFavoriteTF: true
    }
  }

  onclick_admin_contact_tab = (i_newValue) => {
    this.props.AdminMobx.a_set_details_fields_options_selected_subtab(i_newValue);
  }

  onswitch_bit_master_set_asides_only_short_names = (i_newValue01) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("bit_master_set_asides_only_short_names_01", i_newValue01, "i");
  }

  onswitch_bit_master_naics_codes_only6digits = (i_newValue01) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("bit_master_naics_codes_all0_only6digit1", i_newValue01, "i");
  }

  onsave_bit_master_naics_codes_ranges_comma = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("bit_master_naics_codes_ranges_comma", i_newValue, "s");
  }

  onselect_company_idiq_vehicle_wins_filter = (i_selectedIDIQFilteringOption012) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("idiq_vehicle_wins0_winsandactive1", i_selectedIDIQFilteringOption012, "i");
  }

  onchange_example_idiq_capture_id = (i_newValueInt) => {
    this.setState({s_exampleIDIQVehicleCaptureID:i_newValueInt});
  }

  onselect_example_capture_priority_level = (i_newValueInt) => {
    this.setState({s_exampleCapturePriorityLevelID:i_newValueInt});
  }

  onsave_capture_favorites_color = (i_selectedColorString) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_favorites_color", i_selectedColorString, "s");
  }

  onclick_example_capture_favorite = (i_newValueTF) => {
    this.setState({s_exampleCaptureFavoriteTF:i_newValueTF});
  }

  render() {
    const s_exampleIDIQVehicleCaptureID = this.state.s_exampleIDIQVehicleCaptureID;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_detailsFieldsOptionsSelectedSubtab = this.props.AdminMobx.o_detailsFieldsOptionsSelectedSubtab;
    const c_fieldMapOfIdiqCaptureIDTOLink = this.props.DatabaseMobx.c_fieldMapOfIdiqCaptureIDTOLink;

    const idiqCaptureIDTOLinkFieldDisplayName = c_fieldMapOfIdiqCaptureIDTOLink.get("display_name");

    var tabTitle = undefined;
    var tabHelpLinesArray = [];
    var adminDetailsFieldsOptionsPageComponent = null;
    if(o_detailsFieldsOptionsSelectedSubtab === "competitionTypeSBCertsMasterTbl") {
      const c_bitMasterSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs = this.props.AdminMobx.c_bitMasterSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs;
      const c_companyBitMasterSetAsidesOnlyShortNames01 = this.props.DatabaseMobx.c_companyBitMasterSetAsidesOnlyShortNames01;
      const c_companyBitMasterSetAsidesOnlyShortNamesTF = this.props.DatabaseMobx.c_companyBitMasterSetAsidesOnlyShortNamesTF;

      tabTitle = "BIT Master Competition Type Set-Asides Table";
      tabHelpLinesArray = [
        "This table of Set-Asides (used to specify both Competition Type for opportunities and Small Business Certifications for Contact Companies) is kept at an uneditable Master level in " + c_productStylingObj.productName + ".",
        "You can use the checkboxes below to reduce the list of options presented to users when filling out the field."
      ];

      const setAsidesFullNamesOption0 = "[default] Display full Set-Aside names when appropriate in " + c_productStylingObj.productName + " (uses short name/abbreviation in small spaces)";
      const setAsidesAbbrNamesOption1 = "Always use short names/abbreviations when displaying or selecting Set-Aside values in " + c_productStylingObj.productName;

      adminDetailsFieldsOptionsPageComponent = (
        <>
          <div className="flex00a borderT1ddd borderB1ddd tbPad" style={{width:"70em"}}>
            <div className="microBottomMargin">
              <font className="fontItalic fontTextLighter">
                {"Use this switch below to control whether selected Set-Asides display their full name when possible, or if they always use their abbreviated name."}
              </font>
            </div>
            <div className="" style={{maxWidth:"61em"}}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Set-Asides Display Option", [0, 1], false, [setAsidesFullNamesOption0, setAsidesAbbrNamesOption1], 1.9)}
                p_valueRaw={c_companyBitMasterSetAsidesOnlyShortNames01}
                f_onChangeOrOnSelect={this.onswitch_bit_master_set_asides_only_short_names}
              />
            </div>
          </div>
          <AdminBitMasterTblCheckboxes
            p_tblACompanyIDsToNotIncludeCommaFieldName="bit_master_set_aside_ids_to_not_include_comma"
            p_hiddenUnlessCheckedTFFieldName="setAsideHiddenUnlessCheckedTF"
            p_tblDataArrayOfObjs={c_bitMasterSetAsidesSamAlphabeticTopCustomSortedBottomDuplicatesRemovedArrayOfObjs}
            p_tblDataNameFieldName={((c_companyBitMasterSetAsidesOnlyShortNamesTF) ? ("short_name") : ("bracketsShortNameName"))}
            p_tblRowIDsToNotIncludeArray={this.props.DatabaseMobx.c_companyBitMasterSetAsideIDsToNotIncludeArray}
          />
        </>
      );
    }
    else if(o_detailsFieldsOptionsSelectedSubtab === "naicsCodes") {
      const numNaicsCodesNotHidden = this.props.AdminMobx.c_bitMasterNaicsCombinedNamesNotHiddenArray.length;

      tabTitle = "BIT Master NAICS Codes Table";
      tabHelpLinesArray = [
        "This table of NAICS Codes used in " + c_productStylingObj.productName + " is kept at an uneditable Master level.",
        "Using this tool below, you can filter and minimize the list of NAICS Code choices to pick from throughout the system by specifying which ranges of codes you would like."
      ];

      adminDetailsFieldsOptionsPageComponent = (
        <>
          <div className="flex00a displayFlexRow borderT1ddd borderB1ddd tbPad">
            <div className="flex11a lrMedPad borderR1bbb" style={{flexBasis:"100em"}}>
              <div className="smallBottomMargin">
                <font className="fontItalic fontTextLighter">
                  {"Choose whether to include all NAICS Codes, or to filter the list to only include the 6-digit codes"}
                </font>
              </div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("NAICS Inclusion Option", [0, 1], false, ["[default] Include all NAICS Codes (2, 3, 4, 5, and 6 digit codes)", "Only include 6-digit NAICS Codes"], 1.9)}
                p_valueRaw={this.props.DatabaseMobx.c_companyBitMasterNaicsCodesAll0Only6Digit1}
                f_onChangeOrOnSelect={this.onswitch_bit_master_naics_codes_only6digits}
              />
            </div>
            <div className="flex11a lrMedPad" style={{flexBasis:"150em"}}>
              <div className="smallBottomMargin">
                <font className="fontItalic fontTextLight">
                  {"Use all 6 digit codes when specifying ranges. Blank input means every NAICS code is included. [Example: 541000-699999,800000-899999]"}
                </font>
              </div>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="adminBudgetsApprovalNumDays"
                p_fieldDisplayName="Master NAICS Codes Table Minimization Code Ranges"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={this.props.DatabaseMobx.c_companyBitMasterNaicsCodesRangesComma}
                p_valueIsEditableTFU={true}
                p_fieldClass="fontBlue"
                p_fieldWidth="14em"
                f_onSaveChanged={this.onsave_bit_master_naics_codes_ranges_comma}
              />
            </div>
          </div>
          <div className="flex00a microBottomMargin textRight">
            <font className="font12 fontBold fontTextLighter">
              {numNaicsCodesNotHidden + " NAICS " + JSFUNC.plural(numNaicsCodesNotHidden, "Code", "Codes")}
            </font>
          </div>
          <div className="flex11a yScroll medBottomMargin border1bbb bgLightestGray medFullPad">
            {this.props.AdminMobx.c_bitMasterNaicsCombinedNamesNotHiddenArray.map((m_naicsCombinedName) =>
              <div className="">
                <font className="">
                  {m_naicsCombinedName}
                </font>
              </div>
            )}
          </div>
        </>
      );
    }
    else if(o_detailsFieldsOptionsSelectedSubtab === "productServiceCodesMasterTbl") {
      tabTitle = "BIT Master Product Service Codes (PSC)";
      tabHelpLinesArray = [
        "This table of Product Service Codes used in " + c_productStylingObj.productName + " is kept at an uneditable Master level.",
        "You can use the checkboxes below to reduce the list of options presented to users when filling out the field."
      ];

      adminDetailsFieldsOptionsPageComponent = (
        <AdminBitMasterTblCheckboxes
          p_tblACompanyIDsToNotIncludeCommaFieldName="bit_master_product_service_code_ids_to_not_include_comma"
          p_hiddenUnlessCheckedTFFieldName="productServiceCodeHiddenUnlessCheckedTF"
          p_tblDataArrayOfObjs={this.props.AdminMobx.c_bitMasterProductServiceCodesArrayOfObjs}
          p_tblDataNameFieldName="code"
          p_tblRowIDsToNotIncludeArray={this.props.DatabaseMobx.c_companyBitMasterProductServiceCodeIDsToNotIncludeArray}
        />
      );
    }
    else if(o_detailsFieldsOptionsSelectedSubtab === "departmentsAgenciesMasterTbl") {
      tabTitle = "BIT Master Departments/Agencies Table";
      tabHelpLinesArray = [
        "This table of Departments/Agencies used in " + c_productStylingObj.productName + " is kept at an uneditable Master level.",
        "You can use the checkboxes below to reduce the list of options presented to users when filling out the field."
      ];

      adminDetailsFieldsOptionsPageComponent = (
        <AdminBitMasterTblCheckboxes
          p_tblACompanyIDsToNotIncludeCommaFieldName="bit_master_depts_ags_ids_to_not_include_comma"
          p_hiddenUnlessCheckedTFFieldName="departmentAgencyHiddenUnlessCheckedTF"
          p_tblDataArrayOfObjs={this.props.AdminMobx.c_bitMasterDepartmentsAgenciesArrayOfObjs}
          p_tblDataNameFieldName="name"
          p_tblRowIDsToNotIncludeArray={this.props.DatabaseMobx.c_companyBitMasterDepartmentsAgenciesIDsToNotIncludeArray}
        />
      );
    }
    else if(o_detailsFieldsOptionsSelectedSubtab === "subTiersMasterTbl") {
      tabTitle = "BIT Master Sub-Tiers Table";
      tabHelpLinesArray = [
        "This table of Sub-Tiers used in " + c_productStylingObj.productName + " is kept at an uneditable Master level.",
        "You can use the checkboxes below to reduce the list of options presented to users when filling out the field."
      ];

      adminDetailsFieldsOptionsPageComponent = (
        <AdminBitMasterTblCheckboxes
          p_tblACompanyIDsToNotIncludeCommaFieldName="bit_master_subtiers_ids_to_not_include_comma"
          p_hiddenUnlessCheckedTFFieldName="subTierHiddenUnlessCheckedTF"
          p_tblDataArrayOfObjs={this.props.AdminMobx.c_bitMasterSubTiersArrayOfObjs}
          p_tblDataNameFieldName="displayName"
          p_tblRowIDsToNotIncludeArray={this.props.DatabaseMobx.c_companyBitMasterSubTiersIDsToNotIncludeArray}
        />
      );
    }
    else if(o_detailsFieldsOptionsSelectedSubtab === "idiqVehicleFieldOptions") {
      const c_companyIDIQVehicleWins0WinsAndActive1 = this.props.DatabaseMobx.c_companyIDIQVehicleWins0WinsAndActive1;
      const c_fieldMapOfContractStartDate = this.props.DatabaseMobx.c_fieldMapOfContractStartDate;
      const c_fieldMapOfContractEndDate = this.props.DatabaseMobx.c_fieldMapOfContractEndDate;
      const c_fieldMapOfPeriodOfPerformance = this.props.DatabaseMobx.c_fieldMapOfPeriodOfPerformance;

      tabTitle = "'" + idiqCaptureIDTOLinkFieldDisplayName + "' as a Details Field";
      tabHelpLinesArray = [
        "The " + c_productStylingObj.productName + " System field '" + idiqCaptureIDTOLinkFieldDisplayName + "' is used in Task Order type Captures to link them to an existing IDIQ type Capture as its vehicle",
        "You can use the switch below to specify when choosing from the list of IDIQ Captures if all Active and Won IDIQs are included, or only Won IDIQs [the default setting]."
      ];

      const idiqListOptions012Array = [0, 1, 2];
      const idiqListOptionsDisplayArray = ["Won IDIQs Only", "Won and Active IDIQs Shown", "All IDIQs Shown"];
      const exampleIdiqSelectDescriptionsArray = [
        "This IDIQ is 'Won' and within the " + c_fieldMapOfPeriodOfPerformance.get("display_name") + " (after " + c_fieldMapOfContractStartDate.get("display_name") + ").",
        "This IDIQ is in an Active stage, not yet 'Won'. Task Orders can still be assigned/managed at this point.",
        "The IDIQ's " + c_fieldMapOfContractEndDate.get("display_name") + " has expired (or " + c_fieldMapOfContractStartDate.get("display_name") + " or " + c_fieldMapOfPeriodOfPerformance.get("display_name") + " is not set).",
        "The IDIQ is Lost, No Bid, or Cancelled."
      ];
      const exampleIdiqSelectBgColorsArray = ["ffffff", "ccddff", "cccccc", "ffbbbb"];

      adminDetailsFieldsOptionsPageComponent = (
        <>
          <div className="flex00a borderT1ddd borderB1ddd tbMedPad" style={{width:"40em"}}>
            <div className="smallBottomMargin">
              <font className="fontBold fontTextLighter">
                {"Specify how IDIQs are filtered when selecting the IDIQ Vehicle for a Task Order type Capture"}
              </font>
            </div>
            <div style={{maxWidth:"20em"}}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("IDIQ List Option", idiqListOptions012Array, false, idiqListOptionsDisplayArray, 1.9)}
                p_valueRaw={c_companyIDIQVehicleWins0WinsAndActive1}
                f_onChangeOrOnSelect={this.onselect_company_idiq_vehicle_wins_filter}
              />
            </div>
          </div>
          <div className="flex00a displayFlexRow hugeTopMargin">
            <div className="flex00a" style={{flexBasis:"40em"}}>
              <div className="border1bbb borderRadius05 bgLighterGray bigFullPad">
                <div className="smallBottomMargin">
                  <font className="fontItalic fontTextLight">
                    {"Example " + idiqCaptureIDTOLinkFieldDisplayName + " field options filtered using the selected setting above:"}
                  </font>
                </div>
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={c_fieldMapOfIdiqCaptureIDTOLink.get("fieldTypeObj")}
                  p_valueRaw={s_exampleIDIQVehicleCaptureID}
                  f_onChangeOrOnSelect={this.onchange_example_idiq_capture_id}
                />
              </div>
            </div>
            <div className="flex00a" style={{flexBasis:"5em"}} />
            <div className="flex00a" style={{flexBasis:"30em"}}>
              <div className="smallBottomMargin textCenter">
                <font className="fontBold">
                  {idiqCaptureIDTOLinkFieldDisplayName + " Selection Color Legend"}
                </font>
              </div>
              {exampleIdiqSelectDescriptionsArray.map((m_exampleIdiqSelectDescription, m_index) =>
                <div className="displayFlexColumnHcVc border1bbb tbMicroPad lrMedPad textCenter" style={{height:"2.8em", backgroundColor:"#" + exampleIdiqSelectBgColorsArray[m_index]}}>
                  <font>
                    {m_exampleIdiqSelectDescription}
                  </font>
                </div>
              )}
            </div>
          </div>
        </>
      );
    }
    else if(o_detailsFieldsOptionsSelectedSubtab === "priorityFavorites") {
      const c_companyCaptureFavoritesColor = this.props.DatabaseMobx.c_companyCaptureFavoritesColor;
      const c_fieldMapOfCaptureFavorites = this.props.DatabaseMobx.c_fieldMapOfCaptureFavorites;
      const c_fieldMapOfCapturePriorityLevel = this.props.DatabaseMobx.c_fieldMapOfCapturePriorityLevel;

      const captureFavoritesFieldDisplayName = c_fieldMapOfCaptureFavorites.get("display_name");
      const capturePriorityLevelFieldDisplayName = c_fieldMapOfCapturePriorityLevel.get("display_name");

      const capturePriorityLevelsAedTableObj = {
        tblName: "tbl_a_capture_priority_levels",
        dragToResortFieldDbName: "sort",
        initialSortedFieldDbName: "sort",
        tableWidth: "100%",
        itemName: "Priority Level",
        deleteItemNameFieldDbName: "name",
        fieldsArrayOfObjs: [
          {width:"250em", dbName:"name", displayName:"Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, requiredNotEmptyTF:true},
          {width:"100em", dbName:"color", displayName:"Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"cccccc", requiredNotEmptyTF:true}
        ]
      };

      adminDetailsFieldsOptionsPageComponent = (
        <div className="displayFlexRow flexWrap">
          <div className="flex00a tbMedMargin borderR1bbb lrHugePad" style={{flexBasis:"53em"}}>
            <div className="smallBottomMargin textCenter">
              <font className="font14">
                {"Capture field: '" + capturePriorityLevelFieldDisplayName + "'"}
              </font>
            </div>
            <div className="medBottomMargin">
              <font className="font12 fontBold fontTextLight">
                {"Specify the number, colors, and order of priority levels that can be set for a Capture"}
              </font>
            </div>
            <div className="displayFlexRow">
              <div className="flex11a">
                <AddEditDeleteTable
                  p_dataArrayOfObjs={this.props.DatabaseMobx.c_capturePriorityLevelsArrayOfObjs}
                  p_aedTableObj={capturePriorityLevelsAedTableObj}
                />
              </div>
              <div className="flex00a lrBigMargin" style={{flexBasis:"9em"}}>
                <div className="smallBottomMargin textCenter">
                  <font className="fontItalic fontTextLight">
                    {"Interactive example of this field"}
                  </font>
                </div>
                <div className="displayFlexColumnHcVc">
                  <CEGeneralReact.GenericInputOrSelectFromInputType
                    p_valueRaw={this.state.s_exampleCapturePriorityLevelID}
                    p_fieldTypeObj={c_fieldMapOfCapturePriorityLevel.get("fieldTypeObj")}
                    f_onChangeOrOnSelect={this.onselect_example_capture_priority_level}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex00a tbMedMargin lrHugePad" style={{flexBasis:"53em"}}>
            <div className="smallBottomMargin textCenter">
              <font className="font14">
                {"Capture field: '" + captureFavoritesFieldDisplayName + "'"}
              </font>
            </div>
            <div className="medBottomMargin">
              <font className="font12 fontBold fontTextLight">
                {"Specify the icon color when a User favorites a Capture for themselves"}
              </font>
            </div>
            <div className="displayFlexRow">
              <div className="flex11a">
                <div className="border1bbb borderRadius05 medFullPad">
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString="adminCaptureFavoritesColor"
                    p_fieldDisplayName="Capture Favorites Color"
                    p_fieldTypeObj={this.props.DatabaseMobx.c_genericColorFieldTypeObj}
                    p_valueRaw={c_companyCaptureFavoritesColor}
                    p_valueIsEditableTFU={true}
                    p_fieldClass="fontBlue"
                    p_fieldWidth="12em"
                    f_onSaveChanged={this.onsave_capture_favorites_color}
                  />
                </div>
              </div>
              <div className="flex00a lrBigMargin" style={{flexBasis:"9em"}}>
                <div className="smallBottomMargin textCenter">
                  <font className="fontItalic fontTextLight">
                    {"Interactive example of this field"}
                  </font>
                </div>
                <div className="displayFlexColumnHcVc">
                  <CEGeneralReact.GenericInputOrSelectFromInputType
                    p_valueRaw={this.state.s_exampleCaptureFavoriteTF}
                    p_fieldTypeObj={c_fieldMapOfCaptureFavorites.get("fieldTypeObj")}
                    f_onChangeOrOnSelect={this.onclick_example_capture_favorite}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }


    return(
      <>
        <div className="flex00a smallFullPad">
          <CEGeneralReact.TabsList
            p_tabDbNamesArray={["competitionTypeSBCertsMasterTbl", "naicsCodes", "productServiceCodesMasterTbl", "departmentsAgenciesMasterTbl", "subTiersMasterTbl", "idiqVehicleFieldOptions", "priorityFavorites"]}
            p_tabDisplayNamesArray={["Competition Type Set-Asides", "NAICS Codes", "Product Service Codes", "Departments/Agencies", "Sub-Tiers", idiqCaptureIDTOLinkFieldDisplayName, "Capture Priority / Favorites"]}
            p_selectedTabDbName={o_detailsFieldsOptionsSelectedSubtab}
            p_tabHeight="2.4em"
            p_textMaxHeight="2.2em"
            p_tabWidth="17em"
            p_unselectedFontClass="font11 fontBold fontTextLight"
            p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
            p_selectedBgClass="bgGoldGradient"
            f_onSelect={this.onclick_admin_contact_tab}
          />
        </div>
        {(tabTitle !== undefined) &&
          <div className="flex00a tbMargin lrMedPad">
            <div className="microBottomMargin">
              <font className="font11 fontBold">
                {tabTitle}
              </font>
            </div>
            <div className="">
              <font className="fontTextLight">
                {tabHelpLinesArray.map((m_tabHelpLine) =>
                  <div>{m_tabHelpLine}</div>
                )}
              </font>
            </div>
          </div>
        }
        <div className="flex11a displayFlexColumn medFullPad">
          {adminDetailsFieldsOptionsPageComponent}
        </div>
      </>
    );
  }
}));


class AdminBitMasterTblCheckboxes extends Component { //props: p_tblACompanyIDsToNotIncludeCommaFieldName, p_hiddenUnlessCheckedTFFieldName, p_tblDataArrayOfObjs, p_tblDataNameFieldName, p_tblRowIDsToNotIncludeArray
  constructor(props) {
    super(props);
    this.state = {
      s_searchFilterText: "",
      s_onlyShowCheckedTF: false
    }
  }

  onclick_only_show_checked = () => {
    const s_onlyShowCheckedTF = this.state.s_onlyShowCheckedTF;
    this.setState({s_onlyShowCheckedTF:!s_onlyShowCheckedTF});
  }

  onchange_search_filter_text = (i_newValueString) => {
    this.setState({s_searchFilterText:i_newValueString});
  }

  onclick_check_all = () => {
    const p_tblACompanyIDsToNotIncludeCommaFieldName = this.props.p_tblACompanyIDsToNotIncludeCommaFieldName;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminBitMasterTblCheckboxes", "onclick_check_all", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_company", 1, p_tblACompanyIDsToNotIncludeCommaFieldName, "", "s");
    C_CallPhpTblUID.execute();
  }

  onclick_uncheck_all = () => {
    const p_tblACompanyIDsToNotIncludeCommaFieldName = this.props.p_tblACompanyIDsToNotIncludeCommaFieldName;
    const p_tblDataArrayOfObjs = this.props.p_tblDataArrayOfObjs;

    const allRowIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(p_tblDataArrayOfObjs, "id");
    const allRowIDsComma = JSFUNC.convert_array_to_comma_list(allRowIDsArray);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminBitMasterTblCheckboxes", "onclick_uncheck_all", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_company", 1, p_tblACompanyIDsToNotIncludeCommaFieldName, allRowIDsComma, "s");
    C_CallPhpTblUID.execute();
  }

  render() {
    const s_onlyShowCheckedTF = this.state.s_onlyShowCheckedTF;
    const s_searchFilterText = this.state.s_searchFilterText;

    const p_tblACompanyIDsToNotIncludeCommaFieldName = this.props.p_tblACompanyIDsToNotIncludeCommaFieldName;
    const p_hiddenUnlessCheckedTFFieldName = this.props.p_hiddenUnlessCheckedTFFieldName;
    const p_tblDataArrayOfObjs = this.props.p_tblDataArrayOfObjs;
    const p_tblDataNameFieldName = this.props.p_tblDataNameFieldName;
    const p_tblRowIDsToNotIncludeArray = this.props.p_tblRowIDsToNotIncludeArray;

    const searchFilterFilledOutTF = JSFUNC.string_is_filled_out_tf(s_searchFilterText);
    var p_searchFilterTextLowercase = "";
    if(searchFilterFilledOutTF) {
      p_searchFilterTextLowercase = s_searchFilterText.toLowerCase();
    }

    return(
      <div className="flex11a medFullMargin border1bbb bgLighterGray smallFullPad yScroll">
        <div className="flex00a displayFlexRowVc smallBottomMargin">
          <div className="flex00a" style={{flexBasis:"20em"}}>
            <CEGeneralReact.SearchInputTextWithClearX
              p_inputText={s_searchFilterText}
              p_placeholderText="Search BIT Master Table Items"
              p_includeSearchIconTF={true}
              p_heightEm={undefined}
              p_bgHashColor="#fbfbfb"
              p_tabIndex={undefined}
              f_onChange={this.onchange_search_filter_text}
            />
          </div>
          <div className="flex11a" />
          <div className="flex00a displayFlexRowVc lrMedPad cursorPointer" onClick={this.onclick_only_show_checked}>
            <div className="flex00a rMargin">
              <font className="">
                {"Only Show Checked?"}
              </font>
            </div>
            <div className="flex00a">
              <LibraryReact.CheckBox
                p_u0_s1_p2_du3_ds4={((s_onlyShowCheckedTF) ? (1) : (0))}
                p_sizeEm={1.2}
              />
            </div>
          </div>
          <div className="flex00a lrMedPad">
            <CEGeneralReact.ButtonWithConfirmBox
              p_buttonType="add"
              p_buttonText="Check All"
              p_confirmType="confirm"
              p_confirmMessage="Are you sure you want to check all options?"
              f_onClickConfirm={this.onclick_check_all}
            />
          </div>
          <div className="flex00a lrMedPad">
            <CEGeneralReact.ButtonWithConfirmBox
              p_buttonType="add"
              p_buttonText="Uncheck All"
              p_confirmType="confirm"
              p_confirmMessage="Are you sure you want to uncheck all options?"
              f_onClickConfirm={this.onclick_uncheck_all}
            />
          </div>
        </div>
        {p_tblDataArrayOfObjs.map((m_tblDataObj) =>
          ((!searchFilterFilledOutTF || (JSFUNC.input_string_converted_to_lowercase_contains_lowercase_search_term_string_tf(m_tblDataObj[p_tblDataNameFieldName], p_searchFilterTextLowercase))) && (!s_onlyShowCheckedTF || (!m_tblDataObj[p_hiddenUnlessCheckedTFFieldName]))) &&
          <AdminBitMasterTblCheckboxesSingleRow
            p_tblACompanyIDsToNotIncludeCommaFieldName={p_tblACompanyIDsToNotIncludeCommaFieldName}
            p_hiddenUnlessCheckedTFFieldName={p_hiddenUnlessCheckedTFFieldName}
            p_tblDataObj={m_tblDataObj}
            p_tblDataNameFieldName={p_tblDataNameFieldName}
            p_tblRowIDsToNotIncludeArray={p_tblRowIDsToNotIncludeArray}
          />
        )}
      </div>
    );
  }
}


const AdminBitMasterTblCheckboxesSingleRow = inject("AdminMobx")(observer(
class AdminBitMasterTblCheckboxesSingleRow extends Component { //props: p_tblACompanyIDsToNotIncludeCommaFieldName, p_hiddenUnlessCheckedTFFieldName, p_tblDataObj, p_tblDataNameFieldName, p_tblRowIDsToNotIncludeArray
  onclick_bit_master_tbl_item_checkbox = () => {
    const p_tblACompanyIDsToNotIncludeCommaFieldName = this.props.p_tblACompanyIDsToNotIncludeCommaFieldName;
    const p_hiddenUnlessCheckedTFFieldName = this.props.p_hiddenUnlessCheckedTFFieldName;
    const p_tblDataObj = this.props.p_tblDataObj;
    const p_tblRowIDsToNotIncludeArray = this.props.p_tblRowIDsToNotIncludeArray;

    const addIDTrueRemoveIDFalse = (!(p_tblDataObj[p_hiddenUnlessCheckedTFFieldName]));
    var updatedIDsToNotIncludeArray = [];
    if(addIDTrueRemoveIDFalse) { //mark this row as hidden (unchecked)
      updatedIDsToNotIncludeArray = JSFUNC.concat_arrays_or_values_into_new_array(p_tblRowIDsToNotIncludeArray, p_tblDataObj.id);
    }
    else { //mark this row as visible (checked)
      updatedIDsToNotIncludeArray = JSFUNC.remove_all_values_from_array(p_tblDataObj.id, p_tblRowIDsToNotIncludeArray);
    }

    const updatedIDsToNotIncludeComma = JSFUNC.convert_array_to_comma_list(updatedIDsToNotIncludeArray);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminBitMasterTblCheckboxesSingleRow", "onclick_bit_master_tbl_item_checkbox", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_company", 1, p_tblACompanyIDsToNotIncludeCommaFieldName, updatedIDsToNotIncludeComma, "s");
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_tblACompanyIDsToNotIncludeCommaFieldName = this.props.p_tblACompanyIDsToNotIncludeCommaFieldName;
    const p_hiddenUnlessCheckedTFFieldName = this.props.p_hiddenUnlessCheckedTFFieldName;
    const p_tblDataObj = this.props.p_tblDataObj;
    const p_tblDataNameFieldName = this.props.p_tblDataNameFieldName;
    const p_tblRowIDsToNotIncludeArray = this.props.p_tblRowIDsToNotIncludeArray;

    const checkedTF = (!(p_tblDataObj[p_hiddenUnlessCheckedTFFieldName]));

    return(
      <div
        className="displayFlexRow borderB1ddd bgLightesterGray hoverLighterBlueGradient tbMicroPad cursorPointer"
        onClick={this.onclick_bit_master_tbl_item_checkbox}>
        <div className="flex00a displayFlexColumnHcVc lrPad">
          <LibraryReact.CheckBox
            p_u0_s1_p2_du3_ds4={((checkedTF) ? (1) : (0))}
            p_sizeEm={1.4}
          />
        </div>
        <div className="flex11a displayFlexRowVc lrPad">
          <font className="">
            {p_tblDataObj[p_tblDataNameFieldName]}
          </font>
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================






export const AdminSubtabDealShapingQuestions = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabDealShapingQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_importNewQuestionsFromCsvFloatingBoxIsOpenTF: false,
      s_importNewQuestionsFromCsvCurrentImportNumber: undefined,
      s_importNewQuestionsFromCsvCurrentQuestionText: undefined,
      s_importNewQuestionsFromCsvNumQuestionsToImport: undefined
    };
  }

  componentDidMount() {
    this.props.AdminMobx.a_initialize_questions_pool_selected_receiving_stage();
  }

  onclick_add_pool_question_to_receiving_stage = (i_questionID) => { //click the add button in the questions pool, add this question to the receiving stage
    this.props.AdminMobx.a_add_pool_question_to_receiving_stage(i_questionID, this.props.AdminMobx.o_questionsSelectedReceivingStageID);
  }

  onclick_import_new_questions_from_csv = () => {
    this.setState({s_importNewQuestionsFromCsvFloatingBoxIsOpenTF:true});
  }

  onclick_close_import_new_questions_From_csv_floating_box = () => {
    this.setState({s_importNewQuestionsFromCsvFloatingBoxIsOpenTF:false});
  }

  onclick_import_csv_into_new_questions = (i_csvDataArrayOrArrayOfArrays, i_runThisFunctionUponSuccess, i_runThisFunctionUponError) => {
    const c_questionsPoolArrayOfObjs = this.props.AdminMobx.c_questionsPoolArrayOfObjs;

    if(JSFUNC.is_array(i_csvDataArrayOrArrayOfArrays) && (i_csvDataArrayOrArrayOfArrays.length > 0)) { //if there is at least 1 question to import
      const numPoolQuestions = c_questionsPoolArrayOfObjs.length;

      const initialCurrent = 0;
      const initialMax = i_csvDataArrayOrArrayOfArrays.length;
      var initialLastPoolAdminSort = 0;
      if(numPoolQuestions > 0) {
        initialLastPoolAdminSort = c_questionsPoolArrayOfObjs[(numPoolQuestions - 1)].admin_sort;
      }

      this.setState({s_importNewQuestionsFromCsvNumQuestionsToImport:initialMax});

      this.recursive_import_csv_single_question_and_answers_into_questions_pool(i_csvDataArrayOrArrayOfArrays, initialCurrent, initialMax, initialLastPoolAdminSort, i_runThisFunctionUponSuccess);
    }
    else {
      i_runThisFunctionUponSuccess();
    }
  }

  recursive_import_csv_single_question_and_answers_into_questions_pool = (i_csvDataArrayOrArrayOfArrays, i_prevCurrent, i_max, i_previousPoolAdminSort, i_runThisFunctionUponSuccess) => {
    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabDealShapingQuestions", "recursive_import_csv_single_question_and_answers_into_questions_pool", ["i_csvDataArrayOrArrayOfArrays", "i_prevCurrent", "i_max", "i_previousPoolAdminSort"], [i_csvDataArrayOrArrayOfArrays, i_prevCurrent, i_max, i_previousPoolAdminSort]);

    const current = (i_prevCurrent + 1);
    const currentIndex = (current - 1);
    const currentDataRowArray = i_csvDataArrayOrArrayOfArrays[currentIndex];
    const currentDataRowIsArrayTF = JSFUNC.is_array(currentDataRowArray);
    const currentDataRowNumColumns = ((currentDataRowIsArrayTF) ? (currentDataRowArray.length) : (0));
    const currentNewQuestionAdminSort = (i_previousPoolAdminSort + 1);

    if((current > i_max) || (currentDataRowNumColumns < 4)) { //end recursion if the last item was handled on the previous loop, or if this data row is not an array with at least 4 columns (all rows should be arrays for this 2D csv shaping questions import)
      i_runThisFunctionUponSuccess();

      this.setState({
        s_importNewQuestionsFromCsvCurrentImportNumber: undefined,
        s_importNewQuestionsFromCsvNumQuestionsToImport: undefined
      });
    }
    else {
      const questionText = currentDataRowArray[0];
      const questionTypeSelectTextTextareaString = JSFUNC.str2lower(JSFUNC.remove_whitespace_from_string(currentDataRowArray[1]));
      var questionType123 = 3; //textarea by default
      var questionTypeIsSelectTF = false;
      if(questionTypeSelectTextTextareaString === "select") { questionType123 = 1; questionTypeIsSelectTF = true; }
      else if(questionTypeSelectTextTextareaString === "text") { questionType123 = 2; }
      else if(questionTypeSelectTextTextareaString === "textarea") { questionType123 = 3; }
      const questionTagIDsComma = JSFUNC.convert_unformatted_comma_list_to_int_only_comma_list(currentDataRowArray[2]);
      const questionHoverText = ((JSFUNC.string_is_filled_out_tf(currentDataRowArray[3])) ? (currentDataRowArray[3]) : (""));
      var selectAnswersArrayOfObjs = [];
      if(questionTypeIsSelectTF) {
        var colIndex = 4;
        var checkNextAnswerPairTF = true;
        while(checkNextAnswerPairTF && (colIndex < currentDataRowNumColumns)) {
          var answerText = currentDataRowArray[colIndex];
          var answerPercent0to100Int = JSFUNC.str2int(currentDataRowArray[(colIndex + 1)]);
          if(JSFUNC.string_is_filled_out_tf(answerText) && JSFUNC.is_number(answerPercent0to100Int)) {
            selectAnswersArrayOfObjs.push({
              answerText: answerText,
              answerPercent0to100: answerPercent0to100Int
            });
            colIndex += 2;
          }
          else {
            checkNextAnswerPairTF = false;
          }
        }
      }

      this.setState({
        s_importNewQuestionsFromCsvCurrentImportNumber: current,
        s_importNewQuestionsFromCsvCurrentQuestionText: "[" + questionTypeSelectTextTextareaString + "] " + questionText
      });

      //------insert question-----------------------
      const C_CallPhpTblUIDInsertQuestion = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const questionFieldNamesArray = ["name", "answer_select1_text2_textarea3", "tag_ids_comma", "admin_sort", "hover_text", "question_name_styling_string_comma"];
      const questionValuesArrayOfArrays = [questionText, questionType123, questionTagIDsComma, currentNewQuestionAdminSort, questionHoverText, ""]; //styling string not an option for .csv import, always "" default
      const questionIdsbArray = ["s", "i", "s", "i", "s", "s"];
      C_CallPhpTblUIDInsertQuestion.add_insert("tbl_a_shaping_questions_pool", questionFieldNamesArray, questionValuesArrayOfArrays, questionIdsbArray);

      const functionOnSuccessInsertQuestion = (i_parseResponse) => {
        const newlyInsertedQuestionID = i_parseResponse.outputObj.i0;
        if(questionTypeIsSelectTF) { //select question, need to insert answers which contain newly inserted questionID
          //&&&&&&inset answers&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
          const C_CallPhpTblUIDInsertAnswers = new JSPHP.ClassCallPhpTblUID(jsDescription);
          
          const answersFieldNamesArray = ["question_id", "name", "score0to100"];
          const answersIdsbArray = ["i", "s", "i"];
          for(let selectAnswerObj of selectAnswersArrayOfObjs) {
            var answersValuesArrayOfArrays = [newlyInsertedQuestionID, selectAnswerObj.answerText, selectAnswerObj.answerPercent0to100];
            C_CallPhpTblUIDInsertAnswers.add_insert("tbl_a_shaping_select_answers", answersFieldNamesArray, answersValuesArrayOfArrays, answersIdsbArray);
          }

          const functionOnFinishInsertAnswers = () => { //success or failure of inserting select question answers, move to the next question
            this.recursive_import_csv_single_question_and_answers_into_questions_pool(i_csvDataArrayOrArrayOfArrays, current, i_max, currentNewQuestionAdminSort, i_runThisFunctionUponSuccess);
          }
          C_CallPhpTblUIDInsertAnswers.add_function("onFinish", functionOnFinishInsertAnswers);

          C_CallPhpTblUIDInsertAnswers.execute();
          //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
        }
        else { //text/textarea question, finished inserting can move to next question
          this.recursive_import_csv_single_question_and_answers_into_questions_pool(i_csvDataArrayOrArrayOfArrays, current, i_max, currentNewQuestionAdminSort, i_runThisFunctionUponSuccess);
        }
      }
      C_CallPhpTblUIDInsertQuestion.add_function("onSuccess", functionOnSuccessInsertQuestion);

      const functionOnErrorInsertQuestion = () => { //if there is an error inserting this question, skip it and move to the next with no error handling or reporting
        this.recursive_import_csv_single_question_and_answers_into_questions_pool(i_csvDataArrayOrArrayOfArrays, current, i_max, currentNewQuestionAdminSort, i_runThisFunctionUponSuccess);
      }
      C_CallPhpTblUIDInsertQuestion.add_function("onError", functionOnErrorInsertQuestion);

      C_CallPhpTblUIDInsertQuestion.execute();
      //--------------------------------------------
    }
  }

  onclick_cancel_import_new_questions_to_pool_from_csv = () => {
    this.setState({
      s_importNewQuestionsFromCsvFloatingBoxIsOpenTF: false,
      s_importNewQuestionsFromCsvCurrentImportNumber: undefined,
      s_importNewQuestionsFromCsvCurrentQuestionText: undefined,
      s_importNewQuestionsFromCsvNumQuestionsToImport: undefined
    });
  }

  onclick_pool_view_print_questions = () => {
    this.onclick_view_print_questions(true);
  }

  onclick_capture_type_view_print_questions = () => {
    this.onclick_view_print_questions(false);
  }

  onclick_view_print_questions = (i_poolTrueCaptureTypeFalse) => {
    const c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined = this.props.CaptureExecMobx.c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_questionsPoolArrayOfObjs = this.props.AdminMobx.c_questionsPoolArrayOfObjs;
    const c_selectedCaptureTypeQuestionsPerStageObj = this.props.AdminMobx.c_selectedCaptureTypeQuestionsPerStageObj;
    const c_customDealShapingQuestionsStringFromCardName = this.props.DatabaseMobx.c_customDealShapingQuestionsStringFromCardName;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    if(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined !== undefined) {
      const activeStagesArrayOfObjs = c_selectedCaptureTypeQuestionsPerStageObj.activeStagesArrayOfObjs;
      const closedStageTypesArrayOfObjs = c_selectedCaptureTypeQuestionsPerStageObj.closedStageTypesArrayOfObjs;

      const maxNumCols = 8;
      const questionNumberColWidthPx = 30;
      const answerTypeColWidthPx = 45;
      const tagsColWidthPx = 75;
      const idColWidthPx = 55;
      const requiredColWidthPx = 40;
      const answerIndentWidthPx = 40;

      var viewPrintQuestionsHtmlString = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml">';
      viewPrintQuestionsHtmlString += '<head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /><title>' + c_productStylingObj.productName + ' - ' + c_customDealShapingQuestionsStringFromCardName + '</title><meta name="viewport" content="width=device-width, initial-scale=1.0"/>';
      viewPrintQuestionsHtmlString += '<style>td{padding:2px;}</style></head>';
      viewPrintQuestionsHtmlString += '<body style="box-sizing:border-box; margin:0; padding:3px; border:0; background:#ffffff; font-family:sans-serif; font-size:12px; color:#000000">';
      viewPrintQuestionsHtmlString += '<table style="width:850px; border-collapse:collapse; border-spacing:0;">';
      
      if(i_poolTrueCaptureTypeFalse) {
        viewPrintQuestionsHtmlString += '<tr><td colspan="' + maxNumCols + '" style="padding-bottom:5px; font-size:14px;">All ' + c_customDealShapingQuestionsStringFromCardName + ' in Questions Pool</td></tr>';

        for(let questionPoolObj of c_questionsPoolArrayOfObjs) {
          viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc;">';
          viewPrintQuestionsHtmlString += '<td style="width:' + questionNumberColWidthPx + 'px;">' + questionPoolObj.admin_sort + '</td>';
          viewPrintQuestionsHtmlString += '<td style="width:' + answerTypeColWidthPx + 'px; font-style:italic;">[' + questionPoolObj.answerTypeShort + ']</td>';
          viewPrintQuestionsHtmlString += '<td style="width:' + tagsColWidthPx + 'px;">Tags: ' + ((JSFUNC.selectmulti_is_filled_out_tf(questionPoolObj.tag_ids_comma)) ? ("{" + questionPoolObj.tag_ids_comma + "}") : ("-")) + '</td>';
          viewPrintQuestionsHtmlString += '<td style="width:' + idColWidthPx + 'px; font-style:italic;">[ID: ' + questionPoolObj.id + ']</td>';
          viewPrintQuestionsHtmlString += '<td></td>';
          viewPrintQuestionsHtmlString += '<td colspan="3">' + questionPoolObj.name + ((JSFUNC.string_is_filled_out_tf(questionPoolObj.hover_text)) ? ('<br /><font style="font-style:italic;">{Hover: ' + questionPoolObj.hover_text + '}</font>') : ('')) + '</td>';
          viewPrintQuestionsHtmlString += '</tr>';

          if(questionPoolObj.numAnswers > 0) {
            for(let answerObj of questionPoolObj.answersArrayOfObjs) {
              viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc; font-style:italic;">';
              viewPrintQuestionsHtmlString += '<td colspan="5"></td>';
              viewPrintQuestionsHtmlString += '<td style="width:' + answerIndentWidthPx + 'px;"></td>';
              viewPrintQuestionsHtmlString += '<td>- ' + answerObj.name + '</td>';
              viewPrintQuestionsHtmlString += '<td style="text-align:right;">' + answerObj.score0to100 + '%</td>';
              viewPrintQuestionsHtmlString += '</tr>';
            }
          }
        }
      }
      else {
      viewPrintQuestionsHtmlString += '<tr><td colspan="' + maxNumCols + '" style="padding-bottom:5px; font-size:14px;">' + c_customDealShapingQuestionsStringFromCardName + ' for ' + c_fieldMapOfCaptureType.get("display_name") + ' "' + c_selectedCaptureTypeObj.name + '"</td></tr>';

        for(let activeStageObj of activeStagesArrayOfObjs) {
          var stageObj = activeStageObj.stageObj;
          var questionsArrayOfObjs = activeStageObj.questionsArrayOfObjs;

          viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc; background:#ccc; font-size:15px;"><td colspan="' + maxNumCols + '">' + stageObj.name + '</td></tr>';

          var q = 1; //question sort number within each stage
          for(let questionObj of questionsArrayOfObjs) {
            //find the matching question pool obj from this question_id field (of this question being assigned to this capture type)
            var questionPoolObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_questionsPoolArrayOfObjs, "id", questionObj.question_id);
            if(questionPoolObj !== undefined) { //the matching question pool obj was found in the array of all pool questions
              viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc;">';
              viewPrintQuestionsHtmlString += '<td style="width:' + questionNumberColWidthPx + 'px;">' + q + '</td>';
              viewPrintQuestionsHtmlString += '<td style="width:' + answerTypeColWidthPx + 'px; font-style:italic;">[' + questionPoolObj.answerTypeShort + ']</td>';
              viewPrintQuestionsHtmlString += '<td>Pr: ' + questionObj.progress_weight + '</td>';
              viewPrintQuestionsHtmlString += '<td>PW: ' + questionObj.pwin_weight + '</td>';
              viewPrintQuestionsHtmlString += '<td style="width:' + requiredColWidthPx + 'px;">R: ' + ((questionObj.requiredTF) ? ("Y") : ("-")) + '</td>';
              viewPrintQuestionsHtmlString += '<td colspan="3">' + questionPoolObj.name + ((JSFUNC.string_is_filled_out_tf(questionPoolObj.hover_text)) ? ('<br /><font style="font-style:italic;">{Hover: ' + questionPoolObj.hover_text + '}</font>') : ('')) + '</td>';
              viewPrintQuestionsHtmlString += '</tr>';

              if(questionPoolObj.numAnswers > 0) {
                for(let answerObj of questionPoolObj.answersArrayOfObjs) {
                  viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc; font-style:italic;">';
                  viewPrintQuestionsHtmlString += '<td colspan="5"></td>';
                  viewPrintQuestionsHtmlString += '<td style="width:' + answerIndentWidthPx + 'px;"></td>';
                  viewPrintQuestionsHtmlString += '<td>- ' + answerObj.name + '</td>';
                  viewPrintQuestionsHtmlString += '<td style="text-align:right;">' + answerObj.score0to100 + '%</td>';
                  viewPrintQuestionsHtmlString += '</tr>';
                }
              }
            }

            q++;
          }
        }

        for(let closedStageTypeObj of closedStageTypesArrayOfObjs) {
          var closedStagesArrayOfObjs = closedStageTypeObj.closedStagesArrayOfObjs;

          for(let closedStageObj of closedStagesArrayOfObjs) {
            var stageObj = closedStageObj.stageObj;
            var questionsArrayOfObjs = closedStageObj.questionsArrayOfObjs;

            viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc; background:#ccc; font-size:15px; font-style:italic;"><td colspan="' + maxNumCols + '">' + stageObj.name + '</td></tr>';

            var q = 1; //question sort number within each stage
            for(let questionObj of questionsArrayOfObjs) {
              //find the matching question pool obj from this question_id field (of this question being assigned to this capture type)
              var questionPoolObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_questionsPoolArrayOfObjs, "id", questionObj.question_id);
              if(questionPoolObj !== undefined) { //the matching question pool obj was found in the array of all pool questions
                viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc;">';
                viewPrintQuestionsHtmlString += '<td style="width:' + questionNumberColWidthPx + 'px;">' + q + '</td>';
                viewPrintQuestionsHtmlString += '<td style="width:' + answerTypeColWidthPx + 'px; font-style:italic;">[' + questionPoolObj.answerTypeShort + ']</td>';
                viewPrintQuestionsHtmlString += '<td>-</td>';
                viewPrintQuestionsHtmlString += '<td>-</td>';
                viewPrintQuestionsHtmlString += '<td style="width:' + requiredColWidthPx + 'px;">-</td>';
                viewPrintQuestionsHtmlString += '<td colspan="3">' + questionObj.name + '</td>';
                viewPrintQuestionsHtmlString += '</tr>';

                if(questionPoolObj.numAnswers > 0) {
                  for(let answerObj of questionPoolObj.answersArrayOfObjs) {
                    viewPrintQuestionsHtmlString += '<tr style="border:solid 1px #ccc; font-style:italic;">';
                    viewPrintQuestionsHtmlString += '<td colspan="5"></td>';
                    viewPrintQuestionsHtmlString += '<td style="width:' + answerIndentWidthPx + 'px;"></td>';
                    viewPrintQuestionsHtmlString += '<td>- ' + answerObj.name + '</td>';
                    viewPrintQuestionsHtmlString += '<td style="text-align:right;">' + answerObj.score0to100 + '%</td>';
                    viewPrintQuestionsHtmlString += '</tr>';
                  }
                }
              }

              q++;
            }
          }
        }
      }

      viewPrintQuestionsHtmlString += '</table>';
      viewPrintQuestionsHtmlString += '</body>';
      viewPrintQuestionsHtmlString += '</html>';

      const jsWindowNewTab = window.open(c_productWebsiteAddressEndingSlashAndDevRemovedOrUndefined, "_blank");
      jsWindowNewTab.document.write(viewPrintQuestionsHtmlString);
    }
  }

  render() {
    const s_importNewQuestionsFromCsvFloatingBoxIsOpenTF = this.state.s_importNewQuestionsFromCsvFloatingBoxIsOpenTF;
    const s_importNewQuestionsFromCsvCurrentImportNumber = this.state.s_importNewQuestionsFromCsvCurrentImportNumber;
    const s_importNewQuestionsFromCsvCurrentQuestionText = this.state.s_importNewQuestionsFromCsvCurrentQuestionText;
    const s_importNewQuestionsFromCsvNumQuestionsToImport = this.state.s_importNewQuestionsFromCsvNumQuestionsToImport;

    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_questionsPoolArrayOfObjs = this.props.AdminMobx.c_questionsPoolArrayOfObjs;
    const c_selectedCaptureTypeQuestionsPerStageObj = this.props.AdminMobx.c_selectedCaptureTypeQuestionsPerStageObj;
    const c_customDealShapingQuestionsStringFromCardName = this.props.DatabaseMobx.c_customDealShapingQuestionsStringFromCardName;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;
    const c_valueDisplayArraysObjCaptureShapingQuestionTags = this.props.DatabaseMobx.c_valueDisplayArraysObjCaptureShapingQuestionTags;

    const drawRecursiveImportNewQuestionsProgressFloatingBoxTF = (JSFUNC.is_number_not_nan_gt_0(s_importNewQuestionsFromCsvCurrentImportNumber) && JSFUNC.is_number_not_nan_gt_0(s_importNewQuestionsFromCsvNumQuestionsToImport));

    const activeStagesArrayOfObjs = c_selectedCaptureTypeQuestionsPerStageObj.activeStagesArrayOfObjs;
    const activeQuestionIDsAlreadyAddedArray = c_selectedCaptureTypeQuestionsPerStageObj.activeQuestionIDsAlreadyAddedArray;
    const closedStageTypesArrayOfObjs = c_selectedCaptureTypeQuestionsPerStageObj.closedStageTypesArrayOfObjs;
    const selectedReceivingStageQuestionIDsAlreadyAddedArray = c_selectedCaptureTypeQuestionsPerStageObj.selectedReceivingStageQuestionIDsAlreadyAddedArray;

    const functionDeleteDealShapingQuestionCleanup = (i_C_CallPhpTblUID, i_questionRowObj) => {
      const questionID = i_questionRowObj.id;

      //delete answers to this question if it was a select type question
      const selectAnswerIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(this.props.DatabaseMobx.o_tbl_a_shaping_select_answers, "question_id", questionID, "id");
      i_C_CallPhpTblUID.add_delete("tbl_a_shaping_select_answers", selectAnswerIDsToDeleteArray); //no sort column in tbl_a_shaping_select_answers to resort after delete

      //find any assignments of this questionID to any capture type for active stages
      for(let [captureTypeShapingQuestionRecordID, captureTypeShapingQuestionRecordMap] of this.props.DatabaseMobx.o_tbl_a_capture_types_shaping_questions) {
        if(captureTypeShapingQuestionRecordMap.get("question_id") === questionID) { //delete this record if the question_id matches the questionID of the question that was just deleted
          var resortSortColumnName = "sort";
          var resortFilterFieldNameOrFieldNamesArray = ["capture_type_id", "stage_id_relevant"];
          var resortFilterValueOrValuesArray = [captureTypeShapingQuestionRecordMap.get("capture_type_id"), captureTypeShapingQuestionRecordMap.get("stage_id_relevant")];
          i_C_CallPhpTblUID.add_delete("tbl_a_capture_types_shaping_questions", captureTypeShapingQuestionRecordID, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
        }
      }

      //find any assignments of this questionID to any capture type for debrief stages
      for(let [captureTypeDebriefQuestionRecordID, captureTypeDebriefQuestionRecordMap] of this.props.DatabaseMobx.o_tbl_a_capture_types_debrief_questions) {
        if(captureTypeDebriefQuestionRecordMap.get("question_id") === questionID) { //delete this record if the question_id matches the questionID of the question that was just deleted
          var resortSortColumnName = "sort";
          var resortFilterFieldNameOrFieldNamesArray = ["capture_type_id", "closed_stage_id"];
          var resortFilterValueOrValuesArray = [captureTypeDebriefQuestionRecordMap.get("capture_type_id"), captureTypeDebriefQuestionRecordMap.get("closed_stage_id")];
          i_C_CallPhpTblUID.add_delete("tbl_a_capture_types_debrief_questions", captureTypeDebriefQuestionRecordID, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
        }
      }
    }

    const answerTypeFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Answer Type", [1, 3], false, ["Scored Select", "Scored Textarea"], undefined);

    var answerTypeColumnWidthEm = 6.5;
    var numAnswersColumnWidthEm = 7;
    var hoverHelpTextColumnWidthEm = 7;
    var styleColumnWidthEm = 5;
    if(o_mediaQueryFlag <= 2) {
      answerTypeColumnWidthEm = 4;
      numAnswersColumnWidthEm = 4;
      hoverHelpTextColumnWidthEm = 4;
      styleColumnWidthEm = 3;
    }

    const aedTableObj = {
      tblName: "tbl_a_shaping_questions_pool",
      dragToResortFieldDbName: "admin_sort",
      canAlwaysDragResortTF: true,
      initialSortedFieldDbName: "admin_sort",
      tableWidth: "100%",
      itemName: "Question",
      deleteItemNameFieldDbName: "name",
      searchFieldDbName: "name",
      idsAlreadyAddedArray: selectedReceivingStageQuestionIDsAlreadyAddedArray,
      itemDeleteFunctionsArray: [functionDeleteDealShapingQuestionCleanup],
      fieldsArrayOfObjs: [
        {width:"500em", dbName:"name", displayName:"Question", fieldTypeObj:this.props.DatabaseMobx.c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF: true},
        {width:answerTypeColumnWidthEm + "em", fixedWidthTF:true, dbName:"answerTypeShort", displayName:"Answer Type", fieldTypeObj:undefined, initialValue:undefined, cellCenterTF:true, rawValueDisplayedFieldNotOnFormTF:true},
        {width:undefined, dbName:"answer_select1_text2_textarea3", displayName:"Answer Type", fieldTypeObj:answerTypeFieldTypeObj, initialValue:1},
        {width:numAnswersColumnWidthEm + "em", fixedWidthTF:true, dbName:"numAnswers", displayName:"# Answers", fieldTypeObj:undefined, initialValue:undefined, cellCenterTF:true, rawValueDisplayedFieldNotOnFormTF:true},
        {width:undefined, dbName:"answersArrayOfObjs", displayName:"Answers (for Question Type 'Select')", fieldTypeObj:{editAnswersForSelectQuestionTF:true}, initialValue:undefined},
        {width:"100em", dbName:"tag_ids_comma", displayName:"Tags", fieldTypeObj:this.props.DatabaseMobx.c_selectMultiAddDealShapingQuestionTagsFieldTypeObj, initialValue:"", cellHoverTF: true},
        {width:hoverHelpTextColumnWidthEm + "em", fixedWidthTF:true, dbName:"hover_text", displayName:"Hover Help Text", fieldTypeObj:this.props.DatabaseMobx.c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF: true},
        {width:styleColumnWidthEm + "em", fixedWidthTF:true, dbName:"question_name_styling_string_comma", displayName:"Style", fieldTypeObj:this.props.DatabaseMobx.c_genericStylingStringFontBlueFieldTypeObj, initialValue:"", cellCenterTF:true, cellHoverTF:true, cellDisplayBlankIfNotFilledOutTF:true},
        {width:"3em", dbName:"admin_sort", displayName:"Question Pool Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    //upper right vertical dots menu for printing pool or capture type questions/answers
    const adminDealShapingPoolVerticalDotsMenuItemsArrayOfObjs = [
      {
        displayName: "Import New Questions from .csv",
        functionOnClickMenuItem: this.onclick_import_new_questions_from_csv
      },
      {
        displayName: "[Entire Pool] View/Print Questions and All Answers",
        functionOnClickMenuItem: this.onclick_pool_view_print_questions
      }
    ];

    const adminDealShapingCaptureTypeVerticalDotsMenuItemsArrayOfObjs = [
      {
        displayName: "[Selcted " + c_fieldMapOfCaptureType.get("display_name") + "] View/Print Questions and All Answers",
        functionOnClickMenuItem: this.onclick_capture_type_view_print_questions
      }
    ];

    var importShapingQuestionsFromCsvInstructionsHtml = null;
    if(s_importNewQuestionsFromCsvFloatingBoxIsOpenTF) {
      const csvInstructionsColumnsTextArray = [
        "Column A: Question Text",
        "Column B: Question Type (enter 'select', 'text', or 'textarea' without the quotes)",
        "Column C: Tag ID number(s) separated by commas",
        "Column D: Hover Help Text",
        "Column E: ('select' type only) Select Answer #1 Text",
        "Column F: ('select' type only) Select Answer #1 Score [0-100]",
        "Column G: ('select' type only) Select Answer #2 Text",
        "Column H: ('select' type only) Select Answer #2 Score [0-100]",
        "Columns I-?: ('select' type only) Answers #3-? as needed"
      ];
      importShapingQuestionsFromCsvInstructionsHtml = (
        <>
          <div className="microBottomMargin"><font className="">{"Newly imported Questions will be added to the bottom of the existing pool of Questions"}</font></div>
          <div className=""><font className="">{"Only columns 'A' through 'I' will be displayed on the next page - all columns will be imported"}</font></div>
          <div className="hugeTopMargin" />
          <div className="microBottomMargin"><font className="">{"Example .csv file columns for new Questions import (no header row, first row is first Question to import)"}</font></div>
          {csvInstructionsColumnsTextArray.map((m_csvInstructionsColumnText) =>
            <div style={{marginLeft:"0.5em"}}><font className="fontTextLight">{m_csvInstructionsColumnText}</font></div>
          )}
          <div className="hugeTopMargin" />
          <div className="microBottomMargin"><font className="">{"Tags with [ID numbers] to use in Tags column C"}</font></div>
          {c_valueDisplayArraysObjCaptureShapingQuestionTags.valueArray.map((m_value, m_index) =>
            <div style={{marginLeft:"0.5em"}}><font className="fontTextLight">{"[" + m_value + "] " + c_valueDisplayArraysObjCaptureShapingQuestionTags.displayArray[m_index]}</font></div>
          )}
        </>
      );
    }

    var recursiveProgressTextHtml = null;
    if(drawRecursiveImportNewQuestionsProgressFloatingBoxTF) {
      recursiveProgressTextHtml = (
        <>
          <div className="smallBottomMargin">
            <font className="">
              {"Importing Question " + s_importNewQuestionsFromCsvCurrentImportNumber + " of " + s_importNewQuestionsFromCsvNumQuestionsToImport}
            </font>
          </div>
          <div className="">
            <font className="fontItalic">
              {"'" + s_importNewQuestionsFromCsvCurrentQuestionText + "'"}
            </font>
          </div>
        </>
      );
    }

    return (
      <div className="flex11a displayFlexColumn">
        <CaptureTypeSelectionBoxes />
        <div className="flex11a displayFlexRow">
          <div className="flex11a yScroll lrPad" style={{flexBasis:"150em"}}>
            <div className="displayFlexRow" style={{minHeight:"3em"}}>
              <div className="flex11a displayFlexRowVc smallFullPad">
                <font className="font13 fontBold fontUnderline fontItalic">
                  {"Questions Pool:"}
                </font>
                <font className="font13 fontItalic">
                  {" All unique " + c_customDealShapingQuestionsStringFromCardName + " used for every " + c_fieldMapOfCaptureType.get("display_name")}
                </font>
              </div>
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
                <CEGeneralReact.VerticalDotsMenu
                  p_menuItemsArrayOfObjs={adminDealShapingPoolVerticalDotsMenuItemsArrayOfObjs}
                />
              </div>
            </div>
            <AddEditDeleteTable
              p_dataArrayOfObjs={c_questionsPoolArrayOfObjs}
              p_aedTableObj={aedTableObj}
              f_onClickAddItem={this.onclick_add_pool_question_to_receiving_stage}
            />
          </div>
          <div className="flex00a" style={{flexBasis:"0.5em"}} />
          <div className="flex11a yScroll" style={{flexBasis:"100em"}}>
            <div className="displayFlexRow" style={{minHeight:"3em"}}>
              <div className="flex11a displayFlexRowVc smallFullPad">
                <font className="font13 fontBold fontUnderline fontItalic">
                  {"Assigned Questions per Stage:"}
                </font>
                <font className="font13 fontItalic">
                  {" for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
                </font>
              </div>
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
                <CEGeneralReact.VerticalDotsMenu
                  p_menuItemsArrayOfObjs={adminDealShapingCaptureTypeVerticalDotsMenuItemsArrayOfObjs}
                />
              </div>
            </div>
            <div className="smallTopPad lrPad" style={{border:"solid 1px #bbb"}}>
              <div className="">
                <font className="font13 fontBold fontItalic">
                  {"Questions for Active Stages"}
                </font>
              </div>
              {activeStagesArrayOfObjs.map((m_activeStageObj) =>
                <ActiveStageOfAddedQuestions
                  key={m_activeStageObj.stageObj.id}
                  p_activeStageObj={m_activeStageObj}
                  p_activeQuestionIDsAlreadyAddedArray={activeQuestionIDsAlreadyAddedArray}
                />
              )}
            </div>
            <div className="hugeTopMargin smallFullPad" style={{border:"solid 1px #bbb"}}>
              <div className="">
                <font className="font13 fontBold fontItalic">
                  {"Debrief Questions for Closed Stages"}
                </font>
              </div>
              {closedStageTypesArrayOfObjs.map((m_closedStageTypeObj, m_index) =>
                <div
                  className="medTopMargin hugeBottomMargin"
                  style={{borderTop:((m_index > 0) ? ("solid 1px #ccc") : (undefined))}}>
                  <div className="tbMargin">
                    <font className="font12 fontItalic">
                      {m_closedStageTypeObj.stageTypeName}
                    </font>
                  </div>
                  {m_closedStageTypeObj.closedStagesArrayOfObjs.map((m_closedStageObj) =>
                    <ClosedStageOfAddedQuestions
                      key={m_closedStageObj.stageObj.id}
                      p_closedStageObj={m_closedStageObj}
                      p_closedQuestionIDsAlreadyAddedArray={m_closedStageObj.closedStageQuestionIDsAlreadyAddedArray}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {(s_importNewQuestionsFromCsvFloatingBoxIsOpenTF) &&
          <CEGeneralReact.ImportDataFromCsvFileFloatingBox
            p_trblFlag="medium"
            p_importTitle={"Import New " + c_customDealShapingQuestionsStringFromCardName + " into Questions Pool"}
            p_instructionsHtmlOrUndefined={importShapingQuestionsFromCsvInstructionsHtml}
            p_existingValuesArray={undefined}
            p_maxNumColumnsInCsvOrUndefined={undefined}
            p_maxNumColumnsToShow={10}
            p_combineAll2DDataInto1DTF={false}
            p_markAndPreventDuplicateEntriesTF={false}
            f_onClickImportCsvData={this.onclick_import_csv_into_new_questions}
            f_onClickClose={this.onclick_close_import_new_questions_From_csv_floating_box}
          />
        }
        {(drawRecursiveImportNewQuestionsProgressFloatingBoxTF) &&
          <CEGeneralReact.RecursiveProgressBarFloatingBox
            p_floatingBoxTitle={"Importing New " + c_customDealShapingQuestionsStringFromCardName + " into Questions Pool"}
            p_progressTextHtmlOrUndefined={recursiveProgressTextHtml}
            p_currentItemNumber={s_importNewQuestionsFromCsvCurrentImportNumber}
            p_totalNumItems={s_importNewQuestionsFromCsvNumQuestionsToImport}
            p_progressColor="fb7"
            f_onClickCancel={this.onclick_cancel_import_new_questions_to_pool_from_csv}
          />
        }
      </div>
    );
  }
}));

export const ActiveStageOfAddedQuestions = inject("AdminMobx")(observer(
class ActiveStageOfAddedQuestions extends Component { //props: p_activeStageObj, p_activeQuestionIDsAlreadyAddedArray
  onclick_set_stage_as_receiving_questions = () => {
    this.props.AdminMobx.a_set_receiving_item_collection_id(this.props.p_activeStageObj.stageObj.id);
  }

  ondrop_question_from_pool_into_active_stage_drop_zone = (i_draggedQuestionID) => {
    if(!JSFUNC.in_array(i_draggedQuestionID, this.props.p_activeQuestionIDsAlreadyAddedArray)) {
      this.props.AdminMobx.a_add_pool_question_to_receiving_stage(i_draggedQuestionID, this.props.p_activeStageObj.stageObj.id);
    }
  }

  onremove_all_shaping_questions_in_active_stage_from_capture_type = () => {
    const p_activeStageObj = this.props.p_activeStageObj;
    const allCaptureTypeShapingQuestionsRowIDsInActiveStageArray = JSFUNC.get_column_vector_from_arrayOfObjs(p_activeStageObj.questionsArrayOfObjs, "id");
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx - ActiveStageOfAddedQuestions", "onremove_all_shaping_questions_in_active_stage_from_capture_type", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_capture_types_shaping_questions", allCaptureTypeShapingQuestionsRowIDsInActiveStageArray);
    C_CallPhpTblUID.execute();
  }

  render() {
    const activeStageObj = this.props.p_activeStageObj;
    const activeQuestionIDsAlreadyAddedArray = this.props.p_activeQuestionIDsAlreadyAddedArray;

    const questionsSelectedReceivingStageID = this.props.AdminMobx.o_questionsSelectedReceivingStageID;

    const stageObj = activeStageObj.stageObj;
    const questionsArrayOfObjs = activeStageObj.questionsArrayOfObjs;
    const numQuestions = questionsArrayOfObjs.length;

    return (
      <BlueItemCollectionShell
        p_isReceivingTF={(stageObj.id === questionsSelectedReceivingStageID)}
        p_title={"Stage: '" + stageObj.name + "'"}
        p_dropUniqueString="AddEditDeleteTableRow_tbl_a_shaping_questions_pool"
        p_dropNotAllowedDraggerIDsArray={activeQuestionIDsAlreadyAddedArray}
        p_dropMessage="[Drag/drop questions from the pool]"
        p_dropAllowDropMessage={"[Drop question to add to stage '" + stageObj.name + "']"}
        p_dropNotAllowDropMessage="[Cannot add a question already added to one of the active stages]"
        f_onClickSetAsReceiving={this.onclick_set_stage_as_receiving_questions}
        f_onDrop={this.ondrop_question_from_pool_into_active_stage_drop_zone}
        f_onRemoveAllItems={this.onremove_all_shaping_questions_in_active_stage_from_capture_type}>
        {questionsArrayOfObjs.map((m_questionObj, m_index) =>
          <ActiveStageAddedDealShapingQuestionItem
            key={m_questionObj.id}
            p_stageObj={stageObj}
            p_questionObj={m_questionObj}
            p_sortNumber={(m_index + 1)}
            p_isOnlyItemTF={(numQuestions === 1)}
            p_isLastItemTF={(m_index === (numQuestions - 1))}
          />
        )}
      </BlueItemCollectionShell>
    );
  }
}));


export const ActiveStageAddedDealShapingQuestionItem = inject("AdminMobx", "DatabaseMobx")(observer(
class ActiveStageAddedDealShapingQuestionItem extends Component { //props: p_stageObj, p_questionObj, p_sortNumber, p_isOnlyItemTF, p_isLastItemTF
  constructor(props) {
    super(props);
    this.state = {
      s_editingAddedCaptureTypeQuestionRecordTF: false
    };
  }

  onclick_capture_type_stage_question_item_to_edit = () => {
    this.setState({s_editingAddedCaptureTypeQuestionRecordTF:true});
  }

  onclick_close_capture_type_stage_question_item_to_edit = () => {
    this.setState({s_editingAddedCaptureTypeQuestionRecordTF:false});
  }

  onclick_remove_question_from_capture_type_stage = () => {
    const p_questionObj = this.props.p_questionObj;
    this.props.AdminMobx.a_remove_question_from_capture_type_stage(true, p_questionObj.id, p_questionObj.capture_type_id, p_questionObj.stage_id_relevant);
  }

  render() {
    const s_editingAddedCaptureTypeQuestionRecordTF = this.state.s_editingAddedCaptureTypeQuestionRecordTF;

    const p_stageObj = this.props.p_stageObj;
    const p_questionObj = this.props.p_questionObj;
    const p_sortNumber = this.props.p_sortNumber;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const k_blueItemCollectionShellItemStylingObj = this.props.AdminMobx.k_blueItemCollectionShellItemStylingObj;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    const questionItemTitle = p_questionObj.name + "\n[Click to edit Progress/PWin weighting and requirements for this Question]";

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="captureTypeShapingQuestion"
        p_itemID={p_questionObj.id}
        p_itemSort={p_sortNumber}
        p_itemSortColumnWidth="2em"
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass={k_blueItemCollectionShellItemStylingObj.outerPadClass}
        p_itemClass="displayFlexRow border bevelBorderColors borderRadius05 bgAlmostWhite hoverLighterBlueGradient"
        p_itemStyleObj={{height:k_blueItemCollectionShellItemStylingObj.rowHeightEm + "em"}}
        p_lastItemExtraDropZoneHeight={k_blueItemCollectionShellItemStylingObj.lastItemExtraDropZoneHeightEm + "em"}
        p_tblName="tbl_a_capture_types_shaping_questions"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={["capture_type_id", "stage_id_relevant"]}
        p_filterValuesArray={[p_questionObj.capture_type_id, p_questionObj.stage_id_relevant]}>
        <div
          className="flex11a displayFlexRow cursorPointer"
          title={questionItemTitle}
          onClick={this.onclick_capture_type_stage_question_item_to_edit}>
          <div className="flex11a displayFlexRowVc lrPad">
            <LibraryReact.Nowrap>
              {p_questionObj.name}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:"7em"}}>
            <font className="fontItalic fontTextLighter">{"Progress: "}</font>{p_questionObj.progress_weight}
          </div>
          <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:"5em"}}>
            <font className="fontItalic fontTextLighter">{"PWin: "}</font>{p_questionObj.pwin_weight}
          </div>
          <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:"7em"}}>
            <font className="fontItalic fontTextLighter">{"Required?: "}</font>{((p_questionObj.requiredTF) ? ("Y") : ("-"))}
          </div>
        </div>
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove Question '" + p_questionObj.name + "' from stage '" + p_stageObj.name + "' for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
            f_onClick={this.onclick_remove_question_from_capture_type_stage}
          />
        </div>
        {(s_editingAddedCaptureTypeQuestionRecordTF) &&
          <AddedCaptureTypeQuestionEditFloatingBox
            p_selectedCaptureTypeObj={c_selectedCaptureTypeObj}
            p_stageObj={p_stageObj}
            p_questionObj={p_questionObj}
            f_onClickClose={this.onclick_close_capture_type_stage_question_item_to_edit}
          />
        }
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));


const AddedCaptureTypeQuestionEditFloatingBox = inject("DatabaseMobx")(observer(
class AddedCaptureTypeQuestionEditFloatingBox extends Component { //props: p_selectedCaptureTypeObj, p_stageObj, p_questionObj, f_onClickClose
  onsave_progress_weight = (i_newValue) => {
    this.update_capture_type_question_record_field_value("progress_weight", i_newValue, "i");
  }

  onsave_pwin_weight = (i_newValue) => {
    this.update_capture_type_question_record_field_value("pwin_weight", i_newValue, "i");
  }

  onswitch_required = () => {
    const p_questionObj = this.props.p_questionObj;
    const newRec1Req3 = ((p_questionObj.rec1_reqsig2_req3 === 3) ? (1) : (3));
    this.update_capture_type_question_record_field_value("rec1_reqsig2_req3", newRec1Req3, "i");
  }

  update_capture_type_question_record_field_value = (i_fieldName, i_value, i_idsb) => {
    const p_questionObj = this.props.p_questionObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AddedCaptureTypeQuestionEditFloatingBox", "update_capture_type_question_record_field_value", ["i_fieldName", "i_value", "i_idsb"], [i_fieldName, i_value, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types_shaping_questions", p_questionObj.id, i_fieldName, i_value, i_idsb);
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_selectedCaptureTypeObj = this.props.p_selectedCaptureTypeObj;
    const p_stageObj = this.props.p_stageObj;
    const p_questionObj = this.props.p_questionObj;

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditItem"
        p_title="Editing Added Question Weights and Requirements"
        f_onClickSave={this.props.f_onClickClose}>
        <div className="flex11a yScroll medFullPad">
          <div className="">
            <font className="fontItalic fontTextLighter">{"Capture Type: "}</font>
            <font className="fontTextLight">{p_selectedCaptureTypeObj.name}</font>
          </div>
          <div className="smallTopMargin">
            <font className="fontItalic fontTextLighter">{"Stage where this question appears: "}</font>
            <font className="fontTextLight">{p_stageObj.name}</font>
          </div>
          <div className="lrMedMargin smallTopMargin medBottomMargin smallFullPad" style={{border:"solid 1px #ccc"}}>
            <div className="">
              <font className="font11 fontItalic fontTextLighter">
                {"Question:"}
              </font>
            </div>
            <div className="smallTopMargin">
              <font className="fontTextLight">
                {p_questionObj.name}
              </font>
            </div>
          </div>
          <div className="hugeTopMargin">
            <font className="fontItalic fontTextLighter">
              {"Question Weighting: A number from 0-9. 1 (least important) to 9 (most important) weights the questions among each other in how much their answers affect the Progress/PWin. 0 means the question will not affect the Progress/PWin."}
            </font>
          </div>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"adminDSQ_qID" + p_questionObj.id + "progress_weight"}
            p_fieldDisplayName="Question Weighting towards Total Progress (0-9)"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericInt0to9FieldTypeObj}
            p_valueRaw={p_questionObj.progress_weight}
            p_valueIsEditableTFU={true}
            p_containerClass="smallTopMargin"
            p_fieldClass="fontBlue"
            p_fieldWidth="50%"
            f_onSaveChanged={this.onsave_progress_weight}
          />
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"adminDSQ_qID" + p_questionObj.id + "pwin_weight"}
            p_fieldDisplayName="Question Weighting towards Calculated PWin (0-9)"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericInt0to9FieldTypeObj}
            p_valueRaw={p_questionObj.pwin_weight}
            p_valueIsEditableTFU={true}
            p_containerClass="smallTopMargin"
            p_fieldClass="fontBlue"
            p_fieldWidth="50%"
            f_onSaveChanged={this.onsave_pwin_weight}
          />
          <div className="displayFlexRowVc hugeTopMargin">
            <div className="flex11a" style={{flexBasis:"100em"}}>
              <font className="fontBlue">
                {"Question is Required?"}
              </font>
            </div>
            <div className="flex11a" style={{flexBasis:"100em"}}>
              <LibraryReact.Switch
                p_isOnTF={(p_questionObj.rec1_reqsig2_req3 === 3)}
                f_onClick={this.onswitch_required}
              />
            </div>
          </div>
          <div className="">
            <font className="font09 fontItalic fontTextLighter">
              {"(Required means the question must be answered before the stage can advance)"}
            </font>
          </div>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


export const ClosedStageOfAddedQuestions = inject("AdminMobx")(observer(
class ClosedStageOfAddedQuestions extends Component { //props: p_closedStageObj, p_closedQuestionIDsAlreadyAddedArray
  onclick_set_stage_as_receiving_questions = () => {
    const p_closedStageObj = this.props.p_closedStageObj;
    this.props.AdminMobx.a_set_receiving_item_collection_id(p_closedStageObj.stageObj.id);
  }

  ondrop_question_from_pool_into_closed_stage_drop_zone = (i_draggedQuestionID) => {
    const p_closedStageObj = this.props.p_closedStageObj;
    const p_closedQuestionIDsAlreadyAddedArray = this.props.p_closedQuestionIDsAlreadyAddedArray;
    if(!JSFUNC.in_array(i_draggedQuestionID, p_closedQuestionIDsAlreadyAddedArray)) {
      this.props.AdminMobx.a_add_pool_question_to_receiving_stage(i_draggedQuestionID, p_closedStageObj.stageObj.id);
    }
  }

  onremove_all_shaping_questions_in_closed_stage_from_capture_type = () => {
    const p_closedStageObj = this.props.p_closedStageObj;

    const allCaptureTypeShapingQuestionsRowIDsInClosedStageArray = JSFUNC.get_column_vector_from_arrayOfObjs(p_closedStageObj.questionsArrayOfObjs, "id");
    const jsDescription = JSFUNC.js_description_from_action("AdminMobx - ClosedStageOfAddedQuestions", "onremove_all_shaping_questions_in_closed_stage_from_capture_type", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_capture_types_debrief_questions", allCaptureTypeShapingQuestionsRowIDsInClosedStageArray);
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_closedStageObj = this.props.p_closedStageObj;
    const p_closedQuestionIDsAlreadyAddedArray = this.props.p_closedQuestionIDsAlreadyAddedArray;

    const o_questionsSelectedReceivingStageID = this.props.AdminMobx.o_questionsSelectedReceivingStageID;

    const stageObj = p_closedStageObj.stageObj;
    const questionsArrayOfObjs = p_closedStageObj.questionsArrayOfObjs;
    const numQuestions = questionsArrayOfObjs.length;

    return (
      <BlueItemCollectionShell
        p_isReceivingTF={(stageObj.id === o_questionsSelectedReceivingStageID)}
        p_title={"Stage: '" + stageObj.name + "'"}
        p_dropUniqueString="AddEditDeleteTableRow_tbl_a_shaping_questions_pool"
        p_dropNotAllowedDraggerIDsArray={p_closedQuestionIDsAlreadyAddedArray}
        p_dropMessage="[Drag/drop questions from the pool]"
        p_dropAllowDropMessage={"[Drop question to add to stage '" + stageObj.name + "']"}
        p_dropNotAllowDropMessage="[Cannot add a question already added to one of the active stages]"
        f_onClickSetAsReceiving={this.onclick_set_stage_as_receiving_questions}
        f_onDrop={this.ondrop_question_from_pool_into_closed_stage_drop_zone}
        f_onRemoveAllItems={this.onremove_all_shaping_questions_in_closed_stage_from_capture_type}>
        {questionsArrayOfObjs.map((m_questionObj, m_index) =>
          <ClosedStageAddedDealShapingQuestionItem
            key={m_questionObj.id}
            p_stageObj={stageObj}
            p_questionObj={m_questionObj}
            p_sortNumber={(m_index + 1)}
            p_isOnlyItemTF={(numQuestions === 1)}
            p_isLastItemTF={(m_index === (numQuestions - 1))}
          />
        )}
      </BlueItemCollectionShell>
    );
  }
}));


export const ClosedStageAddedDealShapingQuestionItem = inject("AdminMobx", "DatabaseMobx")(observer(
class ClosedStageAddedDealShapingQuestionItem extends Component { //props: p_stageObj, p_questionObj, p_sortNumber, p_isOnlyItemTF, p_isLastItemTF
  onclick_remove_question_from_capture_type_stage = () => {
    const p_questionObj = this.props.p_questionObj;
    this.props.AdminMobx.a_remove_question_from_capture_type_stage(false, p_questionObj.id, p_questionObj.capture_type_id, p_questionObj.closed_stage_id);
  }

  render() {
    const p_stageObj = this.props.p_stageObj;
    const p_questionObj = this.props.p_questionObj;
    const p_sortNumber = this.props.p_sortNumber;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const k_blueItemCollectionShellItemStylingObj = this.props.AdminMobx.k_blueItemCollectionShellItemStylingObj;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    const questionItemTitle = p_questionObj.name + "\n[Closed Stage Questions do not have any additional parameters to edit]";

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="captureTypeDebriefQuestion"
        p_itemID={p_questionObj.id}
        p_itemSort={p_sortNumber}
        p_itemSortColumnWidth="2em"
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass={k_blueItemCollectionShellItemStylingObj.outerPadClass}
        p_itemClass="displayFlexRow border bevelBorderColors borderRadius05 bgAlmostWhite hoverLighterBlueGradient"
        p_itemStyleObj={{height:k_blueItemCollectionShellItemStylingObj.rowHeightEm + "em"}}
        p_lastItemExtraDropZoneHeight={k_blueItemCollectionShellItemStylingObj.lastItemExtraDropZoneHeightEm + "em"}
        p_tblName="tbl_a_capture_types_debrief_questions"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={["capture_type_id", "closed_stage_id"]}
        p_filterValuesArray={[p_questionObj.capture_type_id, p_questionObj.closed_stage_id]}>
        <div
          className="flex11a displayFlexRowVc lrPad"
          title={questionItemTitle}>
          <LibraryReact.Nowrap>
            {p_questionObj.name}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove Question '" + p_questionObj.name + "' from stage '" + p_stageObj.name + "' for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
            f_onClick={this.onclick_remove_question_from_capture_type_stage}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));





const AddEditDeleteTableAddNewDealShapingQuestionFloatingBox = inject("DatabaseMobx")(observer(
class AddEditDeleteTableAddNewDealShapingQuestionFloatingBox extends Component { //props: p_dataArrayOfObjs, p_aedTableObj, f_onClickClose
  constructor(props) {
    super(props);

    this.state = {
      s_processStep: "answerType", //"answerType", "questionFields", "createAnswers", "createAnswersSearchQuestionsToCopyAnswers"
      s_answerType123: undefined,
      s_questionName: "",
      s_tagIDsComma: "",
      s_hoverText: "",
      s_stylingStringCommaList: "",
      s_questionNameErrorMessage: undefined,
      s_phpIsCreatingQuestionTF: false,
      s_newlyCreatedQuestionID: -1
    }
  }

  onclick_select_answer_type = (i_selectedAnswerType123) => {
    this.setState({s_answerType123:i_selectedAnswerType123});
  }

  onclick_proceed_to_define_question = () => {
    this.setState({s_processStep:"questionFields"});
  }

  onclick_return_to_select_answer_type = () => {
    this.setState({
      s_processStep: "answerType",
      s_answerType123: undefined
    });
  }

  onchange_question_name = (i_newValue) => {
    this.setState({
      s_questionName: i_newValue,
      s_questionNameErrorMessage: undefined
    });
  }

  onchange_tag_ids_comma = (i_newValue) => {
    this.setState({s_tagIDsComma:i_newValue});
  }

  onchange_hover_text = (i_newValue) => {
    this.setState({s_hoverText:i_newValue});
  }

  onchange_styling_string_comma_list = (i_newValue) => {
    this.setState({s_stylingStringCommaList:i_newValue});
  }

  onclick_create_question_or_proceed_to_add_answers = () => {
    if(this.state.s_questionName === "") {
      this.setState({s_questionNameErrorMessage: "The question text cannot be blank"});
    }
    else {
      this.setState({s_phpIsCreatingQuestionTF:true});

      const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - AddEditDeleteTableAddNewDealShapingQuestionFloatingBox", "onclick_create_text_question", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const fieldNamesArray = ["name", "answer_select1_text2_textarea3", "tag_ids_comma", "admin_sort", "hover_text", "question_name_styling_string_comma"];
      const valuesArray = [this.state.s_questionName, this.state.s_answerType123, this.state.s_tagIDsComma, JSFUNC.sort_max_mysqli_int(), this.state.s_hoverText, this.state.s_stylingStringCommaList];
      const idsbArray = ["s", "i", "s", "i", "s", "s"];
      const resortSortColumnName = "admin_sort";
      const resortFilterFieldNameOrFieldNamesArray = [];
      const resortFilterValueOrValuesArray = [];
      C_CallPhpTblUID.add_insert("tbl_a_shaping_questions_pool", fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);

      const functionOnSuccess = (i_parseResponse) => {
        this.setState({s_newlyCreatedQuestionID:i_parseResponse.outputObj.i0});
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      const functionOnFinish = () => {
        this.setState({s_phpIsCreatingQuestionTF:false});

        if(this.state.s_answerType123 === 1) { //select question, proceed to add answers step after creating question in background
          this.setState({s_processStep:"createAnswers"});
        }
        else {
          if(this.props.f_onClickClose) {
            this.props.f_onClickClose();
          }
        }
      }
      C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

      C_CallPhpTblUID.execute();
    }
  }

  onclick_copy_answers_from_an_existing_question_button = () => {
    this.setState({s_processStep:"createAnswersSearchQuestionsToCopyAnswers"});
  }

  onclose_copy_answers_from_existing_question = () => {
    this.setState({s_processStep:"createAnswers"});
  }

  render() {
    const s_processStep = this.state.s_processStep;
    const s_answerType123 = this.state.s_answerType123;
    const s_questionName = this.state.s_questionName;
    const s_tagIDsComma = this.state.s_tagIDsComma;
    const s_hoverText = this.state.s_hoverText;
    const s_stylingStringCommaList = this.state.s_stylingStringCommaList;
    const s_questionNameErrorMessage = this.state.s_questionNameErrorMessage;
    const s_phpIsCreatingQuestionTF = this.state.s_phpIsCreatingQuestionTF;
    const s_newlyCreatedQuestionID = this.state.s_newlyCreatedQuestionID;

    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;

    const c_cardNameDealShaping = this.props.DatabaseMobx.c_cardNameDealShaping;

    var processInstructions = undefined;
    var processComponent = null;
    if(s_processStep === "answerType") {
      processInstructions = "First, choose the answer type for this new Question";
      processComponent = (
        <div className="flex11a displayFlexColumn">
          <div className="flex11a yScroll bgLighterGray">
            {[1,3].map((m_answerType123) =>
              <NewQuestionAnswerTypeChoice
                p_answerType123={m_answerType123}
                p_selectedAnswerType123={s_answerType123}
                f_onClick={this.onclick_select_answer_type}
              />
            )}
          </div>
          <div className="flex00a displayFlexRowHcVc tbPad borderT1ddd">
            {(s_answerType123 !== undefined) &&
              <div className="lrMedPad">
                <CEGeneralReact.CEButton p_type="blue" p_text="Proceed to Next Step Defining the Question" f_onClick={this.onclick_proceed_to_define_question} />
              </div>
            }
            <div className="lrMedPad">
              <CEGeneralReact.CEButton p_type="gray" p_text="Cancel" f_onClick={this.props.f_onClickClose} />
            </div>
          </div>
        </div>
      );
    }
    else if(s_processStep === "questionFields") {
      var bottomButtonComponent = null;
      if(s_phpIsCreatingQuestionTF) {
        bottomButtonComponent = (
          <CEGeneralReact.PhpWorkingMessage p_text="Creating Question..." />
        );
      }
      else {
        if(s_answerType123 === 1) {
          bottomButtonComponent = (<CEGeneralReact.CEButton p_type="blue" p_text="Proceed to Next Step Creating Answers" f_onClick={this.onclick_create_question_or_proceed_to_add_answers} />);
        }
        else if(s_answerType123 === 2 || s_answerType123 === 3) {
          bottomButtonComponent = (<CEGeneralReact.CEButton p_type="blue" p_text="Create This Question" f_onClick={this.onclick_create_question_or_proceed_to_add_answers} />);
        }
        else {
          bottomButtonComponent = (<CEGeneralReact.CEButton p_type="gray" p_text="Cancel" f_onClick={this.props.f_onClickClose} />);
        }
      }

      processInstructions = "Next, fill out the question, and add any optional tags or help text";
      processComponent = (
        <div className="flex11a displayFlexColumn">
          <div className="flex11a yScroll yScrollBottomPad">
            <div className="smallFullPad borderB1ddd">
              <font className="font12 fontItalic">{"Answer Type for this Question: "}</font>
              <font className="font14 fontBold fontTextLighter">{JSFUNC.get_first_array2_value_and_found_match_tf_where_array1_matches_input(s_answerType123, [1,2,3], ["Scored Select", "Scored Text", "Scored Textarea"], "--Answer Type Does Not Exist (ID: " + s_answerType123 + ")--")}</font>
            </div>
            <div className="medFullPad">
              <div className="smallTopMargin">
                <font className="font12">
                  {"Question Text"}
                </font>
              </div>
              <div className="smallTopMargin">
                <LibraryReact.Textarea
                  p_value={s_questionName}
                  p_class=""
                  p_styleObj={{height:"8em"}}
                  p_tabIndex={1}
                  p_placeholder="Enter the question..."
                  p_errorTF={(s_questionNameErrorMessage !== undefined)}
                  f_onChange={this.onchange_question_name}
                />
              </div>
              {(s_questionNameErrorMessage !== undefined) &&
                <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={s_questionNameErrorMessage} />
              }
              <div className="displayFlexRow hugeTopMargin">
                <div className="flex11a rMargin" style={{flexBasis:"100em"}}>
                  <div className="">
                    <font className="font12">
                      {"Question Tag(s) (optional)"}
                    </font>
                  </div>
                  <div className="microTopMargin">
                    <font className="fontItalic fontTextLighter">
                      {"Tags can be applied to questions for users to easily filter their " + c_cardNameDealShaping + " card to view questions that match a selected tag. Tags can help group together questions about a certain topic that become relevant across different stages. Example tag ideas might include 'Customer Relationship', 'Pricing', 'Strategy', 'Technical', etc"}
                    </font>
                  </div>
                </div>
                <div className="flex11a lrMargin" style={{flexBasis:"150em"}}>
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString="aedAddNewDSQFloatingBoxSelectMultiAddTags"
                    p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiAddDealShapingQuestionTagsFieldTypeObj}
                    p_valueRaw={s_tagIDsComma}
                    p_valueIsEditableTFU={true}
                    f_onSaveChanged={this.onchange_tag_ids_comma}
                  />
                </div>
              </div>
              <div className="hugeTopMargin">
                <font className="font12">
                  {"Question Hover Help Text (optional) "}
                </font>
              </div>
              <div className="microTopMargin">
                <font className="fontItalic fontTextLighter">
                  {"This text will show if the user hovers their mouse over the wording of the question"}
                </font>
              </div>
              <div className="smallTopMargin hugeBottomMargin">
                <LibraryReact.Textarea
                  p_value={s_hoverText}
                  p_styleObj={{height:"11em"}}
                  p_tabIndex={3}
                  p_placeholder="Enter the hover help text..."
                  f_onChange={this.onchange_hover_text}
                />
              </div>
            </div>
          </div>
          <div className="flex00a displayFlexRow tbPad borderT1ddd">
            <div className="flex00a displayFlexColumnHcVc lrMedPad borderR1ddd">
              <CEGeneralReact.CEButton p_type="add" p_text="Go Back" f_onClick={this.onclick_return_to_select_answer_type} />
            </div>
            <div className="flex11a displayFlexColumnHcVc">
              {bottomButtonComponent}
            </div>
          </div>
        </div>
      );
    }
    else if((s_processStep === "createAnswers") || (s_processStep === "createAnswersSearchQuestionsToCopyAnswers")) {
      const selectQuestionAnswersMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(this.props.DatabaseMobx.o_tbl_a_shaping_select_answers, "question_id", s_newlyCreatedQuestionID);
      const selectQuestionAnswersArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(selectQuestionAnswersMapOfMaps, "score0to100", false);

      const selectQuestionAnswersAedTableObj = {
        tblName: "tbl_a_shaping_select_answers",
        initialSortedFieldDbName: "score0to100",
        initialSortedFieldIsAscTF: false,
        itemName: "Answer",
        newItemConstantFieldNamesArray: ["question_id"],
        newItemConstantValuesArray: [s_newlyCreatedQuestionID],
        newItemConstantIdsbArray: ["i"],
        fieldsArrayOfObjs: [
          {width:"250em", dbName:"name", displayName:"Answer", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, onkeydownenterAddsNewItemTF:true},
          {width:"100em", dbName:"score0to100", displayName:"Score (0-100%, or negative down to -1000%)", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentIntm1000to100FieldTypeObj, initialValue:100, cellCenterTF:true, onkeydownenterAddsNewItemTF:true}
        ]
      };

      processInstructions = "Finally, create (or copy) the Answers that will appear in the list of choices for this new Question";
      processComponent = (
        <div className="flex11a displayFlexColumn">
          <div className="flex11a yScroll medFullPad">
            <div className="medBottomMargin">
              <font className="font11 fontBold fontTextLighter">{"Question: "}</font>
              <font className="font11 fontBlue">{s_questionName}</font>
            </div>
            <div className="hugeBottomPad borderB1ddd">
              <AddEditDeleteTable
                p_dataArrayOfObjs={selectQuestionAnswersArrayOfObjs}
                p_aedTableObj={selectQuestionAnswersAedTableObj}
              />
            </div>
            <div className="hugeTopMargin">
              {(s_processStep === "createAnswers") ? (
                <div className="displayFlexColumnHcVc">
                  <CEGeneralReact.CEButton
                    p_type="gray"
                    p_text="Copy Answers from an Existing Question"
                    f_onClick={this.onclick_copy_answers_from_an_existing_question_button}
                  />
                </div>
              ) : (
                <NewSelectQuestionCopyAnswersFromExistingQuestion
                  p_newlyCreatedQuestionID={s_newlyCreatedQuestionID}
                  f_onCloseCopyAnswersFromExistingQuestion={this.onclose_copy_answers_from_existing_question}
                />
              )}
            </div>
          </div>
          <div className="flex00a displayFlexColumnHcVc tbPad borderT1ddd">
            {(s_processStep === "createAnswers") &&
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text="Complete Process and Close"
                f_onClick={this.props.f_onClickClose}
              />
            }
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditQuestion"
        p_title={"Adding a New " + p_aedTableObj.itemName}
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex11a displayFlexColumn">
          <NewCaptureFieldOrDealShapingQuestionInstructionsBar p_label={processInstructions} />
          {processComponent}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


class NewQuestionAnswerTypeChoice extends Component { //props: p_answerType123, p_selectedAnswerType123, f_onClick
  onclick_answer_type_choice = () => {
    if(this.props.f_onClick) {
      const newSelectedAnswerType123 = ((this.props.p_answerType123 === this.props.p_selectedAnswerType123) ? (undefined) : (this.props.p_answerType123));
      this.props.f_onClick(newSelectedAnswerType123);
    }
  }

  render() {
    const answerType123 = this.props.p_answerType123;
    const selectedAnswerType123 = this.props.p_selectedAnswerType123;

    const typeIsSelectedTF = (answerType123 === selectedAnswerType123);

    var typeName = "";
    var typeDescriptionArray = [];
    var typeExamplesArray = [];
    if(answerType123 === 1) {
      typeName = "Scored Select";
      typeDescriptionArray = [
        "Creates a 'Select' (pull-down list) of admin defined answers",
        "Each individual answer is assigned a score from 0-100%, which contributes to the overall Shaping Progress score",
        "Answers can also be given negative scores from -1000% to 0% for answers that are very detrimental to winning a Capture",
        "Users can select 1 answer from the list (or clear the selection and leave it 'unanswered')"
      ];
      typeExamplesArray = [
        "'Does the customer express the business need?'  (Answers: 'Yes, the customer wants this solution' (100%), 'No, the customer is only interested' (-400%))",
        "'What type of competition is this?'  (Answers: 'Incumbent' (100%), 'One of few' (70%), 'One of many' (20%))",
        "'Rate our capability to deliver the technical solution 0-10'  (Answers: '10' (100%), '9' (90%), '8' (80%), '7' (70%), '6' (60%), '5' (50%), '4' (40%), '3' (30%), '2' (20%), '1' (10%), '0' (0%))"
      ];
    }
    else if(answerType123 === 2) {
      typeName = "Scored Text";
      typeDescriptionArray = [
        "For the 'Text' type, answering the question opens a single line text input for the user to enter their self typed answer to the question.",
        "There is no limit to the amount of text that can be entered, but it can be difficult to see what you have typed if the answer is longer than a few words.",
        "To the left of this input is a slider where the user can give their answer a self evaluated 'completeness score' from 0-100% for the answer they just typed."
      ];
      typeExamplesArray = [

      ];
    }
    else if(answerType123 === 3) {
      typeName = "Scored Textarea";
      typeDescriptionArray = [
        "Provides a large area for text input, can store approx 500,000 words",
        "User can enter a self evaluated rating score for their given answer"
      ];
      typeExamplesArray = [
        "'What is our marketing approach with the customer?'",
        "'Explain the customer business case.'",
        "'List the customer's top 5 priorities.'"
      ];
    }

    return(
      <div
        className={"medFullMargin medFullPad border bevelBorderColors " + ((typeIsSelectedTF) ? ("ticketResponseFromBITUnread") : ("bgAlmostWhite hoverLighterBlueGradient cursorPointer"))}
        onClick={this.onclick_answer_type_choice}>
        <div className="displayFlexRow">
          <div className="flex11a" style={{flexBasis:"100em"}}>
            <div className="smallBottomMargin">
              <font className="font15 fontBold fontTextLighter">
                {typeName}
              </font>
            </div>
          </div>
          <div className="flex11a" style={{flexBasis:"200em"}}>
            <CEGeneralReact.BulletList p_linesArray={typeDescriptionArray} p_fontClass="fontItalic fontTextLighter" p_dashFontClass="fontTextLighter" />
          </div>
        </div>
        {(typeExamplesArray.length > 0) &&
          <>
            <div className="smallTopMargin microBottomMargin">
              <font className="fontBold fontItalic fontBlue">
                {"Examples"}
              </font>
            </div>
            <CEGeneralReact.BulletList p_linesArray={typeExamplesArray} p_fontClass="fontBlue" />
          </>
        }
      </div>
    );
  }
}



const NewSelectQuestionCopyAnswersFromExistingQuestion = inject("AdminMobx")(observer(
class NewSelectQuestionCopyAnswersFromExistingQuestion extends Component { //props: p_newlyCreatedQuestionID, f_onCloseCopyAnswersFromExistingQuestion
  constructor(props) {
    super(props);

    this.state = {
      s_viewingQuestionID: undefined,
      s_viewingQuestionAnswersArrayOfObjs: undefined,
      s_isCopyingTF: false
    }
  }

  onclick_cancel_search_questions = () => {
    this.setState({
      s_viewingQuestionID: undefined,
      s_viewingQuestionAnswersArrayOfObjs: undefined,
      s_isCopyingTF: false
    });

    if(JSFUNC.is_function(this.props.f_onCloseCopyAnswersFromExistingQuestion)) {
      this.props.f_onCloseCopyAnswersFromExistingQuestion();
    }
  }

  onclick_question = (i_selectedQuestionObj) => {
    this.setState({
      s_viewingQuestionID: i_selectedQuestionObj.id,
      s_viewingQuestionAnswersArrayOfObjs: i_selectedQuestionObj.answersArrayOfObjs
    });
  }

  onclick_copy_answers_to_new_question = () => {
    const jsDescription = JSFUNC.js_description_from_action("AdminReact - NewSelectQuestionCopyAnswersFromExistingQuestion", "onclick_copy_answers_to_new_question", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var allNewAnswerNamesArray = [];
    var allNewAnswerScoresArray = [];
    for(let answerObj of this.state.s_viewingQuestionAnswersArrayOfObjs) {
      allNewAnswerNamesArray.push(answerObj.name);
      allNewAnswerScoresArray.push(answerObj.score0to100);
    }
    const fieldNamesArray = ["question_id", "name", "score0to100"];
    const valuesArrayOfArrays = [this.props.p_newlyCreatedQuestionID, allNewAnswerNamesArray, allNewAnswerScoresArray];
    const valueIdsbArray = ["i", "s", "i"];
    C_CallPhpTblUID.add_multi_insert("tbl_a_shaping_select_answers", fieldNamesArray, valuesArrayOfArrays, valueIdsbArray);

    const functionOnFinish = () => {
      this.onclick_cancel_search_questions();
    }
    C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

    C_CallPhpTblUID.execute();

    this.setState({s_isCopyingTF:true});
  }

  render() {
    const viewingQuestionID = this.state.s_viewingQuestionID;
    const viewingQuestionAnswersArrayOfObjs = this.state.s_viewingQuestionAnswersArrayOfObjs;
    const isCopyingTF = this.state.s_isCopyingTF;

    const c_questionsPoolArrayOfObjs = this.props.AdminMobx.c_questionsPoolArrayOfObjs;

    return(
      <div className="">
        <div className="displayFlexColumnHcVc">
          <CEGeneralReact.CEButton
            p_type="gray"
            p_text="Cancel Copying Answers"
            f_onClick={this.onclick_cancel_search_questions}
          />
        </div>
        <div className="displayFlexRow medTopMargin" style={{height:"40em", border:"solid 1px #ccc"}}>
          <div className="flex11a displayFlexColumn borderR1ddd" style={{flexBasis:"100em"}}>
            <div className="flex00a smallFullPad bgDarkGrayGradient" style={{borderBottom:"solid 1px #666"}}>
              <font className="font12 fontBold fontItalic fontWhite">
                {"Existing Select Questions (and # Answers)"}
              </font>
            </div>
            <div className="flex11a yScroll yScrollBottomPad bgLightestGray">
              {c_questionsPoolArrayOfObjs.map((m_questionObj) =>
                (m_questionObj.answer_select1_text2_textarea3 === 1) &&
                <NewSelectQuestionCopyAnswersSingleQuestion
                  key={m_questionObj.id}
                  p_questionObj={m_questionObj}
                  f_onClick={this.onclick_question}
                />
              )}
            </div>
          </div>
          <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
            <div className="flex00a smallFullPad bgDarkGrayGradient" style={{borderBottom:"solid 1px #666"}}>
              <font className="font12 fontBold fontItalic fontWhite">
                {"Answers to Selected Question (with % Score)"}
              </font>
            </div>
            {(viewingQuestionID === undefined) ? (
              <CEGeneralReact.EmptyScreenGray>
                {"Select a Question to view its Answers"}
              </CEGeneralReact.EmptyScreenGray>
            ) : (
              <div className="flex11a yScroll yScrollBottomPad bgLightestGray">
                <div className="displayFlexColumnHcVc tbMargin">
                  {(isCopyingTF) ? (
                    <font className="fontItalic fontTextLight">
                      {"Copying Answers..."}
                    </font>
                  ) : (
                    <CEGeneralReact.CEButton p_type="blue" p_text="Copy these Answers to New Question" f_onClick={this.onclick_copy_answers_to_new_question} />
                  )}
                </div>
                {viewingQuestionAnswersArrayOfObjs.map((m_answerObj) =>
                  <div key={"q" + viewingQuestionID + "a" + m_answerObj.id} className="displayFlexRow smallFullPad border bevelBorderColors bgAlmostWhite">
                    <div className="flex11a rMargin">
                      <font className="">
                        {m_answerObj.name}
                      </font>
                    </div>
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"4em"}}>
                      <font className="fontItalic">
                        {m_answerObj.score0to100 + "%"}
                      </font>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}));

class NewSelectQuestionCopyAnswersSingleQuestion extends Component {
  onclick_question = () => {
    if(this.props.f_onClick) {
      this.props.f_onClick(this.props.p_questionObj);
    }
  }

  render() {
    const questionObj = this.props.p_questionObj;
    return(
      <div className="displayFlexRow smallFullPad border bevelBorderColors bgAlmostWhite hoverLightBlueGradient cursorPointer" onClick={this.onclick_question}>
        <div className="flex11a rMargin">
          <font className="fontBlue">
            {questionObj.name}
          </font>
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"4em"}}>
          <font className="font11 fontItalic">
            {questionObj.numAnswers}
          </font>
        </div>
      </div>
    );
  }
}





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabTeammates = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabTeammates extends Component {
  render() {
    const c_teammatesWorkshareTypesArrayOfObjs = this.props.AdminMobx.c_teammatesWorkshareTypesArrayOfObjs;
    const c_teammatesWorkshareTypeFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareTypeFieldDisplayName;
    const c_genericTextFieldTypeObj = this.props.DatabaseMobx.c_genericTextFieldTypeObj;
    const c_genericIntPositiveFieldTypeObj = this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj;

    const workshareTypesAedTableObj = {
      tblName: "tbl_a_teammates_workshare_types",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: "40em",
      itemName: "Teammate " + c_teammatesWorkshareTypeFieldDisplayName,
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"name", displayName:c_teammatesWorkshareTypeFieldDisplayName, fieldTypeObj:c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    return (
      <div className="flex11a yScroll">
        <AEDTableForExtraFieldColumnManipulation p_tblName="tbl_a_teammates_extra_fields" />
        <div className="lrMedMargin hugeTopMargin hugeBottomMargin borderB1bbb" />
        <div className="medFullPad">
          <AddEditDeleteTable
            p_dataArrayOfObjs={c_teammatesWorkshareTypesArrayOfObjs}
            p_aedTableObj={workshareTypesAedTableObj}
          />
        </div>
      </div>
    );
  }
}));




//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabTeammateContracts = inject("AdminMobx")(observer(
class AdminSubtabTeammateContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectedTab: "typesAndTemplates"
    }
  }

  onclick_admin_teammate_contract_tab = (i_newValue) => {
    this.setState({s_selectedTab:i_newValue});
  }

  render() {
    const selectedTab = this.state.s_selectedTab;

    var teammateContractsPageComponent = null;
    if(selectedTab === "typesAndTemplates") {
      teammateContractsPageComponent = (<TeammateContractTypesAndTemplates />);
    }
    else if(selectedTab === "processStatuses") {
      teammateContractsPageComponent = (<TeammateContractProcessStatuses />);
    }
    else if(selectedTab === "agreementTypes") {
      teammateContractsPageComponent = (<TeammateContractAgreementTypes />);
    }

    return (
      <>
        <div className="flex00a borderB1bbb smallFullPad">
          <CEGeneralReact.TabsList
            p_tabDbNamesArray={["typesAndTemplates", "processStatuses", "agreementTypes"]}
            p_tabDisplayNamesArray={["Contract Types (NDA/TA/SubK) and Templates", "Contract Process Statuses", "Edit Agreement Types"]}
            p_selectedTabDbName={selectedTab}
            p_tabHeight="3.2em"
            p_textMaxHeight="3em"
            p_tabWidth="18em"
            p_unselectedFontClass="font11 fontBold fontTextLight"
            p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
            p_selectedBgClass="bgLightRedGradient"
            f_onSelect={this.onclick_admin_teammate_contract_tab}
          />
        </div>
        <div className="flex11a displayFlexColumn yScroll">
          {teammateContractsPageComponent}
        </div>
      </>
    );
  }
}));


const TeammateContractTypesAndTemplates = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx", "ContactsMobx")(observer(
class TeammateContractTypesAndTemplates extends Component {
  constructor(props) {
    super(props);

    var initialSelectedFileFolderSystemContactTypeID = -1;
    if(this.props.DatabaseMobx.c_teammatesContractTypesArrayOfObjs.length > 0) {
      initialSelectedFileFolderSystemContactTypeID = this.props.DatabaseMobx.c_teammatesContractTypesArrayOfObjs[0].id;
    }

    this.state = {
      s_selectedFileFolderSystemContractTypeID: initialSelectedFileFolderSystemContactTypeID,
      s_loadingAllContractsState: "notLoaded", //"notLoaded", "loading", "loaded"
      s_selectedCaptureID: -1,
      s_selectedContractRecordID: -1,
      s_selectedTemplateID: -1,
      s_showErrorsTF: false
    }
  }

  onswitch_contracts_on_other_teammates_when_sub = (i_newValue01) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("teammate_contracts_on_other_teammates_when_sub_01", i_newValue01, "i");
  }

  onclick_select_teammate_contract_type = (i_selectedTeammateContractTypeID) => {
    this.setState({s_selectedFileFolderSystemContractTypeID:i_selectedTeammateContractTypeID});
  }

  onclick_load_all_contracts_from_database = () => {
    this.setState({s_loadingAllContractsState:"loading"});

    const functionOnSuccess = () => {
      this.setState({s_loadingAllContractsState:"loaded"});
    }

    const functionOnError = () => {
      this.setState({s_loadingAllContractsState:"notLoaded"});
    }

    this.props.AdminMobx.a_teammate_contracts_load_all_contracts_from_database(functionOnSuccess, functionOnError);
  }

  onselect_capture_teammate_combination = (i_captureIDCommaContractRecordID) => {
    const explodeArray = JSFUNC.convert_comma_list_to_int_array(i_captureIDCommaContractRecordID);
    if(JSFUNC.is_array(explodeArray) && (explodeArray.length === 2)) {
      this.setState({
        s_selectedCaptureID: explodeArray[0],
        s_selectedContractRecordID: explodeArray[1]
      });
    }
  }

  onselect_template = (i_templateID) => {
    this.setState({s_selectedTemplateID:i_templateID});
  }

  onclick_generate_button_set_show_errors = () => {
    this.setState({s_showErrorsTF:true});
  }

  render() {
    var selectedFileFolderSystemContractTypeID = this.state.s_selectedFileFolderSystemContractTypeID;
    const loadingAllContractsState = this.state.s_loadingAllContractsState;
    const selectedCaptureID = this.state.s_selectedCaptureID;
    const selectedContractRecordID = this.state.s_selectedContractRecordID;
    const selectedTemplateID = this.state.s_selectedTemplateID;
    const s_showErrorsTF = this.state.s_showErrorsTF;

    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_companyTeammateContractsOnOtherTeammatesWhenSub01 = this.props.DatabaseMobx.c_companyTeammateContractsOnOtherTeammatesWhenSub01;
    const teammatesContractTypesArrayOfObjs = this.props.DatabaseMobx.c_teammatesContractTypesArrayOfObjs;

    const captureValidTF = JSFUNC.select_int_is_filled_out_tf(selectedCaptureID);
    const contractRecordValidTF = JSFUNC.select_int_is_filled_out_tf(selectedContractRecordID);
    const templateValidTF = JSFUNC.select_int_is_filled_out_tf(selectedTemplateID);

    const teammateContractTypesAedTableObj = {
      tblName: "tbl_a_teammates_contract_types",
      dragToResortFieldDbName: "admin_sort",
      initialSortedFieldDbName: "admin_sort",
      tableWidth: "100%",
      itemName: "Teammate Contract",
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"300em", dbName:"name", displayName:"Teammate Contract Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"short_name", displayName:"Short Name / Acronym", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", requiredNotEmptyTF:true},
        {width:"3em", dbName:"admin_sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    //if the contract type selected for the FFS was just deleted, change the selected contract type to the first one remaining in the array of contract types
    if((teammatesContractTypesArrayOfObjs.length > 0) && !JSFUNC.in_array(selectedFileFolderSystemContractTypeID, JSFUNC.get_column_vector_from_arrayOfObjs(teammatesContractTypesArrayOfObjs, "id"))) {
      selectedFileFolderSystemContractTypeID = teammatesContractTypesArrayOfObjs[0].id;
    }

    //get the name of the red Contract Type currently selected at the top
    const selectedContractTypeMap = this.props.DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_types", selectedFileFolderSystemContractTypeID);
    const selectedContractTypeShortName = selectedContractTypeMap.get("short_name");

    //build the state logic for loading all capture teammate contracts into memory from the database, selecting one, and running it as a test
    var selectContractTestsComponent = null;
    if(loadingAllContractsState === "notLoaded") {
      selectContractTestsComponent = (
        <div className="displayFlexColumnHcVc tbPad">
          <CEGeneralReact.CEButton
            p_type="gray"
            p_text="Click to Load Template Testing Choices"
            f_onClick={this.onclick_load_all_contracts_from_database}
          />
        </div>
      );
    }
    else if(loadingAllContractsState === "loading") {
      selectContractTestsComponent = (
        <div className="displayFlexColumnHcVc tbMedPad">
          <CEGeneralReact.LoadingAnimation />
        </div>
      );
    }
    else if(loadingAllContractsState === "loaded") {
      const selectedContractTypeTemplatesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(this.props.DatabaseMobx.o_tbl_a_teammates_contract_templates_filefoldersystem, "contract_type_id", selectedFileFolderSystemContractTypeID, "display_name", true, true);
      const teamplateSwsOptionsObj = {hasSearchTF:true, unselectedDisplay:"--Select a Teammate Contract Template--"};
      const templateSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("Teammate Contract Template", selectedContractTypeTemplatesArrayOfObjs, "id", false, "display_name", teamplateSwsOptionsObj);
      const selectTemplatesFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", templateSwsDataObj);

      var contractRecordsArrayOfObjs = [];
      for(let contractRecordObj of this.props.AdminMobx.o_allTeammateContractsArrayOfObjs) {
        if(contractRecordObj.teammate_contract_type_id === selectedFileFolderSystemContractTypeID) {
          var captureName = this.props.DatabaseMobx.capture_name_plaintext_from_capture_id(contractRecordObj.capture_id);
          var captureNameShort = JSFUNC.trim_string_max_chars_add_ellipsis(captureName, 100);

          var contactCompanyName = this.props.ContactsMobx.contact_name_from_id(false, contractRecordObj.contactCompanyID);

          var captureIDCommaContactRecordIDString = contractRecordObj.capture_id + "," + contractRecordObj.id;

          contractRecordsArrayOfObjs.push({
            captureIDCommaContactRecordIDString: captureIDCommaContactRecordIDString,
            captureNameSlashTeammateName: (captureName + " / " + contactCompanyName)
          });
        }
      }
      JSFUNC.sort_arrayOfObjs(contractRecordsArrayOfObjs, "captureNameSlashTeammateName", true);

      const captureTeammateSwsOptionsObj = {hasSearchTF:true, unselectedDisplay:"--Select a Teammate/Contract--"};
      const captureTeammateSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("Teammate/Contract", contractRecordsArrayOfObjs, "captureIDCommaContactRecordIDString", true, "captureNameSlashTeammateName", captureTeammateSwsOptionsObj);
      const selectCaptureTeammateFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", captureTeammateSwsDataObj);

      const selectedCaptureIDCommaContractRecordIDString = selectedCaptureID + "," + selectedContractRecordID;

      selectContractTestsComponent = (
        <>
          <div className="lrMedMargin">
            <div className="smallTopMargin microBottomMargin">
              <font className="fontItalic fontTextLight">
                {"Select a Capture/Teammate combination to test"}
              </font>
            </div>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={selectCaptureTeammateFieldTypeObj}
              p_valueRaw={selectedCaptureIDCommaContractRecordIDString}
              p_errorTF={(s_showErrorsTF && (!JSFUNC.select_int_is_filled_out_tf(selectedCaptureID) || !JSFUNC.select_int_is_filled_out_tf(selectedContractRecordID)))}
              f_onChangeOrOnSelect={this.onselect_capture_teammate_combination}
            />
          </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex11a tbMargin lrMedMargin">
              <div className="smallTopMargin">
                <font className="fontItalic fontTextLight">
                  {"Select a Contract Template to test"}
                </font>
              </div>
              <div className="microTopMargin">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={selectTemplatesFieldTypeObj}
                  p_valueRaw={selectedTemplateID}
                  p_errorTF={(s_showErrorsTF && !templateValidTF)}
                  f_onChangeOrOnSelect={this.onselect_template}
                />
              </div>
            </div>
            <div className="flex00a lrMedMargin">
              <CEGeneralReact.GenerateAndUploadTeammateContractTemplateButtonProcess
                p_captureID={selectedCaptureID}
                p_teammateContractID={selectedContractRecordID}
                p_teammateContractTemplateID={selectedTemplateID}
                p_buttonText="Generate/Download Test Contract Template"
                p_canClickButtonTF={(captureValidTF && contractRecordValidTF && templateValidTF)}
                p_adminDownloadNoUploadTF={true}
                f_onClick={this.onclick_generate_button_set_show_errors}
              />
            </div>
          </div>
        </>
      );
    }

    const processContractsOnlyToPrimeOption0 = "[default] Can only process contracts between You (as a Sub) and the Prime";
    const processContractsToPrimeAndSubsOption1 = "Can process contracts between You (as a Sub) and other added Sub Teammates";
    const processContractsAsSubVerticalSwitchOptionsHeightEm = ((o_mediaQueryFlag >= 4) ? (1.9) : (2.8));

    return(
      <div className="flex11a displayFlexRow">
        <div className="flex11a yScroll" style={{flexBasis:"100em", borderRight:"solid 2px #bbb"}}>
          <div className="medFullPad">
            <div className="smallBottomMargin">
              <font className="font11 fontItalic fontTextLight">
                {"Option for processing Teammate Contracts when You are a Sub"}
              </font>
            </div>
            <div style={{maxWidth:"44em"}}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Process Contracts as Sub Option", [0, 1], false, [processContractsOnlyToPrimeOption0, processContractsToPrimeAndSubsOption1], processContractsAsSubVerticalSwitchOptionsHeightEm)}
                p_valueRaw={c_companyTeammateContractsOnOtherTeammatesWhenSub01}
                f_onChangeOrOnSelect={this.onswitch_contracts_on_other_teammates_when_sub}
              />
            </div>
          </div>
          <div className="medFullPad borderT1bbb">
            <AddEditDeleteTable
              p_dataArrayOfObjs={teammatesContractTypesArrayOfObjs}
              p_aedTableObj={teammateContractTypesAedTableObj}
            />
          </div>
        </div>
        <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
          <div className="flex00a medFullPad borderB1bbb">
            <div className="smallBottomMargin">
              <font className="font13 fontBold fontItalic fontTextLight">
                {"Select a Teammate Contract Type to upload Templates of that type below:"}
              </font>
            </div>
            <div className="displayFlexRow xyScroll">
              {teammatesContractTypesArrayOfObjs.map((m_teammateContractTypeObj) =>
                <TeammateContractTypeSelectionBox
                  key={m_teammateContractTypeObj.id}
                  p_contractTypeObj={m_teammateContractTypeObj}
                  p_selectedContractTypeID={selectedFileFolderSystemContractTypeID}
                  f_onClick={this.onclick_select_teammate_contract_type}
                />
              )}
            </div>
          </div>
          {(teammatesContractTypesArrayOfObjs.length > 0) ? (
            <CEGeneralReact.FileFolderSystem
              p_tblName="tbl_a_teammates_contract_templates_filefoldersystem"
              p_tblMapOfMaps={this.props.DatabaseMobx.o_tbl_a_teammates_contract_templates_filefoldersystem}
              p_tblFilterValuesObj={{contract_type_id:selectedFileFolderSystemContractTypeID}}
              p_title={undefined}
              p_defaultXmlType={undefined}
              p_allowedExtsArray={["xml"]}
              p_disableUploadFilesTF={false}
              p_disableCreateOnlineLinkDocsTF={true}
              p_disableCreateFoldersTF={false}
              p_viewWideTrueNarrowFalse={(this.props.CaptureExecMobx.o_mediaQueryFlag >= 6)}
              p_navigateToFolderID={undefined}
              f_onPerformFFSAction={undefined}
              f_onNavigateToFolder={undefined}
            />
          ) : (
            <CEGeneralReact.EmptyScreenGray>
              {"There are 0 Teammate Contract Types, no Teammate Contract Templates can be uploaded until at least 1 is created"}
            </CEGeneralReact.EmptyScreenGray>
          )}
          <div className="flex00a bgAddEditDeleteTable" style={{borderTop:"solid 2px #985"}}>
            {selectContractTestsComponent}
            <div className="displayFlexColumnHcVc tbPad" style={{borderTop:"solid 1px #985"}}>
              <ViewAllTemplateCodewordsButtonWithFloatingBox />
            </div>
          </div>
        </div>
      </div>
    );
  }
}));


const TeammateContractTypeSelectionBox = inject("AdminMobx")(observer(
class TeammateContractTypeSelectionBox extends Component { //props: p_contractTypeObj, p_selectedContractTypeID, f_onClick
  onclick_teammate_contract_type_box = () => {
    if(this.props.f_onClick) {
      this.props.f_onClick(this.props.p_contractTypeObj.id);
    }
  }

  render() {
    const contractTypeObj = this.props.p_contractTypeObj;
    const selectedContractTypeID = this.props.p_selectedContractTypeID;
    const isSelectedTF = (contractTypeObj.id === selectedContractTypeID);
    return(
      <div
        className={"flex00a displayFlexRowVc lrMargin lrPad border bevelBorderColors textCenter " + ((isSelectedTF) ? ("bgRed fontWhite") : ("bgLighterGray hoverLighterBlueGradient cursorPointer"))}
        style={{height:"4em", width:"11em", borderRadius:"0.9em"}}
        onClick={((isSelectedTF) ? (undefined) : (this.onclick_teammate_contract_type_box))}>
        <LibraryReact.MaxHeightWrap p_maxHeight="3.5em">
          {contractTypeObj.name}
        </LibraryReact.MaxHeightWrap>
      </div>
    );
  }
}));


const TeammateContractProcessStatuses = inject("AdminMobx", "DatabaseMobx")(observer(
class TeammateContractProcessStatuses extends Component {
  render() {
    const c_teammatesContractOtherStatusesArrayOfObjs = this.props.AdminMobx.c_teammatesContractOtherStatusesArrayOfObjs;
    const c_teammatesContractThemStatusesArrayOfObjs = this.props.AdminMobx.c_teammatesContractThemStatusesArrayOfObjs;
    const c_teammatesContractWeStatusesArrayOfObjs = this.props.AdminMobx.c_teammatesContractWeStatusesArrayOfObjs;

    const teammateContractOtherStatusesAedTableObj = {
      tblName: "tbl_a_teammates_contract_statuses",
      initialSortedFieldDbName: "sort",
      tableWidth: "37em",
      canAddNewItemsTF: false,
      canDeleteItemsTF: false,
      itemName: "Teammate Contracts Process Other Statuses",
      fieldsArrayOfObjs: [
        {width:"200em", dbName:"name", displayName:"Teammate Contract Agreement Type", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"color", displayName:"Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"1155cc"}
      ]
    };

    const teammateContractThemStatusesAedTableObj = {
      tblName: "tbl_a_teammates_contract_statuses",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: "47em",
      itemName: "Teammate Contracts Process 'Them' Status",
      deleteItemNameFieldDbName: "name",
      newItemConstantFieldNamesArray: ["hc0_they1_we2"],
      newItemConstantValuesArray: [1],
      newItemConstantIdsbArray: ["i"],
      fieldsArrayOfObjs: [
        {width:"200em", dbName:"name", displayName:"Teammate Contract Agreement Type", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"color", displayName:"Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"c09000"},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    const teammateContractWeStatusesAedTableObj = {
      tblName: "tbl_a_teammates_contract_statuses",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: "47em",
      itemName: "Teammate Contracts Process 'We' Status",
      deleteItemNameFieldDbName: "name",
      newItemConstantFieldNamesArray: ["hc0_they1_we2"],
      newItemConstantValuesArray: [2],
      newItemConstantIdsbArray: ["i"],
      fieldsArrayOfObjs: [
        {width:"200em", dbName:"name", displayName:"Teammate Contract Agreement Type", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"color", displayName:"Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"1155cc"},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    return(
      <>
        <div className="smallFullMargin hugeTopPad hugeBottomPad lrMedPad border1bbb bgLightestGray" style={{maxWidth:"100em"}}>
          <div className="smallBottomMargin">
            <font className="font15 fontTextLight">
              {"Full Teammate Contract Process"}
            </font>
          </div>
          <TeammateContractsReact.StatusTimeline />
        </div>
        <div className="displayFlexRow flexWrap">
          <div className="flex00a hugeTopPad hugeBottomPad lrMedPad borderR1bbb">
            <AddEditDeleteTable
              p_dataArrayOfObjs={c_teammatesContractOtherStatusesArrayOfObjs}
              p_aedTableObj={teammateContractOtherStatusesAedTableObj}
            />
          </div>
          <div className="flex00a hugeTopPad hugeBottomPad lrMedPad borderR1bbb">
            <AddEditDeleteTable
              p_dataArrayOfObjs={c_teammatesContractThemStatusesArrayOfObjs}
              p_aedTableObj={teammateContractThemStatusesAedTableObj}
            />
          </div>
          <div className="flex00a hugeTopPad hugeBottomPad lrMedPad">
            <AddEditDeleteTable
              p_dataArrayOfObjs={c_teammatesContractWeStatusesArrayOfObjs}
              p_aedTableObj={teammateContractWeStatusesAedTableObj}
            />
          </div>
        </div>
      </>
    );
  }
}));


const TeammateContractAgreementTypes = inject("AdminMobx", "DatabaseMobx")(observer(
class TeammateContractAgreementTypes extends Component {
  render() {
    const teammatesContractAgreementTypesArrayOfObjs = this.props.AdminMobx.c_teammatesContractAgreementTypesArrayOfObjs;

    const teammateContractAgreementTypesAedTableObj = {
      tblName: "tbl_a_teammates_contract_agreement_types",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: "35em",
      itemName: "Teammate Contract Agreement Type",
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"name", displayName:"Teammate Contract Agreement Type", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    return(
      <div className="medFullMargin">
        <AddEditDeleteTable
          p_dataArrayOfObjs={teammatesContractAgreementTypesArrayOfObjs}
          p_aedTableObj={teammateContractAgreementTypesAedTableObj}
        />
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabTeammateRatings = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabTeammateRatings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_recomputingContactCompanyTeammateRatingsState: "initial" //"initial", "working", "success", "error"
    }
  }

  onclick_recompute_all_contact_company_teammate_ratings = () => {
    this.setState({s_recomputingContactCompanyTeammateRatingsState:"working"});

    const functionOnSuccess = () => {
      this.setState({s_recomputingContactCompanyTeammateRatingsState:"success"});
    }
    const functionOnError = () => {
      this.setState({s_recomputingContactCompanyTeammateRatingsState:"error"});
    }
    this.props.AdminMobx.a_teammates_ratings_recompute_all_contact_teammate_ratings(functionOnSuccess, functionOnError);
  }


  render() {
    const s_recomputingContactCompanyTeammateRatingsState = this.state.s_recomputingContactCompanyTeammateRatingsState;

    const teammatesRatingsQuestionsArrayOfObjs = this.props.DatabaseMobx.c_teammatesRatingsQuestionsArrayOfObjs;

    const functionOnInsertTeammateRatingQuestion = (i_parseResponse) => {
      const newlyInsertedTeammateRatingQuestionID = i_parseResponse.outputObj.i0;

      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabTeammateRatings", "functionOnInsertTeammateRatingQuestion", ["newlyInsertedTeammateRatingQuestionID"], [newlyInsertedTeammateRatingQuestionID]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const contactCompaniesRatingColumnDbName = "tr_q" + newlyInsertedTeammateRatingQuestionID;
      const decimalFieldTypeObj = this.props.DatabaseMobx.c_genericDecimalFieldTypeObj;
      const initialValueForAllExistingRows = -1; //set every rating value to -1 initially for "N/A"
      C_CallPhpTblUID.add_alter_db_tbl_insert_column("tbl_g_contacts_companies", contactCompaniesRatingColumnDbName, decimalFieldTypeObj.mysqlColumnDataType, decimalFieldTypeObj.blankValue, initialValueForAllExistingRows);

      const questionnaireSubmissionAnswerColumnDbName = "q" + newlyInsertedTeammateRatingQuestionID + "a";
      const int0to9FieldTypeObj = this.props.DatabaseMobx.c_genericInt0to9FieldTypeObj;
      C_CallPhpTblUID.add_alter_db_tbl_insert_column("tbl_c_teammates_ratings_questionnaire_submissions", questionnaireSubmissionAnswerColumnDbName, int0to9FieldTypeObj.mysqlColumnDataType, int0to9FieldTypeObj.blankValue, initialValueForAllExistingRows);

      const questionnaireSubmissionCommentColumnDbName = "q" + newlyInsertedTeammateRatingQuestionID + "c";
      const textFieldTypeObj = this.props.DatabaseMobx.c_genericTextFieldTypeObj;
      C_CallPhpTblUID.add_alter_db_tbl_insert_column("tbl_c_teammates_ratings_questionnaire_submissions", questionnaireSubmissionCommentColumnDbName, textFieldTypeObj.mysqlColumnDataType, textFieldTypeObj.blankValue);

      C_CallPhpTblUID.execute();
    }

    const functionOnDeleteTeammateRatingQuestion = (i_C_CallPhpTblUID, i_dataRowObj) => {
      const contactCompaniesRatingColumnDbName = "tr_q" + i_dataRowObj.id;
      i_C_CallPhpTblUID.add_alter_db_tbl_delete_column("tbl_g_contacts_companies", contactCompaniesRatingColumnDbName);

      const questionnaireSubmissionAnswerColumnDbName = "q" + i_dataRowObj.id + "a";
      i_C_CallPhpTblUID.add_alter_db_tbl_delete_column("tbl_c_teammates_ratings_questionnaire_submissions", questionnaireSubmissionAnswerColumnDbName);

      const questionnaireSubmissionCommentColumnDbName = "q" + i_dataRowObj.id + "c";
      i_C_CallPhpTblUID.add_alter_db_tbl_delete_column("tbl_c_teammates_ratings_questionnaire_submissions", questionnaireSubmissionCommentColumnDbName);
    }

    const teammatesRatingsQuestionsAedTableObj = {
      tblName: "tbl_a_teammates_ratings_questions",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: "70em",
      itemName: "Teammate Rating Question",
      deleteItemNameFieldDbName: "name",
      itemInsertFunctionOnSuccess: functionOnInsertTeammateRatingQuestion,
      itemDeleteFunctionsArray: [functionOnDeleteTeammateRatingQuestion],
      fieldsArrayOfObjs: [
        {width:"500em", dbName:"name", displayName:"Question", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"weight", displayName:"Weight (0-9)", fieldTypeObj:this.props.DatabaseMobx.c_genericInt0to9FieldTypeObj, initialValue:5, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    return(
      <div className="flex11a yScroll medFullPad">
        <div className="displayFlexRow flexWrap">
          <div className="flex00a">
            <AddEditDeleteTable
              p_dataArrayOfObjs={teammatesRatingsQuestionsArrayOfObjs}
              p_aedTableObj={teammatesRatingsQuestionsAedTableObj}
            />
          </div>
          <div className="flex00a" style={{flexBasis: "6em"}} />
          <div className="flex00a" style={{flexBasis:"41em"}}>
            <div className="border1bbb medFullPad">
              <div className="smallBottomMargin">
                <font className="fontItalic fontTextLighter">
                  {"Use this button to manually recompute the Teammate Rating for all Contact Companies when the Questions or Weights are changed/added/deleted in this table"}
                </font>
              </div>
              {(s_recomputingContactCompanyTeammateRatingsState === "working") ? (
                <div className="displayFlexColumnHcVc tbPad lrMedPad bgLightestGray border1ddd borderRadius05 textCenter">
                  <div className="">
                    <font className="fontItalic">
                      {"Recomputing..."}
                    </font>
                  </div>
                  <div>
                    <CEGeneralReact.LoadingAnimation />
                  </div>
                </div>
              ) : (
                <CEGeneralReact.ButtonWithConfirmBox
                  p_buttonType="add"
                  p_buttonText="Recompute All Contact Company Teammate Ratings"
                  p_confirmType="confirm"
                  p_confirmMessage="Are you sure you want to recompute the Teammate Rating for all Contact Companies?"
                  f_onClickConfirm={this.onclick_recompute_all_contact_company_teammate_ratings}
                />
              )}
              {(s_recomputingContactCompanyTeammateRatingsState === "success") &&
                <div className="displayFlexColumnHcVc smallTopMargin tbPad lrMedPad bgLighterGreen border1LightGreen borderRadius05 textCenter">
                  <font className="fontItalic fontDarkGreen">
                    {"Recomputation completed successfully"}
                  </font>
                </div>
              }
              {(s_recomputingContactCompanyTeammateRatingsState === "error") &&
                <div className="displayFlexColumnHcVc smallTopMargin tbPad lrMedPad bgLightRed border1LightRed borderRadius05 textCenter">
                  <font className="fontBold fontRed">
                    {"Recomputation unsuccessful, please try again"}
                  </font>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabTeammateSurveys = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabTeammateSurveys extends Component {
  onswitch_company_teammate_surveys_notifications_to_all_capture_managers = (i_newValue01) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("teammate_surveys_notifications_to_all_capture_managers_01", i_newValue01, "i");
  }

  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_companyTeammateSurveysNotificationsToAllCaptureManagers01 = this.props.DatabaseMobx.c_companyTeammateSurveysNotificationsToAllCaptureManagers01;

    return(
      <div className="flex11a yScroll medFullPad">
        <div className="bgLightestestGray border1bbb borderRadius05 medFullPad" style={{width:"30em"}}>
          <div className="medBottomMargin">
            <font className="fontItalic fontTextLight">
              {"Here you can custom define the 6 colors representing 0 to 5 (out of 5) on 'Scale 0-5' type questions in the " + c_productStylingObj.productName + " Teammates Survey Matrix of response results"}
            </font>
          </div>
          {[0,1,2,3,4,5].map((m_scale0to5Int) =>
            <AdminTeammateSurveysEditColor p_scale0to5Int={m_scale0to5Int} />
          )}
        </div>
        <div className="hugeTopMargin border1bbb medFullPad" style={{maxWidth:"44em"}}>
          <div className="medBottomMargin">
            <font className="fontItalic fontTextLight">
              {"Use this switch to choose whether notifications/emails regarding Teammate Survey completion from Teammates are sent to only the original sender of the survey, or also to all Capture Managers on the Capture"}
            </font>
          </div>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Survey Notifications Option", [0, 1], false, ["[default] Notifications/Emails are only sent to the survey sender", "Notifications/Emails are sent to the survey sender and all Capture Managers"], 1.9)}
            p_valueRaw={c_companyTeammateSurveysNotificationsToAllCaptureManagers01}
            f_onChangeOrOnSelect={this.onswitch_company_teammate_surveys_notifications_to_all_capture_managers}
          />
        </div>
      </div>
    );
  }
}));

const AdminTeammateSurveysEditColor = inject("DatabaseMobx")(observer(
class AdminTeammateSurveysEditColor extends Component { //props: p_scale0to5Int
  onsave_teammates_surveys_scale05_color = (i_newValue) => {
    const scale0to5Int = this.props.p_scale0to5Int;

    const colorFieldDbName = "color" + scale0to5Int;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminTeammateSurveysEditColor", "onsave_teammates_surveys_scale05_color", ["i_newValue"], [i_newValue]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_teammates_surveys_scale05_colors", 1, [colorFieldDbName], [i_newValue], ["s"]);
    C_CallPhpTblUID.execute();
  }

  render() {
    const scale0to5Int = this.props.p_scale0to5Int;

    const colorFieldDbName = "color" + scale0to5Int;
    var currentColor = "ffffff";
    const teammatesSurveysScale05ColorsMap = this.props.DatabaseMobx.o_tbl_a_teammates_surveys_scale05_colors.get(1);
    if(teammatesSurveysScale05ColorsMap !== undefined) {
      currentColor = teammatesSurveysScale05ColorsMap.get(colorFieldDbName);
    }

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={"AdminTeammatesSurveysEdit_" + colorFieldDbName}
        p_fieldDisplayName={"Color for Scale Response " + scale0to5Int}
        p_fieldTypeObj={this.props.DatabaseMobx.c_genericColorFieldTypeObj}
        p_valueRaw={currentColor}
        p_valueIsEditableTFU={true}
        p_containerClass=""
        p_fieldClass="fontBlue"
        p_fieldWidth="14em"
        f_onSaveChanged={this.onsave_teammates_surveys_scale05_color}
      />
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabCompetitors = inject("AdminMobx")(observer(
class AdminSubtabCompetitors extends Component {
  render() {
    return (
      <div className="flex11a yScroll yScrollBottomPad">
        <AEDTableForExtraFieldColumnManipulation p_tblName="tbl_a_competitors_extra_fields" />
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabProposalThemes = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabProposalThemes extends Component {
  render() {
    const usingDifferentiatorsTF = this.props.DatabaseMobx.c_companyUsingPTDifferentiatorsTF;
    const usingWinThemesTF = this.props.DatabaseMobx.c_companyUsingPTWinThemesTF;
    const usingGhostThemesTF = this.props.DatabaseMobx.c_companyUsingPTGhostThemesTF;
    const c_cardNameProposalThemes = this.props.DatabaseMobx.c_cardNameProposalThemes;

    const usingModuleTFArray = [usingDifferentiatorsTF, usingWinThemesTF, usingGhostThemesTF];
    const moduleNamesArray = ["Differentiators", "Win Themes", "Ghost Themes"];
    const tblCompanyColumnNamesArray = ["pt_differentiators_01", "pt_win_themes_01", "pt_ghost_themes_01"];
    const labelBgColorsArray = ["#469", "#985", "#777"];
    const toolBgColorsArray = ["#dde6fc", "#faf8e0", "#f3f3f3"];

    var differentiatorsAedTableComponent = null;
    if(usingDifferentiatorsTF) {
      const differentiatorsAedTableObj = {
        tblName: "tbl_a_pt_differentiators",
        dragToResortFieldDbName: undefined,
        initialSortedFieldDbName: "name",
        tableWidth: "60em",
        itemName: "Differentiator",
        deleteItemNameFieldDbName: "name",
        fieldsArrayOfObjs: [
          {width:"100em", dbName:"name", displayName:"Differentiator Choices", fieldTypeObj:this.props.DatabaseMobx.c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true}
        ]
      };

      differentiatorsAedTableComponent = (
        <AddEditDeleteTable
          p_dataArrayOfObjs={this.props.AdminMobx.c_ptDifferentiatorsArrayOfObjs}
          p_aedTableObj={differentiatorsAedTableObj}
        />
      );
    }



    return (
      <div className="flex11a xyScroll yScrollBottomPad">
        <div className="displayFlexRow">
          <div className="flex00a medFullPad" style={{flexBasis:"55em", borderRight:"solid 1px #ccc"}}>
            <div className="tbPad">
              <font className="font13">
                {"Use the switches below to turn on/off modules within the " + c_cardNameProposalThemes + " capture card"}
              </font>
            </div>
            {usingModuleTFArray.map((m_usingModuleTF, m_index) =>
              <ProposalThemeModuleSwitch
                p_usingModuleTF={m_usingModuleTF}
                p_moduleName={moduleNamesArray[m_index]}
                p_tblCompanyColumnName={tblCompanyColumnNamesArray[m_index]}
              />
            )}
            <div className="displayFlexColumn" style={{height:"35em"}}>
              <OpenCaptureReact.CardShell
                p_label={c_cardNameProposalThemes}
                p_drawLeftRightButtonsTF={true}>
                <div className="flex11a displayFlexRow">
                  {usingModuleTFArray.map((m_usingModuleTF, m_index) =>
                    (m_usingModuleTF) &&
                    <div className="flex11a displayFlexColumn" style={{flexBasis:"100em", background:toolBgColorsArray[m_index], borderRight:"solid 1px #bbb"}}>
                      <div className="flex00a" style={{borderBottom:"solid 1px #eee"}}>
                        <div className="smallFullPad" style={{background:labelBgColorsArray[m_index], color:"#fff"}}>
                          <LibraryReact.Nowrap p_fontClass="font12">
                            {moduleNamesArray[m_index]}
                          </LibraryReact.Nowrap>
                        </div>
                      </div>
                      <div className="flex11a" />
                    </div>
                  )}
                </div>
              </OpenCaptureReact.CardShell>
            </div>
          </div>
          <div className="flex11a medFullPad">
            {differentiatorsAedTableComponent}
          </div>
        </div>
      </div>
    );
  }
}));

const ProposalThemeModuleSwitch = inject("AdminMobx", "DatabaseMobx")(observer(
class ProposalThemeModuleSwitch extends Component { //p_usingModuleTF, p_moduleName, p_tblCompanyColumnName
  onswitch_turn_onoff_module = () => {
    const newValue01 = ((this.props.p_usingModuleTF) ? (0) : (1));
    this.props.AdminMobx.a_update_tbl_a_company_fields(this.props.p_tblCompanyColumnName, newValue01, "i");
  }

  render() {
    const p_usingModuleTF = this.props.p_usingModuleTF;
    const p_moduleName = this.props.p_moduleName;
    const p_tblCompanyColumnName = this.props.p_tblCompanyColumnName;

    const c_cardNameProposalThemes = this.props.DatabaseMobx.c_cardNameProposalThemes;

    return(
      <div className="displayFlexRowVc tbMedMargin">
        <div className="flex00a rMargin" style={{flexBasis:"18em"}}>
          <font className="font13 fontBlue">
            {"Using '" + p_moduleName + "' module"}
          </font>
        </div>
        <div className="flex11a">
          <LibraryReact.Switch
            p_isOnTF={p_usingModuleTF}
            p_title={"Turn " + ((p_usingModuleTF) ? ("off") : ("on")) + " the " + p_moduleName + " module on the " + c_cardNameProposalThemes + " capture card"}
            f_onClick={this.onswitch_turn_onoff_module}
          />
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabRiskAssessment = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabRiskAssessment extends Component {
  render() {
    const riskCategoriesArrayOfObjs = this.props.AdminMobx.c_riskCategoriesArrayOfObjs;
    const probabilityRiskLevelsArrayOfObjs = this.props.AdminMobx.c_risksProbabilityRiskLevelsArrayOfObjs;
    const impactRiskLevelsArrayOfObjs = this.props.AdminMobx.c_risksImpactRiskLevelsArrayOfObjs;

    const riskCategoriesAedTableObj = {
      tblName: "tbl_a_risks_categories",
      dragToResortFieldDbName: undefined,
      initialSortedFieldDbName: "name",
      tableWidth: "35em",
      itemName: "Risk Category",
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"name", displayName:"Risk Category", fieldTypeObj:this.props.DatabaseMobx.c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true}
      ]
    };

    const probabilityAedTableObj = {
      tblName: "tbl_a_risks_probability_levels",
      dragToResortFieldDbName: undefined,
      initialSortedFieldDbName: "percent_0to100",
      tableWidth: "45em",
      itemName: "Probability Risk Level",
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"200em", dbName:"name", displayName:"Probability Risk Level Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"percent_0to100", displayName:"Percent (0-100%)", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj, initialValue:50, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"color", displayName:"Font Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"666666", cellCenterTF:true, requiredNotEmptyTF:true}
      ]
    };

    const impactAedTableObj = {
      tblName: "tbl_a_risks_impact_levels",
      dragToResortFieldDbName: undefined,
      initialSortedFieldDbName: "percent_0to100",
      tableWidth: "45em",
      itemName: "Impact Risk Level",
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"200em", dbName:"name", displayName:"Impact Risk Level Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"percent_0to100", displayName:"Percent (0-100%)", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj, initialValue:50, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"color", displayName:"Font Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"666666", cellCenterTF:true, requiredNotEmptyTF:true}
      ]
    };

    return (
      <div className="flex11a xyScroll yScrollBottomPad">
        <div className="displayFlexRow flexWrap" style={{borderBottom:"solid 1px #bbb"}}>
          <div className="flex00a medFullMargin">
            <AddEditDeleteTable
              p_dataArrayOfObjs={riskCategoriesArrayOfObjs}
              p_aedTableObj={riskCategoriesAedTableObj}
            />
          </div>
          <div className="flex00a medFullMargin">
            <AddEditDeleteTable
              p_dataArrayOfObjs={probabilityRiskLevelsArrayOfObjs}
              p_aedTableObj={probabilityAedTableObj}
            />
          </div>
          <div className="flex00a medFullMargin">
            <AddEditDeleteTable
              p_dataArrayOfObjs={impactRiskLevelsArrayOfObjs}
              p_aedTableObj={impactAedTableObj}
            />
          </div>
        </div>
        <div className="medFullMargin" style={{width:"70em"}}>
          <OpenCaptureReact.RiskAssessmentProbabilityImpactMatrix p_risksArrayOfObjs={[]} />
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabBudgets = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabBudgets extends Component {
  onsave_budget_manager_approval_num_days = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("budget_manager_approval_num_days", i_newValue, "i");
  }

  ondrop_budget_category_into_capture_type = (i_draggedBudgetCategoryID) => {
    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;
    const c_selectedCaptureTypeBudgetCategoryIDsArray = this.props.AdminMobx.c_selectedCaptureTypeBudgetCategoryIDsArray;

    if(!JSFUNC.in_array(i_draggedBudgetCategoryID, c_selectedCaptureTypeBudgetCategoryIDsArray)) { //must be adding a unique budget category to this capture type that is not already added
      var newSelectedCaptureTypeDetailsCardGroupIDsArray = JSFUNC.copy_array(c_selectedCaptureTypeBudgetCategoryIDsArray);
      newSelectedCaptureTypeDetailsCardGroupIDsArray.push(i_draggedBudgetCategoryID);
      const newSelectedCaptureTypeDetailsCardGroupIDsComma = JSFUNC.convert_array_to_comma_list(newSelectedCaptureTypeDetailsCardGroupIDsArray);

      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabBudgets", "ondrop_budget_category_into_capture_type", ["i_draggedBudgetCategoryID"], [i_draggedBudgetCategoryID]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_a_capture_types", o_selectedCaptureTypeID, "budget_category_ids_comma", newSelectedCaptureTypeDetailsCardGroupIDsComma, "s");
      C_CallPhpTblUID.execute();
    }
  }

  onremove_all_budget_categories_from_capture_type = () => {
    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;
    const c_selectedCaptureTypeBudgetCategoryIDsArray = this.props.AdminMobx.c_selectedCaptureTypeBudgetCategoryIDsArray;
    if(c_selectedCaptureTypeBudgetCategoryIDsArray.length > 0) {
      const jsDescription = JSFUNC.js_description_from_action("AdminMobx - AdminSubtabBudgets", "onremove_all_budget_categories_from_capture_type", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update("tbl_a_capture_types", o_selectedCaptureTypeID, "budget_category_ids_comma", "", "s");
      C_CallPhpTblUID.execute();
    }
  }

  render() {
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeBudgetCategoryIDsArray = this.props.AdminMobx.c_selectedCaptureTypeBudgetCategoryIDsArray;
    const selectedCaptureTypeBudgetCategoriesArrayOfObjs = this.props.AdminMobx.c_selectedCaptureTypeBudgetCategoriesArrayOfObjs;
    const c_cardNameBudget = this.props.DatabaseMobx.c_cardNameBudget;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    const numBudgetCategoriesAdded = c_selectedCaptureTypeBudgetCategoryIDsArray.length;

    const budgetCategoriesAedTableObj = {
      tblName: "tbl_a_budget_categories_pool",
      dragToResortFieldDbName: "sort",
      canAlwaysDragResortTF: true,
      initialSortedFieldDbName: "sort",
      tableWidth: "100%",
      itemName: "Budget Category",
      deleteItemNameFieldDbName: "name",
      idsAlreadyAddedArray: c_selectedCaptureTypeBudgetCategoryIDsArray,
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"name", displayName:"Budget Category", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    return (
      <>
        <CaptureTypeSelectionBoxes />
        <div className="flex11a displayFlexRow">
          <div className="flex11a yScroll medFullPad" style={{flexBasis:"100em"}}>
            <div className="smallBottomMargin">
              <font className="font14 fontItalic">
                {"All unique Budget Categories used in every " + c_fieldMapOfCaptureType.get("display_name")}
              </font>
            </div>
            <AddEditDeleteTable
              p_dataArrayOfObjs={this.props.AdminMobx.c_budgetCategoriesPoolArrayOfObjs}
              p_aedTableObj={budgetCategoriesAedTableObj}
              f_onClickAddItem={this.ondrop_budget_category_into_capture_type}
            />
            <div className="flex00a hugeTopMargin medFullPad borderT1ddd borderB1ddd" style={{width:"40em"}}>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="adminBudgetsApprovalNumDays"
                p_fieldDisplayName="Budget Manager Target # Days to Approve Funding Requests"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
                p_valueRaw={this.props.DatabaseMobx.c_companyBudgetManagerApprovalNumDays}
                p_valueIsEditableTFU={true}
                p_fieldClass="fontBlue"
                p_fieldWidth="14em"
                f_onSaveChanged={this.onsave_budget_manager_approval_num_days}
              />
            </div>
          </div>
          <div className="flex11a displayFlexColumn medFullPad" style={{flexBasis:"100em"}}>
            <div className="flex11a xyScroll">
              <BlueItemCollectionShell
                p_isReceivingTF={true}
                p_title={"Budget Categories for captures with " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
                p_dropUniqueString="AddEditDeleteTableRow_tbl_a_budget_categories_pool"
                p_dropNotAllowedDraggerIDsArray={c_selectedCaptureTypeBudgetCategoryIDsArray}
                p_dropMessage="[Drag/drop Budget Categories from the pool]"
                p_dropAllowDropMessage={"[Drop Category to add to " + c_cardNameBudget + " Card]"}
                p_dropNotAllowDropMessage={"[Cannot have duplicate Budget Categories on the " + c_cardNameBudget + " Card]"}
                f_onClickSetAsReceiving={undefined}
                f_onDrop={this.ondrop_budget_category_into_capture_type}
                f_onRemoveAllItems={this.onremove_all_budget_categories_from_capture_type}>
                {selectedCaptureTypeBudgetCategoriesArrayOfObjs.map((m_budgetCategoryObj, m_index) =>
                  <AddedBudgetCategoryItem
                    key={c_selectedCaptureTypeObj.id + "-" + m_budgetCategoryObj.id}
                    p_budgetCategoryObj={m_budgetCategoryObj}
                    p_budgetCategoryIndex={m_index}
                    p_isOnlyItemTF={(numBudgetCategoriesAdded === 1)}
                    p_isLastItemTF={(m_index === (numBudgetCategoriesAdded - 1))}
                  />
                )}
              </BlueItemCollectionShell>
            </div>
          </div>
        </div>
      </>
    );
  }
}));


export const AddedBudgetCategoryItem = inject("AdminMobx", "DatabaseMobx")(observer(
class AddedBudgetCategoryItem extends Component { //props: p_budgetCategoryObj, p_budgetCategoryIndex, p_isOnlyItemTF, p_isLastItemTF
  onclick_remove_details_card_group_from_capture_type = () => {
    const p_budgetCategoryObj = this.props.p_budgetCategoryObj;

    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_selectedCaptureTypeBudgetCategoryIDsArray = this.props.AdminMobx.c_selectedCaptureTypeBudgetCategoryIDsArray;

    const newSelectedCaptureTypeDetailsCardGroupIDsArray = JSFUNC.remove_all_values_from_array(p_budgetCategoryObj.id, c_selectedCaptureTypeBudgetCategoryIDsArray);
    const newSelectedCaptureTypeDetailsCardGroupIDsComma = JSFUNC.convert_array_to_comma_list(newSelectedCaptureTypeDetailsCardGroupIDsArray);

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AddedBudgetCategoryItem", "onclick_remove_details_card_group_from_capture_type", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_capture_types", c_selectedCaptureTypeObj.id, "budget_category_ids_comma", newSelectedCaptureTypeDetailsCardGroupIDsComma, "s");
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_budgetCategoryObj = this.props.p_budgetCategoryObj;
    const p_budgetCategoryIndex = this.props.p_budgetCategoryIndex;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_cardNameBudget = this.props.DatabaseMobx.c_cardNameBudget;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    return(
      <CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="captureTypeDetailsCardGroup"
        p_itemIndex={p_budgetCategoryIndex}
        p_itemID={p_budgetCategoryObj.id}
        p_itemSortColumnWidth="2em"
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="microFullPad"
        p_itemClass="displayFlexRow border bevelBorderColors borderRadius05 bgAlmostWhite"
        p_itemStyleObj={{height:"2.4em"}}
        p_lastItemExtraDropZoneHeight="1.8em"
        p_tblName="tbl_a_capture_types"
        p_tblRowID={c_selectedCaptureTypeObj.id}
        p_tblCommaListColumnName="budget_category_ids_comma"
        p_commaList={c_selectedCaptureTypeObj.budget_category_ids_comma}>
        <div className="flex11a displayFlexRowVc lrPad">
          {p_budgetCategoryObj.name}
        </div>
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove Budget Category '" + p_budgetCategoryObj.name + "' from " + c_cardNameBudget + " Card for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'"}
            f_onClick={this.onclick_remove_details_card_group_from_capture_type}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinCommaListWithinDbTbl>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================






export const AdminSubtabSingleCaptureTemplates = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class AdminSubtabSingleCaptureTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectedCaptureID: -1,
      s_selectedTemplateID: -1,
      s_showErrorsTF: false
    }
  }

  onselect_capture = (i_captureID) => {
    this.setState({s_selectedCaptureID:i_captureID});
  }

  onselect_template = (i_templateID) => {
    this.setState({s_selectedTemplateID:i_templateID});
  }

  onclick_generate_button_set_show_errors = () => {
    this.setState({s_showErrorsTF:true});
  }

  render() {
    const selectedCaptureID = this.state.s_selectedCaptureID;
    const selectedTemplateID = this.state.s_selectedTemplateID;
    const s_showErrorsTF = this.state.s_showErrorsTF;

    const captureValidTF = JSFUNC.select_int_is_filled_out_tf(selectedCaptureID);
    const templateValidTF = JSFUNC.select_int_is_filled_out_tf(selectedTemplateID);

    return (
      <>
        <CEGeneralReact.FileFolderSystem
          p_tblName="tbl_a_single_capture_templates_filefoldersystem"
          p_tblMapOfMaps={this.props.DatabaseMobx.o_tbl_a_single_capture_templates_filefoldersystem}
          p_tblFilterValuesObj={undefined}
          p_title={undefined}
          p_defaultXmlType={undefined}
          p_allowedExtsArray={["xml"]}
          p_disableUploadFilesTF={false}
          p_disableCreateOnlineLinkDocsTF={true}
          p_disableCreateFoldersTF={false}
          p_viewWideTrueNarrowFalse={(this.props.CaptureExecMobx.o_mediaQueryFlag >= 4)}
          p_navigateToFolderID={undefined}
          f_onPerformFFSAction={undefined}
          f_onNavigateToFolder={undefined}
        />
        <div className="flex00a displayFlexRow bgAddEditDeleteTable" style={{padding:"1em 0", borderTop:"solid 2px #985"}}>
          <div className="flex00a displayFlexColumnHcVc lrMedPad" style={{borderRight:"solid 1px #985"}}>
            <ViewAllTemplateCodewordsButtonWithFloatingBox />
          </div>
          <div className="flex11a displayFlexRowVc flexWrap">
            <div className="flex00a tbMicroPad lrMedPad" style={{flexBasis:"19em"}}>
              <font className="font12 fontBold fontItalic fontTextLight">
                {"Select a Capture to test out generating a sample template"}
              </font>
            </div>
            <div className="flex00a tbMicroPad lrMedPad" style={{flexBasis:"25em"}}>
              <div className="microBottomMargin textCenter">
                <font className="fontItalic fontTextLight">
                  {"Select a sample Capture"}
                </font>
              </div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectAllCapturesNoClearFieldTypeObj}
                p_valueRaw={selectedCaptureID}
                p_errorTF={(s_showErrorsTF && !captureValidTF)}
                f_onChangeOrOnSelect={this.onselect_capture}
              />
            </div>
            <div className="flex00a tbMicroPad lrMedPad" style={{flexBasis:"25em"}}>
              <div className="microBottomMargin textCenter">
                <font className="fontItalic fontTextLight">
                  {"Select a Template to test"}
                </font>
              </div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectSingleCaptureTemplateFieldTypeObj}
                p_valueRaw={selectedTemplateID}
                p_errorTF={(s_showErrorsTF && !templateValidTF)}
                f_onChangeOrOnSelect={this.onselect_template}
              />
            </div>
            <div className="flex00a tbMicroPad lrMedPad" style={{flexBasis:"20em"}}>
              <CEGeneralReact.GenerateAndDownloadSingleCaptureTemplateButtonProcess
                p_fromOpenCaptureTrueAdminFalse={false}
                p_captureID={selectedCaptureID}
                p_singleCaptureTemplateID={selectedTemplateID}
                p_buttonText="Generate/Download Test Template"
                p_buttonTitle={undefined}
                p_canClickButtonTF={(captureValidTF && templateValidTF)}
                f_onClick={this.onclick_generate_button_set_show_errors}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}));



export function ViewAllTemplateCodewordsButtonWithFloatingBox(props) { //props: p_excelReportsTF
  const [hookCodewordsFloatingBoxIsOpenTF, f_hookSetCodewordFloatingBoxIsOpenTF] = useState(false);

  const p_excelReportsTF = JSFUNC.prop_value(props.p_excelReportsTF, false);
  const buttonText = ((p_excelReportsTF) ? ("View All Excel Report Codewords") : ("View All Template Codewords"));
  return(
    <>
      <CEGeneralReact.CEButton
        p_type="add"
        p_text={buttonText}
        f_onClick={() => f_hookSetCodewordFloatingBoxIsOpenTF(true)}
      />
      {(hookCodewordsFloatingBoxIsOpenTF) &&
        <ViewAllTemplateCodewordsFloatingBox
          p_excelReportsTF={p_excelReportsTF}
          f_onClose={() => f_hookSetCodewordFloatingBoxIsOpenTF(false)}
        />
      }
    </>
  );
}


const ViewAllTemplateCodewordsFloatingBox = inject("CaptureExecMobx", "AdminMobx")(observer(
class ViewAllTemplateCodewordsFloatingBox extends Component { //props: p_excelReportsTF, f_onClose
  constructor(props) {
    super(props);
    this.state = {
      s_searchFilterText: ""
    }
  }

  onchange_search_filter_text = (i_newValueString) => {
    this.setState({s_searchFilterText:i_newValueString});
  }

  onclick_view_codewords_list_in_new_tab_for_printing = () => {
    const p_excelReportsTF = this.props.p_excelReportsTF;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    const newWindow = window.open();

    newWindow.document.title = c_productStylingObj.productName + " Template Codewords List"

    const templateCodewordsExamplesArrayOfObjs = this.props.AdminMobx.create_template_codewords_examples_arrayOfObjs(p_excelReportsTF);

    var codewordsHtmlString = '<font style="font-family:sans-serif;">';
    for(let templateCodewordExampleObj of templateCodewordsExamplesArrayOfObjs) {
      if(templateCodewordExampleObj.sectionTitle !== undefined) {
        codewordsHtmlString += '<div style="border-top:solid 1px #000; padding:0.3em 0; margin-top:0.3em"><font style="font-size:22px; font-weight:bold;">' + templateCodewordExampleObj.sectionTitle + '</font></div>';
      }

      if(JSFUNC.is_array(templateCodewordExampleObj.exampleRowsArrayOfObjs)) {
        codewordsHtmlString += '<div style="border:dashed 1px #aaa; padding:0.2em">';
        for(let exampleRowObj of templateCodewordExampleObj.exampleRowsArrayOfObjs) {
          codewordsHtmlString += this.codeword_row_html_from_row_obj(exampleRowObj);
        }
        codewordsHtmlString += '</div>';
      }

      if(JSFUNC.is_array(templateCodewordExampleObj.rowsArrayOfObjs)) {
        for(let rowObj of templateCodewordExampleObj.rowsArrayOfObjs) {
          codewordsHtmlString += this.codeword_row_html_from_row_obj(rowObj);
        }
      }
    }
    codewordsHtmlString += "</font>";

    newWindow.document.body.innerHTML = codewordsHtmlString;
  }

  codeword_row_html_from_row_obj = (i_rowObj) => {
    if(i_rowObj.subtitle !== undefined) {
      return('<div style="padding-bottom:0.2em"><font style="font-size:16px; font-weight:bold; color:#333">' + i_rowObj.subtitle + '</font></div>');
    }

    const codewordsArray = JSFUNC.convert_single_or_array_to_array(i_rowObj.codewordOrCodewordsArray);
    var codewordRowHtml = '<div><font style="font-size:13px; font-style:italic;">' + i_rowObj.description + '</font></div>';
    codewordRowHtml += '<div style="margin-left:2em; margin-bottom:0.2em"><font style="font-size:13px;">';
    for(let c = 0; c < codewordsArray.length; c++) {
      if(c > 0) {
        codewordRowHtml += " or ";
      }
      codewordRowHtml += "[[" + codewordsArray[c] + "]]";
    }
    codewordRowHtml += '</font></div>';
    return(codewordRowHtml);
  }

  render() {
    const s_searchFilterText = this.state.s_searchFilterText;

    const p_excelReportsTF = this.props.p_excelReportsTF;

    const templateCodewordsExamplesArrayOfObjs = this.props.AdminMobx.c_templateCodewordsExamplesArrayOfObjs;

    const searchFilterFilledOutTF = (s_searchFilterText !== "");

    return (
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditQuestion"
        p_title="All Template Codewords"
        f_onClickCancel={this.props.f_onClose}>
        <div className="flex00a displayFlexRowVc flexWrap bgLightGray borderT1bbb borderB1bbb">
          <div className="flex00a tbMargin lrMedMargin" style={{flexBasis:"25em"}}>
            <CEGeneralReact.SearchInputTextWithClearX
              p_inputText={s_searchFilterText}
              p_placeholderText="Search Codeword List..."
              p_includeSearchIconTF={false}
              p_tabIndex={1}
              f_onChange={this.onchange_search_filter_text}
            />
          </div>
          <div className="flex11a" />
          <div className="flex00a tbPad lrMedPad">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="View Codewords List in New Tab for Printing"
              f_onClick={this.onclick_view_codewords_list_in_new_tab_for_printing}
            />
          </div>
        </div>
        <div className="flex11a yScroll yScrollBottomPad">
          {templateCodewordsExamplesArrayOfObjs.map((m_templateCodewordExampleObj, m_index) =>
            (!p_excelReportsTF || (m_templateCodewordExampleObj.inExcelReportsTF !== false)) &&
            <>
              {(m_templateCodewordExampleObj.sectionTitle !== undefined) &&
                <ViewCodewordTitle
                  p_text={m_templateCodewordExampleObj.sectionTitle}
                  p_topMarginTF={(m_index > 0)}
                />
              }
              {(JSFUNC.is_array(m_templateCodewordExampleObj.exampleRowsArrayOfObjs)) &&
                <ViewCodewordExamplesBg p_color={m_templateCodewordExampleObj.exampleBgColor}>
                  {m_templateCodewordExampleObj.exampleRowsArrayOfObjs.map((m_exampleRowObj) =>
                    <ViewCodewordSubTitleOrCodewordRow
                      p_rowObj={m_exampleRowObj}
                      p_excelReportsTF={p_excelReportsTF}
                      p_searchFilterTextLowercase={s_searchFilterText}
                      p_searchFilterFilledOutTF={searchFilterFilledOutTF}
                    />
                  )}
                </ViewCodewordExamplesBg>
              }
              {(JSFUNC.is_array(m_templateCodewordExampleObj.rowsArrayOfObjs)) &&
                m_templateCodewordExampleObj.rowsArrayOfObjs.map((m_rowObj) =>
                  <ViewCodewordSubTitleOrCodewordRow
                    p_rowObj={m_rowObj}
                    p_excelReportsTF={p_excelReportsTF}
                    p_searchFilterTextLowercase={s_searchFilterText}
                    p_searchFilterFilledOutTF={searchFilterFilledOutTF}
                  />
                )
              }
            </>
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

function ViewCodewordTitle(props) { //props: p_text, p_color, p_topMarginTF
  const topMarginTF = JSFUNC.prop_value(props.p_topMarginTF, true);

  var bgColor = "c7c1bb";
  if(props.p_color === "green") {
    bgColor = "bbc7bb";
  }

  return(
    <div
      className={"medFullPad " + ((topMarginTF) ? ("hugeTopMargin") : (""))}
      style={{background:"#" + bgColor, borderBottom:"solid 1px #eee"}}>
      <font className="font16 fontBold fontItalic">
        {props.p_text}
      </font>
    </div>
  );
}

function ViewCodewordExamplesBg(props) { //props: p_color
  var bgColor = "eee8dd";
  if(props.p_color === "green") {
    bgColor = "dde8dd";
  }

  return(
    <div style={{background:"#" + bgColor}}>
      {props.children}
    </div>
  );
}

function ViewCodewordSubTitleOrCodewordRow(props) { //props: p_rowObj, p_excelReportsTF, p_searchFilterTextLowercase, p_searchFilterFilledOutTF
  const p_rowObj = props.p_rowObj;
  const p_excelReportsTF = props.p_excelReportsTF;
  const p_searchFilterTextLowercase = props.p_searchFilterTextLowercase;
  const p_searchFilterFilledOutTF = props.p_searchFilterFilledOutTF;

  if(p_excelReportsTF && (p_rowObj.inExcelReportsTF === false)) {
    return(null);
  }

  if(p_searchFilterFilledOutTF) {
    const searchFilterMatchesTF = JSFUNC.input_lowercase_string_contains_lowercase_search_term_string_tf(p_rowObj.codewordsAndDescriptionLowercase, p_searchFilterTextLowercase);
    if(!searchFilterMatchesTF) {
      return(null);
    }
  }

  if(p_rowObj.subtitle !== undefined) {
    return(
      <div className="tbPad lrPad" style={{borderBottom:"solid 1px #eee"}}>
        <font className="font12 fontBold fontTextLighter">
          {p_rowObj.subtitle}
        </font>
      </div>
    );
  }

  return(
    <ViewCodewordRow
      p_codeword={p_rowObj.codewordOrCodewordsArray}
      p_description={p_rowObj.description}
      p_codewordsHorizontalTF={p_rowObj.codewordsHorizontalTF}
    />
  );
}

function ViewCodewordRow(props) { //props: p_codeword, p_description, p_codewordsHorizontalTF
  const codeword = props.p_codeword;
  const description = props.p_description;
  const codewordsHorizontalTF = props.p_codewordsHorizontalTF;

  const lowercaseCodewordsArray = JSFUNC.convert_single_or_array_to_array(codeword);
  const numCodewords = lowercaseCodewordsArray.length;

  var codewordsDisplayFlexString = "";
  var codewordsComponent = null;
  if(numCodewords === 1) {
    codewordsComponent = (
      <ViewCodewordSingleCodeword p_codeword={lowercaseCodewordsArray[0]} />
    );
  }
  else if(numCodewords > 1) {
    if(codewordsHorizontalTF) {
      codewordsDisplayFlexString = "displayFlexRow";
      codewordsComponent = (
        lowercaseCodewordsArray.map((m_codeword, m_index) =>
          <div className="flex00a displayFlexRowVc">
            <ViewCodewordSingleCodeword p_codeword={m_codeword} />
            {(m_index < (numCodewords - 1)) &&
              <div className="flex00a lrPad" />
            }
            <div className="flex11a" />
          </div>
        )
      );
    }
    else {
      codewordsDisplayFlexString = "displayFlexColumn";
      codewordsComponent = (
        lowercaseCodewordsArray.map((m_codeword, m_index) =>
          <div className="flex00a displayFlexRowVc">
            <ViewCodewordSingleCodeword p_codeword={m_codeword} />
            {(m_index < (numCodewords - 1)) &&
              <div className="flex00a lrPad">
                <font className="font09 fontItalic fontTextLighter">
                  {"or"}
                </font>
              </div>
            }
            <div className="flex11a" />
          </div>
        )
      );
    }
  }

  return(
    <div className="displayFlexRowVc tbPad hoverLighterBlueGradient" style={{borderBottom:"solid 1px #eee"}}>
      <div className="flex11a lrPad" style={{flexBasis:"100em"}}>
        <font className="fontItalic fontTextLight">
          {description}
        </font>
      </div>
      <div className={"flex11a " + codewordsDisplayFlexString} style={{flexBasis:"100em"}}>
        {codewordsComponent}
      </div>
    </div>
  );
}

class ViewCodewordSingleCodeword extends Component { //props: p_codeword
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      s_copiedTF: false
    }
  }

  onclick_copy_input_text_value_to_clipboard = (event) => {
    this.inputRef.current.select();
    document.execCommand('copy');
    this.setState({s_copiedTF:true});
    this.timeoutID = setTimeout(() => this.reset_to_initial_state_and_clear_timeout(), 1500);
  };

  reset_to_initial_state_and_clear_timeout = () => {
    this.setState({s_copiedTF:false});
    clearTimeout(this.timeoutID);
  }

  render() {
    const copiedTF = this.state.s_copiedTF;

    const codeword = this.props.p_codeword;

    const codewordWithBrackets = "[[" + codeword + "]]";
    const codewordDivHeight = "1.4em";

    return(
      <>
        {(copiedTF) ? (
          <div className="flex00a displayFlexColumnVc lrMedPad tbMicroPad bgDarkGrayGradient border bevelBorderDarkColors borderRadius05" style={{height:codewordDivHeight}}>
            <font className="font12 fontWhite">
              {"Copied " + codewordWithBrackets + " to clipboard"}
            </font>
          </div>
        ) : (
          <div
            className="flex00a displayFlexColumnVc lrPad tbMicroPad hoverFontUnderline breakWord cursorPointer"
            style={{height:codewordDivHeight}}
            title={"Click to copy '" + codewordWithBrackets + "' to clipboard for pasting into Templates"}
            onClick={this.onclick_copy_input_text_value_to_clipboard}>
            <font className="font12 fontTextLight">
              {codewordWithBrackets}
            </font>
          </div>
        )}
        <input
          type="text"
          style={{position:"absolute", top:"-9999px", left:"-9999px"}}
          ref={this.inputRef}
          value={codewordWithBrackets}
        />
      </>
    );
  }
}





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabGCSSOptions = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx", "UserMobx")(observer(
class AdminSubtabGCSSOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectedGcssOptionsTabDbName: "dataSources", //"dataSources", "customReasonsNotInterested", "autoUpdateOptions"
      s_selectedDataSourceLearnMoreTabDbName: "",
      s_fedCompFormCompany: this.props.UserMobx.o_userCompanyCode,
      s_fedCompFormName: this.props.UserMobx.c_userName,
      s_fedCompFormWorkEmail: this.props.UserMobx.c_userEmail,
      s_fedCompFormWorkPhone: "",
      s_fedCompFormEmailSentTF: false
    };
  }

  onselect_gcss_options_tab = (i_selectedTabDbName) => {
    this.setState({s_selectedGcssOptionsTabDbName:i_selectedTabDbName});
  }

  onselect_gcss_learn_more = (i_selectedTabDbName) => {
    const s_selectedDataSourceLearnMoreTabDbName = this.state.s_selectedDataSourceLearnMoreTabDbName;
    const updatedSelectedLearnMoreTabDbName = ((i_selectedTabDbName === s_selectedDataSourceLearnMoreTabDbName) ? ("") : (i_selectedTabDbName));
    this.setState({
      s_selectedDataSourceLearnMoreTabDbName: updatedSelectedLearnMoreTabDbName,
      s_fedCompFormEmailSentTF: false
    });
  }

  onswitch_gcss_blank_data_overwrite = (i_newValue01) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("gcss_blank_data_overwrite_01", i_newValue01, "i");
  }

  onclick_fedcomp_send_form_by_email = () => {
    const s_selectedDataSourceLearnMoreTabDbName = this.state.s_selectedDataSourceLearnMoreTabDbName;
    const s_fedCompFormCompany = this.state.s_fedCompFormCompany;
    const s_fedCompFormName = this.state.s_fedCompFormName;
    const s_fedCompFormWorkEmail = this.state.s_fedCompFormWorkEmail;
    const s_fedCompFormWorkPhone = this.state.s_fedCompFormWorkPhone;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    const selectedDataSourceLearnMoreGovWinTF = (s_selectedDataSourceLearnMoreTabDbName === "govwin");
    const selectedDataSourceLearnMoreFedCompTF = (s_selectedDataSourceLearnMoreTabDbName === "fedcomp");

    var formCompanyName = "Deltek";
    var toEmailsComma = "skip@bitsolutionsllc.com";
    var ccEmailsComma = "";
    if(selectedDataSourceLearnMoreFedCompTF) {
      formCompanyName = "Federal Compass";
      toEmailsComma = "skip@bitsolutionsllc.com";
      ccEmailsComma = "";
    }

    const subject = "New interest in " + formCompanyName + " & " + c_productStylingObj.productName + " integration!";
    
    var bodyHtml = "This " + c_productStylingObj.productName + " User below is interested in your service.<br /><br />";
    bodyHtml += "New interest in " + formCompanyName + " & " + c_productStylingObj.productName + " integration<br /><br />";
    bodyHtml += "Company: " + s_fedCompFormCompany + "<br/>";
    bodyHtml += "Name: " + s_fedCompFormName + "<br/>";
    bodyHtml += "Work Email: " + s_fedCompFormWorkEmail + "<br/>";
    bodyHtml += "Work Phone: " + s_fedCompFormWorkPhone;

    const fromEmail = "";
    const fromFullName = "";
    const notificationClickActionString = "";

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabGCSSOptions", "onclick_fedcomp_send_form_by_email", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_email(toEmailsComma, ccEmailsComma, subject, bodyHtml, fromEmail, fromFullName, notificationClickActionString);
    C_CallPhpTblUID.execute();

    this.setState({s_fedCompFormEmailSentTF:true});
  }

  onchange_fedcomp_form_company = (i_newValueString) => { this.setState({s_fedCompFormCompany:i_newValueString}); }
  onchange_fedcomp_form_name = (i_newValueString) => { this.setState({s_fedCompFormName:i_newValueString}); }
  onchange_fedcomp_form_work_email = (i_newValueString) => { this.setState({s_fedCompFormWorkEmail:i_newValueString}); }
  onchange_fedcomp_form_work_phone = (i_newValueString) => { this.setState({s_fedCompFormWorkPhone:i_newValueString}); }

  render() {
    const s_selectedGcssOptionsTabDbName = this.state.s_selectedGcssOptionsTabDbName;
    const s_selectedDataSourceLearnMoreTabDbName = this.state.s_selectedDataSourceLearnMoreTabDbName;
    const s_fedCompFormCompany = this.state.s_fedCompFormCompany;
    const s_fedCompFormName = this.state.s_fedCompFormName;
    const s_fedCompFormWorkEmail = this.state.s_fedCompFormWorkEmail;
    const s_fedCompFormWorkPhone = this.state.s_fedCompFormWorkPhone;
    const s_fedCompFormEmailSentTF = this.state.s_fedCompFormEmailSentTF;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_gcssGovWinCredentialsArrayOfObjs = this.props.AdminMobx.c_gcssGovWinCredentialsArrayOfObjs;
    const c_gcssFedCompCredentialsArrayOfObjs = this.props.AdminMobx.c_gcssFedCompCredentialsArrayOfObjs;
    const c_gcssNotInterestedReasonsArrayOfObjs = this.props.AdminMobx.c_gcssNotInterestedReasonsArrayOfObjs;
    const c_bitGovConSmartSearchTF = this.props.DatabaseMobx.c_bitGovConSmartSearchTF;
    const c_companyGcssDataSourceSamShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceSamShownTF;
    const c_companyGcssDataSourceGovWinShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceGovWinShownTF;
    const c_companyGcssDataSourceFedCompShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceFedCompShownTF;
    const c_companyGcssBlankDataOverwrite01 = this.props.DatabaseMobx.c_companyGcssBlankDataOverwrite01;

    if(!c_bitGovConSmartSearchTF) {
      return(
        <div className="medFullPad">
          <font className="font12 fontItalic fontTextLight">
            {"Your company has not purchased the GovCon Smart Search (GCSS) Module. Please contact BIT Solutions if you are interested adding this module to your " + c_productStylingObj.productName + " suite of government tools."}
          </font>
        </div>
      );
    }

    const selectedGcssOptionsTabIsDataSourcesTF = (s_selectedGcssOptionsTabDbName === "dataSources");
    const selectedGcssOptionsTabIsCustomReasonsNotInterestedTF = (s_selectedGcssOptionsTabDbName === "customReasonsNotInterested");
    const selectedGcssOptionsTabIsAutoUpdateOptionsTF = (s_selectedGcssOptionsTabDbName === "autoUpdateOptions");
    const selectedDataSourceLearnMoreSamTF = (s_selectedDataSourceLearnMoreTabDbName === "sam");
    const selectedDataSourceLearnMoreGovWinTF = (s_selectedDataSourceLearnMoreTabDbName === "govwin");
    const selectedDataSourceLearnMoreFedCompTF = (s_selectedDataSourceLearnMoreTabDbName === "fedcomp");

    var gcssOptionsContentComponent = null;
    var dataSourceAdContentsComponent = null;
    if(selectedGcssOptionsTabIsDataSourcesTF) {
      gcssOptionsContentComponent = (
        <>
          <div className="displayFlexRow flexWrap bgDarkGray">
            <GCSSSingleDataSourceInfoBox
              p_dataSourceDisplayName="SAM.gov"
              p_logoFileNameAndExt="gcss_ds_sam_70px280px.png"
              p_dataSourceShownTF={c_companyGcssDataSourceSamShownTF}
              p_dataSourceShownCompanyColumnDbName="gcss_data_source_sam_shown_01"
              p_switchOnBgColor="cc202a"
              p_learnMoreTabDbName="sam"
              p_selectedLearnMoreTabDbName={s_selectedDataSourceLearnMoreTabDbName}
              p_learnMoreSelectedBgClass="bgDarkGrayGradient"
              f_onClickLearnMore={this.onselect_gcss_learn_more}>
              <GCSSSingleDataSourceInfoName p_name="SAM.gov Integration" />
              <div className="displayFlexColumnHcVc smallBottomMargin">
                <div className="flex00a smallBottomMargin lrMedMargin border1ddd borderRadius10 tbPad lrMedPad textCenter" style={{width:"25em"}}>
                  <font className="font13 fontTextLighter">
                    {"Included free with " + c_productStylingObj.productName + " GovCon Smart Search module purchase"}
                  </font>
                </div>
              </div>
            </GCSSSingleDataSourceInfoBox>
            <GCSSSingleDataSourceInfoBox
              p_dataSourceDisplayName="GovWin"
              p_logoFileNameAndExt="gcss_ds_govwin_70px280px.png"
              p_dataSourceShownTF={c_companyGcssDataSourceGovWinShownTF}
              p_dataSourceShownCompanyColumnDbName="gcss_data_source_govwin_shown_01"
              p_switchOnBgColor="0069aa"
              p_learnMoreTabDbName="govwin"
              p_selectedLearnMoreTabDbName={s_selectedDataSourceLearnMoreTabDbName}
              p_learnMoreSelectedBgClass="bgDarkGrayGradient"
              f_onClickLearnMore={this.onselect_gcss_learn_more}>
              <GCSSSingleDataSourceInfoName p_name="Deltek GovWin IQ Integration" />
              <div className="flex00a smallBottomMargin lrMedMargin">
                <font className="fontItalic">
                  {"After purchasing a GovWin IQ license through Deltek, fill in the 4 fields below to active integration through the " + c_productStylingObj.productName + " GCSS system. Make sure to come back and update these if they are changed."}
                </font>
              </div>
              <GCSSGovWinMultipleCredentialsEditor
                p_dataSourceSam0GovWin1={1}
                p_gcssCredentialsArrayOfObjs={c_gcssGovWinCredentialsArrayOfObjs}
              />
            </GCSSSingleDataSourceInfoBox>
            <GCSSSingleDataSourceInfoBox
              p_dataSourceDisplayName="Federal Compass"
              p_logoFileNameAndExt="gcss_ds_fedcomp_70px280px.png"
              p_dataSourceShownTF={c_companyGcssDataSourceFedCompShownTF}
              p_dataSourceShownCompanyColumnDbName="gcss_data_source_fedcomp_shown_01"
              p_switchOnBgColor="ffbd29"
              p_learnMoreTabDbName="fedcomp"
              p_selectedLearnMoreTabDbName={s_selectedDataSourceLearnMoreTabDbName}
              p_learnMoreSelectedBgClass="bgDarkGrayGradient"
              f_onClickLearnMore={this.onselect_gcss_learn_more}>
              <GCSSSingleDataSourceInfoName p_name="Federal Compass Integration" />
              <div className="smallBottomMargin lrMedMargin">
                <font className="fontItalic">
                  {"After purchasing a license through Federal Compass (contact form to fill out in the 'Learn More'), fill in the API key below to active integration through the " + c_productStylingObj.productName + " GCSS system."}
                </font>
              </div>
              <GCSSGovWinMultipleCredentialsEditor
                p_dataSourceSam0GovWin1={2}
                p_gcssCredentialsArrayOfObjs={c_gcssFedCompCredentialsArrayOfObjs}
              />
            </GCSSSingleDataSourceInfoBox>
          </div>
        </>
      );

      var integrationEmailFormComponent = null;
      if(selectedDataSourceLearnMoreGovWinTF || selectedDataSourceLearnMoreFedCompTF) {
        var formCompanyName = "Deltek";
        var formFontColor = "19d";
        var formSendButtonBgColor = "29bdff";
        if(selectedDataSourceLearnMoreFedCompTF) {
          formCompanyName = "Federal Compass";
          formFontColor = "d91";
          formSendButtonBgColor = "ffbd29";
        }

        const fedCompFormInputTextStyleObj = {width:"100%", border:"solid 1px #bdd", borderRadius:"0.2em", background:"#ecf1f4", padding:"0.7em"};

        integrationEmailFormComponent = (
          <div className="flex00a" style={{width:"30em", marginLeft:"1em"}}>
            <div className="border1bbb borderRadius05 bigFullPad">
              <div className="textCenter">
                <font className="font14 fontBold" style={{color:"#" + formFontColor}}>
                  {"Tell " + formCompanyName + " you're interested in integration with " + c_productStylingObj.productName + "!"}
                </font>
              </div>
              <FedCompAdFormLabel>{"Company"}</FedCompAdFormLabel>
              <LibraryReact.Text
                p_value={s_fedCompFormCompany}
                p_styleObj={fedCompFormInputTextStyleObj}
                p_tabIndex={1}
                f_onChange={this.onchange_fedcomp_form_company}
              />
              <FedCompAdFormLabel>{"Name"}</FedCompAdFormLabel>
              <LibraryReact.Text
                p_value={s_fedCompFormName}
                p_styleObj={fedCompFormInputTextStyleObj}
                p_tabIndex={2}
                f_onChange={this.onchange_fedcomp_form_name}
              />
              <FedCompAdFormLabel>{"Work Email"}</FedCompAdFormLabel>
              <LibraryReact.Text
                p_value={s_fedCompFormWorkEmail}
                p_styleObj={fedCompFormInputTextStyleObj}
                p_tabIndex={3}
                f_onChange={this.onchange_fedcomp_form_work_email}
              />
              <FedCompAdFormLabel>{"Work Phone (optional)"}</FedCompAdFormLabel>
              <LibraryReact.Text
                p_value={s_fedCompFormWorkPhone}
                p_styleObj={fedCompFormInputTextStyleObj}
                p_tabIndex={4}
                f_onChange={this.onchange_fedcomp_form_work_phone}
              />
              <div className="displayFlexColumnHcVc hugeTopMargin">
                {(s_fedCompFormEmailSentTF) ? (
                  <font className="fontBold" style={{color:"#" + formFontColor}}>
                    {"Email sent! Thank you for your interest."}
                  </font>
                ) : (
                  <div
                    className="flex00a displayFlexColumnHcVc border bevelBorderColors borderRadius05 hoverLighterBlueGradient textCenter cursorPointer"
                    style={{width:"15em", height:"4em", backgroundColor:"#" + formSendButtonBgColor}}
                    onClick={this.onclick_fedcomp_send_form_by_email}>
                    <font className="font14 fontBold fontWhite">
                      {"Email Us!"}
                    </font>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }

      if(selectedDataSourceLearnMoreSamTF) {
        const samUseSiteToBulletLinesArray = [
          "Register to do business with the U.S. Government",
          "Update, renew, or check the status of your entity registration",
          "Search for entity registration and exclusion records",
          "Search for assistance listings (formerly CFDA.gov), wage determinations (formerly WDOL.gov), contract opportunities (formerly FBO.gov), and contract data reports (formerly part of FPDS.gov).",
          "View and submit BioPreferred and Service Contract Reports",
          "Access publicly available award data via data extracts and system accounts"
        ];

        const samFPDSBulletLinesArray = [
          "Standard Reports contain information about contract activity and are formatted to meet the needs of both government and public users. Data in Standard Reports can be filtered and sorted to locate information of interest.",
          "Static Reports are prepared on a fiscal year basis for the public and are available as prepared for download. There are currently four Static Reports for Contract Data represented in the Data Bank section.",
          "Administrative Reports are configurable and available to Federal users assigned the appropriate roles.",
          "Ad Hoc Reports offer the ability to select data fields from the database to run a query. Ad hoc reports are an advanced feature recommended for users who are familiar with the data parameters and knowledge of the sophisticated reporting tool."
        ];

        dataSourceAdContentsComponent = (
          <GCSSAdContainer
            key="sam"
            p_logoFileLoc={c_productWebsiteGraphicsSubfolderAddress + "/gcss_sam_whitebg.png"}
            p_logoWidthPx={210}
            p_logoHeightPx={57}
            p_productName="SAM.gov"
            p_webAddress="https://sam.gov/content/home">
            <div style={{height:"1em", background:"linear-gradient(#ccc,#fff)"}} />
            <div className="tbPad lrMedPad" style={{maxWidth:"80em"}}>
              <SAMAdTitle>{"About This Site"}</SAMAdTitle>
              <SAMAdHeader>{"What is SAM.gov?"}</SAMAdHeader>
              <SAMAdText>{"The System for Award Management (SAM.gov) is an official website of the U.S. Government. There is no cost to use SAM.gov. You can use this site to:"}</SAMAdText>
              <CEGeneralReact.BulletList p_linesArray={samUseSiteToBulletLinesArray} p_fontClass="fontTextLight" p_dashFontClass="fontTextLight" />
              <SAMAdHeader>{"What is the IAE?"}</SAMAdHeader>
              <SAMAdText>{"The Integrated Award Environment (IAE) is a government-wide initiative administered by GSA’s Federal Acquisition Service (FAS). We manage the suite of systems, including SAM.gov, that are an essential part of the federal awards lifecycle. The goal of the IAE is to continue to evolve and integrate the existing shared portfolio of multiple online systems used for awarding and administering federal financial assistance (i.e., grants, loans) and contracts."}</SAMAdText>
              <SAMAdHeader>{"What is an entity?"}</SAMAdHeader>
              <SAMAdText>{"At SAM.gov, we use the term “entity” to refer to prime contractors, organizations or individuals applying for assistance awards, those receiving loans, sole proprietors, corporations, partnerships, and any U.S. federal government agencies desiring to do business with the government. Entity can also refer to a party that has been suspended or debarred, is covered by a prohibition or restriction, or is otherwise excluded from doing business with the government."}</SAMAdText>
              <SAMAdHeader>{"What are entity registrations?"}</SAMAdHeader>
              <SAMAdText>{"You register your entity to do business with the U.S. federal government by completing the entity registration process at SAM.gov. Active registration in SAM.gov provides your entity the ability to apply for federal grants or loans or bid on government contracts."}</SAMAdText>
              <SAMAdHeader>{"What are exclusions?"}</SAMAdHeader>
              <SAMAdText>{"An exclusion identifies a party excluded from receiving federal contracts, certain subcontracts, and certain types of federal financial and non-financial assistance and benefits. If your entity or any of its principals are subject to an active exclusion, it means your entity is currently debarred, suspended, proposed for debarment, or declared ineligible for the award of contracts by any federal agency. Awarding officials check SAM.gov to see if an exclusion exists for your entity. Always contact the issuing agency if you have questions about an exclusion record."}</SAMAdText>
              <SAMAdHeader>{"What is entity reporting?"}</SAMAdHeader>
              <SAMAdText>{"Contractors may be required to submit periodic reports on contracts that meet certain criteria. Two examples are Service Contract Reporting (SCR) and BioPreferred Reporting. Under SCR, contractors awarded cost-reimbursement, time-and-materials, and labor hour service contracts and orders at or above the simplified acquisition threshold must report information about the amount invoiced, labor hours expended, and subcontractor labor hours to help build a governmentwide service contract inventory. Under the BioPreferred program, contractors awarded service or construction contracts must report purchases of biobased products in the execution of those contracts."}</SAMAdText>
              <SAMAdHeader>{"What are assistance listings?"}</SAMAdHeader>
              <SAMAdText>{"The federal government provides assistance to the American public in the form of projects, services, and activities. It supports a broad range of assistance listings—such as education, health care, research, infrastructure, economic development, and other assistance listings—through grants, loans, scholarships, insurance, and other types of financial assistance."}</SAMAdText>
              <SAMAdText>{"SAM.gov provides detailed, public descriptions of federal assistance listings available to state and local governments (including the District of Columbia); federally recognized Indian tribal governments, territories (and possessions) of the United States; domestic public, quasi-public, and private profit and nonprofit organizations and institutions; specialized groups, and individuals."}</SAMAdText>
              <SAMAdText>{"As an entity looking for federal assistance, you can conduct your preliminary planning using SAM.gov. Browse assistance listings across all government agencies to form a “big picture” of your options."}</SAMAdText>
              <SAMAdText>{"Each assistance listing is associated with a unique five-digit AL (Assistance Listing) number. Once you identify a federal assistance listing that you’re interested in, you can link directly to grant opportunities on Grants.gov or follow up with that specific agency using the contact information provided."}</SAMAdText>
              <SAMAdHeader>{"What are wage determinations?"}</SAMAdHeader>
              <SAMAdText>{"Wage determinations are legally mandated wage rates and fringe benefits that must be paid by federal contractors to their employees based on the county where the work is performed. The U.S. Department of Labor determines wage rates and fringe benefits in a given area and shall be the minimum paid by contractors and subcontractors to laborers and mechanics on federal contracts."}</SAMAdText>
              <SAMAdHeader>{"What are contract opportunities?"}</SAMAdHeader>
              <SAMAdText>{"Contract opportunities are listings of federal business opportunities greater than $25,000, including synopses of proposed contract actions, solicitations, and associated information that can be accessed electronically by the public. Organizations registered inSAM.gov may compete to be awarded federal contracts."}</SAMAdText>
              <SAMAdHeader>{"What contract data reports are available?"}</SAMAdHeader>
              <SAMAdText>{"Contract data reports provide detailed information on awarded contracts using data directly from the Federal Data Procurement System (FPDS). The report data can be aggregated by various attributes and can be downloaded for detailed analysis. There are four categories of contract data reports available:"}</SAMAdText>
              <CEGeneralReact.BulletList p_linesArray={samFPDSBulletLinesArray} p_fontClass="fontTextLight" p_dashFontClass="fontTextLight" />
              <SAMAdHeader>{"What is the federal hierarchy?"}</SAMAdHeader>
              <SAMAdText>{"The federal hierarchy is the authoritative source for managing and referencing federal funding and awarding organizations. It is a directory that organizes federal government users and establishes relationships between each department or independent agency’s sub-tiers and its offices."}</SAMAdText>
              <SAMAdHeader>{"What design standards have been used to create the user interface at SAM.gov?"}</SAMAdHeader>
              <SAMAdText>{"The design of this site implements the most current U.S. Web Design System (USWDS) standards and best practices mandated by the 21st Century Integrated Digital Experience Act (IDEA). This site is continually updated and improved based on user feedback and federal requirements."}</SAMAdText>
            </div>
          </GCSSAdContainer>
        );
      }
      else if(selectedDataSourceLearnMoreGovWinTF) {
        dataSourceAdContentsComponent = (
          <GCSSAdContainer
            key="govwin"
            p_logoFileLoc={c_productWebsiteGraphicsSubfolderAddress + "/gcss_deltek_whitebg.png"}
            p_logoWidthPx={193}
            p_logoHeightPx={57}
            p_productName="GovWin"
            p_webAddress="https://www.deltek.com/en/government-contracting/govwin/federal">
            <div className="displayFlexRow">
              <div className="flex11a" style={{maxWidth:"80em"}}>
                <div className="hugeFullPad">
                  <GovWinAdTitleAndLine>{"FEDERAL MARKET"}</GovWinAdTitleAndLine>
                  <GovWinAdHeader>{"GOVWIN IQ FOR FEDERAL CONTRACTORS"}</GovWinAdHeader>
                  <GovWinAdText>{"The federal government contracting market is vibrant and healthy with the top opportunities for FY 2023 alone valued at more than $750B. Within this favorable & lucrative environment, contractors can win more business by using GovWin IQ. GovWin IQ serves contractors that are selling to the federal government by providing the earliest awareness of new opportunities, deep planning intelligence, potential teaming partnerships, and the strategic insight that businesses need to get ahead of the competition and win more federal government contracts."}</GovWinAdText>
                </div>
                <div className="hugeFullPad bgLighterGray">
                  <div className="textCenter">
                    <font className="font16 fontDarkBlue">
                      {"“Since becoming a GovWin IQ member, we realized very quickly why [GovWin’s] product and supporting services are known as the ‘gold standard’ for research and tracking of the government market.”"}
                    </font>
                  </div>
                  <div className="bigTopMargin textCenter">
                    <font className="font12 fontDarkBlue">
                      {" - Development Director, IMT, LLC, A Mission Essential Company"}
                    </font>
                  </div>
                </div>
                <div className="hugeFullPad">
                  <GovWinAdTitleAndLine>{"HOW TO COMPETE"}</GovWinAdTitleAndLine>
                  <GovWinAdText>{"Understanding the federal market is key to being successful at federal government contracting, whether you are an experienced vendor or a new entrant. GovWin IQ provides key insights and opportunities to help you navigate this complex, cumbersome world."}</GovWinAdText>
                </div>
                <div className="hugeFullPad bgLighterGray">
                  <GovWinAdTitleAndLine>{"BENEFITS"}</GovWinAdTitleAndLine>
                  <div className="displayFlexRow textCenter">
                    <div className="flex11a lrMedMargin">
                      <GovWinAdHeader>{"PLAN BEYOND THE PROPOSAL"}</GovWinAdHeader>
                      <GovWinAdText>{"Discover opportunities months, or even years, before they go to bid in the form of forecast pre-RFP intelligence."}</GovWinAdText>
                    </div>
                    <div className="flex11a lrMedMargin">
                      <GovWinAdHeader>{"FIND A QUALITY PARTNER"}</GovWinAdHeader>
                      <GovWinAdText>{"Identify quality teaming partners in a specific location, level of experience, or set-aside category in our exclusive GovWin Network™ of contacts."}</GovWinAdText>
                    </div>
                    <div className="flex11a lrMedMargin">
                      <GovWinAdHeader>{"CAPTURE MARKET INSIGHTS"}</GovWinAdHeader>
                      <GovWinAdText>{"Detect market potential based on data analysis conducted by our research support team along with easy-to-use data breakdown tools within GovWin IQ."}</GovWinAdText>
                    </div>
                  </div>
                </div>
                <div className="hugeFullPad">
                  <GovWinAdTitleAndLine>{"CANADIAN MARKET"}</GovWinAdTitleAndLine>
                  <GovWinAdText>{"New! We have expanded our coverage into the Canadian government contracting markets. Grow your footprint beyond the U.S. into the Canadian provincial, MASH (municipal, academic institutes, schools, health & social) and federal government markets with the same level of centralized coverage and early visibility you expect from GovWin."}</GovWinAdText>
                </div>
              </div>
              {integrationEmailFormComponent}
            </div>
          </GCSSAdContainer>
        );
      }
      else if(selectedDataSourceLearnMoreFedCompTF) {
        dataSourceAdContentsComponent = (
          <GCSSAdContainer
            key="fedcomp"
            p_logoFileLoc={c_productWebsiteGraphicsSubfolderAddress + "/gcss_ds_fedcomp_70px280px.png"}
            p_logoWidthPx={280}
            p_logoHeightPx={70}
            p_productName="Federal Compass"
            p_webAddress="https://federalcompass.com/">
            <div className="displayFlexRow">
              <div className="flex11a" style={{maxWidth:"80em"}}>
                <div className="hugeFullPad" style={{maxWidth:"80em", background:"#172432"}}>
                  <FedCompAdTitleWhite>{"Plan. Execute. Capture."}</FedCompAdTitleWhite>
                  <FedCompAdTextWhite>{"Federal Compass is a SaaS solution that enables strategy, growth, and process for federal contractors and the federal market."}</FedCompAdTextWhite>
                </div>
                <div className="hugeFullPad" style={{maxWidth:"80em", background:"#ecf1f4"}}>
                  <FedCompAdTitleBlue>{"Federal Opportunities & Pipeline Tools"}</FedCompAdTitleBlue>
                  <FedCompAdTextBlue>{"With the largest database of federal pre-RFP and recompete opportunities, we have you covered. Combine that with rich pipeline tracking, collaboration and management tools built exclusively for Federal contractors."}</FedCompAdTextBlue>
                </div>
                <div className="hugeFullPad">
                  <FedCompAdTitleBlue>{"Personalized Markets"}</FedCompAdTitleBlue>
                  <FedCompAdTextBlue>{"Our market-centric platform adds context to every pursuit. Every opportunity, every report, tailored specifically to you."}</FedCompAdTextBlue>
                </div>
                <div className="hugeFullPad" style={{maxWidth:"80em", background:"#ecf1f4"}}>
                  <FedCompAdTitleBlue>{"Award Research & Market Intelligence"}</FedCompAdTitleBlue>
                  <FedCompAdTextBlue>{"Find gaps in the market, identify key competitors and teaming partners, evaluate performance, and analyze pathways to new business. *including prime and subcontract awards"}</FedCompAdTextBlue>
                </div>
                <div className="hugeFullPad" style={{maxWidth:"80em", background:"#172432"}}>
                  <FedCompAdTitleWhite>{"Let's get started today"}</FedCompAdTitleWhite>
                  <FedCompAdTextWhite>{"If you do business with the Federal Government, you need Federal Compass."}</FedCompAdTextWhite>
                </div>
              </div>
              {integrationEmailFormComponent}
            </div>
          </GCSSAdContainer>
        );
      }
    }
    else if(selectedGcssOptionsTabIsCustomReasonsNotInterestedTF) {
      const gcssNotInterestedReasonsAedTableObj = {
        tblName: "tbl_a_gcss_not_interested_reasons",
        dragToResortFieldDbName: "sort",
        initialSortedFieldDbName: "sort",
        tableWidth: "50em",
        itemName: "GCSS Not Interested Reason",
        deleteItemNameFieldDbName: "name",
        fieldsArrayOfObjs: [
          {width:"100em", dbName:"name", displayName:"Reason", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
          {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
        ]
      };

      gcssOptionsContentComponent = (
        <div className="medFullPad">
          <div className="smallBottomMargin">
            <font className="font13 fontBold fontBlue">
              {"Create Custom 'Reasons Not Interested' for GCSS"}
            </font>
          </div>
          <div className="smallBottomMargin">
            <font className="fontItalic">
              {"When an opportunity from GCSS is marked as 'Not Interested', you can select one of these created Reasons below to show other " + c_productStylingObj.productName + " Users why you believe this deal is not relevant to pursue"}
            </font>
          </div>
          <AddEditDeleteTable
            p_dataArrayOfObjs={c_gcssNotInterestedReasonsArrayOfObjs}
            p_aedTableObj={gcssNotInterestedReasonsAedTableObj}
          />
        </div>
      );
    }
    else if(selectedGcssOptionsTabIsAutoUpdateOptionsTF) {
      const gcssBlankDataOverwriteOption0 = "[default] Ignore updates of fields from government data sources if the value is blank, retain value in " + c_productStylingObj.productName;
      const gcssBlankDataOverwriteOption1 = "Always copy every new value from a government update overwriting values in " + c_productStylingObj.productName + ", even if the new value is blank";
      const gcssBlankDataOverwriteVerticalSwitchOptionsHeightEm = 2;

      gcssOptionsContentComponent = (
        <div className="medFullPad">
          <div className="smallBottomMargin">
            <font className="font13 fontBold fontBlue">
              {"GCSS Option for handling blank values from Government Data Source updates"}
            </font>
          </div>
          <div style={{maxWidth:"65em"}}>
            <div className="smallBottomMargin">
              <font className="fontTextLight">
                {"Each Capture imported from a government data source (SAM.gov, GovWin, etc) into " + c_productStylingObj.productName + " continues to check back on that opportunity looking for updates from the government, and will overwrite those updated values into " + c_productStylingObj.productName + "."}
              </font>
            </div>
            <div className="smallBottomMargin">
              <font className="fontTextLight">
                {"Using Proposal Due Date as an example, for many opportunities, the government will provide this date, only to in a future update provide a blank date of '0000-00-00'. During this time, your company may have gathered other intel and entered in your own revised or predicted date into Proposal Due Date in " + c_productStylingObj.productName + ", which would be overwritten by the blank value."}
              </font>
            </div>
            <div className="smallBottomMargin">
              <font className="fontTextLight">
                {"This switch gives you the control to either not perform updates on fields when the government value is 'blank', or to always have the government value (blank or not) overwritten into the imported Capture."}
              </font>
            </div>
            <div className="smallBottomMargin">
              <font className="fontItalic fontTextLight">
                {"[Note: this switch only applies to GCSS imported Captures where a more general switch 'GCSS Automatic Updates Overwrite Data?' can be turned on or off. If this field is off, then no values are ever overwritten from the government after import into " + c_productStylingObj.productName + "]"}
              </font>
            </div>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("GCSS Blank Data Overwrite Option", [0, 1], false, [gcssBlankDataOverwriteOption0, gcssBlankDataOverwriteOption1], gcssBlankDataOverwriteVerticalSwitchOptionsHeightEm)}
              p_valueRaw={c_companyGcssBlankDataOverwrite01}
              f_onChangeOrOnSelect={this.onswitch_gcss_blank_data_overwrite}
            />
          </div>
        </div>
      );
    }

    return(
      <>
        <div className="flex00a bgLightGray smallFullPad borderB1bbb">
          <CEGeneralReact.TabsList
            p_tabDbNamesArray={["dataSources", "customReasonsNotInterested", "autoUpdateOptions"]}
            p_tabDisplayNamesArray={["GCSS Data Sources", "GCSS Reasons Not Interested", "GCSS Automatic Update Options"]}
            p_selectedTabDbName={s_selectedGcssOptionsTabDbName}
            p_tabHeight="2.4em"
            p_textMaxHeight="2em"
            p_tabWidth="17em"
            p_selectedBgClass="bgGreenGradient"
            p_selectedFontClass="fontBold fontAlmostWhite"
            p_unselectedBgClass="bgLightGrayGradient"
            p_unselectedFontClass="fontTextLight"
            p_canEditTF={true}
            p_rowFlexWrapTF={true}
            p_borderRadiusClass="borderRadius05"
            f_onSelect={this.onselect_gcss_options_tab}
          />
        </div>
        <div className={"flex11a yScroll " + ((selectedGcssOptionsTabIsDataSourcesTF) ? ("bgLightGray") : (""))}>
          {gcssOptionsContentComponent}
          {dataSourceAdContentsComponent}
        </div>
      </>
    );
  }
}));

function GCSSAdContainer(props) { //props: p_logoFileLoc, p_logoWidthPx, p_logoHeightPx, p_productName, p_webAddress, children
  const p_logoFileLoc = props.p_logoFileLoc;
  const p_logoWidthPx = props.p_logoWidthPx;
  const p_logoHeightPx = props.p_logoHeightPx;
  const p_productName = props.p_productName;
  const p_webAddress = props.p_webAddress;

  return(
    <div className="medFullMargin boxShadowDarkGray bgWhite" style={{border:"solid 2px #889"}}>
      <div className="displayFlexRow tbPad lrMedPad">
        <div className="flex00a">
          <div className="border bevelBorderColors">
            <div className="displayFlexColumn" style={{width:p_logoWidthPx, height:p_logoHeightPx}}>
              <LibraryReact.Image p_src={p_logoFileLoc} p_alt={p_productName} />
            </div>
          </div>
        </div>
        <div className="flex11a lrMedMargin">
          <font className="fontItalic fontTextLight">
            {"From the official " + p_productName + " website " + p_webAddress}
          </font>
        </div>
      </div>
      {props.children}
    </div>
  );
}

function SAMAdTitle(props) { return(<div className="medBottomMargin"><font className="font20 fontBold fontTextLighter">{props.children}</font></div>); }
function SAMAdHeader(props) { return(<div className="bigTopMargin smallBottomMargin"><font className="font11 fontBold fontTextLight">{props.children}</font></div>); }
function SAMAdText(props) { return(<div className="smallBottomMargin"><font className="fontTextLight">{props.children}</font></div>); }

function GovWinAdTitleAndLine(props) { return(<><div className="medBottomMargin"><font className="font14 fontDarkBlue">{props.children}</font></div><div className="bigBottomMargin borderT1bbb" /></>); }
function GovWinAdHeader(props) { return(<div className="smallBottomMargin"><font className="font12 fontDarkBlue">{props.children}</font></div>); }
function GovWinAdText(props) { return(<div className="smallBottomMargin"><font className="fontTextLight">{props.children}</font></div>); }

function FedCompAdTitleWhite(props) { return(<div className="bigBottomMargin"><font className="font25 fontBold fontWhite">{props.children}</font></div>); }
function FedCompAdTextWhite(props) { return(<div className=""><font className="font13 fontWhite">{props.children}</font></div>); }
function FedCompAdTitleBlue(props) { return(<div className="bigBottomMargin"><font className="font20 fontBold fontDarkBlue">{props.children}</font></div>); }
function FedCompAdTextBlue(props) { return(<div className=""><font className="font13 fontDarkBlue">{props.children}</font></div>); }
function FedCompAdFormLabel(props) { return(<div className="bigTopMargin smallBottomMargin"><font className="font12 fontDarkBlue">{props.children}</font></div>); }


const GCSSSingleDataSourceInfoBox = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class GCSSSingleDataSourceInfoBox extends Component { //props: p_dataSourceDisplayName, p_logoFileNameAndExt, p_dataSourceShownTF, p_dataSourceShownCompanyColumnDbName, p_switchOnBgColor, p_learnMoreTabDbName, p_selectedLearnMoreTabDbName, p_learnMoreSelectedBgClass, f_onClickLearnMore, children
  onswitch_data_source_shown = () => {
    const p_dataSourceShownTF = this.props.p_dataSourceShownTF;
    const p_dataSourceShownCompanyColumnDbName = this.props.p_dataSourceShownCompanyColumnDbName;
    const updatedDataSourceShown01 = ((p_dataSourceShownTF) ? (0) : (1));
    this.props.AdminMobx.a_update_tbl_a_company_fields(p_dataSourceShownCompanyColumnDbName, updatedDataSourceShown01, "i");
  }

  onclick_learn_more_button = () => {
    const p_learnMoreTabDbName = this.props.p_learnMoreTabDbName;

    if(JSFUNC.is_function(this.props.f_onClickLearnMore)) {
      this.props.f_onClickLearnMore(p_learnMoreTabDbName);
    }
  }

  render() {
    const p_dataSourceDisplayName = this.props.p_dataSourceDisplayName;
    const p_logoFileNameAndExt = this.props.p_logoFileNameAndExt;
    const p_dataSourceShownTF = this.props.p_dataSourceShownTF;
    const p_dataSourceShownCompanyColumnDbName = this.props.p_dataSourceShownCompanyColumnDbName;
    const p_switchOnBgColor = this.props.p_switchOnBgColor;
    const p_learnMoreTabDbName = this.props.p_learnMoreTabDbName;
    const p_selectedLearnMoreTabDbName = this.props.p_selectedLearnMoreTabDbName;
    const p_learnMoreSelectedBgClass = this.props.p_learnMoreSelectedBgClass;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    const isSelectedTF = (p_learnMoreTabDbName === p_selectedLearnMoreTabDbName);

    return(
      <div className="flex00a displayFlexColumn smallFullMargin border1bbb bgLightesterGray" style={{flexBasis:"52em", height:"35em"}}>
        <div className="flex00a displayFlexRow">
          <div className="flex00a">
            <div className="border bevelBorderColors">
              <div className="displayFlexColumn" style={{width:"280px", height:"70px"}}>
                <LibraryReact.Image p_src={c_productWebsiteGraphicsSubfolderAddress + "/" + p_logoFileNameAndExt} p_alt={p_dataSourceDisplayName} />
              </div>
            </div>
          </div>
          <div className="flex11a" />
          <div className="flex00a displayFlexColumnHcVc" style={{marginRight:"1em"}}>
            <div className="flex00a displayFlexColumnHcVc border1ddd borderRadius05 bgLighterGray tbPad" style={{width:"15em"}}>
              <div className="textCenter" style={{width:"14em"}}>
                <font className="">
                  {"Show " + p_dataSourceDisplayName + " as GCSS data source option"}
                </font>
              </div>
              <div className="microTopMargin">
                <LibraryReact.Switch
                  p_isOnTF={p_dataSourceShownTF}
                  p_sizeEm={4}
                  p_onColor={p_switchOnBgColor}
                  p_title={"Turn this switch off to hide " + p_dataSourceDisplayName + " as a data source option to select within the GovCon Smart Search tab for " + c_productStylingObj.productName + " Users"}
                  f_onClick={this.onswitch_data_source_shown}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex11a displayFlexColumn">
          {this.props.children}
        </div>
        <div className="flex00a displayFlexColumnHcVc tbPad">
          <div
            className={"flex00a displayFlexColumnHcVc border bevelBorderColors borderRadius05 " + ((isSelectedTF) ? (p_learnMoreSelectedBgClass) : ("bgLightGrayGradient")) + " hoverLighterBlueGradient cursorPointer textCenter"}
            style={{width:"20em", height:"2.5em"}}
            onClick={this.onclick_learn_more_button}>
            <font className={"font11 " + ((isSelectedTF) ? ("fontWhite") : ("fontTextLight"))}>
              {"Learn More About " + p_dataSourceDisplayName}
            </font>
          </div>
        </div>
      </div>
    );
  }
}));

function GCSSSingleDataSourceInfoName(props) { //props: p_name
  const p_name = props.p_name;

  return(
    <div className="smallFullMargin textCenter">
      <font className="font13 fontBold fontBlue">
        {p_name}
      </font>
    </div>
  )
}



class GCSSGovWinMultipleCredentialsEditor extends Component { //props: p_dataSourceSam0GovWin1, p_gcssCredentialsArrayOfObjs
  onclick_add_additional_govwin_credentials = () => {
    const p_dataSourceSam0GovWin1 = this.props.p_dataSourceSam0GovWin1;

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx - GCSSGovWinMultipleCredentialsEditor", "onclick_add_additional_govwin_credentials", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const fieldNamesArray = ["data_source_sam0_govwin1", "division_ids_comma", "username", "password_scramble", "client_id_scramble", "client_secret_scramble", "oauth_access_json", "access_token_expiration_datetime_utc", "previous_access_successful_01"];
    const valuesArray = [p_dataSourceSam0GovWin1, "", "", "", "", "", "", JSFUNC.blank_datetime(), 1];
    const idsbArray = ["i", "s", "s", "s", "s", "s", "s", "s", "i"];
    C_CallPhpTblUID.add_insert("tbl_a_gcss_data_source_credentials", fieldNamesArray, valuesArray, idsbArray); //no resorting with no sort column, ordered by id ASC and can't be changed by user

    C_CallPhpTblUID.execute();
  }

  render() {
    const p_dataSourceSam0GovWin1 = this.props.p_dataSourceSam0GovWin1;
    const p_gcssCredentialsArrayOfObjs = this.props.p_gcssCredentialsArrayOfObjs;

    const dataSourceIsGovWinTF = (p_dataSourceSam0GovWin1 === 1);
    const dataSourceIsFederalCompassTF = (p_dataSourceSam0GovWin1 === 2);
    const multipleGovWinCredentialsTF = (p_gcssCredentialsArrayOfObjs.length > 1);

    return(
      <>
        <div className="flex00a displayFlexColumnHcVc smallBottomMargin">
          <CEGeneralReact.CEButton
            p_type="add"
            p_text={"Add Additional " + ((dataSourceIsFederalCompassTF) ? ("Federal Compass") : ("GovWin")) + " Credentials"}
            p_title="Use this to create multiple instances of valid GovWin login credentials. You can assign these logins to different divisions within your company to address any needs related to licensing and contracts. Having a single instance will apply automatically to all users."
            f_onClick={this.onclick_add_additional_govwin_credentials}
          />
        </div>
        <div className="flex11a yScroll smallBottomMargin">
          {p_gcssCredentialsArrayOfObjs.map((m_gcssCredentialsObj, m_index) =>
            <GCSSGovWinSingleCredentials
              p_dataSourceSam0GovWin1={p_dataSourceSam0GovWin1}
              p_gcssCredentialsObj={m_gcssCredentialsObj}
              p_sortNumber={(m_index + 1)}
              p_multipleGovWinCredentialsTF={multipleGovWinCredentialsTF}
            />
          )}
        </div>
      </>
    );
  }
}


const GCSSGovWinSingleCredentials = inject("AdminMobx", "DatabaseMobx")(observer(
class GCSSGovWinSingleCredentials extends Component { //props: p_dataSourceSam0GovWin1, p_gcssCredentialsObj, p_sortNumber, p_multipleGovWinCredentialsTF
  onsave_govwin_division_ids = (i_newValueString) => {
    this.update_data_source_credentials_field("division_ids_comma", i_newValueString, "s");
  }

  onsave_govwin_username = (i_newValueString) => {
    this.update_data_source_credentials_field("username", i_newValueString, "s");
  }

  onsave_govwin_password = (i_newValueStringUnscrambled) => {
    const newValueStringScrambled = JSFUNC.rfr_scramble_or_unscramble_password(i_newValueStringUnscrambled);
    this.update_data_source_credentials_field("password_scramble", newValueStringScrambled, "s");
  }

  onsave_govwin_client_id = (i_newValueStringUnscrambled) => {
    const newValueStringScrambled = JSFUNC.rfr_scramble_or_unscramble_password(i_newValueStringUnscrambled);
    this.update_data_source_credentials_field("client_id_scramble", newValueStringScrambled, "s");
  }

  onsave_govwin_client_secret = (i_newValueStringUnscrambled) => {
    const newValueStringScrambled = JSFUNC.rfr_scramble_or_unscramble_password(i_newValueStringUnscrambled);
    this.update_data_source_credentials_field("client_secret_scramble", newValueStringScrambled, "s");
  }

  update_data_source_credentials_field = (i_fieldDbName, i_newValue, i_idsb) => {
    const p_gcssCredentialsObj = this.props.p_gcssCredentialsObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx - GCSSGovWinSingleCredentials", "update_data_source_credentials_field", ["i_fieldDbName", "i_newValue", "i_idsb"], [i_fieldDbName, i_newValue, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_gcss_data_source_credentials", p_gcssCredentialsObj.id, i_fieldDbName, i_newValue, i_idsb);
    C_CallPhpTblUID.execute();
  }

  ondelete_govwin_credential = () => {
    const p_gcssCredentialsObj = this.props.p_gcssCredentialsObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx - GCSSGovWinSingleCredentials", "ondelete_govwin_credential", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_gcss_data_source_credentials", p_gcssCredentialsObj.id);
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_dataSourceSam0GovWin1 = this.props.p_dataSourceSam0GovWin1;
    const p_gcssCredentialsObj = this.props.p_gcssCredentialsObj;
    const p_sortNumber = this.props.p_sortNumber;
    const p_multipleGovWinCredentialsTF = this.props.p_multipleGovWinCredentialsTF;

    const dataSourceIsGovWinTF = (p_dataSourceSam0GovWin1 === 1);
    const dataSourceIsFederalCompassTF = (p_dataSourceSam0GovWin1 === 2);

    const govWinFieldClass = "fontBlue";
    const govWinFieldWidth = "13em";

    return(
      <div className="flex00a displayFlexRow smallFullMargin border1bbb">
        <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"2em"}}>
          <font className="font11 fontTextLighter">
            {p_sortNumber}
          </font>
        </div>
        <div className="flex11a smallFullPad">
          {(p_multipleGovWinCredentialsTF) &&
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"adminGcssGovWinDivisions" + p_gcssCredentialsObj.id}
              p_fieldDisplayName="Valid for CE Divisions"
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiDivisionsFieldTypeObj}
              p_valueRaw={p_gcssCredentialsObj.division_ids_comma}
              p_valueIsEditableTFU={true}
              p_fieldClass={govWinFieldClass}
              p_fieldWidth={govWinFieldWidth}
              f_onSaveChanged={this.onsave_govwin_division_ids}
            />
          }
          {(dataSourceIsGovWinTF) &&
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"adminGcssGovWinUsername" + p_gcssCredentialsObj.id}
              p_fieldDisplayName="GovWin Username"
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
              p_valueRaw={p_gcssCredentialsObj.username}
              p_valueIsEditableTFU={true}
              p_fieldClass={govWinFieldClass}
              p_fieldWidth={govWinFieldWidth}
              f_onSaveChanged={this.onsave_govwin_username}
            />
          }
          {(dataSourceIsGovWinTF || dataSourceIsFederalCompassTF) &&
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"adminGcssGovWinPassword" + p_gcssCredentialsObj.id}
              p_fieldDisplayName={((dataSourceIsFederalCompassTF) ? ("Federal Compass API Key") : ("GovWin Password"))}
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
              p_valueRaw={JSFUNC.rfr_scramble_or_unscramble_password(p_gcssCredentialsObj.password_scramble)}
              p_valueIsEditableTFU={true}
              p_fieldClass={govWinFieldClass}
              p_fieldWidth={govWinFieldWidth}
              f_onSaveChanged={this.onsave_govwin_password}
            />
          }
          {(dataSourceIsGovWinTF) &&
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"adminGcssGovWinClientID" + p_gcssCredentialsObj.id}
              p_fieldDisplayName="GovWin Client ID Code"
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
              p_valueRaw={JSFUNC.rfr_scramble_or_unscramble_password(p_gcssCredentialsObj.client_id_scramble)}
              p_valueIsEditableTFU={true}
              p_fieldClass={govWinFieldClass}
              p_fieldWidth={govWinFieldWidth}
              f_onSaveChanged={this.onsave_govwin_client_id}
            />
          }
          {(dataSourceIsGovWinTF) &&
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"adminGcssGovWinClientSecret" + p_gcssCredentialsObj.id}
              p_fieldDisplayName="GovWin Client Secret Code"
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
              p_valueRaw={JSFUNC.rfr_scramble_or_unscramble_password(p_gcssCredentialsObj.client_secret_scramble)}
              p_valueIsEditableTFU={true}
              p_fieldClass={govWinFieldClass}
              p_fieldWidth={govWinFieldWidth}
              f_onSaveChanged={this.onsave_govwin_client_secret}
            />
          }
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <CEGeneralReact.DeleteMenu
            p_message={"Are you sure you want to delete the GovWin credentials for Username '" + p_gcssCredentialsObj.username + "'?"}
            f_onDelete={this.ondelete_govwin_credential}
          />
        </div>
      </div>
    );
  }
}));







//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabContactsFields = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabContactsFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectedTab: "personCompanyExtraFields"
    }
  }

  onclick_admin_contact_tab = (i_newValue) => {
    this.setState({s_selectedTab:i_newValue});
  }

  render() {
    const selectedTab = this.state.s_selectedTab;

    const businessTypesArrayOfObjs = this.props.AdminMobx.c_businessTypesArrayOfObjs;
    const capabilitiesArrayOfObjs = this.props.AdminMobx.c_capabilitiesArrayOfObjs;

    var adminContactsPageComponent = null;
    if(selectedTab === "personCompanyExtraFields") {
      adminContactsPageComponent = (
        <div className="flex11a yScroll medFullPad">
          <AdminContactsDividerAndTitle p_title="Contact Person Fields" p_noTopLineTF={true} />
          <AEDTableForExtraFieldColumnManipulation p_tblName="tbl_a_contacts_persons_extra_fields" />
          <AdminContactsDividerAndTitle p_title="Contact Company Fields" />
          <AEDTableForExtraFieldColumnManipulation p_tblName="tbl_a_contacts_companies_extra_fields" />
        </div>
      );
    }
    else if(selectedTab === "businessTypes") {
      const isSmallBusinessValueArray = [0, 1];
      const isSmallBusinessDisplayArray = ["No (this is a Large Business type, Gov Organization, etc)", "Yes (this is a Small Business type)"];
      const selectIsSmallBusinessFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Is Small Business?", isSmallBusinessValueArray, false, isSmallBusinessDisplayArray, 3);

      const businessTypesAedTableObj = {
        tblName: "tbl_a_business_types",
        dragToResortFieldDbName: "sort",
        initialSortedFieldDbName: "sort",
        tableWidth: "70em",
        itemName: "Company Business Type",
        deleteItemNameFieldDbName: "name",
        fieldsArrayOfObjs: [
          {width:"100em", dbName:"name", displayName:"Business Type Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
          {width:"100em", dbName:"is_sb_type_01", displayName:"Is 'Small Business' Type? (Uses SB Certifications)", fieldTypeObj:selectIsSmallBusinessFieldTypeObj, initialValue:0, cellHoverTF:true, requiredNotEmptyTF:true},
          {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
        ]
      };

      adminContactsPageComponent = (
        <div className="flex11a yScroll medFullPad">
          <AddEditDeleteTable
            p_dataArrayOfObjs={businessTypesArrayOfObjs}
            p_aedTableObj={businessTypesAedTableObj}
          />
        </div>
      );
    }
    else if(selectedTab === "capabilities") {
      const capabilitiesAedTableObj = {
        tblName: "tbl_a_capabilities",
        dragToResortFieldDbName: undefined,
        initialSortedFieldDbName: "name",
        tableWidth: "60em",
        itemName: "Company Capability",
        deleteItemNameFieldDbName: "name",
        fieldsArrayOfObjs: [
          {width:"100em", dbName:"name", displayName:"Capability", fieldTypeObj:this.props.DatabaseMobx.c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true}
        ]
      };

      adminContactsPageComponent = (
        <div className="flex11a yScroll medFullPad">
          <AddEditDeleteTable
            p_dataArrayOfObjs={capabilitiesArrayOfObjs}
            p_aedTableObj={capabilitiesAedTableObj}
          />
        </div>
      );
    }
    else if(selectedTab === "reassignContacts") {
      adminContactsPageComponent = (
        <div className="flex11a yScroll medFullPad">
          <AdminContactsReassignDuplicated />
        </div>
      );
    }


    return (
      <>
        <div className="smallFullPad">
          <CEGeneralReact.TabsList
            p_tabDbNamesArray={["personCompanyExtraFields", "businessTypes", "capabilities", "reassignContacts"]}
            p_tabDisplayNamesArray={["Edit Contact Data Fields", "Edit Business Types", "Edit Capabilities", "Untangle Duplicate Contact Entries"]}
            p_selectedTabDbName={selectedTab}
            p_tabHeight="3.2em"
            p_textMaxHeight="3em"
            p_tabWidth="13em"
            p_unselectedFontClass="font11 fontBold fontTextLight"
            p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
            p_selectedBgClass="bgGoldGradient"
            f_onSelect={this.onclick_admin_contact_tab}
          />
        </div>
        {adminContactsPageComponent}
      </>
    );
  }
}));


const AdminContactsReassignDuplicated = inject("AdminMobx", "DatabaseMobx", "ContactsMobx")(observer(
class AdminContactsReassignDuplicated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_reassignState: "setup", //"setup", "running", "done"
      s_contactsArePersons12: -1, //-1 - none selected yet, 1 - persons, 2 - companies
      s_contact1ID: -1,
      s_contact2ID: -1
    }
  }

  onselect_are_persons_or_companies = (i_newValue) => {
    this.setState({
      s_contactsArePersons12: i_newValue,
      s_contact1ID: -1, //reset the selected contacts when changing their type
      s_contact2ID: -1,
      s_contactIsDeletedTF: false
    });
  }

  onselect_contact1 = (i_newValue) => {
    this.setState({s_contact1ID:i_newValue});
  }

  onselect_contact2 = (i_newValue) => {
    this.setState({s_contact2ID:i_newValue});
  }

  onclick_reassign_contact_assignments = () => {
    this.setState({s_reassignState:"running"});

    const isPersonTF = (this.state.s_contactsArePersons12 === 1);
    const functionOnSuccess = () => {
      this.setState({s_reassignState:"done"});
    }
    const functionOnError = () => {
      this.setState({s_reassignState:"setup"});
      alert("Contacts reassign failed");
    }
    this.props.AdminMobx.a_admin_contacts_reassign(isPersonTF, this.state.s_contact1ID, this.state.s_contact2ID, functionOnSuccess, functionOnError);
  }

  onclick_delete_duplicate_contact = () => {
    const isPersonTF = (this.state.s_contactsArePersons12 === 1);
    this.props.ContactsMobx.a_delete_contact(isPersonTF, this.state.s_contact1ID);
    this.setState({s_contactIsDeletedTF:true});
  }

  onclick_return_to_create_another_reassignment = () => {
    this.setState({
      s_reassignState: "setup",
      s_contactsArePersons12: -1,
      s_contact1ID: -1,
      s_contact2ID: -1,
      s_contactIsDeletedTF: false
    });
  }

  render() {
    const reassignState = this.state.s_reassignState;
    const contactsArePersons12 = this.state.s_contactsArePersons12;
    const contact1ID = this.state.s_contact1ID;
    const contact2ID = this.state.s_contact2ID;
    const contactIsDeletedTF = this.state.s_contactIsDeletedTF;

    const selectedPersonsTF = (contactsArePersons12 === 1);
    const selectedCompaniesTF = (contactsArePersons12 === 2);
    const selectedPersonsOrCompanies = (selectedPersonsTF || selectedCompaniesTF);

    var selectContactFieldTypeObj = undefined;
    if(selectedPersonsTF) {
      selectContactFieldTypeObj = this.props.DatabaseMobx.c_selectContactPersonFieldTypeObj;
    }
    else if(selectedCompaniesTF) {
      selectContactFieldTypeObj = this.props.DatabaseMobx.c_selectContactCompanyFieldTypeObj;
    }

    const personsCompaniesSwsOptionsObj = {hasClearSelectionTF:true, unselectedDisplay:"--Select a Contact Type--"};
    const personsCompaniesSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Contact Type", [1, 2], false, ["Reassigning Contact Persons", "Reassigning Contact Companies"], personsCompaniesSwsOptionsObj);
    const selectPersonsCompaniesFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", personsCompaniesSwsDataObj);

    const contact1And2AreSetTF = (JSFUNC.select_int_is_filled_out_tf(contact1ID) && JSFUNC.select_int_is_filled_out_tf(contact2ID));

    if(reassignState === "setup") {
      return(
        <>
          <div className="microBottomMargin">
            <font className="fontBold fontTextLighter">
              {"Reassign Duplicate Contact Persons or Companies?"}
            </font>
          </div>
          <div style={{width:"20em"}}>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={selectPersonsCompaniesFieldTypeObj}
              p_valueRaw={contactsArePersons12}
              f_onChangeOrOnSelect={this.onselect_are_persons_or_companies}
            />
          </div>
          {(selectedPersonsOrCompanies) &&
            <>
              <div className="smallTopMargin">
                <font className="fontItalic fontTextLighter">
                  {((selectedPersonsTF) ? (
                    "This Contact Person reassignment affects 1) Specific Contact Person Capture Details Fields, 2) Contact Persons added within each Teammate (including specified Contracts Manager), and 3) Conversation Contact Persons"
                  ) : (
                    "This Contact Company reassignment affects 1) Specific Contact Company Capture Details Fields, 2) Teammates, 3) Competitors, and 4) Competitor's Teammates"
                  ))}
                </font>
              </div>
              <div className="medTopMargin microBottomMargin">
                <font className="fontItalic fontTextLighter">
                  {"Remove Assignments from:"}
                </font>
              </div>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="adminContactsReassignContact1"
                p_fieldDisplayName="Remove all assignments in system from this contact:"
                p_fieldTypeObj={selectContactFieldTypeObj}
                p_valueRaw={contact1ID}
                p_valuesToNotIncludeArray={[contact2ID]}
                p_valueIsEditableTFU={true}
                p_fieldClass="fontBlue"
                p_fieldWidth="14em"
                p_floatingBoxTitle="Remove all assignments in system from this selected contact"
                f_onSaveChanged={this.onselect_contact1}
              />
              <div className="medTopMargin microBottomMargin">
                <font className="fontItalic fontTextLighter">
                  {"Give assignments to:"}
                </font>
              </div>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="adminContactsReassignContact2"
                p_fieldDisplayName="Reassign all from above contact to this contact:"
                p_fieldTypeObj={selectContactFieldTypeObj}
                p_valueRaw={contact2ID}
                p_valuesToNotIncludeArray={[contact1ID]}
                p_valueIsEditableTFU={true}
                p_fieldClass="fontBlue"
                p_fieldWidth="14em"
                p_floatingBoxTitle="Reassign all from above contact to this selected contact"
                f_onSaveChanged={this.onselect_contact2}
              />
              <div className="medTopMargin">
                {(contact1And2AreSetTF) &&
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text="Reassign All Contact Assignments"
                    f_onClick={this.onclick_reassign_contact_assignments}
                  />
                }
              </div>
            </>
          }
        </>
      );
    }

    const contact1NamePlainText = this.props.ContactsMobx.contact_name_from_id(selectedPersonsTF, contact1ID);
    const contact2NamePlainText = this.props.ContactsMobx.contact_name_from_id(selectedPersonsTF, contact2ID);

    if(reassignState === "running") {
      return(
        <div className="medFullPad hugeBottomMargin">
          <div className="">
            <font className="font12 fontBold fontItalic fontTextLighter">
              {"Running Contact Reassign..."}
            </font>
          </div>
          <div className="microTopMargin">
            <font className="fontItalic fontTextLighter">
              {"Reassigning '" + contact1NamePlainText + "' to '" + contact2NamePlainText + "'..."}
            </font>
          </div>
          <div className="smallTopMargin">
            <CEGeneralReact.LoadingAnimation />
          </div>
        </div>
      );
    }

    //done
    return(
      <div className="displayFlexRowVc flexWrap medFullPad hugeBottomMargin">
        {(!contactIsDeletedTF) &&
          <div className="flex00a lrMedMargin tbMargin">
            <CEGeneralReact.ButtonWithConfirmBox
              p_buttonType="red"
              p_buttonText={"Delete Duplicate Contact '" + contact1NamePlainText + "'"}
              p_confirmType="confirmDelete"
              p_confirmMessage={"Delete Duplicate Contact '" + contact1NamePlainText + "'?"}
              f_onClickConfirm={this.onclick_delete_duplicate_contact}
            />
          </div>
        }
        <div className="flex00a lrMedMargin tbMargin">
          <CEGeneralReact.CEButton
            p_type="gray"
            p_text="Return to Create Another Reassignment"
            f_onClick={this.onclick_return_to_create_another_reassignment}
          />
        </div>
      </div>
    )
  }
}));

function AdminContactsDividerAndTitle(props) { //props: p_title, p_noTopLineTF
  const noTopLineTF = JSFUNC.prop_value(props.p_noTopLineTF, false);
  return(
    <div
      className={((noTopLineTF) ? (undefined) : ("medTopPad bigTopMargin"))}
      style={{borderTop:((noTopLineTF) ? (undefined) : ("solid 1px #ccc"))}}>
      <font className="font14 fontBold fontTextLighter">
        {props.p_title}
      </font>
    </div>
  );
}





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabTasksOptions = inject("AdminMobx", "RightPanelMobx", "DatabaseMobx")(observer(
class AdminSubtabTasksOptions extends Component {
  onsave_tasks_assignee_can_edit_due_date = (i_newValueInt) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("tasks_assignee_can_edit_due_date_01", i_newValueInt, "i");
  }

  render() {
    const c_tasksExtraCaptureFieldsArrayOfObjs = this.props.RightPanelMobx.c_tasksExtraCaptureFieldsArrayOfObjs;
    const c_companyTasksAssigneeCanEditDueDate01 = this.props.DatabaseMobx.c_companyTasksAssigneeCanEditDueDate01;
    const c_fieldMapOfOpportunityName = this.props.DatabaseMobx.c_fieldMapOfOpportunityName;

    const taskEditPrivilegesFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Task Edit Privilege Option", [0, 1], false, ["[default] Task Assignees cannot change the Due Date of a Task that someone else has assigned to them", "Task Assignees can edit their own Due Dates"], 2.8);
   
    const tasksExtraCaptureFieldsAedTableObj = {
      tblName: "tbl_a_tasks_extra_capture_fields",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: "40em",
      itemName: "Tasks Extra Capture Field",
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"capture_field_id", displayName:"Capture Field", fieldTypeObj:this.props.DatabaseMobx.c_selectCaptureFieldFieldTypeObj, initialValue:-1, cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
      ]
    };

    const nowDateValueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(JSFUNC.now_date(), this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj);

    var exampleExtraCaptureFieldValuesArrayOfObjs = [];
    for(let tasksExtraCaptureFieldsObj of c_tasksExtraCaptureFieldsArrayOfObjs) {
      exampleExtraCaptureFieldValuesArrayOfObjs.push({
        captureFieldDisplayName: tasksExtraCaptureFieldsObj.captureFieldDisplayName,
        valueMaskPlainText: "Example Capture Value"
      });
    }

    const exmapleExpandedTaskObj = {
      id: -1,
      capture_id: -1,
      assigner_user_id: -1,
      assigned_to_user_ids_comma: "",
      date_created: "0000-00-00",
      date_due: "0000-00-00",
      date_completed: "0000-00-00",
      important_01: 0,
      viewed_0123: 0,
      description: "",
      pre_reminder_days: -1,
      pre_reminder_sent_01: 0,
      post_reminder_days: -1,
      post_reminder_sent_date: "0000-00-00",
      dueDateIsSetTF: false,
      isCompletedTF: false,
      isImportantTF: false,
      isGeneralTaskTF: false,
      assignedToMeTF: false,
      assignerIsMeTF: false,
      preReminderIsOnTF: false,
      postReminderIsOnTF: false,
      maskAssignedToName: "Example User",
      maskAssignedToNamePlainText: "Example User",
      maskAssignerName: "Example User",
      maskAssignerNamePlainText: "Example User",
      maskRelevancePlainText: "Capture " + c_fieldMapOfOpportunityName.get("display_name"),
      maskActiveTaskMdyDueDate: nowDateValueMaskSortIfoObj.valueMask,
      maskActiveTaskMdyDueDatePlainText: nowDateValueMaskSortIfoObj.valueMaskPlainText,
      maskCompletedTaskItemDueDate: "",
      maskCompletedTaskItemCompletedDate: "",
      maskDescription: "Example description",
      maskDescriptionPlainText: "Example description",
      extraCaptureFieldValuesArrayOfObjs: exampleExtraCaptureFieldValuesArrayOfObjs
    };

    return (
      <div className="flex11a xyScroll medFullPad">
        <div className="medBottomMargin">
          <font className="font13 fontBold fontTextLight">
            {"Tasks Options"}
          </font>
        </div>
        <div className="" style={{maxWidth:"50em"}}>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="adminSystemTaskEditPrivileges"
            p_fieldDisplayName="Task Assignee Edit Privileges"
            p_fieldTypeObj={taskEditPrivilegesFieldTypeObj}
            p_valueRaw={c_companyTasksAssigneeCanEditDueDate01}
            p_valueIsEditableTFU={true}
            p_containerClass="tbMargin"
            p_fieldClass="fontBlue"
            p_fieldWidth="15em"
            p_valueClass={undefined}
            f_onSaveChanged={this.onsave_tasks_assignee_can_edit_due_date}
          />
        </div>
        <div className="tbBigMargin borderT1bbb" />
        <div style={{width:"72em"}}>
          <div className="medBottomMargin">
            <font className="font11 fontItalic">
              {"Using this table, you can add extra Capture Fields below 'Relevance' when displayed in a Task in the Tasks Panel. 'Relevance' displays the name of the Capture, and these extra fields can help to more easily identify which Capture this is."}
            </font>
          </div>
          <div className="displayFlexRow">
            <div className="flex00a">
              <AddEditDeleteTable
                p_dataArrayOfObjs={c_tasksExtraCaptureFieldsArrayOfObjs}
                p_aedTableObj={tasksExtraCaptureFieldsAedTableObj}
              />
            </div>
            <div className="flex00a" style={{flexBasis:"2em"}} />
            <div className="flex11a">
              <div className="border1bbb bgLighterGray smallFullPad">
                <div className="smallBottomMargin">
                  <font className="fontItalic fontTextLight">
                    {"Example Task as it will appear in Tasks Panel"}
                  </font>
                </div>
                <RightPanelReact.TaskItem
                  p_container="rightPanel"
                  p_taskObj={exmapleExpandedTaskObj}
                  f_onClick={undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}));



//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabCompanyCommunications = inject("AdminMobx")(observer(
class AdminSubtabCompanyCommunications extends Component {
  onclick_company_communications_tab = (i_selectedTabDbName) => {
    this.props.AdminMobx.a_set_company_communications_selected_tab_db_name(i_selectedTabDbName);
  }

  render() {
    const o_companyCommunicationsSelectedTabDbName = this.props.AdminMobx.o_companyCommunicationsSelectedTabDbName;

    return(
        <>
          <div className="flex00a displayFlexColumnVc borderB1bbb lrMedPad xScroll" style={{flexBasis:"3em"}}>
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={["banners", "broadcast"]}
              p_tabDisplayNamesArray={["Publish System Banners", "Send Broadcast Notifications"]}
              p_selectedTabDbName={o_companyCommunicationsSelectedTabDbName}
              p_tabHeight="1.8em"
              p_textMaxHeight="1.8em"
              p_tabWidth="18em"
              p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
              p_unselectedFontClass="fontBold fontTextLight"
              p_selectedBgClass="bgTealGradient"
              p_selectedFontClass="fontBold fontWhite"
              f_onSelect={this.onclick_company_communications_tab}
            />
          </div>
          <div className="flex11a xyScroll medFullPad">
            {(o_companyCommunicationsSelectedTabDbName === "banners") &&
              <AdminCommunicationsBanners />
            }
            {(o_companyCommunicationsSelectedTabDbName === "broadcast") &&
              <div className="flex11a xyScroll medFullPad">
                <div className="" style={{width:"95%", maxWidth:"30em"}}>
                  <RightPanelReact.NotificationsBroadcastCreateAndSend />
                </div>
              </div>
            }
          </div>
        </>
    );
  }
}));


const AdminCommunicationsBanners = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx", "UserMobx")(observer(
class AdminCommunicationsBanners extends Component {
  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_adminCompanyCommunicationsArrayOfObjs = this.props.AdminMobx.c_adminCompanyCommunicationsArrayOfObjs;
    const c_adminCompanyCommunicationsSelectBannerOff0OnlyMe1On2FieldTypeObj = this.props.AdminMobx.c_adminCompanyCommunicationsSelectBannerOff0OnlyMe1On2FieldTypeObj;
    const c_adminCompanyCommunicationsSelectFontSizeMultiplier10xFieldTypeObj = this.props.AdminMobx.c_adminCompanyCommunicationsSelectFontSizeMultiplier10xFieldTypeObj;
    const c_genericTextareaFieldTypeObj = this.props.DatabaseMobx.c_genericTextareaFieldTypeObj;
    const c_genericDecimalPositiveFieldTypeObj = this.props.DatabaseMobx.c_genericDecimalPositiveFieldTypeObj;
    const c_genericDateFieldTypeObj = this.props.DatabaseMobx.c_genericDateFieldTypeObj;
    const c_genericStylingStringFieldTypeObj = this.props.DatabaseMobx.c_genericStylingStringFieldTypeObj;
    const c_selectMultiUsersPerEmailNotDeactivatedOrAllFieldTypeObj = this.props.DatabaseMobx.c_selectMultiUsersPerEmailNotDeactivatedOrAllFieldTypeObj;
    const c_selectMultiDivisionsOrAllFieldTypeObj = this.props.DatabaseMobx.c_selectMultiDivisionsOrAllFieldTypeObj;
    const o_userID = this.props.UserMobx.o_userID;

    const nowDateTimeUtc = JSFUNC.now_datetime_utc();

    const companyCommunicationsAedTableObj = {
      tblName: "tbl_a_communications_banners",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: ((o_mediaQueryFlag >= 5) ? ("95%") : ("100em")),
      itemName: "Communications Banner",
      deleteItemNameFieldDbName: "banner_display_text",
      newItemConstantFieldNamesArray: ["created_datetime_utc", "created_by_user_id"],
      newItemConstantValuesArray: [nowDateTimeUtc, o_userID],
      newItemConstantIdsbArray: ["s", "i"],
      fieldsArrayOfObjs: [
        {width:"8em", fixedWidthTF:true, dbName:"off0_onlyme1_on2", displayName:"On/Off", fieldTypeObj:c_adminCompanyCommunicationsSelectBannerOff0OnlyMe1On2FieldTypeObj, initialValue:1, cellCenterTF:true},
        {width:"8em", fixedWidthTF:true, dbName:"start_date", displayName:"Start Date", fieldTypeObj:c_genericDateFieldTypeObj, initialValue:"0000-00-00", cellCenterTF:true},
        {width:"8em", fixedWidthTF:true, dbName:"end_date", displayName:"End Date", fieldTypeObj:c_genericDateFieldTypeObj, initialValue:"0000-00-00", cellCenterTF:true},
        {width:"200em", dbName:"banner_display_text", displayName:"Banner Display Text", fieldTypeObj:c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF:true},
        {width:"150em", dbName:"banner_full_description", displayName:"Full Description (optional)", fieldTypeObj:c_genericTextareaFieldTypeObj, initialValue:"", cellHoverTF:true},
        {width:"8em", fixedWidthTF:true, dbName:"banner_height_em_decimal", displayName:"Height (# Lines)", fieldTypeObj:c_genericDecimalPositiveFieldTypeObj, initialValue:1.9, cellCenterTF:true},
        {width:"8em", fixedWidthTF:true, dbName:"banner_styling_string_comma", displayName:"Style", fieldTypeObj:c_genericStylingStringFieldTypeObj, initialValue:"color#777777,highlight#fbf9e9", cellCenterTF:true, cellDisplayBlankIfNotFilledOutTF:true},
        {width:"8em", fixedWidthTF:true, dbName:"banner_font_size_multiplier_10x", displayName:"Font Size", fieldTypeObj:c_adminCompanyCommunicationsSelectFontSizeMultiplier10xFieldTypeObj, initialValue:10, cellCenterTF:true},
        {width:"100em", dbName:"seen_by_user_per_email_ids_comma_or_all", displayName:"Seen by Users (optional)", fieldTypeObj:c_selectMultiUsersPerEmailNotDeactivatedOrAllFieldTypeObj, initialValue:"all"},
        {width:"100em", dbName:"seen_by_division_ids_comma_or_all", displayName:"Seen by Divisions (optional)", fieldTypeObj:c_selectMultiDivisionsOrAllFieldTypeObj, initialValue:"all"},
        {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    return(
      <>
        <div className="smallBottomMargin">
          <font className="font12 fontBold fontTextLighter">
            {"System Banners"}
          </font>
        </div>
        <div className="smallBottomMargin" style={{width:"100em"}}>
          <font className="fontItalic fontTextLight">
            <div className="microBottomMargin">
              {"To create a new Banner that displays above the CE system, click the “Add New Communications Banner” button."}
            </div>
            <div className="microBottomMargin">
              {"Start and end dates may be left blank and the banner will always be displayed. If dates are put in, the system will display and remove the banner based on the dates."}
            </div>
            <div className="">
              {"You can also restrict who can see the banners based on the “Seen by Users” and “Seen by Divisions” options."}
            </div>
          </font>
        </div>
        <AddEditDeleteTable
          p_dataArrayOfObjs={c_adminCompanyCommunicationsArrayOfObjs}
          p_aedTableObj={companyCommunicationsAedTableObj}
        />
      </>
    );
  }
}));



//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabAutomatedReminders = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabAutomatedReminders extends Component {
  render() {
    const c_automatedRemindersArrayOfObjs = this.props.AdminMobx.c_automatedRemindersArrayOfObjs;
    const c_selectMultiDivisionsOrAllFieldTypeObj = this.props.DatabaseMobx.c_selectMultiDivisionsOrAllFieldTypeObj;

    const selectActiveAllFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Active/All", [0, 1], false, ["Only Send for Active Captures (Not Won/Lost/Cancelled)", "Send for All Captures"], 3);
    const selectPrePostFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Pre/Post", [0, 1], false, ["Send # Days Prior to Date", "Send # Days After Date"], 3);

    const automatedRemindersAedTableObj = {
      tblName: "tbl_a_automated_reminders",
      tableWidth: "95%",
      itemName: "Automated Reminder",
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"date_field_id", displayName:"Capture Date Field", fieldTypeObj:this.props.DatabaseMobx.c_selectCaptureDateFieldFieldTypeObj, initialValue:-1, cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"6em", fixedWidthTF:true, dbName:"num_days_prior", displayName:"# Days", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:30, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"13em", fixedWidthTF:true, dbName:"pre0_post1", displayName:"Pre/Post Reminder", fieldTypeObj:selectPrePostFieldTypeObj, initialValue:0, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"27em", fixedWidthTF:true, dbName:"active0_all1", displayName:"Active/Closed Capture Send Restriction", fieldTypeObj:selectActiveAllFieldTypeObj, initialValue:0, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"extra_user_per_email_ids_to_notify_comma", displayName:"Users in Addition to Capture Managers to Notify (optional)", fieldTypeObj:this.props.DatabaseMobx.c_selectMultiUsersPerEmailNotDeactivatedFieldTypeObj, initialValue:""},
        {width:"100em", dbName:"extra_message", displayName:"Extra Message Included in Notification (optional)", fieldTypeObj:this.props.DatabaseMobx.c_genericTextareaFieldTypeObj, initialValue:""},
        {width:"100em", dbName:"division_ids_comma_or_all", displayName:"Division(s) Applied To (optional)", fieldTypeObj:c_selectMultiDivisionsOrAllFieldTypeObj, initialValue:"all"}
      ]
    };

    return (
      <div className="flex11a xyScroll medFullPad">
        <AddEditDeleteTable
          p_dataArrayOfObjs={c_automatedRemindersArrayOfObjs}
          p_aedTableObj={automatedRemindersAedTableObj}
        />
      </div>
    );
  }
}));



//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabAutomatedExports = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabAutomatedExports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_createNewAutomatedExportJobFloatingBoxIsOpenTF: false,
      s_editingAutomatedExportIDOrUndefined: undefined
    };
  }

  onclick_open_create_new_automated_export_job_floating_box = () => {
    this.setState({s_createNewAutomatedExportJobFloatingBoxIsOpenTF:true});
  }

  onclick_close_create_new_automated_export_job_floating_box = () => {
    this.setState({s_createNewAutomatedExportJobFloatingBoxIsOpenTF:false});
  }

  onclick_open_edit_automated_export_job_floating_box = (i_automatedExportID) => {
    this.setState({s_editingAutomatedExportIDOrUndefined:i_automatedExportID});
  }

  onclick_close_edit_automated_export_job_floating_box = () => {
    this.setState({s_editingAutomatedExportIDOrUndefined:undefined});
  }

  render() {
    const s_createNewAutomatedExportJobFloatingBoxIsOpenTF = this.state.s_createNewAutomatedExportJobFloatingBoxIsOpenTF;
    const s_editingAutomatedExportIDOrUndefined = this.state.s_editingAutomatedExportIDOrUndefined;

    const c_automatedExportsArrayOfObjs = this.props.AdminMobx.c_automatedExportsArrayOfObjs;

    const colWidthsEmArray = [6, 9, 7, 25, 8, 10, 7, 25, 15, 15, 10, 6, 3];

    return (
      <>
        <div className="flex11a xyScroll medFullPad">
          <div className="">
            <font className="">
              {"Automated Exports - Use this tool to create regularly scheduled exports of Captures in .csv format. These files can be used by tools such as PowerBI."}
            </font>
          </div>
          <div className="">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Create New Automated Export Job"
              f_onClick={this.onclick_open_create_new_automated_export_job_floating_box}
            />
          </div>
          <div className="">
            <div className="displayFlexRow" style={{minHeight:"3em"}}>
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[0]} p_label="On/Off" p_title="'On' will run the job indefinitely creating .csv files of Captures according to the selected schedule. 'Off' will stop the scheduled runs." />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[1]} p_label="Latest File URL" p_title="Icon to copy the URL of latest .csv file generated by this job, hosted on the CaptureExec server. If the job has not yet run for the first time '-' will be shown." />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[2]} p_label="Download Latest File" p_title="Icon to manually download the latest .csv file generated by this job." />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[3]} p_label="Job Name" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[4]} p_label="Recurrence" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[5]} p_label="Recurrence Count" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[6]} p_label="Run Time" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[7]} p_label="Capture Table View Button" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[8]} p_label="Last Run" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[9]} p_label="Created By" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[10]} p_label="Date Created" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[11]} p_label="History" />
              <AutomatedExportsTblHeaderCell p_widthEm={colWidthsEmArray[12]} p_label="" />
            </div>
            {c_automatedExportsArrayOfObjs.map((m_automatedExportObj) =>
              <AutomatedExportsTblRow
                key={m_automatedExportObj.id}
                p_automatedExportObj={m_automatedExportObj}
                p_colWidthsEmArray={colWidthsEmArray}
                f_onClickEdit={this.onclick_open_edit_automated_export_job_floating_box}
              />
            )}
          </div>
        </div>
        {(s_createNewAutomatedExportJobFloatingBoxIsOpenTF) &&
          <AutomatedExportsCreateNewJobFloatingBox
            f_onClickClose={this.onclick_close_create_new_automated_export_job_floating_box}
          />
        }
        {(JSFUNC.is_number_not_nan_gt_0(s_editingAutomatedExportIDOrUndefined)) &&
          <AutomatedExportsEditJobFloatingBox
            p_editingAutomatedExportID={s_editingAutomatedExportIDOrUndefined}
            f_onClickSave={this.onclick_close_edit_automated_export_job_floating_box}
          />
        }
      </>
    );
  }
}));


function AutomatedExportsTblHeaderCell(props) { //props: p_widthEm, p_label, p_title
  const p_widthEm = props.p_widthEm;
  const p_label = props.p_label;
  const p_title = props.p_title;

  return(
    <div className="flex00a displayFlexColumn bgDarkOrangeGradient" style={{flexBasis:p_widthEm + "em"}} title={p_title}>
      <div className="flex11a displayFlexColumnHcVc borderR1bbb lrPad textCenter">
        <font className="fontBold fontWhite">
          {p_label}
        </font>
      </div>
    </div>
  );
}


const AutomatedExportsTblRow = inject("AdminMobx", "CapturesMobx", "DatabaseMobx")(observer(
class AutomatedExportsTblRow extends Component { //props: p_automatedExportObj, p_colWidthsEmArray, f_onClickEdit
  onswitch_job_on_off = () => {
    const p_automatedExportObj = this.props.p_automatedExportObj;
    const newValue01 = ((p_automatedExportObj.isOnTF) ? (0) : (1));
    this.props.AdminMobx.a_automated_exports_update_single_field(p_automatedExportObj.id, "off0_on1", newValue01);
  }


  onclick_edit_job = () => {
    const p_automatedExportObj = this.props.p_automatedExportObj;
    if(JSFUNC.is_function(this.props.f_onClickEdit)) {
      this.props.f_onClickEdit(p_automatedExportObj.id);
    }
  }

  ondelete_job = () => {
    const p_automatedExportObj = this.props.p_automatedExportObj;
    this.props.AdminMobx.a_automated_exports_delete_job_and_server_files(p_automatedExportObj);
  }

  render() {
    const p_automatedExportObj = this.props.p_automatedExportObj;
    const p_colWidthsEmArray = this.props.p_colWidthsEmArray;

    const automatedExportsRowMenuItemsArrayOfObjs = [
      {
        displayName: "Edit Job",
        functionOnClickMenuItem: this.onclick_edit_job
      },
      {
        displayName: "Delete Job",
        confirmType: "confirmDelete",
        confirmTitle: "Delete Automated Export Job",
        confirmMessage: "Delete Automated Export Job '" + p_automatedExportObj.jobNameValueMaskSortIfoObj.valueMaskPlainText + "'?",
        functionOnClickConfirmButton: this.ondelete_job
      }
    ];

    return(
      <div className="displayFlexRow" style={{height:"3em"}}>
        <div className="flex00a displayFlexColumnHcVc borderL1bbb borderB1bbb" style={{flexBasis:p_colWidthsEmArray[0] + "em"}}>
          <CEGeneralReact.SwitchWithTextAndConfirmBox
            p_isOnTF={p_automatedExportObj.isOnTF}
            p_sizeEm={4}
            p_onColor="22aa22"
            p_offColor="994444"
            f_onSwitch={this.onswitch_job_on_off}
          />
        </div>
        <div className="flex00a displayFlexColumnHcVc borderB1bbb" style={{flexBasis:p_colWidthsEmArray[1] + "em"}}>
          {(p_automatedExportObj.hasRunOnceTF) ? (
            <CEGeneralReact.ButtonCopyToClipboard
              p_clipboardText={p_automatedExportObj.latestFileUrl}
              p_preCopyLabel="Copy URL"
              p_postCopyLabel="Copied!"
              p_preCopyClass="bgLightGrayGradient border bevelBorderColors borderRadius10"
              p_postCopyClass="bgDarkGrayGradient border bevelBorderDarkColors borderRadius10"
              p_styleObj={{width:"6em", height:"1.7em"}}
              p_preCopyFontClass=""
              p_postCopyFontClass="fontBold fontWhite"
              p_title={"Click to copy latest file URL '" + p_automatedExportObj.latestFileUrl + "' to clipboard"}
            />
          ) : (
            this.props.DatabaseMobx.not_filled_out_font_html("--Not Yet Run--")
          )}
        </div>
        <div className="flex00a displayFlexColumnHcVc borderB1bbb" style={{flexBasis:p_colWidthsEmArray[2] + "em"}}>
          <CEGeneralReact.SmallDownloadFileButtonForServerFile
            p_fileLocRootUploadsOrWeb="web"
            p_fileLocRelativeFromRoot={p_automatedExportObj.latestFileUrl}
            p_downloadSaveAsFileName={p_automatedExportObj.latestFileNameAndExt}
            p_title={"Click to download '" + p_automatedExportObj.latestFileNameAndExt + "'"}
          />
        </div>
        <AutomatedExportTblRowDataCellNowrap p_dataValueMaskSortIfoObj={p_automatedExportObj.jobNameValueMaskSortIfoObj} p_colWidthEm={p_colWidthsEmArray[3]} />
        <AutomatedExportTblRowDataCellNowrap p_dataValueMaskSortIfoObj={p_automatedExportObj.recurrenceValueMaskSortIfoObj} p_colWidthEm={p_colWidthsEmArray[4]} />
        <AutomatedExportTblRowDataCellNowrap p_dataValueMaskSortIfoObj={p_automatedExportObj.recurrenceCountValueMaskSortIfoObj} p_colWidthEm={p_colWidthsEmArray[5]} />
        <AutomatedExportTblRowDataCellNowrap p_dataValueMaskSortIfoObj={p_automatedExportObj.rumTimeHourValueMaskSortIfoObj} p_colWidthEm={p_colWidthsEmArray[6]} />
        <AutomatedExportTblRowDataCellNowrap p_dataValueMaskSortIfoObj={p_automatedExportObj.captureTableViewButtonPresetValueMaskSortIfoObj} p_colWidthEm={p_colWidthsEmArray[7]} />
        <AutomatedExportTblRowDataCellNowrap p_dataValueMaskSortIfoObj={p_automatedExportObj.lastRunDateTimeEstValueMaskSortIfoObj} p_colWidthEm={p_colWidthsEmArray[8]} />
        <AutomatedExportTblRowDataCellNowrap p_dataValueMaskSortIfoObj={p_automatedExportObj.createdByUserValueMaskSortIfoObj} p_colWidthEm={p_colWidthsEmArray[9]} />
        <AutomatedExportTblRowDataCellNowrap p_dataValueMaskSortIfoObj={p_automatedExportObj.dateCreatedValueMaskSortIfoObj} p_colWidthEm={p_colWidthsEmArray[10]} />
        <div className="flex00a displayFlexColumnHcVc borderB1bbb" style={{flexBasis:p_colWidthsEmArray[11] + "em"}}>

        </div>
        <div className="flex00a displayFlexColumnHcVc borderB1bbb borderR1bbb" style={{flexBasis:p_colWidthsEmArray[12] + "em"}}>
          <CEGeneralReact.VerticalDotsMenu
            p_menuItemsArrayOfObjs={automatedExportsRowMenuItemsArrayOfObjs}
          />
        </div>
      </div>
    );
  }
}));


function AutomatedExportTblRowDataCellNowrap(props) { //props: p_dataValueMaskSortIfoObj, p_colWidthEm
  const p_dataValueMaskSortIfoObj = props.p_dataValueMaskSortIfoObj;
  const p_colWidthEm = props.p_colWidthEm;
  return(
    <div className="flex00a displayFlexRowVc borderB1bbb lrPad" style={{flexBasis:p_colWidthEm + "em"}} title={p_dataValueMaskSortIfoObj.valueMaskPlainText}>
      <LibraryReact.Nowrap p_fontClass={undefined}>
        {p_dataValueMaskSortIfoObj.valueMask}
      </LibraryReact.Nowrap>
    </div>
  );
}


const AutomatedExportsCreateNewJobFloatingBox = inject("AdminMobx", "CapturesMobx", "DatabaseMobx")(observer(
class AutomatedExportsCreateNewJobFloatingBox extends Component { //props: f_onClickClose
  constructor(props) {
    super(props);
    this.state = {
      s_jobName: "",
      s_recurrence: 1,
      s_recurrenceCount: 1,
      s_runTimeHourInt: 1,
      s_ceViewButtonID: -1,
      s_createButtonPushedAtLeastOnceTF: false,
      s_creatingNewJobTF: false
    };
  }

  onchange_job_name = (i_newValue) => { this.setState({s_jobName:i_newValue}); }
  onchange_recurrence = (i_newValue) => { this.setState({s_recurrence:i_newValue}); }
  onchange_recurrence_count = (i_newValue) => { this.setState({s_recurrenceCount:i_newValue}); }
  onchange_run_time_hour_int = (i_newValue) => { this.setState({s_runTimeHourInt:i_newValue}); }
  onselect_ce_view_button = (i_selectedViewButtonID) => { this.setState({s_ceViewButtonID:i_selectedViewButtonID}); }
  
  onclick_create_new_automated_export_job = () => {
    const s_jobName = this.state.s_jobName;
    const s_recurrence = this.state.s_recurrence;
    const s_recurrenceCount = this.state.s_recurrenceCount;
    const s_runTimeHourInt = this.state.s_runTimeHourInt;
    const s_ceViewButtonID = this.state.s_ceViewButtonID;

    this.setState({s_createButtonPushedAtLeastOnceTF:true});

    const inputErrorsObj = this.input_errors_obj();
    if(!inputErrorsObj.anyErrorsTF) {
      this.setState({s_creatingNewJobTF:true});

      const functionOnFinish = () => {
        this.setState({s_creatingNewJobTF:false});
        if(JSFUNC.is_function(this.props.f_onClickClose)) {
          this.props.f_onClickClose();
        }
      }
      this.props.AdminMobx.a_automated_exports_insert_new_job(s_jobName, s_recurrence, s_recurrenceCount, s_runTimeHourInt, s_ceViewButtonID, functionOnFinish);
    }
  }

  input_errors_obj = () => {
    const s_jobName = this.state.s_jobName;
    const s_recurrence = this.state.s_recurrence;
    const s_recurrenceCount = this.state.s_recurrenceCount;
    const s_runTimeHourInt = this.state.s_runTimeHourInt;
    const s_ceViewButtonID = this.state.s_ceViewButtonID;
    const s_createButtonPushedAtLeastOnceTF = this.state.s_createButtonPushedAtLeastOnceTF;

    const jobNameErrorTF = (!JSFUNC.string_is_filled_out_tf(s_jobName));
    const recurrenceErrorTF = (!JSFUNC.in_array(s_recurrence, [1, 2, 3]));
    const recurrenceCountErrorTF = (!JSFUNC.is_number_not_nan_gte_0(s_recurrenceCount));
    const runTimeHourErrorTF = (!JSFUNC.is_number_not_nan_gte_0(s_runTimeHourInt));
    const viewButtonErrorTF = (!JSFUNC.select_int_is_filled_out_tf(s_ceViewButtonID));

    const anyErrorsTF = (jobNameErrorTF || recurrenceErrorTF || recurrenceCountErrorTF || runTimeHourErrorTF || viewButtonErrorTF);

    const showJobNameErrorTF = (s_createButtonPushedAtLeastOnceTF && jobNameErrorTF);
    const showRecurrenceErrorTF = (s_createButtonPushedAtLeastOnceTF && recurrenceErrorTF);
    const showRecurrenceCountErrorTF = (s_createButtonPushedAtLeastOnceTF && recurrenceCountErrorTF);
    const showRunTimeHourErrorTF = (s_createButtonPushedAtLeastOnceTF && runTimeHourErrorTF);
    const showViewButtonErrorTF = (s_createButtonPushedAtLeastOnceTF && viewButtonErrorTF);

    return({
      anyErrorsTF: anyErrorsTF,
      jobNameErrorTF: jobNameErrorTF,
      recurrenceErrorTF: recurrenceErrorTF,
      recurrenceCountErrorTF: recurrenceCountErrorTF,
      runTimeHourErrorTF: runTimeHourErrorTF,
      viewButtonErrorTF: viewButtonErrorTF,
      showJobNameErrorTF: showJobNameErrorTF,
      showRecurrenceErrorTF: showRecurrenceErrorTF,
      showRecurrenceCountErrorTF: showRecurrenceCountErrorTF,
      showRunTimeHourErrorTF: showRunTimeHourErrorTF,
      showViewButtonErrorTF: showViewButtonErrorTF,
      jobNameErrorMessageOrUndefined: ((showJobNameErrorTF) ? ("Job Name must be filled out") : (undefined)),
      recurrenceErrorMessageOrUndefined: ((showRecurrenceErrorTF) ? ("Recurrence must be selected") : (undefined)),
      recurrenceCountErrorMessageOrUndefined: ((showRecurrenceCountErrorTF) ? ("Must be filled out") : (undefined)),
      runTimeHourErrorMessageOrUndefined: ((showRunTimeHourErrorTF) ? ("Run Time must be filled out") : (undefined)),
      viewButtonErrorMessageOrUndefined: ((showViewButtonErrorTF) ? ("A Capture Table View Button must be selected") : (undefined))
    });
  }

  render() {
    const s_jobName = this.state.s_jobName;
    const s_recurrence = this.state.s_recurrence;
    const s_recurrenceCount = this.state.s_recurrenceCount;
    const s_runTimeHourInt = this.state.s_runTimeHourInt;
    const s_ceViewButtonID = this.state.s_ceViewButtonID;
    const s_createButtonPushedAtLeastOnceTF = this.state.s_createButtonPushedAtLeastOnceTF;
    const s_creatingNewJobTF = this.state.s_creatingNewJobTF;

    const c_automatedExportsSelectRecurrenceFieldTypeObj = this.props.AdminMobx.c_automatedExportsSelectRecurrenceFieldTypeObj;
    const c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj = this.props.CapturesMobx.c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj;
    const c_selectDayOfTheWeekFieldTypeObj = this.props.DatabaseMobx.c_selectDayOfTheWeekFieldTypeObj;
    const c_selectDateOfTheMonth1stTo31stFieldTypeObj = this.props.DatabaseMobx.c_selectDateOfTheMonth1stTo31stFieldTypeObj;
    const c_selectTimeHour1to12AmPmFieldTypeObj = this.props.DatabaseMobx.c_selectTimeHour1to12AmPmFieldTypeObj;

    const inputErrorsObj = this.input_errors_obj();

    var recurrenceCountLabel = "";
    var recurrenceCountContainerMaxWidthEm = 10;
    var recurrenceCountInputComponent = null;
    if(s_recurrence === 1) { //daily
      recurrenceCountLabel = "(Daily) Run Every 'X' Days";
      recurrenceCountContainerMaxWidthEm = 6;
      recurrenceCountInputComponent = (
        <LibraryReact.Text
          p_value={s_recurrenceCount}
          p_styleObj={{width:"6em"}}
          p_errorTF={inputErrorsObj.showRecurrenceCountErrorTF}
          p_tabIndex={3}
          p_title={undefined}
          f_onChange={this.onchange_recurrence_count}
        />
      );
    }
    else if(s_recurrence === 2) { //weekly
      recurrenceCountLabel = "(Weekly) Run on Selected Day";
      recurrenceCountContainerMaxWidthEm = 9;
      recurrenceCountInputComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={c_selectDayOfTheWeekFieldTypeObj}
          p_valueRaw={s_recurrenceCount}
          p_tabIndex={3}
          p_title={undefined}
          p_errorTF={inputErrorsObj.showRecurrenceCountErrorTF}
          f_onChangeOrOnSelect={this.onchange_recurrence_count}
        />
      );
    }
    else if(s_recurrence === 3) { //monthly
      recurrenceCountLabel = "(Monthly) Run on Selected Date";
      recurrenceCountContainerMaxWidthEm = 6;
      recurrenceCountInputComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={c_selectDateOfTheMonth1stTo31stFieldTypeObj}
          p_valueRaw={s_recurrenceCount}
          p_tabIndex={3}
          p_title={undefined}
          p_errorTF={inputErrorsObj.showRecurrenceCountErrorTF}
          f_onChangeOrOnSelect={this.onchange_recurrence_count}
        />
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title="Creating New Automated Export Job"
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex11a displayFlexColumn medFullPad yScroll">
          <NewJobFieldShell p_label="Job Name" p_errorMessage={inputErrorsObj.jobNameErrorMessageOrUndefined}>
            <LibraryReact.Text
              p_value={s_jobName}
              p_tabIndex={1}
              p_title={undefined}
              p_errorTF={inputErrorsObj.showJobNameErrorTF}
              f_onChange={this.onchange_job_name}
            />
          </NewJobFieldShell>
          <NewJobFieldShell p_label="Recurrence" p_errorMessage={inputErrorsObj.recurrenceErrorMessageOrUndefined}>
            <div style={{maxWidth:"7em"}}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={c_automatedExportsSelectRecurrenceFieldTypeObj}
                p_valueRaw={s_recurrence}
                p_tabIndex={2}
                p_title={undefined}
                p_errorTF={inputErrorsObj.showRecurrenceErrorTF}
                f_onChangeOrOnSelect={this.onchange_recurrence}
              />
            </div>
          </NewJobFieldShell>
          <NewJobFieldShell p_label={recurrenceCountLabel} p_errorMessage={inputErrorsObj.recurrenceCountErrorMessageOrUndefined}>
            <div style={{maxWidth:recurrenceCountContainerMaxWidthEm + "em"}}>
              {recurrenceCountInputComponent}
            </div>
          </NewJobFieldShell>
          <NewJobFieldShell p_label="Run at Selected Time (EST)" p_errorMessage={inputErrorsObj.runTimeHourErrorMessageOrUndefined}>
            <div style={{maxWidth:"6em"}}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={c_selectTimeHour1to12AmPmFieldTypeObj}
                p_valueRaw={s_runTimeHourInt}
                p_tabIndex={4}
                p_title={undefined}
                p_errorTF={inputErrorsObj.showRunTimeHourErrorTF}
                f_onChangeOrOnSelect={this.onchange_run_time_hour_int}
              />
            </div>
          </NewJobFieldShell>
          <NewJobFieldShell p_label="Capture Table Columns/Filter/Sort 'View Button'" p_errorMessage={inputErrorsObj.viewButtonErrorMessageOrUndefined}>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj}
              p_valueRaw={s_ceViewButtonID}
              p_tabIndex={5}
              p_title={undefined}
              p_errorTF={inputErrorsObj.showViewButtonErrorTF}
              f_onChangeOrOnSelect={this.onselect_ce_view_button}
            />
          </NewJobFieldShell>
        </div>
        <div className="flex00a displayFlexColumnHcVc flexWrap borderT1bbb tbMedPad">
          {(s_creatingNewJobTF) ? (
            <>
              <CEGeneralReact.LoadingAnimation />
              <div className="microTopMargin">
                <font className="fontItalic fontTextLight">
                  {"Creating new Automated Export job..."}
                </font>
              </div>
            </>
          ) : (
            <div className="flex00a displayFlexRowHcVc">
              <div className="flex00a lrMedMargin">
                <CEGeneralReact.CEButton
                  p_type={((s_createButtonPushedAtLeastOnceTF && inputErrorsObj.anyErrorsTF) ? ("blueDisabled") : ("blue"))}
                  p_text="Create New Job"
                  f_onClick={this.onclick_create_new_automated_export_job}
                />
              </div>
              <div className="flex00a lrMedMargin">
                <CEGeneralReact.CEButton
                  p_type="gray"
                  p_text="Cancel"
                  f_onClick={this.props.f_onClickClose}
                />
              </div>
            </div>
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

function NewJobFieldShell(props) { //props: p_label, p_errorMessage, children
  const p_label = props.p_label;
  const p_errorMessage = props.p_errorMessage;

  const errorTF = JSFUNC.string_is_filled_out_tf(p_errorMessage);

  return(
    <div className="displayFlexRow medBottomMargin">
      <div className="flex00a displayFlexRowVc" style={{flexBasis:"15em", marginRight:"0.4em"}}>
        <font className="fontBlue">
          {p_label}
        </font>
      </div>
      <div className="flex11a displayFlexColumnVc">
        {props.children}
        {(errorTF) &&
          <CEGeneralReact.ErrorText
            p_class="microTopMargin"
            p_text={p_errorMessage}
          />
        }
      </div>
    </div>
  );
}


const AutomatedExportsEditJobFloatingBox = inject("AdminMobx", "CapturesMobx", "DatabaseMobx")(observer(
class AutomatedExportsEditJobFloatingBox extends Component { //props: p_editingAutomatedExportID, f_onClickSave
  onsave_job_name = (i_newValue) => { this.props.AdminMobx.a_automated_exports_update_single_field(this.props.p_editingAutomatedExportID, "job_name", i_newValue, "s"); }
  onsave_recurrence = (i_newValue) => { this.props.AdminMobx.a_automated_exports_update_single_field(this.props.p_editingAutomatedExportID, "recurrence_type_123", i_newValue, "i"); }
  onsave_recurrence_count = (i_newValue) => { this.props.AdminMobx.a_automated_exports_update_single_field(this.props.p_editingAutomatedExportID, "recurrence_count", i_newValue, "i"); }
  onsave_run_time_hour = (i_newValue) => { this.props.AdminMobx.a_automated_exports_update_single_field(this.props.p_editingAutomatedExportID, "run_time_hour_0to23", i_newValue, "i"); }
  onsave_capture_table_view_button = (i_newValue) => { this.props.AdminMobx.a_automated_exports_update_single_field(this.props.p_editingAutomatedExportID, "capture_table_view_button_preset_id", i_newValue, "i"); }

  render() {
    const p_editingAutomatedExportID = this.props.p_editingAutomatedExportID;

    const c_automatedExportsArrayOfObjs = this.props.AdminMobx.c_automatedExportsArrayOfObjs;
    const c_automatedExportsSelectRecurrenceFieldTypeObj = this.props.AdminMobx.c_automatedExportsSelectRecurrenceFieldTypeObj;
    const c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj = this.props.CapturesMobx.c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj;
    const c_genericTextFieldTypeObj = this.props.DatabaseMobx.c_genericTextFieldTypeObj;
    const c_genericIntPositiveFieldTypeObj = this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj;
    const c_selectDayOfTheWeekFieldTypeObj = this.props.DatabaseMobx.c_selectDayOfTheWeekFieldTypeObj;
    const c_selectDateOfTheMonth1stTo31stFieldTypeObj = this.props.DatabaseMobx.c_selectDateOfTheMonth1stTo31stFieldTypeObj;
    const c_selectTimeHour1to12AmPmFieldTypeObj = this.props.DatabaseMobx.c_selectTimeHour1to12AmPmFieldTypeObj;

    const automatedExportObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_automatedExportsArrayOfObjs, "id", p_editingAutomatedExportID);

    if(automatedExportObj === undefined) {
      return(null);
    }

    const containerClass = "smallBottomMargin";
    const fieldClass = "fontBlue";
    const fieldWidth = "10em";

    var recurrenceCountLabel = "";
    var recurrenceCountFieldTypeObj = undefined;
    if(automatedExportObj.recurrence_type_123 === 1) { //daily
      recurrenceCountLabel = "(Daily) Run Every 'X' Days";
      recurrenceCountFieldTypeObj = c_genericIntPositiveFieldTypeObj;
    }
    else if(automatedExportObj.recurrence_type_123 === 2) { //weekly
      recurrenceCountLabel = "(Weekly) Run on Selected Day";
      recurrenceCountFieldTypeObj = c_selectDayOfTheWeekFieldTypeObj;
    }
    else if(automatedExportObj.recurrence_type_123 === 3) { //monthly
      recurrenceCountLabel = "(Monthly) Run on Selected Date";
      recurrenceCountFieldTypeObj = c_selectDateOfTheMonth1stTo31stFieldTypeObj;
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title="Editing Automated Export Job"
        f_onClickSave={this.props.f_onClickSave}>
        <div className="flex11a displayFlexColumn medFullPad yScroll">
          <div className="microBottomMargin textCenter">
            <font className="font11">
              {"Latest File URL"}
            </font>
          </div>
          <div className="bigBottomMargin textCenter">
            <font className="fontItalic">
              {automatedExportObj.latestFileUrl}
            </font>
          </div>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="automatedExportsEditJobName"
            p_fieldDisplayName="Job Name"
            p_fieldTypeObj={c_genericTextFieldTypeObj}
            p_valueRaw={automatedExportObj.job_name}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_job_name}
          />
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="automatedExportsEditRecurrence"
            p_fieldDisplayName="Recurrence"
            p_fieldTypeObj={c_automatedExportsSelectRecurrenceFieldTypeObj}
            p_valueRaw={automatedExportObj.recurrence_type_123}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_recurrence}
          />
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="automatedExportsEditRecurrenceCount"
            p_fieldDisplayName={recurrenceCountLabel}
            p_fieldTypeObj={recurrenceCountFieldTypeObj}
            p_valueRaw={automatedExportObj.recurrence_count}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_recurrence_count}
          />
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="automatedExportsEditRunTime"
            p_fieldDisplayName="Run Time"
            p_fieldTypeObj={c_selectTimeHour1to12AmPmFieldTypeObj}
            p_valueRaw={automatedExportObj.run_time_hour_0to23}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_run_time_hour}
          />
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="automatedExportsEditViewButton"
            p_fieldDisplayName="Capture Table View Button"
            p_fieldTypeObj={c_selectMyAndPublicCaptureTableViewButtonPresetsFieldTypeObj}
            p_valueRaw={automatedExportObj.capture_table_view_button_preset_id}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_capture_table_view_button}
          />
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabExcelReportTemplates = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabExcelReportTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectedExcelReportTemplateID: -1,
      s_showErrorsTF: false
    }
  }

  onselect_template = (i_templateID) => {
    this.setState({s_selectedExcelReportTemplateID:i_templateID});
  }

  onclick_generate_button_set_show_errors = () => {
    this.setState({s_showErrorsTF:true});
  }

  render() {
    const s_selectedExcelReportTemplateID = this.state.s_selectedExcelReportTemplateID;
    const s_showErrorsTF = this.state.s_showErrorsTF;

    const templateValidTF = JSFUNC.select_int_is_filled_out_tf(s_selectedExcelReportTemplateID);

    return (
      <div className="flex11a displayFlexColumn">
        <CEGeneralReact.FileFolderSystem
          p_tblName="tbl_a_excel_report_templates_filefoldersystem"
          p_tblMapOfMaps={this.props.DatabaseMobx.o_tbl_a_excel_report_templates_filefoldersystem}
          p_tblFilterValuesObj={undefined}
          p_title={undefined}
          p_defaultXmlType="excel"
          p_allowedExtsArray={["xml"]}
          p_disableUploadFilesTF={false}
          p_disableCreateOnlineLinkDocsTF={true}
          p_disableCreateFoldersTF={false}
          p_viewWideTrueNarrowFalse={(this.props.CaptureExecMobx.o_mediaQueryFlag >= 4)}
          p_navigateToFolderID={undefined}
          f_onPerformFFSAction={undefined}
          f_onNavigateToFolder={undefined}
        />
        <div className="flex00a displayFlexRow" style={{padding:"1em 0", background:"#aca", borderTop:"solid 2px #585"}}>
          <div className="flex00a displayFlexColumnHcVc lrMedPad" style={{borderRight:"solid 1px #585"}}>
            <ViewAllTemplateCodewordsButtonWithFloatingBox p_excelReportsTF={true} />
          </div>
          <div className="flex11a displayFlexRowVc flexWrap">
            <div className="flex00a tbMicroPad lrMedPad" style={{flexBasis:"25em"}}>
              <div className="microBottomMargin textCenter">
                <font className="fontItalic fontTextLight">
                  {"Select an Excel Report Template to test"}
                </font>
              </div>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectExcelReportTemplateFieldTypeObj}
                p_valueRaw={s_selectedExcelReportTemplateID}
                p_errorTF={(s_showErrorsTF && !templateValidTF)}
                f_onChangeOrOnSelect={this.onselect_template}
              />
            </div>
            <div className="flex00a tbMicroPad lrMedPad" style={{flexBasis:"20em"}}>
              <CEGeneralReact.GenerateAndDownloadExcelReportButtonProcess
                p_excelReportTemplateID={s_selectedExcelReportTemplateID}
                p_buttonText="Generate/Download Test Excel Report"
                p_canClickButtonTF={templateValidTF}
                f_onClick={this.onclick_generate_button_set_show_errors}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabSystem = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabSystem extends Component {
  onsave_fyr_start_month = (i_newValueInt) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("fyr_start_month", i_newValueInt, "i");
  }

  onsave_date_format = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("date_format", i_newValue, "s");
  }

  onsave_capture_name_format = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_name_format", i_newValue, "s");
  }

  onsave_my_performance_all_users_ignore_shared_percent_calcs = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("performance_graphs_all_users_ignore_shared_percent_calcs_01", i_newValue, "i");
  }

  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_companyDateFormat = this.props.DatabaseMobx.c_companyDateFormat;
    const c_companyFyrStartMonth1to12 = this.props.DatabaseMobx.c_companyFyrStartMonth1to12;
    const c_companyCaptureNameFormat = this.props.DatabaseMobx.c_companyCaptureNameFormat;
    const c_companyPerformanceGraphsAllUsersIgnoreSharedPercentCalcs01 = this.props.DatabaseMobx.c_companyPerformanceGraphsAllUsersIgnoreSharedPercentCalcs01;
    const c_fieldMapOfCaptureManagers = this.props.DatabaseMobx.c_fieldMapOfCaptureManagers;
    const c_fieldMapOfDivisionOwners = this.props.DatabaseMobx.c_fieldMapOfDivisionOwners;
    const c_selectMonth1to12FieldTypeObj = this.props.DatabaseMobx.c_selectMonth1to12FieldTypeObj;
    
    const dateFormatsArray = ["m/d/Y", "d/m/Y", "Y-m-d", "M j, Y"];
    const dateFormatsForSelectArray = ["m/d/Y", "d/m/Y", "Y-m-d", "M d, Y"];
    var dateSelectDisplaysArray = [];
    for(let d = 0; d < dateFormatsArray.length; d++) {
      var todayJsDateObj = new Date();
      var todayDateFormatted = JSFUNC.date_or_datetime_format_from_jsdateobj(todayJsDateObj, dateFormatsArray[d], "", false);
      dateSelectDisplaysArray.push(dateFormatsForSelectArray[d] + '  "' + todayDateFormatted + '"');
    }
    const dateFormatSwsOptionsObj = undefined;
    const dateFormatSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Date Format", dateFormatsArray, true, dateSelectDisplaysArray, dateFormatSwsOptionsObj);
    const dateFormatFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", dateFormatSwsDataObj);

    const captureNameFormatsArray = [
      "cn(opp)|cn|opp|id", "opp(cn)|opp|cn|id", "cn|opp|id", "opp|cn|id", "cn|id", "opp|id", "id",
      "[id]cn(opp)|[id]cn|[id]opp|[id]", "[id]opp(cn)|[id]opp|[id]cn|[id]", "[id]cn|[id]opp|[id]", "[id]opp|[id]cn|[id]", "[id]cn|[id]", "[id]opp|[id]"
    ];
    const captureNameSelectDisplaysArray = [
      "Codename (Opportunity Name) | Codename | Opportunity Name | [ID]",
      "Opportunity Name (Codename) | Opportunity Name | Codename | [ID]",
      "Codename | Opportunity Name | [ID]",
      "Opportunity Name | Codename | [ID]",
      "Codename | [ID]",
      "Opportunity Name | [ID]",
      "[ID]",
      "[ID] Codename (Opportunity Name) | [ID] Codename | [ID] Opportunity Name",
      "[ID] Opportunity Name (Codename) | [ID] Opportunity Name | [ID] Codename",
      "[ID] Codename | [ID] Opportunity Name",
      "[ID] Opportunity Name | [ID] Codename",
      "[ID] Codename",
      "[ID] Opportunity Name"
    ];
    const captureNameFormatSwsOptionsObj = undefined;
    const captureNameFormatSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Capture Name Format", captureNameFormatsArray, true, captureNameSelectDisplaysArray, captureNameFormatSwsOptionsObj);
    const captureNameFormatFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", captureNameFormatSwsDataObj);

    const mySummariesSharedOwnershipOptionsDisplayArray = ["[default] All 'Performance' calculations are proportional to percent ownership per Capture", "All 'Performance' calculations use whole Capture counts and dollars, regardless of percent ownership"];
    const myPerformanceAllUsersIgnoreSharedPercentCalcsFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Performance Shared Ownership Option", [0, 1], false, mySummariesSharedOwnershipOptionsDisplayArray, 3);

    const containerClass = "tbMargin";
    const fieldClass = "font11 fontBlue";
    const fieldWidth = "18em";
    const valueClass = "font11";

    return(
      <div className="flex11a xyScroll yScrollBottomPad medFullPad">
        <div className="medFullPad border1bbb" style={{width:"65em"}}>
          <div className="">
            <font className="font14 fontItalic fontTextLight">
              {c_productStylingObj.productName + " System Options"}
            </font>
          </div>
          <SystemSectionTitle p_text="Dates" />
          <div style={{width:"40em"}}>
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString="adminSystemFYRStartMonth"
              p_fieldDisplayName="Fiscal Year (FYR) Start Month"
              p_fieldTypeObj={c_selectMonth1to12FieldTypeObj}
              p_valueRaw={c_companyFyrStartMonth1to12}
              p_valueIsEditableTFU={true}
              p_containerClass={containerClass}
              p_fieldClass={fieldClass}
              p_fieldWidth={fieldWidth}
              p_valueClass={valueClass}
              f_onSaveChanged={this.onsave_fyr_start_month}
            />
          </div>
          <div style={{width:"40em"}}>
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString="adminSystemDateFormat"
              p_fieldDisplayName="Date Format"
              p_fieldTypeObj={dateFormatFieldTypeObj}
              p_valueRaw={c_companyDateFormat}
              p_valueIsEditableTFU={true}
              p_containerClass={containerClass}
              p_fieldClass={fieldClass}
              p_fieldWidth={fieldWidth}
              p_valueClass={valueClass}
              f_onSaveChanged={this.onsave_date_format}
            />
          </div>
          <SystemSectionTitle p_text="Captures" />
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="adminSystemCaptureNameFormat"
            p_fieldDisplayName="Capture Name Format"
            p_fieldTypeObj={captureNameFormatFieldTypeObj}
            p_valueRaw={c_companyCaptureNameFormat}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            p_valueClass={valueClass}
            f_onSaveChanged={this.onsave_capture_name_format}
          />
          <SystemSectionTitle p_text="Executive Management/My Summaries - 'Performance' Tab Calculations" />
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="adminSystemMyPerformanceAllUsrsIgnoreSharedPercentCalcs"
            p_fieldDisplayName={"Shared Ownership Calculations (" + c_fieldMapOfCaptureManagers.get("display_name") + ", " + c_fieldMapOfDivisionOwners.get("display_name") + ")"}
            p_fieldTypeObj={myPerformanceAllUsersIgnoreSharedPercentCalcsFieldTypeObj}
            p_valueRaw={c_companyPerformanceGraphsAllUsersIgnoreSharedPercentCalcs01}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            p_valueClass={valueClass}
            f_onSaveChanged={this.onsave_my_performance_all_users_ignore_shared_percent_calcs}
          />
        </div>
        <div style={{marginTop:"4em"}} />
        <VerifyCompanyDomainForCEEmails />
      </div>
    );
  }
}));

function SystemSectionTitle(props) { //props: p_text
  return(
    <div className="microBottomMargin borderB1ddd microBottomPad" style={{marginTop:"1.8em"}}>
      <font className="font12 fontBold fontTextLighter">
        {props.p_text}
      </font>
    </div>
  );
}

const VerifyCompanyDomainForCEEmails = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class VerifyCompanyDomainForCEEmails extends Component {
  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_bitCompanyDomainsDotExtsArray = this.props.DatabaseMobx.c_bitCompanyDomainsDotExtsArray;

    return(
      <div className="medFullPad border1bbb" style={{width:"65em"}}>
        <div className="smallBottomMargin">
          <font className="font14 fontItalic fontTextLight">
            {"Verify Company Domain(s) for Automated Emails"}
          </font>
        </div>
        <div className="smallBottomMargin">
          <font className="fontItalic fontTextLighter">
            {c_productStylingObj.productName + " can send automated emails out to external recipients using the Teammate Survey system within each Capture. If a " + c_productStylingObj.productName + " User in the system has an email address that matches one of the domains listed below for your company, these emails will be sent from their email address, which the recipients can directly reply to."}
          </font>
        </div>
        <div className="smallBottomMargin">
          <font className="fontItalic fontTextLighter">
            {"If you need to modify or add to this list for your company, please email "}
          </font>
          {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj("support@bitsolutionsllc.com", this.props.DatabaseMobx.c_genericEmailFieldTypeObj)}
          <font className="fontItalic fontTextLighter">
            {". Please note that only company domains can be used, thus 'gmail.com' or 'yahoo.com' are not valid. If a " + c_productStylingObj.productName + " User has one of these invalid email addresses, their emails will be sent from the generic '" + c_productStylingObj.productAutomatedEmailFromEmailAddress + "'."}
          </font>
        </div>
        <div className="medBottomMargin" />
        {c_bitCompanyDomainsDotExtsArray.map((m_companyDomainDotExt, m_index) =>
          <div
            key={m_index}
            className="smallTopMargin border1ddd borderRadius05 bgLightestBlue tbPad lrMedPad"
            style={{width:"30em"}}>
            <font className="">
              {"@" + m_companyDomainDotExt}
            </font>
          </div>
        )}
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabShortcutPresets = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabShortcutPresets extends Component {
  onclick_shortcut_presets_tab = (i_selectedTabDbName) => {
    this.props.AdminMobx.a_shortcut_presets_set_selected_tab_db_name(i_selectedTabDbName);
  }

  onsave_shortcut_presets_capture_managers_on_cnc = (i_newValueInt) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("shortcut_presets_capture_managers_on_cnc_01", i_newValueInt, "i");
  }

  onsave_shortcut_presets_documents_card_folders_on_cnc = (i_newValueInt) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("shortcut_presets_documents_card_folders_on_cnc_01", i_newValueInt, "i");
  }

  onconfirm_create_new_documents_card_folders_shortcut_preset = (i_newPresetName) => {
    this.props.AdminMobx.a_shortcut_presets_documents_create_new_preset(i_newPresetName);
  }

  onclick_close_editing_preset = () => {
    this.props.AdminMobx.a_shortcut_presets_set_documents_card_folders_editing_preset_id_or_undefined(undefined);
  }

  render() {
    const o_shortcutPresetsSelectedTabDbName = this.props.AdminMobx.o_shortcutPresetsSelectedTabDbName;
    const o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined = this.props.AdminMobx.o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined;
    const c_shortcutPresetsCaptureManagersArrayOfObjs = this.props.AdminMobx.c_shortcutPresetsCaptureManagersArrayOfObjs;
    const c_shortcutPresetsDocumentsCardFoldersPresetsArrayOfObjs = this.props.AdminMobx.c_shortcutPresetsDocumentsCardFoldersPresetsArrayOfObjs;
    const c_shortcutPresetsDocumentsCardFoldersEditingPresetObj = this.props.AdminMobx.c_shortcutPresetsDocumentsCardFoldersEditingPresetObj;
    const o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem = this.props.DatabaseMobx.o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem;
    const c_companyShortcutPresetsCaptureManagersOnCreateNewCapture01 = this.props.DatabaseMobx.c_companyShortcutPresetsCaptureManagersOnCreateNewCapture01;
    const c_companyShortcutPresetsCaptureManagersOnCreateNewCaptureTF = this.props.DatabaseMobx.c_companyShortcutPresetsCaptureManagersOnCreateNewCaptureTF;
    const c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCapture01 = this.props.DatabaseMobx.c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCapture01;
    const c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF = this.props.DatabaseMobx.c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF;
    const c_cardNameDocuments = this.props.DatabaseMobx.c_cardNameDocuments;
    const c_fieldMapOfCaptureManagers = this.props.DatabaseMobx.c_fieldMapOfCaptureManagers;

    const captureManagersFieldDisplayName = c_fieldMapOfCaptureManagers.get("display_name");
    const captureManagersFieldTypeObj = c_fieldMapOfCaptureManagers.get("fieldTypeObj");

    var shortcutPresetsContentComponent = null;
    if(o_shortcutPresetsSelectedTabDbName === "captureTeam") {
      const captureManagersOffMessage = "[default] Turned off, always manually choose Capture Manager(s) when creating a new Capture";
      const captureManagersOnMessage = "On, you can either select one of the presets in the table below, or manually fill out the " + captureManagersFieldDisplayName + " field";
      const shortCutPresetsCaptureManagersOnCreateNewCaptureFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Capture Manager Shortcut Preset Option", [0, 1], false, [captureManagersOffMessage, captureManagersOnMessage], 2);

      const shortcutPresetsCaptureManagersAedTableObj = {
        tblName: "tbl_a_shortcut_presets_capture_managers",
        dragToResortFieldDbName: "sort",
        initialSortedFieldDbName: "sort",
        tableWidth: "80em",
        itemName: captureManagersFieldDisplayName + " Shortcut Preset",
        deleteItemNameFieldDbName: "preset_name",
        fieldsArrayOfObjs: [
          {width:"100em", dbName:"preset_name", displayName:"Preset Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
          {width:"250em", dbName:"user_ids_colon_percents_comma", displayName:"Preset " + captureManagersFieldDisplayName, fieldTypeObj:captureManagersFieldTypeObj, initialValue:"", cellHoverTF:true},
          {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
        ]
      };

      shortcutPresetsContentComponent = (
        <div className="flex11a xyScroll medFullPad">
          <div className="microBottomMargin">
            <font className="font11 fontItalic fontTextLight">
              {"Optional 'Shortcut Presets' for filling out " + captureManagersFieldDisplayName + " when creating a new Capture from the 'Captures' tab"}
            </font>
          </div>
          <div style={{width:"100%", maxWidth:"55em"}}>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={shortCutPresetsCaptureManagersOnCreateNewCaptureFieldTypeObj}
              p_valueRaw={c_companyShortcutPresetsCaptureManagersOnCreateNewCapture01}
              f_onChangeOrOnSelect={this.onsave_shortcut_presets_capture_managers_on_cnc}
            />
          </div>
          {(c_companyShortcutPresetsCaptureManagersOnCreateNewCaptureTF) &&
            <>
              <div className="hugeTopMargin" />
              <AddEditDeleteTable
                p_dataArrayOfObjs={c_shortcutPresetsCaptureManagersArrayOfObjs}
                p_aedTableObj={shortcutPresetsCaptureManagersAedTableObj}
              />
            </>
          }
        </div>
      );
    }
    else {
      const documentFoldersOffMessage = "[default] Always empty " + c_cardNameDocuments + " Card when creating a new Capture";
      const documentFoldersOnMessage = "Offer presets created below with a predefined folder structure";
      const shortCutPresetsDocumentsCardFoldersOnCreateNewCaptureFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Capture Manager Shortcut Preset Option", [0, 1], false, [documentFoldersOffMessage, documentFoldersOnMessage], 2);

      const numDocumentsCardFoldersPresets = c_shortcutPresetsDocumentsCardFoldersPresetsArrayOfObjs.length;

      const documentsShortcutPresetOnOffComponent = (
        <>
          <div className="microBottomMargin">
            <font className="font11 fontItalic fontTextLight">
              {"Optional 'Shortcut Presets' to create a predefined structure of empty folders in the " + c_cardNameDocuments + " Card when a new Capture is created"}
            </font>
          </div>
          <div style={{width:"100%", maxWidth:"40em"}}>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={shortCutPresetsDocumentsCardFoldersOnCreateNewCaptureFieldTypeObj}
              p_valueRaw={c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCapture01}
              f_onChangeOrOnSelect={this.onsave_shortcut_presets_documents_card_folders_on_cnc}
            />
          </div>
        </>
      );

      if(!c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF) {
        shortcutPresetsContentComponent = (
          <div className="flex11a displayFlexColumn medFullPad yScroll">
            {documentsShortcutPresetOnOffComponent}
          </div>
        );
      }
      else {
        shortcutPresetsContentComponent = (
          <div className="flex11a displayFlexRow">
            <div className="flex11a displayFlexColumn borderR1bbb medFullPad yScroll" style={{flexBasis:"100em"}}>
              {documentsShortcutPresetOnOffComponent}
              <div className="medTopMargin border1bbb bigTopPad smallBottomPad lrPad" style={{background:"#dce"}}>
                <div className="displayFlexColumnHcVc medBottomMargin">
                  <CEGeneralReact.ButtonWithConfirmBox
                    p_buttonType="blue"
                    p_buttonText="Create a New Shortcut Preset of Folders"
                    p_buttonFontClass={undefined}
                    p_tabIndex={undefined}
                    p_title={"Add a new Shortcut Preset when creating a new Capture to fill the " + c_cardNameDocuments + " Card with a predefined structure of empty folders"}
                    p_confirmType="inputText"
                    p_confirmMessage={"Enter a name for a new " + c_cardNameDocuments + " Card folders Shortcut Preset"}
                    p_button1Name="Create New Preset"
                    p_textMustBeFilledOutTF={true}
                    f_onClickConfirm={this.onconfirm_create_new_documents_card_folders_shortcut_preset}
                  />
                </div>
                {(numDocumentsCardFoldersPresets === 0) ? (
                  <div className="smallTopPad bigBottomPad lrMedPad textCenter">
                    <font className="fontItalic fontTextLight">
                      {"--No " + c_cardNameDocuments + " Card folders presets have been created yet--"}
                    </font>
                  </div>
                ) : (
                  c_shortcutPresetsDocumentsCardFoldersPresetsArrayOfObjs.map((m_documentsCardFoldersPresetObj, m_index) =>
                    <ShortcutPresetsDocumentsCardFoldersPresetItem
                      key={m_documentsCardFoldersPresetObj.id}
                      p_documentsCardFoldersPresetObj={m_documentsCardFoldersPresetObj}
                      p_isOnlyItemTF={(numDocumentsCardFoldersPresets === 1)}
                      p_isLastItemTF={(m_index === (numDocumentsCardFoldersPresets - 1))}
                    />
                  )
                )}
              </div>
            </div>
            <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
              {(JSFUNC.is_number_not_nan_gt_0(o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined)) ? (
                <>
                  <div className="flex00a displayFlexRowVc bgPurpleGradient tbPad">
                    <div className="flex11a lrMedPad">
                      <font className="font11 fontBold fontWhite">
                        {"Editing folder structure for Shortcut Preset '" + c_shortcutPresetsDocumentsCardFoldersEditingPresetObj.preset_name + "'"}
                      </font>
                    </div>
                    <div className="lrPad">
                      <CEGeneralReact.CEButton
                        p_type="gray"
                        p_text="Close"
                        f_onClick={this.onclick_close_editing_preset}
                      />
                    </div>
                  </div>
                  <CEGeneralReact.FileFolderSystem
                    p_tblName="tbl_a_shortcut_presets_documents_card_folders_filefoldersystem"
                    p_tblMapOfMaps={o_tbl_a_shortcut_presets_documents_card_folders_filefoldersystem}
                    p_tblFilterValuesObj={{shortcut_preset_id:o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined}}
                    p_title={undefined}
                    p_defaultXmlType={undefined}
                    p_allowedExtsArray={undefined}
                    p_disableUploadFilesTF={true}
                    p_disableCreateOnlineLinkDocsTF={true}
                    p_disableCreateFoldersTF={false}
                    p_viewWideTrueNarrowFalse={false}
                    p_navigateToFolderID={undefined}
                    f_onPerformFFSAction={undefined}
                    f_onNavigateToFolder={undefined}
                  />
                </>
              ) : (
                <CEGeneralReact.EmptyScreenGray>
                  {"Select a Preset to create/edit its structure of folders"}
                </CEGeneralReact.EmptyScreenGray>
              )}
            </div>
          </div>
        );
      }
    }

    return(
      <>
        <div className="flex00a borderB1bbb tbPad lrMedPad xScroll">
          <CEGeneralReact.TabsList
            p_tabDbNamesArray={["captureTeam", "documentsCard"]}
            p_tabDisplayNamesArray={[captureManagersFieldDisplayName, c_cardNameDocuments + " Card"]}
            p_selectedTabDbName={o_shortcutPresetsSelectedTabDbName}
            p_tabHeight="1.8em"
            p_textMaxHeight="1.8em"
            p_tabWidth="18em"
            p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
            p_unselectedFontClass="fontBold fontTextLight"
            p_selectedBgClass="bgDarkRedGradient"
            p_selectedFontClass="fontBold fontWhite"
            f_onSelect={this.onclick_shortcut_presets_tab}
          />
        </div>
        {shortcutPresetsContentComponent}
      </>
    );
  }
}));



const ShortcutPresetsDocumentsCardFoldersPresetItem = inject("AdminMobx", "DatabaseMobx")(observer(
class ShortcutPresetsDocumentsCardFoldersPresetItem extends Component { //props: p_documentsCardFoldersPresetObj, p_isOnlyItemTF, p_isLastItemTF
  onclick_preset_open_ffs_folders_editor = () => {
    const p_documentsCardFoldersPresetObj = this.props.p_documentsCardFoldersPresetObj;

    const o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined = this.props.AdminMobx.o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined;

    var selectedDocumentsCardFoldersPresetIDOrUndefined = p_documentsCardFoldersPresetObj.id;
    if(p_documentsCardFoldersPresetObj.id === o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined) {
      selectedDocumentsCardFoldersPresetIDOrUndefined = undefined;
    }
    this.props.AdminMobx.a_shortcut_presets_set_documents_card_folders_editing_preset_id_or_undefined(selectedDocumentsCardFoldersPresetIDOrUndefined);
  }

  onconfirm_rename_documents_card_folders_preset_item = (i_newPresetNameString) => {
    const p_documentsCardFoldersPresetObj = this.props.p_documentsCardFoldersPresetObj;
    this.props.AdminMobx.a_shortcut_presets_rename_documents_card_folders_preset(p_documentsCardFoldersPresetObj.id, i_newPresetNameString);
  }

  ondelete_documents_card_folders_preset_item = () => {
    const p_documentsCardFoldersPresetObj = this.props.p_documentsCardFoldersPresetObj;

    if(p_documentsCardFoldersPresetObj !== undefined) {
      //set that no preset is being edited anymore
      this.props.AdminMobx.a_shortcut_presets_set_documents_card_folders_editing_preset_id_or_undefined(undefined);

      //delete all folders under this preset, then the preset record itself
      this.props.AdminMobx.a_shortcut_presets_delete_documents_card_folders_preset(p_documentsCardFoldersPresetObj);
    }
  }

  render() {
    const p_documentsCardFoldersPresetObj = this.props.p_documentsCardFoldersPresetObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;
    
    const o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined = this.props.AdminMobx.o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined;
    const c_cardNameDocuments = this.props.DatabaseMobx.c_cardNameDocuments;

    const documentsCardFoldersPresetIsEditingTF = (p_documentsCardFoldersPresetObj.id === o_shortcutPresetsDocumentsCardFoldersEditingPresetIDOrUndefined);
    const hoverText = p_documentsCardFoldersPresetObj.preset_name + "\n[Click to add/edit the structure of folders for this preset]";

    const documentsCardFoldersPresetMenuItemsArrayOfObjs = [
      {
        displayName: "Rename Preset",
        confirmType: "inputText",
        confirmTitle: "Rename Preset",
        confirmMessage: "Enter a new name for this preset '" + p_documentsCardFoldersPresetObj.preset_name + "'",
        confirmTextInitialValue: p_documentsCardFoldersPresetObj.preset_name,
        confirmTextMustBeFilledOutTF: true,
        confirmButton1Name: "Rename",
        functionOnClickConfirmButton: this.onconfirm_rename_documents_card_folders_preset_item
      },
      {
        displayName: "Delete Preset",
        confirmType: "confirmDelete",
        confirmTitle: "Delete Preset",
        confirmMessage: "Delete " + c_cardNameDocuments + " Card Folders Shortcut Preset '" + p_documentsCardFoldersPresetObj.preset_name + "'?",
        functionOnClickConfirmButton: this.ondelete_documents_card_folders_preset_item
      }
    ];

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="documentsCardFoldersPresetItem"
        p_itemID={p_documentsCardFoldersPresetObj.id}
        p_itemSort={p_documentsCardFoldersPresetObj.sort}
        p_itemSortColumnWidth={undefined}
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="lrPad microTopPad"
        p_itemClass="border bevelBorderColors bgAlmostWhite hoverLighterBlueGradient"
        p_itemStyleObj={{height:"2.4em"}}
        p_lastItemExtraDropZoneHeight="1.8em"
        p_tblName="tbl_a_shortcut_presets_documents_card_folders"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={undefined}
        p_filterValuesArray={undefined}>
        <div
          className={"flex11a displayFlexRow cursorPointer " + ((documentsCardFoldersPresetIsEditingTF) ? ("bgDarkPurpleGradient") : (""))}
          title={hoverText}
          onClick={this.onclick_preset_open_ffs_folders_editor}>
          <div className="flex11a displayFlexRowVc lrMedPad">
            <LibraryReact.Nowrap p_fontClass={((documentsCardFoldersPresetIsEditingTF) ? ("fontWhite") : (""))}>
              {(documentsCardFoldersPresetIsEditingTF) &&
                <font className="fontItalic">{"[editing] "}</font>
              }
              {p_documentsCardFoldersPresetObj.preset_name}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"7em"}}>
            <font className={"fontItalic " + ((documentsCardFoldersPresetIsEditingTF) ? ("fontAlmostWhite") : ("fontTextLight"))}>
              {p_documentsCardFoldersPresetObj.numFolders + " " + JSFUNC.plural(p_documentsCardFoldersPresetObj.numFolders, "Folder", "Folders")}
            </font>
          </div>
        </div>
        <div className="flex00a borderL1ddd displayFlexColumnHcVc" style={{flexBasis:"2.4em"}}>
          <CEGeneralReact.VerticalDotsMenu
            p_menuItemsArrayOfObjs={documentsCardFoldersPresetMenuItemsArrayOfObjs}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));



//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabCaptureArchiving = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabCaptureArchiving extends Component {
  onswitch_company_using_automated_capture_archiving = () => {
    const c_companyUsingAutomatedCaptureArchivingTF = this.props.DatabaseMobx.c_companyUsingAutomatedCaptureArchivingTF;
    const c_fieldMapOfAddedDate = this.props.DatabaseMobx.c_fieldMapOfAddedDate;
    const c_closedNoBidStageIDsArray = this.props.DatabaseMobx.c_closedNoBidStageIDsArray;
    const c_closedCancelledStageIDsArray = this.props.DatabaseMobx.c_closedCancelledStageIDsArray;

    const updatedValue01 = ((c_companyUsingAutomatedCaptureArchivingTF) ? (0) : (1));
    var companyFieldNamesArray = ["using_automated_capture_archiving_01"];
    var companyUpdatedValuesArray = [updatedValue01];
    var companyIdsbArray = ["i"];
    if(updatedValue01 === 1) { //if turning the switch on, set default values for the num days and set the selected date field to Added Date
      //get all stageIDs that are no bid and cancelled types
      const noBidAndCancelledStageIDsArray = JSFUNC.merge_unique(c_closedNoBidStageIDsArray, c_closedCancelledStageIDsArray);
      const noBidAndCancelledStageIDsComma = JSFUNC.convert_array_to_comma_list(noBidAndCancelledStageIDsArray);

      companyFieldNamesArray = ["using_automated_capture_archiving_01", "capture_archiving_closed_stage_ids_comma", "capture_archiving_num_days_after_date", "capture_archiving_date_field_id", "capture_archiving_num_days_no_activity"];
      companyUpdatedValuesArray = [updatedValue01, noBidAndCancelledStageIDsComma, 720, c_fieldMapOfAddedDate.get("id"), 90];
      companyIdsbArray = ["i", "s", "i", "i", "i"];
    }
    this.props.AdminMobx.a_update_tbl_a_company_fields(companyFieldNamesArray, companyUpdatedValuesArray, companyIdsbArray);
  }

  onsave_capture_archive_closed_stages = (i_newValueCommaListString) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_archiving_closed_stage_ids_comma", i_newValueCommaListString, "s");
  }

  onsave_capture_archive_num_days_after_date = (i_newValueInt) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_archiving_num_days_after_date", i_newValueInt, "i");
  }

  onsave_capture_archive_date_field = (i_newCaptureDateFieldID) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_archiving_date_field_id", i_newCaptureDateFieldID, "i");
  }

  onsave_capture_archive_num_days_no_activity = (i_newValueInt) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_archiving_num_days_no_activity", i_newValueInt, "i");
  }

  onswitch_capture_archive_send_gcss_update_reminders = () => {
    const c_companyCaptureArchivingSendGcssUpdateRemindersTF = this.props.DatabaseMobx.c_companyCaptureArchivingSendGcssUpdateRemindersTF;
    const newValue01 = ((c_companyCaptureArchivingSendGcssUpdateRemindersTF) ? (0) : (1));
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_archiving_send_gcss_update_reminders_01", newValue01, "i");
  }

  onswitch_capture_archive_send_task_reminders = () => {
    const c_companyCaptureArchivingSendTaskRemindersTF = this.props.DatabaseMobx.c_companyCaptureArchivingSendTaskRemindersTF;
    const newValue01 = ((c_companyCaptureArchivingSendTaskRemindersTF) ? (0) : (1));
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_archiving_send_task_reminders_01", newValue01, "i");
  }

  onswitch_capture_archive_send_automated_reminders = () => {
    const c_companyCaptureArchivingSendAutomatedRemindersTF = this.props.DatabaseMobx.c_companyCaptureArchivingSendAutomatedRemindersTF;
    const newValue01 = ((c_companyCaptureArchivingSendAutomatedRemindersTF) ? (0) : (1));
    this.props.AdminMobx.a_update_tbl_a_company_fields("capture_archiving_send_automated_reminders_01", newValue01, "i");
  }

  render() {
    const c_companyUsingAutomatedCaptureArchivingTF = this.props.DatabaseMobx.c_companyUsingAutomatedCaptureArchivingTF;
    const c_companyCaptureArchivingClosedStageIDsComma = this.props.DatabaseMobx.c_companyCaptureArchivingClosedStageIDsComma;
    const c_companyCaptureArchivingNumDaysAfterDate = this.props.DatabaseMobx.c_companyCaptureArchivingNumDaysAfterDate;
    const c_companyCaptureArchivingDateFieldID = this.props.DatabaseMobx.c_companyCaptureArchivingDateFieldID;
    const c_companyCaptureArchivingNumDaysNoActivity = this.props.DatabaseMobx.c_companyCaptureArchivingNumDaysNoActivity;
    const c_companyCaptureArchivingSendGcssUpdateRemindersTF = this.props.DatabaseMobx.c_companyCaptureArchivingSendGcssUpdateRemindersTF;
    const c_companyCaptureArchivingSendTaskRemindersTF = this.props.DatabaseMobx.c_companyCaptureArchivingSendTaskRemindersTF;
    const c_companyCaptureArchivingSendAutomatedRemindersTF = this.props.DatabaseMobx.c_companyCaptureArchivingSendAutomatedRemindersTF;

    const archivingInfoBulletListArray = [
      "Each User has the choice in their User Settings to always load archived Captures, be prompted with a choice every time, or to never load them",
      "Not loading archived Captures has the advantage of speeding up load time of your system, and reducing searches and data from Captures that are not relevant anymore",
      "Divexecs may wish to always load archived Captures to fill the historical data of their analysis charts"
    ];

    return (
      <div className="flex11a xyScroll medFullPad">
        <div className="displayFlexRowVc border1bbb medFullPad bgLightestGray" style={{width:"90em"}}>
          <div className="flex00" style={{flexBasis:"13em"}}>
            <font className="font14">
              <div>{"Using Automated"}</div>
              <div>{"Capture Archiving?"}</div>
            </font>
          </div>
          <div className="flex00">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={c_companyUsingAutomatedCaptureArchivingTF}
              p_sizeEm={5}
              f_onSwitch={this.onswitch_company_using_automated_capture_archiving}
            />
          </div>
          <div className="flex11a" style={{marginLeft:"5em"}}>
            <div className="smallBottomMargin">
              <font className="font12 fontItalic">
                {"Capture Archiving General Info:"}
              </font>
            </div>
            <CEGeneralReact.BulletList
              p_linesArray={archivingInfoBulletListArray}
              p_fontClass="fontItalic fontTextLight"
              p_dashFontClass="fontItalic fontTextLight"
            />
          </div>
        </div>
        {(c_companyUsingAutomatedCaptureArchivingTF) &&
          <div className="medTopMargin displayFlexRow" style={{width:"90em"}}>
            <div className="flex11a border1bbb medFullPad" style={{flexBasis:"175em"}}>
              <div className="">
                <font className="font12 fontBold fontTextLight">
                  {"Captures will be automatically marked for archiving following these rules at 5am ET every morning:"}
                </font>
              </div>
              <div className="medTopMargin lrMedPad">
                <div className="displayFlexRowVc">
                  <div className="flex00a" style={{flexBasis:"26em"}}>
                    <font className="">
                      {"1) Capture must be in one of the selected Closed Stages:"}
                    </font>
                  </div>
                  <div className="flex11a">
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString="adminCaptureArchivingClosedStagesField"
                      p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiStagesFieldTypeObj}
                      p_valueRaw={c_companyCaptureArchivingClosedStageIDsComma}
                      p_valueIsEditableTFU={true}
                      p_valuesToNotIncludeArray={this.props.DatabaseMobx.c_activeStageIDsArray}
                      p_containerClass="border1ddd"
                      f_onSaveChanged={this.onsave_capture_archive_closed_stages}
                    />
                  </div>
                </div>
                <div className="tbMargin lrMedPad">
                  <font className="fontBold fontItalic fontDarkBlue">
                    {"AND"}
                  </font>
                </div>
                <div className="displayFlexRowVc">
                  <div className="flex00a" style={{flexBasis:"6em"}}>
                    <font className="">
                      {"2) Capture is "}
                    </font>
                  </div>
                  <div className="flex00a" style={{flexBasis:"11em"}}>
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString="adminCaptureArchivingNumDaysAfterDate"
                      p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
                      p_valueRaw={c_companyCaptureArchivingNumDaysAfterDate}
                      p_valueIsEditableTFU={true}
                      p_containerClass="border1ddd"
                      f_onSaveChanged={this.onsave_capture_archive_num_days_after_date}
                    />
                  </div>
                  <div className="flex00a lrPad" style={{flexBasis:"8em"}}>
                    <font className="">
                      {"days past date"}
                    </font>
                  </div>
                  <div className="flex11a">
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString="adminCaptureArchivingDateField"
                      p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureDateFieldFieldTypeObj}
                      p_valueRaw={c_companyCaptureArchivingDateFieldID}
                      p_valueIsEditableTFU={true}
                      p_containerClass="border1ddd"
                      f_onSaveChanged={this.onsave_capture_archive_date_field}
                    />
                  </div>
                </div>
                <div className="tbMargin lrMedPad">
                  <font className="fontBold fontItalic fontDarkBlue">
                    {"AND"}
                  </font>
                </div>
                <div className="displayFlexRowVc">
                  <div className="flex00a" style={{flexBasis:"6em"}}>
                    <font className="">
                      {"3) Capture is "}
                    </font>
                  </div>
                  <div className="flex00a" style={{flexBasis:"11em"}}>
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString="adminCaptureArchivingNumDaysNoActivity"
                      p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
                      p_valueRaw={c_companyCaptureArchivingNumDaysNoActivity}
                      p_valueIsEditableTFU={true}
                      p_containerClass="border1ddd"
                      f_onSaveChanged={this.onsave_capture_archive_num_days_no_activity}
                    />
                  </div>
                  <div className="flex11a lrPad">
                    <font className="">
                      {"days with no recent activity"}
                    </font>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex00a" style={{flexBasis:"2em"}} />
            <div className="flex11a border1bbb medFullPad" style={{flexBasis:"100em"}}>
              <div className="medBottomMargin">
                <font className="font12 fontBold fontTextLight">
                  {"Reminders when Archived:"}
                </font>
              </div>
              <div className="medBottomMargin">
                <CEGeneralReact.SwitchWithTextAndConfirmBox
                  p_isOnTF={c_companyCaptureArchivingSendGcssUpdateRemindersTF}
                  p_sizeEm={3.3}
                  p_onText="Reminders will be sent"
                  p_offText="Reminders will not be sent"
                  p_labelText="GCSS Updates"
                  p_labelFontClass=""
                  p_labelWidthEm={11}
                  p_title="When this switch is on, Capture Team members will receive system notifications whenever SAM.gov or GovWin publish data updates, even though the Capture is currently archived. Turning this switch off disables these notifications for Captures that are archived."
                  f_onSwitch={this.onswitch_capture_archive_send_gcss_update_reminders}
                />
              </div>
              <div className="medBottomMargin">
                <CEGeneralReact.SwitchWithTextAndConfirmBox
                  p_isOnTF={c_companyCaptureArchivingSendTaskRemindersTF}
                  p_sizeEm={3.3}
                  p_onText="Reminders will be sent"
                  p_offText="Reminders will not be sent"
                  p_labelText="Task Reminders"
                  p_labelFontClass=""
                  p_labelWidthEm={11}
                  p_title="When this switch is on, Task participants will receive pre/post reminders, even for archived Captures. Turning this switch off disables these reminders for Captures that are archived."
                  f_onSwitch={this.onswitch_capture_archive_send_task_reminders}
                />
              </div>
              <div className="medBottomMargin">
                <CEGeneralReact.SwitchWithTextAndConfirmBox
                  p_isOnTF={c_companyCaptureArchivingSendAutomatedRemindersTF}
                  p_sizeEm={3.3}
                  p_onText="Reminders will be sent"
                  p_offText="Reminders will not be sent"
                  p_labelText="Automated Reminders"
                  p_labelFontClass=""
                  p_labelWidthEm={11}
                  p_title="When this switch is on, Capture Team members will receive Automated Reminders set up by the Admin whenever they are triggered by the system, even for archived Captures. Turning this switch off disables these reminders for Captures that are archived."
                  f_onSwitch={this.onswitch_capture_archive_send_automated_reminders}
                />
              </div>
            </div>
          </div>
        }
        <div style={{marginTop:"5em"}} />
        <CaptureArchivingArchivedCapturesSearchResultsTable />
      </div>
    );
  }
}));

const CaptureArchivingArchivedCapturesSearchResultsTable = inject("AdminMobx", "CapturesMobx", "DatabaseMobx", "UserMobx")(observer(
class CaptureArchivingArchivedCapturesSearchResultsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_loadingAllArchivedCapturesInSeparateFetchTF: false,
      s_checkedCaptureIDsArray: [],
      s_unarchiveWorkingMessageOrUndefined: undefined
    };
  }

  onclick_admin_load_archived_captures_into_the_system = () => {
    const o_userID = this.props.UserMobx.o_userID;

    this.setState({s_loadingAllArchivedCapturesInSeparateFetchTF:true});

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - CaptureArchivingArchivedCapturesSearchResultsTable", "onclick_admin_load_archived_captures_into_the_system", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadAllArchivedCapturesSlim", jsDescription);
    C_CallPhpScript.add_post_var("i_userID", o_userID);
    C_CallPhpScript.add_return_vars("ceCompressedArchivedSlimCapturesMatrix");

    const functionOnSuccess = (i_parseResponse) => {
      //uncompress the CE compressed data matrix from php (raw rows from tbl_captures)
      const archivedSlimCapturesArrayOfObjs = this.props.DatabaseMobx.uncompress_ce_compressed_data_table_arrayOfObjs(i_parseResponse.ceCompressedArchivedSlimCapturesMatrix);
      this.props.AdminMobx.a_capture_archving_set_loaded_slim_captures_arrayOfObjs(archivedSlimCapturesArrayOfObjs);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    const functionOnFinish = () => {
      this.setState({s_loadingAllArchivedCapturesInSeparateFetchTF:false});
    }
    C_CallPhpScript.add_function("onFinish", functionOnFinish);

    C_CallPhpScript.execute();
  }

  onclick_select_all_captures_checkbox = () => {
    const s_checkedCaptureIDsArray = this.state.s_checkedCaptureIDsArray;

    const c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined = this.props.AdminMobx.c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined;

    if(JSFUNC.is_array(c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined)) {
      var updatedCheckedCaptureIDsArray = []; //if every checkbox was checked, uncheck them all
      if(s_checkedCaptureIDsArray.length < c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined.length) { //not every checkbox is checked yet, check them all
        updatedCheckedCaptureIDsArray = JSFUNC.get_column_vector_from_arrayOfObjs(c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined, "id");
      }
      this.setState({s_checkedCaptureIDsArray:updatedCheckedCaptureIDsArray});
    }
  }

  onclick_archived_capture_checkbox = (i_captureID) => {
    const s_checkedCaptureIDsArray = this.state.s_checkedCaptureIDsArray;
    const updatedCheckedCaptureIDsArray = JSFUNC.remove_value_from_array_if_exists_otherwise_append(i_captureID, s_checkedCaptureIDsArray);
    this.setState({s_checkedCaptureIDsArray:updatedCheckedCaptureIDsArray});
  }

  onclick_confirm_unarchive_selected_captures = () => {
    const s_checkedCaptureIDsArray = this.state.s_checkedCaptureIDsArray;

    const o_captureArchivingLoadedSlimCapturesArrayOfObjs = this.props.AdminMobx.o_captureArchivingLoadedSlimCapturesArrayOfObjs;
    const k_allCapturesNamesReferenceForCreateNewCaptureTblNamesArray = this.props.DatabaseMobx.k_allCapturesNamesReferenceForCreateNewCaptureTblNamesArray;
    const c_phase1TblNamesToLoadArray = this.props.DatabaseMobx.c_phase1TblNamesToLoadArray;
    const c_fieldMapOfArchiveDate = this.props.DatabaseMobx.c_fieldMapOfArchiveDate;

    //initialize the unarchiving processing message as working
    this.setState({s_unarchiveWorkingMessageOrUndefined:"Unarchiving Captures (loading set of Captures)..."});
    
    //1) load all archived captures (phase1 capture tbls)
    const phase1TblNamesComma = JSFUNC.convert_array_to_comma_list(c_phase1TblNamesToLoadArray);
    const loadAllArchivedCapturesForceLoadArchivedCapturesTF = true;
    const singleCaptureIDOrM1AllCaptures = -1;
    const functionOnFinishLoadEveryCapture = () => {
      //set the unarchiving processing message as working
      this.setState({s_unarchiveWorkingMessageOrUndefined:"Unarchiving Captures (resetting Archive Date values)..."});

      //2) mass edit to set all loaded archived captures to unarchived
      const selectedCapturesArrayOfObjs = JSFUNC.filtered_arrayOfObjs_from_arrayOfObjs_single_field_matching_arrayOfValues(o_captureArchivingLoadedSlimCapturesArrayOfObjs, "id", s_checkedCaptureIDsArray);
      const newArchiveDateValueRaw = JSFUNC.blank_date();
      const sendMassEditNotificationsTF = false;
      const floatingBoxTitle = "Unarchiving Captures";
      const functionOnFinishMassEditArchiveDate = () => {
        //set the unarchiving processing message as working
        this.setState({s_unarchiveWorkingMessageOrUndefined:"Unarchiving Captures (merging all Captures into current system session)..."});

        //clear selected captureIDs
        this.setState({s_checkedCaptureIDsArray:[]});

        //3) reload all captures normally loaded at login (phase1 capture tbls)
        var reloadAllNonArchivedCapturesForceLoadArchivedCapturesTF = false;
        const functionOnFinishSecondReload = () => {
          //4) reload the all captures names and archive dates from the database for create new capture to reflect the new archive status of captures
          JSPHP.php_db_api_load_multiple_full_database_tbls_from_tbl_names_array_and_uncompress_and_update_local_memory_maps(k_allCapturesNamesReferenceForCreateNewCaptureTblNamesArray, undefined);

          //mark the unarchive process as finished
          this.setState({s_unarchiveWorkingMessageOrUndefined:undefined});
        }
        JSPHP.php_db_api_load_firewalled_archived_filtered_tbl_captures_and_c_tbls_and_uncompress_and_update_local_memory_maps(phase1TblNamesComma, reloadAllNonArchivedCapturesForceLoadArchivedCapturesTF, singleCaptureIDOrM1AllCaptures, functionOnFinishSecondReload);

        //3a) force this subtab to recalculate the archived captures list
        this.onclick_admin_load_archived_captures_into_the_system();
      }
      this.props.CapturesMobx.a_mass_edit_field_for_all_captures_in_capture_table(selectedCapturesArrayOfObjs, c_fieldMapOfArchiveDate, newArchiveDateValueRaw, sendMassEditNotificationsTF, floatingBoxTitle, functionOnFinishMassEditArchiveDate);
    }
    JSPHP.php_db_api_load_firewalled_archived_filtered_tbl_captures_and_c_tbls_and_uncompress_and_update_local_memory_maps(phase1TblNamesComma, loadAllArchivedCapturesForceLoadArchivedCapturesTF, singleCaptureIDOrM1AllCaptures, functionOnFinishLoadEveryCapture);
  }

  onchange_archived_captures_search_text = (i_newValueString) => {
    this.props.AdminMobx.a_set_capture_archiving_search_text(i_newValueString);
  }

  render() {
    const s_loadingAllArchivedCapturesInSeparateFetchTF = this.state.s_loadingAllArchivedCapturesInSeparateFetchTF;
    const s_checkedCaptureIDsArray = this.state.s_checkedCaptureIDsArray;
    const s_unarchiveWorkingMessageOrUndefined = this.state.s_unarchiveWorkingMessageOrUndefined;

    const o_captureArchivingSearchText = this.props.AdminMobx.o_captureArchivingSearchText;
    const o_captureArchivingLoadedSlimCapturesArrayOfObjs = this.props.AdminMobx.o_captureArchivingLoadedSlimCapturesArrayOfObjs;
    const c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined = this.props.AdminMobx.c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined;
    const c_searchFieldsDivexecExpandedCstColumnsArrayOfObjs = this.props.CapturesMobx.c_searchFieldsDivexecExpandedCstColumnsArrayOfObjs;
    const c_searchFieldsDivexecInitialSortObj = this.props.CapturesMobx.c_searchFieldsDivexecInitialSortObj;

    const archivedCapturesLoadedTF = JSFUNC.is_array(c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined);

    var archivedCapturesTableComponent = null;
    if(JSFUNC.string_is_filled_out_tf(s_unarchiveWorkingMessageOrUndefined)) {
      archivedCapturesTableComponent = (
        <CEGeneralReact.EmptyScreenWhite>
          <div className="smallBottomMargin">
            {s_unarchiveWorkingMessageOrUndefined}
          </div>
          <CEGeneralReact.LoadingAnimation />
        </CEGeneralReact.EmptyScreenWhite>
      );
    }
    else if(archivedCapturesLoadedTF) {
      const numFilteredLoadedArchivedCaptures = c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined.length;
      const numCheckedCaptures = s_checkedCaptureIDsArray.length;
      const showUnarchiveButtonTF = (numCheckedCaptures > 0);

      var selectAllCapturesCheckboxStatus = 2; //partial selection
      if(numCheckedCaptures === numFilteredLoadedArchivedCaptures) {
        selectAllCapturesCheckboxStatus = 1; //all selected
      }
      else if(numCheckedCaptures === 0) {
        selectAllCapturesCheckboxStatus = 0; //none selected
      }

      var unarchiveConfirmMessage = "";
      if(showUnarchiveButtonTF) {
        unarchiveConfirmMessage = "Are you sure you want to unarchive the following Captures?\n";
        for(let slimCaptureObj of o_captureArchivingLoadedSlimCapturesArrayOfObjs) {
          if(JSFUNC.in_array(slimCaptureObj.id, s_checkedCaptureIDsArray)) {
            unarchiveConfirmMessage += "\n - " + this.props.DatabaseMobx.capture_name_plaintext_from_capture_id_codename_opportunity_name(slimCaptureObj.id, slimCaptureObj.codename, slimCaptureObj.opportunity_name);
          }
        }
      }

      archivedCapturesTableComponent = (
        <>
          <div className="hugeTopMargin">
            <font className="font12 fontItalic fontTextLighter">
              {"System Archived Captures (" + numFilteredLoadedArchivedCaptures + ")"}
            </font>
          </div>
          <div className="displayFlexRow flexWrap">
            <div className="flex00a displayFlexRow tbMargin border1bbb bgLighterGray lrMedPad" style={{flexBasis:"36em", height:"3em"}}>
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
                <LibraryReact.CheckBox
                  p_u0_s1_p2_du3_ds4={selectAllCapturesCheckboxStatus}
                  p_sizeEm={1.4}
                  f_onClick={this.onclick_select_all_captures_checkbox}
                />
              </div>
              <div className="flex00a displayFlexRowVc" style={{flexBasis:"11em"}}>
                <font className="fontTextLight">
                  {"Select All Captures"}
                </font>
              </div>
              <div className="flex11a" />
              {(showUnarchiveButtonTF) &&
                <div className="flex00a displayFlexColumnHcVc">
                  <CEGeneralReact.ButtonWithConfirmBox
                    p_buttonType="blue"
                    p_buttonText={"Unarchive " + numCheckedCaptures + " selected " + JSFUNC.plural(numCheckedCaptures, "Capture", "Captures")}
                    p_confirmType="confirm"
                    p_confirmMessage={unarchiveConfirmMessage}
                    p_button1Name={"Unarchive " + numCheckedCaptures + " " + JSFUNC.plural(numCheckedCaptures, "Capture", "Captures")}
                    f_onClickConfirm={this.onclick_confirm_unarchive_selected_captures}
                  />
                </div>
              }
            </div>
            <div className="flex00a displayFlexRow tbMargin border1bbb bgLighterGray lrMedPad" style={{flexBasis:"36em", height:"3em", marginLeft:"2em"}}>
              <div className="flex00a displayFlexRowVc" style={{flexBasis:"8em"}}>
                <font className="fontTextLight">
                  {"Search Archived Captures"}
                </font>
              </div>
              <div className="flex11a displayFlexColumnVc">
                <CEGeneralReact.SearchInputTextWithClearX
                  p_inputText={o_captureArchivingSearchText}
                  p_placeholderText="Filter Archived Captures Table Below..."
                  p_includeSearchIconTF={true}
                  p_heightEm={2}
                  f_onChange={this.onchange_archived_captures_search_text}
                />
              </div>
            </div>
            <div className="flex11a" />
          </div>
          <CapturesReact.SearchFieldsCaptureSummaryTable
            p_filteredCstMaskedCaptureValuesArrayOfObjs={c_captureArchivingFilteredLoadedCstMaskedCaptureValuesArrayOfObjsOrUndefined}
            p_cstSumRowColumnsArrayOfObjsOrUndefined={undefined}
            p_expandedCstColumnsArrayOfObjs={c_searchFieldsDivexecExpandedCstColumnsArrayOfObjs}
            p_initialSortFieldID={c_searchFieldsDivexecInitialSortObj.sortFieldIDOrUndefined}
            p_initialSortIsAscTF={c_searchFieldsDivexecInitialSortObj.sortIsAscTFOrUndefined}
            p_checkedCaptureIDsArray={s_checkedCaptureIDsArray}
            p_maxNumCapturesShown={undefined}
            f_onClickCapture={undefined}
            f_onClickCheckBox={this.onclick_archived_capture_checkbox}
          />
        </>
      );
    }

    return(
      <>
        <div className="smallBottomMargin">
          <font className="fontItalic fontTextLighter">
            {"You can load and view all Archived Captures in a table below using this 'Load All Archived Captures' button"}
          </font>
        </div>
        {(s_loadingAllArchivedCapturesInSeparateFetchTF) ? (
          <div className="displayFlexRowVc">
            <div className="flex00a">
              <font className="fontBold fontItalic fontTextLighter">
                {"Loading Captures"}
              </font>
            </div>
            <div className="flex11a lrMedPad">
              <CEGeneralReact.LoadingAnimation />
            </div>
          </div>
        ) : (
          <CEGeneralReact.CEButton
            p_type="add"
            p_text={((archivedCapturesLoadedTF) ? ("Clear and Reload Archived Captures to Display Below") : ("Load All Archived Captures to Display Below"))}
            f_onClick={this.onclick_admin_load_archived_captures_into_the_system}
          />
        )}
        {archivedCapturesTableComponent}
      </>
    );
  }
}));




//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabNewCaptureAdditionalFields = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabNewCaptureAdditionalFields extends Component {
  render() {
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs = this.props.AdminMobx.c_createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    return(
      <div className="flex11a displayFlexColumn">
        <CaptureTypeSelectionBoxes />
        <div className="flex11a displayFlexRow">
          <div className="flex11a displayFlexColumn borderR1bbb" style={{flexBasis:"100em"}}>
            <div className="flex00a medFullPad">
              <div className="">
                <font className="font12 fontBold fontTextLight">
                  {"Fields shown when using Create New Capture on the 'Captures' tab (when " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "' is selected)"}
                </font>
              </div>
              <div className="microTopMargin">
                <font className="fontItalic fontTextLight">
                  {"Below you can add custom fields to a new area on the Create New Capture form in addition to the standard fields offered"}
                </font>
              </div>
            </div>
            <CreateNewCaptureAddAdditionalFields />
            <NewCaptureAdditionalFieldsList />
          </div>
          <div className="flex11a displayFlexColumn" style={{flexBasis:"125em"}}>
            <div className="flex00a medFullPad">
              <div className="textCenter">
                <font className="font12 fontBold fontItalic fontTextLight">
                  {"Create New Capture Layout"}
                </font>
              </div>
              <div className="microTopMargin textCenter">
                <font className="fontItalic fontTextLight">
                  {"when " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "' is selected"}
                </font>
              </div>
            </div>
            <div className="flex11a displayFlexColumn lrMedPad medBottomPad yScroll">
              <div className="border1bbb medFullPad">
                {c_createNewCaptureStandardFieldsLayoutArrayOfArraysOfObjs.map((m_rowArrayOfObjs) =>
                  <div className="displayFlexRow medBottomMargin">
                    {m_rowArrayOfObjs.map((m_sectionObj) =>
                      <div className="flex11a displayFlexRow lrMargin" style={{flexBasis:"100em"}}>
                        {(m_sectionObj !== undefined) &&
                          <CEGeneralReact.NewCaptureSectionContainer p_drawBorderTF={true}>
                            <CEGeneralReact.NewCaptureSectionLabel p_label={m_sectionObj.sectionLabel}>
                              {m_sectionObj.fieldMapsArray.map((m_fieldMap) =>
                                <div className="smallTopMargin">
                                  <CEGeneralReact.NewCaptureFieldLabel p_widthEm={11} p_label={m_fieldMap.get("display_name")} />
                                </div>
                              )}
                            </CEGeneralReact.NewCaptureSectionLabel>
                          </CEGeneralReact.NewCaptureSectionContainer>
                        }
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}));

const CreateNewCaptureAddAdditionalFields = inject("AdminMobx", "DatabaseMobx")(observer(
class CreateNewCaptureAddAdditionalFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_selectingAdditionalFieldsToAddTF: false,
      s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma: ""
    };
  }

  onclick_add_additional_create_new_capture_fields_button = () => {
    this.setState({
      s_selectingAdditionalFieldsToAddTF: true,
      s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma: ""
    });
  }

  onselect_capture_fields = (i_selectedFieldIDsComma) => {
    this.setState({s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma:i_selectedFieldIDsComma});
  }

  onclick_add_selected_fields_to_create_new_capture = () => {
    const s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma = this.state.s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma;

    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;
    const c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs = this.props.AdminMobx.c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs;

    const selectedCaptureFieldsToAddAsAdditionalFieldIDsArray = JSFUNC.convert_comma_list_to_int_array(s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma);
    const numSelectedCaptureFieldsToAddAsAdditionalFields = selectedCaptureFieldsToAddAsAdditionalFieldIDsArray.length;
    if(numSelectedCaptureFieldsToAddAsAdditionalFields > 0) { //if there is at least 1 new field to add, make the database insert
      const numCurrentAdditionalFields = c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs.length;

      const jsDescription = JSFUNC.js_description_from_action("AdminMobx - NewCaptureSingleAdditionalFieldItem", "ondelete_additional_field_item", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      var insertCaptureFieldIDsArray = [];
      var insertCaptureFieldSortsArray = [];
      for(let f = 0; f < numSelectedCaptureFieldsToAddAsAdditionalFields; f++) {
        insertCaptureFieldIDsArray.push(selectedCaptureFieldsToAddAsAdditionalFieldIDsArray[f]);
        insertCaptureFieldSortsArray.push(numCurrentAdditionalFields + f + 1);
      }
      const tblName = "tbl_a_create_new_capture_additional_fields";
      const fieldNamesArray = ["capture_type_id", "capture_field_id", "sort"];
      const valuesArrayOfArrays = [o_selectedCaptureTypeID, insertCaptureFieldIDsArray, insertCaptureFieldSortsArray];
      const valueIdsbArray = ["i", "i", "i"];
      C_CallPhpTblUID.add_multi_insert(tblName, fieldNamesArray, valuesArrayOfArrays, valueIdsbArray);
      C_CallPhpTblUID.execute();
    }

    this.onclick_cancel_add_additional_create_new_capture_fields();
  }

  onclick_cancel_add_additional_create_new_capture_fields = () => {
    this.setState({
      s_selectingAdditionalFieldsToAddTF: false,
      s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma: ""
    });
  }

  render() {
    const s_selectingAdditionalFieldsToAddTF = this.state.s_selectingAdditionalFieldsToAddTF;
    const s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma = this.state.s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma;

    const c_selectNewCaptureAdditionalFieldForCaptureTypeFieldTypeObj = this.props.AdminMobx.c_selectNewCaptureAdditionalFieldForCaptureTypeFieldTypeObj;

    var numNewAdditionalFieldsSelected = 0;
    if(s_selectingAdditionalFieldsToAddTF) {
      numNewAdditionalFieldsSelected = JSFUNC.count_comma_list_num_items(s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma);
    }

    return(
      <div className="tbPad flex00a displayFlexColumnHcVc">
        {(!s_selectingAdditionalFieldsToAddTF) ? (
          <CEGeneralReact.CEButton
            p_type="blue"
            p_text="Add Additional 'Create New Capture' Fields"
            f_onClick={this.onclick_add_additional_create_new_capture_fields_button}
          />
        ) : (
          <>
            <div className="flex00a" style={{width:"90%", maxWidth:"40em"}}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={c_selectNewCaptureAdditionalFieldForCaptureTypeFieldTypeObj}
                p_valueRaw={s_selectedCaptureFieldsToAddAsAdditionalFieldIDsComma}
                p_selectInitOptionsBoxOpenTF={true}
                f_onChangeOrOnSelect={this.onselect_capture_fields}
              />
            </div>
            <div className="flex00a displayFlexRowVc medTopMargin">
              <CEGeneralReact.CEButton
                p_type={((numNewAdditionalFieldsSelected > 0) ? ("blue") : ("blueDisabled"))}
                p_text={((numNewAdditionalFieldsSelected > 0) ? ("Add " + numNewAdditionalFieldsSelected + " New " + JSFUNC.plural(numNewAdditionalFieldsSelected, "Field", "Fields")) : ("No Fields Selected"))}
                f_onClick={this.onclick_add_selected_fields_to_create_new_capture}
              />
              <div className="flex00a" style={{flexBasis:"1em"}} />
              <CEGeneralReact.CEButton
                p_type="gray"
                p_text="Cancel"
                f_onClick={this.onclick_cancel_add_additional_create_new_capture_fields}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}));

const NewCaptureAdditionalFieldsList = inject("AdminMobx", "DatabaseMobx")(observer(
class NewCaptureAdditionalFieldsList extends Component {
  render() {
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs = this.props.AdminMobx.c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    const numAdditionalFields = c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs.length;
    const isOnlyItemTF = (numAdditionalFields === 1);

    return(
      <div className="flex11a yScroll medFullPad">
        <div className="border1bbb borderRadius05 bgLightestGray medFullPad">
          {(numAdditionalFields === 0) ? (
            <div className="medFullPad textCenter">
              <font className="fontItalic fontTextLight">
                {"--No Additional Fields have been added yet to Create New Capture for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'--"}
              </font>
            </div>
          ) : (
            c_createNewCaptureAdditionalFieldsForSelectedCaptureTypeArrayOfObjs.map((m_cncAdditionalFieldObj, m_index) =>
              <NewCaptureSingleAdditionalFieldItem
                p_cncAdditionalFieldObj={m_cncAdditionalFieldObj}
                p_isOnlyItemTF={isOnlyItemTF}
                p_isLastItemTF={(m_index === (numAdditionalFields - 1))}
              />
            )
          )}
        </div>
      </div>
    );
  }
}));

const NewCaptureSingleAdditionalFieldItem = inject("AdminMobx", "DatabaseMobx")(observer(
class NewCaptureSingleAdditionalFieldItem extends Component { //props: p_cncAdditionalFieldObj, p_isOnlyItemTF, p_isLastItemTF
  ondelete_additional_field_item = () => {
    const p_cncAdditionalFieldObj = this.props.p_cncAdditionalFieldObj;

    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;

    const jsDescription = JSFUNC.js_description_from_action("AdminMobx - NewCaptureSingleAdditionalFieldItem", "ondelete_additional_field_item", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_create_new_capture_additional_fields", p_cncAdditionalFieldObj.id, "sort", ["capture_type_id"], [o_selectedCaptureTypeID]);
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_cncAdditionalFieldObj = this.props.p_cncAdditionalFieldObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;
    const c_selectedCaptureTypeObj = this.props.AdminMobx.c_selectedCaptureTypeObj;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="cncAdditionalFieldItem"
        p_itemID={p_cncAdditionalFieldObj.id}
        p_itemSort={p_cncAdditionalFieldObj.sort}
        p_itemSortColumnWidth="3em"
        p_itemSortNumberContainerClass={undefined}
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="microBottomMargin"
        p_itemClass="border1bbb bgWhite"
        p_itemStyleObj={{height:"2.4em"}}
        p_lastItemExtraDropZoneHeight="1.6em"
        p_dragEntireItemTrueOnlySortNumberFalse={undefined}
        p_tblName="tbl_a_create_new_capture_additional_fields"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={["capture_type_id"]}
        p_filterValuesArray={[o_selectedCaptureTypeID]}
        f_onDropForeignItem={undefined}>
        <div className="flex11a displayFlexRowVc lrPad" title={p_cncAdditionalFieldObj.fieldDisplayName}>
          <LibraryReact.Nowrap p_fontClass="font11 fontTextLight">
            {p_cncAdditionalFieldObj.fieldDisplayName}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <CEGeneralReact.DeleteMenu
            p_message={"Are you sure you want to remove '" + p_cncAdditionalFieldObj.fieldDisplayName + "' as an additional Create New Capture field for " + c_fieldMapOfCaptureType.get("display_name") + " '" + c_selectedCaptureTypeObj.name + "'?"}
            f_onDelete={this.ondelete_additional_field_item}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));


//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabSearchFields = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabSearchFields extends Component {
  onswitch_search_fields_separate_divexec_fields = () => {
    const c_companySearchFieldsSeparateDivexecFieldsTF = this.props.DatabaseMobx.c_companySearchFieldsSeparateDivexecFieldsTF;
    const newValue01 = ((c_companySearchFieldsSeparateDivexecFieldsTF) ? (0) : (1));
    this.props.AdminMobx.a_update_tbl_a_company_fields("search_fields_separate_divexec_fields_01", newValue01, "i");
  }

  render() {
    const c_companySearchFieldsSeparateDivexecFieldsTF = this.props.DatabaseMobx.c_companySearchFieldsSeparateDivexecFieldsTF;  

    return (
      <div className="flex11a yScroll">
        <SearchFieldsExampleAndAedTable p_searchResults0Divexec1={0} />
        <div className="hugeTopMargin" style={{borderTop:"solid 2px #bbb"}} />
        <div className="tbHugePad lrPad">
          <div className="border1bbb bgLighterGray smallFullPad">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={c_companySearchFieldsSeparateDivexecFieldsTF}
              p_sizeEm={3}
              p_onText="Use separate Column Set for Divexec Graphs/Performance Capture Tables"
              p_offText="Use Search Results Column Set above for all mini Capture Tables"
              p_labelText="2nd Column Set"
              p_labelFontClass=""
              p_labelWidthEm={9}
              p_title="Use this switch to enable a 2nd customizable set of Search Fields columns that can be applied to all Divexec Graphs/Performance when clicking on graph segments to view Capture lists"
              f_onSwitch={this.onswitch_search_fields_separate_divexec_fields}
            />
          </div>
        </div>
        {(c_companySearchFieldsSeparateDivexecFieldsTF) &&
          <SearchFieldsExampleAndAedTable p_searchResults0Divexec1={1} />
        }
        <div style={{marginTop:"10em"}} />
      </div>
    );
  }
}));

const SearchFieldsExampleAndAedTable = inject("AdminMobx", "CapturesMobx", "DatabaseMobx")(observer(
class SearchFieldsExampleAndAedTable extends Component { //props: p_searchResults0Divexec1
  render() {
    const p_searchResults0Divexec1 = this.props.p_searchResults0Divexec1;

    const c_searchFieldsSelectUsedInSearchFieldTypeObj = this.props.AdminMobx.c_searchFieldsSelectUsedInSearchFieldTypeObj;
    const c_searchFieldsSelectSortColumnFieldTypeObj = this.props.AdminMobx.c_searchFieldsSelectSortColumnFieldTypeObj;
    const c_searchFieldsSelectShowSumFieldTypeObj = this.props.AdminMobx.c_searchFieldsSelectShowSumFieldTypeObj;
    const c_searchFieldsSearchResultsExpandedCstColumnsArrayOfObjs = this.props.CapturesMobx.c_searchFieldsSearchResultsExpandedCstColumnsArrayOfObjs;
    const c_searchFieldsDivexecExpandedCstColumnsArrayOfObjs = this.props.CapturesMobx.c_searchFieldsDivexecExpandedCstColumnsArrayOfObjs;
    const c_genericIntPositiveFieldTypeObj = this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj;
    const c_selectNoClearCaptureFieldFieldTypeObj = this.props.DatabaseMobx.c_selectNoClearCaptureFieldFieldTypeObj;

    var searchFieldsExpandedCstColumnsArrayOfObjs = [];
    var aedTblWidthEm = 70;
    var showUsedInSearchColumnTF = true;
    var exampleTitle = "";
    var itemName = "";
    var bgClass = "";
    if(p_searchResults0Divexec1 === 0) {
      searchFieldsExpandedCstColumnsArrayOfObjs = c_searchFieldsSearchResultsExpandedCstColumnsArrayOfObjs;
      aedTblWidthEm = 70;
      showUsedInSearchColumnTF = true;
      exampleTitle = "'Search Results' Search Fields - used in 'Search Captures...' box";
      itemName = "'Search Results' Search Field";
      bgClass = "bgLightOrange";
    }
    else if(p_searchResults0Divexec1 === 1) {
      searchFieldsExpandedCstColumnsArrayOfObjs = c_searchFieldsDivexecExpandedCstColumnsArrayOfObjs;
      aedTblWidthEm = 59;
      showUsedInSearchColumnTF = false;
      exampleTitle = "'DivExec' Search Fields - used in DivExec Graphs/Performance, Archived Captures list";
      itemName = "'DivExec' Search Field";
      bgClass = "bgLightBlue";
    }

    //example fake data rows
    const exampleCapturesMapOfMaps = JSFUNC.mapOfMaps_from_arrayOfObjs([{id:1},{id:2}]);
    var exampleFilteredCstMaskedCaptureValuesArrayOfObjs = this.props.CapturesMobx.create_filtered_cst_masked_capture_values_arrayOfObjs_from_filtered_captures_mapOfMaps_and_expanded_cst_columns_arrayOfObjs_and_sort_capture_field_ids_array(exampleCapturesMapOfMaps, searchFieldsExpandedCstColumnsArrayOfObjs, []);
    for(let exampleCstMaskedCatpureValuesObj of exampleFilteredCstMaskedCaptureValuesArrayOfObjs) {
      for(let searchFieldsExpandedCstColumnObj of searchFieldsExpandedCstColumnsArrayOfObjs) {
        exampleCstMaskedCatpureValuesObj[searchFieldsExpandedCstColumnObj.capture_field_id + "MASKNCL"] = "Example Value";
      }
    }

    //example sum row
    var exampleCstSumRowColumnsArrayOfObjsOrUndefined = this.props.CapturesMobx.create_cst_sum_row_columns_arrayOfObjs_or_undefined_from_filtered_cst_masked_capture_values_arrayOfObjs_and_expanded_cst_columns_arrayOfObjs(exampleFilteredCstMaskedCaptureValuesArrayOfObjs, searchFieldsExpandedCstColumnsArrayOfObjs);
    if(JSFUNC.is_array(exampleCstSumRowColumnsArrayOfObjsOrUndefined)) {
      for(let exampleCstSumRowColumnObj of exampleCstSumRowColumnsArrayOfObjsOrUndefined) {
        exampleCstSumRowColumnObj.sumValueMask = "Example Value";
      }
    }

    //example sort
    const exampleSearchFieldsInitialSortObj = this.props.CapturesMobx.create_search_fields_initial_sort_obj_from_search_fields_expanded_cst_columns_arrayOfObjs(searchFieldsExpandedCstColumnsArrayOfObjs);

    //modify the columns data, set sum_row_01 to false where it cannot be turned into a sumRow
    var modifiedSearchFieldsExpandedCstColumnsArrayOfObjs = [];
    for(let searchFieldsExpandedCstColumnObj of searchFieldsExpandedCstColumnsArrayOfObjs) {
      modifiedSearchFieldsExpandedCstColumnsArrayOfObjs.push({
        id: searchFieldsExpandedCstColumnObj.id,
        sr0_de1: searchFieldsExpandedCstColumnObj.sr0_de1,
        field_id: searchFieldsExpandedCstColumnObj.field_id,
        sort: searchFieldsExpandedCstColumnObj.sort,
        used_in_search_01: searchFieldsExpandedCstColumnObj.used_in_search_01,
        width_em: searchFieldsExpandedCstColumnObj.width_em,
        sortcol_1no_2asc_3desc: searchFieldsExpandedCstColumnObj.sortcol_1no_2asc_3desc,
        sum_row_01: ((searchFieldsExpandedCstColumnObj.canCalculateCstSumRowTF) ? (searchFieldsExpandedCstColumnObj.sum_row_01) : (false))
      });
    }

    const searchResultFieldIDsAlreadyAddedArray = JSFUNC.get_column_vector_from_arrayOfObjs(searchFieldsExpandedCstColumnsArrayOfObjs, "field_id");

    var fieldsArrayOfObjs = [];
    fieldsArrayOfObjs.push({width:"100em", dbName:"field_id", displayName:"Capture Field", fieldTypeObj:c_selectNoClearCaptureFieldFieldTypeObj, valuesToNotIncludeArray:searchResultFieldIDsAlreadyAddedArray, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true, onlyEditableDuringCreateUneditableOnEditTF:true});
    fieldsArrayOfObjs.push({width:"7em", fixedWidthTF:true, dbName:"width_em", displayName:"Column Width", fieldTypeObj:c_genericIntPositiveFieldTypeObj, initialValue:10, cellCenterTF:true, requiredNotEmptyTF:true});
    fieldsArrayOfObjs.push({width:"10em", fixedWidthTF:true, dbName:"sortcol_1no_2asc_3desc", displayName:"Results Sorted by this Column?", fieldTypeObj:c_searchFieldsSelectSortColumnFieldTypeObj, initialValue:1, cellCenterTF:true, requiredNotEmptyTF:true});
    fieldsArrayOfObjs.push({width:"8em", fixedWidthTF:true, dbName:"sum_row_01", displayName:"Show Sum At Top?", fieldTypeObj:c_searchFieldsSelectShowSumFieldTypeObj, initialValue:0, cellCenterTF:true, rawValueFalseDisplaysNAAndNoEditTF:true, onlyEditableInEditNotShownOnCreateNewTF:true});
    if(showUsedInSearchColumnTF) {
      fieldsArrayOfObjs.push({width:"11em", fixedWidthTF:true, dbName:"used_in_search_01", displayName:"Used to Compare with Search Term?", fieldTypeObj:c_searchFieldsSelectUsedInSearchFieldTypeObj, initialValue:1, cellCenterTF:true, requiredNotEmptyTF:true});
    }
    fieldsArrayOfObjs.push({width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:c_genericIntPositiveFieldTypeObj, initialValue:1});
    
    const searchFieldsSearchResultsAedTableObj = {
      tblName: "tbl_a_search_fields",
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: aedTblWidthEm + "em",
      itemName: itemName,
      deleteItemNameFieldDbName: "field_id",
      newItemConstantFieldNamesArray: ["sr0_de1"],
      newItemConstantValuesArray: [p_searchResults0Divexec1],
      newItemConstantIdsbArray: ["i"],
      insertDeleteResortFilterFieldNamesArray: ["sr0_de1"],
      insertDeleteResortFilterValuesArray: [p_searchResults0Divexec1],
      fieldsArrayOfObjs: fieldsArrayOfObjs
    };

    return(
      <div className={bgClass}>
        <div className="displayFlexRow bgLighterGray medFullPad" style={{borderBottom:"solid 1px #666"}}>
          <div className="flex11a displayFlexColumn border bevelBorderColors bgWhite" style={{flexBasis:"400em", height:"12em"}}>
            <div className="flex00a displayFlexRowVc bgLightGrayGradient" style={{flexBasis:"2.3em", borderBottom:"solid 1px #bbb"}}>
              <div className="flex11a lrPad">
                <LibraryReact.Nowrap p_fontClass="font11 fontBold">
                  {exampleTitle}
                </LibraryReact.Nowrap>
              </div>
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em", borderLeft:"solid 1px #ddd"}}>
                <CEGeneralReact.RemoveItemButton p_fontClass="font13" />
              </div>
            </div>
            {(searchFieldsExpandedCstColumnsArrayOfObjs.length === 0) ? (
              <CEGeneralReact.EmptyScreenWhite p_fontClass="">
                {"--No columns added to this Search Fields table yet--"}
              </CEGeneralReact.EmptyScreenWhite>
            ) : (
              <div className="flex11a xyScroll">
                <CapturesReact.SearchFieldsCaptureSummaryTable
                  p_filteredCstMaskedCaptureValuesArrayOfObjs={exampleFilteredCstMaskedCaptureValuesArrayOfObjs}
                  p_cstSumRowColumnsArrayOfObjsOrUndefined={exampleCstSumRowColumnsArrayOfObjsOrUndefined}
                  p_expandedCstColumnsArrayOfObjs={searchFieldsExpandedCstColumnsArrayOfObjs}
                  p_initialSortFieldID={exampleSearchFieldsInitialSortObj.sortFieldIDOrUndefined}
                  p_initialSortIsAscTF={exampleSearchFieldsInitialSortObj.sortIsAscTFOrUndefined}
                  p_maxNumCapturesShown={undefined}
                  f_onClickCapture={undefined}
                />
              </div>
            )}
          </div>
          <div className="flex11a" style={{flexBasis:"100em"}} />
        </div>
        <div className="medFullPad">
          <AddEditDeleteTable
            p_dataArrayOfObjs={modifiedSearchFieldsExpandedCstColumnsArrayOfObjs}
            p_aedTableObj={searchFieldsSearchResultsAedTableObj}
          />
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabPwin = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabPwin extends Component {
  onswitch_turn_onoff_pwin = () => {
    const newCompanyPwinFlag = ((this.props.DatabaseMobx.c_companyUsingPwinTF) ? ("off") : ("manual"));
    this.props.AdminMobx.a_update_tbl_a_company_fields("pwin_flag", newCompanyPwinFlag, "s");
  }

  onswitch_set_pwin_manual_calculated = () => {
    const newCompanyPwinFlag = ((this.props.DatabaseMobx.c_companyPwinIsCalculatedTF) ? ("manual") : ("calculated"));
    this.props.AdminMobx.a_update_tbl_a_company_fields("pwin_flag", newCompanyPwinFlag, "s");
  }

  onswitch_turn_onoff_calc_pwin_min_threshold = () => {
    const minThreshWasOnTF = (this.props.DatabaseMobx.c_companyPwinCalcMinThreshScore >= 0 && this.props.DatabaseMobx.c_companyPwinCalcMinThreshPwin >= 0);
    const fieldDbNamesArray = ["pwin_calc_min_thresh_score", "pwin_calc_min_thresh_pwin"];
    const newValuesArray = [((minThreshWasOnTF) ? (-1) : (10)), ((minThreshWasOnTF) ? (-1) : (10))];
    const newIdsbArray = ["i", "i"];
    this.props.AdminMobx.a_update_tbl_a_company_fields(fieldDbNamesArray, newValuesArray, newIdsbArray);
  }

  onsave_calc_min_thresh_score = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("pwin_calc_min_thresh_score", i_newValue, "i");
  }

  onsave_calc_min_thresh_pwin = (i_newValue) => {
    this.props.AdminMobx.a_update_tbl_a_company_fields("pwin_calc_min_thresh_pwin", i_newValue, "i");
  }

  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_companyUsingPwinTF = this.props.DatabaseMobx.c_companyUsingPwinTF;
    const c_companyPwinIsCalculatedTF = this.props.DatabaseMobx.c_companyPwinIsCalculatedTF;
    const c_companyPwinCalcMinThreshScore = this.props.DatabaseMobx.c_companyPwinCalcMinThreshScore;
    const c_companyPwinCalcMinThreshPwin = this.props.DatabaseMobx.c_companyPwinCalcMinThreshPwin;
    const c_pwinCalcLinesSortedArrayOfObjs = this.props.DatabaseMobx.c_pwinCalcLinesSortedArrayOfObjs;
    const c_pwinColorsArrayOfObjs = this.props.DatabaseMobx.c_pwinColorsArrayOfObjs;

    const pwinCalcLinesAedTableObj = {
      tblName: "tbl_a_pwin_calc_lines",
      initialSortedFieldDbName: "progress_x1",
      tableWidth: "45em",
      itemName: "Calculated PWin Line",
      deleteItemNameFieldDbName: "progress_x1",
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"progress_x1", displayName:"PWin Answers x1", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj, initialValue:50, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"progress_x2", displayName:"PWin Answers x2", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj, initialValue:50, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"pwin_y1", displayName:"Calc PWin y1", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj, initialValue:50, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"pwin_y2", displayName:"Calc PWin y2", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj, initialValue:50, cellCenterTF:true, requiredNotEmptyTF:true}
      ]
    };

    const pwinColorsAedTableObj = {
      tblName: "tbl_a_pwin_colors",
      initialSortedFieldDbName: "percent_start",
      tableWidth: "35em",
      itemName: "PWin Color",
      deleteItemNameFieldDbName: "percent_start",
      fieldsArrayOfObjs: [
        {width:"200em", dbName:"color", displayName:"Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"cccccc", requiredNotEmptyTF:true},
        {width:"100em", dbName:"percent_start", displayName:"Percent Start", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj, initialValue:0, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"font_color", displayName:"Font Color", fieldTypeObj:this.props.DatabaseMobx.c_selectAdminFontColorFieldTypeObj, initialValue:"2a2a2a", cellCenterTF:true, requiredNotEmptyTF:true}
      ]
    };

    var examplePercentsArray = [0];
    for(let colorObj of c_pwinColorsArrayOfObjs) {
      var colorStart = colorObj.percent_start;
      var colorStartM1 = (colorStart - 1);
      if(colorStartM1 > 0 && !JSFUNC.in_array(colorStartM1, examplePercentsArray)) {
        examplePercentsArray.push(colorStartM1);
      }

      if(!JSFUNC.in_array(colorStart, examplePercentsArray)) {
        examplePercentsArray.push(colorStart);
      }
    }

    if(!JSFUNC.in_array(100, examplePercentsArray)) {
      examplePercentsArray.push(100);
    }

    const pwinFieldTypeObj = this.props.DatabaseMobx.c_fieldMapOfPwin.get("fieldTypeObj");

    return (
      <div className="flex11a displayFlexRow">
        <div className="flex11a yScroll yScrollBottomPad">
          <div className="medFullPad displayFlexRowVc" style={{borderBottom:"solid 1px #aaa"}}>
            <div className="flex00a" style={{flexBasis:"11em"}}>
              <font className="font14 fontBlue">
                {"Use PWin in " + c_productStylingObj.productName + "?"}
              </font>
            </div>
            <div className="flex00a">
              <LibraryReact.Switch
                p_isOnTF={c_companyUsingPwinTF}
                p_sizeEm={4.5}
                p_title={"Turn " + ((c_companyUsingPwinTF) ? ("off") : ("on")) + " PWin in the " + c_productStylingObj.productName + " system"}
                f_onClick={this.onswitch_turn_onoff_pwin}
              />
            </div>
            <div className="flex11a lrMedMargin">
              <font className="font12 fontItalic fontTextLighter">
                {'If turned on, PWin will be displayed at the top-center of the Deal Shaping capture card. For each capture, the PWin value can be entered manually ("Manual"), or automatically calculated ("Calculated") using the switch below.'}
              </font>
            </div>
          </div>
          <div className="medFullPad displayFlexRowVc" style={{borderBottom:"solid 1px #aaa"}}>
            <div className="flex00a" style={{flexBasis:"11em"}}>
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("PWin Calculation Option", [0, 1], false, ["Manual PWin", "Calculated PWin"], 1.9)}
                p_valueRaw={((c_companyUsingPwinTF) ? ((c_companyPwinIsCalculatedTF) ? (1) : (0)) : (-1))}
                f_onChangeOrOnSelect={((c_companyUsingPwinTF) ? (this.onswitch_set_pwin_manual_calculated) : (undefined))}
              />
            </div>
            <div className="flex11a lrMedMargin">
              <font className="fontItalic fontTextLighter">
                {((c_companyUsingPwinTF) ? (
                  (c_companyPwinIsCalculatedTF) ? (
                    '"Calculated" - PWin cannot be edited, and is an automatically calculated value based on the answers given to PWin specific Deal Shaping questions. The weighted average of those answers are correlated against the calculation tables below to determine the PWin value'
                  ) : (
                    '"Manual" - PWin can be edited manually for each capture with a value from 0 to 100%'
                  )
                ) : (
                  'PWin is currently turned off and must be turned on before chosing Manual/Calculated'
                ))}
              </font>
            </div>
          </div>
          {(c_companyUsingPwinTF && c_companyPwinIsCalculatedTF) &&
            <>
              <div className="medFullPad displayFlexRow flexWrap" style={{borderBottom:"solid 1px #aaa"}}>
                <div className="flex00a" style={{flexBasis:"30em"}}>
                  <div className="displayFlexRowVc">
                    <div className="flex00a" style={{flexBasis:"15em"}}>
                      <font className="font11 fontBlue">
                        {"Using Calculated PWin Minimum Threshold"}
                      </font>
                    </div>
                    <div className="flex00a">
                      <LibraryReact.Switch
                        p_isOnTF={((c_companyPwinCalcMinThreshScore >= 0) && (c_companyPwinCalcMinThreshPwin >= 0))}
                        p_onColor="992222"
                        p_title={"Turn " + (((c_companyPwinCalcMinThreshScore >= 0) && (c_companyPwinCalcMinThreshPwin >= 0)) ? ("off") : ("on")) + " Calculated PWin Minimum Threshold"}
                        f_onClick={((c_companyUsingPwinTF) ? (this.onswitch_turn_onoff_calc_pwin_min_threshold) : (undefined))}
                      />
                    </div>
                  </div>
                  {((c_companyPwinCalcMinThreshScore >= 0) && (c_companyPwinCalcMinThreshPwin >= 0)) &&
                    <>
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString="adminPwinMinThreshScore"
                      p_fieldDisplayName="Calculated PWin Minimum Progress Threshold"
                      p_fieldTypeObj={this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj}
                      p_valueRaw={c_companyPwinCalcMinThreshScore}
                      p_valueIsEditableTFU={true}
                      p_containerClass="tbMedMargin"
                      p_fieldClass="font11 fontBlue"
                      p_fieldWidth="15em"
                      p_valueClass="font11"
                      f_onSaveChanged={this.onsave_calc_min_thresh_score}
                    />
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString="adminPwinMinThreshPwin"
                      p_fieldDisplayName="Calculated PWin Minimum PWin Threshold"
                      p_fieldTypeObj={this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj}
                      p_valueRaw={c_companyPwinCalcMinThreshPwin}
                      p_valueIsEditableTFU={true}
                      p_containerClass="tbMedMargin"
                      p_fieldClass="font11 fontBlue"
                      p_fieldWidth="15em"
                      p_valueClass="font11"
                      f_onSaveChanged={this.onsave_calc_min_thresh_pwin}
                    />
                    </>
                  }
                </div>
                <div className="flex00a" style={{flexBasis:"45em"}}>
                  <AddEditDeleteTable
                    p_dataArrayOfObjs={c_pwinCalcLinesSortedArrayOfObjs}
                    p_aedTableObj={pwinCalcLinesAedTableObj}
                  />
                </div>
              </div>
              <div className="medFullPad">
                <div className="displayFlexColumn">
                  <div className="flex11a displayFlexRow">
                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em", transform:"rotate(270deg)"}}>
                      <font className="font15 fontBold fontTextLighter">
                        {"Calculated PWin"}
                      </font>
                    </div>
                    <div className="flex11a">
                      <PwinCalculatedGraph />
                    </div>
                  </div>
                  <div className="flex00a displayFlexRow">
                    <div className="flex00a" style={{flexBasis:"3em"}} />
                    <div className="flex11a textCenter">
                      <font className="font15 fontBold fontTextLighter">
                        {"PWin Deal Shaping Answer Progress"}
                      </font>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
        <div className="flex00a yScroll yScrollBottomPad medFullPad" style={{flexBasis:"52em", borderLeft:"solid 1px #aaa"}}>
          <div className="flex00a displayFlexRow">
            <div className="flex00a">
              <AddEditDeleteTable
                p_dataArrayOfObjs={c_pwinColorsArrayOfObjs}
                p_aedTableObj={pwinColorsAedTableObj}
              />
            </div>
            <div className="flex11a medFullPad" style={{margin:"0 1em", border:"solid 1px #ddd"}}>
              <div className="textCenter smallBottomMargin">
                <font className="font12 fontItalic">
                  {"Examples to show PWin color ranges:"}
                </font>
              </div>
              {examplePercentsArray.map((m_percent) =>
                <div key={m_percent} className="displayFlexColumnHcVc microTopMargin microBottomMargin">
                  {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(m_percent, pwinFieldTypeObj)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}));


export const PwinCalculatedGraph = inject("AdminMobx", "DatabaseMobx")(observer(
class PwinCalculatedGraph extends Component {
  compute_calc_line_obj(i_x1_0to100, i_x2_0to100, i_y1_0to100, i_y2_0to100, i_lineColor, i_gridXStartP, i_gridXEndP, i_gridYStartP, i_gridYEndP) {
    return({
      x1p: JSFUNC.two_point_interpolation(0, 100, i_gridXStartP, i_gridXEndP, i_x1_0to100),
      x2p: JSFUNC.two_point_interpolation(0, 100, i_gridXStartP, i_gridXEndP, i_x2_0to100),
      y1p: JSFUNC.two_point_interpolation(0, 100, i_gridYStartP, i_gridYEndP, i_y1_0to100),
      y2p: JSFUNC.two_point_interpolation(0, 100, i_gridYStartP, i_gridYEndP, i_y2_0to100),
      color: i_lineColor
    });
  }

  render() {
    const c_companyPwinCalcMinThreshScore = this.props.DatabaseMobx.c_companyPwinCalcMinThreshScore;
    const c_companyPwinCalcMinThreshPwin = this.props.DatabaseMobx.c_companyPwinCalcMinThreshPwin;
    const c_pwinCalcLinesSortedArrayOfObjs = this.props.DatabaseMobx.c_pwinCalcLinesSortedArrayOfObjs;

    const numCalcLines = c_pwinCalcLinesSortedArrayOfObjs.length;
    const usingPwinMinThreshTF = (c_companyPwinCalcMinThreshScore >= 0 && c_companyPwinCalcMinThreshPwin >= 0);

    const gridColor = "#999";
    const gridWidthEm = 0.2;
    const gridLabelFontSize = "1.5em";
    const axisLabelFontSize = "2em";
    const minThreshLineColor = "#922";
    const minThreshLineWidthP = 1;
    const calcLineColor = "#292";
    const calcVConnectorLineColor = "#595";
    const calcHConnectorLineColor = "#595";
    const calcUnusedLineColor = "#cdc";
    const calcLineWidthP = 1;

    //grid
    const gridXStartP = 6;
    const gridXEndP = 95;
    const gridYStartP = 95;
    const gridYEndP = 5;

    const xGridPercentsArray = [0, 20, 40, 60, 80, 100];
    const yGridPercentsArray = [0, 20, 40, 60, 80, 100];

    var xGridPArray = [];
    for(let xGridPercent of xGridPercentsArray) {
      xGridPArray.push(JSFUNC.two_point_interpolation(0, 100, gridXStartP, gridXEndP, xGridPercent));
    }

    var yGridPArray = [];
    for(let yGridPercent of yGridPercentsArray) {
      yGridPArray.push(JSFUNC.two_point_interpolation(0, 100, gridYStartP, gridYEndP, yGridPercent));
    }

    //calc lines
    var calcLinePArrayOfObjs = [];
    for(let c = 0; c < numCalcLines; c++) {
      var currentCalcLineObj = c_pwinCalcLinesSortedArrayOfObjs[c];
      var cx1 = currentCalcLineObj.progress_x1;
      var cx2 = currentCalcLineObj.progress_x2;
      var cy1 = currentCalcLineObj.pwin_y1;
      var cy2 = currentCalcLineObj.pwin_y2;

      var lineX1 = undefined;
      var lineX2 = undefined;
      var lineY1 = undefined;
      var lineY2 = undefined;

      if(c === 0 && cx1 > 0 && cx1 > c_companyPwinCalcMinThreshScore) {
        var horiz0X1 = Math.max(0, c_companyPwinCalcMinThreshScore);
        var horiz0X2 = cx1;
        var horiz0Y1 = cy1;
        var horiz0Y2 = cy1;
        calcLinePArrayOfObjs.push(this.compute_calc_line_obj(horiz0X1, horiz0X2, horiz0Y1, horiz0Y2, calcHConnectorLineColor, gridXStartP, gridXEndP, gridYStartP, gridYEndP));
      }

      if(c > 0) {
        var prevCalcLineObj = c_pwinCalcLinesSortedArrayOfObjs[c - 1];
        var px1 = prevCalcLineObj.progress_x1;
        var px2 = prevCalcLineObj.progress_x2;
        var py1 = prevCalcLineObj.pwin_y1;
        var py2 = prevCalcLineObj.pwin_y2;

        if(cx1 > 0 && cx1 > c_companyPwinCalcMinThreshScore && cx1 > px2) {
          var horizX1 = Math.max(0, c_companyPwinCalcMinThreshScore, px2);
          var horizX2 = cx1;
          var horizY1 = cy1;
          var horizY2 = cy1;
          calcLinePArrayOfObjs.push(this.compute_calc_line_obj(horizX1, horizX2, horizY1, horizY2, calcHConnectorLineColor, gridXStartP, gridXEndP, gridYStartP, gridYEndP));
        }

        if(c === (numCalcLines - 1) && cx2 < 100) { //last defined calc line
          calcLinePArrayOfObjs.push(this.compute_calc_line_obj(cx2, 100, cy2, cy2, calcHConnectorLineColor, gridXStartP, gridXEndP, gridYStartP, gridYEndP));
        }
      }

      if(cx1 < c_companyPwinCalcMinThreshScore && usingPwinMinThreshTF) { //any of this line is in the red, draw that part of the line unused
        var redX1 = cx1;
        var redX2 = undefined;
        var redY1 = cy1;
        var redY2 = undefined;
        if(cx2 < c_companyPwinCalcMinThreshScore) { //line starts and ends in red
          redX2 = cx2;
          redY2 = cy2;
        }
        else { //line starts in red and ends outside
          redX2 = c_companyPwinCalcMinThreshScore;
          redY2 = JSFUNC.two_point_interpolation(cx1, cx2, cy1, cy2, c_companyPwinCalcMinThreshScore);
          calcLinePArrayOfObjs.push(this.compute_calc_line_obj(redX2, cx2, redY2, cy2, calcLineColor, gridXStartP, gridXEndP, gridYStartP, gridYEndP));
        }
        calcLinePArrayOfObjs.push(this.compute_calc_line_obj(redX1, redX2, redY1, redY2, calcUnusedLineColor, gridXStartP, gridXEndP, gridYStartP, gridYEndP));
      }
      else {
        calcLinePArrayOfObjs.push(this.compute_calc_line_obj(cx1, cx2, cy1, cy2, calcLineColor, gridXStartP, gridXEndP, gridYStartP, gridYEndP));
      }

    }

    //min threshold line
    const minThreshXStartP = gridXStartP;
    const minThreshXEndP = JSFUNC.two_point_interpolation(0, 100, gridXStartP, gridXEndP, c_companyPwinCalcMinThreshScore);
    const minThreshYStartP = JSFUNC.two_point_interpolation(0, 100, gridYStartP, gridYEndP, c_companyPwinCalcMinThreshPwin);
    const minThreshYEndP = minThreshYStartP;

    return(
      <svg width="100%" height="40em">
        {xGridPArray.map((m_xGridP, m_index) =>
          <>
            <LibraryReact.SvgVLine p_y1p={gridYStartP} p_y2p={gridYEndP} p_xcp={m_xGridP} p_widthEm={gridWidthEm} p_color={gridColor} />
            <text x={(m_xGridP - 1) + "%"} y={(gridYStartP + 4) + "%"} fill={gridColor} style={{fontSize:gridLabelFontSize}}>{xGridPercentsArray[m_index] + "%"}</text>
          </>
        )}
        {yGridPArray.map((m_yGridP, m_index) =>
          <>
            <LibraryReact.SvgHLine p_x1p={gridXStartP} p_x2p={gridXEndP} p_ycp={m_yGridP} p_widthEm={gridWidthEm} p_color={gridColor} />
            <text x={(gridXStartP - 5) + "%"} y={(m_yGridP + 2) + "%"} fill={gridColor} style={{fontSize:gridLabelFontSize}}>{yGridPercentsArray[m_index] + "%"}</text>
          </>
        )}
        {calcLinePArrayOfObjs.map((m_calcLinePObj) =>
          <line x1={m_calcLinePObj.x1p + "%"} x2={m_calcLinePObj.x2p + "%"} y1={m_calcLinePObj.y1p + "%"} y2={m_calcLinePObj.y2p + "%"} style={{stroke:m_calcLinePObj.color, strokeWidth:calcLineWidthP + "%"}} />
        )}
        <line x1={minThreshXStartP + "%"} x2={minThreshXEndP + "%"} y1={minThreshYStartP + "%"} y2={minThreshYEndP + "%"} style={{stroke:minThreshLineColor, strokeWidth:minThreshLineWidthP + "%"}} />
      </svg>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabProgressColors = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabProgressColors extends Component {
  render() {
    const aedTableObj = {
      tblName: "tbl_a_shaping_progress_colors",
      initialSortedFieldDbName: "percent_start",
      tableWidth: "35em",
      itemName: "Progress Color",
      deleteItemNameFieldDbName: "percent_start",
      fieldsArrayOfObjs: [
        {width:"200em", dbName:"color", displayName:"Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"DD2222", requiredNotEmptyTF:true},
        {width:"100em", dbName:"percent_start", displayName:"Percent Start", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentInt0to100FieldTypeObj, initialValue:0, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"font_color", displayName:"Font Color", fieldTypeObj:this.props.DatabaseMobx.c_selectAdminFontColorFieldTypeObj, initialValue:"2a2a2a", cellCenterTF:true, requiredNotEmptyTF:true}
      ]
    };

    var examplePercentsArray = [0];
    for(let colorObj of this.props.DatabaseMobx.c_shapingProgressColorsArrayOfObjs) {
      var colorStart = colorObj.percent_start;
      var colorStartM1 = (colorStart - 1);
      if(colorStartM1 > 0 && !JSFUNC.in_array(colorStartM1, examplePercentsArray)) {
        examplePercentsArray.push(colorStartM1);
      }

      if(!JSFUNC.in_array(colorStart, examplePercentsArray)) {
        examplePercentsArray.push(colorStart);
      }
    }

    if(!JSFUNC.in_array(100, examplePercentsArray)) {
      examplePercentsArray.push(100);
    }

    return (
      <div className="flex11a xyScroll yScrollBottomPad">
        <div className="displayFlexRow medFullMargin">
          <div className="flex00a">
            <AddEditDeleteTable
              p_dataArrayOfObjs={this.props.DatabaseMobx.c_shapingProgressColorsArrayOfObjs}
              p_aedTableObj={aedTableObj}
            />
          </div>
          <div className="flex00a medFullPad" style={{flexBasis:"16em", marginLeft:"2em", border:"solid 1px #ddd"}}>
            <div className="smallBottomMargin textCenter">
              <font className="font12 fontItalic">
                {"Examples to show progress bar color ranges:"}
              </font>
            </div>
            {examplePercentsArray.map((m_percent) =>
              <div key={m_percent} className="microTopMargin microBottomMargin">
                {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(m_percent, this.props.DatabaseMobx.c_genericProgressBarShapingFieldTypeObj)}
              </div>
            )}
            <div className="hugeTopMargin microBottomMargin textCenter">
              <font className="fontItalic fontTextLighter">
                {"Example negative percentage"}
              </font>
            </div>
            <div className="microTopMargin microBottomMargin">
              {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(-40, this.props.DatabaseMobx.c_genericProgressBarShapingFieldTypeObj)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}));




//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabUpcomingDateColors = inject("CaptureExecMobx", "AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabUpcomingDateColors extends Component {
  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_fieldMapOfProposalDueDateTimeUtc = this.props.DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc;

    return (
      <div className="flex11a displayFlexRow xyScroll">
        <div className="flex00a borderR1bbb lrMedPad" style={{flexBasis:"60em"}}>
          <div className="smallTopMargin microBottomMargin lrMedPad textCenter">
            <font className="font13">
              {"Upcoming Date Color Set #1"}
            </font>
          </div>
          <div className="microBottomMargin lrMedPad textCenter">
            <font className="fontItalic fontTextLight">
              {c_productStylingObj.productName + " uses this color set for "}
              <font className="fontUnderline">{"Task due dates"}</font>{", "}
              <font className="fontUnderline">{"Conversation dates"}</font>{", and "}
              <font className="fontUnderline">{"Contract/Budget need by dates"}</font>
            </font>
          </div>
          <div className="smallBottomMargin lrMedPad textCenter">
            <font className="fontItalic fontTextLight">
              {"You can also create custom Date or Date/Time fields with this color set"}
            </font>
          </div>
          <DateDayMdyDaysUntilOverdueAEDTableWithExamples
            p_tblDataArrayOfObjs={this.props.DatabaseMobx.c_dateUpcomingColors1ArrayOfObjs}
            p_dateDayMdyDaysUntilOverdueFieldTypeObj={this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj}
            p_tblName="tbl_a_date_upcoming_colors1"
            p_itemName="Upcoming Date Color #1"
          />
        </div>
        <div className="flex00a borderR1bbb lrMedPad" style={{flexBasis:"60em"}}>
          <div className="smallTopMargin microBottomMargin lrMedPad textCenter">
            <font className="font13">
              {"Upcoming Date Color Set #2"}
            </font>
          </div>
          <div className="microBottomMargin lrMedPad textCenter">
            <font className="fontItalic fontTextLight">
              {c_productStylingObj.productName + " uses this color set for the "}
              <font className="fontUnderline">{"Proposal Due Date/Time"}</font>{" Capture Field"}
            </font>
          </div>
          <div className="smallBottomMargin lrMedPad textCenter">
            <font className="fontItalic fontTextLight">
              {"You can also create custom Date or Date/Time fields with this color set"}
            </font>
          </div>
          <DateDayMdyDaysUntilOverdueAEDTableWithExamples
            p_tblDataArrayOfObjs={this.props.DatabaseMobx.c_dateUpcomingColors2ArrayOfObjs}
            p_dateDayMdyDaysUntilOverdueFieldTypeObj={this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil2OverdueFieldTypeObj}
            p_tblName="tbl_a_date_upcoming_colors2"
            p_itemName="Upcoming Date Color #2"
          />
        </div>
      </div>
    );
  }
}));


const DateDayMdyDaysUntilOverdueAEDTableWithExamples = inject("AdminMobx", "DatabaseMobx")(observer(
class DateDayMdyDaysUntilOverdueAEDTableWithExamples extends Component { //props: p_tblDataArrayOfObjs, p_dateDayMdyDaysUntilOverdueFieldTypeObj, p_tblName, p_itemName
  render() {
    const p_tblDataArrayOfObjs = this.props.p_tblDataArrayOfObjs;
    const p_dateDayMdyDaysUntilOverdueFieldTypeObj = this.props.p_dateDayMdyDaysUntilOverdueFieldTypeObj
    const p_tblName = this.props.p_tblName;
    const p_itemName = this.props.p_itemName;

    const aedTableObj = {
      tblName: p_tblName,
      dragToResortFieldDbName: undefined,
      initialSortedFieldDbName: "days_before_due",
      tableWidth: "40em",
      itemName: p_itemName,
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"days_before_due", displayName:"# Days Before Due", fieldTypeObj:this.props.DatabaseMobx.c_genericIntFieldTypeObj, initialValue:0, cellCenterTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"color", displayName:"Color", fieldTypeObj:this.props.DatabaseMobx.c_genericColorFieldTypeObj, initialValue:"bbbbbb", requiredNotEmptyTF:true},
        {width:"100em", dbName:"font_color", displayName:"Font Color", fieldTypeObj:this.props.DatabaseMobx.c_selectAdminFontColorFieldTypeObj, initialValue:"2a2a2a", cellCenterTF:true, requiredNotEmptyTF:true}
      ]
    };

    var daysUntilDueArray = [];
    for(let d = -7; d < 60; d++) {
      daysUntilDueArray.push(d);
    }
    for(let d = 60; d < 1500; d+=28) {
      daysUntilDueArray.push(d);
    }

    var ymdDatesArray = [];
    for(let d of daysUntilDueArray) {
      var shiftedDateObj = new Date();
      shiftedDateObj.setDate(shiftedDateObj.getDate() + d);
      var shiftedYear = shiftedDateObj.getFullYear();
      var shiftedMonth = JSFUNC.zero_pad_integer_from_left((shiftedDateObj.getMonth() + 1), 2);
      var shiftedDate = JSFUNC.zero_pad_integer_from_left(shiftedDateObj.getDate(), 2);
      ymdDatesArray.push(shiftedYear + "-" + shiftedMonth + "-" + shiftedDate);
    }

    return (
      <div className="displayFlexRow">
        <div className="flex00a">
          <AddEditDeleteTable
            p_dataArrayOfObjs={p_tblDataArrayOfObjs}
            p_aedTableObj={aedTableObj}
          />
        </div>
        <div className="flex00a lrMargin" />
        <div className="flex00a border1ddd medFullPad" style={{flexBasis:"17em"}}>
          {ymdDatesArray.map((m_ymdDate) =>
            <div>
              {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(m_ymdDate, p_dateDayMdyDaysUntilOverdueFieldTypeObj)}
            </div>
          )}
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabDeleteCaptureReasons = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabDeleteCaptureReasons extends Component {
  render() {
    const deleteCaptureReasonsArrayOfObjs = this.props.AdminMobx.c_deleteCaptureReasonsArrayOfObjs;

    const aedTableObj = {
      tblName: "tbl_a_delete_capture_reasons",
      dragToResortFieldDbName: "admin_sort",
      initialSortedFieldDbName: "admin_sort",
      tableWidth: "60em",
      itemName: "Delete Capture Reason",
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"100em", dbName:"name", displayName:"Reason", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"3em", dbName:"admin_sort", displayName:"Admin Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
      ]
    };

    return (
      <div className="flex11a xyScroll yScrollBottomPad">
        <div className="medFullMargin">
          <AddEditDeleteTable
            p_dataArrayOfObjs={deleteCaptureReasonsArrayOfObjs}
            p_aedTableObj={aedTableObj}
          />
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabQuestionTags = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabQuestionTags extends Component {
  render() {
    const aedTableObj = {
      tblName: "tbl_a_shaping_questions_tags",
      dragToResortFieldDbName: undefined,
      initialSortedFieldDbName: "name",
      tableWidth: "60em",
      itemName: "Question Tag",
      deleteItemNameFieldDbName: "name",
      fieldsArrayOfObjs: [
        {width:"150em", dbName:"name", displayName:"Tag", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
        {width:"100em", dbName:"hover_text", displayName:"Hover Help Text", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true}
      ]
    };

    return (
      <div className="flex11a xyScroll yScrollBottomPad">
        <div className="medFullMargin">
          <AddEditDeleteTable
            p_dataArrayOfObjs={this.props.AdminMobx.c_shapingQuestionTagsArrayOfObjs}
            p_aedTableObj={aedTableObj}
          />
        </div>
      </div>
    );
  }
}));




//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabAdminChangelog = inject("AdminMobx")(observer(
class AdminSubtabAdminChangelog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_clickedAdminChangelogRowKey: -1
    }
  }

  componentDidMount() {
    this.props.AdminMobx.a_load_admin_changelog_data();
  }

  onclick_column_header = (i_columnDbName) => {
    const o_adminChangelogFilterObj = this.props.AdminMobx.o_adminChangelogFilterObj;

    if(i_columnDbName === o_adminChangelogFilterObj.sortColumnDbName) { //clicked column already sorted, flip the direction of the sort
      const updatedSortIsAscTF = (!o_adminChangelogFilterObj.sortIsAscTF);
      this.props.AdminMobx.a_set_admin_changelog_filter_field("sortIsAscTF", updatedSortIsAscTF);
    }
    else {
      this.props.AdminMobx.a_set_admin_changelog_filter_field("sortColumnDbName", i_columnDbName);
      this.props.AdminMobx.a_set_admin_changelog_filter_field("sortIsAscTF", true);
    }
  }

  onclick_admin_changelog_row = (i_changelogIDClicked) => {
    const s_clickedAdminChangelogRowKey = this.state.s_clickedAdminChangelogRowKey;

    var updatedClickedChangelogRowID = -1;
    if(i_changelogIDClicked !== s_clickedAdminChangelogRowKey) {
      updatedClickedChangelogRowID = i_changelogIDClicked;
    }
    this.setState({s_clickedAdminChangelogRowKey:updatedClickedChangelogRowID});
  }

  render() {
    const s_clickedAdminChangelogRowKey = this.state.s_clickedAdminChangelogRowKey;

    const o_adminChangelogLoadingDataOrErrorUndefinedTFU = this.props.AdminMobx.o_adminChangelogLoadingDataOrErrorUndefinedTFU;
    const o_adminChangelogFilterObj = this.props.AdminMobx.o_adminChangelogFilterObj;
    const c_filteredSortedItemsOnSelectedPageExpandedAdminChangelogArrayOfObjs = this.props.AdminMobx.c_filteredSortedItemsOnSelectedPageExpandedAdminChangelogArrayOfObjs;

    const flex01Array = [0,1,1,1,1];
    const flexBasisEmArray = [11.5,100,200,300,100];
    const columnKeysArray = ["d", "c", "f", "v", "u"];
    const columnDbNamesArray = ["datetime_utc", "categoryDescription", "field", "value", "user"];
    var columnDisplayNamesArray = ["Date", "Action Category", "Field", "New Value", "Changed By"];
    for(let c = 0; c < columnDbNamesArray.length; c++) {
      if(columnDbNamesArray[c] === o_adminChangelogFilterObj.sortColumnDbName) {
        columnDisplayNamesArray[c] += " " + ((o_adminChangelogFilterObj.sortIsAscTF) ? (" \u25BC") : (" \u25B2"));
      }
    }

    if(o_adminChangelogLoadingDataOrErrorUndefinedTFU === true) {
      return(
        <div className="displayFlexColumnHcVc medFullMargin border1bbb borderRadius05 bgLightestGray medFullPad" style={{maxWidth:"40em"}}>
          <div className="flex00a smallBottomMargin textCenter">
            <font className="font11 fontItalic fontTextLight">
              {"Loading the Admin Changelog data..."}
            </font>
          </div>
          <div className="flex00a">
            <CEGeneralReact.LoadingAnimation />
          </div>
        </div>
      );
    }

    if(o_adminChangelogLoadingDataOrErrorUndefinedTFU === undefined) {
      return(
        <div className="displayFlexColumnHcVc medFullMargin border1bbb borderRadius05 bgLightRed medFullPad" style={{maxWidth:"40em"}}>
          <div className="flex00a smallBottomMargin textCenter">
            <font className="font11 fontItalic fontRed">
              <div>{"There was an issue loading the Admin Changelog data."}</div>
              <div>{"Switch tabs and come back to this one to try reloading."}</div>
            </font>
          </div>
        </div>
      );
    }

    return (
      <>
        <AdminChangelogFilterControls />
        <CEGeneralReact.ColumnHeaderRow
          p_flex01Array={flex01Array}
          p_flexBasisEmArray={flexBasisEmArray}
          p_columnKeysArray={columnKeysArray}
          p_columnDbNamesArray={columnDbNamesArray}
          p_columnDisplayNamesArray={columnDisplayNamesArray}
          f_onClickHeader={this.onclick_column_header}
        />
        <div className="flex11a yScroll yScrollBottomPad">
          {c_filteredSortedItemsOnSelectedPageExpandedAdminChangelogArrayOfObjs.map((m_expandedAdminChangelogObj) =>
            <CEGeneralReact.DataRow
              key={m_expandedAdminChangelogObj.key}
              p_itemID={m_expandedAdminChangelogObj.key}
              p_flex01Array={flex01Array}
              p_flexBasisEmArray={flexBasisEmArray}
              p_columnKeysArray={columnKeysArray}
              p_dataArray={JSFUNC.create_array_from_obj_and_array_of_property_names(m_expandedAdminChangelogObj, columnDbNamesArray)}
              p_class={((m_expandedAdminChangelogObj.key === s_clickedAdminChangelogRowKey) ? ("bgLighterBlue") : (undefined))}
              p_heightLimit={((m_expandedAdminChangelogObj.key === s_clickedAdminChangelogRowKey) ? (undefined) : ("2em"))}
              p_title="[Click to view all content in this data row]"
              f_onClick={this.onclick_admin_changelog_row}
            />
          )}
        </div>
      </>
    );
  }
}));
  
const AdminChangelogFilterControls = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminChangelogFilterControls extends Component { //props:
  onclick_increment_current_page_number = () => {
    const o_adminChangelogFilterObj = this.props.AdminMobx.o_adminChangelogFilterObj;
    const c_adminChangelogCanIncrementCurrentPageNumberTF = this.props.AdminMobx.c_adminChangelogCanIncrementCurrentPageNumberTF;

    if(c_adminChangelogCanIncrementCurrentPageNumberTF) {
      this.props.AdminMobx.a_set_admin_changelog_filter_field("currentPageNumber", o_adminChangelogFilterObj.currentPageNumber + 1);
    }
  }

  onclick_decrement_current_page_number = () => {
    const o_adminChangelogFilterObj = this.props.AdminMobx.o_adminChangelogFilterObj;
    const c_adminChangelogCanDecrementCurrentPageNumberTF = this.props.AdminMobx.c_adminChangelogCanDecrementCurrentPageNumberTF;

    if(c_adminChangelogCanDecrementCurrentPageNumberTF) {
      this.props.AdminMobx.a_set_admin_changelog_filter_field("currentPageNumber", o_adminChangelogFilterObj.currentPageNumber - 1);
    }
  }

  onchange_admin_changelog_filter_category_code = (i_selectedCategoryCodesComma) => {
    this.props.AdminMobx.a_set_admin_changelog_filter_field("adminActionCodeCategoryCodesComma", i_selectedCategoryCodesComma);
    this.props.AdminMobx.a_set_admin_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_admin_changelog_filter_user_id = (i_selectedUserID) => {
    this.props.AdminMobx.a_set_admin_changelog_filter_field("userID", i_selectedUserID);
    this.props.AdminMobx.a_set_admin_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_admin_changelog_filter_date_min = (i_selectedDate) => {
    this.props.AdminMobx.a_set_admin_changelog_filter_field("dateMin", i_selectedDate);
    this.props.AdminMobx.a_set_admin_changelog_filter_field("currentPageNumber", 1);
  }

  onchange_admin_changelog_filter_date_max = (i_selectedDate) => {
    this.props.AdminMobx.a_set_admin_changelog_filter_field("dateMax", i_selectedDate);
    this.props.AdminMobx.a_set_admin_changelog_filter_field("currentPageNumber", 1);
  }

  render() {
    const o_adminChangelogFilterObj = this.props.AdminMobx.o_adminChangelogFilterObj; //numItemsPerPage, currentPageNumber, adminActionCodeCategoryCodesComma, userID, dateMin, dateMax, sortColumnDbName, sortIsAscTF
    const c_adminChangelogFilteredTotalNumItems = this.props.AdminMobx.c_adminChangelogFilteredTotalNumItems;
    const c_adminChangelogCurrentPageFirstItemNumber = this.props.AdminMobx.c_adminChangelogCurrentPageFirstItemNumber;
    const c_adminChangelogCurrentPageLastItemNumber = this.props.AdminMobx.c_adminChangelogCurrentPageLastItemNumber;
    const c_adminChangelogCanIncrementCurrentPageNumberTF = this.props.AdminMobx.c_adminChangelogCanIncrementCurrentPageNumberTF;
    const c_adminChangelogCanDecrementCurrentPageNumberTF = this.props.AdminMobx.c_adminChangelogCanDecrementCurrentPageNumberTF;

    const pageButtonWidthEm = 2.2;
    const pageButtonHeightEm = 1.4;
    const pageButtonCanClickBgClass = "bgDarkOrangeGradient hoverLightOrangeGradient";
    const pageButtonCanClickFontClass = "fontWhite";
    const pageButtonCantClickBgClass = "bgLightestBlue";
    const pageButtonCantClickFontClass = "fontTextLighter";

    return(
      <div className="flex00a displayFlexRow flexWrap borderB1bbb bgLightesterGray tbPad">
        <AdminChangelogControlContainer p_label="Pages of Admin Changelog Entries" p_width="17em">
          <div className="displayFlexRowVc bgAlmostWhite border bevelBorderColors tbMicroPad lrMedPad">
            <div className="flex00a">
              <CEGeneralReact.PageIncrementOrDecrementButton
                p_incTrueDecFalse={false}
                p_canClickTF={c_adminChangelogCanDecrementCurrentPageNumberTF}
                p_widthEm={pageButtonWidthEm}
                p_heightEm={pageButtonHeightEm}
                p_canClickBgClass={pageButtonCanClickBgClass}
                p_canClickFontClass={pageButtonCanClickFontClass}
                p_cantClickBgClass={pageButtonCantClickBgClass}
                p_cantClickFontClass={pageButtonCantClickFontClass}
                f_onClick={this.onclick_decrement_current_page_number}
              />
            </div>
            <div className="flex11a lrPad textCenter">
              <font className="">
                {c_adminChangelogCurrentPageFirstItemNumber + " - " + c_adminChangelogCurrentPageLastItemNumber + " of " + c_adminChangelogFilteredTotalNumItems}
              </font>
            </div>
            <div className="flex00a">
              <CEGeneralReact.PageIncrementOrDecrementButton
                p_incTrueDecFalse={true}
                p_canClickTF={c_adminChangelogCanIncrementCurrentPageNumberTF}
                p_widthEm={pageButtonWidthEm}
                p_heightEm={pageButtonHeightEm}
                p_canClickBgClass={pageButtonCanClickBgClass}
                p_canClickFontClass={pageButtonCanClickFontClass}
                p_cantClickBgClass={pageButtonCantClickBgClass}
                p_cantClickFontClass={pageButtonCantClickFontClass}
                f_onClick={this.onclick_increment_current_page_number}
              />
            </div>
          </div>
        </AdminChangelogControlContainer>
        <AdminChangelogControlContainer p_label="Filter by Action Category" p_width="24em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_adminChangelogFilterObj.adminActionCodeCategoryCodesComma, this.props.AdminMobx.c_selectMultiAdminCategoryCodesFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.AdminMobx.c_selectMultiAdminCategoryCodesFieldTypeObj}
            p_valueRaw={o_adminChangelogFilterObj.adminActionCodeCategoryCodesComma}
            p_tabIndex={2}
            f_onChangeOrOnSelect={this.onchange_admin_changelog_filter_category_code}
          />
        </AdminChangelogControlContainer>
        <AdminChangelogControlContainer p_label="Filter by User" p_width="22em" p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_adminChangelogFilterObj.userID, this.props.DatabaseMobx.c_selectUserFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_selectUserWithAdminPowerFieldTypeObj}
            p_valueRaw={o_adminChangelogFilterObj.userID}
            p_tabIndex={3}
            f_onChangeOrOnSelect={this.onchange_admin_changelog_filter_user_id}
          />
        </AdminChangelogControlContainer>
        <AdminChangelogControlContainer p_label="Date Min" p_width={undefined} p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_adminChangelogFilterObj.dateMin, this.props.DatabaseMobx.c_genericDateFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
            p_valueRaw={o_adminChangelogFilterObj.dateMin}
            p_tabIndex={4}
            f_onChangeOrOnSelect={this.onchange_admin_changelog_filter_date_min}
          />
        </AdminChangelogControlContainer>
        <AdminChangelogControlContainer p_label="Date Max" p_width={undefined} p_isFilledOutTF={this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_adminChangelogFilterObj.dateMax, this.props.DatabaseMobx.c_genericDateFieldTypeObj)}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
            p_valueRaw={o_adminChangelogFilterObj.dateMax}
            p_tabIndex={5}
            f_onChangeOrOnSelect={this.onchange_admin_changelog_filter_date_max}
          />
        </AdminChangelogControlContainer>
      </div>
    );
  }
}));

function AdminChangelogControlContainer(props) { //props: p_label, p_width, p_isFilledOutTF, children
  const p_label = props.p_label;
  const p_width = props.p_width;
  const p_isFilledOutTF = props.p_isFilledOutTF;

  return(
    <div
      className={"flex00a lrMargin " + ((p_isFilledOutTF) ? ("bgLightPurpleGradient") : ("")) + " tbMicroPad lrMedPad"}
      style={{width:p_width}}>
      <div className="microBottomMargin textCenter">
        <font className="font09 fontItalic fontTextLight">
          {p_label}
        </font>
      </div>
      <div>
        {props.children}
      </div>
    </div>
  );
}





//=================================================================================================================================================================
//=================================================================================================================================================================



export const AdminSubtabOnPremise = inject("CaptureExecMobx")(observer(
class AdminSubtabOnPremise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_authenticatedDate: "loading" //"loading", "2022-01-15", undefined
    };
  }

  componentDidMount() {
    this.verify_on_premise_customer_authentication_quarterly_passcode_file();
  }

  set_authenticated_date = (i_loadingDateOrUndefined) => {
    this.setState({s_authenticatedDate:i_loadingDateOrUndefined});
  }

  verify_on_premise_customer_authentication_quarterly_passcode_file = () => {
    this.set_authenticated_date("loading");

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AdminSubtabOnPremise", "verify_on_premise_customer_authentication_quarterly_passcode_file", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("verifyOnPremiseCustomerAuthenticationQuarterlyPasscodeFile", jsDescription);
    C_CallPhpScript.add_return_vars("passcode");

    const functionOnSuccess = (i_parseResponse) => {
      var onPremiseAuthenticationExpirationDate = this.props.CaptureExecMobx.opa(i_parseResponse.passcode);
      this.set_authenticated_date(onPremiseAuthenticationExpirationDate);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
  }

  render() {
    const s_authenticatedDate = this.state.s_authenticatedDate;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    var authenticationString = null;
    var bgColor = "999";
    var fontClass = "font12";
    if(JSFUNC.date_is_filled_out_tf(s_authenticatedDate)) {
      if(s_authenticatedDate >= JSFUNC.now_date()) {
        authenticationString = c_productStylingObj.productName + " On-Premise is authenticated through " + s_authenticatedDate;
        bgColor = "cfc";
      }
      else {
        authenticationString = c_productStylingObj.productName + " On-Premise has expired (" + s_authenticatedDate + ") and requires an Authentication Passcode file to continue use";
        bgColor = "fcc";
        fontClass += " fontDarkRed";
      }
    }
    else if(s_authenticatedDate === "loading") {
      authenticationString = "Verifying " + c_productStylingObj.productName + " On-Premise authentication";
      bgColor = "ddd";
      fontClass += " fontItalic";
    }
    else {
      authenticationString = c_productStylingObj.productName + " On-Premise requires an Authentication Passcode file to continue use";
      bgColor = "fcc";
      fontClass += " fontDarkRed";
    }

    return (
      <div className="flex11a xyScroll medFullPad">
        <div className="displayFlexColumnHcVc medFullMargin border1bbb bgLightestGray hugeTopPad hugeBottomPad lrHugePad" style={{width:"40em"}}>
          <div className="hugeBottomMargin textCenter">
            <font className="font14 fontBold fontTextLighter">
              {"On-Premise Authentication Passcode File"}
            </font>
          </div>
          <div className="displayFlexColumnHcVc width100 hugeBottomMargin border1444 medFullPad textCenter" style={{background:"#" + bgColor}}>
            {authenticationString}
            {(s_authenticatedDate === "loading") &&
              <div className="microTopMargin">
                <CEGeneralReact.LoadingAnimation />
              </div>
            }
          </div>
          <div className="smallBottomMargin textCenter">
            <font className="fontTextLight">
              {"Upload a newer Authentication File here"}
            </font>
          </div>
          <CEGeneralReact.OnPremiseUploadNewAuthenticationFile
            f_onUploadFile={this.verify_on_premise_customer_authentication_quarterly_passcode_file}
          />
        </div>
      </div>
    );
  }
}));





//=================================================================================================================================================================
//=================================================================================================================================================================





export const AdminSubtabMyAccount = inject("AdminMobx", "DatabaseMobx")(observer(
class AdminSubtabMyAccount extends Component {
  componentDidMount() {
    this.props.AdminMobx.a_my_account_load_stripe_data();
  }

  render() {
    const o_myAccountStripeDataLoadingTF = this.props.AdminMobx.o_myAccountStripeDataLoadingTF;
    const o_myAccountStripeDataErrorMessageOrUndefined = this.props.AdminMobx.o_myAccountStripeDataErrorMessageOrUndefined;
    const o_stripeCustomerDataObjOrUndefined = this.props.DatabaseMobx.o_stripeCustomerDataObjOrUndefined;

    if(o_myAccountStripeDataLoadingTF) {
      return("Loading...");
    }

    if(o_myAccountStripeDataErrorMessageOrUndefined !== undefined) {
      return("Error loading account data: " + o_myAccountStripeDataErrorMessageOrUndefined);
    }

    if(o_stripeCustomerDataObjOrUndefined === undefined) {
      return("Error loading account data.");
    }

    const initialPurchaseDateTimeUtc = o_stripeCustomerDataObjOrUndefined.initialPurchaseDateTimeUtc;
    const numLicensesPurchased = o_stripeCustomerDataObjOrUndefined.numLicensesPurchased;
    const currentPeriodStartDateTimeUtc = o_stripeCustomerDataObjOrUndefined.currentPeriodStartDateTimeUtc;
    const currentPeriodEndDateTimeUtc = o_stripeCustomerDataObjOrUndefined.currentPeriodEndDateTimeUtc;
    const upcomingInvoiceObj = o_stripeCustomerDataObjOrUndefined.upcomingInvoiceObj;
    const pastInvoicesArrayOfObjs = o_stripeCustomerDataObjOrUndefined.pastInvoicesArrayOfObjs;

    return(
      <div className="medFullMargin border1bbb medFullPad" style={{maxWidth:"40em"}}>
        <div className="smallBottomMargin">
          <font className="font11 fontItalic">
            {"Upcoming Invoice"}
          </font>
        </div>
        <div className="displayFlexRow borderB1ddd tbPad">
          <div className="flex11a lrPad">
            <font className="">
              {upcomingInvoiceObj.periodStartDateTimeUtc}
            </font>
          </div>
          <div className="flex11a lrPad">
            <font className="">
              {upcomingInvoiceObj.chargeDateTimeUtc}
            </font>
          </div>
          <div className="flex11a lrPad">
            <font className="">
              {upcomingInvoiceObj.totalDueFormatted}
            </font>
          </div>
          <div className="flex11a lrPad">
            <font className="">
              {upcomingInvoiceObj.paid01String}
            </font>
          </div>
        </div>
        <div className="hugeTopMargin smallBottomMargin">
          <font className="font11 fontItalic">
            {"Past Invoices"}
          </font>
        </div>
        {pastInvoicesArrayOfObjs.map((m_pastInvoiceObj) =>
          <div className="displayFlexRow borderB1ddd tbPad">
            <div className="flex11a lrPad">
              <font className="">
                {m_pastInvoiceObj.periodStartDateTimeUtc}
              </font>
            </div>
            <div className="flex11a lrPad">
              <font className="">
                {m_pastInvoiceObj.chargeDateTimeUtc}
              </font>
            </div>
            <div className="flex11a lrPad">
              <font className="">
                {m_pastInvoiceObj.totalDueFormatted}
              </font>
            </div>
            <div className="flex11a lrPad">
              <font className="">
                {m_pastInvoiceObj.paid01String}
              </font>
            </div>
          </div>
        )}
      </div>
    );
  }
}));






//=================================================================================================================================================================
//=================================================================================================================================================================
//=================================================================================================================================================================
//=================================================================================================================================================================
//=================================================================================================================================================================
//=================================================================================================================================================================
//=================================================================================================================================================================
//=================================================================================================================================================================
//=================================================================================================================================================================
//=================================================================================================================================================================




export const CaptureTypeSelectionBoxes = inject("AdminMobx", "DatabaseMobx")(observer(
class CaptureTypeSelectionBoxes extends Component {
render() {
  this.props.AdminMobx.a_ensure_selected_capture_type_exists_or_select_first_capture_type();

  const c_adminCaptureTypesWithMatchingCapturesArrayOfObjs = this.props.AdminMobx.c_adminCaptureTypesWithMatchingCapturesArrayOfObjs;

  const atLeast1CaptureTypeTF = (c_adminCaptureTypesWithMatchingCapturesArrayOfObjs.length > 0);

  return (
    <div className="flex00a displayFlexRowVc border bevelBorderColors" style={{background:"#f3e0cb"}}>
      <div className="flex00a tbPad lrMedPad">
        <font className="font12 fontTextLight fontItalic">
          <div>{"Capture Type"}</div>
          <div>{"Selected for Editing"}</div>
        </font>
      </div>
      {(!atLeast1CaptureTypeTF) ? (
        <div className="flex11a smallFullPad">
          <font className="fontItalic fontTextLighter">
            {"--There are 0 created Capture Types in the system. Use the Admin System Setup subtab 'Capture Types' to create one--"}
          </font>
        </div>
      ) : (
        <div className="flex11a displayFlexRow tbPad xyScroll">
          {c_adminCaptureTypesWithMatchingCapturesArrayOfObjs.map((m_captureTypeObj) =>
            <CaptureTypeSelectionBox
              key={m_captureTypeObj.id}
              p_captureTypeObj={m_captureTypeObj}
            />
          )}
        </div>
      )}
    </div>
  );
}
}));

export const CaptureTypeSelectionBox = inject("AdminMobx")(observer(
class CaptureTypeSelectionBox extends Component { //props: p_captureTypeObj
  onclick_capture_type_box = () => {
    const p_captureTypeObj = this.props.p_captureTypeObj;
    this.props.AdminMobx.a_set_selected_capture_type_id(p_captureTypeObj.id);
  }

  render() {
    const p_captureTypeObj = this.props.p_captureTypeObj;

    const o_selectedCaptureTypeID = this.props.AdminMobx.o_selectedCaptureTypeID;

    const isSelectedTF = (p_captureTypeObj.id === o_selectedCaptureTypeID);

    return(
      <div
        className={"flex00a displayFlexRowVc lrMargin lrMedPad border bevelBorderColors textCenter " + ((isSelectedTF) ? ("bgOrangeGradient") : ("bgLightGrayGradient hoverLighterBlueGradient cursorPointer"))}
        style={{height:"3.8em", width:"11em"}}
        title={p_captureTypeObj.name}
        onClick={((isSelectedTF) ? (undefined) : (this.onclick_capture_type_box))}>
        <LibraryReact.MaxHeightWrap p_maxHeight="3.7em" p_fontClass={"font11 fontBold " + ((isSelectedTF) ? ("") : ("fontTextLighter"))}>
          {p_captureTypeObj.name}
        </LibraryReact.MaxHeightWrap>
      </div>
    );
  }
}));



const BlueItemCollectionShell = inject("AdminMobx")(observer(
class BlueItemCollectionShell extends Component { //props:
  //p_isReceivingTF, p_title, p_dropUniqueString, p_dropNotAllowedDraggerIDsArray, p_dropMessage, p_dropAllowDropMessage, p_dropNotAllowDropMessage,
  //f_onClickSetAsReceiving, f_onDrop, f_onRemoveAllItems, children
  render() {
    const p_isReceivingTF = this.props.p_isReceivingTF;
    const p_title = this.props.p_title;
    const p_dropUniqueString = this.props.p_dropUniqueString;
    const p_dropNotAllowedDraggerIDsArray = this.props.p_dropNotAllowedDraggerIDsArray;
    const p_dropMessage = this.props.p_dropMessage;
    const p_dropAllowDropMessage = this.props.p_dropAllowDropMessage;
    const p_dropNotAllowDropMessage = this.props.p_dropNotAllowDropMessage;

    const c_selectedCaptureTypeExistsTF = this.props.AdminMobx.c_selectedCaptureTypeExistsTF;

    return(
      <div
        className={"medTopMargin smallTopPad lrMedPad " + ((p_isReceivingTF) ? ("bgAdminCaptureTypeTableReceiving") : ("bgAdminCaptureTypeTable"))}
        style={{border:"solid 1px #ddd", borderRadius:"1em"}}>
        {(c_selectedCaptureTypeExistsTF) ? (
          <>
            <div className="displayFlexRow">
              <div className="flex11a">
                <font className="font12">
                  {p_title}
                </font>
              </div>
              {(JSFUNC.is_function(this.props.f_onRemoveAllItems)) &&
                <div className="flex00a displayFlexColumnHcVc" style={{height:"1.2em"}}>
                  <CEGeneralReact.DeleteMenu
                    p_message={["Are you sure you want to remove all items in this group?", "[This performs the same action as clicking the 'x' next to every item within this group]"]}
                    p_menuItemText="Remove All Items"
                    f_onDelete={this.props.f_onRemoveAllItems}
                  />
                </div>
              }
            </div>
            <div className="displayFlexRowVc tbMargin">
              <div className="flex00a" style={{flexBasis:"15em"}}>
                {(p_isReceivingTF) ? (
                  <div className="">
                    <font className="fontItalic fontTextLighter">
                      {"Receiving Added Items"}
                    </font>
                  </div>
                ) : (
                  <CEGeneralReact.CEButton
                    p_type="gray"
                    p_text="Set as Receiving"
                    f_onClick={this.props.f_onClickSetAsReceiving}
                  />
                )}
              </div>
              <div className="flex11a" style={{margin:"0 5%"}}>
                <BlueItemCollectionShellDropZone
                  p_dropUniqueString={p_dropUniqueString}
                  p_dropNotAllowedDraggerIDsArray={p_dropNotAllowedDraggerIDsArray}
                  p_dropMessage={p_dropMessage}
                  p_dropAllowDropMessage={p_dropAllowDropMessage}
                  p_dropNotAllowDropMessage={p_dropNotAllowDropMessage}
                  f_onDrop={this.props.f_onDrop}
                />
              </div>
            </div>
            {this.props.children}
          </>
        ) : (
          <div className="bigTopMargin bigBottomMargin textCenter">
            <font className="font12 fontItalic fontTextLight">
              {"--A Capture Type must be created and selected (using the 'Capture Types' subtab) before adding these items to it--"}
            </font>
          </div>
        )}
      </div>
    );
  }
}));

const BlueItemCollectionShellDropZone = inject("CaptureExecMobx")(observer(
class BlueItemCollectionShellDropZone extends Component { //props: p_dropUniqueString, p_dropNotAllowedDraggerIDsArray, p_dropMessage, p_dropAllowDropMessage, p_dropNotAllowDropMessage, f_onDrop
  render() {
    const p_dropUniqueString = this.props.p_dropUniqueString;
    const p_dropNotAllowedDraggerIDsArray = this.props.p_dropNotAllowedDraggerIDsArray;
    const p_dropMessage = this.props.p_dropMessage;
    const p_dropAllowDropMessage = this.props.p_dropAllowDropMessage;
    const p_dropNotAllowDropMessage = this.props.p_dropNotAllowDropMessage;

    const o_ceDraggedItemObjOrUndefined = this.props.CaptureExecMobx.o_ceDraggedItemObjOrUndefined;

    //allow a drop from matching uniqueString dragged items that have an itemID not already added to this blue item collection
    var allowDropTF = false;
    if(o_ceDraggedItemObjOrUndefined !== undefined) {
      allowDropTF = ((p_dropUniqueString === o_ceDraggedItemObjOrUndefined.uniqueString) && !JSFUNC.in_array(o_ceDraggedItemObjOrUndefined.itemID, p_dropNotAllowedDraggerIDsArray));
    }

    const dragOverMatchingItemMessage = ((allowDropTF) ? (p_dropAllowDropMessage) : (p_dropNotAllowDropMessage));

    return(
      <CEGeneralReact.CEDropZone
        p_width="100%"
        p_heightEm={3}
        p_allowDropTF={allowDropTF}
        p_uniqueString={p_dropUniqueString}
        p_fileUploadIsProcessingTF={false}
        p_message={p_dropMessage}
        p_dragOverMatchingItemMessage={dragOverMatchingItemMessage}
        f_onDropMatchingItem={this.props.f_onDrop}
      />
    );
  }
}));




export const AEDTableForExtraFieldColumnManipulation = inject("ContactsMobx", "DatabaseMobx")(observer(
class AEDTableForExtraFieldColumnManipulation extends Component { //props: p_tblName
  render() {
    const p_tblName = this.props.p_tblName;

    const c_teammatesAllocOfCovFieldDisplayName = this.props.DatabaseMobx.c_teammatesAllocOfCovFieldDisplayName;
    const c_teammatesWorkshareTypeFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareTypeFieldDisplayName;
    const c_teammatesWorkshareNumFtesFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareNumFtesFieldDisplayName;
    const c_teammatesWorkshareNotesFieldDisplayName = this.props.DatabaseMobx.c_teammatesWorkshareNotesFieldDisplayName;

    var renameSystemFieldDisplayNamesComponent = undefined;
    var aedTableLabel = "";
    var itemName = "";
    var hasFieldHoverTextColumnTF = false;
    var customFieldsArrayOfObjs = [];
    var alterDbTblColumnOnAddOrDeleteForTblName = undefined;
    var bgClass = "bgLighterGray";
    var titleComponent = null;
    var allFieldsArrayOfObjs = [];
    if(p_tblName === "tbl_a_contacts_persons_extra_fields" || p_tblName === "tbl_a_contacts_companies_extra_fields") {
      const isPersonTF = (p_tblName === "tbl_a_contacts_persons_extra_fields");
      const personCompanyString = ((isPersonTF) ? ("Person") : ("Company"));
      itemName = "Contact " + personCompanyString + " Custom Field";
      aedTableLabel = "Custom Contact " + personCompanyString + " Fields";
      hasFieldHoverTextColumnTF = false;
      customFieldsArrayOfObjs = ((isPersonTF) ? (this.props.DatabaseMobx.c_contactsPersonsExtraFieldsArrayOfObjs) : (this.props.DatabaseMobx.c_contactsCompaniesExtraFieldsArrayOfObjs));
      alterDbTblColumnOnAddOrDeleteForTblName = ((isPersonTF) ? ("tbl_g_contacts_persons") : ("tbl_g_contacts_companies"));
      bgClass = "bgContactsLighterYellow";
      titleComponent = (
        <div className="displayFlexRowVc smallBottomPad" style={{borderBottom:"solid 1px #ddd"}}>
          <div className="flex00a lrMedPad">
            <ContactsReact.CompanyOrPersonIcon p_isPersonTF={isPersonTF} p_sizeEm={3} />
          </div>
          <div className="flex11a lrMedPad">
            <font className="font12">
              {"All fields (system + custom) for a Contact " + personCompanyString}
            </font>
          </div>
        </div>
      );

      const contactFieldsArrayOfObjs = this.props.ContactsMobx.contact_fields_arrayOfObjs(isPersonTF);
      for(let contactFieldObj of contactFieldsArrayOfObjs) {
        if(contactFieldObj.db_name !== "tree_id") {
          allFieldsArrayOfObjs.push(contactFieldObj);
        }
      }
    }
    else if(p_tblName === "tbl_a_teammates_extra_fields") {
      renameSystemFieldDisplayNamesComponent = (
        <>
          <div className="microBottomMargin">
            <font className="font13 fontItalic">
              {"Teammate System Fields - Optional Alternative Display Names"}
            </font>
          </div>
          <div className="hugeBottomMargin border1bbb borderRadius15 bgAddEditDeleteTable tbBigPad lrMedPad" style={{maxWidth:"56em"}}>
            <div className="smallBottomMargin">
              <font className="fontItalic">
                {"Give optional alternative names to specific system Teammate fields [When left blank, the default name of the field is used]"}
              </font>
            </div>
            <div className="border1bbb bgLightestestGray smallFullPad">
              <TeammateRenameableSystemFieldRow
                p_companyRenameableTeammateAltFieldDbName="teammates_alloc_of_cov_alt_field_display_name"
                p_companyTeammatesAltFieldDisplayName={this.props.DatabaseMobx.c_companyTeammatesAllocOfCovAltFieldDisplayName}
                p_teammateSystemFieldDisplayName={"Allocation % of " + this.props.DatabaseMobx.c_fieldMapOfContractOverallValue.get("display_name")}
              />
              <TeammateRenameableSystemFieldRow
                p_companyRenameableTeammateAltFieldDbName="teammates_workshare_type_alt_field_display_name"
                p_companyTeammatesAltFieldDisplayName={this.props.DatabaseMobx.c_companyTeammatesWorkshareTypeAltFieldDisplayName}
                p_teammateSystemFieldDisplayName="Workshare Type"
              />
              <TeammateRenameableSystemFieldRow
                p_companyRenameableTeammateAltFieldDbName="teammates_workshare_num_ftes_alt_field_display_name"
                p_companyTeammatesAltFieldDisplayName={this.props.DatabaseMobx.c_companyTeammatesWorkshareNumFtesAltFieldDisplayName}
                p_teammateSystemFieldDisplayName="Workshare Number of FTEs"
              />
              <TeammateRenameableSystemFieldRow
                p_companyRenameableTeammateAltFieldDbName="teammates_workshare_notes_alt_field_display_name"
                p_companyTeammatesAltFieldDisplayName={this.props.DatabaseMobx.c_companyTeammatesWorkshareNotesAltFieldDisplayName}
                p_teammateSystemFieldDisplayName="Workshare Notes"
              />
            </div>
          </div>
        </>
      );

      itemName = "Teammate Custom Field";
      aedTableLabel = "Custom Teammate Fields";
      hasFieldHoverTextColumnTF = true;
      customFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(this.props.DatabaseMobx.o_tbl_a_teammates_extra_fields, "sort", true);
      alterDbTblColumnOnAddOrDeleteForTblName = "tbl_c_teammates";
      titleComponent = (
        <div className="tbMedPad" style={{borderBottom:"solid 1px #ddd"}}>
          <font className="font12">
            {"All fields (system + custom) for a Teammate"}
          </font>
        </div>
      );
      allFieldsArrayOfObjs = [
        {id:-1, display_name:"Teammate (Contact Company)"},
        {id:-2, display_name:"On Team Y/N"},
        {id:-3, display_name:c_teammatesAllocOfCovFieldDisplayName},
        {id:-4, display_name:c_teammatesWorkshareTypeFieldDisplayName},
        {id:-5, display_name:c_teammatesWorkshareNumFtesFieldDisplayName},
        {id:-6, display_name:c_teammatesWorkshareNotesFieldDisplayName},
        {id:-7, display_name:"Teammate Contracts Manager (Contact Person)"},
        {id:-8, display_name:"Teammate Contacts (Multiple Contact Persons)"}
      ];
      for(let teammateExtraFieldObj of customFieldsArrayOfObjs) {
        allFieldsArrayOfObjs.push({id:teammateExtraFieldObj.id, display_name:teammateExtraFieldObj.display_name});
      }
    }
    else if(p_tblName === "tbl_a_competitors_extra_fields") {
      itemName = "Competitor Custom Field";
      aedTableLabel = "Custom Competitor Fields";
      hasFieldHoverTextColumnTF = true;
      customFieldsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(this.props.DatabaseMobx.o_tbl_a_competitors_extra_fields, "sort", true);
      alterDbTblColumnOnAddOrDeleteForTblName = "tbl_c_competitors";
      titleComponent = (
        <div className="tbMedPad" style={{borderBottom:"solid 1px #ddd"}}>
          <font className="font12">
            {"All fields (system + custom) for a Competitor"}
          </font>
        </div>
      );
      allFieldsArrayOfObjs = [
        {id:-1, display_name:"Competitor (Contact Company)"},
        {id:-2, display_name:"Competitor's Teammates (Multiple Contact Companies)"},
        {id:-3, display_name:"Strengths"},
        {id:-4, display_name:"Weaknesses"},
        {id:-5, display_name:"Opportunities"},
        {id:-6, display_name:"Threats"}
      ];
      for(let competitorExtraFieldObj of customFieldsArrayOfObjs) {
        allFieldsArrayOfObjs.push({id:competitorExtraFieldObj.id, display_name:competitorExtraFieldObj.display_name});
      }
    }

    const insertItemFunction = (i_C_CallPhpTblUID, i_dataRowObj) => {
      const newColumnDbName = JSFUNC.db_name_from_display_name(i_dataRowObj.display_name);
      const newColumnFieldTypeObj = this.props.DatabaseMobx.c_genericTextFieldTypeObj;
      i_C_CallPhpTblUID.add_alter_db_tbl_insert_column(alterDbTblColumnOnAddOrDeleteForTblName, newColumnDbName, newColumnFieldTypeObj.mysqlColumnDataType, newColumnFieldTypeObj.blankValue);
    }

    const deleteItemFunction = (i_C_CallPhpTblUID, i_dataRowObj) => {
      i_C_CallPhpTblUID.add_alter_db_tbl_delete_column(alterDbTblColumnOnAddOrDeleteForTblName, i_dataRowObj.db_name);
    }

    const allExistingFieldDbNamesArray = JSFUNC.get_column_vector_from_arrayOfObjs(customFieldsArrayOfObjs, "db_name");

    const inputTypesValueArray = ["text", "textarea", "email", "phone", "website"];
    const inputTypesDisplayArray = ["Text", "Textarea", "Email", "Phone", "Website"];
    const inputTypesSwsOptionsObj = undefined;
    const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Input Type", inputTypesValueArray, true, inputTypesDisplayArray, inputTypesSwsOptionsObj);
    const selectInputTypeFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

    var aedTableFieldArrayOfObjs = [];
    aedTableFieldArrayOfObjs.push({width:"150em", dbName:"display_name", displayName:itemName + " Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true, requiredUniqueDbNameArray:allExistingFieldDbNamesArray, inputMaxChars:60});
    aedTableFieldArrayOfObjs.push({width:"100em", dbName:"input_type", displayName:"Input Type", fieldTypeObj:selectInputTypeFieldTypeObj, initialValue:"text", requiredNotEmptyTF:true});
    if(hasFieldHoverTextColumnTF) {
      aedTableFieldArrayOfObjs.push({width:"100em", dbName:"hover_text", displayName:"Hover Help Text", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true});
    }
    aedTableFieldArrayOfObjs.push({width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1});

    const aedTableObj = {
      tblName: p_tblName,
      dragToResortFieldDbName: "sort",
      initialSortedFieldDbName: "sort",
      tableWidth: "100%",
      itemName: itemName,
      deleteItemNameFieldDbName: "display_name",
      newItemAddDbNameColumnFromDisplayNameTF: true,
      itemInsertFunctionsArray: [insertItemFunction],
      itemDeleteFunctionsArray: [deleteItemFunction],
      fieldsArrayOfObjs: aedTableFieldArrayOfObjs
    };

    return(
      <div className="displayFlexRow">
        <div className="flex11a medFullMargin" style={{flexBasis:"100em"}}>
          {renameSystemFieldDisplayNamesComponent}
          <div className="microBottomMargin">
            <font className="font13 fontItalic">
              {aedTableLabel}
            </font>
          </div>
          <AddEditDeleteTable
            p_dataArrayOfObjs={customFieldsArrayOfObjs}
            p_aedTableObj={aedTableObj}
          />
        </div>
        <div className="flex11a medFullMargin" style={{flexBasis:"100em"}}>
          <div className={"medFullPad " + bgClass} style={{border:"solid 1px #ccc"}}>
            {titleComponent}
            <div className="smallTopMargin" />
            {allFieldsArrayOfObjs.map((m_fieldObj) =>
              <div className="microTopMargin">
                <font className={"font11 fontItalic " + ((m_fieldObj.id > 0) ? ("fontBold") : (""))}>
                  {m_fieldObj.display_name}
                </font>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}));


const TeammateRenameableSystemFieldRow = inject("AdminMobx", "DatabaseMobx")(observer(
class TeammateRenameableSystemFieldRow extends Component { //props: p_companyRenameableTeammateAltFieldDbName, p_companyTeammatesAltFieldDisplayName, p_teammateSystemFieldDisplayName
  onsavechanged_teammate_field_alt_display_name = (i_newValueString) => {
    const p_companyRenameableTeammateAltFieldDbName = this.props.p_companyRenameableTeammateAltFieldDbName;
    this.props.AdminMobx.a_update_tbl_a_company_fields(p_companyRenameableTeammateAltFieldDbName, i_newValueString, "s");
  }

  render() {
    const p_companyRenameableTeammateAltFieldDbName = this.props.p_companyRenameableTeammateAltFieldDbName;
    const p_companyTeammatesAltFieldDisplayName = this.props.p_companyTeammatesAltFieldDisplayName;
    const p_teammateSystemFieldDisplayName = this.props.p_teammateSystemFieldDisplayName;

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={"adminTeammateAlt" + p_companyRenameableTeammateAltFieldDbName}
        p_fieldDisplayName={p_teammateSystemFieldDisplayName}
        p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
        p_valueRaw={p_companyTeammatesAltFieldDisplayName}
        p_valueIsEditableTFU={true}
        p_fieldClass="fontBlue"
        p_fieldWidth="18em"
        f_onSaveChanged={this.onsavechanged_teammate_field_alt_display_name}
      />
    );
  }
}));








//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Admin Add/Edit/Delete Tables
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
class AddEditDeleteTable extends Component { //props: p_dataArrayOfObjs, p_aedTableObj, f_onClickAddItem
  //p_aedTableObj:
  //  - tblName
  //  - canAddNewItemsTF
  //  - canDeleteItemsTF
  //  - dragToResortFieldDbName
  //  - maskResortFieldDbName                     [optional: default undefined] will mask the dragToResortFieldDbName value from each data row with the value from the field maskResortFieldDbName instead of the dragToResortFieldDbName for each row in the AED table, this does not affect the resorting operation as the drag/drop works only on id numbers
  //  - canAlwaysDragResortTF                     [optional: default false] normal dragToResort behavior is to prevent drag when there is only 1 item, this will override that rule to allow dragging (to drop zones in different receiving zones) even when there is only 1 item
  //  - initialSortedFieldDbName
  //  - initialSortedFieldIsAscTF
  //  - tableWidth
  //  - itemName
  //  - deleteItemNameFieldDbName
  //  - searchFieldDbName
  //  - idsAlreadyAddedArray
  //  - newItemConstantFieldNamesArray
  //  - newItemConstantValuesArray
  //  - newItemConstantIdsbArray
  //  - newItemAddDbNameColumnFromDisplayNameTF     [optional: default false]
  //  - addNewItemInlineTextInputFieldDbName        [optional: default undefined] default opens a Create New Item extra floating box containing all field columns to input, specifying this fieldDbName will replace the Add Item button at the top of the table with a text input to type a new entry, saving this text creates a new entry filling the text into the specified field dbName
  //  - itemInsertFunctionsArray
  //  - itemInsertFunctionOnSuccess
  //  - insertDeleteResortFilterFieldNamesArray     
  //  - insertDeleteResortFilterValuesArray
  //  - itemDeleteFunctionsArray
  //  - unassignedMatchingCapturesObj               prints at the bottom of the table within the AED box if there are more than 0 unassigned captures specified in this obj, used to complement each fieldObj having matchingCapturesObjTF as a column
  //  - importFromCsvLabel                          [optional] prints a bold line of the provided text in the .csv import floating box below the floating box title to tell users where this import data is going
  //  - importFromCsvIntoFieldDbName                [optional: default undefined] if set, this puts a button at the bottom of the table offering an interface to import many rows for a single field from a .csv file
  //  - includeUniqueIDColumnTF                     [optional: default false] if set, this puts a column 6em wide as the last column in the tbl showing the database unique id number in a light gray italic font
  //  - fieldsArrayOfObjs
  //      - width
  //      - fixedWidthTF                              [optional: default false] if true, uses fixed flex00a for column widths instead of standard flexing flex11a
  //      - dbName
  //      - displayName
  //      - fieldTypeObj
  //      - valuesToNotIncludeArray
  //      - initialValue
  //      - cellHoverTF                               [optional: default false] shows hover text of contents of cell
  //      - cellCenterTF                              [optional: default false] centers cell text
  //      - cellDisplayBlankIfNotFilledOutTF          [optional: default false] if the field is not filled out, "" is displayed in the cell instead of the not filled out valueMask
  //      - rawValueFalseDisplaysNAAndNoEditTF        [optional: default false] used for field num_decimals where it is N/A depending on the input_type field, and can only be edited on the edit form if it's not N/A
  //      - rawValueDisplayedFieldNotOnFormTF         [optional: default false] used for calculated table column like # Answers, displays the value for each item in the table, but does not show up at all on the new/edit forms
  //      - rawValueDisplayedFieldIsReadOnlyOnFormTF  [optional: default false] used for values that cannot be edited (like Details Fields Input Type) where the raw value (actually the mask) is precalculated, and displayed, but can't be edited in the form
  //      - onlyEditableDuringCreateUneditableOnEditTF[optional: default false] used for values like setting a Capture Field, which has other field dependent on the choice made, can be edited during creation, but make it uneditable (but shown) on edit form after creating the row
  //      - onlyEditableInEditNotShownOnCreateNewTF   [optional: default false] field completely hidden on 'Create New' floating box, shown normally when editing a field already created (used in cases where e.g. a SumRow field is only applicable to certain choices from the select of an above field)
  //      - requiredNotEmptyTF                        creating a new row, this particular field cannot be left blank, displays a red error message and cannot save if it's blank
  //      - requiredUniqueDbNameArray                 creating a new row, this display_name field (when converted to a db_name string format) must not exist in this provided array, error message if it does
  //      - inputMaxChars                             if set with a number, any text input will limit max number of characters that can be typed
  //      - matchingCapturesObjTF                     when set to true, this will use the matchingCapturesObj attached to the dataRowObj and ignore dbName and fieldTypeObj, this count of captures will not show up on the edit form
  //      - onkeydownenterAddsNewItemTF               [optional: default false] when adding a new item, when this field is focused, pushing the enter key performs the same action as clicking the blue button at the bottom of the floating box to add this new item

  constructor(props) {
    super(props);
    this.state = {
      s_isAddingNewItemTF: false,
      s_searchText: ""
    };
  }

  onclick_open_add_new_item_floating_box = () => {
    this.setState({s_isAddingNewItemTF:true});
  }

  onclick_close_add_new_item_floating_box = () => {
    this.setState({s_isAddingNewItemTF:false});
  }

  onchange_search_text = (i_newValue) => {
    this.setState({s_searchText:i_newValue});
  }

  ondelete_all_table_items = () => {
    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;
    
    //get all or filtered dataArrayOfObjs to delete (filtering is computed using both insertDeleteResortFilterFieldNamesArray and insertDeleteResortFilterValuesArray arrays, when provided they are the filter on the tbl for this particular AEDTable)
    var dataToDeleteArrayOfObjs = p_dataArrayOfObjs;
    if(JSFUNC.is_array(p_aedTableObj.insertDeleteResortFilterFieldNamesArray) && JSFUNC.is_array(p_aedTableObj.insertDeleteResortFilterValuesArray) && (p_aedTableObj.insertDeleteResortFilterFieldNamesArray.length === p_aedTableObj.insertDeleteResortFilterValuesArray.length)) {
      dataToDeleteArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_arrayOfObjs_matching_multiple_fields_and_values(p_dataArrayOfObjs, p_aedTableObj.insertDeleteResortFilterFieldNamesArray, p_aedTableObj.insertDeleteResortFilterValuesArray);
    }
    
    if(dataToDeleteArrayOfObjs.length > 0) {
      const jsDescription = JSFUNC.js_description_from_action("AdminReact - AddEditDeleteTable", "ondelete_all_table_items", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      if(!JSFUNC.is_array(p_aedTableObj.itemDeleteFunctionsArray)) { //no item delete functions, do a single mass delete on all rowIDs
        const dataIDsToDeleteArray = JSFUNC.get_column_vector_from_arrayOfObjs(dataToDeleteArrayOfObjs, "id");
        C_CallPhpTblUID.add_delete(p_aedTableObj.tblName, dataIDsToDeleteArray);
      }
      else { //otherwise delete each row individually and apply each delete function
        for(let dataRowObj of dataToDeleteArrayOfObjs) {
          //delete the item from the table using the itemID, no resort as every item is being deleted
          C_CallPhpTblUID.add_delete(p_aedTableObj.tblName, dataRowObj.id);
  
          //add any custom delete operations that follow the main item being deleted if specified by the p_aedTableObj input "itemDeleteFunctionsArray"
          for(let itemDeleteFunction of p_aedTableObj.itemDeleteFunctionsArray) {
            if(JSFUNC.is_function(itemDeleteFunction)) {
              itemDeleteFunction(C_CallPhpTblUID, dataRowObj);
            }
          }
        }
      }

      C_CallPhpTblUID.execute();
    }
  }

  render() {
    const s_isAddingNewItemTF = this.state.s_isAddingNewItemTF;
    const s_searchText = this.state.s_searchText;

    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;

    //set default values for adding/deleting fields input flags
    const canAddNewItemsTF = JSFUNC.prop_value(p_aedTableObj.canAddNewItemsTF, true);
    const canDeleteItemsTF = JSFUNC.prop_value(p_aedTableObj.canDeleteItemsTF, true);
    const includeUniqueIDColumnTF = JSFUNC.prop_value(p_aedTableObj.includeUniqueIDColumnTF, false);

    const numTableRows = p_dataArrayOfObjs.length;

    //precompute more aedTable information based on the input p_aedTableObj used by both the header and the body, precompute which columns match the defined 'sort' column to avoid drawing it as the leftmost drag/drop column and as a body column (precompute to avoid string comparisons on every row/cell when rendering the table), also get the sort column width if provided
    var computedFieldsArrayOfObjs = [];
    var sortColumnWidth = "4em"; //default width of sort column is 4em until overridden by the sort column fieldObj.width
    for(let fieldObj of p_aedTableObj.fieldsArrayOfObjs) {
      var columnIsSortColumnTF = (fieldObj.dbName === p_aedTableObj.dragToResortFieldDbName);
      if(columnIsSortColumnTF) {
        sortColumnWidth = fieldObj.width;
      }

      computedFieldsArrayOfObjs.push({
        drawColumnTF: (!columnIsSortColumnTF && (fieldObj.width !== undefined)),
      });
    }

    const computedAedTableObj = {
      computedFieldsArrayOfObjs: computedFieldsArrayOfObjs,
      sortColumnWidth: sortColumnWidth,
      hasAddButtonColumnTF: (JSFUNC.is_array(p_aedTableObj.idsAlreadyAddedArray) && JSFUNC.is_function(this.props.f_onClickAddItem)),
      addButtonColumnWidth: "6em",
      canDeleteItemsTF: canDeleteItemsTF,
      includeUniqueIDColumnTF: includeUniqueIDColumnTF
    };

    //determine if the provided input searchFieldDbName matches one of the provided column fieldDbNames, then get that field's displayName
    var usingSearchTF = false;
    var searchFieldDisplayName = "";
    if(p_aedTableObj.searchFieldDbName !== undefined) {
      for(let fieldObj of p_aedTableObj.fieldsArrayOfObjs) {
        if(fieldObj.dbName === p_aedTableObj.searchFieldDbName) {
          usingSearchTF = true;
          searchFieldDisplayName = fieldObj.displayName;
        }
      }
    }
    const searchTextLower = s_searchText.toLowerCase();

    return(
      <div className="lrMedPad medTopPad smallBottomPad border bevelBorderColors borderRadius10 bgAddEditDeleteTable" style={{width:p_aedTableObj.tableWidth}}>
        <div className="displayFlexRow" style={{minHeight:((canAddNewItemsTF) ? ("3.3em") : (undefined))}}>
          {(canAddNewItemsTF) ? (
            (s_isAddingNewItemTF) ? (
              <div className="flex11a">
                <EditDeleteTableAddNewEntryButtonProcess
                  p_dataArrayOfObjs={p_dataArrayOfObjs}
                  p_aedTableObj={p_aedTableObj}
                  f_onClose={this.onclick_close_add_new_item_floating_box}
                />
              </div>
            ) : (
              <>
                {(usingSearchTF) &&
                  <div className="flex00a displayFlexColumnVc lrMargin" style={{flexBasis:"25em"}}>
                    <CEGeneralReact.SearchInputTextWithClearX
                      p_inputText={s_searchText}
                      p_placeholderText={"Filter rows matching '" + searchFieldDisplayName + "' column..."}
                      p_includeSearchIconTF={false}
                      p_tabIndex={1}
                      f_onChange={this.onchange_search_text}
                    />
                  </div>
                }
                <div className="flex11a displayFlexColumnHcVc">
                  <CEGeneralReact.CEButton
                    p_type="add"
                    p_text={"Add New " + p_aedTableObj.itemName}
                    f_onClick={this.onclick_open_add_new_item_floating_box}
                  />
                </div>
              </>
            )
          ) : (
            <div className="flex11a" />
          )}
          <div className="flex00a displayFlexColumnHcVc lrMedPad textCenter">
            <font className="fontItalic fontTextLighter">
              {(canAddNewItemsTF) ? (
                <>
                  <div><font className="font13">{numTableRows}</font></div>
                  <div>{JSFUNC.plural(numTableRows, "Item", "Items")}</div>
                </>
              ) : (
                <div className="smallBottomMargin">
                  {numTableRows + " " + JSFUNC.plural(numTableRows, "Item", "Items")}
                </div>
              )}
            </font>
          </div>
          {(canDeleteItemsTF) &&
            <div className="flex00a displayFlexColumnHcVc">
              <CEGeneralReact.DeleteMenu
                p_message={"Are you sure you want to delete all " + p_aedTableObj.itemName + " items (" + numTableRows + ") in this table?"}
                p_menuItemText="Delete All Table Items"
                f_onDelete={this.ondelete_all_table_items}
              />
            </div>
          }
        </div>
        <EditDeleteTable
          p_dataArrayOfObjs={p_dataArrayOfObjs}
          p_aedTableObj={p_aedTableObj}
          p_computedAedTableObj={computedAedTableObj}
          p_searchTextLower={searchTextLower}
          f_onClickAddItem={this.props.f_onClickAddItem}
        />
        {(p_aedTableObj.unassignedMatchingCapturesObj !== undefined) &&
          <div className="smallTopMargin">
            <UnassignedMatchingCapturesBoxAndCountButton
              p_unassignedMatchingCapturesObj={p_aedTableObj.unassignedMatchingCapturesObj}
              p_fieldDisplayName={p_aedTableObj.itemName}
            />
          </div>
        }
        {(p_aedTableObj.importFromCsvIntoFieldDbName !== undefined) &&
          <div className="smallTopMargin medBottomMargin">
            <AEDTableImportItemsFromCsvFile
              p_dataArrayOfObjs={p_dataArrayOfObjs}
              p_aedTableObj={p_aedTableObj}
            />
          </div>
        }
      </div>
    );
  }
}

function EditDeleteTableAddNewEntryButtonProcess(props) { //props: p_dataArrayOfObjs, p_aedTableObj, f_onClose
  const p_dataArrayOfObjs = props.p_dataArrayOfObjs;
  const p_aedTableObj = props.p_aedTableObj;

  if(p_aedTableObj.addNewItemInlineTextInputFieldDbName !== undefined) { //Add Item button turns into inline text edit to add single field instead of a floating box
    return(
      <AddEditDeleteTableAddNewItemInlineTextSingleField
        p_dataArrayOfObjs={p_dataArrayOfObjs}
        p_aedTableObj={p_aedTableObj}
        f_onClickCancel={props.f_onClose}
      />
    );
  }
  else if(p_aedTableObj.tblName === "tbl_captures_fields") {
    return(
      <AddEditDeleteTableAddNewCaptureFieldFloatingBox
        p_aedTableObj={p_aedTableObj}
        f_onClickClose={props.f_onClose}
      />
    );
  }
  else if(p_aedTableObj.tblName === "tbl_a_shaping_questions_pool") {
    return(
      <AddEditDeleteTableAddNewDealShapingQuestionFloatingBox
        p_dataArrayOfObjs={p_dataArrayOfObjs}
        p_aedTableObj={p_aedTableObj}
        f_onClickClose={props.f_onClose}
      />
    );
  }

  return(
    <AddEditDeleteTableAddNewItemFloatingBox
      p_dataArrayOfObjs={p_dataArrayOfObjs}
      p_aedTableObj={p_aedTableObj}
      f_onClickClose={props.f_onClose}
    />
  );
}


class AddEditDeleteTableAddNewItemInlineTextSingleField extends Component { //props: p_dataArrayOfObjs, p_aedTableObj, f_onClickCancel
  constructor(props) {
    super(props);
    this.state = {
      s_newSingleFieldValue: "",
      s_showErrorsTF: false
    }
  }

  onchange_new_single_field_value = (i_newValue) => {
    this.setState({s_newSingleFieldValue:i_newValue});
  }

  onclick_add_new_item_from_inline_text = () => {
    const s_newSingleFieldValue = this.state.s_newSingleFieldValue;
    if(s_newSingleFieldValue === "") { //new value to add cannot be blank
      this.setState({s_showErrorsTF:true});
    }
    else {
      const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
      const p_aedTableObj = this.props.p_aedTableObj;

      const tblName = p_aedTableObj.tblName;
      const dragToResortFieldDbName = p_aedTableObj.dragToResortFieldDbName;
      const addNewItemInlineTextInputFieldDbName = p_aedTableObj.addNewItemInlineTextInputFieldDbName;

      const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - AddEditDeleteTableAddNewItemInlineTextSingleField", "onclick_add_new_item_from_inline_text", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //insert the new row with the new field value (and sort at the bottom if there is a dragtoresort column)
      var fieldNamesArray = [addNewItemInlineTextInputFieldDbName];
      var valuesArray = [s_newSingleFieldValue];
      var idsbArray = ["s"];
      if(dragToResortFieldDbName !== undefined) {
        fieldNamesArray.push(dragToResortFieldDbName);
        valuesArray.push(p_dataArrayOfObjs.length + 1); //insert the new item at the bottom of the existing items
        idsbArray.push("i");
      }
      C_CallPhpTblUID.add_insert(tblName, fieldNamesArray, valuesArray, idsbArray);

      C_CallPhpTblUID.execute();

      if(this.props.f_onClickCancel) {
        this.props.f_onClickCancel();
      }
    }
  }

  render() {
    const s_newSingleFieldValue = this.state.s_newSingleFieldValue;
    const s_showErrorsTF = this.state.s_showErrorsTF;

    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;

    const itemName = p_aedTableObj.itemName;
    const newSingleFieldValueErrorTF = (s_showErrorsTF && (s_newSingleFieldValue === ""));

    return(
      <div className="tbPad lrMedPad smallBottomMargin bgCaptureItemEditing">
        <div className="microBottomMargin">
          <font className="fontItalic fontTextLight">
            {"Add New " + itemName}
          </font>
        </div>
        <LibraryReact.Text
          p_value={s_newSingleFieldValue}
          p_styleObj={{width:"100%"}}
          p_tabIndex={1}
          p_errorTF={newSingleFieldValueErrorTF}
          f_onChange={this.onchange_new_single_field_value}
          f_onKeyDownEnter={this.onclick_add_new_item_from_inline_text}
        />
        {(newSingleFieldValueErrorTF) &&
          <CEGeneralReact.ErrorText p_text={"New " + itemName + " cannot be blank"} />
        }
        <div className="displayFlexRowVc smallTopMargin">
          <div className="flex00a lrMedMargin">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text="Add"
              f_onClick={this.onclick_add_new_item_from_inline_text}
            />
          </div>
          <div className="flex00a lrMedMargin">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text="Cancel"
              f_onClick={this.props.f_onClickCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

const AddEditDeleteTableAddNewItemFloatingBox = inject("DatabaseMobx")(observer(
class AddEditDeleteTableAddNewItemFloatingBox extends Component { //props: p_dataArrayOfObjs, p_aedTableObj, f_onClickClose
  constructor(props) {
    super(props);
    
    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;

    var initialDataObj = {};
    for(let fieldObj of p_aedTableObj.fieldsArrayOfObjs) {
      var fieldInitializedValue = fieldObj.initialValue;

      //for the drag to resort sort column initialization, set the sort number to the number of items currently in the table +1 (N+1) to put the new item on the bottom by default
      if(fieldObj.dbName === p_aedTableObj.dragToResortFieldDbName) {
        fieldInitializedValue = p_dataArrayOfObjs.length + 1;
      }

      initialDataObj[fieldObj.dbName] = fieldInitializedValue;
    }

    this.state = {
      s_dataObj: initialDataObj,
      s_showErrorsTF: false //set to true the first time the create new button is pushed at the bottom and stays true, this enables any errors preventing creation to be displayed
    }
  }

  onchange_input_value_update_data = (i_fieldDbName, i_newValue) => {
    var s_dataObj = this.state.s_dataObj;

    var updatedDataObj = s_dataObj;
    updatedDataObj[i_fieldDbName] = i_newValue;
    this.setState({s_dataObj:updatedDataObj});
  }

  compute_error_message_obj() {
    var s_dataObj = this.state.s_dataObj;

    const p_aedTableObj = this.props.p_aedTableObj;

    var errorMessageObj = {numErrors:0}; //if a field is detected to have an error while trying to create the new row, fill this obj with "fieldDbName: errorMessage" for each field

    for(let fieldObj of p_aedTableObj.fieldsArrayOfObjs) {
      if(fieldObj.requiredNotEmptyTF) {
        //use the field input type and state stored new value to determine if the field is 'filled out'
        var valueRaw = s_dataObj[fieldObj.dbName];
        var valueIsFilledOutTF = this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(valueRaw, fieldObj.fieldTypeObj);
        if(!valueIsFilledOutTF) {
          errorMessageObj[fieldObj.dbName] = "'" + fieldObj.displayName + "' cannot be be blank.";
          errorMessageObj.numErrors++;
        }
      }

      if(JSFUNC.is_array(fieldObj.requiredUniqueDbNameArray)) {
        var s_dataObj = this.state.s_dataObj;

        //determine if the new value (when converted from "New Name" to "new_name") already exists in the provided array
        var fieldValue = s_dataObj[fieldObj.dbName];
        var fieldValueConvertedToDbName = JSFUNC.db_name_from_display_name(fieldValue);
        if(JSFUNC.in_array(fieldValueConvertedToDbName, fieldObj.requiredUniqueDbNameArray)) {
          errorMessageObj[fieldObj.dbName] = "'" + fieldValue + "' already exists. Please enter a unique name for this new item.";
          errorMessageObj.numErrors++;
        }
      }

      if(JSFUNC.is_number_not_nan_gte_0(fieldObj.inputMaxChars)) {
        var s_dataObj = this.state.s_dataObj;
        var fieldValue = s_dataObj[fieldObj.dbName];
        if(JSFUNC.is_string(fieldValue)) {
          var fieldValueCurrentNumChars = fieldValue.length;
          if(fieldValueCurrentNumChars > fieldObj.inputMaxChars) {
            errorMessageObj[fieldObj.dbName] = "Limit of " + fieldObj.inputMaxChars + " characters exceeded (" + fieldValueCurrentNumChars + " characters entered).";
            errorMessageObj.numErrors++;
          }
        }
      }
    }

    return(errorMessageObj);
  }

  onclick_add_new_item = () => {
    const p_aedTableObj = this.props.p_aedTableObj;

    //check if any of the fields are required to not be blank before creating the new row
    const errorMessageObj = this.compute_error_message_obj();

    //if all fields have no errors, proceed to insert the new row into the database and local memory (input fields and "constant value" fields combined)
    if(errorMessageObj.numErrors > 0) {
      this.setState({s_showErrorsTF:true});
    }
    else {
      const s_dataObj = this.state.s_dataObj;

      //inject the constant values if any were provided as input
      var fieldNamesArray = [];
      var valuesArray = [];
      var idsbArray = [];
      if(JSFUNC.is_array(p_aedTableObj.newItemConstantFieldNamesArray) && JSFUNC.is_array(p_aedTableObj.newItemConstantValuesArray) && JSFUNC.is_array(p_aedTableObj.newItemConstantIdsbArray)) {
        fieldNamesArray = p_aedTableObj.newItemConstantFieldNamesArray;
        valuesArray = p_aedTableObj.newItemConstantValuesArray;
        idsbArray = p_aedTableObj.newItemConstantIdsbArray;
      }

      //add the user edited field values from the form
      for(let fieldObj of p_aedTableObj.fieldsArrayOfObjs) {
        if(fieldObj.fieldTypeObj !== undefined) {
          var newValue = s_dataObj[fieldObj.dbName];

          //when specifying the sort for the new entry, if it was changed from the default last entry N+1 sort number, use the value-1 so that it sorts above the existing row that already has the sort value in the table
          if(fieldObj.dbName === p_aedTableObj.dragToResortFieldDbName) {
            newValue = newValue - 1;
          }

          fieldNamesArray.push(fieldObj.dbName);
          valuesArray.push(newValue);
          idsbArray.push(fieldObj.fieldTypeObj.idsb);
        }
      }

      //add a column "db_name" converted from the display_name column if specified by the flag newItemAddDbNameColumnFromDisplayNameTF (used for contacts/teammates/competitors extra field tables)
      if(p_aedTableObj.newItemAddDbNameColumnFromDisplayNameTF) {
        fieldNamesArray.push("db_name");
        valuesArray.push(JSFUNC.db_name_from_display_name(s_dataObj.display_name));
        idsbArray.push("s");
      }

      //handle resorting the list of items after the new insert if a sort column was provided
      var resortSortColumnName = undefined;
      var resortFilterFieldNamesArray = undefined;
      var resortFilterValuesArray = undefined;
      if(p_aedTableObj.dragToResortFieldDbName !== undefined) { //if a sort column was provided, resort the tbl by that column after insert
        resortSortColumnName = p_aedTableObj.dragToResortFieldDbName;
        if(JSFUNC.is_array(p_aedTableObj.insertDeleteResortFilterFieldNamesArray) && JSFUNC.is_array(p_aedTableObj.insertDeleteResortFilterValuesArray) && (p_aedTableObj.insertDeleteResortFilterFieldNamesArray.length === p_aedTableObj.insertDeleteResortFilterValuesArray.length)) {
          resortFilterFieldNamesArray = p_aedTableObj.insertDeleteResortFilterFieldNamesArray; //if resort filter fields/valuse were provided, use those as well when inserting/resorting
          resortFilterValuesArray = p_aedTableObj.insertDeleteResortFilterValuesArray;
        }
        else { //if not, simply resort entire table on sort column without filtering
          resortFilterFieldNamesArray = [];
          resortFilterValuesArray = [];
        }
      }

      const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - AddEditDeleteTableAddNewItemFloatingBox", "onclick_add_new_item", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //insert the new row with all entered field values
      C_CallPhpTblUID.add_insert(p_aedTableObj.tblName, fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNamesArray, resortFilterValuesArray);

      //add any custom insert operations that follow the main item being inserted if specified by the p_aedTableObj input "itemInsertFunctionsArray"
      if(JSFUNC.is_array(p_aedTableObj.itemInsertFunctionsArray)) {
        for(let itemInsertFunction of p_aedTableObj.itemInsertFunctionsArray) {
          if(JSFUNC.is_function(itemInsertFunction)) {
            itemInsertFunction(C_CallPhpTblUID, s_dataObj);
          }
        }
      }

      //add any custom insert operations that happen after the successful insert of this new item row (the passed input data object contains the unique database id number of the newly inserted row)
      if(JSFUNC.is_function(p_aedTableObj.itemInsertFunctionOnSuccess)) {
        C_CallPhpTblUID.add_function("onSuccess", p_aedTableObj.itemInsertFunctionOnSuccess);
      }

      C_CallPhpTblUID.execute();

      if(this.props.f_onClickClose) {
        this.props.f_onClickClose();
      }
    }
  }

  render() {
    const s_dataObj = this.state.s_dataObj;
    const s_showErrorsTF = this.state.s_showErrorsTF;

    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;

    //set p_tabIndex for each open item in this create form to count [1, 11, 21, 31, ...] so that inputs in edit mode that have multiple sub pieces to tab to (like Styling) have 10 incremental tab slots to themselves [21,22,23,24,...]
    const tabSkipInterval = 10;

    //only need to compute the errors if the create new button has been pushed at least once where it's possible for the errors to be shown
    var errorMessageObj = {};
    if(s_showErrorsTF) {
      errorMessageObj = this.compute_error_message_obj();
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditItem"
        p_title={"Adding a New " + p_aedTableObj.itemName}
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex11a yScroll medFullPad">
          {p_aedTableObj.fieldsArrayOfObjs.map((m_fieldObj, m_index) =>
            <AddEditDeleteTableAddNewItemFieldInput
              key={m_fieldObj.dbName}
              p_fieldObj={m_fieldObj}
              p_valueRaw={s_dataObj[m_fieldObj.dbName]}
              p_tabIndex={((m_index * tabSkipInterval) + 1)}
              p_errorMessage={errorMessageObj[m_fieldObj.dbName]}
              f_onChange={this.onchange_input_value_update_data}
              f_onKeyDownEnter={this.onclick_add_new_item}
            />
          )}
        </div>
        <div className="flex00a displayFlexColumnHcVc tbPad borderT1ddd">
          <CEGeneralReact.CEButton
            p_type="blue"
            p_text={"Add New " + p_aedTableObj.itemName}
            p_tabIndex={((p_aedTableObj.fieldsArrayOfObjs.length + 1) * tabSkipInterval)}
            f_onClick={this.onclick_add_new_item}
          />
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

class AddEditDeleteTableAddNewItemFieldInput extends Component { //props: p_fieldObj, p_valueRaw, p_tabIndex, p_errorMessage, f_onChange, f_onKeyDownEnter
  onchange_input_value = (i_newValue) => {
    const fieldObj = this.props.p_fieldObj;
    const valueRaw = this.props.p_valueRaw;

    if(JSFUNC.is_function(this.props.f_onChange) && (i_newValue !== valueRaw)) {
      this.props.f_onChange(fieldObj.dbName, i_newValue);
    }
  }

  onkeydownenter_add_new_item = () => {
    if(JSFUNC.is_function(this.props.f_onKeyDownEnter)) {
      this.props.f_onKeyDownEnter();
    }
  }

  render() {
    const fieldObj = this.props.p_fieldObj;
    const valueRaw = this.props.p_valueRaw;
    const tabIndex = this.props.p_tabIndex;
    const errorMessage = this.props.p_errorMessage;

    if(fieldObj.fieldTypeObj === undefined) {
      return(null);
    }

    //if the property for this field onlyEditableInEditNotShownOnCreateNewTF is set to true, do not show this field on the create new form
    if(fieldObj.onlyEditableInEditNotShownOnCreateNewTF) {
      return(null);
    }

    return(
      <div className="displayFlexRowVc">
        <div className="flex00a displayFlexRowVc" style={{flexBasis:"17em"}}>
          <div className="flex11a">
            <font className="fontBlue">
              {fieldObj.displayName}
            </font>
          </div>
        </div>
        <div className="flex11a tbMargin" style={{height:((fieldObj.fieldTypeObj.textareaTF) ? ("10em") : (undefined))}}>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={fieldObj.fieldTypeObj}
            p_valueRaw={valueRaw}
            p_valuesToNotIncludeArray={fieldObj.valuesToNotIncludeArray}
            p_selectInitOptionsBoxOpenTF={false}
            p_tabIndex={tabIndex}
            p_title={undefined}
            p_errorTF={(errorMessage !== undefined)}
            f_onChangeOrOnSelect={this.onchange_input_value}
            f_onKeyDownEnter={((fieldObj.onkeydownenterAddsNewItemTF) ? (this.onkeydownenter_add_new_item) : (undefined))}
          />
          {(errorMessage !== undefined) &&
            <CEGeneralReact.ErrorText p_class="microTopMargin microBottomMargin" p_text={errorMessage} />
          }
        </div>
      </div>
    );
  }
}

class EditDeleteTable extends Component { //props: p_dataArrayOfObjs, p_aedTableObj, p_computedAedTableObj, p_searchTextLower, f_onClickAddItem
  constructor(props) {
    super(props);

    const p_aedTableObj = this.props.p_aedTableObj;

    var initialSortIsAscTF = JSFUNC.prop_value(p_aedTableObj.initialSortedFieldIsAscTF, true); //default column to sort by is the leftmost "sort" column ASC (if it exists)

    var initialHeaderSortObj = undefined;
    if(p_aedTableObj.initialSortedFieldDbName !== undefined) { //if this input is not defined, no sorting is applied (whole headerSortObj is undefined as a flag)
      if(JSFUNC.is_array(p_aedTableObj.initialSortedFieldDbName) && !JSFUNC.is_array(initialSortIsAscTF)) {
        initialSortIsAscTF = JSFUNC.array_fill(p_aedTableObj.initialSortedFieldDbName.length, true); //if an array of sort fieldDbNames was provided, but only a single sort value (or no sort value, which defaults to true), make an array the same length with the same value replicated
      }

      initialHeaderSortObj = {
        fieldDbNamesArray: JSFUNC.convert_single_or_array_to_array(p_aedTableObj.initialSortedFieldDbName),
        isAscTFArray: JSFUNC.convert_single_or_array_to_array(initialSortIsAscTF)
      }
    }

    this.state = {
      s_headerSortObj: initialHeaderSortObj
    };
  }

  onclick_header = (i_fieldDbName) => {
    const s_headerSortObj = this.state.s_headerSortObj;

    var newHeaderSortFieldDbNamesArray = [i_fieldDbName];
    var newHeaderSortIsAscTFArray = [true];
    if(s_headerSortObj !== undefined) {
      const oldHeaderSortFieldDbNamesArray = s_headerSortObj.fieldDbNamesArray;
      const oldHeaderSortIsAscTFArray = s_headerSortObj.isAscTFArray;
      if(JSFUNC.is_array(oldHeaderSortFieldDbNamesArray) && JSFUNC.is_array(oldHeaderSortIsAscTFArray)) {
        if((oldHeaderSortFieldDbNamesArray.length === 1) && (oldHeaderSortIsAscTFArray.length === 1)) { //only 1 column is currently sorted, can do the comparison to see if the same column was just clicked to flip the sort direction
          const oldHeaderSortFieldDbName = oldHeaderSortFieldDbNamesArray[0];
          const oldHeaderSortIsAscTF = oldHeaderSortIsAscTFArray[0];
          const newHeaderSortFieldDbName = i_fieldDbName;
          const newHeaderSortIsAscTF = ((i_fieldDbName === oldHeaderSortFieldDbName) ? (!oldHeaderSortIsAscTF) : (true)); //flip the sort when clicking on the same column, otherwise the sort on the new column is ASC
          newHeaderSortFieldDbNamesArray = [newHeaderSortFieldDbName];
          newHeaderSortIsAscTFArray = [newHeaderSortIsAscTF];
        }
      }
    }

    const updatedHeaderSortObj = {fieldDbNamesArray:newHeaderSortFieldDbNamesArray, isAscTFArray:newHeaderSortIsAscTFArray};
    this.setState({s_headerSortObj:updatedHeaderSortObj});
  }

  render() {
    const s_headerSortObj = this.state.s_headerSortObj;

    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;
    const p_computedAedTableObj = this.props.p_computedAedTableObj;
    const p_searchTextLower = this.props.p_searchTextLower;

    //sort the items based on the currently sorted column and sort direction (both kept in the local state s_headerSortObj)
    if(s_headerSortObj !== undefined) {
      JSFUNC.sort_arrayOfObjs(p_dataArrayOfObjs, s_headerSortObj.fieldDbNamesArray, s_headerSortObj.isAscTFArray, true);
    }

    return(
      <>
        <AddEditDeleteTableHeaderRow
          p_aedTableObj={p_aedTableObj}
          p_computedAedTableObj={p_computedAedTableObj}
          p_headerSortObj={s_headerSortObj}
          f_onClickHeader={this.onclick_header}
        />
        <AddEditDeleteTableBody
          p_sortedDataArrayOfObjs={p_dataArrayOfObjs}
          p_aedTableObj={p_aedTableObj}
          p_computedAedTableObj={p_computedAedTableObj}
          p_searchTextLower={p_searchTextLower}
          f_onClickAddItem={this.props.f_onClickAddItem}
        />
      </>
    );
  }
}

function AddEditDeleteTableHeaderRow(props) { //props: p_aedTableObj, p_computedAedTableObj, p_headerSortObj, f_onClickHeader
  const p_aedTableObj = props.p_aedTableObj;
  const p_computedAedTableObj = props.p_computedAedTableObj;
  const p_headerSortObj = props.p_headerSortObj;

  return(
    <div className="displayFlexRow bgBlueGradient borderB1ddd" style={{height:"3em"}}>
      {(p_aedTableObj.dragToResortFieldDbName !== undefined) &&
        <div className="flex00a displayFlexRow" style={{flexBasis:p_computedAedTableObj.sortColumnWidth}}>
          <AddEditDeleteTableHeaderCell
            p_fieldObj={{width:p_computedAedTableObj.sortColumnWidth, dbName:p_aedTableObj.dragToResortFieldDbName, displayName:""}}
            p_headerSortObj={p_headerSortObj}
            f_onClickHeader={props.f_onClickHeader}
          />
        </div>
      }
      {p_aedTableObj.fieldsArrayOfObjs.map((m_fieldObj, m_index) =>
        (p_computedAedTableObj.computedFieldsArrayOfObjs[m_index].drawColumnTF) &&
        <AddEditDeleteTableHeaderCell
          key={m_fieldObj.dbName}
          p_fieldObj={m_fieldObj}
          p_headerSortObj={p_headerSortObj}
          f_onClickHeader={props.f_onClickHeader}
        />
      )}
      {(p_computedAedTableObj.includeUniqueIDColumnTF) &&
        <div className="flex00a displayFlexRow" style={{flexBasis:"6em"}}>
          <AddEditDeleteTableHeaderCell
            p_fieldObj={{width:"1em", dbName:"id", displayName:"Unique ID"}}
            p_headerSortObj={p_headerSortObj}
            f_onClickHeader={props.f_onClickHeader}
          />
        </div>
      }
      {(p_computedAedTableObj.hasAddButtonColumnTF) &&
        <div className="flex00a displayFlexRow" style={{flexBasis:p_computedAedTableObj.addButtonColumnWidth}}>
          <AddEditDeleteTableHeaderCell
            p_fieldObj={{width:"1em", dbName:"add->", displayName:"Add Item"}}
            p_headerSortObj={p_headerSortObj}
          />
        </div>
      }
      {(p_computedAedTableObj.canDeleteItemsTF) &&
        <div className="flex00a displayFlexRow" style={{flexBasis:"2em"}}>
          <AddEditDeleteTableHeaderCell />
        </div>
      }
    </div>
  );
}

class AddEditDeleteTableHeaderCell extends Component { //props: p_fieldObj, p_headerSortObj, f_onClickHeader
  onclick_header_cell = () => {
    const p_fieldObj = this.props.p_fieldObj;

    if(JSFUNC.is_function(this.props.f_onClickHeader)) {
      this.props.f_onClickHeader(p_fieldObj.dbName);
    }
  }

  render() {
    const p_fieldObj = this.props.p_fieldObj;
    const p_headerSortObj = this.props.p_headerSortObj;

    const fieldObjExistsTF = (p_fieldObj !== undefined);
    const headerSortableTF = JSFUNC.is_function(this.props.f_onClickHeader);

    var headerFlexClass = "flex11a";
    var headerFlexBasis = "1em";
    if(fieldObjExistsTF) {
      if(p_fieldObj.fixedWidthTF) {
        headerFlexClass = "flex00a";
      }
      headerFlexBasis = p_fieldObj.width;
    }

    var isSortedTF = false;
    var sortedAscTF = true;
    if(p_headerSortObj !== undefined) {
      const matchIndex = p_headerSortObj.fieldDbNamesArray.indexOf(p_fieldObj.dbName);
      if(matchIndex >= 0) {
        isSortedTF = true;
        sortedAscTF = p_headerSortObj.isAscTFArray[matchIndex];
      }
    }

    return(
      <div
        className={headerFlexClass + " displayFlexRowVc lrPad borderR1ddd " + ((headerSortableTF) ? ("hoverLightBlueGradient cursorPointer") : (""))}
        style={{flexBasis:headerFlexBasis}}
        onClick={this.onclick_header_cell}>
        {(fieldObjExistsTF) &&
          <>
            {(headerSortableTF) &&
              <div className="flex00a textCenter" style={{flexBasis:"1em"}}>
                <CEGeneralReact.HeaderSortArrow p_isSortedTF={isSortedTF} p_sortedAscTF={sortedAscTF} />
              </div>
            }
            <div className="flex11a lrPad textCenter" title={p_fieldObj.displayName + " [Click to sort by this column]"}>
              <LibraryReact.MaxHeightWrap p_maxHeight="2.4em" p_fontClass="fontWhite">
                {p_fieldObj.displayName}
              </LibraryReact.MaxHeightWrap>
            </div>
          </>
        }
      </div>
    );
  }
}

function AddEditDeleteTableBody(props) { //props: p_sortedDataArrayOfObjs, p_aedTableObj, p_computedAedTableObj, p_searchTextLower, f_onClickAddItem
  const p_sortedDataArrayOfObjs = props.p_sortedDataArrayOfObjs;
  const p_aedTableObj = props.p_aedTableObj;
  const p_computedAedTableObj = props.p_computedAedTableObj;
  const p_searchTextLower = props.p_searchTextLower;

  const numItems = p_sortedDataArrayOfObjs.length;
  const lastItemIndex = (numItems - 1);
  const isOnlyItemTF = (numItems === 1);

  if((p_aedTableObj.searchFieldDbName === undefined) || (p_searchTextLower === "")) {
    return(
      p_sortedDataArrayOfObjs.map((m_dataRowObj, m_index) =>
        <AddEditDeleteTableBodyRow
          key={m_dataRowObj.id}
          p_dataRowObj={m_dataRowObj}
          p_aedTableObj={p_aedTableObj}
          p_computedAedTableObj={p_computedAedTableObj}
          p_isOnlyItemTF={isOnlyItemTF}
          p_isLastItemTF={(m_index === lastItemIndex)}
          f_onClickAddItem={props.f_onClickAddItem}
        />
      )
    );
  }

  //filter the rows matching the search text to the specified field column
  return(
    p_sortedDataArrayOfObjs.map((m_dataRowObj, m_index) =>
      (m_dataRowObj[p_aedTableObj.searchFieldDbName].toLowerCase().indexOf(p_searchTextLower) >= 0) &&
      <AddEditDeleteTableBodyRow
        key={m_dataRowObj.id}
        p_dataRowObj={m_dataRowObj}
        p_aedTableObj={p_aedTableObj}
        p_computedAedTableObj={p_computedAedTableObj}
        p_isOnlyItemTF={isOnlyItemTF}
        p_isLastItemTF={(m_index === lastItemIndex)}
        f_onClickAddItem={props.f_onClickAddItem}
      />
    )
  );
}

class AddEditDeleteTableBodyRow extends Component { //props: p_dataRowObj, p_aedTableObj, p_computedAedTableObj, p_isOnlyItemTF, p_isLastItemTF, f_onClickAddItem
  constructor(props) {
    super(props);
    this.state = {
      s_aedTableEditItemFloatingBoxIsOpenTF: false
    };
  }

  onclick_open_edit_item_floating_box = () => {
    this.setState({s_aedTableEditItemFloatingBoxIsOpenTF:true});
  }

  onclick_close_edit_item_floating_box = () => {
    this.setState({s_aedTableEditItemFloatingBoxIsOpenTF:false});
  }

  render() {
    const s_aedTableEditItemFloatingBoxIsOpenTF = this.state.s_aedTableEditItemFloatingBoxIsOpenTF;

    const p_dataRowObj = this.props.p_dataRowObj;
    const p_aedTableObj = this.props.p_aedTableObj;
    const p_computedAedTableObj = this.props.p_computedAedTableObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    var itemSort = undefined;
    if(p_aedTableObj.maskResortFieldDbName !== undefined) {
      itemSort = p_dataRowObj[p_aedTableObj.maskResortFieldDbName];
    }
    else if(p_aedTableObj.dragToResortFieldDbName !== undefined) {
      itemSort = p_dataRowObj[p_aedTableObj.dragToResortFieldDbName];
    }

    return(
      <>
        <CEGeneralReact.CEDragToResortItemWithinDbTbl
          p_canResortTF={(p_aedTableObj.dragToResortFieldDbName !== undefined)}
          p_uniqueString={"AddEditDeleteTableRow_" + p_aedTableObj.tblName}
          p_itemID={p_dataRowObj.id}
          p_itemSort={itemSort}
          p_itemSortColumnWidth={p_computedAedTableObj.sortColumnWidth}
          p_itemSortNumberFontClass={undefined}
          p_isOnlyItemTF={(p_isOnlyItemTF && !p_aedTableObj.canAlwaysDragResortTF)}
          p_isLastItemTF={p_isLastItemTF}
          p_outerPadClass={undefined}
          p_itemClass="borderB1ddd bgWhite hoverLighterBlueGradient"
          p_itemStyleObj={{height:"2em"}}
          p_lastItemExtraDropZoneHeight="2em"
          p_tblName={p_aedTableObj.tblName}
          p_tblSortFieldDbName={p_aedTableObj.dragToResortFieldDbName}
          p_filterFieldNamesArray={p_aedTableObj.insertDeleteResortFilterFieldNamesArray}
          p_filterValuesArray={p_aedTableObj.insertDeleteResortFilterValuesArray}>
          <div className="flex11a displayFlexRow">
            {p_aedTableObj.fieldsArrayOfObjs.map((m_fieldObj, m_index) =>
              (p_computedAedTableObj.computedFieldsArrayOfObjs[m_index].drawColumnTF) &&
              <AddEditDeleteTableBodyCell
                key={m_fieldObj.dbName}
                p_aedTableObj={p_aedTableObj}
                p_fieldObj={m_fieldObj}
                p_dataRowObj={p_dataRowObj}
                f_onClick={this.onclick_open_edit_item_floating_box}
              />
            )}
            {(p_computedAedTableObj.includeUniqueIDColumnTF) &&
              <div
                className="flex00a displayFlexRowVc textCenter cursorPointer"
                style={{flexBasis:"6em"}}
                onClick={this.onclick_open_edit_item_floating_box}>
                <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLightester">
                  {p_dataRowObj.id}
                </LibraryReact.Nowrap>
              </div>
            }
            {(p_computedAedTableObj.hasAddButtonColumnTF) &&
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:p_computedAedTableObj.addButtonColumnWidth}}>
                {(!JSFUNC.in_array(p_dataRowObj.id, p_aedTableObj.idsAlreadyAddedArray)) &&
                  <AddEditDeleteTableBodyRowAddItemButton
                    p_dataRowObj={p_dataRowObj}
                    f_onClickAddItem={this.props.f_onClickAddItem}
                  />
                }
              </div>
            }
            {(p_computedAedTableObj.canDeleteItemsTF) &&
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
                <AddEditDeleteTableBodyRowDeleteMenu
                  p_dataRowObj={p_dataRowObj}
                  p_aedTableObj={p_aedTableObj}
                />
              </div>
            }
          </div>
        </CEGeneralReact.CEDragToResortItemWithinDbTbl>
        {(s_aedTableEditItemFloatingBoxIsOpenTF) &&
          <AddEditDeleteTableEditItemFloatingBox
            p_dataRowObj={p_dataRowObj}
            p_aedTableObj={p_aedTableObj}
            f_onClickClose={this.onclick_close_edit_item_floating_box}
          />
        }
      </>
    );
  }
}

const AddEditDeleteTableBodyRowAddItemButton = inject("AdminMobx")(observer(
class AddEditDeleteTableBodyRowAddItemButton extends Component { //props: p_dataRowObj, f_onClickAddItem
  onclick_add_item_button = () => {
    if(this.props.f_onClickAddItem) {
      this.props.f_onClickAddItem(this.props.p_dataRowObj.id)
    }
  }

  render() {
    const c_selectedCaptureTypeExistsTF = this.props.AdminMobx.c_selectedCaptureTypeExistsTF;

    if(!c_selectedCaptureTypeExistsTF) {
      return(null); //cannot add items if there is not a valid capture type selected
    }

    return(
      <CEGeneralReact.CEButton
        p_type="add"
        p_text="Add ->"
        f_onClick={this.onclick_add_item_button}
      />
    );
  }
}));

class AddEditDeleteTableBodyRowDeleteMenu extends Component { //props: p_dataRowObj, p_aedTableObj
  ondelete_item = () => {
    const p_dataRowObj = this.props.p_dataRowObj;
    const p_aedTableObj = this.props.p_aedTableObj;

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AddEditDeleteTableBodyRowDeleteMenu", "ondelete_item", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    const itemID = p_dataRowObj.id;

    //handle resorting the list of items after the new insert if a sort column was provided
    var resortSortColumnName = undefined;
    var resortFilterFieldNamesArray = undefined;
    var resortFilterValuesArray = undefined;
    if(p_aedTableObj.dragToResortFieldDbName !== undefined) { //if a sort column was provided, resort the tbl by that column after delete
      resortSortColumnName = p_aedTableObj.dragToResortFieldDbName;
      if(JSFUNC.is_array(p_aedTableObj.insertDeleteResortFilterFieldNamesArray) && JSFUNC.is_array(p_aedTableObj.insertDeleteResortFilterValuesArray) && (p_aedTableObj.insertDeleteResortFilterFieldNamesArray.length === p_aedTableObj.insertDeleteResortFilterValuesArray.length)) {
        resortFilterFieldNamesArray = p_aedTableObj.insertDeleteResortFilterFieldNamesArray; //if resort filter fields/valuse were provided, use those as well when deleting/resorting
        resortFilterValuesArray = p_aedTableObj.insertDeleteResortFilterValuesArray;
      }
      else { //if not, simply resort entire table on sort column without filtering
        resortFilterFieldNamesArray = [];
        resortFilterValuesArray = [];
      }
    }

    //delete the item from the table using the itemID
    C_CallPhpTblUID.add_delete(p_aedTableObj.tblName, itemID, resortSortColumnName, resortFilterFieldNamesArray, resortFilterValuesArray);

    //add any custom delete operations that follow the main item being deleted if specified by the p_aedTableObj input "itemDeleteFunctionsArray"
    if(JSFUNC.is_array(p_aedTableObj.itemDeleteFunctionsArray)) {
      for(let itemDeleteFunction of p_aedTableObj.itemDeleteFunctionsArray) {
        if(JSFUNC.is_function(itemDeleteFunction)) {
          itemDeleteFunction(C_CallPhpTblUID, p_dataRowObj);
        }
      }
    }

    C_CallPhpTblUID.execute();
  }

  render() {
    const p_dataRowObj = this.props.p_dataRowObj;
    const p_aedTableObj = this.props.p_aedTableObj;

    var deleteMessage = "";
    if(p_aedTableObj.deleteItemNameFieldDbName !== undefined) {
      deleteMessage = "Delete " + p_aedTableObj.itemName + " '" + p_dataRowObj[p_aedTableObj.deleteItemNameFieldDbName] + "'" + "?"
    }
    else {
      deleteMessage = "Delete this " + p_aedTableObj.itemName + "?";
    }

    return(
      <CEGeneralReact.DeleteMenu
        p_menuItemText="Delete"
        p_message={deleteMessage}
        f_onDelete={this.ondelete_item}
      />
    );
  }
}

const AddEditDeleteTableBodyCell = inject("DatabaseMobx")(observer(
class AddEditDeleteTableBodyCell extends Component { //props: p_aedTableObj, p_fieldObj, p_dataRowObj, f_onClick
  render() {
    const p_aedTableObj = this.props.p_aedTableObj;
    const p_fieldObj = this.props.p_fieldObj;
    const p_dataRowObj = this.props.p_dataRowObj;

    const fieldObjExistsTF = (p_fieldObj !== undefined);

    var cellFlexClass = "flex11a";
    var cellFlexBasis = "1em";
    if(fieldObjExistsTF) {
      if(p_fieldObj.fixedWidthTF) {
        cellFlexClass = "flex00a";
      }
      cellFlexBasis = p_fieldObj.width;
    }

    //column for # matching Captures blue button
    if(p_fieldObj.matchingCapturesObjTF) {
      return(
        <div
          className={cellFlexClass + " displayFlexColumnHcVc cursorPointer"}
          style={{flexBasis:cellFlexBasis}}
          onClick={this.props.f_onClick}>
          {(p_dataRowObj.matchingCapturesObj !== undefined) &&
            <CapturesReact.NumberOfAssignedCapturesCountButton
              p_partialNumMatchingCaptures={p_dataRowObj.matchingCapturesObj.partialNumMatchingCaptures}
              p_matchingCaptureIDsArray={p_dataRowObj.matchingCapturesObj.matchingCaptureIDsArray}
              p_floatingBoxTitle={"All Captures with " + p_aedTableObj.itemName + " assigned as '" + p_dataRowObj[p_aedTableObj.deleteItemNameFieldDbName] + "'"}
            />
          }
        </div>
      );
    }

    const valueRaw = p_dataRowObj[p_fieldObj.dbName];

    var valueMask = undefined;
    var cellHoverText = undefined;
    if(p_fieldObj.rawValueDisplayedFieldNotOnFormTF || p_fieldObj.rawValueDisplayedFieldIsReadOnlyOnFormTF) {
      valueMask = valueRaw;
      cellHoverText = ((p_fieldObj.cellHoverTF) ? (valueRaw) : (undefined));
    }
    else {
      if(p_fieldObj.rawValueFalseDisplaysNAAndNoEditTF && (valueRaw === false)) {
        valueMask = this.props.DatabaseMobx.not_filled_out_font_html("N/A");
        cellHoverText = ((p_fieldObj.cellHoverTF) ? ("N/A") : (undefined));
      }
      else {
        const valueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(valueRaw, p_fieldObj.fieldTypeObj);
        if(p_fieldObj.cellDisplayBlankIfNotFilledOutTF && !valueMaskSortIfoObj.isFilledOutTF) {
          valueMask = "";
        }
        else {
          valueMask = valueMaskSortIfoObj.valueMask;
        }
        cellHoverText = ((p_fieldObj.cellHoverTF) ? (valueMaskSortIfoObj.valueMaskPlainText) : (undefined));
      }
    }

    return(
      <div
        className={cellFlexClass + " displayFlexRowVc lrPad cursorPointer " + ((p_fieldObj.cellCenterTF) ? ("textCenter") : (""))}
        style={{flexBasis:cellFlexBasis}}
        title={cellHoverText}
        onClick={this.props.f_onClick}>
        <LibraryReact.Nowrap>
          {valueMask}
        </LibraryReact.Nowrap>
      </div>
    );
  }
}));

function AddEditDeleteTableEditItemFloatingBox(props) { //props: p_dataRowObj, p_aedTableObj, f_onClickClose
  const p_dataRowObj = props.p_dataRowObj;
  const p_aedTableObj = props.p_aedTableObj;

  var editItemFloatingBoxTitle = "Editing " + p_aedTableObj.itemName;
  if(p_dataRowObj.name !== undefined) {
    editItemFloatingBoxTitle += " '" + p_dataRowObj.name + "'";
  }
  else if(p_dataRowObj.display_name !== undefined) {
    editItemFloatingBoxTitle += " '" + p_dataRowObj.display_name + "'";
  }
  else {
    editItemFloatingBoxTitle += " (ID: " + p_dataRowObj.id + ")";
  }

  return(
    <CEGeneralReact.FloatingBoxWithSaveCancel
      p_trblFlag="addEditDeleteTableEditItem"
      p_title={editItemFloatingBoxTitle}
      f_onClickCancel={props.f_onClickClose}>
      <div className="flex11a yScroll medFullPad">
        {p_aedTableObj.fieldsArrayOfObjs.map((m_fieldObj) =>
          <AddEditDeleteTableEditItemFieldEditSaveCancel
            key={m_fieldObj.dbName}
            p_aedTableObj={p_aedTableObj}
            p_fieldObj={m_fieldObj}
            p_dataRowObj={p_dataRowObj}
          />
        )}
      </div>
    </CEGeneralReact.FloatingBoxWithSaveCancel>
  );
}

const AddEditDeleteTableEditItemFieldEditSaveCancel = inject("AdminMobx", "DatabaseMobx")(observer(
class AddEditDeleteTableEditItemFieldEditSaveCancel extends Component { //props: p_aedTableObj, p_fieldObj, p_dataRowObj
  onsavechanged_field = (i_newValue) => {
    const p_aedTableObj = this.props.p_aedTableObj;
    const fieldObj = this.props.p_fieldObj;
    const p_dataRowObj = this.props.p_dataRowObj;

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - AddEditDeleteTableEditItemFieldEditSaveCancel", "onsavechanged_field", ["i_newValue"], [i_newValue]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(p_aedTableObj.tblName, p_dataRowObj.id, fieldObj.dbName, i_newValue, fieldObj.fieldTypeObj.idsb);
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_aedTableObj = this.props.p_aedTableObj;
    const fieldObj = this.props.p_fieldObj;
    const p_dataRowObj = this.props.p_dataRowObj;

    //if rawValueDisplayedFieldNotOnFormTF, the EditSaveCancel line item is completely omitted
    if(fieldObj.rawValueDisplayedFieldNotOnFormTF || fieldObj.matchingCapturesObjTF) {
      return(null);
    }

    const valueRaw = p_dataRowObj[fieldObj.dbName];

    if(fieldObj.fieldTypeObj.editOptionsForSelectFieldTF) { //AED table to edit the options for a select table (uses addNewItemInlineTextInputFieldDbName to have inline add new option for tbl_cap tables)
      if(!p_dataRowObj.fieldTypeObj.requiresSelectWithSearchDataObjTF) {
        return(null);
      }

      var aedTableComponent = null;
      if((p_dataRowObj.fieldTypeObj.selectWithSearchDataObj === undefined) || !JSFUNC.is_array(p_dataRowObj.fieldTypeObj.selectWithSearchDataObj.valueArray)) {
        aedTableComponent = (
          <div className="smallFullMargin">
            <font className="fontItalic fontTextLighter">
              {"--The Options reference table for this field does not exist--"}
            </font>
          </div>
        );
      }
      else if(p_dataRowObj.select_tbl_name === "tbl_a_contract_types") {
        const contractTypeSwsOptionsObj = undefined;
        const contractTypesSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Contract Type", [1, 2, 3], false, ["Single Award Contract", "IDIQ", "IDIQ Task Order"], contractTypeSwsOptionsObj);
        const selectContractTypeType123FieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", contractTypesSwsDataObj);

        const contractTypesAedTableObj = {
          tblName: "tbl_a_contract_types",
          dragToResortFieldDbName: "sort",
          initialSortedFieldDbName: "sort",
          tableWidth: "100%",
          itemName: "Contract Type",
          deleteItemNameFieldDbName: "name",
          fieldsArrayOfObjs: [
            {width:"100em", dbName:"sa1_idiq2_to3", displayName:"Contract Type", fieldTypeObj:selectContractTypeType123FieldTypeObj, initialValue:1, cellHoverTF:true, requiredNotEmptyTF:true},
            {width:"200em", dbName:"name", displayName:"Contract Type Name", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
            {width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1}
          ]
        };

        aedTableComponent = (
          <AddEditDeleteTable
            p_dataArrayOfObjs={this.props.AdminMobx.c_contractTypesArrayOfObjs}
            p_aedTableObj={contractTypesAedTableObj}
          />
        );
      }
      else if(JSFUNC.in_array(p_dataRowObj.select_tbl_name, ["tbl_a_reasons_won_lost", "tbl_a_reasons_won", "tbl_a_reasons_lost"])) {
        const selectWonLostBothFieldTypeObj = this.props.DatabaseMobx.create_verticalswitch_field_type_obj_from_value_array_and_display_array("Won/Lost/Both", [1, 2, 3], false, ["Won", "Lost", "Both"], undefined);

        const reasonsWonLostAedTableObj = {
          tblName: "tbl_a_reasons_won_lost",
          dragToResortFieldDbName: undefined,
          initialSortedFieldDbName: ["won1_lost2_both3", "name"],
          tableWidth: "100%",
          itemName: "Reason Won/Lost",
          deleteItemNameFieldDbName: "name",
          fieldsArrayOfObjs: [
            {width:"400em", dbName:"name", displayName:"Reason Text", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, requiredNotEmptyTF:true},
            {width:"100em", dbName:"won1_lost2_both3", displayName:"Won/Lost/Both", fieldTypeObj:selectWonLostBothFieldTypeObj, initialValue:1, cellCenterTF:true, requiredNotEmptyTF:true}
          ]
        };

        aedTableComponent = (
          <AddEditDeleteTable
            p_dataArrayOfObjs={this.props.AdminMobx.c_reasonsWonLostArrayOfObjs}
            p_aedTableObj={reasonsWonLostAedTableObj}
          />
        );
      }
      else { //tbl_cap references and other tables
        const tblNameFirst10Chars = p_dataRowObj.select_tbl_name.substring(0, 10);
        const tblIsCapNameTF = (tblNameFirst10Chars === "tbl_cap_n_");
        const tblIsCapSortTF = (tblNameFirst10Chars === "tbl_cap_s_");
        if(tblIsCapNameTF || tblIsCapSortTF) { //tbl cap tables
          const tblRefMapOfMaps = this.props.DatabaseMobx.tbl_ref_from_tbl_name(p_dataRowObj.select_tbl_name);
          if(tblRefMapOfMaps === undefined) {
            aedTableComponent = (
              <div className="smallFullMargin">
                <font className="fontItalic fontTextLighter">
                  {"--The Options reference table for this field does not exist (" + p_dataRowObj.select_tbl_name + ")--"}
                </font>
              </div>
            );
          }
          else {
            const tblCapOptionsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(tblRefMapOfMaps);

            var tblCapAedTableObj = {
              tblName: p_dataRowObj.select_tbl_name,
              dragToResortFieldDbName: ((tblIsCapSortTF) ? ("sort") : (undefined)),
              initialSortedFieldDbName: ((tblIsCapSortTF) ? ("sort") : ("name")),
              itemName: "Option",
              searchFieldDbName: "name",
              addNewItemInlineTextInputFieldDbName: "name",
              importFromCsvIntoFieldDbName: "name",
              importFromCsvLabel: "Import Options for Details Field '" + p_dataRowObj.display_name + "'",
              fieldsArrayOfObjs: [
                {width:"100em", dbName:"name", displayName:"Option", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true}
              ]
            };
            if(tblIsCapSortTF) {
              tblCapAedTableObj.fieldsArrayOfObjs.push({width:"3em", dbName:"sort", displayName:"Sort", fieldTypeObj:this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj, initialValue:1});
            }

            aedTableComponent = (
              <AddEditDeleteTable
                p_dataArrayOfObjs={tblCapOptionsArrayOfObjs}
                p_aedTableObj={tblCapAedTableObj}
              />
            );
          }
        }
        else { //all highly specilized capture fields that are edited on other Admin subtabs, displays a message pointing to where to go to edit these values
          var optionsMessage = undefined;
          if(p_dataRowObj.fieldTypeObj.selectContactCompanyTF || p_dataRowObj.fieldTypeObj.selectContactPersonTF) { optionsMessage = "--Contacts can be added/edited in the main 'Contacts' tab in the left navigation menu--"; }
          else if(JSFUNC.in_array(p_dataRowObj.select_tbl_name, ["tbl_a_users", "tbl_a_users_contracts", "tbl_a_users_budget"])) { optionsMessage = "--Users can be added/edited in the Admin 'System Setup' tab under subtab 'Users' in the left navigation menu--"; }
          else if(p_dataRowObj.select_tbl_name === "tbl_a_divisions") { optionsMessage = "--Divisions can be added/edited in the Admin 'System Setup' tab under subtab 'Company/Divisions' in the left navigation menu--"; }
          else if(p_dataRowObj.select_tbl_name === "tbl_a_capture_types") { optionsMessage = "--Capture Types can be added/edited in the Admin 'System Setup' tab under subtab 'Capture Types' in the left navigation menu--"; }
          else if(p_dataRowObj.select_tbl_name === "tbl_a_stages_pool") { optionsMessage = "--Stages can be added/edited in the Admin 'System Setup' tab under subtab 'Stages' in the left navigation menu--"; }
          else if(p_dataRowObj.select_tbl_name === "tbl_captures_idiq") { optionsMessage = "--New IDIQ Captures can be created on the 'Captures' tab in the left navigation menu--"; }
          else if(p_dataRowObj.select_tbl_name === "tbl_bit_master_set_asides") { optionsMessage = "--Set-Asides (Small Business Certifications) are kept as a Master List. You can view them and choose whether to use abbreviations in the Admin 'System Setup' tab under subtab 'Contacts Fields' in the left navigation menu. Once on that subtab, click the 'Set-Asides' tab from the tabs across the top--"; }
          else if(p_dataRowObj.select_tbl_name === "tbl_bit_master_naics_codes") { optionsMessage = "--NAICS Codes are kept as a Master List, and can be slimmed down using the tool in the Admin 'System Setup' tab under subtab 'Contacts Fields' in the left navigation menu. Once on that subtab, click the 'NAICS Codes' tab from the tabs across the top.--"; }
          else if(p_dataRowObj.select_tbl_name === "tbl_a_capture_priority_levels") { optionsMessage = "--Capture Priority Levels can be added/edited in the Admin 'System Setup' tab under subtab 'Details Fields Options' (then 'Capture Priority/Favorites' tab across the top) in the left navigation menu--"; }
          else if(p_dataRowObj.select_tbl_name === "tbl_a_capture_types_prime_sub") { optionsMessage = "--Prime/Sub is designated for each Capture Type in the Admin 'System Setup' tab under subtab 'Capture Types' in the left navigation menu--"; }
          else { optionsMessage = "--Select options reference table '" + p_dataRowObj.select_tbl_name + "' cannot be edited--"; }

          aedTableComponent = (
            <div className="smallFullMargin">
              <font className="fontItalic fontTextLighter">
                {optionsMessage}
              </font>
            </div>
          );
        }
      }

      return (
        <div className="tbMedMargin">
          <div className="smallBottomMargin">
            <font className="fontBlue">
              {fieldObj.displayName}
            </font>
          </div>
          {aedTableComponent}
        </div>
      );
    }
    else if(fieldObj.fieldTypeObj.editAnswersForSelectQuestionTF) {
      if(valueRaw === undefined) { //questions with answer type 'text' or 'textarea' have the field 'answersArrayOfObjs' in the questionObj set as undefined
        return(null);
      }

      const aedTableObj = {
        tblName: "tbl_a_shaping_select_answers",
        initialSortedFieldDbName: "score0to100",
        initialSortedFieldIsAscTF: false,
        itemName: "Answer",
        newItemConstantFieldNamesArray: ["question_id"],
        newItemConstantValuesArray: [p_dataRowObj.id],
        newItemConstantIdsbArray: ["i"],
        fieldsArrayOfObjs: [
          {width:"200em", dbName:"name", displayName:"Answer", fieldTypeObj:this.props.DatabaseMobx.c_genericTextFieldTypeObj, initialValue:"", cellHoverTF:true, onkeydownenterAddsNewItemTF:true},
          {width:"100em", dbName:"score0to100", displayName:"Score (0-100%, or negative down to -1000%)", fieldTypeObj:this.props.DatabaseMobx.c_genericPercentIntm1000to100FieldTypeObj, initialValue:100, cellCenterTF:true, onkeydownenterAddsNewItemTF:true}
        ]
      };

      return (
        <div className="tbMedMargin">
          <div className="smallBottomMargin">
            <font className="fontBlue">
              {fieldObj.displayName}
            </font>
          </div>
          <AddEditDeleteTable
            p_dataArrayOfObjs={valueRaw}
            p_aedTableObj={aedTableObj}
          />
        </div>
      );
    }

    var valueIsEditableTFU = true;
    var valueMask = undefined;

    //if rawValueFalseDisplaysNAAndNoEditTF is set to true and the valueRaw is false, display "N/A"
    if(fieldObj.rawValueFalseDisplaysNAAndNoEditTF && (valueRaw === false)) {
      valueMask = this.props.DatabaseMobx.not_filled_out_font_html("N/A");
      valueIsEditableTFU = undefined;
    }

    if(fieldObj.rawValueDisplayedFieldIsReadOnlyOnFormTF) {
      valueIsEditableTFU = undefined; //cannot edit this value on the form
    }

    if(fieldObj.onlyEditableDuringCreateUneditableOnEditTF) { //if set to true, then this field cannot be edited on the edit form
      valueIsEditableTFU = false; //false leaves the space for the pencil edit without drawing it
    }

    //if using the valuesToNotIncludeArray for a select, be sure to remove the id number of this item being edited so that it exists when selecting
    var valuesToNotIncludeArray = undefined;
    if(JSFUNC.is_array(fieldObj.valuesToNotIncludeArray)) {
      valuesToNotIncludeArray = JSFUNC.remove_all_values_from_array(valueRaw, fieldObj.valuesToNotIncludeArray);
    }

    //custom field display name renaming based on tblName and field dbName or type
    var fieldDisplayName = fieldObj.displayName;
    if(p_aedTableObj.tblName === "tbl_captures_fields") {
      if((fieldObj.dbName === "num_decimals") && (p_dataRowObj.input_type === "int_positive")) {
        fieldDisplayName = "# Leading Zeros [default 0]";
      }
      else if((fieldObj.dbName === "textarea_details_max_lines") && (p_dataRowObj.input_type === "verticalswitch")) {
        fieldDisplayName = "Displayed Height per Option (as max # lines of text shown)";
      }
    }

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={"aedAddNewItemFieldESC_" + p_aedTableObj.tblName + "_" + fieldObj.dbName}
        p_fieldDisplayName={fieldDisplayName}
        p_fieldTypeObj={fieldObj.fieldTypeObj}
        p_valueRaw={valueRaw}
        p_valueMask={valueMask}
        p_valueIsEditableTFU={valueIsEditableTFU}
        p_valuesToNotIncludeArray={valuesToNotIncludeArray}
        p_containerClass="tbMargin"
        p_fieldClass="fontBlue"
        p_fieldWidth="15em"
        p_fieldNowrapTF={false}
        p_fieldValueVerticallyAlignedTF={true}
        p_floatingBoxTitle={"Editing " + p_aedTableObj.itemName + " (ID: " + p_dataRowObj.id + ") field '" + fieldObj.displayName + "'"}
        f_onSaveChanged={this.onsavechanged_field}
      />
    );
  }
}));


class AEDTableImportItemsFromCsvFile extends Component { //props: p_dataArrayOfObjs, p_aedTableObj
  onclick_import_csv_data_multiinsert_unique_values_and_sort_numbers_into_aed_tbl = (i_uniqueCsvEntriesArray, i_runThisFunctionUponSuccess, i_runThisFunctionUponError) => {
    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;

    const tblName = p_aedTableObj.tblName;
    const dragToResortFieldDbName = p_aedTableObj.dragToResortFieldDbName;
    const importFromCsvIntoFieldDbName = p_aedTableObj.importFromCsvIntoFieldDbName;

    var fieldNamesArray = [importFromCsvIntoFieldDbName]; //start with 'name' field stored in that AED var, add the new sort numbers as a column if this tbl has a resort column
    var valuesArrayOfArrays = [i_uniqueCsvEntriesArray];
    var valueIdsbArray = ["s"];
    if(dragToResortFieldDbName !== undefined) {
      const importedEntryNewSortNumbersArray = JSFUNC.array_fill_incrementing_x_to_xpnm1((p_dataArrayOfObjs.length + 1), i_uniqueCsvEntriesArray.length); //start new sort count at numExistingItems + 1, count through the number of new entries
      fieldNamesArray.push(dragToResortFieldDbName);
      valuesArrayOfArrays.push(importedEntryNewSortNumbersArray);
      valueIdsbArray.push("i");
    }

    const jsDescription = JSFUNC.js_description_from_action("AdminReact - AEDTableImportItemsFromCsvFile", "onclick_import_csv_data_multiinsert_unique_values_and_sort_numbers_into_aed_tbl", ["i_uniqueCsvEntriesArray"], [i_uniqueCsvEntriesArray]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_multi_insert(tblName, fieldNamesArray, valuesArrayOfArrays, valueIdsbArray);

    const functionOnSuccess = (i_parseResponse) => {
      if(i_parseResponse.outputObj.mi0 === "0") {
        i_runThisFunctionUponError();
      }
      else {
        i_runThisFunctionUponSuccess();
      }
    };
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.add_function("onError", i_runThisFunctionUponError);

    C_CallPhpTblUID.execute();
  }

  render() {
    const p_dataArrayOfObjs = this.props.p_dataArrayOfObjs;
    const p_aedTableObj = this.props.p_aedTableObj;

    const importFromCsvLabel = p_aedTableObj.importFromCsvLabel;
    const importFromCsvIntoFieldDbName = p_aedTableObj.importFromCsvIntoFieldDbName;

    const instructionBulletsArray = [
      "The .csv file used for import is expected have 1 column with all of the new values to import.",
      "If you have multiple columns as well as rows of data in your file, the system will import all values from every row and column as separate values (including any headers).",
      "Duplicate values will be detected and only 1 of each unique value will be presented for final import. This also includes checking for duplicate values that already exist in the table prior to import."
    ];
    const importAEDTableItemsFromCsvInstructionsHtml = (
      <CEGeneralReact.BulletList
        p_linesArray={instructionBulletsArray}
        p_fontClass="fontItalic fontTextLight"
        p_dashFontClass="fontTextLight"
      />
    );

    const existingValuesArray = JSFUNC.get_column_vector_from_arrayOfObjs(p_dataArrayOfObjs, importFromCsvIntoFieldDbName);

    return(
      <div className="displayFlexColumnHcVc">
        <CEGeneralReact.ImportDataFromCsvFileButtonWithFloatingBoxInterface
          p_buttonLabel="Import Items from .csv File"
          p_importTitle={importFromCsvLabel}
          p_instructionsHtmlOrUndefined={importAEDTableItemsFromCsvInstructionsHtml}
          p_existingValuesArray={existingValuesArray}
          p_maxNumColumnsInCsvOrUndefined={1}
          p_maxNumColumnsToShow={1}
          p_combineAll2DDataInto1DTF={true}
          p_markAndPreventDuplicateEntriesTF={true}
          f_onClickImportCsvData={this.onclick_import_csv_data_multiinsert_unique_values_and_sort_numbers_into_aed_tbl}
        />
      </div>
    );
  }
}
